/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/display-name */
import { navigate, useLocation } from '@reach/router';
import React, { useContext } from 'react';
import { LoginRouteUrl } from '../../modules/login/login.route';
import { HcpUserContext, UnauthenticatedUserContext } from '../../UserProvider';

export const withAuth = (Component, redirectPath = LoginRouteUrl) => {
  return (props) => {
    const user = useContext(HcpUserContext);
    const location = useLocation();
    const unauthenticatedUser = useContext(UnauthenticatedUserContext);
    if (user === null) {
      unauthenticatedUser.setPathAfterLogin(location.pathname);
      navigate(redirectPath);
    }
    if (!user) {
      /**
       * These classNames are defined on `packages/app.health-carer/src/index.html`
       */
      return (
        <div className="loader-wrap">
          <div className="loader"></div>
        </div>
      );
    }
    return <Component {...props} />;
  };
};
