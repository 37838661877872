import { Coupon, Health_Carer_Membership_Kind_Enum_Enum } from '../../remote-graphql-types';
import { RedeemCouponKind } from '../coupon-kind';

/*
# DESCRIPTION

| paciente paga | hcp paga | revenue | atualmente                      | nome                 | description                              |
| ------        | ------   | ------  | ------                          | ------               | ------                                   |
| 0             | 0        | 0       | -> EMS                          | FREE_FOR_ALL         | ninguém paga e não há revenue            |
| 0             | 0        | 1       | -> não existe                   | -                    | -                                        |
| 0             | 1        | 0       | -> HCP Pacote avançado          | ADVANCED             | HCP paga e não há revenue                |
| 0             | 1        | 1       | -> não existe                   | -                    | -                                        |
| 1             | 0        | 0       | -> HCP Pacote Básico            | BASIC                | Paciente paga e não há revenue           |
| 1             | 0        | 1       | -> HCP Pacote Básico com share  | BASIC_WITH_REVENUE   | Paciente paga e tem revenue              |
| 1             | 1        | 0       | -> HCP Pacote Suporte           | SUPPORT              | Paciente paga, HCP paga e não há revenue |
| 1             | 1        | 1       | -> HCP Pacote Suporte com share | SUPPORT_WITH_REVENUE | Paciente paga, HCP paga e tem revenue    |
*/

/*
# relationship of membership-kind with coupon-kind

There are some exceptions, but in general:
- FREE_FOR_ALL         = should be used with `subscription_expiration_with_date` or `subscription_expiration_in_days`
- ADVANCED             = should be used with `subscription_expiration_in_days`
- BASIC                = should be used with `relationship`
- BASIC_WITH_REVENUE   = should be used with `relationship`
- SUPPORT              = should be used with `subscription_discount_30`
- SUPPORT_WITH_REVENUE = ?
*/

export const MembershipKindsThatReceiveRevenue = [
  Health_Carer_Membership_Kind_Enum_Enum.BasicWithRevenue,
  Health_Carer_Membership_Kind_Enum_Enum.SupportWithRevenue,
];

export const MembershipKindsThatDontReceiveRevenue = [
  Health_Carer_Membership_Kind_Enum_Enum.Advanced,
  Health_Carer_Membership_Kind_Enum_Enum.Basic,
  Health_Carer_Membership_Kind_Enum_Enum.FreeForAll,
  Health_Carer_Membership_Kind_Enum_Enum.Support,
];

type MapMembershipKindToCouponInfoResponse = Pick<Coupon, 'kind' | 'value' | 'expiration_date'>;
export const mapMembershipKindToCouponInfo = (
  membershipKind: Health_Carer_Membership_Kind_Enum_Enum,
  value?: string
): MapMembershipKindToCouponInfoResponse => {
  const relationshipCoupon: MapMembershipKindToCouponInfoResponse = {
    kind: RedeemCouponKind.Relationship,
    value,
  };
  const subscriptionExpirationCoupon: MapMembershipKindToCouponInfoResponse = {
    kind: RedeemCouponKind.SubscriptionExpirationInDays,
    value: value ?? '365',
    expiration_date: null,
  };
  switch (membershipKind) {
    case Health_Carer_Membership_Kind_Enum_Enum.BasicWithRevenue:
      return relationshipCoupon;
    case Health_Carer_Membership_Kind_Enum_Enum.SupportWithRevenue:
      return relationshipCoupon;
    case Health_Carer_Membership_Kind_Enum_Enum.Advanced:
      return subscriptionExpirationCoupon;
    case Health_Carer_Membership_Kind_Enum_Enum.Basic:
      return relationshipCoupon;
    case Health_Carer_Membership_Kind_Enum_Enum.FreeForAll:
      return subscriptionExpirationCoupon;
    case Health_Carer_Membership_Kind_Enum_Enum.Support:
      return {
        kind: RedeemCouponKind.SubscriptionDiscount70,
        value,
      };
    default:
      return {
        kind: null,
        value: null,
      };
  }
};
