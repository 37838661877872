/* eslint-disable @typescript-eslint/no-var-requires */
import { Border, GrayColor, LightColor, H3Style, Spacing } from '@web/atomic';
import styled from 'styled-components';

export const LoginBoxStyled = styled.div`
  background-color: ${GrayColor.White};
  display: inline-block;
  border-radius: ${Border.Radius};
  overflow: hidden;
  min-width: 320px;
  max-width: 360px;
  word-break: break-word;
`;

export const LoginBoxContentStyled = styled.div`
  padding: ${Spacing.Medium};
`;

const blobPath = require('./blob.svg');
export const BlobStyled = styled.div`
  position: relative;
  background-color: ${GrayColor.White};
  z-index: 1;
  &:before {
    content: '';
    background-color: ${GrayColor.GrayXXLight};
    height: 100px;
    position: absolute;
    width: 100%;
    z-index: -1;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    background-image: url(${blobPath});
    background-size: cover;
    width: 100%;
    z-index: -1;
    background-position: bottom;
    bottom: -${Spacing.Large};
    height: 120px;
  }
`;

export const LoginLogoTitleStyled = styled.div`
  ${H3Style};
  color: ${LightColor.Primary};
  white-space: nowrap;
  margin: 0;
  letter-spacing: 1px;
`;
