import { Border, GrayColor, LightColor, HeaderHeight, Spacing, ThemeProps, BrandColor, Alpha } from '@web/atomic';
import { hexToRGBA, getLevel1Color } from '@global/utils/ui/color-fn';
import styled from 'styled-components';

export const OverflowHiddenWrapper = styled.div`
  overflow: hidden;
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
`;

const minMargin = '6px';
export interface CardWrapperProps {
  height?: string;
  noPadding?: boolean;
  mdMb?: string;
  smMb?: string;
}

export const PaddingWrapper = styled.div`
  padding: ${Spacing.Medium};
`;

export const CardWrapper = styled.div<CardWrapperProps & ThemeProps>`
  overflow: hidden;
  height: ${(props) => props.height || 'auto'};
  padding: ${(props) => (props.noPadding ? 0 : Spacing.Medium)};
  background: ${(props) => getLevel1Color(props.theme.color.background)};
  box-shadow: ${(props) => (props.theme.name === 'regular' ? `0 2px 6px 0 #cdcdcd` : 'none')};
  border-radius: ${Border.Radius};
  margin-bottom: ${minMargin};
  @media all and (max-width: 64em) {
    margin-bottom: ${(props) => props.mdMb || minMargin};
  }
  @media all and (max-width: 48em) {
    margin-bottom: ${(props) => props.smMb || minMargin};
  }
`;
export const BottomSeparatorWrapper = styled.div`
  padding: ${Spacing.Medium};
  border-bottom: 4px solid ${GrayColor.GrayXXLight};
`;

export const FeaturedWrapper = styled.div<ThemeProps>`
  background: ${GrayColor.GrayXXLight};
  background: ${getFeaturedWrapperColor()};
  padding: ${Spacing.Medium};
  border: 1px solid ${getFeaturedWrapperColor};
  height: 100%;
`;
function getFeaturedWrapperColor() {
  return (props) => (props.theme.name === 'regular' ? GrayColor.GrayXXLight : getLevel1Color(props.theme.color.background));
}

export interface MultipleCollumnWrapperProps {
  numberOfCollumns: number;
  numberOfCollumnsMobile?: number;
  collumnGap?: number;
}
export const MultipleCollumnWrapper = styled.div<MultipleCollumnWrapperProps>`
  column-count: ${(props) => (props.numberOfCollumnsMobile ? `${props.numberOfCollumnsMobile}` : 1)};
  @media all and (min-width: 64em), print {
    column-count: ${(props) => `${props.numberOfCollumns}`};
    column-gap: ${(props) => (props.collumnGap ? `${props.collumnGap}` : '')};
  }
`;

export const BorderRoundWrapper = styled.div`
  overflow: hidden;
  border-radius: ${Border.Radius};
  position: relative;
`;

export const StickButtonWrapper = styled.div`
  position: sticky;
  z-index: 3;
  bottom: 0;
  margin: auto;
  padding-top: ${Spacing.Large};
  padding-bottom: ${Spacing.Large};
  width: 90%;
`;

export const TopStickyWrapper = styled.div`
  position: sticky;
  top: ${HeaderHeight.Mobile}px;
  @media all and (min-width: 64em) {
    top: ${HeaderHeight.Desk}px;
  }
  overflow: auto;
  z-index: 2;
  max-height: calc(100vh - ${HeaderHeight.Desk}px);
  padding-bottom: ${Spacing.XLarge};
`;

export const FieldsWrapper = styled.div`
  margin-right: ${Spacing.Medium};
`;

export const CellStyled = styled.div`
  padding: ${Spacing.Large} ${Spacing.XSmall};
  :hover {
    background-color: ${hexToRGBA(GrayColor.Gray, Alpha.L2)};
  }
  :active {
    background-color: ${hexToRGBA(GrayColor.Gray, Alpha.L3)};
  }
`;

/**
 * This component can be used to implement an "accordion" component
 */
export interface CollapsibleWrapperProps {
  expanded: boolean;
  contentHeight: number;
  minHeight: number;
}
export const CollapsibleWrapperStyled = styled.div<CollapsibleWrapperProps>`
  max-height: ${(props) => (props.expanded ? `${props.contentHeight}px` : `${props.minHeight}px`)};
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
`;
