import { noDiactrics } from './diactrics';

export const diactriclessKebabCase = (str: string): string => noDiactrics(kebabCase(str));

// https://gist.github.com/thevangelist/8ff91bac947018c9f3bfaad6487fa149#gistcomment-2183914
const kebabCase = (str: string) =>
  str
    ? str
        .trim()
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace(/\s+/g, '-')
        .toLowerCase()
    : '';

// https://flaviocopes.com/how-to-uppercase-first-letter-javascript/
export const capitalize = (s?: string): string => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};
