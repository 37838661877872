import { HealthCarerPatientReportRoute, HealthCarerPatientReportRouteUrl } from '@global/utils/domain/health-carer/patient-report-url';
import { RouteComponentProps } from '@reach/router';
import * as React from 'react';
import AuthDoctorReportPage from './patient-report.page';

export const PatientReportRouteUrl = HealthCarerPatientReportRouteUrl;

interface PatientReport extends RouteComponentProps, HealthCarerPatientReportRoute {}

export const PatientReportRoute: React.FunctionComponent<PatientReport> = (props) => <AuthDoctorReportPage user={props.user} />;
