export type WhatsappNumberKind = 'support' | 'sales';

const getWhatsappNumber = (kind: WhatsappNumberKind) => {
  const defaultWhatsappNumber = '5511911579831';

  switch (kind) {
    case 'sales':
      return defaultWhatsappNumber;
    case 'support':
      return defaultWhatsappNumber;
    default:
      console.error('ERROR: whatsapp-fab.component.tsx ~ line 33 ~ ', kind);
      break;
  }
};

export const whatsappLink = (kind: WhatsappNumberKind) => `https://api.whatsapp.com/send?phone=${getWhatsappNumber(kind)}`;

export const getWhatsappClickToChat = (kind: WhatsappNumberKind, whatsappText = `Olá%20Vigilantes%20do%20Sono!`) =>
  `https://wa.me/${getWhatsappNumber(kind)}?text=${whatsappText}`;
