import { VigilantesDataSource } from './vigilantes.datasource';
import { usePost, UsePostInput, UsePostState } from './use-post.hook';
import { JsonObject } from 'type-fest';

export function usePostUsingNoAuth<Request = JsonObject, DesiredResponse = JsonObject, RawResponse = JsonObject>(
  input: UsePostInput<DesiredResponse>
): [(data?: Request, mapper?: (response: RawResponse) => DesiredResponse) => Promise<DesiredResponse>, UsePostState<DesiredResponse>] {
  const datasource = new VigilantesDataSource({ getAuthHeader: async () => ({}) });
  return usePost<Request, DesiredResponse, RawResponse>(input, datasource);
}
