import * as React from 'react';
import { EnhancedSelectImplementation } from './enhanced-select-implementation.component';
import { AsyncCreatableSelectStyled, AsyncSelectStyled, CreatableSelectStyled, SelectStyled } from './enhanced-select.component.style';
import { SelectProps } from './enhanced-select.utils';

/**
 * This component is a `react-select` wrapper. It is integrated with the
 * Taqtile form component and with this project field style.
 *
 * To learn more about its usage, check this: https://react-select.com/home
 *
 */
export class EnhancedSelect extends React.Component<SelectProps> {
  private selectRef: React.RefObject<EnhancedSelectImplementation> = React.createRef();

  public render() {
    const SelectComponent = this.props.isCreatable
      ? this.props.loadOptions
        ? AsyncCreatableSelectStyled
        : CreatableSelectStyled
      : this.props.loadOptions
      ? AsyncSelectStyled
      : SelectStyled;

    return <EnhancedSelectImplementation {...this.props} ref={this.selectRef} selectComponent={SelectComponent} />;
  }

  public focus() {
    return this.selectRef.current.focus();
  }
}
