import { onlyNumber } from '../string/only-number';

// https://pt.wikipedia.org/wiki/N%C3%BAmeros_de_telefone_no_Brasil#C.C3.B3digos_de_.C3.81rea
const listOfDDD = [
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '21',
  '22',
  '24',
  '27',
  '28',
  '31',
  '32',
  '33',
  '34',
  '35',
  '37',
  '38',
  '41',
  '42',
  '43',
  '44',
  '45',
  '46',
  '47',
  '48',
  '49',
  '51',
  '53',
  '54',
  '55',
  '61',
  '62',
  '63',
  '64',
  '65',
  '66',
  '67',
  '68',
  '69',
  '71',
  '73',
  '74',
  '75',
  '77',
  '79',
  '81',
  '82',
  '83',
  '84',
  '85',
  '86',
  '87',
  '88',
  '89',
  '91',
  '92',
  '93',
  '94',
  '95',
  '96',
  '97',
  '98',
  '99',
];
const onlyNumbers = (str?: string) => str?.replace(/\D/g, '') || '';

/**
 * this method removes non-number characters and tries to identify a brazilian cellphone number without DDI
 * @param phone
 */
export const fixPhoneNumber = (phone: string) => {
  const num = onlyNumbers(phone);
  if (!num) {
    return undefined;
  }
  if (num.startsWith('559')) return num;
  if (num.length < 3) return phone;

  const prefix = num.slice(0, 2);
  const thirdDigit = num[2];
  const brazilianCellphoneWithoutDDI = listOfDDD.includes(prefix) && thirdDigit === '9';
  if (brazilianCellphoneWithoutDDI) {
    return `55${num}`;
  }

  return num;
};
