import { HcpUserContext } from '@app/health-carer/src/UserProvider';
import { navigate } from '@reach/router';
import { Body, Col, FaIcon, Grid, H1, Row, Separator } from '@web/atomic';
import { Placeholder } from '@web/atomic/legacy/mol.placeholder';
import { LoadingState } from '@web/atomic/legacy/obj.loading-state';
import { Modal } from '@web/atomic/legacy/obj.modal';
import { useBooleanState } from '@web/atomic/obj.boolean-state/boolean-state.hook';
import { useDocumentTitle } from '@web/atomic/obj.custom-hooks/document-title.hook';
import { useQueryCustom } from '@web/data/use-query-custom.hook';
import React, { useContext, useEffect } from 'react';
import { LightBlueBackground } from '../../components/atm.background/background.styled';
import { HcpWhatsappFab } from '../../components/atm.hcp-whatsapp-fab/hcp-whatsapp-fab.component';
import FinancialSignUpInvite from '../../components/mol.financial-sign-up-invite/financial-sign-up-invite.component';
import { Layout } from '../../components/org.layout/layout.component';
import { HealthCarerFinancesQuery } from '../../data/graphql/health-carer-finances.query.hasura';
import { PatientListRouteUrl } from '../patient-list/patient-list.route';
import FinancialActivityEmptyListPlaceholder from './components/financial-activity-empty-list.component';
import MoneyTransferConfirmation from './components/money-transfer-form/money-transfer-form';
import { Transactions } from './components/transactions.component';
import { WithdrawMoney } from './components/withdraw-money.component';

const FinancialActivityPage: React.FunctionComponent = () => {
  useDocumentTitle('Finanças');

  const user = useContext(HcpUserContext);
  const id = user.uid;

  const {
    data: transactionsData,
    error: transactionsError,
    loading: transactionsLoading,
    refetch,
  } = useQueryCustom(HealthCarerFinancesQuery, {
    variables: { id },
  });
  const hasPendingRequest = !!transactionsData?.health_carer_by_pk.health_carer_money_requests?.[0];
  const hasTransactions = !!transactionsData?.health_carer_by_pk.health_carer_transactions.length;

  useEffect(() => {
    if (!hasTransactions && !hasPendingRequest && !transactionsLoading) {
      navigate(PatientListRouteUrl);
    }
  }, [user.membership_kind]);

  const content = (
    <LightBlueBackground>
      <Grid fluid>
        <Row>
          <Col xs={12} sm={6} md={4} lg={3}>
            <FinancialActivitySidebar
              hcpId={id}
              hasPendingRequest={hasPendingRequest}
              onWithdrawSuccess={() => {
                refetch();
                navigate('/');
              }}
            />
          </Col>
          <Col xs={12} sm={6} md={8} lg={6}>
            <Transactions transactions={transactionsData?.health_carer_by_pk.health_carer_transactions} />
          </Col>
        </Row>
      </Grid>
    </LightBlueBackground>
  );

  return (
    <Layout>
      {hasTransactions && (
        <Grid fluid>
          <Row mt mb>
            <Col xs={12} sm={12} md={7} lg={7}>
              <H1> Finanças </H1>
              <Body>Veja sua atividade financeira e faça o resgate do seu saldo disponível.</Body>
            </Col>
          </Row>
        </Grid>
      )}

      <LoadingState loading={transactionsLoading} error={!!transactionsError} data={hasTransactions}>
        <LoadingState.Shimmer>{content}</LoadingState.Shimmer>
        <LoadingState.Error>
          <Grid fluid>
            <Placeholder
              icon={<FaIcon.FlashAlert size="9x" />}
              title={'Erro ao pegar seus dados'}
              description="Tente novamente mais tarde"
            />
          </Grid>
        </LoadingState.Error>
        <LoadingState.NoData>
          <FinancialActivityEmptyListPlaceholder />
        </LoadingState.NoData>
        {content}
      </LoadingState>
      <HcpWhatsappFab />
    </Layout>
  );
};

export default FinancialActivityPage;

////////////////////////////////////////////////////////////////////////////////////////////////////

interface IFinancialActivitySidebarProps {
  hcpId: string;
  hasPendingRequest: boolean;
  onWithdrawSuccess: () => void;
}

const FinancialActivitySidebar: React.FunctionComponent<IFinancialActivitySidebarProps> = (props) => {
  const bool = useBooleanState(false);

  const user = useContext(HcpUserContext);
  const balance = user?.balance;
  const balanceToReceive = (user?.balanceAdditionalInfo.data?.waiting_funds_amount ?? 0) / 100;

  if (user?.balanceAdditionalInfo.error) {
    return (
      <Placeholder
        icon={<FaIcon.FlashAlert size="9x" />}
        title={'Erro ao pegar seu saldo de resgate'}
        description="Tente novamente mais tarde"
      />
    );
  }
  return (
    <>
      {!user?.balanceAdditionalInfo.loading && !user.hasFinancialInfo && (
        <>
          <Separator />
          <FinancialSignUpInvite
            title="[Ação necessária] A opção de resgate estará sendo removida em breve"
            body="A opção de resgate estará sendo removida em breve, caso tenha dificuldades, favor entrar em contato com nosso suporte."
            hasFinancialInfo={user.hasFinancialInfo}
          />
        </>
      )}
      <WithdrawMoney
        pendingRequest={props.hasPendingRequest}
        fetchLoading={user?.balanceAdditionalInfo.loading}
        balance={balance}
        balanceToReceive={balanceToReceive}
        onWithdrawClick={bool.setTrue}
      />
      <Modal opened={bool.value} onClose={bool.setFalse} noPadding small>
        <MoneyTransferConfirmation
          balance={balance}
          onCancel={() => {
            bool.setFalse();
          }}
          onSuccess={props.onWithdrawSuccess}
        />
      </Modal>
    </>
  );
};
