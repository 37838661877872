import { useHotjar } from '@app/health-carer/src/service/hotjar.service';
import { TestPatientReportRouteUrl } from '@global/utils/domain/health-carer/patient-report-url';
import { Router } from '@reach/router';
import { GlobalStyled, ThemeContainer } from '@web/atomic';
import { FlashWrapper } from '@web/atomic/legacy/obj.flash-wrapper/flash-wrapper.component';
import { ApolloProvider } from '@web/data/apollo-client';
import * as React from 'react';
import { ThemeProvider } from 'styled-components';
import './App.css';
import firebaseAuthClient from './data/firebase-auth-client';
import { FinancialActivityRoute, FinancialActivityRouteUrl } from './modules/financial-activity/financial-activity.route';
import { LoginRoute, LoginRouteUrl } from './modules/login/login.route';
import { NotFoundRoute } from './modules/not-found/not-found.route';
import { PatientListRoute, PatientListRouteUrl } from './modules/patient-list/patient-list.route';
import { PatientReportRoute, PatientReportRouteUrl } from './modules/patient-report/patient-report.route';
import { PrintPatientReportRoute, PrintPatientReportRouteUrl } from './modules/patient-report/print-patient-report.route';
import { TestPatientReportRoute } from './modules/patient-report/test-patient-report.route';
import { SignUpRoute, SignUpRouteUrl } from './modules/sign-up/sign-up.route';
import { HcpUserProvider, UnauthenticatedUserProvider } from './UserProvider';

const App: React.FunctionComponent = () => {
  useHotjar();

  return (
    <ThemeContainer>
      {(theme) => {
        return (
          <ThemeProvider theme={theme}>
            <GlobalStyled />
            <FlashWrapper />
            <ApolloProvider client={firebaseAuthClient}>
              <UnauthenticatedUserProvider>
                <HcpUserProvider>
                  <Router>
                    <PatientReportRoute path={PatientReportRouteUrl} />
                    <TestPatientReportRoute path={TestPatientReportRouteUrl} />
                    <LoginRoute path={LoginRouteUrl} />
                    <SignUpRoute path={SignUpRouteUrl} />
                    <PatientListRoute path={PatientListRouteUrl} />
                    <PrintPatientReportRoute path={PrintPatientReportRouteUrl} />
                    <FinancialActivityRoute path={FinancialActivityRouteUrl} />
                    <NotFoundRoute default />
                  </Router>
                </HcpUserProvider>
              </UnauthenticatedUserProvider>
            </ApolloProvider>
          </ThemeProvider>
        );
      }}
    </ThemeContainer>
  );
};

export default App;
