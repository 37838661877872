import { faCogs, faComments, faIdCard, faKey, faMoneyBillWaveAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { debounce } from '@global/utils/function/debounce';
import { formatAsPrice } from '@global/utils/number/format';
import { isNullOrUndefined } from '@global/utils/object/null-or-undefined';
import { MatchMedia } from '@web/atomic/legacy/obj.match-media';
import { PrivacyPolicyComponent } from '@web/atomic/mol.privacy-policy/privacy-policy-component';
import { useBooleanState } from '@web/atomic/obj.boolean-state/boolean-state.hook';
import { sendDataLayer } from '@web/utils/analytics';
import * as React from 'react';
import { HcpUserContext } from '../../UserProvider';
import AuthDatasource from '../../data/auth-datasource';
import { FinancialActivityRouteUrl } from '../../modules/financial-activity/financial-activity.route';
import { DropdownMenu, NavItem } from '../mol.dropdown-menu/dropdown-menu.component';
import ChangePasswordModal from '../org.change-password-modal/change-password-modal.component';
import { EditMenuModal } from '../org.edit-menu/edit-menu-modal.component';
import { Header } from '../org.header/header.component';

export const Layout: React.FunctionComponent = (props) => {
  return (
    <>
      <MatchMedia defaultMinWidth={`48rem`}>{(md) => <LayoutHeader mobile={md} />}</MatchMedia>
      {props.children}
    </>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////
interface LayoutHeaderProps {
  mobile: boolean;
}

const LayoutHeader: React.FunctionComponent<LayoutHeaderProps> = (props) => {
  const { value: dropdownActive, toggle } = useBooleanState(false);
  const { value: passwordModalOpened, setTrue: openPasswordModal, setFalse: closePasswordModal } = useBooleanState(false);
  const { value: dataModalOpened, setTrue: openDataModal, setFalse: closeDataModal } = useBooleanState(false);

  const user = React.useContext(HcpUserContext);

  // WHY?
  // When the dropdown-menu is open and the user clicks on the hamburger-menu, two events are fired. This debounce is used to "aggregate" them (to fire the toggle only once).
  const toggleMenu = React.useCallback(() => {
    toggle();
    sendDataLayer({
      event: 'custom_click',
      id: `ga-toggle-menu`,
    });
  }, [toggle]);
  const debouncedToggle = debounce(toggleMenu, 150);

  const navItems: NavItem[] = [
    props.mobile &&
      !isNullOrUndefined(user?.balance) && {
        text: `Saldo: R$ ${formatAsPrice(user.balance)}`,
        faIcon: faMoneyBillWaveAlt,
        href: FinancialActivityRouteUrl,
        event_category: 'finances',
        event_label: 'go to finances page',
      },
    {
      text: 'Como funciona',
      faIcon: faCogs,
      href: 'https://pages.qwilr.com/Vigilantes-do-Sono-Informa-es-para-profissionais-de-sa-de-2XCybtOSGyBP',
      event_category: 'how_it_works',
      event_label: 'go to how it works page',
    },
    {
      text: 'Comunidade',
      faIcon: faComments,
      href: 'https://comunidade.vigilantesdosono.com/',
      event_category: 'community',
      event_label: 'go to community page',
    },
    {
      text: 'Alterar dados',
      faIcon: faIdCard,
      onClick: openDataModal,
      event_category: 'change_data',
      event_label: 'open change data modal',
    },
    {
      text: 'Alterar senha',
      faIcon: faKey,
      onClick: openPasswordModal,
      event_category: 'change_password',
      event_label: 'open change password modal',
    },
    {
      text: 'Sair',
      faIcon: faSignOutAlt,
      onClick: AuthDatasource.logout,
      event_category: 'sign_out',
      event_label: 'sign out',
    },
  ].filter((item) => item);

  return (
    <>
      <Header userName={user?.name} balance={user?.balance} onMenuClick={debouncedToggle} />
      {user && (
        <>
          <DropdownMenu isVisible={dropdownActive} navItems={navItems} onCloseEvent={debouncedToggle} />
          <ChangePasswordModal opened={passwordModalOpened} onClose={closePasswordModal} />
          <EditMenuModal opened={dataModalOpened} onClose={closeDataModal} />
          <PrivacyPolicyComponent />
        </>
      )}
    </>
  );
};

export default LayoutHeader;
