import { parseSeconds } from '../date/parse-seconds';

export const formatRecord = (count: number) => {
  return `gravaç${count > 1 ? 'ões' : 'ão'}`;
};

export const formatSnore = (durationInSeconds: number, count: number) => {
  const recording = formatRecord(count);
  const text = `${parseSeconds(durationInSeconds)} (${count} ${recording})`;
  return text;
};
