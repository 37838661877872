import {
  HealthCarerMoneyWithdrawRequest,
  HealthCarerMoneyWithdrawResponse,
  HealthCarerMoneyWithdrawUrl,
} from '@global/utils/api/health-carer.controller.model';
import { PagarmeTaxes } from '@global/utils/domain/pagarme';
import { formatAsPrice } from '@global/utils/number/format';
import { Body, Col, Grid, H2, Row } from '@web/atomic';
import flashDispatcherService from '@web/atomic/legacy/obj.flash-wrapper/flash-dispatcher.service';
import React, { useContext } from 'react';
import { ReachButton } from '../../../../components/atm.button/button.component';
import { usePostUsingFirebase } from '../../../../components/obj.custom-hooks/use-post-using-firebase.hook';
import { findBankByNumber } from '../../../../data/bank-list';
import { HcpUserContext } from '../../../../UserProvider';
import { IMoneyTransferFormProps } from './money-transfer-form.utils';

export const MoneyTransferConfirmation: React.FunctionComponent<IMoneyTransferFormProps> = (props) => {
  const user = useContext(HcpUserContext);
  const onSuccess = () => {
    user.onUserChange();
    props.onSuccess();
    flashDispatcherService.dispatchMessage('Pedido criado com sucesso! Iremos processar seu pedido em até 2 dias úteis!', 'success');
  };
  const onError = (error) => {
    if (error.status === 412) {
      flashDispatcherService.dispatchMessage('Não foi possível criar seu pedido de resgate. Por favor, entre em contato conosco.', 'alert');
    } else {
      flashDispatcherService.dispatchMessage(
        'Não foi possível criar seu pedido de resgate. Por favor, tente novamente em 1 minuto.',
        'alert'
      );
    }
  };
  const [createMoneyWithdrawRequest, { loading }] = usePostUsingFirebase<HealthCarerMoneyWithdrawRequest, HealthCarerMoneyWithdrawResponse>(
    {
      url: HealthCarerMoneyWithdrawUrl,
      onError: onError,
      onSuccess: onSuccess,
    }
  );

  const withdrawTax = new PagarmeTaxes().getWithdrawTaxForBankCode(user.financialInfo.bank);
  const availableBalance = Math.max(0, props.balance - withdrawTax);

  if (availableBalance === 0) {
    return <MoneyTransferUnavailable availableBalance={availableBalance} withdrawTax={withdrawTax} onCloseClick={props.onCancel} />;
  }

  const handleSubmit = async () => {
    const id = user.uid;
    createMoneyWithdrawRequest({
      user_id: id,
      amount: availableBalance,
    });
  };
  const financialInfo = user.financialInfo;
  return (
    <Grid fluid>
      <Row center="xs">
        <Col xs={12}>
          <H2>Receber dinheiro </H2>
          <Body>
            {withdrawTax > 0
              ? `Para realizar o resgate há uma tarifa bancária de ${formatAsPrice(
                  withdrawTax,
                  true
                )} cobrada pela nossa plataforma de pagamentos. Portanto você receberá ${formatAsPrice(availableBalance, true)}.`
              : ''}
          </Body>
          <Body>
            Você <strong>confirma o recebimento de {formatAsPrice(availableBalance, true)}</strong> em sua conta?
          </Body>
          <Body>
            {findBankByNumber(financialInfo.bank)?.label}
            <br />
            {`Conta: ${financialInfo.account_number}-${financialInfo.account_check_digit}`}
            <br />
            {`Agência: ${financialInfo.branch_number}-${financialInfo.branch_check_digit}`}
          </Body>
        </Col>
      </Row>
      <Row mt mb>
        <Col xs={12}>
          <ReachButton onClick={handleSubmit} loading={loading} type="submit" kind="primary" expanded>
            Confirmar resgate
          </ReachButton>
        </Col>
      </Row>
      <Row mb>
        <Col xs={12}>
          <ReachButton onClick={props.onCancel} loading={loading} kind="secondary" expanded>
            Cancelar
          </ReachButton>
        </Col>
      </Row>
    </Grid>
  );
};

export default MoneyTransferConfirmation;

////////////////////////////////////////////////////////////////////////////////////////////////////

interface IMoneyTransferUnavailableProps {
  availableBalance: number;
  withdrawTax: number;
  onCloseClick: () => void;
}

const MoneyTransferUnavailable: React.FunctionComponent<IMoneyTransferUnavailableProps> = (props) => {
  const { availableBalance, withdrawTax, onCloseClick } = props;
  if (availableBalance > 0) {
    return null;
  }
  return (
    <Grid fluid>
      <Row center="xs" mb>
        <Col xs={12}>
          <H2>Receber dinheiro </H2>
          <Body>
            Para realizar o resgate há uma tarifa bancária de {formatAsPrice(withdrawTax, true)} cobrada pela nossa plataforma de
            pagamentos. Portanto, não é possível realizar o resgate.
          </Body>
        </Col>
      </Row>

      <Row mb>
        <Col xs={12}>
          <ReachButton onClick={onCloseClick} kind="primary" expanded>
            Fechar
          </ReachButton>
        </Col>
      </Row>
    </Grid>
  );
};
