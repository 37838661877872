import { FaIcon } from '@web/atomic/legacy/atm.fa-icon';
import * as React from 'react';
import { ModalBoxStyled, ModalCloseStyled, ModalOpacityStyled, ModalStyled } from './modal.component.style';
import { useLockBodyScroll } from '@web/atomic/obj.custom-hooks/lock-body-scroll.hook';
import ReactDOM from 'react-dom';
import { useBody } from '../../obj.custom-hooks/body';

export interface ModalProps {
  /**
   * it may be useful to set the scrollable id if you need, for instance, to scroll inside it
   * @see scrollToId
   */
  scrollableContainerId?: string;

  /**
   * By default, the modal will lock the body scroll. You may want to disable
   * this if you experience some performance issues
   */
  disableScrollLock?: boolean;

  small?: boolean;
  noPadding?: boolean;
  // https://www.w3schools.com/css/css_overflow.asp
  overflow?: 'visible' | 'hidden' | 'auto';
  position?: 'centered' | 'top';
  opened?: boolean;
  onClose?: () => void;
  /**
   * if "themed", then it will use the app theme
   * if "default", then it will be white
   */
  background?: 'themed' | 'default';
}

// https://reactjs.org/docs/portals.html
const Portal = ({ children, elm }) => ReactDOM.createPortal(children, elm);

export const Modal: React.FunctionComponent<ModalProps> = (props) => {
  useLockBodyScroll(!props.disableScrollLock && props.opened);
  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };
  const body = useBody();
  if (!body) {
    return null;
  }

  return (
    <Portal elm={document.querySelector('body')}>
      <ModalStyled opened={props.opened}>
        <ModalOpacityStyled opened={props.opened} onClick={handleClose} />
        {props.opened && (
          <ModalBoxStyled
            id={props.scrollableContainerId}
            small={props.small}
            noPadding={props.noPadding}
            overflow={props.overflow}
            position={props.position}
            background={props.background}
          >
            <ModalCloseStyled role="button" onClick={handleClose}>
              <FaIcon.Close />
            </ModalCloseStyled>
            {props.children}
          </ModalBoxStyled>
        )}
      </ModalStyled>
    </Portal>
  );
};

Modal.defaultProps = {
  background: 'default',
};
