import * as React from 'react';
import TextFieldMaskedStyled from './text-field-masked.style';
import { TextFieldWrapper } from './text-field-wrapper.component';
import { TextFieldProps } from './text-field.component';

export interface TextFieldMaskedOptionsProps {
  format?: string;
  mask?: string;
}

export interface TextFieldMaskedProps extends TextFieldProps {
  options?: TextFieldMaskedOptionsProps;
  kind?: 'credit-card' | 'cpf' | 'cnpj' | 'zip-code' | 'only-numbers' | 'money' | 'cel-phone' | 'datetime' | string;
}

export interface TextFieldMaskedState {
  value?: string | number | readonly string[];
}

export class TextFieldMasked extends React.Component<TextFieldMaskedProps, TextFieldMaskedState> {
  static defaultProps = {
    kind: 'normal',
  };

  render() {
    const {
      autoComplete,
      onValueChange,
      kind,
      icon,
      loading,
      disabled,
      onChange,
      value,
      options,
      dismissable,
      light,
      initialValue,
      onBlur,
      onFocus,
      invalid,
      maxLength,
      ...other
    } = this.props;

    return (
      <TextFieldWrapper
        initialValue={initialValue}
        value={value}
        icon={icon}
        loading={loading}
        dismissable={dismissable}
        light={light}
        maxLength={maxLength}
        invalid={invalid}
        onValueChange={onValueChange}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        {(childrenProps) => {
          return (
            <TextFieldMaskedStyled
              kind={kind}
              options={options}
              value={childrenProps.value}
              disabled={disabled || loading}
              onChangeText={childrenProps.onChangeText}
              icon={icon}
              length={maxLength}
              dismissable={dismissable}
              ref={childrenProps.ref}
              autoComplete={autoComplete}
              onInput={childrenProps.onInput}
              onBlur={childrenProps.onBlur}
              onFocus={childrenProps.onFocus}
              invalid={childrenProps.invalid}
              pattern={other.type === 'number' ? '[0-9]*' : other.pattern}
              {...other}
            />
          );
        }}
      </TextFieldWrapper>
    );
  }
}
