import { useCallback, useState } from 'react';

export function useField<T = unknown>(defaultValue?: T): [T, (data: T) => void] {
  const [value, setValue] = useState(defaultValue);
  const handleValueChange = useCallback((data) => {
    setValue(data);
  }, []);

  return [value, handleValueChange];
}
