import { FontFamily, FontSize, FontWeight, GrayColor, Spacing } from '@web/atomic/legacy/obj.constants';
import { ThemeProps } from '@web/atomic/obj.theme';
import styled from 'styled-components';
import { ReadOnlyBadgeProps } from './read-only-badge.component';

const getBkgColor = (props: ReadOnlyBadgeProps & ThemeProps) => {
  if (props.kind !== 'primary') {
    return GrayColor.GrayXDark;
  }
  return props.theme.name === 'regular' ? GrayColor.GrayXXLight : 'transparent';
};

const getBorderColor = (props: ReadOnlyBadgeProps & ThemeProps) => {
  if (props.kind !== 'primary') {
    return GrayColor.GrayXDark;
  }
  return props.theme.name === 'regular' ? GrayColor.GrayXXLight : GrayColor.White;
};

const getTextColor = (props: ReadOnlyBadgeProps & ThemeProps) => {
  if (props.kind !== 'primary') {
    return GrayColor.White;
  }
  return props.theme.name === 'regular' ? props.theme.color.primary : GrayColor.White;
};

export const ReadOnlyBadgeStyled = styled.div<ReadOnlyBadgeProps>`
  user-select: none;
  padding: ${Spacing.XSmall} ${Spacing.Small};
  background-color: ${getBkgColor};
  border: 1px solid;
  border-color: ${getBorderColor};
  border-radius: ${0.2 * (parseInt(FontSize.Small, 10) + 2 * parseInt(Spacing.Small, 10))}px;
  align-self: flex-start;
  justify-content: center;
`;

export const ReadOnlyBadgeTextStyled = styled.span<ReadOnlyBadgeProps>`
  color: ${getTextColor};
  font-size: ${FontSize.XXSmall};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Bold};
`;
