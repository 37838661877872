import { useState, useEffect } from 'react';
import { isMobile, isIos, isAndroid } from '@web/utils/browser-detection';

export const useMobile = (): boolean => {
  const [mobile, setMobile] = useState(true);
  useEffect(() => setMobile(isMobile()), []);

  return mobile;
};

export enum OperatingSystem {
  IOS,
  Android,
  NotSet,
  Other, // Desktop, other OS
}

export const useOS = (): OperatingSystem => {
  const [platform, setOperatingSystem] = useState(OperatingSystem.NotSet);
  useEffect(() => {
    const mobile = isMobile();
    if (mobile && isIos()) {
      setOperatingSystem(OperatingSystem.IOS);
    } else if (mobile && isAndroid()) {
      setOperatingSystem(OperatingSystem.Android);
    } else {
      setOperatingSystem(OperatingSystem.Other);
    }
  });

  return platform as OperatingSystem;
};
