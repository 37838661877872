import { getLevel1Color, lightenDarkenColor } from '@global/utils/ui/color-fn';
import { InputPlaceholderCss, InputValueCss } from '@web/atomic/legacy/atm.typography';
import { GrayColor, LightColor, TransitionDuration } from '@web/atomic/legacy/obj.constants';
import { fieldBackgroundCss, fieldBorderCss, FieldProps, fieldTypographyCss } from '@web/atomic/legacy/obj.form/field.component.styled';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatable from 'react-select/async-creatable';
import Creatable from 'react-select/creatable';
import styled, { css } from 'styled-components';
import { fadeInWithTranslate } from '../obj.animation/animation.component.style';

const verticalPadding = 12;

// https://react-select.com/styles
const selectCss = css<FieldProps>`
  ${fieldTypographyCss};
  margin: 0;

  & .select__input-container,
  & .select__single-value,
  & .select__input > input,
  & input.select__input {
    ${fieldTypographyCss};
  }

  & .select__control {
    ${fieldBorderCss};
    ${fieldBackgroundCss}

    overflow: auto;

    &:hover {
      ${fieldBorderCss};
    }

    &.select__control--is-focused {
      border-color: ${({ theme }) => (theme.name === 'regular' ? LightColor.Primary : GrayColor.White)};
      box-shadow: none;
    }
  }

  & svg {
    fill: ${GrayColor.GrayDark};
  }

  & .select__value-container {
    padding: ${verticalPadding}px 0 ${verticalPadding}px 0;
  }

  & .select__placeholder {
    ${InputPlaceholderCss};
  }

  & .select__menu {
    margin: 0;
    animation: ${fadeInWithTranslate} ${TransitionDuration} linear forwards;
    background-color: ${({ theme }) => getLevel1Color(theme.color.background)};
    z-index: 99;
  }

  & .select__menu-list {
  }

  & .select__indicator {
    ${(props: FieldProps) => (props.disabled ? 'display: none;' : '')}
    > svg {
      fill: ${({ theme }) => (theme.name === 'regular' ? GrayColor.Gray : GrayColor.White)};
    }
  }

  & .select__indicator-separator {
    display: none;
  }

  & .select__option {
    ${InputValueCss};
  }

  & .select__option--is-focused {
    background-color: ${({ theme }) => theme.color.primary}20;
  }

  & .select__option--is-focused:active {
    background-color: ${({ theme }) => theme.color.primary}50;
  }

  & .select__option--is-selected {
    background-color: ${GrayColor.White};
    color: ${LightColor.Primary};
  }

  & .select__menu-notice--no-options {
    ${InputValueCss};
  }

  & .select__single-value {
    top: ${verticalPadding * 2}px;
  }

  & .select__multi-value {
    background-color: ${({ theme }) => (theme.name === 'regular' ? `${theme.color.primary}20` : theme.color.primary)};
  }
  & .select__multi-value__label {
    color: ${({ theme }) => (theme.name === 'regular' ? GrayColor.Black : GrayColor.White)};
  }
  & .select__multi-value__remove {
    ${(props: FieldProps) => (props.disabled ? 'display: none;' : '')}
  }
  & .select__multi-value__remove:hover {
    background-color: ${lightenDarkenColor(GrayColor.GrayLight, -20)};
  }
`;

export const SelectStyled = styled(Select).attrs({ classNamePrefix: 'select' })`
  ${selectCss}
`;

export const AsyncSelectStyled = styled(AsyncSelect).attrs({ classNamePrefix: 'select' })`
  ${selectCss}
`;

export const CreatableSelectStyled = styled(Creatable).attrs({ classNamePrefix: 'select' })`
  ${selectCss}
`;

export const AsyncCreatableSelectStyled = styled(AsyncCreatable).attrs({ classNamePrefix: 'select' })`
  ${selectCss}
`;
