import { BodyStyle, H2Style, H3Style, H4Style } from '@web/atomic/legacy/atm.typography';
import { BrandColor, FontFamily, FontSize, FontWeight, GrayColor, LightColor, Spacing } from '@web/atomic/legacy/obj.constants';
import { ThemeProps } from '@web/atomic/obj.theme';
import { createGlobalStyle } from 'styled-components';
import { lightenDarkenColor } from '@global/utils/ui/color-fn';
import { fieldBorderCss, fieldCss } from '../obj.form/field.component.styled';
import { NativeHelper } from '@web/data/native.helper';

export const GlobalStyled = createGlobalStyle<ThemeProps>`
  * {
    box-sizing: border-box;
  }

  html {
    scroll-padding-top: 8rem;
    transition: background-color 0.3s ease-in;
    background-color: transparent;
  }

  html.with-theme {
    /*
     * this class is set by react on a useEffect to make sure the page starts
     * "transparent". This is important to avoid a "flashing" screen effect on
     * dark-mode
     * @see packages/web.atomic/obj.theme/theme.component.tsx
     */
    background-color: ${({ theme }) => theme.color.background};
  }

  body {
    margin:0;
    padding:0;

    width:100%;

    font-family: ${FontFamily.Primary};
  }
  a {
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0.05);
    color: ${({ theme }) => lightenDarkenColor(BrandColor.Hoki, theme.name === 'regular' ? 0 : 50)};
  }
  p {
    ${BodyStyle}
  }

  blockquote {
    &:before {
      color: ${({ theme }) => (theme.name === 'regular' ? theme.color.primary : GrayColor.White)};
      content: open-quote;
      display: block;
      quotes: "“" "“" "“" "“";
      font-size: 3em;
      line-height: 0.1em;
      padding: ${Spacing.Medium} 0 ${Spacing.Small};
    }

    & > p {
      display: inline;
      font-family: ${FontFamily.Secondary};
    }
  }

  .responsive-table-wrapper {
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
  }

  p + table,
  p + .responsive-table-wrapper {
    margin: ${Spacing.Medium} 0;
  }
  table {
    border:none;
    border-collapse: collapse;
  }
  table td, table th {
    border: 1px solid ${GrayColor.GrayXLight};
    border-bottom: none;
  }
  table tr:first-child > th {
    border-top: none;
  }
  table th:first-child,
  table td:first-child {
    border-left: none;
  }
  table th:last-child,
  table td:last-child {
    border-right: none;
  }
  th {
    color: ${({ theme }) => (theme.name === 'dark' ? GrayColor.White : LightColor.Primary)};
    font-family: ${FontFamily.Primary};
    font-size: ${FontSize.XXSmall};
    font-weight: ${FontWeight.SemiBold};
    text-align: left;
    vertical-align: top;
    padding: ${Spacing.Small} ${Spacing.Medium};
  }
  td {
      color: ${({ theme }) => theme.color.text};
      font-size: ${FontSize.XXSmall};
      font-family: ${FontFamily.Primary};
      font-weight: ${FontWeight.Normal};
      text-align: left;
      vertical-align: top;

      padding: ${Spacing.Medium};
  }

  li {
    ${BodyStyle}
  }
  p + p {
    margin-top: ${Spacing.Medium}
  }
  h2 {
    ${H2Style}
  }
  h3 {
    ${H3Style}
  }
  h4 {
    ${H4Style}
  }

  /* https://stackoverflow.com/a/19655421/3670829 */
  input[type="time"]::-webkit-clear-button {
    display: none;
  }

  /* https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }

  /* https://github.com/ghosh/microtip */
  [role~="tooltip"]::after {
    font-family: ${FontFamily.Primary};
  }
  .microtip::after {
    text-align: left;
    white-space: pre-wrap !important;
  }

  /** https://github.com/fisshy/react-scroll */
  .react-scroll-element {
    display: inline;
  }

  /* ReChart label */
  .rechart-right-label {
    transform: translate(-27px, -${Spacing.XSmall});
    font-family: ${FontFamily.Primary};
    fill: ${(props) => (props.theme.name === 'dark' ? GrayColor.GrayXLight : GrayColor.Gray)};
  }
  .rechart-left-label {
    transform: translate(40px, -${Spacing.XSmall});
    font-family: ${FontFamily.Primary};
    fill: ${(props) => (props.theme.name === 'dark' ? GrayColor.GrayXLight : GrayColor.Gray)};
  }
  /* class .footnotes is inserted by remark   */
  /*https://www.notion.so/vigilantesdosono/ajustes-em-artigos-89b81f620bba44cb86005db21ee47f17*/

  /*hide body footnote link*/
  sup[id*="fnref-hide"] {
    display: none;
  }
  .footnote-backref[href*="fnref-hide"] {
    display: none !important;
  }

  .footnote-ref {
    :before {
      content: '['
    }

    :after {
      content: ']'
    }

    cursor: pointer;
  }
  .footnotes {
    > hr {
      display: none
    }

    :before {
      content: 'Referências científicas';
      ${H2Style}
    }

    > ol > li > p {
      font-size: ${FontSize.XXSmall};
      display: inline;
    }

    li + li {
      margin-top: ${Spacing.Medium};
    }

    .footnote-backref {
      :before {
        content: ' ';
        display: inline-block;
        width: ${Spacing.Medium};
      }
      display: inline;
      text-decoration: none;
      color: ${({ theme }) => lightenDarkenColor(BrandColor.Hoki, theme.name === 'regular' ? 0 : 50)};
      cursor: pointer;
    }
  }
  blockquote, .footnotes {
    background: ${({ theme }) => (theme.name === 'regular' ? GrayColor.GrayXXLight : lightenDarkenColor(theme.color.background, 5))};
    border-left: 4px solid ${({ theme }) => (theme.name === 'regular' ? theme.color.primary : GrayColor.White)};
    margin: 1.5em 0;
    border-radius: 4px;
    padding: 1em ${Spacing.Medium};
  }

  /* used for custom rechart label @see cycle-reference.components.tsx */
  .recharts-30min-reference-line {
    font-size: ${FontSize.XXSmall};
    fill: ${BrandColor.Red};
  }

  /* used for custom rechart label @see cycle-reference.components.tsx */
  .recharts-cycle-reference-line {
    font-size: ${FontSize.XXSmall};
    fill: ${GrayColor.GrayLight};
  }

  /* https://css-tricks.com/snippets/css/shake-css-keyframe-animation/ */
  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  .shake-animation-on-hover {
    :hover {
      animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px;
    }
  }

  /*
  * https://github.com/bl00mber/react-phone-input-2#style
  * @see src/atomic/legacy/atm.text-field/phone-field.component.tsx
  */
  .react-phone-input__container {
    ${fieldBorderCss}
    overflow: visible;

    > input.react-phone-input__input {
      ${fieldCss}
      width: 100%;
      height: 48px;
      padding-left: 48px;
      border: 0;
    }
    > div.react-phone-input__button {
      background: transparent !important;
      border: 0;
      border-bottom: 1px solid transparent;
    }
    > .react-phone-input__dropdown {
      z-index: 2;
    }
    .react-phone-input__dropdown .country-name {
      color: ${GrayColor.GrayDark};
    }
    > .react-phone-input__search {}
  }

  .fb-comments, .fb-comments > span {
    background-color: ${({ theme }) => (theme.name === 'regular' ? GrayColor.White : GrayColor.GrayXXLight)};
    padding: 8px 0;
  }

`;
