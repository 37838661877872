import { Border, Col, GrayColor, LightColor, Row, Spacing } from '@web/atomic';
import { lightenDarkenColor } from '@global/utils/ui/color-fn';
import styled from 'styled-components';

export const PatientListRowStyled = styled(Row)`
  cursor: pointer;
  background: ${GrayColor.White};
  border-radius: ${Border.Radius};
  margin-top: ${Spacing.Small};
  :hover {
    background: ${lightenDarkenColor(GrayColor.GrayXXLight, 70)};
  }
  :active {
    background: ${lightenDarkenColor(GrayColor.GrayXXLight, 30)};
  }
`;

export const PatientListColStyled = styled(Col)`
  margin: ${Spacing.Medium} 0;
  padding: 0 ${Spacing.Medium};
  & + & {
    border-left: 2px solid ${GrayColor.GrayXXLight};
  }
`;

export const PatientListColProgressWrapperStyled = styled.div`
  margin-bottom: ${Spacing.Large};
`;
