import { HttpDataSource } from '@web/data/vigilantes.datasource';
import { logError } from '@web/utils/error';
import * as React from 'react';
import { JsonObject } from 'type-fest';
import { NativeHelper } from './native.helper';

export interface UseGetInput<Req = JsonObject, Res = JsonObject, Err = Error> {
  onSuccess?: (data: Res) => void;
  onError?: (error: Err) => void;
  url: string;
  params?: Req;
  dontFetchOnLoad?: boolean;
}

export interface UseGetState<D = JsonObject> {
  data: D;
  loading: boolean;
  error: D;
}

export interface UseGetResponse<D = JsonObject> extends UseGetState<D> {
  refetch: () => Promise<D>;
}

interface UseGetAction<D> {
  type: 'loading' | 'error' | 'done';
  data?: D;
}

type UseGetReducer<D> = (state: UseGetState<D>, action: UseGetAction<D>) => UseGetState<D>;

function getReducer<D = JsonObject>(state: UseGetState<D>, action: UseGetAction<D>): UseGetState<D> {
  switch (action.type) {
    case 'loading':
      return {
        ...state,
        loading: true,
      };
    case 'error':
      return {
        ...state,
        error: action.data,
        loading: false,
      };
    case 'done':
      return {
        ...state,
        data: action.data,
        error: null,
        loading: false,
      };
    default:
      throw new Error();
  }
}

export function useGet<Req = JsonObject, Res = JsonObject>(input: UseGetInput<Req, Res>, datasource: HttpDataSource): UseGetResponse<Res> {
  const [state, dispatch] = React.useReducer<UseGetReducer<Res>>(getReducer, { data: null, loading: false, error: null });
  const fetchData = () => {
    dispatch({ type: 'loading' });
    return datasource
      .get(input.url, input.params)
      .then((result) => {
        dispatch({ type: 'done', data: result });
        if (input.onSuccess) {
          input.onSuccess(result);
        }
        return result;
      })
      .catch((error) => {
        logError(error);
        NativeHelper.postMessage({ type: 'error', data: { error } });
        dispatch({ type: 'error', data: error });
        if (input.onError) {
          input.onError(error);
        }
      });
  };

  React.useEffect(() => {
    if (!input.dontFetchOnLoad) {
      fetchData();
    }
  }, []);

  const refetch = () => {
    return fetchData();
  };

  return {
    refetch,
    ...state,
  };
}
