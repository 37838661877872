import { ChatfuelBlockIds, ChatfuelBlockNames } from '../chat/chatfuel-blocks-types';
import { Kupperman } from '../remote-graphql-types';
import { IQuestionnaireResultExplanationData } from './questionnaire';

// https://www.notion.so/vigilantesdosono/Fluxograma-de-conversa-29254f4886cc4bd3a300bc3665bebb62#106057f6ecfa4d629a72b85f1afbf3cc
export const computeKuppermanPoints = (
  k: Pick<Kupperman, 'q1' | 'q2' | 'q3' | 'q4' | 'q5' | 'q6' | 'q7' | 'q8' | 'q9' | 'q10' | 'q11' | 'q12' | 'q13'>
): number => {
  return 4 * k.q1 + 2 * k.q2 + 2 * k.q3 + 2 * k.q4 + k.q5 + k.q6 + k.q7 + k.q8 + k.q9 + k.q10 + k.q11 + 2 * k.q12 + 2 * k.q13;
};

// https://www.notion.so/vigilantesdosono/Fluxograma-de-conversa-29254f4886cc4bd3a300bc3665bebb62#106057f6ecfa4d629a72b85f1afbf3cc
export const kuppermanThreshold = [6, 15, 30, 57];

/*
 *
 * Make sure the redirectBlock and Initial Block are the same block
 *
 */
const redirectBlock: ChatfuelBlockIds = '638bfae3a2255d2dbf66831e';
export const kuppermanInitialBlockName: ChatfuelBlockNames = 'kupperman_youpage_entrance';

export const kuppermanSessionStartedToShowOnYouPage = 57;

export const kuppermanQuestionnaire = { name: 'Menopausa', redirect: redirectBlock };

export const kuppermanResultExplanationData: IQuestionnaireResultExplanationData = {
  title: 'Menopausa',
  description: 'O índice tem como objetivo avaliar a intensidade dos sintomas climatéricos.',
  results: [
    {
      title: '0 - 6 pontos',
      description: 'não tem sintomas climatéricos em um grau que os especialistas consideram de relevância clínica',
    },
    {
      title: '7 - 15 pontos',
      description:
        'tem sintomas climatéricos em um grau leve, em que especialistas consideram que pode haver relevância clínica, dependendo de seu limiar de tolerância e de outros fatores de sua história',
    },
    {
      title: '16 - 30 pontos',
      description: 'tem sintomas climatéricos em um grau moderado, em que especialistas já consideram que há relevância clínica.',
    },
    {
      title: '30 - 57 pontos',
      description: 'tem sintomas climatéricos intensos e considerados graves pelos especialistas, com significativa relevância clínica.',
    },
  ],
};
