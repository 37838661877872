import { Sleep_Tag_Enum_Enum, User_Program_Session_Enum_Enum } from '../remote-graphql-types';

export const CognitiveTrainingMinDurationInDays = 5;
export const RelaxingTrainingMinDurationInDays = 7;
export const SleepRestrictionMinDurationInDays = 7;
export const SleepRestrictionMinSleepDiaryToStartQuantity = 2;
export const SleepRestrictionMinSleepDiaryQuantity = 2;
export const GamificationMinPoints = 25;
export const GamificationTargetPoints = 100;

export enum YesNo {
  Yes = 'Sim',
  No = 'Não',
}

export const mapBoolToYesNo = (bool: boolean) => {
  if (bool === true) return YesNo.Yes;
  if (bool === false) return YesNo.No;
  return null;
};

export const mapYesNoToBool = (yesNo: YesNo) => {
  if (yesNo === YesNo.Yes) return true;
  if (yesNo === YesNo.No) return false;
  return null;
};

export enum Gender {
  Female = 'female',
  Male = 'male',
  Other = 'other',
}
export const getNeutralGenderSuffix = (gender: Gender | string): string =>
  gender === Gender.Male ? '' : gender === Gender.Female ? 'a' : '(a)';
export const getGenderSuffix = (gender: Gender | string): string =>
  gender === Gender.Male ? 'o' : gender === Gender.Female ? 'a' : 'o(a)';
export const getGenderPronoun = (gender: Gender | string): string =>
  gender === Gender.Male ? 'ele' : gender === Gender.Female ? 'ela' : 'ele(a)';
export const getGenderName = (gender: Gender): string => {
  switch (gender) {
    case Gender.Male:
      return 'Masculino';
    case Gender.Female:
      return 'Feminino';
    case Gender.Other:
    default:
      return 'Outro';
  }
};

export enum SessionState {
  NotStarted = 0,
  /**
   * the user has seen the first message of the session but haven't interacted with it
   */
  Seen = 1,
  Finished = 2,
  Started = 3,
}

export const HasuraSessionState: Record<User_Program_Session_Enum_Enum, SessionState> = {
  NOT_STARTED: 0,
  SEEN: 1,
  FINISHED: 2,
  STARTED: 3,
};

// BEGIN: move to packages/global.utils/domain/sleep-factor.ts (TODO: REFACTOR)
/**
 * BUSINESS_RULE: This enum comes from Hasura
 */
export enum SleepDiaryTag {
  Alcohol = 'alcohol',
  Bathroom = 'bathroom',
  Caffeine = 'caffeine',
  Exercise = 'exercise',
  Light = 'light',
  Meal = 'meal',
  Medicine = 'medicine',
  Nicotine = 'nicotine',
  Nap = 'nap',
  Noise = 'noise',
  Pain = 'pain',
  Partner = 'partner',
  Temperature = 'temperature',
  Dream = 'dream',
}

export const getEmojiForSleepDiaryTag = (tag: SleepDiaryTag): string => {
  switch (tag) {
    case SleepDiaryTag.Alcohol:
      return '🍻';
    case SleepDiaryTag.Bathroom:
      return '🚽';
    case SleepDiaryTag.Caffeine:
      return '☕';
    case SleepDiaryTag.Exercise:
      return '🏃';
    case SleepDiaryTag.Light:
      return '💡';
    case SleepDiaryTag.Meal:
      return '🍔';
    case SleepDiaryTag.Medicine:
      return '💊';
    case SleepDiaryTag.Nicotine:
      return '🚬';
    case SleepDiaryTag.Nap:
      return '💤';
    case SleepDiaryTag.Noise:
      return '🔊';
    case SleepDiaryTag.Pain:
      return '🤕';
    case SleepDiaryTag.Partner:
      return '🛌';
    case SleepDiaryTag.Temperature:
      return '❄️☀️';
    case SleepDiaryTag.Dream:
      return '☁️';
    default:
      throw new Error(`invalid tag: ${tag}`);
  }
};
export const getNameForSleepDiaryTag = (tag: SleepDiaryTag): string => {
  switch (tag) {
    case SleepDiaryTag.Alcohol:
      return 'Álcool';
    case SleepDiaryTag.Bathroom:
      return 'Acordou para ir ao banheiro';
    case SleepDiaryTag.Caffeine:
      return 'Cafeína';
    case SleepDiaryTag.Exercise:
      return 'Exercício';
    case SleepDiaryTag.Light:
      return 'Luz/Claridade';
    case SleepDiaryTag.Meal:
      return 'Refeição pesada';
    case SleepDiaryTag.Medicine:
      return 'Remédio';
    case SleepDiaryTag.Nicotine:
      return 'Nicotina';
    case SleepDiaryTag.Nap:
      return 'Cochilo';
    case SleepDiaryTag.Noise:
      return 'Barulho';
    case SleepDiaryTag.Pain:
      return 'Dor';
    case SleepDiaryTag.Partner:
      return 'Parceiro na cama';
    case SleepDiaryTag.Temperature:
      return 'Temperatura';
    case SleepDiaryTag.Dream:
      return 'Sonho/Pesadelo';
    default:
      throw new Error(`invalid tag: ${tag}`);
  }
};
export const getEmojiAndNameForSleepDiaryTag = (tag: SleepDiaryTag): string =>
  `${getEmojiForSleepDiaryTag(tag)} ${getNameForSleepDiaryTag(tag)}`;
// END: move to packages/global.utils/domain/sleep-factor.ts

export enum SleepDiaryGrade {
  Good = 3,
  Ok = 2,
  Bad = 1,
}
export const getEmojiForSleepDiaryGrade = (grade: SleepDiaryGrade): string => {
  switch (Math.round(grade)) {
    case SleepDiaryGrade.Good:
      return '🙂';
    case SleepDiaryGrade.Ok:
      return '😐';
    case SleepDiaryGrade.Bad:
      return '😞';
    default:
      console.error('ERROR: getEmojiForSleepDiaryGrade -> invalid grade', grade);
      return '';
  }
};
export const getTextForSleepDiaryGrade = (grade: SleepDiaryGrade): string => {
  switch (Math.round(grade)) {
    case SleepDiaryGrade.Good:
      return 'Descansado';
    case SleepDiaryGrade.Ok:
      return 'Mais ou menos descansado';
    case SleepDiaryGrade.Bad:
      return 'Cansado';
    default:
      console.error('ERROR: getEmojiForSleepDiaryGrade -> invalid grade', grade);
      return '';
  }
};
/**
 * Round between 0 and 100
 * @param value between 1 and 3
 */
export const roundGradeValue = (value: number): number => value && Math.round(((value - 1) * 100) / 2);

/**
 * BUSINESS_RULE: This enum values comes from Hasura
 * This is defined on the server, front-end and chatfuel
 * So, DON'T CHANGE IT
 */
export enum SleepDiaryTechnique {
  DeepBreath = 'deep_breath',
  Meditation = 'meditation',
  PMR = 'pmr',
  Imagery = 'imagery',
  AutgenicTraining = 'autogenic_training',

  BehaviorActivation = 'behavior_activation',
  ParkingLot = 'parking_lot',
  ParadoxicalIntention = 'paradoxical_intention',
  ThoughtBlock = 'thought_block',
  Gratitude = 'gratitude',
  ChallengeCatastrophicThinking = 'challenge_catastrophic_thinking',

  LightTherapy = 'light_therapy',

  StimulusControl = 'stimulus_control',
  SleepRestriction = 'sleep_restriction',
}
export const getNameForSleepDiaryTechnique = (technique: SleepDiaryTechnique): string => {
  const techniqueDict: Record<SleepDiaryTechnique, string> = {
    [SleepDiaryTechnique.ThoughtBlock]: `Bloqueio de pensamento`,
    [SleepDiaryTechnique.StimulusControl]: `Controle de estímulos`,
    [SleepDiaryTechnique.ParkingLot]: `Estacionamento de ideias`,
    [SleepDiaryTechnique.LightTherapy]: `Fototerapia`,
    [SleepDiaryTechnique.Gratitude]: `Gratidão`,
    [SleepDiaryTechnique.ParadoxicalIntention]: `Intenção paradoxal`,
    [SleepDiaryTechnique.Meditation]: `Meditação mindfulness`,
    [SleepDiaryTechnique.PMR]: `Relaxamento muscular progressivo`,
    [SleepDiaryTechnique.DeepBreath]: `Respiração profunda`,
    [SleepDiaryTechnique.AutgenicTraining]: `Treinamento autogeno`,
    [SleepDiaryTechnique.ChallengeCatastrophicThinking]: 'Desafiar pensamentos negativos',
    [SleepDiaryTechnique.SleepRestriction]: `Restrição do sono`,
    [SleepDiaryTechnique.Imagery]: `Visualização`,
    [SleepDiaryTechnique.BehaviorActivation]: 'Ativação de comportamento',
  };
  return techniqueDict[technique];
};
export const getEmojiForSleepDiaryTechnique = (technique: SleepDiaryTechnique): string => {
  const techniqueDict: Record<SleepDiaryTechnique, string> = {
    [SleepDiaryTechnique.DeepBreath]: '👃',
    [SleepDiaryTechnique.PMR]: '💪',
    [SleepDiaryTechnique.Meditation]: '🧘‍♀️',
    [SleepDiaryTechnique.AutgenicTraining]: '🌡',
    [SleepDiaryTechnique.Imagery]: '👁',
    [SleepDiaryTechnique.ParkingLot]: '📓',
    [SleepDiaryTechnique.ParadoxicalIntention]: '🤔',
    [SleepDiaryTechnique.ThoughtBlock]: '✋',
    [SleepDiaryTechnique.StimulusControl]: '🐶',
    [SleepDiaryTechnique.ChallengeCatastrophicThinking]: '💭',
    [SleepDiaryTechnique.Gratitude]: '🙏',
    [SleepDiaryTechnique.LightTherapy]: '💡',
    [SleepDiaryTechnique.SleepRestriction]: '👮‍♂️',
    [SleepDiaryTechnique.BehaviorActivation]: '🧲',
  };
  return techniqueDict[technique];
};
export const getEmojiAndNameForSleepDiaryTechnique = (technique: SleepDiaryTechnique): string =>
  `${getEmojiForSleepDiaryTechnique(technique)} ${getNameForSleepDiaryTechnique(technique)}`;
export enum TechniqueKind {
  Behaviour = 'behaviour',
  Cognitive = 'cognitive',
  Relax = 'relax',
  Other = 'other',
}
export const getNameForTechniqueKind = (kind: TechniqueKind): string => {
  switch (kind) {
    case TechniqueKind.Behaviour:
      return 'Comportamental';
    case TechniqueKind.Cognitive:
      return 'Cognitiva';
    case TechniqueKind.Relax:
      return 'Relaxamento';
    case TechniqueKind.Other:
      return 'Outro';
    default:
      return kind;
  }
};
// These values are also used by Hasura, therefore, DONT CHANGE IT
export enum ResourceKind {
  App = 'app',
  Site = 'site',
  Spotify = 'spotify',
  Youtube = 'youtube',
}

export interface TechniqueMeta {
  kind: TechniqueKind;
  duration: number;
}

export function getTechniqueMeta(technique: SleepDiaryTechnique): TechniqueMeta {
  const techniqueDict: Record<SleepDiaryTechnique, TechniqueMeta> = {
    [SleepDiaryTechnique.DeepBreath]: {
      kind: TechniqueKind.Relax,
      duration: 5,
    },
    [SleepDiaryTechnique.PMR]: {
      kind: TechniqueKind.Relax,
      duration: 10,
    },
    [SleepDiaryTechnique.Meditation]: {
      kind: TechniqueKind.Relax,
      duration: 15,
    },
    [SleepDiaryTechnique.AutgenicTraining]: {
      kind: TechniqueKind.Relax,
      duration: 10,
    },
    [SleepDiaryTechnique.Imagery]: {
      kind: TechniqueKind.Relax,
      duration: 10,
    },
    [SleepDiaryTechnique.ParkingLot]: {
      kind: TechniqueKind.Cognitive,
      duration: 5,
    },
    [SleepDiaryTechnique.ParadoxicalIntention]: {
      kind: TechniqueKind.Cognitive,
      duration: null,
    },
    [SleepDiaryTechnique.ThoughtBlock]: {
      kind: TechniqueKind.Cognitive,
      duration: null,
    },
    [SleepDiaryTechnique.StimulusControl]: {
      kind: TechniqueKind.Behaviour,
      duration: null,
    },
    [SleepDiaryTechnique.Gratitude]: {
      kind: TechniqueKind.Cognitive,
      duration: 5,
    },
    [SleepDiaryTechnique.ChallengeCatastrophicThinking]: {
      kind: TechniqueKind.Cognitive,
      duration: 5,
    },
    [SleepDiaryTechnique.BehaviorActivation]: {
      kind: TechniqueKind.Cognitive,
      duration: null,
    },
    [SleepDiaryTechnique.LightTherapy]: {
      kind: TechniqueKind.Other,
      duration: 20,
    },
    [SleepDiaryTechnique.SleepRestriction]: {
      kind: TechniqueKind.Behaviour,
      duration: null,
    },
  };
  return techniqueDict[technique];
}
// END: move to packages/global.utils/domain/sleep-factor.ts

/**
 * Values come from this form: https://admin.typeform.com/form/c0vuw5/create
 * DON'T CHANGE IT without changing on typeform as well!
 */
export enum Comobidities {
  Anxiety = 'Ansiedade geral',
  Depression = 'Depressão',
  Bipolar = 'Transtorno bipolar',
  Schizophrenia = 'Esquizofrenia',
  Dementia = 'Demência (Alzheimer)',
  Epilepsy = 'Epilepsia',
  Parkinson = 'Mal de Parkinson',
  Diabetes = 'Diabetes',
  Reflux = 'Refluxo',
  Apnea = 'Apneia obstrutiva do sono',
  Musculoskeletal = 'Distúrbios músculo-esqueléticos',
  Nocturia = 'Noctúria',
}

export enum TagClass {
  beforeSleep,
  afterSleep,
}

export const mapSleep_Tag_Enum_EnumToTagClass = (tag: Sleep_Tag_Enum_Enum): TagClass => {
  switch (tag) {
    case Sleep_Tag_Enum_Enum.Alcohol:
      return TagClass.beforeSleep;
    case Sleep_Tag_Enum_Enum.Bathroom:
      return TagClass.afterSleep;
    case Sleep_Tag_Enum_Enum.Caffeine:
      return TagClass.beforeSleep;
    case Sleep_Tag_Enum_Enum.Exercise:
      return TagClass.beforeSleep;
    case Sleep_Tag_Enum_Enum.Light:
      return TagClass.afterSleep;
    case Sleep_Tag_Enum_Enum.Meal:
      return TagClass.beforeSleep;
    case Sleep_Tag_Enum_Enum.Medicine:
      return TagClass.beforeSleep;
    case Sleep_Tag_Enum_Enum.Nicotine:
      return TagClass.beforeSleep;
    case Sleep_Tag_Enum_Enum.Nap:
      return TagClass.beforeSleep;
    case Sleep_Tag_Enum_Enum.Noise:
      return TagClass.afterSleep;
    case Sleep_Tag_Enum_Enum.Pain:
      return TagClass.afterSleep;
    case Sleep_Tag_Enum_Enum.Partner:
      return TagClass.afterSleep;
    case Sleep_Tag_Enum_Enum.Temperature:
      return TagClass.afterSleep;
    case Sleep_Tag_Enum_Enum.Dream:
      return TagClass.afterSleep;
    default:
      throw new Error(`invalid tag: ${tag}`);
  }
};
