import { HcpUserContext, UnauthenticatedUserContext } from '@app/health-carer/src/UserProvider';
import { navigate } from '@reach/router';
import { Body, Button, Col, Grid, H1, H2, InputLabel, LargeSeparator, LightDivisor, Row, Separator } from '@web/atomic';
import { FullScreenPrimaryBackground } from '@web/atomic/atm.background';
import { TextField } from '@web/atomic/legacy/atm.text-field';
import { BooleanContainer } from '@web/atomic/legacy/obj.abstract-container';
import flashDispatcherService from '@web/atomic/legacy/obj.flash-wrapper/flash-dispatcher.service';
import { Form, FormData, Validators } from '@web/atomic/legacy/obj.form';
import { Modal } from '@web/atomic/legacy/obj.modal';
import FormWrapperWithHeader from '@web/atomic/mol.form-wrapper-with-header/form-wrapper-with-header.component';
import { useField } from '@web/atomic/obj.custom-hooks/field.hook';
import React, { useContext, useEffect, useState } from 'react';
import { ReachButton } from '../../components/atm.button/button.component';
import { HcpWhatsappFab } from '../../components/atm.hcp-whatsapp-fab/hcp-whatsapp-fab.component';
import AuthDatasource, { firebaseErrorsPtBr } from '../../data/auth-datasource';
import { SignUpRouteUrl } from '../sign-up/sign-up.route';

interface LoginForm {
  email: string;
  password: string;
}

interface LoginPageProps {
  initialEmail?: string;
}

const LoginPage: React.FunctionComponent<LoginPageProps> = (props) => {
  const user = useContext(HcpUserContext);
  const unauthenticatedUser = useContext(UnauthenticatedUserContext);
  const pathAfterLogin = unauthenticatedUser.pathAfterLogin;
  useEffect(() => {
    if (user) {
      navigate(pathAfterLogin ?? '/');
    }
  }, [user, pathAfterLogin]);

  const [submitLoading, setLoading] = useState(false);
  const handleSubmit = async (formData: FormData<LoginForm>) => {
    if (Object.keys(formData.error).length !== 0) return;
    try {
      setLoading(true);
      const auth = AuthDatasource.auth;
      await auth.signInWithEmailAndPassword(formData.data.email, formData.data.password);
    } catch (error) {
      flashDispatcherService.dispatchMessage(firebaseErrorsPtBr[error.code], 'alert');
    } finally {
      setLoading(false);
    }
  };

  const [email, handleEmail] = useField<string>();

  return (
    <FullScreenPrimaryBackground>
      <Grid>
        <Row mt>
          <Col xs>
            <LargeSeparator />
          </Col>
        </Row>
        <Row center="xs">
          <FormWrapperWithHeader title={'portal vigilantes do sono'}>
            <BooleanContainer>
              {(forgotPasswordVisibility) => (
                <>
                  <Form onSubmit={handleSubmit}>
                    <Row center="xs">
                      <Col xs={12}>
                        <H1>Entrar</H1>
                      </Col>
                    </Row>
                    <Row mt mb center="xs">
                      <Col xs={12}>
                        <Form.Field
                          name={'email' as keyof LoginForm}
                          initialValue={props.initialEmail}
                          validators={[Validators.EmailRegex('O e-mail digitado é inválido.')]}
                          onValueChange={handleEmail}
                        >
                          <InputLabel>E-mail</InputLabel>
                          <TextField type={'email'} autoComplete="email" />
                        </Form.Field>
                        <Separator />
                        <Form.Field
                          name={'password' as keyof LoginForm}
                          validators={[Validators.Required(), Validators.MinLength(6, 'A senha deve ter pelo menos 6 caracteres.')]}
                        >
                          <InputLabel>Senha</InputLabel>
                          <TextField type={'password'} />
                        </Form.Field>
                        <Separator />
                      </Col>
                    </Row>
                    <Row mt mb end="xs">
                      <Col>
                        <Button type="button" kind={'link'} onClick={forgotPasswordVisibility.toggle}>
                          Não sabe a senha?
                        </Button>
                        <Separator />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <Button type="submit" kind="primary" loading={submitLoading} expanded>
                          Entrar
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                  <Modal opened={forgotPasswordVisibility.value} small onClose={forgotPasswordVisibility.setFalse}>
                    <ForgotPasswordForm email={email} onSuccess={forgotPasswordVisibility.setFalse} />
                  </Modal>
                </>
              )}
            </BooleanContainer>

            <Row mb>
              <Col xs={12}>
                <Separator />
                <LightDivisor />
                <Separator />
                <Body center>
                  Não possui cadastro? <br />
                  <ReachButton type="submit" kind="link" to={SignUpRouteUrl}>
                    Crie sua conta grátis
                  </ReachButton>
                </Body>
              </Col>
            </Row>
          </FormWrapperWithHeader>
        </Row>
        <Row mt>
          <Col xs>
            <LargeSeparator />
          </Col>
        </Row>
      </Grid>
      <HcpWhatsappFab />
    </FullScreenPrimaryBackground>
  );
};

export default LoginPage;

////////////////////////////////////////////////////////////////////////////////////////////////////

interface ForgotPassowrdFormData {
  email: string;
}

interface IForgotPasswordFormProps {
  email: string;
  onSuccess: () => void;
}

const ForgotPasswordForm: React.FunctionComponent<IForgotPasswordFormProps> = (props) => {
  const [submitLoading, setLoading] = useState(false);
  const handleSubmit = async (formData: FormData<ForgotPassowrdFormData>) => {
    if (Object.keys(formData.error).length !== 0) return;
    try {
      setLoading(true);
      const auth = AuthDatasource.auth;
      await auth.sendPasswordResetEmail(formData.data.email);
      flashDispatcherService.dispatchMessage('Sucesso! Entre no e-mail informado para redefinir sua senha.', 'success');
      props.onSuccess();
    } catch (error) {
      flashDispatcherService.dispatchMessage(firebaseErrorsPtBr[error.code], 'alert');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid fluid>
      <Row mb>
        <Col xs={12}>
          <H2> Não sabe a senha? </H2>
          <Body>Informe o seu email para receber o link de redefinição de senha.</Body>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit}>
        <Row mb>
          <Col xs={12}>
            <Form.Field
              name={'email' as keyof ForgotPassowrdFormData}
              initialValue={props.email}
              validators={[Validators.Required(), Validators.EmailRegex('O e-mail digitado é inválido.')]}
            >
              <InputLabel>E-mail</InputLabel>
              <TextField type={'email'} autoComplete="email" />
            </Form.Field>
          </Col>
        </Row>
        <Row mb>
          <Col xs={12}>
            <Separator />
            <Button type="submit" kind="primary" loading={submitLoading} expanded>
              Enviar
            </Button>
          </Col>
        </Row>
      </Form>
    </Grid>
  );
};
