import { BrandColor, GrayColor, Spacing } from '@web/atomic';
import styled from 'styled-components';

export interface IPatientPaidTagProps {
  paid: boolean;
}

const size = 12;
export const PatientPaidTagStyled = styled.div<IPatientPaidTagProps>`
  height: ${size}px;
  width: ${size}px;
  background: ${(props) => (props.paid ? BrandColor.Green : GrayColor.GrayLight)};
  border-radius: ${size / 2}px;
  margin: ${Spacing.Medium};
  margin-left: 0;
`;
