import { GrayColor, Spacing } from '@web/atomic';
import styled from 'styled-components';

const width = '285mm';
export const PrintLayout = styled.div`
  border: 1px dashed ${GrayColor.Gray};
  width: ${width};
  margin: ${Spacing.Medium};

  @media print {
    width: ${width};
    margin: 0;
    border: 0;
  }
`;
