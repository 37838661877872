import { ApolloClient, ApolloLink, setContext, HttpLink, RetryLink, InMemoryCache } from '@web/data/apollo-client';
import fetch from 'isomorphic-fetch';
import AuthDatasource from './auth-datasource';

const retryIf = (error, operation) => {
  if (error) {
    console.log('Error: FirebaseAuthApolloClient: retryIf -> error: ', operation, error);
  }
  return !!error;
};

const getLinks = (uri) =>
  ApolloLink.from([
    new RetryLink({
      delay: {
        initial: 500,
        max: Infinity,
        jitter: true,
      },
      attempts: {
        retryIf,
        max: 5,
      },
    }),
    // https://github.com/apollographql/apollo-link/issues/541#issuecomment-392166160
    new ApolloLink((operation, forward) => {
      return forward(operation).map((data) => {
        if (data && data.errors && data.errors.length > 0) {
          throw new Error('GraphQL Operational Error');
        }
        return data;
      });
    }),
    authLink,
    new HttpLink({ fetch, uri }),
  ]);

////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * This auth link gets the authentication info from Messenger Extension SDK
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const authLink = setContext(async (_request, _previousContext) => {
  const headers = await AuthDatasource.getAuthHeader();
  return { headers };
});

const buildClient = (uri) =>
  new ApolloClient({
    link: getLinks(uri),
    cache: new InMemoryCache(),
  });

const firebaseAuthClient = buildClient(process.env.GATSBY_HASURA_BASE_URL);
export default firebaseAuthClient;
