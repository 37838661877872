import { ChatfuelBlockIds, ChatfuelBlockNames } from '../chat/chatfuel-blocks-types';
import { Phq, Phq9 } from '../remote-graphql-types';
import { IQuestionnaireResultExplanationData } from './questionnaire';

/** https://www.hiv.uw.edu/page/mental-health-screening/phq-2 */
export const getPhqPoints = (phq: Pick<Phq, 'q1' | 'q2'>): number => phq.q1 + phq.q2;

// https://www.hiv.uw.edu/page/mental-health-screening/phq-9
export const computePhq9Points = (phq9: Pick<Phq9, 'q1' | 'q2' | 'q3' | 'q4' | 'q5' | 'q6' | 'q7' | 'q8' | 'q9'>): number =>
  phq9.q1 + phq9.q2 + phq9.q3 + phq9.q4 + phq9.q5 + phq9.q6 + phq9.q7 + phq9.q8 + phq9.q9;

export const phq9Threshold = [5, 10, 15, 20, 27];

/*
 *
 * Make sure the redirectBlock and Initial Block are the same block
 *
 */
const redirectBlock: ChatfuelBlockIds = '63091254dbadeb6eec351fe6';
export const phq9InitialBlockName: ChatfuelBlockNames = 'phq9_youpage_entrance';

export const phqQuestionnaire = { name: 'Depressão', redirect: redirectBlock };

export const phq9ResultExplanationData: IQuestionnaireResultExplanationData = {
  title: 'Questionário Phq-9',
  description: 'O questionário tem como objetivo quantificar a percepção da gravidade de sintomas de depressão',
  results: [
    {
      title: '0 - 4 pontos',
      description: 'não tem sintomas depressivos em um grau que os especialistas consideram de relevância clínica',
    },
    {
      title: '5 - 9 pontos',
      description: 'sintomas depressivos em um grau leve',
    },
    {
      title: '10 - 14 pontos',
      description: 'sintomas depressivos em um grau moderado',
    },
    {
      title: '15 - 19 pontos',
      description: 'sintomas de depressão em um grau moderado a grave',
    },
    {
      title: '20 - 27 pontos',
      description: 'sintomas graves de depressão',
    },
  ],
};
