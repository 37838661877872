import { usePost, UsePostInput, UsePostState } from '@web/data/use-post.hook';
import { VigilantesDataSource } from '@web/data/vigilantes.datasource';
import AuthDatasource from '../../data/auth-datasource';
import { JsonObject } from 'type-fest';

export function usePostUsingFirebase<Req = JsonObject, Res = JsonObject, RawResponse = JsonObject>(
  input: UsePostInput<Res>
): [(data?: Req, mapper?: (response: RawResponse) => Res) => Promise<Res>, UsePostState<Res>] {
  const datasource = new VigilantesDataSource(AuthDatasource);
  return usePost<Req, Res, RawResponse>(input, datasource);
}
