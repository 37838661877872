import {
  getEmojiAndNameForSleepDiaryTechnique,
  getEmojiForSleepDiaryTechnique,
  getTechniqueMeta,
  SleepDiaryTechnique,
} from '@global/utils/domain/entities';
import { parseToDate } from '@global/utils/date/parse-to-date';
import { parseDateToShortString } from '@global/utils/date/parse-to-string';
import { AppThemeContext, BodySmall, GrayColor } from '@web/atomic';
import React, { useContext } from 'react';
import { CartesianGrid, Legend, ResponsiveContainer, Scatter, ScatterChart, XAxis, YAxis } from 'recharts';
import { getCycleReferenceLine } from './cycle-reference.components';
import { ChartWrapperStyled } from './graph.styled';
import { CHART_COLORS, SleepDiaryGraphProps } from './utils';

type ITechniquesGraphProps = SleepDiaryGraphProps;

const tagsLabel = 'tags';
export const TechniquesGraph: React.FunctionComponent<ITechniquesGraphProps> = (props) => {
  const cData = getChartData(props);
  // BUSINESS-RULE: the first cycle actually starts one day before the first diary
  if (props.cycles && props.cycles[0]) {
    cData.behaviour.unshift({ name: parseDateToShortString(props.cycles[0].startDate) });
  }
  const { theme } = useContext(AppThemeContext);
  return (
    <>
      <ChartWrapperStyled height={props.height}>
        <ResponsiveContainer>
          <ScatterChart
            height={props.height}
            width={props.width}
            // POG-ALERT: this margin is a hack to remove space before/after Y-Axis
            margin={{ top: 0, right: 35, bottom: 0, left: -35 }}
          >
            <CartesianGrid stroke={theme.name === 'dark' ? GrayColor.GrayDark : GrayColor.GrayXXXLight} />
            <XAxis
              stroke={theme.name === 'dark' ? GrayColor.GrayXLight : GrayColor.Gray}
              allowDuplicatedCategory={false}
              dataKey="name"
              label={{ position: 'insideBottomRight', offset: 0 }}
            />
            <YAxis
              stroke={theme.name === 'dark' ? GrayColor.GrayXLight : GrayColor.Gray}
              dataKey={tagsLabel}
              ticks={techniques.map(mapTechniqueToNum)}
              domain={[0, techniques.length]}
              tickFormatter={mapNumToTechnique}
              padding={{ bottom: 20, top: 0 }}
            />
            <Legend verticalAlign="top" wrapperStyle={{ left: 0, top: -30 }} />
            <Scatter
              isAnimationActive={!props.removeAnimation}
              name="Técnicas comportamentais"
              data={cData.behaviour}
              fill={CHART_COLORS[0]}
            />
            <Scatter isAnimationActive={!props.removeAnimation} name="Técnicas cognitivas" data={cData.cognitive} fill={CHART_COLORS[2]} />
            <Scatter isAnimationActive={!props.removeAnimation} name="Técnicas de relaxamento" data={cData.relax} fill={CHART_COLORS[23]} />
            <Scatter isAnimationActive={!props.removeAnimation} name="Outras" data={cData.other} fill={CHART_COLORS[7]} />
            {getCycleReferenceLine(props)}
          </ScatterChart>
        </ResponsiveContainer>
      </ChartWrapperStyled>
      <BodySmall>
        <strong>relaxamento:</strong>
        {techniquesGroupByKind.relax.map((technique) => getEmojiAndNameForSleepDiaryTechnique(technique)).join('; ')} <br />
        <strong>cognitivas:</strong>
        {techniquesGroupByKind.cognitive.map((technique) => getEmojiAndNameForSleepDiaryTechnique(technique)).join('; ')} <br />
        <strong>comportamentais:</strong>
        {techniquesGroupByKind.behaviour.map((technique) => getEmojiAndNameForSleepDiaryTechnique(technique)).join('; ')} |{' '}
        <strong>outras:</strong>{' '}
        {techniquesGroupByKind.other.map((technique) => getEmojiAndNameForSleepDiaryTechnique(technique)).join('; ')}
      </BodySmall>
    </>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////
const techniques = [
  SleepDiaryTechnique.DeepBreath,
  SleepDiaryTechnique.Meditation,
  SleepDiaryTechnique.PMR,
  SleepDiaryTechnique.Imagery,
  SleepDiaryTechnique.AutgenicTraining,

  SleepDiaryTechnique.ParkingLot,
  SleepDiaryTechnique.ParadoxicalIntention,
  SleepDiaryTechnique.ThoughtBlock,
  SleepDiaryTechnique.Gratitude,
  SleepDiaryTechnique.ChallengeCatastrophicThinking,
  SleepDiaryTechnique.BehaviorActivation,

  SleepDiaryTechnique.StimulusControl,

  SleepDiaryTechnique.LightTherapy,
  // SleepDiaryTechnique.SleepRestriction
];
const techniquesGroupByKind = techniques.reduce(
  (acc, curr) => {
    const meta = getTechniqueMeta(curr);
    acc[meta.kind].push(curr);
    return acc;
  },
  {
    behaviour: [],
    cognitive: [],
    relax: [],
    other: [],
  }
);
const mapTechniqueToNum = (technique: SleepDiaryTechnique) => {
  return techniques.indexOf(technique);
};
const mapNumToTechnique = (num: number) => {
  const technique = techniques[num];
  if (!technique) {
    return '';
  }
  return getEmojiForSleepDiaryTechnique(technique);
};

////////////////////////////////////////////////////////////////////////////////////////////////////
function getChartData(props: React.PropsWithChildren<ITechniquesGraphProps>) {
  const initData = {
    behaviour: [],
    cognitive: [],
    relax: [],
    other: [],
  };
  if (!props.sleepDiaryData) {
    return initData;
  }
  return props.sleepDiaryData.reduce((acc, curr) => {
    let added = false;
    techniques.forEach((technique) => {
      const value = curr[technique];
      if (!value) return;

      added = true;
      const meta = getTechniqueMeta(technique);
      acc[meta.kind].push({
        name: parseDateToShortString(parseToDate(curr.date)),
        [tagsLabel]: mapTechniqueToNum(technique),
      });
    });
    if (!added) {
      acc.behaviour.push({ name: parseDateToShortString(parseToDate(curr.date)) });
    }
    return acc;
  }, initData);
}
