export const noDiactrics = (str: string): string => {
  // remove-accents-diacritics: https://stackoverflow.com/a/37511463/3670829
  if ('normalize' in String.prototype) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  // https://stackoverflow.com/a/51693814/3670829
  const weird = 'öüóőúéáàűíÖÜÓŐÚÉÁÀŰÍçÇ!@£$%^&*()_+?/*."';
  const normalized = 'ouooueaauiOUOOUEAAUIcC                 ';
  const lentext = str.toString().length - 1;

  let newText = '';
  let idoff = -1;
  for (let i = 0; i <= lentext; i = i + 1) {
    idoff = weird.search(str.charAt(i));
    if (idoff === -1) {
      newText = newText + str.charAt(i);
    } else {
      newText = newText + normalized.charAt(idoff);
    }
  }
  return newText;
};
