import { Body, Col, Grid, Row, Separator } from '@web/atomic';
import onboardLogDatasource from '@web/data/onboard-log.datasource';
import React, { useEffect, useState } from 'react';
import { Button } from '../atm.button/button.component';
import { PrivacyPolicyComponentWrapper } from './privacy-policy-component.style';

export const PrivacyPolicyComponent: React.FunctionComponent = (): JSX.Element => {
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

  useEffect(() => {
    setShowPrivacyPolicy(!onboardLogDatasource.hasAgreedToPrivacyPolicy);
  }, []);

  const hidePrivacyPolice = (): void => {
    onboardLogDatasource.hasAgreedToPrivacyPolicy = true;
    setShowPrivacyPolicy(!onboardLogDatasource.hasAgreedToPrivacyPolicy);
  };

  if (!showPrivacyPolicy) return null;

  return (
    <PrivacyPolicyComponentWrapper>
      <Grid>
        <Row mt mb middle={'xs'} center={'xs'}>
          <Col xs={12} sm={9}>
            <Body>
              <span role="img" aria-label="cookie">
                🍪
              </span>{' '}
              Ao utilizar nosso website, você concorda com nossa
              <Button kind="link" to={'https://www.vigilantesdosono.com/sobre/politica-de-privacidade/'}>
                {' '}
                política de privacidade
              </Button>
            </Body>
          </Col>
          <Col xs={12} sm={false}>
            <Separator />
          </Col>
          <Col xs={12} sm={3}>
            <Button kind="secondary" small={true} expanded={true} onClick={hidePrivacyPolice}>
              Fechar
            </Button>
          </Col>
        </Row>
      </Grid>
    </PrivacyPolicyComponentWrapper>
  );
};
