import { isSameDay } from '@global/utils/date/is-same-day';
import { DoctorReportQueryQuery } from '@global/utils/remote-graphql-types';
import { SetRequired } from 'type-fest';
import { ICycleReferenceLineProps } from './cycle-reference.components';

/** https://stackoverflow.com/a/56495465/3670829 */
export const CHART_COLORS = [
  '#3366cc', // 0 kind of blue
  '#dc3912', // 1 kind of red
  '#ff9900', // 2 yellow mustard
  '#109618', // 3 green
  '#990099', // 4 purple
  '#0099c6', // 5 light blue
  '#dd4477', // 6 pink
  '#66aa00', // 7 light green
  '#b82e2e', // 8 dark red
  '#316395', // 9 dark blue
  '#994499', // 10 purple
  '#22aa99', // 11 navy blue
  '#aaaa11', // 12 dark yellow
  '#6633cc', // 13 purple
  '#e67300', // 14 orange
  '#8b0707', // 15 wine
  '#651067', // 16 dark purple
  '#329262', // 17 pastel green
  '#5574a6', // 18 pastel blue
  '#3b3eac', // 19 strong blue
  '#b77322', // 20 dark orange
  '#16d620', // 21 very light green
  '#b91383', // 22 pink
  '#f4359e', // 23 very light pink
  '#9c5935', // 24 brown
  '#a9c413', // 25 yellow phosphorescent
  '#2a778d', // 26 blue
  '#668d1c', // 27 green
  '#bea413', // 28 yellow
  '#0c5922', // 29 dark green
  '#743411', // 30 brown
];

type SleepDiaryGraphPropsUser = DoctorReportQueryQuery['user'][0];

export interface SleepDiaryGraphProps extends ICycleReferenceLineProps {
  width: number;
  height?: number;
  hideBrush?: boolean;
  sleepDiaryData: GetDiariesWithEmptyValuesOutput;
  user?: SleepDiaryGraphPropsUser;
  removeAnimation?: boolean;
}

export const numberOfDaysToShowInitiallyOnGraph = 7;

export type GetDiariesWithEmptyValuesOutput_Diary = DoctorReportQueryQuery['sleep_diary'][number];
export type GetDiariesWithEmptyValuesOutput = Array<SetRequired<Partial<GetDiariesWithEmptyValuesOutput_Diary>, 'date'>>;

/**
 * This method will fill `diaries` with empty diaries
 * @param diaries
 */
export const getDiariesWithEmptyValues = (diaries: DoctorReportQueryQuery['sleep_diary']): GetDiariesWithEmptyValuesOutput => {
  if (!diaries || !diaries.length) {
    return null;
  }

  const firstDiary = diaries[0];
  return diaries.reduce(
    (acc, curr) => {
      const offset = new Date().getTimezoneOffset() / 60;

      const currentDate = new Date(curr.date);
      const lastInsertedDate = new Date(acc[acc.length - 1].date);

      // https://stackoverflow.com/a/57874439/3670829
      currentDate.setHours(currentDate.getHours() + offset);
      lastInsertedDate.setHours(lastInsertedDate.getHours() + offset);

      if (lastInsertedDate > currentDate || isSameDay(lastInsertedDate, currentDate)) {
        return acc;
      }

      for (
        lastInsertedDate.setDate(lastInsertedDate.getDate() + 1);
        !isSameDay(lastInsertedDate, currentDate);
        lastInsertedDate.setDate(lastInsertedDate.getDate() + 1)
      ) {
        acc.push({ date: `${lastInsertedDate.getFullYear()}-${lastInsertedDate.getMonth() + 1}-${lastInsertedDate.getDate()}` });
      }
      acc.push(curr);

      return acc;
    },
    [firstDiary] as GetDiariesWithEmptyValuesOutput
  );
};
