import { hexToRGBA } from '@global/utils/ui/color-fn';
import { InputDisabledCss, InputValueCss } from '@web/atomic/legacy/atm.typography';
import { Alpha, Border, FontFamily, FontSize, GrayColor, LightColor, Spacing } from '@web/atomic/legacy/obj.constants';
import { ThemeProps } from '@web/atomic/obj.theme';
import { css } from 'styled-components';

export interface FieldProps extends ThemeProps {
  invalid?: boolean;
  disabled?: boolean;
  light?: boolean;
}

const getBorderDefaultColor = (props: FieldProps) => (props.light || props.theme.name === 'dark' ? GrayColor.White : GrayColor.Gray);
const getBorderFocusColor = (props: FieldProps) => (props.light || props.theme.name === 'dark' ? GrayColor.White : LightColor.Primary);
const getBorderHoverColor = (props: FieldProps) => (props.light || props.theme.name === 'dark' ? GrayColor.White : LightColor.Primary);

export const fieldBorder = Border.Width;
export const fieldBackgroundCss = css`
  margin-top: ${({ theme }) => (theme.name === 'regular' ? '0' : Spacing.Small)};
  background-color: ${({ theme }) => (theme.name === 'regular' ? 'none' : hexToRGBA(GrayColor.White, Alpha.L2))};
`;
export const fieldBorderCss = css<FieldProps>`
  overflow: hidden;
  background: transparent;
  border: 0;
  border-radius: 0;
  border-bottom: ${fieldBorder} solid;
  border-color: ${(props) => (props.invalid ? props.theme.color.alert : getBorderDefaultColor(props))};

  :focus {
    border-color: ${(props: FieldProps) => getBorderFocusColor(props)};
    /* Box-shadow instead of border-width, so the text won't dance */
    box-shadow: inset 0 -1px 0 0 ${(props: FieldProps) => getBorderFocusColor(props)};
    outline: none;
  }

  @media all and (min-width: 48em) {
    :hover {
      border-color: ${(props: FieldProps) => getBorderHoverColor(props)};
      box-shadow: inset 0 -1px -1px 0 ${(props: FieldProps) => getBorderHoverColor(props)};
    }
  }
`;

export const fieldTypographyCss = css`
  ${(props: FieldProps) => (props.disabled ? InputDisabledCss : InputValueCss)}
  font-family: ${FontFamily.Primary};
  font-size: ${FontSize.Small};
`;

// fieldHeight measured on run-time
export const fieldHeight = '16px';

export const fieldPadding = '12px';
export const fieldCss = css`
  ${fieldTypographyCss}
  width: 100%;
  padding: ${fieldPadding} 0;
  align-self: stretch;
  background-color: transparent;

  :focus {
    outline: none;
  }

  /* https://stackoverflow.com/a/38573257/3670829 */
  -webkit-appearance: none;

  transition: all 0.3s;
`;
