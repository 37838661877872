import * as React from 'react';

import { ReadOnlyBadgeStyled, ReadOnlyBadgeTextStyled } from './read-only-badge.component.style';

export interface ReadOnlyBadgeProps {
  /** Colors supported in this badge. This should follow the color constants  */
  kind: 'primary';
  children: JSX.Element | string;
}

export const ReadOnlyBadge: React.FunctionComponent<ReadOnlyBadgeProps> = (props: ReadOnlyBadgeProps) => {
  return (
    <ReadOnlyBadgeStyled {...props}>
      <ReadOnlyBadgeTextStyled {...props}>{props.children}</ReadOnlyBadgeTextStyled>
    </ReadOnlyBadgeStyled>
  );
};
