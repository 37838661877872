import { isNullOrUndefined } from '../object/null-or-undefined';

/** Expected input:
 * YYYY-MM-DD HH:MM (ex: 2018-10-05 13:45 )
 * YYYY-MM-DD (ex: 2018-10-05 )
 * YYYY-MM-DDTHH:MM:SS.Z (ex: 2018-11-07T18:08:54.526Z )
 * DD-MM-YYYY HH:MM (ex: 05-10-2018 )
 * DD/MM/YYYY (ex: 05/10/2018)
 */
export const parseToDate = (input: string | Date): Date => {
  const stringDate = input as string;
  if (isNullOrUndefined(stringDate) || stringDate.length === 0) {
    return null;
  }
  if (isValidDate(input as Date)) {
    return input as Date;
  }

  let date;

  const normalizedDate = stringDate.replace(new RegExp('-', 'g'), '/');
  const dateComponents = normalizedDate.split('/');
  if (dateComponents && dateComponents[0].length !== 4) {
    date = parseDDMMYYYYToDate(stringDate);
  }
  if (!isValidDate(date)) {
    date = new Date(normalizedDate);
  }
  if (!isValidDate(date)) {
    date = new Date(stringDate);
  }
  if (!isValidDate(date)) {
    // fix for firefox
    // example: it converts `2020-08-16 11:00:00+00` to `2020-08-16 11:00:00+0000`
    date = new Date(`${stringDate}00`);
  }
  if (!isValidDate(date)) {
    console.error(`ERROR: parseToDate`, stringDate);
  }

  return date;
};

/** Expected input:
 * DD-MM-YYYY HH:MM (ex: 05-10-2018 )
 * DD/MM/YYYY (ex: 05/10/2018)
 * DD/MM/YY (ex: 05/10/18)
 */
export const parseDDMMYYYYToDate = (stringDate: string): Date => {
  if (isNullOrUndefined(stringDate) || stringDate.length === 0) {
    return null;
  }

  const normalizedDate = stringDate.replace(new RegExp('-', 'g'), '/');
  const dateComponents = normalizedDate.split('/');

  if (dateComponents.length < 3 || parseInt(dateComponents[0]) > 31 || parseInt(dateComponents[1]) > 12) {
    console.log('parseDDMMYYYYDate::error: invalid dateComponents: ', dateComponents);
    return null;
  }

  if (dateComponents[2].length === 2) {
    const yearPrefix = new Date().getFullYear().toString().slice(0, 2);
    dateComponents[2] = `${yearPrefix}${dateComponents[2]}`;
  }
  if (dateComponents[2].length !== 4) {
    return null;
  }

  return parseToDate(`${dateComponents[2]}/${dateComponents[1]}/${dateComponents[0]}`);
};

export const isValidDate = (d: Date) => {
  return d instanceof Date && !isNaN(d as any);
};
