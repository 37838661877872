import * as React from 'react';
import { hasWindow } from '@web/utils/platform';

export interface MatchMediaProps {
  defaultMinWidth?: string;
  children: (match: boolean) => JSX.Element | string;
}

export interface MatchMediaState {
  match?: boolean;
  minWidth: string;
}

const mdMediaQuery = (minWidth: string) => `(min-width: ${minWidth})`;

export class MatchMedia extends React.Component<MatchMediaProps, MatchMediaState> {
  static defaultProps = {
    defaultMinWidth: '64em',
  };

  private mediaQuery;

  constructor(props: MatchMediaProps) {
    super(props);
    this.state = {
      minWidth: props.defaultMinWidth,
    };
  }

  componentDidMount() {
    if (hasWindow()) {
      this.mediaQuery = window.matchMedia(mdMediaQuery(this.state.minWidth));
      this.mediaQuery.addListener(this.handleChange);
      this.setState({ match: !window.matchMedia(mdMediaQuery(this.props.defaultMinWidth)).matches });
    }
  }

  render() {
    if (this.state.match === undefined) {
      return null;
    }

    return this.props.children(this.state.match);
  }

  componentWillUnmount() {
    if (hasWindow()) {
      this.mediaQuery.removeListener(this.handleChange);
    }
  }

  private handleChange = () => {
    this.setState({ match: !this.mediaQuery.matches });
  };
}

/**
 * Calls callback when media change
 *
 * @param media
 * @param callback
 */
export const useMatchMediaChanged = (media: string, callback: (ev: MediaQueryListEvent) => void) => {
  React.useEffect(() => {
    if (!hasWindow()) {
      return null;
    }

    const mediaQuery = window.matchMedia(media);
    //https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList/change_event
    mediaQuery.addEventListener('change', callback);
    return () => mediaQuery.addEventListener('change', callback);
  }, [callback]);

  if (!hasWindow()) {
    return null;
  }
  return window.matchMedia(media).matches;
};
