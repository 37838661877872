import { mapToSleepTagAndTechniqueSummary, SleepTagAndTechniqueSummary } from '@global/utils/domain/tag-and-technique-comparator';
import { DoctorReportQueryQuery } from '@global/utils/remote-graphql-types';
import { useMemo } from 'react';

/**
 * Get user night goal averages
 * @param data
 */
export const useSleepTagAndTechniqueSummary = (data: DoctorReportQueryQuery): SleepTagAndTechniqueSummary => {
  return useMemo(() => {
    if (!data || !data.sleep_diary) {
      return null;
    }

    const allData = data.sleep_diary;
    return mapToSleepTagAndTechniqueSummary(allData);
  }, [data]);
};
