import { Border, Col, GrayColor, LightColor, Spacing } from '@web/atomic';
import styled from 'styled-components';

export const PatientListColStyled = styled(Col)`
  padding: 0 ${Spacing.Medium};
  & + & {
    border-left: 2px solid ${GrayColor.GrayXXLight};
  }
`;

export interface DoctorReportTableHeaderProps {
  borderBotton?: true | false;
}

export const DoctorReportTableHeader = styled.div<DoctorReportTableHeaderProps>`
  text-align: center;
  border-color: ${Border.Color};
  color: blue;
  border-bottom-style: solid;
  padding-bottom: ${Spacing.Small};
  color: ${(props) => (props.theme.name === 'dark' ? GrayColor.White : LightColor.Primary)};
  border-bottom-width: ${(props) => (props.borderBotton ? '1px' : '0px')};
  @media all and (max-width: 48em) {
    display: none;
  }
`;
