import { Link } from '@reach/router';
import { AutoMargin, Hbox } from '@web/atomic';
import SvgIcLogo from '@web/atomic/atm.svg-icon/ic-logo.component';
import { MatchMedia } from '@web/atomic/legacy/obj.match-media';
import * as React from 'react';
import { UserMenuButton } from '../mol.user-menu-button/user-menu-button.component';
import { HeaderLogoTextStyled, HeaderPlaceholder, HeaderStyled } from './header.component.style';
import { PatientListRouteUrl } from '@app/health-carer/src/modules/patient-list/patient-list.route';

interface HeaderInterface {
  userName: string;
  balance: number;
  onMenuClick?: () => void;
}

export const Header: React.FunctionComponent<HeaderInterface> = (props) => {
  return (
    <>
      <HeaderStyled>
        <Link to={PatientListRouteUrl}>
          <Hbox hAlign="center">
            <Hbox.Item wrap vAlign={'center'}>
              <SvgIcLogo id="ga-footer-logo-svg-desktop" height={32} />
            </Hbox.Item>
            <Hbox.Separator />
            <Hbox.Separator />
            <Hbox.Item wrap vAlign={'center'}>
              <HeaderLogoTextStyled> Vigilantes do Sono </HeaderLogoTextStyled>
            </Hbox.Item>
          </Hbox>
        </Link>
        <AutoMargin />
        <Hbox>
          <Hbox.Item wrap vAlign={'center'}>
            <MatchMedia defaultMinWidth={`48em`}>
              {(md) => <UserMenuButton userName={props.userName} mobile={md} balance={props.balance} onMenuClick={props.onMenuClick} />}
            </MatchMedia>
          </Hbox.Item>
        </Hbox>
      </HeaderStyled>
      <HeaderPlaceholder />
    </>
  );
};
