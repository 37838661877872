import styled from 'styled-components';

export const ChartWrapperStyled = styled.div<{ height? }>`
  margin-top: 40px;
  margin-left: 5px;
  width: 100%;
  height: ${(props) => `${props.height}px` || 'auto'};

  & svg {
    overflow: inherit;
  }
`;
