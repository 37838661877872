/* eslint-disable */

export const defaultDebounceTime = 800;

/** adapted from: https://davidwalsh.name/javascript-debounce-function */
export function asyncDebounce<F extends Function>(asynFunc: F, wait: number = defaultDebounceTime, immediate?: boolean): F {
  let timeout;

  const debouncedFunction = function (...args) {
    return new Promise((resolve) => {
      const later = function () {
        timeout = null;
        if (!immediate) {
          resolve(asynFunc(...args));
        }
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) {
        resolve(asynFunc(...args));
      }
    });
  };

  return debouncedFunction as any;
}

// https://levelup.gitconnected.com/debounce-in-javascript-improve-your-applications-performance-5b01855e086
export const debounce = (func, wait = defaultDebounceTime) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

/**
 *
 * @param limit after calling `limit` times, the function will be called
 */
export function debounceWithLimit(func, limit: number, wait = defaultDebounceTime) {
  let timeout;
  let count = 0;

  return function () {
    const context = this,
      args = arguments;
    const later = function () {
      timeout = null;
      func.apply(context, args);
      count = 0;
    };
    clearTimeout(timeout);

    if (count > limit) {
      func.apply(context, args);
      count = 0;
    } else {
      timeout = setTimeout(later, wait);
      count += 1;
    }
  };
}
