import { getTimeInMinutesChronologically } from '@global/utils/date/get-time-in-minutes-chronologically';
import { parseToDate } from '@global/utils/date/parse-to-date';
import { parseDateToShortString } from '@global/utils/date/parse-to-string';
import { toHour } from '@global/utils/date/to-hour';
import { isNullOrUndefined } from '@global/utils/object/null-or-undefined';
import { AppThemeContext, BrandColor, GrayColor } from '@web/atomic';
import React, { useContext } from 'react';
import { CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { getCycleReferenceLine } from './cycle-reference.components';
import { ChartWrapperStyled } from './graph.styled';
import { CHART_COLORS, GetDiariesWithEmptyValuesOutput_Diary, SleepDiaryGraphProps } from './utils';

interface IBedTimesGraphProps extends SleepDiaryGraphProps {
  hideBrush?: boolean;
  height?: number;
}

export const BedTimesGraph: React.FunctionComponent<IBedTimesGraphProps> = (props) => {
  const inBedLabel = 'foi para cama';
  const asleepLabel = 'saiu da cama';

  const cData = props.sleepDiaryData
    ? props.sleepDiaryData.map((item: GetDiariesWithEmptyValuesOutput_Diary) => {
        const response = {
          name: parseDateToShortString(parseToDate(item.date)),
        };
        if (!isNullOrUndefined(item.go_bed) && !isNullOrUndefined(item.get_up)) {
          const [go_bed, get_up] = getTimeInMinutesChronologically([item.go_bed, item.get_up]);
          const timezoneAdj = item.timezone > 60 ? item.timezone : item.timezone * 60;
          response[inBedLabel] = go_bed + timezoneAdj;
          response[asleepLabel] = get_up + timezoneAdj;
        }

        return response;
      })
    : [];

  // BUSINESS-RULE: the first cycle actually starts one day before the first diary
  if (props.cycles && props.cycles[0]) {
    cData.unshift({ name: parseDateToShortString(props.cycles[0].startDate) });
  }

  const height = props.height || 400;
  const formatter = toHour;

  const { theme } = useContext(AppThemeContext);

  return (
    <>
      <ChartWrapperStyled height={height}>
        <ResponsiveContainer>
          <ComposedChart
            data={cData}
            height={height}
            width={props.width}
            // POG-ALERT: this margin is a hack to remove space before/after Y-Axis
            margin={{ top: 0, right: 35, bottom: 0, left: -35 }}
          >
            <CartesianGrid stroke={theme.name === 'dark' ? GrayColor.GrayDark : GrayColor.GrayXXXLight} />
            <XAxis
              stroke={theme.name === 'dark' ? GrayColor.GrayXLight : GrayColor.Gray}
              dataKey="name"
              label={{ position: 'insideBottomRight', offset: 0 }}
            />

            <YAxis
              stroke={theme.name === 'dark' ? GrayColor.GrayXLight : GrayColor.Gray}
              tickFormatter={formatter}
              label={{ value: 'hora', className: 'rechart-left-label', angle: -90, position: 'top' }}
            />

            <Tooltip formatter={formatter} />

            <Legend verticalAlign="top" width={'100%' as any} wrapperStyle={{ left: 0, top: -30 }} />

            <Line isAnimationActive={!props.removeAnimation} dot={false} dataKey={inBedLabel} stroke={CHART_COLORS[25]} strokeWidth={2} />
            <Line
              isAnimationActive={!props.removeAnimation}
              dot={false}
              dataKey={asleepLabel}
              stroke={BrandColor.CongressBlue}
              strokeWidth={2}
            />

            {getCycleReferenceLine({ ...props })}
          </ComposedChart>
        </ResponsiveContainer>
      </ChartWrapperStyled>
    </>
  );
};
