import gql from 'graphql-tag';

export const HealthCarerFinancesQuery = gql`
  query HealthCarerFinancesQuery($id: String!) {
    health_carer_by_pk(id: $id) {
      id
      health_carer_transactions(order_by: { created_at: desc }) {
        id
        amount
        description
        created_at
        from_user {
          id
          document
          email
          alt_email
        }
      }
      health_carer_money_requests(where: { status: { _eq: PENDING } }, order_by: { created_at: desc }) {
        id
        amount
        status
        created_at
      }
    }
  }
`;
