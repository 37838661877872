import { parseDateToShortString } from '@global/utils/date/parse-to-string';
import { SleepDiaryWithAnalysis } from '@global/utils/domain/diary-analysis';
import { AppThemeContext, BodySmall, BrandColor, GrayColor } from '@web/atomic';
import React, { useContext } from 'react';
import { Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { get30minReferenceLine } from './30-min-reference.components';
import { getCycleReferenceLine } from './cycle-reference.components';
import { ChartWrapperStyled } from './graph.styled';
import { SleepDiaryGraphProps } from './utils';

interface IWasoGraphProps extends Omit<SleepDiaryGraphProps, 'sleepDiaryData'> {
  sleepDiaryData: {
    entryDate: Date;
    awakeAtNight?: number;
    wake_up_count?: number; // out of convention because it comes from Sleep_Diary
  }[];
}

export const WasoGraph: React.FunctionComponent<IWasoGraphProps> = (props) => {
  const wakeCountLabel = 'qtd de despertares';
  const wasoLabel = 'WASO (min)';

  const cData = props.sleepDiaryData
    ? props.sleepDiaryData.map((item) => {
        const response = {
          name: parseDateToShortString(item.entryDate),
        };
        response[wakeCountLabel] = item.wake_up_count;
        response[wasoLabel] = item.awakeAtNight;
        return response;
      })
    : [];
  // BUSINESS-RULE: the first cycle actually starts one day before the first diary
  if (props.cycles && props.cycles[0]) {
    cData.unshift({ name: parseDateToShortString(props.cycles[0].startDate) });
  }
  const { theme } = useContext(AppThemeContext);
  return (
    <>
      <ChartWrapperStyled height={props.height}>
        <ResponsiveContainer>
          <ComposedChart
            data={cData}
            height={props.height}
            width={props.width}
            // POG-ALERT: this margin is a hack to remove space before/after Y-Axis
            margin={{ top: 0, right: -25, bottom: 0, left: -35 }}
          >
            <CartesianGrid stroke={theme.name === 'dark' ? GrayColor.GrayDark : GrayColor.GrayXXXLight} />
            <XAxis
              stroke={theme.name === 'dark' ? GrayColor.GrayXLight : GrayColor.Gray}
              dataKey="name"
              label={{ position: 'insideBottomRight', offset: 0 }}
            />
            <YAxis
              stroke={theme.name === 'dark' ? GrayColor.GrayXLight : GrayColor.Gray}
              yAxisId="left"
              label={{ value: 'minutos', className: 'rechart-left-label', angle: -90, position: 'top' }}
            />
            <YAxis
              stroke={theme.name === 'dark' ? GrayColor.GrayXLight : GrayColor.Gray}
              yAxisId="right"
              orientation="right"
              label={{ value: 'qtd', className: 'rechart-right-label', angle: -90, position: 'top' }}
            />
            <Legend
              payload={
                [
                  { value: wasoLabel, type: 'line', id: wasoLabel, color: GrayColor.GrayDark },
                  { value: wakeCountLabel, type: 'square', id: wakeCountLabel, color: BrandColor.Cream },
                ] as any
              }
              width={'100%' as any}
              verticalAlign="top"
              wrapperStyle={{ left: 0, top: -30 }}
            />
            <Tooltip />
            <Bar yAxisId="right" dataKey={wakeCountLabel} fill={BrandColor.Cream} stroke={'none'} />
            <Line yAxisId="left" dot={false} dataKey={wasoLabel} stroke={GrayColor.GrayDark} strokeWidth={2} />
            {getCycleReferenceLine({ ...props, yAxisId: 'left', hideLabelForLast: true })}
            {get30minReferenceLine({ yAxisId: 'left' })}
          </ComposedChart>
        </ResponsiveContainer>
      </ChartWrapperStyled>
      <BodySmall>WASO: minutos acordado após início de sono</BodySmall>
    </>
  );
};
