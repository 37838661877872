import { Body, Col, Grid, GridSettings, H1, Row, Separator } from '@web/atomic';
import SvgErro404 from '@web/atomic/atm.svg-icon/erro404.component';
import { MatchMedia } from '@web/atomic/legacy/obj.match-media';
import * as React from 'react';
import { ReachButton } from '../../components/atm.button/button.component';
import { Layout } from '../../components/org.layout/layout.component';

const NotFoundPage: React.FunctionComponent = () => {
  return (
    <Layout>
      <Grid fluid>
        <Row mt mb reverse>
          <Col xs={12} mdOffset={2} md={6}>
            <SvgErro404 width="100%" />
            <MatchMedia defaultMinWidth={`${GridSettings.flexboxgrid.breakpoints.md}em`}>{(md) => md && <Separator />}</MatchMedia>
          </Col>

          <Col xs={12} md={4}>
            <Row mb>
              <Col xs={12}>
                <H1>{'Página não encontrada'}</H1>
                <Body>O endereço digitado não existe.</Body>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <ReachButton kind="primary" expanded to={'/'}>
                  Ir para o início
                </ReachButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </Grid>
    </Layout>
  );
};

export default NotFoundPage;
