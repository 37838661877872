import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { EventKind, sendEvent } from '@web/utils/analytics';
import React, { useEffect, useRef } from 'react';
import IconTextButton from '../atm.icon-text-button/icon-text-button.component';
import { DropdownMenuStyled } from './dropdown-menu.styled';

export interface DropdownMenuProps {
  isVisible: boolean;
  navItems: NavItem[];
  onCloseEvent: () => void;
}

export const DropdownMenu: React.FunctionComponent<DropdownMenuProps> = (props) => {
  const navItems = props.navItems;
  const ref = useRef();
  const close = props.isVisible ? props.onCloseEvent : undefined;

  useEffect(() => {
    document.addEventListener('mousedown', close);
    return () => {
      document.removeEventListener('mousedown', close);
    };
  }, [close]);

  return (
    <DropdownMenuStyled isVisible={props.isVisible} ref={ref}>
      {navItems.map((item) => (
        <IconTextButton
          onClick={() => {
            sendEvent(EventKind.click, {
              event_category: item.event_category,
              event_label: item.event_label,
            });
            if (item.onClick) {
              item.onClick();
            }
          }}
          href={item.href}
          faIcon={item.faIcon}
          text={item.text}
          key={item.text}
        />
      ))}
    </DropdownMenuStyled>
  );
};

export interface NavItem {
  text: string;
  faIcon: IconDefinition;
  event_category: string;
  event_label: string;
  onClick?: () => void;
  href?: string;
}
