import { toHour } from '@global/utils/date/to-hour';
import { BodySmall, EmojiStyle, H3 } from '@web/atomic';
import { OpinionatedColorStyled } from '@web/atomic/atm.opinionated-color/opinionated-color.styled';
import { Table, TD, TH, THead, TR } from '@web/atomic/legacy/mol.table';
import { FreezedTableColumnStyled } from '@web/atomic/legacy/mol.table/table.component.style';
import * as React from 'react';
import {
  mapFactorToEmoji,
  mapFactorToName,
  SleepDataSummaryItem,
  SleepTagAndTechniqueSummary,
} from '@global/utils/domain/tag-and-technique-comparator';

const tableLeftMargin = '9em';
const fixedCellWidth = '10em';

export interface ITagAndTechniqueComparatorTableProps {
  summary: SleepTagAndTechniqueSummary;
  tableKeys: (keyof SleepTagAndTechniqueSummary)[];
  showColName?: boolean;
  maxColWidth?: number;
}

export const TagAndTechniqueComparatorTable: React.FunctionComponent<ITagAndTechniqueComparatorTableProps> = (props) => (
  <Table marginLeft={tableLeftMargin}>
    <THead>
      <TR>
        <FreezedTableColumnStyled as={'th'} width={fixedCellWidth}>
          &nbsp;
        </FreezedTableColumnStyled>
        {props.tableKeys.map((key) => {
          return (
            <TH textAlign="left" maxWidth={props.maxColWidth} key={key}>
              {props.showColName ? (
                mapFactorToName(key)
              ) : (
                <H3 cell>
                  <EmojiStyle
                    role="tooltip"
                    data-microtip-position="bottom-right"
                    data-microtip-size="medium"
                    aria-label={mapFactorToName(key)}
                  >
                    {mapFactorToEmoji(key)}
                  </EmojiStyle>
                </H3>
              )}
            </TH>
          );
        })}
      </TR>
    </THead>
    <TR>
      <FreezedTableColumnStyled width={fixedCellWidth} textAlign="left">
        Número de noites
      </FreezedTableColumnStyled>
      {props.tableKeys.map(mapToTdHof(props.summary, 'count'))}
    </TR>
    <TR>
      <FreezedTableColumnStyled width={fixedCellWidth} textAlign="left">
        Disposição ao acordar (0 a 100)
      </FreezedTableColumnStyled>
      {props.tableKeys.map(mapToTdHof(props.summary, 'grade'))}
    </TR>
    <TR>
      <FreezedTableColumnStyled width={fixedCellWidth} textAlign="left">
        Acordado na cama <br /> (h)
      </FreezedTableColumnStyled>
      {props.tableKeys.map(mapToTdHof(props.summary, 'timeAwakeInBed', false))}
    </TR>
    <TR>
      <FreezedTableColumnStyled width={fixedCellWidth} textAlign="left">
        Dormindo
        <br /> (h)
      </FreezedTableColumnStyled>
      {props.tableKeys.map(mapToTdHof(props.summary, 'timeAsleep'))}
    </TR>
    <TR>
      <FreezedTableColumnStyled width={fixedCellWidth} textAlign="left">
        Eficiência
        <br /> (%)
      </FreezedTableColumnStyled>
      {props.tableKeys.map(mapToTdHof(props.summary, 'sleepEfficiency'))}
    </TR>
  </Table>
);

////////////////////////////////////////////////////////////////////////////////////////////////////

const mapToTdHof =
  (summary: SleepTagAndTechniqueSummary, sleepDataSummaryItemKey: keyof SleepDataSummaryItem, shouldIncrease = true) =>
  (sleepDataSummaryKey: keyof SleepTagAndTechniqueSummary) => {
    const all = summary.all;
    const item: SleepDataSummaryItem = summary[sleepDataSummaryKey];
    const allValue = all[sleepDataSummaryItemKey];
    const itemValue = item[sleepDataSummaryItemKey];
    const diffPercentage = Math.abs(Math.round(((itemValue - allValue) / allValue) * 100));
    const formattedValue =
      sleepDataSummaryItemKey === 'timeAsleep' || sleepDataSummaryItemKey === 'timeAwakeInBed' ? toHour(itemValue) : itemValue;
    const hasIncreased = itemValue > allValue;
    const isCount = sleepDataSummaryItemKey === 'count';
    return (
      <TD textAlign="left" key={sleepDataSummaryKey}>
        {formattedValue} <br />
        <BodySmall>
          <OpinionatedColorStyled good={shouldIncrease ? hasIncreased : !hasIncreased}>
            {isCount || allValue === itemValue ? null : hasIncreased ? '↑' : '↓'}
            {isCount || (diffPercentage !== 0 && `${diffPercentage}%`)}
          </OpinionatedColorStyled>
        </BodySmall>
      </TD>
    );
  };
