import { faFacebook, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Body, Button, GrayColor, LightColor, FontFamily, FontSize, H3, Hbox, Separator, Spacing } from '@web/atomic';
import { Modal } from '@web/atomic/legacy/obj.modal';
import { useBooleanState } from '@web/atomic/obj.boolean-state/boolean-state.hook';
import { EventKind, sendEvent } from '@web/utils/analytics';
import React from 'react';
import styled from 'styled-components';
import shareLogDatasource from './share-log.datasource';

const urlToShare = `https://www.vigilantesdosono.com/profissional`;
const textToShare = `Olá, estou usando a Vigilantes do Sono para tratar meus pacientes com insônia. Recomendo!
${urlToShare}`;

export const ShareVigilantesDoSono: React.FunctionComponent = () => {
  const handleCloseClick = () => {
    shareLogDatasource.hasClosedShare = true;
    closeModal();
  };

  const { value: modalOpened, setFalse: closeModal, setTrue: openModal } = useBooleanState(false);
  return (
    <>
      <ShareFixed onClick={openModal} />
      <ShareModal opened={modalOpened} onCloseClick={handleCloseClick} />
    </>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////

interface IShareModalProps {
  opened: boolean;
  onCloseClick: () => void;
}

export const ShareModal: React.FunctionComponent<IShareModalProps> = (props) => {
  const handleSendEmailClick = () => {
    const href = `mailto:?subject=Confira a Vigilantes do Sono&body=${encodeURIComponent(textToShare)}`;
    window.open(href, '_blank');
    sendEvent(EventKind.click, {
      event_category: 'share_vs_email',
      event_label: 'share on email',
    });
  };

  const handleWhatsappClick = () => {
    const href = `https://api.whatsapp.com/send?text=${encodeURIComponent(textToShare)}`;
    window.open(href, '_blank');
    sendEvent(EventKind.click, {
      event_category: 'share_vs_whatsapp',
      event_label: 'share on whatsapp',
    });
  };

  const handleFacebookClick = () => {
    const href = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(urlToShare)}`;
    window.open(href, '_blank');
    sendEvent(EventKind.click, {
      event_category: 'share_vs_facebook',
      event_label: 'share on facebook',
    });
  };

  return (
    <Modal disableScrollLock={true} small={true} opened={props.opened} onClose={props.onCloseClick}>
      <>
        <H3>Recomende a Vigilantes do Sono</H3>
        <Body>Compartilhe a Vigilantes do Sono com outros profissionais de saúde!</Body>
        <Separator />
        <Hbox hAlign="center">
          <Hbox.Item wrap={true}>
            <Button
              kind="primary"
              role="tooltip"
              data-microtip-position="top"
              data-microtip-size="small"
              aria-label={'E-mail'}
              onClick={handleSendEmailClick}
            >
              <FontAwesomeIcon fixedWidth={true} icon={faEnvelope} />
            </Button>
          </Hbox.Item>
          <Hbox.Separator />
          <Hbox.Separator />
          <Hbox.Item wrap={true}>
            <Button
              kind="primary"
              role="tooltip"
              data-microtip-position="top"
              data-microtip-size="small"
              aria-label={'Whatsapp'}
              onClick={handleWhatsappClick}
            >
              <FontAwesomeIcon fixedWidth={true} icon={faWhatsapp} />
            </Button>
          </Hbox.Item>
          <Hbox.Separator />
          <Hbox.Separator />
          <Hbox.Item wrap={true}>
            <Button
              kind="primary"
              role="tooltip"
              data-microtip-position="top"
              data-microtip-size="small"
              aria-label={'Facebook'}
              onClick={handleFacebookClick}
            >
              <FontAwesomeIcon fixedWidth={true} icon={faFacebook} />
            </Button>
          </Hbox.Item>
        </Hbox>
        <Separator />
      </>
    </Modal>
  );
};

export default ShareModal;

////////////////////////////////////////////////////////////////////////////////////////////////////

interface IShareFixedProps {
  onClick: () => void;
}

const ShareFixed: React.FunctionComponent<IShareFixedProps> = (props) => {
  return (
    <ShareFixedStyled onClick={props.onClick}>
      <FontAwesomeIcon fixedWidth={true} icon={faShareAlt} /> <ShareFixedTextStyled> Recomendar </ShareFixedTextStyled>
    </ShareFixedStyled>
  );
};

const ShareFixedStyled = styled.div`
  display: flex;
  align-items: center;

  position: fixed;
  z-index: 98;
  right: 0;
  top: 50%;
  transform: translate(0px, -50%);

  cursor: pointer;
  background: ${LightColor.Primary};
  padding: ${Spacing.Small};

  color: ${GrayColor.White};
  border-radius: ${Spacing.Small} 0 0 ${Spacing.Small};
  text-transform: uppercase;
  writing-mode: vertical-lr;
  text-orientation: sideways;
`;

const ShareFixedTextStyled = styled.span`
  font-size: ${FontSize.XXXSmall};
  font-family: ${FontFamily.Primary};
  transform: rotate(180deg);
  margin-top: ${Spacing.Small};
`;
