import {
  HealthCarerBalanceRequest,
  HealthCarerBalanceResponse,
  HealthCarerBalanceUrl,
} from '@global/utils/api/health-carer.controller.model';
import { MembershipKindsThatReceiveRevenue } from '@global/utils/domain/health-carer/membership-kind';
import { isNullOrUndefined } from '@global/utils/object';
import {
  Health_Carer,
  Health_Carer_Membership_Kind_Enum_Enum,
  Health_Carer_Pagarme_Recipient,
  Query_Root,
} from '@global/utils/remote-graphql-types';
import { useQueryParams } from '@web/atomic/obj.custom-hooks/query-params';
import { useLazyQuery } from '@web/data/apollo-client';
import React, { createContext, useEffect, useState } from 'react';
import { useGetUsingFirebase } from './components/obj.custom-hooks/use-get-using-firebase.hook';
import AuthDatasource from './data/auth-datasource';
import { HealthCarerNameQuery } from './data/graphql/health-carer.query.hasura';

export interface HcpUser extends firebase.User {
  id: string;
  name: string;
  couponCode: string;
  kind: string;
  professional_id: string;
  professional_id_state: string;
  membership_kind: Health_Carer_Membership_Kind_Enum_Enum;
  gender: string;
  whatsapp: string;
  balance: number;
  balanceAdditionalInfo: {
    data: HealthCarerBalanceResponse;
    error: HealthCarerBalanceResponse;
    loading: boolean;
  };
  hasFinancialInfo: boolean;
  financialInfo: Health_Carer_Pagarme_Recipient;
  transactions: {
    id: number;
    amount: number;
    description: string;
  }[];
  onUserChange: () => void;

  loading: boolean;
}

export const HcpUserContext = createContext<HcpUser>(null);

export const HcpUserProvider: React.FunctionComponent = (props) => {
  const queryParams = useQueryParams<{ userId: string }>();
  const [user, setUser] = useState<firebase.User>(undefined);
  const [getName, { refetch, data, loading }] = useLazyQuery<Query_Root>(HealthCarerNameQuery);
  const [refetchUser, setRefetchuser] = useState<number>(null);

  const healthCarer = data?.health_carer_by_pk;
  const id = queryParams.userId || healthCarer?.id || user?.uid;
  const {
    data: balanceData,
    error: balanceError,
    loading: balanceLoading,
    refetch: balanceFetch,
  } = useGetUsingFirebase<HealthCarerBalanceRequest, HealthCarerBalanceResponse>({
    url: HealthCarerBalanceUrl,
    params: { id },
    dontFetchOnLoad: true,
  });

  const isUserLoggedIn = !!user?.uid;
  useEffect(() => {
    if (isUserLoggedIn) {
      balanceFetch();
    }
  }, [isUserLoggedIn, refetchUser]);

  useEffect(() => {
    AuthDatasource.onAuthStateChanged((userAuth) => {
      // It's important to set the user as "null" if not set
      // - null -> means not logged
      // - undefined -> means we don't know
      // - else -> means logged in
      setUser(userAuth || null);
      if (!userAuth) return;
      const nextId = id || userAuth.uid;
      getName({ variables: { id: nextId } });
    });
    if (refetchUser) {
      refetch();
    }
  }, [id, refetchUser]);

  return (
    <HcpUserContext.Provider
      value={
        user &&
        ({
          ...user,
          uid: id,
          id,
          name: healthCarer?.name || user?.displayName,
          couponCode: data?.coupon?.[0]?.code,
          kind: healthCarer?.kind,
          professional_id: healthCarer?.professional_id,
          professional_id_state: healthCarer?.professional_id_state,
          gender: healthCarer?.gender,
          whatsapp: healthCarer?.whatsapp,
          membership_kind: healthCarer?.membership_kind,
          transactions: MembershipKindsThatReceiveRevenue.includes(healthCarer?.membership_kind)
            ? healthCarer?.health_carer_transactions
            : null,
          hasFinancialInfo: !!healthCarer?.pagarme_recipient,
          financialInfo: healthCarer?.pagarme_recipient,
          balance: getBalance(balanceData, healthCarer),
          balanceAdditionalInfo: {
            data: balanceData,
            error: balanceError,
            loading: balanceLoading,
          },
          onUserChange: () => setRefetchuser(Date.now()),
          loading,
        } satisfies HcpUser)
      }
    >
      {props.children}
    </HcpUserContext.Provider>
  );
};

function getBalance(balanceData: HealthCarerBalanceResponse, healthCarer: Health_Carer) {
  if (
    isNullOrUndefined(balanceData?.available_amount) &&
    isNullOrUndefined(balanceData?.transferred_amount) &&
    isNullOrUndefined(balanceData?.waiting_funds_amount)
  ) {
    return null;
  }

  if (!isNullOrUndefined(balanceData?.available_amount)) {
    return (balanceData.available_amount ?? 0) / 100;
  }

  return healthCarer?.health_carer_transactions_aggregate.aggregate.sum.amount ?? 0;
}

////////////////////////////////////////////////////////////////////////////////////////////////////

export interface UnauthenticatedUser {
  pathAfterLogin: string;
  setPathAfterLogin: (path: string) => void;
}

export const UnauthenticatedUserContext = createContext<UnauthenticatedUser>(null);

export const UnauthenticatedUserProvider: React.FunctionComponent = (props) => {
  const [pathAfterLogin, setPathAfterLogin] = useState<string>(undefined);
  return (
    <UnauthenticatedUserContext.Provider value={{ pathAfterLogin, setPathAfterLogin }}>
      {props.children}
    </UnauthenticatedUserContext.Provider>
  );
};
