import { isStateInitialFromBrazil } from '../../object/brazilian-states';
import { Gender, getGenderSuffix, getNeutralGenderSuffix } from '../entities';

export enum HealthCarerKind {
  Doctor = 'dr',
  Psychologist = 'psic',
  Physiotherapist = 'fisio',
  SpeechTherapist = 'fono',
  Dentist = 'dentist',
  Clinic = 'clinic',
  Institute = 'institute',
  PhysicalTrainer = 'trainer',
  Other = 'other',
}

// https://www.w3schools.com/html/html_form_input_types.asp
type InputType = 'text' | 'number';

export interface HealthCarerInterface {
  showHealthCarerKindOnSignup(): boolean;
  getProfessionPrefix(): string;
  getProfessionAbbreviation(): string;
  getHealthCarerKindName(): string;
  getCoupon(id: string, uf: string): string;
  // Ex: CRM, CRP, CRO, CREFITO
  getIdName(): string;
  // https://www.w3schools.com/html/html_form_input_types.asp
  getIdInput(): InputType;
}

export const HealthCarerFactory = (kind: HealthCarerKind, gender: Gender = Gender.Other): HealthCarerInterface => {
  switch (kind) {
    case HealthCarerKind.Doctor:
      return new HealthCarerDoctor(gender);
    case HealthCarerKind.Physiotherapist:
      return new HealthCarerPhysiotherapist();
    case HealthCarerKind.Psychologist:
      return new HealthCarerPsychologist(gender);
    case HealthCarerKind.Dentist:
      return new HealthCarerDentist(gender);
    case HealthCarerKind.SpeechTherapist:
      return new HealthCarerSpeechTherapist(gender);
    case HealthCarerKind.Other:
      return new HealthCarerOther();
    case HealthCarerKind.PhysicalTrainer:
      return new HealthCarerPhysicalTrainer();
    case HealthCarerKind.Clinic:
      return new HealthCarerClinic();
    case HealthCarerKind.Institute:
      return new HealthCarerInstitute();
    default:
      throw new Error(`Invalid health carer kind: ${kind}; gender ${gender}`);
  }
};

export class HealthCarerDoctor implements HealthCarerInterface {
  private gender: Gender;
  constructor(gender: Gender = Gender.Other) {
    this.gender = gender;
  }

  showHealthCarerKindOnSignup(): boolean {
    return true;
  }

  getProfessionPrefix(): string {
    return `dr${getNeutralGenderSuffix(this.gender)}`;
  }

  getProfessionAbbreviation(): string {
    return `dr${getNeutralGenderSuffix(this.gender)}`;
  }

  getHealthCarerKindName(): string {
    return `Médic${getGenderSuffix(this.gender)}`;
  }

  getCoupon(id: string, uf: string): string {
    const coupon = `${this.getIdName()}${uf}${+id}`;
    return normalizeCoupon(coupon);
  }

  getIdName(): string {
    return 'CRM';
  }

  getIdInput(): InputType {
    return 'number';
  }

  /** this method is coupled with `getCoupon` implementation */
  static isCouponForHealthCarerDoctor(coupon: string): boolean {
    const [, state, num] = coupon?.match(/crm([a-z]{2})(.*)/) ?? [];
    const isValidState = isStateInitialFromBrazil(state);
    const isValidNum = +num > 0 && Number.isInteger(+num);
    return isValidState && isValidNum;
  }
}

export class HealthCarerPhysiotherapist implements HealthCarerInterface {
  showHealthCarerKindOnSignup(): boolean {
    return true;
  }

  getProfessionPrefix(): string {
    return `${this.getHealthCarerKindName().toLowerCase()}`;
  }

  getProfessionAbbreviation(): string {
    return `fisio`;
  }

  getHealthCarerKindName(): string {
    return `Fisioterapeuta`;
  }

  getCoupon(id: string, uf: string): string {
    return normalizeCoupon(`${this.getIdName()}${uf}${id}`);
  }

  getIdName(): string {
    return 'CREFITO';
  }

  getIdInput(): InputType {
    return 'text';
  }
}

export class HealthCarerPsychologist implements HealthCarerInterface {
  private gender: Gender;
  constructor(gender: Gender = Gender.Other) {
    this.gender = gender;
  }

  showHealthCarerKindOnSignup(): boolean {
    return true;
  }

  getProfessionPrefix(): string {
    return `${this.getHealthCarerKindName().toLowerCase()}`;
  }

  getProfessionAbbreviation(): string {
    return `psic`;
  }

  getHealthCarerKindName(): string {
    return `Psicólog${getGenderSuffix(this.gender)}`;
  }

  getCoupon(id: string, uf: string): string {
    return normalizeCoupon(`${this.getIdName()}${uf}${id}`);
  }

  getIdName(): string {
    return 'CRP';
  }

  getIdInput(): InputType {
    return 'text';
  }
}

export class HealthCarerDentist implements HealthCarerInterface {
  private gender: Gender;
  constructor(gender: Gender = Gender.Other) {
    this.gender = gender;
  }

  showHealthCarerKindOnSignup(): boolean {
    return true;
  }

  getProfessionPrefix(): string {
    return `dr${getNeutralGenderSuffix(this.gender)}`;
  }

  getProfessionAbbreviation(): string {
    return `dr${getNeutralGenderSuffix(this.gender)}`;
  }

  getHealthCarerKindName(): string {
    return `Dentista`;
  }

  getCoupon(id: string, uf: string): string {
    return normalizeCoupon(`${this.getIdName()}${uf}${+id}`);
  }

  getIdName(): string {
    return 'CRO';
  }

  getIdInput(): InputType {
    return 'number';
  }
}

export class HealthCarerSpeechTherapist implements HealthCarerInterface {
  private gender: Gender;
  constructor(gender: Gender = Gender.Other) {
    this.gender = gender;
  }

  showHealthCarerKindOnSignup(): boolean {
    return true;
  }

  getProfessionPrefix(): string {
    return `${this.getHealthCarerKindName().toLowerCase()}`;
  }

  getProfessionAbbreviation(): string {
    return `fono`;
  }

  getHealthCarerKindName(): string {
    return `Fonoaudiólog${getGenderSuffix(this.gender)}`;
  }

  getCoupon(id: string, uf: string): string {
    return normalizeCoupon(`${this.getIdName()}${uf}${id}`);
  }

  getIdName(): string {
    return 'CRFa';
  }

  getIdInput(): InputType {
    return 'text';
  }
}

export class HealthCarerOther implements HealthCarerInterface {
  showHealthCarerKindOnSignup(): boolean {
    return true;
  }

  getProfessionPrefix(): string {
    return ``;
  }

  getProfessionAbbreviation(): string {
    return ``;
  }

  getHealthCarerKindName(): string {
    return `Outra`;
  }

  getIdName(): string {
    return '';
  }

  getIdInput(): InputType {
    return 'text';
  }

  getCoupon(): string {
    return null;
  }
}

export class HealthCarerPhysicalTrainer implements HealthCarerInterface {
  showHealthCarerKindOnSignup(): boolean {
    return true;
  }

  getProfessionPrefix(): string {
    return `${this.getHealthCarerKindName().toLowerCase()}`;
  }

  getProfessionAbbreviation(): string {
    return 'educ.fis';
  }

  getHealthCarerKindName(): string {
    return `Profissional da Educação Física`;
  }

  getIdName(): string {
    return 'CREF';
  }

  getIdInput(): InputType {
    return 'text';
  }

  getCoupon(id: string, uf: string): string {
    return normalizeCoupon(`${this.getIdName()}${uf}${id}`);
  }
}

export class HealthCarerClinic implements HealthCarerInterface {
  showHealthCarerKindOnSignup(): boolean {
    return false;
  }

  getProfessionPrefix(): string {
    return `clínica`;
  }

  getProfessionAbbreviation(): string {
    return `clin`;
  }

  getHealthCarerKindName(): string {
    return '';
  }

  getIdName(): string {
    return '';
  }

  getIdInput(): InputType {
    return 'text';
  }

  getCoupon(): string {
    return null;
  }
}

export class HealthCarerInstitute implements HealthCarerInterface {
  showHealthCarerKindOnSignup(): boolean {
    return false;
  }

  getProfessionPrefix(): string {
    return `instituto`;
  }

  getProfessionAbbreviation(): string {
    return `inst`;
  }

  getHealthCarerKindName(): string {
    return '';
  }

  getIdName(): string {
    return '';
  }

  getIdInput(): InputType {
    return 'text';
  }

  getCoupon(): string {
    return null;
  }
}

const normalizeCoupon = (coupon: string) => coupon.replace(/([^a-z0-9]+)/gi, '').toLowerCase();
