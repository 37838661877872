import SvgIcClose from '@web/atomic/atm.svg-icon/ic-close.component';
import SvgIcSuccess from '@web/atomic/atm.svg-icon/ic-success.component';
import { Col, Grid, Row } from '@web/atomic';
import { FaIcon } from '@web/atomic/legacy/atm.fa-icon';
import { Fade } from '@web/atomic/legacy/obj.animation/animation.component.style';
import * as React from 'react';
import { Hbox, Separator } from '../obj.box';
import {
  FlashIconStyled,
  FlashMessageCloseStyled,
  FlashMessageContentStyled,
  FlashMessageStyled,
  flashMessageTypesColors,
  FlashMessageWrapperStyled,
} from './flash-message.component.style';

export type FlashMessageType = 'alert' | 'success' | 'info-secondary' | 'info' | 'warning';

export interface FlashMessageProps {
  type?: FlashMessageType;
  outlined?: boolean;
  dismissible?: boolean;
  autoClose?: boolean;
  onClose?: (ev?) => any;
  onClick?: (ev?) => any;
}

interface FlashMessageState {
  hidden: boolean;
  remove: boolean;
}

const timeToHide = 12 * 1000;

/**
 * This is an uncontrolled flash message.
 * It starts NOT hidden and after "timeToHide" seconds it is removed from DOM.
 * The user can also close it manually
 */
export class FlashMessage extends React.PureComponent<FlashMessageProps, FlashMessageState> {
  public static defaultProps: Partial<FlashMessageProps> = {
    type: 'info',
    dismissible: true,
    outlined: false,
    autoClose: true,
  };

  private timeoutList: any[] = [];

  constructor(props: FlashMessageProps) {
    super(props);

    this.state = {
      hidden: false,
      remove: false,
    };
  }

  componentDidMount(): void {
    this.startCloseTimer();
  }

  componentWillUnmount(): void {
    this.clearTimeoutList();
  }

  render(): React.ReactElement {
    const { children, dismissible, ...other } = this.props;
    return this.state.remove ? null : (
      <Fade show={!this.state.hidden}>
        <Separator />
        <Grid>
          <Row center="xs">
            <Col xs={12} md={7}>
              <FlashMessageWrapperStyled>
                <FlashMessageStyled type={other.type}>
                  <Hbox vAlign="flex-start" hAlign="center">
                    <Hbox.Item wrap>
                      <FlashIconStyled onClick={this.handleCloseClick} {...other}>
                        <FlashIcon messageKind={this.props.type} />
                      </FlashIconStyled>
                    </Hbox.Item>
                    <Hbox.Item>
                      <FlashMessageContentStyled>{children}</FlashMessageContentStyled>
                    </Hbox.Item>
                    <Hbox.Item wrap>
                      {dismissible ? (
                        <FlashMessageCloseStyled onClick={this.handleCloseClick} {...other}>
                          <SvgIcClose height="14px" color={flashMessageTypesColors[this.props.type]} />
                        </FlashMessageCloseStyled>
                      ) : null}
                    </Hbox.Item>
                  </Hbox>
                </FlashMessageStyled>
              </FlashMessageWrapperStyled>
            </Col>
          </Row>
        </Grid>
      </Fade>
    );
  }

  private handleCloseClick = (ev) => {
    ev.stopPropagation();
    this.startRemoveFromDomAnimation();
  };

  private startCloseTimer = () => {
    if (this.props.autoClose) {
      const timeout = setTimeout(() => this.startRemoveFromDomAnimation(), timeToHide);
      this.timeoutList.push(timeout);
    }
  };

  private startRemoveFromDomAnimation = () => {
    this.setState({ hidden: true }, () => {
      const timeout = setTimeout(() => this.removeFromDom(), 300);
      this.timeoutList.push(timeout);
    });
  };

  private removeFromDom = () => {
    this.setState({ remove: true }, () => {
      this.clearTimeoutList();
      if (this.props.onClose) {
        this.props.onClose();
      }
    });
  };

  private clearTimeoutList = () => {
    this.timeoutList.forEach((element) => clearTimeout(element));
  };
}

interface FlashIconProps {
  messageKind: FlashMessageType;
}

const FlashIcon: React.FunctionComponent<FlashIconProps> = (props) => {
  switch (props.messageKind) {
    case 'alert':
      return <FaIcon.FlashAlert />;

    case 'info':
      return <FaIcon.FlashInfo />;

    case 'info-secondary':
      return <FaIcon.FlashInfo />;

    case 'success':
      return <SvgIcSuccess height="14px" />;

    case 'warning':
      return <FaIcon.FlashWarning />;

    default:
      return null;
  }
};
