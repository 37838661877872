import { formatAsPrice } from '@global/utils/number/format';
import { isNullOrUndefined } from '@global/utils/object';
import { Body, Button, FaIcon, H2, ProductPrice, SemiBold, Separator } from '@web/atomic';
import { CardWrapper } from '@web/atomic/atm.wrapper/wrapper.component';
import { ShimmerButton, ShimmerText } from '@web/atomic/legacy/mol.shimmer/shimmer.options';
import React from 'react';

interface IWithdrawMoneyProps {
  pendingRequest: boolean;
  balance: number;
  balanceToReceive: number;
  fetchLoading: boolean;
  onWithdrawClick: () => void;
}

export const WithdrawMoney: React.FunctionComponent<IWithdrawMoneyProps> = (props) => {
  return (
    <>
      <Separator />
      <CardWrapper>
        <H2 cell>Saldo disponível para saque</H2>
        <Separator />
        <ProductPrice> {!isNullOrUndefined(props.balance) ? formatAsPrice(props.balance, true) : <ShimmerText />}</ProductPrice>
        {props.balanceToReceive > 0 && (
          <Body
            role="tooltip"
            data-microtip-position="top"
            data-microtip-size="medium"
            aria-label={'Cada valor é liberado em até 30 dias após a confirmação do pagamento.'}
          >
            {formatAsPrice(props.balanceToReceive, true)} a liberar <FaIcon.Question />
          </Body>
        )}
        <Separator />
        {props.fetchLoading && <ShimmerButton />}

        {!props.fetchLoading && props.pendingRequest && (
          <Body>
            <SemiBold>Resgate pendente:</SemiBold> Você tem um pedido de resgate pendente. Realizamos o depósito em até 2 dias úteis a
            partir do horário do pedido.
          </Body>
        )}

        {!props.fetchLoading && !!props.balance && (
          <Button kind="primary" expanded onClick={props.onWithdrawClick}>
            Resgatar dinheiro
          </Button>
        )}
      </CardWrapper>
      <Separator />
    </>
  );
};
