import { DoctorReportPage } from '@component/doctor-report/doctor-report.page';
import { getIdFromHealthCarerPatientReportUrl } from '@global/utils/domain/health-carer/patient-report-url';
import { Body, Col, Grid, Row } from '@web/atomic';
import React, { useState } from 'react';
import { PrintLayout } from '@web/atomic/obj.print-layout/print-layout.styled';
import { withAuth } from '../../components/router/with-auth.hoc';

interface PrintReportPageProps {
  user?: string;
}

const PrintReportPage: React.FunctionComponent<PrintReportPageProps> = (props) => {
  const [loaded, setLoaded] = useState(false);
  const handleDataLoad = () => {
    setLoaded(true);
    setTimeout(() => {
      window.print();
    }, 1000);
  };
  return (
    <>
      {loaded || (
        <Grid fluid>
          <Row mt>
            <Col>
              <Body> Preparando impressão para você ...</Body>
            </Col>
          </Row>
        </Grid>
      )}
      <PrintLayout>
        <DoctorReportPage userId={getIdFromHealthCarerPatientReportUrl(props.user)} onDataLoad={handleDataLoad} />
      </PrintLayout>
    </>
  );
};

const AuthPrintDoctorReportPage = withAuth(PrintReportPage);
export default AuthPrintDoctorReportPage;
