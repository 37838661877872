import styled from 'styled-components';
import { ActivityIndicatorProps } from './activity-indicator.component';
import { isNullOrUndefined } from '@global/utils/object/null-or-undefined';
import { GrayColor, LightColor } from '@web/atomic/legacy/obj.constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ActivityIndicatorStyled = styled(FontAwesomeIcon)<ActivityIndicatorProps>`
  color: ${(props) => (isNullOrUndefined(props.light) ? 'inherit' : props.light ? GrayColor.White : LightColor.Primary)};
  display: inline-block;
`;
