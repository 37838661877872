import { ChatfuelBlockIds, ChatfuelBlockNames } from '../chat/chatfuel-blocks-types';
import { Isi } from '../remote-graphql-types';
import { IQuestionnaireResultExplanationData } from './questionnaire';

// TODO: REFACTOR - create ISI entity and move this logic there
// ISI methods
export const computeIsiPoints = (isi: Omit<Isi, 'id' | 'user_id' | 'created_at'>): number =>
  isi.q1 + isi.q2 + isi.q3 + isi.q4 + isi.q5 + isi.q6 + isi.q7;

export const isiThreshold = [7, 14, 21, 28];

/*
 * Make sure the redirectBlock and Initial Block are the same block
 */
const redirectBlock: ChatfuelBlockIds = '63164bc109e9ad1358379649';
export const isiInitialBlockName: ChatfuelBlockNames = 'isi_youpage_entrance';

export const isiQuestionnaire = {
  name: 'Insônia',
  redirect: redirectBlock,
};

export const isiResultExplanationData: IQuestionnaireResultExplanationData = {
  title: 'Questionário IGI',
  description: 'O questionário tem como objetivo quantificar a percepção da gravidade de sintomas de insônia.',
  results: [
    {
      title: '0 - 7 pontos',
      description: 'não tem sintomas de insônia em um grau que os especialistas consideram de relevância clínica',
    },
    {
      title: '8 - 14 pontos',
      description: 'tem sintomas de insônia em um grau leve',
    },
    {
      title: '15 - 21 pontos',
      description: 'tem sintomas de insônia em um grau moderado',
    },
    {
      title: '22 - 28 pontos',
      description: 'sintomas graves de insônia',
    },
  ],
};
