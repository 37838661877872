import { Body, Button, Col, FaIcon, GrayColor, Hbox, Row, Separator } from '@web/atomic';
import { sendDataLayer } from '@web/utils/analytics';
import { useCopyOrShareInfo } from '@web/utils/copy-or-share-info.hook';
import React, { ReactNode } from 'react';
import { ShareBoxStyled, ShareBoxTextWrapper } from './share-box.styled';

interface ShareBoxProps {
  title: string;
  shareUrl: string;
  textToBeCopied: string;
  textToBePresented: ReactNode;

  secondaryButtonText?: string;
  onSecondaryButtonClick?: () => void;
}

const ShareBox: React.FunctionComponent<ShareBoxProps> = (props) => {
  const successMessage = 'Instruções copiadas!';
  const errorMessage = 'Não foi possível copiar as instruções. 😢';
  const { shareAvailable, copyOrShare } = useCopyOrShareInfo({ successMessage, errorMessage });

  const handleClick = () => {
    copyOrShare({ text: props.textToBeCopied });
    sendDataLayer({
      event: 'custom_click',
      id: `ga-ShareBox-copy-instructions`,
    });
  };

  return (
    <ShareBoxStyled>
      <Row>
        <Col xs={12}>
          <Hbox hAlign="space-between">
            <Hbox.Item vAlign={'center'}>
              <ShareBoxTextWrapper>
                <Body>{props.textToBePresented}</Body>
              </ShareBoxTextWrapper>
            </Hbox.Item>
          </Hbox>
          <Separator />
          <Hbox hAlign="center">
            <Hbox.Item wrap={true}>
              <Button kind={'primary'} onClick={handleClick}>
                {shareAvailable ? <FaIcon.Share color={GrayColor.White} /> : <FaIcon.Clone color={GrayColor.White} />}{' '}
                {shareAvailable ? 'Enviar instruções' : 'Copiar instruções'}
              </Button>
            </Hbox.Item>
            {props.onSecondaryButtonClick && props.secondaryButtonText && (
              <>
                <Hbox.Separator />
                <Hbox.Item wrap={true}>
                  <Button kind={'secondary'} onClick={props.onSecondaryButtonClick}>
                    {props.secondaryButtonText}
                  </Button>
                </Hbox.Item>
              </>
            )}
          </Hbox>
        </Col>
      </Row>
    </ShareBoxStyled>
  );
};

export default ShareBox;
