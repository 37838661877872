import { getTopElementHeight, scrollToElement } from '@web/utils/scroll';
import * as React from 'react';
import { FormField } from './form-field.component';
import { FormContext, FormContextState, FormData, RegistrableField } from './form.context';
import { ValidationError } from './validators';

interface FormProps extends React.FormHTMLAttributes<HTMLFormElement> {
  onChange?: any;
  onSubmit?: any;
  ref?: any;
  autoComplete?: string;

  /**
   * if your header id is not 'header', then pass a props here
   */
  headerId?: string;
}

/**
 * A component for form creation. If you follow its convention it has some out of the box behaviours.
 * When the user submits a form, it iterates over all inner "Form.Field"
 * elements and call their validation. After that, it returns a "formData"
 * object with all found errors and the final data
 *
 * The goal is to enable more declarative forms (no need to have refs, call
 * validation and collect data manually, build the submission data manually)
 *
 */
export class Form extends React.Component<FormProps, FormContextState> {
  static Field = FormField;

  static defaultProps = {
    headerId: 'header',
  };

  private fields: { [name: string]: RegistrableField } = {};

  constructor(props) {
    super(props);

    this.state = {
      register: this.handleRegister,
      unregister: this.handleUnregister,
      validateField: (fieldToValidate: string) => this.validateField(fieldToValidate),
      getFormData: this.getFormData,
      scrollToField: this.scrollToField,
    };
  }

  render() {
    return (
      <FormContext.Provider value={this.state}>
        <form onSubmit={this.handleSubmit} name={this.props.name} noValidate={true} autoComplete={this.props.autoComplete}>
          {this.props.children}
        </form>
      </FormContext.Provider>
    );
  }

  private handleRegister = (field: RegistrableField) => {
    if (field.props.name !== undefined) {
      this.fields[field.props.name] = field;
    }
  };

  private handleUnregister = (field: RegistrableField) => {
    if (field.props.name !== undefined) {
      delete this.fields[field.props.name];
    }
  };

  private handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // WHY? to enable form inside form (using React Portal to respect HTML rules)
    // https://stackoverflow.com/a/74963826/3670829
    e.stopPropagation();

    const formData = await this.mapFormData();
    if (Object.keys(formData.error).length > 0) {
      const data = formData.error;
      const key = Object.keys(data)[0];
      const fieldName = this.getFieldName(key, data);
      this.scrollToField(fieldName);
    }

    if (this.props.onSubmit) {
      this.props.onSubmit(formData);
    }
  };

  private getFieldName(key: string, obj) {
    const nextObj = obj[key];
    if (typeof nextObj !== 'object') {
      return '';
    }

    const nextKey = Object.keys(nextObj)[0];
    const suffix = this.getFieldName(nextKey, nextObj);
    return `${key}${suffix.length ? '.' : ''}${suffix}`;
  }

  private scrollToField = (name: string) => {
    scrollToElement(name, { top: getTopElementHeight(this.props.headerId) });
  };

  private getFormData = (): FormData<any> | Promise<FormData<any>> => {
    const formData: FormData<any> = { data: {}, other: {}, error: {} };
    Object.keys(this.fields)
      .map((fieldName: string) => this.fields[fieldName])
      .map((field: any) => {
        set(formData.data, field.props.name, field.state.value);
        set(formData.other, field.props.name, field.state.other);

        if (field.state.errors) {
          field.state.errors.map((error: ValidationError) => {
            set(formData.error, field.props.name, {
              name: error.name,
              message: error.message,
            });
          });
        }
      });
    return formData;
  };

  private validateField(fieldToValidate): Promise<FormData<any>> {
    if (!Object.keys(this.fields).includes(fieldToValidate)) {
      console.warn('ERROR ~ Incorrect field name ~ fieldToValidate ', fieldToValidate);
      return;
    }
    const field = this.fields[fieldToValidate];
    const value = field.state.value;
    return Promise.resolve(
      // Trigger validation on fieldToValidate
      field.validate(value)
    ).then(this.getFormData);
  }

  private mapFormData(): Promise<FormData<any>> {
    return Promise.all(
      // Trigger validation on all the fields
      Object.keys(this.fields)
        .filter((fieldName: string) => this.fields[fieldName].validate)
        .map((fieldName: string) => this.fields[fieldName])
        .map((field) => field.validate(field.state.value))
    ).then(this.getFormData);
  }
}

// https://stackoverflow.com/questions/54733539/javascript-implementation-of-lodash-set-method
const set = (obj, path, value) => {
  if (Object(obj) !== obj) return obj; // When obj is not an object
  // If not yet an array, get the keys from the string-path
  if (!Array.isArray(path)) path = path.toString().match(/[^.[\]]+/g) || [];
  path.slice(0, -1).reduce(
    (
      a,
      c,
      i // Iterate all of them except the last one
    ) =>
      Object(a[c]) === a[c] // Does the key exist and is its value an object?
        ? // Yes: then follow that path
          a[c]
        : // No: create the key. Is the next key a potential array-index?
          (a[c] =
            Math.abs(path[i + 1]) >> 0 === +path[i + 1]
              ? [] // Yes: assign a new array object
              : {}), // No: assign a new plain object
    obj
  )[path[path.length - 1]] = value; // Finally assign the value to the last key
  return obj; // Return the top-level object to allow chaining
};
