export enum ErrorName {
  AlreadyUsedCouponError = 'AlreadyUsedCouponError',
  /** @deprecated 6Qxlq3Py43BbkD5G telehealth was deprecated in june 2023 - https://www.notion.so/vigilantesdosono/remo-o-teleconsultas-pt1-23098acaf8de4bd085668cbbd92bf249?pvs=4 */
  AppointmentLimitReachedError = 'AppointmentLimitReachedError',
  /** @deprecated 6Qxlq3Py43BbkD5G telehealth was deprecated in june 2023 - https://www.notion.so/vigilantesdosono/remo-o-teleconsultas-pt1-23098acaf8de4bd085668cbbd92bf249?pvs=4 */
  AppointmentAlreadyScheduled = 'AppointmentAlreadyScheduled',
  /** @deprecated 6Qxlq3Py43BbkD5G telehealth was deprecated in june 2023 - https://www.notion.so/vigilantesdosono/remo-o-teleconsultas-pt1-23098acaf8de4bd085668cbbd92bf249?pvs=4 */
  AppointmentNotAvailableNowError = 'AppointmentNotAvailableNowError',
  /** @deprecated 6Qxlq3Py43BbkD5G telehealth was deprecated in june 2023 - https://www.notion.so/vigilantesdosono/remo-o-teleconsultas-pt1-23098acaf8de4bd085668cbbd92bf249?pvs=4 */
  CannotRescheduleAppointmentError = 'CannotRescheduleAppointmentError',
  BadRequestError = 'BadRequestError',
  CantRedeemError = 'CantRedeemError',
  HealthCarerTimeAlreadyBusyError = 'HealthCarerTimeAlreadyBusyError',
  CreateHealthCarerUseCaseDuplicateEmailError = 'CreateHealthCarerUseCaseDuplicateEmailError',
  CreateHealthCarerUseCaseInvalidCRMError = 'CreateHealthCarerUseCaseInvalidCRMError',
  DuplicatedEmailError = 'DuplicatedEmailError',
  DuplicatedUserError = 'DuplicatedUserError',
  EmptyTransactionIdError = 'EmptyTransactionIdError',
  ExpiredCouponError = 'ExpiredCouponError',
  HealthCarerUseCaseInvalidDuplicatedProfessionIdError = 'HealthCarerUseCaseInvalidDuplicatedProfessionIdError',
  HealthCarerrDoesntExistError = 'HealthCarerrDoesntExistError',
  HttpError = 'HttpError',
  InactiveCouponError = 'InactiveCouponError',
  InvalidCouponError = 'InvalidCouponError',
  InvalidEmaillError = 'InvalidEmaillError',
  InvalidPackageName = 'InvalidPackageName',
  InvalidPhoneError = 'InvalidPhoneError',
  InvalidProductId = 'InvalidProductId',
  InvalidSetupPagarMeServiceError = 'InvalidSetupPagarMeServiceError',
  NotAuthorizedPagarMeServiceError = 'NotAuthorizedPagarMeServiceError',
  NotFoundCouponError = 'NotFoundCouponError',
  NotFoundError = 'NotFoundError',
  OperationAlreadyDonePerformHealthCarerMoneyAdditionForUserPaymentUseCaseError = 'OperationAlreadyDonePerformHealthCarerMoneyAdditionForUserPaymentUseCaseError',
  UsageLimitSurpassedCouponError = 'UsageLimitSurpassedCouponError',
  UserDoesntExistError = 'UserDoesntExistError',
  UserHasntPaidPerformHealthCarerMoneyAdditionForUserPaymentUseCaseError = 'UserHasntPaidPerformHealthCarerMoneyAdditionForUserPaymentUseCaseError',
  ValidationErrorPagarMeServiceError = 'ValidationErrorPagarMeServiceError',
  UserHasTwoDifferentsSubscriptionsError = 'UserHasTwoDifferentsSubscriptionsError',
  CantUpdateUserCheckout = 'CantUpdateUserCheckout',
  /** @deprecated 6Qxlq3Py43BbkD5G telehealth was deprecated in june 2023 - https://www.notion.so/vigilantesdosono/remo-o-teleconsultas-pt1-23098acaf8de4bd085668cbbd92bf249?pvs=4 */
  CantUpdateUserTelehealth = 'CantUpdateUserTelehealth',
}
