/* eslint-disable @typescript-eslint/no-var-requires */
import styled from 'styled-components';

import { Spacing, LightColor, Border } from '@web/atomic';

export const ShareBoxStyled = styled.div`
  padding: ${Spacing.Medium};
  background: ${LightColor.BackgroundGrayHealthCarer};
  border-radius: ${Border.Radius};
  overflow: hidden;
`;

export const ShareBoxIconWrapper = styled.div`
  padding: ${Spacing.Medium};
  height: auto;
  display: flex;
  justify-content: center;
`;

export const ShareBoxTextWrapper = styled.div`
  white-space: pre-line;
  text-align: justify;
`;
