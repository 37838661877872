import { InputValue } from '@web/atomic/legacy/atm.typography';
import { GrayColor, LightColor, Spacing } from '@web/atomic/legacy/obj.constants';
import { highlightStyle } from '@web/atomic/legacy/obj.mixin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { fadeIn } from '../obj.animation/animation.component.style';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';

export const checkboxHeight = 24;
export const marginBetweenCheckboxItems = Spacing.Medium;

export const CheckboxFieldStyled = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;

  & ~ & {
    margin-top: ${marginBetweenCheckboxItems};
  }
`;

export const CheckboxFieldTextStyled = styled(InputValue)`
  padding-left: ${Spacing.Small};
  line-height: ${checkboxHeight}px;
  vertical-align: bottom;
  z-index: 2;
  ${highlightStyle};
`;

export const CheckboxCheckedStyled = styled(FontAwesomeIcon).attrs({ icon: faCheckSquare })`
  font-size: ${checkboxHeight}px !important;
  color: ${(props) => (props.theme.name === 'dark' ? GrayColor.White : LightColor.Primary)};
  z-index: 1;
  display: none;
`;

export const CheckboxUncheckedStyled = styled(FontAwesomeIcon).attrs({ icon: faSquare })`
  font-size: ${checkboxHeight}px !important;
  color: ${(props) => (props.theme.name === 'dark' ? GrayColor.White : GrayColor.GrayDark)};
  z-index: 0;
  display: inline-block;
`;

export const CheckboxFieldBulletStyled = styled.input`
  display: none;

  &:checked + ${CheckboxCheckedStyled} {
    display: inline-block;
    animation: ${fadeIn} 0.5s linear forwards;
    + ${CheckboxUncheckedStyled} {
      display: none;
    }
  }
`;
