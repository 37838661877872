import { parseDateToShortString } from '@global/utils/date/parse-to-string';
import { GrayColor } from '@web/atomic';
import * as React from 'react';
import { ReferenceLine } from 'recharts';

export interface ICycleReferenceLineProps {
  cycles?: {
    startDate: Date;
    name: string;
  }[];
  hideLabelForLast?: boolean;
  yAxisId?: string;
}

export const getCycleReferenceLine = (props: ICycleReferenceLineProps): React.ReactElement[] => {
  if (!props.cycles || !props.cycles.length) {
    return null;
  }
  return props.cycles.map((item, index) => {
    const name = index === props.cycles.length - 1 && props.hideLabelForLast ? '' : props.cycles.length > 5 ? `${index}` : item.name;
    return (
      <ReferenceLine
        key={item.name}
        x={parseDateToShortString(item.startDate)}
        yAxisId={props.yAxisId}
        stroke={GrayColor.GrayLight}
        strokeDasharray="3 3"
        label={<CustomLabel name={name} />}
      />
    );
  });
};

////////////////////////////////////////////////////////////////////////////////////////////////////
interface ICustomLabelProps {
  // http://recharts.org/en-US/api/ReferenceLine#viewBox
  viewBox?: {
    x: number;
    y: number;
  };
  name: string;
}

const CustomLabel: React.FunctionComponent<ICustomLabelProps> = (props) => {
  return (
    <text
      x={props.viewBox.x}
      y={props.viewBox.y}
      className={`recharts-text recharts-label recharts-cycle-reference-line`}
      textAnchor="left"
    >
      <tspan x={props.viewBox.x + 4} dy="0.25em">
        {props.name}
      </tspan>
    </text>
  );
};
