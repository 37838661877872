import { RouteComponentProps } from '@reach/router';
import * as React from 'react';
import AuthPrintDoctorReportPage from './print-patient-report.page';

export const PrintPatientReportRouteUrl = '/imprimir/:user';

export interface PrintPatientReportRouteProps extends RouteComponentProps {
  user?: string;
}

export const PrintPatientReportRoute: React.FunctionComponent<PrintPatientReportRouteProps> = (props) => {
  return <AuthPrintDoctorReportPage user={props.user} />;
};
