import styled from 'styled-components';
import { GrayColor } from '@web/atomic/legacy/obj.constants';

export const PrivacyPolicyComponentWrapper = styled.div`
  position: fixed;
  bottom: 0;

  width: 100%;
  background-color: ${GrayColor.White};
  border-top: 1px solid ${GrayColor.GrayXXLight};

  box-shadow: 0 -4px 8px -4px rgba(0, 0, 0, 0.5);

  z-index: 2;
`;
