import { Border, BrandColor, FontFamily, FontSize, FontWeight, GrayColor, LightColor, Spacing } from '@web/atomic/legacy/obj.constants';
import { ThemeProps } from '@web/atomic/obj.theme/theme.interface';
import styled, { css } from 'styled-components';

export interface TypographyProps {
  kind?: 'light' | 'default';
  color?: string;
  center?: boolean;
}

export interface HDisplayProps extends TypographyProps {
  small?: boolean;
}

const getTextColorHOF =
  (defaultDarkColor = GrayColor.GrayDark, defaultLightColor = GrayColor.White) =>
  (props: TypographyProps & ThemeProps) => {
    if (props.kind === 'light') {
      return GrayColor.White;
    }
    if (props.kind === 'default') {
      return GrayColor.GrayDark;
    }
    return props.theme.name === 'regular' ? defaultDarkColor : defaultLightColor;
  };

export const HDisplayStyle = css`
  color: ${({ theme }) => getTextColorHOF(theme.color.primary)};
  font-size: ${(props: HDisplayProps) => (props.small ? FontSize.Large : FontSize.XLarge)};
  line-height: 1.1875;
  font-family: ${FontFamily.Secondary};
  font-weight: ${FontWeight.Bold};
  letter-spacing: 1px;
  margin: ${Spacing.Small} 0;
  text-align: ${(props: HDisplayProps) => (props.center ? 'center' : 'inherit')};
`;

export const HDisplay = styled.h3`
  ${HDisplayStyle}
`;
HDisplay.displayName = 'HDisplay';

export const H1Style = css`
  color: ${getTextColorHOF()};
  text-align: ${(props: TypographyProps) => (props.center ? 'center' : 'inherit')};
  font-family: ${FontFamily.Secondary};
  font-weight: ${FontWeight.Bold};
  margin: 0;
  line-height: 1.5;

  font-size: ${FontSize.Large};
  @media all and (min-width: 32em) {
    font-size: ${FontSize.XLarge};
  }
`;
export const H1 = styled.h1`
  ${H1Style}
`;

export interface H2TypographyProps extends TypographyProps {
  cell?: boolean;
}

export const H2Style = css<H2TypographyProps>`
  color: ${getTextColorHOF(GrayColor.Black)};
  font-size: ${FontSize.Medium};
  font-size: ${({ theme }) => (theme.name === 'regular' ? FontSize.Medium : FontSize.Small)};
  font-family: ${({ theme }) => (theme.name === 'regular' ? FontFamily.Secondary : FontFamily.Primary)};
  font-weight: ${FontWeight.Bold};
  line-height: 1.5;
  margin-top: ${(props: H2TypographyProps) => (props.cell ? 0 : Spacing.Large)};
  margin-bottom: ${(props: H2TypographyProps) => (props.cell ? 0 : Spacing.Small)};
`;

export const NoWrap = styled.span`
  white-space: nowrap;
`;
export const H2 = styled.h2`
  ${H2Style}
`;
H2.displayName = 'H2';
export const FeaturedH2 = styled(H2)`
  text-align: center;
  margin-top: ${Spacing.XXLarge};
  margin-bottom: ${Spacing.Large};

  :after {
    display: block;
    content: '';
    width: 50px;
    height: 4px;
    border-radius: 2px;
    background: ${(props: TypographyProps) => (props.kind === 'light' ? GrayColor.White : LightColor.Primary)};
    margin: ${Spacing.XSmall} auto ${Spacing.Medium};
  }
`;

export interface H3TypographyProps extends TypographyProps {
  cell?: boolean;
}

export const H3Height = FontSize.Small;
export const H3Style = css<H3TypographyProps>`
  color: ${getTextColorHOF()};
  line-height: 1.5;
  font-size: ${H3Height};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.SemiBold};
  margin-top: ${(props) => (props.cell ? '0px' : Spacing.Large)};
  margin-bottom: ${(props) => (props.cell ? '0px' : Spacing.Medium)};
  :first-child {
    margin-top: 0;
  }
`;
export const H3 = styled.h3<H3TypographyProps>`
  ${H3Style}
  text-align: ${(props) => (props.center ? 'center' : 'inherit')};
`;
H3.displayName = 'H3';

export interface H4Props extends TypographyProps {
  /** whether this h4 is being used inside a cell */
  cell?: boolean;
}

export const H4Height = FontSize.XSmall;
export const H4Style = css<H4Props>`
  color: ${getTextColorHOF()};
  text-align: ${(props: TypographyProps) => (props.center ? 'center' : 'inherit')};
  line-height: 1.5;
  font-size: ${H4Height};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Bold};
  margin-top: ${(props) => (props.cell ? 0 : Spacing.Small)};
  margin-bottom: ${(props) => (props.cell ? 0 : Spacing.XSmall)};
`;
export const H4 = styled.h4<H4Props>`
  ${H4Style}
`;
H4.displayName = 'H4';

export const H5 = styled.h5<TypographyProps>`
  color: ${(props) => getTextColorHOF(props.color, props.color)};
  text-align: ${(props: TypographyProps) => (props.center ? 'center' : 'inherit')};
  line-height: 1.5;
  font-size: ${FontSize.XXSmall};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.SemiBold};
  margin: 0;
`;

export const BodyStyle = css<TypographyProps>`
  color: ${(props) => (props.kind === 'default' ? getTextColorHOF(GrayColor.GrayDark, GrayColor.GrayDark) : getTextColorHOF())};
  text-align: ${(props) => (props.center ? 'center' : 'inherit')};
  margin: 0;
  font-size: ${FontSize.XSmall};
  line-height: 1.5;
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
`;

export const Body = styled.p`
  ${BodyStyle}
  white-space: pre-line;
  & + & {
    margin-top: ${Spacing.Medium};
  }
`;
Body.displayName = 'Body';

export const EmojiStyle = styled.span`
  font-family: sans-serif;
`;
export const Headline = styled(Body)`
  color: ${getTextColorHOF(GrayColor.Gray, GrayColor.GrayXLight)};
`;

// https://www.w3schools.com/cssref/pr_print_pageba.asp
export const PageBreakStyled = styled.div`
  @media print {
    page-break-after: always;
  }
`;

export const StrikeThrough = styled.span`
  text-decoration: line-through;
`;
export const bodySecondaryLineHeight = 1.5;
export const bodySecondaryFontSize = FontSize.XSmall;
export const BodySecondary = styled.p<TypographyProps>`
  color: ${getTextColorHOF()};
  font-size: ${bodySecondaryFontSize};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
  line-height: ${bodySecondaryLineHeight};
  margin: 0;
`;

export const Caption = styled.p<TypographyProps>`
  color: ${getTextColorHOF(GrayColor.Gray)};
  font-size: ${FontSize.XXSmall};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
  font-style: italic;
  margin: 0;
  text-align: ${(props: TypographyProps) => (props.center ? 'center' : 'inherit')};
`;

export const BodySmall = styled.label<TypographyProps>`
  color: ${getTextColorHOF()};
  font-size: ${FontSize.XXXSmall};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
  margin: 0;
`;

export const SemiBold = styled.span`
  font-weight: ${FontWeight.SemiBold};
`;

export const BodySecondaryCentered = styled(BodySecondary)`
  text-align: center;
`;

/** https://fontawesome.com/how-to-use/on-the-web/styling/icons-in-a-list */
export const FaUL = styled.ul.attrs({ className: 'fa-ul' })``;
export const FaLI = styled.li`
  padding-left: ${Spacing.Small};
  & + & {
    margin-top: ${Spacing.Medium};
  }
  & > .fa-li {
    top: 3px;
  }
`;

export const DT = styled.dt`
  display: inline;
  color: ${GrayColor.Gray};
  line-height: 1.5;
  font-size: ${FontSize.XSmall};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
`;

export const DD = styled.dd`
  display: inline;
  color: ${GrayColor.GrayDark};
  font-size: ${FontSize.XSmall};
  line-height: 1.5;
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.SemiBold};
  margin: 0;
`;

export const InputLabel = styled.label<TypographyProps>`
  color: ${getTextColorHOF()};
  display: inline-block;
  font-size: ${FontSize.XSmall};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
  line-height: 1.33;
  margin-left: 0;
  margin-bottom: 0;
  margin-top: ${({ theme }) => (theme.name === 'dark' ? Spacing.Small : 0)};
  & > strong {
    color: ${({ theme }) => (theme.name === 'dark' ? GrayColor.GrayXXLight : GrayColor.Black)};
  }
`;

export const BigInputLabel = styled(InputLabel)`
  margin-bottom: ${Spacing.Medium};
  font-size: ${FontSize.Small};
  @media all and (min-width: 48em) {
    font-size: ${FontSize.Medium};
  }
`;

export const InputValueCss = css`
  color: ${getTextColorHOF(GrayColor.Black)};
  font-size: ${FontSize.XSmall};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
`;

export const InputValue = styled.label`
  ${InputValueCss};
`;

export const InputDisabledCss = css`
  color: ${GrayColor.Gray};
  font-size: ${FontSize.XSmall};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};

  /* https://stackoverflow.com/a/45209441/3670829 */
  -webkit-text-fill-color: ${GrayColor.Gray};
  -webkit-opacity: 1;
`;

export const InputDisabled = styled.label`
  ${InputDisabledCss};
`;

export const InputCaption = styled.label`
  color: ${GrayColor.GrayXDark};
  font-size: ${FontSize.XXSmall};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.SemiBold};
  margin-top: ${Spacing.XSmall};
  display: block;
`;

export const InputCaptionError = styled(InputCaption)`
  margin-top: ${Spacing.Small};
  margin-left: 0;
  color: ${(props) => props.theme.color.alert};
  font-size: ${FontSize.XXSmall};
`;

export const InputPlaceholderCss = css`
  color: ${getTextColorHOF(GrayColor.Gray, GrayColor.GrayLight)};
  font-size: ${FontSize.XSmall};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
`;

export const InputPlaceholder = styled.label`
  pointer-events: none;
  ${InputPlaceholderCss};
`;

export const ProductPricePrefix = styled.span`
  color: ${GrayColor.GrayDark};
  font-weight: ${FontWeight.Bold};
`;

export interface PriceProps extends TypographyProps {
  isFeatured?: boolean;
}

export const ProductPrice = styled.p<PriceProps>`
  color: ${(props) => (props.isFeatured ? LightColor.Alert : props.theme.color.secondary)};
  text-align: ${(props) => (props.center ? 'center' : 'inherit')};
  font-size: ${FontSize.XLarge};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.SemiBold};
`;
export const ProductSpan = styled.span`
  font-size: ${FontSize.Medium};
`;

export const ProductOldPrice = styled.label<{ center?: boolean } & TypographyProps>`
  color: ${getTextColorHOF(GrayColor.GrayDark)};
  text-decoration: line-through;
  text-align: ${(props) => (props.center ? 'center' : 'inherit')};
  height: ${Spacing.Medium};
  font-size: ${FontSize.XXSmall};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
`;

export const Mark = styled.mark`
  background: ${(props) => props.color || BrandColor.Yellow};
`;

export const PriceTagOffer = styled.span`
  margin: auto;
  color: ${(props) => (props.theme.name === 'dark' ? GrayColor.GrayDark : GrayColor.White)};
  background-color: ${(props) => (props.theme.name === 'dark' ? GrayColor.White : props.theme.color.secondary)};
  font-size: ${FontSize.XSmall};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
  line-height: 1.5;
  padding: ${Spacing.XSmall} ${Spacing.Medium};
  border-radius: ${Border.Radius};
`;

// Utilities

export const Ellipsed = styled.span`
  max-width: 100%;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Center = styled.div`
  text-align: center;
`;
