import { QueryHookOptions, TypedDocumentNode, useQuery } from '@apollo/client';
import { Query_Root } from '@global/utils/remote-graphql-types';
import { logError } from '@web/utils/error';
import { NativeHelper } from './native.helper';

export const useQueryCustom = <D = Query_Root, V = Record<string, any>>(
  query: TypedDocumentNode<D, V>,
  options?: QueryHookOptions<D, V>
) => {
  const customOptions: QueryHookOptions<D, V> = {
    ssr: false,
    onError: (error) => {
      logError(error);
      NativeHelper.postMessage({ type: 'error', data: { error } });
    },
  };
  return useQuery(query, Object.assign(customOptions, options));
};
