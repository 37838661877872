// BUSINESS_RULE: we use your sleep efficiency calculation to adjust
// your time in bed. If your sleep efficiency is too high (> 0.90), you
// probably aren’t getting enough sleep, and should increase your time in
// bed by 30 minutes (== Good). If your sleep efficiency is too low (< 0.85), you
// should decrease your time in bed (== Bad). Otherwise, don’t change anything (== Ok).
export enum SleepEfficiencyAnalysis {
  Good,
  Ok,
  Bad,
}

export class SleepEfficiency {
  constructor(private value: number) {}

  get analysis(): SleepEfficiencyAnalysis {
    if (this.value < 85) {
      return SleepEfficiencyAnalysis.Bad;
    }

    if (this.value < 90) {
      return SleepEfficiencyAnalysis.Ok;
    }

    return SleepEfficiencyAnalysis.Good;
  }
}
