/* eslint-disable @typescript-eslint/no-explicit-any */
import { ErrorName } from '@global/utils/api/error-name';
import { ChatfuelBroadcastRequest } from '@global/utils/chat/chatfuel';
import { ChainOfResponsibilityAbstractHandler, ChainOfResponsibilityHandler } from '@global/utils/design-patterns/chain-of-responsibility';
import { NativeMessage } from '@global/utils/native/native-message';
import { NativeHelper } from '@web/data/native.helper';
import fetch from 'isomorphic-fetch';
import QueryString from 'querystringify';

export enum FetchUrl {
  config = '/schedule-config',
  isUrlAvailable = '/is-url-available',
}

export enum PostUrl {
  UpdateUser = '/users',
  NotifyUser = '/user/notify',
  SleepDiary = '/sleep-diary',
  HealthCarer = '/health-carer',
  RedirectUser = '/chatfuel/broadcast',
  UpdateHealthCarer = '/update-health-carer',
}

export const ErrorMessageThatCanBeShownToUser = new Set([
  ErrorName.CreateHealthCarerUseCaseDuplicateEmailError,
  ErrorName.CreateHealthCarerUseCaseInvalidCRMError,
  ErrorName.HealthCarerUseCaseInvalidDuplicatedProfessionIdError,
]);

export interface FetchDataSourceInput<TData = any> {
  method: 'get' | 'post';
  data?: TData;
  params?: any;
}

export interface HttpDataSource {
  fetch<TReturn, TData = undefined>(path: string, input: FetchDataSourceInput<TData>): Promise<TReturn>;
  post<TData = any, TReturn = any>(url: string, data?: TData): Promise<TReturn>;
  get<TParams = any, TReturn = any>(url: string, param?: TParams): Promise<TReturn>;
}

export interface HttpAuthHeader {
  getAuthHeader: () => Promise<any>;
}

/**
 * Use this component when you need a flexible GET, POST datasource
 * Tradeoffs:
 *  - PRO: flexibility
 *  - CONS: the response and params will NOT be typed (but you can use interfaces to help with this)
 */
export class VigilantesDataSource implements HttpDataSource {
  private handlerChain: ChainOfResponsibilityHandler<Req, Res>;

  constructor(vigilantesDataSourceAuthHeader: HttpAuthHeader) {
    this.handlerChain = new RedirectHandler();
    this.handlerChain.setNext(new FetchRemoteHandler(vigilantesDataSourceAuthHeader));
  }

  fetch<TReturn, TData = undefined>(path: string, input: FetchDataSourceInput<TData>): Promise<TReturn> {
    return this.handlerChain.handle({ path, input });
  }

  post = <TData = any, TReturn = any>(url: string, data?: TData): Promise<TReturn> => {
    return this.fetch(url, {
      data,
      method: 'post',
    });
  };

  get = <TParams = any, TReturn = any>(url: string, params?: TParams): Promise<TReturn> => {
    return this.fetch(url, { method: 'get', params });
  };
}

////////////////////////////////////////////////////////////////////////////////////////////////////
// https://developers.google.com/web/updates/2015/03/introduction-to-fetch
function status(response) {
  if (response.status >= 200 && response.status < 300) {
    return Promise.resolve(response);
  }
  return Promise.reject(response);
}
function json(response) {
  return response.json();
}

async function error(response) {
  const json = await response.json();
  console.log(`error -> json`, json);
  throw new HttpError(response.statusText, response.status, json);
}

class HttpError extends Error {
  data: any;
  status: number;

  constructor(message: string, status: number, data: any) {
    super(message);
    this.name = ErrorName.HttpError;
    this.status = status;
    this.data = data;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////

interface HandlerRequest<TData> {
  path: string;
  input: FetchDataSourceInput<TData>;
}

type Req<TData = any> = HandlerRequest<TData>;
type Res = any;

/**
 * Performs a http request using fetch
 */
class FetchRemoteHandler extends ChainOfResponsibilityAbstractHandler<Req, Res> {
  constructor(private vigilantesDataSourceAuthHeader: HttpAuthHeader) {
    super();
  }

  async handle(request: Req): Promise<Res> {
    const { path, input } = request;
    const queryParams = input.params ? `?${QueryString.stringify(input.params)}` : '';
    const url = `${path.startsWith('http') ? '' : process.env.GATSBY_VIGILANTES_BASE_URL}${path}${queryParams}`;

    const authHeader = await this.vigilantesDataSourceAuthHeader.getAuthHeader();
    const options = {
      method: input.method,
      headers: {
        ...authHeader,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: input.data ? JSON.stringify(input.data) : undefined,
    };
    return fetch(url, options).then(status).then(json).catch(error);
  }
}

/**
 * Tries to perform local request (inside a react-native app)
 */
class RedirectHandler extends ChainOfResponsibilityAbstractHandler<Req<ChatfuelBroadcastRequest>, Res> {
  async handle(request: Req<ChatfuelBroadcastRequest>): Promise<Res> {
    const { path } = request;
    if (!path?.includes(PostUrl.RedirectUser) || !NativeHelper.isNative) {
      return super.handle(request);
    }
    const message: NativeMessage = { type: 'chatfuelBroadcast', data: request.input.data };
    return NativeHelper.postMessage(message);
  }
}
