import { Border, GrayColor, LightColor, } from '@web/atomic/legacy/obj.constants';

import styled from 'styled-components';

export const LightDivisor = styled.div`
  height: 4px;
  width: 100%;
  background-color: ${GrayColor.GrayXXLight};
`;

export const Divisor = styled.div`
  height: ${Border.Width};
  width: 100%;
  background-color: ${Border.Color};
`;
