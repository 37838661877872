/* eslint-disable @typescript-eslint/no-var-requires */
import { BrandColor, GrayColor, LightColor, FontFamily, FontSize, FontWeight } from '@web/atomic';
import styled from 'styled-components';

type ThumbIconSize = 'small' | 'large';
type ThumbIconColor = 'yellow' | 'blue';

export interface ThumbIconStyledProps {
  size: ThumbIconSize;
  color: ThumbIconColor;
}

const large = '64px';
const small = '32px';

export const ThumbIconStyled = styled.div<ThumbIconStyledProps>`
  width: ${(props) => (props.size === 'small' ? small : large)};
  height: ${(props) => (props.size === 'small' ? small : large)};
  background-color: ${(props) => (props.color === 'yellow' ? BrandColor.Cream : GrayColor.GrayXXLight)};
  position: relative;
  text-align: center;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
`;

export const ThumbIconTextStyled = styled.span<ThumbIconStyledProps>`
  color: ${(props) => (props.color === 'yellow' ? BrandColor.Brown : BrandColor.PortGore)};
  font-size: ${(props) => (props.size === 'small' ? FontSize.XXSmall : FontSize.Small)};
  position: relative;
  width: 100%;
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.SemiBold};
  letter-spacing: 1px;
`;
