import React from 'react';

/**
 * https://github.com/welldone-software/why-did-you-render#setup
 * TIP: comment the following code to disable why-did-you-render
 */
if (process.env.NODE_ENV === 'development') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  });
}

import {
  getIdFromHealthCarerPatientReportUrl,
  HealthCarerPatientReportRoute,
  HealthCarerPatientReportRouteUrl,
} from '@global/utils/domain/health-carer/patient-report-url';
import { urlMatchRoute } from '@global/utils/url/url-match-route';
import { createUrlWithParams } from '@global/utils/url/create-url-with-params';
import * as Sentry from '@sentry/browser';
import 'microtip/microtip.css';
import * as ReactDOM from 'react-dom';
import App from './App';

try {
  Sentry.init({
    dsn: process.env.GATSBY_SENTRY_DSN_URL,
    release: 'health-carer@' + process.env.npm_package_version,
    attachStacktrace: true,
    beforeSend(event) {
      const url = event.request?.url;
      // scrub name from url
      if (urlMatchRoute(url, HealthCarerPatientReportRouteUrl)) {
        event.request.url = createUrlWithParams<HealthCarerPatientReportRoute>(HealthCarerPatientReportRouteUrl, {
          user: `${getIdFromHealthCarerPatientReportUrl(url)}`,
        });
      }
      return event;
    },
  });
} catch (e) {
  console.warn('e', e);
}

ReactDOM.render(<App />, document.getElementById('root'));
