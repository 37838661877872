import { twoDigit } from '../number/two-digit';

/**
 * Map minutes to hour (dd:mm)
 */
export const toHour = (minutes: number): string => {
  const day = 24 * 60;
  const positiveMinutes = minutes > 0 ? minutes : minutes + day;

  const hour = Math.floor(positiveMinutes / 60);
  const h = twoDigit(hour % 24);
  const m = twoDigit(Math.round(positiveMinutes) % 60);
  return `${h}:${m}`;
};

export const toHourString = (date: Date): string => {
  const h = twoDigit(date.getHours());
  const m = twoDigit(date.getMinutes());
  return `${h}:${m}`;
};
