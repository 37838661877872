export const isNullOrUndefined = (obj: unknown) => obj === null || obj === undefined;
export const isNotNullNorUndefined = (obj: unknown) => !isNullOrUndefined(obj);

export const removeNullOrUndefinedValue = (obj: object) => {
  Object.keys(obj).forEach((k) => {
    if (isNullOrUndefined(obj[k])) {
      delete obj[k];
    }
  });
};

// https://stackoverflow.com/a/38340374/3670829
export const replaceUndefined = (obj: unknown) =>
  Object.keys(obj).forEach((key) => {
    if (obj[key] === undefined) obj[key] = null;
  });
