import { Gender } from '../domain/entities';
import { HealthCarerKind } from '../domain/health-carer/health-carer-factory';

export const PostHealthCarerNotifyUrl = '/health-carer/notify';
export interface PostHealthCarerNotifyRequest {
  messengerId: string;
  subject: string;
  content: string;
}

////////////////////////////////////////////////////////////////////////////////////////////////////

export const PostHealthCarerUserUrl = '/health-carer-user';
export interface PostHealthCarerUserRequest {
  health_carer_id: string;
  user_id: string;
}
export interface PostHealthCarerUserResponse {
  healthCarer: {
    name: string;
    kind: HealthCarerKind;
    gender: Gender;
  };
}

////////////////////////////////////////////////////////////////////////////////////////////////////
export const HealthCarerForPaymentGatewayUrl = '/health-carer-for-payment-gateway';
export interface PostHealthCarerForPaymentGatewayRequest {
  health_carer_id: string;
  name: string;
  email: string;
  document: string;
  bank: string;
  branch_number: string;
  branch_check_digit: string;
  account_number: string;
  account_check_digit: string;
  account_type: 'checking' | 'savings';
}
export type PostHealthCarerForPaymentGatewayResponse = unknown;

////////////////////////////////////////////////////////////////////////////////////////////////////

export const InsertHealthCarerUserUrl = '/health-carer';
export interface InsertHealthCarerUserRequest {
  email: string;
  password: string;
  displayName: string;
  kind: HealthCarerKind;
  gender: Gender;
  professional_id?: string;
  professional_id_state?: string;
  whatsapp?: string;
}
export interface InsertHealthCarerUserResponse {
  uid: string;
  blockId?: string;
  fromPath?: string;
}

////////////////////////////////////////////////////////////////////////////////////////////////////

export const UpdateHealthCarerUserUrl = '/update-health-carer';

export interface UpdateHealthCarerUserRequest {
  hcpId: string;
  gender: Gender;
  whatsapp?: string;
  name: string;
  /**
   * TODO B2C: bankAccount should be removed with split payment;
   */
  bankAccount?: {
    holder_document: string;
    bank: string;
    branch_number: string;
    branch_check_digit: string;
    account_number: string;
    account_check_digit: string;
    account_type: 'checking' | 'savings';
  };
}

export interface UpdateHealthCarerUserResponse {
  name: string;
  gender: string;
  whatsapp?: string;
}

////////////////////////////////////////////////////////////////////////////////////////////////////

export const HealthCarerBalanceUrl = '/health-carer/balance';
export interface HealthCarerBalanceRequest {
  id: string;
}
export interface HealthCarerBalanceResponse {
  available_amount: number;
  waiting_funds_amount: number;
  transferred_amount: number;
}

////////////////////////////////////////////////////////////////////////////////////////////////////

export const HealthCarerMoneyWithdrawUrl = '/health-carer/money-withdraw';
export interface HealthCarerMoneyWithdrawRequest {
  user_id: string;
  amount: number;
}

export interface HealthCarerMoneyWithdrawResponse {
  message: string;
  withdraw_id: string | number;
}

////////////////////////////////////////////////////////////////////////////////////////////////////
export interface CouponFormatedResponse {
  id: string;
  name: string;
  kind: string;
  coupon: string;
}

export const PostSearchHealthCarerUrl = '/search-health-carer';
export interface PostSearchHealthCarerRequest {
  search: string;
}
export interface PostSearchHealthCarerResponse {
  result: Array<{ hcpName: string; coupon: string; hcpKind?: HealthCarerKind }>;
}
