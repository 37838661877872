import { Hbox, LightColor, Separator } from '@web/atomic';
import SvgIcLogo from '@web/atomic/atm.svg-icon/ic-logo.component';
import * as React from 'react';
import { BlobStyled, LoginBoxContentStyled, LoginBoxStyled, LoginLogoTitleStyled } from './form-wrapper-with-header.style';

interface FormWrapperWithHeaderProps {
  title: string;
}

const FormWrapperWithHeader: React.FunctionComponent<FormWrapperWithHeaderProps> = (props) => {
  return (
    <LoginBoxStyled>
      <BlobStyled>
        <Separator />
        <Separator />
        <Hbox hAlign="center">
          <Hbox.Item wrap vAlign={'center'}>
            <SvgIcLogo id="ga-footer-logo-svg-desktop" color={LightColor.Primary} height={32} />
          </Hbox.Item>
        </Hbox>
        <Separator />
        <Hbox hAlign="center">
          <Hbox.Item wrap vAlign={'center'}>
            <LoginLogoTitleStyled> {props.title} </LoginLogoTitleStyled>
          </Hbox.Item>
        </Hbox>
        <Separator />
        <Separator />
      </BlobStyled>
      <LoginBoxContentStyled>{props.children}</LoginBoxContentStyled>
    </LoginBoxStyled>
  );
};

export default FormWrapperWithHeader;
