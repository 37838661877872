import gql from 'graphql-tag';

export const PatientListQuery = gql`
  query PatientListQuery($id: String!) {
    fake_user: user_by_pk(id: "teste") {
      ...userFragment
    }
    health_carer_by_pk(id: $id) {
      id
      name
      users {
        user {
          ...userFragment
        }
      }
    }
  }
  fragment userFragment on user {
    id
    current_journey_id
    first_name
    last_name
    profile_picture
    created_at
    updated_at
    paid
    sleep_diaries(limit: 1, order_by: { date: desc }) {
      date
    }
    sleep_diaries_aggregate {
      aggregate {
        count
      }
    }
    user_journeys {
      journey_id
    }
    user_program_sessions(order_by: { created_at: desc }) {
      session_id
      state
      created_at
    }
    ratings(limit: 1, order_by: { created: desc }) {
      created
    }
  }
`;
