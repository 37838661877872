import { Sleep_Restriction } from '../remote-graphql-types';
import { dateDiffInDays } from '../date/date-diff-in-days';
import { parseToDate } from '../date/parse-to-date';
import { ChatfuelBlockNames } from '../chat/chatfuel-blocks-types';
import { SleepDiaryWithAnalysis } from './diary-analysis';
import { SleepRestrictionMinDurationInDays, SleepRestrictionMinSleepDiaryQuantity } from './entities';
import { isSameDay } from '../date/is-same-day';

export class SleepRestriction {
  static getSleepRestrictionSessionInfo(): { id: number; initialBlock: ChatfuelBlockNames }[] {
    return [
      { id: null, initialBlock: null },
      { id: 17, initialBlock: 's17_b0' },
      { id: 24, initialBlock: 's24_b0' },
      { id: 31, initialBlock: 's31_b0' },
      { id: 38, initialBlock: 's38_b0' },
      { id: 45, initialBlock: 's45_b0' },
      { id: 55, initialBlock: 's55_b0' },
    ];
  }

  /**
   *
   * @param sleepRestrictionEnabled whether sleep restriction is enabled
   * @param userPaid whether user has paid
   * @param sleepRestrictionCount number of sleep_restrictions the user has made
   * @param lastSleepRestrictionStartDate start date of the last sleep restriction the user started (a.k.a the current sleep restriction)
   * @param sleepDiaries a list of sleep diaries date (it is enough to send the last `SleepRestrictionMinSleepDiaryQuantity` diaries) @see SleepRestrictionMinSleepDiaryQuantity
   */
  static isSleepRestrictionSessionAvailable(
    sleepRestrictionEnabled: boolean,
    userPaid: boolean,
    sleepRestrictionCount: number,
    lastSleepRestrictionStartDate: string,
    sleepDiaries: { date: string }[]
  ): { isAvailable: boolean; startBlock?: ChatfuelBlockNames; diariesLeftToEnable?: number } {
    // BUSINES_RULE: verificar se usuário tem o mínimo de diários nos últimos dias desde data de início
    if (!userPaid || !sleepRestrictionEnabled) {
      return {
        isAvailable: false,
      };
    }

    const nextSleepRestrictionBlockName = SleepRestriction.getSleepRestrictionSession(sleepRestrictionCount);
    if (!nextSleepRestrictionBlockName) {
      return {
        isAvailable: false,
      };
    }

    const sleepRestrictionStartDate = parseToDate(lastSleepRestrictionStartDate);

    const daysLeft = Math.max(SleepRestrictionMinDurationInDays - dateDiffInDays(sleepRestrictionStartDate, new Date()), 0);
    if (daysLeft > 0) {
      return {
        isAvailable: false,
        diariesLeftToEnable: daysLeft,
      };
    }

    const sleepDiariesInSleepRestriction = sleepDiaries.filter(
      (diary) => dateDiffInDays(sleepRestrictionStartDate, parseToDate(diary.date)) > 0
    );

    const dairiesLeft = Math.max(SleepRestrictionMinSleepDiaryQuantity - sleepDiariesInSleepRestriction.length, 0);
    if (dairiesLeft > 0) {
      return {
        isAvailable: false,
        diariesLeftToEnable: dairiesLeft,
        startBlock: null,
      };
    }

    return {
      isAvailable: true,
      diariesLeftToEnable: 0,
      startBlock: nextSleepRestrictionBlockName,
    };
  }

  private static getSleepRestrictionSession(restriction: number): ChatfuelBlockNames {
    const sleepRestrictionSessionInfo = SleepRestriction.getSleepRestrictionSessionInfo();
    const restrictionInfo = sleepRestrictionSessionInfo[restriction];
    if (restrictionInfo) {
      return restrictionInfo.initialBlock;
    } else {
      return sleepRestrictionSessionInfo[sleepRestrictionSessionInfo.length - 1].initialBlock;
    }
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * * BUSSINESS RULE
 *
 * This enum specifies the margins **in minutes**
 * of the intervals around the recommended time
 * for the sleep restriction.
 *
 * Ex: if the recommended time to sleep is 20:00
 *     there must be an interval for which the
 *     time would be considered "right"
 *
 *     [20:00 - beforeRecommendedTimeToSleep, 20:00 + afterRecommendedTimeToSleep ]
 *
 */
export enum GetUpAndGoToBedSleepRestrictionMargins {
  beforeRecommendedTimeToSleep = 30,
  afterRecommendedTimeToSleep = 60,
  beforeRecommendedTimeToGetUp = 60,
  afterRecommendedTimeToGetUp = 30,
}

////////////////////////////////////////////////////////////////////////////////////////////////////

export interface SleepRestrictionCycles {
  name: string;
  startDate: Date;
  endDate: Date;
  suggestedTTC?: number;
  data: SleepDiaryWithAnalysis[];
}

export function getSleepRestrictionCycles(
  sleepRestrictions: Pick<Sleep_Restriction, 'start_date' | 'new_time_in_bed'>[],
  reportDiaries: SleepDiaryWithAnalysis[]
): SleepRestrictionCycles[] {
  if (!reportDiaries || !reportDiaries.length) return [];
  const firstDiaryDate = new Date(reportDiaries[0].entryDate);
  firstDiaryDate.setDate(firstDiaryDate.getDate() - 1);
  const restrictionDates = sleepRestrictions ? sleepRestrictions.map((item) => parseToDate(item.start_date)) : [];
  const today = new Date();
  const dates = [firstDiaryDate, ...restrictionDates, today];
  const intervals: SleepRestrictionCycles[] = createEmptyIntervals(dates, sleepRestrictions);
  if (!intervals.length) return intervals;
  fillIntervals(reportDiaries, intervals);
  return intervals;
}

function createEmptyIntervals(dates: Date[], sleepRestrictions: Pick<Sleep_Restriction, 'new_time_in_bed'>[]): SleepRestrictionCycles[] {
  return dates.reduce((acc, curr, index) => {
    const startDate = curr;
    const endDate = dates[index + 1];
    if (!startDate || !endDate) return acc;
    const name = index === 0 ? 'Basal' : index === dates.length - 2 ? 'Atual' : `Ciclo ${index}`;
    const suggestedTTC = sleepRestrictions?.[index - 1]?.new_time_in_bed || null;
    acc.push({ name, startDate, endDate, suggestedTTC, data: [] });
    return acc;
  }, []);
}

function fillIntervals(reportDiaries: SleepDiaryWithAnalysis[], intervals: SleepRestrictionCycles[]) {
  reportDiaries.forEach((item) => {
    let inserted = false;
    let index = 0;
    while (!inserted) {
      const interval = intervals[index];
      if (!interval) {
        console.error('ERROR: sleep-restriction.ts ~ line 129 ~ reportDiaries.forEach ~ null interval for', index, item);
        return;
      }
      const endDate = interval.endDate;
      if (item.entryDate < endDate || isSameDay(item.entryDate, endDate)) {
        interval.data.push(item);
        inserted = true;
      } else {
        index += 1;
      }
    }
  });
}

////////////////////////////////////////////////////////////////////////////////////////////////////

export interface SleepRestrictionAnalysis {
  name: string;
  suggestedTTC?: number;
  start: Date;
  end: Date;
  daysCount: number;
  filledDaysCount: number;
  avgMinutesInBed: number;
  avgMinutesSleep: number;
  avgEficiency: number;
  daysWithMedicationCount: number;
  daysWithWakeCount: number;
  // latência > 30
  daysWithHighLatency: number;
  // waso > 30
  daysWithHighWaso: number;
}

export function mapToSleepRestrictionAnalysis(interval: SleepRestrictionCycles, hideLogs = false): SleepRestrictionAnalysis {
  const name = interval.name;
  const suggestedTTC = interval.suggestedTTC;
  const start: Date = interval.startDate;
  const end: Date = interval.endDate;
  const daysCount: number = dateDiffInDays(start, end);

  const data = interval.data.reduce(
    (acc, curr) => {
      const { minutesInBed, minutesSleep, eficiency, usedMedication } = curr;
      if (minutesInBed) {
        acc.filledDaysCount += 1;
      }
      if (minutesInBed) {
        if (minutesInBed > 20 * 60 && !hideLogs) {
          console.log('sleep-restriction.ts ~ line 180: Invalid minutesInBed:', minutesInBed, interval, curr);
        }
        acc.avgMinutesInBed += minutesInBed;
      }

      if (curr.totalTimeToSleep) {
        acc.avgLatency += curr.totalTimeToSleep;
      }

      if (curr.wake_up_duration) {
        acc.avgWaso += curr.wake_up_duration;
      }

      if (minutesSleep) {
        if (minutesSleep > 16 * 60 && !hideLogs) {
          console.log('sleep-restriction.ts ~ line 186: Invalid minutesSleep:', minutesSleep, interval, curr);
        }
        acc.avgMinutesSleep += minutesSleep;
      }
      if (eficiency) {
        if ((eficiency < 0 || eficiency > 100) && !hideLogs) {
          console.log('sleep-restriction.ts ~ line 192: Invalid eficiency:', eficiency, interval, curr);
        }
        acc.avgEficiency += eficiency;
      }
      if (usedMedication) {
        acc.daysWithMedicationCount += 1;
      }
      if (curr.wake_up_count) {
        acc.daysWithWakeCount += 1;
      }
      if (curr.totalTimeToSleep > 30) {
        acc.daysWithHighLatency += 1;
      }
      if (curr.wake_up_duration > 30) {
        acc.daysWithHighWaso += 1;
      }

      return acc;
    },
    {
      filledDaysCount: 0,
      avgMinutesInBed: 0,
      avgMinutesSleep: 0,
      avgEficiency: 0,
      avgLatency: 0,
      avgWaso: 0,
      daysWithMedicationCount: 0,
      daysWithWakeCount: 0,
      daysWithHighLatency: 0,
      daysWithHighWaso: 0,
    }
  );

  if (interval.data.length) {
    data.avgMinutesInBed /= data.filledDaysCount;
    data.avgMinutesSleep /= data.filledDaysCount;
    data.avgEficiency /= data.filledDaysCount;
    data.avgLatency /= data.filledDaysCount;
    data.avgWaso /= data.filledDaysCount;
  }

  return {
    name,
    suggestedTTC,
    start,
    end,
    daysCount,
    ...data,
  };
}
