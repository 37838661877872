import { CHART_COLORS } from '@component/charts/utils';
import * as React from 'react';
import { ReferenceLine } from 'recharts';

export interface ICycleReferenceLineProps {
  yAxisId?: string;
}

export const get30minReferenceLine = (props: ICycleReferenceLineProps = {}): React.ReactElement => {
  return (
    <ReferenceLine
      y={30}
      yAxisId={props.yAxisId}
      stroke={CHART_COLORS[1]}
      strokeWidth={1}
      strokeDasharray="5 5"
      label={(<CustomLabel name={'30 minutos'} />) as any}
    />
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////
interface ICustomLabelProps {
  // http://recharts.org/en-US/api/ReferenceLine#viewBox
  viewBox?: {
    x: number;
    y: number;
  };
  name: string;
}

const CustomLabel: React.FunctionComponent<ICustomLabelProps> = (props) => {
  return (
    <text
      x={props.viewBox.x}
      y={props.viewBox.y}
      className={`recharts-text recharts-label recharts-30min-reference-line`}
      textAnchor="left"
    >
      <tspan x={props.viewBox.x + 4} dy="-0.25em">
        {props.name}
      </tspan>
    </text>
  );
};
