import { isNotNullNorUndefined } from '@global/utils/object/null-or-undefined';
import { NativeHelper } from '@web/data/native.helper';
import ThemeDataSource from '@web/data/theme.datasource';
import React, { createContext, useEffect, useState } from 'react';
import { useQueryParams } from '../obj.custom-hooks/query-params';
import { darkTheme, regularTheme } from './theme';
import { BaseTheme, ThemeName } from './theme.interface';

interface ThemeQueryParams {
  dark?: boolean;
}

interface ThemeContextValue {
  theme: BaseTheme;
  toggle?: () => void;
  setNextTheme?: (name: ThemeName) => void;
}

export const AppThemeContext = createContext<ThemeContextValue>({ theme: regularTheme });

interface IThemeContainerProps {
  /**
   * Depending on the app context, the "theme" feature may not be available
   * (for instances, some urls shouldn't show a theme switch) and the "regular"
   * theme will be used. Use this flag to force the theme feature
   */
  forceThemeEnabled?: boolean;
  defaultTheme?: BaseTheme;
  children: (theme: BaseTheme) => JSX.Element;
}

/**
 * Urls (or part of it) in which the "theme" feature is available
 * If not available, then the "regular" theme will be used
 */
const ThemeWhitelist = ['/chatfuel/', '/artigo/', '/assinatura-app', '/teleatendimento/'];

export const ThemeContainer: React.FunctionComponent<IThemeContainerProps> = (props) => {
  const [theme, setTheme] = useState(regularTheme);
  usetSetInitialTheme(props.forceThemeEnabled, setTheme);
  usePostMessageToNativeSide(theme);
  const toggle = () => {
    setTheme((prev) => {
      const nextTheme = prev.name === 'regular' ? darkTheme : regularTheme;
      ThemeDataSource.themeName = nextTheme.name;
      return nextTheme;
    });
  };
  const setNextTheme = (name: ThemeName) => {
    const nextTheme = name === 'regular' ? regularTheme : darkTheme;
    ThemeDataSource.themeName = nextTheme.name;
    setTheme(nextTheme);
  };
  useEffect(() => {
    document.getElementsByTagName('html')[0].setAttribute('class', 'with-theme');
  }, []);

  return <AppThemeContext.Provider value={{ theme, toggle, setNextTheme }}>{props.children(theme)}</AppThemeContext.Provider>;
};

ThemeContainer.defaultProps = {
  defaultTheme: regularTheme,
};

////////////////////////////////////////////////////////////////////////////////////////////////////
function usetSetInitialTheme(forceThemeEnabled: boolean, setTheme: React.Dispatch<React.SetStateAction<BaseTheme>>) {
  const queryParams = useQueryParams<ThemeQueryParams>();
  const dark = queryParams.dark;

  useEffect(() => {
    if (forceThemeEnabled || ThemeWhitelist.some((item) => window.location.href.includes(item))) {
      const savedThemeName = ThemeDataSource.themeName;
      if (savedThemeName) {
        return setTheme(savedThemeName === 'regular' ? regularTheme : darkTheme);
      } else if (isNotNullNorUndefined(dark)) {
        const nextTheme = dark ? darkTheme : regularTheme;
        setTheme(nextTheme);
        ThemeDataSource.themeName = nextTheme.name;
      }
    }
  }, [dark, setTheme]);
}

/**
 * Notify native side when theme changes on the webview so it can react accordingly
 * @param theme
 */
function usePostMessageToNativeSide(theme: BaseTheme) {
  const name = theme?.name;
  useEffect(() => {
    if (!NativeHelper.isNative) return;
    NativeHelper.postMessage({
      type: 'themeDefined',
      data: {
        name,
      },
    });
  }, [name]);
}
