import { UpdateHealthCarerUserRequest } from '@global/utils/api/health-carer.controller.model';
import { Body, Button, Col, FaIcon, Grid, H2, H3, H4, InputLabel, LargeSeparator, Row, Separator } from '@web/atomic';
import { SelectField } from '@web/atomic/legacy/atm.select';
import { TextField } from '@web/atomic/legacy/atm.text-field';
import { TextFieldMasked } from '@web/atomic/legacy/atm.text-field/text-field-masked.component';
import flashDispatcherService from '@web/atomic/legacy/obj.flash-wrapper/flash-dispatcher.service';
import { Form, FormData, Validators } from '@web/atomic/legacy/obj.form';
import { Modal } from '@web/atomic/legacy/obj.modal';
import { PostUrl } from '@web/data/vigilantes.datasource';
import React from 'react';
import { HcpUserContext } from '../../UserProvider';
import { usePostUsingFirebase } from '../obj.custom-hooks/use-post-using-firebase.hook';
import {
  ChangeDataModalForm,
  IEditMenuModal,
  buildUpdateHcpData,
  getGenderSelection,
  updateTitle as getModalHeader,
} from './edit-menu-modal.utils';

import { isCpfValid } from '@global/utils/domain/cpf';
import { findBankByNumber } from '../../data/bank-list';
import {
  FinancialInfoFormFields,
  FinancialSignUpPageAccountType,
} from '../../modules/financial-sign-up/components/financial-info-form-fields.component';

export const EditMenuModal: React.FunctionComponent<IEditMenuModal> = (props) => {
  const user = React.useContext(HcpUserContext);
  const { title, subtitle } = getModalHeader(user);

  const onSuccess = () => {
    user.onUserChange();
    props.onClose();
    flashDispatcherService.dispatchMessage('Dados alterados com sucesso!', 'success');
  };
  const onError = () => {
    flashDispatcherService.dispatchMessage('Não foi possível alterar os dados. Por favor, tente novamente em 1 minuto.', 'alert');
  };
  const [updateHealthCarer, { loading }] = usePostUsingFirebase<UpdateHealthCarerUserRequest, UpdateHealthCarerUserRequest>({
    url: PostUrl.UpdateHealthCarer,
    onError: onError,
    onSuccess: onSuccess,
  });

  const handleSubmit = async (formData: FormData<ChangeDataModalForm>) => {
    if (Object.keys(formData.error).length !== 0) return;
    const data = buildUpdateHcpData(formData, user);
    await updateHealthCarer(data);
  };

  const financialInfo = user.financialInfo;
  return (
    <Modal opened={props.opened} small onClose={props.onClose} noPadding>
      <Grid fluid>
        <Form onSubmit={handleSubmit}>
          <Row mb center="xs">
            <Col xs={12}>
              <H2>{title}</H2>
              <H4>{subtitle}</H4>
            </Col>
          </Row>

          <Row mt mb center="xs">
            <Col xs={12}>
              <H3> Dados pessoais </H3>
              <PersonalDataFormFields />
              <LargeSeparator />
            </Col>
          </Row>

          {user.hasFinancialInfo && (
            <Row mb>
              <Col xs={12}>
                <H3> Dados bancários </H3>
                {user.financialInfo?.document && (
                  <Body>
                    Importante: a conta bancária informada precisar pertencer ao {isCpfValid(user.financialInfo.document) ? 'CPF' : 'CNPJ'}{' '}
                    {user.financialInfo.document}.
                  </Body>
                )}
                <LargeSeparator />
                <FinancialInfoFormFields
                  initialData={
                    financialInfo && {
                      ...financialInfo,
                      account_type: financialInfo.account_type as FinancialSignUpPageAccountType,
                      bank: findBankByNumber(financialInfo.bank),
                    }
                  }
                />
                <LargeSeparator />
              </Col>
            </Row>
          )}

          <Row mb>
            <Col xs={12}>
              <Button type="submit" kind="primary" loading={loading} expanded>
                Atualizar dados
              </Button>
              <Separator />
            </Col>
          </Row>
        </Form>
      </Grid>
    </Modal>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////

type IPersonalDataFormFieldsProps = unknown;

const PersonalDataFormFields: React.FunctionComponent<IPersonalDataFormFieldsProps> = (props) => {
  const user = React.useContext(HcpUserContext);
  const { name, gender, email, whatsapp } = user;
  return (
    <>
      <Form.Field name={'name' as keyof ChangeDataModalForm} validators={[Validators.Required()]}>
        <InputLabel>Nome</InputLabel>
        <TextField type={'text'} initialValue={name} />
      </Form.Field>
      <LargeSeparator />

      <Form.Field name={'gender' as keyof ChangeDataModalForm} validators={[Validators.Required()]}>
        <InputLabel>Gênero</InputLabel>
        <SelectField initialValue={gender}>{getGenderSelection()}</SelectField>
      </Form.Field>
      <LargeSeparator />

      <Form.Field name={'email' as keyof ChangeDataModalForm} validators={[Validators.Required()]}>
        <InputLabel
          role="tooltip"
          data-microtip-position="right"
          data-microtip-size="medium"
          aria-label={`Se você precisa editar este campo entre em contato com nosso suporte`}
        >
          E-mail <FaIcon.Question />
        </InputLabel>
        <TextField disabled type={'text'} initialValue={email} />
      </Form.Field>
      <LargeSeparator />

      <Form.Field name={'whatsapp' as keyof ChangeDataModalForm}>
        <InputLabel>Whatsapp</InputLabel>
        <TextFieldMasked initialValue={whatsapp?.includes('55') ? whatsapp?.slice(2) : whatsapp} kind="cel-phone" type="tel" />
      </Form.Field>
    </>
  );
};
