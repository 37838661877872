import { ErrorName } from '@global/utils/api/error-name';
import { InsertHealthCarerUserRequest, InsertHealthCarerUserResponse } from '@global/utils/api/health-carer.controller.model';
import { Gender, getGenderName } from '@global/utils/domain/entities';
import { HealthCarerFactory, HealthCarerKind } from '@global/utils/domain/health-carer/health-carer-factory';
import { brazilianStates } from '@global/utils/object/brazilian-states';
import { fixPhoneNumber } from '@global/utils/phone/phone';
import { navigate } from '@reach/router';
import {
  Body,
  Button,
  Caption,
  Col,
  FaIcon,
  Grid,
  H1,
  InputCaption,
  InputLabel,
  LargeSeparator,
  LightDivisor,
  Row,
  Separator,
} from '@web/atomic';
import { FullScreenPrimaryBackground } from '@web/atomic/atm.background';
import { RadioField } from '@web/atomic/legacy/atm.radio';
import { SelectField } from '@web/atomic/legacy/atm.select';
import { TextField } from '@web/atomic/legacy/atm.text-field';
import { TextFieldMasked } from '@web/atomic/legacy/atm.text-field/text-field-masked.component';
import flashDispatcherService, { FlashMessageDataAction } from '@web/atomic/legacy/obj.flash-wrapper/flash-dispatcher.service';
import { Form, FormData, Validators } from '@web/atomic/legacy/obj.form';
import FormWrapperWithHeader from '@web/atomic/mol.form-wrapper-with-header/form-wrapper-with-header.component';
import { useField } from '@web/atomic/obj.custom-hooks/field.hook';
import { usePostUsingNoAuth } from '@web/data/use-post-with-no-auth';
import { ErrorMessageThatCanBeShownToUser, PostUrl } from '@web/data/vigilantes.datasource';
import React, { useContext, useEffect, useState } from 'react';
import { ReachButton } from '../../components/atm.button/button.component';
import { HcpWhatsappFab } from '../../components/atm.hcp-whatsapp-fab/hcp-whatsapp-fab.component';
import AuthDatasource, { firebaseErrorsPtBr } from '../../data/auth-datasource';
import { HcpUserContext } from '../../UserProvider';
import { LoginRouteUrl } from '../login/login.route';

interface SignUpForm {
  displayName: string;
  password: string;
  email: string;
  gender: Gender;
  kind: HealthCarerKind;
  whatsapp: string;
  professional_id: string;
  professional_id_state: string;
  source: string;
}

const SignUpPage: React.FunctionComponent = () => {
  const user = useContext(HcpUserContext);
  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user]);

  const [email, handleEmailChange] = useField();
  const [gender, handleGenderChange] = useField<Gender>();
  const [kind, handleKindChange] = useField<HealthCarerKind>();
  const hcpInfo = kind && HealthCarerFactory(kind, gender);
  const hcpIdName = hcpInfo?.getIdName();

  const [submitLoading, setLoading] = useState(false);
  const handleError = (error) => {
    let message = 'Ocorreu um erro ao criar seu usuário. Tente novamente mais tarde.';
    let action: FlashMessageDataAction;
    if (error.data?.name === ErrorName.CreateHealthCarerUseCaseDuplicateEmailError) {
      message = error.data.message;
      action = {
        text: 'Ir para login',
        onClick: () => navigate(LoginRouteUrl, { state: { email } }),
      };
    } else if (ErrorMessageThatCanBeShownToUser.has(error.data?.name)) {
      message = error.data.message;
    } else if (firebaseErrorsPtBr[error?.data?.code]) {
      message = firebaseErrorsPtBr[error.data.code];
    }
    flashDispatcherService.dispatchMessage(message, 'alert', action);
  };
  const [createHealthCarer] = usePostUsingNoAuth<InsertHealthCarerUserRequest, InsertHealthCarerUserResponse>({
    url: PostUrl.HealthCarer,
    onError: handleError,
  });
  const handleSubmit = async (formData: FormData<SignUpForm>) => {
    if (Object.keys(formData.error).length !== 0) return;
    try {
      setLoading(true);
      if (formData.data.whatsapp) {
        formData.data.whatsapp = fixPhoneNumber(formData.data?.whatsapp);
      }
      formData.data.source = location.href;

      const response = await createHealthCarer(formData.data);
      if (!response) {
        return;
      }
      await signInUserOnFirebase(formData);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FullScreenPrimaryBackground>
      <Grid>
        <Row>
          <Col xs>
            <LargeSeparator />
          </Col>
        </Row>
        <Row center="xs">
          <FormWrapperWithHeader title={'portal vigilantes do sono'}>
            <Row center="xs">
              <Col xs={12}>
                <H1>Cadastro</H1>
                <Body>
                  Cadastre-se para poder indicar o programa da Vigilantes do Sono aos seus pacientes. Já possui conta?{' '}
                  <ReachButton kind="link" to={LoginRouteUrl}>
                    Entrar
                  </ReachButton>
                </Body>
                <Separator />
                <Form onSubmit={handleSubmit}>
                  <Row mt mb>
                    <Col xs={12}>
                      <Form.Field name={'displayName' as keyof SignUpForm} validators={[Validators.Required()]}>
                        <InputLabel>Nome completo</InputLabel>
                        <TextField type={'text'} autoComplete="name" />
                      </Form.Field>
                      <Separator />
                    </Col>
                  </Row>
                  <Row mt mb>
                    <Col xs={12}>
                      <Form.Field
                        onValueChange={handleGenderChange}
                        name={'gender' as keyof SignUpForm}
                        validators={[Validators.Required()]}
                      >
                        <InputLabel>Gênero</InputLabel>
                        <Separator />
                        {Object.values(Gender).map((value) => (
                          <RadioField key={value} id={value}>
                            {getGenderName(value)}
                          </RadioField>
                        ))}
                      </Form.Field>
                      <Separator />
                    </Col>
                  </Row>

                  <Row mt mb>
                    <Col xs={12}>
                      <Form.Field onValueChange={handleKindChange} name={'kind' as keyof SignUpForm} validators={[Validators.Required()]}>
                        <InputLabel>Profissão</InputLabel>
                        <Separator />
                        <SelectField>
                          <option aria-selected={false} value={null} selected disabled />
                          {Object.values(HealthCarerKind)
                            .filter((kind) => HealthCarerFactory(kind).showHealthCarerKindOnSignup())
                            .map((item) => (
                              <option value={item} aria-selected={false} key={item}>
                                {HealthCarerFactory(item, gender).getHealthCarerKindName()}
                              </option>
                            ))}
                        </SelectField>
                      </Form.Field>
                      <Separator />
                    </Col>
                  </Row>

                  {kind && !hcpIdName && (
                    <Row mt mb>
                      <Col xs={12}>
                        <Form.Field name={'professional_id' as keyof SignUpForm} validators={[Validators.Required()]}>
                          <InputLabel>Identificação profissional</InputLabel>
                          <TextField placeholder="Ex: COREN, CRF, CRN etc" autoComplete="off" />
                          <InputCaption>Você declara que é profissional de saúde com registro em conselho de classe</InputCaption>
                        </Form.Field>
                        <Separator />
                      </Col>
                    </Row>
                  )}

                  {kind && hcpIdName && (
                    <>
                      <Row mt mb>
                        <Col xs={12}>
                          <Form.Field
                            name={'professional_id' as keyof SignUpForm}
                            validators={[Validators.Required(), Validators.ProfessionalId(kind, `O ${hcpIdName} fornecido é inválido`)]}
                          >
                            <InputLabel>{hcpIdName}</InputLabel>
                            <TextField type={hcpInfo.getIdInput()} autoComplete="off" />
                          </Form.Field>
                          <Separator />
                        </Col>
                      </Row>
                      <Row mt mb>
                        <Col xs={12}>
                          <Form.Field name={'professional_id_state' as keyof SignUpForm} validators={[Validators.Required()]}>
                            <InputLabel>Estado do {hcpIdName}</InputLabel>
                            <SelectField>
                              <option aria-selected={false} value={null} />
                              {brazilianStates.map((item) => (
                                <option value={item.value} aria-selected={false} key={item.value}>
                                  {item.name}
                                </option>
                              ))}
                            </SelectField>
                          </Form.Field>
                          <Separator />
                        </Col>
                      </Row>
                    </>
                  )}

                  <Row mt mb>
                    <Col xs={12}>
                      <Form.Field
                        onValueChange={handleEmailChange}
                        name={'email' as keyof SignUpForm}
                        validators={[Validators.EmailRegex('O e-mail digitado é inválido.')]}
                      >
                        <InputLabel
                          role="tooltip"
                          data-microtip-position="right"
                          data-microtip-size="medium"
                          aria-label={`Nesse e-mail você receberá atualizações sobre a evolução dos pacientes que encaminhar!`}
                        >
                          E-mail
                        </InputLabel>
                        <TextField type={'email'} autoComplete="email" />
                      </Form.Field>
                      <Separator />
                    </Col>
                  </Row>

                  <Row mt mb>
                    <Col xs={12}>
                      <Form.Field name={'whatsapp' as keyof SignUpForm}>
                        <InputLabel
                          role="tooltip"
                          data-microtip-position="top"
                          data-microtip-size="medium"
                          aria-label={`Este número não será usado como spam 😅, o objetivo é facilitar o contato com nosso suporte`}
                        >
                          Whatsapp com DDD (Opcional) <FaIcon.Question />
                        </InputLabel>
                        <TextFieldMasked kind="cel-phone" type="tel" />
                      </Form.Field>
                      <Separator />
                    </Col>
                  </Row>
                  <Row mt mb>
                    <Col xs={12}>
                      <Form.Field
                        name={'password' as keyof SignUpForm}
                        validators={[Validators.Required(), Validators.MinLength(6, 'A senha deve ter pelo menos 6 caracteres.')]}
                      >
                        <InputLabel>Senha</InputLabel>
                        <TextField type={'password'} />
                      </Form.Field>
                      <Separator />
                    </Col>
                  </Row>

                  <Row mt mb>
                    <Col xs={12}>
                      <Button type="submit" kind="primary" loading={submitLoading} expanded>
                        Cadastrar
                      </Button>
                      <Separator />
                      <Caption>
                        Ao criar uma conta você concorda com nossos{' '}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://www.vigilantesdosono.com/sobre/termos-e-condicoes-profissionais-da-saude"
                        >
                          Termos de Uso
                        </a>{' '}
                        e nossa{' '}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://www.vigilantesdosono.com/sobre/politica-de-privacidade-profissionais-da-saude"
                        >
                          Política de Privacidade
                        </a>{' '}
                      </Caption>
                      <Separator />
                      <LightDivisor />
                      <Separator />
                      <Body center>
                        Já possui conta?{' '}
                        <ReachButton kind="link" to={LoginRouteUrl}>
                          Entre na sua conta
                        </ReachButton>
                      </Body>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </FormWrapperWithHeader>
        </Row>
        <Row mt>
          <Col xs>
            <LargeSeparator />
          </Col>
        </Row>
      </Grid>
      <HcpWhatsappFab />
    </FullScreenPrimaryBackground>
  );
};

export default SignUpPage;

async function signInUserOnFirebase(formData: FormData<SignUpForm>) {
  try {
    const auth = AuthDatasource.auth;
    await auth.signInWithEmailAndPassword(formData.data.email, formData.data.password);
  } catch (error) {
    navigate('/');
  }
}
