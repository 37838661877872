import { formatAsPrice } from '@global/utils/number/format';
import { isNullOrUndefined } from '@global/utils/object/null-or-undefined';
import { Link } from '@reach/router';
import { Body, FaIcon, GrayColor, Hbox, SemiBold } from '@web/atomic';
import { Fade } from '@web/atomic/legacy/obj.animation/animation.component.style';
import { sendDataLayer } from '@web/utils/analytics';
import * as React from 'react';
import { FinancialActivityRouteUrl } from '../../modules/financial-activity/financial-activity.route';
import { getNameInitials } from '../../utils/string';
import { IconTextButtonStyled } from '../atm.icon-text-button/icon-text-button.style';
import ThumbIcon from '../atm.thumb-icon/thumb-icon.component';
import UserMenuDataSource from './user-menu-button.datasource';

interface UserMenuButtonProps {
  userName: string;
  balance: number;
  onMenuClick?: () => any;
  mobile?: boolean;
}

export const UserMenuButton: React.FunctionComponent<UserMenuButtonProps> = (props) => {
  const [eyeOpen, setEyeOpen] = React.useState(UserMenuDataSource.eyeOpen);
  const handleToggleEyeClick = () => {
    const nextValue = !UserMenuDataSource.eyeOpen;
    UserMenuDataSource.eyeOpen = nextValue;
    setEyeOpen(nextValue);
    sendDataLayer({
      event: 'custom_click',
      id: `ga-toggle-eye-click`,
      value: nextValue ? 1 : 0,
    });
  };
  const showFinancialInfo = eyeOpen;
  return (
    <IconTextButtonStyled>
      <Hbox hAlign="center">
        {!props.mobile && !isNullOrUndefined(props.balance) && (
          <>
            <Hbox.Item wrap vAlign={'center'}>
              <Fade show={showFinancialInfo}>
                <Link to={FinancialActivityRouteUrl}>
                  <Body kind={'light'}>Saldo: R$ {formatAsPrice(props.balance)}</Body>
                </Link>
              </Fade>
            </Hbox.Item>
            <Hbox.Separator />
            <Hbox.Separator />
            <Hbox.Item wrap vAlign={'center'} onClick={handleToggleEyeClick}>
              {showFinancialInfo ? <FaIcon.EyeSlash size="1x" color={GrayColor.White} /> : <FaIcon.Eye size="1x" color={GrayColor.White} />}
            </Hbox.Item>
            <Hbox.Separator />
            <Hbox.Separator />
            <Hbox.Separator />
            <Hbox.Separator />
          </>
        )}

        {props.userName ? <InitialMenu {...props} /> : <HamburgerMenu {...props} />}
      </Hbox>
    </IconTextButtonStyled>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////

const InitialMenu: React.FunctionComponent<UserMenuButtonProps> = (props) => {
  const initials = getNameInitials(props.userName);
  return (
    <>
      <Hbox.Item wrap vAlign={'center'} onClick={props.onMenuClick}>
        <ThumbIcon text={initials} size="small" color="yellow" />
      </Hbox.Item>
      {props.mobile || (
        <>
          <Hbox.Separator onClick={props.onMenuClick} />
          <Hbox.Item wrap vAlign={'center'} onClick={props.onMenuClick}>
            <Body kind={'light'}>
              <SemiBold>{props.userName}</SemiBold>
            </Body>
          </Hbox.Item>
          <Hbox.Separator onClick={props.onMenuClick} />
          <Hbox.Item wrap vAlign={'center'} onClick={props.onMenuClick}>
            <FaIcon.ChevronDown size="sm" color={GrayColor.White} />
          </Hbox.Item>
        </>
      )}
    </>
  );
};

const HamburgerMenu: React.FunctionComponent<UserMenuButtonProps> = (props) => {
  return (
    <IconTextButtonStyled>
      <Hbox hAlign="center" onClick={props.onMenuClick}>
        <Hbox.Item wrap vAlign={'center'}>
          <FaIcon.Bars size="md" color={GrayColor.White} />
        </Hbox.Item>
      </Hbox>
    </IconTextButtonStyled>
  );
};
