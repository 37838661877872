import * as React from 'react';

import { ThumbIconStyled, ThumbIconTextStyled, ThumbIconStyledProps } from './thumb-icon.styled';

interface ThumbIconProps extends ThumbIconStyledProps {
  text?: string;
}

const ThumbIcon: React.FunctionComponent<ThumbIconProps> = (props) => {
  if (!props.text) {
    return null;
  }
  return (
    <ThumbIconStyled {...props}>
      <ThumbIconTextStyled {...props}>{props.text}</ThumbIconTextStyled>
    </ThumbIconStyled>
  );
};

export default ThumbIcon;
