import { FormFieldContext, FormFieldContextState } from '@web/atomic/legacy/obj.form';
import { faSpinner, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import * as React from 'react';
import { isNullOrUndefined } from '@global/utils/object/null-or-undefined';
import {
  TextFieldDismissButtonClickableStyled,
  TextFieldDismissButtonStyled,
  TextFieldIconStyled,
  TextFieldIconWrapperStyled,
  TextFieldWrapperStyled,
} from './text-field.component.style';

export interface TextFieldWrapperProps extends React.InputHTMLAttributes<HTMLInputElement> {
  initialValue?: string | number | readonly string[];
  value?: string | number | readonly string[];
  icon?: IconDefinition;
  loading?: boolean;
  dismissable?: boolean;
  light?: boolean;
  maxLength?: number;
  invalid?: boolean;
  letterCase?: 'default' | 'upper'

  onValueChange?: (value: string | number | readonly string[]) => void;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;

  children?: (data: ChildrenProps) => JSX.Element;
}

interface ChildrenProps {
  value: string;
  invalid: boolean;
  onChangeText: (eventOrText: string | React.FormEvent<HTMLInputElement>) => void;
  ref: (ref: any) => any;
  onInput: React.FormEventHandler<HTMLInputElement>;
  onFocus: React.FocusEventHandler<HTMLInputElement>;
  onBlur: React.FocusEventHandler<HTMLInputElement>;
}

export interface TextFieldWrapperState {
  value?: string | number | readonly string[];
}

export class TextFieldWrapper extends React.Component<TextFieldWrapperProps, TextFieldWrapperState> {
  static defaultProps = {
    kind: 'normal',
  };

  private inputRef;

  private formFieldConsumer: FormFieldContextState;

  constructor(props: TextFieldWrapperProps) {
    super(props);
    this.state = {
      value: isNullOrUndefined(props.initialValue) ? props.value : props.initialValue,
    };
  }

  get input() {
    return this.inputRef;
  }

  componentDidMount() {
    if (this.isControlled() && this.props.initialValue !== undefined) {
      throw new Error('Use either the initialValue prop, or the value prop, but not both');
    }

    if (this.formFieldConsumer) {
      if (this.isControlled() || this.props.initialValue !== undefined) {
        if (this.formFieldConsumer.value) {
          throw new Error('Please, use either value props in <TextFieldWrapper> or <Form.Field> component.');
        }

        this.formFieldConsumer.onValueChange(this.state.value, null);
      }
    }
  }

  componentDidUpdate(prevProps: TextFieldWrapperProps) {
    if (prevProps.value !== this.props.value && this.props.value !== this.state.value) {
      if (this.props.onValueChange) {
        this.props.onValueChange(this.props.value);
      }

      if (this.formFieldConsumer) {
        this.formFieldConsumer.onValueChange(this.props.value, null);
      }

      this.setState({ value: this.props.value });
    }
  }

  handleChangeText = (eventOrText: string | React.FormEvent<HTMLInputElement>) => {
    let value: string;

    if (typeof eventOrText === 'string') {
      value = eventOrText;
    } else {
      value = (eventOrText as React.FormEvent<HTMLInputElement>).currentTarget.value;
    }

    if (typeof value === 'string' && this.props.letterCase === 'upper') {
      value = value.toUpperCase()
    }

    if (this.state && value === this.state.value) {
      return;
    }

    if (this.props.onValueChange) {
      this.props.onValueChange(value);
    }

    if (this.isControlled()) {
      return;
    }

    if (this.formFieldConsumer) {
      this.formFieldConsumer.onValueChange(value, null);
    } else {
      this.setState({ value });
    }
  };

  handleInput = (event) => {
    const nextValue = event.currentTarget.value;
    const currentValue = this.getCurrentValue();

    // HACK: "not fired by keyboard" means that who is calling onInput
    // is not the user by typing letters on the keyboard.
    // For instance, it can be fired by pasting some value or
    // by using form auto-complete.
    // Why is this necessary? auto-complete doesn't fire onChange event
    // but it fires onInput.
    // If you don't handle onInput, some bugs may appear if you use
    // auto-complete on Chrome iOS
    const notFiredByKeyboardTyping = (nextValue.length ? nextValue.length : 0) - (currentValue ? currentValue.length : 0) > 1;

    if (notFiredByKeyboardTyping) {
      event.preventDefault();
      this.handleChangeText(nextValue);
    }
  };

  handleFocus = (event) => {
    if (this.formFieldConsumer) {
      this.formFieldConsumer.onFocusChange(true);
    }
    if (this.props.onFocus) {
      this.props.onFocus(event);
    }
  };

  handleBlur = (event: any) => {
    if (this.formFieldConsumer) {
      this.formFieldConsumer.onFocusChange(false);
    }
    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  };

  handleDismiss = () => {
    this.handleChangeText('');

    if (this.inputRef) {
      this.inputRef.focus();
    }
  };

  render() {
    const { icon, loading, dismissable, light, maxLength, invalid } = this.props;

    return (
      <FormFieldContext.Consumer>
        {(formFieldConsumer: FormFieldContextState) => {
          this.formFieldConsumer = formFieldConsumer;

          const val = this.getCurrentValue();

          return (
            <React.Fragment>
              {(icon || loading) && (
                <TextFieldIconWrapperStyled>
                  {/* https://github.com/styled-components/styled-components/issues/1198 */}
                  <TextFieldIconStyled icon={(loading ? faSpinner : icon) || null} spin={loading} light={light ? 1 : undefined} />
                </TextFieldIconWrapperStyled>
              )}

              <TextFieldWrapperStyled>
                {this.props.children({
                  value: !isNullOrUndefined(val) ? val : '',
                  onChangeText: this.handleChangeText,
                  ref: this.setInputRef,
                  onInput: this.handleInput,
                  onBlur: this.handleBlur,
                  onFocus: this.handleFocus,
                  invalid: invalid || !!(formFieldConsumer && formFieldConsumer.errors.length),
                })}

                {dismissable && !isNullOrUndefined(val) && val.length > 0 && (
                  <TextFieldDismissButtonClickableStyled onClick={this.handleDismiss} length={maxLength}>
                    <TextFieldDismissButtonStyled />
                  </TextFieldDismissButtonClickableStyled>
                )}
              </TextFieldWrapperStyled>
            </React.Fragment>
          );
        }}
      </FormFieldContext.Consumer>
    );
  }

  private isControlled = () => this.props.value !== undefined;

  private setInputRef = (ref) => (this.inputRef = ref);

  private getCurrentValue = () => {
    if (this.isControlled()) {
      return this.props.value;
    }
    return this.formFieldConsumer && !isNullOrUndefined(this.formFieldConsumer.value) ? this.formFieldConsumer.value : this.state.value;
  };
}
