import { GrayColor } from '@global/utils/ui/colors';

export { DarkColor, GrayColor, LightColor, BrandColor, Alpha } from '@global/utils/ui/colors';

export enum FontFamily {
  Primary = 'Montserrat, sans-serif',
  Secondary = '"Frank Ruhl Libre", serif',
}

export enum FontWeight {
  Bold = 'bold',
  SemiBold = 600,
  Normal = 'normal',
}

export enum FontSize {
  XXXSmall = '12px',
  XXSmall = '14px',
  XSmall = '16px',
  Small = '18px',
  Medium = '22px',
  Large = '26px',
  XLarge = '32px',
  XXLarge = '56px',
}

export enum Spacing {
  XSmall = '4px',
  Small = '8px',
  Medium = '16px',
  Large = '24px',
  XLarge = '32px',
  XXLarge = '40px',
  XXXLarge = '60px',
}

export enum IconSize {
  Small = '12px',
  Medium = '16px',
  Large = '20px',
}

export const Border = {
  Color: GrayColor.GrayXLight,
  Width: '1px',
  Radius: '8px',
  RadiusLarge: '24px',
};

export const HeaderHeight = {
  Mobile: 64,
  Desk: 84,
};

export const DrawerWidth = '256px';
export const TransitionDuration = '.3s';

// Security recommendation: Failing to enforce passwords of at least 7 characters, a complexity
// of at least alpha and numeric characters increases the risk of a brute force attack.
export enum PasswordLength {
  Max = 18,
  Min = 7,
}

export enum AspectRatio {
  Square = 1,
}
