import { CHART_COLORS } from '@component/charts/utils';
import { getSleepDiaryAnalysis } from '@global/utils/domain/diary-analysis';
import { parseToDate } from '@global/utils/date/parse-to-date';
import { parseDateToShortString } from '@global/utils/date/parse-to-string';
import { AppThemeContext, BrandColor, GrayColor } from '@web/atomic';
import React, { useContext } from 'react';
import { Area, Brush, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { isNullOrUndefined } from '@global/utils/object/null-or-undefined';
import { getCycleReferenceLine } from './cycle-reference.components';
import { ChartWrapperStyled } from './graph.styled';
import { numberOfDaysToShowInitiallyOnGraph, SleepDiaryGraphProps } from './utils';

interface ISleepDiaryGraphProps extends SleepDiaryGraphProps {
  hideBrush?: boolean;
  height?: number;
}

export const SleepDiaryGraph: React.FunctionComponent<ISleepDiaryGraphProps> = (props) => {
  const inBedLabel = 'na cama (h)';
  const asleepLabel = 'sono (h)';
  const eficiencyLabel = 'eficiência (%)';

  const cData = props.sleepDiaryData
    ? props.sleepDiaryData.map((item) => {
        const { minutesSleep, minutesInBed, eficiency } = getSleepDiaryAnalysis({
          goBed: item.go_bed,
          goSleep: item.go_sleep,
          timeToSleep: item.time_to_sleep,
          wakeUpDuration: item.wake_up_duration,
          wakeUp: item.wake_up,
          getUp: item.get_up,
        });

        const response = {
          name: parseDateToShortString(parseToDate(item.date)),
        };
        if ([minutesInBed, minutesSleep, eficiency].every((item) => !isNullOrUndefined(item) && !isNaN(item))) {
          response[inBedLabel] = (minutesInBed / 60).toPrecision(2);
          response[asleepLabel] = (minutesSleep / 60).toPrecision(2);
          response[eficiencyLabel] = eficiency;
        }

        return response;
      })
    : [];

  // BUSINESS-RULE: the first cycle actually starts one day before the first diary
  if (props.cycles && props.cycles[0]) {
    cData.unshift({ name: parseDateToShortString(props.cycles[0].startDate) });
  }

  const height = props.height || 400;
  const { theme } = useContext(AppThemeContext);
  return (
    <>
      <ChartWrapperStyled height={height}>
        <ResponsiveContainer>
          <ComposedChart
            data={cData}
            height={height}
            width={props.width}
            // POG-ALERT: this margin is a hack to remove space before/after Y-Axis
            margin={{ top: 0, right: -25, bottom: 0, left: -35 }}
          >
            <CartesianGrid stroke={theme.name === 'dark' ? GrayColor.GrayDark : GrayColor.GrayXXXLight} />
            <XAxis
              stroke={theme.name === 'dark' ? GrayColor.GrayXLight : GrayColor.Gray}
              dataKey="name"
              label={{ position: 'insideBottomRight', offset: 0 }}
            />

            <YAxis
              stroke={theme.name === 'dark' ? GrayColor.GrayXLight : GrayColor.Gray}
              yAxisId="left"
              allowDataOverflow={true}
              label={{ value: 'horas', className: 'rechart-left-label', angle: -90, position: 'top' }}
            />
            <YAxis
              stroke={theme.name === 'dark' ? GrayColor.GrayXLight : GrayColor.Gray}
              yAxisId="right"
              orientation="right"
              allowDataOverflow={true}
              label={{ value: '%', className: 'rechart-right-label', angle: -90, position: 'top' }}
            />

            <Tooltip />

            {!props.hideBrush && cData.length > 7 && (
              <Brush dataKey="name" height={30} startIndex={cData.length - numberOfDaysToShowInitiallyOnGraph} />
            )}

            <Legend verticalAlign="top" width={'100%' as any} wrapperStyle={{ left: 0, top: -30 }} />

            <Area
              yAxisId="left"
              legendType="square"
              type="monotone"
              isAnimationActive={!props.removeAnimation}
              dataKey={inBedLabel}
              fillOpacity={1}
              fill={BrandColor.Hoki}
              stroke="none"
            />
            <Area
              isAnimationActive={!props.removeAnimation}
              yAxisId="left"
              legendType="square"
              dataKey={asleepLabel}
              fillOpacity={1}
              fill={BrandColor.CongressBlue}
              stroke={'none'}
            />
            <Line
              isAnimationActive={!props.removeAnimation}
              yAxisId="right"
              dot={false}
              type="monotone"
              dataKey={eficiencyLabel}
              stroke={CHART_COLORS[2]}
              strokeWidth={2}
            />

            {getCycleReferenceLine({ ...props, yAxisId: 'left', hideLabelForLast: true })}
          </ComposedChart>
        </ResponsiveContainer>
      </ChartWrapperStyled>
    </>
  );
};
