import { Health_Carer_Transactions } from '@global/utils/remote-graphql-types';
import { H2, Separator } from '@web/atomic';
import { CardWrapper } from '@web/atomic/atm.wrapper/wrapper.component';
import React from 'react';
import { TransactionItem } from './transaction-item.component';

interface ITransactionsProps {
  transactions?: Health_Carer_Transactions[];
}

export const Transactions: React.FunctionComponent<ITransactionsProps> = (props) => {
  return (
    <>
      <Separator />
      <CardWrapper>
        <H2 cell>Movimentações do seu dinheiro</H2>
        <Separator />
        {props.transactions?.map((t) => <TransactionItem key={t.id} transaction={t} />) ?? <TransactionsPlaceholder />}
      </CardWrapper>
      <Separator />
    </>
  );
};

const TransactionsPlaceholder: React.FunctionComponent = () => {
  return (
    <>
      {Array(4)
        .fill(1)
        .map((t, i) => (
          <TransactionItem key={i} />
        ))}
    </>
  );
};
