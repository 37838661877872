/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import * as React from 'react';
import { InView } from 'react-intersection-observer';
import {
  LazyLoadErrorImageStyled,
  LazyLoadImgWrapper,
  LazyLoadPlaceholderImageStyled,
  LazyLoadPlaceholderStyled,
  LazyLoadImageStyled,
} from './lazy-load-image.component.style';

interface LazyLoadImageProps {
  src: string;
  alt: string;
  /** height / width */
  aspectRatio?: number;
  onClick?: () => void;
  noImagePlaceholder?: JSX.Element;
  round?: boolean;
  width?: string | number;
  height?: string | number;
  fitWidth?: boolean;
  fitHeight?: boolean;
  center?: boolean;
  sizes?: string;
  onLoad?: () => void;
  onError?: () => void;
}

enum Status {
  Loading,
  Error,
  Loaded,
}

interface LazyLoadImageState {
  status: Status;
}

export class LazyLoadImage extends React.Component<LazyLoadImageProps, LazyLoadImageState> {
  constructor(props: LazyLoadImageProps) {
    super(props);
    this.state = {
      status: Status.Loading,
    };
  }

  handleError = () => {
    this.setState({ status: Status.Error }, () => {
      if (this.props.onError) {
        this.props.onError();
      }
    });
  };

  handleLoad = () => {
    this.setState({ status: Status.Loaded }, () => {
      if (this.props.onLoad) {
        this.props.onLoad();
      }
    });
  };

  referImageWrapper = (imageWrapper: any) => {
    if (imageWrapper) {
      imageWrapper.onload = this.handleLoad;
    }
  };

  shouldComponentUpdate(nextProps: LazyLoadImageProps, nextState: LazyLoadImageState) {
    return (
      nextState.status !== this.state.status ||
      nextProps.src !== this.props.src ||
      nextProps.alt !== this.props.alt ||
      nextProps.aspectRatio !== this.props.aspectRatio ||
      nextProps.round !== this.props.round ||
      nextProps.width !== this.props.width ||
      nextProps.height !== this.props.height ||
      nextProps.sizes !== this.props.sizes
    );
  }

  render() {
    const aspectRatio = this.props.aspectRatio || (Number(this.props.height) || 0) / (Number(this.props.width) || 1);

    return (
      <InView triggerOnce={true}>
        {({ inView, ref }) => {
          return (
            <LazyLoadImgWrapper
              ref={ref}
              height={this.props.height}
              width={this.props.width}
              round={this.props.round}
              fitWidth={this.props.fitWidth}
              fitHeight={this.props.fitHeight}
              center={this.props.center}
              loaded={this.state.status === Status.Loaded}
            >
              {this.state.status === Status.Loading && (
                <LazyLoadPlaceholderStyled aspectRatio={aspectRatio}>
                  <LazyLoadPlaceholderImageStyled />
                </LazyLoadPlaceholderStyled>
              )}
              {this.state.status === Status.Error && (
                <LazyLoadPlaceholderStyled aspectRatio={aspectRatio}>
                  {this.props.noImagePlaceholder || <LazyLoadErrorImageStyled />}
                </LazyLoadPlaceholderStyled>
              )}
              {((inView && this.state.status === Status.Loading) || this.state.status === Status.Loaded) && (
                <LazyLoadImageStyled
                  alt={this.props.alt}
                  onClick={this.props.onClick}
                  src={this.props.src}
                  loaded={this.state.status === Status.Loaded}
                  onLoad={this.handleLoad}
                  onError={this.handleError}
                  height={this.props.height}
                  width={this.props.width}
                  sizes={this.props.sizes}
                />
              )}
            </LazyLoadImgWrapper>
          );
        }}
      </InView>
    );
  }
}
