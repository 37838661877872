import styled from 'styled-components';

interface VideoStyledProps {
  vertical: boolean;
}

/** https://stackoverflow.com/a/38270745/3670829 */
export const VideoWrapperStyled = styled.div<VideoStyledProps>`
  position: relative;
  padding-bottom: ${(props) => (props.vertical ? '177.78%' : '56.25%')}; /* 16:9 */
  padding-top: 0;
  height: 0;
`;

/** https://stackoverflow.com/a/38270745/3670829 */
export const VideoStyled = styled.iframe.attrs((props: VideoStyledProps) => ({
  width: props.vertical ? 9 : 16,
  height: props.vertical ? 16 : 9,
  frameBorder: 0,
  allowFullScreen: true,
  allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
}))<VideoStyledProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 80vh;
`;
