import { parseDateToDDMMYYYY } from '@global/utils/date/parse-to-string';
import { formatAsPrice } from '@global/utils/number/format';
import { Health_Carer_Transactions } from '@global/utils/remote-graphql-types';
import { AutoMargin, Body, H3, Hbox, LightDivisor, SemiBold, Separator } from '@web/atomic';
import { ShimmerText } from '@web/atomic/legacy/mol.shimmer/shimmer.options';
import React, { memo } from 'react';
import { isNullOrUndefined } from '@global/utils/object/null-or-undefined';
import { isAppleFakeEmail } from '@global/utils/email/is-apple-fake-email';
import { TransactionItemCaptionStyled, TransactionItemDateStyled } from './transaction-item.component.styled';
import TinyMask from 'tinymask';
import { cpfMask, isCpfValid } from '@global/utils/domain/cpf';
import { cnpjMask } from '@global/utils/domain/cnpj';
import { onlyNumber } from '@global/utils/string/only-number';

interface ITransactionItemProps {
  transaction?: Health_Carer_Transactions;
}

export const TransactionItem: React.FunctionComponent<ITransactionItemProps> = memo((props) => {
  const transaction = props.transaction;

  const userEmail = isAppleFakeEmail(transaction?.from_user?.email) ? transaction?.from_user?.alt_email : transaction?.from_user?.email;

  const userDocument = transaction?.from_user?.document;
  const userDocumentFormatMask = userDocument && isCpfValid(userDocument) ? cpfMask : cnpjMask;
  const formattedUserDocument = userDocument && new TinyMask(userDocumentFormatMask).mask(onlyNumber(userDocument));

  return (
    <>
      <LightDivisor />
      <Separator />
      <Hbox>
        <Hbox.Item>
          <H3 cell>{!isNullOrUndefined(transaction?.description) ? transaction.description : <ShimmerText />}</H3>
          {!!userDocument && transaction?.amount > 0 && (
            <TransactionItemCaptionStyled>
              {userEmail}
              {userEmail && formattedUserDocument ? ` | ` : ''}
              {formattedUserDocument}
            </TransactionItemCaptionStyled>
          )}
        </Hbox.Item>
        <Hbox.Separator />
        <AutoMargin />
        <Hbox.Item wrap vAlign="space-between">
          <Body>
            <SemiBold>
              {' '}
              {!isNullOrUndefined(transaction?.amount) ? formatAsPrice(transaction.amount, true) : <ShimmerText width={`100px`} />}{' '}
            </SemiBold>
          </Body>
          <TransactionItemDateStyled>
            {transaction?.created_at ? parseDateToDDMMYYYY(new Date(transaction.created_at)) : <ShimmerText />}
          </TransactionItemDateStyled>
        </Hbox.Item>
      </Hbox>
      <Separator />
    </>
  );
});

TransactionItem.displayName = 'TransactionItem.memo';
