import { hasWindow } from '@web/utils/platform';
import QueryString from 'querystringify';
import * as React from 'react';

export function useQueryParams<T>(): T {
  const [queryParams, setQueryParams] = React.useState<T>({} as T);
  React.useEffect(() => {
    const params: T = QueryString.parse(hasWindow() ? window.location.search : '');
    setQueryParams(params);
  }, [setQueryParams]);

  return queryParams;
}
