import { Body, Col, Grid, HDisplay, LargeSeparator, Row } from '@web/atomic';
import * as React from 'react';
import { HcpUserContext } from '../../../UserProvider';
import { DoctorTutorial } from '../../../components/org.doctor-tutorial/doctor-tutorial.component';

const FinancialActivityEmptyListPlaceholder: React.FunctionComponent = () => {
  const user = React.useContext(HcpUserContext);
  const couponCode = user?.couponCode;
  return (
    <>
      <Grid>
        <Row>
          <Col xs={12}>
            <LargeSeparator />
          </Col>
        </Row>
        <>
          <Row mb center="xs">
            <Col xs={12} md={8} lg={6}>
              <HDisplay center>Você não possui transações. </HDisplay>
              <Body center>Assim que seus pacientes aderirem ao programa, as transações aparecerão nessa tela.</Body>
            </Col>
          </Row>
          <Row mb center="xs">
            <Col xs={10} md={8} lg={4}>
              <SvgEmptyPlaceholder />
            </Col>
          </Row>
        </>
        <Row>
          <Col xs={12}>
            <LargeSeparator />
          </Col>
        </Row>
      </Grid>
      {couponCode && <DoctorTutorial hasFinancialInfo={user.hasFinancialInfo} showTutorialVideo={true} couponCode={couponCode} />}
    </>
  );
};

export default FinancialActivityEmptyListPlaceholder;

////////////////////////////////////////////////////////////////////////////////////////////////////

function SvgEmptyPlaceholder(props) {
  return (
    <svg viewBox={'0 0 386 320'} fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M194.872 272.41c65.934 0 119.384-53.45 119.384-119.384 0-65.935-53.45-119.385-119.384-119.385-65.935 0-119.385 53.45-119.385 119.385 0 65.934 53.45 119.384 119.385 119.384z"
        fill="#EAECF3"
      />
      <circle cx={272.641} cy={213.564} r={9.257} fill="#ECD9B4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M213.501 36.999c-.11 6.311 5.344 11.524 12.181 11.644 6.837.12 12.469-4.9 12.579-11.212.111-6.311-5.343-11.524-12.18-11.644-6.837-.12-12.47 4.9-12.58 11.212zM148.744 35.878c-.111 6.317 4.064 11.513 9.323 11.605 5.26.091 9.613-4.955 9.723-11.272.11-6.317-4.064-11.512-9.324-11.604-5.259-.092-9.612 4.954-9.722 11.271z"
        fill="#ECD9B4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M222.792 50.497c9.467.166 17.276-7.375 17.441-16.842.165-9.467-7.376-17.276-16.842-17.44-9.467-.166-17.276 7.374-17.441 16.841-.166 9.468 7.375 17.276 16.842 17.441zm2.889-1.854c5.786.1 10.558-4.508 10.659-10.293.101-5.785-4.507-10.557-10.293-10.658-5.785-.101-10.557 4.507-10.658 10.292-.101 5.785 4.507 10.558 10.292 10.659zM172.636 31.523c-.166 9.467-1.269 18.077-12.68 16.925-11.411-1.152-12.246-7.89-12.081-17.358.166-9.467 5.842-17.044 12.679-16.925 6.838.12 12.247 7.89 12.082 17.358zM158.084 46.51c-4.734-.082-8.496-4.413-8.404-9.672.091-5.26 4.003-9.457 8.737-9.374 4.733.083 8.496 4.414 8.404 9.673-.092 5.259-4.003 9.456-8.737 9.373z"
        fill="#B68160"
      />
      <path
        d="M333.581 102.386c-20.281-3.697-57.023-.846-77.927 1.815-4.188.534-6.371 5.16-4.231 8.799l1.925 3.272a6 6 0 005.439 2.952l73.531-3.276a6 6 0 005.733-5.994v-1.987c0-2.705-1.808-5.096-4.47-5.581z"
        fill="#F9E3C9"
      />
      <path
        d="M189.229 32.462c-33.608-1.424-42.248 23.854-42.367 36.67l-2.848 46.3c-3.323-.237-17.089-3.887-17.089-21.361 0-19.226 14.241-20.897 16.021-28.374 1.419-5.959-5.34-11.145-19.225-6.16-13.885 4.984-25.99 23.14-19.225 50.555 5.411 21.931 25.515 39.044 34.89 44.859l-.12.618c-2.758 14.263-7.767 40.171-6.645 64.874 1.425 31.33 26.702 51.267 30.262 55.896 3.561 4.628 6.053 28.481 14.953 28.481 7.121 0 8.664-14.003 8.545-21.005h17.089c.356 7.002 3.062 21.005 11.037 21.005 9.969 0 14.241-11.392 14.597-21.005.356-9.613 24.566-16.377 38.451-45.215 13.885-28.838-6.409-105.739-18.514-155.227-12.104-49.487-17.801-49.13-59.812-50.911z"
        fill="#B68160"
      />
      <path
        d="M271.471 155.646c-2.278-11.392-13.648-43.435-18.869-57.676l-6.409 61.949c3.917 14.122 13.387 41.37 19.938 37.382 8.188-4.984 8.188-27.414 5.34-41.655z"
        fill="#B68160"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M176.598 84.648c.037.807.045 1.603.026 2.382l-.029.772-.022.37c-.756 11.276-10.44 14.719-22.974 11.926l-.41-.093c-8.72-2.027-12.655-7.156-12.734-14.227-.028-2.546.45-5.2 1.294-7.865a33.13 33.13 0 011.834-4.595c4.609-9.438 10.557-13.055 18.18-12.27 6.445.664 11.339 6.893 13.639 15.74.405 1.495.732 3.223.96 4.894.057.425.106.828.142 1.177l.045.48.019.275.007.208-.002.115-.009.096.034.615z"
        fill="#ECD9B4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M151.319 71.507c1.883-3.498 8.794-.421 10.543 2.217 1.751 2.638-2.239 8.697-6.411 7.536a4.642 4.642 0 01-.354-.113c-.429 1.673-.619 3.023-.587 4.036 2.029.025 3.892.5 4.964 1.39a1.904 1.904 0 01-2.282 3.044l-.147-.11c-.34-.282-1.685-.571-3.157-.505-.926.041-1.73.21-2.224.426l-.138.067a1.906 1.906 0 01-1.772-3.373c.769-.404 1.758-.686 2.856-.83-.053-1.379.218-3.103.794-5.19-2.472-2.277-3.037-6.825-2.085-8.595z"
        fill="#734B23"
      />
      <circle cx={207.03} cy={62.012} r={3.204} fill="#1D1841" />
      <circle cx={153.627} cy={57.028} r={2.492} fill="#1D1841" />
      <path
        d="M243.481 141.497a1.5 1.5 0 00-2.861.903l2.861-.903zm3.068 24.118l1.492-.159-1.492.159zm24.241 26.298a1.5 1.5 0 00-2.199-2.041l2.199 2.041zM240.62 142.4c2.419 7.659 3.452 14.111 4.437 23.374l2.984-.318c-.995-9.351-2.055-16.027-4.56-23.959l-2.861.903zm4.437 23.374c.53 4.982.857 9.105 1.217 12.712.354 3.551.736 6.546 1.373 9.015.641 2.481 1.568 4.548 3.089 6.163 1.533 1.628 3.548 2.667 6.13 3.312l.727-2.91c-2.194-.549-3.639-1.361-4.673-2.459-1.047-1.111-1.797-2.644-2.368-4.856-.574-2.225-.939-5.016-1.293-8.563-.348-3.491-.691-7.776-1.218-12.732l-2.984.318zm11.809 31.202c4.991 1.248 10.62-1.505 13.924-5.063l-2.199-2.041c-2.867 3.088-7.444 5.082-10.998 4.194l-.727 2.91z"
        fill="#1D1841"
      />
      <ellipse cx={134.25} cy={68.214} rx={6.481} ry={8.101} transform="rotate(54.941 134.25 68.214)" fill="#ECD9B4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M181.864 19.017c-18.669 1.016-25.816 10.317-25.744 19.403.024 3.002-.235 12.069 8.483 12.069 2.566 0 5.132-.756 7.699-2.267 0 0 3.098-1.511 5.988-1.511 2.929 0 4.865 1.245 6.726 2.99 4.395 5.32 12.094 6.832 18.938 6.076 9.135-1.008 16.25-8.02 16.25-16.286 0-.144-.003-.286-.007-.428.002-.103.007-.205.007-.307.004-12.444-19.672-20.755-38.34-19.74z"
        fill="#1D1841"
      />
      <path
        d="M215.219 46.347c4.273 2.373 9.969 7.69 17.089 17.09 7.121 9.398 14.123 14.003 14.953 14.596M158.611 42.075c-1.78 1.068-3.204 2.848-7.12 6.764"
        stroke="#1D1841"
        strokeWidth={5}
        strokeLinecap="round"
      />
      <path
        d="M263.795 109.538c-27.626 6.034-92.042.393-117.378-7.783-1.238-.4-2.553.421-2.694 1.714l-1.43 13.156c-.215 1.982.526 3.963 2.238 4.985 20.192 12.051 89.134 15.283 119.264 12.544 23.019-2.093 67.188-.033 92.758 3.141 3.654.454 6.934-2.365 6.934-6.048v-11.101c0-2.836-1.973-5.286-4.756-5.825-23.285-4.508-68.333-10.592-94.936-4.783z"
        fill="#F9E3C9"
      />
      <path
        d="M336.821 106.667h4.923M361.846 118.974h4.923M361.846 129.641h4.923M361.846 134.564h4.923M337.231 109.128h4.923M362.256 121.436h4.923M361.846 127.179h4.103M362.256 123.897l4.513.821M361.846 132.103l4.513-.821"
        stroke="#751E00"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
