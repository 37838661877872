import { Border, GrayColor, LightColor, FontFamily, Spacing, HeaderHeight, FontSize } from '@web/atomic/legacy/obj.constants';
import styled, { css } from 'styled-components';
import { THProps, TdProps, TextAlignProps } from './table.component.utils';

const TABLE_GUTTER = Spacing.Small;

export const TableWrapperStyled = styled.div<{ marginLeft: number; scrollable: boolean }>`
  ${(props) =>
    props.scrollable
      ? `
    overflow-x: auto;
    overflow-y: hidden;
  `
      : ''}
  margin-left: ${(props) => props.marginLeft || 0};
`;

const TableStyledCss = css`
  border-style: solid;
  border-width: 0;
  border-color: ${Border.Color};
  border-collapse: separate;
  border-spacing: 0;
  text-align: center;
  font-family: ${FontFamily.Primary};
`;

export const TableWrapperFixedStyled = styled.div`
  ${TableStyledCss}
  position: relative;
  margin-top: 0px;
  margin-left: 5px;
  @media all and (min-width: 32em) {
    width: 100%;
  }
  @media all and (max-width: 32em) {
    overscroll-behavior: contain;
  }
`;
export const TableHeaderSticky = styled.div`
  top: ${HeaderHeight.Mobile}px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  @media all and (min-width: 32em) {
    top: ${HeaderHeight.Desk}px;
  }
`;

export const TableStyled = styled.table`
  ${TableStyledCss}
`;

export const TableHeadStyled = styled.thead`
  vertical-align: top;
`;

const TableHeaderStyledCss = css<THProps>`
  max-width: ${(props) => (props.maxWidth >= 0 ? `${props.maxWidth}px` : 'none')};
  color: ${(props) => (props.theme.name === 'dark' ? GrayColor.White : LightColor.Primary)};
  padding: ${(props) => (props.borderBottom ? `auto` : `${Spacing.Medium} ${TABLE_GUTTER}`)};
  border-width: 0;
  border-bottom: ${(props) => (props.borderBottom ? `1px solid ${GrayColor.GrayXLight}` : 'none')};
  text-align: ${(props: TextAlignProps) => props.textAlign || 'center'};
`;

export interface THBodyWithFixedHeaderProps {
  colWidth: string;
  borderBottom?: boolean;
}

export const THTableWithFixedHeader = styled.div<THBodyWithFixedHeaderProps>`
  ${TableHeaderStyledCss}
  flex: 1 0;
  position: -webkit-sticky;
  position: sticky;
  flex-basis: ${(props) => props.colWidth ?? '0'};
  background-color: ${GrayColor.White};
  @media all and (max-width: 48em) {
    min-width: 110px;
  }
  @media all and (min-width: 32em) {
    width: ${(props) => props.colWidth};
  }
`;

export const TableHeaderStyled = styled.th<THProps>`
  ${TableHeaderStyledCss}
`;

const TableColumnStyledCss = css`
  font-variant-numeric: ${(props: TdProps) => (props.mono ? 'tabular-nums' : 'normal')};
  font-size: ${FontSize.XXSmall};
  border-style: solid;
  border-color: ${Border.Color};
  border-width: 0;
  padding: ${Spacing.Medium} 0;
  width: auto;
  text-align: ${(props: TdProps) => props.textAlign || 'left'};
  min-width: ${(props: TdProps) => props.minWidth || 'auto'};
`;

export const TableColumnStyled = styled.td`
  ${TableColumnStyledCss}
  padding: ${Spacing.Medium} ${TABLE_GUTTER};
`;

export const FreezedTableColumnStyled = styled(TableColumnStyled)`
  position: absolute;
  width: ${(props: { width }) => props.width || 'auto'};
  left: 0;
  border-right: 1px solid ${GrayColor.GrayXLight};
  border-left: none;
`;

const TableRowStyledCss = css`
  & > ${TableColumnStyled} {
    background-color: ${({ theme }) => theme.color.background};
  }

  ${(props) =>
    props.onClick &&
    `
    &:hover > td {
      background-color: ${GrayColor.GrayXLight};
      cursor: pointer;
    }`}
`;

export const TableHeaderFixedStyled = styled.div<THProps>`
  ${TableRowStyledCss}
  display: flex;
  background-color: ${GrayColor.White};
  min-height: 20px;
  @media all and (max-width: 48em) {
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    overflow-y: hidden;
  }
`;
export const HeaderTextTableWithFixedHeader = styled.div<TextAlignProps>`
  text-align: ${(props) => props.textAlign || 'center'};
  padding: ${Spacing.Medium} 0;
  padding-right: ${Spacing.Small};
  border-color: ${Border.Color};
  border-bottom-style: solid;
  border-bottom-width: 0;
`;

export const BodyTextTableWithFixedHeader = styled.div<TextAlignProps>`
  ${TableColumnStyledCss}
  padding-right: ${Spacing.Small};
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 65px;
  align-items: ${(props) => props.textAlign || 'center'};
`;

export const THBodyWithFixedHeader = styled.div<THBodyWithFixedHeaderProps>`
  @media all and (min-width: 48em) {
    width: ${(props) => props.colWidth};
    max-widht: ${(props) => props.colWidth};
  }
  @media all and (max-width: 48em) {
    min-width: 110px;
    flex-basis: 0;
  }
`;

export const TableBodyFixedStyled = styled.div<THProps>`
  ${TableRowStyledCss}
  display: flex;
  @media all and (max-width: 48em) {
    -webkit-overflow-scrolling: touch;
    overflow-y: hidden;
    overflow-x: auto;
  }
`;

export const TableRowStyled = styled.tr`
  ${TableRowStyledCss}
`;
