import { BedTimesGraph } from '@component/charts/bed-times-graph.component';
import { BeforeSleepTagsGraph } from '@component/charts/before-sleep-tags-graph.component';
import { DayGoalGraph } from '@component/charts/day-goal-graph.component';
import { GradeGraph } from '@component/charts/grade-graph.component';
import { LatencyGraph } from '@component/charts/latency-graph.component';
import { SleepDiaryGraph } from '@component/charts/sleep-diary-graph.component';
import { SleepTagsGraph } from '@component/charts/sleep-tags-graph.component';
import { TechniquesGraph } from '@component/charts/techniques-graph.component';
import { GetDiariesWithEmptyValuesOutput_Diary, getDiariesWithEmptyValues } from '@component/charts/utils';
import { WasoGraph } from '@component/charts/waso-graph.component';
import { parseToDate } from '@global/utils/date/parse-to-date';
import { parseDateToDDMMYYYY, parseDateToShortString } from '@global/utils/date/parse-to-string';
import { toHour } from '@global/utils/date/to-hour';
import { getSleepDiaryAnalysis, mapToSleepDiaryWithAnalysis } from '@global/utils/domain/diary-analysis';
import { computeGad7Points, computeGadPoints } from '@global/utils/domain/gad';

import { getTitleForDayGoal, getTitleForNightGoal } from '@global/utils/domain/goals';
import { computeIsiPoints } from '@global/utils/domain/isi';
import { computeKuppermanPoints } from '@global/utils/domain/kupperman';
import { computeEpworthPoints } from '@global/utils/domain/epworth';
import { computeStopbangPoints } from '@global/utils/domain/stopbang';
import { computePhq9Points, getPhqPoints } from '@global/utils/domain/phq';
import { mapPsqiComponents } from '@global/utils/domain/psqi';
import { SleepRestrictionCycles, getSleepRestrictionCycles, mapToSleepRestrictionAnalysis } from '@global/utils/domain/sleep-restriction';
import { SleepTagAndTechniqueSummary } from '@global/utils/domain/tag-and-technique-comparator';
import { getFormattedTechniques } from '@global/utils/domain/techniques';
import { twoDigit } from '@global/utils/number/two-digit';
import { DoctorReportQueryQuery } from '@global/utils/remote-graphql-types';
import { formatName } from '@global/utils/string/name';
import {
  Body,
  BodySmall,
  Col,
  DD,
  DT,
  FaIcon,
  GrayColor,
  Grid,
  H1,
  H2,
  LightDivisor,
  Mark,
  PageBreakStyled,
  Row,
  Separator,
  Spacing,
} from '@web/atomic';
import { EfficiencyColorStyled } from '@web/atomic/atm.opinionated-color/opinionated-color.styled';
import { SleepDiaryTagTd } from '@web/atomic/atm.sleep-diary-tag-td/sleep-diary-tag-td.component';
import { MultipleCollumnWrapper } from '@web/atomic/atm.wrapper/wrapper.component';
import { LazyLoadImage } from '@web/atomic/legacy/atm.lazy-load-image';
import { ReadOnlyBadge } from '@web/atomic/legacy/atm.read-only-badge/read-only-badge.component';
import { TD, TH, THead, TR, Table, TableWithFixedHeader, TableWithFixedHeaderProps } from '@web/atomic/legacy/mol.table';
import { InlineBlockStyled, RelativeWrapper } from '@web/atomic/obj.wrappers';
import { TagAndTechniqueComparatorTable } from '@web/atomic/org.tag-and-technique-comparator-table/tag-and-technique-comparator-table.component';
import { useSleepTagAndTechniqueSummary } from '@web/atomic/org.tag-and-technique-comparator-table/tag-and-technique-comparator-table.utils';
import * as React from 'react';
import { MedicineGraph } from '../../component.charts/medicine-graph.component';
import { DoctorReportTableHeader, PatientListColStyled } from './doctor-report.component.style';
import { getProgramProgress } from './doctor-report.utils';
import { getMedicineAcronym } from '@global/utils/domain/sleep-diary';

interface IDoctorReportProps {
  data: DoctorReportQueryQuery;
  printMode: boolean;
  onUserImageLoad?: () => void;
  actionButtons: JSX.Element[];
}

const config = {
  compact: true,
  showComments: true,
  showTable: true,

  mockPsqi: null,
  mockIsi: null,
  mockImg: null,
  mockAge: null,
  hidePersonalInfo: null,
  maxDate: null,
  // maxDate: parseToDate('2020-01-18'),
  // hidePersonalInfo: true,
  // mockImg: 'https://www.thispersondoesnotexist.com/image',
  // mockAge: 43,
  // mockPsqi: [
  //   {
  //     created_at: '2019-10-24',
  //     total: 16,
  //     timeInBedInMinutes: 480,
  //     timeSleepInMinutes: 210
  //   },
  //   {
  //     created_at: '2019-12-05',
  //     total: 9,
  //     timeInBedInMinutes: 300,
  //     timeSleepInMinutes: 250
  //   },
  //   {
  //     created_at: '2020-01-18',
  //     total: 4,
  //     timeInBedInMinutes: 330,
  //     timeSleepInMinutes: 315
  //   }
  // ],
  // mockIsi: [
  //   {
  //     created_at: '2019-10-24',
  //     points: 22
  //   },
  //   {
  //     created_at: '2019-12-05',
  //     points: 14
  //   },
  //   {
  //     created_at: '2020-01-18',
  //     points: 8
  //   }
  // ]
};

export const DoctorReport: React.FunctionComponent<IDoctorReportProps> = (props) => {
  const { user, sleep_diary, sleep_restriction } = props.data || {};
  const userInfo = user?.[0];
  const psqi = config.mockPsqi || (userInfo && userInfo.psqi && userInfo.psqi.map((item) => ({ ...item, ...mapPsqiComponents(item) })));
  const isi = config.mockIsi || (userInfo && userInfo.isi && userInfo.isi.map((item) => ({ ...item, points: computeIsiPoints(item) })));

  const gad = userInfo && userInfo.gad && userInfo.gad.map((item) => ({ ...item, points: computeGadPoints(item) }));
  const gad7 = userInfo && userInfo.gad7s && userInfo.gad7s.map((item) => ({ ...item, points: computeGad7Points(item) }));
  const phq = userInfo && userInfo.phq && userInfo.phq.map((item) => ({ ...item, points: getPhqPoints(item) }));
  const phq9 = userInfo && userInfo.phq9s && userInfo.phq9s.map((item) => ({ ...item, points: computePhq9Points(item) }));
  const stopbang = userInfo && userInfo.stop_bangs && userInfo.stop_bangs.map((item) => ({ ...item, points: computeStopbangPoints(item) }));
  const epworth = userInfo && userInfo.epworths && userInfo.epworths.map((item) => ({ ...item, points: computeEpworthPoints(item) }));
  const kupperman =
    userInfo && userInfo.kuppermans && userInfo.kuppermans.map((item) => ({ ...item, points: computeKuppermanPoints(item) }));

  const showPsqi = psqi?.length > 0;
  const showIsi = isi?.length > 0;
  const showGad = gad?.length > 0;
  const showGad7 = gad7?.length > 0;
  const showPhq = phq?.length > 0;
  const showPhq9 = phq9?.length > 0;
  const showKupperman = kupperman?.length > 0;
  const showStopbang = stopbang?.length > 0;
  const showEpworth = epworth?.length > 0;

  const actionButtons = (
    <Row hideOnPrint>
      <Col xs={12}>
        <Row center="xs" mb>
          {props.actionButtons.map((button, i) => (
            <Col key={i}>
              {' '}
              <Separator /> {button}{' '}
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );

  return (
    <>
      <Grid fluid>
        {actionButtons}
        <Row hideOnPrint>
          <LightDivisor />
        </Row>

        <DoctorReportUserInfoRow
          user={userInfo}
          printMode={props.printMode}
          onImageLoad={props.onUserImageLoad}
          actionButtons={props.actionButtons}
        />

        <Row mb between="xs">
          {showPsqi && (
            <Col xs={12} sm={config.compact ? 7 : 12} md={5} lg={5}>
              <DoctorReportPsqi psqi={psqi} />
            </Col>
          )}
          {showIsi && (
            <Col xs={12} sm={config.compact ? 5 : 12} md={5} lg={5}>
              <DoctorReportIsi isi={isi} />
            </Col>
          )}
          {showPhq && (
            <Col xs={12} sm={config.compact ? 7 : 12} md={5} lg={5}>
              <DoctorReportPhq phq={phq} />
            </Col>
          )}
          {showPhq9 && (
            <Col xs={12} sm={config.compact ? 5 : 12} md={5} lg={5}>
              <DoctorReportPhq9 phq9={phq9} />
            </Col>
          )}
          {showGad && (
            <Col xs={12} sm={config.compact ? 5 : 12} md={5} lg={5}>
              <DoctorReportGad gad={gad} />
            </Col>
          )}
          {showGad7 && (
            <Col xs={12} sm={config.compact ? 5 : 12} md={5} lg={5}>
              <DoctorReportGad7 gad7={gad7} />
            </Col>
          )}
          {showKupperman && (
            <Col xs={12} sm={config.compact ? 5 : 12} md={5} lg={5}>
              <DoctorReportKupperman kupperman={kupperman} />
            </Col>
          )}

          {showEpworth && (
            <Col xs={12} sm={config.compact ? 5 : 12} md={5} lg={5}>
              <DoctorReportEpworth epworth={epworth} />
            </Col>
          )}
          {showStopbang && (
            <Col xs={12} sm={config.compact ? 5 : 12} md={5} lg={5}>
              <DoctorReportStopBang stopBang={stopbang} />
            </Col>
          )}
        </Row>

        <DoctorReportDiaryChartsRows
          userInfo={userInfo}
          sleep_diary={sleep_diary}
          sleep_restriction={sleep_restriction}
          printMode={props.printMode}
          actionButtons={props.actionButtons}
        />

        <DoctorReportSleepTagsRows data={props.data} />

        <Row mb>
          <Col xs={12}>
            {config.showTable && <DoctorReportDiaryTable diaries={sleep_diary} />}
            {config.showComments && <DoctorReportComments diaries={sleep_diary} />}
          </Col>
        </Row>

        <Row hideOnPrint>
          <LightDivisor />
        </Row>
        {actionButtons}
      </Grid>
    </>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////

interface DoctorReportDiaryChartsProps {
  userInfo: DoctorReportQueryQuery['user'][0];
  sleep_diary: DoctorReportQueryQuery['sleep_diary'];
  sleep_restriction: DoctorReportQueryQuery['sleep_restriction'];
  printMode: boolean;
  actionButtons: JSX.Element[];
}

const DoctorReportDiaryChartsRows: React.FunctionComponent<DoctorReportDiaryChartsProps> = (props) => {
  const sleep_diary = props.sleep_diary;

  if (!sleep_diary?.length) {
    return (
      <Row mb>
        <Col xs={12}>
          <Body> Ainda não há diários registrados.</Body>
        </Col>
      </Row>
    );
  }

  const showMedicineGraph = sleep_diary.some((d) => !!d.sleep_diary_medicines.length);
  const userInfo = props.userInfo;
  const sleep_restriction = props.sleep_restriction;
  const printMode = props.printMode;
  const graphWidth = 900;

  const diaries = (getDiariesWithEmptyValues(sleep_diary) || []).filter((diary) => {
    return config.maxDate ? parseToDate(diary.date) < config.maxDate : true;
  });

  const reportDiaries = diaries ? diaries.map(mapToSleepDiaryWithAnalysis) : [];
  const intervals: SleepRestrictionCycles[] = getSleepRestrictionCycles(sleep_restriction, reportDiaries);

  return (
    <>
      {!config.compact && <PageBreakStyled />}

      <Row mb>
        <Col xs={12} lg={11} md={11} sm={12}>
          <Col xs={12}>
            <H2>Diário do sono / Ciclos de restrição do sono</H2>
          </Col>

          <Col xs={4} xsOffset={6}>
            <DoctorReportTableHeader borderBotton={true}>Noites com…</DoctorReportTableHeader>
          </Col>
          <Col xs={10}>
            <DoctorReportSummaryTable intervals={intervals} />
          </Col>
        </Col>
      </Row>

      <Row mb>
        <Col xs={12}>
          <PageBreakStyled />

          <H2>Diário da noite</H2>
          <SleepDiaryGraph
            removeAnimation={printMode}
            height={printMode ? 200 : 300}
            width={graphWidth}
            sleepDiaryData={diaries}
            user={userInfo}
            cycles={intervals}
            hideBrush={true}
          />
          <Separator />
          <BedTimesGraph
            removeAnimation={printMode}
            height={printMode ? 200 : 300}
            width={graphWidth}
            sleepDiaryData={diaries}
            user={userInfo}
            cycles={intervals}
            hideBrush={true}
          />
          <Separator />
          <LatencyGraph
            removeAnimation={printMode}
            height={printMode ? 125 : 200}
            width={graphWidth}
            sleepDiaryData={reportDiaries}
            cycles={intervals}
          />
          <Separator />
          <WasoGraph
            removeAnimation={printMode}
            height={printMode ? 125 : 200}
            width={graphWidth}
            sleepDiaryData={reportDiaries}
            cycles={intervals}
          />
          <Separator />
          <SleepTagsGraph removeAnimation={printMode} height={300} width={graphWidth} sleepDiaryData={diaries} cycles={intervals} />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <PageBreakStyled />

          <H2>Diário do dia</H2>
          <GradeGraph
            removeAnimation={printMode}
            height={printMode ? 300 : 150}
            hideBrush={true}
            width={graphWidth}
            sleepDiaryData={diaries}
            cycles={intervals}
          />
          <DayGoalGraph
            removeAnimation={printMode}
            height={printMode ? 300 : 150}
            hideBrush={true}
            width={graphWidth}
            sleepDiaryData={diaries}
            cycles={intervals}
          />
          <TechniquesGraph removeAnimation={printMode} height={450} width={graphWidth} sleepDiaryData={diaries} cycles={intervals} />
          <BeforeSleepTagsGraph removeAnimation={printMode} height={300} width={graphWidth} sleepDiaryData={diaries} cycles={intervals} />
          <Separator />
          {showMedicineGraph && (
            <>
              <H2>Medicamentos</H2>
              <MedicineGraph removeAnimation={printMode} height={300} width={graphWidth} sleepDiaryData={diaries} cycles={intervals} />
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////

interface IDoctorReportSleepTagsRowsProps {
  data: DoctorReportQueryQuery;
}

const DoctorReportSleepTagsRows: React.FunctionComponent<IDoctorReportSleepTagsRowsProps> = (props) => {
  const summary = useSleepTagAndTechniqueSummary(props.data);
  if (!summary) return null;

  const selectedTagsKeys = ['all', 'bathroom', 'pain', 'dream', 'temperature', 'partner', 'light', 'noise'].filter(
    (key) => summary[key]?.count
  ) as (keyof SleepTagAndTechniqueSummary)[];
  const selectedTechniqueKeys = [
    'all',
    'light_therapy',
    'stimulus_control',
    'challenge_catastrophic_thinking',
    'behavior_activation',
    'gratitude',
    'thought_block',
    'paradoxical_intention',
    'parking_lot',
    'autogenic_training',
    'imagery',
    'pmr',
    'meditation',
    'deep_breath',
  ].filter((key) => summary[key]?.count) as (keyof SleepTagAndTechniqueSummary)[];
  const selectedSleepHygieneKeys = ['all', 'alcohol', 'caffeine', 'nap', 'exercise', 'nicotine', 'meal', 'medicine'].filter(
    (key) => summary[key]?.count
  ) as (keyof SleepTagAndTechniqueSummary)[];
  const selectedMedicineKeys = Object.keys(summary).filter(
    (key) =>
      !selectedTagsKeys.includes(key) &&
      !selectedTechniqueKeys.includes(key) &&
      !selectedSleepHygieneKeys.includes(key) &&
      summary[key]?.count
  );

  return (
    <>
      {selectedTagsKeys.length > 0 && (
        <Row mb>
          <Col xs={12}>
            <RelativeWrapper>
              <H2>Comparativo de interruptores</H2>
              <TagAndTechniqueComparatorTable summary={summary} tableKeys={selectedTagsKeys} showColName={true} maxColWidth={155} />
            </RelativeWrapper>
          </Col>
        </Row>
      )}

      {selectedTechniqueKeys.length > 0 && (
        <Row mb>
          <Col xs={12}>
            <RelativeWrapper>
              <H2>Comparativo de técnicas</H2>
              <TagAndTechniqueComparatorTable summary={summary} tableKeys={selectedTechniqueKeys} showColName={true} />
            </RelativeWrapper>
          </Col>
        </Row>
      )}

      {selectedSleepHygieneKeys.length > 0 && (
        <Row mb>
          <Col xs={12}>
            <RelativeWrapper>
              <H2>Comparativo de fatores da higiene do sono</H2>
              <TagAndTechniqueComparatorTable summary={summary} tableKeys={selectedSleepHygieneKeys} showColName={true} />
            </RelativeWrapper>
          </Col>
        </Row>
      )}

      {selectedMedicineKeys.length > 0 && (
        <Row mb>
          <Col xs={12}>
            <RelativeWrapper>
              <H2>Comparativo de medicamentos</H2>
              <TagAndTechniqueComparatorTable summary={summary} tableKeys={selectedMedicineKeys} showColName={false} />
              <BodySmall>
                {selectedMedicineKeys.map((medicineName) => {
                  return (
                    <React.Fragment key={medicineName}>
                      <strong>{getMedicineAcronym(medicineName)}</strong>
                      {`: ${medicineName}; `}
                    </React.Fragment>
                  );
                })}
              </BodySmall>
            </RelativeWrapper>
          </Col>
        </Row>
      )}
    </>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////
interface IDoctorReportUserInfoRowProps {
  user: GetUserInfoInput;
  printMode: boolean;
  /** success or error */
  onImageLoad: () => void;
  actionButtons: JSX.Element[];
}
const DoctorReportUserInfoRow: React.FunctionComponent<IDoctorReportUserInfoRowProps> = (props) => {
  if (!props.user) {
    return null;
  }

  const userInfo = getUserInfo(props.user);

  const buttonSize = 1;
  const printMode = props.printMode;
  const reportSize = 10 - (printMode ? 0 : props.actionButtons.length * buttonSize);

  return (
    <>
      <Row mt mb center="xs">
        <Col xs={printMode ? 2 : 8} sm={2}>
          <LazyLoadImage
            src={userInfo.img}
            alt={`imagem de ${userInfo.name}`}
            fitWidth
            onError={props.onImageLoad}
            onLoad={props.onImageLoad}
          />
        </Col>

        <Col xs={printMode ? reportSize : 12} sm={reportSize}>
          <Separator />
          <H1>
            Relatório d{userInfo.genderSuffix}{' '}
            <Mark color={config.hidePersonalInfo ? GrayColor.Black : 'transparent'}>{userInfo.name}</Mark>
          </H1>
          <div>
            {userInfo.tags &&
              userInfo.tags.map((item) => (
                <InlineBlockStyled mb key={item}>
                  <ReadOnlyBadge kind="primary">{item}</ReadOnlyBadge>
                </InlineBlockStyled>
              ))}
          </div>
          <Separator />
          <Row>
            {getReportDdDtInfo(userInfo).map((l) => (
              <PatientListColStyled key={l.dt}>
                <Separator />
                <DT>{l.dt}</DT>
                <Separator />
                <DD role="tooltip" className="microtip" data-microtip-position="bottom" data-microtip-size="large" aria-label={l.tooltip}>
                  {l.dd}
                  {l.tooltip && (
                    <>
                      {' '}
                      <FaIcon.Question />
                    </>
                  )}
                </DD>
              </PatientListColStyled>
            ))}
          </Row>
        </Col>
      </Row>
      {userInfo.toolbox?.length > 0 && (
        <Row mb>
          <Col xs={12}>
            <H2>Caixa de ferramentas</H2>
            <BodySmall>
              Principais ferramentas (treinos de relaxamento, técnicas cognitivas e comportamentais) d{userInfo.genderSuffix}{' '}
              {userInfo.firsName}.
            </BodySmall>
            <Separator />
            <div>
              {userInfo.toolbox.map((item) => (
                <InlineBlockStyled mb key={item}>
                  <ReadOnlyBadge kind="primary">{item}</ReadOnlyBadge>
                </InlineBlockStyled>
              ))}
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

function getReportDdDtInfo(userInfo: {
  age?: any;
  signUpDate: string;
  numberOfSessions: number;
  completedSessions: number;
  sessionsDoneDetail: { journeyName: string; sessionsDone: number; allSessions: number }[];
}) {
  return [
    userInfo.age && { dt: 'Idade', dd: `${userInfo.age} anos` },
    { dt: 'Início', dd: userInfo.signUpDate },
    {
      dt: 'Número de sessões',
      dd: `${userInfo.completedSessions}/${userInfo.numberOfSessions}`,
      tooltip: userInfo.sessionsDoneDetail.map((s) => `• ${s.sessionsDone}/${s.allSessions} na jornada "${s.journeyName}"`).join('\n'),
    },
  ].filter((u) => u);
}

////////////////////////////////////////////////////////////////////////////////////////////////////
type GetUserInfoInput = DoctorReportQueryQuery['user'][0];

interface GetUserInfoOutput {
  genderSuffix: string;
  age?: string;
  img?: string;
  firsName: string;
  lastName?: string;
  name: string;
  signUpDate: string;
  numberOfSessions: number;
  tags: string[];
  toolbox: string[];
  completedSessions: number;
  sessionsDoneDetail: {
    journeyName: string;
    sessionsDone: number;
    allSessions: number;
  }[];
}

function getUserInfo(user: GetUserInfoInput): GetUserInfoOutput {
  const firsName = user.first_name;
  const lastName = user.last_name;
  const name = formatName(firsName, lastName);
  const img = config.mockImg || user.profile_picture;
  const psqi = user.psqi;
  const isi = user.isi;

  const age = config.mockAge || Math.max(0, ...psqi?.map((item) => item.age), ...isi?.map((item) => item.age));

  const signUpDate = parseDateToDDMMYYYY(parseToDate(user.created_at));
  const genderSuffix = user.gender?.toLowerCase().startsWith('f') ? 'a' : 'o';

  const { sessionsDone, allSessions, detail } = getProgramProgress(user);
  const numberOfSessions = allSessions;
  const completedSessions = sessionsDone;

  // SMELL_ALERT: the following code assumes that all tags have the format '<id>::<name>'
  const tags = [
    ...user.user_tags.map((item) => item.tag.split('::')[1]),
    user.day_goal && `Quer melhorar ${getTitleForDayGoal(user.day_goal).toLowerCase()}`,
    user.night_goal && `Quer ${getTitleForNightGoal(user.night_goal).toLowerCase()}`,
  ].filter((item) => item);

  const toolbox = getFormattedTechniques(user.toolbox)
    .filter((item) => item.insideToolbox)
    .map((item) => `${item.name} ${item.emoji}`);

  return {
    genderSuffix,
    age,
    img,
    firsName,
    lastName,
    name,
    signUpDate,
    numberOfSessions,
    tags,
    toolbox,
    completedSessions,
    sessionsDoneDetail: detail,
  };
}

////////////////////////////////////////////////////////////////////////////////////////////////////
interface IDoctorReportPsqiProps {
  psqi: {
    created_at: string;
    total: number;
    timeInBedInMinutes: number;
    timeSleepInMinutes: number;
    eficiency: number;
  }[];
}
const DoctorReportPsqi: React.FunctionComponent<IDoctorReportPsqiProps> = (props) => {
  if (!props.psqi || !props.psqi.length) {
    return null;
  }
  return (
    <>
      <H2> PSQI</H2>
      <Body>Índice de Qualidade do Sono de Pittsburgh</Body>
      <Table>
        <THead>
          <TR>
            <TH borderBottom={true}>Data</TH>
            <TH borderBottom={true} textAlign="right">
              Pontuação
            </TH>
            <TH borderBottom={true} textAlign="right">
              TTC
            </TH>
            <TH borderBottom={true} textAlign="right">
              TTS
            </TH>
            <TH borderBottom={true} textAlign="right">
              Eficiência
            </TH>
          </TR>
        </THead>

        {props.psqi &&
          props.psqi.map((item) => {
            const eficiency = (item.timeSleepInMinutes / item.timeInBedInMinutes) * 100;
            return (
              <TR key={item.created_at}>
                <TD> {parseDateToShortString(parseToDate(item.created_at))}</TD>
                <TD textAlign="right" mono>
                  {item.total}
                </TD>
                <TD textAlign="right" mono>
                  {toHour(item.timeInBedInMinutes)}
                </TD>
                <TD textAlign="right" mono>
                  {toHour(item.timeSleepInMinutes)}
                </TD>
                <TD textAlign="right" mono>
                  <EfficiencyColorStyled value={eficiency}>{eficiency.toFixed(1)}%</EfficiencyColorStyled>
                </TD>
              </TR>
            );
          })}
      </Table>
      <Separator />
      <BodySmall>
        TTC: tempo total de cama; <br />
        TTS: tempo total de sono; <br />
        Pontuação: 0-5 - sono de boa qualidade; 6-21 - sono de baixa à péssima qualidade
      </BodySmall>
    </>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////
interface IDoctorReportIsiProps {
  isi: {
    points: number;
    created_at: string;
  }[];
}
const DoctorReportIsi: React.FunctionComponent<IDoctorReportIsiProps> = (props) => {
  if (!props.isi || !props.isi.length) {
    return null;
  }
  return (
    <>
      <H2> IGI </H2>
      <Body>Índice de Gravidade de Insônia</Body>
      <Table>
        <THead>
          <TR>
            <TH borderBottom={true}>Data</TH>
            <TH borderBottom={true} textAlign="right">
              Pontuação
            </TH>
          </TR>
        </THead>

        {props.isi &&
          props.isi.map((item) => {
            return (
              <TR key={item.created_at}>
                <TD>{parseDateToShortString(parseToDate(item.created_at))}</TD>
                <TD textAlign="right" mono>
                  {item.points}
                </TD>
              </TR>
            );
          })}
      </Table>
      <Separator />
      <BodySmall>
        Pontuação: 0-7 sem sintoma de insônia significativo; 8-14 insônia leve; 15-21 insônia clínica moderada; 22-28 insônia clínica grave
      </BodySmall>
    </>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////
interface IDoctorReportGadProps {
  gad: {
    points: number;
    created_at: string;
  }[];
}
const DoctorReportGad: React.FunctionComponent<IDoctorReportGadProps> = (props) => {
  if (!props.gad || !props.gad.length) {
    return null;
  }
  return (
    <>
      <H2> GAD-2 </H2>
      <Body>Generalized Anxiety Disorder 2-item (Ansiedade)</Body>
      <Table>
        <THead>
          <TR>
            <TH borderBottom={true}>Data</TH>
            <TH borderBottom={true} textAlign="right">
              Pontuação
            </TH>
          </TR>
        </THead>

        {props.gad &&
          props.gad.map((item) => {
            return (
              <TR key={item.created_at}>
                <TD>{parseDateToShortString(parseToDate(item.created_at))}</TD>
                <TD textAlign="right" mono>
                  {item.points}
                </TD>
              </TR>
            );
          })}
      </Table>
      <Separator />
      <BodySmall>Pontuação: 0-2 - sem sintoma ansioso significativo; 3-6 - possível transtorno de ansiedade</BodySmall>
    </>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////
interface IDoctorReportGad7Props {
  gad7: {
    points: number;
    created_at: string;
  }[];
}
const DoctorReportGad7: React.FunctionComponent<IDoctorReportGad7Props> = (props) => {
  if (!props.gad7 || !props.gad7.length) {
    return null;
  }
  return (
    <>
      <H2> GAD-7 </H2>
      <Body>Generalized Anxiety Disorder 7-item (Ansiedade)</Body>
      <Table>
        <THead>
          <TR>
            <TH borderBottom={true}>Data</TH>
            <TH borderBottom={true} textAlign="right">
              Pontuação
            </TH>
          </TR>
        </THead>

        {props.gad7 &&
          props.gad7.map((item) => {
            return (
              <TR key={item.created_at}>
                <TD>{parseDateToShortString(parseToDate(item.created_at))}</TD>
                <TD textAlign="right" mono>
                  {item.points}
                </TD>
              </TR>
            );
          })}
      </Table>
      <Separator />
      <BodySmall>
        Pontuação: 0-5 não tem sintomas de ansiedade significativo; 6-10 ansiedade leve; 11-15 ansiedade em grau moderado; 16-21 sintomas
        graves de ansiedade
      </BodySmall>
    </>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////
interface IDoctorReportKuppermanProps {
  kupperman: {
    points: number;
    created_at: string;
  }[];
}

const DoctorReportKupperman: React.FunctionComponent<IDoctorReportKuppermanProps> = (props) => {
  if (!props.kupperman || !props.kupperman.length) {
    return null;
  }
  return (
    <>
      <H2> Índice de Kupperman </H2>
      <Body> Avaliação de intensidade dos sintomas climatéricos (Menopausa)</Body>
      <Table>
        <THead>
          <TR>
            <TH borderBottom={true}>Data</TH>
            <TH borderBottom={true} textAlign="right">
              Pontuação
            </TH>
          </TR>
        </THead>

        {props.kupperman &&
          props.kupperman.map((item) => {
            return (
              <TR key={item.created_at}>
                <TD>{parseDateToShortString(parseToDate(item.created_at))}</TD>
                <TD textAlign="right" mono>
                  {item.points}
                </TD>
              </TR>
            );
          })}
      </Table>
      <Separator />
      <BodySmall>
        Pontuação: 0-6 não tem sintomas climatéricos significativo; 7-15 sintomas com grau leve; 16-30 sintomas com grau moderado; 30-57
        sintomas climatéricos intensos
      </BodySmall>
    </>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////
interface IDoctorReportPhqProps {
  phq: {
    points: number;
    created_at: string;
  }[];
}
const DoctorReportPhq: React.FunctionComponent<IDoctorReportPhqProps> = (props) => {
  if (!props.phq || !props.phq.length) {
    return null;
  }
  return (
    <>
      <H2> PHQ-2 </H2>
      <Body>Patient Health Questionnaire-2 (Depressão)</Body>
      <Table>
        <THead>
          <TR>
            <TH borderBottom={true}>Data</TH>
            <TH borderBottom={true} textAlign="right">
              Pontuação
            </TH>
          </TR>
        </THead>

        {props.phq &&
          props.phq.map((item) => {
            return (
              <TR key={item.created_at}>
                <TD>{parseDateToShortString(parseToDate(item.created_at))}</TD>
                <TD textAlign="right" mono>
                  {item.points}
                </TD>
              </TR>
            );
          })}
      </Table>
      <Separator />
      <BodySmall>Pontuação: 0-2 - sem sintoma depressivo significativo; 3-6 - possível transtorno de depressão</BodySmall>
    </>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////
interface IDoctorReportPhq9Props {
  phq9: {
    points: number;
    created_at: string;
  }[];
}
const DoctorReportPhq9: React.FunctionComponent<IDoctorReportPhq9Props> = (props) => {
  if (!props.phq9 || !props.phq9.length) {
    return null;
  }

  return (
    <>
      <H2> PHQ-9 </H2>
      <Body>Patient Health Questionnaire-9 (Depressão)</Body>
      <Table>
        <THead>
          <TR>
            <TH borderBottom={true}>Data</TH>
            <TH borderBottom={true} textAlign="right">
              Pontuação
            </TH>
          </TR>
        </THead>

        {props.phq9 &&
          props.phq9.map((item) => {
            return (
              <TR key={item.created_at}>
                <TD>{parseDateToShortString(parseToDate(item.created_at))}</TD>
                <TD textAlign="right" mono>
                  {item.points}
                </TD>
              </TR>
            );
          })}
      </Table>
      <Separator />
      <BodySmall>
        Pontuação: 0-4 - sem sintomas depressivos significativo; 5-9 - sintomas depressivos em um grau leve; 10-14 - sintomas depressivos em
        um grau moderado; 15-19 - sintomas de depressão em um grau moderado a grave; 20-27 - sintomas graves de depressão
      </BodySmall>
    </>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////

interface IDoctorReportEpworthProps {
  epworth: {
    points: number;
    created_at: string;
  }[];
}

const DoctorReportEpworth: React.FunctionComponent<IDoctorReportEpworthProps> = (props) => {
  if (!props.epworth || !props.epworth.length) {
    return null;
  }
  return (
    <>
      <H2> Epworth </H2>
      <Body>Escala de Sonolência de Epworth</Body>
      <Table>
        <THead>
          <TR>
            <TH borderBottom={true}>Data</TH>
            <TH borderBottom={true} textAlign="right">
              Pontuação
            </TH>
          </TR>
        </THead>

        {props.epworth &&
          props.epworth.map((item) => {
            return (
              <TR key={item.created_at}>
                <TD>{parseDateToShortString(parseToDate(item.created_at))}</TD>
                <TD textAlign="right" mono>
                  {item.points}
                </TD>
              </TR>
            );
          })}
      </Table>
      <Separator />
      <BodySmall>
        Pontuação: 0-7 - sono normal; 8-9 - sono médio; 10-15 - sonolência excessiva leve a moderada; 16-24 - sonolência excessiva grave
      </BodySmall>
    </>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////
interface IDoctorReportStopBangProps {
  stopBang: {
    points: number;
    created_at: string;
  }[];
}

const DoctorReportStopBang: React.FunctionComponent<IDoctorReportStopBangProps> = (props) => {
  if (!props.stopBang || !props.stopBang.length) {
    return null;
  }
  return (
    <>
      <H2> Stop-Bang </H2>
      <Body>Questionário Stop-Bang (Apneia do Sono)</Body>
      <Table>
        <THead>
          <TR>
            <TH borderBottom={true}>Data</TH>
            <TH borderBottom={true} textAlign="right">
              Pontuação
            </TH>
          </TR>
        </THead>

        {props.stopBang &&
          props.stopBang.map((item) => {
            return (
              <TR key={item.created_at}>
                <TD>{parseDateToShortString(parseToDate(item.created_at))}</TD>
                <TD textAlign="right" mono>
                  {item.points}
                </TD>
              </TR>
            );
          })}
      </Table>
      <Separator />
      <BodySmall>
        Pontuação: 0-2 - baixo risco de apneia do sono; 3-4 - risco intermediário de apneia do sono; 5-8 - alto risco de apneia do sono
      </BodySmall>
    </>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////
interface IDoctorReportSummaryTableProps {
  intervals: SleepRestrictionCycles[];
}

interface DoctorReportSummaryTableInfo {
  interval: JSX.Element;
  go_bed: JSX.Element;
  suggestedTTC: string;
  tcc: string;
  tts: string;
  eficiency: JSX.Element;
  medicine: JSX.Element;
  latency: JSX.Element;
  wake_up: JSX.Element;
  WASO: JSX.Element;
}

const DoctorReportSummaryTable: React.FunctionComponent<IDoctorReportSummaryTableProps> = (props) => {
  const tableMap = props.intervals.map((i) => mapToSleepRestrictionAnalysis(i));

  const doctorReportData = tableMap.map((row) => {
    return {
      interval: (
        <TD mono>
          <strong>{row.name}</strong>
          <br />
          {parseDateToShortString(row.start)} - {parseDateToShortString(row.end)} <br /> ({row.daysCount} dias)
        </TD>
      ),
      go_bed: (
        <>
          {row.filledDaysCount}
          <TablePercentage total={row.daysCount} count={row.filledDaysCount} />
        </>
      ),
      suggestedTTC: row.suggestedTTC ? toHour(row.suggestedTTC) : '-',
      tcc: toHour(row.avgMinutesInBed),
      tts: toHour(row.avgMinutesSleep),
      eficiency: <EfficiencyColorStyled value={row.avgEficiency}>{row.avgEficiency.toFixed(1)}%</EfficiencyColorStyled>,
      medicine: (
        <TD textAlign="right" mono>
          {row.daysWithMedicationCount} <br />
          <TablePercentage total={row.daysCount} count={row.daysWithMedicationCount} />
        </TD>
      ),
      latency: (
        <TD textAlign="right" mono>
          {row.daysWithHighLatency} <br />
          <TablePercentage total={row.daysCount} count={row.daysWithHighLatency} />
        </TD>
      ),
      wake_up: (
        <TD textAlign="right" mono>
          {row.daysWithWakeCount} <br />
          <TablePercentage total={row.daysCount} count={row.daysWithWakeCount} />
        </TD>
      ),
      WASO: (
        <TD textAlign="right" mono>
          {row.daysWithHighWaso} <br />
          <TablePercentage total={row.daysCount} count={row.daysWithHighWaso} />
        </TD>
      ),
    };
  });

  const tableProps: TableWithFixedHeaderProps<DoctorReportSummaryTableInfo> = {
    headerConfig: {
      interval: { title: 'Intervalo', colWidth: '14%', textAlign: 'left' },
      go_bed: { title: 'Diários preenchidos', colWidth: '9%', textAlign: 'right' },
      suggestedTTC: { title: 'TTC sugerido', colWidth: '9%', textAlign: 'right' },
      tcc: { title: 'TTC', colWidth: '9%', textAlign: 'right' },
      tts: { title: 'TTS', colWidth: '9%', textAlign: 'right' },
      eficiency: { title: 'Eficiência', colWidth: '10%', textAlign: 'right' },
      medicine: { title: 'remédio', colWidth: '10%', textAlign: 'right' },
      latency: { title: 'latência aumentada', colWidth: '10%', textAlign: 'right' },
      wake_up: { title: 'despertar', colWidth: '10%', textAlign: 'right' },
      WASO: { title: 'WASO aumentado', colWidth: '10%', textAlign: 'right' },
    },
    data: doctorReportData,
  };
  return (
    <>
      <TableWithFixedHeader {...tableProps} />
      <Separator />
      <BodySmall>
        TTC: tempo total de cama; <br />
        TTS: tempo total de sono; <br />
        WASO: minutos acordado após início de sono; <br />
        Uma latência considerada alta é aquela maior que 30 minutos
      </BodySmall>
    </>
  );
};

interface TableIPercentageProps {
  count: number;
  total: number;
}
const TablePercentage: React.FunctionComponent<TableIPercentageProps> = (props) => {
  if (!props.total) return null;
  return <BodySmall> ({`${Math.round((props.count / props.total) * 100)}`.slice(-3)}%) </BodySmall>;
};

//////////////////////////////////////////////////////////////////
interface IDoctorReportCommentsProps {
  diaries: DoctorReportQueryQuery['sleep_diary'];
}

const DoctorReportComments: React.FunctionComponent<IDoctorReportCommentsProps> = (props) => {
  const comments = props.diaries?.filter((item) => item.comment);
  if (!comments || !comments.length) {
    return null;
  }
  return (
    <>
      <PageBreakStyled />
      <H2> Comentários do diário </H2>
      <MultipleCollumnWrapper numberOfCollumns={2}>
        <ul>
          {comments.reverse().map((item) => (
            <li key={item.date}>
              <strong>{parseDateToShortString(parseToDate(item.date))}</strong>:{' '}
              <q>
                <Mark color={config.hidePersonalInfo ? GrayColor.Black : 'transparent'}>
                  {config.hidePersonalInfo
                    ? // https ://stackoverflow.com/a/41543705/3670829
                      item.comment.replace(
                        /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
                        ''
                      )
                    : item.comment}
                </Mark>
              </q>
            </li>
          ))}
        </ul>
      </MultipleCollumnWrapper>
    </>
  );
};

//////////////////////////////////////////////////////////////////

interface SleepDiaryData {
  date: string;
  go_bed: string;
  go_sleep: string;
  time_to_sleep: string;
  wake_up_count: string | JSX.Element;
  wake_up: string;
  get_up: string;
  tags: string | JSX.Element;
  minutesInBed: string;
  minutesSleep: string;
  eficiency: string | JSX.Element;

  dateRaw: Date;
}

interface DoctorReportDiaryTableProps {
  diaries: DoctorReportQueryQuery['sleep_diary'];
}

const DoctorReportDiaryTable: React.FC<DoctorReportDiaryTableProps> = (props) => {
  const getHour = (date: string, time: string, timezone: number) => {
    const d = parseToDate(`${date} ${time}`);
    const browserTimezone = d.getTimezoneOffset() / 60;
    const hourAdjustment = Math.abs(timezone) > 60 ? timezone / 60 : timezone;
    d.setHours(d.getHours() + browserTimezone + hourAdjustment);
    return `${twoDigit(d.getHours())}:${twoDigit(d.getMinutes())}`;
  };

  const sleepDiary: SleepDiaryData[] = props.diaries.map((item) => {
    const analysis = getSleepDiaryAnalysis({
      goBed: item.go_bed,
      goSleep: item.go_sleep,
      timeToSleep: item.time_to_sleep,
      wakeUpDuration: item.wake_up_duration,
      wakeUp: item.wake_up,
      getUp: item.get_up,
    });
    const dateRaw = parseToDate(item.date);
    return {
      date: parseDateToShortString(dateRaw),
      dateRaw,
      go_bed: getHour(item.date, item.go_bed, item.timezone),
      go_sleep: getHour(item.date, item.go_sleep, item.timezone),
      time_to_sleep: item.time_to_sleep > 0 ? toHour(item.time_to_sleep) : '-',
      wake_up_count:
        item.wake_up_count > 0 ? (
          <>
            {toHour(item.wake_up_duration)} <br />
            <BodySmall>
              {item.wake_up_count} vez{item.wake_up_count > 1 ? 'es' : ''}
            </BodySmall>
          </>
        ) : (
          '-'
        ),
      wake_up: getHour(item.date, item.wake_up, item.timezone),
      get_up: getHour(item.date, item.get_up, item.timezone),
      tags: <SleepDiaryTagTd textAlign="left" diary={item} />,
      minutesInBed: toHour(analysis.minutesInBed),
      minutesSleep: toHour(analysis.minutesSleep),
      eficiency: <EfficiencyColorStyled value={analysis.eficiency}>{analysis.eficiency}%</EfficiencyColorStyled>,
    };
  });

  const tableProps: TableWithFixedHeaderProps<SleepDiaryData> = {
    headerConfig: {
      date: { title: 'Data', colWidth: '5%', textAlign: 'left' },
      go_bed: { title: 'Foi para cama', colWidth: '9%', textAlign: 'right' },
      go_sleep: { title: 'Apagou a luz', colWidth: '9%', textAlign: 'right' },
      time_to_sleep: { title: 'Latência', colWidth: '9%', textAlign: 'right' },
      wake_up_count: { title: 'Tempo acordado no meio da noite', colWidth: '9%', textAlign: 'right' },
      wake_up: { title: 'Acordou', colWidth: '9%', textAlign: 'right' },
      get_up: { title: 'Saiu da cama', colWidth: '9%', textAlign: 'right' },
      tags: { title: 'Tags', colWidth: '14%', textAlign: 'left' },
      minutesInBed: { title: 'Tempo de cama', colWidth: '9%', textAlign: 'right' },
      minutesSleep: { title: 'Tempo de sono', colWidth: '9%', textAlign: 'right' },
      eficiency: { title: 'Eficiência', colWidth: '9%', textAlign: 'right' },
      dateRaw: { skip: true },
    },
    cellStyle: (d) => ({
      backgroundColor: isWeekend(d.dateRaw) ? GrayColor.Neutral : undefined,
      paddingLeft: Spacing.Small,
    }),
    headerStyle: () => ({ paddingLeft: Spacing.Small }),
    data: sleepDiary,
  };
  return (
    <>
      <H2>Dados dos diários do sono</H2>
      <TableWithFixedHeader<SleepDiaryData> {...tableProps} />
    </>
  );
};

function isWeekend(date: Date): boolean {
  const day = date.getDay();
  return day === 0 || day === 6;
}
