import { isNullOrUndefined } from '../object/null-or-undefined';

export class PagarmeTaxes {
  // Crédito à vista: 3,43%
  static creditCardTax = 3.43;

  // Crédito de 2 a 6 vezes: 3,59%
  static creditCard2To6Installmentes = 3.59;

  // Crédito de 7 a 12 vezes: 3,92%
  static creditCard7To12Installmentes = 3.92;

  // Taxas fixas de R$ 0.99
  static fixedTaxInCents = 99;

  // Antecipação: 2,80%
  static anticipation = 2.8;

  // PIX: 1,19%
  static pixTax = 1.19;

  // Boleto: R$ 3,49
  static boletoTax = 3.49;

  // Saque R$ 3,67 (isento para Banco Bradesco)
  private withdrawTax = 3.67;
  private bankIdWithNoWithdrawTax = 237;

  // in R$
  getWithdrawTaxForBankCode = (code: string | number): number => (+code === this.bankIdWithNoWithdrawTax ? 0 : this.withdrawTax);
}

/**
 * to understand all formulas, check this link https://www.notion.so/vigilantesdosono/pagarme-Dedu-o-da-f-rmula-da-parcela-a-ser-paga-de-modo-a-descontar-as-taxas-da-pagarme-para-que-fc3283f287a04b50a34c73eac9f94a02
 * this class is capable of computing the installment price in a way that we
 * always receive the same amount for any given installment number
 *
 * it can also:
 * - map the installment price to its original price
 * - compute pagarme taxes
 *
 */
export class PagarmeInstallmentPriceCalculator {
  private installmentCount: number;

  /**
   * since `computeInstallmentPrice` uses Math.round, then it is not an invertible function
   * Therefore, we store some values on "inverseTable" to compute the originalPrice back
   */
  private static inverseTable = {
    // 14999
    '1_14999': 14999,
    '2_7626': 14999,
    '3_5163': 14999,
    '4_3933': 14999,
    '5_3196': 14999,
    '6_2706': 14999,
    '7_2367': 14999,
    '8_2106': 14999,
    '9_1904': 14999,
    '10_1743': 14999,
    '11_1613': 14999,
    '12_1505': 14999,

    // 19999
    '1_19999': 19999,
    '2_10169': 19999,
    '3_6884': 19999,
    '4_5244': 19999,
    '5_4262': 19999,
    '6_3609': 19999,
    '7_3156': 19999,
    '8_2808': 19999,
    '9_2539': 19999,
    '10_2324': 19999,
    '11_2150': 19999,
    '12_2007': 19999,
  };

  constructor(installmentCount = 1) {
    this.installmentCount = installmentCount;
  }

  computeInstallmentPrice = (originalPriceInCents: number) => {
    const result = Math.round((originalPriceInCents * this.getMultiplierFactor(1)) / this.getMultiplierFactor(this.installmentCount));
    PagarmeInstallmentPriceCalculator.inverseTable[this.getInverseTableKey(result)] = originalPriceInCents;
    return result;
  };

  computeNewPrice = (originalPriceInCents: number) => {
    return this.computeInstallmentPrice(originalPriceInCents) * this.installmentCount;
  };

  mapToOriginalPrice = (installmentPriceInCents: number) => {
    return (
      PagarmeInstallmentPriceCalculator.inverseTable[this.getInverseTableKey(installmentPriceInCents)] ||
      Math.round((installmentPriceInCents * this.getMultiplierFactor(this.installmentCount)) / this.getMultiplierFactor(1))
    );
  };

  /**
   * value_to_receive = value_paid - taxes
   * https://www.notion.so/vigilantesdosono/pagarme-Dedu-o-da-f-rmula-da-parcela-a-ser-paga-de-modo-a-descontar-as-taxas-da-pagarme-para-que-fc3283f287a04b50a34c73eac9f94a02#a161741cdec44796a27343d70003c053
   * @param totalPriceInCents
   * @returns
   */
  computeValueToReceive = (totalPriceInCents: number) => {
    const taxes =
      (100 * PagarmeTaxes.fixedTaxInCents +
        (this.getCreditCardTax(this.installmentCount) + (PagarmeTaxes.anticipation * (this.installmentCount + 1)) / 2) *
          totalPriceInCents) /
      100;
    return Math.round(totalPriceInCents - taxes);
  };

  /**
   * to understand this formula, check this link https://www.notion.so/vigilantesdosono/pagarme-Dedu-o-da-f-rmula-da-parcela-a-ser-paga-de-modo-a-descontar-as-taxas-da-pagarme-para-que-fc3283f287a04b50a34c73eac9f94a02#0974075cb70c48e497a31442eca3699a
   */
  private getMultiplierFactor = (installmentCount: number) =>
    installmentCount *
    (1 - this.getCreditCardTax(installmentCount) / 100 - (((installmentCount + 1) / 2) * PagarmeTaxes.anticipation) / 100);

  /**
   * computes credit card taxes (defined by pagarme)
   * @param installmentCount
   * @returns
   */
  private getCreditCardTax = (installmentCount: number) => {
    if (installmentCount === 1) return PagarmeTaxes.creditCardTax;
    else if (installmentCount <= 6) return PagarmeTaxes.creditCard2To6Installmentes;
    else if (installmentCount <= 12) return PagarmeTaxes.creditCard7To12Installmentes;
  };

  private getInverseTableKey(value: number) {
    return `${this.installmentCount}_${value}`;
  }
}
