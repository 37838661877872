import { diactriclessKebabCase } from '@global/utils/string/case';

export const getCanonicalUrl = (siteUrl: string, path: string, detailSlug = ''): string => {
  if (!siteUrl) throw new Error(`invalid siteUrl`);
  return normalizeUrl(`${siteUrl}${path}/${detailSlug}`);
};

export const getDetailPageUrl = (typeSlug: string, pageSlug: string, subItemSlug = ''): string => {
  if (!typeSlug || !pageSlug) {
    console.error('getDetailPageUrl::error: Invalid input: ', 'typeSlug', typeSlug, 'pageSlug', pageSlug, 'subItemSlug', subItemSlug);
  }
  return normalizeUrl(`/${typeSlug.trim()}/${diactriclessKebabCase(pageSlug)}/${diactriclessKebabCase(subItemSlug)}`);
};

export const normalizeUrl = (url: string): string => {
  if (!url) {
    return url;
  }

  // remove duplicated `/`: https://stackoverflow.com/questions/24381480/remove-duplicate-forward-slashes-from-the-url
  let normalizedUrl = url.replace(/([^:]\/)\/+/g, '$1');

  if (/^\/\//.test(normalizedUrl)) {
    normalizedUrl = normalizedUrl.slice(1);
  }

  // SEO improvement: add last `/` char if needed (to improve url consistency)
  if (normalizedUrl[normalizedUrl.length - 1] !== '/') {
    return `${normalizedUrl}/`;
  }

  return normalizedUrl;
};

export const isExternalUrl = (url: string): boolean => {
  return url.startsWith('http');
};
