import * as React from 'react';
import {
  TableColumnStyled,
  TableHeaderStyled,
  TableHeadStyled,
  TableRowStyled,
  TableStyled,
  TableWrapperStyled,
  TableWrapperFixedStyled,
  TableHeaderFixedStyled,
  TableHeaderSticky,
  THTableWithFixedHeader,
  HeaderTextTableWithFixedHeader,
  BodyTextTableWithFixedHeader,
  THBodyWithFixedHeader,
  TableBodyFixedStyled,
} from './table.component.style';
import { ScrollSync, ScrollSyncNode } from 'scroll-sync-react';
import { TdProps, THProps, TableWithFixedHeaderProps, TableWithFixedHeader_Config } from './table.component.utils';

export const TR = ({ children, ...other }): JSX.Element => <TableRowStyled {...other}>{children}</TableRowStyled>;
export const TD = (props: TdProps): JSX.Element => <TableColumnStyled {...props}>{props.children}</TableColumnStyled>;
export const THead = (props: any): JSX.Element => <TableHeadStyled>{props.children}</TableHeadStyled>;
THead.displayName = 'THead';
export const TH = (props: THProps): JSX.Element => <TableHeaderStyled {...props}>{props.children}</TableHeaderStyled>;

export class Table extends React.Component<{ marginLeft?; scrollable?: boolean }> {
  static defaultProps = {
    scrollable: true,
  };

  render() {
    const { header, body } = this.getTypedChildren();

    return (
      <TableWrapperStyled marginLeft={this.props.marginLeft} scrollable={this.props.scrollable}>
        <TableStyled>
          {header}
          <tbody>{body}</tbody>
        </TableStyled>
      </TableWrapperStyled>
    );
  }

  private getTypedChildren() {
    let header;
    const body = [];

    React.Children.forEach(this.props.children, (child: any) => {
      if (child && child.type && child.type.displayName) {
        switch (child.type.displayName) {
          case THead.displayName:
            header = child;
            break;
          default:
            body.push(child);
        }
      } else {
        body.push(child);
      }
    });

    return { header, body };
  }
}

export function TableWithFixedHeader<P>(props: TableWithFixedHeaderProps<P>): JSX.Element {
  const headerConfig = Object.keys(props.headerConfig).filter((col) => !props.headerConfig[col].skip) as Array<keyof P>;
  return (
    <>
      <ScrollSync>
        <TableWrapperFixedStyled>
          <TableHeaderSticky>
            <ScrollSyncNode>
              <TableHeaderFixedStyled>
                {headerConfig.map((key, i) => {
                  const headerConfig = props.headerConfig[key] as TableWithFixedHeader_Config;
                  return (
                    <THTableWithFixedHeader borderBottom={true} colWidth={headerConfig.colWidth} key={i} style={props.headerStyle?.(key)}>
                      <HeaderTextTableWithFixedHeader textAlign={headerConfig.textAlign}>
                        {headerConfig.title}
                      </HeaderTextTableWithFixedHeader>
                    </THTableWithFixedHeader>
                  );
                })}
              </TableHeaderFixedStyled>
            </ScrollSyncNode>
          </TableHeaderSticky>

          <ScrollSyncNode>
            <TableBodyFixedStyled>
              {headerConfig.map((key, i) => {
                const headerConfig = props.headerConfig[key] as TableWithFixedHeader_Config;
                return (
                  <THBodyWithFixedHeader colWidth={headerConfig.colWidth} key={i}>
                    {props.data.map((d, i) => {
                      return (
                        <BodyTextTableWithFixedHeader key={i} textAlign={headerConfig.textAlign} style={props.cellStyle?.(d)}>
                          {d[key]}
                        </BodyTextTableWithFixedHeader>
                      );
                    })}
                  </THBodyWithFixedHeader>
                );
              })}
            </TableBodyFixedStyled>
          </ScrollSyncNode>
        </TableWrapperFixedStyled>
      </ScrollSync>
    </>
  );
}
