import { useEffect } from 'react';

/**
 * Use this method if you want to set only the document title (at run-time).
 * If you need SSR or want to set mote info (on the <head>), use react-helmet instead
 * reference: https://stackoverflow.com/a/56385973/3670829
 * @param title
 */
export function useDocumentTitle(title: string): void {
  useEffect(() => {
    if (title) {
      document.title = title;
    }
  }, [title]);
}
