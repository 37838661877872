import { getTimeInMinutesChronologically } from '@global/utils/date/get-time-in-minutes-chronologically';
import { parseDDMMYYYYToDate } from '@global/utils/date/parse-to-date';
import { toMinutes } from '@global/utils/date/to-minutes';
import { isCnpjValid } from '@global/utils/domain/cnpj';
import { isCpfValid } from '@global/utils/domain/cpf';
import { HealthCarerKind } from '@global/utils/domain/health-carer/health-carer-factory';
import { validateProfessionalId } from '@global/utils/domain/health-carer/is-valid-crm';
import {
  isBrazilianNumberPhoneValid,
  isCardNumberValid,
  isCpfPatternValid,
  isDateValid,
  isEmailValid,
  isEmailValidStricter,
  isPasswordValid,
  isPathNameValid,
  isPhoneValid,
  isTimeValid,
  isZipCodeValid,
  parseCreditCardDate,
} from './regex-validator';

export interface Validator {
  error: ValidationError;
  validationFn: any;
}

export interface ValidationError {
  name: string;
  message: string;
}

export class Validators {
  static MaxLength(length: number, message: string): Validator {
    return {
      error: {
        name: 'MaxLength',
        message,
      },
      validationFn: (value: any) => {
        return value && value.toLocaleString().length <= length;
      },
    };
  }

  static MinLength(length: number, message: string): Validator {
    return {
      error: {
        name: 'MinLength',
        message,
      },
      validationFn: (value: any) => {
        return value && value.toLocaleString().length >= length;
      },
    };
  }

  static Required(message = 'Campo obrigatório'): Validator {
    return {
      error: {
        name: 'Required',
        message,
      },
      validationFn: (value: any) => {
        if (typeof value === 'string') {
          return value.length > 0;
        }
        return (value && Object.keys(value).length > 0) || value instanceof Date || !isNaN(value);
      },
    };
  }

  static Checked(message?: string): Validator {
    return {
      error: {
        name: 'Checked',
        message: message || 'Checked',
      },
      validationFn: (value: any) => {
        return value && Object.keys(value).length > 0;
      },
    };
  }

  static PathRegex(message: string): Validator {
    return {
      error: {
        name: 'PathRegex',
        message,
      },
      validationFn: (value: any) => {
        if (!value) {
          return true;
        }
        return isPathNameValid(value);
      },
    };
  }

  static EmailRegex(message: string): Validator {
    return {
      error: {
        name: 'EmailRegex',
        message,
      },
      validationFn: (value: any) => {
        if (!value) {
          return true;
        }
        return isEmailValid(value);
      },
    };
  }

  static EmailSubaddress(message = 'O e-mail não pode conter o símbolo "+"'): Validator {
    return {
      error: {
        name: 'EmailSubaddress',
        message,
      },
      validationFn: (value: any) => {
        return value.indexOf('+') === -1;
      },
    };
  }

  static StricterEmailRegex(message: string): Validator {
    return {
      error: {
        name: 'StricterEmailRegex',
        message,
      },
      validationFn: (value: any) => {
        if (!value) {
          return true;
        }
        return isEmailValidStricter(value);
      },
    };
  }

  static InThePast(message: string): Validator {
    return {
      error: {
        name: 'InThePast',
        message,
      },
      validationFn: (
        // DD/MM/YYYY
        value: string
      ) => {
        if (!value) {
          return true;
        }
        const parsedDate = parseDDMMYYYYToDate(value);
        if (!parsedDate) {
          return false;
        }
        return parsedDate < new Date();
      },
    };
  }

  static CpfPatternRegex(message: string): Validator {
    return {
      error: {
        name: 'Cpf pattern regex',
        message,
      },
      validationFn: (value: any) => {
        return isCpfPatternValid(value);
      },
    };
  }

  static CpfRegex(message: string): Validator {
    return {
      error: {
        name: 'Cpf regex',
        message,
      },
      validationFn: (value: any) => {
        return isCpfValid(value);
      },
    };
  }

  static CnpjPatternRegex(message: string): Validator {
    return {
      error: {
        name: 'Cnpj pattern regex',
        message,
      },
      validationFn: (value: any) => {
        return isCnpjValid(value);
      },
    };
  }

  static CpfOrCnpjValidation(message: string): Validator {
    return {
      error: {
        name: 'Cpf or Cnpj pattern regex',
        message,
      },
      validationFn: (value: any) => {
        return isCpfValid(value) || isCnpjValid(value);
      },
    };
  }

  static ZipCodeRegex(message: string): Validator {
    return {
      error: {
        name: 'Zip code regex',
        message,
      },
      validationFn: (value: any) => {
        return isZipCodeValid(value);
      },
    };
  }

  static PhoneRegex(message: string): Validator {
    return {
      error: {
        name: 'PhoneRegex',
        message,
      },
      validationFn: (value: any) => {
        if (!value) return true;
        return isPhoneValid(value);
      },
    };
  }

  static BrazilianPhoneRegex(message: string): Validator {
    return {
      error: {
        name: 'PhoneRegex',
        message,
      },
      validationFn: (value: any) => {
        return value && value.toString().startsWith('55') ? isBrazilianNumberPhoneValid(value) : true;
      },
    };
  }

  static PasswordRegex(message: string): Validator {
    return {
      error: {
        name: 'PasswordRegex',
        message,
      },
      validationFn: (value: any) => {
        return isPasswordValid(value);
      },
    };
  }

  static TimeRegex(message: string): Validator {
    return {
      error: {
        name: 'DateRegex',
        message,
      },
      validationFn: (value: any) => {
        return isTimeValid(value);
      },
    };
  }

  static DateRegex(message: string): Validator {
    return {
      error: {
        name: 'DateRegex',
        message,
      },
      validationFn: (value: any) => {
        return isDateValid(value);
      },
    };
  }

  static IsNotEqualToField(valueToCompare: any, message: string): Validator {
    return {
      error: {
        name: 'IsNotEqualToField',
        message,
      },
      validationFn: (value: any) => {
        return value === valueToCompare;
      },
    };
  }

  static MinTimeAsleep(message = 'Seu tempo de sono deu menor que 0 horas. Verifique os dados inseridos.'): Validator {
    return {
      error: {
        name: 'MinTimeAsleep',
        message,
      },
      validationFn: (value: number) => {
        return value >= 0;
      },
    };
  }

  static MaxTimeAsleep(
    message = 'Seu tempo de sono deu maior que 18 horas. Verifique os dados inseridos. Talvez você tenha que trocar algum horário para ser antes (AM) ou depois (PM) do meio-dia.'
  ): Validator {
    return {
      error: {
        name: 'MaxTimeAsleep',
        message,
      },
      validationFn: (value: number) => {
        const eighteenHoursInMinutes = 18 * 60;
        // BUSINESS_RULE: we consider people will never sleep more than 18 hours
        return value < eighteenHoursInMinutes;
      },
    };
  }

  static MinTimeInBed(message = 'Seu tempo de cama deu menor que 0 horas. Verifique os dados inseridos.'): Validator {
    return {
      error: {
        name: 'MinTimeInBed',
        message,
      },
      validationFn: (value: number) => {
        return value > 0;
      },
    };
  }

  static IsInteger(message = 'O número precisa ser inteiro (sem vírgulas)'): Validator {
    return {
      error: {
        name: 'IsInteger',
        message,
      },
      validationFn: (value: string) => {
        return Number.isInteger(+value);
      },
    };
  }

  static GreaterThanZero(message = 'Número deve ser positivo (maior que 0)'): Validator {
    return {
      error: {
        name: 'GreaterThanZero',
        message,
      },
      validationFn: (value: string | number) => {
        try {
          if (typeof value === 'string') {
            return value && parseFloat(value.replace(',', '.')) > 0;
          } else {
            return value > 0;
          }
        } catch (error) {
          console.error(`ERROR: validators.tsx:360 ~ Validators ~ GreaterThanZero ~ error:`, error);
          return false
        }
      },
    };
  }

  static NotNegative(message = 'O número não pode ser negativo'): Validator {
    return {
      error: {
        name: 'IsPositive',
        message,
      },
      validationFn: (value: string) => {
        return +value >= 0;
      },
    };
  }

  static MaxTimeInBed(
    message = 'Seu tempo de cama deu maior que 18 horas. Verifique os dados inseridos. Talvez você tenha que trocar algum horário para ser antes (AM) ou depois (PM) do meio-dia.'
  ): Validator {
    return {
      error: {
        name: 'MaxTimeInBed',
        message,
      },
      validationFn: (value: number) => {
        const eighteenHoursInMinutes = 18 * 60;
        // BUSINESS_RULE: we consider people will never stay at bed for more than 18 hours
        return value < eighteenHoursInMinutes;
      },
    };
  }

  static MinEfficiency(
    message = 'Sua eficiência deu menor que 0, ou seja, os dados dizem que você dormiu mais tempo que o que você passou na cama. Verifique os dados inseridos.'
  ): Validator {
    return {
      error: {
        name: 'MinEfficiencyValidation',
        message,
      },
      validationFn: (value: number) => {
        return value >= 0;
      },
    };
  }

  static TimeInBed(message = 'O seu tempo de cama deve ser maior que 5 horas', recommendedTimeInBed: number): Validator {
    return {
      error: {
        name: 'TimeInBedValidation',
        message,
      },
      validationFn: (value: string) => {
        const minutes = toMinutes(value);
        return minutes >= 5 * 60 && minutes <= recommendedTimeInBed;
      },
    };
  }

  static MaxEfficiency(message = 'Sua eficiência deu maior que 100%. Verifique os dados inseridos.'): Validator {
    return {
      error: {
        name: 'MaxEfficiencyValidation',
        message,
      },
      validationFn: (value: number) => {
        return value <= 100;
      },
    };
  }

  static AfterTime(time: string, message: string): Validator {
    return {
      error: {
        name: 'AfterTime',
        message,
      },
      validationFn: (value: string) => {
        // The validation of this field depends on previous fields
        // thus, when those previous fields are empty this validation
        // will also be triggered.
        // In order to avoid duplicating the error message, if the argument
        // passed isn't set, this validation will be ignored.
        if (time === 'aN:aN') return true;
        const [before, after] = getTimeInMinutesChronologically([time, value]);
        const threshold = 14 * 60;

        // BUSINESS_RULE: 'after' is considered after 'before' if it is less than 14 hours after 'before'
        return after - before < threshold;
      },
    };
  }

  static ProfessionalId(kind: HealthCarerKind, message: string): Validator {
    return {
      error: {
        name: 'ProfessionalId',
        message,
      },
      validationFn: (value: string) => {
        return validateProfessionalId(kind, value);
      },
    };
  }

  static CardNumberRegex(cardFlagError?: string, errorMessage?: string): Validator {
    return {
      error: {
        name: 'CardNumber regex',
        message: cardFlagError || errorMessage || 'Cartão informado não é aceito ou é inválido',
      },
      validationFn: (value: any) => {
        return !cardFlagError && isCardNumberValid(value);
      },
    };
  }

  static ExpirationDateRegex(message: string): Validator {
    return {
      error: {
        name: 'Expiration date regex',
        message,
      },

      validationFn: (value: any) => {
        const parsedDate = parseCreditCardDate(value);
        const currDate = new Date();

        // compare dates using the format YYYYMM
        const isValidDate =
          parsedDate &&
          parsedDate.getFullYear() * 100 + parsedDate.getMonth() + 1 - (currDate.getFullYear() * 100 + currDate.getMonth() + 1) >= 0;
        return isValidDate;
      },
    };
  }
}
