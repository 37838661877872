import { getEmojiForSleepDiaryGrade } from '@global/utils/domain/entities';
import { toPercentageOn1To3Scale } from '@global/utils/number/to-percentage';
import { parseToDate } from '@global/utils/date/parse-to-date';
import { parseDateToShortString } from '@global/utils/date/parse-to-string';
import { AppThemeContext, BrandColor, GrayColor, LightColor } from '@web/atomic';
import React, { useContext } from 'react';
import { Brush, CartesianGrid, ComposedChart, Legend, Line, LineProps, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { getCycleReferenceLine } from './cycle-reference.components';
import { ChartWrapperStyled } from './graph.styled';
import { numberOfDaysToShowInitiallyOnGraph, SleepDiaryGraphProps } from './utils';

type IGoalGraphProps = SleepDiaryGraphProps;

export const DayGoalGraph: React.FunctionComponent<IGoalGraphProps> = (props) => {
  let humorCount = 0;
  let concentrationCount = 0;
  let energyCount = 0;
  let relationshipCount = 0;

  const cData = props.sleepDiaryData
    ? props.sleepDiaryData.map((item) => {
        humorCount += item.humor ? 1 : 0;
        concentrationCount += item.concentration ? 1 : 0;
        energyCount += item.energy ? 1 : 0;
        relationshipCount += item.relationships ? 1 : 0;

        return {
          name: parseDateToShortString(parseToDate(item.date)),
          humor: item.humor,
          concentração: item.concentration,
          energia: item.energy,
          relacionamento: item.relationships,
        };
      })
    : [];
  // BUSINESS-RULE: the first cycle actually starts one day before the first diary
  if (props.cycles && props.cycles[0]) {
    cData.unshift({
      name: parseDateToShortString(props.cycles[0].startDate),
      humor: undefined,
      concentração: undefined,
      energia: undefined,
      relacionamento: undefined,
    });
  }

  const { theme } = useContext(AppThemeContext);

  const lineProps: Partial<LineProps> = {
    type: 'monotoneX',
    dot: true,
    fill: theme.name === 'dark' ? BrandColor.Yellow : LightColor.Primary,
    stroke: theme.name === 'dark' ? BrandColor.Yellow : LightColor.Primary,
  };

  const height = props.height || 200;
  const formatter = (value) => Math.round(toPercentageOn1To3Scale(value));

  return (
    <>
      <ChartWrapperStyled height={height}>
        <ResponsiveContainer>
          <ComposedChart
            data={cData}
            height={height}
            width={props.width}
            // POG-ALERT: this margin is a hack to remove space before/after Y-Axis
            margin={{ top: 0, right: 35, bottom: 0, left: -35 }}
          >
            <CartesianGrid stroke={theme.name === 'dark' ? GrayColor.GrayDark : GrayColor.GrayXXXLight} />
            <Legend verticalAlign="top" wrapperStyle={{ left: 0, top: -30 }} />
            <XAxis
              stroke={theme.name === 'dark' ? GrayColor.GrayXLight : GrayColor.Gray}
              dataKey="name"
              label={{ position: 'insideBottomRight', offset: 0 }}
            />
            <YAxis
              stroke={theme.name === 'dark' ? GrayColor.GrayXLight : GrayColor.Gray}
              ticks={[1, 2, 3]}
              tickFormatter={getEmojiForSleepDiaryGrade}
              label={{ value: '', className: 'rechart-left-label', angle: -90, position: 'top' }}
            />
            {!props.hideBrush && cData.length > 7 && (
              <Brush dataKey="name" height={30} startIndex={cData.length - numberOfDaysToShowInitiallyOnGraph} />
            )}
            <Tooltip formatter={formatter} />
            {humorCount > 0 && <Line isAnimationActive={!props.removeAnimation} dataKey="humor" {...lineProps} ref={null} />}
            {concentrationCount > 0 && <Line isAnimationActive={!props.removeAnimation} dataKey="concentração" {...lineProps} ref={null} />}
            {energyCount > 0 && <Line isAnimationActive={!props.removeAnimation} dataKey="energia" {...lineProps} ref={null} />}
            {relationshipCount > 0 && (
              <Line isAnimationActive={!props.removeAnimation} dataKey="relacionamento" {...lineProps} ref={null} />
            )}
            {getCycleReferenceLine(props)}
          </ComposedChart>
        </ResponsiveContainer>
      </ChartWrapperStyled>
    </>
  );
};
