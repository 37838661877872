import { JourneyId } from '@global/utils/domain/journey';

export type HealthCarerPortalSessionId = number;

export interface HealthCarerPortalJourney {
  name: string;
  modules: HealthCarerPortalModule[];
}

export interface HealthCarerPortalJourneyInfo {
  id: JourneyId;
  name: string;
  sessions: HealthCarerPortalSessionId[];
}

export interface HealthCarerPortalModule {
  order: string;
  sessions: HealthCarerPortalSessionId[];
}

export interface HealthCarerPortalSessionInfo {
  journeys: Record<JourneyId, HealthCarerPortalJourneyInfo>;
}

export const SessionInfo: HealthCarerPortalSessionInfo = require('./health-carer-portal-program-info.json');
