import { onlyNumber } from '../string/only-number';

// https://github.com/benhurott/tinymask#usage
export const cpfMask = '999.999.999-99';

// src: https://www.devmedia.com.br/validar-cpf-com-javascript/23916
export function isCpfValid(str: string) {
  if (str !== undefined && str !== null && str.trim().length === 0) {
    return true;
  }

  if (str === undefined) {
    return true;
  }

  const cpfNumbers = onlyNumber(str);
  let Soma;
  let Resto;
  Soma = 0;
  if (cpfNumbers === '00000000000') {
    return false;
  }

  for (let i = 1; i <= 9; i++) {
    Soma = Soma + parseInt(cpfNumbers.substring(i - 1, i), 10) * (11 - i);
  }
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) {
    Resto = 0;
  }
  if (Resto !== parseInt(cpfNumbers.substring(9, 10), 10)) {
    return false;
  }

  Soma = 0;
  for (let i = 1; i <= 10; i++) {
    Soma = Soma + parseInt(cpfNumbers.substring(i - 1, i), 10) * (12 - i);
  }
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) {
    Resto = 0;
  }
  if (Resto !== parseInt(cpfNumbers.substring(10, 11), 10)) {
    return false;
  }
  return true;
}
