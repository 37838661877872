import {
  Border,
  BrandColor,
  GrayColor,
  LightColor,
  FontFamily,
  FontSize,
  FontWeight,
  Spacing,
  Alpha,
} from '@web/atomic/legacy/obj.constants';
import { highlightStyle } from '@web/atomic/legacy/obj.mixin';
import { hexToRGBA, lightenDarkenColor } from '@global/utils/ui/color-fn';
import styled from 'styled-components';
import { FlashMessageProps } from './flash-message.component';

export const flashMessageTypesColors = {
  warning: LightColor.Warning,
  success: LightColor.Success,
  info: BrandColor.Ebony,
  'info-secondary': GrayColor.GrayXDark,
  alert: LightColor.Alert,
};

export const FlashMessageContentStyled = styled.div`
  flex-grow: 99;
  width: 100%;
  text-align: left;
  margin: 0 ${Spacing.Small};
  word-break: break-word;
  padding-right: ${Spacing.Large};
`;

export const FlashMessageCloseStyled = styled.div`
  ${highlightStyle}
  margin-left: ${Spacing.Medium};
  margin-right: ${Spacing.Medium};
  margin-top: auto;

  &:hover > span {
    color: ${(props: FlashMessageProps) => lightenDarkenColor(flashMessageTypesColors[props.type], -50)};
  }
`;

export const FlashIconStyled = styled.div`
  ${highlightStyle}
  margin-left: ${Spacing.Medium};
  margin-top: auto;

  &:hover > span {
    color: ${(props: FlashMessageProps) => lightenDarkenColor(flashMessageTypesColors[props.type], -50)};
  }
`;

export const FlashMessageWrapperStyled = styled.div`
  background-color: ${GrayColor.White};
`;

export const FlashMessageStyled = styled.div<{ type }>`
  box-shadow: ${() => `0 2px 6px ${hexToRGBA(GrayColor.GrayXLight, Alpha.L4)}`};
  background-color: ${(props) => hexToRGBA(flashMessageTypesColors[props.type], Alpha.L1)};

  margin-top: auto;
  padding: ${Spacing.Medium} 0 12px;
  border-radius: ${Border.Radius};
  line-height: 1.33;
  border: 0;
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
  font-size: ${FontSize.Small};
  transition: opacity 0.3s ease-in-out;

  svg {
    color: ${(props) => flashMessageTypesColors[props.type]};
  }
`;
