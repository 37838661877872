import { getEmojiForSleepDiaryGrade } from '@global/utils/domain/entities';
import { parseToDate } from '@global/utils/date/parse-to-date';
import { parseDateToShortString } from '@global/utils/date/parse-to-string';
import { AppThemeContext, BrandColor, GrayColor, LightColor } from '@web/atomic';
import React, { useContext } from 'react';
import { Brush, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { getCycleReferenceLine } from './cycle-reference.components';
import { ChartWrapperStyled } from './graph.styled';
import { numberOfDaysToShowInitiallyOnGraph, SleepDiaryGraphProps } from './utils';
import { toPercentageOn1To3Scale } from '@global/utils/number/to-percentage';

type IGradeGraphProps = SleepDiaryGraphProps;

export const GradeGraph: React.FunctionComponent<IGradeGraphProps> = (props) => {
  const gradeLabel = 'disposição ao acordar';
  const cData = props.sleepDiaryData
    ? props.sleepDiaryData.map((item) => {
        return {
          name: parseDateToShortString(parseToDate(item.date)),
          [gradeLabel]: item.grade,
        };
      })
    : [];

  // BUSINESS-RULE: the first cycle actually starts one day before the first diary
  if (props.cycles && props.cycles[0]) {
    cData.unshift({ name: parseDateToShortString(props.cycles[0].startDate), [gradeLabel]: undefined });
  }

  const height = props.height || 200;
  const formatter = (value) => Math.round(toPercentageOn1To3Scale(value));

  const { theme } = useContext(AppThemeContext);

  return (
    <>
      <ChartWrapperStyled height={height}>
        <ResponsiveContainer>
          <ComposedChart
            data={cData}
            height={height}
            width={props.width}
            // POG-ALERT: this margin is a hack to remove space before/after Y-Axis
            margin={{ top: 0, right: 35, bottom: 0, left: -35 }}
          >
            <Legend verticalAlign="top" wrapperStyle={{ left: 0, top: -30 }} />
            <CartesianGrid stroke={theme.name === 'dark' ? GrayColor.GrayDark : GrayColor.GrayXXXLight} />
            <XAxis
              stroke={theme.name === 'dark' ? GrayColor.GrayXLight : GrayColor.Gray}
              dataKey="name"
              label={{ position: 'insideBottomRight', offset: 0 }}
            />
            <YAxis
              stroke={theme.name === 'dark' ? GrayColor.GrayXLight : GrayColor.Gray}
              ticks={[1, 2, 3]}
              tickFormatter={getEmojiForSleepDiaryGrade}
              label={{ value: '', className: 'rechart-left-label', angle: -90, position: 'top' }}
            />
            {!props.hideBrush && cData.length > 7 && (
              <Brush dataKey="name" height={30} startIndex={cData.length - numberOfDaysToShowInitiallyOnGraph} />
            )}

            <Tooltip formatter={formatter} />

            <Line
              isAnimationActive={!props.removeAnimation}
              type="monotoneX"
              dot={true}
              dataKey={gradeLabel}
              fill={theme.name === 'dark' ? BrandColor.Yellow : LightColor.Primary}
              stroke={theme.name === 'dark' ? BrandColor.Yellow : LightColor.Primary}
            />
            {getCycleReferenceLine(props)}
          </ComposedChart>
        </ResponsiveContainer>
      </ChartWrapperStyled>
    </>
  );
};
