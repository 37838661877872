import styled from 'styled-components';
import { Col } from '@web/atomic/legacy/obj.grid';
import { Spacing, GrayColor, LightColor } from '../obj.constants';
import { getLevel1Color } from '@global/utils/ui/color-fn';

export const PlaceholderStyled = styled.div`
  padding: ${Spacing.Medium};
  border-radius: ${Spacing.Small};
  background: ${(props) => (props.theme.name === 'dark' ? getLevel1Color(props.theme.color.background) : GrayColor.GrayXXLight)};
  margin-bottom: ${Spacing.Medium};

  svg {
    color: ${(props) => (props.theme.name === 'dark' ? GrayColor.GrayXLight : LightColor.Primary)};
  }
`;

export const EmptyPlaceholderStyled = styled.div`
  height: 80vh;
`;

export const PlaceholderColStyled = styled(Col)`
  text-align: center;
`;
