import { AuthClientErrors } from '@web/data/messenger-auth-client.model';
import * as React from 'react';
import { FaIcon } from '../atm.fa-icon';
import { Placeholder } from './placeholder.component';

export interface FetchDataErrorPlaceholderProps {
  error?: Error;
}

export const FetchDataErrorPlaceholder: React.FunctionComponent<FetchDataErrorPlaceholderProps> = (props) => {
  let description = 'Tente novamente mais tarde';
  if (props.error?.toString().includes(AuthClientErrors.InvalidCredentialsError)) {
    description =
      'Não foi possível pegar seus dados. Tente novamente, mas se não der certo, entre em contato com o "Suporte" para corrigir essa situação.';
  } else if (props.error?.toString().includes(AuthClientErrors.NoInternetError)) {
    description = 'Parece que você está sem internet. Verifique sua conexão e tente novamente.';
  }
  return <Placeholder icon={<FaIcon.FlashAlert size="9x" />} title={'Erro ao pegar seus dados'} description={description} />;
};
