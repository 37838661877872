import * as React from 'react';

const SvgErro404 = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg viewBox="0 0 1075 585" fill="none" {...props}>
    <g fill="none" fillRule="evenodd">
      <ellipse fill="#F2F2F2" fillRule="nonzero" cx={540.643} cy={549.309} rx={527.5} ry={34.922} />
      <path
        d="M520.426 167.01c-85.944 0-147.651 55.14-147.651 183.798 0 145.813 61.707 184.41 147.65 184.41 85.945 0 151.328-42.273 151.328-184.41 0-151.94-65.383-183.798-151.327-183.798zm.565 319.808c-59.527 0-90.626-34.923-90.626-135.916 0-89.112 32.372-136.105 91.899-136.105s91.899 30.868 91.899 136.105c0 98.447-33.645 135.916-93.172 135.916z"
        fill="#2F2E41"
        fillRule="nonzero"
      />
      <path
        d="M321.311 433.517h-35.534v-104.64c0-11.573-9.382-20.955-20.956-20.956h-8.326a20.956 20.956 0 0 0-20.956 20.956v104.64h-100.23a11.963 11.963 0 0 1-10.578-17.552l106.083-200.78a20.956 20.956 0 0 0-9.58-28.74l-6.263-2.957c-10.1-4.769-22.164-.78-27.43 9.07L58.162 434.591a28.416 28.416 0 0 0 25.06 41.812h152.318v66.167a25.119 25.119 0 0 0 25.119 25.12 25.119 25.119 0 0 0 25.119-25.12v-66.167h35.534c11.843 0 21.443-9.6 21.443-21.443 0-11.843-9.6-21.443-21.443-21.443zM979.308 433.517h-35.534v-104.64c0-11.573-9.383-20.956-20.956-20.956h-8.327a20.956 20.956 0 0 0-20.956 20.956v104.64h-100.23a11.963 11.963 0 0 1-10.577-17.552l106.082-200.78a20.956 20.956 0 0 0-9.58-28.74l-6.263-2.957c-10.099-4.769-22.164-.78-27.429 9.07L716.157 434.591a28.416 28.416 0 0 0-3.356 13.396c0 15.694 12.722 28.416 28.416 28.416h152.318v66.167c0 13.873 11.247 25.12 25.12 25.12 13.872 0 25.118-11.247 25.118-25.12v-66.167h35.535c11.842 0 21.443-9.6 21.443-21.443 0-11.843-9.6-21.443-21.443-21.443z"
        fill="#5f819f"
        fillRule="nonzero"
      />
      <path
        d="M331.114 421.264H295.58v-104.64a20.956 20.956 0 0 0-20.956-20.956h-8.327c-11.573 0-20.956 9.383-20.956 20.956v104.64h-100.23a11.963 11.963 0 0 1-10.577-17.553l106.082-200.78a20.956 20.956 0 0 0-9.58-28.739l-6.263-2.957c-10.099-4.769-22.164-.78-27.429 9.07L67.963 422.338a28.416 28.416 0 0 0-3.356 13.396h0c0 15.693 12.722 28.416 28.416 28.416H245.34v66.167a25.119 25.119 0 0 0 25.12 25.119h0c13.872 0 25.119-11.246 25.119-25.12V464.15h35.534a21.443 21.443 0 0 0 21.443-21.443h0a21.443 21.443 0 0 0-21.443-21.443h0zM997.688 421.264h-35.535v-104.64c0-11.573-9.382-20.956-20.956-20.956h-8.326a20.956 20.956 0 0 0-20.956 20.956v104.64h-100.23a11.963 11.963 0 0 1-10.577-17.553l106.082-200.78a20.956 20.956 0 0 0-9.58-28.739l-6.263-2.957c-10.099-4.769-22.164-.78-27.43 9.07l-129.38 242.033a28.416 28.416 0 0 0-3.356 13.396h0c0 15.693 12.722 28.416 28.415 28.416h152.32v66.167c0 13.873 11.245 25.119 25.118 25.119h0c13.873 0 25.12-11.246 25.12-25.12V464.15h35.534a21.443 21.443 0 0 0 21.443-21.443h0c0-11.843-9.6-21.443-21.443-21.443zM540.03 155.982c-85.943 0-147.65 55.14-147.65 183.798 0 145.813 61.707 184.41 147.65 184.41 85.945 0 151.328-42.273 151.328-184.41 0-151.94-65.383-183.798-151.327-183.798zm.566 319.808c-59.527 0-90.626-34.923-90.626-135.916 0-89.112 32.372-136.105 91.899-136.105s91.899 30.868 91.899 136.105c0 98.447-33.645 135.916-93.172 135.916h0z"
        stroke="#3F3D56"
      />
      <ellipse fill="#2F2E41" fillRule="nonzero" cx={502.607} cy={46.885} rx={36.186} ry={46.885} />
      <path
        d="M502.607 79.61c-18.127 0-33.14-17.27-35.775-39.805a61.12 61.12 0 0 0-.41 7.08c0 25.893 16.2 46.885 36.185 46.885 19.985 0 36.187-20.991 36.187-46.885 0-2.366-.137-4.73-.41-7.08-2.635 22.534-17.649 39.805-35.777 39.805z"
        fill="#000"
        fillRule="nonzero"
        opacity={0.1}
      />
      <path
        d="M576.242 184.189c-.777 3.193-4.128 5.75-7.839 7.538-7.802 3.756-17.425 4.878-26.76 5.254-2.38.145-4.77.1-7.144-.132a20.537 20.537 0 0 1-12.25-5.632 1.682 1.682 0 0 1 .043-2.843c4.97-5.46 13.263-8.806 21.616-11.217 6.335-1.827 17.382-6.56 24.29-5.922 6.692.62 9.025 8.922 8.044 12.954z"
        fill="#3F3D56"
        fillRule="nonzero"
      />
      <path
        d="M576.242 184.189c-.777 3.193-4.128 5.75-7.839 7.538-7.802 3.756-17.425 4.878-26.76 5.254-2.38.145-4.77.1-7.144-.132a20.537 20.537 0 0 1-12.25-5.632 1.682 1.682 0 0 1 .043-2.843c4.97-5.46 13.263-8.806 21.616-11.217 6.335-1.827 17.382-6.56 24.29-5.922 6.692.62 9.025 8.922 8.044 12.954z"
        fill="#000"
        fillRule="nonzero"
        opacity={0.1}
      />
      <path
        d="M477.044 160.321a19.77 19.77 0 0 0-1.199 15.075 26.34 26.34 0 0 0 8.83 12.497c10.094 8.092 23.987 9.205 36.924 9.093a284.65 284.65 0 0 0 33.905-2.324 40.538 40.538 0 0 0 11.002-2.554c4.222-1.827 7.933-5.178 9.436-9.526 1.503-4.348.436-9.673-3.134-12.574-3.137-2.55-7.463-2.9-11.498-3.143l-23.087-1.388c2.28-2.308 5.318-3.614 8.095-5.292 3.686-2.226 6.134-5.965 8.814-9.335a129.001 129.001 0 0 1 13.438-13.817c.752 4.31 3.478 7.85 6.688 10.824 3.209 2.974 6.908 5.369 10.243 8.2 8.079 6.859 13.896 16.167 22.393 22.5a43.829 43.829 0 0 0 16.048-8.011l-3.302-5.982a3.94 3.94 0 0 0-1.245-1.553c-.934-.575-2.14-.278-3.225-.441-2.9-.435-4.167-3.784-5.305-6.487-3.125-7.422-9.109-13.18-14.218-19.404a98.008 98.008 0 0 1-9.996-14.723c-1.717-3.102-3.288-6.331-5.618-9.003-2.329-2.672-5.593-4.773-9.138-4.78-3.132-.007-6.021 1.583-8.714 3.182a230.477 230.477 0 0 0-23.63 16.1c-3.944 3.061-7.864 6.296-12.49 8.173-1.947.79-4 1.33-5.869 2.292-3.273 1.685-5.757 4.535-8.431 7.065-9.538 9.023-22.596 14.869-35.717 15.336z"
        fill="#3F3D56"
        fillRule="nonzero"
      />
      <path
        d="M525.32 96.098a23.774 23.774 0 0 1-1.734 8.033 10.045 10.045 0 0 1-5.768 5.573c-1.835.492-3.76.554-5.623.183a10.882 10.882 0 0 1-4.581-1.561c-2.165-1.489-3.245-4.144-3.638-6.743-.393-2.596-.217-5.249-.469-7.864a42.944 42.944 0 0 0-1.202-6.255c-.17-.683-.343-1.363-.513-2.042-.166-.68-.33-1.36-.481-2.04a44.287 44.287 0 0 1-.378-1.925 28.716 28.716 0 0 1-.233-1.643 18.423 18.423 0 0 0-.808-4.811 14.607 14.607 0 0 0-1.687-2.854c-.286-.409-.563-.818-.818-1.243a5.89 5.89 0 0 1-.972-3.748 3.286 3.286 0 0 1 .233-.824 1.802 1.802 0 0 0 1.23-1.148 3.433 3.433 0 0 0-.148-1.778 11.318 11.318 0 0 0-.96-2.288c-.264-.478-1.16-1.34-1.167-1.888-.013-1.101 2.14-1.989 3.011-2.423a16.796 16.796 0 0 1 8.597-1.743c1.904.129 3.968.714 5.019 2.31.944 1.438.818 3.3 1.22 4.971.043.243.146.471.3.664.205.187.463.306.74.34a4.55 4.55 0 0 0 1.415.057h.003c.125-.01.249-.028.371-.054a4.957 4.957 0 0 0 2.033-.878 12.714 12.714 0 0 1 1.227-.264c1.577 4.975 1.781 10.308 3.072 15.374.638 2.52 1.554 5.01 1.683 7.603.01.113.013.23.016.346.019.834-.047 1.674-.013 2.504.012.375.054.747.126 1.114.141.591.317 1.173.525 1.744l.01.028c.06.182.116.368.164.554.034.135.066.267.09.402l.004.01c.233 1.39.268 2.807.104 4.207z"
        fill="#FBBEBE"
        fillRule="nonzero"
      />
      <circle fill="#FBBEBE" fillRule="nonzero" cx={503.237} cy={44.997} r={18.565} />
      <path
        d="M621.103 146.148a30.445 30.445 0 0 0-5.236-14.103c.482 2.864.964 5.726 1.448 8.587a3.214 3.214 0 0 1-3.367-1.035 10.337 10.337 0 0 1-1.766-3.276 67.466 67.466 0 0 0-8.21-14.735c-11.818-.985-23.501-5.884-33.895-11.596-10.396-5.708-20.125-12.552-30.383-18.502a43.573 43.573 0 0 0-5.545-2.832c-3.21-1.287-6.812-1.954-9.855-3.467-.205-.101-.41-.208-.61-.318a12.578 12.578 0 0 1-1.945-1.306 10.344 10.344 0 0 1-.931-.856 20.351 20.351 0 0 1-3.56-5.953c-1.632-3.613-2.215-7.97-3.845-11.583.75-.7 1.591-1.294 2.502-1.765h.003a11.94 11.94 0 0 1 2.404-.931 12.714 12.714 0 0 1 1.227-.265c1.839-.3 3.723-.15 5.491.434 2.587.875 4.767 2.621 6.942 4.273a114.02 114.02 0 0 1 10.148 8.05c1.793 1.717 3.43 3.605 5.358 5.166a42.144 42.144 0 0 0 5.057 3.351c10.437 6.218 20.876 12.435 31.315 18.65 3.534 2.105 7.07 4.21 10.526 6.438 5.248 3.386 10.308 7.055 15.365 10.72l8.936 6.48a9.728 9.728 0 0 1 2.533 2.34c.617 1.051 1 2.222 1.123 3.434a31.387 31.387 0 0 1-1.23 14.6z"
        fill="#FBBEBE"
        fillRule="nonzero"
      />
      <path
        d="M529.923 110.06c-1.252 5.616-6.929 9.012-9.896 13.945-3.688 6.124-2.184 13.241-.8 20.255-2.529 2.18-5.06 4.362-7.592 6.542-1.397 1.199-2.794 2.404-4.298 3.465a57.35 57.35 0 0 1-6.86 3.94c-2.24 1.15-4.48 2.304-6.721 3.457a32.128 32.128 0 0 1-6.58 2.788c-4.414 1.133-9.103.34-13.47-.972a6.088 6.088 0 0 1-1.473-.601 2.394 2.394 0 0 1-.699-.633 3.91 3.91 0 0 1-.443-2.539c.443-7.35 2.24-14.547 4.03-21.69a85.26 85.26 0 0 1 3.849-12.577 85.077 85.077 0 0 1 5.415-10.15 68.368 68.368 0 0 1 7.93-11.514 18.479 18.479 0 0 0 3.675-4.739c1.117-2.549.686-5.472.913-8.247a17.148 17.148 0 0 1 1.633-6.007v-.003a17.093 17.093 0 0 1 1.743-2.883c.305.711.61 1.421.916 2.13.2.466.4.93.597 1.395a9799.688 9799.688 0 0 1 2.964 6.9 21.59 21.59 0 0 0 3.336 5.944 6.5 6.5 0 0 0 11.123-.853 21.261 21.261 0 0 0 2.272-6.013c.622-2.567.707-5.234.252-7.835-.758-5.01-2.889-10.126-4.437-14.78a14.945 14.945 0 0 1-1.08-4.87c.004-.189.023-.376.057-.56l.029-.146a11.94 11.94 0 0 1 2.004-.733c.39-.104.784-.188 1.18-.255 1.363 7.376 2.727 14.752 4.094 22.128.031.173.066.346.097.522.196 1.057.394 2.114.614 3.169.198.956.412 1.91.651 2.857l.01.028c.082.321.167.639.255.957l.003.009c.242.868.513 1.73.818 2.58 1.85 5.12 5.076 10.274 3.89 15.589z"
        fill="#5f819f"
        fillRule="nonzero"
      />
      <path
        d="M605.267 188.993a6.583 6.583 0 0 0 .61 3.143c1.162 2.123 3.95 2.606 6.363 2.805a188.377 188.377 0 0 0 42.265-1.288 4.886 4.886 0 0 0 2.152-.668c1.99-1.395.763-4.795-1.41-5.883-2.173-1.089-4.751-.826-7.135-1.298a22.48 22.48 0 0 1-6.678-2.896 338.719 338.719 0 0 1-14.293-8.688c-2.794-1.795-6.093-3.71-9.24-2.646-8.525 2.884-12.69 8.222-12.634 17.419z"
        fill="#3F3D56"
        fillRule="nonzero"
      />
      <path
        d="M501.383 82.99v.002a7.75 7.75 0 0 1-.072.39c-.035.215-.076.432-.116.645a77.708 77.708 0 0 1-1.42 5.963c-1.51 5.46-3.533 10.831-5.54 16.124L478.07 148.69c-1.36 3.574-2.716 7.152-4.26 10.654-.517 1.168-1.048 2.344-1.577 3.534-1.894 4.257-3.713 8.653-4.317 13.184a27.45 27.45 0 0 0-.192 9.04c.604 2.97 2.407 5.872 5.23 6.97 1.378.538 3.35 1.259 2.973 2.69-.204.787-1.098 1.171-1.9 1.303a7.312 7.312 0 0 1-5.966-1.718c-1.508-1.337-2.666-3.417-4.67-3.64-1.388-.151-2.665.68-3.937 1.261-5.185 2.37-11.227.712-16.673-.972.427-2.206 2.643-3.66 4.842-4.1 2.2-.444 4.475-.129 6.718-.189a3.51 3.51 0 0 0 2.042-.525 3.705 3.705 0 0 0 1.102-1.882 78.964 78.964 0 0 0 3.212-13.146c.724-4.667 1.026-9.406 2.058-14.016.803-3.596 2.042-7.08 3.106-10.61a224.682 224.682 0 0 0 5.06-22.077 78.02 78.02 0 0 0 1.425-9.367c.18-2.612.095-5.236.346-7.839a60.888 60.888 0 0 1 2.112-9.996l2.885-10.69c1.426-5.27 2.955-10.745 6.567-14.842a13.962 13.962 0 0 1 10.029-4.79c.716 0 1.43.077 2.13.23.116.029.233.057.349.089.762.199 1.494.502 2.174.9 1.114-.709 1.68-.46 2.42.632a6.973 6.973 0 0 1 .881 3.795 52.424 52.424 0 0 1-.856 10.415z"
        fill="#FBBEBE"
        fillRule="nonzero"
      />
      <path
        d="M502.607 87.162l-.047.047-.255.255-2.53 2.526-1.24-5.207-4.25-17.854c.716-.001 1.43.076 2.13.23.03-.205.08-.406.148-.602l.201.69 1.441 4.902 2.728 9.308.45 1.532v.003l1.224 4.17z"
        fill="#5f819f"
        fillRule="nonzero"
      />
      <path
        d="M518.661 30.203a12.582 12.582 0 0 1-5.06 10.072 12.528 12.528 0 0 1-7.536 2.524h-15.096c-6.946-.01-12.57-5.644-12.57-12.59s5.624-12.58 12.57-12.59h15.096c6.944.018 12.571 5.64 12.596 12.584z"
        fill="#2F2E41"
        fillRule="nonzero"
      />
      <path
        d="M513.6 40.275a12.528 12.528 0 0 1-7.535 2.524h-15.096a12.528 12.528 0 0 1-7.536-2.524h30.168z"
        fill="#000"
        fillRule="nonzero"
        opacity={0.1}
      />
      <path d="M611.086 133.756s3.252 9.372 6.229 6.876l-2.373-12.25-3.856 5.374z" fill="#FBBEBE" fillRule="nonzero" />
      <path
        d="M1006.864 419.55a20.813 20.813 0 1 0 2.771-39.916l.521 10.713-5.068-9.18a20.734 20.734 0 0 0-10.683 11.722 20.408 20.408 0 0 0-1.198 5.63 20.809 20.809 0 0 0 13.657 21.03z"
        fill="#57B894"
        fillRule="nonzero"
      />
      <path
        d="M1031.941 543.793c-1.789-9.11 5.963-17.187 13.621-22.436 7.658-5.25 16.605-10.408 19.218-19.317 3.755-12.804-7.43-24.53-16.136-34.642a125.3 125.3 0 0 1-16.523-24.557c-1.812-3.533-3.476-7.226-3.953-11.167-.686-5.675 1.137-11.323 2.974-16.736a1210.366 1210.366 0 0 1 19.629-53.65"
        stroke="#3F3D56"
      />
      <path
        d="M1007.72 416.792a20.813 20.813 0 1 0 2.773-39.916l.52 10.713-5.068-9.18a20.734 20.734 0 0 0-10.683 11.722 20.408 20.408 0 0 0-1.197 5.63 20.809 20.809 0 0 0 13.656 21.03z"
        stroke="#3F3D56"
      />
      <path
        d="M1029.397 357.588a20.788 20.788 0 0 1 14.98-13.197l1.714 10.183 3.177-10.695a20.81 20.81 0 1 1-19.87 13.71z"
        fill="#57B894"
        fillRule="nonzero"
      />
      <path
        d="M1030.54 353.91a20.788 20.788 0 0 1 14.98-13.197l1.714 10.184 3.177-10.696a20.81 20.81 0 1 1-19.87 13.71h0z"
        stroke="#3F3D56"
      />
      <path
        d="M1044.99 467.604c9.946 3.608 21.016-.82 25.728-10.293 4.713-9.472 1.568-20.973-7.308-26.73l-2.441 8.22-1.732-10.304a20.811 20.811 0 1 0-14.247 39.107z"
        fill="#57B894"
        fillRule="nonzero"
      />
      <path
        d="M1045.981 463.88c9.946 3.607 21.015-.821 25.728-10.294 4.712-9.472 1.568-20.972-7.309-26.729l-2.441 8.22-1.731-10.305a20.811 20.811 0 1 0-14.247 39.107h0z"
        stroke="#3F3D56"
      />
      <path
        d="M1023.324 502.167a20.801 20.801 0 1 0 4.01-16.297l9.273 13.956-12.67-7.408a20.616 20.616 0 0 0-.613 9.749z"
        fill="#57B894"
        fillRule="nonzero"
      />
      <path
        d="M1024.18 499.409a20.801 20.801 0 1 0 4.012-16.297l9.272 13.956-12.67-7.407a20.616 20.616 0 0 0-.613 9.748z"
        stroke="#3F3D56"
      />
      <path
        d="M9.007 470.249a13.674 13.674 0 1 0 1.821-26.225l.343 7.038-3.33-6.032a13.623 13.623 0 0 0-7.02 7.702c-.435 1.19-.7 2.435-.786 3.7a13.672 13.672 0 0 0 8.972 13.817z"
        fill="#57B894"
        fillRule="nonzero"
      />
      <path
        d="M25.484 551.879c-1.176-5.986 3.918-11.292 8.949-14.741 5.031-3.45 10.91-6.838 12.626-12.692 2.467-8.412-4.881-16.116-10.601-22.76a82.324 82.324 0 0 1-10.856-16.135 22.21 22.21 0 0 1-2.597-7.336c-.451-3.729.747-7.44 1.954-10.996a795.226 795.226 0 0 1 12.896-35.25"
        stroke="#3F3D56"
      />
      <path
        d="M9.57 468.437a13.674 13.674 0 1 0 1.822-26.225l.342 7.038-3.33-6.032a13.623 13.623 0 0 0-7.02 7.702 13.41 13.41 0 0 0-.786 3.699 13.672 13.672 0 0 0 8.973 13.818z"
        stroke="#3F3D56"
      />
      <path
        d="M23.812 429.539a13.658 13.658 0 0 1 9.842-8.671l1.126 6.69 2.088-7.026a13.673 13.673 0 1 1-13.056 9.007z"
        fill="#57B894"
        fillRule="nonzero"
      />
      <path
        d="M24.563 427.123a13.658 13.658 0 0 1 9.842-8.671l1.126 6.69 2.088-7.026a13.673 13.673 0 1 1-13.056 9.007h0z"
        stroke="#3F3D56"
      />
      <path
        d="M34.058 501.822a13.673 13.673 0 0 0 12.101-24.325l-1.604 5.4-1.137-6.77a13.673 13.673 0 1 0-9.36 25.694z"
        fill="#57B894"
        fillRule="nonzero"
      />
      <path
        d="M34.708 499.374A13.673 13.673 0 0 0 46.81 475.05l-1.604 5.4-1.137-6.77a.238.238 0 0 0-.035-.013c-7.1-2.575-14.942 1.092-17.517 8.19-2.575 7.1 1.092 14.942 8.191 17.517h0z"
        stroke="#3F3D56"
      />
      <path
        d="M19.822 524.53a13.667 13.667 0 1 0 2.635-10.708l6.092 9.17-8.324-4.867a13.545 13.545 0 0 0-.403 6.405z"
        fill="#57B894"
        fillRule="nonzero"
      />
      <path
        d="M20.385 522.718a13.667 13.667 0 1 0 2.635-10.708l6.092 9.17-8.324-4.867a13.545 13.545 0 0 0-.403 6.405h0z"
        stroke="#3F3D56"
      />
      <ellipse fill="#2F2E41" fillRule="nonzero" cx={480.946} cy={319.115} rx={17} ry={22} />
      <ellipse fill="#2F2E41" fillRule="nonzero" cx={573.446} cy={319.615} rx={17} ry={22} />
      <path
        d="M560.446 384.615c0 9.94-13.88 18-31 18-17.12 0-31-8.06-31-18 0-8.61 10.41-15.81 24.32-17.57a50.148 50.148 0 0 1 6.68-.43c3.743-.012 7.476.39 11.13 1.2 11.62 2.59 19.87 9.14 19.87 16.8z"
        fill="#2F2E41"
        fillRule="nonzero"
      />
      <ellipse stroke="#3F3D56" cx={484.946} cy={314.115} rx={17} ry={22} />
      <ellipse stroke="#3F3D56" cx={577.446} cy={314.615} rx={17} ry={22} />
      <ellipse stroke="#3F3D56" cx={533.446} cy={379.615} rx={31} ry={18} />
      <path
        d="M540.946 369.315a4.937 4.937 0 0 1-1.32 3.392 4.339 4.339 0 0 1-3.18 1.409h-10a4.664 4.664 0 0 1-4.5-4.8c-.005-.975.28-1.93.82-2.742a47.02 47.02 0 0 1 6.68-.459 47.665 47.665 0 0 1 11.13 1.28c.247.61.373 1.263.37 1.92z"
        fill="#FFF"
        fillRule="nonzero"
      />
      <circle fill="#FFF" fillRule="nonzero" cx={484.946} cy={308.115} r={5} />
      <circle fill="#FFF" fillRule="nonzero" cx={577.946} cy={308.115} r={5} />
      <circle fill="#3D55E4" fillRule="nonzero" opacity={0.3} cx={582.946} cy={355.115} r={5} />
      <circle fill="#3D55E4" fillRule="nonzero" opacity={0.3} cx={460.946} cy={355.115} r={5} />
    </g>
  </svg>
);

export default SvgErro404;
