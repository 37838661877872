import * as React from 'react';
import { FormFieldContext, FormFieldContextState } from '@web/atomic/legacy/obj.form';

import {
  RadioCheckedStyled,
  RadioFieldInputStyled,
  RadioFieldLabelStyled,
  RadioFieldStyled,
  RadioUncheckedStyled,
  InvisibleRadioClickableArea,
} from './radio-field.component.style';

interface RadioFieldProps {
  id: any;
  onClick?: (id: any) => void;
  disabled?: boolean;
  expanded?: boolean;
  ml?: boolean;
}

const DefaultRadioBullet = (props: { checked: boolean }) => (props.checked ? <RadioCheckedStyled /> : <RadioUncheckedStyled />);

export class RadioField extends React.Component<RadioFieldProps, undefined> {
  private formFieldConsumer: FormFieldContextState;

  render() {
    return (
      <FormFieldContext.Consumer>
        {(formFieldConsumer: FormFieldContextState) => {
          this.formFieldConsumer = formFieldConsumer;
          if (!formFieldConsumer) {
            throw new Error('<RadioField /> must be wrapped with a <Form.Field> tag');
          }
          return (
            <RadioFieldStyled ml={this.props.ml} expanded={this.props.expanded} onClick={this.handlePress}>
              <RadioFieldInputStyled
                type="radio"
                name={this.formFieldConsumer.name}
                id={this.formFieldConsumer.name + '_' + this.props.id}
                value={this.props.id}
              />
              <DefaultRadioBullet checked={this.formFieldConsumer.value === this.props.id} />
              <RadioFieldLabelStyled
                padding={!!this.props.children}
                htmlFor={this.formFieldConsumer.name + '_' + this.props.id}
                expanded={this.props.expanded}
              >
                {this.props.children || <InvisibleRadioClickableArea />}
              </RadioFieldLabelStyled>
            </RadioFieldStyled>
          );
        }}
      </FormFieldContext.Consumer>
    );
  }

  private handlePress = () => {
    if (this.formFieldConsumer) {
      this.formFieldConsumer.onValueChange(this.props.id, null);
      setTimeout(() => {
        this.formFieldConsumer.onFocusChange(false);
      });
    }

    if (this.props.onClick) {
      this.props.onClick(this.props.id);
    }
  };
}
