import { isNullOrUndefined } from '../object/null-or-undefined';
import { twoDigit } from '../number/two-digit';

/**
 * Add minutes to a string hour
 * @param minutesToAdd
 * @param hour expected to be in format hh:mm
 */
export const performHourSum = (minutesToAdd: number | string, hour: string): string => {
  if (isNullOrUndefined(hour)) return null;
  const [h, m] = hour.split(':').map((comp) => parseInt(comp));
  if (isNullOrUndefined(h) || isNullOrUndefined(m)) return null;
  let newMinute = Math.floor(m + parseInt(minutesToAdd as string, 0));
  let hoursToAdd = 0;
  while (newMinute < 0) {
    newMinute += 60;
    hoursToAdd -= 1;
  }
  while (newMinute >= 60) {
    newMinute -= 60;
    hoursToAdd += 1;
  }
  const newHour = (h + hoursToAdd) % 24;

  return `${twoDigit(newHour >= 0 ? newHour : newHour + 24)}:${twoDigit(newMinute)}`;
};
