import { RouteComponentProps } from '@reach/router';
import * as React from 'react';
import { withAuth } from '../../components/router/with-auth.hoc';
import { SignUpRouteUrl } from '../sign-up/sign-up.route';
import PatientListPage from './patient-list.page';

export const PatientListRouteUrl = '/';

const AuthPatientListRoute = withAuth(PatientListPage, SignUpRouteUrl);
export const PatientListRoute: React.FunctionComponent<RouteComponentProps> = () => <AuthPatientListRoute />;
