import { BaseTheme } from '@web/atomic';
import styled from 'styled-components';
import { SleepEfficiency, SleepEfficiencyAnalysis } from '@global/utils/domain/sleep-efficiency';

export const OpinionatedColorStyled = styled.span<{ good }>`
  color: ${(props) => getColorForOpinion(props.good, props.theme)};
`;

export const EfficiencyColorStyled = styled.span<{ value }>`
  color: ${(props) => getColorForEfficiency(props.value, props.theme)};
`;

////////////////////////////////////////////////////////////////////////////////////////////////////

const getColorForOpinion = (good: boolean, theme: BaseTheme) => (good ? theme.color.success : theme.color.alert);

const getColorForEfficiency = (value: number, theme: BaseTheme) => {
  const eficiencyAnalysis = new SleepEfficiency(value).analysis;
  switch (eficiencyAnalysis) {
    case SleepEfficiencyAnalysis.Bad:
      return theme.color.alert;
    case SleepEfficiencyAnalysis.Ok:
      return theme.color.warning;
    case SleepEfficiencyAnalysis.Good:
      return theme.color.success;
  }
};
