import styled, { keyframes } from 'styled-components';
import { TransitionDuration, Spacing } from '@web/atomic/legacy/obj.constants/constants';
import { isNullOrUndefined } from '@global/utils/object/null-or-undefined';

export interface FadeProps {
  show: boolean;
  duration?: number;
  delay?: number;
}

/**
 * This fade animates only when `opacity` changes
 */
export const Fade = styled.div`
  border: 0 solid transparent;
  pointer-events: ${(props: FadeProps) => (props.show ? 'inherit' : 'none')};
  opacity: ${(props: FadeProps) => (props.show ? 1 : 0)};
  transition: opacity ${(props: FadeProps) => (isNullOrUndefined(props.duration) ? `${TransitionDuration}` : `${props.duration}`)};
  transition-delay: ${(props) => props.delay || 0}s;
`;

export const fadeInWithTranslate = keyframes`
  from {
    opacity: 0;
    transform: translateY(${Spacing.XLarge})
  }

  to {
    opacity: 1;
    transform: translateY(0)
  }
`;

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

interface ForcedFadeProps {
  show: boolean;
  delay?: string;
  duration?: string;
}

/**
 * This fade will happen everytime, which is different from `Fade` (component) which animates only when `opacity` changes
 */
export const ForcedFade = styled.div<ForcedFadeProps>`
  border: 0;
  pointer-events: ${(props) => (props.show ? 'inherit' : 'none')};
  opacity: ${(props) => (props.show ? 0 : 1)};
  animation: ${(props) => (props.show ? fadeInWithTranslate : fadeOut)} ${(props) => props.duration || TransitionDuration} linear forwards;
  animation-delay: ${(props) => props.delay || 0};

  @media print {
    opacity: 1;
    animation: none;
  }
`;
////////////////////////////////////////////////////////////////////////////////////////////////////
export const shake = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`;

export const Shake = styled.div`
  transform: translate3d(0, 0, 0);
  animation: ${shake} 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
`;
