import { twoDigit } from '../number/two-digit';

/** parse date to string in the format dd de month de yyyy */
export const parseDateToLongString = (date: Date): string => {
  if (!date) {
    return '';
  }

  const day = twoDigit(date.getDate());
  const month = twoDigit(date.getMonth() + 1);

  return `${day} de ${monthToString(parseInt(month, 10))}  de ${date.getFullYear()}`;
};

/** parse date to string in the format dd/mm */
export const parseDateToShortString = (date: Date): string => {
  const day = twoDigit(date.getDate());
  const month = twoDigit(date.getMonth() + 1);

  return `${day}/${month}`;
};

/** parse date to string in the format dd/mm/yyyy */
export const parseDateToDDMMYYYY = (date: Date): string => {
  return `${parseDateToShortString(date)}/${date.getFullYear()}`;
};

export const parseToHHmm = (date: Date): `${number}:${number}` => {
  const hour = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${hour}:${minutes}` as `${number}:${number}`;
};

export const parseToYYYYMMDD = (date: Date): `${number}-${number}-${number}` => {
  const day = twoDigit(date.getDate());
  const month = twoDigit(date.getMonth() + 1);
  const year = date.getFullYear();

  return `${year}-${month}-${day}` as `${number}-${number}-${number}`;
};

const monthToString = (month: number | string): string => {
  switch (typeof month === 'string' ? parseInt(month, 10) : month) {
    case 1:
      return 'Janeiro';
    case 2:
      return 'Fevereiro';
    case 3:
      return 'Março';
    case 4:
      return 'Abril';
    case 5:
      return 'Maio';
    case 6:
      return 'Junho';
    case 7:
      return 'Julho';
    case 8:
      return 'Agosto';
    case 9:
      return 'Setembro';
    case 10:
      return 'Outubro';
    case 11:
      return 'Novembro';
    case 12:
      return 'Dezembro';

    default:
      return 'Mês Inválido';
  }
};
