import { Button, Col, Grid, H2, InputLabel, Row, Separator } from '@web/atomic';
import { CheckboxField } from '@web/atomic/legacy/atm.checkbox';
import { TextField } from '@web/atomic/legacy/atm.text-field';
import { BooleanContainer } from '@web/atomic/legacy/obj.abstract-container';
import flashDispatcherService from '@web/atomic/legacy/obj.flash-wrapper/flash-dispatcher.service';
import { Form, FormData, Validators } from '@web/atomic/legacy/obj.form';
import { Modal } from '@web/atomic/legacy/obj.modal';
import * as React from 'react';
import { useState } from 'react';
import AuthDatasource, { firebaseErrorsPtBr } from '../../data/auth-datasource';

interface ChangePasswordModalForm {
  currPassword: string;
  newPassword: string;
}
interface ChangePasswordModalProps {
  opened: boolean;
  onClose: () => void;
}

const ChangePasswordModal: React.FunctionComponent<ChangePasswordModalProps> = (props) => {
  const [submitLoading, setLoading] = useState(false);
  const handleSubmit = async (formData: FormData<ChangePasswordModalForm>) => {
    if (Object.keys(formData.error).length !== 0) return;

    try {
      setLoading(true);
      await AuthDatasource.changePassword(formData.data.currPassword, formData.data.newPassword);
      flashDispatcherService.dispatchMessage('Senha alterada com sucesso!', 'success');
      props.onClose();
    } catch (error) {
      flashDispatcherService.dispatchMessage(firebaseErrorsPtBr[error.code], 'alert');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal opened={props.opened} small onClose={props.onClose} noPadding>
      <Grid fluid>
        <Form onSubmit={handleSubmit}>
          <Row mb center="xs">
            <Col xs={12}>
              <H2>Alterar senha</H2>
            </Col>
          </Row>

          <Row mt mb center="xs">
            <BooleanContainer defaultValue={false}>
              {(checkbox) => (
                <Col xs={12}>
                  <Form.Field
                    name={'currPassword' as keyof ChangePasswordModalForm}
                    validators={[Validators.Required(), Validators.MinLength(6, 'A senha deve ter pelo menos 6 caracteres.')]}
                  >
                    <InputLabel>Senha atual</InputLabel>
                    <TextField type={checkbox.value ? 'text' : 'password'} />
                  </Form.Field>
                  <Separator />

                  <Form.Field
                    name={'newPassword' as keyof ChangePasswordModalForm}
                    validators={[Validators.Required(), Validators.MinLength(6, 'A senha deve ter pelo menos 6 caracteres.')]}
                  >
                    <InputLabel>Nova senha</InputLabel>
                    <TextField type={checkbox.value ? 'text' : 'password'} />
                  </Form.Field>
                  <Separator />

                  <CheckboxField id={'show'} onValueChange={checkbox.toggle} initialChecked={checkbox.value}>
                    {' '}
                    Mostrar senhas{' '}
                  </CheckboxField>
                  <Separator />
                </Col>
              )}
            </BooleanContainer>
          </Row>

          <Row mb>
            <Col xs={12}>
              <Button type="submit" kind="primary" loading={submitLoading} expanded>
                Enviar resposta
              </Button>
              <Separator />
            </Col>
          </Row>
        </Form>
      </Grid>
    </Modal>
  );
};

export default ChangePasswordModal;
