import gql from 'graphql-tag';

export const HealthCarerNameQuery = gql`
  query HealthCarerNameQuery($id: String!) {
    coupon(where: { owner_hcp: { _eq: $id } }, order_by: { created_at: desc }) {
      code
    }
    health_carer_by_pk(id: $id) {
      id
      name
      kind
      gender
      professional_id
      professional_id_state
      membership_kind
      whatsapp
      health_carer_transactions_aggregate {
        aggregate {
          sum {
            amount
          }
        }
      }
      pagarme_recipient {
        health_carer_id
        document
        branch_number
        branch_check_digit
        bank
        account_type
        account_number
        account_check_digit
      }
    }
  }
`;
