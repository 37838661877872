import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hexToRGBA } from '@global/utils/ui/color-fn';
import { InputPlaceholderCss } from '@web/atomic/legacy/atm.typography';
import { GrayColor, Spacing, Alpha } from '@web/atomic/legacy/obj.constants';
import {
  fieldBackgroundCss,
  fieldBorder,
  fieldBorderCss,
  fieldCss,
  fieldHeight,
  fieldPadding,
} from '@web/atomic/legacy/obj.form/field.component.styled';
import styled from 'styled-components';
import { ThemeProps } from '@web/atomic/obj.theme';
import { TextFieldProps } from './text-field.component';

const FIELD_WITH_ICON_LEFT_PADDING = 'calc(' + Spacing.Medium + ' * 3)';
const ICON_HEIGHT = '18px';

const getTextColor = (props: TextFieldProps & ThemeProps) => {
  if (props.light) {
    return GrayColor.White;
  }
  return props.theme.name === 'regular' ? GrayColor.Black : GrayColor.White;
};

export const TextFieldStyled = styled.input<TextFieldProps & ThemeProps>`
  ${fieldBorderCss}
  ${fieldCss}
  ${fieldBackgroundCss}
  width: ${(props) => (props.length ? `${12 + props.length * 17 + (props.dismissable ? 24 : 0)}px` : '100%')};
  height: 48px;
  color: ${getTextColor};
  padding: 8px;
  ${(props) => (props.type === 'email' ? 'text-transform: lowercase;' : null)};
  ${(props) => (props.icon ? 'padding-left: ' + FIELD_WITH_ICON_LEFT_PADDING : null)};

  /* https://css-tricks.com/webkit-html5-search-inputs/ */
  ::-webkit-search-cancel-button {
    -webkit-appearance: none;
    width: 14px;
    height: 10px;
    background-image: ${(props: TextFieldProps) =>
      props.light
        ? `url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAJFJREFUOBGtU0EKwCAMk73Bd+z/F1+0R3TJUOgkrcJWKHU2SdeAxcwqsiHPshnEdk4t/YBiF3IpQkzHolijgL9IRUJs2HArLTEZIOu5GUWus00eSoJAXxipP4P/VIC9sSn5eDH/+Jim87f3VxBkruLXiX3IgFlPmSanhCJhQxgqsbjkS2TIybMOcN6T5zF9es43OjLXKFdybbEAAAAASUVORK5CYII=')`
        : `url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAOJJREFUOBGdk8ERgjAQRTcrBwqgDovQIqzDK6ecuFqHRWgR1kEBHBD0kYlDQgKMOQRm97+/m4UYa23Vdf1dtbg2jX3JjlXX9jgM/a0si4sCjzKe3kP/ILHFo0EL4wp/K4uYVmSstkw8jBaGrg0V48RBi3N8nJxmMtgyycFwP4OcCXGO5tuOuwsMUibEcjCZhQHBsOVJ1saVibLUPf7fFx2E1fm8LPfZUl0EBjEMAL5riCnY/wtruamDNQEdsHIak0s4LNxTWuVWrQ1pbsGR3Fzc3YFVrqQR80xNeA77d28CA/sB1IvvTN6ddPsAAAAASUVORK5CYII=')`};
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: top left;
  }

  ::placeholder {
    ${InputPlaceholderCss}
    opacity: 0.5;
  }
`;

const DismissButtonSize = 16;

const padding = Spacing.Medium;
export const TextFieldDismissButtonClickableStyled = styled.div<{ length }>`
  position: absolute;
  padding: ${padding};
  top: 0;
  left: ${(props) => (props.length ? `${props.length * 17 - 20}px` : 'auto')};
  right: ${(props) => (props.length ? `auto` : '0')};
`;

export const TextFieldDismissButtonStyled = styled.div`
  width: ${DismissButtonSize}px;
  height: ${DismissButtonSize}px;
  border-radius: ${DismissButtonSize / 2}px;
  background-color: ${GrayColor.Gray};
  text-align: center;

  &:before,
  &:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 10px;
    top: 50%;
    left: 19px;
    background: ${GrayColor.White};
  }
  &:before {
    transform: rotate(45deg);
    margin-top: -1px;
  }
  &::after {
    transform: rotate(-45deg);
    margin-top: -1px;
  }
`;

export const TextFieldWrapperStyled = styled.div`
  position: relative;
`;

export const TextFieldIconWrapperStyled = styled.div`
  position: relative;
`;

export const TextFieldIconStyled = styled(FontAwesomeIcon)<{ light }>`
  position: absolute;
  z-index: 1;
  color: ${(props) => (props.light ? GrayColor.White : GrayColor.Gray)};
  top: ${`calc((${fieldHeight} + ${fieldBorder} + 2 * ${fieldPadding} - ${ICON_HEIGHT}) / 2)`};
  left: ${Spacing.Medium};
`;
