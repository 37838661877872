import { Body } from '@web/atomic';
import { Button } from '@web/atomic/atm.button/button.component';
import { FlashMessage } from '@web/atomic/legacy/mol.flash-message';
import { Hbox } from '@web/atomic/legacy/obj.box';
import * as React from 'react';
import FlashDispatcherService, { FlashDispatcherListener, FlashMessageData } from './flash-dispatcher.service';
import { FlashWrapperStyled } from './flash-wrapper.component.style';

interface FlashWrapperState {
  messageQueue: FlashMessageData[];
}

const flashDispatcher = FlashDispatcherService;
/**
 * It is a wrapper that shows Flash Message on the window.
 * It also manages a queue of messages by listening to flashDispatcher
 */
export class FlashWrapper extends React.Component<unknown, FlashWrapperState> implements FlashDispatcherListener {
  constructor(props: unknown) {
    super(props);
    this.state = {
      messageQueue: [],
    };
  }

  componentDidMount(): void {
    flashDispatcher.setListener(this);
  }

  componentWillUnmount(): void {
    flashDispatcher.setListener(null);
  }

  render(): JSX.Element {
    return (
      <FlashWrapperStyled>
        {this.state.messageQueue.map((item, index) => (
          <FlashMessage
            type={item.type}
            dismissible={true}
            onClose={this.handleMessageClose(index)}
            key={`${item.time.getTime().toString()}`}
          >
            <Body kind={'default'}>{item.message}</Body>
            {item.action && (
              <Hbox hAlign="flex-end">
                <Button
                  kind={'link'}
                  onClick={() => {
                    this.handleMessageClose(index)();
                    item.action.onClick();
                  }}
                >
                  {item.action.text}
                </Button>
              </Hbox>
            )}
          </FlashMessage>
        ))}
      </FlashWrapperStyled>
    );
  }

  handleMessageClose = (index: number) => {
    return (): void => {
      const messageQueue = this.state.messageQueue;
      messageQueue.splice(index, 1);
      this.setState({ messageQueue });
    };
  };

  // FlashDispatcherListener
  onReceiveMessage = (data: FlashMessageData): void => {
    const messageQueue = this.state.messageQueue;
    messageQueue.push(data);

    this.setState({ messageQueue });
  };
}
