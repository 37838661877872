import { HcpUserContext } from '@app/health-carer/src/UserProvider';
import { PatientListQuery } from '@app/health-carer/src/data/graphql/patient-list.query.hasura';
import { getProgramProgress } from '@component/doctor-report/components/doctor-report.utils';
import { dateDiffInDays } from '@global/utils/date/date-diff-in-days';
import { parseDateToDDMMYYYY } from '@global/utils/date/parse-to-string';
import { getHealthCarerPatientReportUrl, getTestPatientReportUrl } from '@global/utils/domain/health-carer/patient-report-url';
import { isNullOrUndefined } from '@global/utils/object';
import { User } from '@global/utils/remote-graphql-types';
import { formatName } from '@global/utils/string/name';
import { Body, Col, FaIcon, Grid, H1, Row, Separator } from '@web/atomic';
import { Placeholder } from '@web/atomic/legacy/mol.placeholder';
import { LoadingState } from '@web/atomic/legacy/obj.loading-state';
import { useDocumentTitle } from '@web/atomic/obj.custom-hooks/document-title.hook';
import { useQueryCustom } from '@web/data/use-query-custom.hook';
import React, { useContext, useState } from 'react';
import { LightBlueBackground } from '../../components/atm.background/background.styled';
import { HcpWhatsappFab } from '../../components/atm.hcp-whatsapp-fab/hcp-whatsapp-fab.component';
import { DoctorTutorial } from '../../components/org.doctor-tutorial/doctor-tutorial.component';
import { Layout } from '../../components/org.layout/layout.component';
import DoctorSearch from './mol.doctor-search/doctor-search.component';
import { PatientListRow, PatientListRowProps, PatientListRowShimmer } from './mol.patient-list-row/patient-list-row.component';
import EmptyListPlaceholder from './org.empty-list/empty-list.component';

const PatientListPage: React.FunctionComponent = () => {
  const user = useContext(HcpUserContext);
  const id = user.uid;
  const couponCode = user?.couponCode;
  const { data, error, loading } = useQueryCustom(PatientListQuery, { variables: { id } });
  useDocumentTitle('Lista de pacientes');
  const fakeUser: User = (data as any)?.fake_user;
  const users = data?.health_carer_by_pk?.users;
  /** show a user placeholder (this is used to show a test patient when there are no patients to show) */
  const shouldShowTestPatient = (users?.length ?? 0) === 0;
  const usersToBeUsed = shouldShowTestPatient ? [fakeUser].filter((u) => u) : users.map((u) => u.user);
  const hasUsers = usersToBeUsed?.length > 0;

  return (
    <>
      <Layout>
        {(loading || hasUsers) && (
          <Grid fluid>
            <Row mt mb>
              <Col xs={12} sm={12} md={7} lg={7}>
                <H1> Lista de pacientes </H1>
                <Body>
                  {loading
                    ? 'Carregando...'
                    : shouldShowTestPatient
                    ? `Você ainda não possui nenhum paciente, mas para você já ver como ficará quando indicar, deixamos abaixo a "Paciente Teste". Clique nela para ver um relatório já preenchido.`
                    : `Selecione quem você gostaria de analisar.`}
                </Body>
              </Col>
            </Row>
          </Grid>
        )}

        <LoadingState loading={loading} error={!!error} data={hasUsers}>
          <LoadingState.Shimmer>
            <LightBlueBackground>
              <Grid fluid>
                <Separator />
                <PatientListRowShimmer />
                <PatientListRowShimmer />
                <Separator />
              </Grid>
            </LightBlueBackground>
          </LoadingState.Shimmer>
          <LoadingState.Error>
            <Grid fluid>
              <Placeholder
                icon={<FaIcon.FlashAlert size="9x" />}
                title={'Erro ao pegar seus dados'}
                description="Tente novamente mais tarde"
              />
            </Grid>
          </LoadingState.Error>
          <LoadingState.NoData>
            <EmptyListPlaceholder />
          </LoadingState.NoData>
          <PatientListComponent
            users={usersToBeUsed}
            showPaidTag={!isNullOrUndefined(user.balance)}
            testPatientMode={shouldShowTestPatient}
          />
        </LoadingState>
        {couponCode && (
          <DoctorTutorial hasFinancialInfo={user.hasFinancialInfo} showTutorialVideo={usersToBeUsed?.length < 3} couponCode={couponCode} />
        )}
        <HcpWhatsappFab />
      </Layout>
    </>
  );
};

export default PatientListPage;

////////////////////////////////////////////////////////////////////////////////////////////////////

interface PatientListComponentProps {
  users: User[];
  showPaidTag: boolean;
  /** if this mode is active, then the user should be redirected to
   * /pacitente-teste/ instead of /paciente/ when they click on a patient */
  testPatientMode: boolean;
}

const PatientListComponent: React.FunctionComponent<PatientListComponentProps> = (props) => {
  const [users, setUser] = useState(props.users);

  const mapper = mapToPatientListRowPropsHOF(props.testPatientMode, props.showPaidTag);

  return (
    <LightBlueBackground>
      <Grid fluid>
        <Separator />
        {props.users.length >= 10 && <DoctorSearch users={props.users} setList={setUser} />}
        {users
          .map(mapper)
          .sort((a, b) => b.lastAccessDate - a.lastAccessDate)
          .map((u) => (
            <PatientListRow key={u.id} {...u} />
          ))}
        <Separator />
      </Grid>
    </LightBlueBackground>
  );
};

const mapToPatientListRowPropsHOF =
  (testPatientMode: boolean, showPaidTag: boolean) =>
  (user: User): PatientListRowProps & { id: string; lastAccessDate: number } => {
    const id = user.id;
    const paid = user.paid;
    const img = user.profile_picture;
    const name = formatName(user.first_name, user.last_name);
    const signUp = parseDateToDDMMYYYY(new Date(user.created_at));

    const getDate = (d: string) => (d ? new Date(d) : 0);
    const lastUpdate = getDate(user.ratings?.[0]?.created || user.updated_at);
    const lastDiary = getDate(user.sleep_diaries?.[0]?.date);
    // the following line considers that user_program_sessions is ordered desc
    // @see packages/app.health-carer/src/data/graphql/patient-list.query.hasura.ts
    const lastDoneSessionDate = getDate(user.user_program_sessions?.[0]?.created_at);
    const lastAccessDate = testPatientMode
      ? Date.now()
      : Math.max(lastUpdate as number, lastDoneSessionDate as number, lastDiary as number);
    const lastAccessInDays = dateDiffInDays(new Date(lastAccessDate), new Date());
    const lastAccess = lastAccessInDays > 1 ? `há ${lastAccessInDays} dias` : lastAccessInDays === 1 ? `ontem` : `hoje`;

    const { sessionsDone, allSessions } = getProgramProgress(user);
    const progress = sessionsDone && allSessions ? sessionsDone / allSessions : 0;

    const diariesDoneCount = user.sleep_diaries_aggregate?.aggregate?.count;
    const progressLabel = `${sessionsDone} sessões e ${diariesDoneCount} diários`;

    const path = testPatientMode ? getTestPatientReportUrl(user) : getHealthCarerPatientReportUrl(user);

    return {
      id,
      showPaidTag: !testPatientMode && showPaidTag,
      paid,
      lastAccessDate,
      img,
      name,
      signUp,
      lastAccess,
      progress,
      progressLabel,
      path,
    };
  };
