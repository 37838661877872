/* eslint-disable @typescript-eslint/no-var-requires */
import { HeaderHeight, LightColor, Spacing } from '@web/atomic';
import styled from 'styled-components';

interface DropdownMenuStyledProps {
  isVisible: boolean;
}

export const DropdownMenuStyled = styled.div<DropdownMenuStyledProps>`
  background-color: ${LightColor.Primary};
  position: fixed;
  min-width: 180px;
  z-index: 3;
  box-shadow: 0px 2px 4px rgba(122, 88, 76, 0.4);
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  visibility: ${(props) => (props.isVisible ? 'unset' : 'hidden')};

  top: ${HeaderHeight.Mobile + parseInt(Spacing.Medium)}px;
  right: ${Spacing.Medium};
  padding: ${Spacing.Medium};
  @media all and (min-width: 32em) {
    top: ${HeaderHeight.Desk + parseInt(Spacing.Medium)}px;
    right: ${Spacing.XLarge};
    padding: ${Spacing.Large};
  }
`;
