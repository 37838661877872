import { isNullOrUndefined } from '../object/null-or-undefined';
import { toMinutes } from './to-minutes';

/**
 * @param times expected to be strings in format "hh:mm"
 */
export const getTimeInMinutesChronologically = (times: string[]): number[] => {
  return times.reduce((acc, curr, index) => {
    const minutes = toMinutes(curr);
    if (isNullOrUndefined(minutes)) {
      return acc;
    }
    if (index > 0 && minutes < acc[index - 1]) {
      const oneDay = 24 * 60;
      /** if the converted hour is smaller than the last pushed hour, then the hour is actually on the next day */
      acc.push(minutes + oneDay);
    } else {
      acc.push(minutes);
    }
    return acc;
  }, [] as number[]);
};
