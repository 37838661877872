import { HeaderHeight } from '@web/atomic';
import { isDesktopScreenSize } from '@web/utils/browser-detection';
import { hasDocument, hasWindow } from '@web/utils/platform';
import { diactriclessKebabCase } from '@global/utils/string/case';
import * as React from 'react';
import { scrollIntoView, scrollTo } from 'scroll-js';

// https://stackoverflow.com/a/56461927/3670829
export const scrollToId = async (
  id: string,
  offset: number,
  /**
   * if your scrollable container is not the window (i.e. some modal), pass the contianerId
   * https://stackoverflow.com/a/27980290/3670829
   */
  containerId?: string
): Promise<void> => {
  if (!hasWindow() || !hasDocument()) {
    return;
  }
  const scrollToEl = document.getElementById(id);
  if (!scrollToElement) {
    console.log('scrollToId:element doesnt exist: ', id);
    return;
  }
  const buffer = 16;
  if (containerId) {
    const container = document.getElementById(containerId);
    const topOfElement = scrollToEl.offsetTop - offset - buffer;
    if (container) await scrollTo(container, { top: topOfElement, behavior: 'smooth' });
  } else {
    const topOfElement = window.pageYOffset + scrollToEl?.getBoundingClientRect()?.top - (offset ?? 0) - buffer;
    if (topOfElement > 0) {
      const options = { top: topOfElement > 0 ? topOfElement : undefined, behavior: 'smooth' as ScrollBehavior };
      await scrollTo(document.body, options);
    } else {
      console.log('WARN: scroll.tsx:39 ~ topOfElement <= 0 : ', topOfElement, 'scrollToEl', scrollToEl);
      await scrollIntoView(scrollToEl, document.body, { behavior: 'smooth' });
    }
  }
};

////////////////////////////////////////////////////////////////////////////////////////////////////

const getElementId = (name: string) => `ScrollElement-${diactriclessKebabCase(name)}`;

interface IScrollElementProps {
  name: string;
  className?: string;
}

/**
 * if you want to scroll to it, use the `scrollToElement` function
 * @param props
 */
export const ScrollElement: React.FunctionComponent<IScrollElementProps> = (props) => {
  const name = getElementId(props.name);
  return <span id={name} className={props.className} />;
};

interface ScrollOptions {
  containerId?: string;
  top?: number;
}
export const scrollToElement = (name: string, options: ScrollOptions = {}): Promise<void> => {
  const elementId = getElementId(name);
  return scrollToId(elementId, options.top, options.containerId);
};

////////////////////////////////////////////////////////////////////////////////////////////////////

export const getTopElementHeight = (headerId: string): number => {
  if (!hasDocument()) {
    return 0;
  }
  return document.getElementById(headerId) ? (isDesktopScreenSize() ? HeaderHeight.Desk : HeaderHeight.Mobile) : 0;
};
