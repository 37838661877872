import { isNullOrUndefined } from '@global/utils/object';

// export const shouldAskForAccountDigit = ()
export interface BankBranchDigitSolver {
  shouldAskForAccountDigit: () => boolean;
  getBranchDigit: (accountDigit?: number) => string;
}

export const factoryBankBranchDigitSolver = (bankNumber: number): BankBranchDigitSolver => {
  switch (bankNumber) {
    case 104:
      return new CaixaBankBranchDigitSolver();
    case 341:
      return new ItauBankBranchDigitSolver();
    case 237:
      return new BradescoBankBranchDigitSolver();
    case 33:
      return new SantanderBankBranchDigitSolver();
    case 260:
      return new NubankBankBranchDigitSolver();
    case 77:
      return new InterBankBranchDigitSolver();
    default:
      return new OtherBankBranchDigitSolver();
  }
};

class CaixaBankBranchDigitSolver implements BankBranchDigitSolver {
  shouldAskForAccountDigit = () => false;
  getBranchDigit = (accountDigit: number) => (!isNullOrUndefined(accountDigit) ? `${accountDigit}` : '');
}

class ItauBankBranchDigitSolver implements BankBranchDigitSolver {
  shouldAskForAccountDigit = () => false;
  getBranchDigit = (accountDigit: number) => (!isNullOrUndefined(accountDigit) ? `${accountDigit}` : '');
}

class BradescoBankBranchDigitSolver implements BankBranchDigitSolver {
  shouldAskForAccountDigit = () => true;
  getBranchDigit = () => undefined;
}

class SantanderBankBranchDigitSolver implements BankBranchDigitSolver {
  shouldAskForAccountDigit = () => true;
  getBranchDigit = () => undefined;
}

class NubankBankBranchDigitSolver implements BankBranchDigitSolver {
  shouldAskForAccountDigit = () => false;
  getBranchDigit = () => '0';
}

class InterBankBranchDigitSolver implements BankBranchDigitSolver {
  shouldAskForAccountDigit = () => false;
  getBranchDigit = () => '9';
}

class OtherBankBranchDigitSolver implements BankBranchDigitSolver {
  shouldAskForAccountDigit = () => true;
  getBranchDigit = () => undefined;
}
