import { ActivityIndicator } from '@web/atomic/legacy/atm.activity-indicator';
import { TextField } from '@web/atomic/legacy/atm.text-field';
import { Form } from '@web/atomic/legacy/obj.form';
import { RelativeWrapper } from '@web/atomic/obj.wrappers';
import React, { useEffect } from 'react';
import { SearchLoadingStyled } from './debounced-search.component.style';
import { SearchIndex } from './search.utils';

export interface SearchProps<D> {
  searchIndex: SearchIndex<D>;
  onResultChange: (query: string, result: D) => any;
  label?: string;
  initialValue?: string;
  placeholder?: string;
  id?: string;
}

export const Search: React.FunctionComponent<SearchProps<any>> = (props) => {
  const onResultChange = props.onResultChange;
  const [loading, setLoading] = React.useState(false);
  const searchData = React.useCallback(
    async (searchValue: string) => {
      if (searchValue.length > 0) {
        setLoading(true);
        try {
          const result = await props.searchIndex.search(searchValue);
          onResultChange(searchValue, result);
        } catch (error) {
          console.error('ERROR: search.component.tsx:30 ~ error', error);
        } finally {
          setLoading(false);
        }
      } else {
        onResultChange(searchValue, null);
      }
    },
    [onResultChange]
  );

  useEffect(() => {
    if (props.initialValue) {
      searchData(props.initialValue);
    }
  }, [
    // props.initialValue was kept out of this array to avoid unnecessary searches
    searchData,
  ]);

  const handleValueChange = React.useCallback(
    (value) => {
      searchData(value);
    },
    [searchData]
  );

  const handleSubmit = React.useCallback(
    (data) => {
      if (!data) {
        searchData('');
        return;
      }
      searchData(data);
    },
    [searchData]
  );

  return (
    <RelativeWrapper>
      <Form onSubmit={handleSubmit}>
        <Form.Field initialValue={props.initialValue} name="search" label={props.label} onValueChange={handleValueChange}>
          <TextField placeholder={props.placeholder} id={props.id} />
        </Form.Field>
        {loading && (
          <SearchLoadingStyled>
            <ActivityIndicator />
          </SearchLoadingStyled>
        )}
      </Form>
    </RelativeWrapper>
  );
};
