import { Border, BrandColor, LightColor, GrayColor, FontFamily, FontSize, FontWeight, Spacing } from '@web/atomic/legacy/obj.constants';
import { highlightStyle } from '@web/atomic/legacy/obj.mixin';
import { lightenDarkenColor } from '@global/utils/ui/color-fn';
import { BaseTheme, ThemeProps } from '@web/atomic/obj.theme';
import styled, { css } from 'styled-components';
import { ButtonKind, ButtonProps } from './button.component';

const getButtonColor = (theme: BaseTheme, kind: ButtonKind) => {
  const colors = {
    primary: theme.color.primary,
    secondary: theme.color.primary,
    neutral: GrayColor.Gray,
    'call-to-action': theme.color.callToAction,
    alert: theme.color.alert,
    link: theme.color.primary,
    'link-thin': theme.color.primary,
  };
  const color = colors[kind];
  if (!color) {
    console.error('ERROR: button.component.style ~ buttonColors ~ invalid kind', kind);
  }
  return color;
};

interface ButtonStyledProps extends ButtonProps {
  hastext: boolean;
}

export const buttonHeight = '48px';
export const buttonHeightSmall = '32px';
export const activeClassName = 'active';

export const ButtonStyledCss = css<ButtonStyledProps>`
  position: relative;
  width: auto;
  min-height: ${(props) => getHeight(props)};
  padding: ${(props) => getPadding(props)};
  width: ${(props) => (props.expanded ? '100%' : 'auto')};
  text-align: ${(props) => (props.expanded ? 'center' : 'left')};
  text-decoration: none !important;
  padding: 0 ${(props) => (props.small ? Spacing.Small : Spacing.XLarge)};
  font-family: ${FontFamily.Primary};
  font-size: ${FontSize.XSmall};
  letter-spacing: 1px;
  ${highlightStyle}
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.disabled || props.loading ? 0.5 : 1)};
  /* this is useful when its father is pointer-events: none */
  pointer-events: auto;
  ${(props) => (props.kind.startsWith('link') ? `${linkSpecificStyle(props)}` : `${buttonSpecificStyle(props)}`)}
`;

export const ButtonStyled = styled.button`
  ${ButtonStyledCss}

  &:focus {
    outline: 0;
  }
`;

export const linkButtonStyled = css`
  ${ButtonStyledCss}
  text-decoration: ${(props: ButtonStyledProps & ThemeProps) =>
    props.kind.startsWith('link') ? 'regular' : !props.kind.startsWith('link') || props.theme.name === 'regular' ? 'none' : 'underline'};

  &.${activeClassName} {
    opacity: 0.7;
  }

  // This style improves how links are shown inside texts
  p > &,
  label > & {
    padding: 0;
    min-height: ${FontSize.Medium};
  }
`;

export const ExternalLinkButtonStyled = styled.a`
  ${linkButtonStyled}
`;

export const ButtonContentStyled = styled.div<ButtonStyledProps>`
  transition: all 0.2s ease-in-out;
  opacity: ${(props) => (props.loading ? 0 : 1)};
  font-stretch: 100%;
  vertical-align: middle;
  margin: ${(props) => (props.expanded ? 'auto' : '')};
  word-break: break-all;

  & .fa,
  & img,
  & svg {
    padding-right: ${(props) => (props.hastext ? Spacing.Small : 0)};
  }
`;

export const ButtonSpinnerStyled = styled.span<{ loading? }>`
  display: ${(props) => (props.loading ? 'inline-block' : 'none')} !important;
  position: absolute;
  right: calc(50% - 7px);
  top: calc(50% - 7px);

  & svg {
    padding: 0;
  }
`;

const getHeight = (props: ButtonProps): string => {
  if (props.kind.startsWith('link')) {
    return '';
  }
  if (props.small) {
    return buttonHeightSmall;
  }
  return buttonHeight;
};

const getPadding = (props: ButtonProps): string => {
  if (props.kind.startsWith('link')) {
    return `0 ${Spacing.Small} 0 0;`;
  }
  return `0 ${props.small ? Spacing.Large : Spacing.XLarge};`;
};

const getBackGroundColor = (props: ButtonProps & ThemeProps): string => {
  const { kind } = props;

  if (props.outlined) {
    return 'transparent';
  }

  switch (kind) {
    case 'primary':
    case 'call-to-action':
      return getLightMainColor(props);
    case 'secondary':
      return props.theme.name === 'dark' ? GrayColor.White : 'transparent';
    default:
      return 'transparent';
  }
};

const getTextColor = (props: ButtonProps & ThemeProps): string => {
  const { kind, light, theme } = props;
  if (props.outlined && kind === 'primary' && theme.name === 'regular') {
    return getLightMainColor(props);
  }
  if (kind === 'call-to-action') {
    return BrandColor.Brown;
  }
  if (kind !== 'primary') {
    return getLightMainColor(props);
  }
  if (light) {
    return LightColor.Primary;
  }
  return GrayColor.White;
};

const getBorderColor = (props: ButtonProps & ThemeProps): string => {
  const { kind, light, theme } = props;
  if (props.outlined) {
    return getTextColor(props);
  }

  if (kind === 'primary' && !light && theme.name === 'regular') {
    return GrayColor.White;
  }
  return getButtonColor(theme, kind);
};

const getLightMainColor = (props: ButtonProps & ThemeProps): string => {
  const { kind, light, theme } = props;
  if (light) {
    return GrayColor.White;
  }
  return getButtonColor(theme, kind);
};

const linkSpecificStyle = (props: ButtonProps & ThemeProps) => `
  font-weight: ${props.kind === 'link' ? FontWeight.SemiBold : FontWeight.Normal};
  text-decoration: ${props.kind === 'link' || props.theme.name === 'dark' ? 'underline' : 'none'};
  display: inline-block;
  padding: 0;
  height: auto;
  background-color: transparent;
  border-color: transparent;
  color: ${props.theme.name === 'regular' ? getLightMainColor(props) : GrayColor.White};

  &:visited {
    color: ${props.theme.name === 'regular' ? getLightMainColor(props) : GrayColor.White};
  }
  &:hover {
    ${
      props.disabled
        ? null
        : `color: ${lightenDarkenColor(props.theme.name === 'regular' ? getLightMainColor(props) : GrayColor.White, -20)};`
    };
  }

  &:active {
    ${
      props.disabled
        ? null
        : `color: ${lightenDarkenColor(props.theme.name === 'regular' ? getLightMainColor(props) : GrayColor.White, -30)};`
    }
  }
`;

const buttonSpecificStyle = (props: ButtonStyledProps) => `
  ${getButtonSpecificPadding(props)}
  font-weight: ${FontWeight.SemiBold};
  display:inline-flex;
  align-items:center;
  text-align:center;

  min-height: ${props.small ? buttonHeightSmall : buttonHeight};

  background-color: ${getBackGroundColor(props)};
  color: ${getTextColor(props)};
  border-color: ${getBorderColor(props)};
  border-style: solid;
  border-width: ${Border.Width};
  border-radius: ${Border.Radius};
  &:hover {
    ${props.disabled ? null : hoverStyle(props)}
  }

  &:active {
    ${props.disabled ? null : activeStyle(props)}
  }

  & .fa,
  & img,
  & svg {
    padding-right: ${Spacing.Small};
  }

  &:visited {
    color: ${getTextColor(props)} !important;
  }
`;

const getButtonSpecificPadding = (props: ButtonStyledProps) => {
  if (!props.hastext) {
    return `padding: ${Spacing.Small} ${Spacing.Medium};`;
  }

  return `padding: 0 ${props.small ? Spacing.Small : Spacing.XLarge};`;
};

const hoverStyle = (props: ButtonProps & ThemeProps) => `
  ${
    props.kind === 'secondary'
      ? `color: ${lightenDarkenColor(getTextColor(props), -20)};
      background-color: ${lightenDarkenColor(getBorderColor(props), 90)};`
      : `background-color: ${lightenDarkenColor(getBackGroundColor(props), -10)}`
  }`;

const activeStyle = (props: ButtonProps & ThemeProps) => `
  ${
    props.kind === 'secondary'
      ? `color: ${lightenDarkenColor(getTextColor(props), -30)};
      background-color: ${lightenDarkenColor(getBorderColor(props), 85)};`
      : `background-color: ${lightenDarkenColor(getBackGroundColor(props), -20)}`
  }`;
