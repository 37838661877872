import { FaIcon } from '@web/atomic/legacy/atm.fa-icon';
import { GrayColor, LightColor } from '@web/atomic/legacy/obj.constants';
import styled from 'styled-components';

interface ImageStyledProps {
  loaded: boolean;
  round?: boolean;
  srcset?: string;
  height?: number | string;
  width?: number | string;
  fitWidth?: boolean;
  fitHeight?: boolean;
  center?: boolean;
}

export const LazyLoadImageStyled = styled.img<ImageStyledProps>`
  display: block;
  width: 100%;
  transition: opacity 0.6s ease;
  opacity: ${(props) => (props.loaded ? '1' : '0')};
  height: ${(props) => (props.height ? props.height + 'px' : 'auto')};
  max-height: ${(props) => (props.loaded ? 'none' : '0')};
  -webkit-user-drag: none;
`;

export const LazyLoadImgWrapper = styled.div<ImageStyledProps>`
  height: ${(props) => (props.height ? `${typeof props.height === 'number' ? `${props.height}px` : props.height}` : '100%')};
  width: ${(props) => (props.width ? `${props.width}px` : 'auto')};
  border-radius: ${(props) => (props.round ? '50%' : '0')};
  overflow: hidden;
  margin: ${(props) => (props.center ? `auto` : 'inherit')};

  > img {
    display: block;
    width: ${(props) => (props.fitWidth ? `100%` : '')};
    height: ${(props) => (props.fitHeight ? `100%` : '')};
    display: ${(props) => (props.loaded ? '' : 'none')};
  }
`;

interface PlaceholderStyledProps {
  aspectRatio: number;
}

export const LazyLoadPlaceholderStyled = styled.div<PlaceholderStyledProps>`
  width: 100%;
  padding-bottom: ${(props) => (props.aspectRatio ? props.aspectRatio * 100 + '%' : '100%')};
  background-color: ${GrayColor.GrayXXLight};
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
`;

const placeholderImage = (height: number) => `
  color: ${GrayColor.GrayXLight};
  font-size: ${height}px;
  position: absolute;
  top: calc(50% - ${height / 2}px);
`;

export const LazyLoadPlaceholderImageStyled = styled(FaIcon.Image)`
  ${placeholderImage(28)}
`;

export const LazyLoadErrorImageStyled = styled(FaIcon.Ban)`
  ${placeholderImage(32)}
`;
