import { Border, GrayColor, Spacing } from '@web/atomic/legacy/obj.constants';
import styled, { keyframes } from 'styled-components';
import { ModalProps } from './modal.component';

export const ModalStyled = styled.div`
  position: absolute;
  z-index: 98;
  visibility: ${(props: ModalProps) => (props.opened ? 'visible' : 'hidden')};
`;

const slideDown = keyframes`
  0% {
    transform: translate(-50%, -100%);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const ModalOpacityStyled = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 8;
  background-color: ${GrayColor.Black};
  opacity: ${(props: ModalProps) => (props.opened ? '0.5' : '0')};
  transition: all 0.2s ease-out;
  visibility: ${(props: ModalProps) => (props.opened ? 'visible' : 'hidden')};
`;

interface ModalBoxStyledProps {
  small?: boolean;
  noPadding?: boolean;
}

const smallWidth = '360px';
export const ModalBoxStyled = styled.div<ModalProps>`
  position: fixed;
  ${(props) => {
    return props.position === 'top'
      ? `top: ${Spacing.XLarge};
      left: 50%;
      transform: translate(-50%);`
      : `top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);`;
  }}
  z-index: 10;
  max-width: 1000px;
  max-height: 80%;
  overflow: ${(props) => props.overflow ?? `auto`};
  overscroll-behavior: contain;
  background-color: ${GrayColor.White};
  border-radius: ${Border.Radius};
  animation: ${slideDown} 0.3s ease-out;
  padding: ${(props) => (props.noPadding ? '0' : `0 ${Spacing.Medium}`)};

  background-color: ${(props) => {
    return props.background === 'themed' ? props.theme.color.background : GrayColor.White;
  }};

  width: 100%;
  @media all and (min-width: ${smallWidth}) {
    width: ${(props) => (props.small ? smallWidth : '90%')};
  }
  @media all and (min-width: 48em) {
    width: ${(props) => (props.small ? smallWidth : '80%')};
  }
  @media all and (min-width: 64em) {
    width: ${(props) => (props.small ? smallWidth : '70%')};
  }
`;

export const ModalCloseStyled = styled.div`
  position: absolute;
  top: -18px;
  right: -14px;
  z-index: 3;
  padding: ${Spacing.Large};
  color: ${GrayColor.GrayDark};
  cursor: pointer;
`;
