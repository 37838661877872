import ThumbIcon from '@app/health-carer/src/components/atm.thumb-icon/thumb-icon.component';
import { Link } from '@reach/router';
import { Body, DD, DT, FaIcon, H3, Hbox, Separator } from '@web/atomic';
import { LazyLoadImage } from '@web/atomic/legacy/atm.lazy-load-image';
import { ShimmerCircleStyled } from '@web/atomic/legacy/mol.shimmer/shimmer.component.style';
import { ShimmerText } from '@web/atomic/legacy/mol.shimmer/shimmer.options';
import { ProgressGauge } from '@web/atomic/mol.progress-gauge/progress-gauge.component';
import * as React from 'react';
import { getNameInitials } from '../../../utils/string';
import { PatientListColProgressWrapperStyled, PatientListColStyled, PatientListRowStyled } from './patient-list-row.component.styled';
import PatientPaidTag from './patient-paid-tag.component';

export interface PatientListRowProps {
  paid: boolean;
  showPaidTag: boolean;

  path: string;
  img?: string;
  name: string;
  signUp: string;
  lastAccess: string;
  /**
   * between 0 and 1
   */
  progress: number;
  progressLabel: string;
}

export const PatientListRow: React.FunctionComponent<PatientListRowProps> = (props) => {
  const initialThumb = <ThumbIcon text={getNameInitials(props.name)} color={'blue'} size={'large'} />;
  return (
    <Link to={props.path}>
      <PatientListRowStyled>
        <PatientListColStyled xs={12} sm={4}>
          <Hbox>
            {props.showPaidTag && (
              <Hbox.Item wrap vAlign="center">
                <PatientPaidTag paid={props.paid} />
              </Hbox.Item>
            )}
            <Hbox.Item wrap vAlign="center">
              {props.img ? (
                <LazyLoadImage src={props.img} alt={props.name} round={true} width={64} height={64} noImagePlaceholder={initialThumb} />
              ) : (
                initialThumb
              )}
            </Hbox.Item>
            <Hbox.Separator />
            <Hbox.Separator />
            <Hbox.Item vAlign="center">
              <H3 cell>{props.name}</H3>
            </Hbox.Item>
          </Hbox>
        </PatientListColStyled>
        <PatientListColStyled xs={6} sm={2}>
          <DT>Início</DT>
          <Separator />
          <DD> {props.signUp} </DD>
        </PatientListColStyled>
        <PatientListColStyled xs={6} sm={2}>
          <DT
            role="tooltip"
            data-microtip-position="top"
            data-microtip-size="medium"
            aria-label={'Última interação do paciente com o programa'}
          >
            Acessou <FaIcon.Question />
          </DT>
          <Separator />
          <DD> {props.lastAccess} </DD>
        </PatientListColStyled>
        <PatientListColStyled
          xs={12}
          sm={4}
          role="tooltip"
          data-microtip-position="top"
          aria-label={`${Math.round(props.progress * 100)}% concluído`}
        >
          <PatientListColProgressWrapperStyled>
            <ProgressGauge maxValue={1} backgroundValue={1} foregroungValue={props.progress} kind={'neutral'} />
          </PatientListColProgressWrapperStyled>
          <Body center>{props.progressLabel}</Body>
        </PatientListColStyled>
      </PatientListRowStyled>
    </Link>
  );
};

export const PatientListRowShimmer: React.FunctionComponent = () => {
  return (
    <PatientListRowStyled>
      <PatientListColStyled xs={12} sm={4}>
        <Hbox>
          <Hbox.Item wrap vAlign="center">
            <ShimmerCircleStyled radius={32} />
          </Hbox.Item>
          <Hbox.Separator />
          <Hbox.Separator />
          <Hbox.Item vAlign="center">
            <ShimmerText />
          </Hbox.Item>
        </Hbox>
      </PatientListColStyled>
      <PatientListColStyled xs={6} sm={2}>
        <ShimmerText />
      </PatientListColStyled>
      <PatientListColStyled xs={6} sm={2}>
        <ShimmerText />
      </PatientListColStyled>
      <PatientListColStyled xs={12} sm={4}>
        <ShimmerText />
      </PatientListColStyled>
    </PatientListRowStyled>
  );
};
