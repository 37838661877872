import * as React from 'react';
import WhatsappFAB from '@web/atomic/atm.whatsapp-fab/whatsapp-fab.component';
import { HcpUserContext } from '../../UserProvider';
import { getWhatsappText } from './hcp-whatsapp-fab.component.utils';

export const HcpWhatsappFab: React.FunctionComponent = () => {
  const user = React.useContext(HcpUserContext);
  const hcpId = user?.id;
  const hcpName = user?.name;
  const whatsappText = getWhatsappText(hcpName, hcpId);
  return <WhatsappFAB kind="sales" whatsappText={whatsappText} />;
};
