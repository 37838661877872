import React, { useCallback, useRef } from 'react';
import { IndexParams, SearchIndex } from './search.utils';
import { InputWorkerMessage, OutputWorkerMessage } from './search.worker.utlis';
import { BaseSubjectService } from '@global/utils/design-patterns/observer';

export function useWorkerizedSearchIndex<D = unknown>(
  // for better performance, use an stable/immutable indexParams
  indexParams: IndexParams<D>
): SearchIndex<D> {
  const worker = useRef<Worker>(new Worker(new URL('./search.worker.ts', import.meta.url)));
  const workerObserverSubject = useRef(new SearchWorkerObserverSubject<D>());
  useWorkerSetup<D>(worker, workerObserverSubject, indexParams);
  const searchIndex = useSearchIndex<D>(worker, workerObserverSubject);
  return searchIndex;
}

////////////////////////////////////////////////////////////////////////////////////////////////////

interface SearchWorkerObserver<D> {
  onResult: (result: D[], query: string) => void;
}

class SearchWorkerObserverSubject<D> extends BaseSubjectService<SearchWorkerObserver<D>> {
  notifyAll = (result: D[], query: string) => {
    this.observerCollection.forEach((observer) => observer.onResult(result, query));
  };
}

////////////////////////////////////////////////////////////////////////////////////////////////////

function useWorkerSetup<D = unknown>(
  worker: React.MutableRefObject<Worker>,
  workerObserverSubject: React.MutableRefObject<SearchWorkerObserverSubject<D>>,
  indexParams: IndexParams<D>
) {
  React.useEffect(() => {
    worker.current.postMessage({ kind: 'setup', params: indexParams } as InputWorkerMessage);
    worker.current.onmessage = (ev: MessageEvent<OutputWorkerMessage>) => {
      const message = ev.data;
      if (message.kind === 'search') {
        workerObserverSubject.current.notifyAll(message.result as D[], message.query);
      }
    };
  }, [indexParams]);
}

function useSearchIndex<D = unknown>(
  worker: React.MutableRefObject<Worker>,
  workerObserverSubject: React.MutableRefObject<SearchWorkerObserverSubject<D>>
) {
  const search = useCallback((query: string) => {
    return new Promise<D[]>((resolve) => {
      // console.time(`${query}`)
      const observer: SearchWorkerObserver<D> = {
        onResult: (result: D[], resultQuery: string) => {
          if (resultQuery === query) {
            workerObserverSubject.current.unsubscribeObserver(observer);
            // console.timeEnd(`${query}`)
            resolve(result);
          }
        },
      };
      workerObserverSubject.current.subscribeObserver(observer);
      worker.current.postMessage({ kind: 'search', query } as InputWorkerMessage);
    });
  }, []);

  const searchIndex = { search };
  return searchIndex;
}
