import { FakeStorage } from './fake.storage';
import { Storage } from './storage';
import { hasWindow } from '@web/utils/platform';

export enum StorageType {
  Local = 'localStorage',
  Session = 'sessionStorage',
}

export function getStorage(type: StorageType): Storage {
  const storage = getWindowStorage(type);
  return makeStorage(storage);
}

function getWindowStorage(type: StorageType) {
  try {
    if (
      !hasWindow() ||
      (type === StorageType.Local && typeof localStorage === 'undefined') ||
      (type === StorageType.Session && typeof sessionStorage === 'undefined')
    ) {
      return new FakeStorage();
    }

    return window[type];
  } catch (error) {
    console.warn(`getWindowStorage -> error`, error);
    return new FakeStorage();
  }
}

function makeStorage(storage: any): Storage {
  return {
    get(key: string): any {
      try {
        return JSON.parse(storage.getItem(key));
      } catch (err) {
        return null;
      }
    },

    put(key: string, value: any) {
      const data = JSON.stringify(value);
      storage.setItem(key, data);
    },

    remove(key: string) {
      storage.removeItem(key);
    },
  };
}
