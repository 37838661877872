import { Body, Button, Col, FeaturedH2, Grid, Row, SemiBold } from '@web/atomic';
import { CardWrapper } from '@web/atomic/atm.wrapper/wrapper.component';
import { Video } from '@web/atomic/legacy/atm.video/video.component';
import { Modal } from '@web/atomic/legacy/obj.modal';
import { useBooleanState } from '@web/atomic/obj.boolean-state/boolean-state.hook';
import React, { ReactNode } from 'react';
import ShareBox from '../../components/mol.share-box/share-box.component';

interface IDoctorTutorialProps {
  couponCode: string;
  showTutorialVideo: boolean;
  hasFinancialInfo: boolean;
}

export const DoctorTutorial: React.FunctionComponent<IDoctorTutorialProps> = (props) => {
  const { couponCode, hasFinancialInfo } = props;
  const sharePath = `https://www.vigilantesdosono.com/app/${couponCode ?? ''}`;
  const { value, setFalse: closeModal, setTrue: openModal } = useBooleanState(false);

  if (!couponCode) {
    return (
      <>
        <Row mb center="xs">
          <Col xs={12} md={8} lg={6}>
            <Button
              expanded
              kind="primary"
              external
              to="https://pages.qwilr.com/Vigilantes-do-Sono-Informa-es-para-profissionais-de-sa-de-2XCybtOSGyBP"
            >
              Ver como funciona
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  const { instructionText, copiedText } = getInstructionText(couponCode, sharePath);
  const instructionTitle = 'Passo a passo para convidar novos pacientes';
  const videoUrl = 'https://www.youtube.com/watch?v=j_lPfjF_8vk';

  return (
    <Grid fluid>
      <Row mt mb center="xs">
        <Col xs={12}>
          <FeaturedH2>{instructionTitle}</FeaturedH2>
          <Body center>Passar estas instruções para seus pacientes.</Body>
        </Col>
      </Row>
      <Row mb center="xs">
        {props.showTutorialVideo && (
          <Col xs={12} sm={8} md={6} lg={5}>
            <CardWrapper noPadding>
              <Video url={videoUrl} autoplayLoopMuted={false} />
            </CardWrapper>
          </Col>
        )}
        <Col xs={12} sm={props.showTutorialVideo ? 4 : 8} md={6} lg={5}>
          <ShareBox
            textToBePresented={instructionText}
            textToBeCopied={copiedText}
            title={instructionTitle}
            shareUrl={sharePath}
            secondaryButtonText={props.showTutorialVideo ? null : 'Ver como funciona'}
            onSecondaryButtonClick={props.showTutorialVideo ? null : openModal}
          />
        </Col>
      </Row>
      <Modal opened={value} onClose={closeModal} noPadding>
        <Video url={videoUrl} disableSrcDoc={true} />
      </Modal>
    </Grid>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////

interface IGetInstructionTextResponse {
  copiedText: string;
  instructionText: ReactNode;
}

const getInstructionText = (coupon: string, path: string): IGetInstructionTextResponse => {
  const uppercasedCoupon = coupon?.toUpperCase();
  const copiedText = `Passo a passo para começar o programa da Vigilantes do Sono

  1. Baixar o app da Vigilantes do Sono (disponível para Android e iOS) no link: ${path}.
  2. No fluxo de cadastro do app incluir meu código de parceiro: ${uppercasedCoupon}

  Pronto! Com isso poderei acompanhar sua evolução no programa.`;

  const instructionText: ReactNode = (
    <>
      1. Baixar o app do <a href={path}>Vigilantes do Sono</a> (disponível para Android e iOS).
      <br />
      <br />
      2. No fluxo de cadastro do app incluir meu código de parceiro: <SemiBold> {uppercasedCoupon}</SemiBold>
      <br />
      <br />
      Pronto! Com isso poderei acompanhar sua evolução no programa.
    </>
  );

  return { copiedText, instructionText };
};
