import { Toolbox } from '../remote-graphql-types';
import {
  getEmojiForSleepDiaryTechnique,
  getNameForSleepDiaryTechnique,
  getTechniqueMeta,
  SleepDiaryTechnique,
  TechniqueMeta,
} from './entities';

export interface FormattedTechnique extends TechniqueMeta {
  /**
   * undefined : user hasn't defined it
   * true: inside toolbox
   * false: outside toolbox
   */
  insideToolbox?: boolean;
  key: SleepDiaryTechnique;
  name: string;
  emoji: string;
}

export const getFormattedTechniques = (techniques: Omit<Toolbox, 'user' | 'user_id'>): FormattedTechnique[] => {
  const {
    autogenic_training,
    behavior_activation,
    deep_breath,
    imagery,
    light_therapy,
    challenge_catastrophic_thinking,
    meditation,
    paradoxical_intention,
    parking_lot,
    gratitude,
    pmr,
    sleep_restriction,
    stimulus_control,
    thought_block,
  } = techniques || {};

  const getValue = (enabled: boolean, type: SleepDiaryTechnique) => ({
    insideToolbox: enabled, // TODO: check if it is possible to remove "value"
    key: type,
    name: getNameForSleepDiaryTechnique(type),
    emoji: getEmojiForSleepDiaryTechnique(type),
    ...getTechniqueMeta(type),
  });

  return [
    getValue(autogenic_training, SleepDiaryTechnique.AutgenicTraining),
    getValue(behavior_activation, SleepDiaryTechnique.BehaviorActivation),
    getValue(deep_breath, SleepDiaryTechnique.DeepBreath),
    getValue(imagery, SleepDiaryTechnique.Imagery),
    getValue(challenge_catastrophic_thinking, SleepDiaryTechnique.ChallengeCatastrophicThinking),
    getValue(light_therapy, SleepDiaryTechnique.LightTherapy),
    getValue(meditation, SleepDiaryTechnique.Meditation),
    getValue(paradoxical_intention, SleepDiaryTechnique.ParadoxicalIntention),
    getValue(parking_lot, SleepDiaryTechnique.ParkingLot),
    getValue(gratitude, SleepDiaryTechnique.Gratitude),
    getValue(pmr, SleepDiaryTechnique.PMR),
    getValue(sleep_restriction, SleepDiaryTechnique.SleepRestriction),
    getValue(stimulus_control, SleepDiaryTechnique.StimulusControl),
    getValue(thought_block, SleepDiaryTechnique.ThoughtBlock),
  ].sort((a, b) => {
    if (a.name > b.name) return 1;
    if (b.name > a.name) return -1;
    return 0;
  });
};
