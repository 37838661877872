import { toHour } from '../date';
import { parseToDate } from '../date/parse-to-date';
import { getStandardDeviation } from '../number/statistics';
import { toPercentageOn1To3Scale } from '../number/to-percentage';
import { isNullOrUndefined } from '../object/null-or-undefined';
import { Sleep_Diary, User } from '../remote-graphql-types';
import { getSleepDiaryAnalysis } from './diary-analysis';
import { Gender, getGenderSuffix } from './entities';

/**
 * BUSINESS_RULE: This enum comes from Hasura
 * 1️⃣ Pegar no sono mais rápido 😴
 * 2️⃣ Ficar dormindo a noite inteira 🛌
 * 3️⃣ Acordar mais descansado após cada noite 😎
 * 4️⃣ Ter um horário de sono mais confiável 📊
 */
export enum NightGoals {
  Latency = 1,
  Maintenance = 2,
  Mood = 3,
  Reliability = 4,
}

export const getNightGoalInfo = (goal: NightGoals): { title: string; emoji: string } => {
  switch (goal) {
    case NightGoals.Latency:
      return {
        title: `Pegar no sono mais rápido`,
        emoji: `😴`,
      };
    case NightGoals.Maintenance:
      return {
        title: `Ficar dormindo a noite inteira`,
        emoji: `🛌`,
      };
    case NightGoals.Mood:
      return {
        title: `Acordar mais descansado após cada noite`,
        emoji: `😎`,
      };
    case NightGoals.Reliability:
      return {
        title: `Ter um horário de sono mais confiável`,
        emoji: `📊`,
      };
    default:
      console.error(`ERROR: getNightGoalInfo -> invalid goal: ${goal}`);
      return { title: '', emoji: '' };
  }
};

export const getTitleForNightGoal = (goal: NightGoals): string => {
  const info = getNightGoalInfo(goal);
  return `${info.title} ${info.emoji}`;
};

/**
 * BUSINESS_RULE: This enum comes from Hasura
 * 1️⃣ Humor 😄
 * 2️⃣ Concentração ✍️
 * 3️⃣ Energia ⚡️
 * 4️⃣ Relacionamentos 🤝
 */
export enum DailyGoals {
  Humor = 1,
  Concentration = 2,
  Energy = 3,
  Relationships = 4,
}
export const getDayGoalInfo = (goal: DailyGoals, withArticle = false): { title: string; emoji: string } => {
  switch (goal) {
    case DailyGoals.Humor:
      return {
        title: `${withArticle ? 'o ' : ''}Humor`,
        emoji: `😄`,
      };
    case DailyGoals.Concentration:
      return {
        title: `${withArticle ? 'a ' : ''}Concentração`,
        emoji: `✍️`,
      };
    case DailyGoals.Energy:
      return {
        title: `${withArticle ? 'a ' : ''}Energia`,
        emoji: `⚡️`,
      };
    case DailyGoals.Relationships:
      return {
        title: `${withArticle ? 'os ' : ''}Relacionamentos`,
        emoji: `🤝`,
      };
    default:
      console.error(`ERROR: getDayGoalInfo -> invalid goal: ${goal}`);
      return { title: '', emoji: '' };
  }
};
export const getTitleForDayGoal = (goal: DailyGoals, withArticle = false): string => {
  const info = getDayGoalInfo(goal);
  return `${info.title} ${info.emoji}`;
};

////////////////////////////////////////////////////////////////////////////////////////////////////

export type Goals = keyof PeriodGoalAnalysis;

export function getChosenGoals(user: User): Goals[] {
  const goals: Goals[] = [];

  const dailyGoal = getChosenDailyGoal(user);
  if (dailyGoal) goals.push(dailyGoal);

  const nightGoal = getChosenNightGoal(user);
  if (nightGoal) goals.push(nightGoal);

  if (user.sleep_medicine) {
    goals.push('medicine');
  }

  goals.push('eficiency', 'tst');

  return goals;
}

export function getChosenDailyGoal(user: User): Goals {
  switch (user.day_goal) {
    case DailyGoals.Concentration:
      return 'concentration';
    case DailyGoals.Energy:
      return 'energy';
    case DailyGoals.Humor:
      return 'humor';
    case DailyGoals.Relationships:
      return 'relationship';
  }
}

export function getChosenNightGoal(user: User): Goals {
  switch (user.night_goal) {
    case NightGoals.Latency:
      return 'sol';
    case NightGoals.Maintenance:
      return 'waso';
    case NightGoals.Mood:
      return 'grade';
    case NightGoals.Reliability:
      return 'eficiencyStd';
  }
}

export function getChosenGoalInfo(goal: Goals, gender: Gender = Gender.Other): string {
  switch (goal) {
    case 'eficiencyStd':
      return `Demonstra o quanto sua eficiência do sono variou na última semana. Quanto mais baixo o valor melhor, pois quer dizer que tem dormido consistente`;
    case 'eficiency':
      return `Essa é uma medida sobre a relação entre o tempo que você fica na cama e o tempo que você está realmente dormindo. Quanto mais próxima de 100% melhor, significa que você só está na cama quando está dormindo.`;
    case 'tst':
      return `O tempo total de sono se refere ao número de horas que passou realmente dormindo. O tempo de sono recomendado para adultos é de 7 a 9 horas, mas pode variar.`;
    case 'waso':
      return `Esse é o tempo que você fica acordad${getGenderSuffix(
        gender
      )} na cama após ter iniciado o período de sono incluindo aquele tempo entre seu último despertar até se levantar de vez para iniciar o dia. Quanto menor melhor!`;
    case 'sol':
      return `A latência para o início do sono é o tempo entre apagar as luzes e decidir dormir até conseguir pegar no sono efetivamente. Uma latência de até 20 a 30 minutos é aceitável, maior do que isso, ainda não está legal.`;
    case 'grade':
      return `Aqui você pode analisar a variação na média do objetivo que escolheu no início do programa. Quanto mais próximo de 100%, mais você se sentiu dispost${getGenderSuffix(
        gender
      )} ao acordar na última semana.`;
    case 'humor':
      return `Aqui você pode analisar a variação na média do objetivo que escolheu no início do programa. Quanto mais próximo de 100% o valor, mais você se sentiu de bom humor ao acordar na última semana.`;
    case 'energy':
      return `Aqui você pode analisar a variação na média do objetivo que escolheu no início do programa. Quanto mais próximo de 100% o valor, mais você sentiu energia ao acordar na última semana.`;
    case 'concentration':
      return `Aqui você pode analisar a variação na média do objetivo que escolheu no início do programa. Quanto mais próximo de 100% o valor, mais você teve concentração ao longo do dia na última semana.`;
    case 'relationship':
      return `Aqui você pode analisar a variação na média do objetivo que escolheu no início do programa. Quanto mais próximo de 100% o valor, mais você sentiu que seus relacionamentos tiveram qualidade na última semana. É a média (de 0 a 100) da sua resposta para a pergunta do diário do sono "Como foi a qualidade dos seus relacionamentos com outras pessoas ao seu redor no dia anterior?"`;
    case 'medicine':
      return `É a porcentagem sobre o número de dias que você tomou remédio na última semana`;
  }
}

export function getChosenGoalLegend(goal: Goals): string {
  switch (goal) {
    case 'eficiencyStd':
      return 'variação da eficiência do sono';
    case 'eficiency':
      return 'eficiência do sono';
    case 'tst':
      return 'tempo de sono';
    case 'waso':
      return 'tempo acordado durante a noite';
    case 'sol':
      return 'tempo para dormir';
    case 'grade':
      return 'disposição ao acordar';
    case 'humor':
      return 'avaliação do humor';
    case 'energy':
      return 'avaliação da energia';
    case 'concentration':
      return 'avaliação da concentração';
    case 'relationship':
      return 'avaliação de relacionamentos';
    case 'medicine':
      return 'uso de medicamentos';
  }
}

export function formatGoalValue(value: number, goal: keyof PeriodGoalAnalysis) {
  if (isNullOrUndefined(value)) {
    return null;
  }

  let result;
  switch (goal) {
    case 'eficiencyStd':
      result = `${Math.round(toPercentageOn1To3Scale(value))}%`;
      break;
    case 'eficiency':
      result = `${Math.round(value)}%`;
      break;
    case 'tst':
      result = `${toHour(value)}`;
      break;
    case 'waso':
      result = `${toHour(value)}`;
      break;
    case 'sol':
      result = `${Math.round(value)} min`;
      break;
    case 'grade':
      result = `${Math.round(toPercentageOn1To3Scale(value))}%`;
      break;
    case 'humor':
      result = `${Math.round(toPercentageOn1To3Scale(value))}%`;
      break;
    case 'energy':
      result = `${Math.round(toPercentageOn1To3Scale(value))}%`;
      break;
    case 'concentration':
      result = `${Math.round(toPercentageOn1To3Scale(value))}%`;
      break;
    case 'relationship':
      result = `${Math.round(toPercentageOn1To3Scale(value))}%`;
      break;
    case 'medicine':
      result = `${Math.round(value)}%`;
      break;
    default:
      console.error('ERROR: goal not handled', 'value', value, 'goal', goal, 'result', result);
  }
  if (result.includes('NaN')) {
    console.warn('WARN: formatGoalValue ~ ', 'value', value, 'goal', goal, 'result', result);
  }
  return result;
}

////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * The analysis (mean values) of some vars for a given batch of sleep diaries
 */
export interface PeriodGoalAnalysis {
  /**
   * sleep eficiency
   * = (minutesSleep / minutesInBed * 100):  0 - 100
   */
  eficiency: number;

  /** eficiency standard deviation */
  eficiencyStd: number;

  /**
   * total sleep time (TST) (minutes)
   *   = (wakeUp - goSleep) - timeToSleep - wakeUpDuration
   */
  tst: number;

  /**
   * wake time after sleep onset (WASO) (minutes)
   *    = (wakeUpDuration + (getUp - wakeUp))
   */
  waso: number;

  /**
   * sleep-onset latency (SOL) (minutes) : the mean value of the field "time_to_sleep" from sleep diary
   */
  sol: number;

  /**
   * avg 1 - 3  (the mean value for sleep satisfaction on the sleep diary)
   */
  grade?: number;

  /** avg 1 - 3 */
  humor?: number;

  /** avg 1 - 3 */
  energy?: number;

  /** avg 1 - 3 */
  concentration?: number;

  /** avg 1 - 3 */
  relationship?: number;

  /**
   * frequency %  (i.e. for a given quantity of diaries, what's the percentage that the user has taken medicine)
   */
  medicine?: number;
}

/**
 * Use this method to analyse the user goal using an array of diaries
 * @param diaries
 */
export const getPeriodAnalysis = (diaries: DiaryGoalAnalysis[]): PeriodGoalAnalysis => {
  const preAnalysis = diaries.reduce(
    (acc, curr) => {
      acc.eficiency = acc.eficiency + curr.eficiency;
      acc.tst = acc.tst + curr.tst;
      acc.waso = acc.waso + curr.waso;
      acc.sol = acc.sol + curr.sol;
      acc.grade = acc.grade + (curr.grade || 0);
      acc.humor = acc.humor + (curr.humor || 0);
      acc.energy = acc.energy + (curr.energy || 0);
      acc.concentration = acc.concentration + (curr.concentration || 0);
      acc.relationship = acc.relationship + (curr.relationship || 0);
      acc.medicine += curr.medicine ? 1 : 0;
      return acc;
    },
    {
      eficiency: 0,
      tst: 0,
      waso: 0,
      sol: 0,
      grade: 0,
      humor: 0,
      energy: 0,
      concentration: 0,
      relationship: 0,
      medicine: 0,
    } as Partial<PeriodGoalAnalysis>
  );
  return {
    eficiencyStd: getStandardDeviation(diaries.map((item) => item.eficiency)),
    eficiency: preAnalysis.eficiency / diaries.length,
    tst: preAnalysis.tst / diaries.length,
    waso: preAnalysis.waso / diaries.length,
    sol: preAnalysis.sol / diaries.length,
    grade: preAnalysis.grade ? preAnalysis.grade / diaries.filter((item) => !isNullOrUndefined(item.grade)).length : undefined,
    humor: preAnalysis.humor ? preAnalysis.humor / diaries.filter((d) => !isNullOrUndefined(d.humor)).length : undefined,
    energy: preAnalysis.energy ? preAnalysis.energy / diaries.filter((d) => !isNullOrUndefined(d.energy)).length : undefined,
    concentration: preAnalysis.concentration
      ? preAnalysis.concentration / diaries.filter((d) => !isNullOrUndefined(d.concentration)).length
      : undefined,
    relationship: preAnalysis.relationship
      ? preAnalysis.relationship / diaries.filter((d) => !isNullOrUndefined(d.relationship)).length
      : undefined,
    medicine: preAnalysis.medicine / diaries.filter((d) => !isNullOrUndefined(d.medicine)).length,
  };
};

////////////////////////////////////////////////////////////////////////////////////////////////////

export interface DiaryGoalAnalysis {
  date: Date;
  eficiency: number; // %
  tst: number; // total sleep time (TST) - minutes
  waso: number; // wake time after sleep onset (WASO) - minutes
  sol: number; //  sleep-onset latency (SOL) - minutes
  grade: number; // 1- 3

  humor?: number; // 1-3
  energy?: number; // 1-3
  concentration?: number; // 1-3
  relationship?: number; // 1-3
  medicine?: boolean;
}

/**
 * Use this mapper to map a diary to a user goal perspective
 * @param diary
 * @param consumeSleepMedicine
 */
export function mapToDiaryAnalysis(diary: Sleep_Diary, consumeSleepMedicine: boolean): DiaryGoalAnalysis {
  const analysis = getSleepDiaryAnalysis({
    goBed: diary.go_bed,
    goSleep: diary.go_sleep,
    timeToSleep: diary.time_to_sleep,
    wakeUpDuration: diary.wake_up_duration,
    wakeUp: diary.wake_up,
    getUp: diary.get_up,
  });
  return {
    date: parseToDate(diary.date),
    eficiency: analysis.eficiency,
    tst: analysis.minutesSleep,
    waso: analysis.awakeAtNight,
    sol: diary.time_to_sleep,
    medicine: consumeSleepMedicine ? !!diary.tags.find((tag) => tag.sleep_tag === 'medicine') : undefined,
    grade: diary.grade,
    humor: diary.humor,
    energy: diary.energy,
    concentration: diary.concentration,
    relationship: diary.relationships,
  };
}

////////////////////////////////////////////////////////////////////////////////////////////////////
export enum TermDescriptionTagDirection {
  Up,
  Down,
  Equal,
}

export enum TermDescriptionTagColor {
  Red = '#E72400',
  Neutral = '#354991',
  Green = '#00AF8E',
}

export interface TermDescriptionTagAnalysis {
  direction: TermDescriptionTagDirection;
  color: TermDescriptionTagColor;
}

/**
 * Use this rule when the lower the value of a metric the better
 * @param analysis
 */
const lowerTheBetterColorAnalysis = (analysis: TermDescriptionTagAnalysis): TermDescriptionTagColor => {
  return analysis.direction === TermDescriptionTagDirection.Down
    ? TermDescriptionTagColor.Green
    : analysis.direction === TermDescriptionTagDirection.Up
    ? TermDescriptionTagColor.Red
    : TermDescriptionTagColor.Neutral;
};

/**
 * Use this rule when the higher the metric's value the better
 * @param analysis
 */
const higherTheBetterColorAnalysis = (analysis: TermDescriptionTagAnalysis): TermDescriptionTagColor => {
  return analysis.direction === TermDescriptionTagDirection.Up
    ? TermDescriptionTagColor.Green
    : analysis.direction === TermDescriptionTagDirection.Down
    ? TermDescriptionTagColor.Red
    : TermDescriptionTagColor.Neutral;
};

/**
 *
 * Use this rule to analyze changes in statistics:
 *
 * TODO OO switch refactor: https://refactoring.guru/pt-br/smells/switch-statements
 *
 * @param a last diaries
 * @param b basal value (first diaries)
 * @param goal user's goal
 */
export const getTermDescriptionTagAnalysis = (a: number, b: number, goal: Goals): TermDescriptionTagAnalysis => {
  const analysis: TermDescriptionTagAnalysis = { direction: null, color: null };

  if (a === b) {
    analysis.direction = TermDescriptionTagDirection.Equal;
  } else {
    analysis.direction = a > b ? TermDescriptionTagDirection.Up : TermDescriptionTagDirection.Down;
  }
  // * Obs: these numbers are used as reference values
  // Notion: https://www.notion.so/vigilantesdosono/melhoria-em-Voc-quando-diminui-a-lat-ncia-8a5dc8b4a4a2482bb41a4ddabb6489f2
  const recommendedSleepTime = 7 * 60;
  const recommendedLatency = 20;

  switch (goal) {
    case 'eficiencyStd':
      analysis.color = lowerTheBetterColorAnalysis(analysis);
      break;
    case 'eficiency':
      analysis.color = higherTheBetterColorAnalysis(analysis);
      break;
    case 'tst':
      if (analysis.direction === TermDescriptionTagDirection.Down)
        analysis.color = a >= recommendedSleepTime ? TermDescriptionTagColor.Neutral : TermDescriptionTagColor.Red;

      if (analysis.direction === TermDescriptionTagDirection.Up)
        analysis.color = a >= recommendedSleepTime ? TermDescriptionTagColor.Neutral : TermDescriptionTagColor.Green;

      break;
    case 'waso':
      analysis.color = lowerTheBetterColorAnalysis(analysis);
      break;
    case 'sol':
      if (analysis.direction === TermDescriptionTagDirection.Down)
        analysis.color = a >= recommendedLatency ? TermDescriptionTagColor.Green : TermDescriptionTagColor.Neutral;

      if (analysis.direction === TermDescriptionTagDirection.Up)
        analysis.color = a < recommendedLatency ? TermDescriptionTagColor.Neutral : TermDescriptionTagColor.Red;

      break;
    case 'grade':
      analysis.color = higherTheBetterColorAnalysis(analysis);
      break;
    case 'humor':
      analysis.color = higherTheBetterColorAnalysis(analysis);
      break;
    case 'energy':
      analysis.color = higherTheBetterColorAnalysis(analysis);
      break;
    case 'concentration':
      analysis.color = higherTheBetterColorAnalysis(analysis);
      break;
    case 'relationship':
      analysis.color = higherTheBetterColorAnalysis(analysis);
      break;
    case 'medicine':
      analysis.color = lowerTheBetterColorAnalysis(analysis);
      break;
  }

  return analysis;
};
