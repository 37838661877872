import React, { useState, useEffect } from 'react';
import { TextShimmerBoxStyled } from './shimmer.component.style';
import { buttonHeight } from '../../atm.button/button.component.style';

export class ShimmerButton extends React.PureComponent {
  render(): JSX.Element {
    return <TextShimmerBoxStyled height={buttonHeight} width={'100%'} />;
  }
}

export class ShimmerInput extends React.PureComponent {
  render(): JSX.Element {
    return (
      <>
        <TextShimmerBoxStyled height="15px" width={'30%'} />
        <TextShimmerBoxStyled height="1px" margin={'44px 0 0 0'} />
      </>
    );
  }
}

export interface ShimmerTextProps {
  height?: string;
  width?: string;
}

export const ShimmerText: React.FunctionComponent<ShimmerTextProps> = (props) => {
  const [width, setWidth] = useState(`${props.width}` || '0');
  useEffect(() => {
    if (!props.width) {
      setWidth(`${Math.floor(Math.random() * 100) + 1}%`);
    }
  }, []);
  return <TextShimmerBoxStyled height={props.height} width={width} />;
};

export interface ShimmerBoxProps {
  height: string;
  width: string;
  margin?: string;
}

export const ShimmerBox: React.FunctionComponent<ShimmerBoxProps> = (props) => {
  return <TextShimmerBoxStyled height={props.height} width={props.width} margin={props.margin} />;
};

ShimmerText.defaultProps = {
  height: '15px',
};
