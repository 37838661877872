/* eslint-disable */

const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const STRICTER_EMAIL_REGEX = /^([a-z0-9|.||-]+)@([a-z0-9|.|]+)$/;
const PHONE_REGEX = /^(\(?[0-9]{2}\)? ?[0-9]{4}-?[0-9]{4})|(\(?[0-9]{2}\)? ?[0-9]{5}-?[0-9]{4})$/i;
const BRAZILIAN_PHONE_REGEX = /^55[0-9]{2}9[0-9]{8}$/i;
const ZIP_CODE_REGEX = /^\d{5}[-]\d{3}$/;
const CPF_REGEX = /^([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[\-]?[0-9]{2})$/i;
const CNPJ_REGEX = /^([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})$/i;
const CPF_CNPJ_REGEX =
  /^([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})$|^([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})$/i;
const DATE_REGEX = /^((0?[1-9]|[12][0-9]|3[01])[/](0?[1-9]|1[012])([/](19|20)[0-9]{2})?)*$/;
const TIME_REGEX = /^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9](:[0-9][0-9]\+[0-9][0-9])?$/;
const PATHNAME_REGEX = /^[A-Za-z\-\.]+$/;

// At least 1 char and 1 number regex: https://stackoverflow.com/a/7684859/3670829
// Security recommendation: Failing to enforce passwords of at least 7 characters, a complexity
// of at least alpha and numeric characters increases the risk of a brute force attack.
export const PASSWORD_REGEX = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9])$/;

export function isEmailValid(email): boolean {
  return EMAIL_REGEX.test(email);
}

export function isEmailValidStricter(email): boolean {
  return STRICTER_EMAIL_REGEX.test(email?.toLowerCase());
}

export function isPathNameValid(email): boolean {
  return PATHNAME_REGEX.test(email);
}

export function isPhoneValid(phone): boolean {
  return PHONE_REGEX.test(phone);
}

export function isBrazilianNumberPhoneValid(phone): boolean {
  return BRAZILIAN_PHONE_REGEX.test(phone);
}

export function isTimeValid(date): boolean {
  if (date instanceof Date) {
    return true;
  }
  return TIME_REGEX.test(date);
}

export function isDateValid(date): boolean {
  if (date instanceof Date) {
    return true;
  }
  return DATE_REGEX.test(date);
}

export function isPasswordValid(password): boolean {
  return PASSWORD_REGEX.test(password);
}

export function isZipCodeValid(value): boolean {
  return ZIP_CODE_REGEX.test(value);
}

export function isCpfPatternValid(value): boolean {
  return CPF_REGEX.test(value);
}

/** this can be MM/YYYY or MM/YY */
export const parseCreditCardDate = (date: string) => {
  const validDateFormat = /^\d\d\/\d\d\d\d$/.test(date) || /^\d\d\/\d\d$/.test(date);
  if (!validDateFormat) {
    return null;
  }

  const dateComponents = date.split('/');
  const month = Number(dateComponents[0]);
  if (month <= 0 || month > 12) {
    return null;
  }

  let year = dateComponents[1];

  const currDate = new Date();
  const currYear = currDate.getFullYear().toString();
  const currPrefix = currYear.substring(0, 2);
  const currSuffix = currYear.slice(2);

  // The following logic parses a yearSuffix to the future or past (considering an interval of 20 years).
  // Examples:
  // FUTURE: if current year is 1998 and yearSuffix is 01, then year should be 2001
  // PAST: if current year is 2005 and yearSuffix is 01, then year should be 2001
  if (year.length === 2) {
    const yearSuffix = year;
    const yearPrefix = Number(year) - Number(currSuffix) > 0 || Number(year) - Number(currSuffix) > -20 ? currPrefix : currPrefix + 1;

    year = `${yearPrefix}${yearSuffix}`;
  }

  const parsedDate = new Date(Number(year), month - 1);
  return parsedDate;
};

// Luhn algorithm - source: https://gist.github.com/DiegoSalazar/4075533
export function isCardNumberValid(value): boolean {
  if (/[^0-9-\s]+/.test(value)) {
    return false;
  }

  let nCheck = 0;
  let nDigit = 0;
  let bEven = false;

  value = value.replace(/\D/g, '');

  for (let n = value.length - 1; n >= 0; n--) {
    const cDigit = value.charAt(n);
    nDigit = parseInt(cDigit, 10);
    if (bEven) {
      nDigit *= 2;
      if (nDigit > 9) {
        nDigit -= 9;
      }
    }

    nCheck += nDigit;
    bEven = !bEven;
  }

  if (nCheck % 10 !== 0) {
    return false;
  }

  const firstDigit = parseInt(value.charAt(0), 10);
  // AMEX/Diners cards (first digit is 3): 14~15 digits; others: 16~19 digits
  return (firstDigit === 3 && value.length >= 14) || value.length >= 16;
}
