import * as React from 'react';
import { ButtonProxy, ButtonProps } from '@web/atomic/atm.button/button.component';
import { ExternalLinkButtonStyled, ButtonStyled } from '@web/atomic/atm.button/button.component.style';
import { LinkButtonStyled } from './button.component.style';

export class ReachButton extends React.Component<ButtonProps> {
  static defaultProps = {
    disabled: false,
    expanded: false,
    loading: false,
    showActiveClass: true,
    kind: 'primary',
    type: 'button',
  };

  render() {
    return ButtonProxy(this.props.to ? (this.props.external ? ExternalLinkButtonStyled : LinkButtonStyled) : ButtonStyled, this.props);
  }
}
