/**
 * What's that? How to use it?
 * https://refactoring.guru/pt-br/design-patterns/chain-of-responsibility/typescript/example
 */

export interface ChainOfResponsibilityHandler<Req, Res> {
  setNext(handler: ChainOfResponsibilityHandler<Req, Res>): ChainOfResponsibilityHandler<Req, Res>;
  handle(request: Req): Res;
}

export abstract class ChainOfResponsibilityAbstractHandler<Req, Res> implements ChainOfResponsibilityHandler<Req, Res> {
  private nextHandler: ChainOfResponsibilityHandler<Req, Res>;

  public setNext(handler: ChainOfResponsibilityHandler<Req, Res>): ChainOfResponsibilityHandler<Req, Res> {
    this.nextHandler = handler;
    return handler;
  }

  public handle(request: Req): Res {
    if (this.nextHandler) {
      return this.nextHandler.handle(request);
    }

    return null;
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////

// A helper method to assemble the ChainOfResponsibility from an array
export function assembleChainOfResponsibilityFromArray<Req = any, Res = any>(
  handlers: ChainOfResponsibilityHandler<Req, Res>[]
): ChainOfResponsibilityHandler<Req, Res> {
  const mainHandler = handlers[0];
  handlers.slice(1).reduce((acc, curr) => acc.setNext(curr), mainHandler);
  return mainHandler;
}
