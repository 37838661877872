import { getStorage, StorageType } from './storage/storage.provider';

const SEEN_AUTOFILL_KEY = 'HasSeenSleepdiaryAutofill';
const SEEN_SONO_VIRTUAL = 'HasSeenSonoVirtual';
const SEEN_POLICY_PRIVATE = 'HasSeenPolicyPrivate';

class OnboardLogDataSource {
  private localStorage = getStorage(StorageType.Local);

  get hasSeenAutofill() {
    return this.localStorage.get(SEEN_AUTOFILL_KEY) || false;
  }
  set hasSeenAutofill(value: boolean) {
    this.localStorage.put(SEEN_AUTOFILL_KEY, value);
  }

  get hasSeenSonoVirtual() {
    return this.localStorage.get(SEEN_SONO_VIRTUAL) || false;
  }

  set hasSeenSonoVirtual(value: boolean) {
    this.localStorage.put(SEEN_SONO_VIRTUAL, value);
  }

  get hasAgreedToPrivacyPolicy() {
    return this.localStorage.get(SEEN_POLICY_PRIVATE) || false;
  }

  set hasAgreedToPrivacyPolicy(value: boolean) {
    this.localStorage.put(SEEN_POLICY_PRIVATE, value);
  }
}

export default new OnboardLogDataSource();
