import { isNullOrUndefined } from '../object/null-or-undefined';
import { DoctorReportQueryQuery, Sleep_Tag_Enum_Enum } from '../remote-graphql-types';
import { SleepDiaryAnalysis, getSleepDiaryAnalysis } from './diary-analysis';
import {
  SleepDiaryGrade,
  SleepDiaryTag,
  SleepDiaryTechnique,
  TechniqueKind,
  getEmojiAndNameForSleepDiaryTag,
  getEmojiAndNameForSleepDiaryTechnique,
  getEmojiForSleepDiaryTag,
  getEmojiForSleepDiaryTechnique,
  getTechniqueMeta,
  roundGradeValue,
} from './entities';
import { getDiaryTags, getMedicineAcronym } from './sleep-diary';

/**
 * map an array of sleep diaries to their tag and technique summary. This
 * method is useful to analyze how each tag or summary affects this person's sleep
 * @param sleepDiary
 */
export const mapToSleepTagAndTechniqueSummary = (sleepDiary: DoctorReportQueryQuery['sleep_diary']): SleepTagAndTechniqueSummary => {
  const analysis = sleepDiary.map((item) =>
    getSleepDiaryAnalysis({
      goBed: item.go_bed,
      goSleep: item.go_sleep,
      timeToSleep: item.time_to_sleep,
      wakeUpDuration: item.wake_up_duration,
      wakeUp: item.wake_up,
      getUp: item.get_up,
    })
  );
  const groupByTechnique = getGroupByTechniqueAndTag(sleepDiary, analysis);
  return getMeanValues(groupByTechnique);
};

export interface SleepDataSummaryItem {
  count: number;
  timeAsleep: number;
  timeAwakeInBed: number;
  sleepEfficiency: number;
  /** grade may be undefined, so "gradeCount" can be different from "count" */
  gradeCount: number;
  grade: number;
}

export type SleepTagAndTechniqueSummary = Record<string, SleepDataSummaryItem> & {
  all: SleepDataSummaryItem;

  // tags
  alcohol: SleepDataSummaryItem;
  bathroom: SleepDataSummaryItem;
  caffeine: SleepDataSummaryItem;
  exercise: SleepDataSummaryItem;
  light: SleepDataSummaryItem;
  meal: SleepDataSummaryItem;
  medicine: SleepDataSummaryItem;
  nap: SleepDataSummaryItem;
  nicotine: SleepDataSummaryItem;
  noise: SleepDataSummaryItem;
  pain: SleepDataSummaryItem;
  partner: SleepDataSummaryItem;
  temperature: SleepDataSummaryItem;
  dream: SleepDataSummaryItem;

  // techniques
  autogenic_training: SleepDataSummaryItem;
  behavior_activation: SleepDataSummaryItem;
  challenge_catastrophic_thinking: SleepDataSummaryItem;
  deep_breath: SleepDataSummaryItem;
  gratitude: SleepDataSummaryItem;
  imagery: SleepDataSummaryItem;
  light_therapy: SleepDataSummaryItem;
  meditation: SleepDataSummaryItem;
  paradoxical_intention: SleepDataSummaryItem;
  parking_lot: SleepDataSummaryItem;
  pmr: SleepDataSummaryItem;
  stimulus_control: SleepDataSummaryItem;
  thought_block: SleepDataSummaryItem;
};

interface GroupByTechnique {
  all: GroupByTechniqueItem[];
  // tags
  alcohol: GroupByTechniqueItem[];
  bathroom: GroupByTechniqueItem[];
  caffeine: GroupByTechniqueItem[];
  exercise: GroupByTechniqueItem[];
  light: GroupByTechniqueItem[];
  meal: GroupByTechniqueItem[];
  medicine: GroupByTechniqueItem[];
  nap: GroupByTechniqueItem[];
  nicotine: GroupByTechniqueItem[];
  noise: GroupByTechniqueItem[];
  pain: GroupByTechniqueItem[];
  partner: GroupByTechniqueItem[];
  temperature: GroupByTechniqueItem[];
  dream: GroupByTechniqueItem[];
  // techniques
  autogenic_training: GroupByTechniqueItem[];
  behavior_activation: GroupByTechniqueItem[];
  challenge_catastrophic_thinking: GroupByTechniqueItem[];
  deep_breath: GroupByTechniqueItem[];
  gratitude: GroupByTechniqueItem[];
  imagery: GroupByTechniqueItem[];
  light_therapy: GroupByTechniqueItem[];
  meditation: GroupByTechniqueItem[];
  paradoxical_intention: GroupByTechniqueItem[];
  parking_lot: GroupByTechniqueItem[];
  pmr: GroupByTechniqueItem[];
  stimulus_control: GroupByTechniqueItem[];
  thought_block: GroupByTechniqueItem[];
}
interface GroupByTechniqueItem extends SleepDiaryAnalysis {
  grade: SleepDiaryGrade;
}

function getGroupByTechniqueAndTag(allData: DoctorReportQueryQuery['sleep_diary'], analysis: SleepDiaryAnalysis[]) {
  return allData.reduce(
    (acc, diary, index) => {
      const diaryAnalysis: GroupByTechniqueItem = {
        grade: diary.grade,
        ...analysis[index],
      };
      acc.all.push(diaryAnalysis);
      if (diary.autogenic_training) {
        acc.autogenic_training.push(diaryAnalysis);
      }
      if (diary.behavior_activation) {
        acc.behavior_activation.push(diaryAnalysis);
      }
      if (diary.challenge_catastrophic_thinking) {
        acc.challenge_catastrophic_thinking.push(diaryAnalysis);
      }
      if (diary.deep_breath) {
        acc.deep_breath.push(diaryAnalysis);
      }
      if (diary.gratitude) {
        acc.gratitude.push(diaryAnalysis);
      }
      if (diary.imagery) {
        acc.imagery.push(diaryAnalysis);
      }
      if (diary.light_therapy) {
        acc.light_therapy.push(diaryAnalysis);
      }
      if (diary.pmr) {
        acc.pmr.push(diaryAnalysis);
      }
      if (diary.meditation) {
        acc.meditation.push(diaryAnalysis);
      }
      if (diary.paradoxical_intention) {
        acc.paradoxical_intention.push(diaryAnalysis);
      }
      if (diary.parking_lot) {
        acc.parking_lot.push(diaryAnalysis);
      }
      if (diary.stimulus_control) {
        acc.stimulus_control.push(diaryAnalysis);
      }
      if (diary.thought_block) {
        acc.thought_block.push(diaryAnalysis);
      }
      const tags = getDiaryTags(diary).map((d) => d.sleep_tag);
      if (tags?.length) {
        if (tags.includes(Sleep_Tag_Enum_Enum.Alcohol)) {
          acc.alcohol.push(diaryAnalysis);
        }
        if (tags.includes(Sleep_Tag_Enum_Enum.Bathroom)) {
          acc.bathroom.push(diaryAnalysis);
        }
        if (tags.includes(Sleep_Tag_Enum_Enum.Caffeine)) {
          acc.caffeine.push(diaryAnalysis);
        }
        if (tags.includes(Sleep_Tag_Enum_Enum.Exercise)) {
          acc.exercise.push(diaryAnalysis);
        }
        if (tags.includes(Sleep_Tag_Enum_Enum.Light)) {
          acc.light.push(diaryAnalysis);
        }
        if (tags.includes(Sleep_Tag_Enum_Enum.Meal)) {
          acc.meal.push(diaryAnalysis);
        }
        if (tags.includes(Sleep_Tag_Enum_Enum.Medicine)) {
          acc.medicine.push(diaryAnalysis);
        }
        if (tags.includes(Sleep_Tag_Enum_Enum.Nap)) {
          acc.nap.push(diaryAnalysis);
        }
        if (tags.includes(Sleep_Tag_Enum_Enum.Nicotine)) {
          acc.nicotine.push(diaryAnalysis);
        }
        if (tags.includes(Sleep_Tag_Enum_Enum.Noise)) {
          acc.noise.push(diaryAnalysis);
        }
        if (tags.includes(Sleep_Tag_Enum_Enum.Pain)) {
          acc.pain.push(diaryAnalysis);
        }
        if (tags.includes(Sleep_Tag_Enum_Enum.Partner)) {
          acc.partner.push(diaryAnalysis);
        }
        if (tags.includes(Sleep_Tag_Enum_Enum.Temperature)) {
          acc.temperature.push(diaryAnalysis);
        }
        if (tags.includes(Sleep_Tag_Enum_Enum.Dream)) {
          acc.dream.push(diaryAnalysis);
        }
      }

      if (diary.sleep_diary_medicines?.length) {
        diary.sleep_diary_medicines.forEach((d) => {
          if (acc[d.name]) {
            acc[d.name].push(diaryAnalysis);
          } else {
            acc[d.name] = [diaryAnalysis];
          }
        });
      }

      return acc;
    },
    {
      all: [],
      // tags
      alcohol: [],
      bathroom: [],
      caffeine: [],
      exercise: [],
      light: [],
      meal: [],
      medicine: [],
      nap: [],
      nicotine: [],
      noise: [],
      pain: [],
      partner: [],
      temperature: [],
      dream: [],
      // techniques
      autogenic_training: [],
      behavior_activation: [],
      challenge_catastrophic_thinking: [],
      deep_breath: [],
      gratitude: [],
      imagery: [],
      light_therapy: [],
      meditation: [],
      paradoxical_intention: [],
      parking_lot: [],
      pmr: [],
      stimulus_control: [],
      thought_block: [],
    } as GroupByTechnique
  );
}
function getMeanValues(input): SleepTagAndTechniqueSummary {
  const groupByTechnique = Object.assign({}, input);
  Object.keys(groupByTechnique).forEach((key) => {
    const group: GroupByTechniqueItem[] = groupByTechnique[key];
    groupByTechnique[key] = group.reduce(
      (acc, curr) =>
        ({
          count: acc.count + 1,
          timeAsleep: acc.timeAsleep + curr.minutesSleep,
          timeAwakeInBed: acc.timeAwakeInBed + (curr.minutesInBed - curr.minutesSleep),
          sleepEfficiency: acc.sleepEfficiency + curr.eficiency,
          grade: acc.grade + (curr.grade ?? 0),
          gradeCount: acc.gradeCount + (isNullOrUndefined(curr.grade) ? 0 : 1),
        } as SleepDataSummaryItem),
      { count: 0, gradeCount: 0, timeAsleep: 0, timeAwakeInBed: 0, sleepEfficiency: 0, grade: 0 } as SleepDataSummaryItem
    );
    const reducedGroup: SleepDataSummaryItem = groupByTechnique[key];
    if (reducedGroup.count > 0) {
      groupByTechnique[key] = {
        count: reducedGroup.count,
        timeAsleep: Math.round(reducedGroup.timeAsleep / reducedGroup.count),
        timeAwakeInBed: Math.round(reducedGroup.timeAwakeInBed / reducedGroup.count),
        sleepEfficiency: Math.round(reducedGroup.sleepEfficiency / reducedGroup.count),
        gradeCount: reducedGroup.gradeCount,
        grade: roundGradeValue(reducedGroup.grade / reducedGroup.gradeCount),
      } as SleepDataSummaryItem;
    }
  });
  return groupByTechnique as SleepTagAndTechniqueSummary;
}

////////////////////////////////////////////////////////////////////////////////////////////////////

export const getSummaryKeysWithOccurence = (summary: SleepTagAndTechniqueSummary): (keyof SleepTagAndTechniqueSummary)[] =>
  summary && (Object.keys(summary).filter((key) => summary[key].count) as (keyof SleepTagAndTechniqueSummary)[]);

////////////////////////////////////////////////////////////////////////////////////////////////////

export const mapFactorToName = (key: keyof SleepTagAndTechniqueSummary): string => {
  if (key === 'all') {
    return 'todos os diários';
  }
  if (isTechnique(key)) {
    return getEmojiAndNameForSleepDiaryTechnique(key as SleepDiaryTechnique);
  }
  if (isTag(key)) {
    return getEmojiAndNameForSleepDiaryTag(key as SleepDiaryTag);
  }

  // else: it is a medicine name
  return `💊 ${key}`;
};

export const mapFactorToEmoji = (key: keyof SleepTagAndTechniqueSummary): string => {
  if (key === 'all') {
    return 'Total';
  }
  if (isTechnique(key)) {
    return getEmojiForSleepDiaryTechnique(key as SleepDiaryTechnique);
  }
  if (isTag(key)) {
    return getEmojiForSleepDiaryTag(key as SleepDiaryTag);
  }

  // else: it is a medicine
  return getMedicineAcronym(key);
};

export const isTechnique = (key: keyof SleepTagAndTechniqueSummary): boolean => {
  return Object.values(SleepDiaryTechnique).includes(key as SleepDiaryTechnique);
};

export const isTag = (key: keyof SleepTagAndTechniqueSummary): boolean => {
  return Object.values(SleepDiaryTag).includes(key as SleepDiaryTag);
};

////////////////////////////////////////////////////////////////////////////////////////////////////

interface NameAndEfficiency {
  name: string;
  efficiency: number;
}
interface Technique extends NameAndEfficiency {
  key?: SleepDiaryTechnique;
}
interface Factor extends NameAndEfficiency {
  key?: SleepDiaryTag;
}
interface ExcerciseFactor extends Factor {
  key?: SleepDiaryTag.Exercise;
}

/**
 * Use this method to answer the following questions:
 * - Which relax trainning is the best?
 * - Which cognitive technique is the best?
 * - Which technique is the best overall?
 * - Which sleep factor is the worst?
 *
 * @param summary
 */
export function getBestRelaxCognitiveTechniqueAndWorstTag(summary: SleepTagAndTechniqueSummary): {
  bestTechnique: Technique | ExcerciseFactor;
  bestRelax: Technique;
  bestCognitive: Technique;
  worstTag: Factor;
} {
  const summaryKeys: (keyof SleepTagAndTechniqueSummary)[] = getSummaryKeysWithOccurence(summary);
  return summaryKeys.reduce(
    (acc, curr) => {
      if (isTechnique(curr)) {
        const sleepDiaryTechnique = curr as SleepDiaryTechnique;
        const kind = getTechniqueMeta(sleepDiaryTechnique).kind;
        const technique = summary[curr];
        if (kind === TechniqueKind.Relax && technique.sleepEfficiency > acc.bestRelax.efficiency) {
          acc.bestRelax = {
            name: mapFactorToName(curr),
            efficiency: technique.sleepEfficiency,
            key: sleepDiaryTechnique,
          };
        } else if (kind === TechniqueKind.Cognitive && technique.sleepEfficiency > acc.bestCognitive.efficiency) {
          acc.bestCognitive = {
            name: mapFactorToName(curr),
            efficiency: technique.sleepEfficiency,
            key: sleepDiaryTechnique,
          };
        }
        if (technique.sleepEfficiency > acc.bestTechnique.efficiency) {
          acc.bestTechnique = {
            name: mapFactorToName(curr),
            efficiency: technique.sleepEfficiency,
            key: sleepDiaryTechnique,
          };
        }
      } else if (isTag(curr)) {
        const tag = summary[curr];
        if (curr === SleepDiaryTag.Exercise && tag.sleepEfficiency > acc.bestTechnique.efficiency) {
          acc.bestTechnique = {
            name: mapFactorToName(curr),
            efficiency: summary[curr].sleepEfficiency,
            key: SleepDiaryTag.Exercise,
          };
        }

        if (tag.sleepEfficiency < acc.worstTag.efficiency) {
          if (exerciceIsUniqueTag(summaryKeys)) return acc;
          acc.worstTag = {
            name: mapFactorToName(curr),
            efficiency: tag.sleepEfficiency,
            key: curr as SleepDiaryTag,
          };
        }
      }

      return acc;
    },
    {
      bestTechnique: { name: '', efficiency: 0, key: null },
      bestRelax: { name: '', efficiency: 0, key: null },
      bestCognitive: { name: '', efficiency: 0, key: null },
      worstTag: { name: '', efficiency: 100, key: null },
    }
  );
}

//https://www.notion.so/vigilantesdosono/Exerc-cio-f-sico-aparecendo-como-fator-que-atrapalha-c335746c616b46d4b3cc9f34d3455e57
const exerciceIsUniqueTag = (summaryKeys: (keyof SleepTagAndTechniqueSummary)[]): boolean => {
  const notExerciseTagsCount = summaryKeys.reduce((acc, curr) => {
    if (isTag(curr) && curr !== SleepDiaryTag.Exercise) {
      return acc + 1;
    } else {
      return acc;
    }
  }, 0);
  return notExerciseTagsCount > 0 ? false : true;
};
