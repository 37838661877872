import { GrayColor, LightColor, HeaderHeight, FontSize, Spacing } from '@web/atomic/legacy/obj.constants';
import styled from 'styled-components';
import { H3Style } from '@web/atomic';

export const HeaderStyled = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 99;
  background: ${LightColor.Primary};
  box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.5);
  padding: 0 ${Spacing.Medium};
  height: ${HeaderHeight.Mobile}px;
  user-select: none;
  @media all and (min-width: 32em) {
    padding: 0 ${Spacing.XLarge};
    height: ${HeaderHeight.Desk}px;
  }
  @media print {
    display: none;
  }
`;

export const HeaderLogoTextStyled = styled.div`
  ${H3Style};
  color: ${GrayColor.White};
  white-space: nowrap;
  margin: 0;
  letter-spacing: 1px;
  font-size: ${FontSize.XSmall};
`;

export const HeaderPlaceholder = styled.div`
  height: ${HeaderHeight.Desk}px;
  display: flex;
  @media print {
    display: none;
  }
`;
