// TODO: REFACTOR - create PSQI entity and move this logic there

import { ChatfuelBlockIds, ChatfuelBlockNames } from '../chat/chatfuel-blocks-types';
import { getTimeInMinutesChronologically } from '../date/get-time-in-minutes-chronologically';
import { Psqi } from '../remote-graphql-types';
import { IQuestionnaireResultExplanationData } from './questionnaire';

// PSQI methods
export interface SavePsqiRequest {
  user_id: string; // '2676040612419791',
  psqi_q1: string; // '23:00:00',
  psqi_q2: number; // 7,
  psqi_q3: string; // '06:00:00',
  psqi_q4: number; // 7,
  psqi_q5a: string; //  'menos de 1/semana',
  psqi_q5b: string; //  'menos de 1/semana',
  psqi_q5c: string; //  'menos de 1/semana',
  psqi_q5d: string; //  'nenhuma no mês',
  psqi_q5e: string; //  'nenhuma no mês',
  psqi_q5f: string; //  'menos de 1/semana',
  psqi_q5g: string; //  'nenhuma no mês',
  psqi_q5h: string; //  'nenhuma no mês',
  psqi_q5i: string; //  'nenhuma no mês',
  psqi_q5j: string; //  'Não',
  psqi_q5j1: string; // : 'null',
  psqi_q5j2: string; // : 'null',
  psqi_q6: string; // 'boa 😊',
  psqi_q7: string; // 'nenhuma no mês',
  psqi_q8: string; // 'nenhuma no mês',
  psqi_q9: string; // 'nenhuma dificuldade',
  age: number; // 29;
}
type PsqiPoints = 0 | 1 | 2 | 3;
const mapToLowerCase = (str: string) => str.toLowerCase();
const convertTextToPsqiPoints = (text: string): PsqiPoints => {
  const zero = [
    'muito boa 😎',
    'Nenhuma no último mês',
    'Nenhuma dificuldade',
    'Muito boa 😎',
    'Nenhuma no mês',
    'Nenhuma dificuldade',
  ].map(mapToLowerCase);
  if (zero.indexOf(text.toLowerCase()) !== -1) {
    return 0;
  }
  const one = ['boa 😊', 'Menos de 1 vez/ semana', 'Um problema muito leve', 'Boa 😊', 'Menos de 1/semana', 'Problema muito leve'].map(
    mapToLowerCase
  );
  if (one.indexOf(text.toLowerCase()) !== -1) {
    return 1;
  }
  const two = ['ruim 🙁', '1 ou 2 vezes/ semana', 'Um problema razoável', 'Ruim 🙁', '1 ou 2/semana', 'Problema razoável'].map(
    mapToLowerCase
  );
  if (two.indexOf(text.toLowerCase()) !== -1) {
    return 2;
  }
  const three = [
    'muito ruim 😞',
    '3 ou mais vezes/ semana',
    'Um problema muito grande',
    'Muito ruim 😞',
    '3 ou mais/semana',
    'Problema enorme',
  ].map(mapToLowerCase);
  if (three.indexOf(text.toLowerCase()) !== -1) {
    return 3;
  }
  throw new Error(`convertToPsqiPoints -> not mapped value for: ${text}`);
};
const convertYesNoToPsqiPoints = (text: string) => {
  const yes = ['Sim', 'Sim 👍'].map(mapToLowerCase);
  if (yes.indexOf(text.toLowerCase()) !== -1) {
    return true;
  }
  const no = ['Não', 'Não 👎'].map(mapToLowerCase);
  if (no.indexOf(text.toLowerCase()) !== -1) {
    return false;
  }
  throw new Error(`convertYesNoToPsqiPoints -> not mapped value for: ${text}`);
};
const convertEficiencytoPsqiPoints = (eficiency: number): PsqiPoints => {
  if (eficiency > 85) {
    return 0;
  }
  if (eficiency >= 75) {
    return 1;
  }
  if (eficiency >= 65) {
    return 2;
  }
  if (eficiency < 65) {
    return 3;
  }
  throw new Error(`convertEficiencytoPsqiPoints -> not mapped value for: ${eficiency}`);
};
const convertQ2toPsqiPoints = (q2: number): PsqiPoints => {
  if (q2 <= 15) {
    return 0;
  }
  if (q2 <= 30) {
    return 1;
  }
  if (q2 <= 60) {
    return 2;
  }
  if (q2 > 60) {
    return 3;
  }
  throw new Error(`convertQ2toPsqiPoints -> not mapped value for: ${q2}`);
};
const convertQ4toPsqiPoints = (q4: number): PsqiPoints => {
  if (q4 > 7) {
    return 0;
  }
  if (q4 >= 6) {
    return 1;
  }
  if (q4 >= 5) {
    return 2;
  }
  if (q4 < 5) {
    return 3;
  }
  throw new Error(`convertQ4toPsqiPoints -> not mapped value for: ${q4}`);
};

export const mapPsqiResponses = (
  input: SavePsqiRequest
): {
  q5a?: PsqiPoints;
  q5b?: PsqiPoints;
  q5c?: PsqiPoints;
  q5d?: PsqiPoints;
  q5e?: PsqiPoints;
  q5f?: PsqiPoints;
  q5g?: PsqiPoints;
  q5h?: PsqiPoints;
  q5i?: PsqiPoints;
  q5j?: PsqiPoints;
  q5jComment?: string;
  q6?: PsqiPoints;
  q7?: PsqiPoints;
  q8?: PsqiPoints;
  q9?: PsqiPoints;
} => {
  if (!input) {
    return {};
  }
  const q5a = convertTextToPsqiPoints(input.psqi_q5a);
  const q5b = convertTextToPsqiPoints(input.psqi_q5b);
  const q5c = convertTextToPsqiPoints(input.psqi_q5c);
  const q5d = convertTextToPsqiPoints(input.psqi_q5d);
  const q5e = convertTextToPsqiPoints(input.psqi_q5e);
  const q5f = convertTextToPsqiPoints(input.psqi_q5f);
  const q5g = convertTextToPsqiPoints(input.psqi_q5g);
  const q5h = convertTextToPsqiPoints(input.psqi_q5h);
  const q5i = convertTextToPsqiPoints(input.psqi_q5i);
  const q5j = convertYesNoToPsqiPoints(input.psqi_q5j) ? convertTextToPsqiPoints(input.psqi_q5j2) : 0;
  const q5jComment = input.psqi_q5j1;
  const q6 = convertTextToPsqiPoints(input.psqi_q6);
  const q7 = convertTextToPsqiPoints(input.psqi_q7);
  const q8 = convertTextToPsqiPoints(input.psqi_q8);
  const q9 = convertTextToPsqiPoints(input.psqi_q9);
  return { q5a, q5b, q5c, q5d, q5e, q5f, q5g, q5h, q5i, q5j, q5jComment, q6, q7, q8, q9 };
};
export const mapPsqiComponents = (
  psqi: Partial<Psqi>
): {
  timeSleepInMinutes: number;
  timeInBedInMinutes: number;
  eficiency: number;
  c1: PsqiPoints;
  c2: PsqiPoints;
  c3: PsqiPoints;
  c4: PsqiPoints;
  c5: PsqiPoints;
  c6: PsqiPoints;
  c7: PsqiPoints;
  total: number;
  percentage: number;
} => {
  try {
    // https://www.notion.so/Sess-o-4-Auto-avalia-o-da-ins-nia-12bf7a56374f47d4ad2ea3d835a6e252#79ded542daa64f6d993a7bdeb8e32dc8
    const c1 = psqi.q6 as PsqiPoints;
    const q2 = convertQ2toPsqiPoints(psqi.q2);
    const c2 = Math.ceil((q2 + psqi.q5a) / 2) as PsqiPoints;
    const q4 = convertQ4toPsqiPoints(psqi.q4);
    const c3 = q4;
    const [goSleep, wakeUp] = getTimeInMinutesChronologically([psqi.q1, psqi.q3]);
    const timeSleepInMinutes = psqi.q4 * 60;
    const timeInBedInMinutes = wakeUp - goSleep;
    const eficiency = (timeSleepInMinutes / timeInBedInMinutes) * 100;
    const c4 = convertEficiencytoPsqiPoints(eficiency);
    const c5 = Math.ceil(
      (psqi.q5b + psqi.q5c + psqi.q5d + psqi.q5e + psqi.q5f + psqi.q5g + psqi.q5h + psqi.q5i + psqi.q5j) / 9
    ) as PsqiPoints;
    const c6 = psqi.q7 as PsqiPoints;
    const c7 = Math.ceil((psqi.q8 + psqi.q9) / 2) as PsqiPoints;
    const total = c1 + c2 + c3 + c4 + c5 + c6 + c7;
    const maxPoints = 3 * 7;
    const percentage = (1 - total / maxPoints) * 100;
    return { timeSleepInMinutes, timeInBedInMinutes, eficiency, c1, c2, c3, c4, c5, c6, c7, total, percentage };
  } catch (error) {
    console.error('mapPsqiComponents -> error', error, JSON.stringify(psqi));
    throw error;
  }
};

export const computePsqiPoints = (psqi: Omit<Psqi, 'user' | 'updated_at' | 'age' | 'q5j_comment'>): number => mapPsqiComponents(psqi).total;

////////////////////////////////////////////////////////////////////////////////////////////////////

export const psqiThreshold = [5, 11, 21];

/*
 * Make sure the redirectBlock and Initial Block are the same block
 */
const redirectBlock: ChatfuelBlockIds = '653a69147d05ce1944a4a640';
export const psqiInitialBlockName: ChatfuelBlockNames = 'psqi_youpage_entrance';

export const psqiQuestionnaire = {
  name: 'Qualidade do sono',
  redirect: redirectBlock,
};

export const psqiResultExplanationData: IQuestionnaireResultExplanationData = {
  title: 'Questionário PSQI',
  description: 'O questionário tem como objetivo avaliar a qualidade do sono durante o último mês.',
  results: [
    {
      title: '0 - 5 pontos',
      description: 'sono normal',
    },
    {
      title: '6 - 11 pontos',
      description: 'sono com algumas perturbações',
    },
    {
      title: '12 - 21 pontos',
      description: 'qualidade do sono ruim',
    },
  ],
};
