import * as React from 'react';
import { isNullOrUndefined } from '@global/utils/object/null-or-undefined';
import { ActivityIndicator } from '../legacy/atm.activity-indicator';
import {
  activeClassName,
  ButtonContentStyled,
  ButtonSpinnerStyled,
  ExternalLinkButtonStyled,
  ButtonStyled,
} from './button.component.style';

export type ButtonKind = 'primary' | 'secondary' | 'link' | 'link-thin' | 'alert' | 'primary-light' | 'call-to-action';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** Enables/disables a component. */
  disabled?: boolean;
  /** Enables/disables full-width button. */
  expanded?: boolean;
  outlined?: boolean;
  /** Enables/disables loading indicator. */
  loading?: boolean | string;
  /** Event. Fired when button is clicked. */
  onClick?: () => any;
  /** Adds a <a target= /> attribute. Applies only when "to" props is declared. */
  target?: '_self' | '_parent' | '_blank' | '_top';
  /** When declared, button component is rendered as <a href.. />. Otherwise, it renders a <button /> element. */
  to?: string;
  /** https://www.gatsbyjs.org/docs/gatsby-link/#pass-state-as-props-to-the-linked-page */
  state?: any;
  /** Changes button's visual style. */
  kind?: ButtonKind;
  /**
   * why is it necessary? https://github.com/indigotech/eok-weekly/issues/114
   * https://www.w3schools.com/tags/att_button_type.asp
   */
  type?: 'button' | 'submit' | 'reset'; // default-value: "button"
  /** decide between Gatsby link and a default <a> tag */
  external?: boolean;
  // https://www.gatsbyjs.org/docs/gatsby-link/#add-custom-styles-for-the-currently-active-link
  showActiveClass?: boolean;
  small?: boolean;
  light?: boolean;
  id?: string;
  className?: string;
}

interface WithLinkProps extends ButtonProps {
  children?: any;
}

export const ButtonProxy = (Component, props: WithLinkProps) => {
  const { loading, disabled, type, expanded, small, light, external, showActiveClass, to, target, ...others } = props;
  const href = external && props.to;

  const hastext = isNullOrUndefined(props.to)
    ? undefined
    : typeof props.children === 'string' || (Array.isArray(props.children) && props.children.some((item) => typeof item === 'string'));

  return (
    <Component
      // https://github.com/styled-components/styled-components/issues/1198#issuecomment-336621217
      expanded={expanded ? 1 : undefined}
      small={small ? 1 : undefined}
      light={light ? 1 : undefined}
      hastext={hastext ? 1 : undefined}
      disabled={loading || disabled}
      href={href}
      to={to}
      type={!props.to ? type : undefined}
      // https://www.gatsbyjs.org/docs/gatsby-link/#add-custom-styles-for-the-currently-active-link
      activeClassName={showActiveClass ? activeClassName : undefined}
      target={target || (props.external ? '_blank' : undefined)}
      // https://developers.google.com/web/tools/lighthouse/audits/noopener
      rel={target === '_blank' ? 'noopener' : undefined}
      {...others}
      id={props.id}
      className={props.className}
    >
      <ButtonContentStyled
        className={props.className}
        id={props.id ? `${props.id}-content` : undefined}
        loading={loading ? 'true' : undefined}
        hastext={hastext}
        expanded={expanded}
      >
        {props.children}
      </ButtonContentStyled>
      <ButtonSpinnerStyled loading={loading ? 'true' : undefined}>
        <ActivityIndicator />
      </ButtonSpinnerStyled>
    </Component>
  );
};

export class Button extends React.Component<ButtonProps> {
  static defaultProps = {
    disabled: false,
    expanded: false,
    loading: false,
    showActiveClass: true,
    kind: 'primary',
    type: 'button',
  };

  render() {
    return ButtonProxy(this.props.to ? ExternalLinkButtonStyled : ButtonStyled, this.props);
  }
}
