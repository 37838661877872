import { getStorage, StorageType } from '@web/data/storage/storage.provider';

const CLOSED_SHARE = 'HAS_CLOSED_SHARE';

class ShareLogDataSource {
  private localStorage = getStorage(StorageType.Local);

  get hasClosedShare() {
    return this.localStorage.get(CLOSED_SHARE) || false;
  }
  set hasClosedShare(value: boolean) {
    this.localStorage.put(CLOSED_SHARE, value);
  }
}

export default new ShareLogDataSource();
