import { Button } from '@web/atomic/atm.button/button.component';
import { Body, HDisplay } from '@web/atomic/legacy/atm.typography';
import { Separator } from '@web/atomic/legacy/obj.box';
import { Col, Row } from '@web/atomic/legacy/obj.grid';
import * as React from 'react';
import { PlaceholderColStyled, PlaceholderStyled } from './placeholder.component.style';

export interface PlaceholderProps {
  icon: JSX.Element;
  title: string;
  description?: string;
  children?: any;
}

export const Placeholder: React.FunctionComponent<PlaceholderProps> = (props: PlaceholderProps) => {
  const handleReload = React.useCallback(() => {
    location.reload();
  }, []);
  return (
    <PlaceholderStyled>
      <Row reverse mt center="xs">
        <PlaceholderColStyled xs={12} mdOffset={1} md={6}>
          {props.icon}
          <Separator />
        </PlaceholderColStyled>
        <Col xs={12} md={5}>
          <HDisplay>{props.title}</HDisplay>
          {props.description && (
            <>
              <Separator />
              <Body>{props.description}</Body>
            </>
          )}
          <Separator />
          {props.children ? props.children : <Button onClick={handleReload}>Recarregar</Button>}
        </Col>
      </Row>
    </PlaceholderStyled>
  );
};
