import { Spacing } from '@web/atomic/legacy/obj.constants';
import * as React from 'react';
import styled from 'styled-components';

interface BoxProps {
  // src: https://www.w3schools.com/cssref/css3_pr_align-items.asp
  hAlign?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'initial' | 'inherit';
  // src: https://www.w3schools.com/cssref/css3_pr_justify-content.asp
  vAlign?: 'stretch' | 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'initial' | 'inherit';
}

export interface HboxProps extends BoxProps {
  wrap?: boolean;
}

const HboxStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  align-items: ${(props: HboxProps) => (props.vAlign ? props.vAlign : 'stretch')};
  justify-content: ${(props: HboxProps) => (props.hAlign ? props.hAlign : 'stretch')};
`;

export const Hbox: any = HboxStyled;
Hbox.displayName = 'Hbox';

Hbox.Item = styled(({ wrap, vAlign, hAlign, ...props }) => <div {...props} />)`
  flex-direction: column;
  display: flex;
  ${(props: HboxProps) => (!props.wrap ? 'flex: 1' : '')};
  justify-content: ${(props: HboxProps) => (props.vAlign ? props.vAlign : 'flex-start')};
  align-items: ${(props: HboxProps) => (props.hAlign ? props.hAlign : 'stretch')};
`;
Hbox.Item.displayName = 'Hbox.Item';

Hbox.Separator = styled.div`
  width: ${Spacing.Small};
`;
Hbox.Separator.displayName = 'Hbox.Separator';

/** https://css-tricks.com/the-peculiar-magic-of-flexbox-and-auto-margins/ */
export const AutoMargin = styled.div`
  margin: auto;
`;

export const Separator = styled.div`
  padding-bottom: ${Spacing.Medium};
`;

export const LargeSeparator = styled.div`
  margin-bottom: ${Spacing.Large};
  @media all and (min-width: 64em) {
    margin-bottom: ${Spacing.XXLarge};
  }
`;
