export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  _text: string[];
  bigint: number;
  date: `${number}-${number}-${number}`;
  jsonb: unknown;
  numeric: number;
  smallint: number;
  time: `${number}:${number}:${number}`;
  timestamptz: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  timetz: `${number}:${number}:${number}+${number}`;
  uuid: string;
};

export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type Float_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Float']>;
  _gt?: InputMaybe<Scalars['Float']>;
  _gte?: InputMaybe<Scalars['Float']>;
  _in?: InputMaybe<Array<Scalars['Float']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Float']>;
  _lte?: InputMaybe<Scalars['Float']>;
  _neq?: InputMaybe<Scalars['Float']>;
  _nin?: InputMaybe<Array<Scalars['Float']>>;
};

export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  _nlike?: InputMaybe<Scalars['String']>;
  _nsimilar?: InputMaybe<Scalars['String']>;
  _similar?: InputMaybe<Scalars['String']>;
};

export type _Text_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['_text']>;
  _gt?: InputMaybe<Scalars['_text']>;
  _gte?: InputMaybe<Scalars['_text']>;
  _in?: InputMaybe<Array<Scalars['_text']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['_text']>;
  _lte?: InputMaybe<Scalars['_text']>;
  _neq?: InputMaybe<Scalars['_text']>;
  _nin?: InputMaybe<Array<Scalars['_text']>>;
};

export type Apnea_Questionnaire = {
  __typename?: 'apnea_questionnaire';
  age: Scalars['numeric'];
  berlin_cat1: Scalars['smallint'];
  berlin_cat2: Scalars['smallint'];
  berlin_cat3: Scalars['smallint'];
  body_mass_index: Scalars['numeric'];
  created_at: Scalars['timestamptz'];
  first_analysis: Scalars['Boolean'];
  front: Scalars['jsonb'];
  id: Scalars['Int'];
  q1: Scalars['smallint'];
  q2: Scalars['smallint'];
  q3: Scalars['smallint'];
  q4: Scalars['smallint'];
  q5: Scalars['smallint'];
  q6: Scalars['smallint'];
  q7: Scalars['smallint'];
  q8: Scalars['smallint'];
  q9: Scalars['smallint'];
  q10: Scalars['smallint'];
  second_analysis: Scalars['Boolean'];
  sex: Scalars['String'];
  side: Scalars['jsonb'];
  updated_at: Scalars['timestamptz'];
  user: User;
  user_id: Scalars['String'];
};

export type Apnea_QuestionnaireFrontArgs = {
  path?: InputMaybe<Scalars['String']>;
};

export type Apnea_QuestionnaireSideArgs = {
  path?: InputMaybe<Scalars['String']>;
};

export type Apnea_Questionnaire_Aggregate = {
  __typename?: 'apnea_questionnaire_aggregate';
  aggregate?: Maybe<Apnea_Questionnaire_Aggregate_Fields>;
  nodes: Array<Apnea_Questionnaire>;
};

export type Apnea_Questionnaire_Aggregate_Fields = {
  __typename?: 'apnea_questionnaire_aggregate_fields';
  avg?: Maybe<Apnea_Questionnaire_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Apnea_Questionnaire_Max_Fields>;
  min?: Maybe<Apnea_Questionnaire_Min_Fields>;
  stddev?: Maybe<Apnea_Questionnaire_Stddev_Fields>;
  stddev_pop?: Maybe<Apnea_Questionnaire_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Apnea_Questionnaire_Stddev_Samp_Fields>;
  sum?: Maybe<Apnea_Questionnaire_Sum_Fields>;
  var_pop?: Maybe<Apnea_Questionnaire_Var_Pop_Fields>;
  var_samp?: Maybe<Apnea_Questionnaire_Var_Samp_Fields>;
  variance?: Maybe<Apnea_Questionnaire_Variance_Fields>;
};

export type Apnea_Questionnaire_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Apnea_Questionnaire_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Apnea_Questionnaire_Aggregate_Order_By = {
  avg?: InputMaybe<Apnea_Questionnaire_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Apnea_Questionnaire_Max_Order_By>;
  min?: InputMaybe<Apnea_Questionnaire_Min_Order_By>;
  stddev?: InputMaybe<Apnea_Questionnaire_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Apnea_Questionnaire_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Apnea_Questionnaire_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Apnea_Questionnaire_Sum_Order_By>;
  var_pop?: InputMaybe<Apnea_Questionnaire_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Apnea_Questionnaire_Var_Samp_Order_By>;
  variance?: InputMaybe<Apnea_Questionnaire_Variance_Order_By>;
};

export type Apnea_Questionnaire_Append_Input = {
  front?: InputMaybe<Scalars['jsonb']>;
  side?: InputMaybe<Scalars['jsonb']>;
};

export type Apnea_Questionnaire_Arr_Rel_Insert_Input = {
  data: Array<Apnea_Questionnaire_Insert_Input>;
  on_conflict?: InputMaybe<Apnea_Questionnaire_On_Conflict>;
};

export type Apnea_Questionnaire_Avg_Fields = {
  __typename?: 'apnea_questionnaire_avg_fields';
  age?: Maybe<Scalars['Float']>;
  berlin_cat1?: Maybe<Scalars['Float']>;
  berlin_cat2?: Maybe<Scalars['Float']>;
  berlin_cat3?: Maybe<Scalars['Float']>;
  body_mass_index?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
  q10?: Maybe<Scalars['Float']>;
};

export type Apnea_Questionnaire_Avg_Order_By = {
  age?: InputMaybe<Order_By>;
  berlin_cat1?: InputMaybe<Order_By>;
  berlin_cat2?: InputMaybe<Order_By>;
  berlin_cat3?: InputMaybe<Order_By>;
  body_mass_index?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
};

export type Apnea_Questionnaire_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Apnea_Questionnaire_Bool_Exp>>>;
  _not?: InputMaybe<Apnea_Questionnaire_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Apnea_Questionnaire_Bool_Exp>>>;
  age?: InputMaybe<Numeric_Comparison_Exp>;
  berlin_cat1?: InputMaybe<Smallint_Comparison_Exp>;
  berlin_cat2?: InputMaybe<Smallint_Comparison_Exp>;
  berlin_cat3?: InputMaybe<Smallint_Comparison_Exp>;
  body_mass_index?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  first_analysis?: InputMaybe<Boolean_Comparison_Exp>;
  front?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  q1?: InputMaybe<Smallint_Comparison_Exp>;
  q2?: InputMaybe<Smallint_Comparison_Exp>;
  q3?: InputMaybe<Smallint_Comparison_Exp>;
  q4?: InputMaybe<Smallint_Comparison_Exp>;
  q5?: InputMaybe<Smallint_Comparison_Exp>;
  q6?: InputMaybe<Smallint_Comparison_Exp>;
  q7?: InputMaybe<Smallint_Comparison_Exp>;
  q8?: InputMaybe<Smallint_Comparison_Exp>;
  q9?: InputMaybe<Smallint_Comparison_Exp>;
  q10?: InputMaybe<Smallint_Comparison_Exp>;
  second_analysis?: InputMaybe<Boolean_Comparison_Exp>;
  sex?: InputMaybe<String_Comparison_Exp>;
  side?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

export enum Apnea_Questionnaire_Constraint {
  ApneaQuestionnairePkey = 'apnea_questionnaire_pkey',
}

export type Apnea_Questionnaire_Delete_At_Path_Input = {
  front?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  side?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Apnea_Questionnaire_Delete_Elem_Input = {
  front?: InputMaybe<Scalars['Int']>;
  side?: InputMaybe<Scalars['Int']>;
};

export type Apnea_Questionnaire_Delete_Key_Input = {
  front?: InputMaybe<Scalars['String']>;
  side?: InputMaybe<Scalars['String']>;
};

export type Apnea_Questionnaire_Inc_Input = {
  age?: InputMaybe<Scalars['numeric']>;
  berlin_cat1?: InputMaybe<Scalars['smallint']>;
  berlin_cat2?: InputMaybe<Scalars['smallint']>;
  berlin_cat3?: InputMaybe<Scalars['smallint']>;
  body_mass_index?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['smallint']>;
  q2?: InputMaybe<Scalars['smallint']>;
  q3?: InputMaybe<Scalars['smallint']>;
  q4?: InputMaybe<Scalars['smallint']>;
  q5?: InputMaybe<Scalars['smallint']>;
  q6?: InputMaybe<Scalars['smallint']>;
  q7?: InputMaybe<Scalars['smallint']>;
  q8?: InputMaybe<Scalars['smallint']>;
  q9?: InputMaybe<Scalars['smallint']>;
  q10?: InputMaybe<Scalars['smallint']>;
};

export type Apnea_Questionnaire_Insert_Input = {
  age?: InputMaybe<Scalars['numeric']>;
  berlin_cat1?: InputMaybe<Scalars['smallint']>;
  berlin_cat2?: InputMaybe<Scalars['smallint']>;
  berlin_cat3?: InputMaybe<Scalars['smallint']>;
  body_mass_index?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  first_analysis?: InputMaybe<Scalars['Boolean']>;
  front?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['smallint']>;
  q2?: InputMaybe<Scalars['smallint']>;
  q3?: InputMaybe<Scalars['smallint']>;
  q4?: InputMaybe<Scalars['smallint']>;
  q5?: InputMaybe<Scalars['smallint']>;
  q6?: InputMaybe<Scalars['smallint']>;
  q7?: InputMaybe<Scalars['smallint']>;
  q8?: InputMaybe<Scalars['smallint']>;
  q9?: InputMaybe<Scalars['smallint']>;
  q10?: InputMaybe<Scalars['smallint']>;
  second_analysis?: InputMaybe<Scalars['Boolean']>;
  sex?: InputMaybe<Scalars['String']>;
  side?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Apnea_Questionnaire_Max_Fields = {
  __typename?: 'apnea_questionnaire_max_fields';
  age?: Maybe<Scalars['numeric']>;
  berlin_cat1?: Maybe<Scalars['smallint']>;
  berlin_cat2?: Maybe<Scalars['smallint']>;
  berlin_cat3?: Maybe<Scalars['smallint']>;
  body_mass_index?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['smallint']>;
  q2?: Maybe<Scalars['smallint']>;
  q3?: Maybe<Scalars['smallint']>;
  q4?: Maybe<Scalars['smallint']>;
  q5?: Maybe<Scalars['smallint']>;
  q6?: Maybe<Scalars['smallint']>;
  q7?: Maybe<Scalars['smallint']>;
  q8?: Maybe<Scalars['smallint']>;
  q9?: Maybe<Scalars['smallint']>;
  q10?: Maybe<Scalars['smallint']>;
  sex?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Apnea_Questionnaire_Max_Order_By = {
  age?: InputMaybe<Order_By>;
  berlin_cat1?: InputMaybe<Order_By>;
  berlin_cat2?: InputMaybe<Order_By>;
  berlin_cat3?: InputMaybe<Order_By>;
  body_mass_index?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
  sex?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Apnea_Questionnaire_Min_Fields = {
  __typename?: 'apnea_questionnaire_min_fields';
  age?: Maybe<Scalars['numeric']>;
  berlin_cat1?: Maybe<Scalars['smallint']>;
  berlin_cat2?: Maybe<Scalars['smallint']>;
  berlin_cat3?: Maybe<Scalars['smallint']>;
  body_mass_index?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['smallint']>;
  q2?: Maybe<Scalars['smallint']>;
  q3?: Maybe<Scalars['smallint']>;
  q4?: Maybe<Scalars['smallint']>;
  q5?: Maybe<Scalars['smallint']>;
  q6?: Maybe<Scalars['smallint']>;
  q7?: Maybe<Scalars['smallint']>;
  q8?: Maybe<Scalars['smallint']>;
  q9?: Maybe<Scalars['smallint']>;
  q10?: Maybe<Scalars['smallint']>;
  sex?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Apnea_Questionnaire_Min_Order_By = {
  age?: InputMaybe<Order_By>;
  berlin_cat1?: InputMaybe<Order_By>;
  berlin_cat2?: InputMaybe<Order_By>;
  berlin_cat3?: InputMaybe<Order_By>;
  body_mass_index?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
  sex?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Apnea_Questionnaire_Mutation_Response = {
  __typename?: 'apnea_questionnaire_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Apnea_Questionnaire>;
};

export type Apnea_Questionnaire_Obj_Rel_Insert_Input = {
  data: Apnea_Questionnaire_Insert_Input;
  on_conflict?: InputMaybe<Apnea_Questionnaire_On_Conflict>;
};

export type Apnea_Questionnaire_On_Conflict = {
  constraint: Apnea_Questionnaire_Constraint;
  update_columns: Array<Apnea_Questionnaire_Update_Column>;
  where?: InputMaybe<Apnea_Questionnaire_Bool_Exp>;
};

export type Apnea_Questionnaire_Order_By = {
  age?: InputMaybe<Order_By>;
  berlin_cat1?: InputMaybe<Order_By>;
  berlin_cat2?: InputMaybe<Order_By>;
  berlin_cat3?: InputMaybe<Order_By>;
  body_mass_index?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  first_analysis?: InputMaybe<Order_By>;
  front?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
  second_analysis?: InputMaybe<Order_By>;
  sex?: InputMaybe<Order_By>;
  side?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Apnea_Questionnaire_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export type Apnea_Questionnaire_Prepend_Input = {
  front?: InputMaybe<Scalars['jsonb']>;
  side?: InputMaybe<Scalars['jsonb']>;
};

export enum Apnea_Questionnaire_Select_Column {
  Age = 'age',
  BerlinCat1 = 'berlin_cat1',
  BerlinCat2 = 'berlin_cat2',
  BerlinCat3 = 'berlin_cat3',
  BodyMassIndex = 'body_mass_index',
  CreatedAt = 'created_at',
  FirstAnalysis = 'first_analysis',
  Front = 'front',
  Id = 'id',
  Q1 = 'q1',
  Q2 = 'q2',
  Q3 = 'q3',
  Q4 = 'q4',
  Q5 = 'q5',
  Q6 = 'q6',
  Q7 = 'q7',
  Q8 = 'q8',
  Q9 = 'q9',
  Q10 = 'q10',
  SecondAnalysis = 'second_analysis',
  Sex = 'sex',
  Side = 'side',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Apnea_Questionnaire_Set_Input = {
  age?: InputMaybe<Scalars['numeric']>;
  berlin_cat1?: InputMaybe<Scalars['smallint']>;
  berlin_cat2?: InputMaybe<Scalars['smallint']>;
  berlin_cat3?: InputMaybe<Scalars['smallint']>;
  body_mass_index?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  first_analysis?: InputMaybe<Scalars['Boolean']>;
  front?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['smallint']>;
  q2?: InputMaybe<Scalars['smallint']>;
  q3?: InputMaybe<Scalars['smallint']>;
  q4?: InputMaybe<Scalars['smallint']>;
  q5?: InputMaybe<Scalars['smallint']>;
  q6?: InputMaybe<Scalars['smallint']>;
  q7?: InputMaybe<Scalars['smallint']>;
  q8?: InputMaybe<Scalars['smallint']>;
  q9?: InputMaybe<Scalars['smallint']>;
  q10?: InputMaybe<Scalars['smallint']>;
  second_analysis?: InputMaybe<Scalars['Boolean']>;
  sex?: InputMaybe<Scalars['String']>;
  side?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Apnea_Questionnaire_Stddev_Fields = {
  __typename?: 'apnea_questionnaire_stddev_fields';
  age?: Maybe<Scalars['Float']>;
  berlin_cat1?: Maybe<Scalars['Float']>;
  berlin_cat2?: Maybe<Scalars['Float']>;
  berlin_cat3?: Maybe<Scalars['Float']>;
  body_mass_index?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
  q10?: Maybe<Scalars['Float']>;
};

export type Apnea_Questionnaire_Stddev_Order_By = {
  age?: InputMaybe<Order_By>;
  berlin_cat1?: InputMaybe<Order_By>;
  berlin_cat2?: InputMaybe<Order_By>;
  berlin_cat3?: InputMaybe<Order_By>;
  body_mass_index?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
};

export type Apnea_Questionnaire_Stddev_Pop_Fields = {
  __typename?: 'apnea_questionnaire_stddev_pop_fields';
  age?: Maybe<Scalars['Float']>;
  berlin_cat1?: Maybe<Scalars['Float']>;
  berlin_cat2?: Maybe<Scalars['Float']>;
  berlin_cat3?: Maybe<Scalars['Float']>;
  body_mass_index?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
  q10?: Maybe<Scalars['Float']>;
};

export type Apnea_Questionnaire_Stddev_Pop_Order_By = {
  age?: InputMaybe<Order_By>;
  berlin_cat1?: InputMaybe<Order_By>;
  berlin_cat2?: InputMaybe<Order_By>;
  berlin_cat3?: InputMaybe<Order_By>;
  body_mass_index?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
};

export type Apnea_Questionnaire_Stddev_Samp_Fields = {
  __typename?: 'apnea_questionnaire_stddev_samp_fields';
  age?: Maybe<Scalars['Float']>;
  berlin_cat1?: Maybe<Scalars['Float']>;
  berlin_cat2?: Maybe<Scalars['Float']>;
  berlin_cat3?: Maybe<Scalars['Float']>;
  body_mass_index?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
  q10?: Maybe<Scalars['Float']>;
};

export type Apnea_Questionnaire_Stddev_Samp_Order_By = {
  age?: InputMaybe<Order_By>;
  berlin_cat1?: InputMaybe<Order_By>;
  berlin_cat2?: InputMaybe<Order_By>;
  berlin_cat3?: InputMaybe<Order_By>;
  body_mass_index?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
};

export type Apnea_Questionnaire_Sum_Fields = {
  __typename?: 'apnea_questionnaire_sum_fields';
  age?: Maybe<Scalars['numeric']>;
  berlin_cat1?: Maybe<Scalars['smallint']>;
  berlin_cat2?: Maybe<Scalars['smallint']>;
  berlin_cat3?: Maybe<Scalars['smallint']>;
  body_mass_index?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['smallint']>;
  q2?: Maybe<Scalars['smallint']>;
  q3?: Maybe<Scalars['smallint']>;
  q4?: Maybe<Scalars['smallint']>;
  q5?: Maybe<Scalars['smallint']>;
  q6?: Maybe<Scalars['smallint']>;
  q7?: Maybe<Scalars['smallint']>;
  q8?: Maybe<Scalars['smallint']>;
  q9?: Maybe<Scalars['smallint']>;
  q10?: Maybe<Scalars['smallint']>;
};

export type Apnea_Questionnaire_Sum_Order_By = {
  age?: InputMaybe<Order_By>;
  berlin_cat1?: InputMaybe<Order_By>;
  berlin_cat2?: InputMaybe<Order_By>;
  berlin_cat3?: InputMaybe<Order_By>;
  body_mass_index?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
};

export enum Apnea_Questionnaire_Update_Column {
  Age = 'age',
  BerlinCat1 = 'berlin_cat1',
  BerlinCat2 = 'berlin_cat2',
  BerlinCat3 = 'berlin_cat3',
  BodyMassIndex = 'body_mass_index',
  CreatedAt = 'created_at',
  FirstAnalysis = 'first_analysis',
  Front = 'front',
  Id = 'id',
  Q1 = 'q1',
  Q2 = 'q2',
  Q3 = 'q3',
  Q4 = 'q4',
  Q5 = 'q5',
  Q6 = 'q6',
  Q7 = 'q7',
  Q8 = 'q8',
  Q9 = 'q9',
  Q10 = 'q10',
  SecondAnalysis = 'second_analysis',
  Sex = 'sex',
  Side = 'side',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Apnea_Questionnaire_Var_Pop_Fields = {
  __typename?: 'apnea_questionnaire_var_pop_fields';
  age?: Maybe<Scalars['Float']>;
  berlin_cat1?: Maybe<Scalars['Float']>;
  berlin_cat2?: Maybe<Scalars['Float']>;
  berlin_cat3?: Maybe<Scalars['Float']>;
  body_mass_index?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
  q10?: Maybe<Scalars['Float']>;
};

export type Apnea_Questionnaire_Var_Pop_Order_By = {
  age?: InputMaybe<Order_By>;
  berlin_cat1?: InputMaybe<Order_By>;
  berlin_cat2?: InputMaybe<Order_By>;
  berlin_cat3?: InputMaybe<Order_By>;
  body_mass_index?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
};

export type Apnea_Questionnaire_Var_Samp_Fields = {
  __typename?: 'apnea_questionnaire_var_samp_fields';
  age?: Maybe<Scalars['Float']>;
  berlin_cat1?: Maybe<Scalars['Float']>;
  berlin_cat2?: Maybe<Scalars['Float']>;
  berlin_cat3?: Maybe<Scalars['Float']>;
  body_mass_index?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
  q10?: Maybe<Scalars['Float']>;
};

export type Apnea_Questionnaire_Var_Samp_Order_By = {
  age?: InputMaybe<Order_By>;
  berlin_cat1?: InputMaybe<Order_By>;
  berlin_cat2?: InputMaybe<Order_By>;
  berlin_cat3?: InputMaybe<Order_By>;
  body_mass_index?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
};

export type Apnea_Questionnaire_Variance_Fields = {
  __typename?: 'apnea_questionnaire_variance_fields';
  age?: Maybe<Scalars['Float']>;
  berlin_cat1?: Maybe<Scalars['Float']>;
  berlin_cat2?: Maybe<Scalars['Float']>;
  berlin_cat3?: Maybe<Scalars['Float']>;
  body_mass_index?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
  q10?: Maybe<Scalars['Float']>;
};

export type Apnea_Questionnaire_Variance_Order_By = {
  age?: InputMaybe<Order_By>;
  berlin_cat1?: InputMaybe<Order_By>;
  berlin_cat2?: InputMaybe<Order_By>;
  berlin_cat3?: InputMaybe<Order_By>;
  body_mass_index?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
};

export type Apnea_Scan_Photo = {
  __typename?: 'apnea_scan_photo';
  classification: Scalars['jsonb'];
  created_at: Scalars['timestamptz'];
  dimensions: Scalars['jsonb'];
  id: Scalars['Int'];
  location: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['String'];
};

export type Apnea_Scan_PhotoClassificationArgs = {
  path?: InputMaybe<Scalars['String']>;
};

export type Apnea_Scan_PhotoDimensionsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

export type Apnea_Scan_Photo_Aggregate = {
  __typename?: 'apnea_scan_photo_aggregate';
  aggregate?: Maybe<Apnea_Scan_Photo_Aggregate_Fields>;
  nodes: Array<Apnea_Scan_Photo>;
};

export type Apnea_Scan_Photo_Aggregate_Fields = {
  __typename?: 'apnea_scan_photo_aggregate_fields';
  avg?: Maybe<Apnea_Scan_Photo_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Apnea_Scan_Photo_Max_Fields>;
  min?: Maybe<Apnea_Scan_Photo_Min_Fields>;
  stddev?: Maybe<Apnea_Scan_Photo_Stddev_Fields>;
  stddev_pop?: Maybe<Apnea_Scan_Photo_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Apnea_Scan_Photo_Stddev_Samp_Fields>;
  sum?: Maybe<Apnea_Scan_Photo_Sum_Fields>;
  var_pop?: Maybe<Apnea_Scan_Photo_Var_Pop_Fields>;
  var_samp?: Maybe<Apnea_Scan_Photo_Var_Samp_Fields>;
  variance?: Maybe<Apnea_Scan_Photo_Variance_Fields>;
};

export type Apnea_Scan_Photo_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Apnea_Scan_Photo_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Apnea_Scan_Photo_Aggregate_Order_By = {
  avg?: InputMaybe<Apnea_Scan_Photo_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Apnea_Scan_Photo_Max_Order_By>;
  min?: InputMaybe<Apnea_Scan_Photo_Min_Order_By>;
  stddev?: InputMaybe<Apnea_Scan_Photo_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Apnea_Scan_Photo_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Apnea_Scan_Photo_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Apnea_Scan_Photo_Sum_Order_By>;
  var_pop?: InputMaybe<Apnea_Scan_Photo_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Apnea_Scan_Photo_Var_Samp_Order_By>;
  variance?: InputMaybe<Apnea_Scan_Photo_Variance_Order_By>;
};

export type Apnea_Scan_Photo_Append_Input = {
  classification?: InputMaybe<Scalars['jsonb']>;
  dimensions?: InputMaybe<Scalars['jsonb']>;
};

export type Apnea_Scan_Photo_Arr_Rel_Insert_Input = {
  data: Array<Apnea_Scan_Photo_Insert_Input>;
  on_conflict?: InputMaybe<Apnea_Scan_Photo_On_Conflict>;
};

export type Apnea_Scan_Photo_Avg_Fields = {
  __typename?: 'apnea_scan_photo_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Apnea_Scan_Photo_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Apnea_Scan_Photo_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Apnea_Scan_Photo_Bool_Exp>>>;
  _not?: InputMaybe<Apnea_Scan_Photo_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Apnea_Scan_Photo_Bool_Exp>>>;
  classification?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  dimensions?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  location?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

export enum Apnea_Scan_Photo_Constraint {
  ApneaScanPhotoPkey = 'apnea_scan_photo_pkey',
}

export type Apnea_Scan_Photo_Delete_At_Path_Input = {
  classification?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dimensions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Apnea_Scan_Photo_Delete_Elem_Input = {
  classification?: InputMaybe<Scalars['Int']>;
  dimensions?: InputMaybe<Scalars['Int']>;
};

export type Apnea_Scan_Photo_Delete_Key_Input = {
  classification?: InputMaybe<Scalars['String']>;
  dimensions?: InputMaybe<Scalars['String']>;
};

export type Apnea_Scan_Photo_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

export type Apnea_Scan_Photo_Insert_Input = {
  classification?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dimensions?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Apnea_Scan_Photo_Max_Fields = {
  __typename?: 'apnea_scan_photo_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Apnea_Scan_Photo_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Apnea_Scan_Photo_Min_Fields = {
  __typename?: 'apnea_scan_photo_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Apnea_Scan_Photo_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Apnea_Scan_Photo_Mutation_Response = {
  __typename?: 'apnea_scan_photo_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Apnea_Scan_Photo>;
};

export type Apnea_Scan_Photo_Obj_Rel_Insert_Input = {
  data: Apnea_Scan_Photo_Insert_Input;
  on_conflict?: InputMaybe<Apnea_Scan_Photo_On_Conflict>;
};

export type Apnea_Scan_Photo_On_Conflict = {
  constraint: Apnea_Scan_Photo_Constraint;
  update_columns: Array<Apnea_Scan_Photo_Update_Column>;
  where?: InputMaybe<Apnea_Scan_Photo_Bool_Exp>;
};

export type Apnea_Scan_Photo_Order_By = {
  classification?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dimensions?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Apnea_Scan_Photo_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export type Apnea_Scan_Photo_Prepend_Input = {
  classification?: InputMaybe<Scalars['jsonb']>;
  dimensions?: InputMaybe<Scalars['jsonb']>;
};

export enum Apnea_Scan_Photo_Select_Column {
  Classification = 'classification',
  CreatedAt = 'created_at',
  Dimensions = 'dimensions',
  Id = 'id',
  Location = 'location',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Apnea_Scan_Photo_Set_Input = {
  classification?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dimensions?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Apnea_Scan_Photo_Stddev_Fields = {
  __typename?: 'apnea_scan_photo_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Apnea_Scan_Photo_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Apnea_Scan_Photo_Stddev_Pop_Fields = {
  __typename?: 'apnea_scan_photo_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Apnea_Scan_Photo_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Apnea_Scan_Photo_Stddev_Samp_Fields = {
  __typename?: 'apnea_scan_photo_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Apnea_Scan_Photo_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Apnea_Scan_Photo_Sum_Fields = {
  __typename?: 'apnea_scan_photo_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

export type Apnea_Scan_Photo_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

export enum Apnea_Scan_Photo_Update_Column {
  Classification = 'classification',
  CreatedAt = 'created_at',
  Dimensions = 'dimensions',
  Id = 'id',
  Location = 'location',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Apnea_Scan_Photo_Var_Pop_Fields = {
  __typename?: 'apnea_scan_photo_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Apnea_Scan_Photo_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Apnea_Scan_Photo_Var_Samp_Fields = {
  __typename?: 'apnea_scan_photo_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Apnea_Scan_Photo_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Apnea_Scan_Photo_Variance_Fields = {
  __typename?: 'apnea_scan_photo_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Apnea_Scan_Photo_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type App_Customization = {
  __typename?: 'app_customization';
  company: Company;
  created_at: Scalars['timestamptz'];
  data: Scalars['jsonb'];
  id: Scalars['Int'];
  owner_company: Scalars['Int'];
  type: App_Customization_Type_Enum_Enum;
  updated_at: Scalars['timestamptz'];
};

export type App_CustomizationDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

export type App_Customization_Aggregate = {
  __typename?: 'app_customization_aggregate';
  aggregate?: Maybe<App_Customization_Aggregate_Fields>;
  nodes: Array<App_Customization>;
};

export type App_Customization_Aggregate_Fields = {
  __typename?: 'app_customization_aggregate_fields';
  avg?: Maybe<App_Customization_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<App_Customization_Max_Fields>;
  min?: Maybe<App_Customization_Min_Fields>;
  stddev?: Maybe<App_Customization_Stddev_Fields>;
  stddev_pop?: Maybe<App_Customization_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<App_Customization_Stddev_Samp_Fields>;
  sum?: Maybe<App_Customization_Sum_Fields>;
  var_pop?: Maybe<App_Customization_Var_Pop_Fields>;
  var_samp?: Maybe<App_Customization_Var_Samp_Fields>;
  variance?: Maybe<App_Customization_Variance_Fields>;
};

export type App_Customization_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<App_Customization_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type App_Customization_Aggregate_Order_By = {
  avg?: InputMaybe<App_Customization_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<App_Customization_Max_Order_By>;
  min?: InputMaybe<App_Customization_Min_Order_By>;
  stddev?: InputMaybe<App_Customization_Stddev_Order_By>;
  stddev_pop?: InputMaybe<App_Customization_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<App_Customization_Stddev_Samp_Order_By>;
  sum?: InputMaybe<App_Customization_Sum_Order_By>;
  var_pop?: InputMaybe<App_Customization_Var_Pop_Order_By>;
  var_samp?: InputMaybe<App_Customization_Var_Samp_Order_By>;
  variance?: InputMaybe<App_Customization_Variance_Order_By>;
};

export type App_Customization_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

export type App_Customization_Arr_Rel_Insert_Input = {
  data: Array<App_Customization_Insert_Input>;
  on_conflict?: InputMaybe<App_Customization_On_Conflict>;
};

export type App_Customization_Avg_Fields = {
  __typename?: 'app_customization_avg_fields';
  id?: Maybe<Scalars['Float']>;
  owner_company?: Maybe<Scalars['Float']>;
};

export type App_Customization_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  owner_company?: InputMaybe<Order_By>;
};

export type App_Customization_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<App_Customization_Bool_Exp>>>;
  _not?: InputMaybe<App_Customization_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<App_Customization_Bool_Exp>>>;
  company?: InputMaybe<Company_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  owner_company?: InputMaybe<Int_Comparison_Exp>;
  type?: InputMaybe<App_Customization_Type_Enum_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

export enum App_Customization_Constraint {
  AppCustomizationPkey = 'app_customization_pkey',
}

export type App_Customization_Delete_At_Path_Input = {
  data?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type App_Customization_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']>;
};

export type App_Customization_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']>;
};

export type App_Customization_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  owner_company?: InputMaybe<Scalars['Int']>;
};

export type App_Customization_Insert_Input = {
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  owner_company?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<App_Customization_Type_Enum_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type App_Customization_Max_Fields = {
  __typename?: 'app_customization_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  owner_company?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type App_Customization_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_company?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type App_Customization_Min_Fields = {
  __typename?: 'app_customization_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  owner_company?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type App_Customization_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_company?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type App_Customization_Mutation_Response = {
  __typename?: 'app_customization_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<App_Customization>;
};

export type App_Customization_Obj_Rel_Insert_Input = {
  data: App_Customization_Insert_Input;
  on_conflict?: InputMaybe<App_Customization_On_Conflict>;
};

export type App_Customization_On_Conflict = {
  constraint: App_Customization_Constraint;
  update_columns: Array<App_Customization_Update_Column>;
  where?: InputMaybe<App_Customization_Bool_Exp>;
};

export type App_Customization_Order_By = {
  company?: InputMaybe<Company_Order_By>;
  created_at?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_company?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type App_Customization_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export type App_Customization_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

export enum App_Customization_Select_Column {
  CreatedAt = 'created_at',
  Data = 'data',
  Id = 'id',
  OwnerCompany = 'owner_company',
  Type = 'type',
  UpdatedAt = 'updated_at',
}

export type App_Customization_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  owner_company?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<App_Customization_Type_Enum_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type App_Customization_Stddev_Fields = {
  __typename?: 'app_customization_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  owner_company?: Maybe<Scalars['Float']>;
};

export type App_Customization_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  owner_company?: InputMaybe<Order_By>;
};

export type App_Customization_Stddev_Pop_Fields = {
  __typename?: 'app_customization_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  owner_company?: Maybe<Scalars['Float']>;
};

export type App_Customization_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  owner_company?: InputMaybe<Order_By>;
};

export type App_Customization_Stddev_Samp_Fields = {
  __typename?: 'app_customization_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  owner_company?: Maybe<Scalars['Float']>;
};

export type App_Customization_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  owner_company?: InputMaybe<Order_By>;
};

export type App_Customization_Sum_Fields = {
  __typename?: 'app_customization_sum_fields';
  id?: Maybe<Scalars['Int']>;
  owner_company?: Maybe<Scalars['Int']>;
};

export type App_Customization_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  owner_company?: InputMaybe<Order_By>;
};

export type App_Customization_Type_Enum = {
  __typename?: 'app_customization_type_enum';
  comment: Scalars['String'];
  text: Scalars['String'];
};

export type App_Customization_Type_Enum_Aggregate = {
  __typename?: 'app_customization_type_enum_aggregate';
  aggregate?: Maybe<App_Customization_Type_Enum_Aggregate_Fields>;
  nodes: Array<App_Customization_Type_Enum>;
};

export type App_Customization_Type_Enum_Aggregate_Fields = {
  __typename?: 'app_customization_type_enum_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<App_Customization_Type_Enum_Max_Fields>;
  min?: Maybe<App_Customization_Type_Enum_Min_Fields>;
};

export type App_Customization_Type_Enum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<App_Customization_Type_Enum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type App_Customization_Type_Enum_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<App_Customization_Type_Enum_Max_Order_By>;
  min?: InputMaybe<App_Customization_Type_Enum_Min_Order_By>;
};

export type App_Customization_Type_Enum_Arr_Rel_Insert_Input = {
  data: Array<App_Customization_Type_Enum_Insert_Input>;
  on_conflict?: InputMaybe<App_Customization_Type_Enum_On_Conflict>;
};

export type App_Customization_Type_Enum_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<App_Customization_Type_Enum_Bool_Exp>>>;
  _not?: InputMaybe<App_Customization_Type_Enum_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<App_Customization_Type_Enum_Bool_Exp>>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
};

export enum App_Customization_Type_Enum_Constraint {
  AppCustomizationTypeEnumPkey = 'app_customization_type_enum_pkey',
}

export enum App_Customization_Type_Enum_Enum {
  Banner = 'BANNER',
  HomeBannerTop = 'HOME_BANNER_TOP',
  Logo = 'LOGO',
  TelehealthAd = 'TELEHEALTH_AD',
  YouMightLike = 'YOU_MIGHT_LIKE',
}

export type App_Customization_Type_Enum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<App_Customization_Type_Enum_Enum>;
  _in?: InputMaybe<Array<App_Customization_Type_Enum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<App_Customization_Type_Enum_Enum>;
  _nin?: InputMaybe<Array<App_Customization_Type_Enum_Enum>>;
};

export type App_Customization_Type_Enum_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type App_Customization_Type_Enum_Max_Fields = {
  __typename?: 'app_customization_type_enum_max_fields';
  comment?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type App_Customization_Type_Enum_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type App_Customization_Type_Enum_Min_Fields = {
  __typename?: 'app_customization_type_enum_min_fields';
  comment?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type App_Customization_Type_Enum_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type App_Customization_Type_Enum_Mutation_Response = {
  __typename?: 'app_customization_type_enum_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<App_Customization_Type_Enum>;
};

export type App_Customization_Type_Enum_Obj_Rel_Insert_Input = {
  data: App_Customization_Type_Enum_Insert_Input;
  on_conflict?: InputMaybe<App_Customization_Type_Enum_On_Conflict>;
};

export type App_Customization_Type_Enum_On_Conflict = {
  constraint: App_Customization_Type_Enum_Constraint;
  update_columns: Array<App_Customization_Type_Enum_Update_Column>;
  where?: InputMaybe<App_Customization_Type_Enum_Bool_Exp>;
};

export type App_Customization_Type_Enum_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type App_Customization_Type_Enum_Pk_Columns_Input = {
  text: Scalars['String'];
};

export enum App_Customization_Type_Enum_Select_Column {
  Comment = 'comment',
  Text = 'text',
}

export type App_Customization_Type_Enum_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export enum App_Customization_Type_Enum_Update_Column {
  Comment = 'comment',
  Text = 'text',
}

export enum App_Customization_Update_Column {
  CreatedAt = 'created_at',
  Data = 'data',
  Id = 'id',
  OwnerCompany = 'owner_company',
  Type = 'type',
  UpdatedAt = 'updated_at',
}

export type App_Customization_Var_Pop_Fields = {
  __typename?: 'app_customization_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  owner_company?: Maybe<Scalars['Float']>;
};

export type App_Customization_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  owner_company?: InputMaybe<Order_By>;
};

export type App_Customization_Var_Samp_Fields = {
  __typename?: 'app_customization_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  owner_company?: Maybe<Scalars['Float']>;
};

export type App_Customization_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  owner_company?: InputMaybe<Order_By>;
};

export type App_Customization_Variance_Fields = {
  __typename?: 'app_customization_variance_fields';
  id?: Maybe<Scalars['Float']>;
  owner_company?: Maybe<Scalars['Float']>;
};

export type App_Customization_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  owner_company?: InputMaybe<Order_By>;
};

export type Appointment = {
  __typename?: 'appointment';
  comment?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  end: Scalars['timestamptz'];
  external_id?: Maybe<Scalars['String']>;
  health_carer: Health_Carer;
  health_carer_id: Scalars['String'];
  health_carer_link?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  pre_user_id?: Maybe<Scalars['String']>;
  start: Scalars['timestamptz'];
  status: Appointment_Status_Enum_Enum;
  updated_at: Scalars['timestamptz'];
  user_id?: Maybe<Scalars['String']>;
  user_link?: Maybe<Scalars['String']>;
};

export type Appointment_Aggregate = {
  __typename?: 'appointment_aggregate';
  aggregate?: Maybe<Appointment_Aggregate_Fields>;
  nodes: Array<Appointment>;
};

export type Appointment_Aggregate_Fields = {
  __typename?: 'appointment_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Appointment_Max_Fields>;
  min?: Maybe<Appointment_Min_Fields>;
};

export type Appointment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Appointment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Appointment_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Appointment_Max_Order_By>;
  min?: InputMaybe<Appointment_Min_Order_By>;
};

export type Appointment_Arr_Rel_Insert_Input = {
  data: Array<Appointment_Insert_Input>;
  on_conflict?: InputMaybe<Appointment_On_Conflict>;
};

export type Appointment_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Appointment_Bool_Exp>>>;
  _not?: InputMaybe<Appointment_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Appointment_Bool_Exp>>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  end?: InputMaybe<Timestamptz_Comparison_Exp>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  health_carer?: InputMaybe<Health_Carer_Bool_Exp>;
  health_carer_id?: InputMaybe<String_Comparison_Exp>;
  health_carer_link?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  pre_user_id?: InputMaybe<String_Comparison_Exp>;
  start?: InputMaybe<Timestamptz_Comparison_Exp>;
  status?: InputMaybe<Appointment_Status_Enum_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  user_link?: InputMaybe<String_Comparison_Exp>;
};

export enum Appointment_Constraint {
  AppointmentExternalIdKey = 'appointment_external_id_key',
  AppointmentPkey = 'appointment_pkey',
}

export type Appointment_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  end?: InputMaybe<Scalars['timestamptz']>;
  external_id?: InputMaybe<Scalars['String']>;
  health_carer?: InputMaybe<Health_Carer_Obj_Rel_Insert_Input>;
  health_carer_id?: InputMaybe<Scalars['String']>;
  health_carer_link?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  pre_user_id?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Appointment_Status_Enum_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
  user_link?: InputMaybe<Scalars['String']>;
};

export type Appointment_Max_Fields = {
  __typename?: 'appointment_max_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  end?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  health_carer_id?: Maybe<Scalars['String']>;
  health_carer_link?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  pre_user_id?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
  user_link?: Maybe<Scalars['String']>;
};

export type Appointment_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  end?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  health_carer_id?: InputMaybe<Order_By>;
  health_carer_link?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pre_user_id?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_link?: InputMaybe<Order_By>;
};

export type Appointment_Min_Fields = {
  __typename?: 'appointment_min_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  end?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  health_carer_id?: Maybe<Scalars['String']>;
  health_carer_link?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  pre_user_id?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
  user_link?: Maybe<Scalars['String']>;
};

export type Appointment_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  end?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  health_carer_id?: InputMaybe<Order_By>;
  health_carer_link?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pre_user_id?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_link?: InputMaybe<Order_By>;
};

export type Appointment_Mutation_Response = {
  __typename?: 'appointment_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Appointment>;
};

export type Appointment_Obj_Rel_Insert_Input = {
  data: Appointment_Insert_Input;
  on_conflict?: InputMaybe<Appointment_On_Conflict>;
};

export type Appointment_On_Conflict = {
  constraint: Appointment_Constraint;
  update_columns: Array<Appointment_Update_Column>;
  where?: InputMaybe<Appointment_Bool_Exp>;
};

export type Appointment_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  end?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  health_carer?: InputMaybe<Health_Carer_Order_By>;
  health_carer_id?: InputMaybe<Order_By>;
  health_carer_link?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pre_user_id?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_link?: InputMaybe<Order_By>;
};

export type Appointment_Pk_Columns_Input = {
  id: Scalars['String'];
};

export enum Appointment_Select_Column {
  Comment = 'comment',
  CreatedAt = 'created_at',
  End = 'end',
  ExternalId = 'external_id',
  HealthCarerId = 'health_carer_id',
  HealthCarerLink = 'health_carer_link',
  Id = 'id',
  PreUserId = 'pre_user_id',
  Start = 'start',
  Status = 'status',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
  UserLink = 'user_link',
}

export type Appointment_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  end?: InputMaybe<Scalars['timestamptz']>;
  external_id?: InputMaybe<Scalars['String']>;
  health_carer_id?: InputMaybe<Scalars['String']>;
  health_carer_link?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  pre_user_id?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Appointment_Status_Enum_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
  user_link?: InputMaybe<Scalars['String']>;
};

export type Appointment_Status_Enum = {
  __typename?: 'appointment_status_enum';
  comment: Scalars['String'];
  text: Scalars['String'];
};

export type Appointment_Status_Enum_Aggregate = {
  __typename?: 'appointment_status_enum_aggregate';
  aggregate?: Maybe<Appointment_Status_Enum_Aggregate_Fields>;
  nodes: Array<Appointment_Status_Enum>;
};

export type Appointment_Status_Enum_Aggregate_Fields = {
  __typename?: 'appointment_status_enum_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Appointment_Status_Enum_Max_Fields>;
  min?: Maybe<Appointment_Status_Enum_Min_Fields>;
};

export type Appointment_Status_Enum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Appointment_Status_Enum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Appointment_Status_Enum_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Appointment_Status_Enum_Max_Order_By>;
  min?: InputMaybe<Appointment_Status_Enum_Min_Order_By>;
};

export type Appointment_Status_Enum_Arr_Rel_Insert_Input = {
  data: Array<Appointment_Status_Enum_Insert_Input>;
  on_conflict?: InputMaybe<Appointment_Status_Enum_On_Conflict>;
};

export type Appointment_Status_Enum_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Appointment_Status_Enum_Bool_Exp>>>;
  _not?: InputMaybe<Appointment_Status_Enum_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Appointment_Status_Enum_Bool_Exp>>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
};

export enum Appointment_Status_Enum_Constraint {
  AppointmentStatusEnumPkey = 'appointment_status_enum_pkey',
}

export enum Appointment_Status_Enum_Enum {
  Canceled = 'CANCELED',
  CanceledByTheSystem = 'CANCELED_BY_THE_SYSTEM',
  CarriedOut = 'CARRIED_OUT',
  ForcedCancelation = 'FORCED_CANCELATION',
  Initialized = 'INITIALIZED',
  NotPerformed = 'NOT_PERFORMED',
  PerformedManually = 'PERFORMED_MANUALLY',
  Scheduled = 'SCHEDULED',
  Summarizing = 'SUMMARIZING',
  Unknown = 'UNKNOWN',
}

export type Appointment_Status_Enum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Appointment_Status_Enum_Enum>;
  _in?: InputMaybe<Array<Appointment_Status_Enum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Appointment_Status_Enum_Enum>;
  _nin?: InputMaybe<Array<Appointment_Status_Enum_Enum>>;
};

export type Appointment_Status_Enum_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type Appointment_Status_Enum_Max_Fields = {
  __typename?: 'appointment_status_enum_max_fields';
  comment?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type Appointment_Status_Enum_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Appointment_Status_Enum_Min_Fields = {
  __typename?: 'appointment_status_enum_min_fields';
  comment?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type Appointment_Status_Enum_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Appointment_Status_Enum_Mutation_Response = {
  __typename?: 'appointment_status_enum_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Appointment_Status_Enum>;
};

export type Appointment_Status_Enum_Obj_Rel_Insert_Input = {
  data: Appointment_Status_Enum_Insert_Input;
  on_conflict?: InputMaybe<Appointment_Status_Enum_On_Conflict>;
};

export type Appointment_Status_Enum_On_Conflict = {
  constraint: Appointment_Status_Enum_Constraint;
  update_columns: Array<Appointment_Status_Enum_Update_Column>;
  where?: InputMaybe<Appointment_Status_Enum_Bool_Exp>;
};

export type Appointment_Status_Enum_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Appointment_Status_Enum_Pk_Columns_Input = {
  text: Scalars['String'];
};

export enum Appointment_Status_Enum_Select_Column {
  Comment = 'comment',
  Text = 'text',
}

export type Appointment_Status_Enum_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export enum Appointment_Status_Enum_Update_Column {
  Comment = 'comment',
  Text = 'text',
}

export enum Appointment_Update_Column {
  Comment = 'comment',
  CreatedAt = 'created_at',
  End = 'end',
  ExternalId = 'external_id',
  HealthCarerId = 'health_carer_id',
  HealthCarerLink = 'health_carer_link',
  Id = 'id',
  PreUserId = 'pre_user_id',
  Start = 'start',
  Status = 'status',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
  UserLink = 'user_link',
}

export type Assesment = {
  __typename?: 'assesment';
  age: Scalars['String'];
  cause: Scalars['String'];
  challenge: Scalars['String'];
  condition?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email: Scalars['String'];
  first_name: Scalars['String'];
  frequency: Scalars['String'];
  id: Scalars['Int'];
  last_name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  solution?: Maybe<Scalars['String']>;
  subscribe?: Maybe<Scalars['Boolean']>;
  time_to_sleep?: Maybe<Scalars['String']>;
  timestamp: Scalars['timestamptz'];
  wake_up_early?: Maybe<Scalars['String']>;
  wake_up_middle?: Maybe<Scalars['String']>;
};

export type Assesment_Aggregate = {
  __typename?: 'assesment_aggregate';
  aggregate?: Maybe<Assesment_Aggregate_Fields>;
  nodes: Array<Assesment>;
};

export type Assesment_Aggregate_Fields = {
  __typename?: 'assesment_aggregate_fields';
  avg?: Maybe<Assesment_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Assesment_Max_Fields>;
  min?: Maybe<Assesment_Min_Fields>;
  stddev?: Maybe<Assesment_Stddev_Fields>;
  stddev_pop?: Maybe<Assesment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Assesment_Stddev_Samp_Fields>;
  sum?: Maybe<Assesment_Sum_Fields>;
  var_pop?: Maybe<Assesment_Var_Pop_Fields>;
  var_samp?: Maybe<Assesment_Var_Samp_Fields>;
  variance?: Maybe<Assesment_Variance_Fields>;
};

export type Assesment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Assesment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Assesment_Aggregate_Order_By = {
  avg?: InputMaybe<Assesment_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Assesment_Max_Order_By>;
  min?: InputMaybe<Assesment_Min_Order_By>;
  stddev?: InputMaybe<Assesment_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Assesment_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Assesment_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Assesment_Sum_Order_By>;
  var_pop?: InputMaybe<Assesment_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Assesment_Var_Samp_Order_By>;
  variance?: InputMaybe<Assesment_Variance_Order_By>;
};

export type Assesment_Arr_Rel_Insert_Input = {
  data: Array<Assesment_Insert_Input>;
  on_conflict?: InputMaybe<Assesment_On_Conflict>;
};

export type Assesment_Avg_Fields = {
  __typename?: 'assesment_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Assesment_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Assesment_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Assesment_Bool_Exp>>>;
  _not?: InputMaybe<Assesment_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Assesment_Bool_Exp>>>;
  age?: InputMaybe<String_Comparison_Exp>;
  cause?: InputMaybe<String_Comparison_Exp>;
  challenge?: InputMaybe<String_Comparison_Exp>;
  condition?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  frequency?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  solution?: InputMaybe<String_Comparison_Exp>;
  subscribe?: InputMaybe<Boolean_Comparison_Exp>;
  time_to_sleep?: InputMaybe<String_Comparison_Exp>;
  timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  wake_up_early?: InputMaybe<String_Comparison_Exp>;
  wake_up_middle?: InputMaybe<String_Comparison_Exp>;
};

export enum Assesment_Constraint {
  AssesmentPkey = 'assesment_pkey',
}

export type Assesment_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

export type Assesment_Insert_Input = {
  age?: InputMaybe<Scalars['String']>;
  cause?: InputMaybe<Scalars['String']>;
  challenge?: InputMaybe<Scalars['String']>;
  condition?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  frequency?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  last_name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  solution?: InputMaybe<Scalars['String']>;
  subscribe?: InputMaybe<Scalars['Boolean']>;
  time_to_sleep?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  wake_up_early?: InputMaybe<Scalars['String']>;
  wake_up_middle?: InputMaybe<Scalars['String']>;
};

export type Assesment_Max_Fields = {
  __typename?: 'assesment_max_fields';
  age?: Maybe<Scalars['String']>;
  cause?: Maybe<Scalars['String']>;
  challenge?: Maybe<Scalars['String']>;
  condition?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  frequency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  solution?: Maybe<Scalars['String']>;
  time_to_sleep?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  wake_up_early?: Maybe<Scalars['String']>;
  wake_up_middle?: Maybe<Scalars['String']>;
};

export type Assesment_Max_Order_By = {
  age?: InputMaybe<Order_By>;
  cause?: InputMaybe<Order_By>;
  challenge?: InputMaybe<Order_By>;
  condition?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  frequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  solution?: InputMaybe<Order_By>;
  time_to_sleep?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  wake_up_early?: InputMaybe<Order_By>;
  wake_up_middle?: InputMaybe<Order_By>;
};

export type Assesment_Min_Fields = {
  __typename?: 'assesment_min_fields';
  age?: Maybe<Scalars['String']>;
  cause?: Maybe<Scalars['String']>;
  challenge?: Maybe<Scalars['String']>;
  condition?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  frequency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  solution?: Maybe<Scalars['String']>;
  time_to_sleep?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  wake_up_early?: Maybe<Scalars['String']>;
  wake_up_middle?: Maybe<Scalars['String']>;
};

export type Assesment_Min_Order_By = {
  age?: InputMaybe<Order_By>;
  cause?: InputMaybe<Order_By>;
  challenge?: InputMaybe<Order_By>;
  condition?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  frequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  solution?: InputMaybe<Order_By>;
  time_to_sleep?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  wake_up_early?: InputMaybe<Order_By>;
  wake_up_middle?: InputMaybe<Order_By>;
};

export type Assesment_Mutation_Response = {
  __typename?: 'assesment_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Assesment>;
};

export type Assesment_Obj_Rel_Insert_Input = {
  data: Assesment_Insert_Input;
  on_conflict?: InputMaybe<Assesment_On_Conflict>;
};

export type Assesment_On_Conflict = {
  constraint: Assesment_Constraint;
  update_columns: Array<Assesment_Update_Column>;
  where?: InputMaybe<Assesment_Bool_Exp>;
};

export type Assesment_Order_By = {
  age?: InputMaybe<Order_By>;
  cause?: InputMaybe<Order_By>;
  challenge?: InputMaybe<Order_By>;
  condition?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  frequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  solution?: InputMaybe<Order_By>;
  subscribe?: InputMaybe<Order_By>;
  time_to_sleep?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  wake_up_early?: InputMaybe<Order_By>;
  wake_up_middle?: InputMaybe<Order_By>;
};

export type Assesment_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export enum Assesment_Select_Column {
  Age = 'age',
  Cause = 'cause',
  Challenge = 'challenge',
  Condition = 'condition',
  CreatedAt = 'created_at',
  Email = 'email',
  FirstName = 'first_name',
  Frequency = 'frequency',
  Id = 'id',
  LastName = 'last_name',
  Phone = 'phone',
  Solution = 'solution',
  Subscribe = 'subscribe',
  TimeToSleep = 'time_to_sleep',
  Timestamp = 'timestamp',
  WakeUpEarly = 'wake_up_early',
  WakeUpMiddle = 'wake_up_middle',
}

export type Assesment_Set_Input = {
  age?: InputMaybe<Scalars['String']>;
  cause?: InputMaybe<Scalars['String']>;
  challenge?: InputMaybe<Scalars['String']>;
  condition?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  frequency?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  last_name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  solution?: InputMaybe<Scalars['String']>;
  subscribe?: InputMaybe<Scalars['Boolean']>;
  time_to_sleep?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  wake_up_early?: InputMaybe<Scalars['String']>;
  wake_up_middle?: InputMaybe<Scalars['String']>;
};

export type Assesment_Stddev_Fields = {
  __typename?: 'assesment_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Assesment_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Assesment_Stddev_Pop_Fields = {
  __typename?: 'assesment_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Assesment_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Assesment_Stddev_Samp_Fields = {
  __typename?: 'assesment_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Assesment_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Assesment_Sum_Fields = {
  __typename?: 'assesment_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

export type Assesment_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

export enum Assesment_Update_Column {
  Age = 'age',
  Cause = 'cause',
  Challenge = 'challenge',
  Condition = 'condition',
  CreatedAt = 'created_at',
  Email = 'email',
  FirstName = 'first_name',
  Frequency = 'frequency',
  Id = 'id',
  LastName = 'last_name',
  Phone = 'phone',
  Solution = 'solution',
  Subscribe = 'subscribe',
  TimeToSleep = 'time_to_sleep',
  Timestamp = 'timestamp',
  WakeUpEarly = 'wake_up_early',
  WakeUpMiddle = 'wake_up_middle',
}

export type Assesment_Var_Pop_Fields = {
  __typename?: 'assesment_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Assesment_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Assesment_Var_Samp_Fields = {
  __typename?: 'assesment_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Assesment_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Assesment_Variance_Fields = {
  __typename?: 'assesment_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Assesment_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type B2b_Diagnosis_V1 = {
  __typename?: 'b2b_diagnosis_v1';
  anxiety: Scalars['Int'];
  birthdate: Scalars['date'];
  company?: Maybe<Scalars['String']>;
  concern_about_sleep: Scalars['Int'];
  cpf: Scalars['String'];
  created_at: Scalars['timestamptz'];
  extra_data: Scalars['jsonb'];
  gender: Scalars['String'];
  id: Scalars['Int'];
  initial_insomnia: Scalars['Int'];
  liveliness: Scalars['Int'];
  middle_insomnia: Scalars['Int'];
  motivation: Scalars['Int'];
  name: Scalars['String'];
  origin?: Maybe<Scalars['String']>;
  other_perception: Scalars['Int'];
  self_control: Scalars['Int'];
  sleep_disturbance: Scalars['Int'];
  sleep_impact_on_daily_activities: Scalars['Int'];
  sleep_problem_duration?: Maybe<Scalars['Int']>;
  sleep_satisfaction: Scalars['Int'];
  terminal_insomnia: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  week_frequency?: Maybe<Scalars['Int']>;
};

export type B2b_Diagnosis_V1Extra_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

export type B2b_Diagnosis_V1_Aggregate = {
  __typename?: 'b2b_diagnosis_v1_aggregate';
  aggregate?: Maybe<B2b_Diagnosis_V1_Aggregate_Fields>;
  nodes: Array<B2b_Diagnosis_V1>;
};

export type B2b_Diagnosis_V1_Aggregate_Fields = {
  __typename?: 'b2b_diagnosis_v1_aggregate_fields';
  avg?: Maybe<B2b_Diagnosis_V1_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<B2b_Diagnosis_V1_Max_Fields>;
  min?: Maybe<B2b_Diagnosis_V1_Min_Fields>;
  stddev?: Maybe<B2b_Diagnosis_V1_Stddev_Fields>;
  stddev_pop?: Maybe<B2b_Diagnosis_V1_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<B2b_Diagnosis_V1_Stddev_Samp_Fields>;
  sum?: Maybe<B2b_Diagnosis_V1_Sum_Fields>;
  var_pop?: Maybe<B2b_Diagnosis_V1_Var_Pop_Fields>;
  var_samp?: Maybe<B2b_Diagnosis_V1_Var_Samp_Fields>;
  variance?: Maybe<B2b_Diagnosis_V1_Variance_Fields>;
};

export type B2b_Diagnosis_V1_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<B2b_Diagnosis_V1_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type B2b_Diagnosis_V1_Aggregate_Order_By = {
  avg?: InputMaybe<B2b_Diagnosis_V1_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<B2b_Diagnosis_V1_Max_Order_By>;
  min?: InputMaybe<B2b_Diagnosis_V1_Min_Order_By>;
  stddev?: InputMaybe<B2b_Diagnosis_V1_Stddev_Order_By>;
  stddev_pop?: InputMaybe<B2b_Diagnosis_V1_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<B2b_Diagnosis_V1_Stddev_Samp_Order_By>;
  sum?: InputMaybe<B2b_Diagnosis_V1_Sum_Order_By>;
  var_pop?: InputMaybe<B2b_Diagnosis_V1_Var_Pop_Order_By>;
  var_samp?: InputMaybe<B2b_Diagnosis_V1_Var_Samp_Order_By>;
  variance?: InputMaybe<B2b_Diagnosis_V1_Variance_Order_By>;
};

export type B2b_Diagnosis_V1_Append_Input = {
  extra_data?: InputMaybe<Scalars['jsonb']>;
};

export type B2b_Diagnosis_V1_Arr_Rel_Insert_Input = {
  data: Array<B2b_Diagnosis_V1_Insert_Input>;
  on_conflict?: InputMaybe<B2b_Diagnosis_V1_On_Conflict>;
};

export type B2b_Diagnosis_V1_Avg_Fields = {
  __typename?: 'b2b_diagnosis_v1_avg_fields';
  anxiety?: Maybe<Scalars['Float']>;
  concern_about_sleep?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  initial_insomnia?: Maybe<Scalars['Float']>;
  liveliness?: Maybe<Scalars['Float']>;
  middle_insomnia?: Maybe<Scalars['Float']>;
  motivation?: Maybe<Scalars['Float']>;
  other_perception?: Maybe<Scalars['Float']>;
  self_control?: Maybe<Scalars['Float']>;
  sleep_disturbance?: Maybe<Scalars['Float']>;
  sleep_impact_on_daily_activities?: Maybe<Scalars['Float']>;
  sleep_problem_duration?: Maybe<Scalars['Float']>;
  sleep_satisfaction?: Maybe<Scalars['Float']>;
  terminal_insomnia?: Maybe<Scalars['Float']>;
  week_frequency?: Maybe<Scalars['Float']>;
};

export type B2b_Diagnosis_V1_Avg_Order_By = {
  anxiety?: InputMaybe<Order_By>;
  concern_about_sleep?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_insomnia?: InputMaybe<Order_By>;
  liveliness?: InputMaybe<Order_By>;
  middle_insomnia?: InputMaybe<Order_By>;
  motivation?: InputMaybe<Order_By>;
  other_perception?: InputMaybe<Order_By>;
  self_control?: InputMaybe<Order_By>;
  sleep_disturbance?: InputMaybe<Order_By>;
  sleep_impact_on_daily_activities?: InputMaybe<Order_By>;
  sleep_problem_duration?: InputMaybe<Order_By>;
  sleep_satisfaction?: InputMaybe<Order_By>;
  terminal_insomnia?: InputMaybe<Order_By>;
  week_frequency?: InputMaybe<Order_By>;
};

export type B2b_Diagnosis_V1_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<B2b_Diagnosis_V1_Bool_Exp>>>;
  _not?: InputMaybe<B2b_Diagnosis_V1_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<B2b_Diagnosis_V1_Bool_Exp>>>;
  anxiety?: InputMaybe<Int_Comparison_Exp>;
  birthdate?: InputMaybe<Date_Comparison_Exp>;
  company?: InputMaybe<String_Comparison_Exp>;
  concern_about_sleep?: InputMaybe<Int_Comparison_Exp>;
  cpf?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  extra_data?: InputMaybe<Jsonb_Comparison_Exp>;
  gender?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  initial_insomnia?: InputMaybe<Int_Comparison_Exp>;
  liveliness?: InputMaybe<Int_Comparison_Exp>;
  middle_insomnia?: InputMaybe<Int_Comparison_Exp>;
  motivation?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  origin?: InputMaybe<String_Comparison_Exp>;
  other_perception?: InputMaybe<Int_Comparison_Exp>;
  self_control?: InputMaybe<Int_Comparison_Exp>;
  sleep_disturbance?: InputMaybe<Int_Comparison_Exp>;
  sleep_impact_on_daily_activities?: InputMaybe<Int_Comparison_Exp>;
  sleep_problem_duration?: InputMaybe<Int_Comparison_Exp>;
  sleep_satisfaction?: InputMaybe<Int_Comparison_Exp>;
  terminal_insomnia?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  week_frequency?: InputMaybe<Int_Comparison_Exp>;
};

export enum B2b_Diagnosis_V1_Constraint {
  B2bDiagnosisV1Pkey = 'b2b_diagnosis_v1_pkey',
}

export type B2b_Diagnosis_V1_Delete_At_Path_Input = {
  extra_data?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type B2b_Diagnosis_V1_Delete_Elem_Input = {
  extra_data?: InputMaybe<Scalars['Int']>;
};

export type B2b_Diagnosis_V1_Delete_Key_Input = {
  extra_data?: InputMaybe<Scalars['String']>;
};

export type B2b_Diagnosis_V1_Inc_Input = {
  anxiety?: InputMaybe<Scalars['Int']>;
  concern_about_sleep?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  initial_insomnia?: InputMaybe<Scalars['Int']>;
  liveliness?: InputMaybe<Scalars['Int']>;
  middle_insomnia?: InputMaybe<Scalars['Int']>;
  motivation?: InputMaybe<Scalars['Int']>;
  other_perception?: InputMaybe<Scalars['Int']>;
  self_control?: InputMaybe<Scalars['Int']>;
  sleep_disturbance?: InputMaybe<Scalars['Int']>;
  sleep_impact_on_daily_activities?: InputMaybe<Scalars['Int']>;
  sleep_problem_duration?: InputMaybe<Scalars['Int']>;
  sleep_satisfaction?: InputMaybe<Scalars['Int']>;
  terminal_insomnia?: InputMaybe<Scalars['Int']>;
  week_frequency?: InputMaybe<Scalars['Int']>;
};

export type B2b_Diagnosis_V1_Insert_Input = {
  anxiety?: InputMaybe<Scalars['Int']>;
  birthdate?: InputMaybe<Scalars['date']>;
  company?: InputMaybe<Scalars['String']>;
  concern_about_sleep?: InputMaybe<Scalars['Int']>;
  cpf?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  extra_data?: InputMaybe<Scalars['jsonb']>;
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  initial_insomnia?: InputMaybe<Scalars['Int']>;
  liveliness?: InputMaybe<Scalars['Int']>;
  middle_insomnia?: InputMaybe<Scalars['Int']>;
  motivation?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  origin?: InputMaybe<Scalars['String']>;
  other_perception?: InputMaybe<Scalars['Int']>;
  self_control?: InputMaybe<Scalars['Int']>;
  sleep_disturbance?: InputMaybe<Scalars['Int']>;
  sleep_impact_on_daily_activities?: InputMaybe<Scalars['Int']>;
  sleep_problem_duration?: InputMaybe<Scalars['Int']>;
  sleep_satisfaction?: InputMaybe<Scalars['Int']>;
  terminal_insomnia?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  week_frequency?: InputMaybe<Scalars['Int']>;
};

export type B2b_Diagnosis_V1_Max_Fields = {
  __typename?: 'b2b_diagnosis_v1_max_fields';
  anxiety?: Maybe<Scalars['Int']>;
  birthdate?: Maybe<Scalars['date']>;
  company?: Maybe<Scalars['String']>;
  concern_about_sleep?: Maybe<Scalars['Int']>;
  cpf?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  initial_insomnia?: Maybe<Scalars['Int']>;
  liveliness?: Maybe<Scalars['Int']>;
  middle_insomnia?: Maybe<Scalars['Int']>;
  motivation?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  other_perception?: Maybe<Scalars['Int']>;
  self_control?: Maybe<Scalars['Int']>;
  sleep_disturbance?: Maybe<Scalars['Int']>;
  sleep_impact_on_daily_activities?: Maybe<Scalars['Int']>;
  sleep_problem_duration?: Maybe<Scalars['Int']>;
  sleep_satisfaction?: Maybe<Scalars['Int']>;
  terminal_insomnia?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  week_frequency?: Maybe<Scalars['Int']>;
};

export type B2b_Diagnosis_V1_Max_Order_By = {
  anxiety?: InputMaybe<Order_By>;
  birthdate?: InputMaybe<Order_By>;
  company?: InputMaybe<Order_By>;
  concern_about_sleep?: InputMaybe<Order_By>;
  cpf?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_insomnia?: InputMaybe<Order_By>;
  liveliness?: InputMaybe<Order_By>;
  middle_insomnia?: InputMaybe<Order_By>;
  motivation?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  origin?: InputMaybe<Order_By>;
  other_perception?: InputMaybe<Order_By>;
  self_control?: InputMaybe<Order_By>;
  sleep_disturbance?: InputMaybe<Order_By>;
  sleep_impact_on_daily_activities?: InputMaybe<Order_By>;
  sleep_problem_duration?: InputMaybe<Order_By>;
  sleep_satisfaction?: InputMaybe<Order_By>;
  terminal_insomnia?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  week_frequency?: InputMaybe<Order_By>;
};

export type B2b_Diagnosis_V1_Min_Fields = {
  __typename?: 'b2b_diagnosis_v1_min_fields';
  anxiety?: Maybe<Scalars['Int']>;
  birthdate?: Maybe<Scalars['date']>;
  company?: Maybe<Scalars['String']>;
  concern_about_sleep?: Maybe<Scalars['Int']>;
  cpf?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  initial_insomnia?: Maybe<Scalars['Int']>;
  liveliness?: Maybe<Scalars['Int']>;
  middle_insomnia?: Maybe<Scalars['Int']>;
  motivation?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  other_perception?: Maybe<Scalars['Int']>;
  self_control?: Maybe<Scalars['Int']>;
  sleep_disturbance?: Maybe<Scalars['Int']>;
  sleep_impact_on_daily_activities?: Maybe<Scalars['Int']>;
  sleep_problem_duration?: Maybe<Scalars['Int']>;
  sleep_satisfaction?: Maybe<Scalars['Int']>;
  terminal_insomnia?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  week_frequency?: Maybe<Scalars['Int']>;
};

export type B2b_Diagnosis_V1_Min_Order_By = {
  anxiety?: InputMaybe<Order_By>;
  birthdate?: InputMaybe<Order_By>;
  company?: InputMaybe<Order_By>;
  concern_about_sleep?: InputMaybe<Order_By>;
  cpf?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_insomnia?: InputMaybe<Order_By>;
  liveliness?: InputMaybe<Order_By>;
  middle_insomnia?: InputMaybe<Order_By>;
  motivation?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  origin?: InputMaybe<Order_By>;
  other_perception?: InputMaybe<Order_By>;
  self_control?: InputMaybe<Order_By>;
  sleep_disturbance?: InputMaybe<Order_By>;
  sleep_impact_on_daily_activities?: InputMaybe<Order_By>;
  sleep_problem_duration?: InputMaybe<Order_By>;
  sleep_satisfaction?: InputMaybe<Order_By>;
  terminal_insomnia?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  week_frequency?: InputMaybe<Order_By>;
};

export type B2b_Diagnosis_V1_Mutation_Response = {
  __typename?: 'b2b_diagnosis_v1_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<B2b_Diagnosis_V1>;
};

export type B2b_Diagnosis_V1_Obj_Rel_Insert_Input = {
  data: B2b_Diagnosis_V1_Insert_Input;
  on_conflict?: InputMaybe<B2b_Diagnosis_V1_On_Conflict>;
};

export type B2b_Diagnosis_V1_On_Conflict = {
  constraint: B2b_Diagnosis_V1_Constraint;
  update_columns: Array<B2b_Diagnosis_V1_Update_Column>;
  where?: InputMaybe<B2b_Diagnosis_V1_Bool_Exp>;
};

export type B2b_Diagnosis_V1_Order_By = {
  anxiety?: InputMaybe<Order_By>;
  birthdate?: InputMaybe<Order_By>;
  company?: InputMaybe<Order_By>;
  concern_about_sleep?: InputMaybe<Order_By>;
  cpf?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  extra_data?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_insomnia?: InputMaybe<Order_By>;
  liveliness?: InputMaybe<Order_By>;
  middle_insomnia?: InputMaybe<Order_By>;
  motivation?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  origin?: InputMaybe<Order_By>;
  other_perception?: InputMaybe<Order_By>;
  self_control?: InputMaybe<Order_By>;
  sleep_disturbance?: InputMaybe<Order_By>;
  sleep_impact_on_daily_activities?: InputMaybe<Order_By>;
  sleep_problem_duration?: InputMaybe<Order_By>;
  sleep_satisfaction?: InputMaybe<Order_By>;
  terminal_insomnia?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  week_frequency?: InputMaybe<Order_By>;
};

export type B2b_Diagnosis_V1_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export type B2b_Diagnosis_V1_Prepend_Input = {
  extra_data?: InputMaybe<Scalars['jsonb']>;
};

export enum B2b_Diagnosis_V1_Select_Column {
  Anxiety = 'anxiety',
  Birthdate = 'birthdate',
  Company = 'company',
  ConcernAboutSleep = 'concern_about_sleep',
  Cpf = 'cpf',
  CreatedAt = 'created_at',
  ExtraData = 'extra_data',
  Gender = 'gender',
  Id = 'id',
  InitialInsomnia = 'initial_insomnia',
  Liveliness = 'liveliness',
  MiddleInsomnia = 'middle_insomnia',
  Motivation = 'motivation',
  Name = 'name',
  Origin = 'origin',
  OtherPerception = 'other_perception',
  SelfControl = 'self_control',
  SleepDisturbance = 'sleep_disturbance',
  SleepImpactOnDailyActivities = 'sleep_impact_on_daily_activities',
  SleepProblemDuration = 'sleep_problem_duration',
  SleepSatisfaction = 'sleep_satisfaction',
  TerminalInsomnia = 'terminal_insomnia',
  UpdatedAt = 'updated_at',
  WeekFrequency = 'week_frequency',
}

export type B2b_Diagnosis_V1_Set_Input = {
  anxiety?: InputMaybe<Scalars['Int']>;
  birthdate?: InputMaybe<Scalars['date']>;
  company?: InputMaybe<Scalars['String']>;
  concern_about_sleep?: InputMaybe<Scalars['Int']>;
  cpf?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  extra_data?: InputMaybe<Scalars['jsonb']>;
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  initial_insomnia?: InputMaybe<Scalars['Int']>;
  liveliness?: InputMaybe<Scalars['Int']>;
  middle_insomnia?: InputMaybe<Scalars['Int']>;
  motivation?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  origin?: InputMaybe<Scalars['String']>;
  other_perception?: InputMaybe<Scalars['Int']>;
  self_control?: InputMaybe<Scalars['Int']>;
  sleep_disturbance?: InputMaybe<Scalars['Int']>;
  sleep_impact_on_daily_activities?: InputMaybe<Scalars['Int']>;
  sleep_problem_duration?: InputMaybe<Scalars['Int']>;
  sleep_satisfaction?: InputMaybe<Scalars['Int']>;
  terminal_insomnia?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  week_frequency?: InputMaybe<Scalars['Int']>;
};

export type B2b_Diagnosis_V1_Stddev_Fields = {
  __typename?: 'b2b_diagnosis_v1_stddev_fields';
  anxiety?: Maybe<Scalars['Float']>;
  concern_about_sleep?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  initial_insomnia?: Maybe<Scalars['Float']>;
  liveliness?: Maybe<Scalars['Float']>;
  middle_insomnia?: Maybe<Scalars['Float']>;
  motivation?: Maybe<Scalars['Float']>;
  other_perception?: Maybe<Scalars['Float']>;
  self_control?: Maybe<Scalars['Float']>;
  sleep_disturbance?: Maybe<Scalars['Float']>;
  sleep_impact_on_daily_activities?: Maybe<Scalars['Float']>;
  sleep_problem_duration?: Maybe<Scalars['Float']>;
  sleep_satisfaction?: Maybe<Scalars['Float']>;
  terminal_insomnia?: Maybe<Scalars['Float']>;
  week_frequency?: Maybe<Scalars['Float']>;
};

export type B2b_Diagnosis_V1_Stddev_Order_By = {
  anxiety?: InputMaybe<Order_By>;
  concern_about_sleep?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_insomnia?: InputMaybe<Order_By>;
  liveliness?: InputMaybe<Order_By>;
  middle_insomnia?: InputMaybe<Order_By>;
  motivation?: InputMaybe<Order_By>;
  other_perception?: InputMaybe<Order_By>;
  self_control?: InputMaybe<Order_By>;
  sleep_disturbance?: InputMaybe<Order_By>;
  sleep_impact_on_daily_activities?: InputMaybe<Order_By>;
  sleep_problem_duration?: InputMaybe<Order_By>;
  sleep_satisfaction?: InputMaybe<Order_By>;
  terminal_insomnia?: InputMaybe<Order_By>;
  week_frequency?: InputMaybe<Order_By>;
};

export type B2b_Diagnosis_V1_Stddev_Pop_Fields = {
  __typename?: 'b2b_diagnosis_v1_stddev_pop_fields';
  anxiety?: Maybe<Scalars['Float']>;
  concern_about_sleep?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  initial_insomnia?: Maybe<Scalars['Float']>;
  liveliness?: Maybe<Scalars['Float']>;
  middle_insomnia?: Maybe<Scalars['Float']>;
  motivation?: Maybe<Scalars['Float']>;
  other_perception?: Maybe<Scalars['Float']>;
  self_control?: Maybe<Scalars['Float']>;
  sleep_disturbance?: Maybe<Scalars['Float']>;
  sleep_impact_on_daily_activities?: Maybe<Scalars['Float']>;
  sleep_problem_duration?: Maybe<Scalars['Float']>;
  sleep_satisfaction?: Maybe<Scalars['Float']>;
  terminal_insomnia?: Maybe<Scalars['Float']>;
  week_frequency?: Maybe<Scalars['Float']>;
};

export type B2b_Diagnosis_V1_Stddev_Pop_Order_By = {
  anxiety?: InputMaybe<Order_By>;
  concern_about_sleep?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_insomnia?: InputMaybe<Order_By>;
  liveliness?: InputMaybe<Order_By>;
  middle_insomnia?: InputMaybe<Order_By>;
  motivation?: InputMaybe<Order_By>;
  other_perception?: InputMaybe<Order_By>;
  self_control?: InputMaybe<Order_By>;
  sleep_disturbance?: InputMaybe<Order_By>;
  sleep_impact_on_daily_activities?: InputMaybe<Order_By>;
  sleep_problem_duration?: InputMaybe<Order_By>;
  sleep_satisfaction?: InputMaybe<Order_By>;
  terminal_insomnia?: InputMaybe<Order_By>;
  week_frequency?: InputMaybe<Order_By>;
};

export type B2b_Diagnosis_V1_Stddev_Samp_Fields = {
  __typename?: 'b2b_diagnosis_v1_stddev_samp_fields';
  anxiety?: Maybe<Scalars['Float']>;
  concern_about_sleep?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  initial_insomnia?: Maybe<Scalars['Float']>;
  liveliness?: Maybe<Scalars['Float']>;
  middle_insomnia?: Maybe<Scalars['Float']>;
  motivation?: Maybe<Scalars['Float']>;
  other_perception?: Maybe<Scalars['Float']>;
  self_control?: Maybe<Scalars['Float']>;
  sleep_disturbance?: Maybe<Scalars['Float']>;
  sleep_impact_on_daily_activities?: Maybe<Scalars['Float']>;
  sleep_problem_duration?: Maybe<Scalars['Float']>;
  sleep_satisfaction?: Maybe<Scalars['Float']>;
  terminal_insomnia?: Maybe<Scalars['Float']>;
  week_frequency?: Maybe<Scalars['Float']>;
};

export type B2b_Diagnosis_V1_Stddev_Samp_Order_By = {
  anxiety?: InputMaybe<Order_By>;
  concern_about_sleep?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_insomnia?: InputMaybe<Order_By>;
  liveliness?: InputMaybe<Order_By>;
  middle_insomnia?: InputMaybe<Order_By>;
  motivation?: InputMaybe<Order_By>;
  other_perception?: InputMaybe<Order_By>;
  self_control?: InputMaybe<Order_By>;
  sleep_disturbance?: InputMaybe<Order_By>;
  sleep_impact_on_daily_activities?: InputMaybe<Order_By>;
  sleep_problem_duration?: InputMaybe<Order_By>;
  sleep_satisfaction?: InputMaybe<Order_By>;
  terminal_insomnia?: InputMaybe<Order_By>;
  week_frequency?: InputMaybe<Order_By>;
};

export type B2b_Diagnosis_V1_Sum_Fields = {
  __typename?: 'b2b_diagnosis_v1_sum_fields';
  anxiety?: Maybe<Scalars['Int']>;
  concern_about_sleep?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  initial_insomnia?: Maybe<Scalars['Int']>;
  liveliness?: Maybe<Scalars['Int']>;
  middle_insomnia?: Maybe<Scalars['Int']>;
  motivation?: Maybe<Scalars['Int']>;
  other_perception?: Maybe<Scalars['Int']>;
  self_control?: Maybe<Scalars['Int']>;
  sleep_disturbance?: Maybe<Scalars['Int']>;
  sleep_impact_on_daily_activities?: Maybe<Scalars['Int']>;
  sleep_problem_duration?: Maybe<Scalars['Int']>;
  sleep_satisfaction?: Maybe<Scalars['Int']>;
  terminal_insomnia?: Maybe<Scalars['Int']>;
  week_frequency?: Maybe<Scalars['Int']>;
};

export type B2b_Diagnosis_V1_Sum_Order_By = {
  anxiety?: InputMaybe<Order_By>;
  concern_about_sleep?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_insomnia?: InputMaybe<Order_By>;
  liveliness?: InputMaybe<Order_By>;
  middle_insomnia?: InputMaybe<Order_By>;
  motivation?: InputMaybe<Order_By>;
  other_perception?: InputMaybe<Order_By>;
  self_control?: InputMaybe<Order_By>;
  sleep_disturbance?: InputMaybe<Order_By>;
  sleep_impact_on_daily_activities?: InputMaybe<Order_By>;
  sleep_problem_duration?: InputMaybe<Order_By>;
  sleep_satisfaction?: InputMaybe<Order_By>;
  terminal_insomnia?: InputMaybe<Order_By>;
  week_frequency?: InputMaybe<Order_By>;
};

export enum B2b_Diagnosis_V1_Update_Column {
  Anxiety = 'anxiety',
  Birthdate = 'birthdate',
  Company = 'company',
  ConcernAboutSleep = 'concern_about_sleep',
  Cpf = 'cpf',
  CreatedAt = 'created_at',
  ExtraData = 'extra_data',
  Gender = 'gender',
  Id = 'id',
  InitialInsomnia = 'initial_insomnia',
  Liveliness = 'liveliness',
  MiddleInsomnia = 'middle_insomnia',
  Motivation = 'motivation',
  Name = 'name',
  Origin = 'origin',
  OtherPerception = 'other_perception',
  SelfControl = 'self_control',
  SleepDisturbance = 'sleep_disturbance',
  SleepImpactOnDailyActivities = 'sleep_impact_on_daily_activities',
  SleepProblemDuration = 'sleep_problem_duration',
  SleepSatisfaction = 'sleep_satisfaction',
  TerminalInsomnia = 'terminal_insomnia',
  UpdatedAt = 'updated_at',
  WeekFrequency = 'week_frequency',
}

export type B2b_Diagnosis_V1_Var_Pop_Fields = {
  __typename?: 'b2b_diagnosis_v1_var_pop_fields';
  anxiety?: Maybe<Scalars['Float']>;
  concern_about_sleep?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  initial_insomnia?: Maybe<Scalars['Float']>;
  liveliness?: Maybe<Scalars['Float']>;
  middle_insomnia?: Maybe<Scalars['Float']>;
  motivation?: Maybe<Scalars['Float']>;
  other_perception?: Maybe<Scalars['Float']>;
  self_control?: Maybe<Scalars['Float']>;
  sleep_disturbance?: Maybe<Scalars['Float']>;
  sleep_impact_on_daily_activities?: Maybe<Scalars['Float']>;
  sleep_problem_duration?: Maybe<Scalars['Float']>;
  sleep_satisfaction?: Maybe<Scalars['Float']>;
  terminal_insomnia?: Maybe<Scalars['Float']>;
  week_frequency?: Maybe<Scalars['Float']>;
};

export type B2b_Diagnosis_V1_Var_Pop_Order_By = {
  anxiety?: InputMaybe<Order_By>;
  concern_about_sleep?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_insomnia?: InputMaybe<Order_By>;
  liveliness?: InputMaybe<Order_By>;
  middle_insomnia?: InputMaybe<Order_By>;
  motivation?: InputMaybe<Order_By>;
  other_perception?: InputMaybe<Order_By>;
  self_control?: InputMaybe<Order_By>;
  sleep_disturbance?: InputMaybe<Order_By>;
  sleep_impact_on_daily_activities?: InputMaybe<Order_By>;
  sleep_problem_duration?: InputMaybe<Order_By>;
  sleep_satisfaction?: InputMaybe<Order_By>;
  terminal_insomnia?: InputMaybe<Order_By>;
  week_frequency?: InputMaybe<Order_By>;
};

export type B2b_Diagnosis_V1_Var_Samp_Fields = {
  __typename?: 'b2b_diagnosis_v1_var_samp_fields';
  anxiety?: Maybe<Scalars['Float']>;
  concern_about_sleep?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  initial_insomnia?: Maybe<Scalars['Float']>;
  liveliness?: Maybe<Scalars['Float']>;
  middle_insomnia?: Maybe<Scalars['Float']>;
  motivation?: Maybe<Scalars['Float']>;
  other_perception?: Maybe<Scalars['Float']>;
  self_control?: Maybe<Scalars['Float']>;
  sleep_disturbance?: Maybe<Scalars['Float']>;
  sleep_impact_on_daily_activities?: Maybe<Scalars['Float']>;
  sleep_problem_duration?: Maybe<Scalars['Float']>;
  sleep_satisfaction?: Maybe<Scalars['Float']>;
  terminal_insomnia?: Maybe<Scalars['Float']>;
  week_frequency?: Maybe<Scalars['Float']>;
};

export type B2b_Diagnosis_V1_Var_Samp_Order_By = {
  anxiety?: InputMaybe<Order_By>;
  concern_about_sleep?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_insomnia?: InputMaybe<Order_By>;
  liveliness?: InputMaybe<Order_By>;
  middle_insomnia?: InputMaybe<Order_By>;
  motivation?: InputMaybe<Order_By>;
  other_perception?: InputMaybe<Order_By>;
  self_control?: InputMaybe<Order_By>;
  sleep_disturbance?: InputMaybe<Order_By>;
  sleep_impact_on_daily_activities?: InputMaybe<Order_By>;
  sleep_problem_duration?: InputMaybe<Order_By>;
  sleep_satisfaction?: InputMaybe<Order_By>;
  terminal_insomnia?: InputMaybe<Order_By>;
  week_frequency?: InputMaybe<Order_By>;
};

export type B2b_Diagnosis_V1_Variance_Fields = {
  __typename?: 'b2b_diagnosis_v1_variance_fields';
  anxiety?: Maybe<Scalars['Float']>;
  concern_about_sleep?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  initial_insomnia?: Maybe<Scalars['Float']>;
  liveliness?: Maybe<Scalars['Float']>;
  middle_insomnia?: Maybe<Scalars['Float']>;
  motivation?: Maybe<Scalars['Float']>;
  other_perception?: Maybe<Scalars['Float']>;
  self_control?: Maybe<Scalars['Float']>;
  sleep_disturbance?: Maybe<Scalars['Float']>;
  sleep_impact_on_daily_activities?: Maybe<Scalars['Float']>;
  sleep_problem_duration?: Maybe<Scalars['Float']>;
  sleep_satisfaction?: Maybe<Scalars['Float']>;
  terminal_insomnia?: Maybe<Scalars['Float']>;
  week_frequency?: Maybe<Scalars['Float']>;
};

export type B2b_Diagnosis_V1_Variance_Order_By = {
  anxiety?: InputMaybe<Order_By>;
  concern_about_sleep?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_insomnia?: InputMaybe<Order_By>;
  liveliness?: InputMaybe<Order_By>;
  middle_insomnia?: InputMaybe<Order_By>;
  motivation?: InputMaybe<Order_By>;
  other_perception?: InputMaybe<Order_By>;
  self_control?: InputMaybe<Order_By>;
  sleep_disturbance?: InputMaybe<Order_By>;
  sleep_impact_on_daily_activities?: InputMaybe<Order_By>;
  sleep_problem_duration?: InputMaybe<Order_By>;
  sleep_satisfaction?: InputMaybe<Order_By>;
  terminal_insomnia?: InputMaybe<Order_By>;
  week_frequency?: InputMaybe<Order_By>;
};

export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

export type Catastrophic_Thinkings = {
  __typename?: 'catastrophic_thinkings';
  catastrophic_thinkings: Scalars['String'];
  created_at: Scalars['timestamptz'];
  fact_or_opinion: Scalars['String'];
  id: Scalars['Int'];
  user_id: Scalars['String'];
};

export type Catastrophic_Thinkings_Aggregate = {
  __typename?: 'catastrophic_thinkings_aggregate';
  aggregate?: Maybe<Catastrophic_Thinkings_Aggregate_Fields>;
  nodes: Array<Catastrophic_Thinkings>;
};

export type Catastrophic_Thinkings_Aggregate_Fields = {
  __typename?: 'catastrophic_thinkings_aggregate_fields';
  avg?: Maybe<Catastrophic_Thinkings_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Catastrophic_Thinkings_Max_Fields>;
  min?: Maybe<Catastrophic_Thinkings_Min_Fields>;
  stddev?: Maybe<Catastrophic_Thinkings_Stddev_Fields>;
  stddev_pop?: Maybe<Catastrophic_Thinkings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Catastrophic_Thinkings_Stddev_Samp_Fields>;
  sum?: Maybe<Catastrophic_Thinkings_Sum_Fields>;
  var_pop?: Maybe<Catastrophic_Thinkings_Var_Pop_Fields>;
  var_samp?: Maybe<Catastrophic_Thinkings_Var_Samp_Fields>;
  variance?: Maybe<Catastrophic_Thinkings_Variance_Fields>;
};

export type Catastrophic_Thinkings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Catastrophic_Thinkings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Catastrophic_Thinkings_Aggregate_Order_By = {
  avg?: InputMaybe<Catastrophic_Thinkings_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Catastrophic_Thinkings_Max_Order_By>;
  min?: InputMaybe<Catastrophic_Thinkings_Min_Order_By>;
  stddev?: InputMaybe<Catastrophic_Thinkings_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Catastrophic_Thinkings_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Catastrophic_Thinkings_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Catastrophic_Thinkings_Sum_Order_By>;
  var_pop?: InputMaybe<Catastrophic_Thinkings_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Catastrophic_Thinkings_Var_Samp_Order_By>;
  variance?: InputMaybe<Catastrophic_Thinkings_Variance_Order_By>;
};

export type Catastrophic_Thinkings_Arr_Rel_Insert_Input = {
  data: Array<Catastrophic_Thinkings_Insert_Input>;
  on_conflict?: InputMaybe<Catastrophic_Thinkings_On_Conflict>;
};

export type Catastrophic_Thinkings_Avg_Fields = {
  __typename?: 'catastrophic_thinkings_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Catastrophic_Thinkings_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Catastrophic_Thinkings_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Catastrophic_Thinkings_Bool_Exp>>>;
  _not?: InputMaybe<Catastrophic_Thinkings_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Catastrophic_Thinkings_Bool_Exp>>>;
  catastrophic_thinkings?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  fact_or_opinion?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

export enum Catastrophic_Thinkings_Constraint {
  CatastrophicThinkingsPkey = 'catastrophic_thinkings_pkey',
}

export type Catastrophic_Thinkings_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

export type Catastrophic_Thinkings_Insert_Input = {
  catastrophic_thinkings?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  fact_or_opinion?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Catastrophic_Thinkings_Max_Fields = {
  __typename?: 'catastrophic_thinkings_max_fields';
  catastrophic_thinkings?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fact_or_opinion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Catastrophic_Thinkings_Max_Order_By = {
  catastrophic_thinkings?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  fact_or_opinion?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Catastrophic_Thinkings_Min_Fields = {
  __typename?: 'catastrophic_thinkings_min_fields';
  catastrophic_thinkings?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fact_or_opinion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Catastrophic_Thinkings_Min_Order_By = {
  catastrophic_thinkings?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  fact_or_opinion?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Catastrophic_Thinkings_Mutation_Response = {
  __typename?: 'catastrophic_thinkings_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Catastrophic_Thinkings>;
};

export type Catastrophic_Thinkings_Obj_Rel_Insert_Input = {
  data: Catastrophic_Thinkings_Insert_Input;
  on_conflict?: InputMaybe<Catastrophic_Thinkings_On_Conflict>;
};

export type Catastrophic_Thinkings_On_Conflict = {
  constraint: Catastrophic_Thinkings_Constraint;
  update_columns: Array<Catastrophic_Thinkings_Update_Column>;
  where?: InputMaybe<Catastrophic_Thinkings_Bool_Exp>;
};

export type Catastrophic_Thinkings_Order_By = {
  catastrophic_thinkings?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  fact_or_opinion?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Catastrophic_Thinkings_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export enum Catastrophic_Thinkings_Select_Column {
  CatastrophicThinkings = 'catastrophic_thinkings',
  CreatedAt = 'created_at',
  FactOrOpinion = 'fact_or_opinion',
  Id = 'id',
  UserId = 'user_id',
}

export type Catastrophic_Thinkings_Set_Input = {
  catastrophic_thinkings?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  fact_or_opinion?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Catastrophic_Thinkings_Stddev_Fields = {
  __typename?: 'catastrophic_thinkings_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Catastrophic_Thinkings_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Catastrophic_Thinkings_Stddev_Pop_Fields = {
  __typename?: 'catastrophic_thinkings_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Catastrophic_Thinkings_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Catastrophic_Thinkings_Stddev_Samp_Fields = {
  __typename?: 'catastrophic_thinkings_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Catastrophic_Thinkings_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Catastrophic_Thinkings_Sum_Fields = {
  __typename?: 'catastrophic_thinkings_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

export type Catastrophic_Thinkings_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

export enum Catastrophic_Thinkings_Update_Column {
  CatastrophicThinkings = 'catastrophic_thinkings',
  CreatedAt = 'created_at',
  FactOrOpinion = 'fact_or_opinion',
  Id = 'id',
  UserId = 'user_id',
}

export type Catastrophic_Thinkings_Var_Pop_Fields = {
  __typename?: 'catastrophic_thinkings_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Catastrophic_Thinkings_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Catastrophic_Thinkings_Var_Samp_Fields = {
  __typename?: 'catastrophic_thinkings_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Catastrophic_Thinkings_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Catastrophic_Thinkings_Variance_Fields = {
  __typename?: 'catastrophic_thinkings_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Catastrophic_Thinkings_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Community_User = {
  __typename?: 'community_user';
  community_id: Scalars['Int'];
  community_username: Scalars['String'];
  created_at?: Maybe<Scalars['timestamptz']>;
  messenger_id: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<User>;
};

export type Community_User_Aggregate = {
  __typename?: 'community_user_aggregate';
  aggregate?: Maybe<Community_User_Aggregate_Fields>;
  nodes: Array<Community_User>;
};

export type Community_User_Aggregate_Fields = {
  __typename?: 'community_user_aggregate_fields';
  avg?: Maybe<Community_User_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Community_User_Max_Fields>;
  min?: Maybe<Community_User_Min_Fields>;
  stddev?: Maybe<Community_User_Stddev_Fields>;
  stddev_pop?: Maybe<Community_User_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Community_User_Stddev_Samp_Fields>;
  sum?: Maybe<Community_User_Sum_Fields>;
  var_pop?: Maybe<Community_User_Var_Pop_Fields>;
  var_samp?: Maybe<Community_User_Var_Samp_Fields>;
  variance?: Maybe<Community_User_Variance_Fields>;
};

export type Community_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Community_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Community_User_Aggregate_Order_By = {
  avg?: InputMaybe<Community_User_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Community_User_Max_Order_By>;
  min?: InputMaybe<Community_User_Min_Order_By>;
  stddev?: InputMaybe<Community_User_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Community_User_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Community_User_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Community_User_Sum_Order_By>;
  var_pop?: InputMaybe<Community_User_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Community_User_Var_Samp_Order_By>;
  variance?: InputMaybe<Community_User_Variance_Order_By>;
};

export type Community_User_Arr_Rel_Insert_Input = {
  data: Array<Community_User_Insert_Input>;
  on_conflict?: InputMaybe<Community_User_On_Conflict>;
};

export type Community_User_Avg_Fields = {
  __typename?: 'community_user_avg_fields';
  community_id?: Maybe<Scalars['Float']>;
};

export type Community_User_Avg_Order_By = {
  community_id?: InputMaybe<Order_By>;
};

export type Community_User_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Community_User_Bool_Exp>>>;
  _not?: InputMaybe<Community_User_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Community_User_Bool_Exp>>>;
  community_id?: InputMaybe<Int_Comparison_Exp>;
  community_username?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  messenger_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
};

export enum Community_User_Constraint {
  CommunityUserMessengerIdKey = 'community_user_messenger_id_key',
  CommunityUserPkey = 'community_user_pkey',
}

export type Community_User_Inc_Input = {
  community_id?: InputMaybe<Scalars['Int']>;
};

export type Community_User_Insert_Input = {
  community_id?: InputMaybe<Scalars['Int']>;
  community_username?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  messenger_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
};

export type Community_User_Max_Fields = {
  __typename?: 'community_user_max_fields';
  community_id?: Maybe<Scalars['Int']>;
  community_username?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  messenger_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type Community_User_Max_Order_By = {
  community_id?: InputMaybe<Order_By>;
  community_username?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  messenger_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type Community_User_Min_Fields = {
  __typename?: 'community_user_min_fields';
  community_id?: Maybe<Scalars['Int']>;
  community_username?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  messenger_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type Community_User_Min_Order_By = {
  community_id?: InputMaybe<Order_By>;
  community_username?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  messenger_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type Community_User_Mutation_Response = {
  __typename?: 'community_user_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Community_User>;
};

export type Community_User_Obj_Rel_Insert_Input = {
  data: Community_User_Insert_Input;
  on_conflict?: InputMaybe<Community_User_On_Conflict>;
};

export type Community_User_On_Conflict = {
  constraint: Community_User_Constraint;
  update_columns: Array<Community_User_Update_Column>;
  where?: InputMaybe<Community_User_Bool_Exp>;
};

export type Community_User_Order_By = {
  community_id?: InputMaybe<Order_By>;
  community_username?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  messenger_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
};

export type Community_User_Pk_Columns_Input = {
  community_id: Scalars['Int'];
};

export enum Community_User_Select_Column {
  CommunityId = 'community_id',
  CommunityUsername = 'community_username',
  CreatedAt = 'created_at',
  MessengerId = 'messenger_id',
  UpdatedAt = 'updated_at',
}

export type Community_User_Set_Input = {
  community_id?: InputMaybe<Scalars['Int']>;
  community_username?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  messenger_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Community_User_Stddev_Fields = {
  __typename?: 'community_user_stddev_fields';
  community_id?: Maybe<Scalars['Float']>;
};

export type Community_User_Stddev_Order_By = {
  community_id?: InputMaybe<Order_By>;
};

export type Community_User_Stddev_Pop_Fields = {
  __typename?: 'community_user_stddev_pop_fields';
  community_id?: Maybe<Scalars['Float']>;
};

export type Community_User_Stddev_Pop_Order_By = {
  community_id?: InputMaybe<Order_By>;
};

export type Community_User_Stddev_Samp_Fields = {
  __typename?: 'community_user_stddev_samp_fields';
  community_id?: Maybe<Scalars['Float']>;
};

export type Community_User_Stddev_Samp_Order_By = {
  community_id?: InputMaybe<Order_By>;
};

export type Community_User_Sum_Fields = {
  __typename?: 'community_user_sum_fields';
  community_id?: Maybe<Scalars['Int']>;
};

export type Community_User_Sum_Order_By = {
  community_id?: InputMaybe<Order_By>;
};

export enum Community_User_Update_Column {
  CommunityId = 'community_id',
  CommunityUsername = 'community_username',
  CreatedAt = 'created_at',
  MessengerId = 'messenger_id',
  UpdatedAt = 'updated_at',
}

export type Community_User_Var_Pop_Fields = {
  __typename?: 'community_user_var_pop_fields';
  community_id?: Maybe<Scalars['Float']>;
};

export type Community_User_Var_Pop_Order_By = {
  community_id?: InputMaybe<Order_By>;
};

export type Community_User_Var_Samp_Fields = {
  __typename?: 'community_user_var_samp_fields';
  community_id?: Maybe<Scalars['Float']>;
};

export type Community_User_Var_Samp_Order_By = {
  community_id?: InputMaybe<Order_By>;
};

export type Community_User_Variance_Fields = {
  __typename?: 'community_user_variance_fields';
  community_id?: Maybe<Scalars['Float']>;
};

export type Community_User_Variance_Order_By = {
  community_id?: InputMaybe<Order_By>;
};

export type Company = {
  __typename?: 'company';
  app_customizations: Array<App_Customization>;
  app_customizations_aggregate: App_Customization_Aggregate;
  coupons: Array<Coupon>;
  coupons_aggregate: Coupon_Aggregate;
  created_at: Scalars['timestamptz'];
  gender: Scalars['String'];
  health_carers: Array<Company_Health_Carer>;
  health_carers_aggregate: Company_Health_Carer_Aggregate;
  id: Scalars['Int'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  users: Array<Company_User>;
  users_aggregate: Company_User_Aggregate;
};

export type CompanyApp_CustomizationsArgs = {
  distinct_on?: InputMaybe<Array<App_Customization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<App_Customization_Order_By>>;
  where?: InputMaybe<App_Customization_Bool_Exp>;
};

export type CompanyApp_Customizations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Customization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<App_Customization_Order_By>>;
  where?: InputMaybe<App_Customization_Bool_Exp>;
};

export type CompanyCouponsArgs = {
  distinct_on?: InputMaybe<Array<Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coupon_Order_By>>;
  where?: InputMaybe<Coupon_Bool_Exp>;
};

export type CompanyCoupons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coupon_Order_By>>;
  where?: InputMaybe<Coupon_Bool_Exp>;
};

export type CompanyHealth_CarersArgs = {
  distinct_on?: InputMaybe<Array<Company_Health_Carer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Health_Carer_Order_By>>;
  where?: InputMaybe<Company_Health_Carer_Bool_Exp>;
};

export type CompanyHealth_Carers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Health_Carer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Health_Carer_Order_By>>;
  where?: InputMaybe<Company_Health_Carer_Bool_Exp>;
};

export type CompanyUsersArgs = {
  distinct_on?: InputMaybe<Array<Company_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_User_Order_By>>;
  where?: InputMaybe<Company_User_Bool_Exp>;
};

export type CompanyUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_User_Order_By>>;
  where?: InputMaybe<Company_User_Bool_Exp>;
};

export type Company_Aggregate = {
  __typename?: 'company_aggregate';
  aggregate?: Maybe<Company_Aggregate_Fields>;
  nodes: Array<Company>;
};

export type Company_Aggregate_Fields = {
  __typename?: 'company_aggregate_fields';
  avg?: Maybe<Company_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Company_Max_Fields>;
  min?: Maybe<Company_Min_Fields>;
  stddev?: Maybe<Company_Stddev_Fields>;
  stddev_pop?: Maybe<Company_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Company_Stddev_Samp_Fields>;
  sum?: Maybe<Company_Sum_Fields>;
  var_pop?: Maybe<Company_Var_Pop_Fields>;
  var_samp?: Maybe<Company_Var_Samp_Fields>;
  variance?: Maybe<Company_Variance_Fields>;
};

export type Company_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Company_Aggregate_Order_By = {
  avg?: InputMaybe<Company_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Company_Max_Order_By>;
  min?: InputMaybe<Company_Min_Order_By>;
  stddev?: InputMaybe<Company_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Company_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Company_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Company_Sum_Order_By>;
  var_pop?: InputMaybe<Company_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Company_Var_Samp_Order_By>;
  variance?: InputMaybe<Company_Variance_Order_By>;
};

export type Company_Arr_Rel_Insert_Input = {
  data: Array<Company_Insert_Input>;
  on_conflict?: InputMaybe<Company_On_Conflict>;
};

export type Company_Avg_Fields = {
  __typename?: 'company_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Company_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Company_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Company_Bool_Exp>>>;
  _not?: InputMaybe<Company_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Company_Bool_Exp>>>;
  app_customizations?: InputMaybe<App_Customization_Bool_Exp>;
  coupons?: InputMaybe<Coupon_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  gender?: InputMaybe<String_Comparison_Exp>;
  health_carers?: InputMaybe<Company_Health_Carer_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  users?: InputMaybe<Company_User_Bool_Exp>;
};

export enum Company_Constraint {
  CompanyPkey = 'company_pkey',
}

export type Company_Health_Carer = {
  __typename?: 'company_health_carer';
  company: Company;
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  health_carer: Health_Carer;
  health_carer_id: Scalars['String'];
  kind?: Maybe<Company_Health_Carer_Kind_Enum_Enum>;
  updated_at: Scalars['timestamptz'];
};

export type Company_Health_Carer_Aggregate = {
  __typename?: 'company_health_carer_aggregate';
  aggregate?: Maybe<Company_Health_Carer_Aggregate_Fields>;
  nodes: Array<Company_Health_Carer>;
};

export type Company_Health_Carer_Aggregate_Fields = {
  __typename?: 'company_health_carer_aggregate_fields';
  avg?: Maybe<Company_Health_Carer_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Company_Health_Carer_Max_Fields>;
  min?: Maybe<Company_Health_Carer_Min_Fields>;
  stddev?: Maybe<Company_Health_Carer_Stddev_Fields>;
  stddev_pop?: Maybe<Company_Health_Carer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Company_Health_Carer_Stddev_Samp_Fields>;
  sum?: Maybe<Company_Health_Carer_Sum_Fields>;
  var_pop?: Maybe<Company_Health_Carer_Var_Pop_Fields>;
  var_samp?: Maybe<Company_Health_Carer_Var_Samp_Fields>;
  variance?: Maybe<Company_Health_Carer_Variance_Fields>;
};

export type Company_Health_Carer_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Health_Carer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Company_Health_Carer_Aggregate_Order_By = {
  avg?: InputMaybe<Company_Health_Carer_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Company_Health_Carer_Max_Order_By>;
  min?: InputMaybe<Company_Health_Carer_Min_Order_By>;
  stddev?: InputMaybe<Company_Health_Carer_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Company_Health_Carer_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Company_Health_Carer_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Company_Health_Carer_Sum_Order_By>;
  var_pop?: InputMaybe<Company_Health_Carer_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Company_Health_Carer_Var_Samp_Order_By>;
  variance?: InputMaybe<Company_Health_Carer_Variance_Order_By>;
};

export type Company_Health_Carer_Arr_Rel_Insert_Input = {
  data: Array<Company_Health_Carer_Insert_Input>;
  on_conflict?: InputMaybe<Company_Health_Carer_On_Conflict>;
};

export type Company_Health_Carer_Avg_Fields = {
  __typename?: 'company_health_carer_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
};

export type Company_Health_Carer_Avg_Order_By = {
  company_id?: InputMaybe<Order_By>;
};

export type Company_Health_Carer_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Company_Health_Carer_Bool_Exp>>>;
  _not?: InputMaybe<Company_Health_Carer_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Company_Health_Carer_Bool_Exp>>>;
  company?: InputMaybe<Company_Bool_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  health_carer?: InputMaybe<Health_Carer_Bool_Exp>;
  health_carer_id?: InputMaybe<String_Comparison_Exp>;
  kind?: InputMaybe<Company_Health_Carer_Kind_Enum_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

export enum Company_Health_Carer_Constraint {
  CompanyHealthCarerPkey = 'company_health_carer_pkey',
}

export type Company_Health_Carer_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
};

export type Company_Health_Carer_Insert_Input = {
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  health_carer?: InputMaybe<Health_Carer_Obj_Rel_Insert_Input>;
  health_carer_id?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<Company_Health_Carer_Kind_Enum_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Company_Health_Carer_Kind_Enum = {
  __typename?: 'company_health_carer_kind_enum';
  comment: Scalars['String'];
  text: Scalars['String'];
};

export type Company_Health_Carer_Kind_Enum_Aggregate = {
  __typename?: 'company_health_carer_kind_enum_aggregate';
  aggregate?: Maybe<Company_Health_Carer_Kind_Enum_Aggregate_Fields>;
  nodes: Array<Company_Health_Carer_Kind_Enum>;
};

export type Company_Health_Carer_Kind_Enum_Aggregate_Fields = {
  __typename?: 'company_health_carer_kind_enum_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Company_Health_Carer_Kind_Enum_Max_Fields>;
  min?: Maybe<Company_Health_Carer_Kind_Enum_Min_Fields>;
};

export type Company_Health_Carer_Kind_Enum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Health_Carer_Kind_Enum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Company_Health_Carer_Kind_Enum_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Company_Health_Carer_Kind_Enum_Max_Order_By>;
  min?: InputMaybe<Company_Health_Carer_Kind_Enum_Min_Order_By>;
};

export type Company_Health_Carer_Kind_Enum_Arr_Rel_Insert_Input = {
  data: Array<Company_Health_Carer_Kind_Enum_Insert_Input>;
  on_conflict?: InputMaybe<Company_Health_Carer_Kind_Enum_On_Conflict>;
};

export type Company_Health_Carer_Kind_Enum_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Company_Health_Carer_Kind_Enum_Bool_Exp>>>;
  _not?: InputMaybe<Company_Health_Carer_Kind_Enum_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Company_Health_Carer_Kind_Enum_Bool_Exp>>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
};

export enum Company_Health_Carer_Kind_Enum_Constraint {
  CompanyHealthCarerKindEnumPkey = 'company_health_carer_kind_enum_pkey',
}

export enum Company_Health_Carer_Kind_Enum_Enum {
  Admin = 'ADMIN',
  MainHcp = 'MAIN_HCP',
}

export type Company_Health_Carer_Kind_Enum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Company_Health_Carer_Kind_Enum_Enum>;
  _in?: InputMaybe<Array<Company_Health_Carer_Kind_Enum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Company_Health_Carer_Kind_Enum_Enum>;
  _nin?: InputMaybe<Array<Company_Health_Carer_Kind_Enum_Enum>>;
};

export type Company_Health_Carer_Kind_Enum_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type Company_Health_Carer_Kind_Enum_Max_Fields = {
  __typename?: 'company_health_carer_kind_enum_max_fields';
  comment?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type Company_Health_Carer_Kind_Enum_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Company_Health_Carer_Kind_Enum_Min_Fields = {
  __typename?: 'company_health_carer_kind_enum_min_fields';
  comment?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type Company_Health_Carer_Kind_Enum_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Company_Health_Carer_Kind_Enum_Mutation_Response = {
  __typename?: 'company_health_carer_kind_enum_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Company_Health_Carer_Kind_Enum>;
};

export type Company_Health_Carer_Kind_Enum_Obj_Rel_Insert_Input = {
  data: Company_Health_Carer_Kind_Enum_Insert_Input;
  on_conflict?: InputMaybe<Company_Health_Carer_Kind_Enum_On_Conflict>;
};

export type Company_Health_Carer_Kind_Enum_On_Conflict = {
  constraint: Company_Health_Carer_Kind_Enum_Constraint;
  update_columns: Array<Company_Health_Carer_Kind_Enum_Update_Column>;
  where?: InputMaybe<Company_Health_Carer_Kind_Enum_Bool_Exp>;
};

export type Company_Health_Carer_Kind_Enum_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Company_Health_Carer_Kind_Enum_Pk_Columns_Input = {
  text: Scalars['String'];
};

export enum Company_Health_Carer_Kind_Enum_Select_Column {
  Comment = 'comment',
  Text = 'text',
}

export type Company_Health_Carer_Kind_Enum_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export enum Company_Health_Carer_Kind_Enum_Update_Column {
  Comment = 'comment',
  Text = 'text',
}

export type Company_Health_Carer_Max_Fields = {
  __typename?: 'company_health_carer_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  health_carer_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type Company_Health_Carer_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  health_carer_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type Company_Health_Carer_Min_Fields = {
  __typename?: 'company_health_carer_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  health_carer_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type Company_Health_Carer_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  health_carer_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type Company_Health_Carer_Mutation_Response = {
  __typename?: 'company_health_carer_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Company_Health_Carer>;
};

export type Company_Health_Carer_Obj_Rel_Insert_Input = {
  data: Company_Health_Carer_Insert_Input;
  on_conflict?: InputMaybe<Company_Health_Carer_On_Conflict>;
};

export type Company_Health_Carer_On_Conflict = {
  constraint: Company_Health_Carer_Constraint;
  update_columns: Array<Company_Health_Carer_Update_Column>;
  where?: InputMaybe<Company_Health_Carer_Bool_Exp>;
};

export type Company_Health_Carer_Order_By = {
  company?: InputMaybe<Company_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  health_carer?: InputMaybe<Health_Carer_Order_By>;
  health_carer_id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type Company_Health_Carer_Pk_Columns_Input = {
  company_id: Scalars['Int'];
  health_carer_id: Scalars['String'];
};

export enum Company_Health_Carer_Select_Column {
  CompanyId = 'company_id',
  CreatedAt = 'created_at',
  HealthCarerId = 'health_carer_id',
  Kind = 'kind',
  UpdatedAt = 'updated_at',
}

export type Company_Health_Carer_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  health_carer_id?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<Company_Health_Carer_Kind_Enum_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Company_Health_Carer_Stddev_Fields = {
  __typename?: 'company_health_carer_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
};

export type Company_Health_Carer_Stddev_Order_By = {
  company_id?: InputMaybe<Order_By>;
};

export type Company_Health_Carer_Stddev_Pop_Fields = {
  __typename?: 'company_health_carer_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
};

export type Company_Health_Carer_Stddev_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
};

export type Company_Health_Carer_Stddev_Samp_Fields = {
  __typename?: 'company_health_carer_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
};

export type Company_Health_Carer_Stddev_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
};

export type Company_Health_Carer_Sum_Fields = {
  __typename?: 'company_health_carer_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
};

export type Company_Health_Carer_Sum_Order_By = {
  company_id?: InputMaybe<Order_By>;
};

export enum Company_Health_Carer_Update_Column {
  CompanyId = 'company_id',
  CreatedAt = 'created_at',
  HealthCarerId = 'health_carer_id',
  Kind = 'kind',
  UpdatedAt = 'updated_at',
}

export type Company_Health_Carer_Var_Pop_Fields = {
  __typename?: 'company_health_carer_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
};

export type Company_Health_Carer_Var_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
};

export type Company_Health_Carer_Var_Samp_Fields = {
  __typename?: 'company_health_carer_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
};

export type Company_Health_Carer_Var_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
};

export type Company_Health_Carer_Variance_Fields = {
  __typename?: 'company_health_carer_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
};

export type Company_Health_Carer_Variance_Order_By = {
  company_id?: InputMaybe<Order_By>;
};

export type Company_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

export type Company_Insert_Input = {
  app_customizations?: InputMaybe<App_Customization_Arr_Rel_Insert_Input>;
  coupons?: InputMaybe<Coupon_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  gender?: InputMaybe<Scalars['String']>;
  health_carers?: InputMaybe<Company_Health_Carer_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  users?: InputMaybe<Company_User_Arr_Rel_Insert_Input>;
};

export type Company_Max_Fields = {
  __typename?: 'company_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type Company_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type Company_Min_Fields = {
  __typename?: 'company_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type Company_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type Company_Mutation_Response = {
  __typename?: 'company_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Company>;
};

export type Company_Obj_Rel_Insert_Input = {
  data: Company_Insert_Input;
  on_conflict?: InputMaybe<Company_On_Conflict>;
};

export type Company_On_Conflict = {
  constraint: Company_Constraint;
  update_columns: Array<Company_Update_Column>;
  where?: InputMaybe<Company_Bool_Exp>;
};

export type Company_Order_By = {
  app_customizations_aggregate?: InputMaybe<App_Customization_Aggregate_Order_By>;
  coupons_aggregate?: InputMaybe<Coupon_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  health_carers_aggregate?: InputMaybe<Company_Health_Carer_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  users_aggregate?: InputMaybe<Company_User_Aggregate_Order_By>;
};

export type Company_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export enum Company_Select_Column {
  CreatedAt = 'created_at',
  Gender = 'gender',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at',
}

export type Company_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Company_Stddev_Fields = {
  __typename?: 'company_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Company_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Company_Stddev_Pop_Fields = {
  __typename?: 'company_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Company_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Company_Stddev_Samp_Fields = {
  __typename?: 'company_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Company_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Company_Sum_Fields = {
  __typename?: 'company_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

export type Company_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

export enum Company_Update_Column {
  CreatedAt = 'created_at',
  Gender = 'gender',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at',
}

export type Company_User = {
  __typename?: 'company_user';
  company?: Maybe<Company>;
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  user?: Maybe<User>;
  user_id: Scalars['String'];
};

export type Company_User_Aggregate = {
  __typename?: 'company_user_aggregate';
  aggregate?: Maybe<Company_User_Aggregate_Fields>;
  nodes: Array<Company_User>;
};

export type Company_User_Aggregate_Fields = {
  __typename?: 'company_user_aggregate_fields';
  avg?: Maybe<Company_User_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Company_User_Max_Fields>;
  min?: Maybe<Company_User_Min_Fields>;
  stddev?: Maybe<Company_User_Stddev_Fields>;
  stddev_pop?: Maybe<Company_User_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Company_User_Stddev_Samp_Fields>;
  sum?: Maybe<Company_User_Sum_Fields>;
  var_pop?: Maybe<Company_User_Var_Pop_Fields>;
  var_samp?: Maybe<Company_User_Var_Samp_Fields>;
  variance?: Maybe<Company_User_Variance_Fields>;
};

export type Company_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Company_User_Aggregate_Order_By = {
  avg?: InputMaybe<Company_User_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Company_User_Max_Order_By>;
  min?: InputMaybe<Company_User_Min_Order_By>;
  stddev?: InputMaybe<Company_User_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Company_User_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Company_User_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Company_User_Sum_Order_By>;
  var_pop?: InputMaybe<Company_User_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Company_User_Var_Samp_Order_By>;
  variance?: InputMaybe<Company_User_Variance_Order_By>;
};

export type Company_User_Arr_Rel_Insert_Input = {
  data: Array<Company_User_Insert_Input>;
  on_conflict?: InputMaybe<Company_User_On_Conflict>;
};

export type Company_User_Avg_Fields = {
  __typename?: 'company_user_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Company_User_Avg_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type Company_User_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Company_User_Bool_Exp>>>;
  _not?: InputMaybe<Company_User_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Company_User_Bool_Exp>>>;
  company?: InputMaybe<Company_Bool_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

export enum Company_User_Constraint {
  CompanyUserIdKey = 'company_user_id_key',
  CompanyUserPkey = 'company_user_pkey',
}

export type Company_User_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

export type Company_User_Insert_Input = {
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Company_User_Max_Fields = {
  __typename?: 'company_user_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Company_User_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Company_User_Min_Fields = {
  __typename?: 'company_user_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Company_User_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Company_User_Mutation_Response = {
  __typename?: 'company_user_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Company_User>;
};

export type Company_User_Obj_Rel_Insert_Input = {
  data: Company_User_Insert_Input;
  on_conflict?: InputMaybe<Company_User_On_Conflict>;
};

export type Company_User_On_Conflict = {
  constraint: Company_User_Constraint;
  update_columns: Array<Company_User_Update_Column>;
  where?: InputMaybe<Company_User_Bool_Exp>;
};

export type Company_User_Order_By = {
  company?: InputMaybe<Company_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Company_User_Pk_Columns_Input = {
  company_id: Scalars['Int'];
  user_id: Scalars['String'];
};

export enum Company_User_Select_Column {
  CompanyId = 'company_id',
  CreatedAt = 'created_at',
  Id = 'id',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Company_User_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Company_User_Stddev_Fields = {
  __typename?: 'company_user_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Company_User_Stddev_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type Company_User_Stddev_Pop_Fields = {
  __typename?: 'company_user_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Company_User_Stddev_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type Company_User_Stddev_Samp_Fields = {
  __typename?: 'company_user_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Company_User_Stddev_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type Company_User_Sum_Fields = {
  __typename?: 'company_user_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

export type Company_User_Sum_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export enum Company_User_Update_Column {
  CompanyId = 'company_id',
  CreatedAt = 'created_at',
  Id = 'id',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Company_User_Var_Pop_Fields = {
  __typename?: 'company_user_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Company_User_Var_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type Company_User_Var_Samp_Fields = {
  __typename?: 'company_user_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Company_User_Var_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type Company_User_Variance_Fields = {
  __typename?: 'company_user_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Company_User_Variance_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type Company_Var_Pop_Fields = {
  __typename?: 'company_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Company_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Company_Var_Samp_Fields = {
  __typename?: 'company_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Company_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Company_Variance_Fields = {
  __typename?: 'company_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Company_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Coupon = {
  __typename?: 'coupon';
  active: Scalars['Boolean'];
  code: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  coupon_users: Array<User_Coupon>;
  coupon_users_aggregate: User_Coupon_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  expiration_date?: Maybe<Scalars['date']>;
  extra_data?: Maybe<Scalars['jsonb']>;
  hcp?: Maybe<Health_Carer>;
  id: Scalars['bigint'];
  kind?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owner_company?: Maybe<Scalars['Int']>;
  owner_hcp?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['numeric']>;
  redeem_message: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_key_value?: Maybe<Scalars['jsonb']>;
  value?: Maybe<Scalars['String']>;
};

export type CouponCoupon_UsersArgs = {
  distinct_on?: InputMaybe<Array<User_Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Coupon_Order_By>>;
  where?: InputMaybe<User_Coupon_Bool_Exp>;
};

export type CouponCoupon_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Coupon_Order_By>>;
  where?: InputMaybe<User_Coupon_Bool_Exp>;
};

export type CouponExtra_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

export type CouponUser_Key_ValueArgs = {
  path?: InputMaybe<Scalars['String']>;
};

export type Coupon_Aggregate = {
  __typename?: 'coupon_aggregate';
  aggregate?: Maybe<Coupon_Aggregate_Fields>;
  nodes: Array<Coupon>;
};

export type Coupon_Aggregate_Fields = {
  __typename?: 'coupon_aggregate_fields';
  avg?: Maybe<Coupon_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Coupon_Max_Fields>;
  min?: Maybe<Coupon_Min_Fields>;
  stddev?: Maybe<Coupon_Stddev_Fields>;
  stddev_pop?: Maybe<Coupon_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Coupon_Stddev_Samp_Fields>;
  sum?: Maybe<Coupon_Sum_Fields>;
  var_pop?: Maybe<Coupon_Var_Pop_Fields>;
  var_samp?: Maybe<Coupon_Var_Samp_Fields>;
  variance?: Maybe<Coupon_Variance_Fields>;
};

export type Coupon_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Coupon_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Coupon_Aggregate_Order_By = {
  avg?: InputMaybe<Coupon_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Coupon_Max_Order_By>;
  min?: InputMaybe<Coupon_Min_Order_By>;
  stddev?: InputMaybe<Coupon_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Coupon_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Coupon_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Coupon_Sum_Order_By>;
  var_pop?: InputMaybe<Coupon_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Coupon_Var_Samp_Order_By>;
  variance?: InputMaybe<Coupon_Variance_Order_By>;
};

export type Coupon_Append_Input = {
  extra_data?: InputMaybe<Scalars['jsonb']>;
  user_key_value?: InputMaybe<Scalars['jsonb']>;
};

export type Coupon_Arr_Rel_Insert_Input = {
  data: Array<Coupon_Insert_Input>;
  on_conflict?: InputMaybe<Coupon_On_Conflict>;
};

export type Coupon_Avg_Fields = {
  __typename?: 'coupon_avg_fields';
  id?: Maybe<Scalars['Float']>;
  owner_company?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type Coupon_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  owner_company?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

export type Coupon_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Coupon_Bool_Exp>>>;
  _not?: InputMaybe<Coupon_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Coupon_Bool_Exp>>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  code?: InputMaybe<String_Comparison_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  company?: InputMaybe<Company_Bool_Exp>;
  coupon_users?: InputMaybe<User_Coupon_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  expiration_date?: InputMaybe<Date_Comparison_Exp>;
  extra_data?: InputMaybe<Jsonb_Comparison_Exp>;
  hcp?: InputMaybe<Health_Carer_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  kind?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  owner_company?: InputMaybe<Int_Comparison_Exp>;
  owner_hcp?: InputMaybe<String_Comparison_Exp>;
  quantity?: InputMaybe<Numeric_Comparison_Exp>;
  redeem_message?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_key_value?: InputMaybe<Jsonb_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Coupon_Constraint {
  CouponCodeKey = 'coupon_code_key',
  CouponIdKey = 'coupon_id_key',
  CouponPkey = 'coupon_pkey',
}

export type Coupon_Delete_At_Path_Input = {
  extra_data?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  user_key_value?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Coupon_Delete_Elem_Input = {
  extra_data?: InputMaybe<Scalars['Int']>;
  user_key_value?: InputMaybe<Scalars['Int']>;
};

export type Coupon_Delete_Key_Input = {
  extra_data?: InputMaybe<Scalars['String']>;
  user_key_value?: InputMaybe<Scalars['String']>;
};

export type Coupon_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  owner_company?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['numeric']>;
};

export type Coupon_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  coupon_users?: InputMaybe<User_Coupon_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  expiration_date?: InputMaybe<Scalars['date']>;
  extra_data?: InputMaybe<Scalars['jsonb']>;
  hcp?: InputMaybe<Health_Carer_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  kind?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  owner_company?: InputMaybe<Scalars['Int']>;
  owner_hcp?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  redeem_message?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_key_value?: InputMaybe<Scalars['jsonb']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Coupon_Max_Fields = {
  __typename?: 'coupon_max_fields';
  code?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expiration_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  kind?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  owner_company?: Maybe<Scalars['Int']>;
  owner_hcp?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['numeric']>;
  redeem_message?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

export type Coupon_Max_Order_By = {
  code?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owner_company?: InputMaybe<Order_By>;
  owner_hcp?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  redeem_message?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

export type Coupon_Min_Fields = {
  __typename?: 'coupon_min_fields';
  code?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expiration_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  kind?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  owner_company?: Maybe<Scalars['Int']>;
  owner_hcp?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['numeric']>;
  redeem_message?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

export type Coupon_Min_Order_By = {
  code?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owner_company?: InputMaybe<Order_By>;
  owner_hcp?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  redeem_message?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

export type Coupon_Mutation_Response = {
  __typename?: 'coupon_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Coupon>;
};

export type Coupon_Obj_Rel_Insert_Input = {
  data: Coupon_Insert_Input;
  on_conflict?: InputMaybe<Coupon_On_Conflict>;
};

export type Coupon_On_Conflict = {
  constraint: Coupon_Constraint;
  update_columns: Array<Coupon_Update_Column>;
  where?: InputMaybe<Coupon_Bool_Exp>;
};

export type Coupon_Order_By = {
  active?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  company?: InputMaybe<Company_Order_By>;
  coupon_users_aggregate?: InputMaybe<User_Coupon_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  extra_data?: InputMaybe<Order_By>;
  hcp?: InputMaybe<Health_Carer_Order_By>;
  id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owner_company?: InputMaybe<Order_By>;
  owner_hcp?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  redeem_message?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_key_value?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

export type Coupon_Pk_Columns_Input = {
  code: Scalars['String'];
};

export type Coupon_Prepend_Input = {
  extra_data?: InputMaybe<Scalars['jsonb']>;
  user_key_value?: InputMaybe<Scalars['jsonb']>;
};

export enum Coupon_Select_Column {
  Active = 'active',
  Code = 'code',
  Comment = 'comment',
  CreatedAt = 'created_at',
  ExpirationDate = 'expiration_date',
  ExtraData = 'extra_data',
  Id = 'id',
  Kind = 'kind',
  Name = 'name',
  OwnerCompany = 'owner_company',
  OwnerHcp = 'owner_hcp',
  Quantity = 'quantity',
  RedeemMessage = 'redeem_message',
  UpdatedAt = 'updated_at',
  UserKeyValue = 'user_key_value',
  Value = 'value',
}

export type Coupon_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  expiration_date?: InputMaybe<Scalars['date']>;
  extra_data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  kind?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  owner_company?: InputMaybe<Scalars['Int']>;
  owner_hcp?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  redeem_message?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_key_value?: InputMaybe<Scalars['jsonb']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Coupon_Stddev_Fields = {
  __typename?: 'coupon_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  owner_company?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type Coupon_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  owner_company?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

export type Coupon_Stddev_Pop_Fields = {
  __typename?: 'coupon_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  owner_company?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type Coupon_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  owner_company?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

export type Coupon_Stddev_Samp_Fields = {
  __typename?: 'coupon_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  owner_company?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type Coupon_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  owner_company?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

export type Coupon_Sum_Fields = {
  __typename?: 'coupon_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  owner_company?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['numeric']>;
};

export type Coupon_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  owner_company?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

export enum Coupon_Update_Column {
  Active = 'active',
  Code = 'code',
  Comment = 'comment',
  CreatedAt = 'created_at',
  ExpirationDate = 'expiration_date',
  ExtraData = 'extra_data',
  Id = 'id',
  Kind = 'kind',
  Name = 'name',
  OwnerCompany = 'owner_company',
  OwnerHcp = 'owner_hcp',
  Quantity = 'quantity',
  RedeemMessage = 'redeem_message',
  UpdatedAt = 'updated_at',
  UserKeyValue = 'user_key_value',
  Value = 'value',
}

export type Coupon_Var_Pop_Fields = {
  __typename?: 'coupon_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  owner_company?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type Coupon_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  owner_company?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

export type Coupon_Var_Samp_Fields = {
  __typename?: 'coupon_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  owner_company?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type Coupon_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  owner_company?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

export type Coupon_Variance_Fields = {
  __typename?: 'coupon_variance_fields';
  id?: Maybe<Scalars['Float']>;
  owner_company?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type Coupon_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  owner_company?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

export type Crm = {
  __typename?: 'crm';
  created_at: Scalars['timestamptz'];
  identification: Scalars['String'];
  name: Scalars['String'];
  specialty: Scalars['String'];
  state: Scalars['String'];
  status: Scalars['String'];
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

export type Crm_Aggregate = {
  __typename?: 'crm_aggregate';
  aggregate?: Maybe<Crm_Aggregate_Fields>;
  nodes: Array<Crm>;
};

export type Crm_Aggregate_Fields = {
  __typename?: 'crm_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Crm_Max_Fields>;
  min?: Maybe<Crm_Min_Fields>;
};

export type Crm_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Crm_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Crm_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Crm_Max_Order_By>;
  min?: InputMaybe<Crm_Min_Order_By>;
};

export type Crm_Arr_Rel_Insert_Input = {
  data: Array<Crm_Insert_Input>;
  on_conflict?: InputMaybe<Crm_On_Conflict>;
};

export type Crm_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Crm_Bool_Exp>>>;
  _not?: InputMaybe<Crm_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Crm_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  identification?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  specialty?: InputMaybe<String_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

export enum Crm_Constraint {
  CrmPkey = 'crm_pkey',
}

export type Crm_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  identification?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  specialty?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Crm_Max_Fields = {
  __typename?: 'crm_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  identification?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  specialty?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type Crm_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  identification?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  specialty?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type Crm_Min_Fields = {
  __typename?: 'crm_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  identification?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  specialty?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type Crm_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  identification?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  specialty?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type Crm_Mutation_Response = {
  __typename?: 'crm_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Crm>;
};

export type Crm_Obj_Rel_Insert_Input = {
  data: Crm_Insert_Input;
  on_conflict?: InputMaybe<Crm_On_Conflict>;
};

export type Crm_On_Conflict = {
  constraint: Crm_Constraint;
  update_columns: Array<Crm_Update_Column>;
  where?: InputMaybe<Crm_Bool_Exp>;
};

export type Crm_Order_By = {
  created_at?: InputMaybe<Order_By>;
  identification?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  specialty?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type Crm_Pk_Columns_Input = {
  identification: Scalars['String'];
  state: Scalars['String'];
};

export enum Crm_Select_Column {
  CreatedAt = 'created_at',
  Identification = 'identification',
  Name = 'name',
  Specialty = 'specialty',
  State = 'state',
  Status = 'status',
  Type = 'type',
  UpdatedAt = 'updated_at',
}

export type Crm_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  identification?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  specialty?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export enum Crm_Update_Column {
  CreatedAt = 'created_at',
  Identification = 'identification',
  Name = 'name',
  Specialty = 'specialty',
  State = 'state',
  Status = 'status',
  Type = 'type',
  UpdatedAt = 'updated_at',
}

export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

export type Dream = {
  __typename?: 'dream';
  created_at: Scalars['timestamptz'];
  date: Scalars['date'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  remember: Scalars['Boolean'];
  updated_at: Scalars['timestamptz'];
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['String']>;
};

export type Dream_Aggregate = {
  __typename?: 'dream_aggregate';
  aggregate?: Maybe<Dream_Aggregate_Fields>;
  nodes: Array<Dream>;
};

export type Dream_Aggregate_Fields = {
  __typename?: 'dream_aggregate_fields';
  avg?: Maybe<Dream_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Dream_Max_Fields>;
  min?: Maybe<Dream_Min_Fields>;
  stddev?: Maybe<Dream_Stddev_Fields>;
  stddev_pop?: Maybe<Dream_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dream_Stddev_Samp_Fields>;
  sum?: Maybe<Dream_Sum_Fields>;
  var_pop?: Maybe<Dream_Var_Pop_Fields>;
  var_samp?: Maybe<Dream_Var_Samp_Fields>;
  variance?: Maybe<Dream_Variance_Fields>;
};

export type Dream_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dream_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Dream_Aggregate_Order_By = {
  avg?: InputMaybe<Dream_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Dream_Max_Order_By>;
  min?: InputMaybe<Dream_Min_Order_By>;
  stddev?: InputMaybe<Dream_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Dream_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Dream_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Dream_Sum_Order_By>;
  var_pop?: InputMaybe<Dream_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Dream_Var_Samp_Order_By>;
  variance?: InputMaybe<Dream_Variance_Order_By>;
};

export type Dream_Arr_Rel_Insert_Input = {
  data: Array<Dream_Insert_Input>;
  on_conflict?: InputMaybe<Dream_On_Conflict>;
};

export type Dream_Avg_Fields = {
  __typename?: 'dream_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Dream_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Dream_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Dream_Bool_Exp>>>;
  _not?: InputMaybe<Dream_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Dream_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  date?: InputMaybe<Date_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  remember?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

export enum Dream_Constraint {
  DreamPkey = 'dream_pkey',
}

export type Dream_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

export type Dream_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['date']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  remember?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Dream_Max_Fields = {
  __typename?: 'dream_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Dream_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Dream_Min_Fields = {
  __typename?: 'dream_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Dream_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Dream_Mutation_Response = {
  __typename?: 'dream_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Dream>;
};

export type Dream_Obj_Rel_Insert_Input = {
  data: Dream_Insert_Input;
  on_conflict?: InputMaybe<Dream_On_Conflict>;
};

export type Dream_On_Conflict = {
  constraint: Dream_Constraint;
  update_columns: Array<Dream_Update_Column>;
  where?: InputMaybe<Dream_Bool_Exp>;
};

export type Dream_Order_By = {
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  remember?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Dream_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export enum Dream_Select_Column {
  CreatedAt = 'created_at',
  Date = 'date',
  Description = 'description',
  Id = 'id',
  Remember = 'remember',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Dream_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['date']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  remember?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Dream_Stddev_Fields = {
  __typename?: 'dream_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Dream_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Dream_Stddev_Pop_Fields = {
  __typename?: 'dream_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Dream_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Dream_Stddev_Samp_Fields = {
  __typename?: 'dream_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Dream_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Dream_Sum_Fields = {
  __typename?: 'dream_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

export type Dream_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

export enum Dream_Update_Column {
  CreatedAt = 'created_at',
  Date = 'date',
  Description = 'description',
  Id = 'id',
  Remember = 'remember',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Dream_Var_Pop_Fields = {
  __typename?: 'dream_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Dream_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Dream_Var_Samp_Fields = {
  __typename?: 'dream_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Dream_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Dream_Variance_Fields = {
  __typename?: 'dream_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Dream_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Eficiency = {
  __typename?: 'eficiency';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  tib: Scalars['Float'];
  tst: Scalars['Float'];
  user_id: Scalars['String'];
};

export type Eficiency_Aggregate = {
  __typename?: 'eficiency_aggregate';
  aggregate?: Maybe<Eficiency_Aggregate_Fields>;
  nodes: Array<Eficiency>;
};

export type Eficiency_Aggregate_Fields = {
  __typename?: 'eficiency_aggregate_fields';
  avg?: Maybe<Eficiency_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Eficiency_Max_Fields>;
  min?: Maybe<Eficiency_Min_Fields>;
  stddev?: Maybe<Eficiency_Stddev_Fields>;
  stddev_pop?: Maybe<Eficiency_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Eficiency_Stddev_Samp_Fields>;
  sum?: Maybe<Eficiency_Sum_Fields>;
  var_pop?: Maybe<Eficiency_Var_Pop_Fields>;
  var_samp?: Maybe<Eficiency_Var_Samp_Fields>;
  variance?: Maybe<Eficiency_Variance_Fields>;
};

export type Eficiency_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Eficiency_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Eficiency_Aggregate_Order_By = {
  avg?: InputMaybe<Eficiency_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Eficiency_Max_Order_By>;
  min?: InputMaybe<Eficiency_Min_Order_By>;
  stddev?: InputMaybe<Eficiency_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Eficiency_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Eficiency_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Eficiency_Sum_Order_By>;
  var_pop?: InputMaybe<Eficiency_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Eficiency_Var_Samp_Order_By>;
  variance?: InputMaybe<Eficiency_Variance_Order_By>;
};

export type Eficiency_Arr_Rel_Insert_Input = {
  data: Array<Eficiency_Insert_Input>;
  on_conflict?: InputMaybe<Eficiency_On_Conflict>;
};

export type Eficiency_Avg_Fields = {
  __typename?: 'eficiency_avg_fields';
  id?: Maybe<Scalars['Float']>;
  tib?: Maybe<Scalars['Float']>;
  tst?: Maybe<Scalars['Float']>;
};

export type Eficiency_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  tib?: InputMaybe<Order_By>;
  tst?: InputMaybe<Order_By>;
};

export type Eficiency_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Eficiency_Bool_Exp>>>;
  _not?: InputMaybe<Eficiency_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Eficiency_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  tib?: InputMaybe<Float_Comparison_Exp>;
  tst?: InputMaybe<Float_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

export enum Eficiency_Constraint {
  EficiencyPkey = 'eficiency_pkey',
}

export type Eficiency_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  tib?: InputMaybe<Scalars['Float']>;
  tst?: InputMaybe<Scalars['Float']>;
};

export type Eficiency_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  tib?: InputMaybe<Scalars['Float']>;
  tst?: InputMaybe<Scalars['Float']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Eficiency_Max_Fields = {
  __typename?: 'eficiency_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  tib?: Maybe<Scalars['Float']>;
  tst?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Eficiency_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tib?: InputMaybe<Order_By>;
  tst?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Eficiency_Min_Fields = {
  __typename?: 'eficiency_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  tib?: Maybe<Scalars['Float']>;
  tst?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Eficiency_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tib?: InputMaybe<Order_By>;
  tst?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Eficiency_Mutation_Response = {
  __typename?: 'eficiency_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Eficiency>;
};

export type Eficiency_Obj_Rel_Insert_Input = {
  data: Eficiency_Insert_Input;
  on_conflict?: InputMaybe<Eficiency_On_Conflict>;
};

export type Eficiency_On_Conflict = {
  constraint: Eficiency_Constraint;
  update_columns: Array<Eficiency_Update_Column>;
  where?: InputMaybe<Eficiency_Bool_Exp>;
};

export type Eficiency_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tib?: InputMaybe<Order_By>;
  tst?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Eficiency_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export enum Eficiency_Select_Column {
  CreatedAt = 'created_at',
  Id = 'id',
  Tib = 'tib',
  Tst = 'tst',
  UserId = 'user_id',
}

export type Eficiency_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  tib?: InputMaybe<Scalars['Float']>;
  tst?: InputMaybe<Scalars['Float']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Eficiency_Stddev_Fields = {
  __typename?: 'eficiency_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  tib?: Maybe<Scalars['Float']>;
  tst?: Maybe<Scalars['Float']>;
};

export type Eficiency_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  tib?: InputMaybe<Order_By>;
  tst?: InputMaybe<Order_By>;
};

export type Eficiency_Stddev_Pop_Fields = {
  __typename?: 'eficiency_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  tib?: Maybe<Scalars['Float']>;
  tst?: Maybe<Scalars['Float']>;
};

export type Eficiency_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  tib?: InputMaybe<Order_By>;
  tst?: InputMaybe<Order_By>;
};

export type Eficiency_Stddev_Samp_Fields = {
  __typename?: 'eficiency_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  tib?: Maybe<Scalars['Float']>;
  tst?: Maybe<Scalars['Float']>;
};

export type Eficiency_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  tib?: InputMaybe<Order_By>;
  tst?: InputMaybe<Order_By>;
};

export type Eficiency_Sum_Fields = {
  __typename?: 'eficiency_sum_fields';
  id?: Maybe<Scalars['Int']>;
  tib?: Maybe<Scalars['Float']>;
  tst?: Maybe<Scalars['Float']>;
};

export type Eficiency_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  tib?: InputMaybe<Order_By>;
  tst?: InputMaybe<Order_By>;
};

export enum Eficiency_Update_Column {
  CreatedAt = 'created_at',
  Id = 'id',
  Tib = 'tib',
  Tst = 'tst',
  UserId = 'user_id',
}

export type Eficiency_Var_Pop_Fields = {
  __typename?: 'eficiency_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  tib?: Maybe<Scalars['Float']>;
  tst?: Maybe<Scalars['Float']>;
};

export type Eficiency_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  tib?: InputMaybe<Order_By>;
  tst?: InputMaybe<Order_By>;
};

export type Eficiency_Var_Samp_Fields = {
  __typename?: 'eficiency_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  tib?: Maybe<Scalars['Float']>;
  tst?: Maybe<Scalars['Float']>;
};

export type Eficiency_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  tib?: InputMaybe<Order_By>;
  tst?: InputMaybe<Order_By>;
};

export type Eficiency_Variance_Fields = {
  __typename?: 'eficiency_variance_fields';
  id?: Maybe<Scalars['Float']>;
  tib?: Maybe<Scalars['Float']>;
  tst?: Maybe<Scalars['Float']>;
};

export type Eficiency_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  tib?: InputMaybe<Order_By>;
  tst?: InputMaybe<Order_By>;
};

export type Epworth = {
  __typename?: 'epworth';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  q1: Scalars['smallint'];
  q2: Scalars['smallint'];
  q3: Scalars['smallint'];
  q4: Scalars['smallint'];
  q5: Scalars['smallint'];
  q6: Scalars['smallint'];
  q7: Scalars['smallint'];
  q8: Scalars['smallint'];
  updated_at: Scalars['timestamptz'];
  user: User;
  user_id: Scalars['String'];
};

export type Epworth_Aggregate = {
  __typename?: 'epworth_aggregate';
  aggregate?: Maybe<Epworth_Aggregate_Fields>;
  nodes: Array<Epworth>;
};

export type Epworth_Aggregate_Fields = {
  __typename?: 'epworth_aggregate_fields';
  avg?: Maybe<Epworth_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Epworth_Max_Fields>;
  min?: Maybe<Epworth_Min_Fields>;
  stddev?: Maybe<Epworth_Stddev_Fields>;
  stddev_pop?: Maybe<Epworth_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Epworth_Stddev_Samp_Fields>;
  sum?: Maybe<Epworth_Sum_Fields>;
  var_pop?: Maybe<Epworth_Var_Pop_Fields>;
  var_samp?: Maybe<Epworth_Var_Samp_Fields>;
  variance?: Maybe<Epworth_Variance_Fields>;
};

export type Epworth_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Epworth_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Epworth_Aggregate_Order_By = {
  avg?: InputMaybe<Epworth_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Epworth_Max_Order_By>;
  min?: InputMaybe<Epworth_Min_Order_By>;
  stddev?: InputMaybe<Epworth_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Epworth_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Epworth_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Epworth_Sum_Order_By>;
  var_pop?: InputMaybe<Epworth_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Epworth_Var_Samp_Order_By>;
  variance?: InputMaybe<Epworth_Variance_Order_By>;
};

export type Epworth_Arr_Rel_Insert_Input = {
  data: Array<Epworth_Insert_Input>;
  on_conflict?: InputMaybe<Epworth_On_Conflict>;
};

export type Epworth_Avg_Fields = {
  __typename?: 'epworth_avg_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
};

export type Epworth_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
};

export type Epworth_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Epworth_Bool_Exp>>>;
  _not?: InputMaybe<Epworth_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Epworth_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  q1?: InputMaybe<Smallint_Comparison_Exp>;
  q2?: InputMaybe<Smallint_Comparison_Exp>;
  q3?: InputMaybe<Smallint_Comparison_Exp>;
  q4?: InputMaybe<Smallint_Comparison_Exp>;
  q5?: InputMaybe<Smallint_Comparison_Exp>;
  q6?: InputMaybe<Smallint_Comparison_Exp>;
  q7?: InputMaybe<Smallint_Comparison_Exp>;
  q8?: InputMaybe<Smallint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

export enum Epworth_Constraint {
  EpworthPkey = 'epworth_pkey',
}

export type Epworth_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['smallint']>;
  q2?: InputMaybe<Scalars['smallint']>;
  q3?: InputMaybe<Scalars['smallint']>;
  q4?: InputMaybe<Scalars['smallint']>;
  q5?: InputMaybe<Scalars['smallint']>;
  q6?: InputMaybe<Scalars['smallint']>;
  q7?: InputMaybe<Scalars['smallint']>;
  q8?: InputMaybe<Scalars['smallint']>;
};

export type Epworth_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['smallint']>;
  q2?: InputMaybe<Scalars['smallint']>;
  q3?: InputMaybe<Scalars['smallint']>;
  q4?: InputMaybe<Scalars['smallint']>;
  q5?: InputMaybe<Scalars['smallint']>;
  q6?: InputMaybe<Scalars['smallint']>;
  q7?: InputMaybe<Scalars['smallint']>;
  q8?: InputMaybe<Scalars['smallint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Epworth_Max_Fields = {
  __typename?: 'epworth_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['smallint']>;
  q2?: Maybe<Scalars['smallint']>;
  q3?: Maybe<Scalars['smallint']>;
  q4?: Maybe<Scalars['smallint']>;
  q5?: Maybe<Scalars['smallint']>;
  q6?: Maybe<Scalars['smallint']>;
  q7?: Maybe<Scalars['smallint']>;
  q8?: Maybe<Scalars['smallint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Epworth_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Epworth_Min_Fields = {
  __typename?: 'epworth_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['smallint']>;
  q2?: Maybe<Scalars['smallint']>;
  q3?: Maybe<Scalars['smallint']>;
  q4?: Maybe<Scalars['smallint']>;
  q5?: Maybe<Scalars['smallint']>;
  q6?: Maybe<Scalars['smallint']>;
  q7?: Maybe<Scalars['smallint']>;
  q8?: Maybe<Scalars['smallint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Epworth_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Epworth_Mutation_Response = {
  __typename?: 'epworth_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Epworth>;
};

export type Epworth_Obj_Rel_Insert_Input = {
  data: Epworth_Insert_Input;
  on_conflict?: InputMaybe<Epworth_On_Conflict>;
};

export type Epworth_On_Conflict = {
  constraint: Epworth_Constraint;
  update_columns: Array<Epworth_Update_Column>;
  where?: InputMaybe<Epworth_Bool_Exp>;
};

export type Epworth_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Epworth_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export enum Epworth_Select_Column {
  CreatedAt = 'created_at',
  Id = 'id',
  Q1 = 'q1',
  Q2 = 'q2',
  Q3 = 'q3',
  Q4 = 'q4',
  Q5 = 'q5',
  Q6 = 'q6',
  Q7 = 'q7',
  Q8 = 'q8',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Epworth_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['smallint']>;
  q2?: InputMaybe<Scalars['smallint']>;
  q3?: InputMaybe<Scalars['smallint']>;
  q4?: InputMaybe<Scalars['smallint']>;
  q5?: InputMaybe<Scalars['smallint']>;
  q6?: InputMaybe<Scalars['smallint']>;
  q7?: InputMaybe<Scalars['smallint']>;
  q8?: InputMaybe<Scalars['smallint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Epworth_Stddev_Fields = {
  __typename?: 'epworth_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
};

export type Epworth_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
};

export type Epworth_Stddev_Pop_Fields = {
  __typename?: 'epworth_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
};

export type Epworth_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
};

export type Epworth_Stddev_Samp_Fields = {
  __typename?: 'epworth_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
};

export type Epworth_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
};

export type Epworth_Sum_Fields = {
  __typename?: 'epworth_sum_fields';
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['smallint']>;
  q2?: Maybe<Scalars['smallint']>;
  q3?: Maybe<Scalars['smallint']>;
  q4?: Maybe<Scalars['smallint']>;
  q5?: Maybe<Scalars['smallint']>;
  q6?: Maybe<Scalars['smallint']>;
  q7?: Maybe<Scalars['smallint']>;
  q8?: Maybe<Scalars['smallint']>;
};

export type Epworth_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
};

export enum Epworth_Update_Column {
  CreatedAt = 'created_at',
  Id = 'id',
  Q1 = 'q1',
  Q2 = 'q2',
  Q3 = 'q3',
  Q4 = 'q4',
  Q5 = 'q5',
  Q6 = 'q6',
  Q7 = 'q7',
  Q8 = 'q8',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Epworth_Var_Pop_Fields = {
  __typename?: 'epworth_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
};

export type Epworth_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
};

export type Epworth_Var_Samp_Fields = {
  __typename?: 'epworth_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
};

export type Epworth_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
};

export type Epworth_Variance_Fields = {
  __typename?: 'epworth_variance_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
};

export type Epworth_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
};

export type Gad = {
  __typename?: 'gad';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  q1: Scalars['Int'];
  q2: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  user: User;
  user_id: Scalars['String'];
};

export type Gad7 = {
  __typename?: 'gad7';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  q1: Scalars['Int'];
  q2: Scalars['Int'];
  q3: Scalars['Int'];
  q4: Scalars['Int'];
  q5: Scalars['Int'];
  q6: Scalars['Int'];
  q7: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  user: User;
  user_id: Scalars['String'];
};

export type Gad7_Aggregate = {
  __typename?: 'gad7_aggregate';
  aggregate?: Maybe<Gad7_Aggregate_Fields>;
  nodes: Array<Gad7>;
};

export type Gad7_Aggregate_Fields = {
  __typename?: 'gad7_aggregate_fields';
  avg?: Maybe<Gad7_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Gad7_Max_Fields>;
  min?: Maybe<Gad7_Min_Fields>;
  stddev?: Maybe<Gad7_Stddev_Fields>;
  stddev_pop?: Maybe<Gad7_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Gad7_Stddev_Samp_Fields>;
  sum?: Maybe<Gad7_Sum_Fields>;
  var_pop?: Maybe<Gad7_Var_Pop_Fields>;
  var_samp?: Maybe<Gad7_Var_Samp_Fields>;
  variance?: Maybe<Gad7_Variance_Fields>;
};

export type Gad7_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Gad7_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Gad7_Aggregate_Order_By = {
  avg?: InputMaybe<Gad7_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Gad7_Max_Order_By>;
  min?: InputMaybe<Gad7_Min_Order_By>;
  stddev?: InputMaybe<Gad7_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Gad7_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Gad7_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Gad7_Sum_Order_By>;
  var_pop?: InputMaybe<Gad7_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Gad7_Var_Samp_Order_By>;
  variance?: InputMaybe<Gad7_Variance_Order_By>;
};

export type Gad7_Arr_Rel_Insert_Input = {
  data: Array<Gad7_Insert_Input>;
  on_conflict?: InputMaybe<Gad7_On_Conflict>;
};

export type Gad7_Avg_Fields = {
  __typename?: 'gad7_avg_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
};

export type Gad7_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
};

export type Gad7_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Gad7_Bool_Exp>>>;
  _not?: InputMaybe<Gad7_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Gad7_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  q1?: InputMaybe<Int_Comparison_Exp>;
  q2?: InputMaybe<Int_Comparison_Exp>;
  q3?: InputMaybe<Int_Comparison_Exp>;
  q4?: InputMaybe<Int_Comparison_Exp>;
  q5?: InputMaybe<Int_Comparison_Exp>;
  q6?: InputMaybe<Int_Comparison_Exp>;
  q7?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

export enum Gad7_Constraint {
  Gad7Pkey = 'gad7_pkey',
}

export type Gad7_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['Int']>;
  q2?: InputMaybe<Scalars['Int']>;
  q3?: InputMaybe<Scalars['Int']>;
  q4?: InputMaybe<Scalars['Int']>;
  q5?: InputMaybe<Scalars['Int']>;
  q6?: InputMaybe<Scalars['Int']>;
  q7?: InputMaybe<Scalars['Int']>;
};

export type Gad7_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['Int']>;
  q2?: InputMaybe<Scalars['Int']>;
  q3?: InputMaybe<Scalars['Int']>;
  q4?: InputMaybe<Scalars['Int']>;
  q5?: InputMaybe<Scalars['Int']>;
  q6?: InputMaybe<Scalars['Int']>;
  q7?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Gad7_Max_Fields = {
  __typename?: 'gad7_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['Int']>;
  q2?: Maybe<Scalars['Int']>;
  q3?: Maybe<Scalars['Int']>;
  q4?: Maybe<Scalars['Int']>;
  q5?: Maybe<Scalars['Int']>;
  q6?: Maybe<Scalars['Int']>;
  q7?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Gad7_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Gad7_Min_Fields = {
  __typename?: 'gad7_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['Int']>;
  q2?: Maybe<Scalars['Int']>;
  q3?: Maybe<Scalars['Int']>;
  q4?: Maybe<Scalars['Int']>;
  q5?: Maybe<Scalars['Int']>;
  q6?: Maybe<Scalars['Int']>;
  q7?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Gad7_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Gad7_Mutation_Response = {
  __typename?: 'gad7_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Gad7>;
};

export type Gad7_Obj_Rel_Insert_Input = {
  data: Gad7_Insert_Input;
  on_conflict?: InputMaybe<Gad7_On_Conflict>;
};

export type Gad7_On_Conflict = {
  constraint: Gad7_Constraint;
  update_columns: Array<Gad7_Update_Column>;
  where?: InputMaybe<Gad7_Bool_Exp>;
};

export type Gad7_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Gad7_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export enum Gad7_Select_Column {
  CreatedAt = 'created_at',
  Id = 'id',
  Q1 = 'q1',
  Q2 = 'q2',
  Q3 = 'q3',
  Q4 = 'q4',
  Q5 = 'q5',
  Q6 = 'q6',
  Q7 = 'q7',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Gad7_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['Int']>;
  q2?: InputMaybe<Scalars['Int']>;
  q3?: InputMaybe<Scalars['Int']>;
  q4?: InputMaybe<Scalars['Int']>;
  q5?: InputMaybe<Scalars['Int']>;
  q6?: InputMaybe<Scalars['Int']>;
  q7?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Gad7_Stddev_Fields = {
  __typename?: 'gad7_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
};

export type Gad7_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
};

export type Gad7_Stddev_Pop_Fields = {
  __typename?: 'gad7_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
};

export type Gad7_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
};

export type Gad7_Stddev_Samp_Fields = {
  __typename?: 'gad7_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
};

export type Gad7_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
};

export type Gad7_Sum_Fields = {
  __typename?: 'gad7_sum_fields';
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['Int']>;
  q2?: Maybe<Scalars['Int']>;
  q3?: Maybe<Scalars['Int']>;
  q4?: Maybe<Scalars['Int']>;
  q5?: Maybe<Scalars['Int']>;
  q6?: Maybe<Scalars['Int']>;
  q7?: Maybe<Scalars['Int']>;
};

export type Gad7_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
};

export enum Gad7_Update_Column {
  CreatedAt = 'created_at',
  Id = 'id',
  Q1 = 'q1',
  Q2 = 'q2',
  Q3 = 'q3',
  Q4 = 'q4',
  Q5 = 'q5',
  Q6 = 'q6',
  Q7 = 'q7',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Gad7_Var_Pop_Fields = {
  __typename?: 'gad7_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
};

export type Gad7_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
};

export type Gad7_Var_Samp_Fields = {
  __typename?: 'gad7_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
};

export type Gad7_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
};

export type Gad7_Variance_Fields = {
  __typename?: 'gad7_variance_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
};

export type Gad7_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
};

export type Gad_Aggregate = {
  __typename?: 'gad_aggregate';
  aggregate?: Maybe<Gad_Aggregate_Fields>;
  nodes: Array<Gad>;
};

export type Gad_Aggregate_Fields = {
  __typename?: 'gad_aggregate_fields';
  avg?: Maybe<Gad_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Gad_Max_Fields>;
  min?: Maybe<Gad_Min_Fields>;
  stddev?: Maybe<Gad_Stddev_Fields>;
  stddev_pop?: Maybe<Gad_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Gad_Stddev_Samp_Fields>;
  sum?: Maybe<Gad_Sum_Fields>;
  var_pop?: Maybe<Gad_Var_Pop_Fields>;
  var_samp?: Maybe<Gad_Var_Samp_Fields>;
  variance?: Maybe<Gad_Variance_Fields>;
};

export type Gad_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Gad_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Gad_Aggregate_Order_By = {
  avg?: InputMaybe<Gad_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Gad_Max_Order_By>;
  min?: InputMaybe<Gad_Min_Order_By>;
  stddev?: InputMaybe<Gad_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Gad_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Gad_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Gad_Sum_Order_By>;
  var_pop?: InputMaybe<Gad_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Gad_Var_Samp_Order_By>;
  variance?: InputMaybe<Gad_Variance_Order_By>;
};

export type Gad_Arr_Rel_Insert_Input = {
  data: Array<Gad_Insert_Input>;
  on_conflict?: InputMaybe<Gad_On_Conflict>;
};

export type Gad_Avg_Fields = {
  __typename?: 'gad_avg_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
};

export type Gad_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
};

export type Gad_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Gad_Bool_Exp>>>;
  _not?: InputMaybe<Gad_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Gad_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  q1?: InputMaybe<Int_Comparison_Exp>;
  q2?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

export enum Gad_Constraint {
  GadPkey = 'gad_pkey',
}

export type Gad_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['Int']>;
  q2?: InputMaybe<Scalars['Int']>;
};

export type Gad_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['Int']>;
  q2?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Gad_Max_Fields = {
  __typename?: 'gad_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['Int']>;
  q2?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Gad_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Gad_Min_Fields = {
  __typename?: 'gad_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['Int']>;
  q2?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Gad_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Gad_Mutation_Response = {
  __typename?: 'gad_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Gad>;
};

export type Gad_Obj_Rel_Insert_Input = {
  data: Gad_Insert_Input;
  on_conflict?: InputMaybe<Gad_On_Conflict>;
};

export type Gad_On_Conflict = {
  constraint: Gad_Constraint;
  update_columns: Array<Gad_Update_Column>;
  where?: InputMaybe<Gad_Bool_Exp>;
};

export type Gad_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Gad_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export enum Gad_Select_Column {
  CreatedAt = 'created_at',
  Id = 'id',
  Q1 = 'q1',
  Q2 = 'q2',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Gad_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['Int']>;
  q2?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Gad_Stddev_Fields = {
  __typename?: 'gad_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
};

export type Gad_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
};

export type Gad_Stddev_Pop_Fields = {
  __typename?: 'gad_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
};

export type Gad_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
};

export type Gad_Stddev_Samp_Fields = {
  __typename?: 'gad_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
};

export type Gad_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
};

export type Gad_Sum_Fields = {
  __typename?: 'gad_sum_fields';
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['Int']>;
  q2?: Maybe<Scalars['Int']>;
};

export type Gad_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
};

export enum Gad_Update_Column {
  CreatedAt = 'created_at',
  Id = 'id',
  Q1 = 'q1',
  Q2 = 'q2',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Gad_Var_Pop_Fields = {
  __typename?: 'gad_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
};

export type Gad_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
};

export type Gad_Var_Samp_Fields = {
  __typename?: 'gad_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
};

export type Gad_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
};

export type Gad_Variance_Fields = {
  __typename?: 'gad_variance_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
};

export type Gad_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
};

export type Generic_Questionnaire = {
  __typename?: 'generic_questionnaire';
  created_at: Scalars['timestamptz'];
  data: Scalars['jsonb'];
  id: Scalars['Int'];
  name: Generic_Questionnaire_Name_Enum_Enum;
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['String'];
};

export type Generic_QuestionnaireDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

export type Generic_Questionnaire_Aggregate = {
  __typename?: 'generic_questionnaire_aggregate';
  aggregate?: Maybe<Generic_Questionnaire_Aggregate_Fields>;
  nodes: Array<Generic_Questionnaire>;
};

export type Generic_Questionnaire_Aggregate_Fields = {
  __typename?: 'generic_questionnaire_aggregate_fields';
  avg?: Maybe<Generic_Questionnaire_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Generic_Questionnaire_Max_Fields>;
  min?: Maybe<Generic_Questionnaire_Min_Fields>;
  stddev?: Maybe<Generic_Questionnaire_Stddev_Fields>;
  stddev_pop?: Maybe<Generic_Questionnaire_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Generic_Questionnaire_Stddev_Samp_Fields>;
  sum?: Maybe<Generic_Questionnaire_Sum_Fields>;
  var_pop?: Maybe<Generic_Questionnaire_Var_Pop_Fields>;
  var_samp?: Maybe<Generic_Questionnaire_Var_Samp_Fields>;
  variance?: Maybe<Generic_Questionnaire_Variance_Fields>;
};

export type Generic_Questionnaire_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Generic_Questionnaire_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Generic_Questionnaire_Aggregate_Order_By = {
  avg?: InputMaybe<Generic_Questionnaire_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Generic_Questionnaire_Max_Order_By>;
  min?: InputMaybe<Generic_Questionnaire_Min_Order_By>;
  stddev?: InputMaybe<Generic_Questionnaire_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Generic_Questionnaire_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Generic_Questionnaire_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Generic_Questionnaire_Sum_Order_By>;
  var_pop?: InputMaybe<Generic_Questionnaire_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Generic_Questionnaire_Var_Samp_Order_By>;
  variance?: InputMaybe<Generic_Questionnaire_Variance_Order_By>;
};

export type Generic_Questionnaire_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

export type Generic_Questionnaire_Arr_Rel_Insert_Input = {
  data: Array<Generic_Questionnaire_Insert_Input>;
  on_conflict?: InputMaybe<Generic_Questionnaire_On_Conflict>;
};

export type Generic_Questionnaire_Avg_Fields = {
  __typename?: 'generic_questionnaire_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Generic_Questionnaire_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Generic_Questionnaire_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Generic_Questionnaire_Bool_Exp>>>;
  _not?: InputMaybe<Generic_Questionnaire_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Generic_Questionnaire_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<Generic_Questionnaire_Name_Enum_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

export enum Generic_Questionnaire_Constraint {
  GenericQuestionnairePkey = 'generic_questionnaire_pkey',
}

export type Generic_Questionnaire_Delete_At_Path_Input = {
  data?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Generic_Questionnaire_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']>;
};

export type Generic_Questionnaire_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']>;
};

export type Generic_Questionnaire_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

export type Generic_Questionnaire_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Generic_Questionnaire_Name_Enum_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Generic_Questionnaire_Max_Fields = {
  __typename?: 'generic_questionnaire_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Generic_Questionnaire_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Generic_Questionnaire_Min_Fields = {
  __typename?: 'generic_questionnaire_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Generic_Questionnaire_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Generic_Questionnaire_Mutation_Response = {
  __typename?: 'generic_questionnaire_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Generic_Questionnaire>;
};

export type Generic_Questionnaire_Name_Enum = {
  __typename?: 'generic_questionnaire_name_enum';
  comment: Scalars['String'];
  text: Scalars['String'];
};

export type Generic_Questionnaire_Name_Enum_Aggregate = {
  __typename?: 'generic_questionnaire_name_enum_aggregate';
  aggregate?: Maybe<Generic_Questionnaire_Name_Enum_Aggregate_Fields>;
  nodes: Array<Generic_Questionnaire_Name_Enum>;
};

export type Generic_Questionnaire_Name_Enum_Aggregate_Fields = {
  __typename?: 'generic_questionnaire_name_enum_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Generic_Questionnaire_Name_Enum_Max_Fields>;
  min?: Maybe<Generic_Questionnaire_Name_Enum_Min_Fields>;
};

export type Generic_Questionnaire_Name_Enum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Generic_Questionnaire_Name_Enum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Generic_Questionnaire_Name_Enum_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Generic_Questionnaire_Name_Enum_Max_Order_By>;
  min?: InputMaybe<Generic_Questionnaire_Name_Enum_Min_Order_By>;
};

export type Generic_Questionnaire_Name_Enum_Arr_Rel_Insert_Input = {
  data: Array<Generic_Questionnaire_Name_Enum_Insert_Input>;
  on_conflict?: InputMaybe<Generic_Questionnaire_Name_Enum_On_Conflict>;
};

export type Generic_Questionnaire_Name_Enum_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Generic_Questionnaire_Name_Enum_Bool_Exp>>>;
  _not?: InputMaybe<Generic_Questionnaire_Name_Enum_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Generic_Questionnaire_Name_Enum_Bool_Exp>>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
};

export enum Generic_Questionnaire_Name_Enum_Constraint {
  GenericQuestionnaireNameEnumPkey = 'generic_questionnaire_name_enum_pkey',
}

export enum Generic_Questionnaire_Name_Enum_Enum {
  Berlin = 'berlin',
  MunichChronotypeCore = 'munich_chronotype_core',
  PersonalityScale = 'personality_scale',
  S102 = 's102',
  S103 = 's103',
  S104 = 's104',
  S106 = 's106',
  S107 = 's107',
  S108 = 's108',
  S109 = 's109',
  S111 = 's111',
  S112 = 's112',
  S113 = 's113',
  Tcle = 'tcle',
}

export type Generic_Questionnaire_Name_Enum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Generic_Questionnaire_Name_Enum_Enum>;
  _in?: InputMaybe<Array<Generic_Questionnaire_Name_Enum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Generic_Questionnaire_Name_Enum_Enum>;
  _nin?: InputMaybe<Array<Generic_Questionnaire_Name_Enum_Enum>>;
};

export type Generic_Questionnaire_Name_Enum_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type Generic_Questionnaire_Name_Enum_Max_Fields = {
  __typename?: 'generic_questionnaire_name_enum_max_fields';
  comment?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type Generic_Questionnaire_Name_Enum_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Generic_Questionnaire_Name_Enum_Min_Fields = {
  __typename?: 'generic_questionnaire_name_enum_min_fields';
  comment?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type Generic_Questionnaire_Name_Enum_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Generic_Questionnaire_Name_Enum_Mutation_Response = {
  __typename?: 'generic_questionnaire_name_enum_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Generic_Questionnaire_Name_Enum>;
};

export type Generic_Questionnaire_Name_Enum_Obj_Rel_Insert_Input = {
  data: Generic_Questionnaire_Name_Enum_Insert_Input;
  on_conflict?: InputMaybe<Generic_Questionnaire_Name_Enum_On_Conflict>;
};

export type Generic_Questionnaire_Name_Enum_On_Conflict = {
  constraint: Generic_Questionnaire_Name_Enum_Constraint;
  update_columns: Array<Generic_Questionnaire_Name_Enum_Update_Column>;
  where?: InputMaybe<Generic_Questionnaire_Name_Enum_Bool_Exp>;
};

export type Generic_Questionnaire_Name_Enum_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Generic_Questionnaire_Name_Enum_Pk_Columns_Input = {
  text: Scalars['String'];
};

export enum Generic_Questionnaire_Name_Enum_Select_Column {
  Comment = 'comment',
  Text = 'text',
}

export type Generic_Questionnaire_Name_Enum_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export enum Generic_Questionnaire_Name_Enum_Update_Column {
  Comment = 'comment',
  Text = 'text',
}

export type Generic_Questionnaire_Obj_Rel_Insert_Input = {
  data: Generic_Questionnaire_Insert_Input;
  on_conflict?: InputMaybe<Generic_Questionnaire_On_Conflict>;
};

export type Generic_Questionnaire_On_Conflict = {
  constraint: Generic_Questionnaire_Constraint;
  update_columns: Array<Generic_Questionnaire_Update_Column>;
  where?: InputMaybe<Generic_Questionnaire_Bool_Exp>;
};

export type Generic_Questionnaire_Order_By = {
  created_at?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Generic_Questionnaire_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export type Generic_Questionnaire_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

export enum Generic_Questionnaire_Select_Column {
  CreatedAt = 'created_at',
  Data = 'data',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Generic_Questionnaire_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Generic_Questionnaire_Name_Enum_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Generic_Questionnaire_Stddev_Fields = {
  __typename?: 'generic_questionnaire_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Generic_Questionnaire_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Generic_Questionnaire_Stddev_Pop_Fields = {
  __typename?: 'generic_questionnaire_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Generic_Questionnaire_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Generic_Questionnaire_Stddev_Samp_Fields = {
  __typename?: 'generic_questionnaire_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Generic_Questionnaire_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Generic_Questionnaire_Sum_Fields = {
  __typename?: 'generic_questionnaire_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

export type Generic_Questionnaire_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

export enum Generic_Questionnaire_Update_Column {
  CreatedAt = 'created_at',
  Data = 'data',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Generic_Questionnaire_Var_Pop_Fields = {
  __typename?: 'generic_questionnaire_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Generic_Questionnaire_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Generic_Questionnaire_Var_Samp_Fields = {
  __typename?: 'generic_questionnaire_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Generic_Questionnaire_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Generic_Questionnaire_Variance_Fields = {
  __typename?: 'generic_questionnaire_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Generic_Questionnaire_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Google_Sheet_Row = {
  __typename?: 'google_sheet_row';
  created_at: Scalars['timestamptz'];
  data: Scalars['jsonb'];
  from_block?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  spreadsheet_id: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  user_id?: Maybe<Scalars['String']>;
};

export type Google_Sheet_RowDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

export type Google_Sheet_Row_Aggregate = {
  __typename?: 'google_sheet_row_aggregate';
  aggregate?: Maybe<Google_Sheet_Row_Aggregate_Fields>;
  nodes: Array<Google_Sheet_Row>;
};

export type Google_Sheet_Row_Aggregate_Fields = {
  __typename?: 'google_sheet_row_aggregate_fields';
  avg?: Maybe<Google_Sheet_Row_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Google_Sheet_Row_Max_Fields>;
  min?: Maybe<Google_Sheet_Row_Min_Fields>;
  stddev?: Maybe<Google_Sheet_Row_Stddev_Fields>;
  stddev_pop?: Maybe<Google_Sheet_Row_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Google_Sheet_Row_Stddev_Samp_Fields>;
  sum?: Maybe<Google_Sheet_Row_Sum_Fields>;
  var_pop?: Maybe<Google_Sheet_Row_Var_Pop_Fields>;
  var_samp?: Maybe<Google_Sheet_Row_Var_Samp_Fields>;
  variance?: Maybe<Google_Sheet_Row_Variance_Fields>;
};

export type Google_Sheet_Row_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Google_Sheet_Row_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Google_Sheet_Row_Aggregate_Order_By = {
  avg?: InputMaybe<Google_Sheet_Row_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Google_Sheet_Row_Max_Order_By>;
  min?: InputMaybe<Google_Sheet_Row_Min_Order_By>;
  stddev?: InputMaybe<Google_Sheet_Row_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Google_Sheet_Row_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Google_Sheet_Row_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Google_Sheet_Row_Sum_Order_By>;
  var_pop?: InputMaybe<Google_Sheet_Row_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Google_Sheet_Row_Var_Samp_Order_By>;
  variance?: InputMaybe<Google_Sheet_Row_Variance_Order_By>;
};

export type Google_Sheet_Row_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

export type Google_Sheet_Row_Arr_Rel_Insert_Input = {
  data: Array<Google_Sheet_Row_Insert_Input>;
  on_conflict?: InputMaybe<Google_Sheet_Row_On_Conflict>;
};

export type Google_Sheet_Row_Avg_Fields = {
  __typename?: 'google_sheet_row_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Google_Sheet_Row_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Google_Sheet_Row_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Google_Sheet_Row_Bool_Exp>>>;
  _not?: InputMaybe<Google_Sheet_Row_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Google_Sheet_Row_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  from_block?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  spreadsheet_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

export enum Google_Sheet_Row_Constraint {
  GoogleSheetRowPkey = 'google_sheet_row_pkey',
}

export type Google_Sheet_Row_Delete_At_Path_Input = {
  data?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Google_Sheet_Row_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']>;
};

export type Google_Sheet_Row_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']>;
};

export type Google_Sheet_Row_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

export type Google_Sheet_Row_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  from_block?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  spreadsheet_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Google_Sheet_Row_Max_Fields = {
  __typename?: 'google_sheet_row_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  from_block?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  spreadsheet_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Google_Sheet_Row_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  from_block?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  spreadsheet_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Google_Sheet_Row_Min_Fields = {
  __typename?: 'google_sheet_row_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  from_block?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  spreadsheet_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Google_Sheet_Row_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  from_block?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  spreadsheet_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Google_Sheet_Row_Mutation_Response = {
  __typename?: 'google_sheet_row_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Google_Sheet_Row>;
};

export type Google_Sheet_Row_Obj_Rel_Insert_Input = {
  data: Google_Sheet_Row_Insert_Input;
  on_conflict?: InputMaybe<Google_Sheet_Row_On_Conflict>;
};

export type Google_Sheet_Row_On_Conflict = {
  constraint: Google_Sheet_Row_Constraint;
  update_columns: Array<Google_Sheet_Row_Update_Column>;
  where?: InputMaybe<Google_Sheet_Row_Bool_Exp>;
};

export type Google_Sheet_Row_Order_By = {
  created_at?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  from_block?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  spreadsheet_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Google_Sheet_Row_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export type Google_Sheet_Row_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

export enum Google_Sheet_Row_Select_Column {
  CreatedAt = 'created_at',
  Data = 'data',
  FromBlock = 'from_block',
  Id = 'id',
  SpreadsheetId = 'spreadsheet_id',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Google_Sheet_Row_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  from_block?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  spreadsheet_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Google_Sheet_Row_Stddev_Fields = {
  __typename?: 'google_sheet_row_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Google_Sheet_Row_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Google_Sheet_Row_Stddev_Pop_Fields = {
  __typename?: 'google_sheet_row_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Google_Sheet_Row_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Google_Sheet_Row_Stddev_Samp_Fields = {
  __typename?: 'google_sheet_row_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Google_Sheet_Row_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Google_Sheet_Row_Sum_Fields = {
  __typename?: 'google_sheet_row_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

export type Google_Sheet_Row_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

export enum Google_Sheet_Row_Update_Column {
  CreatedAt = 'created_at',
  Data = 'data',
  FromBlock = 'from_block',
  Id = 'id',
  SpreadsheetId = 'spreadsheet_id',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Google_Sheet_Row_Var_Pop_Fields = {
  __typename?: 'google_sheet_row_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Google_Sheet_Row_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Google_Sheet_Row_Var_Samp_Fields = {
  __typename?: 'google_sheet_row_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Google_Sheet_Row_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Google_Sheet_Row_Variance_Fields = {
  __typename?: 'google_sheet_row_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Google_Sheet_Row_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Gratitude = {
  __typename?: 'gratitude';
  created_at: Scalars['timestamptz'];
  good_things: Scalars['String'];
  how_do_you_feel: Scalars['String'];
  id: Scalars['Int'];
  user_id: Scalars['String'];
};

export type Gratitude_Aggregate = {
  __typename?: 'gratitude_aggregate';
  aggregate?: Maybe<Gratitude_Aggregate_Fields>;
  nodes: Array<Gratitude>;
};

export type Gratitude_Aggregate_Fields = {
  __typename?: 'gratitude_aggregate_fields';
  avg?: Maybe<Gratitude_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Gratitude_Max_Fields>;
  min?: Maybe<Gratitude_Min_Fields>;
  stddev?: Maybe<Gratitude_Stddev_Fields>;
  stddev_pop?: Maybe<Gratitude_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Gratitude_Stddev_Samp_Fields>;
  sum?: Maybe<Gratitude_Sum_Fields>;
  var_pop?: Maybe<Gratitude_Var_Pop_Fields>;
  var_samp?: Maybe<Gratitude_Var_Samp_Fields>;
  variance?: Maybe<Gratitude_Variance_Fields>;
};

export type Gratitude_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Gratitude_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Gratitude_Aggregate_Order_By = {
  avg?: InputMaybe<Gratitude_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Gratitude_Max_Order_By>;
  min?: InputMaybe<Gratitude_Min_Order_By>;
  stddev?: InputMaybe<Gratitude_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Gratitude_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Gratitude_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Gratitude_Sum_Order_By>;
  var_pop?: InputMaybe<Gratitude_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Gratitude_Var_Samp_Order_By>;
  variance?: InputMaybe<Gratitude_Variance_Order_By>;
};

export type Gratitude_Arr_Rel_Insert_Input = {
  data: Array<Gratitude_Insert_Input>;
  on_conflict?: InputMaybe<Gratitude_On_Conflict>;
};

export type Gratitude_Avg_Fields = {
  __typename?: 'gratitude_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Gratitude_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Gratitude_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Gratitude_Bool_Exp>>>;
  _not?: InputMaybe<Gratitude_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Gratitude_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  good_things?: InputMaybe<String_Comparison_Exp>;
  how_do_you_feel?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

export enum Gratitude_Constraint {
  GoodThingsPkey = 'good_things_pkey',
}

export type Gratitude_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

export type Gratitude_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  good_things?: InputMaybe<Scalars['String']>;
  how_do_you_feel?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Gratitude_Max_Fields = {
  __typename?: 'gratitude_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  good_things?: Maybe<Scalars['String']>;
  how_do_you_feel?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Gratitude_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  good_things?: InputMaybe<Order_By>;
  how_do_you_feel?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Gratitude_Min_Fields = {
  __typename?: 'gratitude_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  good_things?: Maybe<Scalars['String']>;
  how_do_you_feel?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Gratitude_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  good_things?: InputMaybe<Order_By>;
  how_do_you_feel?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Gratitude_Mutation_Response = {
  __typename?: 'gratitude_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Gratitude>;
};

export type Gratitude_Obj_Rel_Insert_Input = {
  data: Gratitude_Insert_Input;
  on_conflict?: InputMaybe<Gratitude_On_Conflict>;
};

export type Gratitude_On_Conflict = {
  constraint: Gratitude_Constraint;
  update_columns: Array<Gratitude_Update_Column>;
  where?: InputMaybe<Gratitude_Bool_Exp>;
};

export type Gratitude_Order_By = {
  created_at?: InputMaybe<Order_By>;
  good_things?: InputMaybe<Order_By>;
  how_do_you_feel?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Gratitude_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export enum Gratitude_Select_Column {
  CreatedAt = 'created_at',
  GoodThings = 'good_things',
  HowDoYouFeel = 'how_do_you_feel',
  Id = 'id',
  UserId = 'user_id',
}

export type Gratitude_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  good_things?: InputMaybe<Scalars['String']>;
  how_do_you_feel?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Gratitude_Stddev_Fields = {
  __typename?: 'gratitude_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Gratitude_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Gratitude_Stddev_Pop_Fields = {
  __typename?: 'gratitude_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Gratitude_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Gratitude_Stddev_Samp_Fields = {
  __typename?: 'gratitude_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Gratitude_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Gratitude_Sum_Fields = {
  __typename?: 'gratitude_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

export type Gratitude_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

export enum Gratitude_Update_Column {
  CreatedAt = 'created_at',
  GoodThings = 'good_things',
  HowDoYouFeel = 'how_do_you_feel',
  Id = 'id',
  UserId = 'user_id',
}

export type Gratitude_Var_Pop_Fields = {
  __typename?: 'gratitude_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Gratitude_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Gratitude_Var_Samp_Fields = {
  __typename?: 'gratitude_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Gratitude_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Gratitude_Variance_Fields = {
  __typename?: 'gratitude_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Gratitude_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Health_Carer = {
  __typename?: 'health_carer';
  companies: Array<Company_Health_Carer>;
  companies_aggregate: Company_Health_Carer_Aggregate;
  coupons: Array<Coupon>;
  coupons_aggregate: Coupon_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  crm?: Maybe<Crm>;
  email: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  health_carer_money_requests: Array<Health_Carer_Money_Request>;
  health_carer_money_requests_aggregate: Health_Carer_Money_Request_Aggregate;
  health_carer_telehealth?: Maybe<Health_Carer_Telehealth>;
  health_carer_telehealths: Array<Health_Carer_Telehealth>;
  health_carer_telehealths_aggregate: Health_Carer_Telehealth_Aggregate;
  health_carer_transactions: Array<Health_Carer_Transactions>;
  health_carer_transactions_aggregate: Health_Carer_Transactions_Aggregate;
  id: Scalars['String'];
  internal_id: Scalars['Int'];
  kind?: Maybe<Scalars['String']>;
  landing_page_redirect?: Maybe<Landing_Page_Redirect>;
  membership_kind: Health_Carer_Membership_Kind_Enum_Enum;
  name?: Maybe<Scalars['String']>;
  pagarme_recipient?: Maybe<Health_Carer_Pagarme_Recipient>;
  professional_id?: Maybe<Scalars['String']>;
  professional_id_state?: Maybe<Scalars['String']>;
  share_path?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  users: Array<Health_Carer_User>;
  users_aggregate: Health_Carer_User_Aggregate;
  whatsapp?: Maybe<Scalars['String']>;
};

export type Health_CarerCompaniesArgs = {
  distinct_on?: InputMaybe<Array<Company_Health_Carer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Health_Carer_Order_By>>;
  where?: InputMaybe<Company_Health_Carer_Bool_Exp>;
};

export type Health_CarerCompanies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Health_Carer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Health_Carer_Order_By>>;
  where?: InputMaybe<Company_Health_Carer_Bool_Exp>;
};

export type Health_CarerCouponsArgs = {
  distinct_on?: InputMaybe<Array<Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coupon_Order_By>>;
  where?: InputMaybe<Coupon_Bool_Exp>;
};

export type Health_CarerCoupons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coupon_Order_By>>;
  where?: InputMaybe<Coupon_Bool_Exp>;
};

export type Health_CarerHealth_Carer_Money_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_Money_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_Money_Request_Order_By>>;
  where?: InputMaybe<Health_Carer_Money_Request_Bool_Exp>;
};

export type Health_CarerHealth_Carer_Money_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_Money_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_Money_Request_Order_By>>;
  where?: InputMaybe<Health_Carer_Money_Request_Bool_Exp>;
};

export type Health_CarerHealth_Carer_TelehealthsArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_Telehealth_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_Telehealth_Order_By>>;
  where?: InputMaybe<Health_Carer_Telehealth_Bool_Exp>;
};

export type Health_CarerHealth_Carer_Telehealths_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_Telehealth_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_Telehealth_Order_By>>;
  where?: InputMaybe<Health_Carer_Telehealth_Bool_Exp>;
};

export type Health_CarerHealth_Carer_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_Transactions_Order_By>>;
  where?: InputMaybe<Health_Carer_Transactions_Bool_Exp>;
};

export type Health_CarerHealth_Carer_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_Transactions_Order_By>>;
  where?: InputMaybe<Health_Carer_Transactions_Bool_Exp>;
};

export type Health_CarerUsersArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_User_Order_By>>;
  where?: InputMaybe<Health_Carer_User_Bool_Exp>;
};

export type Health_CarerUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_User_Order_By>>;
  where?: InputMaybe<Health_Carer_User_Bool_Exp>;
};

export type Health_Carer_Aggregate = {
  __typename?: 'health_carer_aggregate';
  aggregate?: Maybe<Health_Carer_Aggregate_Fields>;
  nodes: Array<Health_Carer>;
};

export type Health_Carer_Aggregate_Fields = {
  __typename?: 'health_carer_aggregate_fields';
  avg?: Maybe<Health_Carer_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Health_Carer_Max_Fields>;
  min?: Maybe<Health_Carer_Min_Fields>;
  stddev?: Maybe<Health_Carer_Stddev_Fields>;
  stddev_pop?: Maybe<Health_Carer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Health_Carer_Stddev_Samp_Fields>;
  sum?: Maybe<Health_Carer_Sum_Fields>;
  var_pop?: Maybe<Health_Carer_Var_Pop_Fields>;
  var_samp?: Maybe<Health_Carer_Var_Samp_Fields>;
  variance?: Maybe<Health_Carer_Variance_Fields>;
};

export type Health_Carer_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Health_Carer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Health_Carer_Aggregate_Order_By = {
  avg?: InputMaybe<Health_Carer_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Health_Carer_Max_Order_By>;
  min?: InputMaybe<Health_Carer_Min_Order_By>;
  stddev?: InputMaybe<Health_Carer_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Health_Carer_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Health_Carer_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Health_Carer_Sum_Order_By>;
  var_pop?: InputMaybe<Health_Carer_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Health_Carer_Var_Samp_Order_By>;
  variance?: InputMaybe<Health_Carer_Variance_Order_By>;
};

export type Health_Carer_Arr_Rel_Insert_Input = {
  data: Array<Health_Carer_Insert_Input>;
  on_conflict?: InputMaybe<Health_Carer_On_Conflict>;
};

export type Health_Carer_Avg_Fields = {
  __typename?: 'health_carer_avg_fields';
  internal_id?: Maybe<Scalars['Float']>;
};

export type Health_Carer_Avg_Order_By = {
  internal_id?: InputMaybe<Order_By>;
};

export type Health_Carer_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Health_Carer_Bool_Exp>>>;
  _not?: InputMaybe<Health_Carer_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Health_Carer_Bool_Exp>>>;
  companies?: InputMaybe<Company_Health_Carer_Bool_Exp>;
  coupons?: InputMaybe<Coupon_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  crm?: InputMaybe<Crm_Bool_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  gender?: InputMaybe<String_Comparison_Exp>;
  health_carer_money_requests?: InputMaybe<Health_Carer_Money_Request_Bool_Exp>;
  health_carer_telehealth?: InputMaybe<Health_Carer_Telehealth_Bool_Exp>;
  health_carer_telehealths?: InputMaybe<Health_Carer_Telehealth_Bool_Exp>;
  health_carer_transactions?: InputMaybe<Health_Carer_Transactions_Bool_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  internal_id?: InputMaybe<Int_Comparison_Exp>;
  kind?: InputMaybe<String_Comparison_Exp>;
  landing_page_redirect?: InputMaybe<Landing_Page_Redirect_Bool_Exp>;
  membership_kind?: InputMaybe<Health_Carer_Membership_Kind_Enum_Enum_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  pagarme_recipient?: InputMaybe<Health_Carer_Pagarme_Recipient_Bool_Exp>;
  professional_id?: InputMaybe<String_Comparison_Exp>;
  professional_id_state?: InputMaybe<String_Comparison_Exp>;
  share_path?: InputMaybe<String_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  users?: InputMaybe<Health_Carer_User_Bool_Exp>;
  whatsapp?: InputMaybe<String_Comparison_Exp>;
};

export enum Health_Carer_Constraint {
  HealthCarerInternalIdKey = 'health_carer_internal_id_key',
  HealthCarerPkey = 'health_carer_pkey',
}

export type Health_Carer_Inc_Input = {
  internal_id?: InputMaybe<Scalars['Int']>;
};

export type Health_Carer_Insert_Input = {
  companies?: InputMaybe<Company_Health_Carer_Arr_Rel_Insert_Input>;
  coupons?: InputMaybe<Coupon_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  crm?: InputMaybe<Crm_Obj_Rel_Insert_Input>;
  email?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  health_carer_money_requests?: InputMaybe<Health_Carer_Money_Request_Arr_Rel_Insert_Input>;
  health_carer_telehealth?: InputMaybe<Health_Carer_Telehealth_Obj_Rel_Insert_Input>;
  health_carer_telehealths?: InputMaybe<Health_Carer_Telehealth_Arr_Rel_Insert_Input>;
  health_carer_transactions?: InputMaybe<Health_Carer_Transactions_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['String']>;
  internal_id?: InputMaybe<Scalars['Int']>;
  kind?: InputMaybe<Scalars['String']>;
  landing_page_redirect?: InputMaybe<Landing_Page_Redirect_Obj_Rel_Insert_Input>;
  membership_kind?: InputMaybe<Health_Carer_Membership_Kind_Enum_Enum>;
  name?: InputMaybe<Scalars['String']>;
  pagarme_recipient?: InputMaybe<Health_Carer_Pagarme_Recipient_Obj_Rel_Insert_Input>;
  professional_id?: InputMaybe<Scalars['String']>;
  professional_id_state?: InputMaybe<Scalars['String']>;
  share_path?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<Health_Carer_User_Arr_Rel_Insert_Input>;
  whatsapp?: InputMaybe<Scalars['String']>;
};

export type Health_Carer_Max_Fields = {
  __typename?: 'health_carer_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  internal_id?: Maybe<Scalars['Int']>;
  kind?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  professional_id?: Maybe<Scalars['String']>;
  professional_id_state?: Maybe<Scalars['String']>;
  share_path?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  whatsapp?: Maybe<Scalars['String']>;
};

export type Health_Carer_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  professional_id?: InputMaybe<Order_By>;
  professional_id_state?: InputMaybe<Order_By>;
  share_path?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  whatsapp?: InputMaybe<Order_By>;
};

export type Health_Carer_Membership_Kind_Enum = {
  __typename?: 'health_carer_membership_kind_enum';
  comment: Scalars['String'];
  text: Scalars['String'];
};

export type Health_Carer_Membership_Kind_Enum_Aggregate = {
  __typename?: 'health_carer_membership_kind_enum_aggregate';
  aggregate?: Maybe<Health_Carer_Membership_Kind_Enum_Aggregate_Fields>;
  nodes: Array<Health_Carer_Membership_Kind_Enum>;
};

export type Health_Carer_Membership_Kind_Enum_Aggregate_Fields = {
  __typename?: 'health_carer_membership_kind_enum_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Health_Carer_Membership_Kind_Enum_Max_Fields>;
  min?: Maybe<Health_Carer_Membership_Kind_Enum_Min_Fields>;
};

export type Health_Carer_Membership_Kind_Enum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Health_Carer_Membership_Kind_Enum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Health_Carer_Membership_Kind_Enum_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Health_Carer_Membership_Kind_Enum_Max_Order_By>;
  min?: InputMaybe<Health_Carer_Membership_Kind_Enum_Min_Order_By>;
};

export type Health_Carer_Membership_Kind_Enum_Arr_Rel_Insert_Input = {
  data: Array<Health_Carer_Membership_Kind_Enum_Insert_Input>;
  on_conflict?: InputMaybe<Health_Carer_Membership_Kind_Enum_On_Conflict>;
};

export type Health_Carer_Membership_Kind_Enum_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Health_Carer_Membership_Kind_Enum_Bool_Exp>>>;
  _not?: InputMaybe<Health_Carer_Membership_Kind_Enum_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Health_Carer_Membership_Kind_Enum_Bool_Exp>>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
};

export enum Health_Carer_Membership_Kind_Enum_Constraint {
  HealthCarerMembershipKindEnumPkey = 'health_carer_membership_kind_enum_pkey',
}

export enum Health_Carer_Membership_Kind_Enum_Enum {
  Advanced = 'ADVANCED',
  Basic = 'BASIC',
  BasicWithRevenue = 'BASIC_WITH_REVENUE',
  FreeForAll = 'FREE_FOR_ALL',
  Support = 'SUPPORT',
  SupportWithRevenue = 'SUPPORT_WITH_REVENUE',
}

export type Health_Carer_Membership_Kind_Enum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Health_Carer_Membership_Kind_Enum_Enum>;
  _in?: InputMaybe<Array<Health_Carer_Membership_Kind_Enum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Health_Carer_Membership_Kind_Enum_Enum>;
  _nin?: InputMaybe<Array<Health_Carer_Membership_Kind_Enum_Enum>>;
};

export type Health_Carer_Membership_Kind_Enum_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type Health_Carer_Membership_Kind_Enum_Max_Fields = {
  __typename?: 'health_carer_membership_kind_enum_max_fields';
  comment?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type Health_Carer_Membership_Kind_Enum_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Health_Carer_Membership_Kind_Enum_Min_Fields = {
  __typename?: 'health_carer_membership_kind_enum_min_fields';
  comment?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type Health_Carer_Membership_Kind_Enum_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Health_Carer_Membership_Kind_Enum_Mutation_Response = {
  __typename?: 'health_carer_membership_kind_enum_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Health_Carer_Membership_Kind_Enum>;
};

export type Health_Carer_Membership_Kind_Enum_Obj_Rel_Insert_Input = {
  data: Health_Carer_Membership_Kind_Enum_Insert_Input;
  on_conflict?: InputMaybe<Health_Carer_Membership_Kind_Enum_On_Conflict>;
};

export type Health_Carer_Membership_Kind_Enum_On_Conflict = {
  constraint: Health_Carer_Membership_Kind_Enum_Constraint;
  update_columns: Array<Health_Carer_Membership_Kind_Enum_Update_Column>;
  where?: InputMaybe<Health_Carer_Membership_Kind_Enum_Bool_Exp>;
};

export type Health_Carer_Membership_Kind_Enum_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Health_Carer_Membership_Kind_Enum_Pk_Columns_Input = {
  text: Scalars['String'];
};

export enum Health_Carer_Membership_Kind_Enum_Select_Column {
  Comment = 'comment',
  Text = 'text',
}

export type Health_Carer_Membership_Kind_Enum_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export enum Health_Carer_Membership_Kind_Enum_Update_Column {
  Comment = 'comment',
  Text = 'text',
}

export type Health_Carer_Min_Fields = {
  __typename?: 'health_carer_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  internal_id?: Maybe<Scalars['Int']>;
  kind?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  professional_id?: Maybe<Scalars['String']>;
  professional_id_state?: Maybe<Scalars['String']>;
  share_path?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  whatsapp?: Maybe<Scalars['String']>;
};

export type Health_Carer_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  professional_id?: InputMaybe<Order_By>;
  professional_id_state?: InputMaybe<Order_By>;
  share_path?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  whatsapp?: InputMaybe<Order_By>;
};

export type Health_Carer_Money_Request = {
  __typename?: 'health_carer_money_request';
  amount: Scalars['numeric'];
  created_at: Scalars['timestamptz'];
  extra_data?: Maybe<Scalars['jsonb']>;
  health_carer_id: Scalars['String'];
  id: Scalars['bigint'];
  status: Health_Carer_Money_Request_Status_Enum_Enum;
  updated_at: Scalars['timestamptz'];
};

export type Health_Carer_Money_RequestExtra_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

export type Health_Carer_Money_Request_Aggregate = {
  __typename?: 'health_carer_money_request_aggregate';
  aggregate?: Maybe<Health_Carer_Money_Request_Aggregate_Fields>;
  nodes: Array<Health_Carer_Money_Request>;
};

export type Health_Carer_Money_Request_Aggregate_Fields = {
  __typename?: 'health_carer_money_request_aggregate_fields';
  avg?: Maybe<Health_Carer_Money_Request_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Health_Carer_Money_Request_Max_Fields>;
  min?: Maybe<Health_Carer_Money_Request_Min_Fields>;
  stddev?: Maybe<Health_Carer_Money_Request_Stddev_Fields>;
  stddev_pop?: Maybe<Health_Carer_Money_Request_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Health_Carer_Money_Request_Stddev_Samp_Fields>;
  sum?: Maybe<Health_Carer_Money_Request_Sum_Fields>;
  var_pop?: Maybe<Health_Carer_Money_Request_Var_Pop_Fields>;
  var_samp?: Maybe<Health_Carer_Money_Request_Var_Samp_Fields>;
  variance?: Maybe<Health_Carer_Money_Request_Variance_Fields>;
};

export type Health_Carer_Money_Request_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Health_Carer_Money_Request_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Health_Carer_Money_Request_Aggregate_Order_By = {
  avg?: InputMaybe<Health_Carer_Money_Request_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Health_Carer_Money_Request_Max_Order_By>;
  min?: InputMaybe<Health_Carer_Money_Request_Min_Order_By>;
  stddev?: InputMaybe<Health_Carer_Money_Request_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Health_Carer_Money_Request_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Health_Carer_Money_Request_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Health_Carer_Money_Request_Sum_Order_By>;
  var_pop?: InputMaybe<Health_Carer_Money_Request_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Health_Carer_Money_Request_Var_Samp_Order_By>;
  variance?: InputMaybe<Health_Carer_Money_Request_Variance_Order_By>;
};

export type Health_Carer_Money_Request_Append_Input = {
  extra_data?: InputMaybe<Scalars['jsonb']>;
};

export type Health_Carer_Money_Request_Arr_Rel_Insert_Input = {
  data: Array<Health_Carer_Money_Request_Insert_Input>;
  on_conflict?: InputMaybe<Health_Carer_Money_Request_On_Conflict>;
};

export type Health_Carer_Money_Request_Avg_Fields = {
  __typename?: 'health_carer_money_request_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Health_Carer_Money_Request_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type Health_Carer_Money_Request_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Health_Carer_Money_Request_Bool_Exp>>>;
  _not?: InputMaybe<Health_Carer_Money_Request_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Health_Carer_Money_Request_Bool_Exp>>>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  extra_data?: InputMaybe<Jsonb_Comparison_Exp>;
  health_carer_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  status?: InputMaybe<Health_Carer_Money_Request_Status_Enum_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

export enum Health_Carer_Money_Request_Constraint {
  HealthCarerMoneyRequestPkey = 'health_carer_money_request_pkey',
}

export type Health_Carer_Money_Request_Delete_At_Path_Input = {
  extra_data?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Health_Carer_Money_Request_Delete_Elem_Input = {
  extra_data?: InputMaybe<Scalars['Int']>;
};

export type Health_Carer_Money_Request_Delete_Key_Input = {
  extra_data?: InputMaybe<Scalars['String']>;
};

export type Health_Carer_Money_Request_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['bigint']>;
};

export type Health_Carer_Money_Request_Insert_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  extra_data?: InputMaybe<Scalars['jsonb']>;
  health_carer_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Health_Carer_Money_Request_Status_Enum_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Health_Carer_Money_Request_Log = {
  __typename?: 'health_carer_money_request_log';
  comment?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  from_status: Health_Carer_Money_Request_Status_Enum_Enum;
  health_carer_money_request_id: Scalars['bigint'];
  id: Scalars['bigint'];
  to_status: Health_Carer_Money_Request_Status_Enum_Enum;
  updated_at: Scalars['timestamptz'];
};

export type Health_Carer_Money_Request_Log_Aggregate = {
  __typename?: 'health_carer_money_request_log_aggregate';
  aggregate?: Maybe<Health_Carer_Money_Request_Log_Aggregate_Fields>;
  nodes: Array<Health_Carer_Money_Request_Log>;
};

export type Health_Carer_Money_Request_Log_Aggregate_Fields = {
  __typename?: 'health_carer_money_request_log_aggregate_fields';
  avg?: Maybe<Health_Carer_Money_Request_Log_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Health_Carer_Money_Request_Log_Max_Fields>;
  min?: Maybe<Health_Carer_Money_Request_Log_Min_Fields>;
  stddev?: Maybe<Health_Carer_Money_Request_Log_Stddev_Fields>;
  stddev_pop?: Maybe<Health_Carer_Money_Request_Log_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Health_Carer_Money_Request_Log_Stddev_Samp_Fields>;
  sum?: Maybe<Health_Carer_Money_Request_Log_Sum_Fields>;
  var_pop?: Maybe<Health_Carer_Money_Request_Log_Var_Pop_Fields>;
  var_samp?: Maybe<Health_Carer_Money_Request_Log_Var_Samp_Fields>;
  variance?: Maybe<Health_Carer_Money_Request_Log_Variance_Fields>;
};

export type Health_Carer_Money_Request_Log_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Health_Carer_Money_Request_Log_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Health_Carer_Money_Request_Log_Aggregate_Order_By = {
  avg?: InputMaybe<Health_Carer_Money_Request_Log_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Health_Carer_Money_Request_Log_Max_Order_By>;
  min?: InputMaybe<Health_Carer_Money_Request_Log_Min_Order_By>;
  stddev?: InputMaybe<Health_Carer_Money_Request_Log_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Health_Carer_Money_Request_Log_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Health_Carer_Money_Request_Log_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Health_Carer_Money_Request_Log_Sum_Order_By>;
  var_pop?: InputMaybe<Health_Carer_Money_Request_Log_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Health_Carer_Money_Request_Log_Var_Samp_Order_By>;
  variance?: InputMaybe<Health_Carer_Money_Request_Log_Variance_Order_By>;
};

export type Health_Carer_Money_Request_Log_Arr_Rel_Insert_Input = {
  data: Array<Health_Carer_Money_Request_Log_Insert_Input>;
  on_conflict?: InputMaybe<Health_Carer_Money_Request_Log_On_Conflict>;
};

export type Health_Carer_Money_Request_Log_Avg_Fields = {
  __typename?: 'health_carer_money_request_log_avg_fields';
  health_carer_money_request_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Health_Carer_Money_Request_Log_Avg_Order_By = {
  health_carer_money_request_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type Health_Carer_Money_Request_Log_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Health_Carer_Money_Request_Log_Bool_Exp>>>;
  _not?: InputMaybe<Health_Carer_Money_Request_Log_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Health_Carer_Money_Request_Log_Bool_Exp>>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  from_status?: InputMaybe<Health_Carer_Money_Request_Status_Enum_Enum_Comparison_Exp>;
  health_carer_money_request_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  to_status?: InputMaybe<Health_Carer_Money_Request_Status_Enum_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

export enum Health_Carer_Money_Request_Log_Constraint {
  HealthCarerMoneyRequestLogPkey = 'health_carer_money_request_log_pkey',
}

export type Health_Carer_Money_Request_Log_Inc_Input = {
  health_carer_money_request_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
};

export type Health_Carer_Money_Request_Log_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  from_status?: InputMaybe<Health_Carer_Money_Request_Status_Enum_Enum>;
  health_carer_money_request_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  to_status?: InputMaybe<Health_Carer_Money_Request_Status_Enum_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Health_Carer_Money_Request_Log_Max_Fields = {
  __typename?: 'health_carer_money_request_log_max_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  health_carer_money_request_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type Health_Carer_Money_Request_Log_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  health_carer_money_request_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type Health_Carer_Money_Request_Log_Min_Fields = {
  __typename?: 'health_carer_money_request_log_min_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  health_carer_money_request_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type Health_Carer_Money_Request_Log_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  health_carer_money_request_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type Health_Carer_Money_Request_Log_Mutation_Response = {
  __typename?: 'health_carer_money_request_log_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Health_Carer_Money_Request_Log>;
};

export type Health_Carer_Money_Request_Log_Obj_Rel_Insert_Input = {
  data: Health_Carer_Money_Request_Log_Insert_Input;
  on_conflict?: InputMaybe<Health_Carer_Money_Request_Log_On_Conflict>;
};

export type Health_Carer_Money_Request_Log_On_Conflict = {
  constraint: Health_Carer_Money_Request_Log_Constraint;
  update_columns: Array<Health_Carer_Money_Request_Log_Update_Column>;
  where?: InputMaybe<Health_Carer_Money_Request_Log_Bool_Exp>;
};

export type Health_Carer_Money_Request_Log_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  from_status?: InputMaybe<Order_By>;
  health_carer_money_request_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  to_status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type Health_Carer_Money_Request_Log_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

export enum Health_Carer_Money_Request_Log_Select_Column {
  Comment = 'comment',
  CreatedAt = 'created_at',
  FromStatus = 'from_status',
  HealthCarerMoneyRequestId = 'health_carer_money_request_id',
  Id = 'id',
  ToStatus = 'to_status',
  UpdatedAt = 'updated_at',
}

export type Health_Carer_Money_Request_Log_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  from_status?: InputMaybe<Health_Carer_Money_Request_Status_Enum_Enum>;
  health_carer_money_request_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  to_status?: InputMaybe<Health_Carer_Money_Request_Status_Enum_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Health_Carer_Money_Request_Log_Stddev_Fields = {
  __typename?: 'health_carer_money_request_log_stddev_fields';
  health_carer_money_request_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Health_Carer_Money_Request_Log_Stddev_Order_By = {
  health_carer_money_request_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type Health_Carer_Money_Request_Log_Stddev_Pop_Fields = {
  __typename?: 'health_carer_money_request_log_stddev_pop_fields';
  health_carer_money_request_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Health_Carer_Money_Request_Log_Stddev_Pop_Order_By = {
  health_carer_money_request_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type Health_Carer_Money_Request_Log_Stddev_Samp_Fields = {
  __typename?: 'health_carer_money_request_log_stddev_samp_fields';
  health_carer_money_request_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Health_Carer_Money_Request_Log_Stddev_Samp_Order_By = {
  health_carer_money_request_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type Health_Carer_Money_Request_Log_Sum_Fields = {
  __typename?: 'health_carer_money_request_log_sum_fields';
  health_carer_money_request_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

export type Health_Carer_Money_Request_Log_Sum_Order_By = {
  health_carer_money_request_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export enum Health_Carer_Money_Request_Log_Update_Column {
  Comment = 'comment',
  CreatedAt = 'created_at',
  FromStatus = 'from_status',
  HealthCarerMoneyRequestId = 'health_carer_money_request_id',
  Id = 'id',
  ToStatus = 'to_status',
  UpdatedAt = 'updated_at',
}

export type Health_Carer_Money_Request_Log_Var_Pop_Fields = {
  __typename?: 'health_carer_money_request_log_var_pop_fields';
  health_carer_money_request_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Health_Carer_Money_Request_Log_Var_Pop_Order_By = {
  health_carer_money_request_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type Health_Carer_Money_Request_Log_Var_Samp_Fields = {
  __typename?: 'health_carer_money_request_log_var_samp_fields';
  health_carer_money_request_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Health_Carer_Money_Request_Log_Var_Samp_Order_By = {
  health_carer_money_request_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type Health_Carer_Money_Request_Log_Variance_Fields = {
  __typename?: 'health_carer_money_request_log_variance_fields';
  health_carer_money_request_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Health_Carer_Money_Request_Log_Variance_Order_By = {
  health_carer_money_request_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type Health_Carer_Money_Request_Max_Fields = {
  __typename?: 'health_carer_money_request_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  health_carer_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type Health_Carer_Money_Request_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  health_carer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type Health_Carer_Money_Request_Min_Fields = {
  __typename?: 'health_carer_money_request_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  health_carer_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type Health_Carer_Money_Request_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  health_carer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type Health_Carer_Money_Request_Mutation_Response = {
  __typename?: 'health_carer_money_request_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Health_Carer_Money_Request>;
};

export type Health_Carer_Money_Request_Obj_Rel_Insert_Input = {
  data: Health_Carer_Money_Request_Insert_Input;
  on_conflict?: InputMaybe<Health_Carer_Money_Request_On_Conflict>;
};

export type Health_Carer_Money_Request_On_Conflict = {
  constraint: Health_Carer_Money_Request_Constraint;
  update_columns: Array<Health_Carer_Money_Request_Update_Column>;
  where?: InputMaybe<Health_Carer_Money_Request_Bool_Exp>;
};

export type Health_Carer_Money_Request_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  extra_data?: InputMaybe<Order_By>;
  health_carer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type Health_Carer_Money_Request_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

export type Health_Carer_Money_Request_Prepend_Input = {
  extra_data?: InputMaybe<Scalars['jsonb']>;
};

export enum Health_Carer_Money_Request_Select_Column {
  Amount = 'amount',
  CreatedAt = 'created_at',
  ExtraData = 'extra_data',
  HealthCarerId = 'health_carer_id',
  Id = 'id',
  Status = 'status',
  UpdatedAt = 'updated_at',
}

export type Health_Carer_Money_Request_Set_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  extra_data?: InputMaybe<Scalars['jsonb']>;
  health_carer_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Health_Carer_Money_Request_Status_Enum_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Health_Carer_Money_Request_Status_Enum = {
  __typename?: 'health_carer_money_request_status_enum';
  comment: Scalars['String'];
  text: Scalars['String'];
};

export type Health_Carer_Money_Request_Status_Enum_Aggregate = {
  __typename?: 'health_carer_money_request_status_enum_aggregate';
  aggregate?: Maybe<Health_Carer_Money_Request_Status_Enum_Aggregate_Fields>;
  nodes: Array<Health_Carer_Money_Request_Status_Enum>;
};

export type Health_Carer_Money_Request_Status_Enum_Aggregate_Fields = {
  __typename?: 'health_carer_money_request_status_enum_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Health_Carer_Money_Request_Status_Enum_Max_Fields>;
  min?: Maybe<Health_Carer_Money_Request_Status_Enum_Min_Fields>;
};

export type Health_Carer_Money_Request_Status_Enum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Health_Carer_Money_Request_Status_Enum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Health_Carer_Money_Request_Status_Enum_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Health_Carer_Money_Request_Status_Enum_Max_Order_By>;
  min?: InputMaybe<Health_Carer_Money_Request_Status_Enum_Min_Order_By>;
};

export type Health_Carer_Money_Request_Status_Enum_Arr_Rel_Insert_Input = {
  data: Array<Health_Carer_Money_Request_Status_Enum_Insert_Input>;
  on_conflict?: InputMaybe<Health_Carer_Money_Request_Status_Enum_On_Conflict>;
};

export type Health_Carer_Money_Request_Status_Enum_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Health_Carer_Money_Request_Status_Enum_Bool_Exp>>>;
  _not?: InputMaybe<Health_Carer_Money_Request_Status_Enum_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Health_Carer_Money_Request_Status_Enum_Bool_Exp>>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
};

export enum Health_Carer_Money_Request_Status_Enum_Constraint {
  HealthCarerMoneyRequestStatusEnumPkey = 'health_carer_money_request_status_enum_pkey',
}

export enum Health_Carer_Money_Request_Status_Enum_Enum {
  Done = 'DONE',
  Pending = 'PENDING',
}

export type Health_Carer_Money_Request_Status_Enum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Health_Carer_Money_Request_Status_Enum_Enum>;
  _in?: InputMaybe<Array<Health_Carer_Money_Request_Status_Enum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Health_Carer_Money_Request_Status_Enum_Enum>;
  _nin?: InputMaybe<Array<Health_Carer_Money_Request_Status_Enum_Enum>>;
};

export type Health_Carer_Money_Request_Status_Enum_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type Health_Carer_Money_Request_Status_Enum_Max_Fields = {
  __typename?: 'health_carer_money_request_status_enum_max_fields';
  comment?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type Health_Carer_Money_Request_Status_Enum_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Health_Carer_Money_Request_Status_Enum_Min_Fields = {
  __typename?: 'health_carer_money_request_status_enum_min_fields';
  comment?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type Health_Carer_Money_Request_Status_Enum_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Health_Carer_Money_Request_Status_Enum_Mutation_Response = {
  __typename?: 'health_carer_money_request_status_enum_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Health_Carer_Money_Request_Status_Enum>;
};

export type Health_Carer_Money_Request_Status_Enum_Obj_Rel_Insert_Input = {
  data: Health_Carer_Money_Request_Status_Enum_Insert_Input;
  on_conflict?: InputMaybe<Health_Carer_Money_Request_Status_Enum_On_Conflict>;
};

export type Health_Carer_Money_Request_Status_Enum_On_Conflict = {
  constraint: Health_Carer_Money_Request_Status_Enum_Constraint;
  update_columns: Array<Health_Carer_Money_Request_Status_Enum_Update_Column>;
  where?: InputMaybe<Health_Carer_Money_Request_Status_Enum_Bool_Exp>;
};

export type Health_Carer_Money_Request_Status_Enum_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Health_Carer_Money_Request_Status_Enum_Pk_Columns_Input = {
  text: Scalars['String'];
};

export enum Health_Carer_Money_Request_Status_Enum_Select_Column {
  Comment = 'comment',
  Text = 'text',
}

export type Health_Carer_Money_Request_Status_Enum_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export enum Health_Carer_Money_Request_Status_Enum_Update_Column {
  Comment = 'comment',
  Text = 'text',
}

export type Health_Carer_Money_Request_Stddev_Fields = {
  __typename?: 'health_carer_money_request_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Health_Carer_Money_Request_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type Health_Carer_Money_Request_Stddev_Pop_Fields = {
  __typename?: 'health_carer_money_request_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Health_Carer_Money_Request_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type Health_Carer_Money_Request_Stddev_Samp_Fields = {
  __typename?: 'health_carer_money_request_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Health_Carer_Money_Request_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type Health_Carer_Money_Request_Sum_Fields = {
  __typename?: 'health_carer_money_request_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
};

export type Health_Carer_Money_Request_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export enum Health_Carer_Money_Request_Update_Column {
  Amount = 'amount',
  CreatedAt = 'created_at',
  ExtraData = 'extra_data',
  HealthCarerId = 'health_carer_id',
  Id = 'id',
  Status = 'status',
  UpdatedAt = 'updated_at',
}

export type Health_Carer_Money_Request_Var_Pop_Fields = {
  __typename?: 'health_carer_money_request_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Health_Carer_Money_Request_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type Health_Carer_Money_Request_Var_Samp_Fields = {
  __typename?: 'health_carer_money_request_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Health_Carer_Money_Request_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type Health_Carer_Money_Request_Variance_Fields = {
  __typename?: 'health_carer_money_request_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Health_Carer_Money_Request_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type Health_Carer_Mutation_Response = {
  __typename?: 'health_carer_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Health_Carer>;
};

export type Health_Carer_Obj_Rel_Insert_Input = {
  data: Health_Carer_Insert_Input;
  on_conflict?: InputMaybe<Health_Carer_On_Conflict>;
};

export type Health_Carer_On_Conflict = {
  constraint: Health_Carer_Constraint;
  update_columns: Array<Health_Carer_Update_Column>;
  where?: InputMaybe<Health_Carer_Bool_Exp>;
};

export type Health_Carer_Order_By = {
  companies_aggregate?: InputMaybe<Company_Health_Carer_Aggregate_Order_By>;
  coupons_aggregate?: InputMaybe<Coupon_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  crm?: InputMaybe<Crm_Order_By>;
  email?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  health_carer_money_requests_aggregate?: InputMaybe<Health_Carer_Money_Request_Aggregate_Order_By>;
  health_carer_telehealth?: InputMaybe<Health_Carer_Telehealth_Order_By>;
  health_carer_telehealths_aggregate?: InputMaybe<Health_Carer_Telehealth_Aggregate_Order_By>;
  health_carer_transactions_aggregate?: InputMaybe<Health_Carer_Transactions_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  landing_page_redirect?: InputMaybe<Landing_Page_Redirect_Order_By>;
  membership_kind?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pagarme_recipient?: InputMaybe<Health_Carer_Pagarme_Recipient_Order_By>;
  professional_id?: InputMaybe<Order_By>;
  professional_id_state?: InputMaybe<Order_By>;
  share_path?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  users_aggregate?: InputMaybe<Health_Carer_User_Aggregate_Order_By>;
  whatsapp?: InputMaybe<Order_By>;
};

export type Health_Carer_Pagarme_Recipient = {
  __typename?: 'health_carer_pagarme_recipient';
  account_check_digit: Scalars['String'];
  account_number: Scalars['String'];
  account_type: Scalars['String'];
  bank: Scalars['String'];
  branch_check_digit: Scalars['String'];
  branch_number: Scalars['String'];
  created_at: Scalars['timestamptz'];
  document: Scalars['String'];
  health_carer_id: Scalars['String'];
  pagarme_recipient_id: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

export type Health_Carer_Pagarme_Recipient_Aggregate = {
  __typename?: 'health_carer_pagarme_recipient_aggregate';
  aggregate?: Maybe<Health_Carer_Pagarme_Recipient_Aggregate_Fields>;
  nodes: Array<Health_Carer_Pagarme_Recipient>;
};

export type Health_Carer_Pagarme_Recipient_Aggregate_Fields = {
  __typename?: 'health_carer_pagarme_recipient_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Health_Carer_Pagarme_Recipient_Max_Fields>;
  min?: Maybe<Health_Carer_Pagarme_Recipient_Min_Fields>;
};

export type Health_Carer_Pagarme_Recipient_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Health_Carer_Pagarme_Recipient_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Health_Carer_Pagarme_Recipient_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Health_Carer_Pagarme_Recipient_Max_Order_By>;
  min?: InputMaybe<Health_Carer_Pagarme_Recipient_Min_Order_By>;
};

export type Health_Carer_Pagarme_Recipient_Arr_Rel_Insert_Input = {
  data: Array<Health_Carer_Pagarme_Recipient_Insert_Input>;
  on_conflict?: InputMaybe<Health_Carer_Pagarme_Recipient_On_Conflict>;
};

export type Health_Carer_Pagarme_Recipient_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Health_Carer_Pagarme_Recipient_Bool_Exp>>>;
  _not?: InputMaybe<Health_Carer_Pagarme_Recipient_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Health_Carer_Pagarme_Recipient_Bool_Exp>>>;
  account_check_digit?: InputMaybe<String_Comparison_Exp>;
  account_number?: InputMaybe<String_Comparison_Exp>;
  account_type?: InputMaybe<String_Comparison_Exp>;
  bank?: InputMaybe<String_Comparison_Exp>;
  branch_check_digit?: InputMaybe<String_Comparison_Exp>;
  branch_number?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  document?: InputMaybe<String_Comparison_Exp>;
  health_carer_id?: InputMaybe<String_Comparison_Exp>;
  pagarme_recipient_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

export enum Health_Carer_Pagarme_Recipient_Constraint {
  HealthCarerPagarmeRecipientPagarmeRecipientIdKey = 'health_carer_pagarme_recipient_pagarme_recipient_id_key',
  HealthCarerPagarmeRecipientPkey = 'health_carer_pagarme_recipient_pkey',
}

export type Health_Carer_Pagarme_Recipient_Insert_Input = {
  account_check_digit?: InputMaybe<Scalars['String']>;
  account_number?: InputMaybe<Scalars['String']>;
  account_type?: InputMaybe<Scalars['String']>;
  bank?: InputMaybe<Scalars['String']>;
  branch_check_digit?: InputMaybe<Scalars['String']>;
  branch_number?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  document?: InputMaybe<Scalars['String']>;
  health_carer_id?: InputMaybe<Scalars['String']>;
  pagarme_recipient_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Health_Carer_Pagarme_Recipient_Max_Fields = {
  __typename?: 'health_carer_pagarme_recipient_max_fields';
  account_check_digit?: Maybe<Scalars['String']>;
  account_number?: Maybe<Scalars['String']>;
  account_type?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  branch_check_digit?: Maybe<Scalars['String']>;
  branch_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  document?: Maybe<Scalars['String']>;
  health_carer_id?: Maybe<Scalars['String']>;
  pagarme_recipient_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type Health_Carer_Pagarme_Recipient_Max_Order_By = {
  account_check_digit?: InputMaybe<Order_By>;
  account_number?: InputMaybe<Order_By>;
  account_type?: InputMaybe<Order_By>;
  bank?: InputMaybe<Order_By>;
  branch_check_digit?: InputMaybe<Order_By>;
  branch_number?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  document?: InputMaybe<Order_By>;
  health_carer_id?: InputMaybe<Order_By>;
  pagarme_recipient_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type Health_Carer_Pagarme_Recipient_Min_Fields = {
  __typename?: 'health_carer_pagarme_recipient_min_fields';
  account_check_digit?: Maybe<Scalars['String']>;
  account_number?: Maybe<Scalars['String']>;
  account_type?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  branch_check_digit?: Maybe<Scalars['String']>;
  branch_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  document?: Maybe<Scalars['String']>;
  health_carer_id?: Maybe<Scalars['String']>;
  pagarme_recipient_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type Health_Carer_Pagarme_Recipient_Min_Order_By = {
  account_check_digit?: InputMaybe<Order_By>;
  account_number?: InputMaybe<Order_By>;
  account_type?: InputMaybe<Order_By>;
  bank?: InputMaybe<Order_By>;
  branch_check_digit?: InputMaybe<Order_By>;
  branch_number?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  document?: InputMaybe<Order_By>;
  health_carer_id?: InputMaybe<Order_By>;
  pagarme_recipient_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type Health_Carer_Pagarme_Recipient_Mutation_Response = {
  __typename?: 'health_carer_pagarme_recipient_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Health_Carer_Pagarme_Recipient>;
};

export type Health_Carer_Pagarme_Recipient_Obj_Rel_Insert_Input = {
  data: Health_Carer_Pagarme_Recipient_Insert_Input;
  on_conflict?: InputMaybe<Health_Carer_Pagarme_Recipient_On_Conflict>;
};

export type Health_Carer_Pagarme_Recipient_On_Conflict = {
  constraint: Health_Carer_Pagarme_Recipient_Constraint;
  update_columns: Array<Health_Carer_Pagarme_Recipient_Update_Column>;
  where?: InputMaybe<Health_Carer_Pagarme_Recipient_Bool_Exp>;
};

export type Health_Carer_Pagarme_Recipient_Order_By = {
  account_check_digit?: InputMaybe<Order_By>;
  account_number?: InputMaybe<Order_By>;
  account_type?: InputMaybe<Order_By>;
  bank?: InputMaybe<Order_By>;
  branch_check_digit?: InputMaybe<Order_By>;
  branch_number?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  document?: InputMaybe<Order_By>;
  health_carer_id?: InputMaybe<Order_By>;
  pagarme_recipient_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type Health_Carer_Pagarme_Recipient_Pk_Columns_Input = {
  health_carer_id: Scalars['String'];
};

export enum Health_Carer_Pagarme_Recipient_Select_Column {
  AccountCheckDigit = 'account_check_digit',
  AccountNumber = 'account_number',
  AccountType = 'account_type',
  Bank = 'bank',
  BranchCheckDigit = 'branch_check_digit',
  BranchNumber = 'branch_number',
  CreatedAt = 'created_at',
  Document = 'document',
  HealthCarerId = 'health_carer_id',
  PagarmeRecipientId = 'pagarme_recipient_id',
  UpdatedAt = 'updated_at',
}

export type Health_Carer_Pagarme_Recipient_Set_Input = {
  account_check_digit?: InputMaybe<Scalars['String']>;
  account_number?: InputMaybe<Scalars['String']>;
  account_type?: InputMaybe<Scalars['String']>;
  bank?: InputMaybe<Scalars['String']>;
  branch_check_digit?: InputMaybe<Scalars['String']>;
  branch_number?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  document?: InputMaybe<Scalars['String']>;
  health_carer_id?: InputMaybe<Scalars['String']>;
  pagarme_recipient_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export enum Health_Carer_Pagarme_Recipient_Update_Column {
  AccountCheckDigit = 'account_check_digit',
  AccountNumber = 'account_number',
  AccountType = 'account_type',
  Bank = 'bank',
  BranchCheckDigit = 'branch_check_digit',
  BranchNumber = 'branch_number',
  CreatedAt = 'created_at',
  Document = 'document',
  HealthCarerId = 'health_carer_id',
  PagarmeRecipientId = 'pagarme_recipient_id',
  UpdatedAt = 'updated_at',
}

export type Health_Carer_Pk_Columns_Input = {
  id: Scalars['String'];
};

export enum Health_Carer_Select_Column {
  CreatedAt = 'created_at',
  Email = 'email',
  Gender = 'gender',
  Id = 'id',
  InternalId = 'internal_id',
  Kind = 'kind',
  MembershipKind = 'membership_kind',
  Name = 'name',
  ProfessionalId = 'professional_id',
  ProfessionalIdState = 'professional_id_state',
  SharePath = 'share_path',
  Source = 'source',
  Whatsapp = 'whatsapp',
}

export type Health_Carer_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  internal_id?: InputMaybe<Scalars['Int']>;
  kind?: InputMaybe<Scalars['String']>;
  membership_kind?: InputMaybe<Health_Carer_Membership_Kind_Enum_Enum>;
  name?: InputMaybe<Scalars['String']>;
  professional_id?: InputMaybe<Scalars['String']>;
  professional_id_state?: InputMaybe<Scalars['String']>;
  share_path?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  whatsapp?: InputMaybe<Scalars['String']>;
};

export type Health_Carer_Stddev_Fields = {
  __typename?: 'health_carer_stddev_fields';
  internal_id?: Maybe<Scalars['Float']>;
};

export type Health_Carer_Stddev_Order_By = {
  internal_id?: InputMaybe<Order_By>;
};

export type Health_Carer_Stddev_Pop_Fields = {
  __typename?: 'health_carer_stddev_pop_fields';
  internal_id?: Maybe<Scalars['Float']>;
};

export type Health_Carer_Stddev_Pop_Order_By = {
  internal_id?: InputMaybe<Order_By>;
};

export type Health_Carer_Stddev_Samp_Fields = {
  __typename?: 'health_carer_stddev_samp_fields';
  internal_id?: Maybe<Scalars['Float']>;
};

export type Health_Carer_Stddev_Samp_Order_By = {
  internal_id?: InputMaybe<Order_By>;
};

export type Health_Carer_Sum_Fields = {
  __typename?: 'health_carer_sum_fields';
  internal_id?: Maybe<Scalars['Int']>;
};

export type Health_Carer_Sum_Order_By = {
  internal_id?: InputMaybe<Order_By>;
};

export type Health_Carer_Telehealth = {
  __typename?: 'health_carer_telehealth';
  active: Scalars['Boolean'];
  approaches?: Maybe<Scalars['jsonb']>;
  created_at: Scalars['timestamptz'];
  dav_id: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  education?: Maybe<Scalars['jsonb']>;
  full_description?: Maybe<Scalars['String']>;
  google_calendar_id?: Maybe<Scalars['String']>;
  health_carer: Health_Carer;
  health_carer_id: Scalars['String'];
  profile_url?: Maybe<Scalars['String']>;
  sort_weight: Scalars['Int'];
  themes?: Maybe<Scalars['jsonb']>;
};

export type Health_Carer_TelehealthApproachesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

export type Health_Carer_TelehealthEducationArgs = {
  path?: InputMaybe<Scalars['String']>;
};

export type Health_Carer_TelehealthThemesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

export type Health_Carer_Telehealth_Aggregate = {
  __typename?: 'health_carer_telehealth_aggregate';
  aggregate?: Maybe<Health_Carer_Telehealth_Aggregate_Fields>;
  nodes: Array<Health_Carer_Telehealth>;
};

export type Health_Carer_Telehealth_Aggregate_Fields = {
  __typename?: 'health_carer_telehealth_aggregate_fields';
  avg?: Maybe<Health_Carer_Telehealth_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Health_Carer_Telehealth_Max_Fields>;
  min?: Maybe<Health_Carer_Telehealth_Min_Fields>;
  stddev?: Maybe<Health_Carer_Telehealth_Stddev_Fields>;
  stddev_pop?: Maybe<Health_Carer_Telehealth_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Health_Carer_Telehealth_Stddev_Samp_Fields>;
  sum?: Maybe<Health_Carer_Telehealth_Sum_Fields>;
  var_pop?: Maybe<Health_Carer_Telehealth_Var_Pop_Fields>;
  var_samp?: Maybe<Health_Carer_Telehealth_Var_Samp_Fields>;
  variance?: Maybe<Health_Carer_Telehealth_Variance_Fields>;
};

export type Health_Carer_Telehealth_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Health_Carer_Telehealth_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Health_Carer_Telehealth_Aggregate_Order_By = {
  avg?: InputMaybe<Health_Carer_Telehealth_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Health_Carer_Telehealth_Max_Order_By>;
  min?: InputMaybe<Health_Carer_Telehealth_Min_Order_By>;
  stddev?: InputMaybe<Health_Carer_Telehealth_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Health_Carer_Telehealth_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Health_Carer_Telehealth_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Health_Carer_Telehealth_Sum_Order_By>;
  var_pop?: InputMaybe<Health_Carer_Telehealth_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Health_Carer_Telehealth_Var_Samp_Order_By>;
  variance?: InputMaybe<Health_Carer_Telehealth_Variance_Order_By>;
};

export type Health_Carer_Telehealth_Append_Input = {
  approaches?: InputMaybe<Scalars['jsonb']>;
  education?: InputMaybe<Scalars['jsonb']>;
  themes?: InputMaybe<Scalars['jsonb']>;
};

export type Health_Carer_Telehealth_Arr_Rel_Insert_Input = {
  data: Array<Health_Carer_Telehealth_Insert_Input>;
  on_conflict?: InputMaybe<Health_Carer_Telehealth_On_Conflict>;
};

export type Health_Carer_Telehealth_Avg_Fields = {
  __typename?: 'health_carer_telehealth_avg_fields';
  sort_weight?: Maybe<Scalars['Float']>;
};

export type Health_Carer_Telehealth_Avg_Order_By = {
  sort_weight?: InputMaybe<Order_By>;
};

export type Health_Carer_Telehealth_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Health_Carer_Telehealth_Bool_Exp>>>;
  _not?: InputMaybe<Health_Carer_Telehealth_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Health_Carer_Telehealth_Bool_Exp>>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  approaches?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  dav_id?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  education?: InputMaybe<Jsonb_Comparison_Exp>;
  full_description?: InputMaybe<String_Comparison_Exp>;
  google_calendar_id?: InputMaybe<String_Comparison_Exp>;
  health_carer?: InputMaybe<Health_Carer_Bool_Exp>;
  health_carer_id?: InputMaybe<String_Comparison_Exp>;
  profile_url?: InputMaybe<String_Comparison_Exp>;
  sort_weight?: InputMaybe<Int_Comparison_Exp>;
  themes?: InputMaybe<Jsonb_Comparison_Exp>;
};

export enum Health_Carer_Telehealth_Constraint {
  HealthCarerDrAoVivoProfessionalDavIdKey = 'health_carer_dr_ao_vivo_professional_dav_id_key',
  HealthCarerDrAoVivoProfessionalPkey = 'health_carer_dr_ao_vivo_professional_pkey',
}

export type Health_Carer_Telehealth_Delete_At_Path_Input = {
  approaches?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  education?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  themes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Health_Carer_Telehealth_Delete_Elem_Input = {
  approaches?: InputMaybe<Scalars['Int']>;
  education?: InputMaybe<Scalars['Int']>;
  themes?: InputMaybe<Scalars['Int']>;
};

export type Health_Carer_Telehealth_Delete_Key_Input = {
  approaches?: InputMaybe<Scalars['String']>;
  education?: InputMaybe<Scalars['String']>;
  themes?: InputMaybe<Scalars['String']>;
};

export type Health_Carer_Telehealth_Inc_Input = {
  sort_weight?: InputMaybe<Scalars['Int']>;
};

export type Health_Carer_Telehealth_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  approaches?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dav_id?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  education?: InputMaybe<Scalars['jsonb']>;
  full_description?: InputMaybe<Scalars['String']>;
  google_calendar_id?: InputMaybe<Scalars['String']>;
  health_carer?: InputMaybe<Health_Carer_Obj_Rel_Insert_Input>;
  health_carer_id?: InputMaybe<Scalars['String']>;
  profile_url?: InputMaybe<Scalars['String']>;
  sort_weight?: InputMaybe<Scalars['Int']>;
  themes?: InputMaybe<Scalars['jsonb']>;
};

export type Health_Carer_Telehealth_Max_Fields = {
  __typename?: 'health_carer_telehealth_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  dav_id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  full_description?: Maybe<Scalars['String']>;
  google_calendar_id?: Maybe<Scalars['String']>;
  health_carer_id?: Maybe<Scalars['String']>;
  profile_url?: Maybe<Scalars['String']>;
  sort_weight?: Maybe<Scalars['Int']>;
};

export type Health_Carer_Telehealth_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  dav_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  full_description?: InputMaybe<Order_By>;
  google_calendar_id?: InputMaybe<Order_By>;
  health_carer_id?: InputMaybe<Order_By>;
  profile_url?: InputMaybe<Order_By>;
  sort_weight?: InputMaybe<Order_By>;
};

export type Health_Carer_Telehealth_Min_Fields = {
  __typename?: 'health_carer_telehealth_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  dav_id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  full_description?: Maybe<Scalars['String']>;
  google_calendar_id?: Maybe<Scalars['String']>;
  health_carer_id?: Maybe<Scalars['String']>;
  profile_url?: Maybe<Scalars['String']>;
  sort_weight?: Maybe<Scalars['Int']>;
};

export type Health_Carer_Telehealth_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  dav_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  full_description?: InputMaybe<Order_By>;
  google_calendar_id?: InputMaybe<Order_By>;
  health_carer_id?: InputMaybe<Order_By>;
  profile_url?: InputMaybe<Order_By>;
  sort_weight?: InputMaybe<Order_By>;
};

export type Health_Carer_Telehealth_Mutation_Response = {
  __typename?: 'health_carer_telehealth_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Health_Carer_Telehealth>;
};

export type Health_Carer_Telehealth_Obj_Rel_Insert_Input = {
  data: Health_Carer_Telehealth_Insert_Input;
  on_conflict?: InputMaybe<Health_Carer_Telehealth_On_Conflict>;
};

export type Health_Carer_Telehealth_On_Conflict = {
  constraint: Health_Carer_Telehealth_Constraint;
  update_columns: Array<Health_Carer_Telehealth_Update_Column>;
  where?: InputMaybe<Health_Carer_Telehealth_Bool_Exp>;
};

export type Health_Carer_Telehealth_Order_By = {
  active?: InputMaybe<Order_By>;
  approaches?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dav_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  education?: InputMaybe<Order_By>;
  full_description?: InputMaybe<Order_By>;
  google_calendar_id?: InputMaybe<Order_By>;
  health_carer?: InputMaybe<Health_Carer_Order_By>;
  health_carer_id?: InputMaybe<Order_By>;
  profile_url?: InputMaybe<Order_By>;
  sort_weight?: InputMaybe<Order_By>;
  themes?: InputMaybe<Order_By>;
};

export type Health_Carer_Telehealth_Pk_Columns_Input = {
  health_carer_id: Scalars['String'];
};

export type Health_Carer_Telehealth_Prepend_Input = {
  approaches?: InputMaybe<Scalars['jsonb']>;
  education?: InputMaybe<Scalars['jsonb']>;
  themes?: InputMaybe<Scalars['jsonb']>;
};

export enum Health_Carer_Telehealth_Select_Column {
  Active = 'active',
  Approaches = 'approaches',
  CreatedAt = 'created_at',
  DavId = 'dav_id',
  Description = 'description',
  Education = 'education',
  FullDescription = 'full_description',
  GoogleCalendarId = 'google_calendar_id',
  HealthCarerId = 'health_carer_id',
  ProfileUrl = 'profile_url',
  SortWeight = 'sort_weight',
  Themes = 'themes',
}

export type Health_Carer_Telehealth_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  approaches?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dav_id?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  education?: InputMaybe<Scalars['jsonb']>;
  full_description?: InputMaybe<Scalars['String']>;
  google_calendar_id?: InputMaybe<Scalars['String']>;
  health_carer_id?: InputMaybe<Scalars['String']>;
  profile_url?: InputMaybe<Scalars['String']>;
  sort_weight?: InputMaybe<Scalars['Int']>;
  themes?: InputMaybe<Scalars['jsonb']>;
};

export type Health_Carer_Telehealth_Stddev_Fields = {
  __typename?: 'health_carer_telehealth_stddev_fields';
  sort_weight?: Maybe<Scalars['Float']>;
};

export type Health_Carer_Telehealth_Stddev_Order_By = {
  sort_weight?: InputMaybe<Order_By>;
};

export type Health_Carer_Telehealth_Stddev_Pop_Fields = {
  __typename?: 'health_carer_telehealth_stddev_pop_fields';
  sort_weight?: Maybe<Scalars['Float']>;
};

export type Health_Carer_Telehealth_Stddev_Pop_Order_By = {
  sort_weight?: InputMaybe<Order_By>;
};

export type Health_Carer_Telehealth_Stddev_Samp_Fields = {
  __typename?: 'health_carer_telehealth_stddev_samp_fields';
  sort_weight?: Maybe<Scalars['Float']>;
};

export type Health_Carer_Telehealth_Stddev_Samp_Order_By = {
  sort_weight?: InputMaybe<Order_By>;
};

export type Health_Carer_Telehealth_Sum_Fields = {
  __typename?: 'health_carer_telehealth_sum_fields';
  sort_weight?: Maybe<Scalars['Int']>;
};

export type Health_Carer_Telehealth_Sum_Order_By = {
  sort_weight?: InputMaybe<Order_By>;
};

export enum Health_Carer_Telehealth_Update_Column {
  Active = 'active',
  Approaches = 'approaches',
  CreatedAt = 'created_at',
  DavId = 'dav_id',
  Description = 'description',
  Education = 'education',
  FullDescription = 'full_description',
  GoogleCalendarId = 'google_calendar_id',
  HealthCarerId = 'health_carer_id',
  ProfileUrl = 'profile_url',
  SortWeight = 'sort_weight',
  Themes = 'themes',
}

export type Health_Carer_Telehealth_Var_Pop_Fields = {
  __typename?: 'health_carer_telehealth_var_pop_fields';
  sort_weight?: Maybe<Scalars['Float']>;
};

export type Health_Carer_Telehealth_Var_Pop_Order_By = {
  sort_weight?: InputMaybe<Order_By>;
};

export type Health_Carer_Telehealth_Var_Samp_Fields = {
  __typename?: 'health_carer_telehealth_var_samp_fields';
  sort_weight?: Maybe<Scalars['Float']>;
};

export type Health_Carer_Telehealth_Var_Samp_Order_By = {
  sort_weight?: InputMaybe<Order_By>;
};

export type Health_Carer_Telehealth_Variance_Fields = {
  __typename?: 'health_carer_telehealth_variance_fields';
  sort_weight?: Maybe<Scalars['Float']>;
};

export type Health_Carer_Telehealth_Variance_Order_By = {
  sort_weight?: InputMaybe<Order_By>;
};

export type Health_Carer_Transactions = {
  __typename?: 'health_carer_transactions';
  amount: Scalars['numeric'];
  comment?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  extra_data?: Maybe<Scalars['jsonb']>;
  from_user?: Maybe<User>;
  from_user_id?: Maybe<Scalars['String']>;
  health_carer_id: Scalars['String'];
  id: Scalars['bigint'];
  updated_at: Scalars['timestamptz'];
};

export type Health_Carer_TransactionsExtra_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

export type Health_Carer_Transactions_Aggregate = {
  __typename?: 'health_carer_transactions_aggregate';
  aggregate?: Maybe<Health_Carer_Transactions_Aggregate_Fields>;
  nodes: Array<Health_Carer_Transactions>;
};

export type Health_Carer_Transactions_Aggregate_Fields = {
  __typename?: 'health_carer_transactions_aggregate_fields';
  avg?: Maybe<Health_Carer_Transactions_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Health_Carer_Transactions_Max_Fields>;
  min?: Maybe<Health_Carer_Transactions_Min_Fields>;
  stddev?: Maybe<Health_Carer_Transactions_Stddev_Fields>;
  stddev_pop?: Maybe<Health_Carer_Transactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Health_Carer_Transactions_Stddev_Samp_Fields>;
  sum?: Maybe<Health_Carer_Transactions_Sum_Fields>;
  var_pop?: Maybe<Health_Carer_Transactions_Var_Pop_Fields>;
  var_samp?: Maybe<Health_Carer_Transactions_Var_Samp_Fields>;
  variance?: Maybe<Health_Carer_Transactions_Variance_Fields>;
};

export type Health_Carer_Transactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Health_Carer_Transactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Health_Carer_Transactions_Aggregate_Order_By = {
  avg?: InputMaybe<Health_Carer_Transactions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Health_Carer_Transactions_Max_Order_By>;
  min?: InputMaybe<Health_Carer_Transactions_Min_Order_By>;
  stddev?: InputMaybe<Health_Carer_Transactions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Health_Carer_Transactions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Health_Carer_Transactions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Health_Carer_Transactions_Sum_Order_By>;
  var_pop?: InputMaybe<Health_Carer_Transactions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Health_Carer_Transactions_Var_Samp_Order_By>;
  variance?: InputMaybe<Health_Carer_Transactions_Variance_Order_By>;
};

export type Health_Carer_Transactions_Append_Input = {
  extra_data?: InputMaybe<Scalars['jsonb']>;
};

export type Health_Carer_Transactions_Arr_Rel_Insert_Input = {
  data: Array<Health_Carer_Transactions_Insert_Input>;
  on_conflict?: InputMaybe<Health_Carer_Transactions_On_Conflict>;
};

export type Health_Carer_Transactions_Avg_Fields = {
  __typename?: 'health_carer_transactions_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Health_Carer_Transactions_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type Health_Carer_Transactions_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Health_Carer_Transactions_Bool_Exp>>>;
  _not?: InputMaybe<Health_Carer_Transactions_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Health_Carer_Transactions_Bool_Exp>>>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  extra_data?: InputMaybe<Jsonb_Comparison_Exp>;
  from_user?: InputMaybe<User_Bool_Exp>;
  from_user_id?: InputMaybe<String_Comparison_Exp>;
  health_carer_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

export enum Health_Carer_Transactions_Constraint {
  HealthCarerTransactionsPkey = 'health_carer_transactions_pkey',
}

export type Health_Carer_Transactions_Delete_At_Path_Input = {
  extra_data?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Health_Carer_Transactions_Delete_Elem_Input = {
  extra_data?: InputMaybe<Scalars['Int']>;
};

export type Health_Carer_Transactions_Delete_Key_Input = {
  extra_data?: InputMaybe<Scalars['String']>;
};

export type Health_Carer_Transactions_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['bigint']>;
};

export type Health_Carer_Transactions_Insert_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  extra_data?: InputMaybe<Scalars['jsonb']>;
  from_user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  from_user_id?: InputMaybe<Scalars['String']>;
  health_carer_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Health_Carer_Transactions_Max_Fields = {
  __typename?: 'health_carer_transactions_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  from_user_id?: Maybe<Scalars['String']>;
  health_carer_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type Health_Carer_Transactions_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  from_user_id?: InputMaybe<Order_By>;
  health_carer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type Health_Carer_Transactions_Min_Fields = {
  __typename?: 'health_carer_transactions_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  from_user_id?: Maybe<Scalars['String']>;
  health_carer_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type Health_Carer_Transactions_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  from_user_id?: InputMaybe<Order_By>;
  health_carer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type Health_Carer_Transactions_Mutation_Response = {
  __typename?: 'health_carer_transactions_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Health_Carer_Transactions>;
};

export type Health_Carer_Transactions_Obj_Rel_Insert_Input = {
  data: Health_Carer_Transactions_Insert_Input;
  on_conflict?: InputMaybe<Health_Carer_Transactions_On_Conflict>;
};

export type Health_Carer_Transactions_On_Conflict = {
  constraint: Health_Carer_Transactions_Constraint;
  update_columns: Array<Health_Carer_Transactions_Update_Column>;
  where?: InputMaybe<Health_Carer_Transactions_Bool_Exp>;
};

export type Health_Carer_Transactions_Order_By = {
  amount?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  extra_data?: InputMaybe<Order_By>;
  from_user?: InputMaybe<User_Order_By>;
  from_user_id?: InputMaybe<Order_By>;
  health_carer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type Health_Carer_Transactions_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

export type Health_Carer_Transactions_Prepend_Input = {
  extra_data?: InputMaybe<Scalars['jsonb']>;
};

export enum Health_Carer_Transactions_Select_Column {
  Amount = 'amount',
  Comment = 'comment',
  CreatedAt = 'created_at',
  Description = 'description',
  ExtraData = 'extra_data',
  FromUserId = 'from_user_id',
  HealthCarerId = 'health_carer_id',
  Id = 'id',
  UpdatedAt = 'updated_at',
}

export type Health_Carer_Transactions_Set_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  extra_data?: InputMaybe<Scalars['jsonb']>;
  from_user_id?: InputMaybe<Scalars['String']>;
  health_carer_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Health_Carer_Transactions_Stddev_Fields = {
  __typename?: 'health_carer_transactions_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Health_Carer_Transactions_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type Health_Carer_Transactions_Stddev_Pop_Fields = {
  __typename?: 'health_carer_transactions_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Health_Carer_Transactions_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type Health_Carer_Transactions_Stddev_Samp_Fields = {
  __typename?: 'health_carer_transactions_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Health_Carer_Transactions_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type Health_Carer_Transactions_Sum_Fields = {
  __typename?: 'health_carer_transactions_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
};

export type Health_Carer_Transactions_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export enum Health_Carer_Transactions_Update_Column {
  Amount = 'amount',
  Comment = 'comment',
  CreatedAt = 'created_at',
  Description = 'description',
  ExtraData = 'extra_data',
  FromUserId = 'from_user_id',
  HealthCarerId = 'health_carer_id',
  Id = 'id',
  UpdatedAt = 'updated_at',
}

export type Health_Carer_Transactions_Var_Pop_Fields = {
  __typename?: 'health_carer_transactions_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Health_Carer_Transactions_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type Health_Carer_Transactions_Var_Samp_Fields = {
  __typename?: 'health_carer_transactions_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Health_Carer_Transactions_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type Health_Carer_Transactions_Variance_Fields = {
  __typename?: 'health_carer_transactions_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Health_Carer_Transactions_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export enum Health_Carer_Update_Column {
  CreatedAt = 'created_at',
  Email = 'email',
  Gender = 'gender',
  Id = 'id',
  InternalId = 'internal_id',
  Kind = 'kind',
  MembershipKind = 'membership_kind',
  Name = 'name',
  ProfessionalId = 'professional_id',
  ProfessionalIdState = 'professional_id_state',
  SharePath = 'share_path',
  Source = 'source',
  Whatsapp = 'whatsapp',
}

export type Health_Carer_User = {
  __typename?: 'health_carer_user';
  created_at?: Maybe<Scalars['timestamptz']>;
  health_carer: Health_Carer;
  health_carer_id: Scalars['String'];
  id: Scalars['Int'];
  user: User;
  user_id: Scalars['String'];
};

export type Health_Carer_User_Aggregate = {
  __typename?: 'health_carer_user_aggregate';
  aggregate?: Maybe<Health_Carer_User_Aggregate_Fields>;
  nodes: Array<Health_Carer_User>;
};

export type Health_Carer_User_Aggregate_Fields = {
  __typename?: 'health_carer_user_aggregate_fields';
  avg?: Maybe<Health_Carer_User_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Health_Carer_User_Max_Fields>;
  min?: Maybe<Health_Carer_User_Min_Fields>;
  stddev?: Maybe<Health_Carer_User_Stddev_Fields>;
  stddev_pop?: Maybe<Health_Carer_User_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Health_Carer_User_Stddev_Samp_Fields>;
  sum?: Maybe<Health_Carer_User_Sum_Fields>;
  var_pop?: Maybe<Health_Carer_User_Var_Pop_Fields>;
  var_samp?: Maybe<Health_Carer_User_Var_Samp_Fields>;
  variance?: Maybe<Health_Carer_User_Variance_Fields>;
};

export type Health_Carer_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Health_Carer_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Health_Carer_User_Aggregate_Order_By = {
  avg?: InputMaybe<Health_Carer_User_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Health_Carer_User_Max_Order_By>;
  min?: InputMaybe<Health_Carer_User_Min_Order_By>;
  stddev?: InputMaybe<Health_Carer_User_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Health_Carer_User_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Health_Carer_User_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Health_Carer_User_Sum_Order_By>;
  var_pop?: InputMaybe<Health_Carer_User_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Health_Carer_User_Var_Samp_Order_By>;
  variance?: InputMaybe<Health_Carer_User_Variance_Order_By>;
};

export type Health_Carer_User_Arr_Rel_Insert_Input = {
  data: Array<Health_Carer_User_Insert_Input>;
  on_conflict?: InputMaybe<Health_Carer_User_On_Conflict>;
};

export type Health_Carer_User_Avg_Fields = {
  __typename?: 'health_carer_user_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Health_Carer_User_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Health_Carer_User_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Health_Carer_User_Bool_Exp>>>;
  _not?: InputMaybe<Health_Carer_User_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Health_Carer_User_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  health_carer?: InputMaybe<Health_Carer_Bool_Exp>;
  health_carer_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

export enum Health_Carer_User_Constraint {
  HealthCarerUserIdKey = 'health_carer_user_id_key',
  HealthCarerUserPkey = 'health_carer_user_pkey',
}

export type Health_Carer_User_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

export type Health_Carer_User_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  health_carer?: InputMaybe<Health_Carer_Obj_Rel_Insert_Input>;
  health_carer_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Health_Carer_User_Max_Fields = {
  __typename?: 'health_carer_user_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  health_carer_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Health_Carer_User_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  health_carer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Health_Carer_User_Min_Fields = {
  __typename?: 'health_carer_user_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  health_carer_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Health_Carer_User_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  health_carer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Health_Carer_User_Mutation_Response = {
  __typename?: 'health_carer_user_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Health_Carer_User>;
};

export type Health_Carer_User_Obj_Rel_Insert_Input = {
  data: Health_Carer_User_Insert_Input;
  on_conflict?: InputMaybe<Health_Carer_User_On_Conflict>;
};

export type Health_Carer_User_On_Conflict = {
  constraint: Health_Carer_User_Constraint;
  update_columns: Array<Health_Carer_User_Update_Column>;
  where?: InputMaybe<Health_Carer_User_Bool_Exp>;
};

export type Health_Carer_User_Order_By = {
  created_at?: InputMaybe<Order_By>;
  health_carer?: InputMaybe<Health_Carer_Order_By>;
  health_carer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Health_Carer_User_Pk_Columns_Input = {
  health_carer_id: Scalars['String'];
  user_id: Scalars['String'];
};

export enum Health_Carer_User_Select_Column {
  CreatedAt = 'created_at',
  HealthCarerId = 'health_carer_id',
  Id = 'id',
  UserId = 'user_id',
}

export type Health_Carer_User_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  health_carer_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Health_Carer_User_Stddev_Fields = {
  __typename?: 'health_carer_user_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Health_Carer_User_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Health_Carer_User_Stddev_Pop_Fields = {
  __typename?: 'health_carer_user_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Health_Carer_User_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Health_Carer_User_Stddev_Samp_Fields = {
  __typename?: 'health_carer_user_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Health_Carer_User_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Health_Carer_User_Sum_Fields = {
  __typename?: 'health_carer_user_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

export type Health_Carer_User_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

export enum Health_Carer_User_Update_Column {
  CreatedAt = 'created_at',
  HealthCarerId = 'health_carer_id',
  Id = 'id',
  UserId = 'user_id',
}

export type Health_Carer_User_Var_Pop_Fields = {
  __typename?: 'health_carer_user_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Health_Carer_User_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Health_Carer_User_Var_Samp_Fields = {
  __typename?: 'health_carer_user_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Health_Carer_User_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Health_Carer_User_Variance_Fields = {
  __typename?: 'health_carer_user_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Health_Carer_User_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Health_Carer_Var_Pop_Fields = {
  __typename?: 'health_carer_var_pop_fields';
  internal_id?: Maybe<Scalars['Float']>;
};

export type Health_Carer_Var_Pop_Order_By = {
  internal_id?: InputMaybe<Order_By>;
};

export type Health_Carer_Var_Samp_Fields = {
  __typename?: 'health_carer_var_samp_fields';
  internal_id?: Maybe<Scalars['Float']>;
};

export type Health_Carer_Var_Samp_Order_By = {
  internal_id?: InputMaybe<Order_By>;
};

export type Health_Carer_Variance_Fields = {
  __typename?: 'health_carer_variance_fields';
  internal_id?: Maybe<Scalars['Float']>;
};

export type Health_Carer_Variance_Order_By = {
  internal_id?: InputMaybe<Order_By>;
};

export type Isi = {
  __typename?: 'isi';
  age?: Maybe<Scalars['numeric']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  q1: Scalars['Int'];
  q2: Scalars['Int'];
  q3: Scalars['Int'];
  q4: Scalars['Int'];
  q5: Scalars['Int'];
  q6: Scalars['Int'];
  q7: Scalars['Int'];
  user?: Maybe<User>;
  user_id: Scalars['String'];
};

export type Isi_Aggregate = {
  __typename?: 'isi_aggregate';
  aggregate?: Maybe<Isi_Aggregate_Fields>;
  nodes: Array<Isi>;
};

export type Isi_Aggregate_Fields = {
  __typename?: 'isi_aggregate_fields';
  avg?: Maybe<Isi_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Isi_Max_Fields>;
  min?: Maybe<Isi_Min_Fields>;
  stddev?: Maybe<Isi_Stddev_Fields>;
  stddev_pop?: Maybe<Isi_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Isi_Stddev_Samp_Fields>;
  sum?: Maybe<Isi_Sum_Fields>;
  var_pop?: Maybe<Isi_Var_Pop_Fields>;
  var_samp?: Maybe<Isi_Var_Samp_Fields>;
  variance?: Maybe<Isi_Variance_Fields>;
};

export type Isi_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Isi_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Isi_Aggregate_Order_By = {
  avg?: InputMaybe<Isi_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Isi_Max_Order_By>;
  min?: InputMaybe<Isi_Min_Order_By>;
  stddev?: InputMaybe<Isi_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Isi_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Isi_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Isi_Sum_Order_By>;
  var_pop?: InputMaybe<Isi_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Isi_Var_Samp_Order_By>;
  variance?: InputMaybe<Isi_Variance_Order_By>;
};

export type Isi_Arr_Rel_Insert_Input = {
  data: Array<Isi_Insert_Input>;
  on_conflict?: InputMaybe<Isi_On_Conflict>;
};

export type Isi_Avg_Fields = {
  __typename?: 'isi_avg_fields';
  age?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
};

export type Isi_Avg_Order_By = {
  age?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
};

export type Isi_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Isi_Bool_Exp>>>;
  _not?: InputMaybe<Isi_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Isi_Bool_Exp>>>;
  age?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  q1?: InputMaybe<Int_Comparison_Exp>;
  q2?: InputMaybe<Int_Comparison_Exp>;
  q3?: InputMaybe<Int_Comparison_Exp>;
  q4?: InputMaybe<Int_Comparison_Exp>;
  q5?: InputMaybe<Int_Comparison_Exp>;
  q6?: InputMaybe<Int_Comparison_Exp>;
  q7?: InputMaybe<Int_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

export enum Isi_Constraint {
  IsiPkey = 'isi_pkey',
}

export type Isi_Inc_Input = {
  age?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['Int']>;
  q2?: InputMaybe<Scalars['Int']>;
  q3?: InputMaybe<Scalars['Int']>;
  q4?: InputMaybe<Scalars['Int']>;
  q5?: InputMaybe<Scalars['Int']>;
  q6?: InputMaybe<Scalars['Int']>;
  q7?: InputMaybe<Scalars['Int']>;
};

export type Isi_Insert_Input = {
  age?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['Int']>;
  q2?: InputMaybe<Scalars['Int']>;
  q3?: InputMaybe<Scalars['Int']>;
  q4?: InputMaybe<Scalars['Int']>;
  q5?: InputMaybe<Scalars['Int']>;
  q6?: InputMaybe<Scalars['Int']>;
  q7?: InputMaybe<Scalars['Int']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Isi_Max_Fields = {
  __typename?: 'isi_max_fields';
  age?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['Int']>;
  q2?: Maybe<Scalars['Int']>;
  q3?: Maybe<Scalars['Int']>;
  q4?: Maybe<Scalars['Int']>;
  q5?: Maybe<Scalars['Int']>;
  q6?: Maybe<Scalars['Int']>;
  q7?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Isi_Max_Order_By = {
  age?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Isi_Min_Fields = {
  __typename?: 'isi_min_fields';
  age?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['Int']>;
  q2?: Maybe<Scalars['Int']>;
  q3?: Maybe<Scalars['Int']>;
  q4?: Maybe<Scalars['Int']>;
  q5?: Maybe<Scalars['Int']>;
  q6?: Maybe<Scalars['Int']>;
  q7?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Isi_Min_Order_By = {
  age?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Isi_Mutation_Response = {
  __typename?: 'isi_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Isi>;
};

export type Isi_Obj_Rel_Insert_Input = {
  data: Isi_Insert_Input;
  on_conflict?: InputMaybe<Isi_On_Conflict>;
};

export type Isi_On_Conflict = {
  constraint: Isi_Constraint;
  update_columns: Array<Isi_Update_Column>;
  where?: InputMaybe<Isi_Bool_Exp>;
};

export type Isi_Order_By = {
  age?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Isi_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export enum Isi_Select_Column {
  Age = 'age',
  CreatedAt = 'created_at',
  Id = 'id',
  Q1 = 'q1',
  Q2 = 'q2',
  Q3 = 'q3',
  Q4 = 'q4',
  Q5 = 'q5',
  Q6 = 'q6',
  Q7 = 'q7',
  UserId = 'user_id',
}

export type Isi_Set_Input = {
  age?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['Int']>;
  q2?: InputMaybe<Scalars['Int']>;
  q3?: InputMaybe<Scalars['Int']>;
  q4?: InputMaybe<Scalars['Int']>;
  q5?: InputMaybe<Scalars['Int']>;
  q6?: InputMaybe<Scalars['Int']>;
  q7?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Isi_Stddev_Fields = {
  __typename?: 'isi_stddev_fields';
  age?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
};

export type Isi_Stddev_Order_By = {
  age?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
};

export type Isi_Stddev_Pop_Fields = {
  __typename?: 'isi_stddev_pop_fields';
  age?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
};

export type Isi_Stddev_Pop_Order_By = {
  age?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
};

export type Isi_Stddev_Samp_Fields = {
  __typename?: 'isi_stddev_samp_fields';
  age?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
};

export type Isi_Stddev_Samp_Order_By = {
  age?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
};

export type Isi_Sum_Fields = {
  __typename?: 'isi_sum_fields';
  age?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['Int']>;
  q2?: Maybe<Scalars['Int']>;
  q3?: Maybe<Scalars['Int']>;
  q4?: Maybe<Scalars['Int']>;
  q5?: Maybe<Scalars['Int']>;
  q6?: Maybe<Scalars['Int']>;
  q7?: Maybe<Scalars['Int']>;
};

export type Isi_Sum_Order_By = {
  age?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
};

export enum Isi_Update_Column {
  Age = 'age',
  CreatedAt = 'created_at',
  Id = 'id',
  Q1 = 'q1',
  Q2 = 'q2',
  Q3 = 'q3',
  Q4 = 'q4',
  Q5 = 'q5',
  Q6 = 'q6',
  Q7 = 'q7',
  UserId = 'user_id',
}

export type Isi_Var_Pop_Fields = {
  __typename?: 'isi_var_pop_fields';
  age?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
};

export type Isi_Var_Pop_Order_By = {
  age?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
};

export type Isi_Var_Samp_Fields = {
  __typename?: 'isi_var_samp_fields';
  age?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
};

export type Isi_Var_Samp_Order_By = {
  age?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
};

export type Isi_Variance_Fields = {
  __typename?: 'isi_variance_fields';
  age?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
};

export type Isi_Variance_Order_By = {
  age?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
};

export type Journey = {
  __typename?: 'journey';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  image_url: Scalars['String'];
  journey_program_sessions: Array<Journey_Program_Sessions>;
  journey_program_sessions_aggregate: Journey_Program_Sessions_Aggregate;
  min_version_android?: Maybe<Scalars['String']>;
  min_version_ios?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

export type JourneyJourney_Program_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Journey_Program_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Journey_Program_Sessions_Order_By>>;
  where?: InputMaybe<Journey_Program_Sessions_Bool_Exp>;
};

export type JourneyJourney_Program_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Journey_Program_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Journey_Program_Sessions_Order_By>>;
  where?: InputMaybe<Journey_Program_Sessions_Bool_Exp>;
};

export type Journey_Aggregate = {
  __typename?: 'journey_aggregate';
  aggregate?: Maybe<Journey_Aggregate_Fields>;
  nodes: Array<Journey>;
};

export type Journey_Aggregate_Fields = {
  __typename?: 'journey_aggregate_fields';
  avg?: Maybe<Journey_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Journey_Max_Fields>;
  min?: Maybe<Journey_Min_Fields>;
  stddev?: Maybe<Journey_Stddev_Fields>;
  stddev_pop?: Maybe<Journey_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Journey_Stddev_Samp_Fields>;
  sum?: Maybe<Journey_Sum_Fields>;
  var_pop?: Maybe<Journey_Var_Pop_Fields>;
  var_samp?: Maybe<Journey_Var_Samp_Fields>;
  variance?: Maybe<Journey_Variance_Fields>;
};

export type Journey_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Journey_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Journey_Aggregate_Order_By = {
  avg?: InputMaybe<Journey_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Journey_Max_Order_By>;
  min?: InputMaybe<Journey_Min_Order_By>;
  stddev?: InputMaybe<Journey_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Journey_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Journey_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Journey_Sum_Order_By>;
  var_pop?: InputMaybe<Journey_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Journey_Var_Samp_Order_By>;
  variance?: InputMaybe<Journey_Variance_Order_By>;
};

export type Journey_Arr_Rel_Insert_Input = {
  data: Array<Journey_Insert_Input>;
  on_conflict?: InputMaybe<Journey_On_Conflict>;
};

export type Journey_Avg_Fields = {
  __typename?: 'journey_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Journey_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Journey_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Journey_Bool_Exp>>>;
  _not?: InputMaybe<Journey_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Journey_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  image_url?: InputMaybe<String_Comparison_Exp>;
  journey_program_sessions?: InputMaybe<Journey_Program_Sessions_Bool_Exp>;
  min_version_android?: InputMaybe<String_Comparison_Exp>;
  min_version_ios?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

export enum Journey_Constraint {
  JourneyPkey = 'journey_pkey',
}

export type Journey_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

export type Journey_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  image_url?: InputMaybe<Scalars['String']>;
  journey_program_sessions?: InputMaybe<Journey_Program_Sessions_Arr_Rel_Insert_Input>;
  min_version_android?: InputMaybe<Scalars['String']>;
  min_version_ios?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Journey_Max_Fields = {
  __typename?: 'journey_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  image_url?: Maybe<Scalars['String']>;
  min_version_android?: Maybe<Scalars['String']>;
  min_version_ios?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type Journey_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_url?: InputMaybe<Order_By>;
  min_version_android?: InputMaybe<Order_By>;
  min_version_ios?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type Journey_Min_Fields = {
  __typename?: 'journey_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  image_url?: Maybe<Scalars['String']>;
  min_version_android?: Maybe<Scalars['String']>;
  min_version_ios?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type Journey_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_url?: InputMaybe<Order_By>;
  min_version_android?: InputMaybe<Order_By>;
  min_version_ios?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type Journey_Mutation_Response = {
  __typename?: 'journey_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Journey>;
};

export type Journey_Obj_Rel_Insert_Input = {
  data: Journey_Insert_Input;
  on_conflict?: InputMaybe<Journey_On_Conflict>;
};

export type Journey_On_Conflict = {
  constraint: Journey_Constraint;
  update_columns: Array<Journey_Update_Column>;
  where?: InputMaybe<Journey_Bool_Exp>;
};

export type Journey_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_url?: InputMaybe<Order_By>;
  journey_program_sessions_aggregate?: InputMaybe<Journey_Program_Sessions_Aggregate_Order_By>;
  min_version_android?: InputMaybe<Order_By>;
  min_version_ios?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type Journey_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export type Journey_Program_Sessions = {
  __typename?: 'journey_program_sessions';
  created_at: Scalars['timestamptz'];
  journey_id: Scalars['Int'];
  module?: Maybe<Scalars['Int']>;
  order: Scalars['Int'];
  program_session: Program_Session;
  session_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

export type Journey_Program_Sessions_Aggregate = {
  __typename?: 'journey_program_sessions_aggregate';
  aggregate?: Maybe<Journey_Program_Sessions_Aggregate_Fields>;
  nodes: Array<Journey_Program_Sessions>;
};

export type Journey_Program_Sessions_Aggregate_Fields = {
  __typename?: 'journey_program_sessions_aggregate_fields';
  avg?: Maybe<Journey_Program_Sessions_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Journey_Program_Sessions_Max_Fields>;
  min?: Maybe<Journey_Program_Sessions_Min_Fields>;
  stddev?: Maybe<Journey_Program_Sessions_Stddev_Fields>;
  stddev_pop?: Maybe<Journey_Program_Sessions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Journey_Program_Sessions_Stddev_Samp_Fields>;
  sum?: Maybe<Journey_Program_Sessions_Sum_Fields>;
  var_pop?: Maybe<Journey_Program_Sessions_Var_Pop_Fields>;
  var_samp?: Maybe<Journey_Program_Sessions_Var_Samp_Fields>;
  variance?: Maybe<Journey_Program_Sessions_Variance_Fields>;
};

export type Journey_Program_Sessions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Journey_Program_Sessions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Journey_Program_Sessions_Aggregate_Order_By = {
  avg?: InputMaybe<Journey_Program_Sessions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Journey_Program_Sessions_Max_Order_By>;
  min?: InputMaybe<Journey_Program_Sessions_Min_Order_By>;
  stddev?: InputMaybe<Journey_Program_Sessions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Journey_Program_Sessions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Journey_Program_Sessions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Journey_Program_Sessions_Sum_Order_By>;
  var_pop?: InputMaybe<Journey_Program_Sessions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Journey_Program_Sessions_Var_Samp_Order_By>;
  variance?: InputMaybe<Journey_Program_Sessions_Variance_Order_By>;
};

export type Journey_Program_Sessions_Arr_Rel_Insert_Input = {
  data: Array<Journey_Program_Sessions_Insert_Input>;
  on_conflict?: InputMaybe<Journey_Program_Sessions_On_Conflict>;
};

export type Journey_Program_Sessions_Avg_Fields = {
  __typename?: 'journey_program_sessions_avg_fields';
  journey_id?: Maybe<Scalars['Float']>;
  module?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

export type Journey_Program_Sessions_Avg_Order_By = {
  journey_id?: InputMaybe<Order_By>;
  module?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
};

export type Journey_Program_Sessions_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Journey_Program_Sessions_Bool_Exp>>>;
  _not?: InputMaybe<Journey_Program_Sessions_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Journey_Program_Sessions_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  journey_id?: InputMaybe<Int_Comparison_Exp>;
  module?: InputMaybe<Int_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  program_session?: InputMaybe<Program_Session_Bool_Exp>;
  session_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

export enum Journey_Program_Sessions_Constraint {
  JourneyProgramSessionsJourneyIdSessionIdModuleOrderKey = 'journey_program_sessions_journey_id_session_id_module_order_key',
  JourneyProgramSessionsPkey = 'journey_program_sessions_pkey',
}

export type Journey_Program_Sessions_Inc_Input = {
  journey_id?: InputMaybe<Scalars['Int']>;
  module?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  session_id?: InputMaybe<Scalars['Int']>;
};

export type Journey_Program_Sessions_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  journey_id?: InputMaybe<Scalars['Int']>;
  module?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  program_session?: InputMaybe<Program_Session_Obj_Rel_Insert_Input>;
  session_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Journey_Program_Sessions_Max_Fields = {
  __typename?: 'journey_program_sessions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  journey_id?: Maybe<Scalars['Int']>;
  module?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  session_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type Journey_Program_Sessions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  journey_id?: InputMaybe<Order_By>;
  module?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type Journey_Program_Sessions_Min_Fields = {
  __typename?: 'journey_program_sessions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  journey_id?: Maybe<Scalars['Int']>;
  module?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  session_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type Journey_Program_Sessions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  journey_id?: InputMaybe<Order_By>;
  module?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type Journey_Program_Sessions_Mutation_Response = {
  __typename?: 'journey_program_sessions_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Journey_Program_Sessions>;
};

export type Journey_Program_Sessions_Obj_Rel_Insert_Input = {
  data: Journey_Program_Sessions_Insert_Input;
  on_conflict?: InputMaybe<Journey_Program_Sessions_On_Conflict>;
};

export type Journey_Program_Sessions_On_Conflict = {
  constraint: Journey_Program_Sessions_Constraint;
  update_columns: Array<Journey_Program_Sessions_Update_Column>;
  where?: InputMaybe<Journey_Program_Sessions_Bool_Exp>;
};

export type Journey_Program_Sessions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  journey_id?: InputMaybe<Order_By>;
  module?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  program_session?: InputMaybe<Program_Session_Order_By>;
  session_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type Journey_Program_Sessions_Pk_Columns_Input = {
  journey_id: Scalars['Int'];
  session_id: Scalars['Int'];
};

export enum Journey_Program_Sessions_Select_Column {
  CreatedAt = 'created_at',
  JourneyId = 'journey_id',
  Module = 'module',
  Order = 'order',
  SessionId = 'session_id',
  UpdatedAt = 'updated_at',
}

export type Journey_Program_Sessions_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  journey_id?: InputMaybe<Scalars['Int']>;
  module?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  session_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Journey_Program_Sessions_Stddev_Fields = {
  __typename?: 'journey_program_sessions_stddev_fields';
  journey_id?: Maybe<Scalars['Float']>;
  module?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

export type Journey_Program_Sessions_Stddev_Order_By = {
  journey_id?: InputMaybe<Order_By>;
  module?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
};

export type Journey_Program_Sessions_Stddev_Pop_Fields = {
  __typename?: 'journey_program_sessions_stddev_pop_fields';
  journey_id?: Maybe<Scalars['Float']>;
  module?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

export type Journey_Program_Sessions_Stddev_Pop_Order_By = {
  journey_id?: InputMaybe<Order_By>;
  module?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
};

export type Journey_Program_Sessions_Stddev_Samp_Fields = {
  __typename?: 'journey_program_sessions_stddev_samp_fields';
  journey_id?: Maybe<Scalars['Float']>;
  module?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

export type Journey_Program_Sessions_Stddev_Samp_Order_By = {
  journey_id?: InputMaybe<Order_By>;
  module?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
};

export type Journey_Program_Sessions_Sum_Fields = {
  __typename?: 'journey_program_sessions_sum_fields';
  journey_id?: Maybe<Scalars['Int']>;
  module?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  session_id?: Maybe<Scalars['Int']>;
};

export type Journey_Program_Sessions_Sum_Order_By = {
  journey_id?: InputMaybe<Order_By>;
  module?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
};

export enum Journey_Program_Sessions_Update_Column {
  CreatedAt = 'created_at',
  JourneyId = 'journey_id',
  Module = 'module',
  Order = 'order',
  SessionId = 'session_id',
  UpdatedAt = 'updated_at',
}

export type Journey_Program_Sessions_Var_Pop_Fields = {
  __typename?: 'journey_program_sessions_var_pop_fields';
  journey_id?: Maybe<Scalars['Float']>;
  module?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

export type Journey_Program_Sessions_Var_Pop_Order_By = {
  journey_id?: InputMaybe<Order_By>;
  module?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
};

export type Journey_Program_Sessions_Var_Samp_Fields = {
  __typename?: 'journey_program_sessions_var_samp_fields';
  journey_id?: Maybe<Scalars['Float']>;
  module?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

export type Journey_Program_Sessions_Var_Samp_Order_By = {
  journey_id?: InputMaybe<Order_By>;
  module?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
};

export type Journey_Program_Sessions_Variance_Fields = {
  __typename?: 'journey_program_sessions_variance_fields';
  journey_id?: Maybe<Scalars['Float']>;
  module?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

export type Journey_Program_Sessions_Variance_Order_By = {
  journey_id?: InputMaybe<Order_By>;
  module?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
};

export enum Journey_Select_Column {
  CreatedAt = 'created_at',
  Id = 'id',
  ImageUrl = 'image_url',
  MinVersionAndroid = 'min_version_android',
  MinVersionIos = 'min_version_ios',
  Name = 'name',
  UpdatedAt = 'updated_at',
}

export type Journey_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  image_url?: InputMaybe<Scalars['String']>;
  min_version_android?: InputMaybe<Scalars['String']>;
  min_version_ios?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Journey_Stddev_Fields = {
  __typename?: 'journey_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Journey_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Journey_Stddev_Pop_Fields = {
  __typename?: 'journey_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Journey_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Journey_Stddev_Samp_Fields = {
  __typename?: 'journey_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Journey_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Journey_Sum_Fields = {
  __typename?: 'journey_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

export type Journey_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

export enum Journey_Update_Column {
  CreatedAt = 'created_at',
  Id = 'id',
  ImageUrl = 'image_url',
  MinVersionAndroid = 'min_version_android',
  MinVersionIos = 'min_version_ios',
  Name = 'name',
  UpdatedAt = 'updated_at',
}

export type Journey_Var_Pop_Fields = {
  __typename?: 'journey_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Journey_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Journey_Var_Samp_Fields = {
  __typename?: 'journey_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Journey_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Journey_Variance_Fields = {
  __typename?: 'journey_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Journey_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Jsonb_Comparison_Exp = {
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  _has_key?: InputMaybe<Scalars['String']>;
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

export type Kupperman = {
  __typename?: 'kupperman';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  q1: Scalars['Int'];
  q2: Scalars['Int'];
  q3: Scalars['Int'];
  q4: Scalars['Int'];
  q5: Scalars['Int'];
  q6: Scalars['Int'];
  q7: Scalars['Int'];
  q8: Scalars['Int'];
  q9: Scalars['Int'];
  q10: Scalars['Int'];
  q11: Scalars['Int'];
  q12: Scalars['Int'];
  q13: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  user: User;
  user_id: Scalars['String'];
};

export type Kupperman_Aggregate = {
  __typename?: 'kupperman_aggregate';
  aggregate?: Maybe<Kupperman_Aggregate_Fields>;
  nodes: Array<Kupperman>;
};

export type Kupperman_Aggregate_Fields = {
  __typename?: 'kupperman_aggregate_fields';
  avg?: Maybe<Kupperman_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Kupperman_Max_Fields>;
  min?: Maybe<Kupperman_Min_Fields>;
  stddev?: Maybe<Kupperman_Stddev_Fields>;
  stddev_pop?: Maybe<Kupperman_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kupperman_Stddev_Samp_Fields>;
  sum?: Maybe<Kupperman_Sum_Fields>;
  var_pop?: Maybe<Kupperman_Var_Pop_Fields>;
  var_samp?: Maybe<Kupperman_Var_Samp_Fields>;
  variance?: Maybe<Kupperman_Variance_Fields>;
};

export type Kupperman_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Kupperman_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Kupperman_Aggregate_Order_By = {
  avg?: InputMaybe<Kupperman_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Kupperman_Max_Order_By>;
  min?: InputMaybe<Kupperman_Min_Order_By>;
  stddev?: InputMaybe<Kupperman_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Kupperman_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Kupperman_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Kupperman_Sum_Order_By>;
  var_pop?: InputMaybe<Kupperman_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Kupperman_Var_Samp_Order_By>;
  variance?: InputMaybe<Kupperman_Variance_Order_By>;
};

export type Kupperman_Arr_Rel_Insert_Input = {
  data: Array<Kupperman_Insert_Input>;
  on_conflict?: InputMaybe<Kupperman_On_Conflict>;
};

export type Kupperman_Avg_Fields = {
  __typename?: 'kupperman_avg_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
  q10?: Maybe<Scalars['Float']>;
  q11?: Maybe<Scalars['Float']>;
  q12?: Maybe<Scalars['Float']>;
  q13?: Maybe<Scalars['Float']>;
};

export type Kupperman_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q12?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
};

export type Kupperman_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Kupperman_Bool_Exp>>>;
  _not?: InputMaybe<Kupperman_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Kupperman_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  q1?: InputMaybe<Int_Comparison_Exp>;
  q2?: InputMaybe<Int_Comparison_Exp>;
  q3?: InputMaybe<Int_Comparison_Exp>;
  q4?: InputMaybe<Int_Comparison_Exp>;
  q5?: InputMaybe<Int_Comparison_Exp>;
  q6?: InputMaybe<Int_Comparison_Exp>;
  q7?: InputMaybe<Int_Comparison_Exp>;
  q8?: InputMaybe<Int_Comparison_Exp>;
  q9?: InputMaybe<Int_Comparison_Exp>;
  q10?: InputMaybe<Int_Comparison_Exp>;
  q11?: InputMaybe<Int_Comparison_Exp>;
  q12?: InputMaybe<Int_Comparison_Exp>;
  q13?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

export enum Kupperman_Constraint {
  KuppermanPkey = 'kupperman_pkey',
}

export type Kupperman_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['Int']>;
  q2?: InputMaybe<Scalars['Int']>;
  q3?: InputMaybe<Scalars['Int']>;
  q4?: InputMaybe<Scalars['Int']>;
  q5?: InputMaybe<Scalars['Int']>;
  q6?: InputMaybe<Scalars['Int']>;
  q7?: InputMaybe<Scalars['Int']>;
  q8?: InputMaybe<Scalars['Int']>;
  q9?: InputMaybe<Scalars['Int']>;
  q10?: InputMaybe<Scalars['Int']>;
  q11?: InputMaybe<Scalars['Int']>;
  q12?: InputMaybe<Scalars['Int']>;
  q13?: InputMaybe<Scalars['Int']>;
};

export type Kupperman_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['Int']>;
  q2?: InputMaybe<Scalars['Int']>;
  q3?: InputMaybe<Scalars['Int']>;
  q4?: InputMaybe<Scalars['Int']>;
  q5?: InputMaybe<Scalars['Int']>;
  q6?: InputMaybe<Scalars['Int']>;
  q7?: InputMaybe<Scalars['Int']>;
  q8?: InputMaybe<Scalars['Int']>;
  q9?: InputMaybe<Scalars['Int']>;
  q10?: InputMaybe<Scalars['Int']>;
  q11?: InputMaybe<Scalars['Int']>;
  q12?: InputMaybe<Scalars['Int']>;
  q13?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Kupperman_Max_Fields = {
  __typename?: 'kupperman_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['Int']>;
  q2?: Maybe<Scalars['Int']>;
  q3?: Maybe<Scalars['Int']>;
  q4?: Maybe<Scalars['Int']>;
  q5?: Maybe<Scalars['Int']>;
  q6?: Maybe<Scalars['Int']>;
  q7?: Maybe<Scalars['Int']>;
  q8?: Maybe<Scalars['Int']>;
  q9?: Maybe<Scalars['Int']>;
  q10?: Maybe<Scalars['Int']>;
  q11?: Maybe<Scalars['Int']>;
  q12?: Maybe<Scalars['Int']>;
  q13?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Kupperman_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q12?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Kupperman_Min_Fields = {
  __typename?: 'kupperman_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['Int']>;
  q2?: Maybe<Scalars['Int']>;
  q3?: Maybe<Scalars['Int']>;
  q4?: Maybe<Scalars['Int']>;
  q5?: Maybe<Scalars['Int']>;
  q6?: Maybe<Scalars['Int']>;
  q7?: Maybe<Scalars['Int']>;
  q8?: Maybe<Scalars['Int']>;
  q9?: Maybe<Scalars['Int']>;
  q10?: Maybe<Scalars['Int']>;
  q11?: Maybe<Scalars['Int']>;
  q12?: Maybe<Scalars['Int']>;
  q13?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Kupperman_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q12?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Kupperman_Mutation_Response = {
  __typename?: 'kupperman_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Kupperman>;
};

export type Kupperman_Obj_Rel_Insert_Input = {
  data: Kupperman_Insert_Input;
  on_conflict?: InputMaybe<Kupperman_On_Conflict>;
};

export type Kupperman_On_Conflict = {
  constraint: Kupperman_Constraint;
  update_columns: Array<Kupperman_Update_Column>;
  where?: InputMaybe<Kupperman_Bool_Exp>;
};

export type Kupperman_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q12?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Kupperman_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export enum Kupperman_Select_Column {
  CreatedAt = 'created_at',
  Id = 'id',
  Q1 = 'q1',
  Q2 = 'q2',
  Q3 = 'q3',
  Q4 = 'q4',
  Q5 = 'q5',
  Q6 = 'q6',
  Q7 = 'q7',
  Q8 = 'q8',
  Q9 = 'q9',
  Q10 = 'q10',
  Q11 = 'q11',
  Q12 = 'q12',
  Q13 = 'q13',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Kupperman_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['Int']>;
  q2?: InputMaybe<Scalars['Int']>;
  q3?: InputMaybe<Scalars['Int']>;
  q4?: InputMaybe<Scalars['Int']>;
  q5?: InputMaybe<Scalars['Int']>;
  q6?: InputMaybe<Scalars['Int']>;
  q7?: InputMaybe<Scalars['Int']>;
  q8?: InputMaybe<Scalars['Int']>;
  q9?: InputMaybe<Scalars['Int']>;
  q10?: InputMaybe<Scalars['Int']>;
  q11?: InputMaybe<Scalars['Int']>;
  q12?: InputMaybe<Scalars['Int']>;
  q13?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Kupperman_Stddev_Fields = {
  __typename?: 'kupperman_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
  q10?: Maybe<Scalars['Float']>;
  q11?: Maybe<Scalars['Float']>;
  q12?: Maybe<Scalars['Float']>;
  q13?: Maybe<Scalars['Float']>;
};

export type Kupperman_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q12?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
};

export type Kupperman_Stddev_Pop_Fields = {
  __typename?: 'kupperman_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
  q10?: Maybe<Scalars['Float']>;
  q11?: Maybe<Scalars['Float']>;
  q12?: Maybe<Scalars['Float']>;
  q13?: Maybe<Scalars['Float']>;
};

export type Kupperman_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q12?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
};

export type Kupperman_Stddev_Samp_Fields = {
  __typename?: 'kupperman_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
  q10?: Maybe<Scalars['Float']>;
  q11?: Maybe<Scalars['Float']>;
  q12?: Maybe<Scalars['Float']>;
  q13?: Maybe<Scalars['Float']>;
};

export type Kupperman_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q12?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
};

export type Kupperman_Sum_Fields = {
  __typename?: 'kupperman_sum_fields';
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['Int']>;
  q2?: Maybe<Scalars['Int']>;
  q3?: Maybe<Scalars['Int']>;
  q4?: Maybe<Scalars['Int']>;
  q5?: Maybe<Scalars['Int']>;
  q6?: Maybe<Scalars['Int']>;
  q7?: Maybe<Scalars['Int']>;
  q8?: Maybe<Scalars['Int']>;
  q9?: Maybe<Scalars['Int']>;
  q10?: Maybe<Scalars['Int']>;
  q11?: Maybe<Scalars['Int']>;
  q12?: Maybe<Scalars['Int']>;
  q13?: Maybe<Scalars['Int']>;
};

export type Kupperman_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q12?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
};

export enum Kupperman_Update_Column {
  CreatedAt = 'created_at',
  Id = 'id',
  Q1 = 'q1',
  Q2 = 'q2',
  Q3 = 'q3',
  Q4 = 'q4',
  Q5 = 'q5',
  Q6 = 'q6',
  Q7 = 'q7',
  Q8 = 'q8',
  Q9 = 'q9',
  Q10 = 'q10',
  Q11 = 'q11',
  Q12 = 'q12',
  Q13 = 'q13',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Kupperman_Var_Pop_Fields = {
  __typename?: 'kupperman_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
  q10?: Maybe<Scalars['Float']>;
  q11?: Maybe<Scalars['Float']>;
  q12?: Maybe<Scalars['Float']>;
  q13?: Maybe<Scalars['Float']>;
};

export type Kupperman_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q12?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
};

export type Kupperman_Var_Samp_Fields = {
  __typename?: 'kupperman_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
  q10?: Maybe<Scalars['Float']>;
  q11?: Maybe<Scalars['Float']>;
  q12?: Maybe<Scalars['Float']>;
  q13?: Maybe<Scalars['Float']>;
};

export type Kupperman_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q12?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
};

export type Kupperman_Variance_Fields = {
  __typename?: 'kupperman_variance_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
  q10?: Maybe<Scalars['Float']>;
  q11?: Maybe<Scalars['Float']>;
  q12?: Maybe<Scalars['Float']>;
  q13?: Maybe<Scalars['Float']>;
};

export type Kupperman_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q12?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
};

export type Landing_Page_Redirect = {
  __typename?: 'landing_page_redirect';
  comment?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  from_path: Scalars['String'];
  health_carers: Array<Health_Carer>;
  health_carers_aggregate: Health_Carer_Aggregate;
  permanent: Scalars['Boolean'];
  to_path: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

export type Landing_Page_RedirectHealth_CarersArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_Order_By>>;
  where?: InputMaybe<Health_Carer_Bool_Exp>;
};

export type Landing_Page_RedirectHealth_Carers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_Order_By>>;
  where?: InputMaybe<Health_Carer_Bool_Exp>;
};

export type Landing_Page_Redirect_Aggregate = {
  __typename?: 'landing_page_redirect_aggregate';
  aggregate?: Maybe<Landing_Page_Redirect_Aggregate_Fields>;
  nodes: Array<Landing_Page_Redirect>;
};

export type Landing_Page_Redirect_Aggregate_Fields = {
  __typename?: 'landing_page_redirect_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Landing_Page_Redirect_Max_Fields>;
  min?: Maybe<Landing_Page_Redirect_Min_Fields>;
};

export type Landing_Page_Redirect_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Landing_Page_Redirect_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Landing_Page_Redirect_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Landing_Page_Redirect_Max_Order_By>;
  min?: InputMaybe<Landing_Page_Redirect_Min_Order_By>;
};

export type Landing_Page_Redirect_Arr_Rel_Insert_Input = {
  data: Array<Landing_Page_Redirect_Insert_Input>;
  on_conflict?: InputMaybe<Landing_Page_Redirect_On_Conflict>;
};

export type Landing_Page_Redirect_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Landing_Page_Redirect_Bool_Exp>>>;
  _not?: InputMaybe<Landing_Page_Redirect_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Landing_Page_Redirect_Bool_Exp>>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  from_path?: InputMaybe<String_Comparison_Exp>;
  health_carers?: InputMaybe<Health_Carer_Bool_Exp>;
  permanent?: InputMaybe<Boolean_Comparison_Exp>;
  to_path?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

export enum Landing_Page_Redirect_Constraint {
  LandingPageRedirectPkey = 'landing_page_redirect_pkey',
}

export type Landing_Page_Redirect_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  from_path?: InputMaybe<Scalars['String']>;
  health_carers?: InputMaybe<Health_Carer_Arr_Rel_Insert_Input>;
  permanent?: InputMaybe<Scalars['Boolean']>;
  to_path?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Landing_Page_Redirect_Max_Fields = {
  __typename?: 'landing_page_redirect_max_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  from_path?: Maybe<Scalars['String']>;
  to_path?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type Landing_Page_Redirect_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  from_path?: InputMaybe<Order_By>;
  to_path?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type Landing_Page_Redirect_Min_Fields = {
  __typename?: 'landing_page_redirect_min_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  from_path?: Maybe<Scalars['String']>;
  to_path?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type Landing_Page_Redirect_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  from_path?: InputMaybe<Order_By>;
  to_path?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type Landing_Page_Redirect_Mutation_Response = {
  __typename?: 'landing_page_redirect_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Landing_Page_Redirect>;
};

export type Landing_Page_Redirect_Obj_Rel_Insert_Input = {
  data: Landing_Page_Redirect_Insert_Input;
  on_conflict?: InputMaybe<Landing_Page_Redirect_On_Conflict>;
};

export type Landing_Page_Redirect_On_Conflict = {
  constraint: Landing_Page_Redirect_Constraint;
  update_columns: Array<Landing_Page_Redirect_Update_Column>;
  where?: InputMaybe<Landing_Page_Redirect_Bool_Exp>;
};

export type Landing_Page_Redirect_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  from_path?: InputMaybe<Order_By>;
  health_carers_aggregate?: InputMaybe<Health_Carer_Aggregate_Order_By>;
  permanent?: InputMaybe<Order_By>;
  to_path?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type Landing_Page_Redirect_Pk_Columns_Input = {
  from_path: Scalars['String'];
};

export enum Landing_Page_Redirect_Select_Column {
  Comment = 'comment',
  CreatedAt = 'created_at',
  FromPath = 'from_path',
  Permanent = 'permanent',
  ToPath = 'to_path',
  UpdatedAt = 'updated_at',
}

export type Landing_Page_Redirect_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  from_path?: InputMaybe<Scalars['String']>;
  permanent?: InputMaybe<Scalars['Boolean']>;
  to_path?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export enum Landing_Page_Redirect_Update_Column {
  Comment = 'comment',
  CreatedAt = 'created_at',
  FromPath = 'from_path',
  Permanent = 'permanent',
  ToPath = 'to_path',
  UpdatedAt = 'updated_at',
}

export type Log = {
  __typename?: 'log';
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['jsonb']>;
  id: Scalars['Int'];
  log_type: Log_Type_Enum;
  messenger_id?: Maybe<Scalars['String']>;
  type: Log_Type_Enum_Enum;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type LogDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

export type Log_Aggregate = {
  __typename?: 'log_aggregate';
  aggregate?: Maybe<Log_Aggregate_Fields>;
  nodes: Array<Log>;
};

export type Log_Aggregate_Fields = {
  __typename?: 'log_aggregate_fields';
  avg?: Maybe<Log_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Log_Max_Fields>;
  min?: Maybe<Log_Min_Fields>;
  stddev?: Maybe<Log_Stddev_Fields>;
  stddev_pop?: Maybe<Log_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Log_Stddev_Samp_Fields>;
  sum?: Maybe<Log_Sum_Fields>;
  var_pop?: Maybe<Log_Var_Pop_Fields>;
  var_samp?: Maybe<Log_Var_Samp_Fields>;
  variance?: Maybe<Log_Variance_Fields>;
};

export type Log_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Log_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Log_Aggregate_Order_By = {
  avg?: InputMaybe<Log_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Log_Max_Order_By>;
  min?: InputMaybe<Log_Min_Order_By>;
  stddev?: InputMaybe<Log_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Log_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Log_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Log_Sum_Order_By>;
  var_pop?: InputMaybe<Log_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Log_Var_Samp_Order_By>;
  variance?: InputMaybe<Log_Variance_Order_By>;
};

export type Log_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

export type Log_Arr_Rel_Insert_Input = {
  data: Array<Log_Insert_Input>;
  on_conflict?: InputMaybe<Log_On_Conflict>;
};

export type Log_Avg_Fields = {
  __typename?: 'log_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Log_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Log_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Log_Bool_Exp>>>;
  _not?: InputMaybe<Log_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Log_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  log_type?: InputMaybe<Log_Type_Enum_Bool_Exp>;
  messenger_id?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Log_Type_Enum_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

export enum Log_Constraint {
  LogPkey = 'log_pkey',
}

export type Log_Delete_At_Path_Input = {
  data?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Log_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']>;
};

export type Log_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']>;
};

export type Log_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

export type Log_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  log_type?: InputMaybe<Log_Type_Enum_Obj_Rel_Insert_Input>;
  messenger_id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Log_Type_Enum_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Log_Max_Fields = {
  __typename?: 'log_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  messenger_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type Log_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  messenger_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type Log_Min_Fields = {
  __typename?: 'log_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  messenger_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type Log_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  messenger_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type Log_Mutation_Response = {
  __typename?: 'log_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Log>;
};

export type Log_Obj_Rel_Insert_Input = {
  data: Log_Insert_Input;
  on_conflict?: InputMaybe<Log_On_Conflict>;
};

export type Log_On_Conflict = {
  constraint: Log_Constraint;
  update_columns: Array<Log_Update_Column>;
  where?: InputMaybe<Log_Bool_Exp>;
};

export type Log_Order_By = {
  created_at?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  log_type?: InputMaybe<Log_Type_Enum_Order_By>;
  messenger_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type Log_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export type Log_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

export enum Log_Select_Column {
  CreatedAt = 'created_at',
  Data = 'data',
  Id = 'id',
  MessengerId = 'messenger_id',
  Type = 'type',
  UpdatedAt = 'updated_at',
}

export type Log_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  messenger_id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Log_Type_Enum_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Log_Stddev_Fields = {
  __typename?: 'log_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Log_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Log_Stddev_Pop_Fields = {
  __typename?: 'log_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Log_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Log_Stddev_Samp_Fields = {
  __typename?: 'log_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Log_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Log_Sum_Fields = {
  __typename?: 'log_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

export type Log_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Log_Type_Enum = {
  __typename?: 'log_type_enum';
  comment?: Maybe<Scalars['String']>;
  text: Scalars['String'];
};

export type Log_Type_Enum_Aggregate = {
  __typename?: 'log_type_enum_aggregate';
  aggregate?: Maybe<Log_Type_Enum_Aggregate_Fields>;
  nodes: Array<Log_Type_Enum>;
};

export type Log_Type_Enum_Aggregate_Fields = {
  __typename?: 'log_type_enum_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Log_Type_Enum_Max_Fields>;
  min?: Maybe<Log_Type_Enum_Min_Fields>;
};

export type Log_Type_Enum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Log_Type_Enum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Log_Type_Enum_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Log_Type_Enum_Max_Order_By>;
  min?: InputMaybe<Log_Type_Enum_Min_Order_By>;
};

export type Log_Type_Enum_Arr_Rel_Insert_Input = {
  data: Array<Log_Type_Enum_Insert_Input>;
  on_conflict?: InputMaybe<Log_Type_Enum_On_Conflict>;
};

export type Log_Type_Enum_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Log_Type_Enum_Bool_Exp>>>;
  _not?: InputMaybe<Log_Type_Enum_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Log_Type_Enum_Bool_Exp>>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
};

export enum Log_Type_Enum_Constraint {
  LogTypeEnumPkey = 'log_type_enum_pkey',
}

export enum Log_Type_Enum_Enum {
  AccountReactivated = 'ACCOUNT_REACTIVATED',
  AdClick = 'AD_CLICK',
  CallHumanHelp = 'CALL_HUMAN_HELP',
  CancelReminder = 'CANCEL_REMINDER',
  CurrentJourneyUpdate = 'CURRENT_JOURNEY_UPDATE',
  CustomerSupportNotification = 'CUSTOMER_SUPPORT_NOTIFICATION',
  DeleteAccount = 'DELETE_ACCOUNT',
  IapEvent = 'IAP_EVENT',
  LateSleepDiaryReminderSent = 'LATE_SLEEP_DIARY_REMINDER_SENT',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  NewDataFromAppleHealth = 'NEW_DATA_FROM_APPLE_HEALTH',
  OpenWebview = 'OPEN_WEBVIEW',
  PointsUpdate = 'POINTS_UPDATE',
  QuestionnaireEntry = 'QUESTIONNAIRE_ENTRY',
  Reengage_1 = 'REENGAGE_1',
  Reengage_2 = 'REENGAGE_2',
  ReEngageToBeDeleted = 'RE_ENGAGE_TO_BE_DELETED',
  SessionFinished = 'SESSION_FINISHED',
  SessionStarted = 'SESSION_STARTED',
  SleepDiaryEntry = 'SLEEP_DIARY_ENTRY',
  SleepImprovement = 'SLEEP_IMPROVEMENT',
  YouMightLikeClick = 'YOU_MIGHT_LIKE_CLICK',
}

export type Log_Type_Enum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Log_Type_Enum_Enum>;
  _in?: InputMaybe<Array<Log_Type_Enum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Log_Type_Enum_Enum>;
  _nin?: InputMaybe<Array<Log_Type_Enum_Enum>>;
};

export type Log_Type_Enum_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type Log_Type_Enum_Max_Fields = {
  __typename?: 'log_type_enum_max_fields';
  comment?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type Log_Type_Enum_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Log_Type_Enum_Min_Fields = {
  __typename?: 'log_type_enum_min_fields';
  comment?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type Log_Type_Enum_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Log_Type_Enum_Mutation_Response = {
  __typename?: 'log_type_enum_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Log_Type_Enum>;
};

export type Log_Type_Enum_Obj_Rel_Insert_Input = {
  data: Log_Type_Enum_Insert_Input;
  on_conflict?: InputMaybe<Log_Type_Enum_On_Conflict>;
};

export type Log_Type_Enum_On_Conflict = {
  constraint: Log_Type_Enum_Constraint;
  update_columns: Array<Log_Type_Enum_Update_Column>;
  where?: InputMaybe<Log_Type_Enum_Bool_Exp>;
};

export type Log_Type_Enum_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Log_Type_Enum_Pk_Columns_Input = {
  text: Scalars['String'];
};

export enum Log_Type_Enum_Select_Column {
  Comment = 'comment',
  Text = 'text',
}

export type Log_Type_Enum_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export enum Log_Type_Enum_Update_Column {
  Comment = 'comment',
  Text = 'text',
}

export enum Log_Update_Column {
  CreatedAt = 'created_at',
  Data = 'data',
  Id = 'id',
  MessengerId = 'messenger_id',
  Type = 'type',
  UpdatedAt = 'updated_at',
}

export type Log_Var_Pop_Fields = {
  __typename?: 'log_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Log_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Log_Var_Samp_Fields = {
  __typename?: 'log_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Log_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Log_Variance_Fields = {
  __typename?: 'log_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Log_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Menopause_Greene_Scale = {
  __typename?: 'menopause_greene_scale';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  q1: Scalars['Int'];
  q2: Scalars['Int'];
  q3: Scalars['Int'];
  q4: Scalars['Int'];
  q5: Scalars['Int'];
  q6: Scalars['Int'];
  q7: Scalars['Int'];
  q8: Scalars['Int'];
  q9: Scalars['Int'];
  q10: Scalars['Int'];
  q11: Scalars['Int'];
  q12: Scalars['Int'];
  q13: Scalars['Int'];
  q14: Scalars['Int'];
  q15: Scalars['Int'];
  q16: Scalars['Int'];
  q17: Scalars['Int'];
  q18: Scalars['Int'];
  q19: Scalars['Int'];
  q20: Scalars['Int'];
  q21: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  user: User;
  user_id: Scalars['String'];
};

export type Menopause_Greene_Scale_Aggregate = {
  __typename?: 'menopause_greene_scale_aggregate';
  aggregate?: Maybe<Menopause_Greene_Scale_Aggregate_Fields>;
  nodes: Array<Menopause_Greene_Scale>;
};

export type Menopause_Greene_Scale_Aggregate_Fields = {
  __typename?: 'menopause_greene_scale_aggregate_fields';
  avg?: Maybe<Menopause_Greene_Scale_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Menopause_Greene_Scale_Max_Fields>;
  min?: Maybe<Menopause_Greene_Scale_Min_Fields>;
  stddev?: Maybe<Menopause_Greene_Scale_Stddev_Fields>;
  stddev_pop?: Maybe<Menopause_Greene_Scale_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Menopause_Greene_Scale_Stddev_Samp_Fields>;
  sum?: Maybe<Menopause_Greene_Scale_Sum_Fields>;
  var_pop?: Maybe<Menopause_Greene_Scale_Var_Pop_Fields>;
  var_samp?: Maybe<Menopause_Greene_Scale_Var_Samp_Fields>;
  variance?: Maybe<Menopause_Greene_Scale_Variance_Fields>;
};

export type Menopause_Greene_Scale_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Menopause_Greene_Scale_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Menopause_Greene_Scale_Aggregate_Order_By = {
  avg?: InputMaybe<Menopause_Greene_Scale_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Menopause_Greene_Scale_Max_Order_By>;
  min?: InputMaybe<Menopause_Greene_Scale_Min_Order_By>;
  stddev?: InputMaybe<Menopause_Greene_Scale_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Menopause_Greene_Scale_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Menopause_Greene_Scale_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Menopause_Greene_Scale_Sum_Order_By>;
  var_pop?: InputMaybe<Menopause_Greene_Scale_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Menopause_Greene_Scale_Var_Samp_Order_By>;
  variance?: InputMaybe<Menopause_Greene_Scale_Variance_Order_By>;
};

export type Menopause_Greene_Scale_Arr_Rel_Insert_Input = {
  data: Array<Menopause_Greene_Scale_Insert_Input>;
  on_conflict?: InputMaybe<Menopause_Greene_Scale_On_Conflict>;
};

export type Menopause_Greene_Scale_Avg_Fields = {
  __typename?: 'menopause_greene_scale_avg_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
  q10?: Maybe<Scalars['Float']>;
  q11?: Maybe<Scalars['Float']>;
  q12?: Maybe<Scalars['Float']>;
  q13?: Maybe<Scalars['Float']>;
  q14?: Maybe<Scalars['Float']>;
  q15?: Maybe<Scalars['Float']>;
  q16?: Maybe<Scalars['Float']>;
  q17?: Maybe<Scalars['Float']>;
  q18?: Maybe<Scalars['Float']>;
  q19?: Maybe<Scalars['Float']>;
  q20?: Maybe<Scalars['Float']>;
  q21?: Maybe<Scalars['Float']>;
};

export type Menopause_Greene_Scale_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q12?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
  q14?: InputMaybe<Order_By>;
  q15?: InputMaybe<Order_By>;
  q16?: InputMaybe<Order_By>;
  q17?: InputMaybe<Order_By>;
  q18?: InputMaybe<Order_By>;
  q19?: InputMaybe<Order_By>;
  q20?: InputMaybe<Order_By>;
  q21?: InputMaybe<Order_By>;
};

export type Menopause_Greene_Scale_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Menopause_Greene_Scale_Bool_Exp>>>;
  _not?: InputMaybe<Menopause_Greene_Scale_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Menopause_Greene_Scale_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  q1?: InputMaybe<Int_Comparison_Exp>;
  q2?: InputMaybe<Int_Comparison_Exp>;
  q3?: InputMaybe<Int_Comparison_Exp>;
  q4?: InputMaybe<Int_Comparison_Exp>;
  q5?: InputMaybe<Int_Comparison_Exp>;
  q6?: InputMaybe<Int_Comparison_Exp>;
  q7?: InputMaybe<Int_Comparison_Exp>;
  q8?: InputMaybe<Int_Comparison_Exp>;
  q9?: InputMaybe<Int_Comparison_Exp>;
  q10?: InputMaybe<Int_Comparison_Exp>;
  q11?: InputMaybe<Int_Comparison_Exp>;
  q12?: InputMaybe<Int_Comparison_Exp>;
  q13?: InputMaybe<Int_Comparison_Exp>;
  q14?: InputMaybe<Int_Comparison_Exp>;
  q15?: InputMaybe<Int_Comparison_Exp>;
  q16?: InputMaybe<Int_Comparison_Exp>;
  q17?: InputMaybe<Int_Comparison_Exp>;
  q18?: InputMaybe<Int_Comparison_Exp>;
  q19?: InputMaybe<Int_Comparison_Exp>;
  q20?: InputMaybe<Int_Comparison_Exp>;
  q21?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

export enum Menopause_Greene_Scale_Constraint {
  MenopauseGreeneScalePkey = 'menopause_greene_scale_pkey',
}

export type Menopause_Greene_Scale_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['Int']>;
  q2?: InputMaybe<Scalars['Int']>;
  q3?: InputMaybe<Scalars['Int']>;
  q4?: InputMaybe<Scalars['Int']>;
  q5?: InputMaybe<Scalars['Int']>;
  q6?: InputMaybe<Scalars['Int']>;
  q7?: InputMaybe<Scalars['Int']>;
  q8?: InputMaybe<Scalars['Int']>;
  q9?: InputMaybe<Scalars['Int']>;
  q10?: InputMaybe<Scalars['Int']>;
  q11?: InputMaybe<Scalars['Int']>;
  q12?: InputMaybe<Scalars['Int']>;
  q13?: InputMaybe<Scalars['Int']>;
  q14?: InputMaybe<Scalars['Int']>;
  q15?: InputMaybe<Scalars['Int']>;
  q16?: InputMaybe<Scalars['Int']>;
  q17?: InputMaybe<Scalars['Int']>;
  q18?: InputMaybe<Scalars['Int']>;
  q19?: InputMaybe<Scalars['Int']>;
  q20?: InputMaybe<Scalars['Int']>;
  q21?: InputMaybe<Scalars['Int']>;
};

export type Menopause_Greene_Scale_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['Int']>;
  q2?: InputMaybe<Scalars['Int']>;
  q3?: InputMaybe<Scalars['Int']>;
  q4?: InputMaybe<Scalars['Int']>;
  q5?: InputMaybe<Scalars['Int']>;
  q6?: InputMaybe<Scalars['Int']>;
  q7?: InputMaybe<Scalars['Int']>;
  q8?: InputMaybe<Scalars['Int']>;
  q9?: InputMaybe<Scalars['Int']>;
  q10?: InputMaybe<Scalars['Int']>;
  q11?: InputMaybe<Scalars['Int']>;
  q12?: InputMaybe<Scalars['Int']>;
  q13?: InputMaybe<Scalars['Int']>;
  q14?: InputMaybe<Scalars['Int']>;
  q15?: InputMaybe<Scalars['Int']>;
  q16?: InputMaybe<Scalars['Int']>;
  q17?: InputMaybe<Scalars['Int']>;
  q18?: InputMaybe<Scalars['Int']>;
  q19?: InputMaybe<Scalars['Int']>;
  q20?: InputMaybe<Scalars['Int']>;
  q21?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Menopause_Greene_Scale_Max_Fields = {
  __typename?: 'menopause_greene_scale_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['Int']>;
  q2?: Maybe<Scalars['Int']>;
  q3?: Maybe<Scalars['Int']>;
  q4?: Maybe<Scalars['Int']>;
  q5?: Maybe<Scalars['Int']>;
  q6?: Maybe<Scalars['Int']>;
  q7?: Maybe<Scalars['Int']>;
  q8?: Maybe<Scalars['Int']>;
  q9?: Maybe<Scalars['Int']>;
  q10?: Maybe<Scalars['Int']>;
  q11?: Maybe<Scalars['Int']>;
  q12?: Maybe<Scalars['Int']>;
  q13?: Maybe<Scalars['Int']>;
  q14?: Maybe<Scalars['Int']>;
  q15?: Maybe<Scalars['Int']>;
  q16?: Maybe<Scalars['Int']>;
  q17?: Maybe<Scalars['Int']>;
  q18?: Maybe<Scalars['Int']>;
  q19?: Maybe<Scalars['Int']>;
  q20?: Maybe<Scalars['Int']>;
  q21?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Menopause_Greene_Scale_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q12?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
  q14?: InputMaybe<Order_By>;
  q15?: InputMaybe<Order_By>;
  q16?: InputMaybe<Order_By>;
  q17?: InputMaybe<Order_By>;
  q18?: InputMaybe<Order_By>;
  q19?: InputMaybe<Order_By>;
  q20?: InputMaybe<Order_By>;
  q21?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Menopause_Greene_Scale_Min_Fields = {
  __typename?: 'menopause_greene_scale_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['Int']>;
  q2?: Maybe<Scalars['Int']>;
  q3?: Maybe<Scalars['Int']>;
  q4?: Maybe<Scalars['Int']>;
  q5?: Maybe<Scalars['Int']>;
  q6?: Maybe<Scalars['Int']>;
  q7?: Maybe<Scalars['Int']>;
  q8?: Maybe<Scalars['Int']>;
  q9?: Maybe<Scalars['Int']>;
  q10?: Maybe<Scalars['Int']>;
  q11?: Maybe<Scalars['Int']>;
  q12?: Maybe<Scalars['Int']>;
  q13?: Maybe<Scalars['Int']>;
  q14?: Maybe<Scalars['Int']>;
  q15?: Maybe<Scalars['Int']>;
  q16?: Maybe<Scalars['Int']>;
  q17?: Maybe<Scalars['Int']>;
  q18?: Maybe<Scalars['Int']>;
  q19?: Maybe<Scalars['Int']>;
  q20?: Maybe<Scalars['Int']>;
  q21?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Menopause_Greene_Scale_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q12?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
  q14?: InputMaybe<Order_By>;
  q15?: InputMaybe<Order_By>;
  q16?: InputMaybe<Order_By>;
  q17?: InputMaybe<Order_By>;
  q18?: InputMaybe<Order_By>;
  q19?: InputMaybe<Order_By>;
  q20?: InputMaybe<Order_By>;
  q21?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Menopause_Greene_Scale_Mutation_Response = {
  __typename?: 'menopause_greene_scale_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Menopause_Greene_Scale>;
};

export type Menopause_Greene_Scale_Obj_Rel_Insert_Input = {
  data: Menopause_Greene_Scale_Insert_Input;
  on_conflict?: InputMaybe<Menopause_Greene_Scale_On_Conflict>;
};

export type Menopause_Greene_Scale_On_Conflict = {
  constraint: Menopause_Greene_Scale_Constraint;
  update_columns: Array<Menopause_Greene_Scale_Update_Column>;
  where?: InputMaybe<Menopause_Greene_Scale_Bool_Exp>;
};

export type Menopause_Greene_Scale_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q12?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
  q14?: InputMaybe<Order_By>;
  q15?: InputMaybe<Order_By>;
  q16?: InputMaybe<Order_By>;
  q17?: InputMaybe<Order_By>;
  q18?: InputMaybe<Order_By>;
  q19?: InputMaybe<Order_By>;
  q20?: InputMaybe<Order_By>;
  q21?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Menopause_Greene_Scale_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export enum Menopause_Greene_Scale_Select_Column {
  CreatedAt = 'created_at',
  Id = 'id',
  Q1 = 'q1',
  Q2 = 'q2',
  Q3 = 'q3',
  Q4 = 'q4',
  Q5 = 'q5',
  Q6 = 'q6',
  Q7 = 'q7',
  Q8 = 'q8',
  Q9 = 'q9',
  Q10 = 'q10',
  Q11 = 'q11',
  Q12 = 'q12',
  Q13 = 'q13',
  Q14 = 'q14',
  Q15 = 'q15',
  Q16 = 'q16',
  Q17 = 'q17',
  Q18 = 'q18',
  Q19 = 'q19',
  Q20 = 'q20',
  Q21 = 'q21',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Menopause_Greene_Scale_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['Int']>;
  q2?: InputMaybe<Scalars['Int']>;
  q3?: InputMaybe<Scalars['Int']>;
  q4?: InputMaybe<Scalars['Int']>;
  q5?: InputMaybe<Scalars['Int']>;
  q6?: InputMaybe<Scalars['Int']>;
  q7?: InputMaybe<Scalars['Int']>;
  q8?: InputMaybe<Scalars['Int']>;
  q9?: InputMaybe<Scalars['Int']>;
  q10?: InputMaybe<Scalars['Int']>;
  q11?: InputMaybe<Scalars['Int']>;
  q12?: InputMaybe<Scalars['Int']>;
  q13?: InputMaybe<Scalars['Int']>;
  q14?: InputMaybe<Scalars['Int']>;
  q15?: InputMaybe<Scalars['Int']>;
  q16?: InputMaybe<Scalars['Int']>;
  q17?: InputMaybe<Scalars['Int']>;
  q18?: InputMaybe<Scalars['Int']>;
  q19?: InputMaybe<Scalars['Int']>;
  q20?: InputMaybe<Scalars['Int']>;
  q21?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Menopause_Greene_Scale_Stddev_Fields = {
  __typename?: 'menopause_greene_scale_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
  q10?: Maybe<Scalars['Float']>;
  q11?: Maybe<Scalars['Float']>;
  q12?: Maybe<Scalars['Float']>;
  q13?: Maybe<Scalars['Float']>;
  q14?: Maybe<Scalars['Float']>;
  q15?: Maybe<Scalars['Float']>;
  q16?: Maybe<Scalars['Float']>;
  q17?: Maybe<Scalars['Float']>;
  q18?: Maybe<Scalars['Float']>;
  q19?: Maybe<Scalars['Float']>;
  q20?: Maybe<Scalars['Float']>;
  q21?: Maybe<Scalars['Float']>;
};

export type Menopause_Greene_Scale_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q12?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
  q14?: InputMaybe<Order_By>;
  q15?: InputMaybe<Order_By>;
  q16?: InputMaybe<Order_By>;
  q17?: InputMaybe<Order_By>;
  q18?: InputMaybe<Order_By>;
  q19?: InputMaybe<Order_By>;
  q20?: InputMaybe<Order_By>;
  q21?: InputMaybe<Order_By>;
};

export type Menopause_Greene_Scale_Stddev_Pop_Fields = {
  __typename?: 'menopause_greene_scale_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
  q10?: Maybe<Scalars['Float']>;
  q11?: Maybe<Scalars['Float']>;
  q12?: Maybe<Scalars['Float']>;
  q13?: Maybe<Scalars['Float']>;
  q14?: Maybe<Scalars['Float']>;
  q15?: Maybe<Scalars['Float']>;
  q16?: Maybe<Scalars['Float']>;
  q17?: Maybe<Scalars['Float']>;
  q18?: Maybe<Scalars['Float']>;
  q19?: Maybe<Scalars['Float']>;
  q20?: Maybe<Scalars['Float']>;
  q21?: Maybe<Scalars['Float']>;
};

export type Menopause_Greene_Scale_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q12?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
  q14?: InputMaybe<Order_By>;
  q15?: InputMaybe<Order_By>;
  q16?: InputMaybe<Order_By>;
  q17?: InputMaybe<Order_By>;
  q18?: InputMaybe<Order_By>;
  q19?: InputMaybe<Order_By>;
  q20?: InputMaybe<Order_By>;
  q21?: InputMaybe<Order_By>;
};

export type Menopause_Greene_Scale_Stddev_Samp_Fields = {
  __typename?: 'menopause_greene_scale_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
  q10?: Maybe<Scalars['Float']>;
  q11?: Maybe<Scalars['Float']>;
  q12?: Maybe<Scalars['Float']>;
  q13?: Maybe<Scalars['Float']>;
  q14?: Maybe<Scalars['Float']>;
  q15?: Maybe<Scalars['Float']>;
  q16?: Maybe<Scalars['Float']>;
  q17?: Maybe<Scalars['Float']>;
  q18?: Maybe<Scalars['Float']>;
  q19?: Maybe<Scalars['Float']>;
  q20?: Maybe<Scalars['Float']>;
  q21?: Maybe<Scalars['Float']>;
};

export type Menopause_Greene_Scale_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q12?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
  q14?: InputMaybe<Order_By>;
  q15?: InputMaybe<Order_By>;
  q16?: InputMaybe<Order_By>;
  q17?: InputMaybe<Order_By>;
  q18?: InputMaybe<Order_By>;
  q19?: InputMaybe<Order_By>;
  q20?: InputMaybe<Order_By>;
  q21?: InputMaybe<Order_By>;
};

export type Menopause_Greene_Scale_Sum_Fields = {
  __typename?: 'menopause_greene_scale_sum_fields';
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['Int']>;
  q2?: Maybe<Scalars['Int']>;
  q3?: Maybe<Scalars['Int']>;
  q4?: Maybe<Scalars['Int']>;
  q5?: Maybe<Scalars['Int']>;
  q6?: Maybe<Scalars['Int']>;
  q7?: Maybe<Scalars['Int']>;
  q8?: Maybe<Scalars['Int']>;
  q9?: Maybe<Scalars['Int']>;
  q10?: Maybe<Scalars['Int']>;
  q11?: Maybe<Scalars['Int']>;
  q12?: Maybe<Scalars['Int']>;
  q13?: Maybe<Scalars['Int']>;
  q14?: Maybe<Scalars['Int']>;
  q15?: Maybe<Scalars['Int']>;
  q16?: Maybe<Scalars['Int']>;
  q17?: Maybe<Scalars['Int']>;
  q18?: Maybe<Scalars['Int']>;
  q19?: Maybe<Scalars['Int']>;
  q20?: Maybe<Scalars['Int']>;
  q21?: Maybe<Scalars['Int']>;
};

export type Menopause_Greene_Scale_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q12?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
  q14?: InputMaybe<Order_By>;
  q15?: InputMaybe<Order_By>;
  q16?: InputMaybe<Order_By>;
  q17?: InputMaybe<Order_By>;
  q18?: InputMaybe<Order_By>;
  q19?: InputMaybe<Order_By>;
  q20?: InputMaybe<Order_By>;
  q21?: InputMaybe<Order_By>;
};

export enum Menopause_Greene_Scale_Update_Column {
  CreatedAt = 'created_at',
  Id = 'id',
  Q1 = 'q1',
  Q2 = 'q2',
  Q3 = 'q3',
  Q4 = 'q4',
  Q5 = 'q5',
  Q6 = 'q6',
  Q7 = 'q7',
  Q8 = 'q8',
  Q9 = 'q9',
  Q10 = 'q10',
  Q11 = 'q11',
  Q12 = 'q12',
  Q13 = 'q13',
  Q14 = 'q14',
  Q15 = 'q15',
  Q16 = 'q16',
  Q17 = 'q17',
  Q18 = 'q18',
  Q19 = 'q19',
  Q20 = 'q20',
  Q21 = 'q21',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Menopause_Greene_Scale_Var_Pop_Fields = {
  __typename?: 'menopause_greene_scale_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
  q10?: Maybe<Scalars['Float']>;
  q11?: Maybe<Scalars['Float']>;
  q12?: Maybe<Scalars['Float']>;
  q13?: Maybe<Scalars['Float']>;
  q14?: Maybe<Scalars['Float']>;
  q15?: Maybe<Scalars['Float']>;
  q16?: Maybe<Scalars['Float']>;
  q17?: Maybe<Scalars['Float']>;
  q18?: Maybe<Scalars['Float']>;
  q19?: Maybe<Scalars['Float']>;
  q20?: Maybe<Scalars['Float']>;
  q21?: Maybe<Scalars['Float']>;
};

export type Menopause_Greene_Scale_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q12?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
  q14?: InputMaybe<Order_By>;
  q15?: InputMaybe<Order_By>;
  q16?: InputMaybe<Order_By>;
  q17?: InputMaybe<Order_By>;
  q18?: InputMaybe<Order_By>;
  q19?: InputMaybe<Order_By>;
  q20?: InputMaybe<Order_By>;
  q21?: InputMaybe<Order_By>;
};

export type Menopause_Greene_Scale_Var_Samp_Fields = {
  __typename?: 'menopause_greene_scale_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
  q10?: Maybe<Scalars['Float']>;
  q11?: Maybe<Scalars['Float']>;
  q12?: Maybe<Scalars['Float']>;
  q13?: Maybe<Scalars['Float']>;
  q14?: Maybe<Scalars['Float']>;
  q15?: Maybe<Scalars['Float']>;
  q16?: Maybe<Scalars['Float']>;
  q17?: Maybe<Scalars['Float']>;
  q18?: Maybe<Scalars['Float']>;
  q19?: Maybe<Scalars['Float']>;
  q20?: Maybe<Scalars['Float']>;
  q21?: Maybe<Scalars['Float']>;
};

export type Menopause_Greene_Scale_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q12?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
  q14?: InputMaybe<Order_By>;
  q15?: InputMaybe<Order_By>;
  q16?: InputMaybe<Order_By>;
  q17?: InputMaybe<Order_By>;
  q18?: InputMaybe<Order_By>;
  q19?: InputMaybe<Order_By>;
  q20?: InputMaybe<Order_By>;
  q21?: InputMaybe<Order_By>;
};

export type Menopause_Greene_Scale_Variance_Fields = {
  __typename?: 'menopause_greene_scale_variance_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
  q10?: Maybe<Scalars['Float']>;
  q11?: Maybe<Scalars['Float']>;
  q12?: Maybe<Scalars['Float']>;
  q13?: Maybe<Scalars['Float']>;
  q14?: Maybe<Scalars['Float']>;
  q15?: Maybe<Scalars['Float']>;
  q16?: Maybe<Scalars['Float']>;
  q17?: Maybe<Scalars['Float']>;
  q18?: Maybe<Scalars['Float']>;
  q19?: Maybe<Scalars['Float']>;
  q20?: Maybe<Scalars['Float']>;
  q21?: Maybe<Scalars['Float']>;
};

export type Menopause_Greene_Scale_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q12?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
  q14?: InputMaybe<Order_By>;
  q15?: InputMaybe<Order_By>;
  q16?: InputMaybe<Order_By>;
  q17?: InputMaybe<Order_By>;
  q18?: InputMaybe<Order_By>;
  q19?: InputMaybe<Order_By>;
  q20?: InputMaybe<Order_By>;
  q21?: InputMaybe<Order_By>;
};

export type Munich_Chronotype = {
  __typename?: 'munich_chronotype';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  q1: Scalars['Int'];
  q2: Scalars['String'];
  q3?: Maybe<Scalars['Int']>;
  q4: Scalars['String'];
  q5: Scalars['Int'];
  q6: Scalars['Boolean'];
  q7: Yes_No_Question_Enum_Enum;
  q8: Scalars['String'];
  q9: Scalars['Int'];
  q10: Scalars['String'];
  q11: Scalars['Int'];
  q12: Scalars['String'];
  q13: Scalars['Int'];
  q14: Scalars['Boolean'];
  q15: Scalars['String'];
  q16: Scalars['String'];
  q17: Scalars['Int'];
  q18: Yes_No_Question_Enum_Enum;
  q19: Scalars['Boolean'];
  q20: Scalars['Boolean'];
  q21: Scalars['Boolean'];
  q22: Yes_No_Question_Enum_Enum;
  q23: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  user: User;
  user_id: Scalars['String'];
};

export type Munich_Chronotype_Aggregate = {
  __typename?: 'munich_chronotype_aggregate';
  aggregate?: Maybe<Munich_Chronotype_Aggregate_Fields>;
  nodes: Array<Munich_Chronotype>;
};

export type Munich_Chronotype_Aggregate_Fields = {
  __typename?: 'munich_chronotype_aggregate_fields';
  avg?: Maybe<Munich_Chronotype_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Munich_Chronotype_Max_Fields>;
  min?: Maybe<Munich_Chronotype_Min_Fields>;
  stddev?: Maybe<Munich_Chronotype_Stddev_Fields>;
  stddev_pop?: Maybe<Munich_Chronotype_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Munich_Chronotype_Stddev_Samp_Fields>;
  sum?: Maybe<Munich_Chronotype_Sum_Fields>;
  var_pop?: Maybe<Munich_Chronotype_Var_Pop_Fields>;
  var_samp?: Maybe<Munich_Chronotype_Var_Samp_Fields>;
  variance?: Maybe<Munich_Chronotype_Variance_Fields>;
};

export type Munich_Chronotype_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Munich_Chronotype_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Munich_Chronotype_Aggregate_Order_By = {
  avg?: InputMaybe<Munich_Chronotype_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Munich_Chronotype_Max_Order_By>;
  min?: InputMaybe<Munich_Chronotype_Min_Order_By>;
  stddev?: InputMaybe<Munich_Chronotype_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Munich_Chronotype_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Munich_Chronotype_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Munich_Chronotype_Sum_Order_By>;
  var_pop?: InputMaybe<Munich_Chronotype_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Munich_Chronotype_Var_Samp_Order_By>;
  variance?: InputMaybe<Munich_Chronotype_Variance_Order_By>;
};

export type Munich_Chronotype_Arr_Rel_Insert_Input = {
  data: Array<Munich_Chronotype_Insert_Input>;
  on_conflict?: InputMaybe<Munich_Chronotype_On_Conflict>;
};

export type Munich_Chronotype_Avg_Fields = {
  __typename?: 'munich_chronotype_avg_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
  q11?: Maybe<Scalars['Float']>;
  q13?: Maybe<Scalars['Float']>;
  q17?: Maybe<Scalars['Float']>;
  q23?: Maybe<Scalars['Float']>;
};

export type Munich_Chronotype_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
  q17?: InputMaybe<Order_By>;
  q23?: InputMaybe<Order_By>;
};

export type Munich_Chronotype_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Munich_Chronotype_Bool_Exp>>>;
  _not?: InputMaybe<Munich_Chronotype_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Munich_Chronotype_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  q1?: InputMaybe<Int_Comparison_Exp>;
  q2?: InputMaybe<String_Comparison_Exp>;
  q3?: InputMaybe<Int_Comparison_Exp>;
  q4?: InputMaybe<String_Comparison_Exp>;
  q5?: InputMaybe<Int_Comparison_Exp>;
  q6?: InputMaybe<Boolean_Comparison_Exp>;
  q7?: InputMaybe<Yes_No_Question_Enum_Enum_Comparison_Exp>;
  q8?: InputMaybe<String_Comparison_Exp>;
  q9?: InputMaybe<Int_Comparison_Exp>;
  q10?: InputMaybe<String_Comparison_Exp>;
  q11?: InputMaybe<Int_Comparison_Exp>;
  q12?: InputMaybe<String_Comparison_Exp>;
  q13?: InputMaybe<Int_Comparison_Exp>;
  q14?: InputMaybe<Boolean_Comparison_Exp>;
  q15?: InputMaybe<String_Comparison_Exp>;
  q16?: InputMaybe<String_Comparison_Exp>;
  q17?: InputMaybe<Int_Comparison_Exp>;
  q18?: InputMaybe<Yes_No_Question_Enum_Enum_Comparison_Exp>;
  q19?: InputMaybe<Boolean_Comparison_Exp>;
  q20?: InputMaybe<Boolean_Comparison_Exp>;
  q21?: InputMaybe<Boolean_Comparison_Exp>;
  q22?: InputMaybe<Yes_No_Question_Enum_Enum_Comparison_Exp>;
  q23?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

export enum Munich_Chronotype_Constraint {
  MunichChronotypePkey = 'munich_chronotype_pkey',
}

export type Munich_Chronotype_Core = {
  __typename?: 'munich_chronotype_core';
  activity_preference: Scalars['String'];
  analysis: Scalars['jsonb'];
  chronotype_identification: Scalars['String'];
  created_at: Scalars['timestamptz'];
  free_days_schedule_bedtime: Scalars['String'];
  free_days_schedule_sleep_onset_latency: Scalars['numeric'];
  free_days_schedule_sleep_time: Scalars['String'];
  free_days_schedule_use_alarm: Scalars['String'];
  free_days_schedule_wake_up_inertia: Scalars['numeric'];
  free_days_schedule_wake_up_time: Scalars['String'];
  has_regular_schedule: Scalars['String'];
  id: Scalars['Int'];
  result: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  user: User;
  user_id: Scalars['String'];
  work_days_per_week: Scalars['numeric'];
  work_schedule_bedtime: Scalars['String'];
  work_schedule_sleep_onset_latency: Scalars['numeric'];
  work_schedule_sleep_time: Scalars['String'];
  work_schedule_use_alarm: Scalars['String'];
  work_schedule_wake_up_inertia?: Maybe<Scalars['numeric']>;
  work_schedule_wake_up_time: Scalars['String'];
};

export type Munich_Chronotype_CoreAnalysisArgs = {
  path?: InputMaybe<Scalars['String']>;
};

export type Munich_Chronotype_Core_Aggregate = {
  __typename?: 'munich_chronotype_core_aggregate';
  aggregate?: Maybe<Munich_Chronotype_Core_Aggregate_Fields>;
  nodes: Array<Munich_Chronotype_Core>;
};

export type Munich_Chronotype_Core_Aggregate_Fields = {
  __typename?: 'munich_chronotype_core_aggregate_fields';
  avg?: Maybe<Munich_Chronotype_Core_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Munich_Chronotype_Core_Max_Fields>;
  min?: Maybe<Munich_Chronotype_Core_Min_Fields>;
  stddev?: Maybe<Munich_Chronotype_Core_Stddev_Fields>;
  stddev_pop?: Maybe<Munich_Chronotype_Core_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Munich_Chronotype_Core_Stddev_Samp_Fields>;
  sum?: Maybe<Munich_Chronotype_Core_Sum_Fields>;
  var_pop?: Maybe<Munich_Chronotype_Core_Var_Pop_Fields>;
  var_samp?: Maybe<Munich_Chronotype_Core_Var_Samp_Fields>;
  variance?: Maybe<Munich_Chronotype_Core_Variance_Fields>;
};

export type Munich_Chronotype_Core_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Munich_Chronotype_Core_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Munich_Chronotype_Core_Aggregate_Order_By = {
  avg?: InputMaybe<Munich_Chronotype_Core_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Munich_Chronotype_Core_Max_Order_By>;
  min?: InputMaybe<Munich_Chronotype_Core_Min_Order_By>;
  stddev?: InputMaybe<Munich_Chronotype_Core_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Munich_Chronotype_Core_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Munich_Chronotype_Core_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Munich_Chronotype_Core_Sum_Order_By>;
  var_pop?: InputMaybe<Munich_Chronotype_Core_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Munich_Chronotype_Core_Var_Samp_Order_By>;
  variance?: InputMaybe<Munich_Chronotype_Core_Variance_Order_By>;
};

export type Munich_Chronotype_Core_Append_Input = {
  analysis?: InputMaybe<Scalars['jsonb']>;
};

export type Munich_Chronotype_Core_Arr_Rel_Insert_Input = {
  data: Array<Munich_Chronotype_Core_Insert_Input>;
  on_conflict?: InputMaybe<Munich_Chronotype_Core_On_Conflict>;
};

export type Munich_Chronotype_Core_Avg_Fields = {
  __typename?: 'munich_chronotype_core_avg_fields';
  free_days_schedule_sleep_onset_latency?: Maybe<Scalars['Float']>;
  free_days_schedule_wake_up_inertia?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  work_days_per_week?: Maybe<Scalars['Float']>;
  work_schedule_sleep_onset_latency?: Maybe<Scalars['Float']>;
  work_schedule_wake_up_inertia?: Maybe<Scalars['Float']>;
};

export type Munich_Chronotype_Core_Avg_Order_By = {
  free_days_schedule_sleep_onset_latency?: InputMaybe<Order_By>;
  free_days_schedule_wake_up_inertia?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  work_days_per_week?: InputMaybe<Order_By>;
  work_schedule_sleep_onset_latency?: InputMaybe<Order_By>;
  work_schedule_wake_up_inertia?: InputMaybe<Order_By>;
};

export type Munich_Chronotype_Core_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Munich_Chronotype_Core_Bool_Exp>>>;
  _not?: InputMaybe<Munich_Chronotype_Core_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Munich_Chronotype_Core_Bool_Exp>>>;
  activity_preference?: InputMaybe<String_Comparison_Exp>;
  analysis?: InputMaybe<Jsonb_Comparison_Exp>;
  chronotype_identification?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  free_days_schedule_bedtime?: InputMaybe<String_Comparison_Exp>;
  free_days_schedule_sleep_onset_latency?: InputMaybe<Numeric_Comparison_Exp>;
  free_days_schedule_sleep_time?: InputMaybe<String_Comparison_Exp>;
  free_days_schedule_use_alarm?: InputMaybe<String_Comparison_Exp>;
  free_days_schedule_wake_up_inertia?: InputMaybe<Numeric_Comparison_Exp>;
  free_days_schedule_wake_up_time?: InputMaybe<String_Comparison_Exp>;
  has_regular_schedule?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  result?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  work_days_per_week?: InputMaybe<Numeric_Comparison_Exp>;
  work_schedule_bedtime?: InputMaybe<String_Comparison_Exp>;
  work_schedule_sleep_onset_latency?: InputMaybe<Numeric_Comparison_Exp>;
  work_schedule_sleep_time?: InputMaybe<String_Comparison_Exp>;
  work_schedule_use_alarm?: InputMaybe<String_Comparison_Exp>;
  work_schedule_wake_up_inertia?: InputMaybe<Numeric_Comparison_Exp>;
  work_schedule_wake_up_time?: InputMaybe<String_Comparison_Exp>;
};

export enum Munich_Chronotype_Core_Constraint {
  MunichChronotypeCorePkey = 'munich_chronotype_core_pkey',
}

export type Munich_Chronotype_Core_Delete_At_Path_Input = {
  analysis?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Munich_Chronotype_Core_Delete_Elem_Input = {
  analysis?: InputMaybe<Scalars['Int']>;
};

export type Munich_Chronotype_Core_Delete_Key_Input = {
  analysis?: InputMaybe<Scalars['String']>;
};

export type Munich_Chronotype_Core_Inc_Input = {
  free_days_schedule_sleep_onset_latency?: InputMaybe<Scalars['numeric']>;
  free_days_schedule_wake_up_inertia?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  work_days_per_week?: InputMaybe<Scalars['numeric']>;
  work_schedule_sleep_onset_latency?: InputMaybe<Scalars['numeric']>;
  work_schedule_wake_up_inertia?: InputMaybe<Scalars['numeric']>;
};

export type Munich_Chronotype_Core_Insert_Input = {
  activity_preference?: InputMaybe<Scalars['String']>;
  analysis?: InputMaybe<Scalars['jsonb']>;
  chronotype_identification?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  free_days_schedule_bedtime?: InputMaybe<Scalars['String']>;
  free_days_schedule_sleep_onset_latency?: InputMaybe<Scalars['numeric']>;
  free_days_schedule_sleep_time?: InputMaybe<Scalars['String']>;
  free_days_schedule_use_alarm?: InputMaybe<Scalars['String']>;
  free_days_schedule_wake_up_inertia?: InputMaybe<Scalars['numeric']>;
  free_days_schedule_wake_up_time?: InputMaybe<Scalars['String']>;
  has_regular_schedule?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  result?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
  work_days_per_week?: InputMaybe<Scalars['numeric']>;
  work_schedule_bedtime?: InputMaybe<Scalars['String']>;
  work_schedule_sleep_onset_latency?: InputMaybe<Scalars['numeric']>;
  work_schedule_sleep_time?: InputMaybe<Scalars['String']>;
  work_schedule_use_alarm?: InputMaybe<Scalars['String']>;
  work_schedule_wake_up_inertia?: InputMaybe<Scalars['numeric']>;
  work_schedule_wake_up_time?: InputMaybe<Scalars['String']>;
};

export type Munich_Chronotype_Core_Max_Fields = {
  __typename?: 'munich_chronotype_core_max_fields';
  activity_preference?: Maybe<Scalars['String']>;
  chronotype_identification?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  free_days_schedule_bedtime?: Maybe<Scalars['String']>;
  free_days_schedule_sleep_onset_latency?: Maybe<Scalars['numeric']>;
  free_days_schedule_sleep_time?: Maybe<Scalars['String']>;
  free_days_schedule_use_alarm?: Maybe<Scalars['String']>;
  free_days_schedule_wake_up_inertia?: Maybe<Scalars['numeric']>;
  free_days_schedule_wake_up_time?: Maybe<Scalars['String']>;
  has_regular_schedule?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  result?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
  work_days_per_week?: Maybe<Scalars['numeric']>;
  work_schedule_bedtime?: Maybe<Scalars['String']>;
  work_schedule_sleep_onset_latency?: Maybe<Scalars['numeric']>;
  work_schedule_sleep_time?: Maybe<Scalars['String']>;
  work_schedule_use_alarm?: Maybe<Scalars['String']>;
  work_schedule_wake_up_inertia?: Maybe<Scalars['numeric']>;
  work_schedule_wake_up_time?: Maybe<Scalars['String']>;
};

export type Munich_Chronotype_Core_Max_Order_By = {
  activity_preference?: InputMaybe<Order_By>;
  chronotype_identification?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  free_days_schedule_bedtime?: InputMaybe<Order_By>;
  free_days_schedule_sleep_onset_latency?: InputMaybe<Order_By>;
  free_days_schedule_sleep_time?: InputMaybe<Order_By>;
  free_days_schedule_use_alarm?: InputMaybe<Order_By>;
  free_days_schedule_wake_up_inertia?: InputMaybe<Order_By>;
  free_days_schedule_wake_up_time?: InputMaybe<Order_By>;
  has_regular_schedule?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  result?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  work_days_per_week?: InputMaybe<Order_By>;
  work_schedule_bedtime?: InputMaybe<Order_By>;
  work_schedule_sleep_onset_latency?: InputMaybe<Order_By>;
  work_schedule_sleep_time?: InputMaybe<Order_By>;
  work_schedule_use_alarm?: InputMaybe<Order_By>;
  work_schedule_wake_up_inertia?: InputMaybe<Order_By>;
  work_schedule_wake_up_time?: InputMaybe<Order_By>;
};

export type Munich_Chronotype_Core_Min_Fields = {
  __typename?: 'munich_chronotype_core_min_fields';
  activity_preference?: Maybe<Scalars['String']>;
  chronotype_identification?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  free_days_schedule_bedtime?: Maybe<Scalars['String']>;
  free_days_schedule_sleep_onset_latency?: Maybe<Scalars['numeric']>;
  free_days_schedule_sleep_time?: Maybe<Scalars['String']>;
  free_days_schedule_use_alarm?: Maybe<Scalars['String']>;
  free_days_schedule_wake_up_inertia?: Maybe<Scalars['numeric']>;
  free_days_schedule_wake_up_time?: Maybe<Scalars['String']>;
  has_regular_schedule?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  result?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
  work_days_per_week?: Maybe<Scalars['numeric']>;
  work_schedule_bedtime?: Maybe<Scalars['String']>;
  work_schedule_sleep_onset_latency?: Maybe<Scalars['numeric']>;
  work_schedule_sleep_time?: Maybe<Scalars['String']>;
  work_schedule_use_alarm?: Maybe<Scalars['String']>;
  work_schedule_wake_up_inertia?: Maybe<Scalars['numeric']>;
  work_schedule_wake_up_time?: Maybe<Scalars['String']>;
};

export type Munich_Chronotype_Core_Min_Order_By = {
  activity_preference?: InputMaybe<Order_By>;
  chronotype_identification?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  free_days_schedule_bedtime?: InputMaybe<Order_By>;
  free_days_schedule_sleep_onset_latency?: InputMaybe<Order_By>;
  free_days_schedule_sleep_time?: InputMaybe<Order_By>;
  free_days_schedule_use_alarm?: InputMaybe<Order_By>;
  free_days_schedule_wake_up_inertia?: InputMaybe<Order_By>;
  free_days_schedule_wake_up_time?: InputMaybe<Order_By>;
  has_regular_schedule?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  result?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  work_days_per_week?: InputMaybe<Order_By>;
  work_schedule_bedtime?: InputMaybe<Order_By>;
  work_schedule_sleep_onset_latency?: InputMaybe<Order_By>;
  work_schedule_sleep_time?: InputMaybe<Order_By>;
  work_schedule_use_alarm?: InputMaybe<Order_By>;
  work_schedule_wake_up_inertia?: InputMaybe<Order_By>;
  work_schedule_wake_up_time?: InputMaybe<Order_By>;
};

export type Munich_Chronotype_Core_Mutation_Response = {
  __typename?: 'munich_chronotype_core_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Munich_Chronotype_Core>;
};

export type Munich_Chronotype_Core_Obj_Rel_Insert_Input = {
  data: Munich_Chronotype_Core_Insert_Input;
  on_conflict?: InputMaybe<Munich_Chronotype_Core_On_Conflict>;
};

export type Munich_Chronotype_Core_On_Conflict = {
  constraint: Munich_Chronotype_Core_Constraint;
  update_columns: Array<Munich_Chronotype_Core_Update_Column>;
  where?: InputMaybe<Munich_Chronotype_Core_Bool_Exp>;
};

export type Munich_Chronotype_Core_Order_By = {
  activity_preference?: InputMaybe<Order_By>;
  analysis?: InputMaybe<Order_By>;
  chronotype_identification?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  free_days_schedule_bedtime?: InputMaybe<Order_By>;
  free_days_schedule_sleep_onset_latency?: InputMaybe<Order_By>;
  free_days_schedule_sleep_time?: InputMaybe<Order_By>;
  free_days_schedule_use_alarm?: InputMaybe<Order_By>;
  free_days_schedule_wake_up_inertia?: InputMaybe<Order_By>;
  free_days_schedule_wake_up_time?: InputMaybe<Order_By>;
  has_regular_schedule?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  result?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
  work_days_per_week?: InputMaybe<Order_By>;
  work_schedule_bedtime?: InputMaybe<Order_By>;
  work_schedule_sleep_onset_latency?: InputMaybe<Order_By>;
  work_schedule_sleep_time?: InputMaybe<Order_By>;
  work_schedule_use_alarm?: InputMaybe<Order_By>;
  work_schedule_wake_up_inertia?: InputMaybe<Order_By>;
  work_schedule_wake_up_time?: InputMaybe<Order_By>;
};

export type Munich_Chronotype_Core_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export type Munich_Chronotype_Core_Prepend_Input = {
  analysis?: InputMaybe<Scalars['jsonb']>;
};

export enum Munich_Chronotype_Core_Select_Column {
  ActivityPreference = 'activity_preference',
  Analysis = 'analysis',
  ChronotypeIdentification = 'chronotype_identification',
  CreatedAt = 'created_at',
  FreeDaysScheduleBedtime = 'free_days_schedule_bedtime',
  FreeDaysScheduleSleepOnsetLatency = 'free_days_schedule_sleep_onset_latency',
  FreeDaysScheduleSleepTime = 'free_days_schedule_sleep_time',
  FreeDaysScheduleUseAlarm = 'free_days_schedule_use_alarm',
  FreeDaysScheduleWakeUpInertia = 'free_days_schedule_wake_up_inertia',
  FreeDaysScheduleWakeUpTime = 'free_days_schedule_wake_up_time',
  HasRegularSchedule = 'has_regular_schedule',
  Id = 'id',
  Result = 'result',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
  WorkDaysPerWeek = 'work_days_per_week',
  WorkScheduleBedtime = 'work_schedule_bedtime',
  WorkScheduleSleepOnsetLatency = 'work_schedule_sleep_onset_latency',
  WorkScheduleSleepTime = 'work_schedule_sleep_time',
  WorkScheduleUseAlarm = 'work_schedule_use_alarm',
  WorkScheduleWakeUpInertia = 'work_schedule_wake_up_inertia',
  WorkScheduleWakeUpTime = 'work_schedule_wake_up_time',
}

export type Munich_Chronotype_Core_Set_Input = {
  activity_preference?: InputMaybe<Scalars['String']>;
  analysis?: InputMaybe<Scalars['jsonb']>;
  chronotype_identification?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  free_days_schedule_bedtime?: InputMaybe<Scalars['String']>;
  free_days_schedule_sleep_onset_latency?: InputMaybe<Scalars['numeric']>;
  free_days_schedule_sleep_time?: InputMaybe<Scalars['String']>;
  free_days_schedule_use_alarm?: InputMaybe<Scalars['String']>;
  free_days_schedule_wake_up_inertia?: InputMaybe<Scalars['numeric']>;
  free_days_schedule_wake_up_time?: InputMaybe<Scalars['String']>;
  has_regular_schedule?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  result?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
  work_days_per_week?: InputMaybe<Scalars['numeric']>;
  work_schedule_bedtime?: InputMaybe<Scalars['String']>;
  work_schedule_sleep_onset_latency?: InputMaybe<Scalars['numeric']>;
  work_schedule_sleep_time?: InputMaybe<Scalars['String']>;
  work_schedule_use_alarm?: InputMaybe<Scalars['String']>;
  work_schedule_wake_up_inertia?: InputMaybe<Scalars['numeric']>;
  work_schedule_wake_up_time?: InputMaybe<Scalars['String']>;
};

export type Munich_Chronotype_Core_Stddev_Fields = {
  __typename?: 'munich_chronotype_core_stddev_fields';
  free_days_schedule_sleep_onset_latency?: Maybe<Scalars['Float']>;
  free_days_schedule_wake_up_inertia?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  work_days_per_week?: Maybe<Scalars['Float']>;
  work_schedule_sleep_onset_latency?: Maybe<Scalars['Float']>;
  work_schedule_wake_up_inertia?: Maybe<Scalars['Float']>;
};

export type Munich_Chronotype_Core_Stddev_Order_By = {
  free_days_schedule_sleep_onset_latency?: InputMaybe<Order_By>;
  free_days_schedule_wake_up_inertia?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  work_days_per_week?: InputMaybe<Order_By>;
  work_schedule_sleep_onset_latency?: InputMaybe<Order_By>;
  work_schedule_wake_up_inertia?: InputMaybe<Order_By>;
};

export type Munich_Chronotype_Core_Stddev_Pop_Fields = {
  __typename?: 'munich_chronotype_core_stddev_pop_fields';
  free_days_schedule_sleep_onset_latency?: Maybe<Scalars['Float']>;
  free_days_schedule_wake_up_inertia?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  work_days_per_week?: Maybe<Scalars['Float']>;
  work_schedule_sleep_onset_latency?: Maybe<Scalars['Float']>;
  work_schedule_wake_up_inertia?: Maybe<Scalars['Float']>;
};

export type Munich_Chronotype_Core_Stddev_Pop_Order_By = {
  free_days_schedule_sleep_onset_latency?: InputMaybe<Order_By>;
  free_days_schedule_wake_up_inertia?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  work_days_per_week?: InputMaybe<Order_By>;
  work_schedule_sleep_onset_latency?: InputMaybe<Order_By>;
  work_schedule_wake_up_inertia?: InputMaybe<Order_By>;
};

export type Munich_Chronotype_Core_Stddev_Samp_Fields = {
  __typename?: 'munich_chronotype_core_stddev_samp_fields';
  free_days_schedule_sleep_onset_latency?: Maybe<Scalars['Float']>;
  free_days_schedule_wake_up_inertia?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  work_days_per_week?: Maybe<Scalars['Float']>;
  work_schedule_sleep_onset_latency?: Maybe<Scalars['Float']>;
  work_schedule_wake_up_inertia?: Maybe<Scalars['Float']>;
};

export type Munich_Chronotype_Core_Stddev_Samp_Order_By = {
  free_days_schedule_sleep_onset_latency?: InputMaybe<Order_By>;
  free_days_schedule_wake_up_inertia?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  work_days_per_week?: InputMaybe<Order_By>;
  work_schedule_sleep_onset_latency?: InputMaybe<Order_By>;
  work_schedule_wake_up_inertia?: InputMaybe<Order_By>;
};

export type Munich_Chronotype_Core_Sum_Fields = {
  __typename?: 'munich_chronotype_core_sum_fields';
  free_days_schedule_sleep_onset_latency?: Maybe<Scalars['numeric']>;
  free_days_schedule_wake_up_inertia?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  work_days_per_week?: Maybe<Scalars['numeric']>;
  work_schedule_sleep_onset_latency?: Maybe<Scalars['numeric']>;
  work_schedule_wake_up_inertia?: Maybe<Scalars['numeric']>;
};

export type Munich_Chronotype_Core_Sum_Order_By = {
  free_days_schedule_sleep_onset_latency?: InputMaybe<Order_By>;
  free_days_schedule_wake_up_inertia?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  work_days_per_week?: InputMaybe<Order_By>;
  work_schedule_sleep_onset_latency?: InputMaybe<Order_By>;
  work_schedule_wake_up_inertia?: InputMaybe<Order_By>;
};

export enum Munich_Chronotype_Core_Update_Column {
  ActivityPreference = 'activity_preference',
  Analysis = 'analysis',
  ChronotypeIdentification = 'chronotype_identification',
  CreatedAt = 'created_at',
  FreeDaysScheduleBedtime = 'free_days_schedule_bedtime',
  FreeDaysScheduleSleepOnsetLatency = 'free_days_schedule_sleep_onset_latency',
  FreeDaysScheduleSleepTime = 'free_days_schedule_sleep_time',
  FreeDaysScheduleUseAlarm = 'free_days_schedule_use_alarm',
  FreeDaysScheduleWakeUpInertia = 'free_days_schedule_wake_up_inertia',
  FreeDaysScheduleWakeUpTime = 'free_days_schedule_wake_up_time',
  HasRegularSchedule = 'has_regular_schedule',
  Id = 'id',
  Result = 'result',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
  WorkDaysPerWeek = 'work_days_per_week',
  WorkScheduleBedtime = 'work_schedule_bedtime',
  WorkScheduleSleepOnsetLatency = 'work_schedule_sleep_onset_latency',
  WorkScheduleSleepTime = 'work_schedule_sleep_time',
  WorkScheduleUseAlarm = 'work_schedule_use_alarm',
  WorkScheduleWakeUpInertia = 'work_schedule_wake_up_inertia',
  WorkScheduleWakeUpTime = 'work_schedule_wake_up_time',
}

export type Munich_Chronotype_Core_Var_Pop_Fields = {
  __typename?: 'munich_chronotype_core_var_pop_fields';
  free_days_schedule_sleep_onset_latency?: Maybe<Scalars['Float']>;
  free_days_schedule_wake_up_inertia?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  work_days_per_week?: Maybe<Scalars['Float']>;
  work_schedule_sleep_onset_latency?: Maybe<Scalars['Float']>;
  work_schedule_wake_up_inertia?: Maybe<Scalars['Float']>;
};

export type Munich_Chronotype_Core_Var_Pop_Order_By = {
  free_days_schedule_sleep_onset_latency?: InputMaybe<Order_By>;
  free_days_schedule_wake_up_inertia?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  work_days_per_week?: InputMaybe<Order_By>;
  work_schedule_sleep_onset_latency?: InputMaybe<Order_By>;
  work_schedule_wake_up_inertia?: InputMaybe<Order_By>;
};

export type Munich_Chronotype_Core_Var_Samp_Fields = {
  __typename?: 'munich_chronotype_core_var_samp_fields';
  free_days_schedule_sleep_onset_latency?: Maybe<Scalars['Float']>;
  free_days_schedule_wake_up_inertia?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  work_days_per_week?: Maybe<Scalars['Float']>;
  work_schedule_sleep_onset_latency?: Maybe<Scalars['Float']>;
  work_schedule_wake_up_inertia?: Maybe<Scalars['Float']>;
};

export type Munich_Chronotype_Core_Var_Samp_Order_By = {
  free_days_schedule_sleep_onset_latency?: InputMaybe<Order_By>;
  free_days_schedule_wake_up_inertia?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  work_days_per_week?: InputMaybe<Order_By>;
  work_schedule_sleep_onset_latency?: InputMaybe<Order_By>;
  work_schedule_wake_up_inertia?: InputMaybe<Order_By>;
};

export type Munich_Chronotype_Core_Variance_Fields = {
  __typename?: 'munich_chronotype_core_variance_fields';
  free_days_schedule_sleep_onset_latency?: Maybe<Scalars['Float']>;
  free_days_schedule_wake_up_inertia?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  work_days_per_week?: Maybe<Scalars['Float']>;
  work_schedule_sleep_onset_latency?: Maybe<Scalars['Float']>;
  work_schedule_wake_up_inertia?: Maybe<Scalars['Float']>;
};

export type Munich_Chronotype_Core_Variance_Order_By = {
  free_days_schedule_sleep_onset_latency?: InputMaybe<Order_By>;
  free_days_schedule_wake_up_inertia?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  work_days_per_week?: InputMaybe<Order_By>;
  work_schedule_sleep_onset_latency?: InputMaybe<Order_By>;
  work_schedule_wake_up_inertia?: InputMaybe<Order_By>;
};

export type Munich_Chronotype_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['Int']>;
  q3?: InputMaybe<Scalars['Int']>;
  q5?: InputMaybe<Scalars['Int']>;
  q9?: InputMaybe<Scalars['Int']>;
  q11?: InputMaybe<Scalars['Int']>;
  q13?: InputMaybe<Scalars['Int']>;
  q17?: InputMaybe<Scalars['Int']>;
  q23?: InputMaybe<Scalars['Int']>;
};

export type Munich_Chronotype_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['Int']>;
  q2?: InputMaybe<Scalars['String']>;
  q3?: InputMaybe<Scalars['Int']>;
  q4?: InputMaybe<Scalars['String']>;
  q5?: InputMaybe<Scalars['Int']>;
  q6?: InputMaybe<Scalars['Boolean']>;
  q7?: InputMaybe<Yes_No_Question_Enum_Enum>;
  q8?: InputMaybe<Scalars['String']>;
  q9?: InputMaybe<Scalars['Int']>;
  q10?: InputMaybe<Scalars['String']>;
  q11?: InputMaybe<Scalars['Int']>;
  q12?: InputMaybe<Scalars['String']>;
  q13?: InputMaybe<Scalars['Int']>;
  q14?: InputMaybe<Scalars['Boolean']>;
  q15?: InputMaybe<Scalars['String']>;
  q16?: InputMaybe<Scalars['String']>;
  q17?: InputMaybe<Scalars['Int']>;
  q18?: InputMaybe<Yes_No_Question_Enum_Enum>;
  q19?: InputMaybe<Scalars['Boolean']>;
  q20?: InputMaybe<Scalars['Boolean']>;
  q21?: InputMaybe<Scalars['Boolean']>;
  q22?: InputMaybe<Yes_No_Question_Enum_Enum>;
  q23?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Munich_Chronotype_Max_Fields = {
  __typename?: 'munich_chronotype_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['Int']>;
  q2?: Maybe<Scalars['String']>;
  q3?: Maybe<Scalars['Int']>;
  q4?: Maybe<Scalars['String']>;
  q5?: Maybe<Scalars['Int']>;
  q8?: Maybe<Scalars['String']>;
  q9?: Maybe<Scalars['Int']>;
  q10?: Maybe<Scalars['String']>;
  q11?: Maybe<Scalars['Int']>;
  q12?: Maybe<Scalars['String']>;
  q13?: Maybe<Scalars['Int']>;
  q15?: Maybe<Scalars['String']>;
  q16?: Maybe<Scalars['String']>;
  q17?: Maybe<Scalars['Int']>;
  q23?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Munich_Chronotype_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q12?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
  q15?: InputMaybe<Order_By>;
  q16?: InputMaybe<Order_By>;
  q17?: InputMaybe<Order_By>;
  q23?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Munich_Chronotype_Min_Fields = {
  __typename?: 'munich_chronotype_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['Int']>;
  q2?: Maybe<Scalars['String']>;
  q3?: Maybe<Scalars['Int']>;
  q4?: Maybe<Scalars['String']>;
  q5?: Maybe<Scalars['Int']>;
  q8?: Maybe<Scalars['String']>;
  q9?: Maybe<Scalars['Int']>;
  q10?: Maybe<Scalars['String']>;
  q11?: Maybe<Scalars['Int']>;
  q12?: Maybe<Scalars['String']>;
  q13?: Maybe<Scalars['Int']>;
  q15?: Maybe<Scalars['String']>;
  q16?: Maybe<Scalars['String']>;
  q17?: Maybe<Scalars['Int']>;
  q23?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Munich_Chronotype_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q12?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
  q15?: InputMaybe<Order_By>;
  q16?: InputMaybe<Order_By>;
  q17?: InputMaybe<Order_By>;
  q23?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Munich_Chronotype_Mutation_Response = {
  __typename?: 'munich_chronotype_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Munich_Chronotype>;
};

export type Munich_Chronotype_Obj_Rel_Insert_Input = {
  data: Munich_Chronotype_Insert_Input;
  on_conflict?: InputMaybe<Munich_Chronotype_On_Conflict>;
};

export type Munich_Chronotype_On_Conflict = {
  constraint: Munich_Chronotype_Constraint;
  update_columns: Array<Munich_Chronotype_Update_Column>;
  where?: InputMaybe<Munich_Chronotype_Bool_Exp>;
};

export type Munich_Chronotype_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q12?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
  q14?: InputMaybe<Order_By>;
  q15?: InputMaybe<Order_By>;
  q16?: InputMaybe<Order_By>;
  q17?: InputMaybe<Order_By>;
  q18?: InputMaybe<Order_By>;
  q19?: InputMaybe<Order_By>;
  q20?: InputMaybe<Order_By>;
  q21?: InputMaybe<Order_By>;
  q22?: InputMaybe<Order_By>;
  q23?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Munich_Chronotype_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export enum Munich_Chronotype_Select_Column {
  CreatedAt = 'created_at',
  Id = 'id',
  Q1 = 'q1',
  Q2 = 'q2',
  Q3 = 'q3',
  Q4 = 'q4',
  Q5 = 'q5',
  Q6 = 'q6',
  Q7 = 'q7',
  Q8 = 'q8',
  Q9 = 'q9',
  Q10 = 'q10',
  Q11 = 'q11',
  Q12 = 'q12',
  Q13 = 'q13',
  Q14 = 'q14',
  Q15 = 'q15',
  Q16 = 'q16',
  Q17 = 'q17',
  Q18 = 'q18',
  Q19 = 'q19',
  Q20 = 'q20',
  Q21 = 'q21',
  Q22 = 'q22',
  Q23 = 'q23',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Munich_Chronotype_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['Int']>;
  q2?: InputMaybe<Scalars['String']>;
  q3?: InputMaybe<Scalars['Int']>;
  q4?: InputMaybe<Scalars['String']>;
  q5?: InputMaybe<Scalars['Int']>;
  q6?: InputMaybe<Scalars['Boolean']>;
  q7?: InputMaybe<Yes_No_Question_Enum_Enum>;
  q8?: InputMaybe<Scalars['String']>;
  q9?: InputMaybe<Scalars['Int']>;
  q10?: InputMaybe<Scalars['String']>;
  q11?: InputMaybe<Scalars['Int']>;
  q12?: InputMaybe<Scalars['String']>;
  q13?: InputMaybe<Scalars['Int']>;
  q14?: InputMaybe<Scalars['Boolean']>;
  q15?: InputMaybe<Scalars['String']>;
  q16?: InputMaybe<Scalars['String']>;
  q17?: InputMaybe<Scalars['Int']>;
  q18?: InputMaybe<Yes_No_Question_Enum_Enum>;
  q19?: InputMaybe<Scalars['Boolean']>;
  q20?: InputMaybe<Scalars['Boolean']>;
  q21?: InputMaybe<Scalars['Boolean']>;
  q22?: InputMaybe<Yes_No_Question_Enum_Enum>;
  q23?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Munich_Chronotype_Stddev_Fields = {
  __typename?: 'munich_chronotype_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
  q11?: Maybe<Scalars['Float']>;
  q13?: Maybe<Scalars['Float']>;
  q17?: Maybe<Scalars['Float']>;
  q23?: Maybe<Scalars['Float']>;
};

export type Munich_Chronotype_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
  q17?: InputMaybe<Order_By>;
  q23?: InputMaybe<Order_By>;
};

export type Munich_Chronotype_Stddev_Pop_Fields = {
  __typename?: 'munich_chronotype_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
  q11?: Maybe<Scalars['Float']>;
  q13?: Maybe<Scalars['Float']>;
  q17?: Maybe<Scalars['Float']>;
  q23?: Maybe<Scalars['Float']>;
};

export type Munich_Chronotype_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
  q17?: InputMaybe<Order_By>;
  q23?: InputMaybe<Order_By>;
};

export type Munich_Chronotype_Stddev_Samp_Fields = {
  __typename?: 'munich_chronotype_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
  q11?: Maybe<Scalars['Float']>;
  q13?: Maybe<Scalars['Float']>;
  q17?: Maybe<Scalars['Float']>;
  q23?: Maybe<Scalars['Float']>;
};

export type Munich_Chronotype_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
  q17?: InputMaybe<Order_By>;
  q23?: InputMaybe<Order_By>;
};

export type Munich_Chronotype_Sum_Fields = {
  __typename?: 'munich_chronotype_sum_fields';
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['Int']>;
  q3?: Maybe<Scalars['Int']>;
  q5?: Maybe<Scalars['Int']>;
  q9?: Maybe<Scalars['Int']>;
  q11?: Maybe<Scalars['Int']>;
  q13?: Maybe<Scalars['Int']>;
  q17?: Maybe<Scalars['Int']>;
  q23?: Maybe<Scalars['Int']>;
};

export type Munich_Chronotype_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
  q17?: InputMaybe<Order_By>;
  q23?: InputMaybe<Order_By>;
};

export enum Munich_Chronotype_Update_Column {
  CreatedAt = 'created_at',
  Id = 'id',
  Q1 = 'q1',
  Q2 = 'q2',
  Q3 = 'q3',
  Q4 = 'q4',
  Q5 = 'q5',
  Q6 = 'q6',
  Q7 = 'q7',
  Q8 = 'q8',
  Q9 = 'q9',
  Q10 = 'q10',
  Q11 = 'q11',
  Q12 = 'q12',
  Q13 = 'q13',
  Q14 = 'q14',
  Q15 = 'q15',
  Q16 = 'q16',
  Q17 = 'q17',
  Q18 = 'q18',
  Q19 = 'q19',
  Q20 = 'q20',
  Q21 = 'q21',
  Q22 = 'q22',
  Q23 = 'q23',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Munich_Chronotype_Var_Pop_Fields = {
  __typename?: 'munich_chronotype_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
  q11?: Maybe<Scalars['Float']>;
  q13?: Maybe<Scalars['Float']>;
  q17?: Maybe<Scalars['Float']>;
  q23?: Maybe<Scalars['Float']>;
};

export type Munich_Chronotype_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
  q17?: InputMaybe<Order_By>;
  q23?: InputMaybe<Order_By>;
};

export type Munich_Chronotype_Var_Samp_Fields = {
  __typename?: 'munich_chronotype_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
  q11?: Maybe<Scalars['Float']>;
  q13?: Maybe<Scalars['Float']>;
  q17?: Maybe<Scalars['Float']>;
  q23?: Maybe<Scalars['Float']>;
};

export type Munich_Chronotype_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
  q17?: InputMaybe<Order_By>;
  q23?: InputMaybe<Order_By>;
};

export type Munich_Chronotype_Variance_Fields = {
  __typename?: 'munich_chronotype_variance_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
  q11?: Maybe<Scalars['Float']>;
  q13?: Maybe<Scalars['Float']>;
  q17?: Maybe<Scalars['Float']>;
  q23?: Maybe<Scalars['Float']>;
};

export type Munich_Chronotype_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
  q17?: InputMaybe<Order_By>;
  q23?: InputMaybe<Order_By>;
};

export type Mutation_Root = {
  __typename?: 'mutation_root';
  delete_apnea_questionnaire?: Maybe<Apnea_Questionnaire_Mutation_Response>;
  delete_apnea_questionnaire_by_pk?: Maybe<Apnea_Questionnaire>;
  delete_apnea_scan_photo?: Maybe<Apnea_Scan_Photo_Mutation_Response>;
  delete_apnea_scan_photo_by_pk?: Maybe<Apnea_Scan_Photo>;
  delete_app_customization?: Maybe<App_Customization_Mutation_Response>;
  delete_app_customization_by_pk?: Maybe<App_Customization>;
  delete_app_customization_type_enum?: Maybe<App_Customization_Type_Enum_Mutation_Response>;
  delete_app_customization_type_enum_by_pk?: Maybe<App_Customization_Type_Enum>;
  delete_appointment?: Maybe<Appointment_Mutation_Response>;
  delete_appointment_by_pk?: Maybe<Appointment>;
  delete_appointment_status_enum?: Maybe<Appointment_Status_Enum_Mutation_Response>;
  delete_appointment_status_enum_by_pk?: Maybe<Appointment_Status_Enum>;
  delete_assesment?: Maybe<Assesment_Mutation_Response>;
  delete_assesment_by_pk?: Maybe<Assesment>;
  delete_b2b_diagnosis_v1?: Maybe<B2b_Diagnosis_V1_Mutation_Response>;
  delete_b2b_diagnosis_v1_by_pk?: Maybe<B2b_Diagnosis_V1>;
  delete_catastrophic_thinkings?: Maybe<Catastrophic_Thinkings_Mutation_Response>;
  delete_catastrophic_thinkings_by_pk?: Maybe<Catastrophic_Thinkings>;
  delete_community_user?: Maybe<Community_User_Mutation_Response>;
  delete_community_user_by_pk?: Maybe<Community_User>;
  delete_company?: Maybe<Company_Mutation_Response>;
  delete_company_by_pk?: Maybe<Company>;
  delete_company_health_carer?: Maybe<Company_Health_Carer_Mutation_Response>;
  delete_company_health_carer_by_pk?: Maybe<Company_Health_Carer>;
  delete_company_health_carer_kind_enum?: Maybe<Company_Health_Carer_Kind_Enum_Mutation_Response>;
  delete_company_health_carer_kind_enum_by_pk?: Maybe<Company_Health_Carer_Kind_Enum>;
  delete_company_user?: Maybe<Company_User_Mutation_Response>;
  delete_company_user_by_pk?: Maybe<Company_User>;
  delete_coupon?: Maybe<Coupon_Mutation_Response>;
  delete_coupon_by_pk?: Maybe<Coupon>;
  delete_crm?: Maybe<Crm_Mutation_Response>;
  delete_crm_by_pk?: Maybe<Crm>;
  delete_dream?: Maybe<Dream_Mutation_Response>;
  delete_dream_by_pk?: Maybe<Dream>;
  delete_eficiency?: Maybe<Eficiency_Mutation_Response>;
  delete_eficiency_by_pk?: Maybe<Eficiency>;
  delete_epworth?: Maybe<Epworth_Mutation_Response>;
  delete_epworth_by_pk?: Maybe<Epworth>;
  delete_gad?: Maybe<Gad_Mutation_Response>;
  delete_gad7?: Maybe<Gad7_Mutation_Response>;
  delete_gad7_by_pk?: Maybe<Gad7>;
  delete_gad_by_pk?: Maybe<Gad>;
  delete_generic_questionnaire?: Maybe<Generic_Questionnaire_Mutation_Response>;
  delete_generic_questionnaire_by_pk?: Maybe<Generic_Questionnaire>;
  delete_generic_questionnaire_name_enum?: Maybe<Generic_Questionnaire_Name_Enum_Mutation_Response>;
  delete_generic_questionnaire_name_enum_by_pk?: Maybe<Generic_Questionnaire_Name_Enum>;
  delete_google_sheet_row?: Maybe<Google_Sheet_Row_Mutation_Response>;
  delete_google_sheet_row_by_pk?: Maybe<Google_Sheet_Row>;
  delete_gratitude?: Maybe<Gratitude_Mutation_Response>;
  delete_gratitude_by_pk?: Maybe<Gratitude>;
  delete_health_carer?: Maybe<Health_Carer_Mutation_Response>;
  delete_health_carer_by_pk?: Maybe<Health_Carer>;
  delete_health_carer_membership_kind_enum?: Maybe<Health_Carer_Membership_Kind_Enum_Mutation_Response>;
  delete_health_carer_membership_kind_enum_by_pk?: Maybe<Health_Carer_Membership_Kind_Enum>;
  delete_health_carer_money_request?: Maybe<Health_Carer_Money_Request_Mutation_Response>;
  delete_health_carer_money_request_by_pk?: Maybe<Health_Carer_Money_Request>;
  delete_health_carer_money_request_log?: Maybe<Health_Carer_Money_Request_Log_Mutation_Response>;
  delete_health_carer_money_request_log_by_pk?: Maybe<Health_Carer_Money_Request_Log>;
  delete_health_carer_money_request_status_enum?: Maybe<Health_Carer_Money_Request_Status_Enum_Mutation_Response>;
  delete_health_carer_money_request_status_enum_by_pk?: Maybe<Health_Carer_Money_Request_Status_Enum>;
  delete_health_carer_pagarme_recipient?: Maybe<Health_Carer_Pagarme_Recipient_Mutation_Response>;
  delete_health_carer_pagarme_recipient_by_pk?: Maybe<Health_Carer_Pagarme_Recipient>;
  delete_health_carer_telehealth?: Maybe<Health_Carer_Telehealth_Mutation_Response>;
  delete_health_carer_telehealth_by_pk?: Maybe<Health_Carer_Telehealth>;
  delete_health_carer_transactions?: Maybe<Health_Carer_Transactions_Mutation_Response>;
  delete_health_carer_transactions_by_pk?: Maybe<Health_Carer_Transactions>;
  delete_health_carer_user?: Maybe<Health_Carer_User_Mutation_Response>;
  delete_health_carer_user_by_pk?: Maybe<Health_Carer_User>;
  delete_isi?: Maybe<Isi_Mutation_Response>;
  delete_isi_by_pk?: Maybe<Isi>;
  delete_journey?: Maybe<Journey_Mutation_Response>;
  delete_journey_by_pk?: Maybe<Journey>;
  delete_journey_program_sessions?: Maybe<Journey_Program_Sessions_Mutation_Response>;
  delete_journey_program_sessions_by_pk?: Maybe<Journey_Program_Sessions>;
  delete_kupperman?: Maybe<Kupperman_Mutation_Response>;
  delete_kupperman_by_pk?: Maybe<Kupperman>;
  delete_landing_page_redirect?: Maybe<Landing_Page_Redirect_Mutation_Response>;
  delete_landing_page_redirect_by_pk?: Maybe<Landing_Page_Redirect>;
  delete_log?: Maybe<Log_Mutation_Response>;
  delete_log_by_pk?: Maybe<Log>;
  delete_log_type_enum?: Maybe<Log_Type_Enum_Mutation_Response>;
  delete_log_type_enum_by_pk?: Maybe<Log_Type_Enum>;
  delete_menopause_greene_scale?: Maybe<Menopause_Greene_Scale_Mutation_Response>;
  delete_menopause_greene_scale_by_pk?: Maybe<Menopause_Greene_Scale>;
  delete_munich_chronotype?: Maybe<Munich_Chronotype_Mutation_Response>;
  delete_munich_chronotype_by_pk?: Maybe<Munich_Chronotype>;
  delete_munich_chronotype_core?: Maybe<Munich_Chronotype_Core_Mutation_Response>;
  delete_munich_chronotype_core_by_pk?: Maybe<Munich_Chronotype_Core>;
  delete_os_enum?: Maybe<Os_Enum_Mutation_Response>;
  delete_os_enum_by_pk?: Maybe<Os_Enum>;
  delete_parking_lot?: Maybe<Parking_Lot_Mutation_Response>;
  delete_parking_lot_by_pk?: Maybe<Parking_Lot>;
  delete_personality_questionnaire?: Maybe<Personality_Questionnaire_Mutation_Response>;
  delete_personality_questionnaire_by_pk?: Maybe<Personality_Questionnaire>;
  delete_phq?: Maybe<Phq_Mutation_Response>;
  delete_phq9?: Maybe<Phq9_Mutation_Response>;
  delete_phq9_by_pk?: Maybe<Phq9>;
  delete_phq_by_pk?: Maybe<Phq>;
  delete_pre_user?: Maybe<Pre_User_Mutation_Response>;
  delete_pre_user_by_pk?: Maybe<Pre_User>;
  delete_pre_user_source_enum?: Maybe<Pre_User_Source_Enum_Mutation_Response>;
  delete_pre_user_source_enum_by_pk?: Maybe<Pre_User_Source_Enum>;
  delete_productivity?: Maybe<Productivity_Mutation_Response>;
  delete_productivity_by_pk?: Maybe<Productivity>;
  delete_program_session?: Maybe<Program_Session_Mutation_Response>;
  delete_program_session_by_pk?: Maybe<Program_Session>;
  delete_psqi?: Maybe<Psqi_Mutation_Response>;
  delete_psqi_by_pk?: Maybe<Psqi>;
  delete_rating?: Maybe<Rating_Mutation_Response>;
  delete_rating_by_pk?: Maybe<Rating>;
  delete_recurrence_model_enum?: Maybe<Recurrence_Model_Enum_Mutation_Response>;
  delete_recurrence_model_enum_by_pk?: Maybe<Recurrence_Model_Enum>;
  delete_reminder?: Maybe<Reminder_Mutation_Response>;
  delete_reminder_by_pk?: Maybe<Reminder>;
  delete_reminder_type_enum?: Maybe<Reminder_Type_Enum_Mutation_Response>;
  delete_reminder_type_enum_by_pk?: Maybe<Reminder_Type_Enum>;
  delete_sleep_diary?: Maybe<Sleep_Diary_Mutation_Response>;
  delete_sleep_diary_apple_health?: Maybe<Sleep_Diary_Apple_Health_Mutation_Response>;
  delete_sleep_diary_apple_health_by_pk?: Maybe<Sleep_Diary_Apple_Health>;
  delete_sleep_diary_by_pk?: Maybe<Sleep_Diary>;
  delete_sleep_diary_google_fit?: Maybe<Sleep_Diary_Google_Fit_Mutation_Response>;
  delete_sleep_diary_google_fit_by_pk?: Maybe<Sleep_Diary_Google_Fit>;
  delete_sleep_diary_interval?: Maybe<Sleep_Diary_Interval_Mutation_Response>;
  delete_sleep_diary_interval_by_pk?: Maybe<Sleep_Diary_Interval>;
  delete_sleep_diary_medicine?: Maybe<Sleep_Diary_Medicine_Mutation_Response>;
  delete_sleep_diary_medicine_by_pk?: Maybe<Sleep_Diary_Medicine>;
  delete_sleep_diary_sleep_tag?: Maybe<Sleep_Diary_Sleep_Tag_Mutation_Response>;
  delete_sleep_diary_sleep_tag_by_pk?: Maybe<Sleep_Diary_Sleep_Tag>;
  delete_sleep_restriction?: Maybe<Sleep_Restriction_Mutation_Response>;
  delete_sleep_restriction_by_pk?: Maybe<Sleep_Restriction>;
  delete_sleep_tag_enum?: Maybe<Sleep_Tag_Enum_Mutation_Response>;
  delete_sleep_tag_enum_by_pk?: Maybe<Sleep_Tag_Enum>;
  delete_sleep_tracker_compressed_data?: Maybe<Sleep_Tracker_Compressed_Data_Mutation_Response>;
  delete_sleep_tracker_compressed_data_by_pk?: Maybe<Sleep_Tracker_Compressed_Data>;
  delete_stop_bang?: Maybe<Stop_Bang_Mutation_Response>;
  delete_stop_bang_by_pk?: Maybe<Stop_Bang>;
  delete_subscription?: Maybe<Subscription_Mutation_Response>;
  delete_subscription_by_pk?: Maybe<Subscription>;
  delete_technique_resource?: Maybe<Technique_Resource_Mutation_Response>;
  delete_technique_resource_by_pk?: Maybe<Technique_Resource>;
  delete_toolbox?: Maybe<Toolbox_Mutation_Response>;
  delete_toolbox_by_pk?: Maybe<Toolbox>;
  delete_user?: Maybe<User_Mutation_Response>;
  delete_user_by_pk?: Maybe<User>;
  delete_user_checkout?: Maybe<User_Checkout_Mutation_Response>;
  delete_user_checkout_by_pk?: Maybe<User_Checkout>;
  delete_user_coupon?: Maybe<User_Coupon_Mutation_Response>;
  delete_user_coupon_by_pk?: Maybe<User_Coupon>;
  delete_user_journey?: Maybe<User_Journey_Mutation_Response>;
  delete_user_journey_by_pk?: Maybe<User_Journey>;
  delete_user_key_value?: Maybe<User_Key_Value_Mutation_Response>;
  delete_user_key_value_by_pk?: Maybe<User_Key_Value>;
  delete_user_key_value_key_enum?: Maybe<User_Key_Value_Key_Enum_Mutation_Response>;
  delete_user_key_value_key_enum_by_pk?: Maybe<User_Key_Value_Key_Enum>;
  delete_user_notification?: Maybe<User_Notification_Mutation_Response>;
  delete_user_notification_by_pk?: Maybe<User_Notification>;
  delete_user_program_session?: Maybe<User_Program_Session_Mutation_Response>;
  delete_user_program_session_by_pk?: Maybe<User_Program_Session>;
  delete_user_program_session_enum?: Maybe<User_Program_Session_Enum_Mutation_Response>;
  delete_user_program_session_enum_by_pk?: Maybe<User_Program_Session_Enum>;
  delete_user_reminder_info?: Maybe<User_Reminder_Info_Mutation_Response>;
  delete_user_reminder_info_by_pk?: Maybe<User_Reminder_Info>;
  delete_user_tag?: Maybe<User_Tag_Mutation_Response>;
  delete_user_tag_by_pk?: Maybe<User_Tag>;
  delete_user_telehealth?: Maybe<User_Telehealth_Mutation_Response>;
  delete_user_telehealth_by_pk?: Maybe<User_Telehealth>;
  delete_yes_no_question_enum?: Maybe<Yes_No_Question_Enum_Mutation_Response>;
  delete_yes_no_question_enum_by_pk?: Maybe<Yes_No_Question_Enum>;
  insert_apnea_questionnaire?: Maybe<Apnea_Questionnaire_Mutation_Response>;
  insert_apnea_questionnaire_one?: Maybe<Apnea_Questionnaire>;
  insert_apnea_scan_photo?: Maybe<Apnea_Scan_Photo_Mutation_Response>;
  insert_apnea_scan_photo_one?: Maybe<Apnea_Scan_Photo>;
  insert_app_customization?: Maybe<App_Customization_Mutation_Response>;
  insert_app_customization_one?: Maybe<App_Customization>;
  insert_app_customization_type_enum?: Maybe<App_Customization_Type_Enum_Mutation_Response>;
  insert_app_customization_type_enum_one?: Maybe<App_Customization_Type_Enum>;
  insert_appointment?: Maybe<Appointment_Mutation_Response>;
  insert_appointment_one?: Maybe<Appointment>;
  insert_appointment_status_enum?: Maybe<Appointment_Status_Enum_Mutation_Response>;
  insert_appointment_status_enum_one?: Maybe<Appointment_Status_Enum>;
  insert_assesment?: Maybe<Assesment_Mutation_Response>;
  insert_assesment_one?: Maybe<Assesment>;
  insert_b2b_diagnosis_v1?: Maybe<B2b_Diagnosis_V1_Mutation_Response>;
  insert_b2b_diagnosis_v1_one?: Maybe<B2b_Diagnosis_V1>;
  insert_catastrophic_thinkings?: Maybe<Catastrophic_Thinkings_Mutation_Response>;
  insert_catastrophic_thinkings_one?: Maybe<Catastrophic_Thinkings>;
  insert_community_user?: Maybe<Community_User_Mutation_Response>;
  insert_community_user_one?: Maybe<Community_User>;
  insert_company?: Maybe<Company_Mutation_Response>;
  insert_company_health_carer?: Maybe<Company_Health_Carer_Mutation_Response>;
  insert_company_health_carer_kind_enum?: Maybe<Company_Health_Carer_Kind_Enum_Mutation_Response>;
  insert_company_health_carer_kind_enum_one?: Maybe<Company_Health_Carer_Kind_Enum>;
  insert_company_health_carer_one?: Maybe<Company_Health_Carer>;
  insert_company_one?: Maybe<Company>;
  insert_company_user?: Maybe<Company_User_Mutation_Response>;
  insert_company_user_one?: Maybe<Company_User>;
  insert_coupon?: Maybe<Coupon_Mutation_Response>;
  insert_coupon_one?: Maybe<Coupon>;
  insert_crm?: Maybe<Crm_Mutation_Response>;
  insert_crm_one?: Maybe<Crm>;
  insert_dream?: Maybe<Dream_Mutation_Response>;
  insert_dream_one?: Maybe<Dream>;
  insert_eficiency?: Maybe<Eficiency_Mutation_Response>;
  insert_eficiency_one?: Maybe<Eficiency>;
  insert_epworth?: Maybe<Epworth_Mutation_Response>;
  insert_epworth_one?: Maybe<Epworth>;
  insert_gad?: Maybe<Gad_Mutation_Response>;
  insert_gad7?: Maybe<Gad7_Mutation_Response>;
  insert_gad7_one?: Maybe<Gad7>;
  insert_gad_one?: Maybe<Gad>;
  insert_generic_questionnaire?: Maybe<Generic_Questionnaire_Mutation_Response>;
  insert_generic_questionnaire_name_enum?: Maybe<Generic_Questionnaire_Name_Enum_Mutation_Response>;
  insert_generic_questionnaire_name_enum_one?: Maybe<Generic_Questionnaire_Name_Enum>;
  insert_generic_questionnaire_one?: Maybe<Generic_Questionnaire>;
  insert_google_sheet_row?: Maybe<Google_Sheet_Row_Mutation_Response>;
  insert_google_sheet_row_one?: Maybe<Google_Sheet_Row>;
  insert_gratitude?: Maybe<Gratitude_Mutation_Response>;
  insert_gratitude_one?: Maybe<Gratitude>;
  insert_health_carer?: Maybe<Health_Carer_Mutation_Response>;
  insert_health_carer_membership_kind_enum?: Maybe<Health_Carer_Membership_Kind_Enum_Mutation_Response>;
  insert_health_carer_membership_kind_enum_one?: Maybe<Health_Carer_Membership_Kind_Enum>;
  insert_health_carer_money_request?: Maybe<Health_Carer_Money_Request_Mutation_Response>;
  insert_health_carer_money_request_log?: Maybe<Health_Carer_Money_Request_Log_Mutation_Response>;
  insert_health_carer_money_request_log_one?: Maybe<Health_Carer_Money_Request_Log>;
  insert_health_carer_money_request_one?: Maybe<Health_Carer_Money_Request>;
  insert_health_carer_money_request_status_enum?: Maybe<Health_Carer_Money_Request_Status_Enum_Mutation_Response>;
  insert_health_carer_money_request_status_enum_one?: Maybe<Health_Carer_Money_Request_Status_Enum>;
  insert_health_carer_one?: Maybe<Health_Carer>;
  insert_health_carer_pagarme_recipient?: Maybe<Health_Carer_Pagarme_Recipient_Mutation_Response>;
  insert_health_carer_pagarme_recipient_one?: Maybe<Health_Carer_Pagarme_Recipient>;
  insert_health_carer_telehealth?: Maybe<Health_Carer_Telehealth_Mutation_Response>;
  insert_health_carer_telehealth_one?: Maybe<Health_Carer_Telehealth>;
  insert_health_carer_transactions?: Maybe<Health_Carer_Transactions_Mutation_Response>;
  insert_health_carer_transactions_one?: Maybe<Health_Carer_Transactions>;
  insert_health_carer_user?: Maybe<Health_Carer_User_Mutation_Response>;
  insert_health_carer_user_one?: Maybe<Health_Carer_User>;
  insert_isi?: Maybe<Isi_Mutation_Response>;
  insert_isi_one?: Maybe<Isi>;
  insert_journey?: Maybe<Journey_Mutation_Response>;
  insert_journey_one?: Maybe<Journey>;
  insert_journey_program_sessions?: Maybe<Journey_Program_Sessions_Mutation_Response>;
  insert_journey_program_sessions_one?: Maybe<Journey_Program_Sessions>;
  insert_kupperman?: Maybe<Kupperman_Mutation_Response>;
  insert_kupperman_one?: Maybe<Kupperman>;
  insert_landing_page_redirect?: Maybe<Landing_Page_Redirect_Mutation_Response>;
  insert_landing_page_redirect_one?: Maybe<Landing_Page_Redirect>;
  insert_log?: Maybe<Log_Mutation_Response>;
  insert_log_one?: Maybe<Log>;
  insert_log_type_enum?: Maybe<Log_Type_Enum_Mutation_Response>;
  insert_log_type_enum_one?: Maybe<Log_Type_Enum>;
  insert_menopause_greene_scale?: Maybe<Menopause_Greene_Scale_Mutation_Response>;
  insert_menopause_greene_scale_one?: Maybe<Menopause_Greene_Scale>;
  insert_munich_chronotype?: Maybe<Munich_Chronotype_Mutation_Response>;
  insert_munich_chronotype_core?: Maybe<Munich_Chronotype_Core_Mutation_Response>;
  insert_munich_chronotype_core_one?: Maybe<Munich_Chronotype_Core>;
  insert_munich_chronotype_one?: Maybe<Munich_Chronotype>;
  insert_os_enum?: Maybe<Os_Enum_Mutation_Response>;
  insert_os_enum_one?: Maybe<Os_Enum>;
  insert_parking_lot?: Maybe<Parking_Lot_Mutation_Response>;
  insert_parking_lot_one?: Maybe<Parking_Lot>;
  insert_personality_questionnaire?: Maybe<Personality_Questionnaire_Mutation_Response>;
  insert_personality_questionnaire_one?: Maybe<Personality_Questionnaire>;
  insert_phq?: Maybe<Phq_Mutation_Response>;
  insert_phq9?: Maybe<Phq9_Mutation_Response>;
  insert_phq9_one?: Maybe<Phq9>;
  insert_phq_one?: Maybe<Phq>;
  insert_pre_user?: Maybe<Pre_User_Mutation_Response>;
  insert_pre_user_one?: Maybe<Pre_User>;
  insert_pre_user_source_enum?: Maybe<Pre_User_Source_Enum_Mutation_Response>;
  insert_pre_user_source_enum_one?: Maybe<Pre_User_Source_Enum>;
  insert_productivity?: Maybe<Productivity_Mutation_Response>;
  insert_productivity_one?: Maybe<Productivity>;
  insert_program_session?: Maybe<Program_Session_Mutation_Response>;
  insert_program_session_one?: Maybe<Program_Session>;
  insert_psqi?: Maybe<Psqi_Mutation_Response>;
  insert_psqi_one?: Maybe<Psqi>;
  insert_rating?: Maybe<Rating_Mutation_Response>;
  insert_rating_one?: Maybe<Rating>;
  insert_recurrence_model_enum?: Maybe<Recurrence_Model_Enum_Mutation_Response>;
  insert_recurrence_model_enum_one?: Maybe<Recurrence_Model_Enum>;
  insert_reminder?: Maybe<Reminder_Mutation_Response>;
  insert_reminder_one?: Maybe<Reminder>;
  insert_reminder_type_enum?: Maybe<Reminder_Type_Enum_Mutation_Response>;
  insert_reminder_type_enum_one?: Maybe<Reminder_Type_Enum>;
  insert_sleep_diary?: Maybe<Sleep_Diary_Mutation_Response>;
  insert_sleep_diary_apple_health?: Maybe<Sleep_Diary_Apple_Health_Mutation_Response>;
  insert_sleep_diary_apple_health_one?: Maybe<Sleep_Diary_Apple_Health>;
  insert_sleep_diary_google_fit?: Maybe<Sleep_Diary_Google_Fit_Mutation_Response>;
  insert_sleep_diary_google_fit_one?: Maybe<Sleep_Diary_Google_Fit>;
  insert_sleep_diary_interval?: Maybe<Sleep_Diary_Interval_Mutation_Response>;
  insert_sleep_diary_interval_one?: Maybe<Sleep_Diary_Interval>;
  insert_sleep_diary_medicine?: Maybe<Sleep_Diary_Medicine_Mutation_Response>;
  insert_sleep_diary_medicine_one?: Maybe<Sleep_Diary_Medicine>;
  insert_sleep_diary_one?: Maybe<Sleep_Diary>;
  insert_sleep_diary_sleep_tag?: Maybe<Sleep_Diary_Sleep_Tag_Mutation_Response>;
  insert_sleep_diary_sleep_tag_one?: Maybe<Sleep_Diary_Sleep_Tag>;
  insert_sleep_restriction?: Maybe<Sleep_Restriction_Mutation_Response>;
  insert_sleep_restriction_one?: Maybe<Sleep_Restriction>;
  insert_sleep_tag_enum?: Maybe<Sleep_Tag_Enum_Mutation_Response>;
  insert_sleep_tag_enum_one?: Maybe<Sleep_Tag_Enum>;
  insert_sleep_tracker_compressed_data?: Maybe<Sleep_Tracker_Compressed_Data_Mutation_Response>;
  insert_sleep_tracker_compressed_data_one?: Maybe<Sleep_Tracker_Compressed_Data>;
  insert_stop_bang?: Maybe<Stop_Bang_Mutation_Response>;
  insert_stop_bang_one?: Maybe<Stop_Bang>;
  insert_subscription?: Maybe<Subscription_Mutation_Response>;
  insert_subscription_one?: Maybe<Subscription>;
  insert_technique_resource?: Maybe<Technique_Resource_Mutation_Response>;
  insert_technique_resource_one?: Maybe<Technique_Resource>;
  insert_toolbox?: Maybe<Toolbox_Mutation_Response>;
  insert_toolbox_one?: Maybe<Toolbox>;
  insert_user?: Maybe<User_Mutation_Response>;
  insert_user_checkout?: Maybe<User_Checkout_Mutation_Response>;
  insert_user_checkout_one?: Maybe<User_Checkout>;
  insert_user_coupon?: Maybe<User_Coupon_Mutation_Response>;
  insert_user_coupon_one?: Maybe<User_Coupon>;
  insert_user_journey?: Maybe<User_Journey_Mutation_Response>;
  insert_user_journey_one?: Maybe<User_Journey>;
  insert_user_key_value?: Maybe<User_Key_Value_Mutation_Response>;
  insert_user_key_value_key_enum?: Maybe<User_Key_Value_Key_Enum_Mutation_Response>;
  insert_user_key_value_key_enum_one?: Maybe<User_Key_Value_Key_Enum>;
  insert_user_key_value_one?: Maybe<User_Key_Value>;
  insert_user_notification?: Maybe<User_Notification_Mutation_Response>;
  insert_user_notification_one?: Maybe<User_Notification>;
  insert_user_one?: Maybe<User>;
  insert_user_program_session?: Maybe<User_Program_Session_Mutation_Response>;
  insert_user_program_session_enum?: Maybe<User_Program_Session_Enum_Mutation_Response>;
  insert_user_program_session_enum_one?: Maybe<User_Program_Session_Enum>;
  insert_user_program_session_one?: Maybe<User_Program_Session>;
  insert_user_reminder_info?: Maybe<User_Reminder_Info_Mutation_Response>;
  insert_user_reminder_info_one?: Maybe<User_Reminder_Info>;
  insert_user_tag?: Maybe<User_Tag_Mutation_Response>;
  insert_user_tag_one?: Maybe<User_Tag>;
  insert_user_telehealth?: Maybe<User_Telehealth_Mutation_Response>;
  insert_user_telehealth_one?: Maybe<User_Telehealth>;
  insert_yes_no_question_enum?: Maybe<Yes_No_Question_Enum_Mutation_Response>;
  insert_yes_no_question_enum_one?: Maybe<Yes_No_Question_Enum>;
  update_apnea_questionnaire?: Maybe<Apnea_Questionnaire_Mutation_Response>;
  update_apnea_questionnaire_by_pk?: Maybe<Apnea_Questionnaire>;
  update_apnea_scan_photo?: Maybe<Apnea_Scan_Photo_Mutation_Response>;
  update_apnea_scan_photo_by_pk?: Maybe<Apnea_Scan_Photo>;
  update_app_customization?: Maybe<App_Customization_Mutation_Response>;
  update_app_customization_by_pk?: Maybe<App_Customization>;
  update_app_customization_type_enum?: Maybe<App_Customization_Type_Enum_Mutation_Response>;
  update_app_customization_type_enum_by_pk?: Maybe<App_Customization_Type_Enum>;
  update_appointment?: Maybe<Appointment_Mutation_Response>;
  update_appointment_by_pk?: Maybe<Appointment>;
  update_appointment_status_enum?: Maybe<Appointment_Status_Enum_Mutation_Response>;
  update_appointment_status_enum_by_pk?: Maybe<Appointment_Status_Enum>;
  update_assesment?: Maybe<Assesment_Mutation_Response>;
  update_assesment_by_pk?: Maybe<Assesment>;
  update_b2b_diagnosis_v1?: Maybe<B2b_Diagnosis_V1_Mutation_Response>;
  update_b2b_diagnosis_v1_by_pk?: Maybe<B2b_Diagnosis_V1>;
  update_catastrophic_thinkings?: Maybe<Catastrophic_Thinkings_Mutation_Response>;
  update_catastrophic_thinkings_by_pk?: Maybe<Catastrophic_Thinkings>;
  update_community_user?: Maybe<Community_User_Mutation_Response>;
  update_community_user_by_pk?: Maybe<Community_User>;
  update_company?: Maybe<Company_Mutation_Response>;
  update_company_by_pk?: Maybe<Company>;
  update_company_health_carer?: Maybe<Company_Health_Carer_Mutation_Response>;
  update_company_health_carer_by_pk?: Maybe<Company_Health_Carer>;
  update_company_health_carer_kind_enum?: Maybe<Company_Health_Carer_Kind_Enum_Mutation_Response>;
  update_company_health_carer_kind_enum_by_pk?: Maybe<Company_Health_Carer_Kind_Enum>;
  update_company_user?: Maybe<Company_User_Mutation_Response>;
  update_company_user_by_pk?: Maybe<Company_User>;
  update_coupon?: Maybe<Coupon_Mutation_Response>;
  update_coupon_by_pk?: Maybe<Coupon>;
  update_crm?: Maybe<Crm_Mutation_Response>;
  update_crm_by_pk?: Maybe<Crm>;
  update_dream?: Maybe<Dream_Mutation_Response>;
  update_dream_by_pk?: Maybe<Dream>;
  update_eficiency?: Maybe<Eficiency_Mutation_Response>;
  update_eficiency_by_pk?: Maybe<Eficiency>;
  update_epworth?: Maybe<Epworth_Mutation_Response>;
  update_epworth_by_pk?: Maybe<Epworth>;
  update_gad?: Maybe<Gad_Mutation_Response>;
  update_gad7?: Maybe<Gad7_Mutation_Response>;
  update_gad7_by_pk?: Maybe<Gad7>;
  update_gad_by_pk?: Maybe<Gad>;
  update_generic_questionnaire?: Maybe<Generic_Questionnaire_Mutation_Response>;
  update_generic_questionnaire_by_pk?: Maybe<Generic_Questionnaire>;
  update_generic_questionnaire_name_enum?: Maybe<Generic_Questionnaire_Name_Enum_Mutation_Response>;
  update_generic_questionnaire_name_enum_by_pk?: Maybe<Generic_Questionnaire_Name_Enum>;
  update_google_sheet_row?: Maybe<Google_Sheet_Row_Mutation_Response>;
  update_google_sheet_row_by_pk?: Maybe<Google_Sheet_Row>;
  update_gratitude?: Maybe<Gratitude_Mutation_Response>;
  update_gratitude_by_pk?: Maybe<Gratitude>;
  update_health_carer?: Maybe<Health_Carer_Mutation_Response>;
  update_health_carer_by_pk?: Maybe<Health_Carer>;
  update_health_carer_membership_kind_enum?: Maybe<Health_Carer_Membership_Kind_Enum_Mutation_Response>;
  update_health_carer_membership_kind_enum_by_pk?: Maybe<Health_Carer_Membership_Kind_Enum>;
  update_health_carer_money_request?: Maybe<Health_Carer_Money_Request_Mutation_Response>;
  update_health_carer_money_request_by_pk?: Maybe<Health_Carer_Money_Request>;
  update_health_carer_money_request_log?: Maybe<Health_Carer_Money_Request_Log_Mutation_Response>;
  update_health_carer_money_request_log_by_pk?: Maybe<Health_Carer_Money_Request_Log>;
  update_health_carer_money_request_status_enum?: Maybe<Health_Carer_Money_Request_Status_Enum_Mutation_Response>;
  update_health_carer_money_request_status_enum_by_pk?: Maybe<Health_Carer_Money_Request_Status_Enum>;
  update_health_carer_pagarme_recipient?: Maybe<Health_Carer_Pagarme_Recipient_Mutation_Response>;
  update_health_carer_pagarme_recipient_by_pk?: Maybe<Health_Carer_Pagarme_Recipient>;
  update_health_carer_telehealth?: Maybe<Health_Carer_Telehealth_Mutation_Response>;
  update_health_carer_telehealth_by_pk?: Maybe<Health_Carer_Telehealth>;
  update_health_carer_transactions?: Maybe<Health_Carer_Transactions_Mutation_Response>;
  update_health_carer_transactions_by_pk?: Maybe<Health_Carer_Transactions>;
  update_health_carer_user?: Maybe<Health_Carer_User_Mutation_Response>;
  update_health_carer_user_by_pk?: Maybe<Health_Carer_User>;
  update_isi?: Maybe<Isi_Mutation_Response>;
  update_isi_by_pk?: Maybe<Isi>;
  update_journey?: Maybe<Journey_Mutation_Response>;
  update_journey_by_pk?: Maybe<Journey>;
  update_journey_program_sessions?: Maybe<Journey_Program_Sessions_Mutation_Response>;
  update_journey_program_sessions_by_pk?: Maybe<Journey_Program_Sessions>;
  update_kupperman?: Maybe<Kupperman_Mutation_Response>;
  update_kupperman_by_pk?: Maybe<Kupperman>;
  update_landing_page_redirect?: Maybe<Landing_Page_Redirect_Mutation_Response>;
  update_landing_page_redirect_by_pk?: Maybe<Landing_Page_Redirect>;
  update_log?: Maybe<Log_Mutation_Response>;
  update_log_by_pk?: Maybe<Log>;
  update_log_type_enum?: Maybe<Log_Type_Enum_Mutation_Response>;
  update_log_type_enum_by_pk?: Maybe<Log_Type_Enum>;
  update_menopause_greene_scale?: Maybe<Menopause_Greene_Scale_Mutation_Response>;
  update_menopause_greene_scale_by_pk?: Maybe<Menopause_Greene_Scale>;
  update_munich_chronotype?: Maybe<Munich_Chronotype_Mutation_Response>;
  update_munich_chronotype_by_pk?: Maybe<Munich_Chronotype>;
  update_munich_chronotype_core?: Maybe<Munich_Chronotype_Core_Mutation_Response>;
  update_munich_chronotype_core_by_pk?: Maybe<Munich_Chronotype_Core>;
  update_os_enum?: Maybe<Os_Enum_Mutation_Response>;
  update_os_enum_by_pk?: Maybe<Os_Enum>;
  update_parking_lot?: Maybe<Parking_Lot_Mutation_Response>;
  update_parking_lot_by_pk?: Maybe<Parking_Lot>;
  update_personality_questionnaire?: Maybe<Personality_Questionnaire_Mutation_Response>;
  update_personality_questionnaire_by_pk?: Maybe<Personality_Questionnaire>;
  update_phq?: Maybe<Phq_Mutation_Response>;
  update_phq9?: Maybe<Phq9_Mutation_Response>;
  update_phq9_by_pk?: Maybe<Phq9>;
  update_phq_by_pk?: Maybe<Phq>;
  update_pre_user?: Maybe<Pre_User_Mutation_Response>;
  update_pre_user_by_pk?: Maybe<Pre_User>;
  update_pre_user_source_enum?: Maybe<Pre_User_Source_Enum_Mutation_Response>;
  update_pre_user_source_enum_by_pk?: Maybe<Pre_User_Source_Enum>;
  update_productivity?: Maybe<Productivity_Mutation_Response>;
  update_productivity_by_pk?: Maybe<Productivity>;
  update_program_session?: Maybe<Program_Session_Mutation_Response>;
  update_program_session_by_pk?: Maybe<Program_Session>;
  update_psqi?: Maybe<Psqi_Mutation_Response>;
  update_psqi_by_pk?: Maybe<Psqi>;
  update_rating?: Maybe<Rating_Mutation_Response>;
  update_rating_by_pk?: Maybe<Rating>;
  update_recurrence_model_enum?: Maybe<Recurrence_Model_Enum_Mutation_Response>;
  update_recurrence_model_enum_by_pk?: Maybe<Recurrence_Model_Enum>;
  update_reminder?: Maybe<Reminder_Mutation_Response>;
  update_reminder_by_pk?: Maybe<Reminder>;
  update_reminder_type_enum?: Maybe<Reminder_Type_Enum_Mutation_Response>;
  update_reminder_type_enum_by_pk?: Maybe<Reminder_Type_Enum>;
  update_sleep_diary?: Maybe<Sleep_Diary_Mutation_Response>;
  update_sleep_diary_apple_health?: Maybe<Sleep_Diary_Apple_Health_Mutation_Response>;
  update_sleep_diary_apple_health_by_pk?: Maybe<Sleep_Diary_Apple_Health>;
  update_sleep_diary_by_pk?: Maybe<Sleep_Diary>;
  update_sleep_diary_google_fit?: Maybe<Sleep_Diary_Google_Fit_Mutation_Response>;
  update_sleep_diary_google_fit_by_pk?: Maybe<Sleep_Diary_Google_Fit>;
  update_sleep_diary_interval?: Maybe<Sleep_Diary_Interval_Mutation_Response>;
  update_sleep_diary_interval_by_pk?: Maybe<Sleep_Diary_Interval>;
  update_sleep_diary_medicine?: Maybe<Sleep_Diary_Medicine_Mutation_Response>;
  update_sleep_diary_medicine_by_pk?: Maybe<Sleep_Diary_Medicine>;
  update_sleep_diary_sleep_tag?: Maybe<Sleep_Diary_Sleep_Tag_Mutation_Response>;
  update_sleep_diary_sleep_tag_by_pk?: Maybe<Sleep_Diary_Sleep_Tag>;
  update_sleep_restriction?: Maybe<Sleep_Restriction_Mutation_Response>;
  update_sleep_restriction_by_pk?: Maybe<Sleep_Restriction>;
  update_sleep_tag_enum?: Maybe<Sleep_Tag_Enum_Mutation_Response>;
  update_sleep_tag_enum_by_pk?: Maybe<Sleep_Tag_Enum>;
  update_sleep_tracker_compressed_data?: Maybe<Sleep_Tracker_Compressed_Data_Mutation_Response>;
  update_sleep_tracker_compressed_data_by_pk?: Maybe<Sleep_Tracker_Compressed_Data>;
  update_stop_bang?: Maybe<Stop_Bang_Mutation_Response>;
  update_stop_bang_by_pk?: Maybe<Stop_Bang>;
  update_subscription?: Maybe<Subscription_Mutation_Response>;
  update_subscription_by_pk?: Maybe<Subscription>;
  update_technique_resource?: Maybe<Technique_Resource_Mutation_Response>;
  update_technique_resource_by_pk?: Maybe<Technique_Resource>;
  update_toolbox?: Maybe<Toolbox_Mutation_Response>;
  update_toolbox_by_pk?: Maybe<Toolbox>;
  update_user?: Maybe<User_Mutation_Response>;
  update_user_by_pk?: Maybe<User>;
  update_user_checkout?: Maybe<User_Checkout_Mutation_Response>;
  update_user_checkout_by_pk?: Maybe<User_Checkout>;
  update_user_coupon?: Maybe<User_Coupon_Mutation_Response>;
  update_user_coupon_by_pk?: Maybe<User_Coupon>;
  update_user_journey?: Maybe<User_Journey_Mutation_Response>;
  update_user_journey_by_pk?: Maybe<User_Journey>;
  update_user_key_value?: Maybe<User_Key_Value_Mutation_Response>;
  update_user_key_value_by_pk?: Maybe<User_Key_Value>;
  update_user_key_value_key_enum?: Maybe<User_Key_Value_Key_Enum_Mutation_Response>;
  update_user_key_value_key_enum_by_pk?: Maybe<User_Key_Value_Key_Enum>;
  update_user_notification?: Maybe<User_Notification_Mutation_Response>;
  update_user_notification_by_pk?: Maybe<User_Notification>;
  update_user_program_session?: Maybe<User_Program_Session_Mutation_Response>;
  update_user_program_session_by_pk?: Maybe<User_Program_Session>;
  update_user_program_session_enum?: Maybe<User_Program_Session_Enum_Mutation_Response>;
  update_user_program_session_enum_by_pk?: Maybe<User_Program_Session_Enum>;
  update_user_reminder_info?: Maybe<User_Reminder_Info_Mutation_Response>;
  update_user_reminder_info_by_pk?: Maybe<User_Reminder_Info>;
  update_user_tag?: Maybe<User_Tag_Mutation_Response>;
  update_user_tag_by_pk?: Maybe<User_Tag>;
  update_user_telehealth?: Maybe<User_Telehealth_Mutation_Response>;
  update_user_telehealth_by_pk?: Maybe<User_Telehealth>;
  update_yes_no_question_enum?: Maybe<Yes_No_Question_Enum_Mutation_Response>;
  update_yes_no_question_enum_by_pk?: Maybe<Yes_No_Question_Enum>;
};

export type Mutation_RootDelete_Apnea_QuestionnaireArgs = {
  where: Apnea_Questionnaire_Bool_Exp;
};

export type Mutation_RootDelete_Apnea_Questionnaire_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_Apnea_Scan_PhotoArgs = {
  where: Apnea_Scan_Photo_Bool_Exp;
};

export type Mutation_RootDelete_Apnea_Scan_Photo_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_App_CustomizationArgs = {
  where: App_Customization_Bool_Exp;
};

export type Mutation_RootDelete_App_Customization_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_App_Customization_Type_EnumArgs = {
  where: App_Customization_Type_Enum_Bool_Exp;
};

export type Mutation_RootDelete_App_Customization_Type_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Mutation_RootDelete_AppointmentArgs = {
  where: Appointment_Bool_Exp;
};

export type Mutation_RootDelete_Appointment_By_PkArgs = {
  id: Scalars['String'];
};

export type Mutation_RootDelete_Appointment_Status_EnumArgs = {
  where: Appointment_Status_Enum_Bool_Exp;
};

export type Mutation_RootDelete_Appointment_Status_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Mutation_RootDelete_AssesmentArgs = {
  where: Assesment_Bool_Exp;
};

export type Mutation_RootDelete_Assesment_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_B2b_Diagnosis_V1Args = {
  where: B2b_Diagnosis_V1_Bool_Exp;
};

export type Mutation_RootDelete_B2b_Diagnosis_V1_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_Catastrophic_ThinkingsArgs = {
  where: Catastrophic_Thinkings_Bool_Exp;
};

export type Mutation_RootDelete_Catastrophic_Thinkings_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_Community_UserArgs = {
  where: Community_User_Bool_Exp;
};

export type Mutation_RootDelete_Community_User_By_PkArgs = {
  community_id: Scalars['Int'];
};

export type Mutation_RootDelete_CompanyArgs = {
  where: Company_Bool_Exp;
};

export type Mutation_RootDelete_Company_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_Company_Health_CarerArgs = {
  where: Company_Health_Carer_Bool_Exp;
};

export type Mutation_RootDelete_Company_Health_Carer_By_PkArgs = {
  company_id: Scalars['Int'];
  health_carer_id: Scalars['String'];
};

export type Mutation_RootDelete_Company_Health_Carer_Kind_EnumArgs = {
  where: Company_Health_Carer_Kind_Enum_Bool_Exp;
};

export type Mutation_RootDelete_Company_Health_Carer_Kind_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Mutation_RootDelete_Company_UserArgs = {
  where: Company_User_Bool_Exp;
};

export type Mutation_RootDelete_Company_User_By_PkArgs = {
  company_id: Scalars['Int'];
  user_id: Scalars['String'];
};

export type Mutation_RootDelete_CouponArgs = {
  where: Coupon_Bool_Exp;
};

export type Mutation_RootDelete_Coupon_By_PkArgs = {
  code: Scalars['String'];
};

export type Mutation_RootDelete_CrmArgs = {
  where: Crm_Bool_Exp;
};

export type Mutation_RootDelete_Crm_By_PkArgs = {
  identification: Scalars['String'];
  state: Scalars['String'];
};

export type Mutation_RootDelete_DreamArgs = {
  where: Dream_Bool_Exp;
};

export type Mutation_RootDelete_Dream_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_EficiencyArgs = {
  where: Eficiency_Bool_Exp;
};

export type Mutation_RootDelete_Eficiency_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_EpworthArgs = {
  where: Epworth_Bool_Exp;
};

export type Mutation_RootDelete_Epworth_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_GadArgs = {
  where: Gad_Bool_Exp;
};

export type Mutation_RootDelete_Gad7Args = {
  where: Gad7_Bool_Exp;
};

export type Mutation_RootDelete_Gad7_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_Gad_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_Generic_QuestionnaireArgs = {
  where: Generic_Questionnaire_Bool_Exp;
};

export type Mutation_RootDelete_Generic_Questionnaire_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_Generic_Questionnaire_Name_EnumArgs = {
  where: Generic_Questionnaire_Name_Enum_Bool_Exp;
};

export type Mutation_RootDelete_Generic_Questionnaire_Name_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Mutation_RootDelete_Google_Sheet_RowArgs = {
  where: Google_Sheet_Row_Bool_Exp;
};

export type Mutation_RootDelete_Google_Sheet_Row_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_GratitudeArgs = {
  where: Gratitude_Bool_Exp;
};

export type Mutation_RootDelete_Gratitude_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_Health_CarerArgs = {
  where: Health_Carer_Bool_Exp;
};

export type Mutation_RootDelete_Health_Carer_By_PkArgs = {
  id: Scalars['String'];
};

export type Mutation_RootDelete_Health_Carer_Membership_Kind_EnumArgs = {
  where: Health_Carer_Membership_Kind_Enum_Bool_Exp;
};

export type Mutation_RootDelete_Health_Carer_Membership_Kind_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Mutation_RootDelete_Health_Carer_Money_RequestArgs = {
  where: Health_Carer_Money_Request_Bool_Exp;
};

export type Mutation_RootDelete_Health_Carer_Money_Request_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Mutation_RootDelete_Health_Carer_Money_Request_LogArgs = {
  where: Health_Carer_Money_Request_Log_Bool_Exp;
};

export type Mutation_RootDelete_Health_Carer_Money_Request_Log_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Mutation_RootDelete_Health_Carer_Money_Request_Status_EnumArgs = {
  where: Health_Carer_Money_Request_Status_Enum_Bool_Exp;
};

export type Mutation_RootDelete_Health_Carer_Money_Request_Status_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Mutation_RootDelete_Health_Carer_Pagarme_RecipientArgs = {
  where: Health_Carer_Pagarme_Recipient_Bool_Exp;
};

export type Mutation_RootDelete_Health_Carer_Pagarme_Recipient_By_PkArgs = {
  health_carer_id: Scalars['String'];
};

export type Mutation_RootDelete_Health_Carer_TelehealthArgs = {
  where: Health_Carer_Telehealth_Bool_Exp;
};

export type Mutation_RootDelete_Health_Carer_Telehealth_By_PkArgs = {
  health_carer_id: Scalars['String'];
};

export type Mutation_RootDelete_Health_Carer_TransactionsArgs = {
  where: Health_Carer_Transactions_Bool_Exp;
};

export type Mutation_RootDelete_Health_Carer_Transactions_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Mutation_RootDelete_Health_Carer_UserArgs = {
  where: Health_Carer_User_Bool_Exp;
};

export type Mutation_RootDelete_Health_Carer_User_By_PkArgs = {
  health_carer_id: Scalars['String'];
  user_id: Scalars['String'];
};

export type Mutation_RootDelete_IsiArgs = {
  where: Isi_Bool_Exp;
};

export type Mutation_RootDelete_Isi_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_JourneyArgs = {
  where: Journey_Bool_Exp;
};

export type Mutation_RootDelete_Journey_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_Journey_Program_SessionsArgs = {
  where: Journey_Program_Sessions_Bool_Exp;
};

export type Mutation_RootDelete_Journey_Program_Sessions_By_PkArgs = {
  journey_id: Scalars['Int'];
  session_id: Scalars['Int'];
};

export type Mutation_RootDelete_KuppermanArgs = {
  where: Kupperman_Bool_Exp;
};

export type Mutation_RootDelete_Kupperman_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_Landing_Page_RedirectArgs = {
  where: Landing_Page_Redirect_Bool_Exp;
};

export type Mutation_RootDelete_Landing_Page_Redirect_By_PkArgs = {
  from_path: Scalars['String'];
};

export type Mutation_RootDelete_LogArgs = {
  where: Log_Bool_Exp;
};

export type Mutation_RootDelete_Log_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_Log_Type_EnumArgs = {
  where: Log_Type_Enum_Bool_Exp;
};

export type Mutation_RootDelete_Log_Type_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Mutation_RootDelete_Menopause_Greene_ScaleArgs = {
  where: Menopause_Greene_Scale_Bool_Exp;
};

export type Mutation_RootDelete_Menopause_Greene_Scale_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_Munich_ChronotypeArgs = {
  where: Munich_Chronotype_Bool_Exp;
};

export type Mutation_RootDelete_Munich_Chronotype_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_Munich_Chronotype_CoreArgs = {
  where: Munich_Chronotype_Core_Bool_Exp;
};

export type Mutation_RootDelete_Munich_Chronotype_Core_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_Os_EnumArgs = {
  where: Os_Enum_Bool_Exp;
};

export type Mutation_RootDelete_Os_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Mutation_RootDelete_Parking_LotArgs = {
  where: Parking_Lot_Bool_Exp;
};

export type Mutation_RootDelete_Parking_Lot_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_Personality_QuestionnaireArgs = {
  where: Personality_Questionnaire_Bool_Exp;
};

export type Mutation_RootDelete_Personality_Questionnaire_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_PhqArgs = {
  where: Phq_Bool_Exp;
};

export type Mutation_RootDelete_Phq9Args = {
  where: Phq9_Bool_Exp;
};

export type Mutation_RootDelete_Phq9_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_Phq_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_Pre_UserArgs = {
  where: Pre_User_Bool_Exp;
};

export type Mutation_RootDelete_Pre_User_By_PkArgs = {
  id: Scalars['String'];
};

export type Mutation_RootDelete_Pre_User_Source_EnumArgs = {
  where: Pre_User_Source_Enum_Bool_Exp;
};

export type Mutation_RootDelete_Pre_User_Source_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Mutation_RootDelete_ProductivityArgs = {
  where: Productivity_Bool_Exp;
};

export type Mutation_RootDelete_Productivity_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_Program_SessionArgs = {
  where: Program_Session_Bool_Exp;
};

export type Mutation_RootDelete_Program_Session_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_PsqiArgs = {
  where: Psqi_Bool_Exp;
};

export type Mutation_RootDelete_Psqi_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_RatingArgs = {
  where: Rating_Bool_Exp;
};

export type Mutation_RootDelete_Rating_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_Recurrence_Model_EnumArgs = {
  where: Recurrence_Model_Enum_Bool_Exp;
};

export type Mutation_RootDelete_Recurrence_Model_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Mutation_RootDelete_ReminderArgs = {
  where: Reminder_Bool_Exp;
};

export type Mutation_RootDelete_Reminder_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_Reminder_Type_EnumArgs = {
  where: Reminder_Type_Enum_Bool_Exp;
};

export type Mutation_RootDelete_Reminder_Type_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Mutation_RootDelete_Sleep_DiaryArgs = {
  where: Sleep_Diary_Bool_Exp;
};

export type Mutation_RootDelete_Sleep_Diary_Apple_HealthArgs = {
  where: Sleep_Diary_Apple_Health_Bool_Exp;
};

export type Mutation_RootDelete_Sleep_Diary_Apple_Health_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_Sleep_Diary_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_Sleep_Diary_Google_FitArgs = {
  where: Sleep_Diary_Google_Fit_Bool_Exp;
};

export type Mutation_RootDelete_Sleep_Diary_Google_Fit_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_Sleep_Diary_IntervalArgs = {
  where: Sleep_Diary_Interval_Bool_Exp;
};

export type Mutation_RootDelete_Sleep_Diary_Interval_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Mutation_RootDelete_Sleep_Diary_MedicineArgs = {
  where: Sleep_Diary_Medicine_Bool_Exp;
};

export type Mutation_RootDelete_Sleep_Diary_Medicine_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_Sleep_Diary_Sleep_TagArgs = {
  where: Sleep_Diary_Sleep_Tag_Bool_Exp;
};

export type Mutation_RootDelete_Sleep_Diary_Sleep_Tag_By_PkArgs = {
  sleep_diary: Scalars['Int'];
  sleep_tag: Sleep_Tag_Enum_Enum;
};

export type Mutation_RootDelete_Sleep_RestrictionArgs = {
  where: Sleep_Restriction_Bool_Exp;
};

export type Mutation_RootDelete_Sleep_Restriction_By_PkArgs = {
  start_date: Scalars['date'];
  user_id: Scalars['String'];
};

export type Mutation_RootDelete_Sleep_Tag_EnumArgs = {
  where: Sleep_Tag_Enum_Bool_Exp;
};

export type Mutation_RootDelete_Sleep_Tag_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Mutation_RootDelete_Sleep_Tracker_Compressed_DataArgs = {
  where: Sleep_Tracker_Compressed_Data_Bool_Exp;
};

export type Mutation_RootDelete_Sleep_Tracker_Compressed_Data_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_Stop_BangArgs = {
  where: Stop_Bang_Bool_Exp;
};

export type Mutation_RootDelete_Stop_Bang_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_SubscriptionArgs = {
  where: Subscription_Bool_Exp;
};

export type Mutation_RootDelete_Subscription_By_PkArgs = {
  email: Scalars['String'];
};

export type Mutation_RootDelete_Technique_ResourceArgs = {
  where: Technique_Resource_Bool_Exp;
};

export type Mutation_RootDelete_Technique_Resource_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_ToolboxArgs = {
  where: Toolbox_Bool_Exp;
};

export type Mutation_RootDelete_Toolbox_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp;
};

export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['String'];
};

export type Mutation_RootDelete_User_CheckoutArgs = {
  where: User_Checkout_Bool_Exp;
};

export type Mutation_RootDelete_User_Checkout_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_User_CouponArgs = {
  where: User_Coupon_Bool_Exp;
};

export type Mutation_RootDelete_User_Coupon_By_PkArgs = {
  coupon_id: Scalars['bigint'];
  user_id: Scalars['String'];
};

export type Mutation_RootDelete_User_JourneyArgs = {
  where: User_Journey_Bool_Exp;
};

export type Mutation_RootDelete_User_Journey_By_PkArgs = {
  journey_id: Scalars['Int'];
  user_id: Scalars['String'];
};

export type Mutation_RootDelete_User_Key_ValueArgs = {
  where: User_Key_Value_Bool_Exp;
};

export type Mutation_RootDelete_User_Key_Value_By_PkArgs = {
  key: User_Key_Value_Key_Enum_Enum;
  user_id: Scalars['String'];
};

export type Mutation_RootDelete_User_Key_Value_Key_EnumArgs = {
  where: User_Key_Value_Key_Enum_Bool_Exp;
};

export type Mutation_RootDelete_User_Key_Value_Key_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Mutation_RootDelete_User_NotificationArgs = {
  where: User_Notification_Bool_Exp;
};

export type Mutation_RootDelete_User_Notification_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_User_Program_SessionArgs = {
  where: User_Program_Session_Bool_Exp;
};

export type Mutation_RootDelete_User_Program_Session_By_PkArgs = {
  session_id: Scalars['Int'];
  user_id: Scalars['String'];
};

export type Mutation_RootDelete_User_Program_Session_EnumArgs = {
  where: User_Program_Session_Enum_Bool_Exp;
};

export type Mutation_RootDelete_User_Program_Session_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Mutation_RootDelete_User_Reminder_InfoArgs = {
  where: User_Reminder_Info_Bool_Exp;
};

export type Mutation_RootDelete_User_Reminder_Info_By_PkArgs = {
  messenger_id: Scalars['String'];
};

export type Mutation_RootDelete_User_TagArgs = {
  where: User_Tag_Bool_Exp;
};

export type Mutation_RootDelete_User_Tag_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_User_TelehealthArgs = {
  where: User_Telehealth_Bool_Exp;
};

export type Mutation_RootDelete_User_Telehealth_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_Yes_No_Question_EnumArgs = {
  where: Yes_No_Question_Enum_Bool_Exp;
};

export type Mutation_RootDelete_Yes_No_Question_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Mutation_RootInsert_Apnea_QuestionnaireArgs = {
  objects: Array<Apnea_Questionnaire_Insert_Input>;
  on_conflict?: InputMaybe<Apnea_Questionnaire_On_Conflict>;
};

export type Mutation_RootInsert_Apnea_Questionnaire_OneArgs = {
  object: Apnea_Questionnaire_Insert_Input;
  on_conflict?: InputMaybe<Apnea_Questionnaire_On_Conflict>;
};

export type Mutation_RootInsert_Apnea_Scan_PhotoArgs = {
  objects: Array<Apnea_Scan_Photo_Insert_Input>;
  on_conflict?: InputMaybe<Apnea_Scan_Photo_On_Conflict>;
};

export type Mutation_RootInsert_Apnea_Scan_Photo_OneArgs = {
  object: Apnea_Scan_Photo_Insert_Input;
  on_conflict?: InputMaybe<Apnea_Scan_Photo_On_Conflict>;
};

export type Mutation_RootInsert_App_CustomizationArgs = {
  objects: Array<App_Customization_Insert_Input>;
  on_conflict?: InputMaybe<App_Customization_On_Conflict>;
};

export type Mutation_RootInsert_App_Customization_OneArgs = {
  object: App_Customization_Insert_Input;
  on_conflict?: InputMaybe<App_Customization_On_Conflict>;
};

export type Mutation_RootInsert_App_Customization_Type_EnumArgs = {
  objects: Array<App_Customization_Type_Enum_Insert_Input>;
  on_conflict?: InputMaybe<App_Customization_Type_Enum_On_Conflict>;
};

export type Mutation_RootInsert_App_Customization_Type_Enum_OneArgs = {
  object: App_Customization_Type_Enum_Insert_Input;
  on_conflict?: InputMaybe<App_Customization_Type_Enum_On_Conflict>;
};

export type Mutation_RootInsert_AppointmentArgs = {
  objects: Array<Appointment_Insert_Input>;
  on_conflict?: InputMaybe<Appointment_On_Conflict>;
};

export type Mutation_RootInsert_Appointment_OneArgs = {
  object: Appointment_Insert_Input;
  on_conflict?: InputMaybe<Appointment_On_Conflict>;
};

export type Mutation_RootInsert_Appointment_Status_EnumArgs = {
  objects: Array<Appointment_Status_Enum_Insert_Input>;
  on_conflict?: InputMaybe<Appointment_Status_Enum_On_Conflict>;
};

export type Mutation_RootInsert_Appointment_Status_Enum_OneArgs = {
  object: Appointment_Status_Enum_Insert_Input;
  on_conflict?: InputMaybe<Appointment_Status_Enum_On_Conflict>;
};

export type Mutation_RootInsert_AssesmentArgs = {
  objects: Array<Assesment_Insert_Input>;
  on_conflict?: InputMaybe<Assesment_On_Conflict>;
};

export type Mutation_RootInsert_Assesment_OneArgs = {
  object: Assesment_Insert_Input;
  on_conflict?: InputMaybe<Assesment_On_Conflict>;
};

export type Mutation_RootInsert_B2b_Diagnosis_V1Args = {
  objects: Array<B2b_Diagnosis_V1_Insert_Input>;
  on_conflict?: InputMaybe<B2b_Diagnosis_V1_On_Conflict>;
};

export type Mutation_RootInsert_B2b_Diagnosis_V1_OneArgs = {
  object: B2b_Diagnosis_V1_Insert_Input;
  on_conflict?: InputMaybe<B2b_Diagnosis_V1_On_Conflict>;
};

export type Mutation_RootInsert_Catastrophic_ThinkingsArgs = {
  objects: Array<Catastrophic_Thinkings_Insert_Input>;
  on_conflict?: InputMaybe<Catastrophic_Thinkings_On_Conflict>;
};

export type Mutation_RootInsert_Catastrophic_Thinkings_OneArgs = {
  object: Catastrophic_Thinkings_Insert_Input;
  on_conflict?: InputMaybe<Catastrophic_Thinkings_On_Conflict>;
};

export type Mutation_RootInsert_Community_UserArgs = {
  objects: Array<Community_User_Insert_Input>;
  on_conflict?: InputMaybe<Community_User_On_Conflict>;
};

export type Mutation_RootInsert_Community_User_OneArgs = {
  object: Community_User_Insert_Input;
  on_conflict?: InputMaybe<Community_User_On_Conflict>;
};

export type Mutation_RootInsert_CompanyArgs = {
  objects: Array<Company_Insert_Input>;
  on_conflict?: InputMaybe<Company_On_Conflict>;
};

export type Mutation_RootInsert_Company_Health_CarerArgs = {
  objects: Array<Company_Health_Carer_Insert_Input>;
  on_conflict?: InputMaybe<Company_Health_Carer_On_Conflict>;
};

export type Mutation_RootInsert_Company_Health_Carer_Kind_EnumArgs = {
  objects: Array<Company_Health_Carer_Kind_Enum_Insert_Input>;
  on_conflict?: InputMaybe<Company_Health_Carer_Kind_Enum_On_Conflict>;
};

export type Mutation_RootInsert_Company_Health_Carer_Kind_Enum_OneArgs = {
  object: Company_Health_Carer_Kind_Enum_Insert_Input;
  on_conflict?: InputMaybe<Company_Health_Carer_Kind_Enum_On_Conflict>;
};

export type Mutation_RootInsert_Company_Health_Carer_OneArgs = {
  object: Company_Health_Carer_Insert_Input;
  on_conflict?: InputMaybe<Company_Health_Carer_On_Conflict>;
};

export type Mutation_RootInsert_Company_OneArgs = {
  object: Company_Insert_Input;
  on_conflict?: InputMaybe<Company_On_Conflict>;
};

export type Mutation_RootInsert_Company_UserArgs = {
  objects: Array<Company_User_Insert_Input>;
  on_conflict?: InputMaybe<Company_User_On_Conflict>;
};

export type Mutation_RootInsert_Company_User_OneArgs = {
  object: Company_User_Insert_Input;
  on_conflict?: InputMaybe<Company_User_On_Conflict>;
};

export type Mutation_RootInsert_CouponArgs = {
  objects: Array<Coupon_Insert_Input>;
  on_conflict?: InputMaybe<Coupon_On_Conflict>;
};

export type Mutation_RootInsert_Coupon_OneArgs = {
  object: Coupon_Insert_Input;
  on_conflict?: InputMaybe<Coupon_On_Conflict>;
};

export type Mutation_RootInsert_CrmArgs = {
  objects: Array<Crm_Insert_Input>;
  on_conflict?: InputMaybe<Crm_On_Conflict>;
};

export type Mutation_RootInsert_Crm_OneArgs = {
  object: Crm_Insert_Input;
  on_conflict?: InputMaybe<Crm_On_Conflict>;
};

export type Mutation_RootInsert_DreamArgs = {
  objects: Array<Dream_Insert_Input>;
  on_conflict?: InputMaybe<Dream_On_Conflict>;
};

export type Mutation_RootInsert_Dream_OneArgs = {
  object: Dream_Insert_Input;
  on_conflict?: InputMaybe<Dream_On_Conflict>;
};

export type Mutation_RootInsert_EficiencyArgs = {
  objects: Array<Eficiency_Insert_Input>;
  on_conflict?: InputMaybe<Eficiency_On_Conflict>;
};

export type Mutation_RootInsert_Eficiency_OneArgs = {
  object: Eficiency_Insert_Input;
  on_conflict?: InputMaybe<Eficiency_On_Conflict>;
};

export type Mutation_RootInsert_EpworthArgs = {
  objects: Array<Epworth_Insert_Input>;
  on_conflict?: InputMaybe<Epworth_On_Conflict>;
};

export type Mutation_RootInsert_Epworth_OneArgs = {
  object: Epworth_Insert_Input;
  on_conflict?: InputMaybe<Epworth_On_Conflict>;
};

export type Mutation_RootInsert_GadArgs = {
  objects: Array<Gad_Insert_Input>;
  on_conflict?: InputMaybe<Gad_On_Conflict>;
};

export type Mutation_RootInsert_Gad7Args = {
  objects: Array<Gad7_Insert_Input>;
  on_conflict?: InputMaybe<Gad7_On_Conflict>;
};

export type Mutation_RootInsert_Gad7_OneArgs = {
  object: Gad7_Insert_Input;
  on_conflict?: InputMaybe<Gad7_On_Conflict>;
};

export type Mutation_RootInsert_Gad_OneArgs = {
  object: Gad_Insert_Input;
  on_conflict?: InputMaybe<Gad_On_Conflict>;
};

export type Mutation_RootInsert_Generic_QuestionnaireArgs = {
  objects: Array<Generic_Questionnaire_Insert_Input>;
  on_conflict?: InputMaybe<Generic_Questionnaire_On_Conflict>;
};

export type Mutation_RootInsert_Generic_Questionnaire_Name_EnumArgs = {
  objects: Array<Generic_Questionnaire_Name_Enum_Insert_Input>;
  on_conflict?: InputMaybe<Generic_Questionnaire_Name_Enum_On_Conflict>;
};

export type Mutation_RootInsert_Generic_Questionnaire_Name_Enum_OneArgs = {
  object: Generic_Questionnaire_Name_Enum_Insert_Input;
  on_conflict?: InputMaybe<Generic_Questionnaire_Name_Enum_On_Conflict>;
};

export type Mutation_RootInsert_Generic_Questionnaire_OneArgs = {
  object: Generic_Questionnaire_Insert_Input;
  on_conflict?: InputMaybe<Generic_Questionnaire_On_Conflict>;
};

export type Mutation_RootInsert_Google_Sheet_RowArgs = {
  objects: Array<Google_Sheet_Row_Insert_Input>;
  on_conflict?: InputMaybe<Google_Sheet_Row_On_Conflict>;
};

export type Mutation_RootInsert_Google_Sheet_Row_OneArgs = {
  object: Google_Sheet_Row_Insert_Input;
  on_conflict?: InputMaybe<Google_Sheet_Row_On_Conflict>;
};

export type Mutation_RootInsert_GratitudeArgs = {
  objects: Array<Gratitude_Insert_Input>;
  on_conflict?: InputMaybe<Gratitude_On_Conflict>;
};

export type Mutation_RootInsert_Gratitude_OneArgs = {
  object: Gratitude_Insert_Input;
  on_conflict?: InputMaybe<Gratitude_On_Conflict>;
};

export type Mutation_RootInsert_Health_CarerArgs = {
  objects: Array<Health_Carer_Insert_Input>;
  on_conflict?: InputMaybe<Health_Carer_On_Conflict>;
};

export type Mutation_RootInsert_Health_Carer_Membership_Kind_EnumArgs = {
  objects: Array<Health_Carer_Membership_Kind_Enum_Insert_Input>;
  on_conflict?: InputMaybe<Health_Carer_Membership_Kind_Enum_On_Conflict>;
};

export type Mutation_RootInsert_Health_Carer_Membership_Kind_Enum_OneArgs = {
  object: Health_Carer_Membership_Kind_Enum_Insert_Input;
  on_conflict?: InputMaybe<Health_Carer_Membership_Kind_Enum_On_Conflict>;
};

export type Mutation_RootInsert_Health_Carer_Money_RequestArgs = {
  objects: Array<Health_Carer_Money_Request_Insert_Input>;
  on_conflict?: InputMaybe<Health_Carer_Money_Request_On_Conflict>;
};

export type Mutation_RootInsert_Health_Carer_Money_Request_LogArgs = {
  objects: Array<Health_Carer_Money_Request_Log_Insert_Input>;
  on_conflict?: InputMaybe<Health_Carer_Money_Request_Log_On_Conflict>;
};

export type Mutation_RootInsert_Health_Carer_Money_Request_Log_OneArgs = {
  object: Health_Carer_Money_Request_Log_Insert_Input;
  on_conflict?: InputMaybe<Health_Carer_Money_Request_Log_On_Conflict>;
};

export type Mutation_RootInsert_Health_Carer_Money_Request_OneArgs = {
  object: Health_Carer_Money_Request_Insert_Input;
  on_conflict?: InputMaybe<Health_Carer_Money_Request_On_Conflict>;
};

export type Mutation_RootInsert_Health_Carer_Money_Request_Status_EnumArgs = {
  objects: Array<Health_Carer_Money_Request_Status_Enum_Insert_Input>;
  on_conflict?: InputMaybe<Health_Carer_Money_Request_Status_Enum_On_Conflict>;
};

export type Mutation_RootInsert_Health_Carer_Money_Request_Status_Enum_OneArgs = {
  object: Health_Carer_Money_Request_Status_Enum_Insert_Input;
  on_conflict?: InputMaybe<Health_Carer_Money_Request_Status_Enum_On_Conflict>;
};

export type Mutation_RootInsert_Health_Carer_OneArgs = {
  object: Health_Carer_Insert_Input;
  on_conflict?: InputMaybe<Health_Carer_On_Conflict>;
};

export type Mutation_RootInsert_Health_Carer_Pagarme_RecipientArgs = {
  objects: Array<Health_Carer_Pagarme_Recipient_Insert_Input>;
  on_conflict?: InputMaybe<Health_Carer_Pagarme_Recipient_On_Conflict>;
};

export type Mutation_RootInsert_Health_Carer_Pagarme_Recipient_OneArgs = {
  object: Health_Carer_Pagarme_Recipient_Insert_Input;
  on_conflict?: InputMaybe<Health_Carer_Pagarme_Recipient_On_Conflict>;
};

export type Mutation_RootInsert_Health_Carer_TelehealthArgs = {
  objects: Array<Health_Carer_Telehealth_Insert_Input>;
  on_conflict?: InputMaybe<Health_Carer_Telehealth_On_Conflict>;
};

export type Mutation_RootInsert_Health_Carer_Telehealth_OneArgs = {
  object: Health_Carer_Telehealth_Insert_Input;
  on_conflict?: InputMaybe<Health_Carer_Telehealth_On_Conflict>;
};

export type Mutation_RootInsert_Health_Carer_TransactionsArgs = {
  objects: Array<Health_Carer_Transactions_Insert_Input>;
  on_conflict?: InputMaybe<Health_Carer_Transactions_On_Conflict>;
};

export type Mutation_RootInsert_Health_Carer_Transactions_OneArgs = {
  object: Health_Carer_Transactions_Insert_Input;
  on_conflict?: InputMaybe<Health_Carer_Transactions_On_Conflict>;
};

export type Mutation_RootInsert_Health_Carer_UserArgs = {
  objects: Array<Health_Carer_User_Insert_Input>;
  on_conflict?: InputMaybe<Health_Carer_User_On_Conflict>;
};

export type Mutation_RootInsert_Health_Carer_User_OneArgs = {
  object: Health_Carer_User_Insert_Input;
  on_conflict?: InputMaybe<Health_Carer_User_On_Conflict>;
};

export type Mutation_RootInsert_IsiArgs = {
  objects: Array<Isi_Insert_Input>;
  on_conflict?: InputMaybe<Isi_On_Conflict>;
};

export type Mutation_RootInsert_Isi_OneArgs = {
  object: Isi_Insert_Input;
  on_conflict?: InputMaybe<Isi_On_Conflict>;
};

export type Mutation_RootInsert_JourneyArgs = {
  objects: Array<Journey_Insert_Input>;
  on_conflict?: InputMaybe<Journey_On_Conflict>;
};

export type Mutation_RootInsert_Journey_OneArgs = {
  object: Journey_Insert_Input;
  on_conflict?: InputMaybe<Journey_On_Conflict>;
};

export type Mutation_RootInsert_Journey_Program_SessionsArgs = {
  objects: Array<Journey_Program_Sessions_Insert_Input>;
  on_conflict?: InputMaybe<Journey_Program_Sessions_On_Conflict>;
};

export type Mutation_RootInsert_Journey_Program_Sessions_OneArgs = {
  object: Journey_Program_Sessions_Insert_Input;
  on_conflict?: InputMaybe<Journey_Program_Sessions_On_Conflict>;
};

export type Mutation_RootInsert_KuppermanArgs = {
  objects: Array<Kupperman_Insert_Input>;
  on_conflict?: InputMaybe<Kupperman_On_Conflict>;
};

export type Mutation_RootInsert_Kupperman_OneArgs = {
  object: Kupperman_Insert_Input;
  on_conflict?: InputMaybe<Kupperman_On_Conflict>;
};

export type Mutation_RootInsert_Landing_Page_RedirectArgs = {
  objects: Array<Landing_Page_Redirect_Insert_Input>;
  on_conflict?: InputMaybe<Landing_Page_Redirect_On_Conflict>;
};

export type Mutation_RootInsert_Landing_Page_Redirect_OneArgs = {
  object: Landing_Page_Redirect_Insert_Input;
  on_conflict?: InputMaybe<Landing_Page_Redirect_On_Conflict>;
};

export type Mutation_RootInsert_LogArgs = {
  objects: Array<Log_Insert_Input>;
  on_conflict?: InputMaybe<Log_On_Conflict>;
};

export type Mutation_RootInsert_Log_OneArgs = {
  object: Log_Insert_Input;
  on_conflict?: InputMaybe<Log_On_Conflict>;
};

export type Mutation_RootInsert_Log_Type_EnumArgs = {
  objects: Array<Log_Type_Enum_Insert_Input>;
  on_conflict?: InputMaybe<Log_Type_Enum_On_Conflict>;
};

export type Mutation_RootInsert_Log_Type_Enum_OneArgs = {
  object: Log_Type_Enum_Insert_Input;
  on_conflict?: InputMaybe<Log_Type_Enum_On_Conflict>;
};

export type Mutation_RootInsert_Menopause_Greene_ScaleArgs = {
  objects: Array<Menopause_Greene_Scale_Insert_Input>;
  on_conflict?: InputMaybe<Menopause_Greene_Scale_On_Conflict>;
};

export type Mutation_RootInsert_Menopause_Greene_Scale_OneArgs = {
  object: Menopause_Greene_Scale_Insert_Input;
  on_conflict?: InputMaybe<Menopause_Greene_Scale_On_Conflict>;
};

export type Mutation_RootInsert_Munich_ChronotypeArgs = {
  objects: Array<Munich_Chronotype_Insert_Input>;
  on_conflict?: InputMaybe<Munich_Chronotype_On_Conflict>;
};

export type Mutation_RootInsert_Munich_Chronotype_CoreArgs = {
  objects: Array<Munich_Chronotype_Core_Insert_Input>;
  on_conflict?: InputMaybe<Munich_Chronotype_Core_On_Conflict>;
};

export type Mutation_RootInsert_Munich_Chronotype_Core_OneArgs = {
  object: Munich_Chronotype_Core_Insert_Input;
  on_conflict?: InputMaybe<Munich_Chronotype_Core_On_Conflict>;
};

export type Mutation_RootInsert_Munich_Chronotype_OneArgs = {
  object: Munich_Chronotype_Insert_Input;
  on_conflict?: InputMaybe<Munich_Chronotype_On_Conflict>;
};

export type Mutation_RootInsert_Os_EnumArgs = {
  objects: Array<Os_Enum_Insert_Input>;
  on_conflict?: InputMaybe<Os_Enum_On_Conflict>;
};

export type Mutation_RootInsert_Os_Enum_OneArgs = {
  object: Os_Enum_Insert_Input;
  on_conflict?: InputMaybe<Os_Enum_On_Conflict>;
};

export type Mutation_RootInsert_Parking_LotArgs = {
  objects: Array<Parking_Lot_Insert_Input>;
  on_conflict?: InputMaybe<Parking_Lot_On_Conflict>;
};

export type Mutation_RootInsert_Parking_Lot_OneArgs = {
  object: Parking_Lot_Insert_Input;
  on_conflict?: InputMaybe<Parking_Lot_On_Conflict>;
};

export type Mutation_RootInsert_Personality_QuestionnaireArgs = {
  objects: Array<Personality_Questionnaire_Insert_Input>;
  on_conflict?: InputMaybe<Personality_Questionnaire_On_Conflict>;
};

export type Mutation_RootInsert_Personality_Questionnaire_OneArgs = {
  object: Personality_Questionnaire_Insert_Input;
  on_conflict?: InputMaybe<Personality_Questionnaire_On_Conflict>;
};

export type Mutation_RootInsert_PhqArgs = {
  objects: Array<Phq_Insert_Input>;
  on_conflict?: InputMaybe<Phq_On_Conflict>;
};

export type Mutation_RootInsert_Phq9Args = {
  objects: Array<Phq9_Insert_Input>;
  on_conflict?: InputMaybe<Phq9_On_Conflict>;
};

export type Mutation_RootInsert_Phq9_OneArgs = {
  object: Phq9_Insert_Input;
  on_conflict?: InputMaybe<Phq9_On_Conflict>;
};

export type Mutation_RootInsert_Phq_OneArgs = {
  object: Phq_Insert_Input;
  on_conflict?: InputMaybe<Phq_On_Conflict>;
};

export type Mutation_RootInsert_Pre_UserArgs = {
  objects: Array<Pre_User_Insert_Input>;
  on_conflict?: InputMaybe<Pre_User_On_Conflict>;
};

export type Mutation_RootInsert_Pre_User_OneArgs = {
  object: Pre_User_Insert_Input;
  on_conflict?: InputMaybe<Pre_User_On_Conflict>;
};

export type Mutation_RootInsert_Pre_User_Source_EnumArgs = {
  objects: Array<Pre_User_Source_Enum_Insert_Input>;
  on_conflict?: InputMaybe<Pre_User_Source_Enum_On_Conflict>;
};

export type Mutation_RootInsert_Pre_User_Source_Enum_OneArgs = {
  object: Pre_User_Source_Enum_Insert_Input;
  on_conflict?: InputMaybe<Pre_User_Source_Enum_On_Conflict>;
};

export type Mutation_RootInsert_ProductivityArgs = {
  objects: Array<Productivity_Insert_Input>;
  on_conflict?: InputMaybe<Productivity_On_Conflict>;
};

export type Mutation_RootInsert_Productivity_OneArgs = {
  object: Productivity_Insert_Input;
  on_conflict?: InputMaybe<Productivity_On_Conflict>;
};

export type Mutation_RootInsert_Program_SessionArgs = {
  objects: Array<Program_Session_Insert_Input>;
  on_conflict?: InputMaybe<Program_Session_On_Conflict>;
};

export type Mutation_RootInsert_Program_Session_OneArgs = {
  object: Program_Session_Insert_Input;
  on_conflict?: InputMaybe<Program_Session_On_Conflict>;
};

export type Mutation_RootInsert_PsqiArgs = {
  objects: Array<Psqi_Insert_Input>;
  on_conflict?: InputMaybe<Psqi_On_Conflict>;
};

export type Mutation_RootInsert_Psqi_OneArgs = {
  object: Psqi_Insert_Input;
  on_conflict?: InputMaybe<Psqi_On_Conflict>;
};

export type Mutation_RootInsert_RatingArgs = {
  objects: Array<Rating_Insert_Input>;
  on_conflict?: InputMaybe<Rating_On_Conflict>;
};

export type Mutation_RootInsert_Rating_OneArgs = {
  object: Rating_Insert_Input;
  on_conflict?: InputMaybe<Rating_On_Conflict>;
};

export type Mutation_RootInsert_Recurrence_Model_EnumArgs = {
  objects: Array<Recurrence_Model_Enum_Insert_Input>;
  on_conflict?: InputMaybe<Recurrence_Model_Enum_On_Conflict>;
};

export type Mutation_RootInsert_Recurrence_Model_Enum_OneArgs = {
  object: Recurrence_Model_Enum_Insert_Input;
  on_conflict?: InputMaybe<Recurrence_Model_Enum_On_Conflict>;
};

export type Mutation_RootInsert_ReminderArgs = {
  objects: Array<Reminder_Insert_Input>;
  on_conflict?: InputMaybe<Reminder_On_Conflict>;
};

export type Mutation_RootInsert_Reminder_OneArgs = {
  object: Reminder_Insert_Input;
  on_conflict?: InputMaybe<Reminder_On_Conflict>;
};

export type Mutation_RootInsert_Reminder_Type_EnumArgs = {
  objects: Array<Reminder_Type_Enum_Insert_Input>;
  on_conflict?: InputMaybe<Reminder_Type_Enum_On_Conflict>;
};

export type Mutation_RootInsert_Reminder_Type_Enum_OneArgs = {
  object: Reminder_Type_Enum_Insert_Input;
  on_conflict?: InputMaybe<Reminder_Type_Enum_On_Conflict>;
};

export type Mutation_RootInsert_Sleep_DiaryArgs = {
  objects: Array<Sleep_Diary_Insert_Input>;
  on_conflict?: InputMaybe<Sleep_Diary_On_Conflict>;
};

export type Mutation_RootInsert_Sleep_Diary_Apple_HealthArgs = {
  objects: Array<Sleep_Diary_Apple_Health_Insert_Input>;
  on_conflict?: InputMaybe<Sleep_Diary_Apple_Health_On_Conflict>;
};

export type Mutation_RootInsert_Sleep_Diary_Apple_Health_OneArgs = {
  object: Sleep_Diary_Apple_Health_Insert_Input;
  on_conflict?: InputMaybe<Sleep_Diary_Apple_Health_On_Conflict>;
};

export type Mutation_RootInsert_Sleep_Diary_Google_FitArgs = {
  objects: Array<Sleep_Diary_Google_Fit_Insert_Input>;
  on_conflict?: InputMaybe<Sleep_Diary_Google_Fit_On_Conflict>;
};

export type Mutation_RootInsert_Sleep_Diary_Google_Fit_OneArgs = {
  object: Sleep_Diary_Google_Fit_Insert_Input;
  on_conflict?: InputMaybe<Sleep_Diary_Google_Fit_On_Conflict>;
};

export type Mutation_RootInsert_Sleep_Diary_IntervalArgs = {
  objects: Array<Sleep_Diary_Interval_Insert_Input>;
  on_conflict?: InputMaybe<Sleep_Diary_Interval_On_Conflict>;
};

export type Mutation_RootInsert_Sleep_Diary_Interval_OneArgs = {
  object: Sleep_Diary_Interval_Insert_Input;
  on_conflict?: InputMaybe<Sleep_Diary_Interval_On_Conflict>;
};

export type Mutation_RootInsert_Sleep_Diary_MedicineArgs = {
  objects: Array<Sleep_Diary_Medicine_Insert_Input>;
  on_conflict?: InputMaybe<Sleep_Diary_Medicine_On_Conflict>;
};

export type Mutation_RootInsert_Sleep_Diary_Medicine_OneArgs = {
  object: Sleep_Diary_Medicine_Insert_Input;
  on_conflict?: InputMaybe<Sleep_Diary_Medicine_On_Conflict>;
};

export type Mutation_RootInsert_Sleep_Diary_OneArgs = {
  object: Sleep_Diary_Insert_Input;
  on_conflict?: InputMaybe<Sleep_Diary_On_Conflict>;
};

export type Mutation_RootInsert_Sleep_Diary_Sleep_TagArgs = {
  objects: Array<Sleep_Diary_Sleep_Tag_Insert_Input>;
  on_conflict?: InputMaybe<Sleep_Diary_Sleep_Tag_On_Conflict>;
};

export type Mutation_RootInsert_Sleep_Diary_Sleep_Tag_OneArgs = {
  object: Sleep_Diary_Sleep_Tag_Insert_Input;
  on_conflict?: InputMaybe<Sleep_Diary_Sleep_Tag_On_Conflict>;
};

export type Mutation_RootInsert_Sleep_RestrictionArgs = {
  objects: Array<Sleep_Restriction_Insert_Input>;
  on_conflict?: InputMaybe<Sleep_Restriction_On_Conflict>;
};

export type Mutation_RootInsert_Sleep_Restriction_OneArgs = {
  object: Sleep_Restriction_Insert_Input;
  on_conflict?: InputMaybe<Sleep_Restriction_On_Conflict>;
};

export type Mutation_RootInsert_Sleep_Tag_EnumArgs = {
  objects: Array<Sleep_Tag_Enum_Insert_Input>;
  on_conflict?: InputMaybe<Sleep_Tag_Enum_On_Conflict>;
};

export type Mutation_RootInsert_Sleep_Tag_Enum_OneArgs = {
  object: Sleep_Tag_Enum_Insert_Input;
  on_conflict?: InputMaybe<Sleep_Tag_Enum_On_Conflict>;
};

export type Mutation_RootInsert_Sleep_Tracker_Compressed_DataArgs = {
  objects: Array<Sleep_Tracker_Compressed_Data_Insert_Input>;
  on_conflict?: InputMaybe<Sleep_Tracker_Compressed_Data_On_Conflict>;
};

export type Mutation_RootInsert_Sleep_Tracker_Compressed_Data_OneArgs = {
  object: Sleep_Tracker_Compressed_Data_Insert_Input;
  on_conflict?: InputMaybe<Sleep_Tracker_Compressed_Data_On_Conflict>;
};

export type Mutation_RootInsert_Stop_BangArgs = {
  objects: Array<Stop_Bang_Insert_Input>;
  on_conflict?: InputMaybe<Stop_Bang_On_Conflict>;
};

export type Mutation_RootInsert_Stop_Bang_OneArgs = {
  object: Stop_Bang_Insert_Input;
  on_conflict?: InputMaybe<Stop_Bang_On_Conflict>;
};

export type Mutation_RootInsert_SubscriptionArgs = {
  objects: Array<Subscription_Insert_Input>;
  on_conflict?: InputMaybe<Subscription_On_Conflict>;
};

export type Mutation_RootInsert_Subscription_OneArgs = {
  object: Subscription_Insert_Input;
  on_conflict?: InputMaybe<Subscription_On_Conflict>;
};

export type Mutation_RootInsert_Technique_ResourceArgs = {
  objects: Array<Technique_Resource_Insert_Input>;
  on_conflict?: InputMaybe<Technique_Resource_On_Conflict>;
};

export type Mutation_RootInsert_Technique_Resource_OneArgs = {
  object: Technique_Resource_Insert_Input;
  on_conflict?: InputMaybe<Technique_Resource_On_Conflict>;
};

export type Mutation_RootInsert_ToolboxArgs = {
  objects: Array<Toolbox_Insert_Input>;
  on_conflict?: InputMaybe<Toolbox_On_Conflict>;
};

export type Mutation_RootInsert_Toolbox_OneArgs = {
  object: Toolbox_Insert_Input;
  on_conflict?: InputMaybe<Toolbox_On_Conflict>;
};

export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: InputMaybe<User_On_Conflict>;
};

export type Mutation_RootInsert_User_CheckoutArgs = {
  objects: Array<User_Checkout_Insert_Input>;
  on_conflict?: InputMaybe<User_Checkout_On_Conflict>;
};

export type Mutation_RootInsert_User_Checkout_OneArgs = {
  object: User_Checkout_Insert_Input;
  on_conflict?: InputMaybe<User_Checkout_On_Conflict>;
};

export type Mutation_RootInsert_User_CouponArgs = {
  objects: Array<User_Coupon_Insert_Input>;
  on_conflict?: InputMaybe<User_Coupon_On_Conflict>;
};

export type Mutation_RootInsert_User_Coupon_OneArgs = {
  object: User_Coupon_Insert_Input;
  on_conflict?: InputMaybe<User_Coupon_On_Conflict>;
};

export type Mutation_RootInsert_User_JourneyArgs = {
  objects: Array<User_Journey_Insert_Input>;
  on_conflict?: InputMaybe<User_Journey_On_Conflict>;
};

export type Mutation_RootInsert_User_Journey_OneArgs = {
  object: User_Journey_Insert_Input;
  on_conflict?: InputMaybe<User_Journey_On_Conflict>;
};

export type Mutation_RootInsert_User_Key_ValueArgs = {
  objects: Array<User_Key_Value_Insert_Input>;
  on_conflict?: InputMaybe<User_Key_Value_On_Conflict>;
};

export type Mutation_RootInsert_User_Key_Value_Key_EnumArgs = {
  objects: Array<User_Key_Value_Key_Enum_Insert_Input>;
  on_conflict?: InputMaybe<User_Key_Value_Key_Enum_On_Conflict>;
};

export type Mutation_RootInsert_User_Key_Value_Key_Enum_OneArgs = {
  object: User_Key_Value_Key_Enum_Insert_Input;
  on_conflict?: InputMaybe<User_Key_Value_Key_Enum_On_Conflict>;
};

export type Mutation_RootInsert_User_Key_Value_OneArgs = {
  object: User_Key_Value_Insert_Input;
  on_conflict?: InputMaybe<User_Key_Value_On_Conflict>;
};

export type Mutation_RootInsert_User_NotificationArgs = {
  objects: Array<User_Notification_Insert_Input>;
  on_conflict?: InputMaybe<User_Notification_On_Conflict>;
};

export type Mutation_RootInsert_User_Notification_OneArgs = {
  object: User_Notification_Insert_Input;
  on_conflict?: InputMaybe<User_Notification_On_Conflict>;
};

export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input;
  on_conflict?: InputMaybe<User_On_Conflict>;
};

export type Mutation_RootInsert_User_Program_SessionArgs = {
  objects: Array<User_Program_Session_Insert_Input>;
  on_conflict?: InputMaybe<User_Program_Session_On_Conflict>;
};

export type Mutation_RootInsert_User_Program_Session_EnumArgs = {
  objects: Array<User_Program_Session_Enum_Insert_Input>;
  on_conflict?: InputMaybe<User_Program_Session_Enum_On_Conflict>;
};

export type Mutation_RootInsert_User_Program_Session_Enum_OneArgs = {
  object: User_Program_Session_Enum_Insert_Input;
  on_conflict?: InputMaybe<User_Program_Session_Enum_On_Conflict>;
};

export type Mutation_RootInsert_User_Program_Session_OneArgs = {
  object: User_Program_Session_Insert_Input;
  on_conflict?: InputMaybe<User_Program_Session_On_Conflict>;
};

export type Mutation_RootInsert_User_Reminder_InfoArgs = {
  objects: Array<User_Reminder_Info_Insert_Input>;
  on_conflict?: InputMaybe<User_Reminder_Info_On_Conflict>;
};

export type Mutation_RootInsert_User_Reminder_Info_OneArgs = {
  object: User_Reminder_Info_Insert_Input;
  on_conflict?: InputMaybe<User_Reminder_Info_On_Conflict>;
};

export type Mutation_RootInsert_User_TagArgs = {
  objects: Array<User_Tag_Insert_Input>;
  on_conflict?: InputMaybe<User_Tag_On_Conflict>;
};

export type Mutation_RootInsert_User_Tag_OneArgs = {
  object: User_Tag_Insert_Input;
  on_conflict?: InputMaybe<User_Tag_On_Conflict>;
};

export type Mutation_RootInsert_User_TelehealthArgs = {
  objects: Array<User_Telehealth_Insert_Input>;
  on_conflict?: InputMaybe<User_Telehealth_On_Conflict>;
};

export type Mutation_RootInsert_User_Telehealth_OneArgs = {
  object: User_Telehealth_Insert_Input;
  on_conflict?: InputMaybe<User_Telehealth_On_Conflict>;
};

export type Mutation_RootInsert_Yes_No_Question_EnumArgs = {
  objects: Array<Yes_No_Question_Enum_Insert_Input>;
  on_conflict?: InputMaybe<Yes_No_Question_Enum_On_Conflict>;
};

export type Mutation_RootInsert_Yes_No_Question_Enum_OneArgs = {
  object: Yes_No_Question_Enum_Insert_Input;
  on_conflict?: InputMaybe<Yes_No_Question_Enum_On_Conflict>;
};

export type Mutation_RootUpdate_Apnea_QuestionnaireArgs = {
  _append?: InputMaybe<Apnea_Questionnaire_Append_Input>;
  _delete_at_path?: InputMaybe<Apnea_Questionnaire_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Apnea_Questionnaire_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Apnea_Questionnaire_Delete_Key_Input>;
  _inc?: InputMaybe<Apnea_Questionnaire_Inc_Input>;
  _prepend?: InputMaybe<Apnea_Questionnaire_Prepend_Input>;
  _set?: InputMaybe<Apnea_Questionnaire_Set_Input>;
  where: Apnea_Questionnaire_Bool_Exp;
};

export type Mutation_RootUpdate_Apnea_Questionnaire_By_PkArgs = {
  _append?: InputMaybe<Apnea_Questionnaire_Append_Input>;
  _delete_at_path?: InputMaybe<Apnea_Questionnaire_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Apnea_Questionnaire_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Apnea_Questionnaire_Delete_Key_Input>;
  _inc?: InputMaybe<Apnea_Questionnaire_Inc_Input>;
  _prepend?: InputMaybe<Apnea_Questionnaire_Prepend_Input>;
  _set?: InputMaybe<Apnea_Questionnaire_Set_Input>;
  pk_columns: Apnea_Questionnaire_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Apnea_Scan_PhotoArgs = {
  _append?: InputMaybe<Apnea_Scan_Photo_Append_Input>;
  _delete_at_path?: InputMaybe<Apnea_Scan_Photo_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Apnea_Scan_Photo_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Apnea_Scan_Photo_Delete_Key_Input>;
  _inc?: InputMaybe<Apnea_Scan_Photo_Inc_Input>;
  _prepend?: InputMaybe<Apnea_Scan_Photo_Prepend_Input>;
  _set?: InputMaybe<Apnea_Scan_Photo_Set_Input>;
  where: Apnea_Scan_Photo_Bool_Exp;
};

export type Mutation_RootUpdate_Apnea_Scan_Photo_By_PkArgs = {
  _append?: InputMaybe<Apnea_Scan_Photo_Append_Input>;
  _delete_at_path?: InputMaybe<Apnea_Scan_Photo_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Apnea_Scan_Photo_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Apnea_Scan_Photo_Delete_Key_Input>;
  _inc?: InputMaybe<Apnea_Scan_Photo_Inc_Input>;
  _prepend?: InputMaybe<Apnea_Scan_Photo_Prepend_Input>;
  _set?: InputMaybe<Apnea_Scan_Photo_Set_Input>;
  pk_columns: Apnea_Scan_Photo_Pk_Columns_Input;
};

export type Mutation_RootUpdate_App_CustomizationArgs = {
  _append?: InputMaybe<App_Customization_Append_Input>;
  _delete_at_path?: InputMaybe<App_Customization_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<App_Customization_Delete_Elem_Input>;
  _delete_key?: InputMaybe<App_Customization_Delete_Key_Input>;
  _inc?: InputMaybe<App_Customization_Inc_Input>;
  _prepend?: InputMaybe<App_Customization_Prepend_Input>;
  _set?: InputMaybe<App_Customization_Set_Input>;
  where: App_Customization_Bool_Exp;
};

export type Mutation_RootUpdate_App_Customization_By_PkArgs = {
  _append?: InputMaybe<App_Customization_Append_Input>;
  _delete_at_path?: InputMaybe<App_Customization_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<App_Customization_Delete_Elem_Input>;
  _delete_key?: InputMaybe<App_Customization_Delete_Key_Input>;
  _inc?: InputMaybe<App_Customization_Inc_Input>;
  _prepend?: InputMaybe<App_Customization_Prepend_Input>;
  _set?: InputMaybe<App_Customization_Set_Input>;
  pk_columns: App_Customization_Pk_Columns_Input;
};

export type Mutation_RootUpdate_App_Customization_Type_EnumArgs = {
  _set?: InputMaybe<App_Customization_Type_Enum_Set_Input>;
  where: App_Customization_Type_Enum_Bool_Exp;
};

export type Mutation_RootUpdate_App_Customization_Type_Enum_By_PkArgs = {
  _set?: InputMaybe<App_Customization_Type_Enum_Set_Input>;
  pk_columns: App_Customization_Type_Enum_Pk_Columns_Input;
};

export type Mutation_RootUpdate_AppointmentArgs = {
  _set?: InputMaybe<Appointment_Set_Input>;
  where: Appointment_Bool_Exp;
};

export type Mutation_RootUpdate_Appointment_By_PkArgs = {
  _set?: InputMaybe<Appointment_Set_Input>;
  pk_columns: Appointment_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Appointment_Status_EnumArgs = {
  _set?: InputMaybe<Appointment_Status_Enum_Set_Input>;
  where: Appointment_Status_Enum_Bool_Exp;
};

export type Mutation_RootUpdate_Appointment_Status_Enum_By_PkArgs = {
  _set?: InputMaybe<Appointment_Status_Enum_Set_Input>;
  pk_columns: Appointment_Status_Enum_Pk_Columns_Input;
};

export type Mutation_RootUpdate_AssesmentArgs = {
  _inc?: InputMaybe<Assesment_Inc_Input>;
  _set?: InputMaybe<Assesment_Set_Input>;
  where: Assesment_Bool_Exp;
};

export type Mutation_RootUpdate_Assesment_By_PkArgs = {
  _inc?: InputMaybe<Assesment_Inc_Input>;
  _set?: InputMaybe<Assesment_Set_Input>;
  pk_columns: Assesment_Pk_Columns_Input;
};

export type Mutation_RootUpdate_B2b_Diagnosis_V1Args = {
  _append?: InputMaybe<B2b_Diagnosis_V1_Append_Input>;
  _delete_at_path?: InputMaybe<B2b_Diagnosis_V1_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<B2b_Diagnosis_V1_Delete_Elem_Input>;
  _delete_key?: InputMaybe<B2b_Diagnosis_V1_Delete_Key_Input>;
  _inc?: InputMaybe<B2b_Diagnosis_V1_Inc_Input>;
  _prepend?: InputMaybe<B2b_Diagnosis_V1_Prepend_Input>;
  _set?: InputMaybe<B2b_Diagnosis_V1_Set_Input>;
  where: B2b_Diagnosis_V1_Bool_Exp;
};

export type Mutation_RootUpdate_B2b_Diagnosis_V1_By_PkArgs = {
  _append?: InputMaybe<B2b_Diagnosis_V1_Append_Input>;
  _delete_at_path?: InputMaybe<B2b_Diagnosis_V1_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<B2b_Diagnosis_V1_Delete_Elem_Input>;
  _delete_key?: InputMaybe<B2b_Diagnosis_V1_Delete_Key_Input>;
  _inc?: InputMaybe<B2b_Diagnosis_V1_Inc_Input>;
  _prepend?: InputMaybe<B2b_Diagnosis_V1_Prepend_Input>;
  _set?: InputMaybe<B2b_Diagnosis_V1_Set_Input>;
  pk_columns: B2b_Diagnosis_V1_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Catastrophic_ThinkingsArgs = {
  _inc?: InputMaybe<Catastrophic_Thinkings_Inc_Input>;
  _set?: InputMaybe<Catastrophic_Thinkings_Set_Input>;
  where: Catastrophic_Thinkings_Bool_Exp;
};

export type Mutation_RootUpdate_Catastrophic_Thinkings_By_PkArgs = {
  _inc?: InputMaybe<Catastrophic_Thinkings_Inc_Input>;
  _set?: InputMaybe<Catastrophic_Thinkings_Set_Input>;
  pk_columns: Catastrophic_Thinkings_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Community_UserArgs = {
  _inc?: InputMaybe<Community_User_Inc_Input>;
  _set?: InputMaybe<Community_User_Set_Input>;
  where: Community_User_Bool_Exp;
};

export type Mutation_RootUpdate_Community_User_By_PkArgs = {
  _inc?: InputMaybe<Community_User_Inc_Input>;
  _set?: InputMaybe<Community_User_Set_Input>;
  pk_columns: Community_User_Pk_Columns_Input;
};

export type Mutation_RootUpdate_CompanyArgs = {
  _inc?: InputMaybe<Company_Inc_Input>;
  _set?: InputMaybe<Company_Set_Input>;
  where: Company_Bool_Exp;
};

export type Mutation_RootUpdate_Company_By_PkArgs = {
  _inc?: InputMaybe<Company_Inc_Input>;
  _set?: InputMaybe<Company_Set_Input>;
  pk_columns: Company_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Company_Health_CarerArgs = {
  _inc?: InputMaybe<Company_Health_Carer_Inc_Input>;
  _set?: InputMaybe<Company_Health_Carer_Set_Input>;
  where: Company_Health_Carer_Bool_Exp;
};

export type Mutation_RootUpdate_Company_Health_Carer_By_PkArgs = {
  _inc?: InputMaybe<Company_Health_Carer_Inc_Input>;
  _set?: InputMaybe<Company_Health_Carer_Set_Input>;
  pk_columns: Company_Health_Carer_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Company_Health_Carer_Kind_EnumArgs = {
  _set?: InputMaybe<Company_Health_Carer_Kind_Enum_Set_Input>;
  where: Company_Health_Carer_Kind_Enum_Bool_Exp;
};

export type Mutation_RootUpdate_Company_Health_Carer_Kind_Enum_By_PkArgs = {
  _set?: InputMaybe<Company_Health_Carer_Kind_Enum_Set_Input>;
  pk_columns: Company_Health_Carer_Kind_Enum_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Company_UserArgs = {
  _inc?: InputMaybe<Company_User_Inc_Input>;
  _set?: InputMaybe<Company_User_Set_Input>;
  where: Company_User_Bool_Exp;
};

export type Mutation_RootUpdate_Company_User_By_PkArgs = {
  _inc?: InputMaybe<Company_User_Inc_Input>;
  _set?: InputMaybe<Company_User_Set_Input>;
  pk_columns: Company_User_Pk_Columns_Input;
};

export type Mutation_RootUpdate_CouponArgs = {
  _append?: InputMaybe<Coupon_Append_Input>;
  _delete_at_path?: InputMaybe<Coupon_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Coupon_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Coupon_Delete_Key_Input>;
  _inc?: InputMaybe<Coupon_Inc_Input>;
  _prepend?: InputMaybe<Coupon_Prepend_Input>;
  _set?: InputMaybe<Coupon_Set_Input>;
  where: Coupon_Bool_Exp;
};

export type Mutation_RootUpdate_Coupon_By_PkArgs = {
  _append?: InputMaybe<Coupon_Append_Input>;
  _delete_at_path?: InputMaybe<Coupon_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Coupon_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Coupon_Delete_Key_Input>;
  _inc?: InputMaybe<Coupon_Inc_Input>;
  _prepend?: InputMaybe<Coupon_Prepend_Input>;
  _set?: InputMaybe<Coupon_Set_Input>;
  pk_columns: Coupon_Pk_Columns_Input;
};

export type Mutation_RootUpdate_CrmArgs = {
  _set?: InputMaybe<Crm_Set_Input>;
  where: Crm_Bool_Exp;
};

export type Mutation_RootUpdate_Crm_By_PkArgs = {
  _set?: InputMaybe<Crm_Set_Input>;
  pk_columns: Crm_Pk_Columns_Input;
};

export type Mutation_RootUpdate_DreamArgs = {
  _inc?: InputMaybe<Dream_Inc_Input>;
  _set?: InputMaybe<Dream_Set_Input>;
  where: Dream_Bool_Exp;
};

export type Mutation_RootUpdate_Dream_By_PkArgs = {
  _inc?: InputMaybe<Dream_Inc_Input>;
  _set?: InputMaybe<Dream_Set_Input>;
  pk_columns: Dream_Pk_Columns_Input;
};

export type Mutation_RootUpdate_EficiencyArgs = {
  _inc?: InputMaybe<Eficiency_Inc_Input>;
  _set?: InputMaybe<Eficiency_Set_Input>;
  where: Eficiency_Bool_Exp;
};

export type Mutation_RootUpdate_Eficiency_By_PkArgs = {
  _inc?: InputMaybe<Eficiency_Inc_Input>;
  _set?: InputMaybe<Eficiency_Set_Input>;
  pk_columns: Eficiency_Pk_Columns_Input;
};

export type Mutation_RootUpdate_EpworthArgs = {
  _inc?: InputMaybe<Epworth_Inc_Input>;
  _set?: InputMaybe<Epworth_Set_Input>;
  where: Epworth_Bool_Exp;
};

export type Mutation_RootUpdate_Epworth_By_PkArgs = {
  _inc?: InputMaybe<Epworth_Inc_Input>;
  _set?: InputMaybe<Epworth_Set_Input>;
  pk_columns: Epworth_Pk_Columns_Input;
};

export type Mutation_RootUpdate_GadArgs = {
  _inc?: InputMaybe<Gad_Inc_Input>;
  _set?: InputMaybe<Gad_Set_Input>;
  where: Gad_Bool_Exp;
};

export type Mutation_RootUpdate_Gad7Args = {
  _inc?: InputMaybe<Gad7_Inc_Input>;
  _set?: InputMaybe<Gad7_Set_Input>;
  where: Gad7_Bool_Exp;
};

export type Mutation_RootUpdate_Gad7_By_PkArgs = {
  _inc?: InputMaybe<Gad7_Inc_Input>;
  _set?: InputMaybe<Gad7_Set_Input>;
  pk_columns: Gad7_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Gad_By_PkArgs = {
  _inc?: InputMaybe<Gad_Inc_Input>;
  _set?: InputMaybe<Gad_Set_Input>;
  pk_columns: Gad_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Generic_QuestionnaireArgs = {
  _append?: InputMaybe<Generic_Questionnaire_Append_Input>;
  _delete_at_path?: InputMaybe<Generic_Questionnaire_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Generic_Questionnaire_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Generic_Questionnaire_Delete_Key_Input>;
  _inc?: InputMaybe<Generic_Questionnaire_Inc_Input>;
  _prepend?: InputMaybe<Generic_Questionnaire_Prepend_Input>;
  _set?: InputMaybe<Generic_Questionnaire_Set_Input>;
  where: Generic_Questionnaire_Bool_Exp;
};

export type Mutation_RootUpdate_Generic_Questionnaire_By_PkArgs = {
  _append?: InputMaybe<Generic_Questionnaire_Append_Input>;
  _delete_at_path?: InputMaybe<Generic_Questionnaire_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Generic_Questionnaire_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Generic_Questionnaire_Delete_Key_Input>;
  _inc?: InputMaybe<Generic_Questionnaire_Inc_Input>;
  _prepend?: InputMaybe<Generic_Questionnaire_Prepend_Input>;
  _set?: InputMaybe<Generic_Questionnaire_Set_Input>;
  pk_columns: Generic_Questionnaire_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Generic_Questionnaire_Name_EnumArgs = {
  _set?: InputMaybe<Generic_Questionnaire_Name_Enum_Set_Input>;
  where: Generic_Questionnaire_Name_Enum_Bool_Exp;
};

export type Mutation_RootUpdate_Generic_Questionnaire_Name_Enum_By_PkArgs = {
  _set?: InputMaybe<Generic_Questionnaire_Name_Enum_Set_Input>;
  pk_columns: Generic_Questionnaire_Name_Enum_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Google_Sheet_RowArgs = {
  _append?: InputMaybe<Google_Sheet_Row_Append_Input>;
  _delete_at_path?: InputMaybe<Google_Sheet_Row_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Google_Sheet_Row_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Google_Sheet_Row_Delete_Key_Input>;
  _inc?: InputMaybe<Google_Sheet_Row_Inc_Input>;
  _prepend?: InputMaybe<Google_Sheet_Row_Prepend_Input>;
  _set?: InputMaybe<Google_Sheet_Row_Set_Input>;
  where: Google_Sheet_Row_Bool_Exp;
};

export type Mutation_RootUpdate_Google_Sheet_Row_By_PkArgs = {
  _append?: InputMaybe<Google_Sheet_Row_Append_Input>;
  _delete_at_path?: InputMaybe<Google_Sheet_Row_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Google_Sheet_Row_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Google_Sheet_Row_Delete_Key_Input>;
  _inc?: InputMaybe<Google_Sheet_Row_Inc_Input>;
  _prepend?: InputMaybe<Google_Sheet_Row_Prepend_Input>;
  _set?: InputMaybe<Google_Sheet_Row_Set_Input>;
  pk_columns: Google_Sheet_Row_Pk_Columns_Input;
};

export type Mutation_RootUpdate_GratitudeArgs = {
  _inc?: InputMaybe<Gratitude_Inc_Input>;
  _set?: InputMaybe<Gratitude_Set_Input>;
  where: Gratitude_Bool_Exp;
};

export type Mutation_RootUpdate_Gratitude_By_PkArgs = {
  _inc?: InputMaybe<Gratitude_Inc_Input>;
  _set?: InputMaybe<Gratitude_Set_Input>;
  pk_columns: Gratitude_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Health_CarerArgs = {
  _inc?: InputMaybe<Health_Carer_Inc_Input>;
  _set?: InputMaybe<Health_Carer_Set_Input>;
  where: Health_Carer_Bool_Exp;
};

export type Mutation_RootUpdate_Health_Carer_By_PkArgs = {
  _inc?: InputMaybe<Health_Carer_Inc_Input>;
  _set?: InputMaybe<Health_Carer_Set_Input>;
  pk_columns: Health_Carer_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Health_Carer_Membership_Kind_EnumArgs = {
  _set?: InputMaybe<Health_Carer_Membership_Kind_Enum_Set_Input>;
  where: Health_Carer_Membership_Kind_Enum_Bool_Exp;
};

export type Mutation_RootUpdate_Health_Carer_Membership_Kind_Enum_By_PkArgs = {
  _set?: InputMaybe<Health_Carer_Membership_Kind_Enum_Set_Input>;
  pk_columns: Health_Carer_Membership_Kind_Enum_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Health_Carer_Money_RequestArgs = {
  _append?: InputMaybe<Health_Carer_Money_Request_Append_Input>;
  _delete_at_path?: InputMaybe<Health_Carer_Money_Request_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Health_Carer_Money_Request_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Health_Carer_Money_Request_Delete_Key_Input>;
  _inc?: InputMaybe<Health_Carer_Money_Request_Inc_Input>;
  _prepend?: InputMaybe<Health_Carer_Money_Request_Prepend_Input>;
  _set?: InputMaybe<Health_Carer_Money_Request_Set_Input>;
  where: Health_Carer_Money_Request_Bool_Exp;
};

export type Mutation_RootUpdate_Health_Carer_Money_Request_By_PkArgs = {
  _append?: InputMaybe<Health_Carer_Money_Request_Append_Input>;
  _delete_at_path?: InputMaybe<Health_Carer_Money_Request_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Health_Carer_Money_Request_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Health_Carer_Money_Request_Delete_Key_Input>;
  _inc?: InputMaybe<Health_Carer_Money_Request_Inc_Input>;
  _prepend?: InputMaybe<Health_Carer_Money_Request_Prepend_Input>;
  _set?: InputMaybe<Health_Carer_Money_Request_Set_Input>;
  pk_columns: Health_Carer_Money_Request_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Health_Carer_Money_Request_LogArgs = {
  _inc?: InputMaybe<Health_Carer_Money_Request_Log_Inc_Input>;
  _set?: InputMaybe<Health_Carer_Money_Request_Log_Set_Input>;
  where: Health_Carer_Money_Request_Log_Bool_Exp;
};

export type Mutation_RootUpdate_Health_Carer_Money_Request_Log_By_PkArgs = {
  _inc?: InputMaybe<Health_Carer_Money_Request_Log_Inc_Input>;
  _set?: InputMaybe<Health_Carer_Money_Request_Log_Set_Input>;
  pk_columns: Health_Carer_Money_Request_Log_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Health_Carer_Money_Request_Status_EnumArgs = {
  _set?: InputMaybe<Health_Carer_Money_Request_Status_Enum_Set_Input>;
  where: Health_Carer_Money_Request_Status_Enum_Bool_Exp;
};

export type Mutation_RootUpdate_Health_Carer_Money_Request_Status_Enum_By_PkArgs = {
  _set?: InputMaybe<Health_Carer_Money_Request_Status_Enum_Set_Input>;
  pk_columns: Health_Carer_Money_Request_Status_Enum_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Health_Carer_Pagarme_RecipientArgs = {
  _set?: InputMaybe<Health_Carer_Pagarme_Recipient_Set_Input>;
  where: Health_Carer_Pagarme_Recipient_Bool_Exp;
};

export type Mutation_RootUpdate_Health_Carer_Pagarme_Recipient_By_PkArgs = {
  _set?: InputMaybe<Health_Carer_Pagarme_Recipient_Set_Input>;
  pk_columns: Health_Carer_Pagarme_Recipient_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Health_Carer_TelehealthArgs = {
  _append?: InputMaybe<Health_Carer_Telehealth_Append_Input>;
  _delete_at_path?: InputMaybe<Health_Carer_Telehealth_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Health_Carer_Telehealth_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Health_Carer_Telehealth_Delete_Key_Input>;
  _inc?: InputMaybe<Health_Carer_Telehealth_Inc_Input>;
  _prepend?: InputMaybe<Health_Carer_Telehealth_Prepend_Input>;
  _set?: InputMaybe<Health_Carer_Telehealth_Set_Input>;
  where: Health_Carer_Telehealth_Bool_Exp;
};

export type Mutation_RootUpdate_Health_Carer_Telehealth_By_PkArgs = {
  _append?: InputMaybe<Health_Carer_Telehealth_Append_Input>;
  _delete_at_path?: InputMaybe<Health_Carer_Telehealth_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Health_Carer_Telehealth_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Health_Carer_Telehealth_Delete_Key_Input>;
  _inc?: InputMaybe<Health_Carer_Telehealth_Inc_Input>;
  _prepend?: InputMaybe<Health_Carer_Telehealth_Prepend_Input>;
  _set?: InputMaybe<Health_Carer_Telehealth_Set_Input>;
  pk_columns: Health_Carer_Telehealth_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Health_Carer_TransactionsArgs = {
  _append?: InputMaybe<Health_Carer_Transactions_Append_Input>;
  _delete_at_path?: InputMaybe<Health_Carer_Transactions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Health_Carer_Transactions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Health_Carer_Transactions_Delete_Key_Input>;
  _inc?: InputMaybe<Health_Carer_Transactions_Inc_Input>;
  _prepend?: InputMaybe<Health_Carer_Transactions_Prepend_Input>;
  _set?: InputMaybe<Health_Carer_Transactions_Set_Input>;
  where: Health_Carer_Transactions_Bool_Exp;
};

export type Mutation_RootUpdate_Health_Carer_Transactions_By_PkArgs = {
  _append?: InputMaybe<Health_Carer_Transactions_Append_Input>;
  _delete_at_path?: InputMaybe<Health_Carer_Transactions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Health_Carer_Transactions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Health_Carer_Transactions_Delete_Key_Input>;
  _inc?: InputMaybe<Health_Carer_Transactions_Inc_Input>;
  _prepend?: InputMaybe<Health_Carer_Transactions_Prepend_Input>;
  _set?: InputMaybe<Health_Carer_Transactions_Set_Input>;
  pk_columns: Health_Carer_Transactions_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Health_Carer_UserArgs = {
  _inc?: InputMaybe<Health_Carer_User_Inc_Input>;
  _set?: InputMaybe<Health_Carer_User_Set_Input>;
  where: Health_Carer_User_Bool_Exp;
};

export type Mutation_RootUpdate_Health_Carer_User_By_PkArgs = {
  _inc?: InputMaybe<Health_Carer_User_Inc_Input>;
  _set?: InputMaybe<Health_Carer_User_Set_Input>;
  pk_columns: Health_Carer_User_Pk_Columns_Input;
};

export type Mutation_RootUpdate_IsiArgs = {
  _inc?: InputMaybe<Isi_Inc_Input>;
  _set?: InputMaybe<Isi_Set_Input>;
  where: Isi_Bool_Exp;
};

export type Mutation_RootUpdate_Isi_By_PkArgs = {
  _inc?: InputMaybe<Isi_Inc_Input>;
  _set?: InputMaybe<Isi_Set_Input>;
  pk_columns: Isi_Pk_Columns_Input;
};

export type Mutation_RootUpdate_JourneyArgs = {
  _inc?: InputMaybe<Journey_Inc_Input>;
  _set?: InputMaybe<Journey_Set_Input>;
  where: Journey_Bool_Exp;
};

export type Mutation_RootUpdate_Journey_By_PkArgs = {
  _inc?: InputMaybe<Journey_Inc_Input>;
  _set?: InputMaybe<Journey_Set_Input>;
  pk_columns: Journey_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Journey_Program_SessionsArgs = {
  _inc?: InputMaybe<Journey_Program_Sessions_Inc_Input>;
  _set?: InputMaybe<Journey_Program_Sessions_Set_Input>;
  where: Journey_Program_Sessions_Bool_Exp;
};

export type Mutation_RootUpdate_Journey_Program_Sessions_By_PkArgs = {
  _inc?: InputMaybe<Journey_Program_Sessions_Inc_Input>;
  _set?: InputMaybe<Journey_Program_Sessions_Set_Input>;
  pk_columns: Journey_Program_Sessions_Pk_Columns_Input;
};

export type Mutation_RootUpdate_KuppermanArgs = {
  _inc?: InputMaybe<Kupperman_Inc_Input>;
  _set?: InputMaybe<Kupperman_Set_Input>;
  where: Kupperman_Bool_Exp;
};

export type Mutation_RootUpdate_Kupperman_By_PkArgs = {
  _inc?: InputMaybe<Kupperman_Inc_Input>;
  _set?: InputMaybe<Kupperman_Set_Input>;
  pk_columns: Kupperman_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Landing_Page_RedirectArgs = {
  _set?: InputMaybe<Landing_Page_Redirect_Set_Input>;
  where: Landing_Page_Redirect_Bool_Exp;
};

export type Mutation_RootUpdate_Landing_Page_Redirect_By_PkArgs = {
  _set?: InputMaybe<Landing_Page_Redirect_Set_Input>;
  pk_columns: Landing_Page_Redirect_Pk_Columns_Input;
};

export type Mutation_RootUpdate_LogArgs = {
  _append?: InputMaybe<Log_Append_Input>;
  _delete_at_path?: InputMaybe<Log_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Log_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Log_Delete_Key_Input>;
  _inc?: InputMaybe<Log_Inc_Input>;
  _prepend?: InputMaybe<Log_Prepend_Input>;
  _set?: InputMaybe<Log_Set_Input>;
  where: Log_Bool_Exp;
};

export type Mutation_RootUpdate_Log_By_PkArgs = {
  _append?: InputMaybe<Log_Append_Input>;
  _delete_at_path?: InputMaybe<Log_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Log_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Log_Delete_Key_Input>;
  _inc?: InputMaybe<Log_Inc_Input>;
  _prepend?: InputMaybe<Log_Prepend_Input>;
  _set?: InputMaybe<Log_Set_Input>;
  pk_columns: Log_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Log_Type_EnumArgs = {
  _set?: InputMaybe<Log_Type_Enum_Set_Input>;
  where: Log_Type_Enum_Bool_Exp;
};

export type Mutation_RootUpdate_Log_Type_Enum_By_PkArgs = {
  _set?: InputMaybe<Log_Type_Enum_Set_Input>;
  pk_columns: Log_Type_Enum_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Menopause_Greene_ScaleArgs = {
  _inc?: InputMaybe<Menopause_Greene_Scale_Inc_Input>;
  _set?: InputMaybe<Menopause_Greene_Scale_Set_Input>;
  where: Menopause_Greene_Scale_Bool_Exp;
};

export type Mutation_RootUpdate_Menopause_Greene_Scale_By_PkArgs = {
  _inc?: InputMaybe<Menopause_Greene_Scale_Inc_Input>;
  _set?: InputMaybe<Menopause_Greene_Scale_Set_Input>;
  pk_columns: Menopause_Greene_Scale_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Munich_ChronotypeArgs = {
  _inc?: InputMaybe<Munich_Chronotype_Inc_Input>;
  _set?: InputMaybe<Munich_Chronotype_Set_Input>;
  where: Munich_Chronotype_Bool_Exp;
};

export type Mutation_RootUpdate_Munich_Chronotype_By_PkArgs = {
  _inc?: InputMaybe<Munich_Chronotype_Inc_Input>;
  _set?: InputMaybe<Munich_Chronotype_Set_Input>;
  pk_columns: Munich_Chronotype_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Munich_Chronotype_CoreArgs = {
  _append?: InputMaybe<Munich_Chronotype_Core_Append_Input>;
  _delete_at_path?: InputMaybe<Munich_Chronotype_Core_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Munich_Chronotype_Core_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Munich_Chronotype_Core_Delete_Key_Input>;
  _inc?: InputMaybe<Munich_Chronotype_Core_Inc_Input>;
  _prepend?: InputMaybe<Munich_Chronotype_Core_Prepend_Input>;
  _set?: InputMaybe<Munich_Chronotype_Core_Set_Input>;
  where: Munich_Chronotype_Core_Bool_Exp;
};

export type Mutation_RootUpdate_Munich_Chronotype_Core_By_PkArgs = {
  _append?: InputMaybe<Munich_Chronotype_Core_Append_Input>;
  _delete_at_path?: InputMaybe<Munich_Chronotype_Core_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Munich_Chronotype_Core_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Munich_Chronotype_Core_Delete_Key_Input>;
  _inc?: InputMaybe<Munich_Chronotype_Core_Inc_Input>;
  _prepend?: InputMaybe<Munich_Chronotype_Core_Prepend_Input>;
  _set?: InputMaybe<Munich_Chronotype_Core_Set_Input>;
  pk_columns: Munich_Chronotype_Core_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Os_EnumArgs = {
  _set?: InputMaybe<Os_Enum_Set_Input>;
  where: Os_Enum_Bool_Exp;
};

export type Mutation_RootUpdate_Os_Enum_By_PkArgs = {
  _set?: InputMaybe<Os_Enum_Set_Input>;
  pk_columns: Os_Enum_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Parking_LotArgs = {
  _inc?: InputMaybe<Parking_Lot_Inc_Input>;
  _set?: InputMaybe<Parking_Lot_Set_Input>;
  where: Parking_Lot_Bool_Exp;
};

export type Mutation_RootUpdate_Parking_Lot_By_PkArgs = {
  _inc?: InputMaybe<Parking_Lot_Inc_Input>;
  _set?: InputMaybe<Parking_Lot_Set_Input>;
  pk_columns: Parking_Lot_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Personality_QuestionnaireArgs = {
  _inc?: InputMaybe<Personality_Questionnaire_Inc_Input>;
  _set?: InputMaybe<Personality_Questionnaire_Set_Input>;
  where: Personality_Questionnaire_Bool_Exp;
};

export type Mutation_RootUpdate_Personality_Questionnaire_By_PkArgs = {
  _inc?: InputMaybe<Personality_Questionnaire_Inc_Input>;
  _set?: InputMaybe<Personality_Questionnaire_Set_Input>;
  pk_columns: Personality_Questionnaire_Pk_Columns_Input;
};

export type Mutation_RootUpdate_PhqArgs = {
  _inc?: InputMaybe<Phq_Inc_Input>;
  _set?: InputMaybe<Phq_Set_Input>;
  where: Phq_Bool_Exp;
};

export type Mutation_RootUpdate_Phq9Args = {
  _inc?: InputMaybe<Phq9_Inc_Input>;
  _set?: InputMaybe<Phq9_Set_Input>;
  where: Phq9_Bool_Exp;
};

export type Mutation_RootUpdate_Phq9_By_PkArgs = {
  _inc?: InputMaybe<Phq9_Inc_Input>;
  _set?: InputMaybe<Phq9_Set_Input>;
  pk_columns: Phq9_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Phq_By_PkArgs = {
  _inc?: InputMaybe<Phq_Inc_Input>;
  _set?: InputMaybe<Phq_Set_Input>;
  pk_columns: Phq_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Pre_UserArgs = {
  _set?: InputMaybe<Pre_User_Set_Input>;
  where: Pre_User_Bool_Exp;
};

export type Mutation_RootUpdate_Pre_User_By_PkArgs = {
  _set?: InputMaybe<Pre_User_Set_Input>;
  pk_columns: Pre_User_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Pre_User_Source_EnumArgs = {
  _set?: InputMaybe<Pre_User_Source_Enum_Set_Input>;
  where: Pre_User_Source_Enum_Bool_Exp;
};

export type Mutation_RootUpdate_Pre_User_Source_Enum_By_PkArgs = {
  _set?: InputMaybe<Pre_User_Source_Enum_Set_Input>;
  pk_columns: Pre_User_Source_Enum_Pk_Columns_Input;
};

export type Mutation_RootUpdate_ProductivityArgs = {
  _inc?: InputMaybe<Productivity_Inc_Input>;
  _set?: InputMaybe<Productivity_Set_Input>;
  where: Productivity_Bool_Exp;
};

export type Mutation_RootUpdate_Productivity_By_PkArgs = {
  _inc?: InputMaybe<Productivity_Inc_Input>;
  _set?: InputMaybe<Productivity_Set_Input>;
  pk_columns: Productivity_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Program_SessionArgs = {
  _append?: InputMaybe<Program_Session_Append_Input>;
  _delete_at_path?: InputMaybe<Program_Session_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Program_Session_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Program_Session_Delete_Key_Input>;
  _inc?: InputMaybe<Program_Session_Inc_Input>;
  _prepend?: InputMaybe<Program_Session_Prepend_Input>;
  _set?: InputMaybe<Program_Session_Set_Input>;
  where: Program_Session_Bool_Exp;
};

export type Mutation_RootUpdate_Program_Session_By_PkArgs = {
  _append?: InputMaybe<Program_Session_Append_Input>;
  _delete_at_path?: InputMaybe<Program_Session_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Program_Session_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Program_Session_Delete_Key_Input>;
  _inc?: InputMaybe<Program_Session_Inc_Input>;
  _prepend?: InputMaybe<Program_Session_Prepend_Input>;
  _set?: InputMaybe<Program_Session_Set_Input>;
  pk_columns: Program_Session_Pk_Columns_Input;
};

export type Mutation_RootUpdate_PsqiArgs = {
  _inc?: InputMaybe<Psqi_Inc_Input>;
  _set?: InputMaybe<Psqi_Set_Input>;
  where: Psqi_Bool_Exp;
};

export type Mutation_RootUpdate_Psqi_By_PkArgs = {
  _inc?: InputMaybe<Psqi_Inc_Input>;
  _set?: InputMaybe<Psqi_Set_Input>;
  pk_columns: Psqi_Pk_Columns_Input;
};

export type Mutation_RootUpdate_RatingArgs = {
  _inc?: InputMaybe<Rating_Inc_Input>;
  _set?: InputMaybe<Rating_Set_Input>;
  where: Rating_Bool_Exp;
};

export type Mutation_RootUpdate_Rating_By_PkArgs = {
  _inc?: InputMaybe<Rating_Inc_Input>;
  _set?: InputMaybe<Rating_Set_Input>;
  pk_columns: Rating_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Recurrence_Model_EnumArgs = {
  _set?: InputMaybe<Recurrence_Model_Enum_Set_Input>;
  where: Recurrence_Model_Enum_Bool_Exp;
};

export type Mutation_RootUpdate_Recurrence_Model_Enum_By_PkArgs = {
  _set?: InputMaybe<Recurrence_Model_Enum_Set_Input>;
  pk_columns: Recurrence_Model_Enum_Pk_Columns_Input;
};

export type Mutation_RootUpdate_ReminderArgs = {
  _append?: InputMaybe<Reminder_Append_Input>;
  _delete_at_path?: InputMaybe<Reminder_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Reminder_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Reminder_Delete_Key_Input>;
  _inc?: InputMaybe<Reminder_Inc_Input>;
  _prepend?: InputMaybe<Reminder_Prepend_Input>;
  _set?: InputMaybe<Reminder_Set_Input>;
  where: Reminder_Bool_Exp;
};

export type Mutation_RootUpdate_Reminder_By_PkArgs = {
  _append?: InputMaybe<Reminder_Append_Input>;
  _delete_at_path?: InputMaybe<Reminder_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Reminder_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Reminder_Delete_Key_Input>;
  _inc?: InputMaybe<Reminder_Inc_Input>;
  _prepend?: InputMaybe<Reminder_Prepend_Input>;
  _set?: InputMaybe<Reminder_Set_Input>;
  pk_columns: Reminder_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Reminder_Type_EnumArgs = {
  _set?: InputMaybe<Reminder_Type_Enum_Set_Input>;
  where: Reminder_Type_Enum_Bool_Exp;
};

export type Mutation_RootUpdate_Reminder_Type_Enum_By_PkArgs = {
  _set?: InputMaybe<Reminder_Type_Enum_Set_Input>;
  pk_columns: Reminder_Type_Enum_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sleep_DiaryArgs = {
  _inc?: InputMaybe<Sleep_Diary_Inc_Input>;
  _set?: InputMaybe<Sleep_Diary_Set_Input>;
  where: Sleep_Diary_Bool_Exp;
};

export type Mutation_RootUpdate_Sleep_Diary_Apple_HealthArgs = {
  _append?: InputMaybe<Sleep_Diary_Apple_Health_Append_Input>;
  _delete_at_path?: InputMaybe<Sleep_Diary_Apple_Health_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Sleep_Diary_Apple_Health_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Sleep_Diary_Apple_Health_Delete_Key_Input>;
  _inc?: InputMaybe<Sleep_Diary_Apple_Health_Inc_Input>;
  _prepend?: InputMaybe<Sleep_Diary_Apple_Health_Prepend_Input>;
  _set?: InputMaybe<Sleep_Diary_Apple_Health_Set_Input>;
  where: Sleep_Diary_Apple_Health_Bool_Exp;
};

export type Mutation_RootUpdate_Sleep_Diary_Apple_Health_By_PkArgs = {
  _append?: InputMaybe<Sleep_Diary_Apple_Health_Append_Input>;
  _delete_at_path?: InputMaybe<Sleep_Diary_Apple_Health_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Sleep_Diary_Apple_Health_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Sleep_Diary_Apple_Health_Delete_Key_Input>;
  _inc?: InputMaybe<Sleep_Diary_Apple_Health_Inc_Input>;
  _prepend?: InputMaybe<Sleep_Diary_Apple_Health_Prepend_Input>;
  _set?: InputMaybe<Sleep_Diary_Apple_Health_Set_Input>;
  pk_columns: Sleep_Diary_Apple_Health_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sleep_Diary_By_PkArgs = {
  _inc?: InputMaybe<Sleep_Diary_Inc_Input>;
  _set?: InputMaybe<Sleep_Diary_Set_Input>;
  pk_columns: Sleep_Diary_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sleep_Diary_Google_FitArgs = {
  _append?: InputMaybe<Sleep_Diary_Google_Fit_Append_Input>;
  _delete_at_path?: InputMaybe<Sleep_Diary_Google_Fit_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Sleep_Diary_Google_Fit_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Sleep_Diary_Google_Fit_Delete_Key_Input>;
  _inc?: InputMaybe<Sleep_Diary_Google_Fit_Inc_Input>;
  _prepend?: InputMaybe<Sleep_Diary_Google_Fit_Prepend_Input>;
  _set?: InputMaybe<Sleep_Diary_Google_Fit_Set_Input>;
  where: Sleep_Diary_Google_Fit_Bool_Exp;
};

export type Mutation_RootUpdate_Sleep_Diary_Google_Fit_By_PkArgs = {
  _append?: InputMaybe<Sleep_Diary_Google_Fit_Append_Input>;
  _delete_at_path?: InputMaybe<Sleep_Diary_Google_Fit_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Sleep_Diary_Google_Fit_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Sleep_Diary_Google_Fit_Delete_Key_Input>;
  _inc?: InputMaybe<Sleep_Diary_Google_Fit_Inc_Input>;
  _prepend?: InputMaybe<Sleep_Diary_Google_Fit_Prepend_Input>;
  _set?: InputMaybe<Sleep_Diary_Google_Fit_Set_Input>;
  pk_columns: Sleep_Diary_Google_Fit_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sleep_Diary_IntervalArgs = {
  _inc?: InputMaybe<Sleep_Diary_Interval_Inc_Input>;
  _set?: InputMaybe<Sleep_Diary_Interval_Set_Input>;
  where: Sleep_Diary_Interval_Bool_Exp;
};

export type Mutation_RootUpdate_Sleep_Diary_Interval_By_PkArgs = {
  _inc?: InputMaybe<Sleep_Diary_Interval_Inc_Input>;
  _set?: InputMaybe<Sleep_Diary_Interval_Set_Input>;
  pk_columns: Sleep_Diary_Interval_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sleep_Diary_MedicineArgs = {
  _inc?: InputMaybe<Sleep_Diary_Medicine_Inc_Input>;
  _set?: InputMaybe<Sleep_Diary_Medicine_Set_Input>;
  where: Sleep_Diary_Medicine_Bool_Exp;
};

export type Mutation_RootUpdate_Sleep_Diary_Medicine_By_PkArgs = {
  _inc?: InputMaybe<Sleep_Diary_Medicine_Inc_Input>;
  _set?: InputMaybe<Sleep_Diary_Medicine_Set_Input>;
  pk_columns: Sleep_Diary_Medicine_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sleep_Diary_Sleep_TagArgs = {
  _inc?: InputMaybe<Sleep_Diary_Sleep_Tag_Inc_Input>;
  _set?: InputMaybe<Sleep_Diary_Sleep_Tag_Set_Input>;
  where: Sleep_Diary_Sleep_Tag_Bool_Exp;
};

export type Mutation_RootUpdate_Sleep_Diary_Sleep_Tag_By_PkArgs = {
  _inc?: InputMaybe<Sleep_Diary_Sleep_Tag_Inc_Input>;
  _set?: InputMaybe<Sleep_Diary_Sleep_Tag_Set_Input>;
  pk_columns: Sleep_Diary_Sleep_Tag_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sleep_RestrictionArgs = {
  _inc?: InputMaybe<Sleep_Restriction_Inc_Input>;
  _set?: InputMaybe<Sleep_Restriction_Set_Input>;
  where: Sleep_Restriction_Bool_Exp;
};

export type Mutation_RootUpdate_Sleep_Restriction_By_PkArgs = {
  _inc?: InputMaybe<Sleep_Restriction_Inc_Input>;
  _set?: InputMaybe<Sleep_Restriction_Set_Input>;
  pk_columns: Sleep_Restriction_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sleep_Tag_EnumArgs = {
  _set?: InputMaybe<Sleep_Tag_Enum_Set_Input>;
  where: Sleep_Tag_Enum_Bool_Exp;
};

export type Mutation_RootUpdate_Sleep_Tag_Enum_By_PkArgs = {
  _set?: InputMaybe<Sleep_Tag_Enum_Set_Input>;
  pk_columns: Sleep_Tag_Enum_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sleep_Tracker_Compressed_DataArgs = {
  _append?: InputMaybe<Sleep_Tracker_Compressed_Data_Append_Input>;
  _delete_at_path?: InputMaybe<Sleep_Tracker_Compressed_Data_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Sleep_Tracker_Compressed_Data_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Sleep_Tracker_Compressed_Data_Delete_Key_Input>;
  _inc?: InputMaybe<Sleep_Tracker_Compressed_Data_Inc_Input>;
  _prepend?: InputMaybe<Sleep_Tracker_Compressed_Data_Prepend_Input>;
  _set?: InputMaybe<Sleep_Tracker_Compressed_Data_Set_Input>;
  where: Sleep_Tracker_Compressed_Data_Bool_Exp;
};

export type Mutation_RootUpdate_Sleep_Tracker_Compressed_Data_By_PkArgs = {
  _append?: InputMaybe<Sleep_Tracker_Compressed_Data_Append_Input>;
  _delete_at_path?: InputMaybe<Sleep_Tracker_Compressed_Data_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Sleep_Tracker_Compressed_Data_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Sleep_Tracker_Compressed_Data_Delete_Key_Input>;
  _inc?: InputMaybe<Sleep_Tracker_Compressed_Data_Inc_Input>;
  _prepend?: InputMaybe<Sleep_Tracker_Compressed_Data_Prepend_Input>;
  _set?: InputMaybe<Sleep_Tracker_Compressed_Data_Set_Input>;
  pk_columns: Sleep_Tracker_Compressed_Data_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Stop_BangArgs = {
  _inc?: InputMaybe<Stop_Bang_Inc_Input>;
  _set?: InputMaybe<Stop_Bang_Set_Input>;
  where: Stop_Bang_Bool_Exp;
};

export type Mutation_RootUpdate_Stop_Bang_By_PkArgs = {
  _inc?: InputMaybe<Stop_Bang_Inc_Input>;
  _set?: InputMaybe<Stop_Bang_Set_Input>;
  pk_columns: Stop_Bang_Pk_Columns_Input;
};

export type Mutation_RootUpdate_SubscriptionArgs = {
  _inc?: InputMaybe<Subscription_Inc_Input>;
  _set?: InputMaybe<Subscription_Set_Input>;
  where: Subscription_Bool_Exp;
};

export type Mutation_RootUpdate_Subscription_By_PkArgs = {
  _inc?: InputMaybe<Subscription_Inc_Input>;
  _set?: InputMaybe<Subscription_Set_Input>;
  pk_columns: Subscription_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Technique_ResourceArgs = {
  _inc?: InputMaybe<Technique_Resource_Inc_Input>;
  _set?: InputMaybe<Technique_Resource_Set_Input>;
  where: Technique_Resource_Bool_Exp;
};

export type Mutation_RootUpdate_Technique_Resource_By_PkArgs = {
  _inc?: InputMaybe<Technique_Resource_Inc_Input>;
  _set?: InputMaybe<Technique_Resource_Set_Input>;
  pk_columns: Technique_Resource_Pk_Columns_Input;
};

export type Mutation_RootUpdate_ToolboxArgs = {
  _inc?: InputMaybe<Toolbox_Inc_Input>;
  _set?: InputMaybe<Toolbox_Set_Input>;
  where: Toolbox_Bool_Exp;
};

export type Mutation_RootUpdate_Toolbox_By_PkArgs = {
  _inc?: InputMaybe<Toolbox_Inc_Input>;
  _set?: InputMaybe<Toolbox_Set_Input>;
  pk_columns: Toolbox_Pk_Columns_Input;
};

export type Mutation_RootUpdate_UserArgs = {
  _inc?: InputMaybe<User_Inc_Input>;
  _set?: InputMaybe<User_Set_Input>;
  where: User_Bool_Exp;
};

export type Mutation_RootUpdate_User_By_PkArgs = {
  _inc?: InputMaybe<User_Inc_Input>;
  _set?: InputMaybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};

export type Mutation_RootUpdate_User_CheckoutArgs = {
  _append?: InputMaybe<User_Checkout_Append_Input>;
  _delete_at_path?: InputMaybe<User_Checkout_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Checkout_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Checkout_Delete_Key_Input>;
  _inc?: InputMaybe<User_Checkout_Inc_Input>;
  _prepend?: InputMaybe<User_Checkout_Prepend_Input>;
  _set?: InputMaybe<User_Checkout_Set_Input>;
  where: User_Checkout_Bool_Exp;
};

export type Mutation_RootUpdate_User_Checkout_By_PkArgs = {
  _append?: InputMaybe<User_Checkout_Append_Input>;
  _delete_at_path?: InputMaybe<User_Checkout_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Checkout_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Checkout_Delete_Key_Input>;
  _inc?: InputMaybe<User_Checkout_Inc_Input>;
  _prepend?: InputMaybe<User_Checkout_Prepend_Input>;
  _set?: InputMaybe<User_Checkout_Set_Input>;
  pk_columns: User_Checkout_Pk_Columns_Input;
};

export type Mutation_RootUpdate_User_CouponArgs = {
  _inc?: InputMaybe<User_Coupon_Inc_Input>;
  _set?: InputMaybe<User_Coupon_Set_Input>;
  where: User_Coupon_Bool_Exp;
};

export type Mutation_RootUpdate_User_Coupon_By_PkArgs = {
  _inc?: InputMaybe<User_Coupon_Inc_Input>;
  _set?: InputMaybe<User_Coupon_Set_Input>;
  pk_columns: User_Coupon_Pk_Columns_Input;
};

export type Mutation_RootUpdate_User_JourneyArgs = {
  _inc?: InputMaybe<User_Journey_Inc_Input>;
  _set?: InputMaybe<User_Journey_Set_Input>;
  where: User_Journey_Bool_Exp;
};

export type Mutation_RootUpdate_User_Journey_By_PkArgs = {
  _inc?: InputMaybe<User_Journey_Inc_Input>;
  _set?: InputMaybe<User_Journey_Set_Input>;
  pk_columns: User_Journey_Pk_Columns_Input;
};

export type Mutation_RootUpdate_User_Key_ValueArgs = {
  _set?: InputMaybe<User_Key_Value_Set_Input>;
  where: User_Key_Value_Bool_Exp;
};

export type Mutation_RootUpdate_User_Key_Value_By_PkArgs = {
  _set?: InputMaybe<User_Key_Value_Set_Input>;
  pk_columns: User_Key_Value_Pk_Columns_Input;
};

export type Mutation_RootUpdate_User_Key_Value_Key_EnumArgs = {
  _set?: InputMaybe<User_Key_Value_Key_Enum_Set_Input>;
  where: User_Key_Value_Key_Enum_Bool_Exp;
};

export type Mutation_RootUpdate_User_Key_Value_Key_Enum_By_PkArgs = {
  _set?: InputMaybe<User_Key_Value_Key_Enum_Set_Input>;
  pk_columns: User_Key_Value_Key_Enum_Pk_Columns_Input;
};

export type Mutation_RootUpdate_User_NotificationArgs = {
  _inc?: InputMaybe<User_Notification_Inc_Input>;
  _set?: InputMaybe<User_Notification_Set_Input>;
  where: User_Notification_Bool_Exp;
};

export type Mutation_RootUpdate_User_Notification_By_PkArgs = {
  _inc?: InputMaybe<User_Notification_Inc_Input>;
  _set?: InputMaybe<User_Notification_Set_Input>;
  pk_columns: User_Notification_Pk_Columns_Input;
};

export type Mutation_RootUpdate_User_Program_SessionArgs = {
  _inc?: InputMaybe<User_Program_Session_Inc_Input>;
  _set?: InputMaybe<User_Program_Session_Set_Input>;
  where: User_Program_Session_Bool_Exp;
};

export type Mutation_RootUpdate_User_Program_Session_By_PkArgs = {
  _inc?: InputMaybe<User_Program_Session_Inc_Input>;
  _set?: InputMaybe<User_Program_Session_Set_Input>;
  pk_columns: User_Program_Session_Pk_Columns_Input;
};

export type Mutation_RootUpdate_User_Program_Session_EnumArgs = {
  _set?: InputMaybe<User_Program_Session_Enum_Set_Input>;
  where: User_Program_Session_Enum_Bool_Exp;
};

export type Mutation_RootUpdate_User_Program_Session_Enum_By_PkArgs = {
  _set?: InputMaybe<User_Program_Session_Enum_Set_Input>;
  pk_columns: User_Program_Session_Enum_Pk_Columns_Input;
};

export type Mutation_RootUpdate_User_Reminder_InfoArgs = {
  _set?: InputMaybe<User_Reminder_Info_Set_Input>;
  where: User_Reminder_Info_Bool_Exp;
};

export type Mutation_RootUpdate_User_Reminder_Info_By_PkArgs = {
  _set?: InputMaybe<User_Reminder_Info_Set_Input>;
  pk_columns: User_Reminder_Info_Pk_Columns_Input;
};

export type Mutation_RootUpdate_User_TagArgs = {
  _inc?: InputMaybe<User_Tag_Inc_Input>;
  _set?: InputMaybe<User_Tag_Set_Input>;
  where: User_Tag_Bool_Exp;
};

export type Mutation_RootUpdate_User_Tag_By_PkArgs = {
  _inc?: InputMaybe<User_Tag_Inc_Input>;
  _set?: InputMaybe<User_Tag_Set_Input>;
  pk_columns: User_Tag_Pk_Columns_Input;
};

export type Mutation_RootUpdate_User_TelehealthArgs = {
  _inc?: InputMaybe<User_Telehealth_Inc_Input>;
  _set?: InputMaybe<User_Telehealth_Set_Input>;
  where: User_Telehealth_Bool_Exp;
};

export type Mutation_RootUpdate_User_Telehealth_By_PkArgs = {
  _inc?: InputMaybe<User_Telehealth_Inc_Input>;
  _set?: InputMaybe<User_Telehealth_Set_Input>;
  pk_columns: User_Telehealth_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Yes_No_Question_EnumArgs = {
  _set?: InputMaybe<Yes_No_Question_Enum_Set_Input>;
  where: Yes_No_Question_Enum_Bool_Exp;
};

export type Mutation_RootUpdate_Yes_No_Question_Enum_By_PkArgs = {
  _set?: InputMaybe<Yes_No_Question_Enum_Set_Input>;
  pk_columns: Yes_No_Question_Enum_Pk_Columns_Input;
};

export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

export enum Order_By {
  Asc = 'asc',
  AscNullsFirst = 'asc_nulls_first',
  AscNullsLast = 'asc_nulls_last',
  Desc = 'desc',
  DescNullsFirst = 'desc_nulls_first',
  DescNullsLast = 'desc_nulls_last',
}

export type Os_Enum = {
  __typename?: 'os_enum';
  comment: Scalars['String'];
  text: Scalars['String'];
};

export type Os_Enum_Aggregate = {
  __typename?: 'os_enum_aggregate';
  aggregate?: Maybe<Os_Enum_Aggregate_Fields>;
  nodes: Array<Os_Enum>;
};

export type Os_Enum_Aggregate_Fields = {
  __typename?: 'os_enum_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Os_Enum_Max_Fields>;
  min?: Maybe<Os_Enum_Min_Fields>;
};

export type Os_Enum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Os_Enum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Os_Enum_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Os_Enum_Max_Order_By>;
  min?: InputMaybe<Os_Enum_Min_Order_By>;
};

export type Os_Enum_Arr_Rel_Insert_Input = {
  data: Array<Os_Enum_Insert_Input>;
  on_conflict?: InputMaybe<Os_Enum_On_Conflict>;
};

export type Os_Enum_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Os_Enum_Bool_Exp>>>;
  _not?: InputMaybe<Os_Enum_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Os_Enum_Bool_Exp>>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
};

export enum Os_Enum_Constraint {
  OsEnumPkey = 'os_enum_pkey',
}

export enum Os_Enum_Enum {
  Android = 'android',
  Ios = 'ios',
}

export type Os_Enum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Os_Enum_Enum>;
  _in?: InputMaybe<Array<Os_Enum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Os_Enum_Enum>;
  _nin?: InputMaybe<Array<Os_Enum_Enum>>;
};

export type Os_Enum_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type Os_Enum_Max_Fields = {
  __typename?: 'os_enum_max_fields';
  comment?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type Os_Enum_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Os_Enum_Min_Fields = {
  __typename?: 'os_enum_min_fields';
  comment?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type Os_Enum_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Os_Enum_Mutation_Response = {
  __typename?: 'os_enum_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Os_Enum>;
};

export type Os_Enum_Obj_Rel_Insert_Input = {
  data: Os_Enum_Insert_Input;
  on_conflict?: InputMaybe<Os_Enum_On_Conflict>;
};

export type Os_Enum_On_Conflict = {
  constraint: Os_Enum_Constraint;
  update_columns: Array<Os_Enum_Update_Column>;
  where?: InputMaybe<Os_Enum_Bool_Exp>;
};

export type Os_Enum_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Os_Enum_Pk_Columns_Input = {
  text: Scalars['String'];
};

export enum Os_Enum_Select_Column {
  Comment = 'comment',
  Text = 'text',
}

export type Os_Enum_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export enum Os_Enum_Update_Column {
  Comment = 'comment',
  Text = 'text',
}

export type Parking_Lot = {
  __typename?: 'parking_lot';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  tasks: Scalars['String'];
  tasks_when: Scalars['String'];
  user_id: Scalars['String'];
};

export type Parking_Lot_Aggregate = {
  __typename?: 'parking_lot_aggregate';
  aggregate?: Maybe<Parking_Lot_Aggregate_Fields>;
  nodes: Array<Parking_Lot>;
};

export type Parking_Lot_Aggregate_Fields = {
  __typename?: 'parking_lot_aggregate_fields';
  avg?: Maybe<Parking_Lot_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Parking_Lot_Max_Fields>;
  min?: Maybe<Parking_Lot_Min_Fields>;
  stddev?: Maybe<Parking_Lot_Stddev_Fields>;
  stddev_pop?: Maybe<Parking_Lot_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Parking_Lot_Stddev_Samp_Fields>;
  sum?: Maybe<Parking_Lot_Sum_Fields>;
  var_pop?: Maybe<Parking_Lot_Var_Pop_Fields>;
  var_samp?: Maybe<Parking_Lot_Var_Samp_Fields>;
  variance?: Maybe<Parking_Lot_Variance_Fields>;
};

export type Parking_Lot_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Parking_Lot_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Parking_Lot_Aggregate_Order_By = {
  avg?: InputMaybe<Parking_Lot_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Parking_Lot_Max_Order_By>;
  min?: InputMaybe<Parking_Lot_Min_Order_By>;
  stddev?: InputMaybe<Parking_Lot_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Parking_Lot_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Parking_Lot_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Parking_Lot_Sum_Order_By>;
  var_pop?: InputMaybe<Parking_Lot_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Parking_Lot_Var_Samp_Order_By>;
  variance?: InputMaybe<Parking_Lot_Variance_Order_By>;
};

export type Parking_Lot_Arr_Rel_Insert_Input = {
  data: Array<Parking_Lot_Insert_Input>;
  on_conflict?: InputMaybe<Parking_Lot_On_Conflict>;
};

export type Parking_Lot_Avg_Fields = {
  __typename?: 'parking_lot_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Parking_Lot_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Parking_Lot_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Parking_Lot_Bool_Exp>>>;
  _not?: InputMaybe<Parking_Lot_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Parking_Lot_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  tasks?: InputMaybe<String_Comparison_Exp>;
  tasks_when?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

export enum Parking_Lot_Constraint {
  JobsPkey = 'jobs_pkey',
}

export type Parking_Lot_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

export type Parking_Lot_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  tasks?: InputMaybe<Scalars['String']>;
  tasks_when?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Parking_Lot_Max_Fields = {
  __typename?: 'parking_lot_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  tasks?: Maybe<Scalars['String']>;
  tasks_when?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Parking_Lot_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tasks?: InputMaybe<Order_By>;
  tasks_when?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Parking_Lot_Min_Fields = {
  __typename?: 'parking_lot_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  tasks?: Maybe<Scalars['String']>;
  tasks_when?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Parking_Lot_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tasks?: InputMaybe<Order_By>;
  tasks_when?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Parking_Lot_Mutation_Response = {
  __typename?: 'parking_lot_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Parking_Lot>;
};

export type Parking_Lot_Obj_Rel_Insert_Input = {
  data: Parking_Lot_Insert_Input;
  on_conflict?: InputMaybe<Parking_Lot_On_Conflict>;
};

export type Parking_Lot_On_Conflict = {
  constraint: Parking_Lot_Constraint;
  update_columns: Array<Parking_Lot_Update_Column>;
  where?: InputMaybe<Parking_Lot_Bool_Exp>;
};

export type Parking_Lot_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tasks?: InputMaybe<Order_By>;
  tasks_when?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Parking_Lot_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export enum Parking_Lot_Select_Column {
  CreatedAt = 'created_at',
  Id = 'id',
  Tasks = 'tasks',
  TasksWhen = 'tasks_when',
  UserId = 'user_id',
}

export type Parking_Lot_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  tasks?: InputMaybe<Scalars['String']>;
  tasks_when?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Parking_Lot_Stddev_Fields = {
  __typename?: 'parking_lot_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Parking_Lot_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Parking_Lot_Stddev_Pop_Fields = {
  __typename?: 'parking_lot_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Parking_Lot_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Parking_Lot_Stddev_Samp_Fields = {
  __typename?: 'parking_lot_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Parking_Lot_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Parking_Lot_Sum_Fields = {
  __typename?: 'parking_lot_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

export type Parking_Lot_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

export enum Parking_Lot_Update_Column {
  CreatedAt = 'created_at',
  Id = 'id',
  Tasks = 'tasks',
  TasksWhen = 'tasks_when',
  UserId = 'user_id',
}

export type Parking_Lot_Var_Pop_Fields = {
  __typename?: 'parking_lot_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Parking_Lot_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Parking_Lot_Var_Samp_Fields = {
  __typename?: 'parking_lot_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Parking_Lot_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Parking_Lot_Variance_Fields = {
  __typename?: 'parking_lot_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Parking_Lot_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Personality_Questionnaire = {
  __typename?: 'personality_questionnaire';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  q1: Scalars['smallint'];
  q2: Scalars['smallint'];
  q3: Scalars['smallint'];
  q4: Scalars['smallint'];
  q5: Scalars['smallint'];
  q6: Scalars['smallint'];
  q7: Scalars['smallint'];
  q8: Scalars['smallint'];
  q9: Scalars['smallint'];
  q10: Scalars['smallint'];
  q11: Scalars['smallint'];
  q12: Scalars['smallint'];
  q13: Scalars['smallint'];
  q14: Scalars['smallint'];
  q15: Scalars['smallint'];
  q16: Scalars['smallint'];
  q17: Scalars['smallint'];
  q18: Scalars['smallint'];
  q19: Scalars['smallint'];
  q20: Scalars['smallint'];
  updated_at: Scalars['timestamptz'];
  user: User;
  user_id: Scalars['String'];
};

export type Personality_Questionnaire_Aggregate = {
  __typename?: 'personality_questionnaire_aggregate';
  aggregate?: Maybe<Personality_Questionnaire_Aggregate_Fields>;
  nodes: Array<Personality_Questionnaire>;
};

export type Personality_Questionnaire_Aggregate_Fields = {
  __typename?: 'personality_questionnaire_aggregate_fields';
  avg?: Maybe<Personality_Questionnaire_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Personality_Questionnaire_Max_Fields>;
  min?: Maybe<Personality_Questionnaire_Min_Fields>;
  stddev?: Maybe<Personality_Questionnaire_Stddev_Fields>;
  stddev_pop?: Maybe<Personality_Questionnaire_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Personality_Questionnaire_Stddev_Samp_Fields>;
  sum?: Maybe<Personality_Questionnaire_Sum_Fields>;
  var_pop?: Maybe<Personality_Questionnaire_Var_Pop_Fields>;
  var_samp?: Maybe<Personality_Questionnaire_Var_Samp_Fields>;
  variance?: Maybe<Personality_Questionnaire_Variance_Fields>;
};

export type Personality_Questionnaire_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Personality_Questionnaire_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Personality_Questionnaire_Aggregate_Order_By = {
  avg?: InputMaybe<Personality_Questionnaire_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Personality_Questionnaire_Max_Order_By>;
  min?: InputMaybe<Personality_Questionnaire_Min_Order_By>;
  stddev?: InputMaybe<Personality_Questionnaire_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Personality_Questionnaire_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Personality_Questionnaire_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Personality_Questionnaire_Sum_Order_By>;
  var_pop?: InputMaybe<Personality_Questionnaire_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Personality_Questionnaire_Var_Samp_Order_By>;
  variance?: InputMaybe<Personality_Questionnaire_Variance_Order_By>;
};

export type Personality_Questionnaire_Arr_Rel_Insert_Input = {
  data: Array<Personality_Questionnaire_Insert_Input>;
  on_conflict?: InputMaybe<Personality_Questionnaire_On_Conflict>;
};

export type Personality_Questionnaire_Avg_Fields = {
  __typename?: 'personality_questionnaire_avg_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
  q10?: Maybe<Scalars['Float']>;
  q11?: Maybe<Scalars['Float']>;
  q12?: Maybe<Scalars['Float']>;
  q13?: Maybe<Scalars['Float']>;
  q14?: Maybe<Scalars['Float']>;
  q15?: Maybe<Scalars['Float']>;
  q16?: Maybe<Scalars['Float']>;
  q17?: Maybe<Scalars['Float']>;
  q18?: Maybe<Scalars['Float']>;
  q19?: Maybe<Scalars['Float']>;
  q20?: Maybe<Scalars['Float']>;
};

export type Personality_Questionnaire_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q12?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
  q14?: InputMaybe<Order_By>;
  q15?: InputMaybe<Order_By>;
  q16?: InputMaybe<Order_By>;
  q17?: InputMaybe<Order_By>;
  q18?: InputMaybe<Order_By>;
  q19?: InputMaybe<Order_By>;
  q20?: InputMaybe<Order_By>;
};

export type Personality_Questionnaire_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Personality_Questionnaire_Bool_Exp>>>;
  _not?: InputMaybe<Personality_Questionnaire_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Personality_Questionnaire_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  q1?: InputMaybe<Smallint_Comparison_Exp>;
  q2?: InputMaybe<Smallint_Comparison_Exp>;
  q3?: InputMaybe<Smallint_Comparison_Exp>;
  q4?: InputMaybe<Smallint_Comparison_Exp>;
  q5?: InputMaybe<Smallint_Comparison_Exp>;
  q6?: InputMaybe<Smallint_Comparison_Exp>;
  q7?: InputMaybe<Smallint_Comparison_Exp>;
  q8?: InputMaybe<Smallint_Comparison_Exp>;
  q9?: InputMaybe<Smallint_Comparison_Exp>;
  q10?: InputMaybe<Smallint_Comparison_Exp>;
  q11?: InputMaybe<Smallint_Comparison_Exp>;
  q12?: InputMaybe<Smallint_Comparison_Exp>;
  q13?: InputMaybe<Smallint_Comparison_Exp>;
  q14?: InputMaybe<Smallint_Comparison_Exp>;
  q15?: InputMaybe<Smallint_Comparison_Exp>;
  q16?: InputMaybe<Smallint_Comparison_Exp>;
  q17?: InputMaybe<Smallint_Comparison_Exp>;
  q18?: InputMaybe<Smallint_Comparison_Exp>;
  q19?: InputMaybe<Smallint_Comparison_Exp>;
  q20?: InputMaybe<Smallint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

export enum Personality_Questionnaire_Constraint {
  PersonalityQuestionnairePkey = 'personality_questionnaire_pkey',
}

export type Personality_Questionnaire_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['smallint']>;
  q2?: InputMaybe<Scalars['smallint']>;
  q3?: InputMaybe<Scalars['smallint']>;
  q4?: InputMaybe<Scalars['smallint']>;
  q5?: InputMaybe<Scalars['smallint']>;
  q6?: InputMaybe<Scalars['smallint']>;
  q7?: InputMaybe<Scalars['smallint']>;
  q8?: InputMaybe<Scalars['smallint']>;
  q9?: InputMaybe<Scalars['smallint']>;
  q10?: InputMaybe<Scalars['smallint']>;
  q11?: InputMaybe<Scalars['smallint']>;
  q12?: InputMaybe<Scalars['smallint']>;
  q13?: InputMaybe<Scalars['smallint']>;
  q14?: InputMaybe<Scalars['smallint']>;
  q15?: InputMaybe<Scalars['smallint']>;
  q16?: InputMaybe<Scalars['smallint']>;
  q17?: InputMaybe<Scalars['smallint']>;
  q18?: InputMaybe<Scalars['smallint']>;
  q19?: InputMaybe<Scalars['smallint']>;
  q20?: InputMaybe<Scalars['smallint']>;
};

export type Personality_Questionnaire_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['smallint']>;
  q2?: InputMaybe<Scalars['smallint']>;
  q3?: InputMaybe<Scalars['smallint']>;
  q4?: InputMaybe<Scalars['smallint']>;
  q5?: InputMaybe<Scalars['smallint']>;
  q6?: InputMaybe<Scalars['smallint']>;
  q7?: InputMaybe<Scalars['smallint']>;
  q8?: InputMaybe<Scalars['smallint']>;
  q9?: InputMaybe<Scalars['smallint']>;
  q10?: InputMaybe<Scalars['smallint']>;
  q11?: InputMaybe<Scalars['smallint']>;
  q12?: InputMaybe<Scalars['smallint']>;
  q13?: InputMaybe<Scalars['smallint']>;
  q14?: InputMaybe<Scalars['smallint']>;
  q15?: InputMaybe<Scalars['smallint']>;
  q16?: InputMaybe<Scalars['smallint']>;
  q17?: InputMaybe<Scalars['smallint']>;
  q18?: InputMaybe<Scalars['smallint']>;
  q19?: InputMaybe<Scalars['smallint']>;
  q20?: InputMaybe<Scalars['smallint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Personality_Questionnaire_Max_Fields = {
  __typename?: 'personality_questionnaire_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['smallint']>;
  q2?: Maybe<Scalars['smallint']>;
  q3?: Maybe<Scalars['smallint']>;
  q4?: Maybe<Scalars['smallint']>;
  q5?: Maybe<Scalars['smallint']>;
  q6?: Maybe<Scalars['smallint']>;
  q7?: Maybe<Scalars['smallint']>;
  q8?: Maybe<Scalars['smallint']>;
  q9?: Maybe<Scalars['smallint']>;
  q10?: Maybe<Scalars['smallint']>;
  q11?: Maybe<Scalars['smallint']>;
  q12?: Maybe<Scalars['smallint']>;
  q13?: Maybe<Scalars['smallint']>;
  q14?: Maybe<Scalars['smallint']>;
  q15?: Maybe<Scalars['smallint']>;
  q16?: Maybe<Scalars['smallint']>;
  q17?: Maybe<Scalars['smallint']>;
  q18?: Maybe<Scalars['smallint']>;
  q19?: Maybe<Scalars['smallint']>;
  q20?: Maybe<Scalars['smallint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Personality_Questionnaire_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q12?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
  q14?: InputMaybe<Order_By>;
  q15?: InputMaybe<Order_By>;
  q16?: InputMaybe<Order_By>;
  q17?: InputMaybe<Order_By>;
  q18?: InputMaybe<Order_By>;
  q19?: InputMaybe<Order_By>;
  q20?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Personality_Questionnaire_Min_Fields = {
  __typename?: 'personality_questionnaire_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['smallint']>;
  q2?: Maybe<Scalars['smallint']>;
  q3?: Maybe<Scalars['smallint']>;
  q4?: Maybe<Scalars['smallint']>;
  q5?: Maybe<Scalars['smallint']>;
  q6?: Maybe<Scalars['smallint']>;
  q7?: Maybe<Scalars['smallint']>;
  q8?: Maybe<Scalars['smallint']>;
  q9?: Maybe<Scalars['smallint']>;
  q10?: Maybe<Scalars['smallint']>;
  q11?: Maybe<Scalars['smallint']>;
  q12?: Maybe<Scalars['smallint']>;
  q13?: Maybe<Scalars['smallint']>;
  q14?: Maybe<Scalars['smallint']>;
  q15?: Maybe<Scalars['smallint']>;
  q16?: Maybe<Scalars['smallint']>;
  q17?: Maybe<Scalars['smallint']>;
  q18?: Maybe<Scalars['smallint']>;
  q19?: Maybe<Scalars['smallint']>;
  q20?: Maybe<Scalars['smallint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Personality_Questionnaire_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q12?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
  q14?: InputMaybe<Order_By>;
  q15?: InputMaybe<Order_By>;
  q16?: InputMaybe<Order_By>;
  q17?: InputMaybe<Order_By>;
  q18?: InputMaybe<Order_By>;
  q19?: InputMaybe<Order_By>;
  q20?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Personality_Questionnaire_Mutation_Response = {
  __typename?: 'personality_questionnaire_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Personality_Questionnaire>;
};

export type Personality_Questionnaire_Obj_Rel_Insert_Input = {
  data: Personality_Questionnaire_Insert_Input;
  on_conflict?: InputMaybe<Personality_Questionnaire_On_Conflict>;
};

export type Personality_Questionnaire_On_Conflict = {
  constraint: Personality_Questionnaire_Constraint;
  update_columns: Array<Personality_Questionnaire_Update_Column>;
  where?: InputMaybe<Personality_Questionnaire_Bool_Exp>;
};

export type Personality_Questionnaire_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q12?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
  q14?: InputMaybe<Order_By>;
  q15?: InputMaybe<Order_By>;
  q16?: InputMaybe<Order_By>;
  q17?: InputMaybe<Order_By>;
  q18?: InputMaybe<Order_By>;
  q19?: InputMaybe<Order_By>;
  q20?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Personality_Questionnaire_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export enum Personality_Questionnaire_Select_Column {
  CreatedAt = 'created_at',
  Id = 'id',
  Q1 = 'q1',
  Q2 = 'q2',
  Q3 = 'q3',
  Q4 = 'q4',
  Q5 = 'q5',
  Q6 = 'q6',
  Q7 = 'q7',
  Q8 = 'q8',
  Q9 = 'q9',
  Q10 = 'q10',
  Q11 = 'q11',
  Q12 = 'q12',
  Q13 = 'q13',
  Q14 = 'q14',
  Q15 = 'q15',
  Q16 = 'q16',
  Q17 = 'q17',
  Q18 = 'q18',
  Q19 = 'q19',
  Q20 = 'q20',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Personality_Questionnaire_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['smallint']>;
  q2?: InputMaybe<Scalars['smallint']>;
  q3?: InputMaybe<Scalars['smallint']>;
  q4?: InputMaybe<Scalars['smallint']>;
  q5?: InputMaybe<Scalars['smallint']>;
  q6?: InputMaybe<Scalars['smallint']>;
  q7?: InputMaybe<Scalars['smallint']>;
  q8?: InputMaybe<Scalars['smallint']>;
  q9?: InputMaybe<Scalars['smallint']>;
  q10?: InputMaybe<Scalars['smallint']>;
  q11?: InputMaybe<Scalars['smallint']>;
  q12?: InputMaybe<Scalars['smallint']>;
  q13?: InputMaybe<Scalars['smallint']>;
  q14?: InputMaybe<Scalars['smallint']>;
  q15?: InputMaybe<Scalars['smallint']>;
  q16?: InputMaybe<Scalars['smallint']>;
  q17?: InputMaybe<Scalars['smallint']>;
  q18?: InputMaybe<Scalars['smallint']>;
  q19?: InputMaybe<Scalars['smallint']>;
  q20?: InputMaybe<Scalars['smallint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Personality_Questionnaire_Stddev_Fields = {
  __typename?: 'personality_questionnaire_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
  q10?: Maybe<Scalars['Float']>;
  q11?: Maybe<Scalars['Float']>;
  q12?: Maybe<Scalars['Float']>;
  q13?: Maybe<Scalars['Float']>;
  q14?: Maybe<Scalars['Float']>;
  q15?: Maybe<Scalars['Float']>;
  q16?: Maybe<Scalars['Float']>;
  q17?: Maybe<Scalars['Float']>;
  q18?: Maybe<Scalars['Float']>;
  q19?: Maybe<Scalars['Float']>;
  q20?: Maybe<Scalars['Float']>;
};

export type Personality_Questionnaire_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q12?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
  q14?: InputMaybe<Order_By>;
  q15?: InputMaybe<Order_By>;
  q16?: InputMaybe<Order_By>;
  q17?: InputMaybe<Order_By>;
  q18?: InputMaybe<Order_By>;
  q19?: InputMaybe<Order_By>;
  q20?: InputMaybe<Order_By>;
};

export type Personality_Questionnaire_Stddev_Pop_Fields = {
  __typename?: 'personality_questionnaire_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
  q10?: Maybe<Scalars['Float']>;
  q11?: Maybe<Scalars['Float']>;
  q12?: Maybe<Scalars['Float']>;
  q13?: Maybe<Scalars['Float']>;
  q14?: Maybe<Scalars['Float']>;
  q15?: Maybe<Scalars['Float']>;
  q16?: Maybe<Scalars['Float']>;
  q17?: Maybe<Scalars['Float']>;
  q18?: Maybe<Scalars['Float']>;
  q19?: Maybe<Scalars['Float']>;
  q20?: Maybe<Scalars['Float']>;
};

export type Personality_Questionnaire_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q12?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
  q14?: InputMaybe<Order_By>;
  q15?: InputMaybe<Order_By>;
  q16?: InputMaybe<Order_By>;
  q17?: InputMaybe<Order_By>;
  q18?: InputMaybe<Order_By>;
  q19?: InputMaybe<Order_By>;
  q20?: InputMaybe<Order_By>;
};

export type Personality_Questionnaire_Stddev_Samp_Fields = {
  __typename?: 'personality_questionnaire_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
  q10?: Maybe<Scalars['Float']>;
  q11?: Maybe<Scalars['Float']>;
  q12?: Maybe<Scalars['Float']>;
  q13?: Maybe<Scalars['Float']>;
  q14?: Maybe<Scalars['Float']>;
  q15?: Maybe<Scalars['Float']>;
  q16?: Maybe<Scalars['Float']>;
  q17?: Maybe<Scalars['Float']>;
  q18?: Maybe<Scalars['Float']>;
  q19?: Maybe<Scalars['Float']>;
  q20?: Maybe<Scalars['Float']>;
};

export type Personality_Questionnaire_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q12?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
  q14?: InputMaybe<Order_By>;
  q15?: InputMaybe<Order_By>;
  q16?: InputMaybe<Order_By>;
  q17?: InputMaybe<Order_By>;
  q18?: InputMaybe<Order_By>;
  q19?: InputMaybe<Order_By>;
  q20?: InputMaybe<Order_By>;
};

export type Personality_Questionnaire_Sum_Fields = {
  __typename?: 'personality_questionnaire_sum_fields';
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['smallint']>;
  q2?: Maybe<Scalars['smallint']>;
  q3?: Maybe<Scalars['smallint']>;
  q4?: Maybe<Scalars['smallint']>;
  q5?: Maybe<Scalars['smallint']>;
  q6?: Maybe<Scalars['smallint']>;
  q7?: Maybe<Scalars['smallint']>;
  q8?: Maybe<Scalars['smallint']>;
  q9?: Maybe<Scalars['smallint']>;
  q10?: Maybe<Scalars['smallint']>;
  q11?: Maybe<Scalars['smallint']>;
  q12?: Maybe<Scalars['smallint']>;
  q13?: Maybe<Scalars['smallint']>;
  q14?: Maybe<Scalars['smallint']>;
  q15?: Maybe<Scalars['smallint']>;
  q16?: Maybe<Scalars['smallint']>;
  q17?: Maybe<Scalars['smallint']>;
  q18?: Maybe<Scalars['smallint']>;
  q19?: Maybe<Scalars['smallint']>;
  q20?: Maybe<Scalars['smallint']>;
};

export type Personality_Questionnaire_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q12?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
  q14?: InputMaybe<Order_By>;
  q15?: InputMaybe<Order_By>;
  q16?: InputMaybe<Order_By>;
  q17?: InputMaybe<Order_By>;
  q18?: InputMaybe<Order_By>;
  q19?: InputMaybe<Order_By>;
  q20?: InputMaybe<Order_By>;
};

export enum Personality_Questionnaire_Update_Column {
  CreatedAt = 'created_at',
  Id = 'id',
  Q1 = 'q1',
  Q2 = 'q2',
  Q3 = 'q3',
  Q4 = 'q4',
  Q5 = 'q5',
  Q6 = 'q6',
  Q7 = 'q7',
  Q8 = 'q8',
  Q9 = 'q9',
  Q10 = 'q10',
  Q11 = 'q11',
  Q12 = 'q12',
  Q13 = 'q13',
  Q14 = 'q14',
  Q15 = 'q15',
  Q16 = 'q16',
  Q17 = 'q17',
  Q18 = 'q18',
  Q19 = 'q19',
  Q20 = 'q20',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Personality_Questionnaire_Var_Pop_Fields = {
  __typename?: 'personality_questionnaire_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
  q10?: Maybe<Scalars['Float']>;
  q11?: Maybe<Scalars['Float']>;
  q12?: Maybe<Scalars['Float']>;
  q13?: Maybe<Scalars['Float']>;
  q14?: Maybe<Scalars['Float']>;
  q15?: Maybe<Scalars['Float']>;
  q16?: Maybe<Scalars['Float']>;
  q17?: Maybe<Scalars['Float']>;
  q18?: Maybe<Scalars['Float']>;
  q19?: Maybe<Scalars['Float']>;
  q20?: Maybe<Scalars['Float']>;
};

export type Personality_Questionnaire_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q12?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
  q14?: InputMaybe<Order_By>;
  q15?: InputMaybe<Order_By>;
  q16?: InputMaybe<Order_By>;
  q17?: InputMaybe<Order_By>;
  q18?: InputMaybe<Order_By>;
  q19?: InputMaybe<Order_By>;
  q20?: InputMaybe<Order_By>;
};

export type Personality_Questionnaire_Var_Samp_Fields = {
  __typename?: 'personality_questionnaire_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
  q10?: Maybe<Scalars['Float']>;
  q11?: Maybe<Scalars['Float']>;
  q12?: Maybe<Scalars['Float']>;
  q13?: Maybe<Scalars['Float']>;
  q14?: Maybe<Scalars['Float']>;
  q15?: Maybe<Scalars['Float']>;
  q16?: Maybe<Scalars['Float']>;
  q17?: Maybe<Scalars['Float']>;
  q18?: Maybe<Scalars['Float']>;
  q19?: Maybe<Scalars['Float']>;
  q20?: Maybe<Scalars['Float']>;
};

export type Personality_Questionnaire_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q12?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
  q14?: InputMaybe<Order_By>;
  q15?: InputMaybe<Order_By>;
  q16?: InputMaybe<Order_By>;
  q17?: InputMaybe<Order_By>;
  q18?: InputMaybe<Order_By>;
  q19?: InputMaybe<Order_By>;
  q20?: InputMaybe<Order_By>;
};

export type Personality_Questionnaire_Variance_Fields = {
  __typename?: 'personality_questionnaire_variance_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
  q10?: Maybe<Scalars['Float']>;
  q11?: Maybe<Scalars['Float']>;
  q12?: Maybe<Scalars['Float']>;
  q13?: Maybe<Scalars['Float']>;
  q14?: Maybe<Scalars['Float']>;
  q15?: Maybe<Scalars['Float']>;
  q16?: Maybe<Scalars['Float']>;
  q17?: Maybe<Scalars['Float']>;
  q18?: Maybe<Scalars['Float']>;
  q19?: Maybe<Scalars['Float']>;
  q20?: Maybe<Scalars['Float']>;
};

export type Personality_Questionnaire_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  q10?: InputMaybe<Order_By>;
  q11?: InputMaybe<Order_By>;
  q12?: InputMaybe<Order_By>;
  q13?: InputMaybe<Order_By>;
  q14?: InputMaybe<Order_By>;
  q15?: InputMaybe<Order_By>;
  q16?: InputMaybe<Order_By>;
  q17?: InputMaybe<Order_By>;
  q18?: InputMaybe<Order_By>;
  q19?: InputMaybe<Order_By>;
  q20?: InputMaybe<Order_By>;
};

export type Phq = {
  __typename?: 'phq';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  q1: Scalars['Int'];
  q2: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  user: User;
  user_id: Scalars['String'];
};

export type Phq9 = {
  __typename?: 'phq9';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  q1: Scalars['Int'];
  q2: Scalars['Int'];
  q3: Scalars['Int'];
  q4: Scalars['Int'];
  q5: Scalars['Int'];
  q6: Scalars['Int'];
  q7: Scalars['Int'];
  q8: Scalars['Int'];
  q9: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  user: User;
  user_id: Scalars['String'];
};

export type Phq9_Aggregate = {
  __typename?: 'phq9_aggregate';
  aggregate?: Maybe<Phq9_Aggregate_Fields>;
  nodes: Array<Phq9>;
};

export type Phq9_Aggregate_Fields = {
  __typename?: 'phq9_aggregate_fields';
  avg?: Maybe<Phq9_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Phq9_Max_Fields>;
  min?: Maybe<Phq9_Min_Fields>;
  stddev?: Maybe<Phq9_Stddev_Fields>;
  stddev_pop?: Maybe<Phq9_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Phq9_Stddev_Samp_Fields>;
  sum?: Maybe<Phq9_Sum_Fields>;
  var_pop?: Maybe<Phq9_Var_Pop_Fields>;
  var_samp?: Maybe<Phq9_Var_Samp_Fields>;
  variance?: Maybe<Phq9_Variance_Fields>;
};

export type Phq9_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Phq9_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Phq9_Aggregate_Order_By = {
  avg?: InputMaybe<Phq9_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Phq9_Max_Order_By>;
  min?: InputMaybe<Phq9_Min_Order_By>;
  stddev?: InputMaybe<Phq9_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Phq9_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Phq9_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Phq9_Sum_Order_By>;
  var_pop?: InputMaybe<Phq9_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Phq9_Var_Samp_Order_By>;
  variance?: InputMaybe<Phq9_Variance_Order_By>;
};

export type Phq9_Arr_Rel_Insert_Input = {
  data: Array<Phq9_Insert_Input>;
  on_conflict?: InputMaybe<Phq9_On_Conflict>;
};

export type Phq9_Avg_Fields = {
  __typename?: 'phq9_avg_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
};

export type Phq9_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
};

export type Phq9_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Phq9_Bool_Exp>>>;
  _not?: InputMaybe<Phq9_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Phq9_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  q1?: InputMaybe<Int_Comparison_Exp>;
  q2?: InputMaybe<Int_Comparison_Exp>;
  q3?: InputMaybe<Int_Comparison_Exp>;
  q4?: InputMaybe<Int_Comparison_Exp>;
  q5?: InputMaybe<Int_Comparison_Exp>;
  q6?: InputMaybe<Int_Comparison_Exp>;
  q7?: InputMaybe<Int_Comparison_Exp>;
  q8?: InputMaybe<Int_Comparison_Exp>;
  q9?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

export enum Phq9_Constraint {
  Phq9Pkey = 'phq9_pkey',
}

export type Phq9_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['Int']>;
  q2?: InputMaybe<Scalars['Int']>;
  q3?: InputMaybe<Scalars['Int']>;
  q4?: InputMaybe<Scalars['Int']>;
  q5?: InputMaybe<Scalars['Int']>;
  q6?: InputMaybe<Scalars['Int']>;
  q7?: InputMaybe<Scalars['Int']>;
  q8?: InputMaybe<Scalars['Int']>;
  q9?: InputMaybe<Scalars['Int']>;
};

export type Phq9_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['Int']>;
  q2?: InputMaybe<Scalars['Int']>;
  q3?: InputMaybe<Scalars['Int']>;
  q4?: InputMaybe<Scalars['Int']>;
  q5?: InputMaybe<Scalars['Int']>;
  q6?: InputMaybe<Scalars['Int']>;
  q7?: InputMaybe<Scalars['Int']>;
  q8?: InputMaybe<Scalars['Int']>;
  q9?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Phq9_Max_Fields = {
  __typename?: 'phq9_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['Int']>;
  q2?: Maybe<Scalars['Int']>;
  q3?: Maybe<Scalars['Int']>;
  q4?: Maybe<Scalars['Int']>;
  q5?: Maybe<Scalars['Int']>;
  q6?: Maybe<Scalars['Int']>;
  q7?: Maybe<Scalars['Int']>;
  q8?: Maybe<Scalars['Int']>;
  q9?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Phq9_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Phq9_Min_Fields = {
  __typename?: 'phq9_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['Int']>;
  q2?: Maybe<Scalars['Int']>;
  q3?: Maybe<Scalars['Int']>;
  q4?: Maybe<Scalars['Int']>;
  q5?: Maybe<Scalars['Int']>;
  q6?: Maybe<Scalars['Int']>;
  q7?: Maybe<Scalars['Int']>;
  q8?: Maybe<Scalars['Int']>;
  q9?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Phq9_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Phq9_Mutation_Response = {
  __typename?: 'phq9_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Phq9>;
};

export type Phq9_Obj_Rel_Insert_Input = {
  data: Phq9_Insert_Input;
  on_conflict?: InputMaybe<Phq9_On_Conflict>;
};

export type Phq9_On_Conflict = {
  constraint: Phq9_Constraint;
  update_columns: Array<Phq9_Update_Column>;
  where?: InputMaybe<Phq9_Bool_Exp>;
};

export type Phq9_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Phq9_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export enum Phq9_Select_Column {
  CreatedAt = 'created_at',
  Id = 'id',
  Q1 = 'q1',
  Q2 = 'q2',
  Q3 = 'q3',
  Q4 = 'q4',
  Q5 = 'q5',
  Q6 = 'q6',
  Q7 = 'q7',
  Q8 = 'q8',
  Q9 = 'q9',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Phq9_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['Int']>;
  q2?: InputMaybe<Scalars['Int']>;
  q3?: InputMaybe<Scalars['Int']>;
  q4?: InputMaybe<Scalars['Int']>;
  q5?: InputMaybe<Scalars['Int']>;
  q6?: InputMaybe<Scalars['Int']>;
  q7?: InputMaybe<Scalars['Int']>;
  q8?: InputMaybe<Scalars['Int']>;
  q9?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Phq9_Stddev_Fields = {
  __typename?: 'phq9_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
};

export type Phq9_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
};

export type Phq9_Stddev_Pop_Fields = {
  __typename?: 'phq9_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
};

export type Phq9_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
};

export type Phq9_Stddev_Samp_Fields = {
  __typename?: 'phq9_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
};

export type Phq9_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
};

export type Phq9_Sum_Fields = {
  __typename?: 'phq9_sum_fields';
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['Int']>;
  q2?: Maybe<Scalars['Int']>;
  q3?: Maybe<Scalars['Int']>;
  q4?: Maybe<Scalars['Int']>;
  q5?: Maybe<Scalars['Int']>;
  q6?: Maybe<Scalars['Int']>;
  q7?: Maybe<Scalars['Int']>;
  q8?: Maybe<Scalars['Int']>;
  q9?: Maybe<Scalars['Int']>;
};

export type Phq9_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
};

export enum Phq9_Update_Column {
  CreatedAt = 'created_at',
  Id = 'id',
  Q1 = 'q1',
  Q2 = 'q2',
  Q3 = 'q3',
  Q4 = 'q4',
  Q5 = 'q5',
  Q6 = 'q6',
  Q7 = 'q7',
  Q8 = 'q8',
  Q9 = 'q9',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Phq9_Var_Pop_Fields = {
  __typename?: 'phq9_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
};

export type Phq9_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
};

export type Phq9_Var_Samp_Fields = {
  __typename?: 'phq9_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
};

export type Phq9_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
};

export type Phq9_Variance_Fields = {
  __typename?: 'phq9_variance_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
};

export type Phq9_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
};

export type Phq_Aggregate = {
  __typename?: 'phq_aggregate';
  aggregate?: Maybe<Phq_Aggregate_Fields>;
  nodes: Array<Phq>;
};

export type Phq_Aggregate_Fields = {
  __typename?: 'phq_aggregate_fields';
  avg?: Maybe<Phq_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Phq_Max_Fields>;
  min?: Maybe<Phq_Min_Fields>;
  stddev?: Maybe<Phq_Stddev_Fields>;
  stddev_pop?: Maybe<Phq_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Phq_Stddev_Samp_Fields>;
  sum?: Maybe<Phq_Sum_Fields>;
  var_pop?: Maybe<Phq_Var_Pop_Fields>;
  var_samp?: Maybe<Phq_Var_Samp_Fields>;
  variance?: Maybe<Phq_Variance_Fields>;
};

export type Phq_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Phq_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Phq_Aggregate_Order_By = {
  avg?: InputMaybe<Phq_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Phq_Max_Order_By>;
  min?: InputMaybe<Phq_Min_Order_By>;
  stddev?: InputMaybe<Phq_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Phq_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Phq_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Phq_Sum_Order_By>;
  var_pop?: InputMaybe<Phq_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Phq_Var_Samp_Order_By>;
  variance?: InputMaybe<Phq_Variance_Order_By>;
};

export type Phq_Arr_Rel_Insert_Input = {
  data: Array<Phq_Insert_Input>;
  on_conflict?: InputMaybe<Phq_On_Conflict>;
};

export type Phq_Avg_Fields = {
  __typename?: 'phq_avg_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
};

export type Phq_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
};

export type Phq_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Phq_Bool_Exp>>>;
  _not?: InputMaybe<Phq_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Phq_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  q1?: InputMaybe<Int_Comparison_Exp>;
  q2?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

export enum Phq_Constraint {
  PhqPkey = 'phq_pkey',
}

export type Phq_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['Int']>;
  q2?: InputMaybe<Scalars['Int']>;
};

export type Phq_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['Int']>;
  q2?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Phq_Max_Fields = {
  __typename?: 'phq_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['Int']>;
  q2?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Phq_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Phq_Min_Fields = {
  __typename?: 'phq_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['Int']>;
  q2?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Phq_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Phq_Mutation_Response = {
  __typename?: 'phq_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Phq>;
};

export type Phq_Obj_Rel_Insert_Input = {
  data: Phq_Insert_Input;
  on_conflict?: InputMaybe<Phq_On_Conflict>;
};

export type Phq_On_Conflict = {
  constraint: Phq_Constraint;
  update_columns: Array<Phq_Update_Column>;
  where?: InputMaybe<Phq_Bool_Exp>;
};

export type Phq_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Phq_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export enum Phq_Select_Column {
  CreatedAt = 'created_at',
  Id = 'id',
  Q1 = 'q1',
  Q2 = 'q2',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Phq_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['Int']>;
  q2?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Phq_Stddev_Fields = {
  __typename?: 'phq_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
};

export type Phq_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
};

export type Phq_Stddev_Pop_Fields = {
  __typename?: 'phq_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
};

export type Phq_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
};

export type Phq_Stddev_Samp_Fields = {
  __typename?: 'phq_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
};

export type Phq_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
};

export type Phq_Sum_Fields = {
  __typename?: 'phq_sum_fields';
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['Int']>;
  q2?: Maybe<Scalars['Int']>;
};

export type Phq_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
};

export enum Phq_Update_Column {
  CreatedAt = 'created_at',
  Id = 'id',
  Q1 = 'q1',
  Q2 = 'q2',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Phq_Var_Pop_Fields = {
  __typename?: 'phq_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
};

export type Phq_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
};

export type Phq_Var_Samp_Fields = {
  __typename?: 'phq_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
};

export type Phq_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
};

export type Phq_Variance_Fields = {
  __typename?: 'phq_variance_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
};

export type Phq_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
};

export type Pre_User = {
  __typename?: 'pre_user';
  alt_email?: Maybe<Scalars['String']>;
  appointments: Array<Appointment>;
  appointments_aggregate: Appointment_Aggregate;
  birthdate?: Maybe<Scalars['date']>;
  company?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  last_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  source: Pre_User_Source_Enum_Enum;
  status: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  user_id?: Maybe<Scalars['String']>;
  user_telehealth?: Maybe<User_Telehealth>;
};

export type Pre_UserAppointmentsArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Order_By>>;
  where?: InputMaybe<Appointment_Bool_Exp>;
};

export type Pre_UserAppointments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Order_By>>;
  where?: InputMaybe<Appointment_Bool_Exp>;
};

export type Pre_User_Aggregate = {
  __typename?: 'pre_user_aggregate';
  aggregate?: Maybe<Pre_User_Aggregate_Fields>;
  nodes: Array<Pre_User>;
};

export type Pre_User_Aggregate_Fields = {
  __typename?: 'pre_user_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Pre_User_Max_Fields>;
  min?: Maybe<Pre_User_Min_Fields>;
};

export type Pre_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Pre_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Pre_User_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Pre_User_Max_Order_By>;
  min?: InputMaybe<Pre_User_Min_Order_By>;
};

export type Pre_User_Arr_Rel_Insert_Input = {
  data: Array<Pre_User_Insert_Input>;
  on_conflict?: InputMaybe<Pre_User_On_Conflict>;
};

export type Pre_User_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Pre_User_Bool_Exp>>>;
  _not?: InputMaybe<Pre_User_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Pre_User_Bool_Exp>>>;
  alt_email?: InputMaybe<String_Comparison_Exp>;
  appointments?: InputMaybe<Appointment_Bool_Exp>;
  birthdate?: InputMaybe<Date_Comparison_Exp>;
  company?: InputMaybe<String_Comparison_Exp>;
  cpf?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  gender?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  source?: InputMaybe<Pre_User_Source_Enum_Enum_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  user_telehealth?: InputMaybe<User_Telehealth_Bool_Exp>;
};

export enum Pre_User_Constraint {
  PreUserPkey = 'pre_user_pkey',
}

export type Pre_User_Insert_Input = {
  alt_email?: InputMaybe<Scalars['String']>;
  appointments?: InputMaybe<Appointment_Arr_Rel_Insert_Input>;
  birthdate?: InputMaybe<Scalars['date']>;
  company?: InputMaybe<Scalars['String']>;
  cpf?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Pre_User_Source_Enum_Enum>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
  user_telehealth?: InputMaybe<User_Telehealth_Obj_Rel_Insert_Input>;
};

export type Pre_User_Max_Fields = {
  __typename?: 'pre_user_max_fields';
  alt_email?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['date']>;
  company?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Pre_User_Max_Order_By = {
  alt_email?: InputMaybe<Order_By>;
  birthdate?: InputMaybe<Order_By>;
  company?: InputMaybe<Order_By>;
  cpf?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Pre_User_Min_Fields = {
  __typename?: 'pre_user_min_fields';
  alt_email?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['date']>;
  company?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Pre_User_Min_Order_By = {
  alt_email?: InputMaybe<Order_By>;
  birthdate?: InputMaybe<Order_By>;
  company?: InputMaybe<Order_By>;
  cpf?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Pre_User_Mutation_Response = {
  __typename?: 'pre_user_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Pre_User>;
};

export type Pre_User_Obj_Rel_Insert_Input = {
  data: Pre_User_Insert_Input;
  on_conflict?: InputMaybe<Pre_User_On_Conflict>;
};

export type Pre_User_On_Conflict = {
  constraint: Pre_User_Constraint;
  update_columns: Array<Pre_User_Update_Column>;
  where?: InputMaybe<Pre_User_Bool_Exp>;
};

export type Pre_User_Order_By = {
  alt_email?: InputMaybe<Order_By>;
  appointments_aggregate?: InputMaybe<Appointment_Aggregate_Order_By>;
  birthdate?: InputMaybe<Order_By>;
  company?: InputMaybe<Order_By>;
  cpf?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_telehealth?: InputMaybe<User_Telehealth_Order_By>;
};

export type Pre_User_Pk_Columns_Input = {
  id: Scalars['String'];
};

export enum Pre_User_Select_Column {
  AltEmail = 'alt_email',
  Birthdate = 'birthdate',
  Company = 'company',
  Cpf = 'cpf',
  CreatedAt = 'created_at',
  Email = 'email',
  FirstName = 'first_name',
  Gender = 'gender',
  Id = 'id',
  LastName = 'last_name',
  Phone = 'phone',
  Source = 'source',
  Status = 'status',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Pre_User_Set_Input = {
  alt_email?: InputMaybe<Scalars['String']>;
  birthdate?: InputMaybe<Scalars['date']>;
  company?: InputMaybe<Scalars['String']>;
  cpf?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Pre_User_Source_Enum_Enum>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Pre_User_Source_Enum = {
  __typename?: 'pre_user_source_enum';
  comment: Scalars['String'];
  text: Scalars['String'];
};

export type Pre_User_Source_Enum_Aggregate = {
  __typename?: 'pre_user_source_enum_aggregate';
  aggregate?: Maybe<Pre_User_Source_Enum_Aggregate_Fields>;
  nodes: Array<Pre_User_Source_Enum>;
};

export type Pre_User_Source_Enum_Aggregate_Fields = {
  __typename?: 'pre_user_source_enum_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Pre_User_Source_Enum_Max_Fields>;
  min?: Maybe<Pre_User_Source_Enum_Min_Fields>;
};

export type Pre_User_Source_Enum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Pre_User_Source_Enum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Pre_User_Source_Enum_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Pre_User_Source_Enum_Max_Order_By>;
  min?: InputMaybe<Pre_User_Source_Enum_Min_Order_By>;
};

export type Pre_User_Source_Enum_Arr_Rel_Insert_Input = {
  data: Array<Pre_User_Source_Enum_Insert_Input>;
  on_conflict?: InputMaybe<Pre_User_Source_Enum_On_Conflict>;
};

export type Pre_User_Source_Enum_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Pre_User_Source_Enum_Bool_Exp>>>;
  _not?: InputMaybe<Pre_User_Source_Enum_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Pre_User_Source_Enum_Bool_Exp>>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
};

export enum Pre_User_Source_Enum_Constraint {
  PreUserSourceEnumPkey = 'pre_user_source_enum_pkey',
}

export enum Pre_User_Source_Enum_Enum {
  Conexa = 'conexa',
  DasaDav = 'dasa_dav',
  Gympass = 'gympass',
  N2b = 'n2b',
}

export type Pre_User_Source_Enum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Pre_User_Source_Enum_Enum>;
  _in?: InputMaybe<Array<Pre_User_Source_Enum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Pre_User_Source_Enum_Enum>;
  _nin?: InputMaybe<Array<Pre_User_Source_Enum_Enum>>;
};

export type Pre_User_Source_Enum_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type Pre_User_Source_Enum_Max_Fields = {
  __typename?: 'pre_user_source_enum_max_fields';
  comment?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type Pre_User_Source_Enum_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Pre_User_Source_Enum_Min_Fields = {
  __typename?: 'pre_user_source_enum_min_fields';
  comment?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type Pre_User_Source_Enum_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Pre_User_Source_Enum_Mutation_Response = {
  __typename?: 'pre_user_source_enum_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Pre_User_Source_Enum>;
};

export type Pre_User_Source_Enum_Obj_Rel_Insert_Input = {
  data: Pre_User_Source_Enum_Insert_Input;
  on_conflict?: InputMaybe<Pre_User_Source_Enum_On_Conflict>;
};

export type Pre_User_Source_Enum_On_Conflict = {
  constraint: Pre_User_Source_Enum_Constraint;
  update_columns: Array<Pre_User_Source_Enum_Update_Column>;
  where?: InputMaybe<Pre_User_Source_Enum_Bool_Exp>;
};

export type Pre_User_Source_Enum_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Pre_User_Source_Enum_Pk_Columns_Input = {
  text: Scalars['String'];
};

export enum Pre_User_Source_Enum_Select_Column {
  Comment = 'comment',
  Text = 'text',
}

export type Pre_User_Source_Enum_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export enum Pre_User_Source_Enum_Update_Column {
  Comment = 'comment',
  Text = 'text',
}

export enum Pre_User_Update_Column {
  AltEmail = 'alt_email',
  Birthdate = 'birthdate',
  Company = 'company',
  Cpf = 'cpf',
  CreatedAt = 'created_at',
  Email = 'email',
  FirstName = 'first_name',
  Gender = 'gender',
  Id = 'id',
  LastName = 'last_name',
  Phone = 'phone',
  Source = 'source',
  Status = 'status',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Productivity = {
  __typename?: 'productivity';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  q1: Scalars['Int'];
  q2: Scalars['Float'];
  q3: Scalars['Int'];
  q4: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  user?: Maybe<User>;
  user_id: Scalars['String'];
};

export type Productivity_Aggregate = {
  __typename?: 'productivity_aggregate';
  aggregate?: Maybe<Productivity_Aggregate_Fields>;
  nodes: Array<Productivity>;
};

export type Productivity_Aggregate_Fields = {
  __typename?: 'productivity_aggregate_fields';
  avg?: Maybe<Productivity_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Productivity_Max_Fields>;
  min?: Maybe<Productivity_Min_Fields>;
  stddev?: Maybe<Productivity_Stddev_Fields>;
  stddev_pop?: Maybe<Productivity_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Productivity_Stddev_Samp_Fields>;
  sum?: Maybe<Productivity_Sum_Fields>;
  var_pop?: Maybe<Productivity_Var_Pop_Fields>;
  var_samp?: Maybe<Productivity_Var_Samp_Fields>;
  variance?: Maybe<Productivity_Variance_Fields>;
};

export type Productivity_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Productivity_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Productivity_Aggregate_Order_By = {
  avg?: InputMaybe<Productivity_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Productivity_Max_Order_By>;
  min?: InputMaybe<Productivity_Min_Order_By>;
  stddev?: InputMaybe<Productivity_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Productivity_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Productivity_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Productivity_Sum_Order_By>;
  var_pop?: InputMaybe<Productivity_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Productivity_Var_Samp_Order_By>;
  variance?: InputMaybe<Productivity_Variance_Order_By>;
};

export type Productivity_Arr_Rel_Insert_Input = {
  data: Array<Productivity_Insert_Input>;
  on_conflict?: InputMaybe<Productivity_On_Conflict>;
};

export type Productivity_Avg_Fields = {
  __typename?: 'productivity_avg_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
};

export type Productivity_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
};

export type Productivity_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Productivity_Bool_Exp>>>;
  _not?: InputMaybe<Productivity_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Productivity_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  q1?: InputMaybe<Int_Comparison_Exp>;
  q2?: InputMaybe<Float_Comparison_Exp>;
  q3?: InputMaybe<Int_Comparison_Exp>;
  q4?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

export enum Productivity_Constraint {
  ProductivityPkey = 'productivity_pkey',
}

export type Productivity_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['Int']>;
  q2?: InputMaybe<Scalars['Float']>;
  q3?: InputMaybe<Scalars['Int']>;
  q4?: InputMaybe<Scalars['Int']>;
};

export type Productivity_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['Int']>;
  q2?: InputMaybe<Scalars['Float']>;
  q3?: InputMaybe<Scalars['Int']>;
  q4?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Productivity_Max_Fields = {
  __typename?: 'productivity_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['Int']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Int']>;
  q4?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Productivity_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Productivity_Min_Fields = {
  __typename?: 'productivity_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['Int']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Int']>;
  q4?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Productivity_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Productivity_Mutation_Response = {
  __typename?: 'productivity_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Productivity>;
};

export type Productivity_Obj_Rel_Insert_Input = {
  data: Productivity_Insert_Input;
  on_conflict?: InputMaybe<Productivity_On_Conflict>;
};

export type Productivity_On_Conflict = {
  constraint: Productivity_Constraint;
  update_columns: Array<Productivity_Update_Column>;
  where?: InputMaybe<Productivity_Bool_Exp>;
};

export type Productivity_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Productivity_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export enum Productivity_Select_Column {
  CreatedAt = 'created_at',
  Id = 'id',
  Q1 = 'q1',
  Q2 = 'q2',
  Q3 = 'q3',
  Q4 = 'q4',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Productivity_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['Int']>;
  q2?: InputMaybe<Scalars['Float']>;
  q3?: InputMaybe<Scalars['Int']>;
  q4?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Productivity_Stddev_Fields = {
  __typename?: 'productivity_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
};

export type Productivity_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
};

export type Productivity_Stddev_Pop_Fields = {
  __typename?: 'productivity_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
};

export type Productivity_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
};

export type Productivity_Stddev_Samp_Fields = {
  __typename?: 'productivity_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
};

export type Productivity_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
};

export type Productivity_Sum_Fields = {
  __typename?: 'productivity_sum_fields';
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['Int']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Int']>;
  q4?: Maybe<Scalars['Int']>;
};

export type Productivity_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
};

export enum Productivity_Update_Column {
  CreatedAt = 'created_at',
  Id = 'id',
  Q1 = 'q1',
  Q2 = 'q2',
  Q3 = 'q3',
  Q4 = 'q4',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Productivity_Var_Pop_Fields = {
  __typename?: 'productivity_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
};

export type Productivity_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
};

export type Productivity_Var_Samp_Fields = {
  __typename?: 'productivity_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
};

export type Productivity_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
};

export type Productivity_Variance_Fields = {
  __typename?: 'productivity_variance_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
};

export type Productivity_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
};

export type Program_Session = {
  __typename?: 'program_session';
  block: Scalars['String'];
  community_posts: Scalars['jsonb'];
  created_at: Scalars['timestamptz'];
  emoji: Scalars['String'];
  id: Scalars['Int'];
  min_version_android?: Maybe<Scalars['String']>;
  min_version_ios?: Maybe<Scalars['String']>;
  push_notification_message: Scalars['String'];
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

export type Program_SessionCommunity_PostsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

export type Program_Session_Aggregate = {
  __typename?: 'program_session_aggregate';
  aggregate?: Maybe<Program_Session_Aggregate_Fields>;
  nodes: Array<Program_Session>;
};

export type Program_Session_Aggregate_Fields = {
  __typename?: 'program_session_aggregate_fields';
  avg?: Maybe<Program_Session_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Program_Session_Max_Fields>;
  min?: Maybe<Program_Session_Min_Fields>;
  stddev?: Maybe<Program_Session_Stddev_Fields>;
  stddev_pop?: Maybe<Program_Session_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Program_Session_Stddev_Samp_Fields>;
  sum?: Maybe<Program_Session_Sum_Fields>;
  var_pop?: Maybe<Program_Session_Var_Pop_Fields>;
  var_samp?: Maybe<Program_Session_Var_Samp_Fields>;
  variance?: Maybe<Program_Session_Variance_Fields>;
};

export type Program_Session_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Program_Session_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Program_Session_Aggregate_Order_By = {
  avg?: InputMaybe<Program_Session_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Program_Session_Max_Order_By>;
  min?: InputMaybe<Program_Session_Min_Order_By>;
  stddev?: InputMaybe<Program_Session_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Program_Session_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Program_Session_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Program_Session_Sum_Order_By>;
  var_pop?: InputMaybe<Program_Session_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Program_Session_Var_Samp_Order_By>;
  variance?: InputMaybe<Program_Session_Variance_Order_By>;
};

export type Program_Session_Append_Input = {
  community_posts?: InputMaybe<Scalars['jsonb']>;
};

export type Program_Session_Arr_Rel_Insert_Input = {
  data: Array<Program_Session_Insert_Input>;
  on_conflict?: InputMaybe<Program_Session_On_Conflict>;
};

export type Program_Session_Avg_Fields = {
  __typename?: 'program_session_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Program_Session_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Program_Session_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Program_Session_Bool_Exp>>>;
  _not?: InputMaybe<Program_Session_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Program_Session_Bool_Exp>>>;
  block?: InputMaybe<String_Comparison_Exp>;
  community_posts?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  emoji?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  min_version_android?: InputMaybe<String_Comparison_Exp>;
  min_version_ios?: InputMaybe<String_Comparison_Exp>;
  push_notification_message?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

export enum Program_Session_Constraint {
  ProgramSessionPkey = 'program_session_pkey',
}

export type Program_Session_Delete_At_Path_Input = {
  community_posts?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Program_Session_Delete_Elem_Input = {
  community_posts?: InputMaybe<Scalars['Int']>;
};

export type Program_Session_Delete_Key_Input = {
  community_posts?: InputMaybe<Scalars['String']>;
};

export type Program_Session_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

export type Program_Session_Insert_Input = {
  block?: InputMaybe<Scalars['String']>;
  community_posts?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  emoji?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  min_version_android?: InputMaybe<Scalars['String']>;
  min_version_ios?: InputMaybe<Scalars['String']>;
  push_notification_message?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Program_Session_Max_Fields = {
  __typename?: 'program_session_max_fields';
  block?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  emoji?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  min_version_android?: Maybe<Scalars['String']>;
  min_version_ios?: Maybe<Scalars['String']>;
  push_notification_message?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type Program_Session_Max_Order_By = {
  block?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  emoji?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  min_version_android?: InputMaybe<Order_By>;
  min_version_ios?: InputMaybe<Order_By>;
  push_notification_message?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type Program_Session_Min_Fields = {
  __typename?: 'program_session_min_fields';
  block?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  emoji?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  min_version_android?: Maybe<Scalars['String']>;
  min_version_ios?: Maybe<Scalars['String']>;
  push_notification_message?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type Program_Session_Min_Order_By = {
  block?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  emoji?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  min_version_android?: InputMaybe<Order_By>;
  min_version_ios?: InputMaybe<Order_By>;
  push_notification_message?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type Program_Session_Mutation_Response = {
  __typename?: 'program_session_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Program_Session>;
};

export type Program_Session_Obj_Rel_Insert_Input = {
  data: Program_Session_Insert_Input;
  on_conflict?: InputMaybe<Program_Session_On_Conflict>;
};

export type Program_Session_On_Conflict = {
  constraint: Program_Session_Constraint;
  update_columns: Array<Program_Session_Update_Column>;
  where?: InputMaybe<Program_Session_Bool_Exp>;
};

export type Program_Session_Order_By = {
  block?: InputMaybe<Order_By>;
  community_posts?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  emoji?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  min_version_android?: InputMaybe<Order_By>;
  min_version_ios?: InputMaybe<Order_By>;
  push_notification_message?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type Program_Session_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export type Program_Session_Prepend_Input = {
  community_posts?: InputMaybe<Scalars['jsonb']>;
};

export enum Program_Session_Select_Column {
  Block = 'block',
  CommunityPosts = 'community_posts',
  CreatedAt = 'created_at',
  Emoji = 'emoji',
  Id = 'id',
  MinVersionAndroid = 'min_version_android',
  MinVersionIos = 'min_version_ios',
  PushNotificationMessage = 'push_notification_message',
  Title = 'title',
  UpdatedAt = 'updated_at',
}

export type Program_Session_Set_Input = {
  block?: InputMaybe<Scalars['String']>;
  community_posts?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  emoji?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  min_version_android?: InputMaybe<Scalars['String']>;
  min_version_ios?: InputMaybe<Scalars['String']>;
  push_notification_message?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Program_Session_Stddev_Fields = {
  __typename?: 'program_session_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Program_Session_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Program_Session_Stddev_Pop_Fields = {
  __typename?: 'program_session_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Program_Session_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Program_Session_Stddev_Samp_Fields = {
  __typename?: 'program_session_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Program_Session_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Program_Session_Sum_Fields = {
  __typename?: 'program_session_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

export type Program_Session_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

export enum Program_Session_Update_Column {
  Block = 'block',
  CommunityPosts = 'community_posts',
  CreatedAt = 'created_at',
  Emoji = 'emoji',
  Id = 'id',
  MinVersionAndroid = 'min_version_android',
  MinVersionIos = 'min_version_ios',
  PushNotificationMessage = 'push_notification_message',
  Title = 'title',
  UpdatedAt = 'updated_at',
}

export type Program_Session_Var_Pop_Fields = {
  __typename?: 'program_session_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Program_Session_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Program_Session_Var_Samp_Fields = {
  __typename?: 'program_session_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Program_Session_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Program_Session_Variance_Fields = {
  __typename?: 'program_session_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Program_Session_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Psqi = {
  __typename?: 'psqi';
  age: Scalars['numeric'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  q1: Scalars['String'];
  q2: Scalars['numeric'];
  q3: Scalars['String'];
  q4: Scalars['numeric'];
  q5a: Scalars['numeric'];
  q5b: Scalars['numeric'];
  q5c: Scalars['numeric'];
  q5d: Scalars['numeric'];
  q5e: Scalars['numeric'];
  q5f: Scalars['numeric'];
  q5g: Scalars['numeric'];
  q5h: Scalars['numeric'];
  q5i: Scalars['numeric'];
  q5j: Scalars['numeric'];
  q5j_comment: Scalars['String'];
  q6: Scalars['numeric'];
  q7: Scalars['numeric'];
  q8: Scalars['numeric'];
  q9: Scalars['numeric'];
  user?: Maybe<User>;
  user_id: Scalars['String'];
};

export type Psqi_Aggregate = {
  __typename?: 'psqi_aggregate';
  aggregate?: Maybe<Psqi_Aggregate_Fields>;
  nodes: Array<Psqi>;
};

export type Psqi_Aggregate_Fields = {
  __typename?: 'psqi_aggregate_fields';
  avg?: Maybe<Psqi_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Psqi_Max_Fields>;
  min?: Maybe<Psqi_Min_Fields>;
  stddev?: Maybe<Psqi_Stddev_Fields>;
  stddev_pop?: Maybe<Psqi_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Psqi_Stddev_Samp_Fields>;
  sum?: Maybe<Psqi_Sum_Fields>;
  var_pop?: Maybe<Psqi_Var_Pop_Fields>;
  var_samp?: Maybe<Psqi_Var_Samp_Fields>;
  variance?: Maybe<Psqi_Variance_Fields>;
};

export type Psqi_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Psqi_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Psqi_Aggregate_Order_By = {
  avg?: InputMaybe<Psqi_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Psqi_Max_Order_By>;
  min?: InputMaybe<Psqi_Min_Order_By>;
  stddev?: InputMaybe<Psqi_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Psqi_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Psqi_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Psqi_Sum_Order_By>;
  var_pop?: InputMaybe<Psqi_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Psqi_Var_Samp_Order_By>;
  variance?: InputMaybe<Psqi_Variance_Order_By>;
};

export type Psqi_Arr_Rel_Insert_Input = {
  data: Array<Psqi_Insert_Input>;
  on_conflict?: InputMaybe<Psqi_On_Conflict>;
};

export type Psqi_Avg_Fields = {
  __typename?: 'psqi_avg_fields';
  age?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5a?: Maybe<Scalars['Float']>;
  q5b?: Maybe<Scalars['Float']>;
  q5c?: Maybe<Scalars['Float']>;
  q5d?: Maybe<Scalars['Float']>;
  q5e?: Maybe<Scalars['Float']>;
  q5f?: Maybe<Scalars['Float']>;
  q5g?: Maybe<Scalars['Float']>;
  q5h?: Maybe<Scalars['Float']>;
  q5i?: Maybe<Scalars['Float']>;
  q5j?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
};

export type Psqi_Avg_Order_By = {
  age?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5a?: InputMaybe<Order_By>;
  q5b?: InputMaybe<Order_By>;
  q5c?: InputMaybe<Order_By>;
  q5d?: InputMaybe<Order_By>;
  q5e?: InputMaybe<Order_By>;
  q5f?: InputMaybe<Order_By>;
  q5g?: InputMaybe<Order_By>;
  q5h?: InputMaybe<Order_By>;
  q5i?: InputMaybe<Order_By>;
  q5j?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
};

export type Psqi_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Psqi_Bool_Exp>>>;
  _not?: InputMaybe<Psqi_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Psqi_Bool_Exp>>>;
  age?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  q1?: InputMaybe<String_Comparison_Exp>;
  q2?: InputMaybe<Numeric_Comparison_Exp>;
  q3?: InputMaybe<String_Comparison_Exp>;
  q4?: InputMaybe<Numeric_Comparison_Exp>;
  q5a?: InputMaybe<Numeric_Comparison_Exp>;
  q5b?: InputMaybe<Numeric_Comparison_Exp>;
  q5c?: InputMaybe<Numeric_Comparison_Exp>;
  q5d?: InputMaybe<Numeric_Comparison_Exp>;
  q5e?: InputMaybe<Numeric_Comparison_Exp>;
  q5f?: InputMaybe<Numeric_Comparison_Exp>;
  q5g?: InputMaybe<Numeric_Comparison_Exp>;
  q5h?: InputMaybe<Numeric_Comparison_Exp>;
  q5i?: InputMaybe<Numeric_Comparison_Exp>;
  q5j?: InputMaybe<Numeric_Comparison_Exp>;
  q5j_comment?: InputMaybe<String_Comparison_Exp>;
  q6?: InputMaybe<Numeric_Comparison_Exp>;
  q7?: InputMaybe<Numeric_Comparison_Exp>;
  q8?: InputMaybe<Numeric_Comparison_Exp>;
  q9?: InputMaybe<Numeric_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

export enum Psqi_Constraint {
  PsqiPkey = 'psqi_pkey',
}

export type Psqi_Inc_Input = {
  age?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  q2?: InputMaybe<Scalars['numeric']>;
  q4?: InputMaybe<Scalars['numeric']>;
  q5a?: InputMaybe<Scalars['numeric']>;
  q5b?: InputMaybe<Scalars['numeric']>;
  q5c?: InputMaybe<Scalars['numeric']>;
  q5d?: InputMaybe<Scalars['numeric']>;
  q5e?: InputMaybe<Scalars['numeric']>;
  q5f?: InputMaybe<Scalars['numeric']>;
  q5g?: InputMaybe<Scalars['numeric']>;
  q5h?: InputMaybe<Scalars['numeric']>;
  q5i?: InputMaybe<Scalars['numeric']>;
  q5j?: InputMaybe<Scalars['numeric']>;
  q6?: InputMaybe<Scalars['numeric']>;
  q7?: InputMaybe<Scalars['numeric']>;
  q8?: InputMaybe<Scalars['numeric']>;
  q9?: InputMaybe<Scalars['numeric']>;
};

export type Psqi_Insert_Input = {
  age?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['String']>;
  q2?: InputMaybe<Scalars['numeric']>;
  q3?: InputMaybe<Scalars['String']>;
  q4?: InputMaybe<Scalars['numeric']>;
  q5a?: InputMaybe<Scalars['numeric']>;
  q5b?: InputMaybe<Scalars['numeric']>;
  q5c?: InputMaybe<Scalars['numeric']>;
  q5d?: InputMaybe<Scalars['numeric']>;
  q5e?: InputMaybe<Scalars['numeric']>;
  q5f?: InputMaybe<Scalars['numeric']>;
  q5g?: InputMaybe<Scalars['numeric']>;
  q5h?: InputMaybe<Scalars['numeric']>;
  q5i?: InputMaybe<Scalars['numeric']>;
  q5j?: InputMaybe<Scalars['numeric']>;
  q5j_comment?: InputMaybe<Scalars['String']>;
  q6?: InputMaybe<Scalars['numeric']>;
  q7?: InputMaybe<Scalars['numeric']>;
  q8?: InputMaybe<Scalars['numeric']>;
  q9?: InputMaybe<Scalars['numeric']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Psqi_Max_Fields = {
  __typename?: 'psqi_max_fields';
  age?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['String']>;
  q2?: Maybe<Scalars['numeric']>;
  q3?: Maybe<Scalars['String']>;
  q4?: Maybe<Scalars['numeric']>;
  q5a?: Maybe<Scalars['numeric']>;
  q5b?: Maybe<Scalars['numeric']>;
  q5c?: Maybe<Scalars['numeric']>;
  q5d?: Maybe<Scalars['numeric']>;
  q5e?: Maybe<Scalars['numeric']>;
  q5f?: Maybe<Scalars['numeric']>;
  q5g?: Maybe<Scalars['numeric']>;
  q5h?: Maybe<Scalars['numeric']>;
  q5i?: Maybe<Scalars['numeric']>;
  q5j?: Maybe<Scalars['numeric']>;
  q5j_comment?: Maybe<Scalars['String']>;
  q6?: Maybe<Scalars['numeric']>;
  q7?: Maybe<Scalars['numeric']>;
  q8?: Maybe<Scalars['numeric']>;
  q9?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Psqi_Max_Order_By = {
  age?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5a?: InputMaybe<Order_By>;
  q5b?: InputMaybe<Order_By>;
  q5c?: InputMaybe<Order_By>;
  q5d?: InputMaybe<Order_By>;
  q5e?: InputMaybe<Order_By>;
  q5f?: InputMaybe<Order_By>;
  q5g?: InputMaybe<Order_By>;
  q5h?: InputMaybe<Order_By>;
  q5i?: InputMaybe<Order_By>;
  q5j?: InputMaybe<Order_By>;
  q5j_comment?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Psqi_Min_Fields = {
  __typename?: 'psqi_min_fields';
  age?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['String']>;
  q2?: Maybe<Scalars['numeric']>;
  q3?: Maybe<Scalars['String']>;
  q4?: Maybe<Scalars['numeric']>;
  q5a?: Maybe<Scalars['numeric']>;
  q5b?: Maybe<Scalars['numeric']>;
  q5c?: Maybe<Scalars['numeric']>;
  q5d?: Maybe<Scalars['numeric']>;
  q5e?: Maybe<Scalars['numeric']>;
  q5f?: Maybe<Scalars['numeric']>;
  q5g?: Maybe<Scalars['numeric']>;
  q5h?: Maybe<Scalars['numeric']>;
  q5i?: Maybe<Scalars['numeric']>;
  q5j?: Maybe<Scalars['numeric']>;
  q5j_comment?: Maybe<Scalars['String']>;
  q6?: Maybe<Scalars['numeric']>;
  q7?: Maybe<Scalars['numeric']>;
  q8?: Maybe<Scalars['numeric']>;
  q9?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Psqi_Min_Order_By = {
  age?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5a?: InputMaybe<Order_By>;
  q5b?: InputMaybe<Order_By>;
  q5c?: InputMaybe<Order_By>;
  q5d?: InputMaybe<Order_By>;
  q5e?: InputMaybe<Order_By>;
  q5f?: InputMaybe<Order_By>;
  q5g?: InputMaybe<Order_By>;
  q5h?: InputMaybe<Order_By>;
  q5i?: InputMaybe<Order_By>;
  q5j?: InputMaybe<Order_By>;
  q5j_comment?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Psqi_Mutation_Response = {
  __typename?: 'psqi_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Psqi>;
};

export type Psqi_Obj_Rel_Insert_Input = {
  data: Psqi_Insert_Input;
  on_conflict?: InputMaybe<Psqi_On_Conflict>;
};

export type Psqi_On_Conflict = {
  constraint: Psqi_Constraint;
  update_columns: Array<Psqi_Update_Column>;
  where?: InputMaybe<Psqi_Bool_Exp>;
};

export type Psqi_Order_By = {
  age?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5a?: InputMaybe<Order_By>;
  q5b?: InputMaybe<Order_By>;
  q5c?: InputMaybe<Order_By>;
  q5d?: InputMaybe<Order_By>;
  q5e?: InputMaybe<Order_By>;
  q5f?: InputMaybe<Order_By>;
  q5g?: InputMaybe<Order_By>;
  q5h?: InputMaybe<Order_By>;
  q5i?: InputMaybe<Order_By>;
  q5j?: InputMaybe<Order_By>;
  q5j_comment?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Psqi_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export enum Psqi_Select_Column {
  Age = 'age',
  CreatedAt = 'created_at',
  Id = 'id',
  Q1 = 'q1',
  Q2 = 'q2',
  Q3 = 'q3',
  Q4 = 'q4',
  Q5a = 'q5a',
  Q5b = 'q5b',
  Q5c = 'q5c',
  Q5d = 'q5d',
  Q5e = 'q5e',
  Q5f = 'q5f',
  Q5g = 'q5g',
  Q5h = 'q5h',
  Q5i = 'q5i',
  Q5j = 'q5j',
  Q5jComment = 'q5j_comment',
  Q6 = 'q6',
  Q7 = 'q7',
  Q8 = 'q8',
  Q9 = 'q9',
  UserId = 'user_id',
}

export type Psqi_Set_Input = {
  age?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['String']>;
  q2?: InputMaybe<Scalars['numeric']>;
  q3?: InputMaybe<Scalars['String']>;
  q4?: InputMaybe<Scalars['numeric']>;
  q5a?: InputMaybe<Scalars['numeric']>;
  q5b?: InputMaybe<Scalars['numeric']>;
  q5c?: InputMaybe<Scalars['numeric']>;
  q5d?: InputMaybe<Scalars['numeric']>;
  q5e?: InputMaybe<Scalars['numeric']>;
  q5f?: InputMaybe<Scalars['numeric']>;
  q5g?: InputMaybe<Scalars['numeric']>;
  q5h?: InputMaybe<Scalars['numeric']>;
  q5i?: InputMaybe<Scalars['numeric']>;
  q5j?: InputMaybe<Scalars['numeric']>;
  q5j_comment?: InputMaybe<Scalars['String']>;
  q6?: InputMaybe<Scalars['numeric']>;
  q7?: InputMaybe<Scalars['numeric']>;
  q8?: InputMaybe<Scalars['numeric']>;
  q9?: InputMaybe<Scalars['numeric']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Psqi_Stddev_Fields = {
  __typename?: 'psqi_stddev_fields';
  age?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5a?: Maybe<Scalars['Float']>;
  q5b?: Maybe<Scalars['Float']>;
  q5c?: Maybe<Scalars['Float']>;
  q5d?: Maybe<Scalars['Float']>;
  q5e?: Maybe<Scalars['Float']>;
  q5f?: Maybe<Scalars['Float']>;
  q5g?: Maybe<Scalars['Float']>;
  q5h?: Maybe<Scalars['Float']>;
  q5i?: Maybe<Scalars['Float']>;
  q5j?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
};

export type Psqi_Stddev_Order_By = {
  age?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5a?: InputMaybe<Order_By>;
  q5b?: InputMaybe<Order_By>;
  q5c?: InputMaybe<Order_By>;
  q5d?: InputMaybe<Order_By>;
  q5e?: InputMaybe<Order_By>;
  q5f?: InputMaybe<Order_By>;
  q5g?: InputMaybe<Order_By>;
  q5h?: InputMaybe<Order_By>;
  q5i?: InputMaybe<Order_By>;
  q5j?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
};

export type Psqi_Stddev_Pop_Fields = {
  __typename?: 'psqi_stddev_pop_fields';
  age?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5a?: Maybe<Scalars['Float']>;
  q5b?: Maybe<Scalars['Float']>;
  q5c?: Maybe<Scalars['Float']>;
  q5d?: Maybe<Scalars['Float']>;
  q5e?: Maybe<Scalars['Float']>;
  q5f?: Maybe<Scalars['Float']>;
  q5g?: Maybe<Scalars['Float']>;
  q5h?: Maybe<Scalars['Float']>;
  q5i?: Maybe<Scalars['Float']>;
  q5j?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
};

export type Psqi_Stddev_Pop_Order_By = {
  age?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5a?: InputMaybe<Order_By>;
  q5b?: InputMaybe<Order_By>;
  q5c?: InputMaybe<Order_By>;
  q5d?: InputMaybe<Order_By>;
  q5e?: InputMaybe<Order_By>;
  q5f?: InputMaybe<Order_By>;
  q5g?: InputMaybe<Order_By>;
  q5h?: InputMaybe<Order_By>;
  q5i?: InputMaybe<Order_By>;
  q5j?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
};

export type Psqi_Stddev_Samp_Fields = {
  __typename?: 'psqi_stddev_samp_fields';
  age?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5a?: Maybe<Scalars['Float']>;
  q5b?: Maybe<Scalars['Float']>;
  q5c?: Maybe<Scalars['Float']>;
  q5d?: Maybe<Scalars['Float']>;
  q5e?: Maybe<Scalars['Float']>;
  q5f?: Maybe<Scalars['Float']>;
  q5g?: Maybe<Scalars['Float']>;
  q5h?: Maybe<Scalars['Float']>;
  q5i?: Maybe<Scalars['Float']>;
  q5j?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
};

export type Psqi_Stddev_Samp_Order_By = {
  age?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5a?: InputMaybe<Order_By>;
  q5b?: InputMaybe<Order_By>;
  q5c?: InputMaybe<Order_By>;
  q5d?: InputMaybe<Order_By>;
  q5e?: InputMaybe<Order_By>;
  q5f?: InputMaybe<Order_By>;
  q5g?: InputMaybe<Order_By>;
  q5h?: InputMaybe<Order_By>;
  q5i?: InputMaybe<Order_By>;
  q5j?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
};

export type Psqi_Sum_Fields = {
  __typename?: 'psqi_sum_fields';
  age?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  q2?: Maybe<Scalars['numeric']>;
  q4?: Maybe<Scalars['numeric']>;
  q5a?: Maybe<Scalars['numeric']>;
  q5b?: Maybe<Scalars['numeric']>;
  q5c?: Maybe<Scalars['numeric']>;
  q5d?: Maybe<Scalars['numeric']>;
  q5e?: Maybe<Scalars['numeric']>;
  q5f?: Maybe<Scalars['numeric']>;
  q5g?: Maybe<Scalars['numeric']>;
  q5h?: Maybe<Scalars['numeric']>;
  q5i?: Maybe<Scalars['numeric']>;
  q5j?: Maybe<Scalars['numeric']>;
  q6?: Maybe<Scalars['numeric']>;
  q7?: Maybe<Scalars['numeric']>;
  q8?: Maybe<Scalars['numeric']>;
  q9?: Maybe<Scalars['numeric']>;
};

export type Psqi_Sum_Order_By = {
  age?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5a?: InputMaybe<Order_By>;
  q5b?: InputMaybe<Order_By>;
  q5c?: InputMaybe<Order_By>;
  q5d?: InputMaybe<Order_By>;
  q5e?: InputMaybe<Order_By>;
  q5f?: InputMaybe<Order_By>;
  q5g?: InputMaybe<Order_By>;
  q5h?: InputMaybe<Order_By>;
  q5i?: InputMaybe<Order_By>;
  q5j?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
};

export enum Psqi_Update_Column {
  Age = 'age',
  CreatedAt = 'created_at',
  Id = 'id',
  Q1 = 'q1',
  Q2 = 'q2',
  Q3 = 'q3',
  Q4 = 'q4',
  Q5a = 'q5a',
  Q5b = 'q5b',
  Q5c = 'q5c',
  Q5d = 'q5d',
  Q5e = 'q5e',
  Q5f = 'q5f',
  Q5g = 'q5g',
  Q5h = 'q5h',
  Q5i = 'q5i',
  Q5j = 'q5j',
  Q5jComment = 'q5j_comment',
  Q6 = 'q6',
  Q7 = 'q7',
  Q8 = 'q8',
  Q9 = 'q9',
  UserId = 'user_id',
}

export type Psqi_Var_Pop_Fields = {
  __typename?: 'psqi_var_pop_fields';
  age?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5a?: Maybe<Scalars['Float']>;
  q5b?: Maybe<Scalars['Float']>;
  q5c?: Maybe<Scalars['Float']>;
  q5d?: Maybe<Scalars['Float']>;
  q5e?: Maybe<Scalars['Float']>;
  q5f?: Maybe<Scalars['Float']>;
  q5g?: Maybe<Scalars['Float']>;
  q5h?: Maybe<Scalars['Float']>;
  q5i?: Maybe<Scalars['Float']>;
  q5j?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
};

export type Psqi_Var_Pop_Order_By = {
  age?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5a?: InputMaybe<Order_By>;
  q5b?: InputMaybe<Order_By>;
  q5c?: InputMaybe<Order_By>;
  q5d?: InputMaybe<Order_By>;
  q5e?: InputMaybe<Order_By>;
  q5f?: InputMaybe<Order_By>;
  q5g?: InputMaybe<Order_By>;
  q5h?: InputMaybe<Order_By>;
  q5i?: InputMaybe<Order_By>;
  q5j?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
};

export type Psqi_Var_Samp_Fields = {
  __typename?: 'psqi_var_samp_fields';
  age?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5a?: Maybe<Scalars['Float']>;
  q5b?: Maybe<Scalars['Float']>;
  q5c?: Maybe<Scalars['Float']>;
  q5d?: Maybe<Scalars['Float']>;
  q5e?: Maybe<Scalars['Float']>;
  q5f?: Maybe<Scalars['Float']>;
  q5g?: Maybe<Scalars['Float']>;
  q5h?: Maybe<Scalars['Float']>;
  q5i?: Maybe<Scalars['Float']>;
  q5j?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
};

export type Psqi_Var_Samp_Order_By = {
  age?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5a?: InputMaybe<Order_By>;
  q5b?: InputMaybe<Order_By>;
  q5c?: InputMaybe<Order_By>;
  q5d?: InputMaybe<Order_By>;
  q5e?: InputMaybe<Order_By>;
  q5f?: InputMaybe<Order_By>;
  q5g?: InputMaybe<Order_By>;
  q5h?: InputMaybe<Order_By>;
  q5i?: InputMaybe<Order_By>;
  q5j?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
};

export type Psqi_Variance_Fields = {
  __typename?: 'psqi_variance_fields';
  age?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5a?: Maybe<Scalars['Float']>;
  q5b?: Maybe<Scalars['Float']>;
  q5c?: Maybe<Scalars['Float']>;
  q5d?: Maybe<Scalars['Float']>;
  q5e?: Maybe<Scalars['Float']>;
  q5f?: Maybe<Scalars['Float']>;
  q5g?: Maybe<Scalars['Float']>;
  q5h?: Maybe<Scalars['Float']>;
  q5i?: Maybe<Scalars['Float']>;
  q5j?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
};

export type Psqi_Variance_Order_By = {
  age?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5a?: InputMaybe<Order_By>;
  q5b?: InputMaybe<Order_By>;
  q5c?: InputMaybe<Order_By>;
  q5d?: InputMaybe<Order_By>;
  q5e?: InputMaybe<Order_By>;
  q5f?: InputMaybe<Order_By>;
  q5g?: InputMaybe<Order_By>;
  q5h?: InputMaybe<Order_By>;
  q5i?: InputMaybe<Order_By>;
  q5j?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
};

export type Query_Root = {
  __typename?: 'query_root';
  apnea_questionnaire: Array<Apnea_Questionnaire>;
  apnea_questionnaire_aggregate: Apnea_Questionnaire_Aggregate;
  apnea_questionnaire_by_pk?: Maybe<Apnea_Questionnaire>;
  apnea_scan_photo: Array<Apnea_Scan_Photo>;
  apnea_scan_photo_aggregate: Apnea_Scan_Photo_Aggregate;
  apnea_scan_photo_by_pk?: Maybe<Apnea_Scan_Photo>;
  app_customization: Array<App_Customization>;
  app_customization_aggregate: App_Customization_Aggregate;
  app_customization_by_pk?: Maybe<App_Customization>;
  app_customization_type_enum: Array<App_Customization_Type_Enum>;
  app_customization_type_enum_aggregate: App_Customization_Type_Enum_Aggregate;
  app_customization_type_enum_by_pk?: Maybe<App_Customization_Type_Enum>;
  appointment: Array<Appointment>;
  appointment_aggregate: Appointment_Aggregate;
  appointment_by_pk?: Maybe<Appointment>;
  appointment_status_enum: Array<Appointment_Status_Enum>;
  appointment_status_enum_aggregate: Appointment_Status_Enum_Aggregate;
  appointment_status_enum_by_pk?: Maybe<Appointment_Status_Enum>;
  assesment: Array<Assesment>;
  assesment_aggregate: Assesment_Aggregate;
  assesment_by_pk?: Maybe<Assesment>;
  b2b_diagnosis_v1: Array<B2b_Diagnosis_V1>;
  b2b_diagnosis_v1_aggregate: B2b_Diagnosis_V1_Aggregate;
  b2b_diagnosis_v1_by_pk?: Maybe<B2b_Diagnosis_V1>;
  catastrophic_thinkings: Array<Catastrophic_Thinkings>;
  catastrophic_thinkings_aggregate: Catastrophic_Thinkings_Aggregate;
  catastrophic_thinkings_by_pk?: Maybe<Catastrophic_Thinkings>;
  community_user: Array<Community_User>;
  community_user_aggregate: Community_User_Aggregate;
  community_user_by_pk?: Maybe<Community_User>;
  company: Array<Company>;
  company_aggregate: Company_Aggregate;
  company_by_pk?: Maybe<Company>;
  company_health_carer: Array<Company_Health_Carer>;
  company_health_carer_aggregate: Company_Health_Carer_Aggregate;
  company_health_carer_by_pk?: Maybe<Company_Health_Carer>;
  company_health_carer_kind_enum: Array<Company_Health_Carer_Kind_Enum>;
  company_health_carer_kind_enum_aggregate: Company_Health_Carer_Kind_Enum_Aggregate;
  company_health_carer_kind_enum_by_pk?: Maybe<Company_Health_Carer_Kind_Enum>;
  company_user: Array<Company_User>;
  company_user_aggregate: Company_User_Aggregate;
  company_user_by_pk?: Maybe<Company_User>;
  coupon: Array<Coupon>;
  coupon_aggregate: Coupon_Aggregate;
  coupon_by_pk?: Maybe<Coupon>;
  crm: Array<Crm>;
  crm_aggregate: Crm_Aggregate;
  crm_by_pk?: Maybe<Crm>;
  dream: Array<Dream>;
  dream_aggregate: Dream_Aggregate;
  dream_by_pk?: Maybe<Dream>;
  eficiency: Array<Eficiency>;
  eficiency_aggregate: Eficiency_Aggregate;
  eficiency_by_pk?: Maybe<Eficiency>;
  epworth: Array<Epworth>;
  epworth_aggregate: Epworth_Aggregate;
  epworth_by_pk?: Maybe<Epworth>;
  gad: Array<Gad>;
  gad7: Array<Gad7>;
  gad7_aggregate: Gad7_Aggregate;
  gad7_by_pk?: Maybe<Gad7>;
  gad_aggregate: Gad_Aggregate;
  gad_by_pk?: Maybe<Gad>;
  generic_questionnaire: Array<Generic_Questionnaire>;
  generic_questionnaire_aggregate: Generic_Questionnaire_Aggregate;
  generic_questionnaire_by_pk?: Maybe<Generic_Questionnaire>;
  generic_questionnaire_name_enum: Array<Generic_Questionnaire_Name_Enum>;
  generic_questionnaire_name_enum_aggregate: Generic_Questionnaire_Name_Enum_Aggregate;
  generic_questionnaire_name_enum_by_pk?: Maybe<Generic_Questionnaire_Name_Enum>;
  google_sheet_row: Array<Google_Sheet_Row>;
  google_sheet_row_aggregate: Google_Sheet_Row_Aggregate;
  google_sheet_row_by_pk?: Maybe<Google_Sheet_Row>;
  gratitude: Array<Gratitude>;
  gratitude_aggregate: Gratitude_Aggregate;
  gratitude_by_pk?: Maybe<Gratitude>;
  health_carer: Array<Health_Carer>;
  health_carer_aggregate: Health_Carer_Aggregate;
  health_carer_by_pk?: Maybe<Health_Carer>;
  health_carer_membership_kind_enum: Array<Health_Carer_Membership_Kind_Enum>;
  health_carer_membership_kind_enum_aggregate: Health_Carer_Membership_Kind_Enum_Aggregate;
  health_carer_membership_kind_enum_by_pk?: Maybe<Health_Carer_Membership_Kind_Enum>;
  health_carer_money_request: Array<Health_Carer_Money_Request>;
  health_carer_money_request_aggregate: Health_Carer_Money_Request_Aggregate;
  health_carer_money_request_by_pk?: Maybe<Health_Carer_Money_Request>;
  health_carer_money_request_log: Array<Health_Carer_Money_Request_Log>;
  health_carer_money_request_log_aggregate: Health_Carer_Money_Request_Log_Aggregate;
  health_carer_money_request_log_by_pk?: Maybe<Health_Carer_Money_Request_Log>;
  health_carer_money_request_status_enum: Array<Health_Carer_Money_Request_Status_Enum>;
  health_carer_money_request_status_enum_aggregate: Health_Carer_Money_Request_Status_Enum_Aggregate;
  health_carer_money_request_status_enum_by_pk?: Maybe<Health_Carer_Money_Request_Status_Enum>;
  health_carer_pagarme_recipient: Array<Health_Carer_Pagarme_Recipient>;
  health_carer_pagarme_recipient_aggregate: Health_Carer_Pagarme_Recipient_Aggregate;
  health_carer_pagarme_recipient_by_pk?: Maybe<Health_Carer_Pagarme_Recipient>;
  health_carer_telehealth: Array<Health_Carer_Telehealth>;
  health_carer_telehealth_aggregate: Health_Carer_Telehealth_Aggregate;
  health_carer_telehealth_by_pk?: Maybe<Health_Carer_Telehealth>;
  health_carer_transactions: Array<Health_Carer_Transactions>;
  health_carer_transactions_aggregate: Health_Carer_Transactions_Aggregate;
  health_carer_transactions_by_pk?: Maybe<Health_Carer_Transactions>;
  health_carer_user: Array<Health_Carer_User>;
  health_carer_user_aggregate: Health_Carer_User_Aggregate;
  health_carer_user_by_pk?: Maybe<Health_Carer_User>;
  isi: Array<Isi>;
  isi_aggregate: Isi_Aggregate;
  isi_by_pk?: Maybe<Isi>;
  journey: Array<Journey>;
  journey_aggregate: Journey_Aggregate;
  journey_by_pk?: Maybe<Journey>;
  journey_program_sessions: Array<Journey_Program_Sessions>;
  journey_program_sessions_aggregate: Journey_Program_Sessions_Aggregate;
  journey_program_sessions_by_pk?: Maybe<Journey_Program_Sessions>;
  kupperman: Array<Kupperman>;
  kupperman_aggregate: Kupperman_Aggregate;
  kupperman_by_pk?: Maybe<Kupperman>;
  landing_page_redirect: Array<Landing_Page_Redirect>;
  landing_page_redirect_aggregate: Landing_Page_Redirect_Aggregate;
  landing_page_redirect_by_pk?: Maybe<Landing_Page_Redirect>;
  log: Array<Log>;
  log_aggregate: Log_Aggregate;
  log_by_pk?: Maybe<Log>;
  log_type_enum: Array<Log_Type_Enum>;
  log_type_enum_aggregate: Log_Type_Enum_Aggregate;
  log_type_enum_by_pk?: Maybe<Log_Type_Enum>;
  menopause_greene_scale: Array<Menopause_Greene_Scale>;
  menopause_greene_scale_aggregate: Menopause_Greene_Scale_Aggregate;
  menopause_greene_scale_by_pk?: Maybe<Menopause_Greene_Scale>;
  munich_chronotype: Array<Munich_Chronotype>;
  munich_chronotype_aggregate: Munich_Chronotype_Aggregate;
  munich_chronotype_by_pk?: Maybe<Munich_Chronotype>;
  munich_chronotype_core: Array<Munich_Chronotype_Core>;
  munich_chronotype_core_aggregate: Munich_Chronotype_Core_Aggregate;
  munich_chronotype_core_by_pk?: Maybe<Munich_Chronotype_Core>;
  os_enum: Array<Os_Enum>;
  os_enum_aggregate: Os_Enum_Aggregate;
  os_enum_by_pk?: Maybe<Os_Enum>;
  parking_lot: Array<Parking_Lot>;
  parking_lot_aggregate: Parking_Lot_Aggregate;
  parking_lot_by_pk?: Maybe<Parking_Lot>;
  personality_questionnaire: Array<Personality_Questionnaire>;
  personality_questionnaire_aggregate: Personality_Questionnaire_Aggregate;
  personality_questionnaire_by_pk?: Maybe<Personality_Questionnaire>;
  phq: Array<Phq>;
  phq9: Array<Phq9>;
  phq9_aggregate: Phq9_Aggregate;
  phq9_by_pk?: Maybe<Phq9>;
  phq_aggregate: Phq_Aggregate;
  phq_by_pk?: Maybe<Phq>;
  pre_user: Array<Pre_User>;
  pre_user_aggregate: Pre_User_Aggregate;
  pre_user_by_pk?: Maybe<Pre_User>;
  pre_user_source_enum: Array<Pre_User_Source_Enum>;
  pre_user_source_enum_aggregate: Pre_User_Source_Enum_Aggregate;
  pre_user_source_enum_by_pk?: Maybe<Pre_User_Source_Enum>;
  productivity: Array<Productivity>;
  productivity_aggregate: Productivity_Aggregate;
  productivity_by_pk?: Maybe<Productivity>;
  program_session: Array<Program_Session>;
  program_session_aggregate: Program_Session_Aggregate;
  program_session_by_pk?: Maybe<Program_Session>;
  psqi: Array<Psqi>;
  psqi_aggregate: Psqi_Aggregate;
  psqi_by_pk?: Maybe<Psqi>;
  rating: Array<Rating>;
  rating_aggregate: Rating_Aggregate;
  rating_by_pk?: Maybe<Rating>;
  recurrence_model_enum: Array<Recurrence_Model_Enum>;
  recurrence_model_enum_aggregate: Recurrence_Model_Enum_Aggregate;
  recurrence_model_enum_by_pk?: Maybe<Recurrence_Model_Enum>;
  reminder: Array<Reminder>;
  reminder_aggregate: Reminder_Aggregate;
  reminder_by_pk?: Maybe<Reminder>;
  reminder_type_enum: Array<Reminder_Type_Enum>;
  reminder_type_enum_aggregate: Reminder_Type_Enum_Aggregate;
  reminder_type_enum_by_pk?: Maybe<Reminder_Type_Enum>;
  sleep_diary: Array<Sleep_Diary>;
  sleep_diary_aggregate: Sleep_Diary_Aggregate;
  sleep_diary_apple_health: Array<Sleep_Diary_Apple_Health>;
  sleep_diary_apple_health_aggregate: Sleep_Diary_Apple_Health_Aggregate;
  sleep_diary_apple_health_by_pk?: Maybe<Sleep_Diary_Apple_Health>;
  sleep_diary_by_pk?: Maybe<Sleep_Diary>;
  sleep_diary_google_fit: Array<Sleep_Diary_Google_Fit>;
  sleep_diary_google_fit_aggregate: Sleep_Diary_Google_Fit_Aggregate;
  sleep_diary_google_fit_by_pk?: Maybe<Sleep_Diary_Google_Fit>;
  sleep_diary_interval: Array<Sleep_Diary_Interval>;
  sleep_diary_interval_aggregate: Sleep_Diary_Interval_Aggregate;
  sleep_diary_interval_by_pk?: Maybe<Sleep_Diary_Interval>;
  sleep_diary_medicine: Array<Sleep_Diary_Medicine>;
  sleep_diary_medicine_aggregate: Sleep_Diary_Medicine_Aggregate;
  sleep_diary_medicine_by_pk?: Maybe<Sleep_Diary_Medicine>;
  sleep_diary_sleep_tag: Array<Sleep_Diary_Sleep_Tag>;
  sleep_diary_sleep_tag_aggregate: Sleep_Diary_Sleep_Tag_Aggregate;
  sleep_diary_sleep_tag_by_pk?: Maybe<Sleep_Diary_Sleep_Tag>;
  sleep_restriction: Array<Sleep_Restriction>;
  sleep_restriction_aggregate: Sleep_Restriction_Aggregate;
  sleep_restriction_by_pk?: Maybe<Sleep_Restriction>;
  sleep_tag_enum: Array<Sleep_Tag_Enum>;
  sleep_tag_enum_aggregate: Sleep_Tag_Enum_Aggregate;
  sleep_tag_enum_by_pk?: Maybe<Sleep_Tag_Enum>;
  sleep_tracker_compressed_data: Array<Sleep_Tracker_Compressed_Data>;
  sleep_tracker_compressed_data_aggregate: Sleep_Tracker_Compressed_Data_Aggregate;
  sleep_tracker_compressed_data_by_pk?: Maybe<Sleep_Tracker_Compressed_Data>;
  stop_bang: Array<Stop_Bang>;
  stop_bang_aggregate: Stop_Bang_Aggregate;
  stop_bang_by_pk?: Maybe<Stop_Bang>;
  subscription: Array<Subscription>;
  subscription_aggregate: Subscription_Aggregate;
  subscription_by_pk?: Maybe<Subscription>;
  technique_resource: Array<Technique_Resource>;
  technique_resource_aggregate: Technique_Resource_Aggregate;
  technique_resource_by_pk?: Maybe<Technique_Resource>;
  toolbox: Array<Toolbox>;
  toolbox_aggregate: Toolbox_Aggregate;
  toolbox_by_pk?: Maybe<Toolbox>;
  user: Array<User>;
  user_aggregate: User_Aggregate;
  user_by_pk?: Maybe<User>;
  user_checkout: Array<User_Checkout>;
  user_checkout_aggregate: User_Checkout_Aggregate;
  user_checkout_by_pk?: Maybe<User_Checkout>;
  user_coupon: Array<User_Coupon>;
  user_coupon_aggregate: User_Coupon_Aggregate;
  user_coupon_by_pk?: Maybe<User_Coupon>;
  user_journey: Array<User_Journey>;
  user_journey_aggregate: User_Journey_Aggregate;
  user_journey_by_pk?: Maybe<User_Journey>;
  user_key_value: Array<User_Key_Value>;
  user_key_value_aggregate: User_Key_Value_Aggregate;
  user_key_value_by_pk?: Maybe<User_Key_Value>;
  user_key_value_key_enum: Array<User_Key_Value_Key_Enum>;
  user_key_value_key_enum_aggregate: User_Key_Value_Key_Enum_Aggregate;
  user_key_value_key_enum_by_pk?: Maybe<User_Key_Value_Key_Enum>;
  user_notification: Array<User_Notification>;
  user_notification_aggregate: User_Notification_Aggregate;
  user_notification_by_pk?: Maybe<User_Notification>;
  user_program_session: Array<User_Program_Session>;
  user_program_session_aggregate: User_Program_Session_Aggregate;
  user_program_session_by_pk?: Maybe<User_Program_Session>;
  user_program_session_enum: Array<User_Program_Session_Enum>;
  user_program_session_enum_aggregate: User_Program_Session_Enum_Aggregate;
  user_program_session_enum_by_pk?: Maybe<User_Program_Session_Enum>;
  user_reminder_info: Array<User_Reminder_Info>;
  user_reminder_info_aggregate: User_Reminder_Info_Aggregate;
  user_reminder_info_by_pk?: Maybe<User_Reminder_Info>;
  user_tag: Array<User_Tag>;
  user_tag_aggregate: User_Tag_Aggregate;
  user_tag_by_pk?: Maybe<User_Tag>;
  user_telehealth: Array<User_Telehealth>;
  user_telehealth_aggregate: User_Telehealth_Aggregate;
  user_telehealth_by_pk?: Maybe<User_Telehealth>;
  yes_no_question_enum: Array<Yes_No_Question_Enum>;
  yes_no_question_enum_aggregate: Yes_No_Question_Enum_Aggregate;
  yes_no_question_enum_by_pk?: Maybe<Yes_No_Question_Enum>;
};

export type Query_RootApnea_QuestionnaireArgs = {
  distinct_on?: InputMaybe<Array<Apnea_Questionnaire_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Apnea_Questionnaire_Order_By>>;
  where?: InputMaybe<Apnea_Questionnaire_Bool_Exp>;
};

export type Query_RootApnea_Questionnaire_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apnea_Questionnaire_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Apnea_Questionnaire_Order_By>>;
  where?: InputMaybe<Apnea_Questionnaire_Bool_Exp>;
};

export type Query_RootApnea_Questionnaire_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootApnea_Scan_PhotoArgs = {
  distinct_on?: InputMaybe<Array<Apnea_Scan_Photo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Apnea_Scan_Photo_Order_By>>;
  where?: InputMaybe<Apnea_Scan_Photo_Bool_Exp>;
};

export type Query_RootApnea_Scan_Photo_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apnea_Scan_Photo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Apnea_Scan_Photo_Order_By>>;
  where?: InputMaybe<Apnea_Scan_Photo_Bool_Exp>;
};

export type Query_RootApnea_Scan_Photo_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootApp_CustomizationArgs = {
  distinct_on?: InputMaybe<Array<App_Customization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<App_Customization_Order_By>>;
  where?: InputMaybe<App_Customization_Bool_Exp>;
};

export type Query_RootApp_Customization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Customization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<App_Customization_Order_By>>;
  where?: InputMaybe<App_Customization_Bool_Exp>;
};

export type Query_RootApp_Customization_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootApp_Customization_Type_EnumArgs = {
  distinct_on?: InputMaybe<Array<App_Customization_Type_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<App_Customization_Type_Enum_Order_By>>;
  where?: InputMaybe<App_Customization_Type_Enum_Bool_Exp>;
};

export type Query_RootApp_Customization_Type_Enum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Customization_Type_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<App_Customization_Type_Enum_Order_By>>;
  where?: InputMaybe<App_Customization_Type_Enum_Bool_Exp>;
};

export type Query_RootApp_Customization_Type_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Query_RootAppointmentArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Order_By>>;
  where?: InputMaybe<Appointment_Bool_Exp>;
};

export type Query_RootAppointment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Order_By>>;
  where?: InputMaybe<Appointment_Bool_Exp>;
};

export type Query_RootAppointment_By_PkArgs = {
  id: Scalars['String'];
};

export type Query_RootAppointment_Status_EnumArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Status_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Status_Enum_Order_By>>;
  where?: InputMaybe<Appointment_Status_Enum_Bool_Exp>;
};

export type Query_RootAppointment_Status_Enum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Status_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Status_Enum_Order_By>>;
  where?: InputMaybe<Appointment_Status_Enum_Bool_Exp>;
};

export type Query_RootAppointment_Status_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Query_RootAssesmentArgs = {
  distinct_on?: InputMaybe<Array<Assesment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assesment_Order_By>>;
  where?: InputMaybe<Assesment_Bool_Exp>;
};

export type Query_RootAssesment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assesment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assesment_Order_By>>;
  where?: InputMaybe<Assesment_Bool_Exp>;
};

export type Query_RootAssesment_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootB2b_Diagnosis_V1Args = {
  distinct_on?: InputMaybe<Array<B2b_Diagnosis_V1_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<B2b_Diagnosis_V1_Order_By>>;
  where?: InputMaybe<B2b_Diagnosis_V1_Bool_Exp>;
};

export type Query_RootB2b_Diagnosis_V1_AggregateArgs = {
  distinct_on?: InputMaybe<Array<B2b_Diagnosis_V1_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<B2b_Diagnosis_V1_Order_By>>;
  where?: InputMaybe<B2b_Diagnosis_V1_Bool_Exp>;
};

export type Query_RootB2b_Diagnosis_V1_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCatastrophic_ThinkingsArgs = {
  distinct_on?: InputMaybe<Array<Catastrophic_Thinkings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Catastrophic_Thinkings_Order_By>>;
  where?: InputMaybe<Catastrophic_Thinkings_Bool_Exp>;
};

export type Query_RootCatastrophic_Thinkings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Catastrophic_Thinkings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Catastrophic_Thinkings_Order_By>>;
  where?: InputMaybe<Catastrophic_Thinkings_Bool_Exp>;
};

export type Query_RootCatastrophic_Thinkings_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCommunity_UserArgs = {
  distinct_on?: InputMaybe<Array<Community_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_User_Order_By>>;
  where?: InputMaybe<Community_User_Bool_Exp>;
};

export type Query_RootCommunity_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Community_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_User_Order_By>>;
  where?: InputMaybe<Community_User_Bool_Exp>;
};

export type Query_RootCommunity_User_By_PkArgs = {
  community_id: Scalars['Int'];
};

export type Query_RootCompanyArgs = {
  distinct_on?: InputMaybe<Array<Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Order_By>>;
  where?: InputMaybe<Company_Bool_Exp>;
};

export type Query_RootCompany_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Order_By>>;
  where?: InputMaybe<Company_Bool_Exp>;
};

export type Query_RootCompany_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCompany_Health_CarerArgs = {
  distinct_on?: InputMaybe<Array<Company_Health_Carer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Health_Carer_Order_By>>;
  where?: InputMaybe<Company_Health_Carer_Bool_Exp>;
};

export type Query_RootCompany_Health_Carer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Health_Carer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Health_Carer_Order_By>>;
  where?: InputMaybe<Company_Health_Carer_Bool_Exp>;
};

export type Query_RootCompany_Health_Carer_By_PkArgs = {
  company_id: Scalars['Int'];
  health_carer_id: Scalars['String'];
};

export type Query_RootCompany_Health_Carer_Kind_EnumArgs = {
  distinct_on?: InputMaybe<Array<Company_Health_Carer_Kind_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Health_Carer_Kind_Enum_Order_By>>;
  where?: InputMaybe<Company_Health_Carer_Kind_Enum_Bool_Exp>;
};

export type Query_RootCompany_Health_Carer_Kind_Enum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Health_Carer_Kind_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Health_Carer_Kind_Enum_Order_By>>;
  where?: InputMaybe<Company_Health_Carer_Kind_Enum_Bool_Exp>;
};

export type Query_RootCompany_Health_Carer_Kind_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Query_RootCompany_UserArgs = {
  distinct_on?: InputMaybe<Array<Company_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_User_Order_By>>;
  where?: InputMaybe<Company_User_Bool_Exp>;
};

export type Query_RootCompany_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_User_Order_By>>;
  where?: InputMaybe<Company_User_Bool_Exp>;
};

export type Query_RootCompany_User_By_PkArgs = {
  company_id: Scalars['Int'];
  user_id: Scalars['String'];
};

export type Query_RootCouponArgs = {
  distinct_on?: InputMaybe<Array<Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coupon_Order_By>>;
  where?: InputMaybe<Coupon_Bool_Exp>;
};

export type Query_RootCoupon_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coupon_Order_By>>;
  where?: InputMaybe<Coupon_Bool_Exp>;
};

export type Query_RootCoupon_By_PkArgs = {
  code: Scalars['String'];
};

export type Query_RootCrmArgs = {
  distinct_on?: InputMaybe<Array<Crm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Crm_Order_By>>;
  where?: InputMaybe<Crm_Bool_Exp>;
};

export type Query_RootCrm_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Crm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Crm_Order_By>>;
  where?: InputMaybe<Crm_Bool_Exp>;
};

export type Query_RootCrm_By_PkArgs = {
  identification: Scalars['String'];
  state: Scalars['String'];
};

export type Query_RootDreamArgs = {
  distinct_on?: InputMaybe<Array<Dream_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dream_Order_By>>;
  where?: InputMaybe<Dream_Bool_Exp>;
};

export type Query_RootDream_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dream_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dream_Order_By>>;
  where?: InputMaybe<Dream_Bool_Exp>;
};

export type Query_RootDream_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootEficiencyArgs = {
  distinct_on?: InputMaybe<Array<Eficiency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eficiency_Order_By>>;
  where?: InputMaybe<Eficiency_Bool_Exp>;
};

export type Query_RootEficiency_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eficiency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eficiency_Order_By>>;
  where?: InputMaybe<Eficiency_Bool_Exp>;
};

export type Query_RootEficiency_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootEpworthArgs = {
  distinct_on?: InputMaybe<Array<Epworth_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Epworth_Order_By>>;
  where?: InputMaybe<Epworth_Bool_Exp>;
};

export type Query_RootEpworth_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Epworth_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Epworth_Order_By>>;
  where?: InputMaybe<Epworth_Bool_Exp>;
};

export type Query_RootEpworth_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootGadArgs = {
  distinct_on?: InputMaybe<Array<Gad_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gad_Order_By>>;
  where?: InputMaybe<Gad_Bool_Exp>;
};

export type Query_RootGad7Args = {
  distinct_on?: InputMaybe<Array<Gad7_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gad7_Order_By>>;
  where?: InputMaybe<Gad7_Bool_Exp>;
};

export type Query_RootGad7_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gad7_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gad7_Order_By>>;
  where?: InputMaybe<Gad7_Bool_Exp>;
};

export type Query_RootGad7_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootGad_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gad_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gad_Order_By>>;
  where?: InputMaybe<Gad_Bool_Exp>;
};

export type Query_RootGad_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootGeneric_QuestionnaireArgs = {
  distinct_on?: InputMaybe<Array<Generic_Questionnaire_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Generic_Questionnaire_Order_By>>;
  where?: InputMaybe<Generic_Questionnaire_Bool_Exp>;
};

export type Query_RootGeneric_Questionnaire_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Generic_Questionnaire_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Generic_Questionnaire_Order_By>>;
  where?: InputMaybe<Generic_Questionnaire_Bool_Exp>;
};

export type Query_RootGeneric_Questionnaire_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootGeneric_Questionnaire_Name_EnumArgs = {
  distinct_on?: InputMaybe<Array<Generic_Questionnaire_Name_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Generic_Questionnaire_Name_Enum_Order_By>>;
  where?: InputMaybe<Generic_Questionnaire_Name_Enum_Bool_Exp>;
};

export type Query_RootGeneric_Questionnaire_Name_Enum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Generic_Questionnaire_Name_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Generic_Questionnaire_Name_Enum_Order_By>>;
  where?: InputMaybe<Generic_Questionnaire_Name_Enum_Bool_Exp>;
};

export type Query_RootGeneric_Questionnaire_Name_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Query_RootGoogle_Sheet_RowArgs = {
  distinct_on?: InputMaybe<Array<Google_Sheet_Row_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Google_Sheet_Row_Order_By>>;
  where?: InputMaybe<Google_Sheet_Row_Bool_Exp>;
};

export type Query_RootGoogle_Sheet_Row_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Google_Sheet_Row_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Google_Sheet_Row_Order_By>>;
  where?: InputMaybe<Google_Sheet_Row_Bool_Exp>;
};

export type Query_RootGoogle_Sheet_Row_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootGratitudeArgs = {
  distinct_on?: InputMaybe<Array<Gratitude_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gratitude_Order_By>>;
  where?: InputMaybe<Gratitude_Bool_Exp>;
};

export type Query_RootGratitude_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gratitude_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gratitude_Order_By>>;
  where?: InputMaybe<Gratitude_Bool_Exp>;
};

export type Query_RootGratitude_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootHealth_CarerArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_Order_By>>;
  where?: InputMaybe<Health_Carer_Bool_Exp>;
};

export type Query_RootHealth_Carer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_Order_By>>;
  where?: InputMaybe<Health_Carer_Bool_Exp>;
};

export type Query_RootHealth_Carer_By_PkArgs = {
  id: Scalars['String'];
};

export type Query_RootHealth_Carer_Membership_Kind_EnumArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_Membership_Kind_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_Membership_Kind_Enum_Order_By>>;
  where?: InputMaybe<Health_Carer_Membership_Kind_Enum_Bool_Exp>;
};

export type Query_RootHealth_Carer_Membership_Kind_Enum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_Membership_Kind_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_Membership_Kind_Enum_Order_By>>;
  where?: InputMaybe<Health_Carer_Membership_Kind_Enum_Bool_Exp>;
};

export type Query_RootHealth_Carer_Membership_Kind_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Query_RootHealth_Carer_Money_RequestArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_Money_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_Money_Request_Order_By>>;
  where?: InputMaybe<Health_Carer_Money_Request_Bool_Exp>;
};

export type Query_RootHealth_Carer_Money_Request_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_Money_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_Money_Request_Order_By>>;
  where?: InputMaybe<Health_Carer_Money_Request_Bool_Exp>;
};

export type Query_RootHealth_Carer_Money_Request_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Query_RootHealth_Carer_Money_Request_LogArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_Money_Request_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_Money_Request_Log_Order_By>>;
  where?: InputMaybe<Health_Carer_Money_Request_Log_Bool_Exp>;
};

export type Query_RootHealth_Carer_Money_Request_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_Money_Request_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_Money_Request_Log_Order_By>>;
  where?: InputMaybe<Health_Carer_Money_Request_Log_Bool_Exp>;
};

export type Query_RootHealth_Carer_Money_Request_Log_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Query_RootHealth_Carer_Money_Request_Status_EnumArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_Money_Request_Status_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_Money_Request_Status_Enum_Order_By>>;
  where?: InputMaybe<Health_Carer_Money_Request_Status_Enum_Bool_Exp>;
};

export type Query_RootHealth_Carer_Money_Request_Status_Enum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_Money_Request_Status_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_Money_Request_Status_Enum_Order_By>>;
  where?: InputMaybe<Health_Carer_Money_Request_Status_Enum_Bool_Exp>;
};

export type Query_RootHealth_Carer_Money_Request_Status_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Query_RootHealth_Carer_Pagarme_RecipientArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_Pagarme_Recipient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_Pagarme_Recipient_Order_By>>;
  where?: InputMaybe<Health_Carer_Pagarme_Recipient_Bool_Exp>;
};

export type Query_RootHealth_Carer_Pagarme_Recipient_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_Pagarme_Recipient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_Pagarme_Recipient_Order_By>>;
  where?: InputMaybe<Health_Carer_Pagarme_Recipient_Bool_Exp>;
};

export type Query_RootHealth_Carer_Pagarme_Recipient_By_PkArgs = {
  health_carer_id: Scalars['String'];
};

export type Query_RootHealth_Carer_TelehealthArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_Telehealth_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_Telehealth_Order_By>>;
  where?: InputMaybe<Health_Carer_Telehealth_Bool_Exp>;
};

export type Query_RootHealth_Carer_Telehealth_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_Telehealth_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_Telehealth_Order_By>>;
  where?: InputMaybe<Health_Carer_Telehealth_Bool_Exp>;
};

export type Query_RootHealth_Carer_Telehealth_By_PkArgs = {
  health_carer_id: Scalars['String'];
};

export type Query_RootHealth_Carer_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_Transactions_Order_By>>;
  where?: InputMaybe<Health_Carer_Transactions_Bool_Exp>;
};

export type Query_RootHealth_Carer_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_Transactions_Order_By>>;
  where?: InputMaybe<Health_Carer_Transactions_Bool_Exp>;
};

export type Query_RootHealth_Carer_Transactions_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Query_RootHealth_Carer_UserArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_User_Order_By>>;
  where?: InputMaybe<Health_Carer_User_Bool_Exp>;
};

export type Query_RootHealth_Carer_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_User_Order_By>>;
  where?: InputMaybe<Health_Carer_User_Bool_Exp>;
};

export type Query_RootHealth_Carer_User_By_PkArgs = {
  health_carer_id: Scalars['String'];
  user_id: Scalars['String'];
};

export type Query_RootIsiArgs = {
  distinct_on?: InputMaybe<Array<Isi_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Isi_Order_By>>;
  where?: InputMaybe<Isi_Bool_Exp>;
};

export type Query_RootIsi_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Isi_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Isi_Order_By>>;
  where?: InputMaybe<Isi_Bool_Exp>;
};

export type Query_RootIsi_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootJourneyArgs = {
  distinct_on?: InputMaybe<Array<Journey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Journey_Order_By>>;
  where?: InputMaybe<Journey_Bool_Exp>;
};

export type Query_RootJourney_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Journey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Journey_Order_By>>;
  where?: InputMaybe<Journey_Bool_Exp>;
};

export type Query_RootJourney_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootJourney_Program_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Journey_Program_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Journey_Program_Sessions_Order_By>>;
  where?: InputMaybe<Journey_Program_Sessions_Bool_Exp>;
};

export type Query_RootJourney_Program_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Journey_Program_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Journey_Program_Sessions_Order_By>>;
  where?: InputMaybe<Journey_Program_Sessions_Bool_Exp>;
};

export type Query_RootJourney_Program_Sessions_By_PkArgs = {
  journey_id: Scalars['Int'];
  session_id: Scalars['Int'];
};

export type Query_RootKuppermanArgs = {
  distinct_on?: InputMaybe<Array<Kupperman_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kupperman_Order_By>>;
  where?: InputMaybe<Kupperman_Bool_Exp>;
};

export type Query_RootKupperman_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kupperman_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kupperman_Order_By>>;
  where?: InputMaybe<Kupperman_Bool_Exp>;
};

export type Query_RootKupperman_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootLanding_Page_RedirectArgs = {
  distinct_on?: InputMaybe<Array<Landing_Page_Redirect_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Landing_Page_Redirect_Order_By>>;
  where?: InputMaybe<Landing_Page_Redirect_Bool_Exp>;
};

export type Query_RootLanding_Page_Redirect_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Landing_Page_Redirect_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Landing_Page_Redirect_Order_By>>;
  where?: InputMaybe<Landing_Page_Redirect_Bool_Exp>;
};

export type Query_RootLanding_Page_Redirect_By_PkArgs = {
  from_path: Scalars['String'];
};

export type Query_RootLogArgs = {
  distinct_on?: InputMaybe<Array<Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Log_Order_By>>;
  where?: InputMaybe<Log_Bool_Exp>;
};

export type Query_RootLog_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Log_Order_By>>;
  where?: InputMaybe<Log_Bool_Exp>;
};

export type Query_RootLog_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootLog_Type_EnumArgs = {
  distinct_on?: InputMaybe<Array<Log_Type_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Log_Type_Enum_Order_By>>;
  where?: InputMaybe<Log_Type_Enum_Bool_Exp>;
};

export type Query_RootLog_Type_Enum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Log_Type_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Log_Type_Enum_Order_By>>;
  where?: InputMaybe<Log_Type_Enum_Bool_Exp>;
};

export type Query_RootLog_Type_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Query_RootMenopause_Greene_ScaleArgs = {
  distinct_on?: InputMaybe<Array<Menopause_Greene_Scale_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menopause_Greene_Scale_Order_By>>;
  where?: InputMaybe<Menopause_Greene_Scale_Bool_Exp>;
};

export type Query_RootMenopause_Greene_Scale_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Menopause_Greene_Scale_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menopause_Greene_Scale_Order_By>>;
  where?: InputMaybe<Menopause_Greene_Scale_Bool_Exp>;
};

export type Query_RootMenopause_Greene_Scale_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootMunich_ChronotypeArgs = {
  distinct_on?: InputMaybe<Array<Munich_Chronotype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Munich_Chronotype_Order_By>>;
  where?: InputMaybe<Munich_Chronotype_Bool_Exp>;
};

export type Query_RootMunich_Chronotype_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Munich_Chronotype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Munich_Chronotype_Order_By>>;
  where?: InputMaybe<Munich_Chronotype_Bool_Exp>;
};

export type Query_RootMunich_Chronotype_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootMunich_Chronotype_CoreArgs = {
  distinct_on?: InputMaybe<Array<Munich_Chronotype_Core_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Munich_Chronotype_Core_Order_By>>;
  where?: InputMaybe<Munich_Chronotype_Core_Bool_Exp>;
};

export type Query_RootMunich_Chronotype_Core_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Munich_Chronotype_Core_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Munich_Chronotype_Core_Order_By>>;
  where?: InputMaybe<Munich_Chronotype_Core_Bool_Exp>;
};

export type Query_RootMunich_Chronotype_Core_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootOs_EnumArgs = {
  distinct_on?: InputMaybe<Array<Os_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Os_Enum_Order_By>>;
  where?: InputMaybe<Os_Enum_Bool_Exp>;
};

export type Query_RootOs_Enum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Os_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Os_Enum_Order_By>>;
  where?: InputMaybe<Os_Enum_Bool_Exp>;
};

export type Query_RootOs_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Query_RootParking_LotArgs = {
  distinct_on?: InputMaybe<Array<Parking_Lot_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parking_Lot_Order_By>>;
  where?: InputMaybe<Parking_Lot_Bool_Exp>;
};

export type Query_RootParking_Lot_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parking_Lot_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parking_Lot_Order_By>>;
  where?: InputMaybe<Parking_Lot_Bool_Exp>;
};

export type Query_RootParking_Lot_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootPersonality_QuestionnaireArgs = {
  distinct_on?: InputMaybe<Array<Personality_Questionnaire_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Personality_Questionnaire_Order_By>>;
  where?: InputMaybe<Personality_Questionnaire_Bool_Exp>;
};

export type Query_RootPersonality_Questionnaire_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Personality_Questionnaire_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Personality_Questionnaire_Order_By>>;
  where?: InputMaybe<Personality_Questionnaire_Bool_Exp>;
};

export type Query_RootPersonality_Questionnaire_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootPhqArgs = {
  distinct_on?: InputMaybe<Array<Phq_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Phq_Order_By>>;
  where?: InputMaybe<Phq_Bool_Exp>;
};

export type Query_RootPhq9Args = {
  distinct_on?: InputMaybe<Array<Phq9_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Phq9_Order_By>>;
  where?: InputMaybe<Phq9_Bool_Exp>;
};

export type Query_RootPhq9_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Phq9_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Phq9_Order_By>>;
  where?: InputMaybe<Phq9_Bool_Exp>;
};

export type Query_RootPhq9_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootPhq_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Phq_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Phq_Order_By>>;
  where?: InputMaybe<Phq_Bool_Exp>;
};

export type Query_RootPhq_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootPre_UserArgs = {
  distinct_on?: InputMaybe<Array<Pre_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pre_User_Order_By>>;
  where?: InputMaybe<Pre_User_Bool_Exp>;
};

export type Query_RootPre_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pre_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pre_User_Order_By>>;
  where?: InputMaybe<Pre_User_Bool_Exp>;
};

export type Query_RootPre_User_By_PkArgs = {
  id: Scalars['String'];
};

export type Query_RootPre_User_Source_EnumArgs = {
  distinct_on?: InputMaybe<Array<Pre_User_Source_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pre_User_Source_Enum_Order_By>>;
  where?: InputMaybe<Pre_User_Source_Enum_Bool_Exp>;
};

export type Query_RootPre_User_Source_Enum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pre_User_Source_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pre_User_Source_Enum_Order_By>>;
  where?: InputMaybe<Pre_User_Source_Enum_Bool_Exp>;
};

export type Query_RootPre_User_Source_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Query_RootProductivityArgs = {
  distinct_on?: InputMaybe<Array<Productivity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Productivity_Order_By>>;
  where?: InputMaybe<Productivity_Bool_Exp>;
};

export type Query_RootProductivity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Productivity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Productivity_Order_By>>;
  where?: InputMaybe<Productivity_Bool_Exp>;
};

export type Query_RootProductivity_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootProgram_SessionArgs = {
  distinct_on?: InputMaybe<Array<Program_Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Program_Session_Order_By>>;
  where?: InputMaybe<Program_Session_Bool_Exp>;
};

export type Query_RootProgram_Session_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Program_Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Program_Session_Order_By>>;
  where?: InputMaybe<Program_Session_Bool_Exp>;
};

export type Query_RootProgram_Session_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootPsqiArgs = {
  distinct_on?: InputMaybe<Array<Psqi_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Psqi_Order_By>>;
  where?: InputMaybe<Psqi_Bool_Exp>;
};

export type Query_RootPsqi_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Psqi_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Psqi_Order_By>>;
  where?: InputMaybe<Psqi_Bool_Exp>;
};

export type Query_RootPsqi_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootRatingArgs = {
  distinct_on?: InputMaybe<Array<Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rating_Order_By>>;
  where?: InputMaybe<Rating_Bool_Exp>;
};

export type Query_RootRating_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rating_Order_By>>;
  where?: InputMaybe<Rating_Bool_Exp>;
};

export type Query_RootRating_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootRecurrence_Model_EnumArgs = {
  distinct_on?: InputMaybe<Array<Recurrence_Model_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recurrence_Model_Enum_Order_By>>;
  where?: InputMaybe<Recurrence_Model_Enum_Bool_Exp>;
};

export type Query_RootRecurrence_Model_Enum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recurrence_Model_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recurrence_Model_Enum_Order_By>>;
  where?: InputMaybe<Recurrence_Model_Enum_Bool_Exp>;
};

export type Query_RootRecurrence_Model_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Query_RootReminderArgs = {
  distinct_on?: InputMaybe<Array<Reminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reminder_Order_By>>;
  where?: InputMaybe<Reminder_Bool_Exp>;
};

export type Query_RootReminder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reminder_Order_By>>;
  where?: InputMaybe<Reminder_Bool_Exp>;
};

export type Query_RootReminder_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootReminder_Type_EnumArgs = {
  distinct_on?: InputMaybe<Array<Reminder_Type_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reminder_Type_Enum_Order_By>>;
  where?: InputMaybe<Reminder_Type_Enum_Bool_Exp>;
};

export type Query_RootReminder_Type_Enum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reminder_Type_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reminder_Type_Enum_Order_By>>;
  where?: InputMaybe<Reminder_Type_Enum_Bool_Exp>;
};

export type Query_RootReminder_Type_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Query_RootSleep_DiaryArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Diary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Diary_Order_By>>;
  where?: InputMaybe<Sleep_Diary_Bool_Exp>;
};

export type Query_RootSleep_Diary_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Diary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Diary_Order_By>>;
  where?: InputMaybe<Sleep_Diary_Bool_Exp>;
};

export type Query_RootSleep_Diary_Apple_HealthArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Diary_Apple_Health_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Diary_Apple_Health_Order_By>>;
  where?: InputMaybe<Sleep_Diary_Apple_Health_Bool_Exp>;
};

export type Query_RootSleep_Diary_Apple_Health_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Diary_Apple_Health_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Diary_Apple_Health_Order_By>>;
  where?: InputMaybe<Sleep_Diary_Apple_Health_Bool_Exp>;
};

export type Query_RootSleep_Diary_Apple_Health_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootSleep_Diary_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootSleep_Diary_Google_FitArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Diary_Google_Fit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Diary_Google_Fit_Order_By>>;
  where?: InputMaybe<Sleep_Diary_Google_Fit_Bool_Exp>;
};

export type Query_RootSleep_Diary_Google_Fit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Diary_Google_Fit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Diary_Google_Fit_Order_By>>;
  where?: InputMaybe<Sleep_Diary_Google_Fit_Bool_Exp>;
};

export type Query_RootSleep_Diary_Google_Fit_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootSleep_Diary_IntervalArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Diary_Interval_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Diary_Interval_Order_By>>;
  where?: InputMaybe<Sleep_Diary_Interval_Bool_Exp>;
};

export type Query_RootSleep_Diary_Interval_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Diary_Interval_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Diary_Interval_Order_By>>;
  where?: InputMaybe<Sleep_Diary_Interval_Bool_Exp>;
};

export type Query_RootSleep_Diary_Interval_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Query_RootSleep_Diary_MedicineArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Diary_Medicine_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Diary_Medicine_Order_By>>;
  where?: InputMaybe<Sleep_Diary_Medicine_Bool_Exp>;
};

export type Query_RootSleep_Diary_Medicine_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Diary_Medicine_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Diary_Medicine_Order_By>>;
  where?: InputMaybe<Sleep_Diary_Medicine_Bool_Exp>;
};

export type Query_RootSleep_Diary_Medicine_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootSleep_Diary_Sleep_TagArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Diary_Sleep_Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Diary_Sleep_Tag_Order_By>>;
  where?: InputMaybe<Sleep_Diary_Sleep_Tag_Bool_Exp>;
};

export type Query_RootSleep_Diary_Sleep_Tag_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Diary_Sleep_Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Diary_Sleep_Tag_Order_By>>;
  where?: InputMaybe<Sleep_Diary_Sleep_Tag_Bool_Exp>;
};

export type Query_RootSleep_Diary_Sleep_Tag_By_PkArgs = {
  sleep_diary: Scalars['Int'];
  sleep_tag: Sleep_Tag_Enum_Enum;
};

export type Query_RootSleep_RestrictionArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Restriction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Restriction_Order_By>>;
  where?: InputMaybe<Sleep_Restriction_Bool_Exp>;
};

export type Query_RootSleep_Restriction_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Restriction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Restriction_Order_By>>;
  where?: InputMaybe<Sleep_Restriction_Bool_Exp>;
};

export type Query_RootSleep_Restriction_By_PkArgs = {
  start_date: Scalars['date'];
  user_id: Scalars['String'];
};

export type Query_RootSleep_Tag_EnumArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Tag_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Tag_Enum_Order_By>>;
  where?: InputMaybe<Sleep_Tag_Enum_Bool_Exp>;
};

export type Query_RootSleep_Tag_Enum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Tag_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Tag_Enum_Order_By>>;
  where?: InputMaybe<Sleep_Tag_Enum_Bool_Exp>;
};

export type Query_RootSleep_Tag_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Query_RootSleep_Tracker_Compressed_DataArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Tracker_Compressed_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Tracker_Compressed_Data_Order_By>>;
  where?: InputMaybe<Sleep_Tracker_Compressed_Data_Bool_Exp>;
};

export type Query_RootSleep_Tracker_Compressed_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Tracker_Compressed_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Tracker_Compressed_Data_Order_By>>;
  where?: InputMaybe<Sleep_Tracker_Compressed_Data_Bool_Exp>;
};

export type Query_RootSleep_Tracker_Compressed_Data_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootStop_BangArgs = {
  distinct_on?: InputMaybe<Array<Stop_Bang_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stop_Bang_Order_By>>;
  where?: InputMaybe<Stop_Bang_Bool_Exp>;
};

export type Query_RootStop_Bang_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stop_Bang_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stop_Bang_Order_By>>;
  where?: InputMaybe<Stop_Bang_Bool_Exp>;
};

export type Query_RootStop_Bang_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootSubscriptionArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_Order_By>>;
  where?: InputMaybe<Subscription_Bool_Exp>;
};

export type Query_RootSubscription_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_Order_By>>;
  where?: InputMaybe<Subscription_Bool_Exp>;
};

export type Query_RootSubscription_By_PkArgs = {
  email: Scalars['String'];
};

export type Query_RootTechnique_ResourceArgs = {
  distinct_on?: InputMaybe<Array<Technique_Resource_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Technique_Resource_Order_By>>;
  where?: InputMaybe<Technique_Resource_Bool_Exp>;
};

export type Query_RootTechnique_Resource_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Technique_Resource_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Technique_Resource_Order_By>>;
  where?: InputMaybe<Technique_Resource_Bool_Exp>;
};

export type Query_RootTechnique_Resource_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootToolboxArgs = {
  distinct_on?: InputMaybe<Array<Toolbox_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Toolbox_Order_By>>;
  where?: InputMaybe<Toolbox_Bool_Exp>;
};

export type Query_RootToolbox_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Toolbox_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Toolbox_Order_By>>;
  where?: InputMaybe<Toolbox_Bool_Exp>;
};

export type Query_RootToolbox_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};

export type Query_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};

export type Query_RootUser_By_PkArgs = {
  id: Scalars['String'];
};

export type Query_RootUser_CheckoutArgs = {
  distinct_on?: InputMaybe<Array<User_Checkout_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Checkout_Order_By>>;
  where?: InputMaybe<User_Checkout_Bool_Exp>;
};

export type Query_RootUser_Checkout_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Checkout_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Checkout_Order_By>>;
  where?: InputMaybe<User_Checkout_Bool_Exp>;
};

export type Query_RootUser_Checkout_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootUser_CouponArgs = {
  distinct_on?: InputMaybe<Array<User_Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Coupon_Order_By>>;
  where?: InputMaybe<User_Coupon_Bool_Exp>;
};

export type Query_RootUser_Coupon_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Coupon_Order_By>>;
  where?: InputMaybe<User_Coupon_Bool_Exp>;
};

export type Query_RootUser_Coupon_By_PkArgs = {
  coupon_id: Scalars['bigint'];
  user_id: Scalars['String'];
};

export type Query_RootUser_JourneyArgs = {
  distinct_on?: InputMaybe<Array<User_Journey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Journey_Order_By>>;
  where?: InputMaybe<User_Journey_Bool_Exp>;
};

export type Query_RootUser_Journey_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Journey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Journey_Order_By>>;
  where?: InputMaybe<User_Journey_Bool_Exp>;
};

export type Query_RootUser_Journey_By_PkArgs = {
  journey_id: Scalars['Int'];
  user_id: Scalars['String'];
};

export type Query_RootUser_Key_ValueArgs = {
  distinct_on?: InputMaybe<Array<User_Key_Value_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Key_Value_Order_By>>;
  where?: InputMaybe<User_Key_Value_Bool_Exp>;
};

export type Query_RootUser_Key_Value_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Key_Value_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Key_Value_Order_By>>;
  where?: InputMaybe<User_Key_Value_Bool_Exp>;
};

export type Query_RootUser_Key_Value_By_PkArgs = {
  key: User_Key_Value_Key_Enum_Enum;
  user_id: Scalars['String'];
};

export type Query_RootUser_Key_Value_Key_EnumArgs = {
  distinct_on?: InputMaybe<Array<User_Key_Value_Key_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Key_Value_Key_Enum_Order_By>>;
  where?: InputMaybe<User_Key_Value_Key_Enum_Bool_Exp>;
};

export type Query_RootUser_Key_Value_Key_Enum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Key_Value_Key_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Key_Value_Key_Enum_Order_By>>;
  where?: InputMaybe<User_Key_Value_Key_Enum_Bool_Exp>;
};

export type Query_RootUser_Key_Value_Key_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Query_RootUser_NotificationArgs = {
  distinct_on?: InputMaybe<Array<User_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Notification_Order_By>>;
  where?: InputMaybe<User_Notification_Bool_Exp>;
};

export type Query_RootUser_Notification_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Notification_Order_By>>;
  where?: InputMaybe<User_Notification_Bool_Exp>;
};

export type Query_RootUser_Notification_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootUser_Program_SessionArgs = {
  distinct_on?: InputMaybe<Array<User_Program_Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Program_Session_Order_By>>;
  where?: InputMaybe<User_Program_Session_Bool_Exp>;
};

export type Query_RootUser_Program_Session_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Program_Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Program_Session_Order_By>>;
  where?: InputMaybe<User_Program_Session_Bool_Exp>;
};

export type Query_RootUser_Program_Session_By_PkArgs = {
  session_id: Scalars['Int'];
  user_id: Scalars['String'];
};

export type Query_RootUser_Program_Session_EnumArgs = {
  distinct_on?: InputMaybe<Array<User_Program_Session_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Program_Session_Enum_Order_By>>;
  where?: InputMaybe<User_Program_Session_Enum_Bool_Exp>;
};

export type Query_RootUser_Program_Session_Enum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Program_Session_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Program_Session_Enum_Order_By>>;
  where?: InputMaybe<User_Program_Session_Enum_Bool_Exp>;
};

export type Query_RootUser_Program_Session_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Query_RootUser_Reminder_InfoArgs = {
  distinct_on?: InputMaybe<Array<User_Reminder_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Reminder_Info_Order_By>>;
  where?: InputMaybe<User_Reminder_Info_Bool_Exp>;
};

export type Query_RootUser_Reminder_Info_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Reminder_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Reminder_Info_Order_By>>;
  where?: InputMaybe<User_Reminder_Info_Bool_Exp>;
};

export type Query_RootUser_Reminder_Info_By_PkArgs = {
  messenger_id: Scalars['String'];
};

export type Query_RootUser_TagArgs = {
  distinct_on?: InputMaybe<Array<User_Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Tag_Order_By>>;
  where?: InputMaybe<User_Tag_Bool_Exp>;
};

export type Query_RootUser_Tag_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Tag_Order_By>>;
  where?: InputMaybe<User_Tag_Bool_Exp>;
};

export type Query_RootUser_Tag_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootUser_TelehealthArgs = {
  distinct_on?: InputMaybe<Array<User_Telehealth_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Telehealth_Order_By>>;
  where?: InputMaybe<User_Telehealth_Bool_Exp>;
};

export type Query_RootUser_Telehealth_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Telehealth_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Telehealth_Order_By>>;
  where?: InputMaybe<User_Telehealth_Bool_Exp>;
};

export type Query_RootUser_Telehealth_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootYes_No_Question_EnumArgs = {
  distinct_on?: InputMaybe<Array<Yes_No_Question_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Yes_No_Question_Enum_Order_By>>;
  where?: InputMaybe<Yes_No_Question_Enum_Bool_Exp>;
};

export type Query_RootYes_No_Question_Enum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Yes_No_Question_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Yes_No_Question_Enum_Order_By>>;
  where?: InputMaybe<Yes_No_Question_Enum_Bool_Exp>;
};

export type Query_RootYes_No_Question_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Rating = {
  __typename?: 'rating';
  created: Scalars['timestamptz'];
  evaluator?: Maybe<User>;
  grade: Scalars['smallint'];
  id: Scalars['Int'];
  label: Scalars['String'];
  user: Scalars['String'];
};

export type Rating_Aggregate = {
  __typename?: 'rating_aggregate';
  aggregate?: Maybe<Rating_Aggregate_Fields>;
  nodes: Array<Rating>;
};

export type Rating_Aggregate_Fields = {
  __typename?: 'rating_aggregate_fields';
  avg?: Maybe<Rating_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Rating_Max_Fields>;
  min?: Maybe<Rating_Min_Fields>;
  stddev?: Maybe<Rating_Stddev_Fields>;
  stddev_pop?: Maybe<Rating_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rating_Stddev_Samp_Fields>;
  sum?: Maybe<Rating_Sum_Fields>;
  var_pop?: Maybe<Rating_Var_Pop_Fields>;
  var_samp?: Maybe<Rating_Var_Samp_Fields>;
  variance?: Maybe<Rating_Variance_Fields>;
};

export type Rating_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rating_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Rating_Aggregate_Order_By = {
  avg?: InputMaybe<Rating_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rating_Max_Order_By>;
  min?: InputMaybe<Rating_Min_Order_By>;
  stddev?: InputMaybe<Rating_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rating_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rating_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rating_Sum_Order_By>;
  var_pop?: InputMaybe<Rating_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rating_Var_Samp_Order_By>;
  variance?: InputMaybe<Rating_Variance_Order_By>;
};

export type Rating_Arr_Rel_Insert_Input = {
  data: Array<Rating_Insert_Input>;
  on_conflict?: InputMaybe<Rating_On_Conflict>;
};

export type Rating_Avg_Fields = {
  __typename?: 'rating_avg_fields';
  grade?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Rating_Avg_Order_By = {
  grade?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type Rating_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Rating_Bool_Exp>>>;
  _not?: InputMaybe<Rating_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rating_Bool_Exp>>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  evaluator?: InputMaybe<User_Bool_Exp>;
  grade?: InputMaybe<Smallint_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<String_Comparison_Exp>;
};

export enum Rating_Constraint {
  NpsPkey = 'nps_pkey',
}

export type Rating_Inc_Input = {
  grade?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['Int']>;
};

export type Rating_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  evaluator?: InputMaybe<User_Obj_Rel_Insert_Input>;
  grade?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};

export type Rating_Max_Fields = {
  __typename?: 'rating_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  grade?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
};

export type Rating_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  grade?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

export type Rating_Min_Fields = {
  __typename?: 'rating_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  grade?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
};

export type Rating_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  grade?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

export type Rating_Mutation_Response = {
  __typename?: 'rating_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Rating>;
};

export type Rating_Obj_Rel_Insert_Input = {
  data: Rating_Insert_Input;
  on_conflict?: InputMaybe<Rating_On_Conflict>;
};

export type Rating_On_Conflict = {
  constraint: Rating_Constraint;
  update_columns: Array<Rating_Update_Column>;
  where?: InputMaybe<Rating_Bool_Exp>;
};

export type Rating_Order_By = {
  created?: InputMaybe<Order_By>;
  evaluator?: InputMaybe<User_Order_By>;
  grade?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

export type Rating_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export enum Rating_Select_Column {
  Created = 'created',
  Grade = 'grade',
  Id = 'id',
  Label = 'label',
  User = 'user',
}

export type Rating_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  grade?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};

export type Rating_Stddev_Fields = {
  __typename?: 'rating_stddev_fields';
  grade?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Rating_Stddev_Order_By = {
  grade?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type Rating_Stddev_Pop_Fields = {
  __typename?: 'rating_stddev_pop_fields';
  grade?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Rating_Stddev_Pop_Order_By = {
  grade?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type Rating_Stddev_Samp_Fields = {
  __typename?: 'rating_stddev_samp_fields';
  grade?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Rating_Stddev_Samp_Order_By = {
  grade?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type Rating_Sum_Fields = {
  __typename?: 'rating_sum_fields';
  grade?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['Int']>;
};

export type Rating_Sum_Order_By = {
  grade?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export enum Rating_Update_Column {
  Created = 'created',
  Grade = 'grade',
  Id = 'id',
  Label = 'label',
  User = 'user',
}

export type Rating_Var_Pop_Fields = {
  __typename?: 'rating_var_pop_fields';
  grade?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Rating_Var_Pop_Order_By = {
  grade?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type Rating_Var_Samp_Fields = {
  __typename?: 'rating_var_samp_fields';
  grade?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Rating_Var_Samp_Order_By = {
  grade?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type Rating_Variance_Fields = {
  __typename?: 'rating_variance_fields';
  grade?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Rating_Variance_Order_By = {
  grade?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type Recurrence_Model_Enum = {
  __typename?: 'recurrence_model_enum';
  comment: Scalars['String'];
  text: Scalars['String'];
};

export type Recurrence_Model_Enum_Aggregate = {
  __typename?: 'recurrence_model_enum_aggregate';
  aggregate?: Maybe<Recurrence_Model_Enum_Aggregate_Fields>;
  nodes: Array<Recurrence_Model_Enum>;
};

export type Recurrence_Model_Enum_Aggregate_Fields = {
  __typename?: 'recurrence_model_enum_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Recurrence_Model_Enum_Max_Fields>;
  min?: Maybe<Recurrence_Model_Enum_Min_Fields>;
};

export type Recurrence_Model_Enum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Recurrence_Model_Enum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Recurrence_Model_Enum_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Recurrence_Model_Enum_Max_Order_By>;
  min?: InputMaybe<Recurrence_Model_Enum_Min_Order_By>;
};

export type Recurrence_Model_Enum_Arr_Rel_Insert_Input = {
  data: Array<Recurrence_Model_Enum_Insert_Input>;
  on_conflict?: InputMaybe<Recurrence_Model_Enum_On_Conflict>;
};

export type Recurrence_Model_Enum_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Recurrence_Model_Enum_Bool_Exp>>>;
  _not?: InputMaybe<Recurrence_Model_Enum_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Recurrence_Model_Enum_Bool_Exp>>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
};

export enum Recurrence_Model_Enum_Constraint {
  RecurrenceModelEnumPkey = 'recurrence_model_enum_pkey',
}

export enum Recurrence_Model_Enum_Enum {
  Monthly = 'MONTHLY',
  None = 'NONE',
  Yearly = 'YEARLY',
}

export type Recurrence_Model_Enum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Recurrence_Model_Enum_Enum>;
  _in?: InputMaybe<Array<Recurrence_Model_Enum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Recurrence_Model_Enum_Enum>;
  _nin?: InputMaybe<Array<Recurrence_Model_Enum_Enum>>;
};

export type Recurrence_Model_Enum_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type Recurrence_Model_Enum_Max_Fields = {
  __typename?: 'recurrence_model_enum_max_fields';
  comment?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type Recurrence_Model_Enum_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Recurrence_Model_Enum_Min_Fields = {
  __typename?: 'recurrence_model_enum_min_fields';
  comment?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type Recurrence_Model_Enum_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Recurrence_Model_Enum_Mutation_Response = {
  __typename?: 'recurrence_model_enum_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Recurrence_Model_Enum>;
};

export type Recurrence_Model_Enum_Obj_Rel_Insert_Input = {
  data: Recurrence_Model_Enum_Insert_Input;
  on_conflict?: InputMaybe<Recurrence_Model_Enum_On_Conflict>;
};

export type Recurrence_Model_Enum_On_Conflict = {
  constraint: Recurrence_Model_Enum_Constraint;
  update_columns: Array<Recurrence_Model_Enum_Update_Column>;
  where?: InputMaybe<Recurrence_Model_Enum_Bool_Exp>;
};

export type Recurrence_Model_Enum_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Recurrence_Model_Enum_Pk_Columns_Input = {
  text: Scalars['String'];
};

export enum Recurrence_Model_Enum_Select_Column {
  Comment = 'comment',
  Text = 'text',
}

export type Recurrence_Model_Enum_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export enum Recurrence_Model_Enum_Update_Column {
  Comment = 'comment',
  Text = 'text',
}

export type Reminder = {
  __typename?: 'reminder';
  created_at?: Maybe<Scalars['timestamptz']>;
  enabled: Scalars['Boolean'];
  extra_data?: Maybe<Scalars['jsonb']>;
  hour?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  messenger_id: Scalars['String'];
  minute?: Maybe<Scalars['Int']>;
  reminder_type: Reminder_Type_Enum;
  timezone?: Maybe<Scalars['Float']>;
  type: Reminder_Type_Enum_Enum;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_reminder_info: User_Reminder_Info;
};

export type ReminderExtra_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

export type Reminder_Aggregate = {
  __typename?: 'reminder_aggregate';
  aggregate?: Maybe<Reminder_Aggregate_Fields>;
  nodes: Array<Reminder>;
};

export type Reminder_Aggregate_Fields = {
  __typename?: 'reminder_aggregate_fields';
  avg?: Maybe<Reminder_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Reminder_Max_Fields>;
  min?: Maybe<Reminder_Min_Fields>;
  stddev?: Maybe<Reminder_Stddev_Fields>;
  stddev_pop?: Maybe<Reminder_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Reminder_Stddev_Samp_Fields>;
  sum?: Maybe<Reminder_Sum_Fields>;
  var_pop?: Maybe<Reminder_Var_Pop_Fields>;
  var_samp?: Maybe<Reminder_Var_Samp_Fields>;
  variance?: Maybe<Reminder_Variance_Fields>;
};

export type Reminder_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reminder_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Reminder_Aggregate_Order_By = {
  avg?: InputMaybe<Reminder_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Reminder_Max_Order_By>;
  min?: InputMaybe<Reminder_Min_Order_By>;
  stddev?: InputMaybe<Reminder_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Reminder_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Reminder_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Reminder_Sum_Order_By>;
  var_pop?: InputMaybe<Reminder_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Reminder_Var_Samp_Order_By>;
  variance?: InputMaybe<Reminder_Variance_Order_By>;
};

export type Reminder_Append_Input = {
  extra_data?: InputMaybe<Scalars['jsonb']>;
};

export type Reminder_Arr_Rel_Insert_Input = {
  data: Array<Reminder_Insert_Input>;
  on_conflict?: InputMaybe<Reminder_On_Conflict>;
};

export type Reminder_Avg_Fields = {
  __typename?: 'reminder_avg_fields';
  hour?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  minute?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['Float']>;
};

export type Reminder_Avg_Order_By = {
  hour?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  minute?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
};

export type Reminder_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Reminder_Bool_Exp>>>;
  _not?: InputMaybe<Reminder_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Reminder_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  enabled?: InputMaybe<Boolean_Comparison_Exp>;
  extra_data?: InputMaybe<Jsonb_Comparison_Exp>;
  hour?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  messenger_id?: InputMaybe<String_Comparison_Exp>;
  minute?: InputMaybe<Int_Comparison_Exp>;
  reminder_type?: InputMaybe<Reminder_Type_Enum_Bool_Exp>;
  timezone?: InputMaybe<Float_Comparison_Exp>;
  type?: InputMaybe<Reminder_Type_Enum_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_reminder_info?: InputMaybe<User_Reminder_Info_Bool_Exp>;
};

export enum Reminder_Constraint {
  ReminderMessengerIdTypeKey = 'reminder_messenger_id_type_key',
  ReminderPkey = 'reminder_pkey',
}

export type Reminder_Delete_At_Path_Input = {
  extra_data?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Reminder_Delete_Elem_Input = {
  extra_data?: InputMaybe<Scalars['Int']>;
};

export type Reminder_Delete_Key_Input = {
  extra_data?: InputMaybe<Scalars['String']>;
};

export type Reminder_Inc_Input = {
  hour?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  minute?: InputMaybe<Scalars['Int']>;
  timezone?: InputMaybe<Scalars['Float']>;
};

export type Reminder_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  extra_data?: InputMaybe<Scalars['jsonb']>;
  hour?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  messenger_id?: InputMaybe<Scalars['String']>;
  minute?: InputMaybe<Scalars['Int']>;
  reminder_type?: InputMaybe<Reminder_Type_Enum_Obj_Rel_Insert_Input>;
  timezone?: InputMaybe<Scalars['Float']>;
  type?: InputMaybe<Reminder_Type_Enum_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_reminder_info?: InputMaybe<User_Reminder_Info_Obj_Rel_Insert_Input>;
};

export type Reminder_Max_Fields = {
  __typename?: 'reminder_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  hour?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  messenger_id?: Maybe<Scalars['String']>;
  minute?: Maybe<Scalars['Int']>;
  timezone?: Maybe<Scalars['Float']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type Reminder_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  hour?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  messenger_id?: InputMaybe<Order_By>;
  minute?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type Reminder_Min_Fields = {
  __typename?: 'reminder_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  hour?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  messenger_id?: Maybe<Scalars['String']>;
  minute?: Maybe<Scalars['Int']>;
  timezone?: Maybe<Scalars['Float']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type Reminder_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  hour?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  messenger_id?: InputMaybe<Order_By>;
  minute?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type Reminder_Mutation_Response = {
  __typename?: 'reminder_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Reminder>;
};

export type Reminder_Obj_Rel_Insert_Input = {
  data: Reminder_Insert_Input;
  on_conflict?: InputMaybe<Reminder_On_Conflict>;
};

export type Reminder_On_Conflict = {
  constraint: Reminder_Constraint;
  update_columns: Array<Reminder_Update_Column>;
  where?: InputMaybe<Reminder_Bool_Exp>;
};

export type Reminder_Order_By = {
  created_at?: InputMaybe<Order_By>;
  enabled?: InputMaybe<Order_By>;
  extra_data?: InputMaybe<Order_By>;
  hour?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  messenger_id?: InputMaybe<Order_By>;
  minute?: InputMaybe<Order_By>;
  reminder_type?: InputMaybe<Reminder_Type_Enum_Order_By>;
  timezone?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_reminder_info?: InputMaybe<User_Reminder_Info_Order_By>;
};

export type Reminder_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export type Reminder_Prepend_Input = {
  extra_data?: InputMaybe<Scalars['jsonb']>;
};

export enum Reminder_Select_Column {
  CreatedAt = 'created_at',
  Enabled = 'enabled',
  ExtraData = 'extra_data',
  Hour = 'hour',
  Id = 'id',
  MessengerId = 'messenger_id',
  Minute = 'minute',
  Timezone = 'timezone',
  Type = 'type',
  UpdatedAt = 'updated_at',
}

export type Reminder_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  extra_data?: InputMaybe<Scalars['jsonb']>;
  hour?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  messenger_id?: InputMaybe<Scalars['String']>;
  minute?: InputMaybe<Scalars['Int']>;
  timezone?: InputMaybe<Scalars['Float']>;
  type?: InputMaybe<Reminder_Type_Enum_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Reminder_Stddev_Fields = {
  __typename?: 'reminder_stddev_fields';
  hour?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  minute?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['Float']>;
};

export type Reminder_Stddev_Order_By = {
  hour?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  minute?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
};

export type Reminder_Stddev_Pop_Fields = {
  __typename?: 'reminder_stddev_pop_fields';
  hour?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  minute?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['Float']>;
};

export type Reminder_Stddev_Pop_Order_By = {
  hour?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  minute?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
};

export type Reminder_Stddev_Samp_Fields = {
  __typename?: 'reminder_stddev_samp_fields';
  hour?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  minute?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['Float']>;
};

export type Reminder_Stddev_Samp_Order_By = {
  hour?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  minute?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
};

export type Reminder_Sum_Fields = {
  __typename?: 'reminder_sum_fields';
  hour?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  minute?: Maybe<Scalars['Int']>;
  timezone?: Maybe<Scalars['Float']>;
};

export type Reminder_Sum_Order_By = {
  hour?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  minute?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
};

export type Reminder_Type_Enum = {
  __typename?: 'reminder_type_enum';
  comment?: Maybe<Scalars['String']>;
  text: Scalars['String'];
};

export type Reminder_Type_Enum_Aggregate = {
  __typename?: 'reminder_type_enum_aggregate';
  aggregate?: Maybe<Reminder_Type_Enum_Aggregate_Fields>;
  nodes: Array<Reminder_Type_Enum>;
};

export type Reminder_Type_Enum_Aggregate_Fields = {
  __typename?: 'reminder_type_enum_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Reminder_Type_Enum_Max_Fields>;
  min?: Maybe<Reminder_Type_Enum_Min_Fields>;
};

export type Reminder_Type_Enum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reminder_Type_Enum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Reminder_Type_Enum_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Reminder_Type_Enum_Max_Order_By>;
  min?: InputMaybe<Reminder_Type_Enum_Min_Order_By>;
};

export type Reminder_Type_Enum_Arr_Rel_Insert_Input = {
  data: Array<Reminder_Type_Enum_Insert_Input>;
  on_conflict?: InputMaybe<Reminder_Type_Enum_On_Conflict>;
};

export type Reminder_Type_Enum_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Reminder_Type_Enum_Bool_Exp>>>;
  _not?: InputMaybe<Reminder_Type_Enum_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Reminder_Type_Enum_Bool_Exp>>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
};

export enum Reminder_Type_Enum_Constraint {
  ReminderTypeEnumPkey = 'reminder_type_enum_pkey',
}

export enum Reminder_Type_Enum_Enum {
  Session = 'SESSION',
  SleepDiary = 'SLEEP_DIARY',
  SleepTracker = 'SLEEP_TRACKER',
}

export type Reminder_Type_Enum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Reminder_Type_Enum_Enum>;
  _in?: InputMaybe<Array<Reminder_Type_Enum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Reminder_Type_Enum_Enum>;
  _nin?: InputMaybe<Array<Reminder_Type_Enum_Enum>>;
};

export type Reminder_Type_Enum_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type Reminder_Type_Enum_Max_Fields = {
  __typename?: 'reminder_type_enum_max_fields';
  comment?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type Reminder_Type_Enum_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Reminder_Type_Enum_Min_Fields = {
  __typename?: 'reminder_type_enum_min_fields';
  comment?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type Reminder_Type_Enum_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Reminder_Type_Enum_Mutation_Response = {
  __typename?: 'reminder_type_enum_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Reminder_Type_Enum>;
};

export type Reminder_Type_Enum_Obj_Rel_Insert_Input = {
  data: Reminder_Type_Enum_Insert_Input;
  on_conflict?: InputMaybe<Reminder_Type_Enum_On_Conflict>;
};

export type Reminder_Type_Enum_On_Conflict = {
  constraint: Reminder_Type_Enum_Constraint;
  update_columns: Array<Reminder_Type_Enum_Update_Column>;
  where?: InputMaybe<Reminder_Type_Enum_Bool_Exp>;
};

export type Reminder_Type_Enum_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Reminder_Type_Enum_Pk_Columns_Input = {
  text: Scalars['String'];
};

export enum Reminder_Type_Enum_Select_Column {
  Comment = 'comment',
  Text = 'text',
}

export type Reminder_Type_Enum_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export enum Reminder_Type_Enum_Update_Column {
  Comment = 'comment',
  Text = 'text',
}

export enum Reminder_Update_Column {
  CreatedAt = 'created_at',
  Enabled = 'enabled',
  ExtraData = 'extra_data',
  Hour = 'hour',
  Id = 'id',
  MessengerId = 'messenger_id',
  Minute = 'minute',
  Timezone = 'timezone',
  Type = 'type',
  UpdatedAt = 'updated_at',
}

export type Reminder_Var_Pop_Fields = {
  __typename?: 'reminder_var_pop_fields';
  hour?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  minute?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['Float']>;
};

export type Reminder_Var_Pop_Order_By = {
  hour?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  minute?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
};

export type Reminder_Var_Samp_Fields = {
  __typename?: 'reminder_var_samp_fields';
  hour?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  minute?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['Float']>;
};

export type Reminder_Var_Samp_Order_By = {
  hour?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  minute?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
};

export type Reminder_Variance_Fields = {
  __typename?: 'reminder_variance_fields';
  hour?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  minute?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['Float']>;
};

export type Reminder_Variance_Order_By = {
  hour?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  minute?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
};

export type Sleep_Diary = {
  __typename?: 'sleep_diary';
  autogenic_training?: Maybe<Scalars['Boolean']>;
  behavior_activation?: Maybe<Scalars['Boolean']>;
  challenge_catastrophic_thinking?: Maybe<Scalars['Boolean']>;
  comment?: Maybe<Scalars['String']>;
  concentration?: Maybe<Scalars['Float']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date: Scalars['date'];
  deep_breath?: Maybe<Scalars['Boolean']>;
  energy?: Maybe<Scalars['Float']>;
  get_up: Scalars['timetz'];
  go_bed: Scalars['timetz'];
  go_sleep: Scalars['timetz'];
  grade?: Maybe<Scalars['Float']>;
  gratitude?: Maybe<Scalars['Boolean']>;
  humor?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  imagery?: Maybe<Scalars['Boolean']>;
  light_therapy?: Maybe<Scalars['Boolean']>;
  medicine?: Maybe<Scalars['Boolean']>;
  meditation?: Maybe<Scalars['Boolean']>;
  paradoxical_intention?: Maybe<Scalars['Boolean']>;
  parking_lot?: Maybe<Scalars['Boolean']>;
  pmr?: Maybe<Scalars['Boolean']>;
  relationships?: Maybe<Scalars['Float']>;
  sleep_diary_medicines: Array<Sleep_Diary_Medicine>;
  sleep_diary_medicines_aggregate: Sleep_Diary_Medicine_Aggregate;
  snore_count?: Maybe<Scalars['smallint']>;
  snore_duration?: Maybe<Scalars['Int']>;
  stimulus_control?: Maybe<Scalars['Boolean']>;
  tags: Array<Sleep_Diary_Sleep_Tag>;
  tags_aggregate: Sleep_Diary_Sleep_Tag_Aggregate;
  thought_block?: Maybe<Scalars['Boolean']>;
  time_to_sleep: Scalars['numeric'];
  timezone?: Maybe<Scalars['smallint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user: User;
  user_id: Scalars['String'];
  wake_up: Scalars['timetz'];
  wake_up_count: Scalars['Int'];
  wake_up_duration: Scalars['Int'];
};

export type Sleep_DiarySleep_Diary_MedicinesArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Diary_Medicine_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Diary_Medicine_Order_By>>;
  where?: InputMaybe<Sleep_Diary_Medicine_Bool_Exp>;
};

export type Sleep_DiarySleep_Diary_Medicines_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Diary_Medicine_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Diary_Medicine_Order_By>>;
  where?: InputMaybe<Sleep_Diary_Medicine_Bool_Exp>;
};

export type Sleep_DiaryTagsArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Diary_Sleep_Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Diary_Sleep_Tag_Order_By>>;
  where?: InputMaybe<Sleep_Diary_Sleep_Tag_Bool_Exp>;
};

export type Sleep_DiaryTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Diary_Sleep_Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Diary_Sleep_Tag_Order_By>>;
  where?: InputMaybe<Sleep_Diary_Sleep_Tag_Bool_Exp>;
};

export type Sleep_Diary_Aggregate = {
  __typename?: 'sleep_diary_aggregate';
  aggregate?: Maybe<Sleep_Diary_Aggregate_Fields>;
  nodes: Array<Sleep_Diary>;
};

export type Sleep_Diary_Aggregate_Fields = {
  __typename?: 'sleep_diary_aggregate_fields';
  avg?: Maybe<Sleep_Diary_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Sleep_Diary_Max_Fields>;
  min?: Maybe<Sleep_Diary_Min_Fields>;
  stddev?: Maybe<Sleep_Diary_Stddev_Fields>;
  stddev_pop?: Maybe<Sleep_Diary_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sleep_Diary_Stddev_Samp_Fields>;
  sum?: Maybe<Sleep_Diary_Sum_Fields>;
  var_pop?: Maybe<Sleep_Diary_Var_Pop_Fields>;
  var_samp?: Maybe<Sleep_Diary_Var_Samp_Fields>;
  variance?: Maybe<Sleep_Diary_Variance_Fields>;
};

export type Sleep_Diary_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sleep_Diary_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Sleep_Diary_Aggregate_Order_By = {
  avg?: InputMaybe<Sleep_Diary_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Sleep_Diary_Max_Order_By>;
  min?: InputMaybe<Sleep_Diary_Min_Order_By>;
  stddev?: InputMaybe<Sleep_Diary_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Sleep_Diary_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Sleep_Diary_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Sleep_Diary_Sum_Order_By>;
  var_pop?: InputMaybe<Sleep_Diary_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Sleep_Diary_Var_Samp_Order_By>;
  variance?: InputMaybe<Sleep_Diary_Variance_Order_By>;
};

export type Sleep_Diary_Apple_Health = {
  __typename?: 'sleep_diary_apple_health';
  created_at: Scalars['timestamptz'];
  data: Scalars['jsonb'];
  date: Scalars['date'];
  id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['String'];
};

export type Sleep_Diary_Apple_HealthDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

export type Sleep_Diary_Apple_Health_Aggregate = {
  __typename?: 'sleep_diary_apple_health_aggregate';
  aggregate?: Maybe<Sleep_Diary_Apple_Health_Aggregate_Fields>;
  nodes: Array<Sleep_Diary_Apple_Health>;
};

export type Sleep_Diary_Apple_Health_Aggregate_Fields = {
  __typename?: 'sleep_diary_apple_health_aggregate_fields';
  avg?: Maybe<Sleep_Diary_Apple_Health_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Sleep_Diary_Apple_Health_Max_Fields>;
  min?: Maybe<Sleep_Diary_Apple_Health_Min_Fields>;
  stddev?: Maybe<Sleep_Diary_Apple_Health_Stddev_Fields>;
  stddev_pop?: Maybe<Sleep_Diary_Apple_Health_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sleep_Diary_Apple_Health_Stddev_Samp_Fields>;
  sum?: Maybe<Sleep_Diary_Apple_Health_Sum_Fields>;
  var_pop?: Maybe<Sleep_Diary_Apple_Health_Var_Pop_Fields>;
  var_samp?: Maybe<Sleep_Diary_Apple_Health_Var_Samp_Fields>;
  variance?: Maybe<Sleep_Diary_Apple_Health_Variance_Fields>;
};

export type Sleep_Diary_Apple_Health_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sleep_Diary_Apple_Health_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Sleep_Diary_Apple_Health_Aggregate_Order_By = {
  avg?: InputMaybe<Sleep_Diary_Apple_Health_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Sleep_Diary_Apple_Health_Max_Order_By>;
  min?: InputMaybe<Sleep_Diary_Apple_Health_Min_Order_By>;
  stddev?: InputMaybe<Sleep_Diary_Apple_Health_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Sleep_Diary_Apple_Health_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Sleep_Diary_Apple_Health_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Sleep_Diary_Apple_Health_Sum_Order_By>;
  var_pop?: InputMaybe<Sleep_Diary_Apple_Health_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Sleep_Diary_Apple_Health_Var_Samp_Order_By>;
  variance?: InputMaybe<Sleep_Diary_Apple_Health_Variance_Order_By>;
};

export type Sleep_Diary_Apple_Health_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

export type Sleep_Diary_Apple_Health_Arr_Rel_Insert_Input = {
  data: Array<Sleep_Diary_Apple_Health_Insert_Input>;
  on_conflict?: InputMaybe<Sleep_Diary_Apple_Health_On_Conflict>;
};

export type Sleep_Diary_Apple_Health_Avg_Fields = {
  __typename?: 'sleep_diary_apple_health_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Apple_Health_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Apple_Health_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Sleep_Diary_Apple_Health_Bool_Exp>>>;
  _not?: InputMaybe<Sleep_Diary_Apple_Health_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Sleep_Diary_Apple_Health_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

export enum Sleep_Diary_Apple_Health_Constraint {
  SleepDiaryAppleHealthPkey = 'sleep_diary_apple_health_pkey',
}

export type Sleep_Diary_Apple_Health_Delete_At_Path_Input = {
  data?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Sleep_Diary_Apple_Health_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']>;
};

export type Sleep_Diary_Apple_Health_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']>;
};

export type Sleep_Diary_Apple_Health_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

export type Sleep_Diary_Apple_Health_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Sleep_Diary_Apple_Health_Max_Fields = {
  __typename?: 'sleep_diary_apple_health_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Sleep_Diary_Apple_Health_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Apple_Health_Min_Fields = {
  __typename?: 'sleep_diary_apple_health_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Sleep_Diary_Apple_Health_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Apple_Health_Mutation_Response = {
  __typename?: 'sleep_diary_apple_health_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Sleep_Diary_Apple_Health>;
};

export type Sleep_Diary_Apple_Health_Obj_Rel_Insert_Input = {
  data: Sleep_Diary_Apple_Health_Insert_Input;
  on_conflict?: InputMaybe<Sleep_Diary_Apple_Health_On_Conflict>;
};

export type Sleep_Diary_Apple_Health_On_Conflict = {
  constraint: Sleep_Diary_Apple_Health_Constraint;
  update_columns: Array<Sleep_Diary_Apple_Health_Update_Column>;
  where?: InputMaybe<Sleep_Diary_Apple_Health_Bool_Exp>;
};

export type Sleep_Diary_Apple_Health_Order_By = {
  created_at?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Apple_Health_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export type Sleep_Diary_Apple_Health_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

export enum Sleep_Diary_Apple_Health_Select_Column {
  CreatedAt = 'created_at',
  Data = 'data',
  Date = 'date',
  Id = 'id',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Sleep_Diary_Apple_Health_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Sleep_Diary_Apple_Health_Stddev_Fields = {
  __typename?: 'sleep_diary_apple_health_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Apple_Health_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Apple_Health_Stddev_Pop_Fields = {
  __typename?: 'sleep_diary_apple_health_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Apple_Health_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Apple_Health_Stddev_Samp_Fields = {
  __typename?: 'sleep_diary_apple_health_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Apple_Health_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Apple_Health_Sum_Fields = {
  __typename?: 'sleep_diary_apple_health_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

export type Sleep_Diary_Apple_Health_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

export enum Sleep_Diary_Apple_Health_Update_Column {
  CreatedAt = 'created_at',
  Data = 'data',
  Date = 'date',
  Id = 'id',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Sleep_Diary_Apple_Health_Var_Pop_Fields = {
  __typename?: 'sleep_diary_apple_health_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Apple_Health_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Apple_Health_Var_Samp_Fields = {
  __typename?: 'sleep_diary_apple_health_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Apple_Health_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Apple_Health_Variance_Fields = {
  __typename?: 'sleep_diary_apple_health_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Apple_Health_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Arr_Rel_Insert_Input = {
  data: Array<Sleep_Diary_Insert_Input>;
  on_conflict?: InputMaybe<Sleep_Diary_On_Conflict>;
};

export type Sleep_Diary_Avg_Fields = {
  __typename?: 'sleep_diary_avg_fields';
  concentration?: Maybe<Scalars['Float']>;
  energy?: Maybe<Scalars['Float']>;
  grade?: Maybe<Scalars['Float']>;
  humor?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  relationships?: Maybe<Scalars['Float']>;
  snore_count?: Maybe<Scalars['Float']>;
  snore_duration?: Maybe<Scalars['Float']>;
  time_to_sleep?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['Float']>;
  wake_up_count?: Maybe<Scalars['Float']>;
  wake_up_duration?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Avg_Order_By = {
  concentration?: InputMaybe<Order_By>;
  energy?: InputMaybe<Order_By>;
  grade?: InputMaybe<Order_By>;
  humor?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  relationships?: InputMaybe<Order_By>;
  snore_count?: InputMaybe<Order_By>;
  snore_duration?: InputMaybe<Order_By>;
  time_to_sleep?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
  wake_up_count?: InputMaybe<Order_By>;
  wake_up_duration?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Sleep_Diary_Bool_Exp>>>;
  _not?: InputMaybe<Sleep_Diary_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Sleep_Diary_Bool_Exp>>>;
  autogenic_training?: InputMaybe<Boolean_Comparison_Exp>;
  behavior_activation?: InputMaybe<Boolean_Comparison_Exp>;
  challenge_catastrophic_thinking?: InputMaybe<Boolean_Comparison_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  concentration?: InputMaybe<Float_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  date?: InputMaybe<Date_Comparison_Exp>;
  deep_breath?: InputMaybe<Boolean_Comparison_Exp>;
  energy?: InputMaybe<Float_Comparison_Exp>;
  get_up?: InputMaybe<Timetz_Comparison_Exp>;
  go_bed?: InputMaybe<Timetz_Comparison_Exp>;
  go_sleep?: InputMaybe<Timetz_Comparison_Exp>;
  grade?: InputMaybe<Float_Comparison_Exp>;
  gratitude?: InputMaybe<Boolean_Comparison_Exp>;
  humor?: InputMaybe<Float_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  imagery?: InputMaybe<Boolean_Comparison_Exp>;
  light_therapy?: InputMaybe<Boolean_Comparison_Exp>;
  medicine?: InputMaybe<Boolean_Comparison_Exp>;
  meditation?: InputMaybe<Boolean_Comparison_Exp>;
  paradoxical_intention?: InputMaybe<Boolean_Comparison_Exp>;
  parking_lot?: InputMaybe<Boolean_Comparison_Exp>;
  pmr?: InputMaybe<Boolean_Comparison_Exp>;
  relationships?: InputMaybe<Float_Comparison_Exp>;
  sleep_diary_medicines?: InputMaybe<Sleep_Diary_Medicine_Bool_Exp>;
  snore_count?: InputMaybe<Smallint_Comparison_Exp>;
  snore_duration?: InputMaybe<Int_Comparison_Exp>;
  stimulus_control?: InputMaybe<Boolean_Comparison_Exp>;
  tags?: InputMaybe<Sleep_Diary_Sleep_Tag_Bool_Exp>;
  thought_block?: InputMaybe<Boolean_Comparison_Exp>;
  time_to_sleep?: InputMaybe<Numeric_Comparison_Exp>;
  timezone?: InputMaybe<Smallint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  wake_up?: InputMaybe<Timetz_Comparison_Exp>;
  wake_up_count?: InputMaybe<Int_Comparison_Exp>;
  wake_up_duration?: InputMaybe<Int_Comparison_Exp>;
};

export enum Sleep_Diary_Constraint {
  SleepDiaryDateUserIdKey = 'sleep_diary_date_user_id_key',
  SleepDiaryIdKey = 'sleep_diary_id_key',
  SleepDiaryPkey = 'sleep_diary_pkey',
}

export type Sleep_Diary_Google_Fit = {
  __typename?: 'sleep_diary_google_fit';
  created_at: Scalars['timestamptz'];
  data: Scalars['jsonb'];
  date: Scalars['date'];
  id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['String'];
};

export type Sleep_Diary_Google_FitDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

export type Sleep_Diary_Google_Fit_Aggregate = {
  __typename?: 'sleep_diary_google_fit_aggregate';
  aggregate?: Maybe<Sleep_Diary_Google_Fit_Aggregate_Fields>;
  nodes: Array<Sleep_Diary_Google_Fit>;
};

export type Sleep_Diary_Google_Fit_Aggregate_Fields = {
  __typename?: 'sleep_diary_google_fit_aggregate_fields';
  avg?: Maybe<Sleep_Diary_Google_Fit_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Sleep_Diary_Google_Fit_Max_Fields>;
  min?: Maybe<Sleep_Diary_Google_Fit_Min_Fields>;
  stddev?: Maybe<Sleep_Diary_Google_Fit_Stddev_Fields>;
  stddev_pop?: Maybe<Sleep_Diary_Google_Fit_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sleep_Diary_Google_Fit_Stddev_Samp_Fields>;
  sum?: Maybe<Sleep_Diary_Google_Fit_Sum_Fields>;
  var_pop?: Maybe<Sleep_Diary_Google_Fit_Var_Pop_Fields>;
  var_samp?: Maybe<Sleep_Diary_Google_Fit_Var_Samp_Fields>;
  variance?: Maybe<Sleep_Diary_Google_Fit_Variance_Fields>;
};

export type Sleep_Diary_Google_Fit_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sleep_Diary_Google_Fit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Sleep_Diary_Google_Fit_Aggregate_Order_By = {
  avg?: InputMaybe<Sleep_Diary_Google_Fit_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Sleep_Diary_Google_Fit_Max_Order_By>;
  min?: InputMaybe<Sleep_Diary_Google_Fit_Min_Order_By>;
  stddev?: InputMaybe<Sleep_Diary_Google_Fit_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Sleep_Diary_Google_Fit_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Sleep_Diary_Google_Fit_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Sleep_Diary_Google_Fit_Sum_Order_By>;
  var_pop?: InputMaybe<Sleep_Diary_Google_Fit_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Sleep_Diary_Google_Fit_Var_Samp_Order_By>;
  variance?: InputMaybe<Sleep_Diary_Google_Fit_Variance_Order_By>;
};

export type Sleep_Diary_Google_Fit_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

export type Sleep_Diary_Google_Fit_Arr_Rel_Insert_Input = {
  data: Array<Sleep_Diary_Google_Fit_Insert_Input>;
  on_conflict?: InputMaybe<Sleep_Diary_Google_Fit_On_Conflict>;
};

export type Sleep_Diary_Google_Fit_Avg_Fields = {
  __typename?: 'sleep_diary_google_fit_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Google_Fit_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Google_Fit_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Sleep_Diary_Google_Fit_Bool_Exp>>>;
  _not?: InputMaybe<Sleep_Diary_Google_Fit_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Sleep_Diary_Google_Fit_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

export enum Sleep_Diary_Google_Fit_Constraint {
  SleepDiaryGoogleFitPkey = 'sleep_diary_google_fit_pkey',
}

export type Sleep_Diary_Google_Fit_Delete_At_Path_Input = {
  data?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Sleep_Diary_Google_Fit_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']>;
};

export type Sleep_Diary_Google_Fit_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']>;
};

export type Sleep_Diary_Google_Fit_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

export type Sleep_Diary_Google_Fit_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Sleep_Diary_Google_Fit_Max_Fields = {
  __typename?: 'sleep_diary_google_fit_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Sleep_Diary_Google_Fit_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Google_Fit_Min_Fields = {
  __typename?: 'sleep_diary_google_fit_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Sleep_Diary_Google_Fit_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Google_Fit_Mutation_Response = {
  __typename?: 'sleep_diary_google_fit_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Sleep_Diary_Google_Fit>;
};

export type Sleep_Diary_Google_Fit_Obj_Rel_Insert_Input = {
  data: Sleep_Diary_Google_Fit_Insert_Input;
  on_conflict?: InputMaybe<Sleep_Diary_Google_Fit_On_Conflict>;
};

export type Sleep_Diary_Google_Fit_On_Conflict = {
  constraint: Sleep_Diary_Google_Fit_Constraint;
  update_columns: Array<Sleep_Diary_Google_Fit_Update_Column>;
  where?: InputMaybe<Sleep_Diary_Google_Fit_Bool_Exp>;
};

export type Sleep_Diary_Google_Fit_Order_By = {
  created_at?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Google_Fit_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export type Sleep_Diary_Google_Fit_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

export enum Sleep_Diary_Google_Fit_Select_Column {
  CreatedAt = 'created_at',
  Data = 'data',
  Date = 'date',
  Id = 'id',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Sleep_Diary_Google_Fit_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Sleep_Diary_Google_Fit_Stddev_Fields = {
  __typename?: 'sleep_diary_google_fit_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Google_Fit_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Google_Fit_Stddev_Pop_Fields = {
  __typename?: 'sleep_diary_google_fit_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Google_Fit_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Google_Fit_Stddev_Samp_Fields = {
  __typename?: 'sleep_diary_google_fit_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Google_Fit_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Google_Fit_Sum_Fields = {
  __typename?: 'sleep_diary_google_fit_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

export type Sleep_Diary_Google_Fit_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

export enum Sleep_Diary_Google_Fit_Update_Column {
  CreatedAt = 'created_at',
  Data = 'data',
  Date = 'date',
  Id = 'id',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Sleep_Diary_Google_Fit_Var_Pop_Fields = {
  __typename?: 'sleep_diary_google_fit_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Google_Fit_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Google_Fit_Var_Samp_Fields = {
  __typename?: 'sleep_diary_google_fit_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Google_Fit_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Google_Fit_Variance_Fields = {
  __typename?: 'sleep_diary_google_fit_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Google_Fit_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Inc_Input = {
  concentration?: InputMaybe<Scalars['Float']>;
  energy?: InputMaybe<Scalars['Float']>;
  grade?: InputMaybe<Scalars['Float']>;
  humor?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  relationships?: InputMaybe<Scalars['Float']>;
  snore_count?: InputMaybe<Scalars['smallint']>;
  snore_duration?: InputMaybe<Scalars['Int']>;
  time_to_sleep?: InputMaybe<Scalars['numeric']>;
  timezone?: InputMaybe<Scalars['smallint']>;
  wake_up_count?: InputMaybe<Scalars['Int']>;
  wake_up_duration?: InputMaybe<Scalars['Int']>;
};

export type Sleep_Diary_Insert_Input = {
  autogenic_training?: InputMaybe<Scalars['Boolean']>;
  behavior_activation?: InputMaybe<Scalars['Boolean']>;
  challenge_catastrophic_thinking?: InputMaybe<Scalars['Boolean']>;
  comment?: InputMaybe<Scalars['String']>;
  concentration?: InputMaybe<Scalars['Float']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['date']>;
  deep_breath?: InputMaybe<Scalars['Boolean']>;
  energy?: InputMaybe<Scalars['Float']>;
  get_up?: InputMaybe<Scalars['timetz']>;
  go_bed?: InputMaybe<Scalars['timetz']>;
  go_sleep?: InputMaybe<Scalars['timetz']>;
  grade?: InputMaybe<Scalars['Float']>;
  gratitude?: InputMaybe<Scalars['Boolean']>;
  humor?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  imagery?: InputMaybe<Scalars['Boolean']>;
  light_therapy?: InputMaybe<Scalars['Boolean']>;
  medicine?: InputMaybe<Scalars['Boolean']>;
  meditation?: InputMaybe<Scalars['Boolean']>;
  paradoxical_intention?: InputMaybe<Scalars['Boolean']>;
  parking_lot?: InputMaybe<Scalars['Boolean']>;
  pmr?: InputMaybe<Scalars['Boolean']>;
  relationships?: InputMaybe<Scalars['Float']>;
  sleep_diary_medicines?: InputMaybe<Sleep_Diary_Medicine_Arr_Rel_Insert_Input>;
  snore_count?: InputMaybe<Scalars['smallint']>;
  snore_duration?: InputMaybe<Scalars['Int']>;
  stimulus_control?: InputMaybe<Scalars['Boolean']>;
  tags?: InputMaybe<Sleep_Diary_Sleep_Tag_Arr_Rel_Insert_Input>;
  thought_block?: InputMaybe<Scalars['Boolean']>;
  time_to_sleep?: InputMaybe<Scalars['numeric']>;
  timezone?: InputMaybe<Scalars['smallint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
  wake_up?: InputMaybe<Scalars['timetz']>;
  wake_up_count?: InputMaybe<Scalars['Int']>;
  wake_up_duration?: InputMaybe<Scalars['Int']>;
};

export type Sleep_Diary_Interval = {
  __typename?: 'sleep_diary_interval';
  created_at: Scalars['timestamptz'];
  date: Scalars['date'];
  end: Scalars['bigint'];
  id: Scalars['bigint'];
  sleeping: Scalars['Boolean'];
  start: Scalars['bigint'];
  updated_at: Scalars['timestamptz'];
  user_id?: Maybe<Scalars['String']>;
};

export type Sleep_Diary_Interval_Aggregate = {
  __typename?: 'sleep_diary_interval_aggregate';
  aggregate?: Maybe<Sleep_Diary_Interval_Aggregate_Fields>;
  nodes: Array<Sleep_Diary_Interval>;
};

export type Sleep_Diary_Interval_Aggregate_Fields = {
  __typename?: 'sleep_diary_interval_aggregate_fields';
  avg?: Maybe<Sleep_Diary_Interval_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Sleep_Diary_Interval_Max_Fields>;
  min?: Maybe<Sleep_Diary_Interval_Min_Fields>;
  stddev?: Maybe<Sleep_Diary_Interval_Stddev_Fields>;
  stddev_pop?: Maybe<Sleep_Diary_Interval_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sleep_Diary_Interval_Stddev_Samp_Fields>;
  sum?: Maybe<Sleep_Diary_Interval_Sum_Fields>;
  var_pop?: Maybe<Sleep_Diary_Interval_Var_Pop_Fields>;
  var_samp?: Maybe<Sleep_Diary_Interval_Var_Samp_Fields>;
  variance?: Maybe<Sleep_Diary_Interval_Variance_Fields>;
};

export type Sleep_Diary_Interval_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sleep_Diary_Interval_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Sleep_Diary_Interval_Aggregate_Order_By = {
  avg?: InputMaybe<Sleep_Diary_Interval_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Sleep_Diary_Interval_Max_Order_By>;
  min?: InputMaybe<Sleep_Diary_Interval_Min_Order_By>;
  stddev?: InputMaybe<Sleep_Diary_Interval_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Sleep_Diary_Interval_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Sleep_Diary_Interval_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Sleep_Diary_Interval_Sum_Order_By>;
  var_pop?: InputMaybe<Sleep_Diary_Interval_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Sleep_Diary_Interval_Var_Samp_Order_By>;
  variance?: InputMaybe<Sleep_Diary_Interval_Variance_Order_By>;
};

export type Sleep_Diary_Interval_Arr_Rel_Insert_Input = {
  data: Array<Sleep_Diary_Interval_Insert_Input>;
  on_conflict?: InputMaybe<Sleep_Diary_Interval_On_Conflict>;
};

export type Sleep_Diary_Interval_Avg_Fields = {
  __typename?: 'sleep_diary_interval_avg_fields';
  end?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  start?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Interval_Avg_Order_By = {
  end?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Interval_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Sleep_Diary_Interval_Bool_Exp>>>;
  _not?: InputMaybe<Sleep_Diary_Interval_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Sleep_Diary_Interval_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  date?: InputMaybe<Date_Comparison_Exp>;
  end?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  sleeping?: InputMaybe<Boolean_Comparison_Exp>;
  start?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

export enum Sleep_Diary_Interval_Constraint {
  SleepDiaryIntervalPkey = 'sleep_diary_interval_pkey',
}

export type Sleep_Diary_Interval_Inc_Input = {
  end?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  start?: InputMaybe<Scalars['bigint']>;
};

export type Sleep_Diary_Interval_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['date']>;
  end?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  sleeping?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Sleep_Diary_Interval_Max_Fields = {
  __typename?: 'sleep_diary_interval_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  end?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  start?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Sleep_Diary_Interval_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  end?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Interval_Min_Fields = {
  __typename?: 'sleep_diary_interval_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  end?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  start?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Sleep_Diary_Interval_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  end?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Interval_Mutation_Response = {
  __typename?: 'sleep_diary_interval_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Sleep_Diary_Interval>;
};

export type Sleep_Diary_Interval_Obj_Rel_Insert_Input = {
  data: Sleep_Diary_Interval_Insert_Input;
  on_conflict?: InputMaybe<Sleep_Diary_Interval_On_Conflict>;
};

export type Sleep_Diary_Interval_On_Conflict = {
  constraint: Sleep_Diary_Interval_Constraint;
  update_columns: Array<Sleep_Diary_Interval_Update_Column>;
  where?: InputMaybe<Sleep_Diary_Interval_Bool_Exp>;
};

export type Sleep_Diary_Interval_Order_By = {
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  end?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sleeping?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Interval_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

export enum Sleep_Diary_Interval_Select_Column {
  CreatedAt = 'created_at',
  Date = 'date',
  End = 'end',
  Id = 'id',
  Sleeping = 'sleeping',
  Start = 'start',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Sleep_Diary_Interval_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['date']>;
  end?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  sleeping?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Sleep_Diary_Interval_Stddev_Fields = {
  __typename?: 'sleep_diary_interval_stddev_fields';
  end?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  start?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Interval_Stddev_Order_By = {
  end?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Interval_Stddev_Pop_Fields = {
  __typename?: 'sleep_diary_interval_stddev_pop_fields';
  end?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  start?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Interval_Stddev_Pop_Order_By = {
  end?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Interval_Stddev_Samp_Fields = {
  __typename?: 'sleep_diary_interval_stddev_samp_fields';
  end?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  start?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Interval_Stddev_Samp_Order_By = {
  end?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Interval_Sum_Fields = {
  __typename?: 'sleep_diary_interval_sum_fields';
  end?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  start?: Maybe<Scalars['bigint']>;
};

export type Sleep_Diary_Interval_Sum_Order_By = {
  end?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
};

export enum Sleep_Diary_Interval_Update_Column {
  CreatedAt = 'created_at',
  Date = 'date',
  End = 'end',
  Id = 'id',
  Sleeping = 'sleeping',
  Start = 'start',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Sleep_Diary_Interval_Var_Pop_Fields = {
  __typename?: 'sleep_diary_interval_var_pop_fields';
  end?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  start?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Interval_Var_Pop_Order_By = {
  end?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Interval_Var_Samp_Fields = {
  __typename?: 'sleep_diary_interval_var_samp_fields';
  end?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  start?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Interval_Var_Samp_Order_By = {
  end?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Interval_Variance_Fields = {
  __typename?: 'sleep_diary_interval_variance_fields';
  end?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  start?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Interval_Variance_Order_By = {
  end?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Max_Fields = {
  __typename?: 'sleep_diary_max_fields';
  comment?: Maybe<Scalars['String']>;
  concentration?: Maybe<Scalars['Float']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  energy?: Maybe<Scalars['Float']>;
  get_up?: Maybe<Scalars['timetz']>;
  go_bed?: Maybe<Scalars['timetz']>;
  go_sleep?: Maybe<Scalars['timetz']>;
  grade?: Maybe<Scalars['Float']>;
  humor?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  relationships?: Maybe<Scalars['Float']>;
  snore_count?: Maybe<Scalars['smallint']>;
  snore_duration?: Maybe<Scalars['Int']>;
  time_to_sleep?: Maybe<Scalars['numeric']>;
  timezone?: Maybe<Scalars['smallint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
  wake_up?: Maybe<Scalars['timetz']>;
  wake_up_count?: Maybe<Scalars['Int']>;
  wake_up_duration?: Maybe<Scalars['Int']>;
};

export type Sleep_Diary_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  concentration?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  energy?: InputMaybe<Order_By>;
  get_up?: InputMaybe<Order_By>;
  go_bed?: InputMaybe<Order_By>;
  go_sleep?: InputMaybe<Order_By>;
  grade?: InputMaybe<Order_By>;
  humor?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  relationships?: InputMaybe<Order_By>;
  snore_count?: InputMaybe<Order_By>;
  snore_duration?: InputMaybe<Order_By>;
  time_to_sleep?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  wake_up?: InputMaybe<Order_By>;
  wake_up_count?: InputMaybe<Order_By>;
  wake_up_duration?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Medicine = {
  __typename?: 'sleep_diary_medicine';
  dose: Scalars['Float'];
  id: Scalars['Int'];
  name: Scalars['String'];
  sleep_diary: Sleep_Diary;
  sleep_diary_id: Scalars['Int'];
};

export type Sleep_Diary_Medicine_Aggregate = {
  __typename?: 'sleep_diary_medicine_aggregate';
  aggregate?: Maybe<Sleep_Diary_Medicine_Aggregate_Fields>;
  nodes: Array<Sleep_Diary_Medicine>;
};

export type Sleep_Diary_Medicine_Aggregate_Fields = {
  __typename?: 'sleep_diary_medicine_aggregate_fields';
  avg?: Maybe<Sleep_Diary_Medicine_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Sleep_Diary_Medicine_Max_Fields>;
  min?: Maybe<Sleep_Diary_Medicine_Min_Fields>;
  stddev?: Maybe<Sleep_Diary_Medicine_Stddev_Fields>;
  stddev_pop?: Maybe<Sleep_Diary_Medicine_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sleep_Diary_Medicine_Stddev_Samp_Fields>;
  sum?: Maybe<Sleep_Diary_Medicine_Sum_Fields>;
  var_pop?: Maybe<Sleep_Diary_Medicine_Var_Pop_Fields>;
  var_samp?: Maybe<Sleep_Diary_Medicine_Var_Samp_Fields>;
  variance?: Maybe<Sleep_Diary_Medicine_Variance_Fields>;
};

export type Sleep_Diary_Medicine_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sleep_Diary_Medicine_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Sleep_Diary_Medicine_Aggregate_Order_By = {
  avg?: InputMaybe<Sleep_Diary_Medicine_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Sleep_Diary_Medicine_Max_Order_By>;
  min?: InputMaybe<Sleep_Diary_Medicine_Min_Order_By>;
  stddev?: InputMaybe<Sleep_Diary_Medicine_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Sleep_Diary_Medicine_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Sleep_Diary_Medicine_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Sleep_Diary_Medicine_Sum_Order_By>;
  var_pop?: InputMaybe<Sleep_Diary_Medicine_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Sleep_Diary_Medicine_Var_Samp_Order_By>;
  variance?: InputMaybe<Sleep_Diary_Medicine_Variance_Order_By>;
};

export type Sleep_Diary_Medicine_Arr_Rel_Insert_Input = {
  data: Array<Sleep_Diary_Medicine_Insert_Input>;
  on_conflict?: InputMaybe<Sleep_Diary_Medicine_On_Conflict>;
};

export type Sleep_Diary_Medicine_Avg_Fields = {
  __typename?: 'sleep_diary_medicine_avg_fields';
  dose?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sleep_diary_id?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Medicine_Avg_Order_By = {
  dose?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sleep_diary_id?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Medicine_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Sleep_Diary_Medicine_Bool_Exp>>>;
  _not?: InputMaybe<Sleep_Diary_Medicine_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Sleep_Diary_Medicine_Bool_Exp>>>;
  dose?: InputMaybe<Float_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  sleep_diary?: InputMaybe<Sleep_Diary_Bool_Exp>;
  sleep_diary_id?: InputMaybe<Int_Comparison_Exp>;
};

export enum Sleep_Diary_Medicine_Constraint {
  SleepDiaryMedicinePkey = 'sleep_diary_medicine_pkey',
}

export type Sleep_Diary_Medicine_Inc_Input = {
  dose?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  sleep_diary_id?: InputMaybe<Scalars['Int']>;
};

export type Sleep_Diary_Medicine_Insert_Input = {
  dose?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  sleep_diary?: InputMaybe<Sleep_Diary_Obj_Rel_Insert_Input>;
  sleep_diary_id?: InputMaybe<Scalars['Int']>;
};

export type Sleep_Diary_Medicine_Max_Fields = {
  __typename?: 'sleep_diary_medicine_max_fields';
  dose?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  sleep_diary_id?: Maybe<Scalars['Int']>;
};

export type Sleep_Diary_Medicine_Max_Order_By = {
  dose?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  sleep_diary_id?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Medicine_Min_Fields = {
  __typename?: 'sleep_diary_medicine_min_fields';
  dose?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  sleep_diary_id?: Maybe<Scalars['Int']>;
};

export type Sleep_Diary_Medicine_Min_Order_By = {
  dose?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  sleep_diary_id?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Medicine_Mutation_Response = {
  __typename?: 'sleep_diary_medicine_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Sleep_Diary_Medicine>;
};

export type Sleep_Diary_Medicine_Obj_Rel_Insert_Input = {
  data: Sleep_Diary_Medicine_Insert_Input;
  on_conflict?: InputMaybe<Sleep_Diary_Medicine_On_Conflict>;
};

export type Sleep_Diary_Medicine_On_Conflict = {
  constraint: Sleep_Diary_Medicine_Constraint;
  update_columns: Array<Sleep_Diary_Medicine_Update_Column>;
  where?: InputMaybe<Sleep_Diary_Medicine_Bool_Exp>;
};

export type Sleep_Diary_Medicine_Order_By = {
  dose?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  sleep_diary?: InputMaybe<Sleep_Diary_Order_By>;
  sleep_diary_id?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Medicine_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export enum Sleep_Diary_Medicine_Select_Column {
  Dose = 'dose',
  Id = 'id',
  Name = 'name',
  SleepDiaryId = 'sleep_diary_id',
}

export type Sleep_Diary_Medicine_Set_Input = {
  dose?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  sleep_diary_id?: InputMaybe<Scalars['Int']>;
};

export type Sleep_Diary_Medicine_Stddev_Fields = {
  __typename?: 'sleep_diary_medicine_stddev_fields';
  dose?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sleep_diary_id?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Medicine_Stddev_Order_By = {
  dose?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sleep_diary_id?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Medicine_Stddev_Pop_Fields = {
  __typename?: 'sleep_diary_medicine_stddev_pop_fields';
  dose?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sleep_diary_id?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Medicine_Stddev_Pop_Order_By = {
  dose?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sleep_diary_id?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Medicine_Stddev_Samp_Fields = {
  __typename?: 'sleep_diary_medicine_stddev_samp_fields';
  dose?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sleep_diary_id?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Medicine_Stddev_Samp_Order_By = {
  dose?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sleep_diary_id?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Medicine_Sum_Fields = {
  __typename?: 'sleep_diary_medicine_sum_fields';
  dose?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  sleep_diary_id?: Maybe<Scalars['Int']>;
};

export type Sleep_Diary_Medicine_Sum_Order_By = {
  dose?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sleep_diary_id?: InputMaybe<Order_By>;
};

export enum Sleep_Diary_Medicine_Update_Column {
  Dose = 'dose',
  Id = 'id',
  Name = 'name',
  SleepDiaryId = 'sleep_diary_id',
}

export type Sleep_Diary_Medicine_Var_Pop_Fields = {
  __typename?: 'sleep_diary_medicine_var_pop_fields';
  dose?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sleep_diary_id?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Medicine_Var_Pop_Order_By = {
  dose?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sleep_diary_id?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Medicine_Var_Samp_Fields = {
  __typename?: 'sleep_diary_medicine_var_samp_fields';
  dose?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sleep_diary_id?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Medicine_Var_Samp_Order_By = {
  dose?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sleep_diary_id?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Medicine_Variance_Fields = {
  __typename?: 'sleep_diary_medicine_variance_fields';
  dose?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sleep_diary_id?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Medicine_Variance_Order_By = {
  dose?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sleep_diary_id?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Min_Fields = {
  __typename?: 'sleep_diary_min_fields';
  comment?: Maybe<Scalars['String']>;
  concentration?: Maybe<Scalars['Float']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  energy?: Maybe<Scalars['Float']>;
  get_up?: Maybe<Scalars['timetz']>;
  go_bed?: Maybe<Scalars['timetz']>;
  go_sleep?: Maybe<Scalars['timetz']>;
  grade?: Maybe<Scalars['Float']>;
  humor?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  relationships?: Maybe<Scalars['Float']>;
  snore_count?: Maybe<Scalars['smallint']>;
  snore_duration?: Maybe<Scalars['Int']>;
  time_to_sleep?: Maybe<Scalars['numeric']>;
  timezone?: Maybe<Scalars['smallint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
  wake_up?: Maybe<Scalars['timetz']>;
  wake_up_count?: Maybe<Scalars['Int']>;
  wake_up_duration?: Maybe<Scalars['Int']>;
};

export type Sleep_Diary_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  concentration?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  energy?: InputMaybe<Order_By>;
  get_up?: InputMaybe<Order_By>;
  go_bed?: InputMaybe<Order_By>;
  go_sleep?: InputMaybe<Order_By>;
  grade?: InputMaybe<Order_By>;
  humor?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  relationships?: InputMaybe<Order_By>;
  snore_count?: InputMaybe<Order_By>;
  snore_duration?: InputMaybe<Order_By>;
  time_to_sleep?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  wake_up?: InputMaybe<Order_By>;
  wake_up_count?: InputMaybe<Order_By>;
  wake_up_duration?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Mutation_Response = {
  __typename?: 'sleep_diary_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Sleep_Diary>;
};

export type Sleep_Diary_Obj_Rel_Insert_Input = {
  data: Sleep_Diary_Insert_Input;
  on_conflict?: InputMaybe<Sleep_Diary_On_Conflict>;
};

export type Sleep_Diary_On_Conflict = {
  constraint: Sleep_Diary_Constraint;
  update_columns: Array<Sleep_Diary_Update_Column>;
  where?: InputMaybe<Sleep_Diary_Bool_Exp>;
};

export type Sleep_Diary_Order_By = {
  autogenic_training?: InputMaybe<Order_By>;
  behavior_activation?: InputMaybe<Order_By>;
  challenge_catastrophic_thinking?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  concentration?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  deep_breath?: InputMaybe<Order_By>;
  energy?: InputMaybe<Order_By>;
  get_up?: InputMaybe<Order_By>;
  go_bed?: InputMaybe<Order_By>;
  go_sleep?: InputMaybe<Order_By>;
  grade?: InputMaybe<Order_By>;
  gratitude?: InputMaybe<Order_By>;
  humor?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imagery?: InputMaybe<Order_By>;
  light_therapy?: InputMaybe<Order_By>;
  medicine?: InputMaybe<Order_By>;
  meditation?: InputMaybe<Order_By>;
  paradoxical_intention?: InputMaybe<Order_By>;
  parking_lot?: InputMaybe<Order_By>;
  pmr?: InputMaybe<Order_By>;
  relationships?: InputMaybe<Order_By>;
  sleep_diary_medicines_aggregate?: InputMaybe<Sleep_Diary_Medicine_Aggregate_Order_By>;
  snore_count?: InputMaybe<Order_By>;
  snore_duration?: InputMaybe<Order_By>;
  stimulus_control?: InputMaybe<Order_By>;
  tags_aggregate?: InputMaybe<Sleep_Diary_Sleep_Tag_Aggregate_Order_By>;
  thought_block?: InputMaybe<Order_By>;
  time_to_sleep?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
  wake_up?: InputMaybe<Order_By>;
  wake_up_count?: InputMaybe<Order_By>;
  wake_up_duration?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export enum Sleep_Diary_Select_Column {
  AutogenicTraining = 'autogenic_training',
  BehaviorActivation = 'behavior_activation',
  ChallengeCatastrophicThinking = 'challenge_catastrophic_thinking',
  Comment = 'comment',
  Concentration = 'concentration',
  CreatedAt = 'created_at',
  Date = 'date',
  DeepBreath = 'deep_breath',
  Energy = 'energy',
  GetUp = 'get_up',
  GoBed = 'go_bed',
  GoSleep = 'go_sleep',
  Grade = 'grade',
  Gratitude = 'gratitude',
  Humor = 'humor',
  Id = 'id',
  Imagery = 'imagery',
  LightTherapy = 'light_therapy',
  Medicine = 'medicine',
  Meditation = 'meditation',
  ParadoxicalIntention = 'paradoxical_intention',
  ParkingLot = 'parking_lot',
  Pmr = 'pmr',
  Relationships = 'relationships',
  SnoreCount = 'snore_count',
  SnoreDuration = 'snore_duration',
  StimulusControl = 'stimulus_control',
  ThoughtBlock = 'thought_block',
  TimeToSleep = 'time_to_sleep',
  Timezone = 'timezone',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
  WakeUp = 'wake_up',
  WakeUpCount = 'wake_up_count',
  WakeUpDuration = 'wake_up_duration',
}

export type Sleep_Diary_Set_Input = {
  autogenic_training?: InputMaybe<Scalars['Boolean']>;
  behavior_activation?: InputMaybe<Scalars['Boolean']>;
  challenge_catastrophic_thinking?: InputMaybe<Scalars['Boolean']>;
  comment?: InputMaybe<Scalars['String']>;
  concentration?: InputMaybe<Scalars['Float']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['date']>;
  deep_breath?: InputMaybe<Scalars['Boolean']>;
  energy?: InputMaybe<Scalars['Float']>;
  get_up?: InputMaybe<Scalars['timetz']>;
  go_bed?: InputMaybe<Scalars['timetz']>;
  go_sleep?: InputMaybe<Scalars['timetz']>;
  grade?: InputMaybe<Scalars['Float']>;
  gratitude?: InputMaybe<Scalars['Boolean']>;
  humor?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  imagery?: InputMaybe<Scalars['Boolean']>;
  light_therapy?: InputMaybe<Scalars['Boolean']>;
  medicine?: InputMaybe<Scalars['Boolean']>;
  meditation?: InputMaybe<Scalars['Boolean']>;
  paradoxical_intention?: InputMaybe<Scalars['Boolean']>;
  parking_lot?: InputMaybe<Scalars['Boolean']>;
  pmr?: InputMaybe<Scalars['Boolean']>;
  relationships?: InputMaybe<Scalars['Float']>;
  snore_count?: InputMaybe<Scalars['smallint']>;
  snore_duration?: InputMaybe<Scalars['Int']>;
  stimulus_control?: InputMaybe<Scalars['Boolean']>;
  thought_block?: InputMaybe<Scalars['Boolean']>;
  time_to_sleep?: InputMaybe<Scalars['numeric']>;
  timezone?: InputMaybe<Scalars['smallint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
  wake_up?: InputMaybe<Scalars['timetz']>;
  wake_up_count?: InputMaybe<Scalars['Int']>;
  wake_up_duration?: InputMaybe<Scalars['Int']>;
};

export type Sleep_Diary_Sleep_Tag = {
  __typename?: 'sleep_diary_sleep_tag';
  sleep_diary: Scalars['Int'];
  sleep_diary_tags: Sleep_Diary;
  sleep_tag: Sleep_Tag_Enum_Enum;
  tag_sleep_diaries: Sleep_Tag_Enum;
};

export type Sleep_Diary_Sleep_Tag_Aggregate = {
  __typename?: 'sleep_diary_sleep_tag_aggregate';
  aggregate?: Maybe<Sleep_Diary_Sleep_Tag_Aggregate_Fields>;
  nodes: Array<Sleep_Diary_Sleep_Tag>;
};

export type Sleep_Diary_Sleep_Tag_Aggregate_Fields = {
  __typename?: 'sleep_diary_sleep_tag_aggregate_fields';
  avg?: Maybe<Sleep_Diary_Sleep_Tag_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Sleep_Diary_Sleep_Tag_Max_Fields>;
  min?: Maybe<Sleep_Diary_Sleep_Tag_Min_Fields>;
  stddev?: Maybe<Sleep_Diary_Sleep_Tag_Stddev_Fields>;
  stddev_pop?: Maybe<Sleep_Diary_Sleep_Tag_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sleep_Diary_Sleep_Tag_Stddev_Samp_Fields>;
  sum?: Maybe<Sleep_Diary_Sleep_Tag_Sum_Fields>;
  var_pop?: Maybe<Sleep_Diary_Sleep_Tag_Var_Pop_Fields>;
  var_samp?: Maybe<Sleep_Diary_Sleep_Tag_Var_Samp_Fields>;
  variance?: Maybe<Sleep_Diary_Sleep_Tag_Variance_Fields>;
};

export type Sleep_Diary_Sleep_Tag_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sleep_Diary_Sleep_Tag_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Sleep_Diary_Sleep_Tag_Aggregate_Order_By = {
  avg?: InputMaybe<Sleep_Diary_Sleep_Tag_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Sleep_Diary_Sleep_Tag_Max_Order_By>;
  min?: InputMaybe<Sleep_Diary_Sleep_Tag_Min_Order_By>;
  stddev?: InputMaybe<Sleep_Diary_Sleep_Tag_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Sleep_Diary_Sleep_Tag_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Sleep_Diary_Sleep_Tag_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Sleep_Diary_Sleep_Tag_Sum_Order_By>;
  var_pop?: InputMaybe<Sleep_Diary_Sleep_Tag_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Sleep_Diary_Sleep_Tag_Var_Samp_Order_By>;
  variance?: InputMaybe<Sleep_Diary_Sleep_Tag_Variance_Order_By>;
};

export type Sleep_Diary_Sleep_Tag_Arr_Rel_Insert_Input = {
  data: Array<Sleep_Diary_Sleep_Tag_Insert_Input>;
  on_conflict?: InputMaybe<Sleep_Diary_Sleep_Tag_On_Conflict>;
};

export type Sleep_Diary_Sleep_Tag_Avg_Fields = {
  __typename?: 'sleep_diary_sleep_tag_avg_fields';
  sleep_diary?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Sleep_Tag_Avg_Order_By = {
  sleep_diary?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Sleep_Tag_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Sleep_Diary_Sleep_Tag_Bool_Exp>>>;
  _not?: InputMaybe<Sleep_Diary_Sleep_Tag_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Sleep_Diary_Sleep_Tag_Bool_Exp>>>;
  sleep_diary?: InputMaybe<Int_Comparison_Exp>;
  sleep_diary_tags?: InputMaybe<Sleep_Diary_Bool_Exp>;
  sleep_tag?: InputMaybe<Sleep_Tag_Enum_Enum_Comparison_Exp>;
  tag_sleep_diaries?: InputMaybe<Sleep_Tag_Enum_Bool_Exp>;
};

export enum Sleep_Diary_Sleep_Tag_Constraint {
  SleepDiarySleepTagPkey = 'sleep_diary_sleep_tag_pkey',
}

export type Sleep_Diary_Sleep_Tag_Inc_Input = {
  sleep_diary?: InputMaybe<Scalars['Int']>;
};

export type Sleep_Diary_Sleep_Tag_Insert_Input = {
  sleep_diary?: InputMaybe<Scalars['Int']>;
  sleep_diary_tags?: InputMaybe<Sleep_Diary_Obj_Rel_Insert_Input>;
  sleep_tag?: InputMaybe<Sleep_Tag_Enum_Enum>;
  tag_sleep_diaries?: InputMaybe<Sleep_Tag_Enum_Obj_Rel_Insert_Input>;
};

export type Sleep_Diary_Sleep_Tag_Max_Fields = {
  __typename?: 'sleep_diary_sleep_tag_max_fields';
  sleep_diary?: Maybe<Scalars['Int']>;
};

export type Sleep_Diary_Sleep_Tag_Max_Order_By = {
  sleep_diary?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Sleep_Tag_Min_Fields = {
  __typename?: 'sleep_diary_sleep_tag_min_fields';
  sleep_diary?: Maybe<Scalars['Int']>;
};

export type Sleep_Diary_Sleep_Tag_Min_Order_By = {
  sleep_diary?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Sleep_Tag_Mutation_Response = {
  __typename?: 'sleep_diary_sleep_tag_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Sleep_Diary_Sleep_Tag>;
};

export type Sleep_Diary_Sleep_Tag_Obj_Rel_Insert_Input = {
  data: Sleep_Diary_Sleep_Tag_Insert_Input;
  on_conflict?: InputMaybe<Sleep_Diary_Sleep_Tag_On_Conflict>;
};

export type Sleep_Diary_Sleep_Tag_On_Conflict = {
  constraint: Sleep_Diary_Sleep_Tag_Constraint;
  update_columns: Array<Sleep_Diary_Sleep_Tag_Update_Column>;
  where?: InputMaybe<Sleep_Diary_Sleep_Tag_Bool_Exp>;
};

export type Sleep_Diary_Sleep_Tag_Order_By = {
  sleep_diary?: InputMaybe<Order_By>;
  sleep_diary_tags?: InputMaybe<Sleep_Diary_Order_By>;
  sleep_tag?: InputMaybe<Order_By>;
  tag_sleep_diaries?: InputMaybe<Sleep_Tag_Enum_Order_By>;
};

export type Sleep_Diary_Sleep_Tag_Pk_Columns_Input = {
  sleep_diary: Scalars['Int'];
  sleep_tag: Sleep_Tag_Enum_Enum;
};

export enum Sleep_Diary_Sleep_Tag_Select_Column {
  SleepDiary = 'sleep_diary',
  SleepTag = 'sleep_tag',
}

export type Sleep_Diary_Sleep_Tag_Set_Input = {
  sleep_diary?: InputMaybe<Scalars['Int']>;
  sleep_tag?: InputMaybe<Sleep_Tag_Enum_Enum>;
};

export type Sleep_Diary_Sleep_Tag_Stddev_Fields = {
  __typename?: 'sleep_diary_sleep_tag_stddev_fields';
  sleep_diary?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Sleep_Tag_Stddev_Order_By = {
  sleep_diary?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Sleep_Tag_Stddev_Pop_Fields = {
  __typename?: 'sleep_diary_sleep_tag_stddev_pop_fields';
  sleep_diary?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Sleep_Tag_Stddev_Pop_Order_By = {
  sleep_diary?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Sleep_Tag_Stddev_Samp_Fields = {
  __typename?: 'sleep_diary_sleep_tag_stddev_samp_fields';
  sleep_diary?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Sleep_Tag_Stddev_Samp_Order_By = {
  sleep_diary?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Sleep_Tag_Sum_Fields = {
  __typename?: 'sleep_diary_sleep_tag_sum_fields';
  sleep_diary?: Maybe<Scalars['Int']>;
};

export type Sleep_Diary_Sleep_Tag_Sum_Order_By = {
  sleep_diary?: InputMaybe<Order_By>;
};

export enum Sleep_Diary_Sleep_Tag_Update_Column {
  SleepDiary = 'sleep_diary',
  SleepTag = 'sleep_tag',
}

export type Sleep_Diary_Sleep_Tag_Var_Pop_Fields = {
  __typename?: 'sleep_diary_sleep_tag_var_pop_fields';
  sleep_diary?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Sleep_Tag_Var_Pop_Order_By = {
  sleep_diary?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Sleep_Tag_Var_Samp_Fields = {
  __typename?: 'sleep_diary_sleep_tag_var_samp_fields';
  sleep_diary?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Sleep_Tag_Var_Samp_Order_By = {
  sleep_diary?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Sleep_Tag_Variance_Fields = {
  __typename?: 'sleep_diary_sleep_tag_variance_fields';
  sleep_diary?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Sleep_Tag_Variance_Order_By = {
  sleep_diary?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Stddev_Fields = {
  __typename?: 'sleep_diary_stddev_fields';
  concentration?: Maybe<Scalars['Float']>;
  energy?: Maybe<Scalars['Float']>;
  grade?: Maybe<Scalars['Float']>;
  humor?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  relationships?: Maybe<Scalars['Float']>;
  snore_count?: Maybe<Scalars['Float']>;
  snore_duration?: Maybe<Scalars['Float']>;
  time_to_sleep?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['Float']>;
  wake_up_count?: Maybe<Scalars['Float']>;
  wake_up_duration?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Stddev_Order_By = {
  concentration?: InputMaybe<Order_By>;
  energy?: InputMaybe<Order_By>;
  grade?: InputMaybe<Order_By>;
  humor?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  relationships?: InputMaybe<Order_By>;
  snore_count?: InputMaybe<Order_By>;
  snore_duration?: InputMaybe<Order_By>;
  time_to_sleep?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
  wake_up_count?: InputMaybe<Order_By>;
  wake_up_duration?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Stddev_Pop_Fields = {
  __typename?: 'sleep_diary_stddev_pop_fields';
  concentration?: Maybe<Scalars['Float']>;
  energy?: Maybe<Scalars['Float']>;
  grade?: Maybe<Scalars['Float']>;
  humor?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  relationships?: Maybe<Scalars['Float']>;
  snore_count?: Maybe<Scalars['Float']>;
  snore_duration?: Maybe<Scalars['Float']>;
  time_to_sleep?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['Float']>;
  wake_up_count?: Maybe<Scalars['Float']>;
  wake_up_duration?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Stddev_Pop_Order_By = {
  concentration?: InputMaybe<Order_By>;
  energy?: InputMaybe<Order_By>;
  grade?: InputMaybe<Order_By>;
  humor?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  relationships?: InputMaybe<Order_By>;
  snore_count?: InputMaybe<Order_By>;
  snore_duration?: InputMaybe<Order_By>;
  time_to_sleep?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
  wake_up_count?: InputMaybe<Order_By>;
  wake_up_duration?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Stddev_Samp_Fields = {
  __typename?: 'sleep_diary_stddev_samp_fields';
  concentration?: Maybe<Scalars['Float']>;
  energy?: Maybe<Scalars['Float']>;
  grade?: Maybe<Scalars['Float']>;
  humor?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  relationships?: Maybe<Scalars['Float']>;
  snore_count?: Maybe<Scalars['Float']>;
  snore_duration?: Maybe<Scalars['Float']>;
  time_to_sleep?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['Float']>;
  wake_up_count?: Maybe<Scalars['Float']>;
  wake_up_duration?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Stddev_Samp_Order_By = {
  concentration?: InputMaybe<Order_By>;
  energy?: InputMaybe<Order_By>;
  grade?: InputMaybe<Order_By>;
  humor?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  relationships?: InputMaybe<Order_By>;
  snore_count?: InputMaybe<Order_By>;
  snore_duration?: InputMaybe<Order_By>;
  time_to_sleep?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
  wake_up_count?: InputMaybe<Order_By>;
  wake_up_duration?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Sum_Fields = {
  __typename?: 'sleep_diary_sum_fields';
  concentration?: Maybe<Scalars['Float']>;
  energy?: Maybe<Scalars['Float']>;
  grade?: Maybe<Scalars['Float']>;
  humor?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  relationships?: Maybe<Scalars['Float']>;
  snore_count?: Maybe<Scalars['smallint']>;
  snore_duration?: Maybe<Scalars['Int']>;
  time_to_sleep?: Maybe<Scalars['numeric']>;
  timezone?: Maybe<Scalars['smallint']>;
  wake_up_count?: Maybe<Scalars['Int']>;
  wake_up_duration?: Maybe<Scalars['Int']>;
};

export type Sleep_Diary_Sum_Order_By = {
  concentration?: InputMaybe<Order_By>;
  energy?: InputMaybe<Order_By>;
  grade?: InputMaybe<Order_By>;
  humor?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  relationships?: InputMaybe<Order_By>;
  snore_count?: InputMaybe<Order_By>;
  snore_duration?: InputMaybe<Order_By>;
  time_to_sleep?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
  wake_up_count?: InputMaybe<Order_By>;
  wake_up_duration?: InputMaybe<Order_By>;
};

export enum Sleep_Diary_Update_Column {
  AutogenicTraining = 'autogenic_training',
  BehaviorActivation = 'behavior_activation',
  ChallengeCatastrophicThinking = 'challenge_catastrophic_thinking',
  Comment = 'comment',
  Concentration = 'concentration',
  CreatedAt = 'created_at',
  Date = 'date',
  DeepBreath = 'deep_breath',
  Energy = 'energy',
  GetUp = 'get_up',
  GoBed = 'go_bed',
  GoSleep = 'go_sleep',
  Grade = 'grade',
  Gratitude = 'gratitude',
  Humor = 'humor',
  Id = 'id',
  Imagery = 'imagery',
  LightTherapy = 'light_therapy',
  Medicine = 'medicine',
  Meditation = 'meditation',
  ParadoxicalIntention = 'paradoxical_intention',
  ParkingLot = 'parking_lot',
  Pmr = 'pmr',
  Relationships = 'relationships',
  SnoreCount = 'snore_count',
  SnoreDuration = 'snore_duration',
  StimulusControl = 'stimulus_control',
  ThoughtBlock = 'thought_block',
  TimeToSleep = 'time_to_sleep',
  Timezone = 'timezone',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
  WakeUp = 'wake_up',
  WakeUpCount = 'wake_up_count',
  WakeUpDuration = 'wake_up_duration',
}

export type Sleep_Diary_Var_Pop_Fields = {
  __typename?: 'sleep_diary_var_pop_fields';
  concentration?: Maybe<Scalars['Float']>;
  energy?: Maybe<Scalars['Float']>;
  grade?: Maybe<Scalars['Float']>;
  humor?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  relationships?: Maybe<Scalars['Float']>;
  snore_count?: Maybe<Scalars['Float']>;
  snore_duration?: Maybe<Scalars['Float']>;
  time_to_sleep?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['Float']>;
  wake_up_count?: Maybe<Scalars['Float']>;
  wake_up_duration?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Var_Pop_Order_By = {
  concentration?: InputMaybe<Order_By>;
  energy?: InputMaybe<Order_By>;
  grade?: InputMaybe<Order_By>;
  humor?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  relationships?: InputMaybe<Order_By>;
  snore_count?: InputMaybe<Order_By>;
  snore_duration?: InputMaybe<Order_By>;
  time_to_sleep?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
  wake_up_count?: InputMaybe<Order_By>;
  wake_up_duration?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Var_Samp_Fields = {
  __typename?: 'sleep_diary_var_samp_fields';
  concentration?: Maybe<Scalars['Float']>;
  energy?: Maybe<Scalars['Float']>;
  grade?: Maybe<Scalars['Float']>;
  humor?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  relationships?: Maybe<Scalars['Float']>;
  snore_count?: Maybe<Scalars['Float']>;
  snore_duration?: Maybe<Scalars['Float']>;
  time_to_sleep?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['Float']>;
  wake_up_count?: Maybe<Scalars['Float']>;
  wake_up_duration?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Var_Samp_Order_By = {
  concentration?: InputMaybe<Order_By>;
  energy?: InputMaybe<Order_By>;
  grade?: InputMaybe<Order_By>;
  humor?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  relationships?: InputMaybe<Order_By>;
  snore_count?: InputMaybe<Order_By>;
  snore_duration?: InputMaybe<Order_By>;
  time_to_sleep?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
  wake_up_count?: InputMaybe<Order_By>;
  wake_up_duration?: InputMaybe<Order_By>;
};

export type Sleep_Diary_Variance_Fields = {
  __typename?: 'sleep_diary_variance_fields';
  concentration?: Maybe<Scalars['Float']>;
  energy?: Maybe<Scalars['Float']>;
  grade?: Maybe<Scalars['Float']>;
  humor?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  relationships?: Maybe<Scalars['Float']>;
  snore_count?: Maybe<Scalars['Float']>;
  snore_duration?: Maybe<Scalars['Float']>;
  time_to_sleep?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['Float']>;
  wake_up_count?: Maybe<Scalars['Float']>;
  wake_up_duration?: Maybe<Scalars['Float']>;
};

export type Sleep_Diary_Variance_Order_By = {
  concentration?: InputMaybe<Order_By>;
  energy?: InputMaybe<Order_By>;
  grade?: InputMaybe<Order_By>;
  humor?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  relationships?: InputMaybe<Order_By>;
  snore_count?: InputMaybe<Order_By>;
  snore_duration?: InputMaybe<Order_By>;
  time_to_sleep?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
  wake_up_count?: InputMaybe<Order_By>;
  wake_up_duration?: InputMaybe<Order_By>;
};

export type Sleep_Restriction = {
  __typename?: 'sleep_restriction';
  before_eficiency: Scalars['numeric'];
  before_fill_count: Scalars['Int'];
  before_time_in_bed: Scalars['Int'];
  before_time_sleep: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  desired_wake_up: Scalars['time'];
  hour_to_sleep: Scalars['time'];
  id: Scalars['Int'];
  new_time_in_bed: Scalars['Int'];
  start_date: Scalars['date'];
  user?: Maybe<User>;
  user_id: Scalars['String'];
};

export type Sleep_Restriction_Aggregate = {
  __typename?: 'sleep_restriction_aggregate';
  aggregate?: Maybe<Sleep_Restriction_Aggregate_Fields>;
  nodes: Array<Sleep_Restriction>;
};

export type Sleep_Restriction_Aggregate_Fields = {
  __typename?: 'sleep_restriction_aggregate_fields';
  avg?: Maybe<Sleep_Restriction_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Sleep_Restriction_Max_Fields>;
  min?: Maybe<Sleep_Restriction_Min_Fields>;
  stddev?: Maybe<Sleep_Restriction_Stddev_Fields>;
  stddev_pop?: Maybe<Sleep_Restriction_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sleep_Restriction_Stddev_Samp_Fields>;
  sum?: Maybe<Sleep_Restriction_Sum_Fields>;
  var_pop?: Maybe<Sleep_Restriction_Var_Pop_Fields>;
  var_samp?: Maybe<Sleep_Restriction_Var_Samp_Fields>;
  variance?: Maybe<Sleep_Restriction_Variance_Fields>;
};

export type Sleep_Restriction_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sleep_Restriction_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Sleep_Restriction_Aggregate_Order_By = {
  avg?: InputMaybe<Sleep_Restriction_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Sleep_Restriction_Max_Order_By>;
  min?: InputMaybe<Sleep_Restriction_Min_Order_By>;
  stddev?: InputMaybe<Sleep_Restriction_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Sleep_Restriction_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Sleep_Restriction_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Sleep_Restriction_Sum_Order_By>;
  var_pop?: InputMaybe<Sleep_Restriction_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Sleep_Restriction_Var_Samp_Order_By>;
  variance?: InputMaybe<Sleep_Restriction_Variance_Order_By>;
};

export type Sleep_Restriction_Arr_Rel_Insert_Input = {
  data: Array<Sleep_Restriction_Insert_Input>;
  on_conflict?: InputMaybe<Sleep_Restriction_On_Conflict>;
};

export type Sleep_Restriction_Avg_Fields = {
  __typename?: 'sleep_restriction_avg_fields';
  before_eficiency?: Maybe<Scalars['Float']>;
  before_fill_count?: Maybe<Scalars['Float']>;
  before_time_in_bed?: Maybe<Scalars['Float']>;
  before_time_sleep?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_time_in_bed?: Maybe<Scalars['Float']>;
};

export type Sleep_Restriction_Avg_Order_By = {
  before_eficiency?: InputMaybe<Order_By>;
  before_fill_count?: InputMaybe<Order_By>;
  before_time_in_bed?: InputMaybe<Order_By>;
  before_time_sleep?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  new_time_in_bed?: InputMaybe<Order_By>;
};

export type Sleep_Restriction_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Sleep_Restriction_Bool_Exp>>>;
  _not?: InputMaybe<Sleep_Restriction_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Sleep_Restriction_Bool_Exp>>>;
  before_eficiency?: InputMaybe<Numeric_Comparison_Exp>;
  before_fill_count?: InputMaybe<Int_Comparison_Exp>;
  before_time_in_bed?: InputMaybe<Int_Comparison_Exp>;
  before_time_sleep?: InputMaybe<Int_Comparison_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  desired_wake_up?: InputMaybe<Time_Comparison_Exp>;
  hour_to_sleep?: InputMaybe<Time_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  new_time_in_bed?: InputMaybe<Int_Comparison_Exp>;
  start_date?: InputMaybe<Date_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

export enum Sleep_Restriction_Constraint {
  SleepRestrictionIdKey = 'sleep_restriction_id_key',
  SleepRestrictionPkey = 'sleep_restriction_pkey',
}

export type Sleep_Restriction_Inc_Input = {
  before_eficiency?: InputMaybe<Scalars['numeric']>;
  before_fill_count?: InputMaybe<Scalars['Int']>;
  before_time_in_bed?: InputMaybe<Scalars['Int']>;
  before_time_sleep?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  new_time_in_bed?: InputMaybe<Scalars['Int']>;
};

export type Sleep_Restriction_Insert_Input = {
  before_eficiency?: InputMaybe<Scalars['numeric']>;
  before_fill_count?: InputMaybe<Scalars['Int']>;
  before_time_in_bed?: InputMaybe<Scalars['Int']>;
  before_time_sleep?: InputMaybe<Scalars['Int']>;
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  desired_wake_up?: InputMaybe<Scalars['time']>;
  hour_to_sleep?: InputMaybe<Scalars['time']>;
  id?: InputMaybe<Scalars['Int']>;
  new_time_in_bed?: InputMaybe<Scalars['Int']>;
  start_date?: InputMaybe<Scalars['date']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Sleep_Restriction_Max_Fields = {
  __typename?: 'sleep_restriction_max_fields';
  before_eficiency?: Maybe<Scalars['numeric']>;
  before_fill_count?: Maybe<Scalars['Int']>;
  before_time_in_bed?: Maybe<Scalars['Int']>;
  before_time_sleep?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  new_time_in_bed?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['date']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Sleep_Restriction_Max_Order_By = {
  before_eficiency?: InputMaybe<Order_By>;
  before_fill_count?: InputMaybe<Order_By>;
  before_time_in_bed?: InputMaybe<Order_By>;
  before_time_sleep?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  new_time_in_bed?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Sleep_Restriction_Min_Fields = {
  __typename?: 'sleep_restriction_min_fields';
  before_eficiency?: Maybe<Scalars['numeric']>;
  before_fill_count?: Maybe<Scalars['Int']>;
  before_time_in_bed?: Maybe<Scalars['Int']>;
  before_time_sleep?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  new_time_in_bed?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['date']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Sleep_Restriction_Min_Order_By = {
  before_eficiency?: InputMaybe<Order_By>;
  before_fill_count?: InputMaybe<Order_By>;
  before_time_in_bed?: InputMaybe<Order_By>;
  before_time_sleep?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  new_time_in_bed?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Sleep_Restriction_Mutation_Response = {
  __typename?: 'sleep_restriction_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Sleep_Restriction>;
};

export type Sleep_Restriction_Obj_Rel_Insert_Input = {
  data: Sleep_Restriction_Insert_Input;
  on_conflict?: InputMaybe<Sleep_Restriction_On_Conflict>;
};

export type Sleep_Restriction_On_Conflict = {
  constraint: Sleep_Restriction_Constraint;
  update_columns: Array<Sleep_Restriction_Update_Column>;
  where?: InputMaybe<Sleep_Restriction_Bool_Exp>;
};

export type Sleep_Restriction_Order_By = {
  before_eficiency?: InputMaybe<Order_By>;
  before_fill_count?: InputMaybe<Order_By>;
  before_time_in_bed?: InputMaybe<Order_By>;
  before_time_sleep?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  desired_wake_up?: InputMaybe<Order_By>;
  hour_to_sleep?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  new_time_in_bed?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Sleep_Restriction_Pk_Columns_Input = {
  start_date: Scalars['date'];
  user_id: Scalars['String'];
};

export enum Sleep_Restriction_Select_Column {
  BeforeEficiency = 'before_eficiency',
  BeforeFillCount = 'before_fill_count',
  BeforeTimeInBed = 'before_time_in_bed',
  BeforeTimeSleep = 'before_time_sleep',
  Comment = 'comment',
  CreatedAt = 'created_at',
  DesiredWakeUp = 'desired_wake_up',
  HourToSleep = 'hour_to_sleep',
  Id = 'id',
  NewTimeInBed = 'new_time_in_bed',
  StartDate = 'start_date',
  UserId = 'user_id',
}

export type Sleep_Restriction_Set_Input = {
  before_eficiency?: InputMaybe<Scalars['numeric']>;
  before_fill_count?: InputMaybe<Scalars['Int']>;
  before_time_in_bed?: InputMaybe<Scalars['Int']>;
  before_time_sleep?: InputMaybe<Scalars['Int']>;
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  desired_wake_up?: InputMaybe<Scalars['time']>;
  hour_to_sleep?: InputMaybe<Scalars['time']>;
  id?: InputMaybe<Scalars['Int']>;
  new_time_in_bed?: InputMaybe<Scalars['Int']>;
  start_date?: InputMaybe<Scalars['date']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Sleep_Restriction_Stddev_Fields = {
  __typename?: 'sleep_restriction_stddev_fields';
  before_eficiency?: Maybe<Scalars['Float']>;
  before_fill_count?: Maybe<Scalars['Float']>;
  before_time_in_bed?: Maybe<Scalars['Float']>;
  before_time_sleep?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_time_in_bed?: Maybe<Scalars['Float']>;
};

export type Sleep_Restriction_Stddev_Order_By = {
  before_eficiency?: InputMaybe<Order_By>;
  before_fill_count?: InputMaybe<Order_By>;
  before_time_in_bed?: InputMaybe<Order_By>;
  before_time_sleep?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  new_time_in_bed?: InputMaybe<Order_By>;
};

export type Sleep_Restriction_Stddev_Pop_Fields = {
  __typename?: 'sleep_restriction_stddev_pop_fields';
  before_eficiency?: Maybe<Scalars['Float']>;
  before_fill_count?: Maybe<Scalars['Float']>;
  before_time_in_bed?: Maybe<Scalars['Float']>;
  before_time_sleep?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_time_in_bed?: Maybe<Scalars['Float']>;
};

export type Sleep_Restriction_Stddev_Pop_Order_By = {
  before_eficiency?: InputMaybe<Order_By>;
  before_fill_count?: InputMaybe<Order_By>;
  before_time_in_bed?: InputMaybe<Order_By>;
  before_time_sleep?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  new_time_in_bed?: InputMaybe<Order_By>;
};

export type Sleep_Restriction_Stddev_Samp_Fields = {
  __typename?: 'sleep_restriction_stddev_samp_fields';
  before_eficiency?: Maybe<Scalars['Float']>;
  before_fill_count?: Maybe<Scalars['Float']>;
  before_time_in_bed?: Maybe<Scalars['Float']>;
  before_time_sleep?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_time_in_bed?: Maybe<Scalars['Float']>;
};

export type Sleep_Restriction_Stddev_Samp_Order_By = {
  before_eficiency?: InputMaybe<Order_By>;
  before_fill_count?: InputMaybe<Order_By>;
  before_time_in_bed?: InputMaybe<Order_By>;
  before_time_sleep?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  new_time_in_bed?: InputMaybe<Order_By>;
};

export type Sleep_Restriction_Sum_Fields = {
  __typename?: 'sleep_restriction_sum_fields';
  before_eficiency?: Maybe<Scalars['numeric']>;
  before_fill_count?: Maybe<Scalars['Int']>;
  before_time_in_bed?: Maybe<Scalars['Int']>;
  before_time_sleep?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  new_time_in_bed?: Maybe<Scalars['Int']>;
};

export type Sleep_Restriction_Sum_Order_By = {
  before_eficiency?: InputMaybe<Order_By>;
  before_fill_count?: InputMaybe<Order_By>;
  before_time_in_bed?: InputMaybe<Order_By>;
  before_time_sleep?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  new_time_in_bed?: InputMaybe<Order_By>;
};

export enum Sleep_Restriction_Update_Column {
  BeforeEficiency = 'before_eficiency',
  BeforeFillCount = 'before_fill_count',
  BeforeTimeInBed = 'before_time_in_bed',
  BeforeTimeSleep = 'before_time_sleep',
  Comment = 'comment',
  CreatedAt = 'created_at',
  DesiredWakeUp = 'desired_wake_up',
  HourToSleep = 'hour_to_sleep',
  Id = 'id',
  NewTimeInBed = 'new_time_in_bed',
  StartDate = 'start_date',
  UserId = 'user_id',
}

export type Sleep_Restriction_Var_Pop_Fields = {
  __typename?: 'sleep_restriction_var_pop_fields';
  before_eficiency?: Maybe<Scalars['Float']>;
  before_fill_count?: Maybe<Scalars['Float']>;
  before_time_in_bed?: Maybe<Scalars['Float']>;
  before_time_sleep?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_time_in_bed?: Maybe<Scalars['Float']>;
};

export type Sleep_Restriction_Var_Pop_Order_By = {
  before_eficiency?: InputMaybe<Order_By>;
  before_fill_count?: InputMaybe<Order_By>;
  before_time_in_bed?: InputMaybe<Order_By>;
  before_time_sleep?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  new_time_in_bed?: InputMaybe<Order_By>;
};

export type Sleep_Restriction_Var_Samp_Fields = {
  __typename?: 'sleep_restriction_var_samp_fields';
  before_eficiency?: Maybe<Scalars['Float']>;
  before_fill_count?: Maybe<Scalars['Float']>;
  before_time_in_bed?: Maybe<Scalars['Float']>;
  before_time_sleep?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_time_in_bed?: Maybe<Scalars['Float']>;
};

export type Sleep_Restriction_Var_Samp_Order_By = {
  before_eficiency?: InputMaybe<Order_By>;
  before_fill_count?: InputMaybe<Order_By>;
  before_time_in_bed?: InputMaybe<Order_By>;
  before_time_sleep?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  new_time_in_bed?: InputMaybe<Order_By>;
};

export type Sleep_Restriction_Variance_Fields = {
  __typename?: 'sleep_restriction_variance_fields';
  before_eficiency?: Maybe<Scalars['Float']>;
  before_fill_count?: Maybe<Scalars['Float']>;
  before_time_in_bed?: Maybe<Scalars['Float']>;
  before_time_sleep?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  new_time_in_bed?: Maybe<Scalars['Float']>;
};

export type Sleep_Restriction_Variance_Order_By = {
  before_eficiency?: InputMaybe<Order_By>;
  before_fill_count?: InputMaybe<Order_By>;
  before_time_in_bed?: InputMaybe<Order_By>;
  before_time_sleep?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  new_time_in_bed?: InputMaybe<Order_By>;
};

export type Sleep_Tag_Enum = {
  __typename?: 'sleep_tag_enum';
  comment?: Maybe<Scalars['String']>;
  sleep_diaries: Array<Sleep_Diary_Sleep_Tag>;
  sleep_diaries_aggregate: Sleep_Diary_Sleep_Tag_Aggregate;
  text: Scalars['String'];
};

export type Sleep_Tag_EnumSleep_DiariesArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Diary_Sleep_Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Diary_Sleep_Tag_Order_By>>;
  where?: InputMaybe<Sleep_Diary_Sleep_Tag_Bool_Exp>;
};

export type Sleep_Tag_EnumSleep_Diaries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Diary_Sleep_Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Diary_Sleep_Tag_Order_By>>;
  where?: InputMaybe<Sleep_Diary_Sleep_Tag_Bool_Exp>;
};

export type Sleep_Tag_Enum_Aggregate = {
  __typename?: 'sleep_tag_enum_aggregate';
  aggregate?: Maybe<Sleep_Tag_Enum_Aggregate_Fields>;
  nodes: Array<Sleep_Tag_Enum>;
};

export type Sleep_Tag_Enum_Aggregate_Fields = {
  __typename?: 'sleep_tag_enum_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Sleep_Tag_Enum_Max_Fields>;
  min?: Maybe<Sleep_Tag_Enum_Min_Fields>;
};

export type Sleep_Tag_Enum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sleep_Tag_Enum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Sleep_Tag_Enum_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Sleep_Tag_Enum_Max_Order_By>;
  min?: InputMaybe<Sleep_Tag_Enum_Min_Order_By>;
};

export type Sleep_Tag_Enum_Arr_Rel_Insert_Input = {
  data: Array<Sleep_Tag_Enum_Insert_Input>;
  on_conflict?: InputMaybe<Sleep_Tag_Enum_On_Conflict>;
};

export type Sleep_Tag_Enum_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Sleep_Tag_Enum_Bool_Exp>>>;
  _not?: InputMaybe<Sleep_Tag_Enum_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Sleep_Tag_Enum_Bool_Exp>>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  sleep_diaries?: InputMaybe<Sleep_Diary_Sleep_Tag_Bool_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
};

export enum Sleep_Tag_Enum_Constraint {
  SleepDiaryTagPkey = 'sleep_diary_tag_pkey',
  SleepDiaryTagTextKey = 'sleep_diary_tag_text_key',
}

export enum Sleep_Tag_Enum_Enum {
  Alcohol = 'alcohol',
  Bathroom = 'bathroom',
  Caffeine = 'caffeine',
  Dream = 'dream',
  Exercise = 'exercise',
  Light = 'light',
  Meal = 'meal',
  Medicine = 'medicine',
  Nap = 'nap',
  Nicotine = 'nicotine',
  Noise = 'noise',
  Pain = 'pain',
  Partner = 'partner',
  Temperature = 'temperature',
}

export type Sleep_Tag_Enum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Sleep_Tag_Enum_Enum>;
  _in?: InputMaybe<Array<Sleep_Tag_Enum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Sleep_Tag_Enum_Enum>;
  _nin?: InputMaybe<Array<Sleep_Tag_Enum_Enum>>;
};

export type Sleep_Tag_Enum_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  sleep_diaries?: InputMaybe<Sleep_Diary_Sleep_Tag_Arr_Rel_Insert_Input>;
  text?: InputMaybe<Scalars['String']>;
};

export type Sleep_Tag_Enum_Max_Fields = {
  __typename?: 'sleep_tag_enum_max_fields';
  comment?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type Sleep_Tag_Enum_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Sleep_Tag_Enum_Min_Fields = {
  __typename?: 'sleep_tag_enum_min_fields';
  comment?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type Sleep_Tag_Enum_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Sleep_Tag_Enum_Mutation_Response = {
  __typename?: 'sleep_tag_enum_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Sleep_Tag_Enum>;
};

export type Sleep_Tag_Enum_Obj_Rel_Insert_Input = {
  data: Sleep_Tag_Enum_Insert_Input;
  on_conflict?: InputMaybe<Sleep_Tag_Enum_On_Conflict>;
};

export type Sleep_Tag_Enum_On_Conflict = {
  constraint: Sleep_Tag_Enum_Constraint;
  update_columns: Array<Sleep_Tag_Enum_Update_Column>;
  where?: InputMaybe<Sleep_Tag_Enum_Bool_Exp>;
};

export type Sleep_Tag_Enum_Order_By = {
  comment?: InputMaybe<Order_By>;
  sleep_diaries_aggregate?: InputMaybe<Sleep_Diary_Sleep_Tag_Aggregate_Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Sleep_Tag_Enum_Pk_Columns_Input = {
  text: Scalars['String'];
};

export enum Sleep_Tag_Enum_Select_Column {
  Comment = 'comment',
  Text = 'text',
}

export type Sleep_Tag_Enum_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export enum Sleep_Tag_Enum_Update_Column {
  Comment = 'comment',
  Text = 'text',
}

export type Sleep_Tracker_Compressed_Data = {
  __typename?: 'sleep_tracker_compressed_data';
  alarm?: Maybe<Scalars['jsonb']>;
  compressed_data?: Maybe<Scalars['jsonb']>;
  created_at: Scalars['timestamptz'];
  date: Scalars['date'];
  id: Scalars['Int'];
  intervals?: Maybe<Scalars['jsonb']>;
  snore_data?: Maybe<Scalars['jsonb']>;
  updated_at: Scalars['timestamptz'];
  user: User;
  user_id: Scalars['String'];
};

export type Sleep_Tracker_Compressed_DataAlarmArgs = {
  path?: InputMaybe<Scalars['String']>;
};

export type Sleep_Tracker_Compressed_DataCompressed_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

export type Sleep_Tracker_Compressed_DataIntervalsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

export type Sleep_Tracker_Compressed_DataSnore_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

export type Sleep_Tracker_Compressed_Data_Aggregate = {
  __typename?: 'sleep_tracker_compressed_data_aggregate';
  aggregate?: Maybe<Sleep_Tracker_Compressed_Data_Aggregate_Fields>;
  nodes: Array<Sleep_Tracker_Compressed_Data>;
};

export type Sleep_Tracker_Compressed_Data_Aggregate_Fields = {
  __typename?: 'sleep_tracker_compressed_data_aggregate_fields';
  avg?: Maybe<Sleep_Tracker_Compressed_Data_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Sleep_Tracker_Compressed_Data_Max_Fields>;
  min?: Maybe<Sleep_Tracker_Compressed_Data_Min_Fields>;
  stddev?: Maybe<Sleep_Tracker_Compressed_Data_Stddev_Fields>;
  stddev_pop?: Maybe<Sleep_Tracker_Compressed_Data_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sleep_Tracker_Compressed_Data_Stddev_Samp_Fields>;
  sum?: Maybe<Sleep_Tracker_Compressed_Data_Sum_Fields>;
  var_pop?: Maybe<Sleep_Tracker_Compressed_Data_Var_Pop_Fields>;
  var_samp?: Maybe<Sleep_Tracker_Compressed_Data_Var_Samp_Fields>;
  variance?: Maybe<Sleep_Tracker_Compressed_Data_Variance_Fields>;
};

export type Sleep_Tracker_Compressed_Data_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sleep_Tracker_Compressed_Data_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Sleep_Tracker_Compressed_Data_Aggregate_Order_By = {
  avg?: InputMaybe<Sleep_Tracker_Compressed_Data_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Sleep_Tracker_Compressed_Data_Max_Order_By>;
  min?: InputMaybe<Sleep_Tracker_Compressed_Data_Min_Order_By>;
  stddev?: InputMaybe<Sleep_Tracker_Compressed_Data_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Sleep_Tracker_Compressed_Data_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Sleep_Tracker_Compressed_Data_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Sleep_Tracker_Compressed_Data_Sum_Order_By>;
  var_pop?: InputMaybe<Sleep_Tracker_Compressed_Data_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Sleep_Tracker_Compressed_Data_Var_Samp_Order_By>;
  variance?: InputMaybe<Sleep_Tracker_Compressed_Data_Variance_Order_By>;
};

export type Sleep_Tracker_Compressed_Data_Append_Input = {
  alarm?: InputMaybe<Scalars['jsonb']>;
  compressed_data?: InputMaybe<Scalars['jsonb']>;
  intervals?: InputMaybe<Scalars['jsonb']>;
  snore_data?: InputMaybe<Scalars['jsonb']>;
};

export type Sleep_Tracker_Compressed_Data_Arr_Rel_Insert_Input = {
  data: Array<Sleep_Tracker_Compressed_Data_Insert_Input>;
  on_conflict?: InputMaybe<Sleep_Tracker_Compressed_Data_On_Conflict>;
};

export type Sleep_Tracker_Compressed_Data_Avg_Fields = {
  __typename?: 'sleep_tracker_compressed_data_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Sleep_Tracker_Compressed_Data_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Sleep_Tracker_Compressed_Data_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Sleep_Tracker_Compressed_Data_Bool_Exp>>>;
  _not?: InputMaybe<Sleep_Tracker_Compressed_Data_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Sleep_Tracker_Compressed_Data_Bool_Exp>>>;
  alarm?: InputMaybe<Jsonb_Comparison_Exp>;
  compressed_data?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  intervals?: InputMaybe<Jsonb_Comparison_Exp>;
  snore_data?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

export enum Sleep_Tracker_Compressed_Data_Constraint {
  SleepTrackerCompressedDataPkey = 'sleep_tracker_compressed_data_pkey',
}

export type Sleep_Tracker_Compressed_Data_Delete_At_Path_Input = {
  alarm?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  compressed_data?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  intervals?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  snore_data?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Sleep_Tracker_Compressed_Data_Delete_Elem_Input = {
  alarm?: InputMaybe<Scalars['Int']>;
  compressed_data?: InputMaybe<Scalars['Int']>;
  intervals?: InputMaybe<Scalars['Int']>;
  snore_data?: InputMaybe<Scalars['Int']>;
};

export type Sleep_Tracker_Compressed_Data_Delete_Key_Input = {
  alarm?: InputMaybe<Scalars['String']>;
  compressed_data?: InputMaybe<Scalars['String']>;
  intervals?: InputMaybe<Scalars['String']>;
  snore_data?: InputMaybe<Scalars['String']>;
};

export type Sleep_Tracker_Compressed_Data_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

export type Sleep_Tracker_Compressed_Data_Insert_Input = {
  alarm?: InputMaybe<Scalars['jsonb']>;
  compressed_data?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  intervals?: InputMaybe<Scalars['jsonb']>;
  snore_data?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Sleep_Tracker_Compressed_Data_Max_Fields = {
  __typename?: 'sleep_tracker_compressed_data_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Sleep_Tracker_Compressed_Data_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Sleep_Tracker_Compressed_Data_Min_Fields = {
  __typename?: 'sleep_tracker_compressed_data_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Sleep_Tracker_Compressed_Data_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Sleep_Tracker_Compressed_Data_Mutation_Response = {
  __typename?: 'sleep_tracker_compressed_data_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Sleep_Tracker_Compressed_Data>;
};

export type Sleep_Tracker_Compressed_Data_Obj_Rel_Insert_Input = {
  data: Sleep_Tracker_Compressed_Data_Insert_Input;
  on_conflict?: InputMaybe<Sleep_Tracker_Compressed_Data_On_Conflict>;
};

export type Sleep_Tracker_Compressed_Data_On_Conflict = {
  constraint: Sleep_Tracker_Compressed_Data_Constraint;
  update_columns: Array<Sleep_Tracker_Compressed_Data_Update_Column>;
  where?: InputMaybe<Sleep_Tracker_Compressed_Data_Bool_Exp>;
};

export type Sleep_Tracker_Compressed_Data_Order_By = {
  alarm?: InputMaybe<Order_By>;
  compressed_data?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  intervals?: InputMaybe<Order_By>;
  snore_data?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Sleep_Tracker_Compressed_Data_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export type Sleep_Tracker_Compressed_Data_Prepend_Input = {
  alarm?: InputMaybe<Scalars['jsonb']>;
  compressed_data?: InputMaybe<Scalars['jsonb']>;
  intervals?: InputMaybe<Scalars['jsonb']>;
  snore_data?: InputMaybe<Scalars['jsonb']>;
};

export enum Sleep_Tracker_Compressed_Data_Select_Column {
  Alarm = 'alarm',
  CompressedData = 'compressed_data',
  CreatedAt = 'created_at',
  Date = 'date',
  Id = 'id',
  Intervals = 'intervals',
  SnoreData = 'snore_data',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Sleep_Tracker_Compressed_Data_Set_Input = {
  alarm?: InputMaybe<Scalars['jsonb']>;
  compressed_data?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  intervals?: InputMaybe<Scalars['jsonb']>;
  snore_data?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Sleep_Tracker_Compressed_Data_Stddev_Fields = {
  __typename?: 'sleep_tracker_compressed_data_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Sleep_Tracker_Compressed_Data_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Sleep_Tracker_Compressed_Data_Stddev_Pop_Fields = {
  __typename?: 'sleep_tracker_compressed_data_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Sleep_Tracker_Compressed_Data_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Sleep_Tracker_Compressed_Data_Stddev_Samp_Fields = {
  __typename?: 'sleep_tracker_compressed_data_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Sleep_Tracker_Compressed_Data_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Sleep_Tracker_Compressed_Data_Sum_Fields = {
  __typename?: 'sleep_tracker_compressed_data_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

export type Sleep_Tracker_Compressed_Data_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

export enum Sleep_Tracker_Compressed_Data_Update_Column {
  Alarm = 'alarm',
  CompressedData = 'compressed_data',
  CreatedAt = 'created_at',
  Date = 'date',
  Id = 'id',
  Intervals = 'intervals',
  SnoreData = 'snore_data',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Sleep_Tracker_Compressed_Data_Var_Pop_Fields = {
  __typename?: 'sleep_tracker_compressed_data_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Sleep_Tracker_Compressed_Data_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Sleep_Tracker_Compressed_Data_Var_Samp_Fields = {
  __typename?: 'sleep_tracker_compressed_data_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Sleep_Tracker_Compressed_Data_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Sleep_Tracker_Compressed_Data_Variance_Fields = {
  __typename?: 'sleep_tracker_compressed_data_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Sleep_Tracker_Compressed_Data_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Smallint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['smallint']>;
  _gt?: InputMaybe<Scalars['smallint']>;
  _gte?: InputMaybe<Scalars['smallint']>;
  _in?: InputMaybe<Array<Scalars['smallint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['smallint']>;
  _lte?: InputMaybe<Scalars['smallint']>;
  _neq?: InputMaybe<Scalars['smallint']>;
  _nin?: InputMaybe<Array<Scalars['smallint']>>;
};

export type Stop_Bang = {
  __typename?: 'stop_bang';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  q1: Scalars['Int'];
  q2: Scalars['Int'];
  q3: Scalars['Int'];
  q4: Scalars['Int'];
  q5: Scalars['numeric'];
  q6: Scalars['numeric'];
  q7: Scalars['numeric'];
  q8: Scalars['Int'];
  q9?: Maybe<Scalars['numeric']>;
  updated_at: Scalars['timestamptz'];
  user: User;
  user_id: Scalars['String'];
};

export type Stop_Bang_Aggregate = {
  __typename?: 'stop_bang_aggregate';
  aggregate?: Maybe<Stop_Bang_Aggregate_Fields>;
  nodes: Array<Stop_Bang>;
};

export type Stop_Bang_Aggregate_Fields = {
  __typename?: 'stop_bang_aggregate_fields';
  avg?: Maybe<Stop_Bang_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Stop_Bang_Max_Fields>;
  min?: Maybe<Stop_Bang_Min_Fields>;
  stddev?: Maybe<Stop_Bang_Stddev_Fields>;
  stddev_pop?: Maybe<Stop_Bang_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stop_Bang_Stddev_Samp_Fields>;
  sum?: Maybe<Stop_Bang_Sum_Fields>;
  var_pop?: Maybe<Stop_Bang_Var_Pop_Fields>;
  var_samp?: Maybe<Stop_Bang_Var_Samp_Fields>;
  variance?: Maybe<Stop_Bang_Variance_Fields>;
};

export type Stop_Bang_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stop_Bang_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Stop_Bang_Aggregate_Order_By = {
  avg?: InputMaybe<Stop_Bang_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Stop_Bang_Max_Order_By>;
  min?: InputMaybe<Stop_Bang_Min_Order_By>;
  stddev?: InputMaybe<Stop_Bang_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Stop_Bang_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Stop_Bang_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Stop_Bang_Sum_Order_By>;
  var_pop?: InputMaybe<Stop_Bang_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Stop_Bang_Var_Samp_Order_By>;
  variance?: InputMaybe<Stop_Bang_Variance_Order_By>;
};

export type Stop_Bang_Arr_Rel_Insert_Input = {
  data: Array<Stop_Bang_Insert_Input>;
  on_conflict?: InputMaybe<Stop_Bang_On_Conflict>;
};

export type Stop_Bang_Avg_Fields = {
  __typename?: 'stop_bang_avg_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
};

export type Stop_Bang_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
};

export type Stop_Bang_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Stop_Bang_Bool_Exp>>>;
  _not?: InputMaybe<Stop_Bang_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Stop_Bang_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  q1?: InputMaybe<Int_Comparison_Exp>;
  q2?: InputMaybe<Int_Comparison_Exp>;
  q3?: InputMaybe<Int_Comparison_Exp>;
  q4?: InputMaybe<Int_Comparison_Exp>;
  q5?: InputMaybe<Numeric_Comparison_Exp>;
  q6?: InputMaybe<Numeric_Comparison_Exp>;
  q7?: InputMaybe<Numeric_Comparison_Exp>;
  q8?: InputMaybe<Int_Comparison_Exp>;
  q9?: InputMaybe<Numeric_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

export enum Stop_Bang_Constraint {
  StopBangPkey = 'stop_bang_pkey',
}

export type Stop_Bang_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['Int']>;
  q2?: InputMaybe<Scalars['Int']>;
  q3?: InputMaybe<Scalars['Int']>;
  q4?: InputMaybe<Scalars['Int']>;
  q5?: InputMaybe<Scalars['numeric']>;
  q6?: InputMaybe<Scalars['numeric']>;
  q7?: InputMaybe<Scalars['numeric']>;
  q8?: InputMaybe<Scalars['Int']>;
  q9?: InputMaybe<Scalars['numeric']>;
};

export type Stop_Bang_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['Int']>;
  q2?: InputMaybe<Scalars['Int']>;
  q3?: InputMaybe<Scalars['Int']>;
  q4?: InputMaybe<Scalars['Int']>;
  q5?: InputMaybe<Scalars['numeric']>;
  q6?: InputMaybe<Scalars['numeric']>;
  q7?: InputMaybe<Scalars['numeric']>;
  q8?: InputMaybe<Scalars['Int']>;
  q9?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Stop_Bang_Max_Fields = {
  __typename?: 'stop_bang_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['Int']>;
  q2?: Maybe<Scalars['Int']>;
  q3?: Maybe<Scalars['Int']>;
  q4?: Maybe<Scalars['Int']>;
  q5?: Maybe<Scalars['numeric']>;
  q6?: Maybe<Scalars['numeric']>;
  q7?: Maybe<Scalars['numeric']>;
  q8?: Maybe<Scalars['Int']>;
  q9?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Stop_Bang_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Stop_Bang_Min_Fields = {
  __typename?: 'stop_bang_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['Int']>;
  q2?: Maybe<Scalars['Int']>;
  q3?: Maybe<Scalars['Int']>;
  q4?: Maybe<Scalars['Int']>;
  q5?: Maybe<Scalars['numeric']>;
  q6?: Maybe<Scalars['numeric']>;
  q7?: Maybe<Scalars['numeric']>;
  q8?: Maybe<Scalars['Int']>;
  q9?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Stop_Bang_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Stop_Bang_Mutation_Response = {
  __typename?: 'stop_bang_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Stop_Bang>;
};

export type Stop_Bang_Obj_Rel_Insert_Input = {
  data: Stop_Bang_Insert_Input;
  on_conflict?: InputMaybe<Stop_Bang_On_Conflict>;
};

export type Stop_Bang_On_Conflict = {
  constraint: Stop_Bang_Constraint;
  update_columns: Array<Stop_Bang_Update_Column>;
  where?: InputMaybe<Stop_Bang_Bool_Exp>;
};

export type Stop_Bang_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Stop_Bang_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export enum Stop_Bang_Select_Column {
  CreatedAt = 'created_at',
  Id = 'id',
  Q1 = 'q1',
  Q2 = 'q2',
  Q3 = 'q3',
  Q4 = 'q4',
  Q5 = 'q5',
  Q6 = 'q6',
  Q7 = 'q7',
  Q8 = 'q8',
  Q9 = 'q9',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Stop_Bang_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  q1?: InputMaybe<Scalars['Int']>;
  q2?: InputMaybe<Scalars['Int']>;
  q3?: InputMaybe<Scalars['Int']>;
  q4?: InputMaybe<Scalars['Int']>;
  q5?: InputMaybe<Scalars['numeric']>;
  q6?: InputMaybe<Scalars['numeric']>;
  q7?: InputMaybe<Scalars['numeric']>;
  q8?: InputMaybe<Scalars['Int']>;
  q9?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Stop_Bang_Stddev_Fields = {
  __typename?: 'stop_bang_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
};

export type Stop_Bang_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
};

export type Stop_Bang_Stddev_Pop_Fields = {
  __typename?: 'stop_bang_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
};

export type Stop_Bang_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
};

export type Stop_Bang_Stddev_Samp_Fields = {
  __typename?: 'stop_bang_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
};

export type Stop_Bang_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
};

export type Stop_Bang_Sum_Fields = {
  __typename?: 'stop_bang_sum_fields';
  id?: Maybe<Scalars['Int']>;
  q1?: Maybe<Scalars['Int']>;
  q2?: Maybe<Scalars['Int']>;
  q3?: Maybe<Scalars['Int']>;
  q4?: Maybe<Scalars['Int']>;
  q5?: Maybe<Scalars['numeric']>;
  q6?: Maybe<Scalars['numeric']>;
  q7?: Maybe<Scalars['numeric']>;
  q8?: Maybe<Scalars['Int']>;
  q9?: Maybe<Scalars['numeric']>;
};

export type Stop_Bang_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
};

export enum Stop_Bang_Update_Column {
  CreatedAt = 'created_at',
  Id = 'id',
  Q1 = 'q1',
  Q2 = 'q2',
  Q3 = 'q3',
  Q4 = 'q4',
  Q5 = 'q5',
  Q6 = 'q6',
  Q7 = 'q7',
  Q8 = 'q8',
  Q9 = 'q9',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type Stop_Bang_Var_Pop_Fields = {
  __typename?: 'stop_bang_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
};

export type Stop_Bang_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
};

export type Stop_Bang_Var_Samp_Fields = {
  __typename?: 'stop_bang_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
};

export type Stop_Bang_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
};

export type Stop_Bang_Variance_Fields = {
  __typename?: 'stop_bang_variance_fields';
  id?: Maybe<Scalars['Float']>;
  q1?: Maybe<Scalars['Float']>;
  q2?: Maybe<Scalars['Float']>;
  q3?: Maybe<Scalars['Float']>;
  q4?: Maybe<Scalars['Float']>;
  q5?: Maybe<Scalars['Float']>;
  q6?: Maybe<Scalars['Float']>;
  q7?: Maybe<Scalars['Float']>;
  q8?: Maybe<Scalars['Float']>;
  q9?: Maybe<Scalars['Float']>;
};

export type Stop_Bang_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  q1?: InputMaybe<Order_By>;
  q2?: InputMaybe<Order_By>;
  q3?: InputMaybe<Order_By>;
  q4?: InputMaybe<Order_By>;
  q5?: InputMaybe<Order_By>;
  q6?: InputMaybe<Order_By>;
  q7?: InputMaybe<Order_By>;
  q8?: InputMaybe<Order_By>;
  q9?: InputMaybe<Order_By>;
};

export type Subscription = {
  __typename?: 'subscription';
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  internal_id: Scalars['Int'];
  name: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  subscribed: Scalars['Boolean'];
  userId?: Maybe<Scalars['String']>;
};

export type Subscription_Aggregate = {
  __typename?: 'subscription_aggregate';
  aggregate?: Maybe<Subscription_Aggregate_Fields>;
  nodes: Array<Subscription>;
};

export type Subscription_Aggregate_Fields = {
  __typename?: 'subscription_aggregate_fields';
  avg?: Maybe<Subscription_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Subscription_Max_Fields>;
  min?: Maybe<Subscription_Min_Fields>;
  stddev?: Maybe<Subscription_Stddev_Fields>;
  stddev_pop?: Maybe<Subscription_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subscription_Stddev_Samp_Fields>;
  sum?: Maybe<Subscription_Sum_Fields>;
  var_pop?: Maybe<Subscription_Var_Pop_Fields>;
  var_samp?: Maybe<Subscription_Var_Samp_Fields>;
  variance?: Maybe<Subscription_Variance_Fields>;
};

export type Subscription_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Subscription_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Subscription_Aggregate_Order_By = {
  avg?: InputMaybe<Subscription_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Subscription_Max_Order_By>;
  min?: InputMaybe<Subscription_Min_Order_By>;
  stddev?: InputMaybe<Subscription_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Subscription_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Subscription_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Subscription_Sum_Order_By>;
  var_pop?: InputMaybe<Subscription_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Subscription_Var_Samp_Order_By>;
  variance?: InputMaybe<Subscription_Variance_Order_By>;
};

export type Subscription_Arr_Rel_Insert_Input = {
  data: Array<Subscription_Insert_Input>;
  on_conflict?: InputMaybe<Subscription_On_Conflict>;
};

export type Subscription_Avg_Fields = {
  __typename?: 'subscription_avg_fields';
  internal_id?: Maybe<Scalars['Float']>;
};

export type Subscription_Avg_Order_By = {
  internal_id?: InputMaybe<Order_By>;
};

export type Subscription_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Subscription_Bool_Exp>>>;
  _not?: InputMaybe<Subscription_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Subscription_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  internal_id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  subscribed?: InputMaybe<Boolean_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

export enum Subscription_Constraint {
  SubscriptionInternalIdKey = 'subscription_internal_id_key',
  SubscriptionPkey = 'subscription_pkey',
}

export type Subscription_Inc_Input = {
  internal_id?: InputMaybe<Scalars['Int']>;
};

export type Subscription_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  internal_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  subscribed?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type Subscription_Max_Fields = {
  __typename?: 'subscription_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  internal_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type Subscription_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

export type Subscription_Min_Fields = {
  __typename?: 'subscription_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  internal_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type Subscription_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

export type Subscription_Mutation_Response = {
  __typename?: 'subscription_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Subscription>;
};

export type Subscription_Obj_Rel_Insert_Input = {
  data: Subscription_Insert_Input;
  on_conflict?: InputMaybe<Subscription_On_Conflict>;
};

export type Subscription_On_Conflict = {
  constraint: Subscription_Constraint;
  update_columns: Array<Subscription_Update_Column>;
  where?: InputMaybe<Subscription_Bool_Exp>;
};

export type Subscription_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  subscribed?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

export type Subscription_Pk_Columns_Input = {
  email: Scalars['String'];
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  apnea_questionnaire: Array<Apnea_Questionnaire>;
  apnea_questionnaire_aggregate: Apnea_Questionnaire_Aggregate;
  apnea_questionnaire_by_pk?: Maybe<Apnea_Questionnaire>;
  apnea_scan_photo: Array<Apnea_Scan_Photo>;
  apnea_scan_photo_aggregate: Apnea_Scan_Photo_Aggregate;
  apnea_scan_photo_by_pk?: Maybe<Apnea_Scan_Photo>;
  app_customization: Array<App_Customization>;
  app_customization_aggregate: App_Customization_Aggregate;
  app_customization_by_pk?: Maybe<App_Customization>;
  app_customization_type_enum: Array<App_Customization_Type_Enum>;
  app_customization_type_enum_aggregate: App_Customization_Type_Enum_Aggregate;
  app_customization_type_enum_by_pk?: Maybe<App_Customization_Type_Enum>;
  appointment: Array<Appointment>;
  appointment_aggregate: Appointment_Aggregate;
  appointment_by_pk?: Maybe<Appointment>;
  appointment_status_enum: Array<Appointment_Status_Enum>;
  appointment_status_enum_aggregate: Appointment_Status_Enum_Aggregate;
  appointment_status_enum_by_pk?: Maybe<Appointment_Status_Enum>;
  assesment: Array<Assesment>;
  assesment_aggregate: Assesment_Aggregate;
  assesment_by_pk?: Maybe<Assesment>;
  b2b_diagnosis_v1: Array<B2b_Diagnosis_V1>;
  b2b_diagnosis_v1_aggregate: B2b_Diagnosis_V1_Aggregate;
  b2b_diagnosis_v1_by_pk?: Maybe<B2b_Diagnosis_V1>;
  catastrophic_thinkings: Array<Catastrophic_Thinkings>;
  catastrophic_thinkings_aggregate: Catastrophic_Thinkings_Aggregate;
  catastrophic_thinkings_by_pk?: Maybe<Catastrophic_Thinkings>;
  community_user: Array<Community_User>;
  community_user_aggregate: Community_User_Aggregate;
  community_user_by_pk?: Maybe<Community_User>;
  company: Array<Company>;
  company_aggregate: Company_Aggregate;
  company_by_pk?: Maybe<Company>;
  company_health_carer: Array<Company_Health_Carer>;
  company_health_carer_aggregate: Company_Health_Carer_Aggregate;
  company_health_carer_by_pk?: Maybe<Company_Health_Carer>;
  company_health_carer_kind_enum: Array<Company_Health_Carer_Kind_Enum>;
  company_health_carer_kind_enum_aggregate: Company_Health_Carer_Kind_Enum_Aggregate;
  company_health_carer_kind_enum_by_pk?: Maybe<Company_Health_Carer_Kind_Enum>;
  company_user: Array<Company_User>;
  company_user_aggregate: Company_User_Aggregate;
  company_user_by_pk?: Maybe<Company_User>;
  coupon: Array<Coupon>;
  coupon_aggregate: Coupon_Aggregate;
  coupon_by_pk?: Maybe<Coupon>;
  crm: Array<Crm>;
  crm_aggregate: Crm_Aggregate;
  crm_by_pk?: Maybe<Crm>;
  dream: Array<Dream>;
  dream_aggregate: Dream_Aggregate;
  dream_by_pk?: Maybe<Dream>;
  eficiency: Array<Eficiency>;
  eficiency_aggregate: Eficiency_Aggregate;
  eficiency_by_pk?: Maybe<Eficiency>;
  epworth: Array<Epworth>;
  epworth_aggregate: Epworth_Aggregate;
  epworth_by_pk?: Maybe<Epworth>;
  gad: Array<Gad>;
  gad7: Array<Gad7>;
  gad7_aggregate: Gad7_Aggregate;
  gad7_by_pk?: Maybe<Gad7>;
  gad_aggregate: Gad_Aggregate;
  gad_by_pk?: Maybe<Gad>;
  generic_questionnaire: Array<Generic_Questionnaire>;
  generic_questionnaire_aggregate: Generic_Questionnaire_Aggregate;
  generic_questionnaire_by_pk?: Maybe<Generic_Questionnaire>;
  generic_questionnaire_name_enum: Array<Generic_Questionnaire_Name_Enum>;
  generic_questionnaire_name_enum_aggregate: Generic_Questionnaire_Name_Enum_Aggregate;
  generic_questionnaire_name_enum_by_pk?: Maybe<Generic_Questionnaire_Name_Enum>;
  google_sheet_row: Array<Google_Sheet_Row>;
  google_sheet_row_aggregate: Google_Sheet_Row_Aggregate;
  google_sheet_row_by_pk?: Maybe<Google_Sheet_Row>;
  gratitude: Array<Gratitude>;
  gratitude_aggregate: Gratitude_Aggregate;
  gratitude_by_pk?: Maybe<Gratitude>;
  health_carer: Array<Health_Carer>;
  health_carer_aggregate: Health_Carer_Aggregate;
  health_carer_by_pk?: Maybe<Health_Carer>;
  health_carer_membership_kind_enum: Array<Health_Carer_Membership_Kind_Enum>;
  health_carer_membership_kind_enum_aggregate: Health_Carer_Membership_Kind_Enum_Aggregate;
  health_carer_membership_kind_enum_by_pk?: Maybe<Health_Carer_Membership_Kind_Enum>;
  health_carer_money_request: Array<Health_Carer_Money_Request>;
  health_carer_money_request_aggregate: Health_Carer_Money_Request_Aggregate;
  health_carer_money_request_by_pk?: Maybe<Health_Carer_Money_Request>;
  health_carer_money_request_log: Array<Health_Carer_Money_Request_Log>;
  health_carer_money_request_log_aggregate: Health_Carer_Money_Request_Log_Aggregate;
  health_carer_money_request_log_by_pk?: Maybe<Health_Carer_Money_Request_Log>;
  health_carer_money_request_status_enum: Array<Health_Carer_Money_Request_Status_Enum>;
  health_carer_money_request_status_enum_aggregate: Health_Carer_Money_Request_Status_Enum_Aggregate;
  health_carer_money_request_status_enum_by_pk?: Maybe<Health_Carer_Money_Request_Status_Enum>;
  health_carer_pagarme_recipient: Array<Health_Carer_Pagarme_Recipient>;
  health_carer_pagarme_recipient_aggregate: Health_Carer_Pagarme_Recipient_Aggregate;
  health_carer_pagarme_recipient_by_pk?: Maybe<Health_Carer_Pagarme_Recipient>;
  health_carer_telehealth: Array<Health_Carer_Telehealth>;
  health_carer_telehealth_aggregate: Health_Carer_Telehealth_Aggregate;
  health_carer_telehealth_by_pk?: Maybe<Health_Carer_Telehealth>;
  health_carer_transactions: Array<Health_Carer_Transactions>;
  health_carer_transactions_aggregate: Health_Carer_Transactions_Aggregate;
  health_carer_transactions_by_pk?: Maybe<Health_Carer_Transactions>;
  health_carer_user: Array<Health_Carer_User>;
  health_carer_user_aggregate: Health_Carer_User_Aggregate;
  health_carer_user_by_pk?: Maybe<Health_Carer_User>;
  isi: Array<Isi>;
  isi_aggregate: Isi_Aggregate;
  isi_by_pk?: Maybe<Isi>;
  journey: Array<Journey>;
  journey_aggregate: Journey_Aggregate;
  journey_by_pk?: Maybe<Journey>;
  journey_program_sessions: Array<Journey_Program_Sessions>;
  journey_program_sessions_aggregate: Journey_Program_Sessions_Aggregate;
  journey_program_sessions_by_pk?: Maybe<Journey_Program_Sessions>;
  kupperman: Array<Kupperman>;
  kupperman_aggregate: Kupperman_Aggregate;
  kupperman_by_pk?: Maybe<Kupperman>;
  landing_page_redirect: Array<Landing_Page_Redirect>;
  landing_page_redirect_aggregate: Landing_Page_Redirect_Aggregate;
  landing_page_redirect_by_pk?: Maybe<Landing_Page_Redirect>;
  log: Array<Log>;
  log_aggregate: Log_Aggregate;
  log_by_pk?: Maybe<Log>;
  log_type_enum: Array<Log_Type_Enum>;
  log_type_enum_aggregate: Log_Type_Enum_Aggregate;
  log_type_enum_by_pk?: Maybe<Log_Type_Enum>;
  menopause_greene_scale: Array<Menopause_Greene_Scale>;
  menopause_greene_scale_aggregate: Menopause_Greene_Scale_Aggregate;
  menopause_greene_scale_by_pk?: Maybe<Menopause_Greene_Scale>;
  munich_chronotype: Array<Munich_Chronotype>;
  munich_chronotype_aggregate: Munich_Chronotype_Aggregate;
  munich_chronotype_by_pk?: Maybe<Munich_Chronotype>;
  munich_chronotype_core: Array<Munich_Chronotype_Core>;
  munich_chronotype_core_aggregate: Munich_Chronotype_Core_Aggregate;
  munich_chronotype_core_by_pk?: Maybe<Munich_Chronotype_Core>;
  os_enum: Array<Os_Enum>;
  os_enum_aggregate: Os_Enum_Aggregate;
  os_enum_by_pk?: Maybe<Os_Enum>;
  parking_lot: Array<Parking_Lot>;
  parking_lot_aggregate: Parking_Lot_Aggregate;
  parking_lot_by_pk?: Maybe<Parking_Lot>;
  personality_questionnaire: Array<Personality_Questionnaire>;
  personality_questionnaire_aggregate: Personality_Questionnaire_Aggregate;
  personality_questionnaire_by_pk?: Maybe<Personality_Questionnaire>;
  phq: Array<Phq>;
  phq9: Array<Phq9>;
  phq9_aggregate: Phq9_Aggregate;
  phq9_by_pk?: Maybe<Phq9>;
  phq_aggregate: Phq_Aggregate;
  phq_by_pk?: Maybe<Phq>;
  pre_user: Array<Pre_User>;
  pre_user_aggregate: Pre_User_Aggregate;
  pre_user_by_pk?: Maybe<Pre_User>;
  pre_user_source_enum: Array<Pre_User_Source_Enum>;
  pre_user_source_enum_aggregate: Pre_User_Source_Enum_Aggregate;
  pre_user_source_enum_by_pk?: Maybe<Pre_User_Source_Enum>;
  productivity: Array<Productivity>;
  productivity_aggregate: Productivity_Aggregate;
  productivity_by_pk?: Maybe<Productivity>;
  program_session: Array<Program_Session>;
  program_session_aggregate: Program_Session_Aggregate;
  program_session_by_pk?: Maybe<Program_Session>;
  psqi: Array<Psqi>;
  psqi_aggregate: Psqi_Aggregate;
  psqi_by_pk?: Maybe<Psqi>;
  rating: Array<Rating>;
  rating_aggregate: Rating_Aggregate;
  rating_by_pk?: Maybe<Rating>;
  recurrence_model_enum: Array<Recurrence_Model_Enum>;
  recurrence_model_enum_aggregate: Recurrence_Model_Enum_Aggregate;
  recurrence_model_enum_by_pk?: Maybe<Recurrence_Model_Enum>;
  reminder: Array<Reminder>;
  reminder_aggregate: Reminder_Aggregate;
  reminder_by_pk?: Maybe<Reminder>;
  reminder_type_enum: Array<Reminder_Type_Enum>;
  reminder_type_enum_aggregate: Reminder_Type_Enum_Aggregate;
  reminder_type_enum_by_pk?: Maybe<Reminder_Type_Enum>;
  sleep_diary: Array<Sleep_Diary>;
  sleep_diary_aggregate: Sleep_Diary_Aggregate;
  sleep_diary_apple_health: Array<Sleep_Diary_Apple_Health>;
  sleep_diary_apple_health_aggregate: Sleep_Diary_Apple_Health_Aggregate;
  sleep_diary_apple_health_by_pk?: Maybe<Sleep_Diary_Apple_Health>;
  sleep_diary_by_pk?: Maybe<Sleep_Diary>;
  sleep_diary_google_fit: Array<Sleep_Diary_Google_Fit>;
  sleep_diary_google_fit_aggregate: Sleep_Diary_Google_Fit_Aggregate;
  sleep_diary_google_fit_by_pk?: Maybe<Sleep_Diary_Google_Fit>;
  sleep_diary_interval: Array<Sleep_Diary_Interval>;
  sleep_diary_interval_aggregate: Sleep_Diary_Interval_Aggregate;
  sleep_diary_interval_by_pk?: Maybe<Sleep_Diary_Interval>;
  sleep_diary_medicine: Array<Sleep_Diary_Medicine>;
  sleep_diary_medicine_aggregate: Sleep_Diary_Medicine_Aggregate;
  sleep_diary_medicine_by_pk?: Maybe<Sleep_Diary_Medicine>;
  sleep_diary_sleep_tag: Array<Sleep_Diary_Sleep_Tag>;
  sleep_diary_sleep_tag_aggregate: Sleep_Diary_Sleep_Tag_Aggregate;
  sleep_diary_sleep_tag_by_pk?: Maybe<Sleep_Diary_Sleep_Tag>;
  sleep_restriction: Array<Sleep_Restriction>;
  sleep_restriction_aggregate: Sleep_Restriction_Aggregate;
  sleep_restriction_by_pk?: Maybe<Sleep_Restriction>;
  sleep_tag_enum: Array<Sleep_Tag_Enum>;
  sleep_tag_enum_aggregate: Sleep_Tag_Enum_Aggregate;
  sleep_tag_enum_by_pk?: Maybe<Sleep_Tag_Enum>;
  sleep_tracker_compressed_data: Array<Sleep_Tracker_Compressed_Data>;
  sleep_tracker_compressed_data_aggregate: Sleep_Tracker_Compressed_Data_Aggregate;
  sleep_tracker_compressed_data_by_pk?: Maybe<Sleep_Tracker_Compressed_Data>;
  stop_bang: Array<Stop_Bang>;
  stop_bang_aggregate: Stop_Bang_Aggregate;
  stop_bang_by_pk?: Maybe<Stop_Bang>;
  subscription: Array<Subscription>;
  subscription_aggregate: Subscription_Aggregate;
  subscription_by_pk?: Maybe<Subscription>;
  technique_resource: Array<Technique_Resource>;
  technique_resource_aggregate: Technique_Resource_Aggregate;
  technique_resource_by_pk?: Maybe<Technique_Resource>;
  toolbox: Array<Toolbox>;
  toolbox_aggregate: Toolbox_Aggregate;
  toolbox_by_pk?: Maybe<Toolbox>;
  user: Array<User>;
  user_aggregate: User_Aggregate;
  user_by_pk?: Maybe<User>;
  user_checkout: Array<User_Checkout>;
  user_checkout_aggregate: User_Checkout_Aggregate;
  user_checkout_by_pk?: Maybe<User_Checkout>;
  user_coupon: Array<User_Coupon>;
  user_coupon_aggregate: User_Coupon_Aggregate;
  user_coupon_by_pk?: Maybe<User_Coupon>;
  user_journey: Array<User_Journey>;
  user_journey_aggregate: User_Journey_Aggregate;
  user_journey_by_pk?: Maybe<User_Journey>;
  user_key_value: Array<User_Key_Value>;
  user_key_value_aggregate: User_Key_Value_Aggregate;
  user_key_value_by_pk?: Maybe<User_Key_Value>;
  user_key_value_key_enum: Array<User_Key_Value_Key_Enum>;
  user_key_value_key_enum_aggregate: User_Key_Value_Key_Enum_Aggregate;
  user_key_value_key_enum_by_pk?: Maybe<User_Key_Value_Key_Enum>;
  user_notification: Array<User_Notification>;
  user_notification_aggregate: User_Notification_Aggregate;
  user_notification_by_pk?: Maybe<User_Notification>;
  user_program_session: Array<User_Program_Session>;
  user_program_session_aggregate: User_Program_Session_Aggregate;
  user_program_session_by_pk?: Maybe<User_Program_Session>;
  user_program_session_enum: Array<User_Program_Session_Enum>;
  user_program_session_enum_aggregate: User_Program_Session_Enum_Aggregate;
  user_program_session_enum_by_pk?: Maybe<User_Program_Session_Enum>;
  user_reminder_info: Array<User_Reminder_Info>;
  user_reminder_info_aggregate: User_Reminder_Info_Aggregate;
  user_reminder_info_by_pk?: Maybe<User_Reminder_Info>;
  user_tag: Array<User_Tag>;
  user_tag_aggregate: User_Tag_Aggregate;
  user_tag_by_pk?: Maybe<User_Tag>;
  user_telehealth: Array<User_Telehealth>;
  user_telehealth_aggregate: User_Telehealth_Aggregate;
  user_telehealth_by_pk?: Maybe<User_Telehealth>;
  yes_no_question_enum: Array<Yes_No_Question_Enum>;
  yes_no_question_enum_aggregate: Yes_No_Question_Enum_Aggregate;
  yes_no_question_enum_by_pk?: Maybe<Yes_No_Question_Enum>;
};

export type Subscription_RootApnea_QuestionnaireArgs = {
  distinct_on?: InputMaybe<Array<Apnea_Questionnaire_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Apnea_Questionnaire_Order_By>>;
  where?: InputMaybe<Apnea_Questionnaire_Bool_Exp>;
};

export type Subscription_RootApnea_Questionnaire_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apnea_Questionnaire_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Apnea_Questionnaire_Order_By>>;
  where?: InputMaybe<Apnea_Questionnaire_Bool_Exp>;
};

export type Subscription_RootApnea_Questionnaire_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootApnea_Scan_PhotoArgs = {
  distinct_on?: InputMaybe<Array<Apnea_Scan_Photo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Apnea_Scan_Photo_Order_By>>;
  where?: InputMaybe<Apnea_Scan_Photo_Bool_Exp>;
};

export type Subscription_RootApnea_Scan_Photo_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apnea_Scan_Photo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Apnea_Scan_Photo_Order_By>>;
  where?: InputMaybe<Apnea_Scan_Photo_Bool_Exp>;
};

export type Subscription_RootApnea_Scan_Photo_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootApp_CustomizationArgs = {
  distinct_on?: InputMaybe<Array<App_Customization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<App_Customization_Order_By>>;
  where?: InputMaybe<App_Customization_Bool_Exp>;
};

export type Subscription_RootApp_Customization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Customization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<App_Customization_Order_By>>;
  where?: InputMaybe<App_Customization_Bool_Exp>;
};

export type Subscription_RootApp_Customization_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootApp_Customization_Type_EnumArgs = {
  distinct_on?: InputMaybe<Array<App_Customization_Type_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<App_Customization_Type_Enum_Order_By>>;
  where?: InputMaybe<App_Customization_Type_Enum_Bool_Exp>;
};

export type Subscription_RootApp_Customization_Type_Enum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Customization_Type_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<App_Customization_Type_Enum_Order_By>>;
  where?: InputMaybe<App_Customization_Type_Enum_Bool_Exp>;
};

export type Subscription_RootApp_Customization_Type_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Subscription_RootAppointmentArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Order_By>>;
  where?: InputMaybe<Appointment_Bool_Exp>;
};

export type Subscription_RootAppointment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Order_By>>;
  where?: InputMaybe<Appointment_Bool_Exp>;
};

export type Subscription_RootAppointment_By_PkArgs = {
  id: Scalars['String'];
};

export type Subscription_RootAppointment_Status_EnumArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Status_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Status_Enum_Order_By>>;
  where?: InputMaybe<Appointment_Status_Enum_Bool_Exp>;
};

export type Subscription_RootAppointment_Status_Enum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Status_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Status_Enum_Order_By>>;
  where?: InputMaybe<Appointment_Status_Enum_Bool_Exp>;
};

export type Subscription_RootAppointment_Status_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Subscription_RootAssesmentArgs = {
  distinct_on?: InputMaybe<Array<Assesment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assesment_Order_By>>;
  where?: InputMaybe<Assesment_Bool_Exp>;
};

export type Subscription_RootAssesment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assesment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assesment_Order_By>>;
  where?: InputMaybe<Assesment_Bool_Exp>;
};

export type Subscription_RootAssesment_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootB2b_Diagnosis_V1Args = {
  distinct_on?: InputMaybe<Array<B2b_Diagnosis_V1_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<B2b_Diagnosis_V1_Order_By>>;
  where?: InputMaybe<B2b_Diagnosis_V1_Bool_Exp>;
};

export type Subscription_RootB2b_Diagnosis_V1_AggregateArgs = {
  distinct_on?: InputMaybe<Array<B2b_Diagnosis_V1_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<B2b_Diagnosis_V1_Order_By>>;
  where?: InputMaybe<B2b_Diagnosis_V1_Bool_Exp>;
};

export type Subscription_RootB2b_Diagnosis_V1_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCatastrophic_ThinkingsArgs = {
  distinct_on?: InputMaybe<Array<Catastrophic_Thinkings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Catastrophic_Thinkings_Order_By>>;
  where?: InputMaybe<Catastrophic_Thinkings_Bool_Exp>;
};

export type Subscription_RootCatastrophic_Thinkings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Catastrophic_Thinkings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Catastrophic_Thinkings_Order_By>>;
  where?: InputMaybe<Catastrophic_Thinkings_Bool_Exp>;
};

export type Subscription_RootCatastrophic_Thinkings_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCommunity_UserArgs = {
  distinct_on?: InputMaybe<Array<Community_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_User_Order_By>>;
  where?: InputMaybe<Community_User_Bool_Exp>;
};

export type Subscription_RootCommunity_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Community_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Community_User_Order_By>>;
  where?: InputMaybe<Community_User_Bool_Exp>;
};

export type Subscription_RootCommunity_User_By_PkArgs = {
  community_id: Scalars['Int'];
};

export type Subscription_RootCompanyArgs = {
  distinct_on?: InputMaybe<Array<Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Order_By>>;
  where?: InputMaybe<Company_Bool_Exp>;
};

export type Subscription_RootCompany_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Order_By>>;
  where?: InputMaybe<Company_Bool_Exp>;
};

export type Subscription_RootCompany_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCompany_Health_CarerArgs = {
  distinct_on?: InputMaybe<Array<Company_Health_Carer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Health_Carer_Order_By>>;
  where?: InputMaybe<Company_Health_Carer_Bool_Exp>;
};

export type Subscription_RootCompany_Health_Carer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Health_Carer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Health_Carer_Order_By>>;
  where?: InputMaybe<Company_Health_Carer_Bool_Exp>;
};

export type Subscription_RootCompany_Health_Carer_By_PkArgs = {
  company_id: Scalars['Int'];
  health_carer_id: Scalars['String'];
};

export type Subscription_RootCompany_Health_Carer_Kind_EnumArgs = {
  distinct_on?: InputMaybe<Array<Company_Health_Carer_Kind_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Health_Carer_Kind_Enum_Order_By>>;
  where?: InputMaybe<Company_Health_Carer_Kind_Enum_Bool_Exp>;
};

export type Subscription_RootCompany_Health_Carer_Kind_Enum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Health_Carer_Kind_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Health_Carer_Kind_Enum_Order_By>>;
  where?: InputMaybe<Company_Health_Carer_Kind_Enum_Bool_Exp>;
};

export type Subscription_RootCompany_Health_Carer_Kind_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Subscription_RootCompany_UserArgs = {
  distinct_on?: InputMaybe<Array<Company_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_User_Order_By>>;
  where?: InputMaybe<Company_User_Bool_Exp>;
};

export type Subscription_RootCompany_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_User_Order_By>>;
  where?: InputMaybe<Company_User_Bool_Exp>;
};

export type Subscription_RootCompany_User_By_PkArgs = {
  company_id: Scalars['Int'];
  user_id: Scalars['String'];
};

export type Subscription_RootCouponArgs = {
  distinct_on?: InputMaybe<Array<Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coupon_Order_By>>;
  where?: InputMaybe<Coupon_Bool_Exp>;
};

export type Subscription_RootCoupon_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coupon_Order_By>>;
  where?: InputMaybe<Coupon_Bool_Exp>;
};

export type Subscription_RootCoupon_By_PkArgs = {
  code: Scalars['String'];
};

export type Subscription_RootCrmArgs = {
  distinct_on?: InputMaybe<Array<Crm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Crm_Order_By>>;
  where?: InputMaybe<Crm_Bool_Exp>;
};

export type Subscription_RootCrm_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Crm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Crm_Order_By>>;
  where?: InputMaybe<Crm_Bool_Exp>;
};

export type Subscription_RootCrm_By_PkArgs = {
  identification: Scalars['String'];
  state: Scalars['String'];
};

export type Subscription_RootDreamArgs = {
  distinct_on?: InputMaybe<Array<Dream_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dream_Order_By>>;
  where?: InputMaybe<Dream_Bool_Exp>;
};

export type Subscription_RootDream_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dream_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dream_Order_By>>;
  where?: InputMaybe<Dream_Bool_Exp>;
};

export type Subscription_RootDream_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootEficiencyArgs = {
  distinct_on?: InputMaybe<Array<Eficiency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eficiency_Order_By>>;
  where?: InputMaybe<Eficiency_Bool_Exp>;
};

export type Subscription_RootEficiency_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eficiency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eficiency_Order_By>>;
  where?: InputMaybe<Eficiency_Bool_Exp>;
};

export type Subscription_RootEficiency_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootEpworthArgs = {
  distinct_on?: InputMaybe<Array<Epworth_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Epworth_Order_By>>;
  where?: InputMaybe<Epworth_Bool_Exp>;
};

export type Subscription_RootEpworth_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Epworth_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Epworth_Order_By>>;
  where?: InputMaybe<Epworth_Bool_Exp>;
};

export type Subscription_RootEpworth_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootGadArgs = {
  distinct_on?: InputMaybe<Array<Gad_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gad_Order_By>>;
  where?: InputMaybe<Gad_Bool_Exp>;
};

export type Subscription_RootGad7Args = {
  distinct_on?: InputMaybe<Array<Gad7_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gad7_Order_By>>;
  where?: InputMaybe<Gad7_Bool_Exp>;
};

export type Subscription_RootGad7_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gad7_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gad7_Order_By>>;
  where?: InputMaybe<Gad7_Bool_Exp>;
};

export type Subscription_RootGad7_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootGad_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gad_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gad_Order_By>>;
  where?: InputMaybe<Gad_Bool_Exp>;
};

export type Subscription_RootGad_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootGeneric_QuestionnaireArgs = {
  distinct_on?: InputMaybe<Array<Generic_Questionnaire_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Generic_Questionnaire_Order_By>>;
  where?: InputMaybe<Generic_Questionnaire_Bool_Exp>;
};

export type Subscription_RootGeneric_Questionnaire_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Generic_Questionnaire_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Generic_Questionnaire_Order_By>>;
  where?: InputMaybe<Generic_Questionnaire_Bool_Exp>;
};

export type Subscription_RootGeneric_Questionnaire_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootGeneric_Questionnaire_Name_EnumArgs = {
  distinct_on?: InputMaybe<Array<Generic_Questionnaire_Name_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Generic_Questionnaire_Name_Enum_Order_By>>;
  where?: InputMaybe<Generic_Questionnaire_Name_Enum_Bool_Exp>;
};

export type Subscription_RootGeneric_Questionnaire_Name_Enum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Generic_Questionnaire_Name_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Generic_Questionnaire_Name_Enum_Order_By>>;
  where?: InputMaybe<Generic_Questionnaire_Name_Enum_Bool_Exp>;
};

export type Subscription_RootGeneric_Questionnaire_Name_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Subscription_RootGoogle_Sheet_RowArgs = {
  distinct_on?: InputMaybe<Array<Google_Sheet_Row_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Google_Sheet_Row_Order_By>>;
  where?: InputMaybe<Google_Sheet_Row_Bool_Exp>;
};

export type Subscription_RootGoogle_Sheet_Row_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Google_Sheet_Row_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Google_Sheet_Row_Order_By>>;
  where?: InputMaybe<Google_Sheet_Row_Bool_Exp>;
};

export type Subscription_RootGoogle_Sheet_Row_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootGratitudeArgs = {
  distinct_on?: InputMaybe<Array<Gratitude_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gratitude_Order_By>>;
  where?: InputMaybe<Gratitude_Bool_Exp>;
};

export type Subscription_RootGratitude_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gratitude_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gratitude_Order_By>>;
  where?: InputMaybe<Gratitude_Bool_Exp>;
};

export type Subscription_RootGratitude_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootHealth_CarerArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_Order_By>>;
  where?: InputMaybe<Health_Carer_Bool_Exp>;
};

export type Subscription_RootHealth_Carer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_Order_By>>;
  where?: InputMaybe<Health_Carer_Bool_Exp>;
};

export type Subscription_RootHealth_Carer_By_PkArgs = {
  id: Scalars['String'];
};

export type Subscription_RootHealth_Carer_Membership_Kind_EnumArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_Membership_Kind_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_Membership_Kind_Enum_Order_By>>;
  where?: InputMaybe<Health_Carer_Membership_Kind_Enum_Bool_Exp>;
};

export type Subscription_RootHealth_Carer_Membership_Kind_Enum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_Membership_Kind_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_Membership_Kind_Enum_Order_By>>;
  where?: InputMaybe<Health_Carer_Membership_Kind_Enum_Bool_Exp>;
};

export type Subscription_RootHealth_Carer_Membership_Kind_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Subscription_RootHealth_Carer_Money_RequestArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_Money_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_Money_Request_Order_By>>;
  where?: InputMaybe<Health_Carer_Money_Request_Bool_Exp>;
};

export type Subscription_RootHealth_Carer_Money_Request_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_Money_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_Money_Request_Order_By>>;
  where?: InputMaybe<Health_Carer_Money_Request_Bool_Exp>;
};

export type Subscription_RootHealth_Carer_Money_Request_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Subscription_RootHealth_Carer_Money_Request_LogArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_Money_Request_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_Money_Request_Log_Order_By>>;
  where?: InputMaybe<Health_Carer_Money_Request_Log_Bool_Exp>;
};

export type Subscription_RootHealth_Carer_Money_Request_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_Money_Request_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_Money_Request_Log_Order_By>>;
  where?: InputMaybe<Health_Carer_Money_Request_Log_Bool_Exp>;
};

export type Subscription_RootHealth_Carer_Money_Request_Log_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Subscription_RootHealth_Carer_Money_Request_Status_EnumArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_Money_Request_Status_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_Money_Request_Status_Enum_Order_By>>;
  where?: InputMaybe<Health_Carer_Money_Request_Status_Enum_Bool_Exp>;
};

export type Subscription_RootHealth_Carer_Money_Request_Status_Enum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_Money_Request_Status_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_Money_Request_Status_Enum_Order_By>>;
  where?: InputMaybe<Health_Carer_Money_Request_Status_Enum_Bool_Exp>;
};

export type Subscription_RootHealth_Carer_Money_Request_Status_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Subscription_RootHealth_Carer_Pagarme_RecipientArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_Pagarme_Recipient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_Pagarme_Recipient_Order_By>>;
  where?: InputMaybe<Health_Carer_Pagarme_Recipient_Bool_Exp>;
};

export type Subscription_RootHealth_Carer_Pagarme_Recipient_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_Pagarme_Recipient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_Pagarme_Recipient_Order_By>>;
  where?: InputMaybe<Health_Carer_Pagarme_Recipient_Bool_Exp>;
};

export type Subscription_RootHealth_Carer_Pagarme_Recipient_By_PkArgs = {
  health_carer_id: Scalars['String'];
};

export type Subscription_RootHealth_Carer_TelehealthArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_Telehealth_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_Telehealth_Order_By>>;
  where?: InputMaybe<Health_Carer_Telehealth_Bool_Exp>;
};

export type Subscription_RootHealth_Carer_Telehealth_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_Telehealth_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_Telehealth_Order_By>>;
  where?: InputMaybe<Health_Carer_Telehealth_Bool_Exp>;
};

export type Subscription_RootHealth_Carer_Telehealth_By_PkArgs = {
  health_carer_id: Scalars['String'];
};

export type Subscription_RootHealth_Carer_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_Transactions_Order_By>>;
  where?: InputMaybe<Health_Carer_Transactions_Bool_Exp>;
};

export type Subscription_RootHealth_Carer_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_Transactions_Order_By>>;
  where?: InputMaybe<Health_Carer_Transactions_Bool_Exp>;
};

export type Subscription_RootHealth_Carer_Transactions_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Subscription_RootHealth_Carer_UserArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_User_Order_By>>;
  where?: InputMaybe<Health_Carer_User_Bool_Exp>;
};

export type Subscription_RootHealth_Carer_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_User_Order_By>>;
  where?: InputMaybe<Health_Carer_User_Bool_Exp>;
};

export type Subscription_RootHealth_Carer_User_By_PkArgs = {
  health_carer_id: Scalars['String'];
  user_id: Scalars['String'];
};

export type Subscription_RootIsiArgs = {
  distinct_on?: InputMaybe<Array<Isi_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Isi_Order_By>>;
  where?: InputMaybe<Isi_Bool_Exp>;
};

export type Subscription_RootIsi_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Isi_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Isi_Order_By>>;
  where?: InputMaybe<Isi_Bool_Exp>;
};

export type Subscription_RootIsi_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootJourneyArgs = {
  distinct_on?: InputMaybe<Array<Journey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Journey_Order_By>>;
  where?: InputMaybe<Journey_Bool_Exp>;
};

export type Subscription_RootJourney_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Journey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Journey_Order_By>>;
  where?: InputMaybe<Journey_Bool_Exp>;
};

export type Subscription_RootJourney_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootJourney_Program_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Journey_Program_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Journey_Program_Sessions_Order_By>>;
  where?: InputMaybe<Journey_Program_Sessions_Bool_Exp>;
};

export type Subscription_RootJourney_Program_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Journey_Program_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Journey_Program_Sessions_Order_By>>;
  where?: InputMaybe<Journey_Program_Sessions_Bool_Exp>;
};

export type Subscription_RootJourney_Program_Sessions_By_PkArgs = {
  journey_id: Scalars['Int'];
  session_id: Scalars['Int'];
};

export type Subscription_RootKuppermanArgs = {
  distinct_on?: InputMaybe<Array<Kupperman_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kupperman_Order_By>>;
  where?: InputMaybe<Kupperman_Bool_Exp>;
};

export type Subscription_RootKupperman_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kupperman_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kupperman_Order_By>>;
  where?: InputMaybe<Kupperman_Bool_Exp>;
};

export type Subscription_RootKupperman_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootLanding_Page_RedirectArgs = {
  distinct_on?: InputMaybe<Array<Landing_Page_Redirect_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Landing_Page_Redirect_Order_By>>;
  where?: InputMaybe<Landing_Page_Redirect_Bool_Exp>;
};

export type Subscription_RootLanding_Page_Redirect_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Landing_Page_Redirect_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Landing_Page_Redirect_Order_By>>;
  where?: InputMaybe<Landing_Page_Redirect_Bool_Exp>;
};

export type Subscription_RootLanding_Page_Redirect_By_PkArgs = {
  from_path: Scalars['String'];
};

export type Subscription_RootLogArgs = {
  distinct_on?: InputMaybe<Array<Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Log_Order_By>>;
  where?: InputMaybe<Log_Bool_Exp>;
};

export type Subscription_RootLog_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Log_Order_By>>;
  where?: InputMaybe<Log_Bool_Exp>;
};

export type Subscription_RootLog_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootLog_Type_EnumArgs = {
  distinct_on?: InputMaybe<Array<Log_Type_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Log_Type_Enum_Order_By>>;
  where?: InputMaybe<Log_Type_Enum_Bool_Exp>;
};

export type Subscription_RootLog_Type_Enum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Log_Type_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Log_Type_Enum_Order_By>>;
  where?: InputMaybe<Log_Type_Enum_Bool_Exp>;
};

export type Subscription_RootLog_Type_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Subscription_RootMenopause_Greene_ScaleArgs = {
  distinct_on?: InputMaybe<Array<Menopause_Greene_Scale_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menopause_Greene_Scale_Order_By>>;
  where?: InputMaybe<Menopause_Greene_Scale_Bool_Exp>;
};

export type Subscription_RootMenopause_Greene_Scale_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Menopause_Greene_Scale_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menopause_Greene_Scale_Order_By>>;
  where?: InputMaybe<Menopause_Greene_Scale_Bool_Exp>;
};

export type Subscription_RootMenopause_Greene_Scale_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootMunich_ChronotypeArgs = {
  distinct_on?: InputMaybe<Array<Munich_Chronotype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Munich_Chronotype_Order_By>>;
  where?: InputMaybe<Munich_Chronotype_Bool_Exp>;
};

export type Subscription_RootMunich_Chronotype_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Munich_Chronotype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Munich_Chronotype_Order_By>>;
  where?: InputMaybe<Munich_Chronotype_Bool_Exp>;
};

export type Subscription_RootMunich_Chronotype_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootMunich_Chronotype_CoreArgs = {
  distinct_on?: InputMaybe<Array<Munich_Chronotype_Core_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Munich_Chronotype_Core_Order_By>>;
  where?: InputMaybe<Munich_Chronotype_Core_Bool_Exp>;
};

export type Subscription_RootMunich_Chronotype_Core_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Munich_Chronotype_Core_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Munich_Chronotype_Core_Order_By>>;
  where?: InputMaybe<Munich_Chronotype_Core_Bool_Exp>;
};

export type Subscription_RootMunich_Chronotype_Core_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootOs_EnumArgs = {
  distinct_on?: InputMaybe<Array<Os_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Os_Enum_Order_By>>;
  where?: InputMaybe<Os_Enum_Bool_Exp>;
};

export type Subscription_RootOs_Enum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Os_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Os_Enum_Order_By>>;
  where?: InputMaybe<Os_Enum_Bool_Exp>;
};

export type Subscription_RootOs_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Subscription_RootParking_LotArgs = {
  distinct_on?: InputMaybe<Array<Parking_Lot_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parking_Lot_Order_By>>;
  where?: InputMaybe<Parking_Lot_Bool_Exp>;
};

export type Subscription_RootParking_Lot_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parking_Lot_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parking_Lot_Order_By>>;
  where?: InputMaybe<Parking_Lot_Bool_Exp>;
};

export type Subscription_RootParking_Lot_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootPersonality_QuestionnaireArgs = {
  distinct_on?: InputMaybe<Array<Personality_Questionnaire_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Personality_Questionnaire_Order_By>>;
  where?: InputMaybe<Personality_Questionnaire_Bool_Exp>;
};

export type Subscription_RootPersonality_Questionnaire_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Personality_Questionnaire_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Personality_Questionnaire_Order_By>>;
  where?: InputMaybe<Personality_Questionnaire_Bool_Exp>;
};

export type Subscription_RootPersonality_Questionnaire_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootPhqArgs = {
  distinct_on?: InputMaybe<Array<Phq_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Phq_Order_By>>;
  where?: InputMaybe<Phq_Bool_Exp>;
};

export type Subscription_RootPhq9Args = {
  distinct_on?: InputMaybe<Array<Phq9_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Phq9_Order_By>>;
  where?: InputMaybe<Phq9_Bool_Exp>;
};

export type Subscription_RootPhq9_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Phq9_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Phq9_Order_By>>;
  where?: InputMaybe<Phq9_Bool_Exp>;
};

export type Subscription_RootPhq9_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootPhq_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Phq_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Phq_Order_By>>;
  where?: InputMaybe<Phq_Bool_Exp>;
};

export type Subscription_RootPhq_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootPre_UserArgs = {
  distinct_on?: InputMaybe<Array<Pre_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pre_User_Order_By>>;
  where?: InputMaybe<Pre_User_Bool_Exp>;
};

export type Subscription_RootPre_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pre_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pre_User_Order_By>>;
  where?: InputMaybe<Pre_User_Bool_Exp>;
};

export type Subscription_RootPre_User_By_PkArgs = {
  id: Scalars['String'];
};

export type Subscription_RootPre_User_Source_EnumArgs = {
  distinct_on?: InputMaybe<Array<Pre_User_Source_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pre_User_Source_Enum_Order_By>>;
  where?: InputMaybe<Pre_User_Source_Enum_Bool_Exp>;
};

export type Subscription_RootPre_User_Source_Enum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pre_User_Source_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pre_User_Source_Enum_Order_By>>;
  where?: InputMaybe<Pre_User_Source_Enum_Bool_Exp>;
};

export type Subscription_RootPre_User_Source_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Subscription_RootProductivityArgs = {
  distinct_on?: InputMaybe<Array<Productivity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Productivity_Order_By>>;
  where?: InputMaybe<Productivity_Bool_Exp>;
};

export type Subscription_RootProductivity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Productivity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Productivity_Order_By>>;
  where?: InputMaybe<Productivity_Bool_Exp>;
};

export type Subscription_RootProductivity_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootProgram_SessionArgs = {
  distinct_on?: InputMaybe<Array<Program_Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Program_Session_Order_By>>;
  where?: InputMaybe<Program_Session_Bool_Exp>;
};

export type Subscription_RootProgram_Session_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Program_Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Program_Session_Order_By>>;
  where?: InputMaybe<Program_Session_Bool_Exp>;
};

export type Subscription_RootProgram_Session_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootPsqiArgs = {
  distinct_on?: InputMaybe<Array<Psqi_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Psqi_Order_By>>;
  where?: InputMaybe<Psqi_Bool_Exp>;
};

export type Subscription_RootPsqi_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Psqi_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Psqi_Order_By>>;
  where?: InputMaybe<Psqi_Bool_Exp>;
};

export type Subscription_RootPsqi_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootRatingArgs = {
  distinct_on?: InputMaybe<Array<Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rating_Order_By>>;
  where?: InputMaybe<Rating_Bool_Exp>;
};

export type Subscription_RootRating_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rating_Order_By>>;
  where?: InputMaybe<Rating_Bool_Exp>;
};

export type Subscription_RootRating_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootRecurrence_Model_EnumArgs = {
  distinct_on?: InputMaybe<Array<Recurrence_Model_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recurrence_Model_Enum_Order_By>>;
  where?: InputMaybe<Recurrence_Model_Enum_Bool_Exp>;
};

export type Subscription_RootRecurrence_Model_Enum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recurrence_Model_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recurrence_Model_Enum_Order_By>>;
  where?: InputMaybe<Recurrence_Model_Enum_Bool_Exp>;
};

export type Subscription_RootRecurrence_Model_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Subscription_RootReminderArgs = {
  distinct_on?: InputMaybe<Array<Reminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reminder_Order_By>>;
  where?: InputMaybe<Reminder_Bool_Exp>;
};

export type Subscription_RootReminder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reminder_Order_By>>;
  where?: InputMaybe<Reminder_Bool_Exp>;
};

export type Subscription_RootReminder_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootReminder_Type_EnumArgs = {
  distinct_on?: InputMaybe<Array<Reminder_Type_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reminder_Type_Enum_Order_By>>;
  where?: InputMaybe<Reminder_Type_Enum_Bool_Exp>;
};

export type Subscription_RootReminder_Type_Enum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reminder_Type_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reminder_Type_Enum_Order_By>>;
  where?: InputMaybe<Reminder_Type_Enum_Bool_Exp>;
};

export type Subscription_RootReminder_Type_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Subscription_RootSleep_DiaryArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Diary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Diary_Order_By>>;
  where?: InputMaybe<Sleep_Diary_Bool_Exp>;
};

export type Subscription_RootSleep_Diary_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Diary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Diary_Order_By>>;
  where?: InputMaybe<Sleep_Diary_Bool_Exp>;
};

export type Subscription_RootSleep_Diary_Apple_HealthArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Diary_Apple_Health_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Diary_Apple_Health_Order_By>>;
  where?: InputMaybe<Sleep_Diary_Apple_Health_Bool_Exp>;
};

export type Subscription_RootSleep_Diary_Apple_Health_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Diary_Apple_Health_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Diary_Apple_Health_Order_By>>;
  where?: InputMaybe<Sleep_Diary_Apple_Health_Bool_Exp>;
};

export type Subscription_RootSleep_Diary_Apple_Health_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootSleep_Diary_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootSleep_Diary_Google_FitArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Diary_Google_Fit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Diary_Google_Fit_Order_By>>;
  where?: InputMaybe<Sleep_Diary_Google_Fit_Bool_Exp>;
};

export type Subscription_RootSleep_Diary_Google_Fit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Diary_Google_Fit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Diary_Google_Fit_Order_By>>;
  where?: InputMaybe<Sleep_Diary_Google_Fit_Bool_Exp>;
};

export type Subscription_RootSleep_Diary_Google_Fit_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootSleep_Diary_IntervalArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Diary_Interval_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Diary_Interval_Order_By>>;
  where?: InputMaybe<Sleep_Diary_Interval_Bool_Exp>;
};

export type Subscription_RootSleep_Diary_Interval_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Diary_Interval_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Diary_Interval_Order_By>>;
  where?: InputMaybe<Sleep_Diary_Interval_Bool_Exp>;
};

export type Subscription_RootSleep_Diary_Interval_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Subscription_RootSleep_Diary_MedicineArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Diary_Medicine_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Diary_Medicine_Order_By>>;
  where?: InputMaybe<Sleep_Diary_Medicine_Bool_Exp>;
};

export type Subscription_RootSleep_Diary_Medicine_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Diary_Medicine_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Diary_Medicine_Order_By>>;
  where?: InputMaybe<Sleep_Diary_Medicine_Bool_Exp>;
};

export type Subscription_RootSleep_Diary_Medicine_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootSleep_Diary_Sleep_TagArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Diary_Sleep_Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Diary_Sleep_Tag_Order_By>>;
  where?: InputMaybe<Sleep_Diary_Sleep_Tag_Bool_Exp>;
};

export type Subscription_RootSleep_Diary_Sleep_Tag_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Diary_Sleep_Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Diary_Sleep_Tag_Order_By>>;
  where?: InputMaybe<Sleep_Diary_Sleep_Tag_Bool_Exp>;
};

export type Subscription_RootSleep_Diary_Sleep_Tag_By_PkArgs = {
  sleep_diary: Scalars['Int'];
  sleep_tag: Sleep_Tag_Enum_Enum;
};

export type Subscription_RootSleep_RestrictionArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Restriction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Restriction_Order_By>>;
  where?: InputMaybe<Sleep_Restriction_Bool_Exp>;
};

export type Subscription_RootSleep_Restriction_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Restriction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Restriction_Order_By>>;
  where?: InputMaybe<Sleep_Restriction_Bool_Exp>;
};

export type Subscription_RootSleep_Restriction_By_PkArgs = {
  start_date: Scalars['date'];
  user_id: Scalars['String'];
};

export type Subscription_RootSleep_Tag_EnumArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Tag_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Tag_Enum_Order_By>>;
  where?: InputMaybe<Sleep_Tag_Enum_Bool_Exp>;
};

export type Subscription_RootSleep_Tag_Enum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Tag_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Tag_Enum_Order_By>>;
  where?: InputMaybe<Sleep_Tag_Enum_Bool_Exp>;
};

export type Subscription_RootSleep_Tag_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Subscription_RootSleep_Tracker_Compressed_DataArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Tracker_Compressed_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Tracker_Compressed_Data_Order_By>>;
  where?: InputMaybe<Sleep_Tracker_Compressed_Data_Bool_Exp>;
};

export type Subscription_RootSleep_Tracker_Compressed_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Tracker_Compressed_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Tracker_Compressed_Data_Order_By>>;
  where?: InputMaybe<Sleep_Tracker_Compressed_Data_Bool_Exp>;
};

export type Subscription_RootSleep_Tracker_Compressed_Data_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootStop_BangArgs = {
  distinct_on?: InputMaybe<Array<Stop_Bang_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stop_Bang_Order_By>>;
  where?: InputMaybe<Stop_Bang_Bool_Exp>;
};

export type Subscription_RootStop_Bang_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stop_Bang_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stop_Bang_Order_By>>;
  where?: InputMaybe<Stop_Bang_Bool_Exp>;
};

export type Subscription_RootStop_Bang_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootSubscriptionArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_Order_By>>;
  where?: InputMaybe<Subscription_Bool_Exp>;
};

export type Subscription_RootSubscription_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_Order_By>>;
  where?: InputMaybe<Subscription_Bool_Exp>;
};

export type Subscription_RootSubscription_By_PkArgs = {
  email: Scalars['String'];
};

export type Subscription_RootTechnique_ResourceArgs = {
  distinct_on?: InputMaybe<Array<Technique_Resource_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Technique_Resource_Order_By>>;
  where?: InputMaybe<Technique_Resource_Bool_Exp>;
};

export type Subscription_RootTechnique_Resource_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Technique_Resource_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Technique_Resource_Order_By>>;
  where?: InputMaybe<Technique_Resource_Bool_Exp>;
};

export type Subscription_RootTechnique_Resource_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootToolboxArgs = {
  distinct_on?: InputMaybe<Array<Toolbox_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Toolbox_Order_By>>;
  where?: InputMaybe<Toolbox_Bool_Exp>;
};

export type Subscription_RootToolbox_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Toolbox_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Toolbox_Order_By>>;
  where?: InputMaybe<Toolbox_Bool_Exp>;
};

export type Subscription_RootToolbox_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};

export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};

export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['String'];
};

export type Subscription_RootUser_CheckoutArgs = {
  distinct_on?: InputMaybe<Array<User_Checkout_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Checkout_Order_By>>;
  where?: InputMaybe<User_Checkout_Bool_Exp>;
};

export type Subscription_RootUser_Checkout_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Checkout_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Checkout_Order_By>>;
  where?: InputMaybe<User_Checkout_Bool_Exp>;
};

export type Subscription_RootUser_Checkout_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootUser_CouponArgs = {
  distinct_on?: InputMaybe<Array<User_Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Coupon_Order_By>>;
  where?: InputMaybe<User_Coupon_Bool_Exp>;
};

export type Subscription_RootUser_Coupon_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Coupon_Order_By>>;
  where?: InputMaybe<User_Coupon_Bool_Exp>;
};

export type Subscription_RootUser_Coupon_By_PkArgs = {
  coupon_id: Scalars['bigint'];
  user_id: Scalars['String'];
};

export type Subscription_RootUser_JourneyArgs = {
  distinct_on?: InputMaybe<Array<User_Journey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Journey_Order_By>>;
  where?: InputMaybe<User_Journey_Bool_Exp>;
};

export type Subscription_RootUser_Journey_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Journey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Journey_Order_By>>;
  where?: InputMaybe<User_Journey_Bool_Exp>;
};

export type Subscription_RootUser_Journey_By_PkArgs = {
  journey_id: Scalars['Int'];
  user_id: Scalars['String'];
};

export type Subscription_RootUser_Key_ValueArgs = {
  distinct_on?: InputMaybe<Array<User_Key_Value_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Key_Value_Order_By>>;
  where?: InputMaybe<User_Key_Value_Bool_Exp>;
};

export type Subscription_RootUser_Key_Value_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Key_Value_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Key_Value_Order_By>>;
  where?: InputMaybe<User_Key_Value_Bool_Exp>;
};

export type Subscription_RootUser_Key_Value_By_PkArgs = {
  key: User_Key_Value_Key_Enum_Enum;
  user_id: Scalars['String'];
};

export type Subscription_RootUser_Key_Value_Key_EnumArgs = {
  distinct_on?: InputMaybe<Array<User_Key_Value_Key_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Key_Value_Key_Enum_Order_By>>;
  where?: InputMaybe<User_Key_Value_Key_Enum_Bool_Exp>;
};

export type Subscription_RootUser_Key_Value_Key_Enum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Key_Value_Key_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Key_Value_Key_Enum_Order_By>>;
  where?: InputMaybe<User_Key_Value_Key_Enum_Bool_Exp>;
};

export type Subscription_RootUser_Key_Value_Key_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Subscription_RootUser_NotificationArgs = {
  distinct_on?: InputMaybe<Array<User_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Notification_Order_By>>;
  where?: InputMaybe<User_Notification_Bool_Exp>;
};

export type Subscription_RootUser_Notification_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Notification_Order_By>>;
  where?: InputMaybe<User_Notification_Bool_Exp>;
};

export type Subscription_RootUser_Notification_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootUser_Program_SessionArgs = {
  distinct_on?: InputMaybe<Array<User_Program_Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Program_Session_Order_By>>;
  where?: InputMaybe<User_Program_Session_Bool_Exp>;
};

export type Subscription_RootUser_Program_Session_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Program_Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Program_Session_Order_By>>;
  where?: InputMaybe<User_Program_Session_Bool_Exp>;
};

export type Subscription_RootUser_Program_Session_By_PkArgs = {
  session_id: Scalars['Int'];
  user_id: Scalars['String'];
};

export type Subscription_RootUser_Program_Session_EnumArgs = {
  distinct_on?: InputMaybe<Array<User_Program_Session_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Program_Session_Enum_Order_By>>;
  where?: InputMaybe<User_Program_Session_Enum_Bool_Exp>;
};

export type Subscription_RootUser_Program_Session_Enum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Program_Session_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Program_Session_Enum_Order_By>>;
  where?: InputMaybe<User_Program_Session_Enum_Bool_Exp>;
};

export type Subscription_RootUser_Program_Session_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export type Subscription_RootUser_Reminder_InfoArgs = {
  distinct_on?: InputMaybe<Array<User_Reminder_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Reminder_Info_Order_By>>;
  where?: InputMaybe<User_Reminder_Info_Bool_Exp>;
};

export type Subscription_RootUser_Reminder_Info_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Reminder_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Reminder_Info_Order_By>>;
  where?: InputMaybe<User_Reminder_Info_Bool_Exp>;
};

export type Subscription_RootUser_Reminder_Info_By_PkArgs = {
  messenger_id: Scalars['String'];
};

export type Subscription_RootUser_TagArgs = {
  distinct_on?: InputMaybe<Array<User_Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Tag_Order_By>>;
  where?: InputMaybe<User_Tag_Bool_Exp>;
};

export type Subscription_RootUser_Tag_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Tag_Order_By>>;
  where?: InputMaybe<User_Tag_Bool_Exp>;
};

export type Subscription_RootUser_Tag_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootUser_TelehealthArgs = {
  distinct_on?: InputMaybe<Array<User_Telehealth_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Telehealth_Order_By>>;
  where?: InputMaybe<User_Telehealth_Bool_Exp>;
};

export type Subscription_RootUser_Telehealth_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Telehealth_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Telehealth_Order_By>>;
  where?: InputMaybe<User_Telehealth_Bool_Exp>;
};

export type Subscription_RootUser_Telehealth_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootYes_No_Question_EnumArgs = {
  distinct_on?: InputMaybe<Array<Yes_No_Question_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Yes_No_Question_Enum_Order_By>>;
  where?: InputMaybe<Yes_No_Question_Enum_Bool_Exp>;
};

export type Subscription_RootYes_No_Question_Enum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Yes_No_Question_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Yes_No_Question_Enum_Order_By>>;
  where?: InputMaybe<Yes_No_Question_Enum_Bool_Exp>;
};

export type Subscription_RootYes_No_Question_Enum_By_PkArgs = {
  text: Scalars['String'];
};

export enum Subscription_Select_Column {
  CreatedAt = 'created_at',
  Email = 'email',
  InternalId = 'internal_id',
  Name = 'name',
  Source = 'source',
  Subscribed = 'subscribed',
  UserId = 'userId',
}

export type Subscription_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  internal_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  subscribed?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type Subscription_Stddev_Fields = {
  __typename?: 'subscription_stddev_fields';
  internal_id?: Maybe<Scalars['Float']>;
};

export type Subscription_Stddev_Order_By = {
  internal_id?: InputMaybe<Order_By>;
};

export type Subscription_Stddev_Pop_Fields = {
  __typename?: 'subscription_stddev_pop_fields';
  internal_id?: Maybe<Scalars['Float']>;
};

export type Subscription_Stddev_Pop_Order_By = {
  internal_id?: InputMaybe<Order_By>;
};

export type Subscription_Stddev_Samp_Fields = {
  __typename?: 'subscription_stddev_samp_fields';
  internal_id?: Maybe<Scalars['Float']>;
};

export type Subscription_Stddev_Samp_Order_By = {
  internal_id?: InputMaybe<Order_By>;
};

export type Subscription_Sum_Fields = {
  __typename?: 'subscription_sum_fields';
  internal_id?: Maybe<Scalars['Int']>;
};

export type Subscription_Sum_Order_By = {
  internal_id?: InputMaybe<Order_By>;
};

export enum Subscription_Update_Column {
  CreatedAt = 'created_at',
  Email = 'email',
  InternalId = 'internal_id',
  Name = 'name',
  Source = 'source',
  Subscribed = 'subscribed',
  UserId = 'userId',
}

export type Subscription_Var_Pop_Fields = {
  __typename?: 'subscription_var_pop_fields';
  internal_id?: Maybe<Scalars['Float']>;
};

export type Subscription_Var_Pop_Order_By = {
  internal_id?: InputMaybe<Order_By>;
};

export type Subscription_Var_Samp_Fields = {
  __typename?: 'subscription_var_samp_fields';
  internal_id?: Maybe<Scalars['Float']>;
};

export type Subscription_Var_Samp_Order_By = {
  internal_id?: InputMaybe<Order_By>;
};

export type Subscription_Variance_Fields = {
  __typename?: 'subscription_variance_fields';
  internal_id?: Maybe<Scalars['Float']>;
};

export type Subscription_Variance_Order_By = {
  internal_id?: InputMaybe<Order_By>;
};

export type Technique_Resource = {
  __typename?: 'technique_resource';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  image: Scalars['String'];
  kind: Scalars['String'];
  technique: Scalars['String'];
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  url: Scalars['String'];
};

export type Technique_Resource_Aggregate = {
  __typename?: 'technique_resource_aggregate';
  aggregate?: Maybe<Technique_Resource_Aggregate_Fields>;
  nodes: Array<Technique_Resource>;
};

export type Technique_Resource_Aggregate_Fields = {
  __typename?: 'technique_resource_aggregate_fields';
  avg?: Maybe<Technique_Resource_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Technique_Resource_Max_Fields>;
  min?: Maybe<Technique_Resource_Min_Fields>;
  stddev?: Maybe<Technique_Resource_Stddev_Fields>;
  stddev_pop?: Maybe<Technique_Resource_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Technique_Resource_Stddev_Samp_Fields>;
  sum?: Maybe<Technique_Resource_Sum_Fields>;
  var_pop?: Maybe<Technique_Resource_Var_Pop_Fields>;
  var_samp?: Maybe<Technique_Resource_Var_Samp_Fields>;
  variance?: Maybe<Technique_Resource_Variance_Fields>;
};

export type Technique_Resource_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Technique_Resource_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Technique_Resource_Aggregate_Order_By = {
  avg?: InputMaybe<Technique_Resource_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Technique_Resource_Max_Order_By>;
  min?: InputMaybe<Technique_Resource_Min_Order_By>;
  stddev?: InputMaybe<Technique_Resource_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Technique_Resource_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Technique_Resource_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Technique_Resource_Sum_Order_By>;
  var_pop?: InputMaybe<Technique_Resource_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Technique_Resource_Var_Samp_Order_By>;
  variance?: InputMaybe<Technique_Resource_Variance_Order_By>;
};

export type Technique_Resource_Arr_Rel_Insert_Input = {
  data: Array<Technique_Resource_Insert_Input>;
  on_conflict?: InputMaybe<Technique_Resource_On_Conflict>;
};

export type Technique_Resource_Avg_Fields = {
  __typename?: 'technique_resource_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Technique_Resource_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Technique_Resource_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Technique_Resource_Bool_Exp>>>;
  _not?: InputMaybe<Technique_Resource_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Technique_Resource_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  image?: InputMaybe<String_Comparison_Exp>;
  kind?: InputMaybe<String_Comparison_Exp>;
  technique?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

export enum Technique_Resource_Constraint {
  TechniqueResourcePkey = 'technique_resource_pkey',
}

export type Technique_Resource_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

export type Technique_Resource_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<Scalars['String']>;
  technique?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

export type Technique_Resource_Max_Fields = {
  __typename?: 'technique_resource_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  technique?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

export type Technique_Resource_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  technique?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

export type Technique_Resource_Min_Fields = {
  __typename?: 'technique_resource_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  technique?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

export type Technique_Resource_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  technique?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

export type Technique_Resource_Mutation_Response = {
  __typename?: 'technique_resource_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Technique_Resource>;
};

export type Technique_Resource_Obj_Rel_Insert_Input = {
  data: Technique_Resource_Insert_Input;
  on_conflict?: InputMaybe<Technique_Resource_On_Conflict>;
};

export type Technique_Resource_On_Conflict = {
  constraint: Technique_Resource_Constraint;
  update_columns: Array<Technique_Resource_Update_Column>;
  where?: InputMaybe<Technique_Resource_Bool_Exp>;
};

export type Technique_Resource_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  technique?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

export type Technique_Resource_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export enum Technique_Resource_Select_Column {
  CreatedAt = 'created_at',
  Id = 'id',
  Image = 'image',
  Kind = 'kind',
  Technique = 'technique',
  Title = 'title',
  UpdatedAt = 'updated_at',
  Url = 'url',
}

export type Technique_Resource_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<Scalars['String']>;
  technique?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

export type Technique_Resource_Stddev_Fields = {
  __typename?: 'technique_resource_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Technique_Resource_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Technique_Resource_Stddev_Pop_Fields = {
  __typename?: 'technique_resource_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Technique_Resource_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Technique_Resource_Stddev_Samp_Fields = {
  __typename?: 'technique_resource_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Technique_Resource_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Technique_Resource_Sum_Fields = {
  __typename?: 'technique_resource_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

export type Technique_Resource_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

export enum Technique_Resource_Update_Column {
  CreatedAt = 'created_at',
  Id = 'id',
  Image = 'image',
  Kind = 'kind',
  Technique = 'technique',
  Title = 'title',
  UpdatedAt = 'updated_at',
  Url = 'url',
}

export type Technique_Resource_Var_Pop_Fields = {
  __typename?: 'technique_resource_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Technique_Resource_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Technique_Resource_Var_Samp_Fields = {
  __typename?: 'technique_resource_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Technique_Resource_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Technique_Resource_Variance_Fields = {
  __typename?: 'technique_resource_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Technique_Resource_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Time_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['time']>;
  _gt?: InputMaybe<Scalars['time']>;
  _gte?: InputMaybe<Scalars['time']>;
  _in?: InputMaybe<Array<Scalars['time']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['time']>;
  _lte?: InputMaybe<Scalars['time']>;
  _neq?: InputMaybe<Scalars['time']>;
  _nin?: InputMaybe<Array<Scalars['time']>>;
};

export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

export type Timetz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timetz']>;
  _gt?: InputMaybe<Scalars['timetz']>;
  _gte?: InputMaybe<Scalars['timetz']>;
  _in?: InputMaybe<Array<Scalars['timetz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timetz']>;
  _lte?: InputMaybe<Scalars['timetz']>;
  _neq?: InputMaybe<Scalars['timetz']>;
  _nin?: InputMaybe<Array<Scalars['timetz']>>;
};

export type Toolbox = {
  __typename?: 'toolbox';
  autogenic_training?: Maybe<Scalars['Boolean']>;
  behavior_activation?: Maybe<Scalars['Boolean']>;
  challenge_catastrophic_thinking?: Maybe<Scalars['Boolean']>;
  deep_breath?: Maybe<Scalars['Boolean']>;
  gratitude?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  imagery?: Maybe<Scalars['Boolean']>;
  light_therapy?: Maybe<Scalars['Boolean']>;
  meditation?: Maybe<Scalars['Boolean']>;
  paradoxical_intention?: Maybe<Scalars['Boolean']>;
  parking_lot?: Maybe<Scalars['Boolean']>;
  pmr?: Maybe<Scalars['Boolean']>;
  sleep_restriction?: Maybe<Scalars['Boolean']>;
  stimulus_control?: Maybe<Scalars['Boolean']>;
  thought_block?: Maybe<Scalars['Boolean']>;
  user: User;
  user_id: Scalars['String'];
};

export type Toolbox_Aggregate = {
  __typename?: 'toolbox_aggregate';
  aggregate?: Maybe<Toolbox_Aggregate_Fields>;
  nodes: Array<Toolbox>;
};

export type Toolbox_Aggregate_Fields = {
  __typename?: 'toolbox_aggregate_fields';
  avg?: Maybe<Toolbox_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Toolbox_Max_Fields>;
  min?: Maybe<Toolbox_Min_Fields>;
  stddev?: Maybe<Toolbox_Stddev_Fields>;
  stddev_pop?: Maybe<Toolbox_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Toolbox_Stddev_Samp_Fields>;
  sum?: Maybe<Toolbox_Sum_Fields>;
  var_pop?: Maybe<Toolbox_Var_Pop_Fields>;
  var_samp?: Maybe<Toolbox_Var_Samp_Fields>;
  variance?: Maybe<Toolbox_Variance_Fields>;
};

export type Toolbox_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Toolbox_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Toolbox_Aggregate_Order_By = {
  avg?: InputMaybe<Toolbox_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Toolbox_Max_Order_By>;
  min?: InputMaybe<Toolbox_Min_Order_By>;
  stddev?: InputMaybe<Toolbox_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Toolbox_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Toolbox_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Toolbox_Sum_Order_By>;
  var_pop?: InputMaybe<Toolbox_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Toolbox_Var_Samp_Order_By>;
  variance?: InputMaybe<Toolbox_Variance_Order_By>;
};

export type Toolbox_Arr_Rel_Insert_Input = {
  data: Array<Toolbox_Insert_Input>;
  on_conflict?: InputMaybe<Toolbox_On_Conflict>;
};

export type Toolbox_Avg_Fields = {
  __typename?: 'toolbox_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Toolbox_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Toolbox_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Toolbox_Bool_Exp>>>;
  _not?: InputMaybe<Toolbox_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Toolbox_Bool_Exp>>>;
  autogenic_training?: InputMaybe<Boolean_Comparison_Exp>;
  behavior_activation?: InputMaybe<Boolean_Comparison_Exp>;
  challenge_catastrophic_thinking?: InputMaybe<Boolean_Comparison_Exp>;
  deep_breath?: InputMaybe<Boolean_Comparison_Exp>;
  gratitude?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  imagery?: InputMaybe<Boolean_Comparison_Exp>;
  light_therapy?: InputMaybe<Boolean_Comparison_Exp>;
  meditation?: InputMaybe<Boolean_Comparison_Exp>;
  paradoxical_intention?: InputMaybe<Boolean_Comparison_Exp>;
  parking_lot?: InputMaybe<Boolean_Comparison_Exp>;
  pmr?: InputMaybe<Boolean_Comparison_Exp>;
  sleep_restriction?: InputMaybe<Boolean_Comparison_Exp>;
  stimulus_control?: InputMaybe<Boolean_Comparison_Exp>;
  thought_block?: InputMaybe<Boolean_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

export enum Toolbox_Constraint {
  ToolboxPkey = 'toolbox_pkey',
  ToolboxUserIdKey = 'toolbox_user_id_key',
}

export type Toolbox_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

export type Toolbox_Insert_Input = {
  autogenic_training?: InputMaybe<Scalars['Boolean']>;
  behavior_activation?: InputMaybe<Scalars['Boolean']>;
  challenge_catastrophic_thinking?: InputMaybe<Scalars['Boolean']>;
  deep_breath?: InputMaybe<Scalars['Boolean']>;
  gratitude?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  imagery?: InputMaybe<Scalars['Boolean']>;
  light_therapy?: InputMaybe<Scalars['Boolean']>;
  meditation?: InputMaybe<Scalars['Boolean']>;
  paradoxical_intention?: InputMaybe<Scalars['Boolean']>;
  parking_lot?: InputMaybe<Scalars['Boolean']>;
  pmr?: InputMaybe<Scalars['Boolean']>;
  sleep_restriction?: InputMaybe<Scalars['Boolean']>;
  stimulus_control?: InputMaybe<Scalars['Boolean']>;
  thought_block?: InputMaybe<Scalars['Boolean']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Toolbox_Max_Fields = {
  __typename?: 'toolbox_max_fields';
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Toolbox_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Toolbox_Min_Fields = {
  __typename?: 'toolbox_min_fields';
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Toolbox_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Toolbox_Mutation_Response = {
  __typename?: 'toolbox_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Toolbox>;
};

export type Toolbox_Obj_Rel_Insert_Input = {
  data: Toolbox_Insert_Input;
  on_conflict?: InputMaybe<Toolbox_On_Conflict>;
};

export type Toolbox_On_Conflict = {
  constraint: Toolbox_Constraint;
  update_columns: Array<Toolbox_Update_Column>;
  where?: InputMaybe<Toolbox_Bool_Exp>;
};

export type Toolbox_Order_By = {
  autogenic_training?: InputMaybe<Order_By>;
  behavior_activation?: InputMaybe<Order_By>;
  challenge_catastrophic_thinking?: InputMaybe<Order_By>;
  deep_breath?: InputMaybe<Order_By>;
  gratitude?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imagery?: InputMaybe<Order_By>;
  light_therapy?: InputMaybe<Order_By>;
  meditation?: InputMaybe<Order_By>;
  paradoxical_intention?: InputMaybe<Order_By>;
  parking_lot?: InputMaybe<Order_By>;
  pmr?: InputMaybe<Order_By>;
  sleep_restriction?: InputMaybe<Order_By>;
  stimulus_control?: InputMaybe<Order_By>;
  thought_block?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Toolbox_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export enum Toolbox_Select_Column {
  AutogenicTraining = 'autogenic_training',
  BehaviorActivation = 'behavior_activation',
  ChallengeCatastrophicThinking = 'challenge_catastrophic_thinking',
  DeepBreath = 'deep_breath',
  Gratitude = 'gratitude',
  Id = 'id',
  Imagery = 'imagery',
  LightTherapy = 'light_therapy',
  Meditation = 'meditation',
  ParadoxicalIntention = 'paradoxical_intention',
  ParkingLot = 'parking_lot',
  Pmr = 'pmr',
  SleepRestriction = 'sleep_restriction',
  StimulusControl = 'stimulus_control',
  ThoughtBlock = 'thought_block',
  UserId = 'user_id',
}

export type Toolbox_Set_Input = {
  autogenic_training?: InputMaybe<Scalars['Boolean']>;
  behavior_activation?: InputMaybe<Scalars['Boolean']>;
  challenge_catastrophic_thinking?: InputMaybe<Scalars['Boolean']>;
  deep_breath?: InputMaybe<Scalars['Boolean']>;
  gratitude?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  imagery?: InputMaybe<Scalars['Boolean']>;
  light_therapy?: InputMaybe<Scalars['Boolean']>;
  meditation?: InputMaybe<Scalars['Boolean']>;
  paradoxical_intention?: InputMaybe<Scalars['Boolean']>;
  parking_lot?: InputMaybe<Scalars['Boolean']>;
  pmr?: InputMaybe<Scalars['Boolean']>;
  sleep_restriction?: InputMaybe<Scalars['Boolean']>;
  stimulus_control?: InputMaybe<Scalars['Boolean']>;
  thought_block?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type Toolbox_Stddev_Fields = {
  __typename?: 'toolbox_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Toolbox_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Toolbox_Stddev_Pop_Fields = {
  __typename?: 'toolbox_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Toolbox_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Toolbox_Stddev_Samp_Fields = {
  __typename?: 'toolbox_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Toolbox_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Toolbox_Sum_Fields = {
  __typename?: 'toolbox_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

export type Toolbox_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

export enum Toolbox_Update_Column {
  AutogenicTraining = 'autogenic_training',
  BehaviorActivation = 'behavior_activation',
  ChallengeCatastrophicThinking = 'challenge_catastrophic_thinking',
  DeepBreath = 'deep_breath',
  Gratitude = 'gratitude',
  Id = 'id',
  Imagery = 'imagery',
  LightTherapy = 'light_therapy',
  Meditation = 'meditation',
  ParadoxicalIntention = 'paradoxical_intention',
  ParkingLot = 'parking_lot',
  Pmr = 'pmr',
  SleepRestriction = 'sleep_restriction',
  StimulusControl = 'stimulus_control',
  ThoughtBlock = 'thought_block',
  UserId = 'user_id',
}

export type Toolbox_Var_Pop_Fields = {
  __typename?: 'toolbox_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Toolbox_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Toolbox_Var_Samp_Fields = {
  __typename?: 'toolbox_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Toolbox_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Toolbox_Variance_Fields = {
  __typename?: 'toolbox_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Toolbox_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type User = {
  __typename?: 'user';
  alcohol: Scalars['Boolean'];
  alt_email?: Maybe<Scalars['String']>;
  alt_id?: Maybe<Scalars['String']>;
  apnea_questionnaires: Array<Apnea_Questionnaire>;
  apnea_questionnaires_aggregate: Apnea_Questionnaire_Aggregate;
  app_version?: Maybe<Scalars['String']>;
  appointments: Array<Appointment>;
  appointments_aggregate: Appointment_Aggregate;
  autogenic_training?: Maybe<Scalars['Boolean']>;
  bathroom: Scalars['Boolean'];
  behavior_activation?: Maybe<Scalars['Boolean']>;
  birthdate?: Maybe<Scalars['date']>;
  caffeine: Scalars['Boolean'];
  can_send_email_reminder?: Maybe<Scalars['Boolean']>;
  can_send_whatsapp_reminder?: Maybe<Scalars['Boolean']>;
  catastrophic_thinkings: Array<Catastrophic_Thinkings>;
  catastrophic_thinkings_aggregate: Catastrophic_Thinkings_Aggregate;
  challenge_catastrophic_thinking?: Maybe<Scalars['Boolean']>;
  checkout: Array<User_Checkout>;
  checkout_aggregate: User_Checkout_Aggregate;
  comment?: Maybe<Scalars['String']>;
  companies: Array<Company_User>;
  companies_aggregate: Company_User_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  current_journey_id?: Maybe<Scalars['Int']>;
  day_goal?: Maybe<Scalars['smallint']>;
  deep_breath?: Maybe<Scalars['Boolean']>;
  desired_wake_up?: Maybe<Scalars['timetz']>;
  device_os?: Maybe<Os_Enum_Enum>;
  document?: Maybe<Scalars['String']>;
  dream: Scalars['Boolean'];
  dreams: Array<Dream>;
  dreams_aggregate: Dream_Aggregate;
  eficiency: Array<Eficiency>;
  eficiency_aggregate: Eficiency_Aggregate;
  email?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  epworths: Array<Epworth>;
  epworths_aggregate: Epworth_Aggregate;
  exercise: Scalars['Boolean'];
  first_name?: Maybe<Scalars['String']>;
  gad: Array<Gad>;
  gad7s: Array<Gad7>;
  gad7s_aggregate: Gad7_Aggregate;
  gad_aggregate: Gad_Aggregate;
  gender?: Maybe<Scalars['String']>;
  generic_questionnaires: Array<Generic_Questionnaire>;
  generic_questionnaires_aggregate: Generic_Questionnaire_Aggregate;
  google_sheet_rows: Array<Google_Sheet_Row>;
  google_sheet_rows_aggregate: Google_Sheet_Row_Aggregate;
  gratitude?: Maybe<Scalars['Boolean']>;
  gratitudes: Array<Gratitude>;
  gratitudes_aggregate: Gratitude_Aggregate;
  health_carers: Array<Health_Carer_User>;
  health_carers_aggregate: Health_Carer_User_Aggregate;
  heavy_meal: Scalars['Boolean'];
  id: Scalars['String'];
  imagery?: Maybe<Scalars['Boolean']>;
  internal_id: Scalars['Int'];
  isi: Array<Isi>;
  isi_aggregate: Isi_Aggregate;
  key_values: Array<User_Key_Value>;
  key_values_aggregate: User_Key_Value_Aggregate;
  kuppermans: Array<Kupperman>;
  kuppermans_aggregate: Kupperman_Aggregate;
  last_name?: Maybe<Scalars['String']>;
  light: Scalars['Boolean'];
  light_therapy?: Maybe<Scalars['Boolean']>;
  logs: Array<Log>;
  logs_aggregate: Log_Aggregate;
  meditation?: Maybe<Scalars['Boolean']>;
  menopause_greene_scales: Array<Menopause_Greene_Scale>;
  menopause_greene_scales_aggregate: Menopause_Greene_Scale_Aggregate;
  munich_chronotype_cores: Array<Munich_Chronotype_Core>;
  munich_chronotype_cores_aggregate: Munich_Chronotype_Core_Aggregate;
  munich_chronotypes: Array<Munich_Chronotype>;
  munich_chronotypes_aggregate: Munich_Chronotype_Aggregate;
  nap: Scalars['Boolean'];
  nicotine: Scalars['Boolean'];
  night_goal?: Maybe<Scalars['smallint']>;
  noise: Scalars['Boolean'];
  paid?: Maybe<Scalars['Boolean']>;
  paid_value?: Maybe<Scalars['numeric']>;
  pain: Scalars['Boolean'];
  paradoxical_intention?: Maybe<Scalars['Boolean']>;
  parking_lot?: Maybe<Scalars['Boolean']>;
  parking_lots: Array<Parking_Lot>;
  parking_lots_aggregate: Parking_Lot_Aggregate;
  partner: Scalars['Boolean'];
  personality_questionnaires: Array<Personality_Questionnaire>;
  personality_questionnaires_aggregate: Personality_Questionnaire_Aggregate;
  phone?: Maybe<Scalars['String']>;
  phq: Array<Phq>;
  phq9s: Array<Phq9>;
  phq9s_aggregate: Phq9_Aggregate;
  phq_aggregate: Phq_Aggregate;
  pmr?: Maybe<Scalars['Boolean']>;
  points?: Maybe<Scalars['Int']>;
  pre_users: Array<Pre_User>;
  pre_users_aggregate: Pre_User_Aggregate;
  productivity: Array<Productivity>;
  productivity_aggregate: Productivity_Aggregate;
  profile_picture?: Maybe<Scalars['String']>;
  psqi: Array<Psqi>;
  psqi_aggregate: Psqi_Aggregate;
  psqi_state: Scalars['smallint'];
  ratings: Array<Rating>;
  ratings_aggregate: Rating_Aggregate;
  reminder_info?: Maybe<User_Reminder_Info>;
  session_1_state: Scalars['smallint'];
  session_2_state: Scalars['smallint'];
  session_3_state: Scalars['smallint'];
  session_4_state: Scalars['smallint'];
  session_5_state: Scalars['smallint'];
  session_6_state: Scalars['smallint'];
  session_7_state: Scalars['smallint'];
  session_8_state: Scalars['smallint'];
  session_9_state: Scalars['smallint'];
  session_10_state: Scalars['smallint'];
  session_11_state: Scalars['smallint'];
  session_12_state: Scalars['smallint'];
  session_13_state: Scalars['smallint'];
  session_14_state: Scalars['smallint'];
  session_15_state: Scalars['smallint'];
  session_16_state: Scalars['smallint'];
  session_17_state: Scalars['smallint'];
  session_18_state: Scalars['smallint'];
  session_19_state: Scalars['smallint'];
  session_20_state: Scalars['smallint'];
  session_21_state: Scalars['smallint'];
  session_22_state: Scalars['smallint'];
  session_23_state: Scalars['smallint'];
  session_24_state: Scalars['smallint'];
  session_25_state: Scalars['smallint'];
  session_26_state: Scalars['smallint'];
  session_27_state: Scalars['smallint'];
  session_28_state: Scalars['smallint'];
  session_29_state: Scalars['smallint'];
  session_30_state: Scalars['smallint'];
  session_31_state: Scalars['smallint'];
  session_32_state: Scalars['smallint'];
  session_33_state: Scalars['smallint'];
  session_34_state: Scalars['smallint'];
  session_35_state: Scalars['smallint'];
  session_36_state: Scalars['smallint'];
  session_37_state: Scalars['smallint'];
  session_38_state: Scalars['smallint'];
  session_39_state: Scalars['smallint'];
  session_40_state: Scalars['smallint'];
  session_41_state: Scalars['smallint'];
  session_42_state: Scalars['smallint'];
  session_43_state: Scalars['smallint'];
  session_44_state: Scalars['smallint'];
  session_45_state: Scalars['smallint'];
  session_46_state: Scalars['smallint'];
  session_47_state: Scalars['smallint'];
  session_48_state: Scalars['smallint'];
  session_49_state: Scalars['smallint'];
  session_50_state: Scalars['smallint'];
  session_51_state: Scalars['smallint'];
  session_52_state: Scalars['smallint'];
  session_53_state: Scalars['smallint'];
  session_54_state: Scalars['smallint'];
  session_55_state: Scalars['smallint'];
  session_56_state: Scalars['smallint'];
  session_57_state: Scalars['smallint'];
  session_58_state: Scalars['smallint'];
  session_59_state: Scalars['smallint'];
  session_60_state: Scalars['smallint'];
  session_to_pay: Scalars['Int'];
  sleep_diaries: Array<Sleep_Diary>;
  sleep_diaries_aggregate: Sleep_Diary_Aggregate;
  sleep_diary_apple_healths: Array<Sleep_Diary_Apple_Health>;
  sleep_diary_apple_healths_aggregate: Sleep_Diary_Apple_Health_Aggregate;
  sleep_diary_google_fits: Array<Sleep_Diary_Google_Fit>;
  sleep_diary_google_fits_aggregate: Sleep_Diary_Google_Fit_Aggregate;
  sleep_diary_intervals: Array<Sleep_Diary_Interval>;
  sleep_diary_intervals_aggregate: Sleep_Diary_Interval_Aggregate;
  sleep_medicine: Scalars['Boolean'];
  sleep_restriction?: Maybe<Scalars['Boolean']>;
  sleep_restrictions: Array<Sleep_Restriction>;
  sleep_restrictions_aggregate: Sleep_Restriction_Aggregate;
  sleep_tracker_compressed_data: Array<Sleep_Tracker_Compressed_Data>;
  sleep_tracker_compressed_data_aggregate: Sleep_Tracker_Compressed_Data_Aggregate;
  stimulus_control?: Maybe<Scalars['Boolean']>;
  stop_bangs: Array<Stop_Bang>;
  stop_bangs_aggregate: Stop_Bang_Aggregate;
  subscription_expiration?: Maybe<Scalars['date']>;
  temperature: Scalars['Boolean'];
  thought_block?: Maybe<Scalars['Boolean']>;
  toolbox?: Maybe<Toolbox>;
  tried_alcohol?: Maybe<Scalars['Boolean']>;
  tried_medicine?: Maybe<Scalars['Boolean']>;
  tried_melatonin?: Maybe<Scalars['Boolean']>;
  tried_other?: Maybe<Scalars['String']>;
  tried_restriction?: Maybe<Scalars['Boolean']>;
  tried_sleep_hygiene?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_coupons: Array<User_Coupon>;
  user_coupons_aggregate: User_Coupon_Aggregate;
  user_journeys: Array<User_Journey>;
  user_journeys_aggregate: User_Journey_Aggregate;
  user_notifications: Array<User_Notification>;
  user_notifications_aggregate: User_Notification_Aggregate;
  user_program_sessions: Array<User_Program_Session>;
  user_program_sessions_aggregate: User_Program_Session_Aggregate;
  user_tags: Array<User_Tag>;
  user_tags_aggregate: User_Tag_Aggregate;
  user_telehealth?: Maybe<User_Telehealth>;
};

export type UserApnea_QuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Apnea_Questionnaire_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Apnea_Questionnaire_Order_By>>;
  where?: InputMaybe<Apnea_Questionnaire_Bool_Exp>;
};

export type UserApnea_Questionnaires_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apnea_Questionnaire_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Apnea_Questionnaire_Order_By>>;
  where?: InputMaybe<Apnea_Questionnaire_Bool_Exp>;
};

export type UserAppointmentsArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Order_By>>;
  where?: InputMaybe<Appointment_Bool_Exp>;
};

export type UserAppointments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Order_By>>;
  where?: InputMaybe<Appointment_Bool_Exp>;
};

export type UserCatastrophic_ThinkingsArgs = {
  distinct_on?: InputMaybe<Array<Catastrophic_Thinkings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Catastrophic_Thinkings_Order_By>>;
  where?: InputMaybe<Catastrophic_Thinkings_Bool_Exp>;
};

export type UserCatastrophic_Thinkings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Catastrophic_Thinkings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Catastrophic_Thinkings_Order_By>>;
  where?: InputMaybe<Catastrophic_Thinkings_Bool_Exp>;
};

export type UserCheckoutArgs = {
  distinct_on?: InputMaybe<Array<User_Checkout_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Checkout_Order_By>>;
  where?: InputMaybe<User_Checkout_Bool_Exp>;
};

export type UserCheckout_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Checkout_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Checkout_Order_By>>;
  where?: InputMaybe<User_Checkout_Bool_Exp>;
};

export type UserCompaniesArgs = {
  distinct_on?: InputMaybe<Array<Company_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_User_Order_By>>;
  where?: InputMaybe<Company_User_Bool_Exp>;
};

export type UserCompanies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_User_Order_By>>;
  where?: InputMaybe<Company_User_Bool_Exp>;
};

export type UserDreamsArgs = {
  distinct_on?: InputMaybe<Array<Dream_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dream_Order_By>>;
  where?: InputMaybe<Dream_Bool_Exp>;
};

export type UserDreams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dream_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dream_Order_By>>;
  where?: InputMaybe<Dream_Bool_Exp>;
};

export type UserEficiencyArgs = {
  distinct_on?: InputMaybe<Array<Eficiency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eficiency_Order_By>>;
  where?: InputMaybe<Eficiency_Bool_Exp>;
};

export type UserEficiency_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eficiency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eficiency_Order_By>>;
  where?: InputMaybe<Eficiency_Bool_Exp>;
};

export type UserEpworthsArgs = {
  distinct_on?: InputMaybe<Array<Epworth_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Epworth_Order_By>>;
  where?: InputMaybe<Epworth_Bool_Exp>;
};

export type UserEpworths_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Epworth_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Epworth_Order_By>>;
  where?: InputMaybe<Epworth_Bool_Exp>;
};

export type UserGadArgs = {
  distinct_on?: InputMaybe<Array<Gad_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gad_Order_By>>;
  where?: InputMaybe<Gad_Bool_Exp>;
};

export type UserGad7sArgs = {
  distinct_on?: InputMaybe<Array<Gad7_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gad7_Order_By>>;
  where?: InputMaybe<Gad7_Bool_Exp>;
};

export type UserGad7s_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gad7_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gad7_Order_By>>;
  where?: InputMaybe<Gad7_Bool_Exp>;
};

export type UserGad_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gad_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gad_Order_By>>;
  where?: InputMaybe<Gad_Bool_Exp>;
};

export type UserGeneric_QuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Generic_Questionnaire_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Generic_Questionnaire_Order_By>>;
  where?: InputMaybe<Generic_Questionnaire_Bool_Exp>;
};

export type UserGeneric_Questionnaires_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Generic_Questionnaire_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Generic_Questionnaire_Order_By>>;
  where?: InputMaybe<Generic_Questionnaire_Bool_Exp>;
};

export type UserGoogle_Sheet_RowsArgs = {
  distinct_on?: InputMaybe<Array<Google_Sheet_Row_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Google_Sheet_Row_Order_By>>;
  where?: InputMaybe<Google_Sheet_Row_Bool_Exp>;
};

export type UserGoogle_Sheet_Rows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Google_Sheet_Row_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Google_Sheet_Row_Order_By>>;
  where?: InputMaybe<Google_Sheet_Row_Bool_Exp>;
};

export type UserGratitudesArgs = {
  distinct_on?: InputMaybe<Array<Gratitude_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gratitude_Order_By>>;
  where?: InputMaybe<Gratitude_Bool_Exp>;
};

export type UserGratitudes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gratitude_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gratitude_Order_By>>;
  where?: InputMaybe<Gratitude_Bool_Exp>;
};

export type UserHealth_CarersArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_User_Order_By>>;
  where?: InputMaybe<Health_Carer_User_Bool_Exp>;
};

export type UserHealth_Carers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Health_Carer_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Health_Carer_User_Order_By>>;
  where?: InputMaybe<Health_Carer_User_Bool_Exp>;
};

export type UserIsiArgs = {
  distinct_on?: InputMaybe<Array<Isi_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Isi_Order_By>>;
  where?: InputMaybe<Isi_Bool_Exp>;
};

export type UserIsi_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Isi_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Isi_Order_By>>;
  where?: InputMaybe<Isi_Bool_Exp>;
};

export type UserKey_ValuesArgs = {
  distinct_on?: InputMaybe<Array<User_Key_Value_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Key_Value_Order_By>>;
  where?: InputMaybe<User_Key_Value_Bool_Exp>;
};

export type UserKey_Values_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Key_Value_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Key_Value_Order_By>>;
  where?: InputMaybe<User_Key_Value_Bool_Exp>;
};

export type UserKuppermansArgs = {
  distinct_on?: InputMaybe<Array<Kupperman_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kupperman_Order_By>>;
  where?: InputMaybe<Kupperman_Bool_Exp>;
};

export type UserKuppermans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kupperman_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kupperman_Order_By>>;
  where?: InputMaybe<Kupperman_Bool_Exp>;
};

export type UserLogsArgs = {
  distinct_on?: InputMaybe<Array<Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Log_Order_By>>;
  where?: InputMaybe<Log_Bool_Exp>;
};

export type UserLogs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Log_Order_By>>;
  where?: InputMaybe<Log_Bool_Exp>;
};

export type UserMenopause_Greene_ScalesArgs = {
  distinct_on?: InputMaybe<Array<Menopause_Greene_Scale_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menopause_Greene_Scale_Order_By>>;
  where?: InputMaybe<Menopause_Greene_Scale_Bool_Exp>;
};

export type UserMenopause_Greene_Scales_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Menopause_Greene_Scale_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menopause_Greene_Scale_Order_By>>;
  where?: InputMaybe<Menopause_Greene_Scale_Bool_Exp>;
};

export type UserMunich_Chronotype_CoresArgs = {
  distinct_on?: InputMaybe<Array<Munich_Chronotype_Core_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Munich_Chronotype_Core_Order_By>>;
  where?: InputMaybe<Munich_Chronotype_Core_Bool_Exp>;
};

export type UserMunich_Chronotype_Cores_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Munich_Chronotype_Core_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Munich_Chronotype_Core_Order_By>>;
  where?: InputMaybe<Munich_Chronotype_Core_Bool_Exp>;
};

export type UserMunich_ChronotypesArgs = {
  distinct_on?: InputMaybe<Array<Munich_Chronotype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Munich_Chronotype_Order_By>>;
  where?: InputMaybe<Munich_Chronotype_Bool_Exp>;
};

export type UserMunich_Chronotypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Munich_Chronotype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Munich_Chronotype_Order_By>>;
  where?: InputMaybe<Munich_Chronotype_Bool_Exp>;
};

export type UserParking_LotsArgs = {
  distinct_on?: InputMaybe<Array<Parking_Lot_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parking_Lot_Order_By>>;
  where?: InputMaybe<Parking_Lot_Bool_Exp>;
};

export type UserParking_Lots_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parking_Lot_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parking_Lot_Order_By>>;
  where?: InputMaybe<Parking_Lot_Bool_Exp>;
};

export type UserPersonality_QuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Personality_Questionnaire_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Personality_Questionnaire_Order_By>>;
  where?: InputMaybe<Personality_Questionnaire_Bool_Exp>;
};

export type UserPersonality_Questionnaires_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Personality_Questionnaire_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Personality_Questionnaire_Order_By>>;
  where?: InputMaybe<Personality_Questionnaire_Bool_Exp>;
};

export type UserPhqArgs = {
  distinct_on?: InputMaybe<Array<Phq_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Phq_Order_By>>;
  where?: InputMaybe<Phq_Bool_Exp>;
};

export type UserPhq9sArgs = {
  distinct_on?: InputMaybe<Array<Phq9_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Phq9_Order_By>>;
  where?: InputMaybe<Phq9_Bool_Exp>;
};

export type UserPhq9s_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Phq9_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Phq9_Order_By>>;
  where?: InputMaybe<Phq9_Bool_Exp>;
};

export type UserPhq_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Phq_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Phq_Order_By>>;
  where?: InputMaybe<Phq_Bool_Exp>;
};

export type UserPre_UsersArgs = {
  distinct_on?: InputMaybe<Array<Pre_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pre_User_Order_By>>;
  where?: InputMaybe<Pre_User_Bool_Exp>;
};

export type UserPre_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pre_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pre_User_Order_By>>;
  where?: InputMaybe<Pre_User_Bool_Exp>;
};

export type UserProductivityArgs = {
  distinct_on?: InputMaybe<Array<Productivity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Productivity_Order_By>>;
  where?: InputMaybe<Productivity_Bool_Exp>;
};

export type UserProductivity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Productivity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Productivity_Order_By>>;
  where?: InputMaybe<Productivity_Bool_Exp>;
};

export type UserPsqiArgs = {
  distinct_on?: InputMaybe<Array<Psqi_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Psqi_Order_By>>;
  where?: InputMaybe<Psqi_Bool_Exp>;
};

export type UserPsqi_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Psqi_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Psqi_Order_By>>;
  where?: InputMaybe<Psqi_Bool_Exp>;
};

export type UserRatingsArgs = {
  distinct_on?: InputMaybe<Array<Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rating_Order_By>>;
  where?: InputMaybe<Rating_Bool_Exp>;
};

export type UserRatings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rating_Order_By>>;
  where?: InputMaybe<Rating_Bool_Exp>;
};

export type UserSleep_DiariesArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Diary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Diary_Order_By>>;
  where?: InputMaybe<Sleep_Diary_Bool_Exp>;
};

export type UserSleep_Diaries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Diary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Diary_Order_By>>;
  where?: InputMaybe<Sleep_Diary_Bool_Exp>;
};

export type UserSleep_Diary_Apple_HealthsArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Diary_Apple_Health_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Diary_Apple_Health_Order_By>>;
  where?: InputMaybe<Sleep_Diary_Apple_Health_Bool_Exp>;
};

export type UserSleep_Diary_Apple_Healths_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Diary_Apple_Health_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Diary_Apple_Health_Order_By>>;
  where?: InputMaybe<Sleep_Diary_Apple_Health_Bool_Exp>;
};

export type UserSleep_Diary_Google_FitsArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Diary_Google_Fit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Diary_Google_Fit_Order_By>>;
  where?: InputMaybe<Sleep_Diary_Google_Fit_Bool_Exp>;
};

export type UserSleep_Diary_Google_Fits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Diary_Google_Fit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Diary_Google_Fit_Order_By>>;
  where?: InputMaybe<Sleep_Diary_Google_Fit_Bool_Exp>;
};

export type UserSleep_Diary_IntervalsArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Diary_Interval_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Diary_Interval_Order_By>>;
  where?: InputMaybe<Sleep_Diary_Interval_Bool_Exp>;
};

export type UserSleep_Diary_Intervals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Diary_Interval_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Diary_Interval_Order_By>>;
  where?: InputMaybe<Sleep_Diary_Interval_Bool_Exp>;
};

export type UserSleep_RestrictionsArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Restriction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Restriction_Order_By>>;
  where?: InputMaybe<Sleep_Restriction_Bool_Exp>;
};

export type UserSleep_Restrictions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Restriction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Restriction_Order_By>>;
  where?: InputMaybe<Sleep_Restriction_Bool_Exp>;
};

export type UserSleep_Tracker_Compressed_DataArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Tracker_Compressed_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Tracker_Compressed_Data_Order_By>>;
  where?: InputMaybe<Sleep_Tracker_Compressed_Data_Bool_Exp>;
};

export type UserSleep_Tracker_Compressed_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sleep_Tracker_Compressed_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sleep_Tracker_Compressed_Data_Order_By>>;
  where?: InputMaybe<Sleep_Tracker_Compressed_Data_Bool_Exp>;
};

export type UserStop_BangsArgs = {
  distinct_on?: InputMaybe<Array<Stop_Bang_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stop_Bang_Order_By>>;
  where?: InputMaybe<Stop_Bang_Bool_Exp>;
};

export type UserStop_Bangs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stop_Bang_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stop_Bang_Order_By>>;
  where?: InputMaybe<Stop_Bang_Bool_Exp>;
};

export type UserUser_CouponsArgs = {
  distinct_on?: InputMaybe<Array<User_Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Coupon_Order_By>>;
  where?: InputMaybe<User_Coupon_Bool_Exp>;
};

export type UserUser_Coupons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Coupon_Order_By>>;
  where?: InputMaybe<User_Coupon_Bool_Exp>;
};

export type UserUser_JourneysArgs = {
  distinct_on?: InputMaybe<Array<User_Journey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Journey_Order_By>>;
  where?: InputMaybe<User_Journey_Bool_Exp>;
};

export type UserUser_Journeys_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Journey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Journey_Order_By>>;
  where?: InputMaybe<User_Journey_Bool_Exp>;
};

export type UserUser_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<User_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Notification_Order_By>>;
  where?: InputMaybe<User_Notification_Bool_Exp>;
};

export type UserUser_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Notification_Order_By>>;
  where?: InputMaybe<User_Notification_Bool_Exp>;
};

export type UserUser_Program_SessionsArgs = {
  distinct_on?: InputMaybe<Array<User_Program_Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Program_Session_Order_By>>;
  where?: InputMaybe<User_Program_Session_Bool_Exp>;
};

export type UserUser_Program_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Program_Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Program_Session_Order_By>>;
  where?: InputMaybe<User_Program_Session_Bool_Exp>;
};

export type UserUser_TagsArgs = {
  distinct_on?: InputMaybe<Array<User_Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Tag_Order_By>>;
  where?: InputMaybe<User_Tag_Bool_Exp>;
};

export type UserUser_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Tag_Order_By>>;
  where?: InputMaybe<User_Tag_Bool_Exp>;
};

export type User_Aggregate = {
  __typename?: 'user_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields';
  avg?: Maybe<User_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
  stddev?: Maybe<User_Stddev_Fields>;
  stddev_pop?: Maybe<User_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Stddev_Samp_Fields>;
  sum?: Maybe<User_Sum_Fields>;
  var_pop?: Maybe<User_Var_Pop_Fields>;
  var_samp?: Maybe<User_Var_Samp_Fields>;
  variance?: Maybe<User_Variance_Fields>;
};

export type User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type User_Aggregate_Order_By = {
  avg?: InputMaybe<User_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Max_Order_By>;
  min?: InputMaybe<User_Min_Order_By>;
  stddev?: InputMaybe<User_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Sum_Order_By>;
  var_pop?: InputMaybe<User_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Variance_Order_By>;
};

export type User_Arr_Rel_Insert_Input = {
  data: Array<User_Insert_Input>;
  on_conflict?: InputMaybe<User_On_Conflict>;
};

export type User_Avg_Fields = {
  __typename?: 'user_avg_fields';
  current_journey_id?: Maybe<Scalars['Float']>;
  day_goal?: Maybe<Scalars['Float']>;
  internal_id?: Maybe<Scalars['Float']>;
  night_goal?: Maybe<Scalars['Float']>;
  paid_value?: Maybe<Scalars['Float']>;
  points?: Maybe<Scalars['Float']>;
  psqi_state?: Maybe<Scalars['Float']>;
  session_1_state?: Maybe<Scalars['Float']>;
  session_2_state?: Maybe<Scalars['Float']>;
  session_3_state?: Maybe<Scalars['Float']>;
  session_4_state?: Maybe<Scalars['Float']>;
  session_5_state?: Maybe<Scalars['Float']>;
  session_6_state?: Maybe<Scalars['Float']>;
  session_7_state?: Maybe<Scalars['Float']>;
  session_8_state?: Maybe<Scalars['Float']>;
  session_9_state?: Maybe<Scalars['Float']>;
  session_10_state?: Maybe<Scalars['Float']>;
  session_11_state?: Maybe<Scalars['Float']>;
  session_12_state?: Maybe<Scalars['Float']>;
  session_13_state?: Maybe<Scalars['Float']>;
  session_14_state?: Maybe<Scalars['Float']>;
  session_15_state?: Maybe<Scalars['Float']>;
  session_16_state?: Maybe<Scalars['Float']>;
  session_17_state?: Maybe<Scalars['Float']>;
  session_18_state?: Maybe<Scalars['Float']>;
  session_19_state?: Maybe<Scalars['Float']>;
  session_20_state?: Maybe<Scalars['Float']>;
  session_21_state?: Maybe<Scalars['Float']>;
  session_22_state?: Maybe<Scalars['Float']>;
  session_23_state?: Maybe<Scalars['Float']>;
  session_24_state?: Maybe<Scalars['Float']>;
  session_25_state?: Maybe<Scalars['Float']>;
  session_26_state?: Maybe<Scalars['Float']>;
  session_27_state?: Maybe<Scalars['Float']>;
  session_28_state?: Maybe<Scalars['Float']>;
  session_29_state?: Maybe<Scalars['Float']>;
  session_30_state?: Maybe<Scalars['Float']>;
  session_31_state?: Maybe<Scalars['Float']>;
  session_32_state?: Maybe<Scalars['Float']>;
  session_33_state?: Maybe<Scalars['Float']>;
  session_34_state?: Maybe<Scalars['Float']>;
  session_35_state?: Maybe<Scalars['Float']>;
  session_36_state?: Maybe<Scalars['Float']>;
  session_37_state?: Maybe<Scalars['Float']>;
  session_38_state?: Maybe<Scalars['Float']>;
  session_39_state?: Maybe<Scalars['Float']>;
  session_40_state?: Maybe<Scalars['Float']>;
  session_41_state?: Maybe<Scalars['Float']>;
  session_42_state?: Maybe<Scalars['Float']>;
  session_43_state?: Maybe<Scalars['Float']>;
  session_44_state?: Maybe<Scalars['Float']>;
  session_45_state?: Maybe<Scalars['Float']>;
  session_46_state?: Maybe<Scalars['Float']>;
  session_47_state?: Maybe<Scalars['Float']>;
  session_48_state?: Maybe<Scalars['Float']>;
  session_49_state?: Maybe<Scalars['Float']>;
  session_50_state?: Maybe<Scalars['Float']>;
  session_51_state?: Maybe<Scalars['Float']>;
  session_52_state?: Maybe<Scalars['Float']>;
  session_53_state?: Maybe<Scalars['Float']>;
  session_54_state?: Maybe<Scalars['Float']>;
  session_55_state?: Maybe<Scalars['Float']>;
  session_56_state?: Maybe<Scalars['Float']>;
  session_57_state?: Maybe<Scalars['Float']>;
  session_58_state?: Maybe<Scalars['Float']>;
  session_59_state?: Maybe<Scalars['Float']>;
  session_60_state?: Maybe<Scalars['Float']>;
  session_to_pay?: Maybe<Scalars['Float']>;
};

export type User_Avg_Order_By = {
  current_journey_id?: InputMaybe<Order_By>;
  day_goal?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  night_goal?: InputMaybe<Order_By>;
  paid_value?: InputMaybe<Order_By>;
  points?: InputMaybe<Order_By>;
  psqi_state?: InputMaybe<Order_By>;
  session_1_state?: InputMaybe<Order_By>;
  session_2_state?: InputMaybe<Order_By>;
  session_3_state?: InputMaybe<Order_By>;
  session_4_state?: InputMaybe<Order_By>;
  session_5_state?: InputMaybe<Order_By>;
  session_6_state?: InputMaybe<Order_By>;
  session_7_state?: InputMaybe<Order_By>;
  session_8_state?: InputMaybe<Order_By>;
  session_9_state?: InputMaybe<Order_By>;
  session_10_state?: InputMaybe<Order_By>;
  session_11_state?: InputMaybe<Order_By>;
  session_12_state?: InputMaybe<Order_By>;
  session_13_state?: InputMaybe<Order_By>;
  session_14_state?: InputMaybe<Order_By>;
  session_15_state?: InputMaybe<Order_By>;
  session_16_state?: InputMaybe<Order_By>;
  session_17_state?: InputMaybe<Order_By>;
  session_18_state?: InputMaybe<Order_By>;
  session_19_state?: InputMaybe<Order_By>;
  session_20_state?: InputMaybe<Order_By>;
  session_21_state?: InputMaybe<Order_By>;
  session_22_state?: InputMaybe<Order_By>;
  session_23_state?: InputMaybe<Order_By>;
  session_24_state?: InputMaybe<Order_By>;
  session_25_state?: InputMaybe<Order_By>;
  session_26_state?: InputMaybe<Order_By>;
  session_27_state?: InputMaybe<Order_By>;
  session_28_state?: InputMaybe<Order_By>;
  session_29_state?: InputMaybe<Order_By>;
  session_30_state?: InputMaybe<Order_By>;
  session_31_state?: InputMaybe<Order_By>;
  session_32_state?: InputMaybe<Order_By>;
  session_33_state?: InputMaybe<Order_By>;
  session_34_state?: InputMaybe<Order_By>;
  session_35_state?: InputMaybe<Order_By>;
  session_36_state?: InputMaybe<Order_By>;
  session_37_state?: InputMaybe<Order_By>;
  session_38_state?: InputMaybe<Order_By>;
  session_39_state?: InputMaybe<Order_By>;
  session_40_state?: InputMaybe<Order_By>;
  session_41_state?: InputMaybe<Order_By>;
  session_42_state?: InputMaybe<Order_By>;
  session_43_state?: InputMaybe<Order_By>;
  session_44_state?: InputMaybe<Order_By>;
  session_45_state?: InputMaybe<Order_By>;
  session_46_state?: InputMaybe<Order_By>;
  session_47_state?: InputMaybe<Order_By>;
  session_48_state?: InputMaybe<Order_By>;
  session_49_state?: InputMaybe<Order_By>;
  session_50_state?: InputMaybe<Order_By>;
  session_51_state?: InputMaybe<Order_By>;
  session_52_state?: InputMaybe<Order_By>;
  session_53_state?: InputMaybe<Order_By>;
  session_54_state?: InputMaybe<Order_By>;
  session_55_state?: InputMaybe<Order_By>;
  session_56_state?: InputMaybe<Order_By>;
  session_57_state?: InputMaybe<Order_By>;
  session_58_state?: InputMaybe<Order_By>;
  session_59_state?: InputMaybe<Order_By>;
  session_60_state?: InputMaybe<Order_By>;
  session_to_pay?: InputMaybe<Order_By>;
};

export type User_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<User_Bool_Exp>>>;
  _not?: InputMaybe<User_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<User_Bool_Exp>>>;
  alcohol?: InputMaybe<Boolean_Comparison_Exp>;
  alt_email?: InputMaybe<String_Comparison_Exp>;
  alt_id?: InputMaybe<String_Comparison_Exp>;
  apnea_questionnaires?: InputMaybe<Apnea_Questionnaire_Bool_Exp>;
  app_version?: InputMaybe<String_Comparison_Exp>;
  appointments?: InputMaybe<Appointment_Bool_Exp>;
  autogenic_training?: InputMaybe<Boolean_Comparison_Exp>;
  bathroom?: InputMaybe<Boolean_Comparison_Exp>;
  behavior_activation?: InputMaybe<Boolean_Comparison_Exp>;
  birthdate?: InputMaybe<Date_Comparison_Exp>;
  caffeine?: InputMaybe<Boolean_Comparison_Exp>;
  can_send_email_reminder?: InputMaybe<Boolean_Comparison_Exp>;
  can_send_whatsapp_reminder?: InputMaybe<Boolean_Comparison_Exp>;
  catastrophic_thinkings?: InputMaybe<Catastrophic_Thinkings_Bool_Exp>;
  challenge_catastrophic_thinking?: InputMaybe<Boolean_Comparison_Exp>;
  checkout?: InputMaybe<User_Checkout_Bool_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  companies?: InputMaybe<Company_User_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  current_journey_id?: InputMaybe<Int_Comparison_Exp>;
  day_goal?: InputMaybe<Smallint_Comparison_Exp>;
  deep_breath?: InputMaybe<Boolean_Comparison_Exp>;
  desired_wake_up?: InputMaybe<Timetz_Comparison_Exp>;
  device_os?: InputMaybe<Os_Enum_Enum_Comparison_Exp>;
  document?: InputMaybe<String_Comparison_Exp>;
  dream?: InputMaybe<Boolean_Comparison_Exp>;
  dreams?: InputMaybe<Dream_Bool_Exp>;
  eficiency?: InputMaybe<Eficiency_Bool_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  enabled?: InputMaybe<Boolean_Comparison_Exp>;
  epworths?: InputMaybe<Epworth_Bool_Exp>;
  exercise?: InputMaybe<Boolean_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  gad?: InputMaybe<Gad_Bool_Exp>;
  gad7s?: InputMaybe<Gad7_Bool_Exp>;
  gender?: InputMaybe<String_Comparison_Exp>;
  generic_questionnaires?: InputMaybe<Generic_Questionnaire_Bool_Exp>;
  google_sheet_rows?: InputMaybe<Google_Sheet_Row_Bool_Exp>;
  gratitude?: InputMaybe<Boolean_Comparison_Exp>;
  gratitudes?: InputMaybe<Gratitude_Bool_Exp>;
  health_carers?: InputMaybe<Health_Carer_User_Bool_Exp>;
  heavy_meal?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  imagery?: InputMaybe<Boolean_Comparison_Exp>;
  internal_id?: InputMaybe<Int_Comparison_Exp>;
  isi?: InputMaybe<Isi_Bool_Exp>;
  key_values?: InputMaybe<User_Key_Value_Bool_Exp>;
  kuppermans?: InputMaybe<Kupperman_Bool_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  light?: InputMaybe<Boolean_Comparison_Exp>;
  light_therapy?: InputMaybe<Boolean_Comparison_Exp>;
  logs?: InputMaybe<Log_Bool_Exp>;
  meditation?: InputMaybe<Boolean_Comparison_Exp>;
  menopause_greene_scales?: InputMaybe<Menopause_Greene_Scale_Bool_Exp>;
  munich_chronotype_cores?: InputMaybe<Munich_Chronotype_Core_Bool_Exp>;
  munich_chronotypes?: InputMaybe<Munich_Chronotype_Bool_Exp>;
  nap?: InputMaybe<Boolean_Comparison_Exp>;
  nicotine?: InputMaybe<Boolean_Comparison_Exp>;
  night_goal?: InputMaybe<Smallint_Comparison_Exp>;
  noise?: InputMaybe<Boolean_Comparison_Exp>;
  paid?: InputMaybe<Boolean_Comparison_Exp>;
  paid_value?: InputMaybe<Numeric_Comparison_Exp>;
  pain?: InputMaybe<Boolean_Comparison_Exp>;
  paradoxical_intention?: InputMaybe<Boolean_Comparison_Exp>;
  parking_lot?: InputMaybe<Boolean_Comparison_Exp>;
  parking_lots?: InputMaybe<Parking_Lot_Bool_Exp>;
  partner?: InputMaybe<Boolean_Comparison_Exp>;
  personality_questionnaires?: InputMaybe<Personality_Questionnaire_Bool_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  phq?: InputMaybe<Phq_Bool_Exp>;
  phq9s?: InputMaybe<Phq9_Bool_Exp>;
  pmr?: InputMaybe<Boolean_Comparison_Exp>;
  points?: InputMaybe<Int_Comparison_Exp>;
  pre_users?: InputMaybe<Pre_User_Bool_Exp>;
  productivity?: InputMaybe<Productivity_Bool_Exp>;
  profile_picture?: InputMaybe<String_Comparison_Exp>;
  psqi?: InputMaybe<Psqi_Bool_Exp>;
  psqi_state?: InputMaybe<Smallint_Comparison_Exp>;
  ratings?: InputMaybe<Rating_Bool_Exp>;
  reminder_info?: InputMaybe<User_Reminder_Info_Bool_Exp>;
  session_1_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_2_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_3_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_4_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_5_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_6_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_7_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_8_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_9_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_10_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_11_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_12_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_13_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_14_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_15_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_16_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_17_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_18_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_19_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_20_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_21_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_22_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_23_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_24_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_25_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_26_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_27_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_28_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_29_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_30_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_31_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_32_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_33_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_34_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_35_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_36_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_37_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_38_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_39_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_40_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_41_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_42_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_43_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_44_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_45_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_46_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_47_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_48_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_49_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_50_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_51_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_52_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_53_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_54_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_55_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_56_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_57_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_58_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_59_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_60_state?: InputMaybe<Smallint_Comparison_Exp>;
  session_to_pay?: InputMaybe<Int_Comparison_Exp>;
  sleep_diaries?: InputMaybe<Sleep_Diary_Bool_Exp>;
  sleep_diary_apple_healths?: InputMaybe<Sleep_Diary_Apple_Health_Bool_Exp>;
  sleep_diary_google_fits?: InputMaybe<Sleep_Diary_Google_Fit_Bool_Exp>;
  sleep_diary_intervals?: InputMaybe<Sleep_Diary_Interval_Bool_Exp>;
  sleep_medicine?: InputMaybe<Boolean_Comparison_Exp>;
  sleep_restriction?: InputMaybe<Boolean_Comparison_Exp>;
  sleep_restrictions?: InputMaybe<Sleep_Restriction_Bool_Exp>;
  sleep_tracker_compressed_data?: InputMaybe<Sleep_Tracker_Compressed_Data_Bool_Exp>;
  stimulus_control?: InputMaybe<Boolean_Comparison_Exp>;
  stop_bangs?: InputMaybe<Stop_Bang_Bool_Exp>;
  subscription_expiration?: InputMaybe<Date_Comparison_Exp>;
  temperature?: InputMaybe<Boolean_Comparison_Exp>;
  thought_block?: InputMaybe<Boolean_Comparison_Exp>;
  toolbox?: InputMaybe<Toolbox_Bool_Exp>;
  tried_alcohol?: InputMaybe<Boolean_Comparison_Exp>;
  tried_medicine?: InputMaybe<Boolean_Comparison_Exp>;
  tried_melatonin?: InputMaybe<Boolean_Comparison_Exp>;
  tried_other?: InputMaybe<String_Comparison_Exp>;
  tried_restriction?: InputMaybe<Boolean_Comparison_Exp>;
  tried_sleep_hygiene?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_coupons?: InputMaybe<User_Coupon_Bool_Exp>;
  user_journeys?: InputMaybe<User_Journey_Bool_Exp>;
  user_notifications?: InputMaybe<User_Notification_Bool_Exp>;
  user_program_sessions?: InputMaybe<User_Program_Session_Bool_Exp>;
  user_tags?: InputMaybe<User_Tag_Bool_Exp>;
  user_telehealth?: InputMaybe<User_Telehealth_Bool_Exp>;
};

export type User_Checkout = {
  __typename?: 'user_checkout';
  created_at: Scalars['timestamptz'];
  data: Scalars['jsonb'];
  id: Scalars['Int'];
  receipt?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  user?: Maybe<User>;
  user_id: Scalars['String'];
  uuid?: Maybe<Scalars['uuid']>;
};

export type User_CheckoutDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

export type User_Checkout_Aggregate = {
  __typename?: 'user_checkout_aggregate';
  aggregate?: Maybe<User_Checkout_Aggregate_Fields>;
  nodes: Array<User_Checkout>;
};

export type User_Checkout_Aggregate_Fields = {
  __typename?: 'user_checkout_aggregate_fields';
  avg?: Maybe<User_Checkout_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Checkout_Max_Fields>;
  min?: Maybe<User_Checkout_Min_Fields>;
  stddev?: Maybe<User_Checkout_Stddev_Fields>;
  stddev_pop?: Maybe<User_Checkout_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Checkout_Stddev_Samp_Fields>;
  sum?: Maybe<User_Checkout_Sum_Fields>;
  var_pop?: Maybe<User_Checkout_Var_Pop_Fields>;
  var_samp?: Maybe<User_Checkout_Var_Samp_Fields>;
  variance?: Maybe<User_Checkout_Variance_Fields>;
};

export type User_Checkout_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Checkout_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type User_Checkout_Aggregate_Order_By = {
  avg?: InputMaybe<User_Checkout_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Checkout_Max_Order_By>;
  min?: InputMaybe<User_Checkout_Min_Order_By>;
  stddev?: InputMaybe<User_Checkout_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Checkout_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Checkout_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Checkout_Sum_Order_By>;
  var_pop?: InputMaybe<User_Checkout_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Checkout_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Checkout_Variance_Order_By>;
};

export type User_Checkout_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

export type User_Checkout_Arr_Rel_Insert_Input = {
  data: Array<User_Checkout_Insert_Input>;
  on_conflict?: InputMaybe<User_Checkout_On_Conflict>;
};

export type User_Checkout_Avg_Fields = {
  __typename?: 'user_checkout_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

export type User_Checkout_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type User_Checkout_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<User_Checkout_Bool_Exp>>>;
  _not?: InputMaybe<User_Checkout_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<User_Checkout_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  receipt?: InputMaybe<String_Comparison_Exp>;
  sku?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  uuid?: InputMaybe<Uuid_Comparison_Exp>;
};

export enum User_Checkout_Constraint {
  UserCheckoutPkey = 'user_checkout_pkey',
}

export type User_Checkout_Delete_At_Path_Input = {
  data?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type User_Checkout_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']>;
};

export type User_Checkout_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']>;
};

export type User_Checkout_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

export type User_Checkout_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  receipt?: InputMaybe<Scalars['String']>;
  sku?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['uuid']>;
};

export type User_Checkout_Max_Fields = {
  __typename?: 'user_checkout_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  receipt?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
};

export type User_Checkout_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  receipt?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  uuid?: InputMaybe<Order_By>;
};

export type User_Checkout_Min_Fields = {
  __typename?: 'user_checkout_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  receipt?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
};

export type User_Checkout_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  receipt?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  uuid?: InputMaybe<Order_By>;
};

export type User_Checkout_Mutation_Response = {
  __typename?: 'user_checkout_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<User_Checkout>;
};

export type User_Checkout_Obj_Rel_Insert_Input = {
  data: User_Checkout_Insert_Input;
  on_conflict?: InputMaybe<User_Checkout_On_Conflict>;
};

export type User_Checkout_On_Conflict = {
  constraint: User_Checkout_Constraint;
  update_columns: Array<User_Checkout_Update_Column>;
  where?: InputMaybe<User_Checkout_Bool_Exp>;
};

export type User_Checkout_Order_By = {
  created_at?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  receipt?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
  uuid?: InputMaybe<Order_By>;
};

export type User_Checkout_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export type User_Checkout_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

export enum User_Checkout_Select_Column {
  CreatedAt = 'created_at',
  Data = 'data',
  Id = 'id',
  Receipt = 'receipt',
  Sku = 'sku',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
  Uuid = 'uuid',
}

export type User_Checkout_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  receipt?: InputMaybe<Scalars['String']>;
  sku?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['uuid']>;
};

export type User_Checkout_Stddev_Fields = {
  __typename?: 'user_checkout_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

export type User_Checkout_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type User_Checkout_Stddev_Pop_Fields = {
  __typename?: 'user_checkout_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type User_Checkout_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type User_Checkout_Stddev_Samp_Fields = {
  __typename?: 'user_checkout_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type User_Checkout_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type User_Checkout_Sum_Fields = {
  __typename?: 'user_checkout_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

export type User_Checkout_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

export enum User_Checkout_Update_Column {
  CreatedAt = 'created_at',
  Data = 'data',
  Id = 'id',
  Receipt = 'receipt',
  Sku = 'sku',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
  Uuid = 'uuid',
}

export type User_Checkout_Var_Pop_Fields = {
  __typename?: 'user_checkout_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type User_Checkout_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type User_Checkout_Var_Samp_Fields = {
  __typename?: 'user_checkout_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type User_Checkout_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type User_Checkout_Variance_Fields = {
  __typename?: 'user_checkout_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type User_Checkout_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

export enum User_Constraint {
  UserIdKey = 'User_id_key',
  UserPkey = 'User_pkey',
  UserInternalIdKey = 'user_internal_id_key',
}

export type User_Coupon = {
  __typename?: 'user_coupon';
  comment?: Maybe<Scalars['String']>;
  coupon?: Maybe<Coupon>;
  coupon_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  updated_at: Scalars['timestamptz'];
  user?: Maybe<User>;
  user_id: Scalars['String'];
};

export type User_Coupon_Aggregate = {
  __typename?: 'user_coupon_aggregate';
  aggregate?: Maybe<User_Coupon_Aggregate_Fields>;
  nodes: Array<User_Coupon>;
};

export type User_Coupon_Aggregate_Fields = {
  __typename?: 'user_coupon_aggregate_fields';
  avg?: Maybe<User_Coupon_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Coupon_Max_Fields>;
  min?: Maybe<User_Coupon_Min_Fields>;
  stddev?: Maybe<User_Coupon_Stddev_Fields>;
  stddev_pop?: Maybe<User_Coupon_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Coupon_Stddev_Samp_Fields>;
  sum?: Maybe<User_Coupon_Sum_Fields>;
  var_pop?: Maybe<User_Coupon_Var_Pop_Fields>;
  var_samp?: Maybe<User_Coupon_Var_Samp_Fields>;
  variance?: Maybe<User_Coupon_Variance_Fields>;
};

export type User_Coupon_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Coupon_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type User_Coupon_Aggregate_Order_By = {
  avg?: InputMaybe<User_Coupon_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Coupon_Max_Order_By>;
  min?: InputMaybe<User_Coupon_Min_Order_By>;
  stddev?: InputMaybe<User_Coupon_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Coupon_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Coupon_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Coupon_Sum_Order_By>;
  var_pop?: InputMaybe<User_Coupon_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Coupon_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Coupon_Variance_Order_By>;
};

export type User_Coupon_Arr_Rel_Insert_Input = {
  data: Array<User_Coupon_Insert_Input>;
  on_conflict?: InputMaybe<User_Coupon_On_Conflict>;
};

export type User_Coupon_Avg_Fields = {
  __typename?: 'user_coupon_avg_fields';
  coupon_id?: Maybe<Scalars['Float']>;
};

export type User_Coupon_Avg_Order_By = {
  coupon_id?: InputMaybe<Order_By>;
};

export type User_Coupon_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<User_Coupon_Bool_Exp>>>;
  _not?: InputMaybe<User_Coupon_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<User_Coupon_Bool_Exp>>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  coupon?: InputMaybe<Coupon_Bool_Exp>;
  coupon_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

export enum User_Coupon_Constraint {
  UserCouponPkey1 = 'user_coupon_pkey1',
}

export type User_Coupon_Inc_Input = {
  coupon_id?: InputMaybe<Scalars['bigint']>;
};

export type User_Coupon_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  coupon?: InputMaybe<Coupon_Obj_Rel_Insert_Input>;
  coupon_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type User_Coupon_Max_Fields = {
  __typename?: 'user_coupon_max_fields';
  comment?: Maybe<Scalars['String']>;
  coupon_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type User_Coupon_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  coupon_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type User_Coupon_Min_Fields = {
  __typename?: 'user_coupon_min_fields';
  comment?: Maybe<Scalars['String']>;
  coupon_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type User_Coupon_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  coupon_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type User_Coupon_Mutation_Response = {
  __typename?: 'user_coupon_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<User_Coupon>;
};

export type User_Coupon_Obj_Rel_Insert_Input = {
  data: User_Coupon_Insert_Input;
  on_conflict?: InputMaybe<User_Coupon_On_Conflict>;
};

export type User_Coupon_On_Conflict = {
  constraint: User_Coupon_Constraint;
  update_columns: Array<User_Coupon_Update_Column>;
  where?: InputMaybe<User_Coupon_Bool_Exp>;
};

export type User_Coupon_Order_By = {
  comment?: InputMaybe<Order_By>;
  coupon?: InputMaybe<Coupon_Order_By>;
  coupon_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type User_Coupon_Pk_Columns_Input = {
  coupon_id: Scalars['bigint'];
  user_id: Scalars['String'];
};

export enum User_Coupon_Select_Column {
  Comment = 'comment',
  CouponId = 'coupon_id',
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type User_Coupon_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  coupon_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type User_Coupon_Stddev_Fields = {
  __typename?: 'user_coupon_stddev_fields';
  coupon_id?: Maybe<Scalars['Float']>;
};

export type User_Coupon_Stddev_Order_By = {
  coupon_id?: InputMaybe<Order_By>;
};

export type User_Coupon_Stddev_Pop_Fields = {
  __typename?: 'user_coupon_stddev_pop_fields';
  coupon_id?: Maybe<Scalars['Float']>;
};

export type User_Coupon_Stddev_Pop_Order_By = {
  coupon_id?: InputMaybe<Order_By>;
};

export type User_Coupon_Stddev_Samp_Fields = {
  __typename?: 'user_coupon_stddev_samp_fields';
  coupon_id?: Maybe<Scalars['Float']>;
};

export type User_Coupon_Stddev_Samp_Order_By = {
  coupon_id?: InputMaybe<Order_By>;
};

export type User_Coupon_Sum_Fields = {
  __typename?: 'user_coupon_sum_fields';
  coupon_id?: Maybe<Scalars['bigint']>;
};

export type User_Coupon_Sum_Order_By = {
  coupon_id?: InputMaybe<Order_By>;
};

export enum User_Coupon_Update_Column {
  Comment = 'comment',
  CouponId = 'coupon_id',
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type User_Coupon_Var_Pop_Fields = {
  __typename?: 'user_coupon_var_pop_fields';
  coupon_id?: Maybe<Scalars['Float']>;
};

export type User_Coupon_Var_Pop_Order_By = {
  coupon_id?: InputMaybe<Order_By>;
};

export type User_Coupon_Var_Samp_Fields = {
  __typename?: 'user_coupon_var_samp_fields';
  coupon_id?: Maybe<Scalars['Float']>;
};

export type User_Coupon_Var_Samp_Order_By = {
  coupon_id?: InputMaybe<Order_By>;
};

export type User_Coupon_Variance_Fields = {
  __typename?: 'user_coupon_variance_fields';
  coupon_id?: Maybe<Scalars['Float']>;
};

export type User_Coupon_Variance_Order_By = {
  coupon_id?: InputMaybe<Order_By>;
};

export type User_Inc_Input = {
  current_journey_id?: InputMaybe<Scalars['Int']>;
  day_goal?: InputMaybe<Scalars['smallint']>;
  internal_id?: InputMaybe<Scalars['Int']>;
  night_goal?: InputMaybe<Scalars['smallint']>;
  paid_value?: InputMaybe<Scalars['numeric']>;
  points?: InputMaybe<Scalars['Int']>;
  psqi_state?: InputMaybe<Scalars['smallint']>;
  session_1_state?: InputMaybe<Scalars['smallint']>;
  session_2_state?: InputMaybe<Scalars['smallint']>;
  session_3_state?: InputMaybe<Scalars['smallint']>;
  session_4_state?: InputMaybe<Scalars['smallint']>;
  session_5_state?: InputMaybe<Scalars['smallint']>;
  session_6_state?: InputMaybe<Scalars['smallint']>;
  session_7_state?: InputMaybe<Scalars['smallint']>;
  session_8_state?: InputMaybe<Scalars['smallint']>;
  session_9_state?: InputMaybe<Scalars['smallint']>;
  session_10_state?: InputMaybe<Scalars['smallint']>;
  session_11_state?: InputMaybe<Scalars['smallint']>;
  session_12_state?: InputMaybe<Scalars['smallint']>;
  session_13_state?: InputMaybe<Scalars['smallint']>;
  session_14_state?: InputMaybe<Scalars['smallint']>;
  session_15_state?: InputMaybe<Scalars['smallint']>;
  session_16_state?: InputMaybe<Scalars['smallint']>;
  session_17_state?: InputMaybe<Scalars['smallint']>;
  session_18_state?: InputMaybe<Scalars['smallint']>;
  session_19_state?: InputMaybe<Scalars['smallint']>;
  session_20_state?: InputMaybe<Scalars['smallint']>;
  session_21_state?: InputMaybe<Scalars['smallint']>;
  session_22_state?: InputMaybe<Scalars['smallint']>;
  session_23_state?: InputMaybe<Scalars['smallint']>;
  session_24_state?: InputMaybe<Scalars['smallint']>;
  session_25_state?: InputMaybe<Scalars['smallint']>;
  session_26_state?: InputMaybe<Scalars['smallint']>;
  session_27_state?: InputMaybe<Scalars['smallint']>;
  session_28_state?: InputMaybe<Scalars['smallint']>;
  session_29_state?: InputMaybe<Scalars['smallint']>;
  session_30_state?: InputMaybe<Scalars['smallint']>;
  session_31_state?: InputMaybe<Scalars['smallint']>;
  session_32_state?: InputMaybe<Scalars['smallint']>;
  session_33_state?: InputMaybe<Scalars['smallint']>;
  session_34_state?: InputMaybe<Scalars['smallint']>;
  session_35_state?: InputMaybe<Scalars['smallint']>;
  session_36_state?: InputMaybe<Scalars['smallint']>;
  session_37_state?: InputMaybe<Scalars['smallint']>;
  session_38_state?: InputMaybe<Scalars['smallint']>;
  session_39_state?: InputMaybe<Scalars['smallint']>;
  session_40_state?: InputMaybe<Scalars['smallint']>;
  session_41_state?: InputMaybe<Scalars['smallint']>;
  session_42_state?: InputMaybe<Scalars['smallint']>;
  session_43_state?: InputMaybe<Scalars['smallint']>;
  session_44_state?: InputMaybe<Scalars['smallint']>;
  session_45_state?: InputMaybe<Scalars['smallint']>;
  session_46_state?: InputMaybe<Scalars['smallint']>;
  session_47_state?: InputMaybe<Scalars['smallint']>;
  session_48_state?: InputMaybe<Scalars['smallint']>;
  session_49_state?: InputMaybe<Scalars['smallint']>;
  session_50_state?: InputMaybe<Scalars['smallint']>;
  session_51_state?: InputMaybe<Scalars['smallint']>;
  session_52_state?: InputMaybe<Scalars['smallint']>;
  session_53_state?: InputMaybe<Scalars['smallint']>;
  session_54_state?: InputMaybe<Scalars['smallint']>;
  session_55_state?: InputMaybe<Scalars['smallint']>;
  session_56_state?: InputMaybe<Scalars['smallint']>;
  session_57_state?: InputMaybe<Scalars['smallint']>;
  session_58_state?: InputMaybe<Scalars['smallint']>;
  session_59_state?: InputMaybe<Scalars['smallint']>;
  session_60_state?: InputMaybe<Scalars['smallint']>;
  session_to_pay?: InputMaybe<Scalars['Int']>;
};

export type User_Insert_Input = {
  alcohol?: InputMaybe<Scalars['Boolean']>;
  alt_email?: InputMaybe<Scalars['String']>;
  alt_id?: InputMaybe<Scalars['String']>;
  apnea_questionnaires?: InputMaybe<Apnea_Questionnaire_Arr_Rel_Insert_Input>;
  app_version?: InputMaybe<Scalars['String']>;
  appointments?: InputMaybe<Appointment_Arr_Rel_Insert_Input>;
  autogenic_training?: InputMaybe<Scalars['Boolean']>;
  bathroom?: InputMaybe<Scalars['Boolean']>;
  behavior_activation?: InputMaybe<Scalars['Boolean']>;
  birthdate?: InputMaybe<Scalars['date']>;
  caffeine?: InputMaybe<Scalars['Boolean']>;
  can_send_email_reminder?: InputMaybe<Scalars['Boolean']>;
  can_send_whatsapp_reminder?: InputMaybe<Scalars['Boolean']>;
  catastrophic_thinkings?: InputMaybe<Catastrophic_Thinkings_Arr_Rel_Insert_Input>;
  challenge_catastrophic_thinking?: InputMaybe<Scalars['Boolean']>;
  checkout?: InputMaybe<User_Checkout_Arr_Rel_Insert_Input>;
  comment?: InputMaybe<Scalars['String']>;
  companies?: InputMaybe<Company_User_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  current_journey_id?: InputMaybe<Scalars['Int']>;
  day_goal?: InputMaybe<Scalars['smallint']>;
  deep_breath?: InputMaybe<Scalars['Boolean']>;
  desired_wake_up?: InputMaybe<Scalars['timetz']>;
  device_os?: InputMaybe<Os_Enum_Enum>;
  document?: InputMaybe<Scalars['String']>;
  dream?: InputMaybe<Scalars['Boolean']>;
  dreams?: InputMaybe<Dream_Arr_Rel_Insert_Input>;
  eficiency?: InputMaybe<Eficiency_Arr_Rel_Insert_Input>;
  email?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  epworths?: InputMaybe<Epworth_Arr_Rel_Insert_Input>;
  exercise?: InputMaybe<Scalars['Boolean']>;
  first_name?: InputMaybe<Scalars['String']>;
  gad?: InputMaybe<Gad_Arr_Rel_Insert_Input>;
  gad7s?: InputMaybe<Gad7_Arr_Rel_Insert_Input>;
  gender?: InputMaybe<Scalars['String']>;
  generic_questionnaires?: InputMaybe<Generic_Questionnaire_Arr_Rel_Insert_Input>;
  google_sheet_rows?: InputMaybe<Google_Sheet_Row_Arr_Rel_Insert_Input>;
  gratitude?: InputMaybe<Scalars['Boolean']>;
  gratitudes?: InputMaybe<Gratitude_Arr_Rel_Insert_Input>;
  health_carers?: InputMaybe<Health_Carer_User_Arr_Rel_Insert_Input>;
  heavy_meal?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  imagery?: InputMaybe<Scalars['Boolean']>;
  internal_id?: InputMaybe<Scalars['Int']>;
  isi?: InputMaybe<Isi_Arr_Rel_Insert_Input>;
  key_values?: InputMaybe<User_Key_Value_Arr_Rel_Insert_Input>;
  kuppermans?: InputMaybe<Kupperman_Arr_Rel_Insert_Input>;
  last_name?: InputMaybe<Scalars['String']>;
  light?: InputMaybe<Scalars['Boolean']>;
  light_therapy?: InputMaybe<Scalars['Boolean']>;
  logs?: InputMaybe<Log_Arr_Rel_Insert_Input>;
  meditation?: InputMaybe<Scalars['Boolean']>;
  menopause_greene_scales?: InputMaybe<Menopause_Greene_Scale_Arr_Rel_Insert_Input>;
  munich_chronotype_cores?: InputMaybe<Munich_Chronotype_Core_Arr_Rel_Insert_Input>;
  munich_chronotypes?: InputMaybe<Munich_Chronotype_Arr_Rel_Insert_Input>;
  nap?: InputMaybe<Scalars['Boolean']>;
  nicotine?: InputMaybe<Scalars['Boolean']>;
  night_goal?: InputMaybe<Scalars['smallint']>;
  noise?: InputMaybe<Scalars['Boolean']>;
  paid?: InputMaybe<Scalars['Boolean']>;
  paid_value?: InputMaybe<Scalars['numeric']>;
  pain?: InputMaybe<Scalars['Boolean']>;
  paradoxical_intention?: InputMaybe<Scalars['Boolean']>;
  parking_lot?: InputMaybe<Scalars['Boolean']>;
  parking_lots?: InputMaybe<Parking_Lot_Arr_Rel_Insert_Input>;
  partner?: InputMaybe<Scalars['Boolean']>;
  personality_questionnaires?: InputMaybe<Personality_Questionnaire_Arr_Rel_Insert_Input>;
  phone?: InputMaybe<Scalars['String']>;
  phq?: InputMaybe<Phq_Arr_Rel_Insert_Input>;
  phq9s?: InputMaybe<Phq9_Arr_Rel_Insert_Input>;
  pmr?: InputMaybe<Scalars['Boolean']>;
  points?: InputMaybe<Scalars['Int']>;
  pre_users?: InputMaybe<Pre_User_Arr_Rel_Insert_Input>;
  productivity?: InputMaybe<Productivity_Arr_Rel_Insert_Input>;
  profile_picture?: InputMaybe<Scalars['String']>;
  psqi?: InputMaybe<Psqi_Arr_Rel_Insert_Input>;
  psqi_state?: InputMaybe<Scalars['smallint']>;
  ratings?: InputMaybe<Rating_Arr_Rel_Insert_Input>;
  reminder_info?: InputMaybe<User_Reminder_Info_Obj_Rel_Insert_Input>;
  session_1_state?: InputMaybe<Scalars['smallint']>;
  session_2_state?: InputMaybe<Scalars['smallint']>;
  session_3_state?: InputMaybe<Scalars['smallint']>;
  session_4_state?: InputMaybe<Scalars['smallint']>;
  session_5_state?: InputMaybe<Scalars['smallint']>;
  session_6_state?: InputMaybe<Scalars['smallint']>;
  session_7_state?: InputMaybe<Scalars['smallint']>;
  session_8_state?: InputMaybe<Scalars['smallint']>;
  session_9_state?: InputMaybe<Scalars['smallint']>;
  session_10_state?: InputMaybe<Scalars['smallint']>;
  session_11_state?: InputMaybe<Scalars['smallint']>;
  session_12_state?: InputMaybe<Scalars['smallint']>;
  session_13_state?: InputMaybe<Scalars['smallint']>;
  session_14_state?: InputMaybe<Scalars['smallint']>;
  session_15_state?: InputMaybe<Scalars['smallint']>;
  session_16_state?: InputMaybe<Scalars['smallint']>;
  session_17_state?: InputMaybe<Scalars['smallint']>;
  session_18_state?: InputMaybe<Scalars['smallint']>;
  session_19_state?: InputMaybe<Scalars['smallint']>;
  session_20_state?: InputMaybe<Scalars['smallint']>;
  session_21_state?: InputMaybe<Scalars['smallint']>;
  session_22_state?: InputMaybe<Scalars['smallint']>;
  session_23_state?: InputMaybe<Scalars['smallint']>;
  session_24_state?: InputMaybe<Scalars['smallint']>;
  session_25_state?: InputMaybe<Scalars['smallint']>;
  session_26_state?: InputMaybe<Scalars['smallint']>;
  session_27_state?: InputMaybe<Scalars['smallint']>;
  session_28_state?: InputMaybe<Scalars['smallint']>;
  session_29_state?: InputMaybe<Scalars['smallint']>;
  session_30_state?: InputMaybe<Scalars['smallint']>;
  session_31_state?: InputMaybe<Scalars['smallint']>;
  session_32_state?: InputMaybe<Scalars['smallint']>;
  session_33_state?: InputMaybe<Scalars['smallint']>;
  session_34_state?: InputMaybe<Scalars['smallint']>;
  session_35_state?: InputMaybe<Scalars['smallint']>;
  session_36_state?: InputMaybe<Scalars['smallint']>;
  session_37_state?: InputMaybe<Scalars['smallint']>;
  session_38_state?: InputMaybe<Scalars['smallint']>;
  session_39_state?: InputMaybe<Scalars['smallint']>;
  session_40_state?: InputMaybe<Scalars['smallint']>;
  session_41_state?: InputMaybe<Scalars['smallint']>;
  session_42_state?: InputMaybe<Scalars['smallint']>;
  session_43_state?: InputMaybe<Scalars['smallint']>;
  session_44_state?: InputMaybe<Scalars['smallint']>;
  session_45_state?: InputMaybe<Scalars['smallint']>;
  session_46_state?: InputMaybe<Scalars['smallint']>;
  session_47_state?: InputMaybe<Scalars['smallint']>;
  session_48_state?: InputMaybe<Scalars['smallint']>;
  session_49_state?: InputMaybe<Scalars['smallint']>;
  session_50_state?: InputMaybe<Scalars['smallint']>;
  session_51_state?: InputMaybe<Scalars['smallint']>;
  session_52_state?: InputMaybe<Scalars['smallint']>;
  session_53_state?: InputMaybe<Scalars['smallint']>;
  session_54_state?: InputMaybe<Scalars['smallint']>;
  session_55_state?: InputMaybe<Scalars['smallint']>;
  session_56_state?: InputMaybe<Scalars['smallint']>;
  session_57_state?: InputMaybe<Scalars['smallint']>;
  session_58_state?: InputMaybe<Scalars['smallint']>;
  session_59_state?: InputMaybe<Scalars['smallint']>;
  session_60_state?: InputMaybe<Scalars['smallint']>;
  session_to_pay?: InputMaybe<Scalars['Int']>;
  sleep_diaries?: InputMaybe<Sleep_Diary_Arr_Rel_Insert_Input>;
  sleep_diary_apple_healths?: InputMaybe<Sleep_Diary_Apple_Health_Arr_Rel_Insert_Input>;
  sleep_diary_google_fits?: InputMaybe<Sleep_Diary_Google_Fit_Arr_Rel_Insert_Input>;
  sleep_diary_intervals?: InputMaybe<Sleep_Diary_Interval_Arr_Rel_Insert_Input>;
  sleep_medicine?: InputMaybe<Scalars['Boolean']>;
  sleep_restriction?: InputMaybe<Scalars['Boolean']>;
  sleep_restrictions?: InputMaybe<Sleep_Restriction_Arr_Rel_Insert_Input>;
  sleep_tracker_compressed_data?: InputMaybe<Sleep_Tracker_Compressed_Data_Arr_Rel_Insert_Input>;
  stimulus_control?: InputMaybe<Scalars['Boolean']>;
  stop_bangs?: InputMaybe<Stop_Bang_Arr_Rel_Insert_Input>;
  subscription_expiration?: InputMaybe<Scalars['date']>;
  temperature?: InputMaybe<Scalars['Boolean']>;
  thought_block?: InputMaybe<Scalars['Boolean']>;
  toolbox?: InputMaybe<Toolbox_Obj_Rel_Insert_Input>;
  tried_alcohol?: InputMaybe<Scalars['Boolean']>;
  tried_medicine?: InputMaybe<Scalars['Boolean']>;
  tried_melatonin?: InputMaybe<Scalars['Boolean']>;
  tried_other?: InputMaybe<Scalars['String']>;
  tried_restriction?: InputMaybe<Scalars['Boolean']>;
  tried_sleep_hygiene?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_coupons?: InputMaybe<User_Coupon_Arr_Rel_Insert_Input>;
  user_journeys?: InputMaybe<User_Journey_Arr_Rel_Insert_Input>;
  user_notifications?: InputMaybe<User_Notification_Arr_Rel_Insert_Input>;
  user_program_sessions?: InputMaybe<User_Program_Session_Arr_Rel_Insert_Input>;
  user_tags?: InputMaybe<User_Tag_Arr_Rel_Insert_Input>;
  user_telehealth?: InputMaybe<User_Telehealth_Obj_Rel_Insert_Input>;
};

export type User_Journey = {
  __typename?: 'user_journey';
  created_at: Scalars['timestamptz'];
  journey_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  user: User;
  user_id: Scalars['String'];
};

export type User_Journey_Aggregate = {
  __typename?: 'user_journey_aggregate';
  aggregate?: Maybe<User_Journey_Aggregate_Fields>;
  nodes: Array<User_Journey>;
};

export type User_Journey_Aggregate_Fields = {
  __typename?: 'user_journey_aggregate_fields';
  avg?: Maybe<User_Journey_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Journey_Max_Fields>;
  min?: Maybe<User_Journey_Min_Fields>;
  stddev?: Maybe<User_Journey_Stddev_Fields>;
  stddev_pop?: Maybe<User_Journey_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Journey_Stddev_Samp_Fields>;
  sum?: Maybe<User_Journey_Sum_Fields>;
  var_pop?: Maybe<User_Journey_Var_Pop_Fields>;
  var_samp?: Maybe<User_Journey_Var_Samp_Fields>;
  variance?: Maybe<User_Journey_Variance_Fields>;
};

export type User_Journey_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Journey_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type User_Journey_Aggregate_Order_By = {
  avg?: InputMaybe<User_Journey_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Journey_Max_Order_By>;
  min?: InputMaybe<User_Journey_Min_Order_By>;
  stddev?: InputMaybe<User_Journey_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Journey_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Journey_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Journey_Sum_Order_By>;
  var_pop?: InputMaybe<User_Journey_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Journey_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Journey_Variance_Order_By>;
};

export type User_Journey_Arr_Rel_Insert_Input = {
  data: Array<User_Journey_Insert_Input>;
  on_conflict?: InputMaybe<User_Journey_On_Conflict>;
};

export type User_Journey_Avg_Fields = {
  __typename?: 'user_journey_avg_fields';
  journey_id?: Maybe<Scalars['Float']>;
};

export type User_Journey_Avg_Order_By = {
  journey_id?: InputMaybe<Order_By>;
};

export type User_Journey_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<User_Journey_Bool_Exp>>>;
  _not?: InputMaybe<User_Journey_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<User_Journey_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  journey_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

export enum User_Journey_Constraint {
  UserJourneyPkey = 'user_journey_pkey',
}

export type User_Journey_Inc_Input = {
  journey_id?: InputMaybe<Scalars['Int']>;
};

export type User_Journey_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  journey_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type User_Journey_Max_Fields = {
  __typename?: 'user_journey_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  journey_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type User_Journey_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  journey_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type User_Journey_Min_Fields = {
  __typename?: 'user_journey_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  journey_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type User_Journey_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  journey_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type User_Journey_Mutation_Response = {
  __typename?: 'user_journey_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<User_Journey>;
};

export type User_Journey_Obj_Rel_Insert_Input = {
  data: User_Journey_Insert_Input;
  on_conflict?: InputMaybe<User_Journey_On_Conflict>;
};

export type User_Journey_On_Conflict = {
  constraint: User_Journey_Constraint;
  update_columns: Array<User_Journey_Update_Column>;
  where?: InputMaybe<User_Journey_Bool_Exp>;
};

export type User_Journey_Order_By = {
  created_at?: InputMaybe<Order_By>;
  journey_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type User_Journey_Pk_Columns_Input = {
  journey_id: Scalars['Int'];
  user_id: Scalars['String'];
};

export enum User_Journey_Select_Column {
  CreatedAt = 'created_at',
  JourneyId = 'journey_id',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type User_Journey_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  journey_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type User_Journey_Stddev_Fields = {
  __typename?: 'user_journey_stddev_fields';
  journey_id?: Maybe<Scalars['Float']>;
};

export type User_Journey_Stddev_Order_By = {
  journey_id?: InputMaybe<Order_By>;
};

export type User_Journey_Stddev_Pop_Fields = {
  __typename?: 'user_journey_stddev_pop_fields';
  journey_id?: Maybe<Scalars['Float']>;
};

export type User_Journey_Stddev_Pop_Order_By = {
  journey_id?: InputMaybe<Order_By>;
};

export type User_Journey_Stddev_Samp_Fields = {
  __typename?: 'user_journey_stddev_samp_fields';
  journey_id?: Maybe<Scalars['Float']>;
};

export type User_Journey_Stddev_Samp_Order_By = {
  journey_id?: InputMaybe<Order_By>;
};

export type User_Journey_Sum_Fields = {
  __typename?: 'user_journey_sum_fields';
  journey_id?: Maybe<Scalars['Int']>;
};

export type User_Journey_Sum_Order_By = {
  journey_id?: InputMaybe<Order_By>;
};

export enum User_Journey_Update_Column {
  CreatedAt = 'created_at',
  JourneyId = 'journey_id',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type User_Journey_Var_Pop_Fields = {
  __typename?: 'user_journey_var_pop_fields';
  journey_id?: Maybe<Scalars['Float']>;
};

export type User_Journey_Var_Pop_Order_By = {
  journey_id?: InputMaybe<Order_By>;
};

export type User_Journey_Var_Samp_Fields = {
  __typename?: 'user_journey_var_samp_fields';
  journey_id?: Maybe<Scalars['Float']>;
};

export type User_Journey_Var_Samp_Order_By = {
  journey_id?: InputMaybe<Order_By>;
};

export type User_Journey_Variance_Fields = {
  __typename?: 'user_journey_variance_fields';
  journey_id?: Maybe<Scalars['Float']>;
};

export type User_Journey_Variance_Order_By = {
  journey_id?: InputMaybe<Order_By>;
};

export type User_Key_Value = {
  __typename?: 'user_key_value';
  created_at: Scalars['timestamptz'];
  key: User_Key_Value_Key_Enum_Enum;
  updated_at: Scalars['timestamptz'];
  user: User;
  user_id: Scalars['String'];
  user_key_value_key_enum: User_Key_Value_Key_Enum;
  value?: Maybe<Scalars['String']>;
};

export type User_Key_Value_Aggregate = {
  __typename?: 'user_key_value_aggregate';
  aggregate?: Maybe<User_Key_Value_Aggregate_Fields>;
  nodes: Array<User_Key_Value>;
};

export type User_Key_Value_Aggregate_Fields = {
  __typename?: 'user_key_value_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Key_Value_Max_Fields>;
  min?: Maybe<User_Key_Value_Min_Fields>;
};

export type User_Key_Value_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Key_Value_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type User_Key_Value_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Key_Value_Max_Order_By>;
  min?: InputMaybe<User_Key_Value_Min_Order_By>;
};

export type User_Key_Value_Arr_Rel_Insert_Input = {
  data: Array<User_Key_Value_Insert_Input>;
  on_conflict?: InputMaybe<User_Key_Value_On_Conflict>;
};

export type User_Key_Value_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<User_Key_Value_Bool_Exp>>>;
  _not?: InputMaybe<User_Key_Value_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<User_Key_Value_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  key?: InputMaybe<User_Key_Value_Key_Enum_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  user_key_value_key_enum?: InputMaybe<User_Key_Value_Key_Enum_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum User_Key_Value_Constraint {
  UserKeyValuePkey = 'user_key_value_pkey',
}

export type User_Key_Value_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  key?: InputMaybe<User_Key_Value_Key_Enum_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
  user_key_value_key_enum?: InputMaybe<User_Key_Value_Key_Enum_Obj_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

export type User_Key_Value_Key_Enum = {
  __typename?: 'user_key_value_key_enum';
  comment: Scalars['String'];
  text: Scalars['String'];
};

export type User_Key_Value_Key_Enum_Aggregate = {
  __typename?: 'user_key_value_key_enum_aggregate';
  aggregate?: Maybe<User_Key_Value_Key_Enum_Aggregate_Fields>;
  nodes: Array<User_Key_Value_Key_Enum>;
};

export type User_Key_Value_Key_Enum_Aggregate_Fields = {
  __typename?: 'user_key_value_key_enum_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Key_Value_Key_Enum_Max_Fields>;
  min?: Maybe<User_Key_Value_Key_Enum_Min_Fields>;
};

export type User_Key_Value_Key_Enum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Key_Value_Key_Enum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type User_Key_Value_Key_Enum_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Key_Value_Key_Enum_Max_Order_By>;
  min?: InputMaybe<User_Key_Value_Key_Enum_Min_Order_By>;
};

export type User_Key_Value_Key_Enum_Arr_Rel_Insert_Input = {
  data: Array<User_Key_Value_Key_Enum_Insert_Input>;
  on_conflict?: InputMaybe<User_Key_Value_Key_Enum_On_Conflict>;
};

export type User_Key_Value_Key_Enum_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<User_Key_Value_Key_Enum_Bool_Exp>>>;
  _not?: InputMaybe<User_Key_Value_Key_Enum_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<User_Key_Value_Key_Enum_Bool_Exp>>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
};

export enum User_Key_Value_Key_Enum_Constraint {
  UserKeyValueKeyEnumPkey = 'user_key_value_key_enum_pkey',
}

export enum User_Key_Value_Key_Enum_Enum {
  HideTelehealthTab = 'hide_telehealth_tab',
  SleepTrackerShowCsv = 'sleep_tracker_show_csv',
  TelehealthTab = 'telehealth_tab',
}

export type User_Key_Value_Key_Enum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<User_Key_Value_Key_Enum_Enum>;
  _in?: InputMaybe<Array<User_Key_Value_Key_Enum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<User_Key_Value_Key_Enum_Enum>;
  _nin?: InputMaybe<Array<User_Key_Value_Key_Enum_Enum>>;
};

export type User_Key_Value_Key_Enum_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type User_Key_Value_Key_Enum_Max_Fields = {
  __typename?: 'user_key_value_key_enum_max_fields';
  comment?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type User_Key_Value_Key_Enum_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type User_Key_Value_Key_Enum_Min_Fields = {
  __typename?: 'user_key_value_key_enum_min_fields';
  comment?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type User_Key_Value_Key_Enum_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type User_Key_Value_Key_Enum_Mutation_Response = {
  __typename?: 'user_key_value_key_enum_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<User_Key_Value_Key_Enum>;
};

export type User_Key_Value_Key_Enum_Obj_Rel_Insert_Input = {
  data: User_Key_Value_Key_Enum_Insert_Input;
  on_conflict?: InputMaybe<User_Key_Value_Key_Enum_On_Conflict>;
};

export type User_Key_Value_Key_Enum_On_Conflict = {
  constraint: User_Key_Value_Key_Enum_Constraint;
  update_columns: Array<User_Key_Value_Key_Enum_Update_Column>;
  where?: InputMaybe<User_Key_Value_Key_Enum_Bool_Exp>;
};

export type User_Key_Value_Key_Enum_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type User_Key_Value_Key_Enum_Pk_Columns_Input = {
  text: Scalars['String'];
};

export enum User_Key_Value_Key_Enum_Select_Column {
  Comment = 'comment',
  Text = 'text',
}

export type User_Key_Value_Key_Enum_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export enum User_Key_Value_Key_Enum_Update_Column {
  Comment = 'comment',
  Text = 'text',
}

export type User_Key_Value_Max_Fields = {
  __typename?: 'user_key_value_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type User_Key_Value_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

export type User_Key_Value_Min_Fields = {
  __typename?: 'user_key_value_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type User_Key_Value_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

export type User_Key_Value_Mutation_Response = {
  __typename?: 'user_key_value_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<User_Key_Value>;
};

export type User_Key_Value_Obj_Rel_Insert_Input = {
  data: User_Key_Value_Insert_Input;
  on_conflict?: InputMaybe<User_Key_Value_On_Conflict>;
};

export type User_Key_Value_On_Conflict = {
  constraint: User_Key_Value_Constraint;
  update_columns: Array<User_Key_Value_Update_Column>;
  where?: InputMaybe<User_Key_Value_Bool_Exp>;
};

export type User_Key_Value_Order_By = {
  created_at?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_key_value_key_enum?: InputMaybe<User_Key_Value_Key_Enum_Order_By>;
  value?: InputMaybe<Order_By>;
};

export type User_Key_Value_Pk_Columns_Input = {
  key: User_Key_Value_Key_Enum_Enum;
  user_id: Scalars['String'];
};

export enum User_Key_Value_Select_Column {
  CreatedAt = 'created_at',
  Key = 'key',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
  Value = 'value',
}

export type User_Key_Value_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  key?: InputMaybe<User_Key_Value_Key_Enum_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export enum User_Key_Value_Update_Column {
  CreatedAt = 'created_at',
  Key = 'key',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
  Value = 'value',
}

export type User_Max_Fields = {
  __typename?: 'user_max_fields';
  alt_email?: Maybe<Scalars['String']>;
  alt_id?: Maybe<Scalars['String']>;
  app_version?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['date']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  current_journey_id?: Maybe<Scalars['Int']>;
  day_goal?: Maybe<Scalars['smallint']>;
  desired_wake_up?: Maybe<Scalars['timetz']>;
  document?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  internal_id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  night_goal?: Maybe<Scalars['smallint']>;
  paid_value?: Maybe<Scalars['numeric']>;
  phone?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Int']>;
  profile_picture?: Maybe<Scalars['String']>;
  psqi_state?: Maybe<Scalars['smallint']>;
  session_1_state?: Maybe<Scalars['smallint']>;
  session_2_state?: Maybe<Scalars['smallint']>;
  session_3_state?: Maybe<Scalars['smallint']>;
  session_4_state?: Maybe<Scalars['smallint']>;
  session_5_state?: Maybe<Scalars['smallint']>;
  session_6_state?: Maybe<Scalars['smallint']>;
  session_7_state?: Maybe<Scalars['smallint']>;
  session_8_state?: Maybe<Scalars['smallint']>;
  session_9_state?: Maybe<Scalars['smallint']>;
  session_10_state?: Maybe<Scalars['smallint']>;
  session_11_state?: Maybe<Scalars['smallint']>;
  session_12_state?: Maybe<Scalars['smallint']>;
  session_13_state?: Maybe<Scalars['smallint']>;
  session_14_state?: Maybe<Scalars['smallint']>;
  session_15_state?: Maybe<Scalars['smallint']>;
  session_16_state?: Maybe<Scalars['smallint']>;
  session_17_state?: Maybe<Scalars['smallint']>;
  session_18_state?: Maybe<Scalars['smallint']>;
  session_19_state?: Maybe<Scalars['smallint']>;
  session_20_state?: Maybe<Scalars['smallint']>;
  session_21_state?: Maybe<Scalars['smallint']>;
  session_22_state?: Maybe<Scalars['smallint']>;
  session_23_state?: Maybe<Scalars['smallint']>;
  session_24_state?: Maybe<Scalars['smallint']>;
  session_25_state?: Maybe<Scalars['smallint']>;
  session_26_state?: Maybe<Scalars['smallint']>;
  session_27_state?: Maybe<Scalars['smallint']>;
  session_28_state?: Maybe<Scalars['smallint']>;
  session_29_state?: Maybe<Scalars['smallint']>;
  session_30_state?: Maybe<Scalars['smallint']>;
  session_31_state?: Maybe<Scalars['smallint']>;
  session_32_state?: Maybe<Scalars['smallint']>;
  session_33_state?: Maybe<Scalars['smallint']>;
  session_34_state?: Maybe<Scalars['smallint']>;
  session_35_state?: Maybe<Scalars['smallint']>;
  session_36_state?: Maybe<Scalars['smallint']>;
  session_37_state?: Maybe<Scalars['smallint']>;
  session_38_state?: Maybe<Scalars['smallint']>;
  session_39_state?: Maybe<Scalars['smallint']>;
  session_40_state?: Maybe<Scalars['smallint']>;
  session_41_state?: Maybe<Scalars['smallint']>;
  session_42_state?: Maybe<Scalars['smallint']>;
  session_43_state?: Maybe<Scalars['smallint']>;
  session_44_state?: Maybe<Scalars['smallint']>;
  session_45_state?: Maybe<Scalars['smallint']>;
  session_46_state?: Maybe<Scalars['smallint']>;
  session_47_state?: Maybe<Scalars['smallint']>;
  session_48_state?: Maybe<Scalars['smallint']>;
  session_49_state?: Maybe<Scalars['smallint']>;
  session_50_state?: Maybe<Scalars['smallint']>;
  session_51_state?: Maybe<Scalars['smallint']>;
  session_52_state?: Maybe<Scalars['smallint']>;
  session_53_state?: Maybe<Scalars['smallint']>;
  session_54_state?: Maybe<Scalars['smallint']>;
  session_55_state?: Maybe<Scalars['smallint']>;
  session_56_state?: Maybe<Scalars['smallint']>;
  session_57_state?: Maybe<Scalars['smallint']>;
  session_58_state?: Maybe<Scalars['smallint']>;
  session_59_state?: Maybe<Scalars['smallint']>;
  session_60_state?: Maybe<Scalars['smallint']>;
  session_to_pay?: Maybe<Scalars['Int']>;
  subscription_expiration?: Maybe<Scalars['date']>;
  tried_other?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type User_Max_Order_By = {
  alt_email?: InputMaybe<Order_By>;
  alt_id?: InputMaybe<Order_By>;
  app_version?: InputMaybe<Order_By>;
  birthdate?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  current_journey_id?: InputMaybe<Order_By>;
  day_goal?: InputMaybe<Order_By>;
  desired_wake_up?: InputMaybe<Order_By>;
  document?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  night_goal?: InputMaybe<Order_By>;
  paid_value?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  points?: InputMaybe<Order_By>;
  profile_picture?: InputMaybe<Order_By>;
  psqi_state?: InputMaybe<Order_By>;
  session_1_state?: InputMaybe<Order_By>;
  session_2_state?: InputMaybe<Order_By>;
  session_3_state?: InputMaybe<Order_By>;
  session_4_state?: InputMaybe<Order_By>;
  session_5_state?: InputMaybe<Order_By>;
  session_6_state?: InputMaybe<Order_By>;
  session_7_state?: InputMaybe<Order_By>;
  session_8_state?: InputMaybe<Order_By>;
  session_9_state?: InputMaybe<Order_By>;
  session_10_state?: InputMaybe<Order_By>;
  session_11_state?: InputMaybe<Order_By>;
  session_12_state?: InputMaybe<Order_By>;
  session_13_state?: InputMaybe<Order_By>;
  session_14_state?: InputMaybe<Order_By>;
  session_15_state?: InputMaybe<Order_By>;
  session_16_state?: InputMaybe<Order_By>;
  session_17_state?: InputMaybe<Order_By>;
  session_18_state?: InputMaybe<Order_By>;
  session_19_state?: InputMaybe<Order_By>;
  session_20_state?: InputMaybe<Order_By>;
  session_21_state?: InputMaybe<Order_By>;
  session_22_state?: InputMaybe<Order_By>;
  session_23_state?: InputMaybe<Order_By>;
  session_24_state?: InputMaybe<Order_By>;
  session_25_state?: InputMaybe<Order_By>;
  session_26_state?: InputMaybe<Order_By>;
  session_27_state?: InputMaybe<Order_By>;
  session_28_state?: InputMaybe<Order_By>;
  session_29_state?: InputMaybe<Order_By>;
  session_30_state?: InputMaybe<Order_By>;
  session_31_state?: InputMaybe<Order_By>;
  session_32_state?: InputMaybe<Order_By>;
  session_33_state?: InputMaybe<Order_By>;
  session_34_state?: InputMaybe<Order_By>;
  session_35_state?: InputMaybe<Order_By>;
  session_36_state?: InputMaybe<Order_By>;
  session_37_state?: InputMaybe<Order_By>;
  session_38_state?: InputMaybe<Order_By>;
  session_39_state?: InputMaybe<Order_By>;
  session_40_state?: InputMaybe<Order_By>;
  session_41_state?: InputMaybe<Order_By>;
  session_42_state?: InputMaybe<Order_By>;
  session_43_state?: InputMaybe<Order_By>;
  session_44_state?: InputMaybe<Order_By>;
  session_45_state?: InputMaybe<Order_By>;
  session_46_state?: InputMaybe<Order_By>;
  session_47_state?: InputMaybe<Order_By>;
  session_48_state?: InputMaybe<Order_By>;
  session_49_state?: InputMaybe<Order_By>;
  session_50_state?: InputMaybe<Order_By>;
  session_51_state?: InputMaybe<Order_By>;
  session_52_state?: InputMaybe<Order_By>;
  session_53_state?: InputMaybe<Order_By>;
  session_54_state?: InputMaybe<Order_By>;
  session_55_state?: InputMaybe<Order_By>;
  session_56_state?: InputMaybe<Order_By>;
  session_57_state?: InputMaybe<Order_By>;
  session_58_state?: InputMaybe<Order_By>;
  session_59_state?: InputMaybe<Order_By>;
  session_60_state?: InputMaybe<Order_By>;
  session_to_pay?: InputMaybe<Order_By>;
  subscription_expiration?: InputMaybe<Order_By>;
  tried_other?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type User_Min_Fields = {
  __typename?: 'user_min_fields';
  alt_email?: Maybe<Scalars['String']>;
  alt_id?: Maybe<Scalars['String']>;
  app_version?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['date']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  current_journey_id?: Maybe<Scalars['Int']>;
  day_goal?: Maybe<Scalars['smallint']>;
  desired_wake_up?: Maybe<Scalars['timetz']>;
  document?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  internal_id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  night_goal?: Maybe<Scalars['smallint']>;
  paid_value?: Maybe<Scalars['numeric']>;
  phone?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Int']>;
  profile_picture?: Maybe<Scalars['String']>;
  psqi_state?: Maybe<Scalars['smallint']>;
  session_1_state?: Maybe<Scalars['smallint']>;
  session_2_state?: Maybe<Scalars['smallint']>;
  session_3_state?: Maybe<Scalars['smallint']>;
  session_4_state?: Maybe<Scalars['smallint']>;
  session_5_state?: Maybe<Scalars['smallint']>;
  session_6_state?: Maybe<Scalars['smallint']>;
  session_7_state?: Maybe<Scalars['smallint']>;
  session_8_state?: Maybe<Scalars['smallint']>;
  session_9_state?: Maybe<Scalars['smallint']>;
  session_10_state?: Maybe<Scalars['smallint']>;
  session_11_state?: Maybe<Scalars['smallint']>;
  session_12_state?: Maybe<Scalars['smallint']>;
  session_13_state?: Maybe<Scalars['smallint']>;
  session_14_state?: Maybe<Scalars['smallint']>;
  session_15_state?: Maybe<Scalars['smallint']>;
  session_16_state?: Maybe<Scalars['smallint']>;
  session_17_state?: Maybe<Scalars['smallint']>;
  session_18_state?: Maybe<Scalars['smallint']>;
  session_19_state?: Maybe<Scalars['smallint']>;
  session_20_state?: Maybe<Scalars['smallint']>;
  session_21_state?: Maybe<Scalars['smallint']>;
  session_22_state?: Maybe<Scalars['smallint']>;
  session_23_state?: Maybe<Scalars['smallint']>;
  session_24_state?: Maybe<Scalars['smallint']>;
  session_25_state?: Maybe<Scalars['smallint']>;
  session_26_state?: Maybe<Scalars['smallint']>;
  session_27_state?: Maybe<Scalars['smallint']>;
  session_28_state?: Maybe<Scalars['smallint']>;
  session_29_state?: Maybe<Scalars['smallint']>;
  session_30_state?: Maybe<Scalars['smallint']>;
  session_31_state?: Maybe<Scalars['smallint']>;
  session_32_state?: Maybe<Scalars['smallint']>;
  session_33_state?: Maybe<Scalars['smallint']>;
  session_34_state?: Maybe<Scalars['smallint']>;
  session_35_state?: Maybe<Scalars['smallint']>;
  session_36_state?: Maybe<Scalars['smallint']>;
  session_37_state?: Maybe<Scalars['smallint']>;
  session_38_state?: Maybe<Scalars['smallint']>;
  session_39_state?: Maybe<Scalars['smallint']>;
  session_40_state?: Maybe<Scalars['smallint']>;
  session_41_state?: Maybe<Scalars['smallint']>;
  session_42_state?: Maybe<Scalars['smallint']>;
  session_43_state?: Maybe<Scalars['smallint']>;
  session_44_state?: Maybe<Scalars['smallint']>;
  session_45_state?: Maybe<Scalars['smallint']>;
  session_46_state?: Maybe<Scalars['smallint']>;
  session_47_state?: Maybe<Scalars['smallint']>;
  session_48_state?: Maybe<Scalars['smallint']>;
  session_49_state?: Maybe<Scalars['smallint']>;
  session_50_state?: Maybe<Scalars['smallint']>;
  session_51_state?: Maybe<Scalars['smallint']>;
  session_52_state?: Maybe<Scalars['smallint']>;
  session_53_state?: Maybe<Scalars['smallint']>;
  session_54_state?: Maybe<Scalars['smallint']>;
  session_55_state?: Maybe<Scalars['smallint']>;
  session_56_state?: Maybe<Scalars['smallint']>;
  session_57_state?: Maybe<Scalars['smallint']>;
  session_58_state?: Maybe<Scalars['smallint']>;
  session_59_state?: Maybe<Scalars['smallint']>;
  session_60_state?: Maybe<Scalars['smallint']>;
  session_to_pay?: Maybe<Scalars['Int']>;
  subscription_expiration?: Maybe<Scalars['date']>;
  tried_other?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type User_Min_Order_By = {
  alt_email?: InputMaybe<Order_By>;
  alt_id?: InputMaybe<Order_By>;
  app_version?: InputMaybe<Order_By>;
  birthdate?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  current_journey_id?: InputMaybe<Order_By>;
  day_goal?: InputMaybe<Order_By>;
  desired_wake_up?: InputMaybe<Order_By>;
  document?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  night_goal?: InputMaybe<Order_By>;
  paid_value?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  points?: InputMaybe<Order_By>;
  profile_picture?: InputMaybe<Order_By>;
  psqi_state?: InputMaybe<Order_By>;
  session_1_state?: InputMaybe<Order_By>;
  session_2_state?: InputMaybe<Order_By>;
  session_3_state?: InputMaybe<Order_By>;
  session_4_state?: InputMaybe<Order_By>;
  session_5_state?: InputMaybe<Order_By>;
  session_6_state?: InputMaybe<Order_By>;
  session_7_state?: InputMaybe<Order_By>;
  session_8_state?: InputMaybe<Order_By>;
  session_9_state?: InputMaybe<Order_By>;
  session_10_state?: InputMaybe<Order_By>;
  session_11_state?: InputMaybe<Order_By>;
  session_12_state?: InputMaybe<Order_By>;
  session_13_state?: InputMaybe<Order_By>;
  session_14_state?: InputMaybe<Order_By>;
  session_15_state?: InputMaybe<Order_By>;
  session_16_state?: InputMaybe<Order_By>;
  session_17_state?: InputMaybe<Order_By>;
  session_18_state?: InputMaybe<Order_By>;
  session_19_state?: InputMaybe<Order_By>;
  session_20_state?: InputMaybe<Order_By>;
  session_21_state?: InputMaybe<Order_By>;
  session_22_state?: InputMaybe<Order_By>;
  session_23_state?: InputMaybe<Order_By>;
  session_24_state?: InputMaybe<Order_By>;
  session_25_state?: InputMaybe<Order_By>;
  session_26_state?: InputMaybe<Order_By>;
  session_27_state?: InputMaybe<Order_By>;
  session_28_state?: InputMaybe<Order_By>;
  session_29_state?: InputMaybe<Order_By>;
  session_30_state?: InputMaybe<Order_By>;
  session_31_state?: InputMaybe<Order_By>;
  session_32_state?: InputMaybe<Order_By>;
  session_33_state?: InputMaybe<Order_By>;
  session_34_state?: InputMaybe<Order_By>;
  session_35_state?: InputMaybe<Order_By>;
  session_36_state?: InputMaybe<Order_By>;
  session_37_state?: InputMaybe<Order_By>;
  session_38_state?: InputMaybe<Order_By>;
  session_39_state?: InputMaybe<Order_By>;
  session_40_state?: InputMaybe<Order_By>;
  session_41_state?: InputMaybe<Order_By>;
  session_42_state?: InputMaybe<Order_By>;
  session_43_state?: InputMaybe<Order_By>;
  session_44_state?: InputMaybe<Order_By>;
  session_45_state?: InputMaybe<Order_By>;
  session_46_state?: InputMaybe<Order_By>;
  session_47_state?: InputMaybe<Order_By>;
  session_48_state?: InputMaybe<Order_By>;
  session_49_state?: InputMaybe<Order_By>;
  session_50_state?: InputMaybe<Order_By>;
  session_51_state?: InputMaybe<Order_By>;
  session_52_state?: InputMaybe<Order_By>;
  session_53_state?: InputMaybe<Order_By>;
  session_54_state?: InputMaybe<Order_By>;
  session_55_state?: InputMaybe<Order_By>;
  session_56_state?: InputMaybe<Order_By>;
  session_57_state?: InputMaybe<Order_By>;
  session_58_state?: InputMaybe<Order_By>;
  session_59_state?: InputMaybe<Order_By>;
  session_60_state?: InputMaybe<Order_By>;
  session_to_pay?: InputMaybe<Order_By>;
  subscription_expiration?: InputMaybe<Order_By>;
  tried_other?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type User_Mutation_Response = {
  __typename?: 'user_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<User>;
};

export type User_Notification = {
  __typename?: 'user_notification';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  message: Scalars['String'];
  sender: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['String'];
};

export type User_Notification_Aggregate = {
  __typename?: 'user_notification_aggregate';
  aggregate?: Maybe<User_Notification_Aggregate_Fields>;
  nodes: Array<User_Notification>;
};

export type User_Notification_Aggregate_Fields = {
  __typename?: 'user_notification_aggregate_fields';
  avg?: Maybe<User_Notification_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Notification_Max_Fields>;
  min?: Maybe<User_Notification_Min_Fields>;
  stddev?: Maybe<User_Notification_Stddev_Fields>;
  stddev_pop?: Maybe<User_Notification_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Notification_Stddev_Samp_Fields>;
  sum?: Maybe<User_Notification_Sum_Fields>;
  var_pop?: Maybe<User_Notification_Var_Pop_Fields>;
  var_samp?: Maybe<User_Notification_Var_Samp_Fields>;
  variance?: Maybe<User_Notification_Variance_Fields>;
};

export type User_Notification_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Notification_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type User_Notification_Aggregate_Order_By = {
  avg?: InputMaybe<User_Notification_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Notification_Max_Order_By>;
  min?: InputMaybe<User_Notification_Min_Order_By>;
  stddev?: InputMaybe<User_Notification_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Notification_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Notification_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Notification_Sum_Order_By>;
  var_pop?: InputMaybe<User_Notification_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Notification_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Notification_Variance_Order_By>;
};

export type User_Notification_Arr_Rel_Insert_Input = {
  data: Array<User_Notification_Insert_Input>;
  on_conflict?: InputMaybe<User_Notification_On_Conflict>;
};

export type User_Notification_Avg_Fields = {
  __typename?: 'user_notification_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

export type User_Notification_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type User_Notification_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<User_Notification_Bool_Exp>>>;
  _not?: InputMaybe<User_Notification_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<User_Notification_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  sender?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

export enum User_Notification_Constraint {
  UserNotificationPkey = 'user_notification_pkey',
}

export type User_Notification_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

export type User_Notification_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  message?: InputMaybe<Scalars['String']>;
  sender?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type User_Notification_Max_Fields = {
  __typename?: 'user_notification_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  sender?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type User_Notification_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  sender?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type User_Notification_Min_Fields = {
  __typename?: 'user_notification_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  sender?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type User_Notification_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  sender?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type User_Notification_Mutation_Response = {
  __typename?: 'user_notification_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<User_Notification>;
};

export type User_Notification_Obj_Rel_Insert_Input = {
  data: User_Notification_Insert_Input;
  on_conflict?: InputMaybe<User_Notification_On_Conflict>;
};

export type User_Notification_On_Conflict = {
  constraint: User_Notification_Constraint;
  update_columns: Array<User_Notification_Update_Column>;
  where?: InputMaybe<User_Notification_Bool_Exp>;
};

export type User_Notification_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  sender?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type User_Notification_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export enum User_Notification_Select_Column {
  CreatedAt = 'created_at',
  Id = 'id',
  Message = 'message',
  Sender = 'sender',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type User_Notification_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  message?: InputMaybe<Scalars['String']>;
  sender?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type User_Notification_Stddev_Fields = {
  __typename?: 'user_notification_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

export type User_Notification_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type User_Notification_Stddev_Pop_Fields = {
  __typename?: 'user_notification_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type User_Notification_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type User_Notification_Stddev_Samp_Fields = {
  __typename?: 'user_notification_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type User_Notification_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type User_Notification_Sum_Fields = {
  __typename?: 'user_notification_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

export type User_Notification_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

export enum User_Notification_Update_Column {
  CreatedAt = 'created_at',
  Id = 'id',
  Message = 'message',
  Sender = 'sender',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type User_Notification_Var_Pop_Fields = {
  __typename?: 'user_notification_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type User_Notification_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type User_Notification_Var_Samp_Fields = {
  __typename?: 'user_notification_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type User_Notification_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type User_Notification_Variance_Fields = {
  __typename?: 'user_notification_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type User_Notification_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input;
  on_conflict?: InputMaybe<User_On_Conflict>;
};

export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns: Array<User_Update_Column>;
  where?: InputMaybe<User_Bool_Exp>;
};

export type User_Order_By = {
  alcohol?: InputMaybe<Order_By>;
  alt_email?: InputMaybe<Order_By>;
  alt_id?: InputMaybe<Order_By>;
  apnea_questionnaires_aggregate?: InputMaybe<Apnea_Questionnaire_Aggregate_Order_By>;
  app_version?: InputMaybe<Order_By>;
  appointments_aggregate?: InputMaybe<Appointment_Aggregate_Order_By>;
  autogenic_training?: InputMaybe<Order_By>;
  bathroom?: InputMaybe<Order_By>;
  behavior_activation?: InputMaybe<Order_By>;
  birthdate?: InputMaybe<Order_By>;
  caffeine?: InputMaybe<Order_By>;
  can_send_email_reminder?: InputMaybe<Order_By>;
  can_send_whatsapp_reminder?: InputMaybe<Order_By>;
  catastrophic_thinkings_aggregate?: InputMaybe<Catastrophic_Thinkings_Aggregate_Order_By>;
  challenge_catastrophic_thinking?: InputMaybe<Order_By>;
  checkout_aggregate?: InputMaybe<User_Checkout_Aggregate_Order_By>;
  comment?: InputMaybe<Order_By>;
  companies_aggregate?: InputMaybe<Company_User_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  current_journey_id?: InputMaybe<Order_By>;
  day_goal?: InputMaybe<Order_By>;
  deep_breath?: InputMaybe<Order_By>;
  desired_wake_up?: InputMaybe<Order_By>;
  device_os?: InputMaybe<Order_By>;
  document?: InputMaybe<Order_By>;
  dream?: InputMaybe<Order_By>;
  dreams_aggregate?: InputMaybe<Dream_Aggregate_Order_By>;
  eficiency_aggregate?: InputMaybe<Eficiency_Aggregate_Order_By>;
  email?: InputMaybe<Order_By>;
  enabled?: InputMaybe<Order_By>;
  epworths_aggregate?: InputMaybe<Epworth_Aggregate_Order_By>;
  exercise?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  gad7s_aggregate?: InputMaybe<Gad7_Aggregate_Order_By>;
  gad_aggregate?: InputMaybe<Gad_Aggregate_Order_By>;
  gender?: InputMaybe<Order_By>;
  generic_questionnaires_aggregate?: InputMaybe<Generic_Questionnaire_Aggregate_Order_By>;
  google_sheet_rows_aggregate?: InputMaybe<Google_Sheet_Row_Aggregate_Order_By>;
  gratitude?: InputMaybe<Order_By>;
  gratitudes_aggregate?: InputMaybe<Gratitude_Aggregate_Order_By>;
  health_carers_aggregate?: InputMaybe<Health_Carer_User_Aggregate_Order_By>;
  heavy_meal?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imagery?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  isi_aggregate?: InputMaybe<Isi_Aggregate_Order_By>;
  key_values_aggregate?: InputMaybe<User_Key_Value_Aggregate_Order_By>;
  kuppermans_aggregate?: InputMaybe<Kupperman_Aggregate_Order_By>;
  last_name?: InputMaybe<Order_By>;
  light?: InputMaybe<Order_By>;
  light_therapy?: InputMaybe<Order_By>;
  logs_aggregate?: InputMaybe<Log_Aggregate_Order_By>;
  meditation?: InputMaybe<Order_By>;
  menopause_greene_scales_aggregate?: InputMaybe<Menopause_Greene_Scale_Aggregate_Order_By>;
  munich_chronotype_cores_aggregate?: InputMaybe<Munich_Chronotype_Core_Aggregate_Order_By>;
  munich_chronotypes_aggregate?: InputMaybe<Munich_Chronotype_Aggregate_Order_By>;
  nap?: InputMaybe<Order_By>;
  nicotine?: InputMaybe<Order_By>;
  night_goal?: InputMaybe<Order_By>;
  noise?: InputMaybe<Order_By>;
  paid?: InputMaybe<Order_By>;
  paid_value?: InputMaybe<Order_By>;
  pain?: InputMaybe<Order_By>;
  paradoxical_intention?: InputMaybe<Order_By>;
  parking_lot?: InputMaybe<Order_By>;
  parking_lots_aggregate?: InputMaybe<Parking_Lot_Aggregate_Order_By>;
  partner?: InputMaybe<Order_By>;
  personality_questionnaires_aggregate?: InputMaybe<Personality_Questionnaire_Aggregate_Order_By>;
  phone?: InputMaybe<Order_By>;
  phq9s_aggregate?: InputMaybe<Phq9_Aggregate_Order_By>;
  phq_aggregate?: InputMaybe<Phq_Aggregate_Order_By>;
  pmr?: InputMaybe<Order_By>;
  points?: InputMaybe<Order_By>;
  pre_users_aggregate?: InputMaybe<Pre_User_Aggregate_Order_By>;
  productivity_aggregate?: InputMaybe<Productivity_Aggregate_Order_By>;
  profile_picture?: InputMaybe<Order_By>;
  psqi_aggregate?: InputMaybe<Psqi_Aggregate_Order_By>;
  psqi_state?: InputMaybe<Order_By>;
  ratings_aggregate?: InputMaybe<Rating_Aggregate_Order_By>;
  reminder_info?: InputMaybe<User_Reminder_Info_Order_By>;
  session_1_state?: InputMaybe<Order_By>;
  session_2_state?: InputMaybe<Order_By>;
  session_3_state?: InputMaybe<Order_By>;
  session_4_state?: InputMaybe<Order_By>;
  session_5_state?: InputMaybe<Order_By>;
  session_6_state?: InputMaybe<Order_By>;
  session_7_state?: InputMaybe<Order_By>;
  session_8_state?: InputMaybe<Order_By>;
  session_9_state?: InputMaybe<Order_By>;
  session_10_state?: InputMaybe<Order_By>;
  session_11_state?: InputMaybe<Order_By>;
  session_12_state?: InputMaybe<Order_By>;
  session_13_state?: InputMaybe<Order_By>;
  session_14_state?: InputMaybe<Order_By>;
  session_15_state?: InputMaybe<Order_By>;
  session_16_state?: InputMaybe<Order_By>;
  session_17_state?: InputMaybe<Order_By>;
  session_18_state?: InputMaybe<Order_By>;
  session_19_state?: InputMaybe<Order_By>;
  session_20_state?: InputMaybe<Order_By>;
  session_21_state?: InputMaybe<Order_By>;
  session_22_state?: InputMaybe<Order_By>;
  session_23_state?: InputMaybe<Order_By>;
  session_24_state?: InputMaybe<Order_By>;
  session_25_state?: InputMaybe<Order_By>;
  session_26_state?: InputMaybe<Order_By>;
  session_27_state?: InputMaybe<Order_By>;
  session_28_state?: InputMaybe<Order_By>;
  session_29_state?: InputMaybe<Order_By>;
  session_30_state?: InputMaybe<Order_By>;
  session_31_state?: InputMaybe<Order_By>;
  session_32_state?: InputMaybe<Order_By>;
  session_33_state?: InputMaybe<Order_By>;
  session_34_state?: InputMaybe<Order_By>;
  session_35_state?: InputMaybe<Order_By>;
  session_36_state?: InputMaybe<Order_By>;
  session_37_state?: InputMaybe<Order_By>;
  session_38_state?: InputMaybe<Order_By>;
  session_39_state?: InputMaybe<Order_By>;
  session_40_state?: InputMaybe<Order_By>;
  session_41_state?: InputMaybe<Order_By>;
  session_42_state?: InputMaybe<Order_By>;
  session_43_state?: InputMaybe<Order_By>;
  session_44_state?: InputMaybe<Order_By>;
  session_45_state?: InputMaybe<Order_By>;
  session_46_state?: InputMaybe<Order_By>;
  session_47_state?: InputMaybe<Order_By>;
  session_48_state?: InputMaybe<Order_By>;
  session_49_state?: InputMaybe<Order_By>;
  session_50_state?: InputMaybe<Order_By>;
  session_51_state?: InputMaybe<Order_By>;
  session_52_state?: InputMaybe<Order_By>;
  session_53_state?: InputMaybe<Order_By>;
  session_54_state?: InputMaybe<Order_By>;
  session_55_state?: InputMaybe<Order_By>;
  session_56_state?: InputMaybe<Order_By>;
  session_57_state?: InputMaybe<Order_By>;
  session_58_state?: InputMaybe<Order_By>;
  session_59_state?: InputMaybe<Order_By>;
  session_60_state?: InputMaybe<Order_By>;
  session_to_pay?: InputMaybe<Order_By>;
  sleep_diaries_aggregate?: InputMaybe<Sleep_Diary_Aggregate_Order_By>;
  sleep_diary_apple_healths_aggregate?: InputMaybe<Sleep_Diary_Apple_Health_Aggregate_Order_By>;
  sleep_diary_google_fits_aggregate?: InputMaybe<Sleep_Diary_Google_Fit_Aggregate_Order_By>;
  sleep_diary_intervals_aggregate?: InputMaybe<Sleep_Diary_Interval_Aggregate_Order_By>;
  sleep_medicine?: InputMaybe<Order_By>;
  sleep_restriction?: InputMaybe<Order_By>;
  sleep_restrictions_aggregate?: InputMaybe<Sleep_Restriction_Aggregate_Order_By>;
  sleep_tracker_compressed_data_aggregate?: InputMaybe<Sleep_Tracker_Compressed_Data_Aggregate_Order_By>;
  stimulus_control?: InputMaybe<Order_By>;
  stop_bangs_aggregate?: InputMaybe<Stop_Bang_Aggregate_Order_By>;
  subscription_expiration?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
  thought_block?: InputMaybe<Order_By>;
  toolbox?: InputMaybe<Toolbox_Order_By>;
  tried_alcohol?: InputMaybe<Order_By>;
  tried_medicine?: InputMaybe<Order_By>;
  tried_melatonin?: InputMaybe<Order_By>;
  tried_other?: InputMaybe<Order_By>;
  tried_restriction?: InputMaybe<Order_By>;
  tried_sleep_hygiene?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_coupons_aggregate?: InputMaybe<User_Coupon_Aggregate_Order_By>;
  user_journeys_aggregate?: InputMaybe<User_Journey_Aggregate_Order_By>;
  user_notifications_aggregate?: InputMaybe<User_Notification_Aggregate_Order_By>;
  user_program_sessions_aggregate?: InputMaybe<User_Program_Session_Aggregate_Order_By>;
  user_tags_aggregate?: InputMaybe<User_Tag_Aggregate_Order_By>;
  user_telehealth?: InputMaybe<User_Telehealth_Order_By>;
};

export type User_Pk_Columns_Input = {
  id: Scalars['String'];
};

export type User_Program_Session = {
  __typename?: 'user_program_session';
  created_at: Scalars['timestamptz'];
  program_session: Program_Session;
  session_id: Scalars['Int'];
  state: User_Program_Session_Enum_Enum;
  updated_at: Scalars['timestamptz'];
  user: User;
  user_id: Scalars['String'];
};

export type User_Program_Session_Aggregate = {
  __typename?: 'user_program_session_aggregate';
  aggregate?: Maybe<User_Program_Session_Aggregate_Fields>;
  nodes: Array<User_Program_Session>;
};

export type User_Program_Session_Aggregate_Fields = {
  __typename?: 'user_program_session_aggregate_fields';
  avg?: Maybe<User_Program_Session_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Program_Session_Max_Fields>;
  min?: Maybe<User_Program_Session_Min_Fields>;
  stddev?: Maybe<User_Program_Session_Stddev_Fields>;
  stddev_pop?: Maybe<User_Program_Session_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Program_Session_Stddev_Samp_Fields>;
  sum?: Maybe<User_Program_Session_Sum_Fields>;
  var_pop?: Maybe<User_Program_Session_Var_Pop_Fields>;
  var_samp?: Maybe<User_Program_Session_Var_Samp_Fields>;
  variance?: Maybe<User_Program_Session_Variance_Fields>;
};

export type User_Program_Session_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Program_Session_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type User_Program_Session_Aggregate_Order_By = {
  avg?: InputMaybe<User_Program_Session_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Program_Session_Max_Order_By>;
  min?: InputMaybe<User_Program_Session_Min_Order_By>;
  stddev?: InputMaybe<User_Program_Session_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Program_Session_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Program_Session_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Program_Session_Sum_Order_By>;
  var_pop?: InputMaybe<User_Program_Session_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Program_Session_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Program_Session_Variance_Order_By>;
};

export type User_Program_Session_Arr_Rel_Insert_Input = {
  data: Array<User_Program_Session_Insert_Input>;
  on_conflict?: InputMaybe<User_Program_Session_On_Conflict>;
};

export type User_Program_Session_Avg_Fields = {
  __typename?: 'user_program_session_avg_fields';
  session_id?: Maybe<Scalars['Float']>;
};

export type User_Program_Session_Avg_Order_By = {
  session_id?: InputMaybe<Order_By>;
};

export type User_Program_Session_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<User_Program_Session_Bool_Exp>>>;
  _not?: InputMaybe<User_Program_Session_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<User_Program_Session_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  program_session?: InputMaybe<Program_Session_Bool_Exp>;
  session_id?: InputMaybe<Int_Comparison_Exp>;
  state?: InputMaybe<User_Program_Session_Enum_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

export enum User_Program_Session_Constraint {
  UserProgramSessionPkey = 'user_program_session_pkey',
}

export type User_Program_Session_Enum = {
  __typename?: 'user_program_session_enum';
  comment: Scalars['String'];
  text: Scalars['String'];
};

export type User_Program_Session_Enum_Aggregate = {
  __typename?: 'user_program_session_enum_aggregate';
  aggregate?: Maybe<User_Program_Session_Enum_Aggregate_Fields>;
  nodes: Array<User_Program_Session_Enum>;
};

export type User_Program_Session_Enum_Aggregate_Fields = {
  __typename?: 'user_program_session_enum_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Program_Session_Enum_Max_Fields>;
  min?: Maybe<User_Program_Session_Enum_Min_Fields>;
};

export type User_Program_Session_Enum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Program_Session_Enum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type User_Program_Session_Enum_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Program_Session_Enum_Max_Order_By>;
  min?: InputMaybe<User_Program_Session_Enum_Min_Order_By>;
};

export type User_Program_Session_Enum_Arr_Rel_Insert_Input = {
  data: Array<User_Program_Session_Enum_Insert_Input>;
  on_conflict?: InputMaybe<User_Program_Session_Enum_On_Conflict>;
};

export type User_Program_Session_Enum_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<User_Program_Session_Enum_Bool_Exp>>>;
  _not?: InputMaybe<User_Program_Session_Enum_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<User_Program_Session_Enum_Bool_Exp>>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
};

export enum User_Program_Session_Enum_Constraint {
  UserProgramSessionEnumPkey = 'user_program_session_enum_pkey',
}

export enum User_Program_Session_Enum_Enum {
  Finished = 'FINISHED',
  NotStarted = 'NOT_STARTED',
  Seen = 'SEEN',
  Started = 'STARTED',
}

export type User_Program_Session_Enum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<User_Program_Session_Enum_Enum>;
  _in?: InputMaybe<Array<User_Program_Session_Enum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<User_Program_Session_Enum_Enum>;
  _nin?: InputMaybe<Array<User_Program_Session_Enum_Enum>>;
};

export type User_Program_Session_Enum_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type User_Program_Session_Enum_Max_Fields = {
  __typename?: 'user_program_session_enum_max_fields';
  comment?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type User_Program_Session_Enum_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type User_Program_Session_Enum_Min_Fields = {
  __typename?: 'user_program_session_enum_min_fields';
  comment?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type User_Program_Session_Enum_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type User_Program_Session_Enum_Mutation_Response = {
  __typename?: 'user_program_session_enum_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<User_Program_Session_Enum>;
};

export type User_Program_Session_Enum_Obj_Rel_Insert_Input = {
  data: User_Program_Session_Enum_Insert_Input;
  on_conflict?: InputMaybe<User_Program_Session_Enum_On_Conflict>;
};

export type User_Program_Session_Enum_On_Conflict = {
  constraint: User_Program_Session_Enum_Constraint;
  update_columns: Array<User_Program_Session_Enum_Update_Column>;
  where?: InputMaybe<User_Program_Session_Enum_Bool_Exp>;
};

export type User_Program_Session_Enum_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type User_Program_Session_Enum_Pk_Columns_Input = {
  text: Scalars['String'];
};

export enum User_Program_Session_Enum_Select_Column {
  Comment = 'comment',
  Text = 'text',
}

export type User_Program_Session_Enum_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export enum User_Program_Session_Enum_Update_Column {
  Comment = 'comment',
  Text = 'text',
}

export type User_Program_Session_Inc_Input = {
  session_id?: InputMaybe<Scalars['Int']>;
};

export type User_Program_Session_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  program_session?: InputMaybe<Program_Session_Obj_Rel_Insert_Input>;
  session_id?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<User_Program_Session_Enum_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type User_Program_Session_Max_Fields = {
  __typename?: 'user_program_session_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  session_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type User_Program_Session_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type User_Program_Session_Min_Fields = {
  __typename?: 'user_program_session_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  session_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type User_Program_Session_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type User_Program_Session_Mutation_Response = {
  __typename?: 'user_program_session_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<User_Program_Session>;
};

export type User_Program_Session_Obj_Rel_Insert_Input = {
  data: User_Program_Session_Insert_Input;
  on_conflict?: InputMaybe<User_Program_Session_On_Conflict>;
};

export type User_Program_Session_On_Conflict = {
  constraint: User_Program_Session_Constraint;
  update_columns: Array<User_Program_Session_Update_Column>;
  where?: InputMaybe<User_Program_Session_Bool_Exp>;
};

export type User_Program_Session_Order_By = {
  created_at?: InputMaybe<Order_By>;
  program_session?: InputMaybe<Program_Session_Order_By>;
  session_id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type User_Program_Session_Pk_Columns_Input = {
  session_id: Scalars['Int'];
  user_id: Scalars['String'];
};

export enum User_Program_Session_Select_Column {
  CreatedAt = 'created_at',
  SessionId = 'session_id',
  State = 'state',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type User_Program_Session_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  session_id?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<User_Program_Session_Enum_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type User_Program_Session_Stddev_Fields = {
  __typename?: 'user_program_session_stddev_fields';
  session_id?: Maybe<Scalars['Float']>;
};

export type User_Program_Session_Stddev_Order_By = {
  session_id?: InputMaybe<Order_By>;
};

export type User_Program_Session_Stddev_Pop_Fields = {
  __typename?: 'user_program_session_stddev_pop_fields';
  session_id?: Maybe<Scalars['Float']>;
};

export type User_Program_Session_Stddev_Pop_Order_By = {
  session_id?: InputMaybe<Order_By>;
};

export type User_Program_Session_Stddev_Samp_Fields = {
  __typename?: 'user_program_session_stddev_samp_fields';
  session_id?: Maybe<Scalars['Float']>;
};

export type User_Program_Session_Stddev_Samp_Order_By = {
  session_id?: InputMaybe<Order_By>;
};

export type User_Program_Session_Sum_Fields = {
  __typename?: 'user_program_session_sum_fields';
  session_id?: Maybe<Scalars['Int']>;
};

export type User_Program_Session_Sum_Order_By = {
  session_id?: InputMaybe<Order_By>;
};

export enum User_Program_Session_Update_Column {
  CreatedAt = 'created_at',
  SessionId = 'session_id',
  State = 'state',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type User_Program_Session_Var_Pop_Fields = {
  __typename?: 'user_program_session_var_pop_fields';
  session_id?: Maybe<Scalars['Float']>;
};

export type User_Program_Session_Var_Pop_Order_By = {
  session_id?: InputMaybe<Order_By>;
};

export type User_Program_Session_Var_Samp_Fields = {
  __typename?: 'user_program_session_var_samp_fields';
  session_id?: Maybe<Scalars['Float']>;
};

export type User_Program_Session_Var_Samp_Order_By = {
  session_id?: InputMaybe<Order_By>;
};

export type User_Program_Session_Variance_Fields = {
  __typename?: 'user_program_session_variance_fields';
  session_id?: Maybe<Scalars['Float']>;
};

export type User_Program_Session_Variance_Order_By = {
  session_id?: InputMaybe<Order_By>;
};

export type User_Reminder_Info = {
  __typename?: 'user_reminder_info';
  created_at?: Maybe<Scalars['timestamptz']>;
  messenger_id: Scalars['String'];
  push_token?: Maybe<Scalars['_text']>;
  reminders: Array<Reminder>;
  reminders_aggregate: Reminder_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type User_Reminder_InfoRemindersArgs = {
  distinct_on?: InputMaybe<Array<Reminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reminder_Order_By>>;
  where?: InputMaybe<Reminder_Bool_Exp>;
};

export type User_Reminder_InfoReminders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reminder_Order_By>>;
  where?: InputMaybe<Reminder_Bool_Exp>;
};

export type User_Reminder_Info_Aggregate = {
  __typename?: 'user_reminder_info_aggregate';
  aggregate?: Maybe<User_Reminder_Info_Aggregate_Fields>;
  nodes: Array<User_Reminder_Info>;
};

export type User_Reminder_Info_Aggregate_Fields = {
  __typename?: 'user_reminder_info_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Reminder_Info_Max_Fields>;
  min?: Maybe<User_Reminder_Info_Min_Fields>;
};

export type User_Reminder_Info_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Reminder_Info_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type User_Reminder_Info_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Reminder_Info_Max_Order_By>;
  min?: InputMaybe<User_Reminder_Info_Min_Order_By>;
};

export type User_Reminder_Info_Arr_Rel_Insert_Input = {
  data: Array<User_Reminder_Info_Insert_Input>;
  on_conflict?: InputMaybe<User_Reminder_Info_On_Conflict>;
};

export type User_Reminder_Info_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<User_Reminder_Info_Bool_Exp>>>;
  _not?: InputMaybe<User_Reminder_Info_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<User_Reminder_Info_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  messenger_id?: InputMaybe<String_Comparison_Exp>;
  push_token?: InputMaybe<_Text_Comparison_Exp>;
  reminders?: InputMaybe<Reminder_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

export enum User_Reminder_Info_Constraint {
  UserReminderInfoPkey = 'user_reminder_info_pkey',
}

export type User_Reminder_Info_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  messenger_id?: InputMaybe<Scalars['String']>;
  push_token?: InputMaybe<Scalars['_text']>;
  reminders?: InputMaybe<Reminder_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type User_Reminder_Info_Max_Fields = {
  __typename?: 'user_reminder_info_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  messenger_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type User_Reminder_Info_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  messenger_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type User_Reminder_Info_Min_Fields = {
  __typename?: 'user_reminder_info_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  messenger_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type User_Reminder_Info_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  messenger_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type User_Reminder_Info_Mutation_Response = {
  __typename?: 'user_reminder_info_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<User_Reminder_Info>;
};

export type User_Reminder_Info_Obj_Rel_Insert_Input = {
  data: User_Reminder_Info_Insert_Input;
  on_conflict?: InputMaybe<User_Reminder_Info_On_Conflict>;
};

export type User_Reminder_Info_On_Conflict = {
  constraint: User_Reminder_Info_Constraint;
  update_columns: Array<User_Reminder_Info_Update_Column>;
  where?: InputMaybe<User_Reminder_Info_Bool_Exp>;
};

export type User_Reminder_Info_Order_By = {
  created_at?: InputMaybe<Order_By>;
  messenger_id?: InputMaybe<Order_By>;
  push_token?: InputMaybe<Order_By>;
  reminders_aggregate?: InputMaybe<Reminder_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type User_Reminder_Info_Pk_Columns_Input = {
  messenger_id: Scalars['String'];
};

export enum User_Reminder_Info_Select_Column {
  CreatedAt = 'created_at',
  MessengerId = 'messenger_id',
  PushToken = 'push_token',
  UpdatedAt = 'updated_at',
}

export type User_Reminder_Info_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  messenger_id?: InputMaybe<Scalars['String']>;
  push_token?: InputMaybe<Scalars['_text']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export enum User_Reminder_Info_Update_Column {
  CreatedAt = 'created_at',
  MessengerId = 'messenger_id',
  PushToken = 'push_token',
  UpdatedAt = 'updated_at',
}

export enum User_Select_Column {
  Alcohol = 'alcohol',
  AltEmail = 'alt_email',
  AltId = 'alt_id',
  AppVersion = 'app_version',
  AutogenicTraining = 'autogenic_training',
  Bathroom = 'bathroom',
  BehaviorActivation = 'behavior_activation',
  Birthdate = 'birthdate',
  Caffeine = 'caffeine',
  CanSendEmailReminder = 'can_send_email_reminder',
  CanSendWhatsappReminder = 'can_send_whatsapp_reminder',
  ChallengeCatastrophicThinking = 'challenge_catastrophic_thinking',
  Comment = 'comment',
  CreatedAt = 'created_at',
  CurrentJourneyId = 'current_journey_id',
  DayGoal = 'day_goal',
  DeepBreath = 'deep_breath',
  DesiredWakeUp = 'desired_wake_up',
  DeviceOs = 'device_os',
  Document = 'document',
  Dream = 'dream',
  Email = 'email',
  Enabled = 'enabled',
  Exercise = 'exercise',
  FirstName = 'first_name',
  Gender = 'gender',
  Gratitude = 'gratitude',
  HeavyMeal = 'heavy_meal',
  Id = 'id',
  Imagery = 'imagery',
  InternalId = 'internal_id',
  LastName = 'last_name',
  Light = 'light',
  LightTherapy = 'light_therapy',
  Meditation = 'meditation',
  Nap = 'nap',
  Nicotine = 'nicotine',
  NightGoal = 'night_goal',
  Noise = 'noise',
  Paid = 'paid',
  PaidValue = 'paid_value',
  Pain = 'pain',
  ParadoxicalIntention = 'paradoxical_intention',
  ParkingLot = 'parking_lot',
  Partner = 'partner',
  Phone = 'phone',
  Pmr = 'pmr',
  Points = 'points',
  ProfilePicture = 'profile_picture',
  PsqiState = 'psqi_state',
  Session_1State = 'session_1_state',
  Session_2State = 'session_2_state',
  Session_3State = 'session_3_state',
  Session_4State = 'session_4_state',
  Session_5State = 'session_5_state',
  Session_6State = 'session_6_state',
  Session_7State = 'session_7_state',
  Session_8State = 'session_8_state',
  Session_9State = 'session_9_state',
  Session_10State = 'session_10_state',
  Session_11State = 'session_11_state',
  Session_12State = 'session_12_state',
  Session_13State = 'session_13_state',
  Session_14State = 'session_14_state',
  Session_15State = 'session_15_state',
  Session_16State = 'session_16_state',
  Session_17State = 'session_17_state',
  Session_18State = 'session_18_state',
  Session_19State = 'session_19_state',
  Session_20State = 'session_20_state',
  Session_21State = 'session_21_state',
  Session_22State = 'session_22_state',
  Session_23State = 'session_23_state',
  Session_24State = 'session_24_state',
  Session_25State = 'session_25_state',
  Session_26State = 'session_26_state',
  Session_27State = 'session_27_state',
  Session_28State = 'session_28_state',
  Session_29State = 'session_29_state',
  Session_30State = 'session_30_state',
  Session_31State = 'session_31_state',
  Session_32State = 'session_32_state',
  Session_33State = 'session_33_state',
  Session_34State = 'session_34_state',
  Session_35State = 'session_35_state',
  Session_36State = 'session_36_state',
  Session_37State = 'session_37_state',
  Session_38State = 'session_38_state',
  Session_39State = 'session_39_state',
  Session_40State = 'session_40_state',
  Session_41State = 'session_41_state',
  Session_42State = 'session_42_state',
  Session_43State = 'session_43_state',
  Session_44State = 'session_44_state',
  Session_45State = 'session_45_state',
  Session_46State = 'session_46_state',
  Session_47State = 'session_47_state',
  Session_48State = 'session_48_state',
  Session_49State = 'session_49_state',
  Session_50State = 'session_50_state',
  Session_51State = 'session_51_state',
  Session_52State = 'session_52_state',
  Session_53State = 'session_53_state',
  Session_54State = 'session_54_state',
  Session_55State = 'session_55_state',
  Session_56State = 'session_56_state',
  Session_57State = 'session_57_state',
  Session_58State = 'session_58_state',
  Session_59State = 'session_59_state',
  Session_60State = 'session_60_state',
  SessionToPay = 'session_to_pay',
  SleepMedicine = 'sleep_medicine',
  SleepRestriction = 'sleep_restriction',
  StimulusControl = 'stimulus_control',
  SubscriptionExpiration = 'subscription_expiration',
  Temperature = 'temperature',
  ThoughtBlock = 'thought_block',
  TriedAlcohol = 'tried_alcohol',
  TriedMedicine = 'tried_medicine',
  TriedMelatonin = 'tried_melatonin',
  TriedOther = 'tried_other',
  TriedRestriction = 'tried_restriction',
  TriedSleepHygiene = 'tried_sleep_hygiene',
  UpdatedAt = 'updated_at',
}

export type User_Set_Input = {
  alcohol?: InputMaybe<Scalars['Boolean']>;
  alt_email?: InputMaybe<Scalars['String']>;
  alt_id?: InputMaybe<Scalars['String']>;
  app_version?: InputMaybe<Scalars['String']>;
  autogenic_training?: InputMaybe<Scalars['Boolean']>;
  bathroom?: InputMaybe<Scalars['Boolean']>;
  behavior_activation?: InputMaybe<Scalars['Boolean']>;
  birthdate?: InputMaybe<Scalars['date']>;
  caffeine?: InputMaybe<Scalars['Boolean']>;
  can_send_email_reminder?: InputMaybe<Scalars['Boolean']>;
  can_send_whatsapp_reminder?: InputMaybe<Scalars['Boolean']>;
  challenge_catastrophic_thinking?: InputMaybe<Scalars['Boolean']>;
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  current_journey_id?: InputMaybe<Scalars['Int']>;
  day_goal?: InputMaybe<Scalars['smallint']>;
  deep_breath?: InputMaybe<Scalars['Boolean']>;
  desired_wake_up?: InputMaybe<Scalars['timetz']>;
  device_os?: InputMaybe<Os_Enum_Enum>;
  document?: InputMaybe<Scalars['String']>;
  dream?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  exercise?: InputMaybe<Scalars['Boolean']>;
  first_name?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  gratitude?: InputMaybe<Scalars['Boolean']>;
  heavy_meal?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  imagery?: InputMaybe<Scalars['Boolean']>;
  internal_id?: InputMaybe<Scalars['Int']>;
  last_name?: InputMaybe<Scalars['String']>;
  light?: InputMaybe<Scalars['Boolean']>;
  light_therapy?: InputMaybe<Scalars['Boolean']>;
  meditation?: InputMaybe<Scalars['Boolean']>;
  nap?: InputMaybe<Scalars['Boolean']>;
  nicotine?: InputMaybe<Scalars['Boolean']>;
  night_goal?: InputMaybe<Scalars['smallint']>;
  noise?: InputMaybe<Scalars['Boolean']>;
  paid?: InputMaybe<Scalars['Boolean']>;
  paid_value?: InputMaybe<Scalars['numeric']>;
  pain?: InputMaybe<Scalars['Boolean']>;
  paradoxical_intention?: InputMaybe<Scalars['Boolean']>;
  parking_lot?: InputMaybe<Scalars['Boolean']>;
  partner?: InputMaybe<Scalars['Boolean']>;
  phone?: InputMaybe<Scalars['String']>;
  pmr?: InputMaybe<Scalars['Boolean']>;
  points?: InputMaybe<Scalars['Int']>;
  profile_picture?: InputMaybe<Scalars['String']>;
  psqi_state?: InputMaybe<Scalars['smallint']>;
  session_1_state?: InputMaybe<Scalars['smallint']>;
  session_2_state?: InputMaybe<Scalars['smallint']>;
  session_3_state?: InputMaybe<Scalars['smallint']>;
  session_4_state?: InputMaybe<Scalars['smallint']>;
  session_5_state?: InputMaybe<Scalars['smallint']>;
  session_6_state?: InputMaybe<Scalars['smallint']>;
  session_7_state?: InputMaybe<Scalars['smallint']>;
  session_8_state?: InputMaybe<Scalars['smallint']>;
  session_9_state?: InputMaybe<Scalars['smallint']>;
  session_10_state?: InputMaybe<Scalars['smallint']>;
  session_11_state?: InputMaybe<Scalars['smallint']>;
  session_12_state?: InputMaybe<Scalars['smallint']>;
  session_13_state?: InputMaybe<Scalars['smallint']>;
  session_14_state?: InputMaybe<Scalars['smallint']>;
  session_15_state?: InputMaybe<Scalars['smallint']>;
  session_16_state?: InputMaybe<Scalars['smallint']>;
  session_17_state?: InputMaybe<Scalars['smallint']>;
  session_18_state?: InputMaybe<Scalars['smallint']>;
  session_19_state?: InputMaybe<Scalars['smallint']>;
  session_20_state?: InputMaybe<Scalars['smallint']>;
  session_21_state?: InputMaybe<Scalars['smallint']>;
  session_22_state?: InputMaybe<Scalars['smallint']>;
  session_23_state?: InputMaybe<Scalars['smallint']>;
  session_24_state?: InputMaybe<Scalars['smallint']>;
  session_25_state?: InputMaybe<Scalars['smallint']>;
  session_26_state?: InputMaybe<Scalars['smallint']>;
  session_27_state?: InputMaybe<Scalars['smallint']>;
  session_28_state?: InputMaybe<Scalars['smallint']>;
  session_29_state?: InputMaybe<Scalars['smallint']>;
  session_30_state?: InputMaybe<Scalars['smallint']>;
  session_31_state?: InputMaybe<Scalars['smallint']>;
  session_32_state?: InputMaybe<Scalars['smallint']>;
  session_33_state?: InputMaybe<Scalars['smallint']>;
  session_34_state?: InputMaybe<Scalars['smallint']>;
  session_35_state?: InputMaybe<Scalars['smallint']>;
  session_36_state?: InputMaybe<Scalars['smallint']>;
  session_37_state?: InputMaybe<Scalars['smallint']>;
  session_38_state?: InputMaybe<Scalars['smallint']>;
  session_39_state?: InputMaybe<Scalars['smallint']>;
  session_40_state?: InputMaybe<Scalars['smallint']>;
  session_41_state?: InputMaybe<Scalars['smallint']>;
  session_42_state?: InputMaybe<Scalars['smallint']>;
  session_43_state?: InputMaybe<Scalars['smallint']>;
  session_44_state?: InputMaybe<Scalars['smallint']>;
  session_45_state?: InputMaybe<Scalars['smallint']>;
  session_46_state?: InputMaybe<Scalars['smallint']>;
  session_47_state?: InputMaybe<Scalars['smallint']>;
  session_48_state?: InputMaybe<Scalars['smallint']>;
  session_49_state?: InputMaybe<Scalars['smallint']>;
  session_50_state?: InputMaybe<Scalars['smallint']>;
  session_51_state?: InputMaybe<Scalars['smallint']>;
  session_52_state?: InputMaybe<Scalars['smallint']>;
  session_53_state?: InputMaybe<Scalars['smallint']>;
  session_54_state?: InputMaybe<Scalars['smallint']>;
  session_55_state?: InputMaybe<Scalars['smallint']>;
  session_56_state?: InputMaybe<Scalars['smallint']>;
  session_57_state?: InputMaybe<Scalars['smallint']>;
  session_58_state?: InputMaybe<Scalars['smallint']>;
  session_59_state?: InputMaybe<Scalars['smallint']>;
  session_60_state?: InputMaybe<Scalars['smallint']>;
  session_to_pay?: InputMaybe<Scalars['Int']>;
  sleep_medicine?: InputMaybe<Scalars['Boolean']>;
  sleep_restriction?: InputMaybe<Scalars['Boolean']>;
  stimulus_control?: InputMaybe<Scalars['Boolean']>;
  subscription_expiration?: InputMaybe<Scalars['date']>;
  temperature?: InputMaybe<Scalars['Boolean']>;
  thought_block?: InputMaybe<Scalars['Boolean']>;
  tried_alcohol?: InputMaybe<Scalars['Boolean']>;
  tried_medicine?: InputMaybe<Scalars['Boolean']>;
  tried_melatonin?: InputMaybe<Scalars['Boolean']>;
  tried_other?: InputMaybe<Scalars['String']>;
  tried_restriction?: InputMaybe<Scalars['Boolean']>;
  tried_sleep_hygiene?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type User_Stddev_Fields = {
  __typename?: 'user_stddev_fields';
  current_journey_id?: Maybe<Scalars['Float']>;
  day_goal?: Maybe<Scalars['Float']>;
  internal_id?: Maybe<Scalars['Float']>;
  night_goal?: Maybe<Scalars['Float']>;
  paid_value?: Maybe<Scalars['Float']>;
  points?: Maybe<Scalars['Float']>;
  psqi_state?: Maybe<Scalars['Float']>;
  session_1_state?: Maybe<Scalars['Float']>;
  session_2_state?: Maybe<Scalars['Float']>;
  session_3_state?: Maybe<Scalars['Float']>;
  session_4_state?: Maybe<Scalars['Float']>;
  session_5_state?: Maybe<Scalars['Float']>;
  session_6_state?: Maybe<Scalars['Float']>;
  session_7_state?: Maybe<Scalars['Float']>;
  session_8_state?: Maybe<Scalars['Float']>;
  session_9_state?: Maybe<Scalars['Float']>;
  session_10_state?: Maybe<Scalars['Float']>;
  session_11_state?: Maybe<Scalars['Float']>;
  session_12_state?: Maybe<Scalars['Float']>;
  session_13_state?: Maybe<Scalars['Float']>;
  session_14_state?: Maybe<Scalars['Float']>;
  session_15_state?: Maybe<Scalars['Float']>;
  session_16_state?: Maybe<Scalars['Float']>;
  session_17_state?: Maybe<Scalars['Float']>;
  session_18_state?: Maybe<Scalars['Float']>;
  session_19_state?: Maybe<Scalars['Float']>;
  session_20_state?: Maybe<Scalars['Float']>;
  session_21_state?: Maybe<Scalars['Float']>;
  session_22_state?: Maybe<Scalars['Float']>;
  session_23_state?: Maybe<Scalars['Float']>;
  session_24_state?: Maybe<Scalars['Float']>;
  session_25_state?: Maybe<Scalars['Float']>;
  session_26_state?: Maybe<Scalars['Float']>;
  session_27_state?: Maybe<Scalars['Float']>;
  session_28_state?: Maybe<Scalars['Float']>;
  session_29_state?: Maybe<Scalars['Float']>;
  session_30_state?: Maybe<Scalars['Float']>;
  session_31_state?: Maybe<Scalars['Float']>;
  session_32_state?: Maybe<Scalars['Float']>;
  session_33_state?: Maybe<Scalars['Float']>;
  session_34_state?: Maybe<Scalars['Float']>;
  session_35_state?: Maybe<Scalars['Float']>;
  session_36_state?: Maybe<Scalars['Float']>;
  session_37_state?: Maybe<Scalars['Float']>;
  session_38_state?: Maybe<Scalars['Float']>;
  session_39_state?: Maybe<Scalars['Float']>;
  session_40_state?: Maybe<Scalars['Float']>;
  session_41_state?: Maybe<Scalars['Float']>;
  session_42_state?: Maybe<Scalars['Float']>;
  session_43_state?: Maybe<Scalars['Float']>;
  session_44_state?: Maybe<Scalars['Float']>;
  session_45_state?: Maybe<Scalars['Float']>;
  session_46_state?: Maybe<Scalars['Float']>;
  session_47_state?: Maybe<Scalars['Float']>;
  session_48_state?: Maybe<Scalars['Float']>;
  session_49_state?: Maybe<Scalars['Float']>;
  session_50_state?: Maybe<Scalars['Float']>;
  session_51_state?: Maybe<Scalars['Float']>;
  session_52_state?: Maybe<Scalars['Float']>;
  session_53_state?: Maybe<Scalars['Float']>;
  session_54_state?: Maybe<Scalars['Float']>;
  session_55_state?: Maybe<Scalars['Float']>;
  session_56_state?: Maybe<Scalars['Float']>;
  session_57_state?: Maybe<Scalars['Float']>;
  session_58_state?: Maybe<Scalars['Float']>;
  session_59_state?: Maybe<Scalars['Float']>;
  session_60_state?: Maybe<Scalars['Float']>;
  session_to_pay?: Maybe<Scalars['Float']>;
};

export type User_Stddev_Order_By = {
  current_journey_id?: InputMaybe<Order_By>;
  day_goal?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  night_goal?: InputMaybe<Order_By>;
  paid_value?: InputMaybe<Order_By>;
  points?: InputMaybe<Order_By>;
  psqi_state?: InputMaybe<Order_By>;
  session_1_state?: InputMaybe<Order_By>;
  session_2_state?: InputMaybe<Order_By>;
  session_3_state?: InputMaybe<Order_By>;
  session_4_state?: InputMaybe<Order_By>;
  session_5_state?: InputMaybe<Order_By>;
  session_6_state?: InputMaybe<Order_By>;
  session_7_state?: InputMaybe<Order_By>;
  session_8_state?: InputMaybe<Order_By>;
  session_9_state?: InputMaybe<Order_By>;
  session_10_state?: InputMaybe<Order_By>;
  session_11_state?: InputMaybe<Order_By>;
  session_12_state?: InputMaybe<Order_By>;
  session_13_state?: InputMaybe<Order_By>;
  session_14_state?: InputMaybe<Order_By>;
  session_15_state?: InputMaybe<Order_By>;
  session_16_state?: InputMaybe<Order_By>;
  session_17_state?: InputMaybe<Order_By>;
  session_18_state?: InputMaybe<Order_By>;
  session_19_state?: InputMaybe<Order_By>;
  session_20_state?: InputMaybe<Order_By>;
  session_21_state?: InputMaybe<Order_By>;
  session_22_state?: InputMaybe<Order_By>;
  session_23_state?: InputMaybe<Order_By>;
  session_24_state?: InputMaybe<Order_By>;
  session_25_state?: InputMaybe<Order_By>;
  session_26_state?: InputMaybe<Order_By>;
  session_27_state?: InputMaybe<Order_By>;
  session_28_state?: InputMaybe<Order_By>;
  session_29_state?: InputMaybe<Order_By>;
  session_30_state?: InputMaybe<Order_By>;
  session_31_state?: InputMaybe<Order_By>;
  session_32_state?: InputMaybe<Order_By>;
  session_33_state?: InputMaybe<Order_By>;
  session_34_state?: InputMaybe<Order_By>;
  session_35_state?: InputMaybe<Order_By>;
  session_36_state?: InputMaybe<Order_By>;
  session_37_state?: InputMaybe<Order_By>;
  session_38_state?: InputMaybe<Order_By>;
  session_39_state?: InputMaybe<Order_By>;
  session_40_state?: InputMaybe<Order_By>;
  session_41_state?: InputMaybe<Order_By>;
  session_42_state?: InputMaybe<Order_By>;
  session_43_state?: InputMaybe<Order_By>;
  session_44_state?: InputMaybe<Order_By>;
  session_45_state?: InputMaybe<Order_By>;
  session_46_state?: InputMaybe<Order_By>;
  session_47_state?: InputMaybe<Order_By>;
  session_48_state?: InputMaybe<Order_By>;
  session_49_state?: InputMaybe<Order_By>;
  session_50_state?: InputMaybe<Order_By>;
  session_51_state?: InputMaybe<Order_By>;
  session_52_state?: InputMaybe<Order_By>;
  session_53_state?: InputMaybe<Order_By>;
  session_54_state?: InputMaybe<Order_By>;
  session_55_state?: InputMaybe<Order_By>;
  session_56_state?: InputMaybe<Order_By>;
  session_57_state?: InputMaybe<Order_By>;
  session_58_state?: InputMaybe<Order_By>;
  session_59_state?: InputMaybe<Order_By>;
  session_60_state?: InputMaybe<Order_By>;
  session_to_pay?: InputMaybe<Order_By>;
};

export type User_Stddev_Pop_Fields = {
  __typename?: 'user_stddev_pop_fields';
  current_journey_id?: Maybe<Scalars['Float']>;
  day_goal?: Maybe<Scalars['Float']>;
  internal_id?: Maybe<Scalars['Float']>;
  night_goal?: Maybe<Scalars['Float']>;
  paid_value?: Maybe<Scalars['Float']>;
  points?: Maybe<Scalars['Float']>;
  psqi_state?: Maybe<Scalars['Float']>;
  session_1_state?: Maybe<Scalars['Float']>;
  session_2_state?: Maybe<Scalars['Float']>;
  session_3_state?: Maybe<Scalars['Float']>;
  session_4_state?: Maybe<Scalars['Float']>;
  session_5_state?: Maybe<Scalars['Float']>;
  session_6_state?: Maybe<Scalars['Float']>;
  session_7_state?: Maybe<Scalars['Float']>;
  session_8_state?: Maybe<Scalars['Float']>;
  session_9_state?: Maybe<Scalars['Float']>;
  session_10_state?: Maybe<Scalars['Float']>;
  session_11_state?: Maybe<Scalars['Float']>;
  session_12_state?: Maybe<Scalars['Float']>;
  session_13_state?: Maybe<Scalars['Float']>;
  session_14_state?: Maybe<Scalars['Float']>;
  session_15_state?: Maybe<Scalars['Float']>;
  session_16_state?: Maybe<Scalars['Float']>;
  session_17_state?: Maybe<Scalars['Float']>;
  session_18_state?: Maybe<Scalars['Float']>;
  session_19_state?: Maybe<Scalars['Float']>;
  session_20_state?: Maybe<Scalars['Float']>;
  session_21_state?: Maybe<Scalars['Float']>;
  session_22_state?: Maybe<Scalars['Float']>;
  session_23_state?: Maybe<Scalars['Float']>;
  session_24_state?: Maybe<Scalars['Float']>;
  session_25_state?: Maybe<Scalars['Float']>;
  session_26_state?: Maybe<Scalars['Float']>;
  session_27_state?: Maybe<Scalars['Float']>;
  session_28_state?: Maybe<Scalars['Float']>;
  session_29_state?: Maybe<Scalars['Float']>;
  session_30_state?: Maybe<Scalars['Float']>;
  session_31_state?: Maybe<Scalars['Float']>;
  session_32_state?: Maybe<Scalars['Float']>;
  session_33_state?: Maybe<Scalars['Float']>;
  session_34_state?: Maybe<Scalars['Float']>;
  session_35_state?: Maybe<Scalars['Float']>;
  session_36_state?: Maybe<Scalars['Float']>;
  session_37_state?: Maybe<Scalars['Float']>;
  session_38_state?: Maybe<Scalars['Float']>;
  session_39_state?: Maybe<Scalars['Float']>;
  session_40_state?: Maybe<Scalars['Float']>;
  session_41_state?: Maybe<Scalars['Float']>;
  session_42_state?: Maybe<Scalars['Float']>;
  session_43_state?: Maybe<Scalars['Float']>;
  session_44_state?: Maybe<Scalars['Float']>;
  session_45_state?: Maybe<Scalars['Float']>;
  session_46_state?: Maybe<Scalars['Float']>;
  session_47_state?: Maybe<Scalars['Float']>;
  session_48_state?: Maybe<Scalars['Float']>;
  session_49_state?: Maybe<Scalars['Float']>;
  session_50_state?: Maybe<Scalars['Float']>;
  session_51_state?: Maybe<Scalars['Float']>;
  session_52_state?: Maybe<Scalars['Float']>;
  session_53_state?: Maybe<Scalars['Float']>;
  session_54_state?: Maybe<Scalars['Float']>;
  session_55_state?: Maybe<Scalars['Float']>;
  session_56_state?: Maybe<Scalars['Float']>;
  session_57_state?: Maybe<Scalars['Float']>;
  session_58_state?: Maybe<Scalars['Float']>;
  session_59_state?: Maybe<Scalars['Float']>;
  session_60_state?: Maybe<Scalars['Float']>;
  session_to_pay?: Maybe<Scalars['Float']>;
};

export type User_Stddev_Pop_Order_By = {
  current_journey_id?: InputMaybe<Order_By>;
  day_goal?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  night_goal?: InputMaybe<Order_By>;
  paid_value?: InputMaybe<Order_By>;
  points?: InputMaybe<Order_By>;
  psqi_state?: InputMaybe<Order_By>;
  session_1_state?: InputMaybe<Order_By>;
  session_2_state?: InputMaybe<Order_By>;
  session_3_state?: InputMaybe<Order_By>;
  session_4_state?: InputMaybe<Order_By>;
  session_5_state?: InputMaybe<Order_By>;
  session_6_state?: InputMaybe<Order_By>;
  session_7_state?: InputMaybe<Order_By>;
  session_8_state?: InputMaybe<Order_By>;
  session_9_state?: InputMaybe<Order_By>;
  session_10_state?: InputMaybe<Order_By>;
  session_11_state?: InputMaybe<Order_By>;
  session_12_state?: InputMaybe<Order_By>;
  session_13_state?: InputMaybe<Order_By>;
  session_14_state?: InputMaybe<Order_By>;
  session_15_state?: InputMaybe<Order_By>;
  session_16_state?: InputMaybe<Order_By>;
  session_17_state?: InputMaybe<Order_By>;
  session_18_state?: InputMaybe<Order_By>;
  session_19_state?: InputMaybe<Order_By>;
  session_20_state?: InputMaybe<Order_By>;
  session_21_state?: InputMaybe<Order_By>;
  session_22_state?: InputMaybe<Order_By>;
  session_23_state?: InputMaybe<Order_By>;
  session_24_state?: InputMaybe<Order_By>;
  session_25_state?: InputMaybe<Order_By>;
  session_26_state?: InputMaybe<Order_By>;
  session_27_state?: InputMaybe<Order_By>;
  session_28_state?: InputMaybe<Order_By>;
  session_29_state?: InputMaybe<Order_By>;
  session_30_state?: InputMaybe<Order_By>;
  session_31_state?: InputMaybe<Order_By>;
  session_32_state?: InputMaybe<Order_By>;
  session_33_state?: InputMaybe<Order_By>;
  session_34_state?: InputMaybe<Order_By>;
  session_35_state?: InputMaybe<Order_By>;
  session_36_state?: InputMaybe<Order_By>;
  session_37_state?: InputMaybe<Order_By>;
  session_38_state?: InputMaybe<Order_By>;
  session_39_state?: InputMaybe<Order_By>;
  session_40_state?: InputMaybe<Order_By>;
  session_41_state?: InputMaybe<Order_By>;
  session_42_state?: InputMaybe<Order_By>;
  session_43_state?: InputMaybe<Order_By>;
  session_44_state?: InputMaybe<Order_By>;
  session_45_state?: InputMaybe<Order_By>;
  session_46_state?: InputMaybe<Order_By>;
  session_47_state?: InputMaybe<Order_By>;
  session_48_state?: InputMaybe<Order_By>;
  session_49_state?: InputMaybe<Order_By>;
  session_50_state?: InputMaybe<Order_By>;
  session_51_state?: InputMaybe<Order_By>;
  session_52_state?: InputMaybe<Order_By>;
  session_53_state?: InputMaybe<Order_By>;
  session_54_state?: InputMaybe<Order_By>;
  session_55_state?: InputMaybe<Order_By>;
  session_56_state?: InputMaybe<Order_By>;
  session_57_state?: InputMaybe<Order_By>;
  session_58_state?: InputMaybe<Order_By>;
  session_59_state?: InputMaybe<Order_By>;
  session_60_state?: InputMaybe<Order_By>;
  session_to_pay?: InputMaybe<Order_By>;
};

export type User_Stddev_Samp_Fields = {
  __typename?: 'user_stddev_samp_fields';
  current_journey_id?: Maybe<Scalars['Float']>;
  day_goal?: Maybe<Scalars['Float']>;
  internal_id?: Maybe<Scalars['Float']>;
  night_goal?: Maybe<Scalars['Float']>;
  paid_value?: Maybe<Scalars['Float']>;
  points?: Maybe<Scalars['Float']>;
  psqi_state?: Maybe<Scalars['Float']>;
  session_1_state?: Maybe<Scalars['Float']>;
  session_2_state?: Maybe<Scalars['Float']>;
  session_3_state?: Maybe<Scalars['Float']>;
  session_4_state?: Maybe<Scalars['Float']>;
  session_5_state?: Maybe<Scalars['Float']>;
  session_6_state?: Maybe<Scalars['Float']>;
  session_7_state?: Maybe<Scalars['Float']>;
  session_8_state?: Maybe<Scalars['Float']>;
  session_9_state?: Maybe<Scalars['Float']>;
  session_10_state?: Maybe<Scalars['Float']>;
  session_11_state?: Maybe<Scalars['Float']>;
  session_12_state?: Maybe<Scalars['Float']>;
  session_13_state?: Maybe<Scalars['Float']>;
  session_14_state?: Maybe<Scalars['Float']>;
  session_15_state?: Maybe<Scalars['Float']>;
  session_16_state?: Maybe<Scalars['Float']>;
  session_17_state?: Maybe<Scalars['Float']>;
  session_18_state?: Maybe<Scalars['Float']>;
  session_19_state?: Maybe<Scalars['Float']>;
  session_20_state?: Maybe<Scalars['Float']>;
  session_21_state?: Maybe<Scalars['Float']>;
  session_22_state?: Maybe<Scalars['Float']>;
  session_23_state?: Maybe<Scalars['Float']>;
  session_24_state?: Maybe<Scalars['Float']>;
  session_25_state?: Maybe<Scalars['Float']>;
  session_26_state?: Maybe<Scalars['Float']>;
  session_27_state?: Maybe<Scalars['Float']>;
  session_28_state?: Maybe<Scalars['Float']>;
  session_29_state?: Maybe<Scalars['Float']>;
  session_30_state?: Maybe<Scalars['Float']>;
  session_31_state?: Maybe<Scalars['Float']>;
  session_32_state?: Maybe<Scalars['Float']>;
  session_33_state?: Maybe<Scalars['Float']>;
  session_34_state?: Maybe<Scalars['Float']>;
  session_35_state?: Maybe<Scalars['Float']>;
  session_36_state?: Maybe<Scalars['Float']>;
  session_37_state?: Maybe<Scalars['Float']>;
  session_38_state?: Maybe<Scalars['Float']>;
  session_39_state?: Maybe<Scalars['Float']>;
  session_40_state?: Maybe<Scalars['Float']>;
  session_41_state?: Maybe<Scalars['Float']>;
  session_42_state?: Maybe<Scalars['Float']>;
  session_43_state?: Maybe<Scalars['Float']>;
  session_44_state?: Maybe<Scalars['Float']>;
  session_45_state?: Maybe<Scalars['Float']>;
  session_46_state?: Maybe<Scalars['Float']>;
  session_47_state?: Maybe<Scalars['Float']>;
  session_48_state?: Maybe<Scalars['Float']>;
  session_49_state?: Maybe<Scalars['Float']>;
  session_50_state?: Maybe<Scalars['Float']>;
  session_51_state?: Maybe<Scalars['Float']>;
  session_52_state?: Maybe<Scalars['Float']>;
  session_53_state?: Maybe<Scalars['Float']>;
  session_54_state?: Maybe<Scalars['Float']>;
  session_55_state?: Maybe<Scalars['Float']>;
  session_56_state?: Maybe<Scalars['Float']>;
  session_57_state?: Maybe<Scalars['Float']>;
  session_58_state?: Maybe<Scalars['Float']>;
  session_59_state?: Maybe<Scalars['Float']>;
  session_60_state?: Maybe<Scalars['Float']>;
  session_to_pay?: Maybe<Scalars['Float']>;
};

export type User_Stddev_Samp_Order_By = {
  current_journey_id?: InputMaybe<Order_By>;
  day_goal?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  night_goal?: InputMaybe<Order_By>;
  paid_value?: InputMaybe<Order_By>;
  points?: InputMaybe<Order_By>;
  psqi_state?: InputMaybe<Order_By>;
  session_1_state?: InputMaybe<Order_By>;
  session_2_state?: InputMaybe<Order_By>;
  session_3_state?: InputMaybe<Order_By>;
  session_4_state?: InputMaybe<Order_By>;
  session_5_state?: InputMaybe<Order_By>;
  session_6_state?: InputMaybe<Order_By>;
  session_7_state?: InputMaybe<Order_By>;
  session_8_state?: InputMaybe<Order_By>;
  session_9_state?: InputMaybe<Order_By>;
  session_10_state?: InputMaybe<Order_By>;
  session_11_state?: InputMaybe<Order_By>;
  session_12_state?: InputMaybe<Order_By>;
  session_13_state?: InputMaybe<Order_By>;
  session_14_state?: InputMaybe<Order_By>;
  session_15_state?: InputMaybe<Order_By>;
  session_16_state?: InputMaybe<Order_By>;
  session_17_state?: InputMaybe<Order_By>;
  session_18_state?: InputMaybe<Order_By>;
  session_19_state?: InputMaybe<Order_By>;
  session_20_state?: InputMaybe<Order_By>;
  session_21_state?: InputMaybe<Order_By>;
  session_22_state?: InputMaybe<Order_By>;
  session_23_state?: InputMaybe<Order_By>;
  session_24_state?: InputMaybe<Order_By>;
  session_25_state?: InputMaybe<Order_By>;
  session_26_state?: InputMaybe<Order_By>;
  session_27_state?: InputMaybe<Order_By>;
  session_28_state?: InputMaybe<Order_By>;
  session_29_state?: InputMaybe<Order_By>;
  session_30_state?: InputMaybe<Order_By>;
  session_31_state?: InputMaybe<Order_By>;
  session_32_state?: InputMaybe<Order_By>;
  session_33_state?: InputMaybe<Order_By>;
  session_34_state?: InputMaybe<Order_By>;
  session_35_state?: InputMaybe<Order_By>;
  session_36_state?: InputMaybe<Order_By>;
  session_37_state?: InputMaybe<Order_By>;
  session_38_state?: InputMaybe<Order_By>;
  session_39_state?: InputMaybe<Order_By>;
  session_40_state?: InputMaybe<Order_By>;
  session_41_state?: InputMaybe<Order_By>;
  session_42_state?: InputMaybe<Order_By>;
  session_43_state?: InputMaybe<Order_By>;
  session_44_state?: InputMaybe<Order_By>;
  session_45_state?: InputMaybe<Order_By>;
  session_46_state?: InputMaybe<Order_By>;
  session_47_state?: InputMaybe<Order_By>;
  session_48_state?: InputMaybe<Order_By>;
  session_49_state?: InputMaybe<Order_By>;
  session_50_state?: InputMaybe<Order_By>;
  session_51_state?: InputMaybe<Order_By>;
  session_52_state?: InputMaybe<Order_By>;
  session_53_state?: InputMaybe<Order_By>;
  session_54_state?: InputMaybe<Order_By>;
  session_55_state?: InputMaybe<Order_By>;
  session_56_state?: InputMaybe<Order_By>;
  session_57_state?: InputMaybe<Order_By>;
  session_58_state?: InputMaybe<Order_By>;
  session_59_state?: InputMaybe<Order_By>;
  session_60_state?: InputMaybe<Order_By>;
  session_to_pay?: InputMaybe<Order_By>;
};

export type User_Sum_Fields = {
  __typename?: 'user_sum_fields';
  current_journey_id?: Maybe<Scalars['Int']>;
  day_goal?: Maybe<Scalars['smallint']>;
  internal_id?: Maybe<Scalars['Int']>;
  night_goal?: Maybe<Scalars['smallint']>;
  paid_value?: Maybe<Scalars['numeric']>;
  points?: Maybe<Scalars['Int']>;
  psqi_state?: Maybe<Scalars['smallint']>;
  session_1_state?: Maybe<Scalars['smallint']>;
  session_2_state?: Maybe<Scalars['smallint']>;
  session_3_state?: Maybe<Scalars['smallint']>;
  session_4_state?: Maybe<Scalars['smallint']>;
  session_5_state?: Maybe<Scalars['smallint']>;
  session_6_state?: Maybe<Scalars['smallint']>;
  session_7_state?: Maybe<Scalars['smallint']>;
  session_8_state?: Maybe<Scalars['smallint']>;
  session_9_state?: Maybe<Scalars['smallint']>;
  session_10_state?: Maybe<Scalars['smallint']>;
  session_11_state?: Maybe<Scalars['smallint']>;
  session_12_state?: Maybe<Scalars['smallint']>;
  session_13_state?: Maybe<Scalars['smallint']>;
  session_14_state?: Maybe<Scalars['smallint']>;
  session_15_state?: Maybe<Scalars['smallint']>;
  session_16_state?: Maybe<Scalars['smallint']>;
  session_17_state?: Maybe<Scalars['smallint']>;
  session_18_state?: Maybe<Scalars['smallint']>;
  session_19_state?: Maybe<Scalars['smallint']>;
  session_20_state?: Maybe<Scalars['smallint']>;
  session_21_state?: Maybe<Scalars['smallint']>;
  session_22_state?: Maybe<Scalars['smallint']>;
  session_23_state?: Maybe<Scalars['smallint']>;
  session_24_state?: Maybe<Scalars['smallint']>;
  session_25_state?: Maybe<Scalars['smallint']>;
  session_26_state?: Maybe<Scalars['smallint']>;
  session_27_state?: Maybe<Scalars['smallint']>;
  session_28_state?: Maybe<Scalars['smallint']>;
  session_29_state?: Maybe<Scalars['smallint']>;
  session_30_state?: Maybe<Scalars['smallint']>;
  session_31_state?: Maybe<Scalars['smallint']>;
  session_32_state?: Maybe<Scalars['smallint']>;
  session_33_state?: Maybe<Scalars['smallint']>;
  session_34_state?: Maybe<Scalars['smallint']>;
  session_35_state?: Maybe<Scalars['smallint']>;
  session_36_state?: Maybe<Scalars['smallint']>;
  session_37_state?: Maybe<Scalars['smallint']>;
  session_38_state?: Maybe<Scalars['smallint']>;
  session_39_state?: Maybe<Scalars['smallint']>;
  session_40_state?: Maybe<Scalars['smallint']>;
  session_41_state?: Maybe<Scalars['smallint']>;
  session_42_state?: Maybe<Scalars['smallint']>;
  session_43_state?: Maybe<Scalars['smallint']>;
  session_44_state?: Maybe<Scalars['smallint']>;
  session_45_state?: Maybe<Scalars['smallint']>;
  session_46_state?: Maybe<Scalars['smallint']>;
  session_47_state?: Maybe<Scalars['smallint']>;
  session_48_state?: Maybe<Scalars['smallint']>;
  session_49_state?: Maybe<Scalars['smallint']>;
  session_50_state?: Maybe<Scalars['smallint']>;
  session_51_state?: Maybe<Scalars['smallint']>;
  session_52_state?: Maybe<Scalars['smallint']>;
  session_53_state?: Maybe<Scalars['smallint']>;
  session_54_state?: Maybe<Scalars['smallint']>;
  session_55_state?: Maybe<Scalars['smallint']>;
  session_56_state?: Maybe<Scalars['smallint']>;
  session_57_state?: Maybe<Scalars['smallint']>;
  session_58_state?: Maybe<Scalars['smallint']>;
  session_59_state?: Maybe<Scalars['smallint']>;
  session_60_state?: Maybe<Scalars['smallint']>;
  session_to_pay?: Maybe<Scalars['Int']>;
};

export type User_Sum_Order_By = {
  current_journey_id?: InputMaybe<Order_By>;
  day_goal?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  night_goal?: InputMaybe<Order_By>;
  paid_value?: InputMaybe<Order_By>;
  points?: InputMaybe<Order_By>;
  psqi_state?: InputMaybe<Order_By>;
  session_1_state?: InputMaybe<Order_By>;
  session_2_state?: InputMaybe<Order_By>;
  session_3_state?: InputMaybe<Order_By>;
  session_4_state?: InputMaybe<Order_By>;
  session_5_state?: InputMaybe<Order_By>;
  session_6_state?: InputMaybe<Order_By>;
  session_7_state?: InputMaybe<Order_By>;
  session_8_state?: InputMaybe<Order_By>;
  session_9_state?: InputMaybe<Order_By>;
  session_10_state?: InputMaybe<Order_By>;
  session_11_state?: InputMaybe<Order_By>;
  session_12_state?: InputMaybe<Order_By>;
  session_13_state?: InputMaybe<Order_By>;
  session_14_state?: InputMaybe<Order_By>;
  session_15_state?: InputMaybe<Order_By>;
  session_16_state?: InputMaybe<Order_By>;
  session_17_state?: InputMaybe<Order_By>;
  session_18_state?: InputMaybe<Order_By>;
  session_19_state?: InputMaybe<Order_By>;
  session_20_state?: InputMaybe<Order_By>;
  session_21_state?: InputMaybe<Order_By>;
  session_22_state?: InputMaybe<Order_By>;
  session_23_state?: InputMaybe<Order_By>;
  session_24_state?: InputMaybe<Order_By>;
  session_25_state?: InputMaybe<Order_By>;
  session_26_state?: InputMaybe<Order_By>;
  session_27_state?: InputMaybe<Order_By>;
  session_28_state?: InputMaybe<Order_By>;
  session_29_state?: InputMaybe<Order_By>;
  session_30_state?: InputMaybe<Order_By>;
  session_31_state?: InputMaybe<Order_By>;
  session_32_state?: InputMaybe<Order_By>;
  session_33_state?: InputMaybe<Order_By>;
  session_34_state?: InputMaybe<Order_By>;
  session_35_state?: InputMaybe<Order_By>;
  session_36_state?: InputMaybe<Order_By>;
  session_37_state?: InputMaybe<Order_By>;
  session_38_state?: InputMaybe<Order_By>;
  session_39_state?: InputMaybe<Order_By>;
  session_40_state?: InputMaybe<Order_By>;
  session_41_state?: InputMaybe<Order_By>;
  session_42_state?: InputMaybe<Order_By>;
  session_43_state?: InputMaybe<Order_By>;
  session_44_state?: InputMaybe<Order_By>;
  session_45_state?: InputMaybe<Order_By>;
  session_46_state?: InputMaybe<Order_By>;
  session_47_state?: InputMaybe<Order_By>;
  session_48_state?: InputMaybe<Order_By>;
  session_49_state?: InputMaybe<Order_By>;
  session_50_state?: InputMaybe<Order_By>;
  session_51_state?: InputMaybe<Order_By>;
  session_52_state?: InputMaybe<Order_By>;
  session_53_state?: InputMaybe<Order_By>;
  session_54_state?: InputMaybe<Order_By>;
  session_55_state?: InputMaybe<Order_By>;
  session_56_state?: InputMaybe<Order_By>;
  session_57_state?: InputMaybe<Order_By>;
  session_58_state?: InputMaybe<Order_By>;
  session_59_state?: InputMaybe<Order_By>;
  session_60_state?: InputMaybe<Order_By>;
  session_to_pay?: InputMaybe<Order_By>;
};

export type User_Tag = {
  __typename?: 'user_tag';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  tag: Scalars['String'];
  user: User;
  user_id: Scalars['String'];
};

export type User_Tag_Aggregate = {
  __typename?: 'user_tag_aggregate';
  aggregate?: Maybe<User_Tag_Aggregate_Fields>;
  nodes: Array<User_Tag>;
};

export type User_Tag_Aggregate_Fields = {
  __typename?: 'user_tag_aggregate_fields';
  avg?: Maybe<User_Tag_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Tag_Max_Fields>;
  min?: Maybe<User_Tag_Min_Fields>;
  stddev?: Maybe<User_Tag_Stddev_Fields>;
  stddev_pop?: Maybe<User_Tag_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Tag_Stddev_Samp_Fields>;
  sum?: Maybe<User_Tag_Sum_Fields>;
  var_pop?: Maybe<User_Tag_Var_Pop_Fields>;
  var_samp?: Maybe<User_Tag_Var_Samp_Fields>;
  variance?: Maybe<User_Tag_Variance_Fields>;
};

export type User_Tag_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Tag_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type User_Tag_Aggregate_Order_By = {
  avg?: InputMaybe<User_Tag_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Tag_Max_Order_By>;
  min?: InputMaybe<User_Tag_Min_Order_By>;
  stddev?: InputMaybe<User_Tag_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Tag_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Tag_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Tag_Sum_Order_By>;
  var_pop?: InputMaybe<User_Tag_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Tag_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Tag_Variance_Order_By>;
};

export type User_Tag_Arr_Rel_Insert_Input = {
  data: Array<User_Tag_Insert_Input>;
  on_conflict?: InputMaybe<User_Tag_On_Conflict>;
};

export type User_Tag_Avg_Fields = {
  __typename?: 'user_tag_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

export type User_Tag_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type User_Tag_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<User_Tag_Bool_Exp>>>;
  _not?: InputMaybe<User_Tag_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<User_Tag_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  tag?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

export enum User_Tag_Constraint {
  UserTagPkey = 'user_tag_pkey',
}

export type User_Tag_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

export type User_Tag_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  tag?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type User_Tag_Max_Fields = {
  __typename?: 'user_tag_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  tag?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

export type User_Tag_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type User_Tag_Min_Fields = {
  __typename?: 'user_tag_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  tag?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

export type User_Tag_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type User_Tag_Mutation_Response = {
  __typename?: 'user_tag_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<User_Tag>;
};

export type User_Tag_Obj_Rel_Insert_Input = {
  data: User_Tag_Insert_Input;
  on_conflict?: InputMaybe<User_Tag_On_Conflict>;
};

export type User_Tag_On_Conflict = {
  constraint: User_Tag_Constraint;
  update_columns: Array<User_Tag_Update_Column>;
  where?: InputMaybe<User_Tag_Bool_Exp>;
};

export type User_Tag_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type User_Tag_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export enum User_Tag_Select_Column {
  CreatedAt = 'created_at',
  Id = 'id',
  Tag = 'tag',
  UserId = 'user_id',
}

export type User_Tag_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  tag?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type User_Tag_Stddev_Fields = {
  __typename?: 'user_tag_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

export type User_Tag_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type User_Tag_Stddev_Pop_Fields = {
  __typename?: 'user_tag_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type User_Tag_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type User_Tag_Stddev_Samp_Fields = {
  __typename?: 'user_tag_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type User_Tag_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type User_Tag_Sum_Fields = {
  __typename?: 'user_tag_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

export type User_Tag_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

export enum User_Tag_Update_Column {
  CreatedAt = 'created_at',
  Id = 'id',
  Tag = 'tag',
  UserId = 'user_id',
}

export type User_Tag_Var_Pop_Fields = {
  __typename?: 'user_tag_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

export type User_Tag_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type User_Tag_Var_Samp_Fields = {
  __typename?: 'user_tag_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

export type User_Tag_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type User_Tag_Variance_Fields = {
  __typename?: 'user_tag_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type User_Tag_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type User_Telehealth = {
  __typename?: 'user_telehealth';
  comment?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  dav_id?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  max_appointments: Scalars['Int'];
  min_days_between_appointments: Scalars['Int'];
  pre_user?: Maybe<Pre_User>;
  pre_user_id?: Maybe<Scalars['String']>;
  recurrence_model?: Maybe<Recurrence_Model_Enum_Enum>;
  recurrence_start_date?: Maybe<Scalars['timestamptz']>;
  subscription_expiration?: Maybe<Scalars['timestamptz']>;
  updated_at: Scalars['timestamptz'];
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['String']>;
};

export type User_Telehealth_Aggregate = {
  __typename?: 'user_telehealth_aggregate';
  aggregate?: Maybe<User_Telehealth_Aggregate_Fields>;
  nodes: Array<User_Telehealth>;
};

export type User_Telehealth_Aggregate_Fields = {
  __typename?: 'user_telehealth_aggregate_fields';
  avg?: Maybe<User_Telehealth_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Telehealth_Max_Fields>;
  min?: Maybe<User_Telehealth_Min_Fields>;
  stddev?: Maybe<User_Telehealth_Stddev_Fields>;
  stddev_pop?: Maybe<User_Telehealth_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Telehealth_Stddev_Samp_Fields>;
  sum?: Maybe<User_Telehealth_Sum_Fields>;
  var_pop?: Maybe<User_Telehealth_Var_Pop_Fields>;
  var_samp?: Maybe<User_Telehealth_Var_Samp_Fields>;
  variance?: Maybe<User_Telehealth_Variance_Fields>;
};

export type User_Telehealth_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Telehealth_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type User_Telehealth_Aggregate_Order_By = {
  avg?: InputMaybe<User_Telehealth_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Telehealth_Max_Order_By>;
  min?: InputMaybe<User_Telehealth_Min_Order_By>;
  stddev?: InputMaybe<User_Telehealth_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Telehealth_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Telehealth_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Telehealth_Sum_Order_By>;
  var_pop?: InputMaybe<User_Telehealth_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Telehealth_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Telehealth_Variance_Order_By>;
};

export type User_Telehealth_Arr_Rel_Insert_Input = {
  data: Array<User_Telehealth_Insert_Input>;
  on_conflict?: InputMaybe<User_Telehealth_On_Conflict>;
};

export type User_Telehealth_Avg_Fields = {
  __typename?: 'user_telehealth_avg_fields';
  id?: Maybe<Scalars['Float']>;
  max_appointments?: Maybe<Scalars['Float']>;
  min_days_between_appointments?: Maybe<Scalars['Float']>;
};

export type User_Telehealth_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  max_appointments?: InputMaybe<Order_By>;
  min_days_between_appointments?: InputMaybe<Order_By>;
};

export type User_Telehealth_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<User_Telehealth_Bool_Exp>>>;
  _not?: InputMaybe<User_Telehealth_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<User_Telehealth_Bool_Exp>>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  dav_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  max_appointments?: InputMaybe<Int_Comparison_Exp>;
  min_days_between_appointments?: InputMaybe<Int_Comparison_Exp>;
  pre_user?: InputMaybe<Pre_User_Bool_Exp>;
  pre_user_id?: InputMaybe<String_Comparison_Exp>;
  recurrence_model?: InputMaybe<Recurrence_Model_Enum_Enum_Comparison_Exp>;
  recurrence_start_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  subscription_expiration?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

export enum User_Telehealth_Constraint {
  UserTelehealthDavIdKey = 'user_telehealth_dav_id_key',
  UserTelehealthIdKey = 'user_telehealth_id_key',
  UserTelehealthPkey = 'user_telehealth_pkey',
  UserTelehealthPreUserIdKey = 'user_telehealth_pre_user_id_key',
  UserTelehealthUserIdKey = 'user_telehealth_user_id_key',
}

export type User_Telehealth_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  max_appointments?: InputMaybe<Scalars['Int']>;
  min_days_between_appointments?: InputMaybe<Scalars['Int']>;
};

export type User_Telehealth_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dav_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  max_appointments?: InputMaybe<Scalars['Int']>;
  min_days_between_appointments?: InputMaybe<Scalars['Int']>;
  pre_user?: InputMaybe<Pre_User_Obj_Rel_Insert_Input>;
  pre_user_id?: InputMaybe<Scalars['String']>;
  recurrence_model?: InputMaybe<Recurrence_Model_Enum_Enum>;
  recurrence_start_date?: InputMaybe<Scalars['timestamptz']>;
  subscription_expiration?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type User_Telehealth_Max_Fields = {
  __typename?: 'user_telehealth_max_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dav_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  max_appointments?: Maybe<Scalars['Int']>;
  min_days_between_appointments?: Maybe<Scalars['Int']>;
  pre_user_id?: Maybe<Scalars['String']>;
  recurrence_start_date?: Maybe<Scalars['timestamptz']>;
  subscription_expiration?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type User_Telehealth_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dav_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_appointments?: InputMaybe<Order_By>;
  min_days_between_appointments?: InputMaybe<Order_By>;
  pre_user_id?: InputMaybe<Order_By>;
  recurrence_start_date?: InputMaybe<Order_By>;
  subscription_expiration?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type User_Telehealth_Min_Fields = {
  __typename?: 'user_telehealth_min_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dav_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  max_appointments?: Maybe<Scalars['Int']>;
  min_days_between_appointments?: Maybe<Scalars['Int']>;
  pre_user_id?: Maybe<Scalars['String']>;
  recurrence_start_date?: Maybe<Scalars['timestamptz']>;
  subscription_expiration?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

export type User_Telehealth_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dav_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_appointments?: InputMaybe<Order_By>;
  min_days_between_appointments?: InputMaybe<Order_By>;
  pre_user_id?: InputMaybe<Order_By>;
  recurrence_start_date?: InputMaybe<Order_By>;
  subscription_expiration?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type User_Telehealth_Mutation_Response = {
  __typename?: 'user_telehealth_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<User_Telehealth>;
};

export type User_Telehealth_Obj_Rel_Insert_Input = {
  data: User_Telehealth_Insert_Input;
  on_conflict?: InputMaybe<User_Telehealth_On_Conflict>;
};

export type User_Telehealth_On_Conflict = {
  constraint: User_Telehealth_Constraint;
  update_columns: Array<User_Telehealth_Update_Column>;
  where?: InputMaybe<User_Telehealth_Bool_Exp>;
};

export type User_Telehealth_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dav_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_appointments?: InputMaybe<Order_By>;
  min_days_between_appointments?: InputMaybe<Order_By>;
  pre_user?: InputMaybe<Pre_User_Order_By>;
  pre_user_id?: InputMaybe<Order_By>;
  recurrence_model?: InputMaybe<Order_By>;
  recurrence_start_date?: InputMaybe<Order_By>;
  subscription_expiration?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type User_Telehealth_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export enum User_Telehealth_Select_Column {
  Comment = 'comment',
  CreatedAt = 'created_at',
  DavId = 'dav_id',
  Id = 'id',
  MaxAppointments = 'max_appointments',
  MinDaysBetweenAppointments = 'min_days_between_appointments',
  PreUserId = 'pre_user_id',
  RecurrenceModel = 'recurrence_model',
  RecurrenceStartDate = 'recurrence_start_date',
  SubscriptionExpiration = 'subscription_expiration',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type User_Telehealth_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dav_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  max_appointments?: InputMaybe<Scalars['Int']>;
  min_days_between_appointments?: InputMaybe<Scalars['Int']>;
  pre_user_id?: InputMaybe<Scalars['String']>;
  recurrence_model?: InputMaybe<Recurrence_Model_Enum_Enum>;
  recurrence_start_date?: InputMaybe<Scalars['timestamptz']>;
  subscription_expiration?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type User_Telehealth_Stddev_Fields = {
  __typename?: 'user_telehealth_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  max_appointments?: Maybe<Scalars['Float']>;
  min_days_between_appointments?: Maybe<Scalars['Float']>;
};

export type User_Telehealth_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  max_appointments?: InputMaybe<Order_By>;
  min_days_between_appointments?: InputMaybe<Order_By>;
};

export type User_Telehealth_Stddev_Pop_Fields = {
  __typename?: 'user_telehealth_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  max_appointments?: Maybe<Scalars['Float']>;
  min_days_between_appointments?: Maybe<Scalars['Float']>;
};

export type User_Telehealth_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  max_appointments?: InputMaybe<Order_By>;
  min_days_between_appointments?: InputMaybe<Order_By>;
};

export type User_Telehealth_Stddev_Samp_Fields = {
  __typename?: 'user_telehealth_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  max_appointments?: Maybe<Scalars['Float']>;
  min_days_between_appointments?: Maybe<Scalars['Float']>;
};

export type User_Telehealth_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  max_appointments?: InputMaybe<Order_By>;
  min_days_between_appointments?: InputMaybe<Order_By>;
};

export type User_Telehealth_Sum_Fields = {
  __typename?: 'user_telehealth_sum_fields';
  id?: Maybe<Scalars['Int']>;
  max_appointments?: Maybe<Scalars['Int']>;
  min_days_between_appointments?: Maybe<Scalars['Int']>;
};

export type User_Telehealth_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  max_appointments?: InputMaybe<Order_By>;
  min_days_between_appointments?: InputMaybe<Order_By>;
};

export enum User_Telehealth_Update_Column {
  Comment = 'comment',
  CreatedAt = 'created_at',
  DavId = 'dav_id',
  Id = 'id',
  MaxAppointments = 'max_appointments',
  MinDaysBetweenAppointments = 'min_days_between_appointments',
  PreUserId = 'pre_user_id',
  RecurrenceModel = 'recurrence_model',
  RecurrenceStartDate = 'recurrence_start_date',
  SubscriptionExpiration = 'subscription_expiration',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
}

export type User_Telehealth_Var_Pop_Fields = {
  __typename?: 'user_telehealth_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  max_appointments?: Maybe<Scalars['Float']>;
  min_days_between_appointments?: Maybe<Scalars['Float']>;
};

export type User_Telehealth_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  max_appointments?: InputMaybe<Order_By>;
  min_days_between_appointments?: InputMaybe<Order_By>;
};

export type User_Telehealth_Var_Samp_Fields = {
  __typename?: 'user_telehealth_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  max_appointments?: Maybe<Scalars['Float']>;
  min_days_between_appointments?: Maybe<Scalars['Float']>;
};

export type User_Telehealth_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  max_appointments?: InputMaybe<Order_By>;
  min_days_between_appointments?: InputMaybe<Order_By>;
};

export type User_Telehealth_Variance_Fields = {
  __typename?: 'user_telehealth_variance_fields';
  id?: Maybe<Scalars['Float']>;
  max_appointments?: Maybe<Scalars['Float']>;
  min_days_between_appointments?: Maybe<Scalars['Float']>;
};

export type User_Telehealth_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  max_appointments?: InputMaybe<Order_By>;
  min_days_between_appointments?: InputMaybe<Order_By>;
};

export enum User_Update_Column {
  Alcohol = 'alcohol',
  AltEmail = 'alt_email',
  AltId = 'alt_id',
  AppVersion = 'app_version',
  AutogenicTraining = 'autogenic_training',
  Bathroom = 'bathroom',
  BehaviorActivation = 'behavior_activation',
  Birthdate = 'birthdate',
  Caffeine = 'caffeine',
  CanSendEmailReminder = 'can_send_email_reminder',
  CanSendWhatsappReminder = 'can_send_whatsapp_reminder',
  ChallengeCatastrophicThinking = 'challenge_catastrophic_thinking',
  Comment = 'comment',
  CreatedAt = 'created_at',
  CurrentJourneyId = 'current_journey_id',
  DayGoal = 'day_goal',
  DeepBreath = 'deep_breath',
  DesiredWakeUp = 'desired_wake_up',
  DeviceOs = 'device_os',
  Document = 'document',
  Dream = 'dream',
  Email = 'email',
  Enabled = 'enabled',
  Exercise = 'exercise',
  FirstName = 'first_name',
  Gender = 'gender',
  Gratitude = 'gratitude',
  HeavyMeal = 'heavy_meal',
  Id = 'id',
  Imagery = 'imagery',
  InternalId = 'internal_id',
  LastName = 'last_name',
  Light = 'light',
  LightTherapy = 'light_therapy',
  Meditation = 'meditation',
  Nap = 'nap',
  Nicotine = 'nicotine',
  NightGoal = 'night_goal',
  Noise = 'noise',
  Paid = 'paid',
  PaidValue = 'paid_value',
  Pain = 'pain',
  ParadoxicalIntention = 'paradoxical_intention',
  ParkingLot = 'parking_lot',
  Partner = 'partner',
  Phone = 'phone',
  Pmr = 'pmr',
  Points = 'points',
  ProfilePicture = 'profile_picture',
  PsqiState = 'psqi_state',
  Session_1State = 'session_1_state',
  Session_2State = 'session_2_state',
  Session_3State = 'session_3_state',
  Session_4State = 'session_4_state',
  Session_5State = 'session_5_state',
  Session_6State = 'session_6_state',
  Session_7State = 'session_7_state',
  Session_8State = 'session_8_state',
  Session_9State = 'session_9_state',
  Session_10State = 'session_10_state',
  Session_11State = 'session_11_state',
  Session_12State = 'session_12_state',
  Session_13State = 'session_13_state',
  Session_14State = 'session_14_state',
  Session_15State = 'session_15_state',
  Session_16State = 'session_16_state',
  Session_17State = 'session_17_state',
  Session_18State = 'session_18_state',
  Session_19State = 'session_19_state',
  Session_20State = 'session_20_state',
  Session_21State = 'session_21_state',
  Session_22State = 'session_22_state',
  Session_23State = 'session_23_state',
  Session_24State = 'session_24_state',
  Session_25State = 'session_25_state',
  Session_26State = 'session_26_state',
  Session_27State = 'session_27_state',
  Session_28State = 'session_28_state',
  Session_29State = 'session_29_state',
  Session_30State = 'session_30_state',
  Session_31State = 'session_31_state',
  Session_32State = 'session_32_state',
  Session_33State = 'session_33_state',
  Session_34State = 'session_34_state',
  Session_35State = 'session_35_state',
  Session_36State = 'session_36_state',
  Session_37State = 'session_37_state',
  Session_38State = 'session_38_state',
  Session_39State = 'session_39_state',
  Session_40State = 'session_40_state',
  Session_41State = 'session_41_state',
  Session_42State = 'session_42_state',
  Session_43State = 'session_43_state',
  Session_44State = 'session_44_state',
  Session_45State = 'session_45_state',
  Session_46State = 'session_46_state',
  Session_47State = 'session_47_state',
  Session_48State = 'session_48_state',
  Session_49State = 'session_49_state',
  Session_50State = 'session_50_state',
  Session_51State = 'session_51_state',
  Session_52State = 'session_52_state',
  Session_53State = 'session_53_state',
  Session_54State = 'session_54_state',
  Session_55State = 'session_55_state',
  Session_56State = 'session_56_state',
  Session_57State = 'session_57_state',
  Session_58State = 'session_58_state',
  Session_59State = 'session_59_state',
  Session_60State = 'session_60_state',
  SessionToPay = 'session_to_pay',
  SleepMedicine = 'sleep_medicine',
  SleepRestriction = 'sleep_restriction',
  StimulusControl = 'stimulus_control',
  SubscriptionExpiration = 'subscription_expiration',
  Temperature = 'temperature',
  ThoughtBlock = 'thought_block',
  TriedAlcohol = 'tried_alcohol',
  TriedMedicine = 'tried_medicine',
  TriedMelatonin = 'tried_melatonin',
  TriedOther = 'tried_other',
  TriedRestriction = 'tried_restriction',
  TriedSleepHygiene = 'tried_sleep_hygiene',
  UpdatedAt = 'updated_at',
}

export type User_Var_Pop_Fields = {
  __typename?: 'user_var_pop_fields';
  current_journey_id?: Maybe<Scalars['Float']>;
  day_goal?: Maybe<Scalars['Float']>;
  internal_id?: Maybe<Scalars['Float']>;
  night_goal?: Maybe<Scalars['Float']>;
  paid_value?: Maybe<Scalars['Float']>;
  points?: Maybe<Scalars['Float']>;
  psqi_state?: Maybe<Scalars['Float']>;
  session_1_state?: Maybe<Scalars['Float']>;
  session_2_state?: Maybe<Scalars['Float']>;
  session_3_state?: Maybe<Scalars['Float']>;
  session_4_state?: Maybe<Scalars['Float']>;
  session_5_state?: Maybe<Scalars['Float']>;
  session_6_state?: Maybe<Scalars['Float']>;
  session_7_state?: Maybe<Scalars['Float']>;
  session_8_state?: Maybe<Scalars['Float']>;
  session_9_state?: Maybe<Scalars['Float']>;
  session_10_state?: Maybe<Scalars['Float']>;
  session_11_state?: Maybe<Scalars['Float']>;
  session_12_state?: Maybe<Scalars['Float']>;
  session_13_state?: Maybe<Scalars['Float']>;
  session_14_state?: Maybe<Scalars['Float']>;
  session_15_state?: Maybe<Scalars['Float']>;
  session_16_state?: Maybe<Scalars['Float']>;
  session_17_state?: Maybe<Scalars['Float']>;
  session_18_state?: Maybe<Scalars['Float']>;
  session_19_state?: Maybe<Scalars['Float']>;
  session_20_state?: Maybe<Scalars['Float']>;
  session_21_state?: Maybe<Scalars['Float']>;
  session_22_state?: Maybe<Scalars['Float']>;
  session_23_state?: Maybe<Scalars['Float']>;
  session_24_state?: Maybe<Scalars['Float']>;
  session_25_state?: Maybe<Scalars['Float']>;
  session_26_state?: Maybe<Scalars['Float']>;
  session_27_state?: Maybe<Scalars['Float']>;
  session_28_state?: Maybe<Scalars['Float']>;
  session_29_state?: Maybe<Scalars['Float']>;
  session_30_state?: Maybe<Scalars['Float']>;
  session_31_state?: Maybe<Scalars['Float']>;
  session_32_state?: Maybe<Scalars['Float']>;
  session_33_state?: Maybe<Scalars['Float']>;
  session_34_state?: Maybe<Scalars['Float']>;
  session_35_state?: Maybe<Scalars['Float']>;
  session_36_state?: Maybe<Scalars['Float']>;
  session_37_state?: Maybe<Scalars['Float']>;
  session_38_state?: Maybe<Scalars['Float']>;
  session_39_state?: Maybe<Scalars['Float']>;
  session_40_state?: Maybe<Scalars['Float']>;
  session_41_state?: Maybe<Scalars['Float']>;
  session_42_state?: Maybe<Scalars['Float']>;
  session_43_state?: Maybe<Scalars['Float']>;
  session_44_state?: Maybe<Scalars['Float']>;
  session_45_state?: Maybe<Scalars['Float']>;
  session_46_state?: Maybe<Scalars['Float']>;
  session_47_state?: Maybe<Scalars['Float']>;
  session_48_state?: Maybe<Scalars['Float']>;
  session_49_state?: Maybe<Scalars['Float']>;
  session_50_state?: Maybe<Scalars['Float']>;
  session_51_state?: Maybe<Scalars['Float']>;
  session_52_state?: Maybe<Scalars['Float']>;
  session_53_state?: Maybe<Scalars['Float']>;
  session_54_state?: Maybe<Scalars['Float']>;
  session_55_state?: Maybe<Scalars['Float']>;
  session_56_state?: Maybe<Scalars['Float']>;
  session_57_state?: Maybe<Scalars['Float']>;
  session_58_state?: Maybe<Scalars['Float']>;
  session_59_state?: Maybe<Scalars['Float']>;
  session_60_state?: Maybe<Scalars['Float']>;
  session_to_pay?: Maybe<Scalars['Float']>;
};

export type User_Var_Pop_Order_By = {
  current_journey_id?: InputMaybe<Order_By>;
  day_goal?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  night_goal?: InputMaybe<Order_By>;
  paid_value?: InputMaybe<Order_By>;
  points?: InputMaybe<Order_By>;
  psqi_state?: InputMaybe<Order_By>;
  session_1_state?: InputMaybe<Order_By>;
  session_2_state?: InputMaybe<Order_By>;
  session_3_state?: InputMaybe<Order_By>;
  session_4_state?: InputMaybe<Order_By>;
  session_5_state?: InputMaybe<Order_By>;
  session_6_state?: InputMaybe<Order_By>;
  session_7_state?: InputMaybe<Order_By>;
  session_8_state?: InputMaybe<Order_By>;
  session_9_state?: InputMaybe<Order_By>;
  session_10_state?: InputMaybe<Order_By>;
  session_11_state?: InputMaybe<Order_By>;
  session_12_state?: InputMaybe<Order_By>;
  session_13_state?: InputMaybe<Order_By>;
  session_14_state?: InputMaybe<Order_By>;
  session_15_state?: InputMaybe<Order_By>;
  session_16_state?: InputMaybe<Order_By>;
  session_17_state?: InputMaybe<Order_By>;
  session_18_state?: InputMaybe<Order_By>;
  session_19_state?: InputMaybe<Order_By>;
  session_20_state?: InputMaybe<Order_By>;
  session_21_state?: InputMaybe<Order_By>;
  session_22_state?: InputMaybe<Order_By>;
  session_23_state?: InputMaybe<Order_By>;
  session_24_state?: InputMaybe<Order_By>;
  session_25_state?: InputMaybe<Order_By>;
  session_26_state?: InputMaybe<Order_By>;
  session_27_state?: InputMaybe<Order_By>;
  session_28_state?: InputMaybe<Order_By>;
  session_29_state?: InputMaybe<Order_By>;
  session_30_state?: InputMaybe<Order_By>;
  session_31_state?: InputMaybe<Order_By>;
  session_32_state?: InputMaybe<Order_By>;
  session_33_state?: InputMaybe<Order_By>;
  session_34_state?: InputMaybe<Order_By>;
  session_35_state?: InputMaybe<Order_By>;
  session_36_state?: InputMaybe<Order_By>;
  session_37_state?: InputMaybe<Order_By>;
  session_38_state?: InputMaybe<Order_By>;
  session_39_state?: InputMaybe<Order_By>;
  session_40_state?: InputMaybe<Order_By>;
  session_41_state?: InputMaybe<Order_By>;
  session_42_state?: InputMaybe<Order_By>;
  session_43_state?: InputMaybe<Order_By>;
  session_44_state?: InputMaybe<Order_By>;
  session_45_state?: InputMaybe<Order_By>;
  session_46_state?: InputMaybe<Order_By>;
  session_47_state?: InputMaybe<Order_By>;
  session_48_state?: InputMaybe<Order_By>;
  session_49_state?: InputMaybe<Order_By>;
  session_50_state?: InputMaybe<Order_By>;
  session_51_state?: InputMaybe<Order_By>;
  session_52_state?: InputMaybe<Order_By>;
  session_53_state?: InputMaybe<Order_By>;
  session_54_state?: InputMaybe<Order_By>;
  session_55_state?: InputMaybe<Order_By>;
  session_56_state?: InputMaybe<Order_By>;
  session_57_state?: InputMaybe<Order_By>;
  session_58_state?: InputMaybe<Order_By>;
  session_59_state?: InputMaybe<Order_By>;
  session_60_state?: InputMaybe<Order_By>;
  session_to_pay?: InputMaybe<Order_By>;
};

export type User_Var_Samp_Fields = {
  __typename?: 'user_var_samp_fields';
  current_journey_id?: Maybe<Scalars['Float']>;
  day_goal?: Maybe<Scalars['Float']>;
  internal_id?: Maybe<Scalars['Float']>;
  night_goal?: Maybe<Scalars['Float']>;
  paid_value?: Maybe<Scalars['Float']>;
  points?: Maybe<Scalars['Float']>;
  psqi_state?: Maybe<Scalars['Float']>;
  session_1_state?: Maybe<Scalars['Float']>;
  session_2_state?: Maybe<Scalars['Float']>;
  session_3_state?: Maybe<Scalars['Float']>;
  session_4_state?: Maybe<Scalars['Float']>;
  session_5_state?: Maybe<Scalars['Float']>;
  session_6_state?: Maybe<Scalars['Float']>;
  session_7_state?: Maybe<Scalars['Float']>;
  session_8_state?: Maybe<Scalars['Float']>;
  session_9_state?: Maybe<Scalars['Float']>;
  session_10_state?: Maybe<Scalars['Float']>;
  session_11_state?: Maybe<Scalars['Float']>;
  session_12_state?: Maybe<Scalars['Float']>;
  session_13_state?: Maybe<Scalars['Float']>;
  session_14_state?: Maybe<Scalars['Float']>;
  session_15_state?: Maybe<Scalars['Float']>;
  session_16_state?: Maybe<Scalars['Float']>;
  session_17_state?: Maybe<Scalars['Float']>;
  session_18_state?: Maybe<Scalars['Float']>;
  session_19_state?: Maybe<Scalars['Float']>;
  session_20_state?: Maybe<Scalars['Float']>;
  session_21_state?: Maybe<Scalars['Float']>;
  session_22_state?: Maybe<Scalars['Float']>;
  session_23_state?: Maybe<Scalars['Float']>;
  session_24_state?: Maybe<Scalars['Float']>;
  session_25_state?: Maybe<Scalars['Float']>;
  session_26_state?: Maybe<Scalars['Float']>;
  session_27_state?: Maybe<Scalars['Float']>;
  session_28_state?: Maybe<Scalars['Float']>;
  session_29_state?: Maybe<Scalars['Float']>;
  session_30_state?: Maybe<Scalars['Float']>;
  session_31_state?: Maybe<Scalars['Float']>;
  session_32_state?: Maybe<Scalars['Float']>;
  session_33_state?: Maybe<Scalars['Float']>;
  session_34_state?: Maybe<Scalars['Float']>;
  session_35_state?: Maybe<Scalars['Float']>;
  session_36_state?: Maybe<Scalars['Float']>;
  session_37_state?: Maybe<Scalars['Float']>;
  session_38_state?: Maybe<Scalars['Float']>;
  session_39_state?: Maybe<Scalars['Float']>;
  session_40_state?: Maybe<Scalars['Float']>;
  session_41_state?: Maybe<Scalars['Float']>;
  session_42_state?: Maybe<Scalars['Float']>;
  session_43_state?: Maybe<Scalars['Float']>;
  session_44_state?: Maybe<Scalars['Float']>;
  session_45_state?: Maybe<Scalars['Float']>;
  session_46_state?: Maybe<Scalars['Float']>;
  session_47_state?: Maybe<Scalars['Float']>;
  session_48_state?: Maybe<Scalars['Float']>;
  session_49_state?: Maybe<Scalars['Float']>;
  session_50_state?: Maybe<Scalars['Float']>;
  session_51_state?: Maybe<Scalars['Float']>;
  session_52_state?: Maybe<Scalars['Float']>;
  session_53_state?: Maybe<Scalars['Float']>;
  session_54_state?: Maybe<Scalars['Float']>;
  session_55_state?: Maybe<Scalars['Float']>;
  session_56_state?: Maybe<Scalars['Float']>;
  session_57_state?: Maybe<Scalars['Float']>;
  session_58_state?: Maybe<Scalars['Float']>;
  session_59_state?: Maybe<Scalars['Float']>;
  session_60_state?: Maybe<Scalars['Float']>;
  session_to_pay?: Maybe<Scalars['Float']>;
};

export type User_Var_Samp_Order_By = {
  current_journey_id?: InputMaybe<Order_By>;
  day_goal?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  night_goal?: InputMaybe<Order_By>;
  paid_value?: InputMaybe<Order_By>;
  points?: InputMaybe<Order_By>;
  psqi_state?: InputMaybe<Order_By>;
  session_1_state?: InputMaybe<Order_By>;
  session_2_state?: InputMaybe<Order_By>;
  session_3_state?: InputMaybe<Order_By>;
  session_4_state?: InputMaybe<Order_By>;
  session_5_state?: InputMaybe<Order_By>;
  session_6_state?: InputMaybe<Order_By>;
  session_7_state?: InputMaybe<Order_By>;
  session_8_state?: InputMaybe<Order_By>;
  session_9_state?: InputMaybe<Order_By>;
  session_10_state?: InputMaybe<Order_By>;
  session_11_state?: InputMaybe<Order_By>;
  session_12_state?: InputMaybe<Order_By>;
  session_13_state?: InputMaybe<Order_By>;
  session_14_state?: InputMaybe<Order_By>;
  session_15_state?: InputMaybe<Order_By>;
  session_16_state?: InputMaybe<Order_By>;
  session_17_state?: InputMaybe<Order_By>;
  session_18_state?: InputMaybe<Order_By>;
  session_19_state?: InputMaybe<Order_By>;
  session_20_state?: InputMaybe<Order_By>;
  session_21_state?: InputMaybe<Order_By>;
  session_22_state?: InputMaybe<Order_By>;
  session_23_state?: InputMaybe<Order_By>;
  session_24_state?: InputMaybe<Order_By>;
  session_25_state?: InputMaybe<Order_By>;
  session_26_state?: InputMaybe<Order_By>;
  session_27_state?: InputMaybe<Order_By>;
  session_28_state?: InputMaybe<Order_By>;
  session_29_state?: InputMaybe<Order_By>;
  session_30_state?: InputMaybe<Order_By>;
  session_31_state?: InputMaybe<Order_By>;
  session_32_state?: InputMaybe<Order_By>;
  session_33_state?: InputMaybe<Order_By>;
  session_34_state?: InputMaybe<Order_By>;
  session_35_state?: InputMaybe<Order_By>;
  session_36_state?: InputMaybe<Order_By>;
  session_37_state?: InputMaybe<Order_By>;
  session_38_state?: InputMaybe<Order_By>;
  session_39_state?: InputMaybe<Order_By>;
  session_40_state?: InputMaybe<Order_By>;
  session_41_state?: InputMaybe<Order_By>;
  session_42_state?: InputMaybe<Order_By>;
  session_43_state?: InputMaybe<Order_By>;
  session_44_state?: InputMaybe<Order_By>;
  session_45_state?: InputMaybe<Order_By>;
  session_46_state?: InputMaybe<Order_By>;
  session_47_state?: InputMaybe<Order_By>;
  session_48_state?: InputMaybe<Order_By>;
  session_49_state?: InputMaybe<Order_By>;
  session_50_state?: InputMaybe<Order_By>;
  session_51_state?: InputMaybe<Order_By>;
  session_52_state?: InputMaybe<Order_By>;
  session_53_state?: InputMaybe<Order_By>;
  session_54_state?: InputMaybe<Order_By>;
  session_55_state?: InputMaybe<Order_By>;
  session_56_state?: InputMaybe<Order_By>;
  session_57_state?: InputMaybe<Order_By>;
  session_58_state?: InputMaybe<Order_By>;
  session_59_state?: InputMaybe<Order_By>;
  session_60_state?: InputMaybe<Order_By>;
  session_to_pay?: InputMaybe<Order_By>;
};

export type User_Variance_Fields = {
  __typename?: 'user_variance_fields';
  current_journey_id?: Maybe<Scalars['Float']>;
  day_goal?: Maybe<Scalars['Float']>;
  internal_id?: Maybe<Scalars['Float']>;
  night_goal?: Maybe<Scalars['Float']>;
  paid_value?: Maybe<Scalars['Float']>;
  points?: Maybe<Scalars['Float']>;
  psqi_state?: Maybe<Scalars['Float']>;
  session_1_state?: Maybe<Scalars['Float']>;
  session_2_state?: Maybe<Scalars['Float']>;
  session_3_state?: Maybe<Scalars['Float']>;
  session_4_state?: Maybe<Scalars['Float']>;
  session_5_state?: Maybe<Scalars['Float']>;
  session_6_state?: Maybe<Scalars['Float']>;
  session_7_state?: Maybe<Scalars['Float']>;
  session_8_state?: Maybe<Scalars['Float']>;
  session_9_state?: Maybe<Scalars['Float']>;
  session_10_state?: Maybe<Scalars['Float']>;
  session_11_state?: Maybe<Scalars['Float']>;
  session_12_state?: Maybe<Scalars['Float']>;
  session_13_state?: Maybe<Scalars['Float']>;
  session_14_state?: Maybe<Scalars['Float']>;
  session_15_state?: Maybe<Scalars['Float']>;
  session_16_state?: Maybe<Scalars['Float']>;
  session_17_state?: Maybe<Scalars['Float']>;
  session_18_state?: Maybe<Scalars['Float']>;
  session_19_state?: Maybe<Scalars['Float']>;
  session_20_state?: Maybe<Scalars['Float']>;
  session_21_state?: Maybe<Scalars['Float']>;
  session_22_state?: Maybe<Scalars['Float']>;
  session_23_state?: Maybe<Scalars['Float']>;
  session_24_state?: Maybe<Scalars['Float']>;
  session_25_state?: Maybe<Scalars['Float']>;
  session_26_state?: Maybe<Scalars['Float']>;
  session_27_state?: Maybe<Scalars['Float']>;
  session_28_state?: Maybe<Scalars['Float']>;
  session_29_state?: Maybe<Scalars['Float']>;
  session_30_state?: Maybe<Scalars['Float']>;
  session_31_state?: Maybe<Scalars['Float']>;
  session_32_state?: Maybe<Scalars['Float']>;
  session_33_state?: Maybe<Scalars['Float']>;
  session_34_state?: Maybe<Scalars['Float']>;
  session_35_state?: Maybe<Scalars['Float']>;
  session_36_state?: Maybe<Scalars['Float']>;
  session_37_state?: Maybe<Scalars['Float']>;
  session_38_state?: Maybe<Scalars['Float']>;
  session_39_state?: Maybe<Scalars['Float']>;
  session_40_state?: Maybe<Scalars['Float']>;
  session_41_state?: Maybe<Scalars['Float']>;
  session_42_state?: Maybe<Scalars['Float']>;
  session_43_state?: Maybe<Scalars['Float']>;
  session_44_state?: Maybe<Scalars['Float']>;
  session_45_state?: Maybe<Scalars['Float']>;
  session_46_state?: Maybe<Scalars['Float']>;
  session_47_state?: Maybe<Scalars['Float']>;
  session_48_state?: Maybe<Scalars['Float']>;
  session_49_state?: Maybe<Scalars['Float']>;
  session_50_state?: Maybe<Scalars['Float']>;
  session_51_state?: Maybe<Scalars['Float']>;
  session_52_state?: Maybe<Scalars['Float']>;
  session_53_state?: Maybe<Scalars['Float']>;
  session_54_state?: Maybe<Scalars['Float']>;
  session_55_state?: Maybe<Scalars['Float']>;
  session_56_state?: Maybe<Scalars['Float']>;
  session_57_state?: Maybe<Scalars['Float']>;
  session_58_state?: Maybe<Scalars['Float']>;
  session_59_state?: Maybe<Scalars['Float']>;
  session_60_state?: Maybe<Scalars['Float']>;
  session_to_pay?: Maybe<Scalars['Float']>;
};

export type User_Variance_Order_By = {
  current_journey_id?: InputMaybe<Order_By>;
  day_goal?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  night_goal?: InputMaybe<Order_By>;
  paid_value?: InputMaybe<Order_By>;
  points?: InputMaybe<Order_By>;
  psqi_state?: InputMaybe<Order_By>;
  session_1_state?: InputMaybe<Order_By>;
  session_2_state?: InputMaybe<Order_By>;
  session_3_state?: InputMaybe<Order_By>;
  session_4_state?: InputMaybe<Order_By>;
  session_5_state?: InputMaybe<Order_By>;
  session_6_state?: InputMaybe<Order_By>;
  session_7_state?: InputMaybe<Order_By>;
  session_8_state?: InputMaybe<Order_By>;
  session_9_state?: InputMaybe<Order_By>;
  session_10_state?: InputMaybe<Order_By>;
  session_11_state?: InputMaybe<Order_By>;
  session_12_state?: InputMaybe<Order_By>;
  session_13_state?: InputMaybe<Order_By>;
  session_14_state?: InputMaybe<Order_By>;
  session_15_state?: InputMaybe<Order_By>;
  session_16_state?: InputMaybe<Order_By>;
  session_17_state?: InputMaybe<Order_By>;
  session_18_state?: InputMaybe<Order_By>;
  session_19_state?: InputMaybe<Order_By>;
  session_20_state?: InputMaybe<Order_By>;
  session_21_state?: InputMaybe<Order_By>;
  session_22_state?: InputMaybe<Order_By>;
  session_23_state?: InputMaybe<Order_By>;
  session_24_state?: InputMaybe<Order_By>;
  session_25_state?: InputMaybe<Order_By>;
  session_26_state?: InputMaybe<Order_By>;
  session_27_state?: InputMaybe<Order_By>;
  session_28_state?: InputMaybe<Order_By>;
  session_29_state?: InputMaybe<Order_By>;
  session_30_state?: InputMaybe<Order_By>;
  session_31_state?: InputMaybe<Order_By>;
  session_32_state?: InputMaybe<Order_By>;
  session_33_state?: InputMaybe<Order_By>;
  session_34_state?: InputMaybe<Order_By>;
  session_35_state?: InputMaybe<Order_By>;
  session_36_state?: InputMaybe<Order_By>;
  session_37_state?: InputMaybe<Order_By>;
  session_38_state?: InputMaybe<Order_By>;
  session_39_state?: InputMaybe<Order_By>;
  session_40_state?: InputMaybe<Order_By>;
  session_41_state?: InputMaybe<Order_By>;
  session_42_state?: InputMaybe<Order_By>;
  session_43_state?: InputMaybe<Order_By>;
  session_44_state?: InputMaybe<Order_By>;
  session_45_state?: InputMaybe<Order_By>;
  session_46_state?: InputMaybe<Order_By>;
  session_47_state?: InputMaybe<Order_By>;
  session_48_state?: InputMaybe<Order_By>;
  session_49_state?: InputMaybe<Order_By>;
  session_50_state?: InputMaybe<Order_By>;
  session_51_state?: InputMaybe<Order_By>;
  session_52_state?: InputMaybe<Order_By>;
  session_53_state?: InputMaybe<Order_By>;
  session_54_state?: InputMaybe<Order_By>;
  session_55_state?: InputMaybe<Order_By>;
  session_56_state?: InputMaybe<Order_By>;
  session_57_state?: InputMaybe<Order_By>;
  session_58_state?: InputMaybe<Order_By>;
  session_59_state?: InputMaybe<Order_By>;
  session_60_state?: InputMaybe<Order_By>;
  session_to_pay?: InputMaybe<Order_By>;
};

export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

export type Yes_No_Question_Enum = {
  __typename?: 'yes_no_question_enum';
  comment: Scalars['String'];
  text: Scalars['String'];
};

export type Yes_No_Question_Enum_Aggregate = {
  __typename?: 'yes_no_question_enum_aggregate';
  aggregate?: Maybe<Yes_No_Question_Enum_Aggregate_Fields>;
  nodes: Array<Yes_No_Question_Enum>;
};

export type Yes_No_Question_Enum_Aggregate_Fields = {
  __typename?: 'yes_no_question_enum_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Yes_No_Question_Enum_Max_Fields>;
  min?: Maybe<Yes_No_Question_Enum_Min_Fields>;
};

export type Yes_No_Question_Enum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Yes_No_Question_Enum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

export type Yes_No_Question_Enum_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Yes_No_Question_Enum_Max_Order_By>;
  min?: InputMaybe<Yes_No_Question_Enum_Min_Order_By>;
};

export type Yes_No_Question_Enum_Arr_Rel_Insert_Input = {
  data: Array<Yes_No_Question_Enum_Insert_Input>;
  on_conflict?: InputMaybe<Yes_No_Question_Enum_On_Conflict>;
};

export type Yes_No_Question_Enum_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Yes_No_Question_Enum_Bool_Exp>>>;
  _not?: InputMaybe<Yes_No_Question_Enum_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Yes_No_Question_Enum_Bool_Exp>>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
};

export enum Yes_No_Question_Enum_Constraint {
  YesNoQuestionEnumPkey = 'yes_no_question_enum_pkey',
}

export enum Yes_No_Question_Enum_Enum {
  DontKnow = 'DONT_KNOW',
  No = 'NO',
  Yes = 'YES',
}

export type Yes_No_Question_Enum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Yes_No_Question_Enum_Enum>;
  _in?: InputMaybe<Array<Yes_No_Question_Enum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Yes_No_Question_Enum_Enum>;
  _nin?: InputMaybe<Array<Yes_No_Question_Enum_Enum>>;
};

export type Yes_No_Question_Enum_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type Yes_No_Question_Enum_Max_Fields = {
  __typename?: 'yes_no_question_enum_max_fields';
  comment?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type Yes_No_Question_Enum_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Yes_No_Question_Enum_Min_Fields = {
  __typename?: 'yes_no_question_enum_min_fields';
  comment?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type Yes_No_Question_Enum_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Yes_No_Question_Enum_Mutation_Response = {
  __typename?: 'yes_no_question_enum_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Yes_No_Question_Enum>;
};

export type Yes_No_Question_Enum_Obj_Rel_Insert_Input = {
  data: Yes_No_Question_Enum_Insert_Input;
  on_conflict?: InputMaybe<Yes_No_Question_Enum_On_Conflict>;
};

export type Yes_No_Question_Enum_On_Conflict = {
  constraint: Yes_No_Question_Enum_Constraint;
  update_columns: Array<Yes_No_Question_Enum_Update_Column>;
  where?: InputMaybe<Yes_No_Question_Enum_Bool_Exp>;
};

export type Yes_No_Question_Enum_Order_By = {
  comment?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Yes_No_Question_Enum_Pk_Columns_Input = {
  text: Scalars['String'];
};

export enum Yes_No_Question_Enum_Select_Column {
  Comment = 'comment',
  Text = 'text',
}

export type Yes_No_Question_Enum_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export enum Yes_No_Question_Enum_Update_Column {
  Comment = 'comment',
  Text = 'text',
}

export type ApneaQuestionnaireMutationMutationVariables = Exact<{
  objects: Array<Apnea_Questionnaire_Insert_Input> | Apnea_Questionnaire_Insert_Input;
}>;

export type ApneaQuestionnaireMutationMutation = {
  __typename?: 'mutation_root';
  insert_apnea_questionnaire?: {
    __typename?: 'apnea_questionnaire_mutation_response';
    returning: Array<{ __typename?: 'apnea_questionnaire'; id: number }>;
  } | null;
};

export type GetAppoitmentByStartAndHcpQueryQueryVariables = Exact<{
  start?: InputMaybe<Scalars['timestamptz']>;
  hcpId: Scalars['String'];
}>;

export type GetAppoitmentByStartAndHcpQueryQuery = {
  __typename?: 'query_root';
  appointment: Array<{ __typename?: 'appointment'; id: string; user_id?: string | null; pre_user_id?: string | null }>;
};

export type GetAppointmentsStatusAndDataQueryQueryVariables = Exact<{
  start?: InputMaybe<Scalars['timestamptz']>;
}>;

export type GetAppointmentsStatusAndDataQueryQuery = {
  __typename?: 'query_root';
  appointment: Array<{
    __typename?: 'appointment';
    id: string;
    status: Appointment_Status_Enum_Enum;
    start: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    end: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    external_id?: string | null;
  }>;
};

export type AppointmentCancelMutationMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type AppointmentCancelMutationMutation = {
  __typename?: 'mutation_root';
  update_appointment_by_pk?: {
    __typename?: 'appointment';
    id: string;
    pre_user_id?: string | null;
    user_id?: string | null;
    status: Appointment_Status_Enum_Enum;
    health_carer_id: string;
    start: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    end: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  } | null;
};

export type AppointmentInsertMutationMutationVariables = Exact<{
  appointment: Appointment_Insert_Input;
}>;

export type AppointmentInsertMutationMutation = {
  __typename?: 'mutation_root';
  insert_appointment_one?: {
    __typename?: 'appointment';
    id: string;
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    end: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    health_carer_id: string;
    health_carer_link?: string | null;
    pre_user_id?: string | null;
    start: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    status: Appointment_Status_Enum_Enum;
    updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    user_id?: string | null;
    user_link?: string | null;
  } | null;
};

export type NextAppointmentFragmentFragment = {
  __typename?: 'appointment';
  end: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  health_carer_id: string;
  health_carer_link?: string | null;
  id: string;
  start: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  user_id?: string | null;
  user_link?: string | null;
  external_id?: string | null;
  health_carer: { __typename?: 'health_carer'; name?: string | null };
};

export type NextAppointmentForUserQueryQueryVariables = Exact<{
  id: Scalars['String'];
  start: Scalars['timestamptz'];
  end: Scalars['timestamptz'];
  now: Scalars['timestamptz'];
}>;

export type NextAppointmentForUserQueryQuery = {
  __typename?: 'query_root';
  appointment: Array<{
    __typename?: 'appointment';
    end: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    health_carer_id: string;
    health_carer_link?: string | null;
    id: string;
    start: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    user_id?: string | null;
    user_link?: string | null;
    external_id?: string | null;
    health_carer: { __typename?: 'health_carer'; name?: string | null };
  }>;
  last_appointment: Array<{
    __typename?: 'appointment';
    id: string;
    start: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    health_carer_id: string;
  }>;
  all_appointment_aggregate: {
    __typename?: 'appointment_aggregate';
    aggregate?: { __typename?: 'appointment_aggregate_fields'; count?: number | null } | null;
  };
  all_carried_out_appointment_aggregate: {
    __typename?: 'appointment_aggregate';
    aggregate?: { __typename?: 'appointment_aggregate_fields'; count?: number | null } | null;
  };
  appointment_aggregate: {
    __typename?: 'appointment_aggregate';
    aggregate?: { __typename?: 'appointment_aggregate_fields'; count?: number | null } | null;
  };
};

export type NextAppointmentForPreUserQueryQueryVariables = Exact<{
  id: Scalars['String'];
  start: Scalars['timestamptz'];
  end: Scalars['timestamptz'];
  now: Scalars['timestamptz'];
}>;

export type NextAppointmentForPreUserQueryQuery = {
  __typename?: 'query_root';
  appointment: Array<{
    __typename?: 'appointment';
    end: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    health_carer_id: string;
    health_carer_link?: string | null;
    id: string;
    start: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    user_id?: string | null;
    user_link?: string | null;
    external_id?: string | null;
    health_carer: { __typename?: 'health_carer'; name?: string | null };
  }>;
  last_appointment: Array<{
    __typename?: 'appointment';
    id: string;
    start: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    health_carer_id: string;
  }>;
  all_appointment_aggregate: {
    __typename?: 'appointment_aggregate';
    aggregate?: { __typename?: 'appointment_aggregate_fields'; count?: number | null } | null;
  };
  all_carried_out_appointment_aggregate: {
    __typename?: 'appointment_aggregate';
    aggregate?: { __typename?: 'appointment_aggregate_fields'; count?: number | null } | null;
  };
  appointment_aggregate: {
    __typename?: 'appointment_aggregate';
    aggregate?: { __typename?: 'appointment_aggregate_fields'; count?: number | null } | null;
  };
};

export type NextAppointmentQueryQueryVariables = Exact<{
  user_id: Scalars['String'];
  start: Scalars['timestamptz'];
}>;

export type NextAppointmentQueryQuery = {
  __typename?: 'query_root';
  appointment: Array<{
    __typename?: 'appointment';
    end: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    health_carer_id: string;
    health_carer_link?: string | null;
    id: string;
    start: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    user_id?: string | null;
    user_link?: string | null;
    external_id?: string | null;
    health_carer: { __typename?: 'health_carer'; name?: string | null };
  }>;
  appointment_aggregate: {
    __typename?: 'appointment_aggregate';
    aggregate?: { __typename?: 'appointment_aggregate_fields'; count?: number | null } | null;
  };
};

export type AppointmentQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type AppointmentQueryQuery = {
  __typename?: 'query_root';
  appointment_by_pk?: { __typename?: 'appointment'; external_id?: string | null; id: string } | null;
};

export type AppointmentUpdateMutationMutationVariables = Exact<{
  id: Scalars['String'];
  external_id: Scalars['String'];
  health_carer_link: Scalars['String'];
  user_link: Scalars['String'];
}>;

export type AppointmentUpdateMutationMutation = {
  __typename?: 'mutation_root';
  update_appointment_by_pk?: {
    __typename?: 'appointment';
    start: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    id: string;
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    external_id?: string | null;
    end: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    health_carer_id: string;
    health_carer_link?: string | null;
    user_link?: string | null;
    status: Appointment_Status_Enum_Enum;
    updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    user_id?: string | null;
    pre_user_id?: string | null;
  } | null;
};

export type AppointmentValidationUserTelehealthFragmentFragment = {
  __typename?: 'user_telehealth';
  dav_id?: string | null;
  max_appointments: number;
  min_days_between_appointments: number;
  user_id?: string | null;
};

export type AppointmentValidationAppointmentFragmentFragment = {
  __typename?: 'appointment';
  id: string;
  start: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  health_carer_id: string;
};

export type AppointmentValidationForUserQueryQueryVariables = Exact<{
  dav_id: Scalars['String'];
  id: Scalars['String'];
}>;

export type AppointmentValidationForUserQueryQuery = {
  __typename?: 'query_root';
  user_telehealth: Array<{
    __typename?: 'user_telehealth';
    dav_id?: string | null;
    max_appointments: number;
    min_days_between_appointments: number;
    user_id?: string | null;
  }>;
  appointment_aggregate: {
    __typename?: 'appointment_aggregate';
    aggregate?: { __typename?: 'appointment_aggregate_fields'; count?: number | null } | null;
  };
  appointment: Array<{
    __typename?: 'appointment';
    id: string;
    start: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    health_carer_id: string;
  }>;
};

export type AppointmentValidationForPreUserQueryQueryVariables = Exact<{
  dav_id: Scalars['String'];
  id: Scalars['String'];
}>;

export type AppointmentValidationForPreUserQueryQuery = {
  __typename?: 'query_root';
  user_telehealth: Array<{
    __typename?: 'user_telehealth';
    dav_id?: string | null;
    max_appointments: number;
    min_days_between_appointments: number;
    user_id?: string | null;
  }>;
  appointment_aggregate: {
    __typename?: 'appointment_aggregate';
    aggregate?: { __typename?: 'appointment_aggregate_fields'; count?: number | null } | null;
  };
  appointment: Array<{
    __typename?: 'appointment';
    id: string;
    start: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    health_carer_id: string;
  }>;
};

export type CatastrophicThinkingsInsertMutationMutationVariables = Exact<{
  objects: Array<Catastrophic_Thinkings_Insert_Input> | Catastrophic_Thinkings_Insert_Input;
}>;

export type CatastrophicThinkingsInsertMutationMutation = {
  __typename?: 'mutation_root';
  insert_catastrophic_thinkings?: {
    __typename?: 'catastrophic_thinkings_mutation_response';
    returning: Array<{ __typename?: 'catastrophic_thinkings'; id: number }>;
  } | null;
};

export type CheckCouponExistenceQueryQueryVariables = Exact<{
  couponId: Scalars['String'];
}>;

export type CheckCouponExistenceQueryQuery = { __typename?: 'query_root'; coupon: Array<{ __typename?: 'coupon'; id: number }> };

export type CommunityUserInsertMutationMutationVariables = Exact<{
  object: Community_User_Insert_Input;
}>;

export type CommunityUserInsertMutationMutation = {
  __typename?: 'mutation_root';
  insert_community_user_one?: { __typename?: 'community_user'; community_id: number } | null;
};

export type CompanyHcpInsertMutationMutationVariables = Exact<{
  object: Company_Health_Carer_Insert_Input;
}>;

export type CompanyHcpInsertMutationMutation = {
  __typename?: 'mutation_root';
  insert_company_health_carer_one?: { __typename?: 'company_health_carer'; company_id: number; health_carer_id: string } | null;
};

export type SpecialCompanyHealthCarersQueryQueryVariables = Exact<{
  company_id: Scalars['Int'];
}>;

export type SpecialCompanyHealthCarersQueryQuery = {
  __typename?: 'query_root';
  company_health_carer: Array<{
    __typename?: 'company_health_carer';
    health_carer_id: string;
    kind?: Company_Health_Carer_Kind_Enum_Enum | null;
  }>;
};

export type CompanyUserInsertMutationMutationVariables = Exact<{
  company_user: Company_User_Insert_Input;
  userId: Scalars['String'];
}>;

export type CompanyUserInsertMutationMutation = {
  __typename?: 'mutation_root';
  insert_user_one?: { __typename?: 'user'; id: string } | null;
  insert_company_user_one?: { __typename?: 'company_user'; company_id: number; user_id: string } | null;
};

export type CouponAndUserCouponsQueryQueryVariables = Exact<{
  userId: Scalars['String'];
  couponId: Scalars['String'];
}>;

export type CouponAndUserCouponsQueryQuery = {
  __typename?: 'query_root';
  user_by_pk?: {
    __typename?: 'user';
    id: string;
    first_name?: string | null;
    last_name?: string | null;
    gender?: string | null;
    email?: string | null;
    user_coupons: Array<{ __typename?: 'user_coupon'; coupon_id: number }>;
  } | null;
  coupon: Array<{
    __typename?: 'coupon';
    id: number;
    code: string;
    comment?: string | null;
    expiration_date?: `${number}-${number}-${number}` | null;
    kind?: string | null;
    quantity?: number | null;
    value?: string | null;
    redeem_message: string;
    name: string;
    active: boolean;
    owner_hcp?: string | null;
    user_key_value?: unknown | null;
    extra_data?: unknown | null;
    owner_company?: number | null;
    hcp?: { __typename?: 'health_carer'; name?: string | null; kind?: string | null; gender?: string | null } | null;
    coupon_users_aggregate: {
      __typename?: 'user_coupon_aggregate';
      aggregate?: { __typename?: 'user_coupon_aggregate_fields'; count?: number | null } | null;
    };
  }>;
};

export type CouponIdByCodeQueryQueryVariables = Exact<{
  code: Scalars['String'];
}>;

export type CouponIdByCodeQueryQuery = { __typename?: 'query_root'; coupon: Array<{ __typename?: 'coupon'; id: number }> };

export type CouponInsertMutationMutationVariables = Exact<{
  coupon: Coupon_Insert_Input;
}>;

export type CouponInsertMutationMutation = {
  __typename?: 'mutation_root';
  insert_coupon_one?: { __typename?: 'coupon'; id: number } | null;
};

export type UserCouponsQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type UserCouponsQueryQuery = {
  __typename?: 'query_root';
  user_by_pk?: {
    __typename?: 'user';
    id: string;
    user_coupons: Array<{
      __typename?: 'user_coupon';
      user_id: string;
      coupon_id: number;
      coupon?: {
        __typename?: 'coupon';
        id: number;
        code: string;
        expiration_date?: `${number}-${number}-${number}` | null;
        kind?: string | null;
        owner_hcp?: string | null;
      } | null;
    }>;
  } | null;
};

export type CouponUpdateMutationMutationVariables = Exact<{
  code: Scalars['String'];
  set: Coupon_Set_Input;
}>;

export type CouponUpdateMutationMutation = {
  __typename?: 'mutation_root';
  update_coupon_by_pk?: {
    __typename?: 'coupon';
    code: string;
    expiration_date?: `${number}-${number}-${number}` | null;
    kind?: string | null;
    value?: string | null;
    name: string;
    redeem_message: string;
    owner_hcp?: string | null;
    owner_company?: number | null;
  } | null;
};

export type CrmCouponQueryQueryVariables = Exact<{
  coupon: Scalars['String'];
}>;

export type CrmCouponQueryQuery = {
  __typename?: 'query_root';
  coupon: Array<{
    __typename?: 'coupon';
    id: number;
    code: string;
    owner_company?: number | null;
    coupon_users: Array<{ __typename?: 'user_coupon'; user_id: string }>;
  }>;
};

export type DreamInsertMutationMutationVariables = Exact<{
  objects: Array<Dream_Insert_Input> | Dream_Insert_Input;
}>;

export type DreamInsertMutationMutation = {
  __typename?: 'mutation_root';
  insert_dream?: { __typename?: 'dream_mutation_response'; returning: Array<{ __typename?: 'dream'; id: number }> } | null;
};

export type EficiencyInsertMutationMutationVariables = Exact<{
  objects: Array<Eficiency_Insert_Input> | Eficiency_Insert_Input;
}>;

export type EficiencyInsertMutationMutation = {
  __typename?: 'mutation_root';
  insert_eficiency?: { __typename?: 'eficiency_mutation_response'; returning: Array<{ __typename?: 'eficiency'; id: number }> } | null;
};

export type EpworthInsertMutationMutationVariables = Exact<{
  objects: Array<Epworth_Insert_Input> | Epworth_Insert_Input;
}>;

export type EpworthInsertMutationMutation = {
  __typename?: 'mutation_root';
  insert_epworth?: { __typename?: 'epworth_mutation_response'; returning: Array<{ __typename?: 'epworth'; id: number }> } | null;
};

export type FetchUserSessionsQueryQueryVariables = Exact<{
  limit: Scalars['Int'];
  internal_id: Scalars['Int'];
}>;

export type FetchUserSessionsQueryQuery = {
  __typename?: 'query_root';
  user: Array<{
    __typename?: 'user';
    internal_id: number;
    current_journey_id?: number | null;
    id: string;
    session_1_state: number;
    session_2_state: number;
    session_3_state: number;
    session_4_state: number;
    session_5_state: number;
    session_6_state: number;
    session_7_state: number;
    session_8_state: number;
    session_9_state: number;
    session_10_state: number;
    session_11_state: number;
    session_12_state: number;
    session_13_state: number;
    session_14_state: number;
    session_15_state: number;
    session_16_state: number;
    session_17_state: number;
    session_18_state: number;
    session_19_state: number;
    session_20_state: number;
    session_21_state: number;
    session_22_state: number;
    session_23_state: number;
    session_24_state: number;
    session_25_state: number;
    session_26_state: number;
    session_27_state: number;
    session_28_state: number;
    session_29_state: number;
    session_30_state: number;
    session_31_state: number;
    session_32_state: number;
    session_33_state: number;
    session_34_state: number;
    session_35_state: number;
    session_36_state: number;
    session_37_state: number;
    session_38_state: number;
    session_39_state: number;
    session_40_state: number;
    session_41_state: number;
    session_42_state: number;
    session_43_state: number;
    session_44_state: number;
    session_45_state: number;
    session_46_state: number;
    session_47_state: number;
    session_48_state: number;
    session_49_state: number;
    session_50_state: number;
    session_51_state: number;
    session_52_state: number;
    session_53_state: number;
    session_54_state: number;
    session_55_state: number;
    session_56_state: number;
    session_57_state: number;
    session_58_state: number;
    session_59_state: number;
    session_60_state: number;
  }>;
};

export type GadInsertMutationMutationVariables = Exact<{
  objects: Array<Gad_Insert_Input> | Gad_Insert_Input;
}>;

export type GadInsertMutationMutation = {
  __typename?: 'mutation_root';
  insert_gad?: { __typename?: 'gad_mutation_response'; returning: Array<{ __typename?: 'gad'; id: number }> } | null;
};

export type Gad7InsertMutationMutationVariables = Exact<{
  objects: Array<Gad7_Insert_Input> | Gad7_Insert_Input;
}>;

export type Gad7InsertMutationMutation = {
  __typename?: 'mutation_root';
  insert_gad7?: { __typename?: 'gad7_mutation_response'; returning: Array<{ __typename?: 'gad7'; id: number }> } | null;
};

export type FirstGad7QueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type FirstGad7QueryQuery = {
  __typename?: 'query_root';
  gad7: Array<{
    __typename?: 'gad7';
    id: number;
    user_id: string;
    q1: number;
    q2: number;
    q3: number;
    q4: number;
    q5: number;
    q6: number;
    q7: number;
  }>;
};

export type InsertGenericQuestionnaireMutationVariables = Exact<{
  object: Generic_Questionnaire_Insert_Input;
}>;

export type InsertGenericQuestionnaireMutation = {
  __typename?: 'mutation_root';
  insert_generic_questionnaire_one?: { __typename?: 'generic_questionnaire'; id: number } | null;
};

export type GetAppointmentByHcpAndStartQueryQueryVariables = Exact<{
  hcpId: Scalars['String'];
  start: Scalars['timestamptz'];
  end: Scalars['timestamptz'];
}>;

export type GetAppointmentByHcpAndStartQueryQuery = {
  __typename?: 'query_root';
  appointment: Array<{ __typename?: 'appointment'; id: string }>;
};

export type GetFutureAppointmetsQueryFragmentFragment = {
  __typename?: 'appointment';
  id: string;
  external_id?: string | null;
  start: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
};

export type GetFutureAppointmetsForUserQueryQueryVariables = Exact<{
  id: Scalars['String'];
  after: Scalars['timestamptz'];
}>;

export type GetFutureAppointmetsForUserQueryQuery = {
  __typename?: 'query_root';
  appointment: Array<{
    __typename?: 'appointment';
    id: string;
    external_id?: string | null;
    start: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
};

export type GetFutureAppointmetsForPreUserQueryQueryVariables = Exact<{
  id: Scalars['String'];
  after: Scalars['timestamptz'];
}>;

export type GetFutureAppointmetsForPreUserQueryQuery = {
  __typename?: 'query_root';
  appointment: Array<{
    __typename?: 'appointment';
    id: string;
    external_id?: string | null;
    start: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
};

export type GoogleSheetsHasuraMutationVariables = Exact<{
  object: Google_Sheet_Row_Insert_Input;
}>;

export type GoogleSheetsHasuraMutation = {
  __typename?: 'mutation_root';
  insert_google_sheet_row_one?: { __typename?: 'google_sheet_row'; id: number } | null;
};

export type GratitudeInsertMutationMutationVariables = Exact<{
  objects: Array<Gratitude_Insert_Input> | Gratitude_Insert_Input;
}>;

export type GratitudeInsertMutationMutation = {
  __typename?: 'mutation_root';
  insert_gratitude?: { __typename?: 'gratitude_mutation_response'; returning: Array<{ __typename?: 'gratitude'; id: number }> } | null;
};

export type SearchCouponsByHcpIdOrCodeQueryQueryVariables = Exact<{
  owner_hcps?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  codes?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type SearchCouponsByHcpIdOrCodeQueryQuery = {
  __typename?: 'query_root';
  coupon: Array<{
    __typename?: 'coupon';
    owner_hcp?: string | null;
    code: string;
    kind?: string | null;
    hcp?: { __typename?: 'health_carer'; kind?: string | null; name?: string | null } | null;
  }>;
};

export type SearchHcpQueryQueryVariables = Exact<{
  query: Scalars['String'];
}>;

export type SearchHcpQueryQuery = {
  __typename?: 'query_root';
  health_carer: Array<{ __typename?: 'health_carer'; id: string; name?: string | null }>;
  crm: Array<{ __typename?: 'crm'; name: string; identification: string; state: string }>;
};

export type InsertHealthCarerMoneyRequestMutationMutationVariables = Exact<{
  object: Health_Carer_Money_Request_Insert_Input;
}>;

export type InsertHealthCarerMoneyRequestMutationMutation = {
  __typename?: 'mutation_root';
  insert_health_carer_money_request_one?: { __typename?: 'health_carer_money_request'; id: number; extra_data?: unknown | null } | null;
};

export type HealthCarerMoneyRequestQueryQueryVariables = Exact<{
  user_id: Scalars['String'];
}>;

export type HealthCarerMoneyRequestQueryQuery = {
  __typename?: 'query_root';
  health_carer_by_pk?: { __typename?: 'health_carer'; name?: string | null; email: string; source?: string | null } | null;
  health_carer_money_request: Array<{ __typename?: 'health_carer_money_request'; id: number; extra_data?: unknown | null; amount: number }>;
  health_carer_transactions_aggregate: {
    __typename?: 'health_carer_transactions_aggregate';
    aggregate?: {
      __typename?: 'health_carer_transactions_aggregate_fields';
      sum?: { __typename?: 'health_carer_transactions_sum_fields'; amount?: number | null } | null;
    } | null;
  };
};

export type TransferHealthCarerMoneyMutationMutationVariables = Exact<{
  objects: Array<Health_Carer_Transactions_Insert_Input> | Health_Carer_Transactions_Insert_Input;
}>;

export type TransferHealthCarerMoneyMutationMutation = {
  __typename?: 'mutation_root';
  insert_health_carer_transactions?: {
    __typename?: 'health_carer_transactions_mutation_response';
    returning: Array<{ __typename?: 'health_carer_transactions'; id: number; description: string }>;
  } | null;
};

export type TransferHealthCarerMoneyWithRequestIdMutationMutationVariables = Exact<{
  objects: Array<Health_Carer_Transactions_Insert_Input> | Health_Carer_Transactions_Insert_Input;
  request_id: Scalars['bigint'];
}>;

export type TransferHealthCarerMoneyWithRequestIdMutationMutation = {
  __typename?: 'mutation_root';
  insert_health_carer_transactions?: {
    __typename?: 'health_carer_transactions_mutation_response';
    returning: Array<{ __typename?: 'health_carer_transactions'; id: number; description: string }>;
  } | null;
  update_health_carer_money_request_by_pk?: { __typename?: 'health_carer_money_request'; id: number } | null;
};

export type HealthCarerPagarmeRecipientByExternalIdQueryQueryVariables = Exact<{
  recipient_id: Scalars['String'];
}>;

export type HealthCarerPagarmeRecipientByExternalIdQueryQuery = {
  __typename?: 'query_root';
  health_carer_pagarme_recipient: Array<{
    __typename?: 'health_carer_pagarme_recipient';
    pagarme_recipient_id: string;
    health_carer_id: string;
  }>;
};

export type InsertHealthCarerPagarmeRecipientMutationMutationVariables = Exact<{
  object: Health_Carer_Pagarme_Recipient_Insert_Input;
}>;

export type InsertHealthCarerPagarmeRecipientMutationMutation = {
  __typename?: 'mutation_root';
  insert_health_carer_pagarme_recipient_one?: {
    __typename?: 'health_carer_pagarme_recipient';
    pagarme_recipient_id: string;
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  } | null;
};

export type GetHealthCarerPagarmeRecipientIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetHealthCarerPagarmeRecipientIdQuery = {
  __typename?: 'query_root';
  health_carer_pagarme_recipient_by_pk?: { __typename?: 'health_carer_pagarme_recipient'; pagarme_recipient_id: string } | null;
};

export type HealthCarerTransactionsBalanceQueryQueryVariables = Exact<{
  user_id: Scalars['String'];
}>;

export type HealthCarerTransactionsBalanceQueryQuery = {
  __typename?: 'query_root';
  health_carer_transactions_aggregate: {
    __typename?: 'health_carer_transactions_aggregate';
    aggregate?: {
      __typename?: 'health_carer_transactions_aggregate_fields';
      sum?: { __typename?: 'health_carer_transactions_sum_fields'; amount?: number | null } | null;
    } | null;
  };
};

export type HealthCarerTransactionsForHealthCarerAndPatientTupleQueryQueryVariables = Exact<{
  extraData: Scalars['jsonb'];
}>;

export type HealthCarerTransactionsForHealthCarerAndPatientTupleQueryQuery = {
  __typename?: 'query_root';
  health_carer_transactions: Array<{ __typename?: 'health_carer_transactions'; id: number }>;
};

export type InserHealthCarerTransactionsMutationMutationVariables = Exact<{
  objects: Array<Health_Carer_Transactions_Insert_Input> | Health_Carer_Transactions_Insert_Input;
}>;

export type InserHealthCarerTransactionsMutationMutation = {
  __typename?: 'mutation_root';
  insert_health_carer_transactions?: {
    __typename?: 'health_carer_transactions_mutation_response';
    returning: Array<{
      __typename?: 'health_carer_transactions';
      amount: number;
      description: string;
      id: number;
      health_carer_id: string;
      from_user_id?: string | null;
    }>;
  } | null;
};

export type HealthCarerUserByUserQueryQueryVariables = Exact<{
  user_id: Scalars['String'];
}>;

export type HealthCarerUserByUserQueryQuery = {
  __typename?: 'query_root';
  health_carer_user: Array<{ __typename?: 'health_carer_user'; id: number; health_carer_id: string; user_id: string }>;
};

export type HealthCarerUserInsertMutationMutationVariables = Exact<{
  object: Health_Carer_User_Insert_Input;
  userId: Scalars['String'];
}>;

export type HealthCarerUserInsertMutationMutation = {
  __typename?: 'mutation_root';
  insert_user_one?: { __typename?: 'user'; id: string } | null;
  insert_health_carer_user_one?: {
    __typename?: 'health_carer_user';
    health_carer_id: string;
    user_id: string;
    user: { __typename?: 'user'; id: string; first_name?: string | null; last_name?: string | null; gender?: string | null };
    health_carer: {
      __typename?: 'health_carer';
      id: string;
      name?: string | null;
      kind?: string | null;
      gender?: string | null;
      email: string;
      users_aggregate: {
        __typename?: 'health_carer_user_aggregate';
        aggregate?: { __typename?: 'health_carer_user_aggregate_fields'; count?: number | null } | null;
      };
    };
  } | null;
};

export type CheckHealthCarerExistenceQueryQueryVariables = Exact<{
  email: Scalars['String'];
  name: Scalars['String'];
  coupons: Array<Scalars['String']> | Scalars['String'];
  professional_id: Scalars['String'];
  professional_id_state: Scalars['String'];
  kind: Scalars['String'];
}>;

export type CheckHealthCarerExistenceQueryQuery = {
  __typename?: 'query_root';
  health_carer: Array<{
    __typename?: 'health_carer';
    id: string;
    email: string;
    name?: string | null;
    share_path?: string | null;
    professional_id?: string | null;
    professional_id_state?: string | null;
    kind?: string | null;
  }>;
  coupon: Array<{ __typename?: 'coupon'; id: number; code: string }>;
};

export type HealthCarerInsertMutationMutationVariables = Exact<{
  hcp: Health_Carer_Insert_Input;
}>;

export type HealthCarerInsertMutationMutation = {
  __typename?: 'mutation_root';
  insert_health_carer_one?: { __typename?: 'health_carer'; id: string } | null;
};

export type HealthCarerUpdateMutationMutationVariables = Exact<{
  id: Scalars['String'];
  gender: Scalars['String'];
  name: Scalars['String'];
  whatsapp?: InputMaybe<Scalars['String']>;
}>;

export type HealthCarerUpdateMutationMutation = {
  __typename?: 'mutation_root';
  update_health_carer_by_pk?: {
    __typename?: 'health_carer';
    name?: string | null;
    gender?: string | null;
    whatsapp?: string | null;
  } | null;
};

export type HealthCarerAndHealthCarerPagarmeRecipientUpdateMutationMutationVariables = Exact<{
  id: Scalars['String'];
  gender: Scalars['String'];
  name: Scalars['String'];
  whatsapp?: InputMaybe<Scalars['String']>;
  bank_account: Health_Carer_Pagarme_Recipient_Set_Input;
}>;

export type HealthCarerAndHealthCarerPagarmeRecipientUpdateMutationMutation = {
  __typename?: 'mutation_root';
  update_health_carer_by_pk?: {
    __typename?: 'health_carer';
    name?: string | null;
    gender?: string | null;
    whatsapp?: string | null;
  } | null;
  update_health_carer_pagarme_recipient_by_pk?: {
    __typename?: 'health_carer_pagarme_recipient';
    document: string;
    account_check_digit: string;
    account_number: string;
    account_type: string;
    bank: string;
    branch_check_digit: string;
    branch_number: string;
  } | null;
};

export type HealthCarerUserCountQueryQueryVariables = Exact<{
  health_carer_id: Scalars['String'];
}>;

export type HealthCarerUserCountQueryQuery = {
  __typename?: 'query_root';
  health_carer_by_pk?: {
    __typename?: 'health_carer';
    id: string;
    name?: string | null;
    membership_kind: Health_Carer_Membership_Kind_Enum_Enum;
  } | null;
  health_carer_user_aggregate: {
    __typename?: 'health_carer_user_aggregate';
    aggregate?: { __typename?: 'health_carer_user_aggregate_fields'; count?: number | null } | null;
  };
};

export type InsertCouponMutationMutationVariables = Exact<{
  coupon_insert_input: Array<Coupon_Insert_Input> | Coupon_Insert_Input;
}>;

export type InsertCouponMutationMutation = {
  __typename?: 'mutation_root';
  insert_coupon?: {
    __typename?: 'coupon_mutation_response';
    affected_rows: number;
    returning: Array<{ __typename?: 'coupon'; code: string }>;
  } | null;
};

export type InsertPreUserOneMutationMutationVariables = Exact<{
  object: Pre_User_Insert_Input;
}>;

export type InsertPreUserOneMutationMutation = {
  __typename?: 'mutation_root';
  insert_pre_user_one?: {
    __typename?: 'pre_user';
    id: string;
    email?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    user_id?: string | null;
    source: Pre_User_Source_Enum_Enum;
  } | null;
};

export type InserUserReminderInfoMutationMutationVariables = Exact<{
  user_reminder_info: User_Reminder_Info_Insert_Input;
}>;

export type InserUserReminderInfoMutationMutation = {
  __typename?: 'mutation_root';
  insert_user_reminder_info_one?: { __typename?: 'user_reminder_info'; messenger_id: string } | null;
};

export type InsertApneaScanPhotoMutationMutationVariables = Exact<{
  object: Apnea_Scan_Photo_Insert_Input;
}>;

export type InsertApneaScanPhotoMutationMutation = {
  __typename?: 'mutation_root';
  insert_apnea_scan_photo_one?: { __typename?: 'apnea_scan_photo'; id: number } | null;
};

export type IsiInsertMutationMutationVariables = Exact<{
  objects: Array<Isi_Insert_Input> | Isi_Insert_Input;
}>;

export type IsiInsertMutationMutation = {
  __typename?: 'mutation_root';
  insert_isi?: { __typename?: 'isi_mutation_response'; returning: Array<{ __typename?: 'isi'; id: number }> } | null;
};

export type FirstIsiQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type FirstIsiQueryQuery = {
  __typename?: 'query_root';
  isi: Array<{
    __typename?: 'isi';
    id: number;
    user_id: string;
    q1: number;
    q2: number;
    q3: number;
    q4: number;
    q5: number;
    q6: number;
    q7: number;
    age?: number | null;
  }>;
};

export type LastIsiQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type LastIsiQueryQuery = {
  __typename?: 'query_root';
  isi: Array<{
    __typename?: 'isi';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    id: number;
    user_id: string;
  }>;
};

export type KuppermanInsertMutationMutationVariables = Exact<{
  objects: Array<Kupperman_Insert_Input> | Kupperman_Insert_Input;
}>;

export type KuppermanInsertMutationMutation = {
  __typename?: 'mutation_root';
  insert_kupperman?: { __typename?: 'kupperman_mutation_response'; returning: Array<{ __typename?: 'kupperman'; id: number }> } | null;
};

export type FirstKuppermanQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type FirstKuppermanQueryQuery = {
  __typename?: 'query_root';
  kupperman: Array<{
    __typename?: 'kupperman';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    id: number;
    q1: number;
    q2: number;
    q3: number;
    q4: number;
    q5: number;
    q6: number;
    q7: number;
    q8: number;
    q9: number;
    q10: number;
    q11: number;
    q12: number;
    q13: number;
    user_id: string;
  }>;
};

export type LandingPageRedirectMutationMutationVariables = Exact<{
  object: Landing_Page_Redirect_Insert_Input;
}>;

export type LandingPageRedirectMutationMutation = {
  __typename?: 'mutation_root';
  insert_landing_page_redirect_one?: {
    __typename?: 'landing_page_redirect';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  } | null;
};

export type LandingPageRedirectQueryQueryVariables = Exact<{
  from_path: Scalars['String'];
}>;

export type LandingPageRedirectQueryQuery = {
  __typename?: 'query_root';
  landing_page_redirect: Array<{ __typename?: 'landing_page_redirect'; from_path: string; to_path: string }>;
};

export type LastSleepRestrictionTimeInBedQueryQueryVariables = Exact<{
  id: Scalars['String'];
  date?: InputMaybe<Scalars['date']>;
}>;

export type LastSleepRestrictionTimeInBedQueryQuery = {
  __typename?: 'query_root';
  sleep_restriction: Array<{ __typename?: 'sleep_restriction'; new_time_in_bed: number }>;
};

export type LastSleepRestrictionQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type LastSleepRestrictionQueryQuery = {
  __typename?: 'query_root';
  sleep_restriction: Array<{
    __typename?: 'sleep_restriction';
    start_date: `${number}-${number}-${number}`;
    desired_wake_up: `${number}:${number}:${number}`;
    hour_to_sleep: `${number}:${number}:${number}`;
    new_time_in_bed: number;
    before_time_in_bed: number;
    before_time_sleep: number;
  }>;
  user_by_pk?: { __typename?: 'user'; id: string; sleep_restriction?: boolean | null } | null;
};

export type InsertLogMutationMutationVariables = Exact<{
  objects: Array<Log_Insert_Input> | Log_Insert_Input;
}>;

export type InsertLogMutationMutation = {
  __typename?: 'mutation_root';
  insert_log?: { __typename?: 'log_mutation_response'; affected_rows: number } | null;
};

export type LogMostRecentQueryQueryVariables = Exact<{
  where: Log_Bool_Exp;
}>;

export type LogMostRecentQueryQuery = {
  __typename?: 'query_root';
  log: Array<{
    __typename?: 'log';
    id: number;
    data?: unknown | null;
    created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    messenger_id?: string | null;
    type: Log_Type_Enum_Enum;
  }>;
};

export type LogTypeCountQueryQueryVariables = Exact<{
  type: Log_Type_Enum_Enum;
  user: Scalars['String'];
}>;

export type LogTypeCountQueryQuery = {
  __typename?: 'query_root';
  log_aggregate: { __typename?: 'log_aggregate'; aggregate?: { __typename?: 'log_aggregate_fields'; count?: number | null } | null };
};

export type MunichChronotypeCoreInsertMutationMutationVariables = Exact<{
  objects: Array<Munich_Chronotype_Core_Insert_Input> | Munich_Chronotype_Core_Insert_Input;
}>;

export type MunichChronotypeCoreInsertMutationMutation = {
  __typename?: 'mutation_root';
  insert_munich_chronotype_core?: {
    __typename?: 'munich_chronotype_core_mutation_response';
    returning: Array<{ __typename?: 'munich_chronotype_core'; id: number }>;
  } | null;
};

export type MunichChronotypeInsertMutationMutationVariables = Exact<{
  objects: Array<Munich_Chronotype_Insert_Input> | Munich_Chronotype_Insert_Input;
}>;

export type MunichChronotypeInsertMutationMutation = {
  __typename?: 'mutation_root';
  insert_munich_chronotype?: {
    __typename?: 'munich_chronotype_mutation_response';
    returning: Array<{ __typename?: 'munich_chronotype'; id: number }>;
  } | null;
};

export type AchievedGoalsFromSleepDiaryPart1QueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type AchievedGoalsFromSleepDiaryPart1QueryQuery = {
  __typename?: 'query_root';
  user: Array<{
    __typename?: 'user';
    sleep_medicine: boolean;
    day_goal?: number | null;
    night_goal?: number | null;
    created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
  }>;
  sleep_diary: Array<{
    __typename?: 'sleep_diary';
    concentration?: number | null;
    date: `${number}-${number}-${number}`;
    energy?: number | null;
    get_up: `${number}:${number}:${number}+${number}`;
    go_bed: `${number}:${number}:${number}+${number}`;
    go_sleep: `${number}:${number}:${number}+${number}`;
    grade?: number | null;
    humor?: number | null;
    relationships?: number | null;
    time_to_sleep: number;
    wake_up: `${number}:${number}:${number}+${number}`;
    wake_up_count: number;
    wake_up_duration: number;
    tags: Array<{ __typename?: 'sleep_diary_sleep_tag'; sleep_tag: Sleep_Tag_Enum_Enum }>;
  }>;
  sleep_restriction: Array<{ __typename?: 'sleep_restriction'; start_date: `${number}-${number}-${number}` }>;
};

export type DrAoVivoIdQueryQueryVariables = Exact<{
  user_id: Scalars['String'];
  pre_user_id: Scalars['String'];
  health_carer_id: Scalars['String'];
}>;

export type DrAoVivoIdQueryQuery = {
  __typename?: 'query_root';
  user: Array<{ __typename?: 'user_telehealth'; dav_id?: string | null; user_id?: string | null }>;
  pre_user: Array<{ __typename?: 'user_telehealth'; dav_id?: string | null; user_id?: string | null }>;
  health_carer?: { __typename?: 'health_carer_telehealth'; dav_id: string; health_carer_id: string } | null;
};

export type FetchAllLastQuestionnairesDateQueryQueryVariables = Exact<{
  user_id: Scalars['String'];
}>;

export type FetchAllLastQuestionnairesDateQueryQuery = {
  __typename?: 'query_root';
  munich_chronotype_core: Array<{
    __typename?: 'munich_chronotype_core';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
  epworth: Array<{ __typename?: 'epworth'; created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` }>;
  apnea_questionnaire: Array<{
    __typename?: 'apnea_questionnaire';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
  personality_questionnaire: Array<{
    __typename?: 'personality_questionnaire';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
  munich_chronotype: Array<{
    __typename?: 'munich_chronotype';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
  psqi: Array<{ __typename?: 'psqi'; created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` }>;
  productivity: Array<{
    __typename?: 'productivity';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
  phq: Array<{ __typename?: 'phq'; created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` }>;
  isi: Array<{ __typename?: 'isi'; created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` }>;
  gad7: Array<{ __typename?: 'gad7'; created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` }>;
  gad: Array<{ __typename?: 'gad'; created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` }>;
  phq9: Array<{ __typename?: 'phq9'; created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` }>;
  kupperman: Array<{
    __typename?: 'kupperman';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
};

export type FetchLastQuestionnairesToDecideWhatIsTheNextOneQueryQueryVariables = Exact<{
  user_id: Scalars['String'];
}>;

export type FetchLastQuestionnairesToDecideWhatIsTheNextOneQueryQuery = {
  __typename?: 'query_root';
  gad7: Array<{ __typename?: 'gad7'; created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` }>;
  menopause_greene_scale: Array<{
    __typename?: 'menopause_greene_scale';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
  phq9: Array<{ __typename?: 'phq9'; created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` }>;
  kupperman: Array<{
    __typename?: 'kupperman';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
};

export type InserReminderMutationMutationVariables = Exact<{
  reminder: Reminder_Insert_Input;
  user_reminder_info: User_Reminder_Info_Insert_Input;
}>;

export type InserReminderMutationMutation = {
  __typename?: 'mutation_root';
  insert_user_reminder_info_one?: { __typename?: 'user_reminder_info'; messenger_id: string } | null;
  insert_reminder_one?: {
    __typename?: 'reminder';
    id: number;
    enabled: boolean;
    type: Reminder_Type_Enum_Enum;
    hour?: number | null;
    minute?: number | null;
    timezone?: number | null;
    extra_data?: unknown | null;
  } | null;
};

export type PreUserForTelehealthUpsertMutationMutationVariables = Exact<{
  pre_user_id?: InputMaybe<Scalars['String']>;
  dav_id?: InputMaybe<Scalars['String']>;
  first_name: Scalars['String'];
  last_name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  cpf?: InputMaybe<Scalars['String']>;
  birthdate?: InputMaybe<Scalars['date']>;
  max_appointments?: InputMaybe<Scalars['Int']>;
  min_days_between_appointments?: InputMaybe<Scalars['Int']>;
  recurrence_model?: InputMaybe<Recurrence_Model_Enum_Enum>;
  subscription_expiration?: InputMaybe<Scalars['timestamptz']>;
  recurrence_start_date?: InputMaybe<Scalars['timestamptz']>;
  source: Pre_User_Source_Enum_Enum;
}>;

export type PreUserForTelehealthUpsertMutationMutation = {
  __typename?: 'mutation_root';
  insert_pre_user_one?: {
    __typename?: 'pre_user';
    id: string;
    last_name?: string | null;
    first_name?: string | null;
    email?: string | null;
    birthdate?: `${number}-${number}-${number}` | null;
    phone?: string | null;
    cpf?: string | null;
    source: Pre_User_Source_Enum_Enum;
  } | null;
  insert_user_telehealth_one?: {
    __typename?: 'user_telehealth';
    dav_id?: string | null;
    user_id?: string | null;
    max_appointments: number;
    min_days_between_appointments: number;
    subscription_expiration?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    recurrence_start_date?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    recurrence_model?: Recurrence_Model_Enum_Enum | null;
  } | null;
};

export type SleepDiaryReportQueryQueryVariables = Exact<{
  id: Scalars['String'];
  from: Scalars['date'];
}>;

export type SleepDiaryReportQueryQuery = {
  __typename?: 'query_root';
  sleep_diary: Array<{
    __typename?: 'sleep_diary';
    date: `${number}-${number}-${number}`;
    get_up: `${number}:${number}:${number}+${number}`;
    go_bed: `${number}:${number}:${number}+${number}`;
    go_sleep: `${number}:${number}:${number}+${number}`;
    id: number;
    time_to_sleep: number;
    wake_up: `${number}:${number}:${number}+${number}`;
    wake_up_duration: number;
  }>;
  user: Array<{ __typename?: 'user'; id: string; desired_wake_up?: `${number}:${number}:${number}+${number}` | null }>;
};

export type SleepRestrictionFinishedQueryQueryVariables = Exact<{
  id: Scalars['String'];
  from: Scalars['date'];
}>;

export type SleepRestrictionFinishedQueryQuery = {
  __typename?: 'query_root';
  user: Array<{
    __typename?: 'user';
    subscription_expiration?: `${number}-${number}-${number}` | null;
    sleep_restriction?: boolean | null;
    paid?: boolean | null;
  }>;
  sleep_restriction_aggregate: {
    __typename?: 'sleep_restriction_aggregate';
    aggregate?: { __typename?: 'sleep_restriction_aggregate_fields'; count?: number | null } | null;
  };
  sleep_restriction: Array<{ __typename?: 'sleep_restriction'; start_date: `${number}-${number}-${number}` }>;
  sleep_diary: Array<{ __typename?: 'sleep_diary'; date: `${number}-${number}-${number}` }>;
};

export type UserForTelehealthUpsertFragmentFragment = {
  __typename?: 'user_telehealth_mutation_response';
  returning: Array<{
    __typename?: 'user_telehealth';
    dav_id?: string | null;
    id: number;
    min_days_between_appointments: number;
    max_appointments: number;
  }>;
};

export type UserForTelehealth_UserUpsertMutationMutationVariables = Exact<{
  id: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  document: Scalars['String'];
  phone: Scalars['String'];
  alt_email: Scalars['String'];
  birthdate: Scalars['date'];
  dav_id: Scalars['String'];
}>;

export type UserForTelehealth_UserUpsertMutationMutation = {
  __typename?: 'mutation_root';
  insert_user_one?: { __typename?: 'user'; id: string } | null;
  update_user_telehealth?: {
    __typename?: 'user_telehealth_mutation_response';
    returning: Array<{
      __typename?: 'user_telehealth';
      dav_id?: string | null;
      id: number;
      min_days_between_appointments: number;
      max_appointments: number;
    }>;
  } | null;
};

export type UserForTelehealth_PreUserUpsertMutationMutationVariables = Exact<{
  id: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  document: Scalars['String'];
  phone: Scalars['String'];
  alt_email: Scalars['String'];
  birthdate: Scalars['date'];
  dav_id: Scalars['String'];
}>;

export type UserForTelehealth_PreUserUpsertMutationMutation = {
  __typename?: 'mutation_root';
  insert_pre_user_one?: { __typename?: 'pre_user'; id: string } | null;
  update_user_telehealth?: {
    __typename?: 'user_telehealth_mutation_response';
    returning: Array<{
      __typename?: 'user_telehealth';
      dav_id?: string | null;
      id: number;
      min_days_between_appointments: number;
      max_appointments: number;
    }>;
  } | null;
};

export type UserSessionsAndSleepRestrictionQueryQueryVariables = Exact<{
  id: Scalars['String'];
  from: Scalars['date'];
}>;

export type UserSessionsAndSleepRestrictionQueryQuery = {
  __typename?: 'query_root';
  user: Array<{
    __typename?: 'user';
    can_send_email_reminder?: boolean | null;
    can_send_whatsapp_reminder?: boolean | null;
    current_journey_id?: number | null;
    email?: string | null;
    first_name?: string | null;
    id: string;
    paid?: boolean | null;
    phone?: string | null;
    psqi_state: number;
    session_to_pay: number;
    sleep_restriction?: boolean | null;
    subscription_expiration?: `${number}-${number}-${number}` | null;
  }>;
  user_program_session: Array<{
    __typename?: 'user_program_session';
    state: User_Program_Session_Enum_Enum;
    user_id: string;
    session_id: number;
  }>;
  sleep_restriction_aggregate: {
    __typename?: 'sleep_restriction_aggregate';
    aggregate?: { __typename?: 'sleep_restriction_aggregate_fields'; count?: number | null } | null;
  };
  sleep_restriction: Array<{ __typename?: 'sleep_restriction'; start_date: `${number}-${number}-${number}` }>;
  sleep_diary: Array<{ __typename?: 'sleep_diary'; date: `${number}-${number}-${number}` }>;
};

export type ParkingLotInsertMutationMutationVariables = Exact<{
  objects: Array<Parking_Lot_Insert_Input> | Parking_Lot_Insert_Input;
}>;

export type ParkingLotInsertMutationMutation = {
  __typename?: 'mutation_root';
  insert_parking_lot?: {
    __typename?: 'parking_lot_mutation_response';
    returning: Array<{ __typename?: 'parking_lot'; id: number }>;
  } | null;
};

export type PersonalityQuestionnaireInsertMutationMutationVariables = Exact<{
  objects: Array<Personality_Questionnaire_Insert_Input> | Personality_Questionnaire_Insert_Input;
}>;

export type PersonalityQuestionnaireInsertMutationMutation = {
  __typename?: 'mutation_root';
  insert_personality_questionnaire?: {
    __typename?: 'personality_questionnaire_mutation_response';
    returning: Array<{ __typename?: 'personality_questionnaire'; id: number }>;
  } | null;
};

export type PhqInsertMutationMutationVariables = Exact<{
  objects: Array<Phq_Insert_Input> | Phq_Insert_Input;
}>;

export type PhqInsertMutationMutation = {
  __typename?: 'mutation_root';
  insert_phq?: { __typename?: 'phq_mutation_response'; returning: Array<{ __typename?: 'phq'; id: number }> } | null;
};

export type Phq9ScaleInsertMutationMutationVariables = Exact<{
  objects: Array<Phq9_Insert_Input> | Phq9_Insert_Input;
}>;

export type Phq9ScaleInsertMutationMutation = {
  __typename?: 'mutation_root';
  insert_phq9?: { __typename?: 'phq9_mutation_response'; returning: Array<{ __typename?: 'phq9'; id: number }> } | null;
};

export type FirstPhq9QueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type FirstPhq9QueryQuery = {
  __typename?: 'query_root';
  phq9: Array<{
    __typename?: 'phq9';
    id: number;
    user_id: string;
    q1: number;
    q2: number;
    q3: number;
    q4: number;
    q5: number;
    q6: number;
    q7: number;
    q8: number;
    q9: number;
  }>;
};

export type PreUserByCpfQueryQueryVariables = Exact<{
  cpf: Scalars['String'];
}>;

export type PreUserByCpfQueryQuery = {
  __typename?: 'query_root';
  pre_user: Array<{
    __typename?: 'pre_user';
    id: string;
    user_id?: string | null;
    source: Pre_User_Source_Enum_Enum;
    user_telehealth?: {
      __typename?: 'user_telehealth';
      max_appointments: number;
      subscription_expiration?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    } | null;
  }>;
};

export type PreUserByUserIdQueryQueryVariables = Exact<{
  user_id: Scalars['String'];
}>;

export type PreUserByUserIdQueryQuery = {
  __typename?: 'query_root';
  pre_user: Array<{ __typename?: 'pre_user'; id: string; user_id?: string | null }>;
};

export type PreUserByUserTelehealthByIdQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type PreUserByUserTelehealthByIdQueryQuery = {
  __typename?: 'query_root';
  pre_user_by_pk?: {
    __typename?: 'pre_user';
    id: string;
    first_name?: string | null;
    email?: string | null;
    source: Pre_User_Source_Enum_Enum;
    gender?: string | null;
    user_telehealth?: { __typename?: 'user_telehealth'; max_appointments: number } | null;
    appointments_aggregate: {
      __typename?: 'appointment_aggregate';
      aggregate?: { __typename?: 'appointment_aggregate_fields'; count?: number | null } | null;
    };
  } | null;
};

export type PreUserContactQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type PreUserContactQueryQuery = {
  __typename?: 'query_root';
  pre_user_by_pk?: {
    __typename?: 'pre_user';
    id: string;
    first_name?: string | null;
    last_name?: string | null;
    birthdate?: `${number}-${number}-${number}` | null;
    cpf?: string | null;
    email?: string | null;
    gender?: string | null;
    phone?: string | null;
    alt_email?: string | null;
    user_id?: string | null;
    source: Pre_User_Source_Enum_Enum;
  } | null;
};

export type FetchPreUserSourceQueryQueryVariables = Exact<{
  user_id?: InputMaybe<Scalars['String']>;
  pre_user_id?: InputMaybe<Scalars['String']>;
}>;

export type FetchPreUserSourceQueryQuery = {
  __typename?: 'query_root';
  pre_user: Array<{ __typename?: 'pre_user'; id: string; user_id?: string | null; source: Pre_User_Source_Enum_Enum }>;
};

export type ProductivityInsertMutationMutationVariables = Exact<{
  objects: Array<Productivity_Insert_Input> | Productivity_Insert_Input;
}>;

export type ProductivityInsertMutationMutation = {
  __typename?: 'mutation_root';
  insert_productivity?: {
    __typename?: 'productivity_mutation_response';
    returning: Array<{ __typename?: 'productivity'; id: number }>;
  } | null;
};

export type PsqiInsertMutationMutationVariables = Exact<{
  objects: Array<Psqi_Insert_Input> | Psqi_Insert_Input;
}>;

export type PsqiInsertMutationMutation = {
  __typename?: 'mutation_root';
  insert_psqi?: { __typename?: 'psqi_mutation_response'; returning: Array<{ __typename?: 'psqi'; id: number }> } | null;
};

export type PsqiQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type PsqiQueryQuery = {
  __typename?: 'query_root';
  psqi: Array<{
    __typename?: 'psqi';
    id: number;
    q1: string;
    q2: number;
    q3: string;
    q4: number;
    q5a: number;
    q5b: number;
    q5c: number;
    q5d: number;
    q5e: number;
    q5f: number;
    q5h: number;
    q5g: number;
    q5i: number;
    q5j: number;
    q5j_comment: string;
    q6: number;
    q8: number;
    q7: number;
    q9: number;
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
};

export type RatingInsertMutationMutationVariables = Exact<{
  objects: Array<Rating_Insert_Input> | Rating_Insert_Input;
}>;

export type RatingInsertMutationMutation = {
  __typename?: 'mutation_root';
  insert_rating?: { __typename?: 'rating_mutation_response'; returning: Array<{ __typename?: 'rating'; id: number }> } | null;
};

export type RatingAvgQueryQueryVariables = Exact<{
  label: Scalars['String'];
}>;

export type RatingAvgQueryQuery = {
  __typename?: 'query_root';
  rating_aggregate: {
    __typename?: 'rating_aggregate';
    aggregate?: { __typename?: 'rating_aggregate_fields'; avg?: { __typename?: 'rating_avg_fields'; grade?: number | null } | null } | null;
  };
};

export type UserRatingAggregateQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type UserRatingAggregateQueryQuery = {
  __typename?: 'query_root';
  one: { __typename?: 'rating_aggregate'; aggregate?: { __typename?: 'rating_aggregate_fields'; count?: number | null } | null };
  two: { __typename?: 'rating_aggregate'; aggregate?: { __typename?: 'rating_aggregate_fields'; count?: number | null } | null };
  three: { __typename?: 'rating_aggregate'; aggregate?: { __typename?: 'rating_aggregate_fields'; count?: number | null } | null };
  four: { __typename?: 'rating_aggregate'; aggregate?: { __typename?: 'rating_aggregate_fields'; count?: number | null } | null };
  five: { __typename?: 'rating_aggregate'; aggregate?: { __typename?: 'rating_aggregate_fields'; count?: number | null } | null };
  avg: {
    __typename?: 'rating_aggregate';
    aggregate?: {
      __typename?: 'rating_aggregate_fields';
      count?: number | null;
      avg?: { __typename?: 'rating_avg_fields'; grade?: number | null } | null;
    } | null;
  };
};

export type DeleteReminderMutationMutationVariables = Exact<{
  userId: Scalars['String'];
}>;

export type DeleteReminderMutationMutation = {
  __typename?: 'mutation_root';
  delete_reminder?: { __typename?: 'reminder_mutation_response'; affected_rows: number } | null;
};

export type ReminderQueryQueryVariables = Exact<{
  where: Reminder_Bool_Exp;
}>;

export type ReminderQueryQuery = {
  __typename?: 'query_root';
  reminder: Array<{
    __typename?: 'reminder';
    enabled: boolean;
    hour?: number | null;
    messenger_id: string;
    minute?: number | null;
    timezone?: number | null;
    type: Reminder_Type_Enum_Enum;
    extra_data?: unknown | null;
  }>;
};

export type UpdateReminderMutationMutationVariables = Exact<{
  userId: Scalars['String'];
  enabled: Scalars['Boolean'];
}>;

export type UpdateReminderMutationMutation = {
  __typename?: 'mutation_root';
  update_reminder?: { __typename?: 'reminder_mutation_response'; affected_rows: number } | null;
};

export type ReminderWithTokenQueryQueryVariables = Exact<{
  where: Reminder_Bool_Exp;
}>;

export type ReminderWithTokenQueryQuery = {
  __typename?: 'query_root';
  reminder: Array<{
    __typename?: 'reminder';
    enabled: boolean;
    hour?: number | null;
    messenger_id: string;
    minute?: number | null;
    timezone?: number | null;
    type: Reminder_Type_Enum_Enum;
    extra_data?: unknown | null;
    user_reminder_info: { __typename?: 'user_reminder_info'; push_token?: string[] | null };
  }>;
};

export type CheckSleepDiaryTagQueryQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>;
  tags: Array<Sleep_Tag_Enum_Enum> | Sleep_Tag_Enum_Enum;
  until: Scalars['timestamptz'];
}>;

export type CheckSleepDiaryTagQueryQuery = {
  __typename?: 'query_root';
  sleep_diary_sleep_tag_aggregate: {
    __typename?: 'sleep_diary_sleep_tag_aggregate';
    nodes: Array<{ __typename?: 'sleep_diary_sleep_tag'; sleep_tag: Sleep_Tag_Enum_Enum }>;
  };
  sleep_diary_aggregate: {
    __typename?: 'sleep_diary_aggregate';
    aggregate?: { __typename?: 'sleep_diary_aggregate_fields'; count?: number | null } | null;
  };
};

export type AllSleepDiaryReportQueryQueryVariables = Exact<{
  limit: Scalars['Int'];
  id: Scalars['Int'];
}>;

export type AllSleepDiaryReportQueryQuery = {
  __typename?: 'query_root';
  sleep_diary: Array<{
    __typename?: 'sleep_diary';
    id: number;
    user_id: string;
    concentration?: number | null;
    date: `${number}-${number}-${number}`;
    get_up: `${number}:${number}:${number}+${number}`;
    go_bed: `${number}:${number}:${number}+${number}`;
    go_sleep: `${number}:${number}:${number}+${number}`;
    time_to_sleep: number;
    wake_up: `${number}:${number}:${number}+${number}`;
    wake_up_count: number;
    wake_up_duration: number;
    tags: Array<{ __typename?: 'sleep_diary_sleep_tag'; sleep_tag: Sleep_Tag_Enum_Enum }>;
  }>;
};

export type SleepDiariesBeforeDateQueryQueryVariables = Exact<{
  id: Scalars['String'];
  date: Scalars['date'];
}>;

export type SleepDiariesBeforeDateQueryQuery = {
  __typename?: 'query_root';
  sleep_diary: Array<{
    __typename?: 'sleep_diary';
    concentration?: number | null;
    date: `${number}-${number}-${number}`;
    energy?: number | null;
    get_up: `${number}:${number}:${number}+${number}`;
    go_bed: `${number}:${number}:${number}+${number}`;
    go_sleep: `${number}:${number}:${number}+${number}`;
    grade?: number | null;
    humor?: number | null;
    relationships?: number | null;
    time_to_sleep: number;
    wake_up: `${number}:${number}:${number}+${number}`;
    wake_up_count: number;
    wake_up_duration: number;
    tags: Array<{ __typename?: 'sleep_diary_sleep_tag'; sleep_tag: Sleep_Tag_Enum_Enum }>;
  }>;
};

export type SleepDiaryCountQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type SleepDiaryCountQueryQuery = {
  __typename?: 'query_root';
  sleep_diary_aggregate: {
    __typename?: 'sleep_diary_aggregate';
    aggregate?: { __typename?: 'sleep_diary_aggregate_fields'; count?: number | null } | null;
  };
};

export type First7SleepDiariesAfterDateQueryQueryVariables = Exact<{
  id: Scalars['String'];
  date: Scalars['date'];
}>;

export type First7SleepDiariesAfterDateQueryQuery = {
  __typename?: 'query_root';
  sleep_diary: Array<{
    __typename?: 'sleep_diary';
    concentration?: number | null;
    date: `${number}-${number}-${number}`;
    energy?: number | null;
    get_up: `${number}:${number}:${number}+${number}`;
    go_bed: `${number}:${number}:${number}+${number}`;
    go_sleep: `${number}:${number}:${number}+${number}`;
    grade?: number | null;
    humor?: number | null;
    relationships?: number | null;
    time_to_sleep: number;
    wake_up: `${number}:${number}:${number}+${number}`;
    wake_up_count: number;
    wake_up_duration: number;
    tags: Array<{ __typename?: 'sleep_diary_sleep_tag'; sleep_tag: Sleep_Tag_Enum_Enum }>;
  }>;
};

export type SleepRestrictionInsertMutationMutationVariables = Exact<{
  objects: Array<Sleep_Restriction_Insert_Input> | Sleep_Restriction_Insert_Input;
  update_columns: Array<Sleep_Restriction_Update_Column> | Sleep_Restriction_Update_Column;
  user_id: Scalars['String'];
}>;

export type SleepRestrictionInsertMutationMutation = {
  __typename?: 'mutation_root';
  insert_sleep_restriction?: {
    __typename?: 'sleep_restriction_mutation_response';
    returning: Array<{ __typename?: 'sleep_restriction'; user_id: string; start_date: `${number}-${number}-${number}` }>;
  } | null;
  update_user_by_pk?: { __typename?: 'user'; id: string; sleep_restriction?: boolean | null } | null;
};

export type StopbangScaleInsertMutationMutationVariables = Exact<{
  objects: Array<Stop_Bang_Insert_Input> | Stop_Bang_Insert_Input;
}>;

export type StopbangScaleInsertMutationMutation = {
  __typename?: 'mutation_root';
  insert_stop_bang?: { __typename?: 'stop_bang_mutation_response'; returning: Array<{ __typename?: 'stop_bang'; id: number }> } | null;
};

export type FirstStopbangQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type FirstStopbangQueryQuery = {
  __typename?: 'query_root';
  stop_bang: Array<{
    __typename?: 'stop_bang';
    id: number;
    user_id: string;
    q1: number;
    q2: number;
    q3: number;
    q4: number;
    q5: number;
    q6: number;
    q7: number;
    q8: number;
    q9?: number | null;
  }>;
};

export type ToolboxQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ToolboxQueryQuery = {
  __typename?: 'query_root';
  toolbox: Array<{
    __typename?: 'toolbox';
    id: number;
    autogenic_training?: boolean | null;
    behavior_activation?: boolean | null;
    deep_breath?: boolean | null;
    imagery?: boolean | null;
    meditation?: boolean | null;
    paradoxical_intention?: boolean | null;
    parking_lot?: boolean | null;
    gratitude?: boolean | null;
    light_therapy?: boolean | null;
    challenge_catastrophic_thinking?: boolean | null;
    sleep_restriction?: boolean | null;
    pmr?: boolean | null;
    stimulus_control?: boolean | null;
    thought_block?: boolean | null;
  }>;
};

export type ToolboxUpsertMutationVariables = Exact<{
  objects: Array<Toolbox_Insert_Input> | Toolbox_Insert_Input;
  update_columns: Array<Toolbox_Update_Column> | Toolbox_Update_Column;
}>;

export type ToolboxUpsertMutation = {
  __typename?: 'mutation_root';
  insert_toolbox?: { __typename?: 'toolbox_mutation_response'; returning: Array<{ __typename?: 'toolbox'; id: number }> } | null;
};

export type UpdateAppointmentStatusMutationMutationVariables = Exact<{
  status: Appointment_Status_Enum_Enum;
  id: Scalars['String'];
  start: Scalars['timestamptz'];
  end: Scalars['timestamptz'];
}>;

export type UpdateAppointmentStatusMutationMutation = {
  __typename?: 'mutation_root';
  update_appointment?: {
    __typename?: 'appointment_mutation_response';
    returning: Array<{
      __typename?: 'appointment';
      id: string;
      status: Appointment_Status_Enum_Enum;
      start: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      end: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
  } | null;
};

export type UpdateUserAndPreUserMutationMutationVariables = Exact<{
  user_id: Scalars['String'];
  pre_user_id: Scalars['String'];
  set_user: User_Set_Input;
}>;

export type UpdateUserAndPreUserMutationMutation = {
  __typename?: 'mutation_root';
  update_user_telehealth?: {
    __typename?: 'user_telehealth_mutation_response';
    affected_rows: number;
    returning: Array<{ __typename?: 'user_telehealth'; id: number }>;
  } | null;
  update_appointment?: {
    __typename?: 'appointment_mutation_response';
    affected_rows: number;
    returning: Array<{ __typename?: 'appointment'; id: string }>;
  } | null;
  update_user_by_pk?: {
    __typename?: 'user';
    email?: string | null;
    first_name?: string | null;
    id: string;
    last_name?: string | null;
    paid?: boolean | null;
    paid_value?: number | null;
    subscription_expiration?: `${number}-${number}-${number}` | null;
  } | null;
  update_pre_user_by_pk?: {
    __typename?: 'pre_user';
    email?: string | null;
    first_name?: string | null;
    id: string;
    last_name?: string | null;
    user_id?: string | null;
  } | null;
};

export type UpdateUserForPreUserMutationMutationVariables = Exact<{
  id: Scalars['String'];
  setUser: User_Set_Input;
  setPreUser: Pre_User_Set_Input;
}>;

export type UpdateUserForPreUserMutationMutation = {
  __typename?: 'mutation_root';
  update_user?: {
    __typename?: 'user_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'user';
      id: string;
      paid?: boolean | null;
      paid_value?: number | null;
      subscription_expiration?: `${number}-${number}-${number}` | null;
    }>;
  } | null;
  update_pre_user_by_pk?: { __typename?: 'pre_user'; status: string; id: string } | null;
};

export type UpsertCouponMutationMutationVariables = Exact<{
  objects: Array<Coupon_Insert_Input> | Coupon_Insert_Input;
}>;

export type UpsertCouponMutationMutation = {
  __typename?: 'mutation_root';
  insert_coupon?: {
    __typename?: 'coupon_mutation_response';
    affected_rows: number;
    returning: Array<{ __typename?: 'coupon'; code: string }>;
  } | null;
};

export type UpsertCrmMutationMutationVariables = Exact<{
  objects: Array<Crm_Insert_Input> | Crm_Insert_Input;
}>;

export type UpsertCrmMutationMutation = {
  __typename?: 'mutation_root';
  insert_crm?: { __typename?: 'crm_mutation_response'; affected_rows: number } | null;
};

export type UserAndPreUserSearchQueryQueryVariables = Exact<{
  email: Scalars['String'];
}>;

export type UserAndPreUserSearchQueryQuery = {
  __typename?: 'query_root';
  pre_user: Array<{
    __typename?: 'pre_user';
    email?: string | null;
    first_name?: string | null;
    id: string;
    last_name?: string | null;
    user_id?: string | null;
    status: string;
    cpf?: string | null;
    source: Pre_User_Source_Enum_Enum;
    birthdate?: `${number}-${number}-${number}` | null;
    company?: string | null;
    gender?: string | null;
  }>;
  user: Array<{ __typename?: 'user'; email?: string | null; first_name?: string | null; id: string; last_name?: string | null }>;
};

export type UserJourneyUserAppointmentCalendarInfoQueryInfoQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type UserJourneyUserAppointmentCalendarInfoQueryInfoQueryQuery = {
  __typename?: 'query_root';
  user_by_pk?: {
    __typename?: 'user';
    first_name?: string | null;
    last_name?: string | null;
    current_journey_id?: number | null;
    user_telehealth?: {
      __typename?: 'user_telehealth';
      max_appointments: number;
      min_days_between_appointments: number;
      recurrence_start_date?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
      recurrence_model?: Recurrence_Model_Enum_Enum | null;
    } | null;
    appointments: Array<{
      __typename?: 'appointment';
      start: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      status: Appointment_Status_Enum_Enum;
    }>;
    pre_users: Array<{ __typename?: 'pre_user'; id: string; source: Pre_User_Source_Enum_Enum }>;
  } | null;
  journey: Array<{ __typename?: 'journey'; id: number; name: string }>;
  user_checkout: Array<{ __typename?: 'user_checkout'; sku?: string | null }>;
};

export type UserCheckoutByDataQueryQueryVariables = Exact<{
  data: Scalars['jsonb'];
}>;

export type UserCheckoutByDataQueryQuery = {
  __typename?: 'query_root';
  user_checkout: Array<{ __typename?: 'user_checkout'; id: number; user_id: string }>;
};

export type UserCheckoutByUuidQueryQueryVariables = Exact<{
  uuid: Scalars['uuid'];
}>;

export type UserCheckoutByUuidQueryQuery = {
  __typename?: 'query_root';
  user_checkout: Array<{ __typename?: 'user_checkout'; id: number; user_id: string }>;
};

export type UserCheckoutInsertMutationMutationVariables = Exact<{
  checkout: User_Checkout_Insert_Input;
}>;

export type UserCheckoutInsertMutationMutation = {
  __typename?: 'mutation_root';
  insert_user_checkout_one?: { __typename?: 'user_checkout'; id: number } | null;
};

export type SearchUserCheckoutByIosIdQueryQueryVariables = Exact<{
  obj: Scalars['jsonb'];
}>;

export type SearchUserCheckoutByIosIdQueryQuery = {
  __typename?: 'query_root';
  user_checkout: Array<{
    __typename?: 'user_checkout';
    user_id: string;
    id: number;
    user?: { __typename?: 'user'; email?: string | null } | null;
  }>;
};

export type UserCheckoutMutationMutationVariables = Exact<{
  user: User_Insert_Input;
  checkout: User_Checkout_Insert_Input;
}>;

export type UserCheckoutMutationMutation = {
  __typename?: 'mutation_root';
  insert_user_one?: { __typename?: 'user'; id: string; first_name?: string | null; email?: string | null } | null;
  insert_user_checkout_one?: { __typename?: 'user_checkout'; id: number } | null;
};

export type UserCheckoutQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type UserCheckoutQueryQuery = {
  __typename?: 'query_root';
  user_checkout: Array<{
    __typename?: 'user_checkout';
    user_id: string;
    data: unknown;
    id: number;
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    sku?: string | null;
    receipt?: string | null;
    uuid?: string | null;
  }>;
};

export type UserCouponCompanyQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type UserCouponCompanyQueryQuery = {
  __typename?: 'query_root';
  user_by_pk?: {
    __typename?: 'user';
    companies: Array<{ __typename?: 'company_user'; company_id: number }>;
    user_coupons: Array<{
      __typename?: 'user_coupon';
      coupon_id: number;
      coupon?: { __typename?: 'coupon'; code: string; active: boolean; kind?: string | null } | null;
    }>;
  } | null;
};

export type UserCouponMutationMutationVariables = Exact<{
  user: User_Insert_Input;
  coupon: User_Coupon_Insert_Input;
  update_columns: Array<User_Update_Column> | User_Update_Column;
}>;

export type UserCouponMutationMutation = {
  __typename?: 'mutation_root';
  insert_user_one?: { __typename?: 'user'; id: string } | null;
  insert_user_coupon_one?: { __typename?: 'user_coupon'; coupon_id: number; user_id: string } | null;
};

export type UserGoalQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type UserGoalQueryQuery = {
  __typename?: 'query_root';
  user: Array<{ __typename?: 'user'; id: string; day_goal?: number | null; night_goal?: number | null }>;
};

export type UserJourneyAndSessionQueryQueryVariables = Exact<{
  user_id: Scalars['String'];
}>;

export type UserJourneyAndSessionQueryQuery = {
  __typename?: 'query_root';
  user_journey: Array<{ __typename?: 'user_journey'; journey_id: number }>;
  user_program_session: Array<{ __typename?: 'user_program_session'; session_id: number; state: User_Program_Session_Enum_Enum }>;
};

export type UserJourneyQueryQueryVariables = Exact<{
  user_id: Scalars['String'];
}>;

export type UserJourneyQueryQuery = {
  __typename?: 'query_root';
  user_journey: Array<{ __typename?: 'user_journey'; journey_id: number; user_id: string }>;
};

export type UserKeyValueUpsertMutationMutationVariables = Exact<{
  objects: Array<User_Key_Value_Insert_Input> | User_Key_Value_Insert_Input;
}>;

export type UserKeyValueUpsertMutationMutation = {
  __typename?: 'mutation_root';
  insert_user_key_value?: {
    __typename?: 'user_key_value_mutation_response';
    returning: Array<{ __typename?: 'user_key_value'; key: User_Key_Value_Key_Enum_Enum; user_id: string; value?: string | null }>;
  } | null;
};

export type GetPreUserAndHcpQueryVariables = Exact<{
  hcpId: Scalars['String'];
  preUserId: Scalars['String'];
}>;

export type GetPreUserAndHcpQuery = {
  __typename?: 'query_root';
  health_carer_by_pk?: { __typename?: 'health_carer'; email: string } | null;
  pre_user_by_pk?: { __typename?: 'pre_user'; first_name?: string | null } | null;
};

export type GetUserAndHcpQueryVariables = Exact<{
  hcpId: Scalars['String'];
  userId: Scalars['String'];
}>;

export type GetUserAndHcpQuery = {
  __typename?: 'query_root';
  health_carer_by_pk?: { __typename?: 'health_carer'; email: string } | null;
  user_by_pk?: { __typename?: 'user'; first_name?: string | null } | null;
};

export type UserNotificationMutationMutationVariables = Exact<{
  message: Scalars['String'];
  sender?: InputMaybe<Scalars['String']>;
  user_id: Scalars['String'];
}>;

export type UserNotificationMutationMutation = {
  __typename?: 'mutation_root';
  insert_user_notification_one?: { __typename?: 'user_notification'; id: number } | null;
};

export type UserUpsertMutationMutationVariables = Exact<{
  objects: Array<User_Program_Session_Insert_Input> | User_Program_Session_Insert_Input;
}>;

export type UserUpsertMutationMutation = {
  __typename?: 'mutation_root';
  insert_user_program_session?: {
    __typename?: 'user_program_session_mutation_response';
    returning: Array<{ __typename?: 'user_program_session'; state: User_Program_Session_Enum_Enum; session_id: number; user_id: string }>;
  } | null;
};

export type UserProgramSessionQueryQueryVariables = Exact<{
  user_id: Scalars['String'];
}>;

export type UserProgramSessionQueryQuery = {
  __typename?: 'query_root';
  user_program_session: Array<{
    __typename?: 'user_program_session';
    user_id: string;
    state: User_Program_Session_Enum_Enum;
    session_id: number;
  }>;
};

export type UserReminderInfoQueryQueryVariables = Exact<{
  messenger_id: Scalars['String'];
}>;

export type UserReminderInfoQueryQuery = {
  __typename?: 'query_root';
  user_reminder_info_by_pk?: { __typename?: 'user_reminder_info'; push_token?: string[] | null } | null;
};

export type UserTagInsertMutationVariables = Exact<{
  objects: Array<User_Tag_Insert_Input> | User_Tag_Insert_Input;
}>;

export type UserTagInsertMutation = {
  __typename?: 'mutation_root';
  insert_user_tag?: { __typename?: 'user_tag_mutation_response'; returning: Array<{ __typename?: 'user_tag'; id: number }> } | null;
};

export type GetUserTelehealthAndCompanyByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetUserTelehealthAndCompanyByIdQuery = {
  __typename?: 'query_root';
  user_telehealth: Array<{ __typename?: 'user_telehealth'; id: number; max_appointments: number; min_days_between_appointments: number }>;
  company_user: Array<{ __typename?: 'company_user'; company_id: number }>;
};

export type UserByUserTelehealthByIdQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type UserByUserTelehealthByIdQueryQuery = {
  __typename?: 'query_root';
  user_by_pk?: {
    __typename?: 'user';
    id: string;
    first_name?: string | null;
    email?: string | null;
    gender?: string | null;
    user_telehealth?: { __typename?: 'user_telehealth'; max_appointments: number } | null;
    appointments_aggregate: {
      __typename?: 'appointment_aggregate';
      aggregate?: { __typename?: 'appointment_aggregate_fields'; count?: number | null } | null;
    };
  } | null;
};

export type InsertUserTelehealthOneMutationMutationVariables = Exact<{
  input: User_Telehealth_Insert_Input;
}>;

export type InsertUserTelehealthOneMutationMutation = {
  __typename?: 'mutation_root';
  insert_user_telehealth_one?: { __typename?: 'user_telehealth'; user_id?: string | null; dav_id?: string | null } | null;
};

export type InsertUserTelehealthOneConflictOnUserIdMutationMutationVariables = Exact<{
  input: User_Telehealth_Insert_Input;
}>;

export type InsertUserTelehealthOneConflictOnUserIdMutationMutation = {
  __typename?: 'mutation_root';
  insert_user_telehealth_one?: {
    __typename?: 'user_telehealth';
    user_id?: string | null;
    pre_user_id?: string | null;
    dav_id?: string | null;
    max_appointments: number;
    min_days_between_appointments: number;
    subscription_expiration?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    recurrence_model?: Recurrence_Model_Enum_Enum | null;
    recurrence_start_date?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
  } | null;
};

export type InsertUserTelehealthOneConflictOnPreUserIdMutationMutationVariables = Exact<{
  input: User_Telehealth_Insert_Input;
}>;

export type InsertUserTelehealthOneConflictOnPreUserIdMutationMutation = {
  __typename?: 'mutation_root';
  insert_user_telehealth_one?: {
    __typename?: 'user_telehealth';
    user_id?: string | null;
    pre_user_id?: string | null;
    dav_id?: string | null;
    max_appointments: number;
    min_days_between_appointments: number;
    subscription_expiration?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    recurrence_model?: Recurrence_Model_Enum_Enum | null;
    recurrence_start_date?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
  } | null;
};

export type GetUserTelehealthFragmentFragment = {
  __typename?: 'user_telehealth';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  dav_id?: string | null;
  id: number;
  max_appointments: number;
  min_days_between_appointments: number;
  recurrence_model?: Recurrence_Model_Enum_Enum | null;
  recurrence_start_date?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
  subscription_expiration?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  user_id?: string | null;
  pre_user_id?: string | null;
};

export type GetUserTelehealthByUserIdQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetUserTelehealthByUserIdQueryQuery = {
  __typename?: 'query_root';
  user_telehealth: Array<{
    __typename?: 'user_telehealth';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    dav_id?: string | null;
    id: number;
    max_appointments: number;
    min_days_between_appointments: number;
    recurrence_model?: Recurrence_Model_Enum_Enum | null;
    recurrence_start_date?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    subscription_expiration?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    user_id?: string | null;
    pre_user_id?: string | null;
  }>;
};

export type GetUserTelehealthByPreUserIdQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetUserTelehealthByPreUserIdQueryQuery = {
  __typename?: 'query_root';
  user_telehealth: Array<{
    __typename?: 'user_telehealth';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    dav_id?: string | null;
    id: number;
    max_appointments: number;
    min_days_between_appointments: number;
    recurrence_model?: Recurrence_Model_Enum_Enum | null;
    recurrence_start_date?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    subscription_expiration?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    user_id?: string | null;
    pre_user_id?: string | null;
  }>;
};

export type CancelSubscriptionMutationMutationVariables = Exact<{
  id: Scalars['String'];
  subscription_expiration: Scalars['date'];
}>;

export type CancelSubscriptionMutationMutation = {
  __typename?: 'mutation_root';
  update_user_by_pk?: {
    __typename?: 'user';
    paid?: boolean | null;
    subscription_expiration?: `${number}-${number}-${number}` | null;
  } | null;
};

export type UserContactQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type UserContactQueryQuery = {
  __typename?: 'query_root';
  user: Array<{
    __typename?: 'user';
    id: string;
    first_name?: string | null;
    last_name?: string | null;
    email?: string | null;
    alt_email?: string | null;
    gender?: string | null;
    phone?: string | null;
    birthdate?: `${number}-${number}-${number}` | null;
    can_send_email_reminder?: boolean | null;
    can_send_whatsapp_reminder?: boolean | null;
    session_51_state: number;
    updated_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    current_journey_id?: number | null;
    ratings: Array<{ __typename?: 'rating'; created: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` }>;
    user_program_sessions: Array<{ __typename?: 'user_program_session'; session_id: number; state: User_Program_Session_Enum_Enum }>;
  }>;
};

export type UserEmailAndNameQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type UserEmailAndNameQuery = {
  __typename?: 'query_root';
  user_by_pk?: { __typename?: 'user'; id: string; first_name?: string | null; email?: string | null } | null;
};

export type UserContactInfoQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type UserContactInfoQueryQuery = {
  __typename?: 'query_root';
  user: Array<{ __typename?: 'user'; id: string; first_name?: string | null; email?: string | null; phone?: string | null }>;
  user_reminder_info: Array<{ __typename?: 'user_reminder_info'; push_token?: string[] | null }>;
};

export type UserHcpListToPerformHealthCarerMoneyAdditionQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type UserHcpListToPerformHealthCarerMoneyAdditionQueryQuery = {
  __typename?: 'query_root';
  user_by_pk?: {
    __typename?: 'user';
    id: string;
    first_name?: string | null;
    last_name?: string | null;
    gender?: string | null;
    paid?: boolean | null;
    paid_value?: number | null;
  } | null;
};

export type UserImprovementReportQueryQueryVariables = Exact<{
  limit: Scalars['Int'];
  internal_id: Scalars['Int'];
}>;

export type UserImprovementReportQueryQuery = {
  __typename?: 'query_root';
  user: Array<{
    __typename?: 'user';
    internal_id: number;
    id: string;
    sleep_medicine: boolean;
    day_goal?: number | null;
    night_goal?: number | null;
    created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    ratings: Array<{
      __typename?: 'rating';
      label: string;
      created: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
    sleep_diaries: Array<{
      __typename?: 'sleep_diary';
      concentration?: number | null;
      date: `${number}-${number}-${number}`;
      energy?: number | null;
      get_up: `${number}:${number}:${number}+${number}`;
      go_bed: `${number}:${number}:${number}+${number}`;
      go_sleep: `${number}:${number}:${number}+${number}`;
      grade?: number | null;
      humor?: number | null;
      relationships?: number | null;
      time_to_sleep: number;
      wake_up: `${number}:${number}:${number}+${number}`;
      wake_up_count: number;
      wake_up_duration: number;
      tags: Array<{ __typename?: 'sleep_diary_sleep_tag'; sleep_tag: Sleep_Tag_Enum_Enum }>;
    }>;
    sleep_restrictions: Array<{ __typename?: 'sleep_restriction'; start_date: `${number}-${number}-${number}` }>;
  }>;
};

export type UserInfoForAppointmentQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type UserInfoForAppointmentQueryQuery = {
  __typename?: 'query_root';
  user_by_pk?: {
    __typename?: 'user';
    id: string;
    first_name?: string | null;
    last_name?: string | null;
    email?: string | null;
    alt_email?: string | null;
    gender?: string | null;
    user_telehealth?: { __typename?: 'user_telehealth'; user_id?: string | null; pre_user_id?: string | null } | null;
  } | null;
};

export type LauraAnalysisReportFragmentFragment = {
  __typename?: 'user';
  internal_id: number;
  id: string;
  phone?: string | null;
  email?: string | null;
  gender?: string | null;
  paid?: boolean | null;
  paid_value?: number | null;
  subscription_expiration?: `${number}-${number}-${number}` | null;
  points?: number | null;
  alcohol: boolean;
  bathroom: boolean;
  caffeine: boolean;
  exercise: boolean;
  heavy_meal: boolean;
  light: boolean;
  nap: boolean;
  nicotine: boolean;
  noise: boolean;
  pain: boolean;
  partner: boolean;
  sleep_medicine: boolean;
  temperature: boolean;
  dream: boolean;
  autogenic_training?: boolean | null;
  behavior_activation?: boolean | null;
  challenge_catastrophic_thinking?: boolean | null;
  deep_breath?: boolean | null;
  gratitude?: boolean | null;
  imagery?: boolean | null;
  light_therapy?: boolean | null;
  meditation?: boolean | null;
  paradoxical_intention?: boolean | null;
  parking_lot?: boolean | null;
  pmr?: boolean | null;
  stimulus_control?: boolean | null;
  thought_block?: boolean | null;
  day_goal?: number | null;
  night_goal?: number | null;
  created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
  user_tags: Array<{ __typename?: 'user_tag'; tag: string }>;
  ratings: Array<{
    __typename?: 'rating';
    created: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    grade: number;
    label: string;
  }>;
  health_carers: Array<{
    __typename?: 'health_carer_user';
    health_carer: { __typename?: 'health_carer'; id: string; kind?: string | null; name?: string | null };
  }>;
  sleep_diaries: Array<{
    __typename?: 'sleep_diary';
    concentration?: number | null;
    date: `${number}-${number}-${number}`;
    energy?: number | null;
    get_up: `${number}:${number}:${number}+${number}`;
    go_bed: `${number}:${number}:${number}+${number}`;
    go_sleep: `${number}:${number}:${number}+${number}`;
    grade?: number | null;
    humor?: number | null;
    relationships?: number | null;
    time_to_sleep: number;
    wake_up: `${number}:${number}:${number}+${number}`;
    wake_up_count: number;
    wake_up_duration: number;
    autogenic_training?: boolean | null;
    behavior_activation?: boolean | null;
    comment?: string | null;
    deep_breath?: boolean | null;
    id: number;
    imagery?: boolean | null;
    meditation?: boolean | null;
    paradoxical_intention?: boolean | null;
    gratitude?: boolean | null;
    light_therapy?: boolean | null;
    challenge_catastrophic_thinking?: boolean | null;
    parking_lot?: boolean | null;
    pmr?: boolean | null;
    stimulus_control?: boolean | null;
    thought_block?: boolean | null;
    timezone?: number | null;
    tags: Array<{ __typename?: 'sleep_diary_sleep_tag'; sleep_tag: Sleep_Tag_Enum_Enum }>;
    sleep_diary_medicines: Array<{ __typename?: 'sleep_diary_medicine'; name: string }>;
  }>;
  psqi: Array<{
    __typename?: 'psqi';
    age: number;
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    q1: string;
    q2: number;
    q3: string;
    q4: number;
    q5a: number;
    q5b: number;
    q5c: number;
    q5d: number;
    q5e: number;
    q5f: number;
    q5g: number;
    q5i: number;
    q5h: number;
    q5j: number;
    q5j_comment: string;
    q6: number;
    q7: number;
    q8: number;
    q9: number;
  }>;
  sleep_restrictions: Array<{
    __typename?: 'sleep_restriction';
    start_date: `${number}-${number}-${number}`;
    desired_wake_up: `${number}:${number}:${number}`;
    hour_to_sleep: `${number}:${number}:${number}`;
    new_time_in_bed: number;
  }>;
  toolbox?: {
    __typename?: 'toolbox';
    autogenic_training?: boolean | null;
    behavior_activation?: boolean | null;
    challenge_catastrophic_thinking?: boolean | null;
    deep_breath?: boolean | null;
    gratitude?: boolean | null;
    imagery?: boolean | null;
    light_therapy?: boolean | null;
    meditation?: boolean | null;
    paradoxical_intention?: boolean | null;
    parking_lot?: boolean | null;
    pmr?: boolean | null;
    sleep_restriction?: boolean | null;
    stimulus_control?: boolean | null;
    thought_block?: boolean | null;
  } | null;
  isi: Array<{
    __typename?: 'isi';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    q1: number;
    q2: number;
    q3: number;
    q4: number;
    q5: number;
    q6: number;
    q7: number;
    age?: number | null;
  }>;
  gad: Array<{
    __typename?: 'gad';
    q1: number;
    q2: number;
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
  phq: Array<{
    __typename?: 'phq';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    q1: number;
    q2: number;
  }>;
  eficiency: Array<{
    __typename?: 'eficiency';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    tib: number;
    tst: number;
  }>;
  productivity: Array<{
    __typename?: 'productivity';
    q4: number;
    q3: number;
    q2: number;
    q1: number;
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
  user_program_sessions: Array<{ __typename?: 'user_program_session'; state: User_Program_Session_Enum_Enum; session_id: number }>;
  logs: Array<{
    __typename?: 'log';
    id: number;
    type: Log_Type_Enum_Enum;
    data?: unknown | null;
    created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
  }>;
  checkout: Array<{
    __typename?: 'user_checkout';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    data: unknown;
    id: number;
  }>;
  phq9s: Array<{
    __typename?: 'phq9';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    q1: number;
    q2: number;
    q3: number;
    q4: number;
    q5: number;
    q6: number;
    q7: number;
    q8: number;
    q9: number;
  }>;
  munich_chronotypes: Array<{
    __typename?: 'munich_chronotype';
    q1: number;
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    q10: string;
    q11: number;
    q12: string;
    q13: number;
    q14: boolean;
    q15: string;
    q16: string;
    q17: number;
    q18: Yes_No_Question_Enum_Enum;
    q19: boolean;
    q2: string;
    q20: boolean;
    q21: boolean;
    q22: Yes_No_Question_Enum_Enum;
    q23: number;
    q3?: number | null;
    q4: string;
    q5: number;
    q6: boolean;
    q7: Yes_No_Question_Enum_Enum;
    q8: string;
    q9: number;
  }>;
  menopause_greene_scales: Array<{
    __typename?: 'menopause_greene_scale';
    q1: number;
    q10: number;
    q11: number;
    q12: number;
    q13: number;
    q14: number;
    q15: number;
    q16: number;
    q17: number;
    q18: number;
    q19: number;
    q2: number;
    q20: number;
    q21: number;
    q3: number;
    q4: number;
    q5: number;
    q6: number;
    q7: number;
    q8: number;
    q9: number;
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
  kuppermans: Array<{
    __typename?: 'kupperman';
    q1: number;
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    q10: number;
    q11: number;
    q12: number;
    q13: number;
    q2: number;
    q3: number;
    q4: number;
    q5: number;
    q6: number;
    q7: number;
    q8: number;
    q9: number;
  }>;
  gad7s: Array<{
    __typename?: 'gad7';
    q1: number;
    q2: number;
    q3: number;
    q4: number;
    q5: number;
    q6: number;
    q7: number;
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
  sleep_tracker_compressed_data_aggregate: {
    __typename?: 'sleep_tracker_compressed_data_aggregate';
    aggregate?: { __typename?: 'sleep_tracker_compressed_data_aggregate_fields'; count?: number | null } | null;
  };
  sleep_diary_apple_healths_aggregate: {
    __typename?: 'sleep_diary_apple_health_aggregate';
    aggregate?: { __typename?: 'sleep_diary_apple_health_aggregate_fields'; count?: number | null } | null;
  };
  sleep_diary_google_fits_aggregate: {
    __typename?: 'sleep_diary_google_fit_aggregate';
    aggregate?: { __typename?: 'sleep_diary_google_fit_aggregate_fields'; count?: number | null } | null;
  };
};

export type LauraAnalysisReportQueryQueryVariables = Exact<{
  limit: Scalars['Int'];
  internal_id: Scalars['Int'];
}>;

export type LauraAnalysisReportQueryQuery = {
  __typename?: 'query_root';
  user: Array<{
    __typename?: 'user';
    internal_id: number;
    id: string;
    phone?: string | null;
    email?: string | null;
    gender?: string | null;
    paid?: boolean | null;
    paid_value?: number | null;
    subscription_expiration?: `${number}-${number}-${number}` | null;
    points?: number | null;
    alcohol: boolean;
    bathroom: boolean;
    caffeine: boolean;
    exercise: boolean;
    heavy_meal: boolean;
    light: boolean;
    nap: boolean;
    nicotine: boolean;
    noise: boolean;
    pain: boolean;
    partner: boolean;
    sleep_medicine: boolean;
    temperature: boolean;
    dream: boolean;
    autogenic_training?: boolean | null;
    behavior_activation?: boolean | null;
    challenge_catastrophic_thinking?: boolean | null;
    deep_breath?: boolean | null;
    gratitude?: boolean | null;
    imagery?: boolean | null;
    light_therapy?: boolean | null;
    meditation?: boolean | null;
    paradoxical_intention?: boolean | null;
    parking_lot?: boolean | null;
    pmr?: boolean | null;
    stimulus_control?: boolean | null;
    thought_block?: boolean | null;
    day_goal?: number | null;
    night_goal?: number | null;
    created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    user_tags: Array<{ __typename?: 'user_tag'; tag: string }>;
    ratings: Array<{
      __typename?: 'rating';
      created: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      grade: number;
      label: string;
    }>;
    health_carers: Array<{
      __typename?: 'health_carer_user';
      health_carer: { __typename?: 'health_carer'; id: string; kind?: string | null; name?: string | null };
    }>;
    sleep_diaries: Array<{
      __typename?: 'sleep_diary';
      concentration?: number | null;
      date: `${number}-${number}-${number}`;
      energy?: number | null;
      get_up: `${number}:${number}:${number}+${number}`;
      go_bed: `${number}:${number}:${number}+${number}`;
      go_sleep: `${number}:${number}:${number}+${number}`;
      grade?: number | null;
      humor?: number | null;
      relationships?: number | null;
      time_to_sleep: number;
      wake_up: `${number}:${number}:${number}+${number}`;
      wake_up_count: number;
      wake_up_duration: number;
      autogenic_training?: boolean | null;
      behavior_activation?: boolean | null;
      comment?: string | null;
      deep_breath?: boolean | null;
      id: number;
      imagery?: boolean | null;
      meditation?: boolean | null;
      paradoxical_intention?: boolean | null;
      gratitude?: boolean | null;
      light_therapy?: boolean | null;
      challenge_catastrophic_thinking?: boolean | null;
      parking_lot?: boolean | null;
      pmr?: boolean | null;
      stimulus_control?: boolean | null;
      thought_block?: boolean | null;
      timezone?: number | null;
      tags: Array<{ __typename?: 'sleep_diary_sleep_tag'; sleep_tag: Sleep_Tag_Enum_Enum }>;
      sleep_diary_medicines: Array<{ __typename?: 'sleep_diary_medicine'; name: string }>;
    }>;
    psqi: Array<{
      __typename?: 'psqi';
      age: number;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      q1: string;
      q2: number;
      q3: string;
      q4: number;
      q5a: number;
      q5b: number;
      q5c: number;
      q5d: number;
      q5e: number;
      q5f: number;
      q5g: number;
      q5i: number;
      q5h: number;
      q5j: number;
      q5j_comment: string;
      q6: number;
      q7: number;
      q8: number;
      q9: number;
    }>;
    sleep_restrictions: Array<{
      __typename?: 'sleep_restriction';
      start_date: `${number}-${number}-${number}`;
      desired_wake_up: `${number}:${number}:${number}`;
      hour_to_sleep: `${number}:${number}:${number}`;
      new_time_in_bed: number;
    }>;
    toolbox?: {
      __typename?: 'toolbox';
      autogenic_training?: boolean | null;
      behavior_activation?: boolean | null;
      challenge_catastrophic_thinking?: boolean | null;
      deep_breath?: boolean | null;
      gratitude?: boolean | null;
      imagery?: boolean | null;
      light_therapy?: boolean | null;
      meditation?: boolean | null;
      paradoxical_intention?: boolean | null;
      parking_lot?: boolean | null;
      pmr?: boolean | null;
      sleep_restriction?: boolean | null;
      stimulus_control?: boolean | null;
      thought_block?: boolean | null;
    } | null;
    isi: Array<{
      __typename?: 'isi';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      q1: number;
      q2: number;
      q3: number;
      q4: number;
      q5: number;
      q6: number;
      q7: number;
      age?: number | null;
    }>;
    gad: Array<{
      __typename?: 'gad';
      q1: number;
      q2: number;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
    phq: Array<{
      __typename?: 'phq';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      q1: number;
      q2: number;
    }>;
    eficiency: Array<{
      __typename?: 'eficiency';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      tib: number;
      tst: number;
    }>;
    productivity: Array<{
      __typename?: 'productivity';
      q4: number;
      q3: number;
      q2: number;
      q1: number;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
    user_program_sessions: Array<{ __typename?: 'user_program_session'; state: User_Program_Session_Enum_Enum; session_id: number }>;
    logs: Array<{
      __typename?: 'log';
      id: number;
      type: Log_Type_Enum_Enum;
      data?: unknown | null;
      created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    }>;
    checkout: Array<{
      __typename?: 'user_checkout';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      data: unknown;
      id: number;
    }>;
    phq9s: Array<{
      __typename?: 'phq9';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      q1: number;
      q2: number;
      q3: number;
      q4: number;
      q5: number;
      q6: number;
      q7: number;
      q8: number;
      q9: number;
    }>;
    munich_chronotypes: Array<{
      __typename?: 'munich_chronotype';
      q1: number;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      q10: string;
      q11: number;
      q12: string;
      q13: number;
      q14: boolean;
      q15: string;
      q16: string;
      q17: number;
      q18: Yes_No_Question_Enum_Enum;
      q19: boolean;
      q2: string;
      q20: boolean;
      q21: boolean;
      q22: Yes_No_Question_Enum_Enum;
      q23: number;
      q3?: number | null;
      q4: string;
      q5: number;
      q6: boolean;
      q7: Yes_No_Question_Enum_Enum;
      q8: string;
      q9: number;
    }>;
    menopause_greene_scales: Array<{
      __typename?: 'menopause_greene_scale';
      q1: number;
      q10: number;
      q11: number;
      q12: number;
      q13: number;
      q14: number;
      q15: number;
      q16: number;
      q17: number;
      q18: number;
      q19: number;
      q2: number;
      q20: number;
      q21: number;
      q3: number;
      q4: number;
      q5: number;
      q6: number;
      q7: number;
      q8: number;
      q9: number;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
    kuppermans: Array<{
      __typename?: 'kupperman';
      q1: number;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      q10: number;
      q11: number;
      q12: number;
      q13: number;
      q2: number;
      q3: number;
      q4: number;
      q5: number;
      q6: number;
      q7: number;
      q8: number;
      q9: number;
    }>;
    gad7s: Array<{
      __typename?: 'gad7';
      q1: number;
      q2: number;
      q3: number;
      q4: number;
      q5: number;
      q6: number;
      q7: number;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
    sleep_tracker_compressed_data_aggregate: {
      __typename?: 'sleep_tracker_compressed_data_aggregate';
      aggregate?: { __typename?: 'sleep_tracker_compressed_data_aggregate_fields'; count?: number | null } | null;
    };
    sleep_diary_apple_healths_aggregate: {
      __typename?: 'sleep_diary_apple_health_aggregate';
      aggregate?: { __typename?: 'sleep_diary_apple_health_aggregate_fields'; count?: number | null } | null;
    };
    sleep_diary_google_fits_aggregate: {
      __typename?: 'sleep_diary_google_fit_aggregate';
      aggregate?: { __typename?: 'sleep_diary_google_fit_aggregate_fields'; count?: number | null } | null;
    };
  }>;
};

export type LauraAnalysisReportByUserQueryQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
}>;

export type LauraAnalysisReportByUserQueryQuery = {
  __typename?: 'query_root';
  user: Array<{
    __typename?: 'user';
    internal_id: number;
    id: string;
    phone?: string | null;
    email?: string | null;
    gender?: string | null;
    paid?: boolean | null;
    paid_value?: number | null;
    subscription_expiration?: `${number}-${number}-${number}` | null;
    points?: number | null;
    alcohol: boolean;
    bathroom: boolean;
    caffeine: boolean;
    exercise: boolean;
    heavy_meal: boolean;
    light: boolean;
    nap: boolean;
    nicotine: boolean;
    noise: boolean;
    pain: boolean;
    partner: boolean;
    sleep_medicine: boolean;
    temperature: boolean;
    dream: boolean;
    autogenic_training?: boolean | null;
    behavior_activation?: boolean | null;
    challenge_catastrophic_thinking?: boolean | null;
    deep_breath?: boolean | null;
    gratitude?: boolean | null;
    imagery?: boolean | null;
    light_therapy?: boolean | null;
    meditation?: boolean | null;
    paradoxical_intention?: boolean | null;
    parking_lot?: boolean | null;
    pmr?: boolean | null;
    stimulus_control?: boolean | null;
    thought_block?: boolean | null;
    day_goal?: number | null;
    night_goal?: number | null;
    created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    user_tags: Array<{ __typename?: 'user_tag'; tag: string }>;
    ratings: Array<{
      __typename?: 'rating';
      created: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      grade: number;
      label: string;
    }>;
    health_carers: Array<{
      __typename?: 'health_carer_user';
      health_carer: { __typename?: 'health_carer'; id: string; kind?: string | null; name?: string | null };
    }>;
    sleep_diaries: Array<{
      __typename?: 'sleep_diary';
      concentration?: number | null;
      date: `${number}-${number}-${number}`;
      energy?: number | null;
      get_up: `${number}:${number}:${number}+${number}`;
      go_bed: `${number}:${number}:${number}+${number}`;
      go_sleep: `${number}:${number}:${number}+${number}`;
      grade?: number | null;
      humor?: number | null;
      relationships?: number | null;
      time_to_sleep: number;
      wake_up: `${number}:${number}:${number}+${number}`;
      wake_up_count: number;
      wake_up_duration: number;
      autogenic_training?: boolean | null;
      behavior_activation?: boolean | null;
      comment?: string | null;
      deep_breath?: boolean | null;
      id: number;
      imagery?: boolean | null;
      meditation?: boolean | null;
      paradoxical_intention?: boolean | null;
      gratitude?: boolean | null;
      light_therapy?: boolean | null;
      challenge_catastrophic_thinking?: boolean | null;
      parking_lot?: boolean | null;
      pmr?: boolean | null;
      stimulus_control?: boolean | null;
      thought_block?: boolean | null;
      timezone?: number | null;
      tags: Array<{ __typename?: 'sleep_diary_sleep_tag'; sleep_tag: Sleep_Tag_Enum_Enum }>;
      sleep_diary_medicines: Array<{ __typename?: 'sleep_diary_medicine'; name: string }>;
    }>;
    psqi: Array<{
      __typename?: 'psqi';
      age: number;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      q1: string;
      q2: number;
      q3: string;
      q4: number;
      q5a: number;
      q5b: number;
      q5c: number;
      q5d: number;
      q5e: number;
      q5f: number;
      q5g: number;
      q5i: number;
      q5h: number;
      q5j: number;
      q5j_comment: string;
      q6: number;
      q7: number;
      q8: number;
      q9: number;
    }>;
    sleep_restrictions: Array<{
      __typename?: 'sleep_restriction';
      start_date: `${number}-${number}-${number}`;
      desired_wake_up: `${number}:${number}:${number}`;
      hour_to_sleep: `${number}:${number}:${number}`;
      new_time_in_bed: number;
    }>;
    toolbox?: {
      __typename?: 'toolbox';
      autogenic_training?: boolean | null;
      behavior_activation?: boolean | null;
      challenge_catastrophic_thinking?: boolean | null;
      deep_breath?: boolean | null;
      gratitude?: boolean | null;
      imagery?: boolean | null;
      light_therapy?: boolean | null;
      meditation?: boolean | null;
      paradoxical_intention?: boolean | null;
      parking_lot?: boolean | null;
      pmr?: boolean | null;
      sleep_restriction?: boolean | null;
      stimulus_control?: boolean | null;
      thought_block?: boolean | null;
    } | null;
    isi: Array<{
      __typename?: 'isi';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      q1: number;
      q2: number;
      q3: number;
      q4: number;
      q5: number;
      q6: number;
      q7: number;
      age?: number | null;
    }>;
    gad: Array<{
      __typename?: 'gad';
      q1: number;
      q2: number;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
    phq: Array<{
      __typename?: 'phq';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      q1: number;
      q2: number;
    }>;
    eficiency: Array<{
      __typename?: 'eficiency';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      tib: number;
      tst: number;
    }>;
    productivity: Array<{
      __typename?: 'productivity';
      q4: number;
      q3: number;
      q2: number;
      q1: number;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
    user_program_sessions: Array<{ __typename?: 'user_program_session'; state: User_Program_Session_Enum_Enum; session_id: number }>;
    logs: Array<{
      __typename?: 'log';
      id: number;
      type: Log_Type_Enum_Enum;
      data?: unknown | null;
      created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    }>;
    checkout: Array<{
      __typename?: 'user_checkout';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      data: unknown;
      id: number;
    }>;
    phq9s: Array<{
      __typename?: 'phq9';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      q1: number;
      q2: number;
      q3: number;
      q4: number;
      q5: number;
      q6: number;
      q7: number;
      q8: number;
      q9: number;
    }>;
    munich_chronotypes: Array<{
      __typename?: 'munich_chronotype';
      q1: number;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      q10: string;
      q11: number;
      q12: string;
      q13: number;
      q14: boolean;
      q15: string;
      q16: string;
      q17: number;
      q18: Yes_No_Question_Enum_Enum;
      q19: boolean;
      q2: string;
      q20: boolean;
      q21: boolean;
      q22: Yes_No_Question_Enum_Enum;
      q23: number;
      q3?: number | null;
      q4: string;
      q5: number;
      q6: boolean;
      q7: Yes_No_Question_Enum_Enum;
      q8: string;
      q9: number;
    }>;
    menopause_greene_scales: Array<{
      __typename?: 'menopause_greene_scale';
      q1: number;
      q10: number;
      q11: number;
      q12: number;
      q13: number;
      q14: number;
      q15: number;
      q16: number;
      q17: number;
      q18: number;
      q19: number;
      q2: number;
      q20: number;
      q21: number;
      q3: number;
      q4: number;
      q5: number;
      q6: number;
      q7: number;
      q8: number;
      q9: number;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
    kuppermans: Array<{
      __typename?: 'kupperman';
      q1: number;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      q10: number;
      q11: number;
      q12: number;
      q13: number;
      q2: number;
      q3: number;
      q4: number;
      q5: number;
      q6: number;
      q7: number;
      q8: number;
      q9: number;
    }>;
    gad7s: Array<{
      __typename?: 'gad7';
      q1: number;
      q2: number;
      q3: number;
      q4: number;
      q5: number;
      q6: number;
      q7: number;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
    sleep_tracker_compressed_data_aggregate: {
      __typename?: 'sleep_tracker_compressed_data_aggregate';
      aggregate?: { __typename?: 'sleep_tracker_compressed_data_aggregate_fields'; count?: number | null } | null;
    };
    sleep_diary_apple_healths_aggregate: {
      __typename?: 'sleep_diary_apple_health_aggregate';
      aggregate?: { __typename?: 'sleep_diary_apple_health_aggregate_fields'; count?: number | null } | null;
    };
    sleep_diary_google_fits_aggregate: {
      __typename?: 'sleep_diary_google_fit_aggregate';
      aggregate?: { __typename?: 'sleep_diary_google_fit_aggregate_fields'; count?: number | null } | null;
    };
  }>;
};

export type LauraUsersQueryQueryVariables = Exact<{
  _gte?: InputMaybe<Scalars['timestamptz']>;
}>;

export type LauraUsersQueryQuery = {
  __typename?: 'query_root';
  sleep_diary: Array<{ __typename?: 'sleep_diary'; user_id: string }>;
  isi: Array<{ __typename?: 'isi'; user_id: string }>;
  gad: Array<{ __typename?: 'gad'; user_id: string }>;
  gad7: Array<{ __typename?: 'gad7'; user_id: string }>;
  phq: Array<{ __typename?: 'phq'; user_id: string }>;
  phq9: Array<{ __typename?: 'phq9'; user_id: string }>;
};

export type LauraAnalysisReportForCompanyQueryQueryVariables = Exact<{
  limit: Scalars['Int'];
  internal_id: Scalars['Int'];
  company_id?: InputMaybe<Scalars['Int']>;
}>;

export type LauraAnalysisReportForCompanyQueryQuery = {
  __typename?: 'query_root';
  user: Array<{
    __typename?: 'user';
    internal_id: number;
    id: string;
    phone?: string | null;
    email?: string | null;
    gender?: string | null;
    paid?: boolean | null;
    paid_value?: number | null;
    subscription_expiration?: `${number}-${number}-${number}` | null;
    points?: number | null;
    alcohol: boolean;
    bathroom: boolean;
    caffeine: boolean;
    exercise: boolean;
    heavy_meal: boolean;
    light: boolean;
    nap: boolean;
    nicotine: boolean;
    noise: boolean;
    pain: boolean;
    partner: boolean;
    sleep_medicine: boolean;
    temperature: boolean;
    dream: boolean;
    autogenic_training?: boolean | null;
    behavior_activation?: boolean | null;
    challenge_catastrophic_thinking?: boolean | null;
    deep_breath?: boolean | null;
    gratitude?: boolean | null;
    imagery?: boolean | null;
    light_therapy?: boolean | null;
    meditation?: boolean | null;
    paradoxical_intention?: boolean | null;
    parking_lot?: boolean | null;
    pmr?: boolean | null;
    stimulus_control?: boolean | null;
    thought_block?: boolean | null;
    day_goal?: number | null;
    night_goal?: number | null;
    created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    user_tags: Array<{ __typename?: 'user_tag'; tag: string }>;
    ratings: Array<{
      __typename?: 'rating';
      created: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      grade: number;
      label: string;
    }>;
    health_carers: Array<{
      __typename?: 'health_carer_user';
      health_carer: { __typename?: 'health_carer'; id: string; kind?: string | null; name?: string | null };
    }>;
    sleep_diaries: Array<{
      __typename?: 'sleep_diary';
      concentration?: number | null;
      date: `${number}-${number}-${number}`;
      energy?: number | null;
      get_up: `${number}:${number}:${number}+${number}`;
      go_bed: `${number}:${number}:${number}+${number}`;
      go_sleep: `${number}:${number}:${number}+${number}`;
      grade?: number | null;
      humor?: number | null;
      relationships?: number | null;
      time_to_sleep: number;
      wake_up: `${number}:${number}:${number}+${number}`;
      wake_up_count: number;
      wake_up_duration: number;
      autogenic_training?: boolean | null;
      behavior_activation?: boolean | null;
      comment?: string | null;
      deep_breath?: boolean | null;
      id: number;
      imagery?: boolean | null;
      meditation?: boolean | null;
      paradoxical_intention?: boolean | null;
      gratitude?: boolean | null;
      light_therapy?: boolean | null;
      challenge_catastrophic_thinking?: boolean | null;
      parking_lot?: boolean | null;
      pmr?: boolean | null;
      stimulus_control?: boolean | null;
      thought_block?: boolean | null;
      timezone?: number | null;
      tags: Array<{ __typename?: 'sleep_diary_sleep_tag'; sleep_tag: Sleep_Tag_Enum_Enum }>;
      sleep_diary_medicines: Array<{ __typename?: 'sleep_diary_medicine'; name: string }>;
    }>;
    psqi: Array<{
      __typename?: 'psqi';
      age: number;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      q1: string;
      q2: number;
      q3: string;
      q4: number;
      q5a: number;
      q5b: number;
      q5c: number;
      q5d: number;
      q5e: number;
      q5f: number;
      q5g: number;
      q5i: number;
      q5h: number;
      q5j: number;
      q5j_comment: string;
      q6: number;
      q7: number;
      q8: number;
      q9: number;
    }>;
    sleep_restrictions: Array<{
      __typename?: 'sleep_restriction';
      start_date: `${number}-${number}-${number}`;
      desired_wake_up: `${number}:${number}:${number}`;
      hour_to_sleep: `${number}:${number}:${number}`;
      new_time_in_bed: number;
    }>;
    toolbox?: {
      __typename?: 'toolbox';
      autogenic_training?: boolean | null;
      behavior_activation?: boolean | null;
      challenge_catastrophic_thinking?: boolean | null;
      deep_breath?: boolean | null;
      gratitude?: boolean | null;
      imagery?: boolean | null;
      light_therapy?: boolean | null;
      meditation?: boolean | null;
      paradoxical_intention?: boolean | null;
      parking_lot?: boolean | null;
      pmr?: boolean | null;
      sleep_restriction?: boolean | null;
      stimulus_control?: boolean | null;
      thought_block?: boolean | null;
    } | null;
    isi: Array<{
      __typename?: 'isi';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      q1: number;
      q2: number;
      q3: number;
      q4: number;
      q5: number;
      q6: number;
      q7: number;
      age?: number | null;
    }>;
    gad: Array<{
      __typename?: 'gad';
      q1: number;
      q2: number;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
    phq: Array<{
      __typename?: 'phq';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      q1: number;
      q2: number;
    }>;
    eficiency: Array<{
      __typename?: 'eficiency';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      tib: number;
      tst: number;
    }>;
    productivity: Array<{
      __typename?: 'productivity';
      q4: number;
      q3: number;
      q2: number;
      q1: number;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
    user_program_sessions: Array<{ __typename?: 'user_program_session'; state: User_Program_Session_Enum_Enum; session_id: number }>;
    logs: Array<{
      __typename?: 'log';
      id: number;
      type: Log_Type_Enum_Enum;
      data?: unknown | null;
      created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    }>;
    checkout: Array<{
      __typename?: 'user_checkout';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      data: unknown;
      id: number;
    }>;
    phq9s: Array<{
      __typename?: 'phq9';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      q1: number;
      q2: number;
      q3: number;
      q4: number;
      q5: number;
      q6: number;
      q7: number;
      q8: number;
      q9: number;
    }>;
    munich_chronotypes: Array<{
      __typename?: 'munich_chronotype';
      q1: number;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      q10: string;
      q11: number;
      q12: string;
      q13: number;
      q14: boolean;
      q15: string;
      q16: string;
      q17: number;
      q18: Yes_No_Question_Enum_Enum;
      q19: boolean;
      q2: string;
      q20: boolean;
      q21: boolean;
      q22: Yes_No_Question_Enum_Enum;
      q23: number;
      q3?: number | null;
      q4: string;
      q5: number;
      q6: boolean;
      q7: Yes_No_Question_Enum_Enum;
      q8: string;
      q9: number;
    }>;
    menopause_greene_scales: Array<{
      __typename?: 'menopause_greene_scale';
      q1: number;
      q10: number;
      q11: number;
      q12: number;
      q13: number;
      q14: number;
      q15: number;
      q16: number;
      q17: number;
      q18: number;
      q19: number;
      q2: number;
      q20: number;
      q21: number;
      q3: number;
      q4: number;
      q5: number;
      q6: number;
      q7: number;
      q8: number;
      q9: number;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
    kuppermans: Array<{
      __typename?: 'kupperman';
      q1: number;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      q10: number;
      q11: number;
      q12: number;
      q13: number;
      q2: number;
      q3: number;
      q4: number;
      q5: number;
      q6: number;
      q7: number;
      q8: number;
      q9: number;
    }>;
    gad7s: Array<{
      __typename?: 'gad7';
      q1: number;
      q2: number;
      q3: number;
      q4: number;
      q5: number;
      q6: number;
      q7: number;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
    sleep_tracker_compressed_data_aggregate: {
      __typename?: 'sleep_tracker_compressed_data_aggregate';
      aggregate?: { __typename?: 'sleep_tracker_compressed_data_aggregate_fields'; count?: number | null } | null;
    };
    sleep_diary_apple_healths_aggregate: {
      __typename?: 'sleep_diary_apple_health_aggregate';
      aggregate?: { __typename?: 'sleep_diary_apple_health_aggregate_fields'; count?: number | null } | null;
    };
    sleep_diary_google_fits_aggregate: {
      __typename?: 'sleep_diary_google_fit_aggregate';
      aggregate?: { __typename?: 'sleep_diary_google_fit_aggregate_fields'; count?: number | null } | null;
    };
  }>;
};

export type UserPointsQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type UserPointsQueryQuery = { __typename?: 'query_root'; user: Array<{ __typename?: 'user'; id: string; points?: number | null }> };

export type UserQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type UserQueryQuery = {
  __typename?: 'query_root';
  user: Array<{
    __typename?: 'user';
    id: string;
    points?: number | null;
    email?: string | null;
    phone?: string | null;
    desired_wake_up?: `${number}:${number}:${number}+${number}` | null;
    session_to_pay: number;
    subscription_expiration?: `${number}-${number}-${number}` | null;
    paid?: boolean | null;
    paid_value?: number | null;
    current_journey_id?: number | null;
    user_coupons: Array<{ __typename?: 'user_coupon'; coupon?: { __typename?: 'coupon'; code: string } | null }>;
  }>;
};

export type RenewSubscriptionMutationMutationVariables = Exact<{
  id: Scalars['String'];
  subscription_expiration: Scalars['date'];
}>;

export type RenewSubscriptionMutationMutation = {
  __typename?: 'mutation_root';
  update_user_by_pk?: {
    __typename?: 'user';
    id: string;
    paid?: boolean | null;
    subscription_expiration?: `${number}-${number}-${number}` | null;
  } | null;
};

export type UserReportQueryQueryVariables = Exact<{
  limit: Scalars['Int'];
  internal_id: Scalars['Int'];
}>;

export type UserReportQueryQuery = {
  __typename?: 'query_root';
  user: Array<{
    __typename?: 'user';
    first_name?: string | null;
    last_name?: string | null;
    subscription_expiration?: `${number}-${number}-${number}` | null;
    paid?: boolean | null;
    paid_value?: number | null;
    email?: string | null;
    phone?: string | null;
    id: string;
    session_1_state: number;
    session_2_state: number;
    session_3_state: number;
    session_4_state: number;
    session_5_state: number;
    session_6_state: number;
    session_7_state: number;
    session_8_state: number;
    session_9_state: number;
    session_10_state: number;
    session_11_state: number;
    session_12_state: number;
    session_13_state: number;
    session_14_state: number;
    session_15_state: number;
    session_16_state: number;
    session_17_state: number;
    session_18_state: number;
    session_19_state: number;
    session_20_state: number;
    session_21_state: number;
    session_22_state: number;
    session_23_state: number;
    session_24_state: number;
    session_25_state: number;
    session_26_state: number;
    session_27_state: number;
    session_28_state: number;
    session_29_state: number;
    session_30_state: number;
    session_31_state: number;
    session_32_state: number;
    session_33_state: number;
    session_34_state: number;
    session_35_state: number;
    session_36_state: number;
    session_37_state: number;
    session_38_state: number;
    session_39_state: number;
    session_40_state: number;
    session_41_state: number;
    session_42_state: number;
    session_43_state: number;
    session_44_state: number;
    session_45_state: number;
    session_46_state: number;
    session_47_state: number;
    session_48_state: number;
    session_49_state: number;
    session_50_state: number;
    session_51_state: number;
    session_52_state: number;
    session_53_state: number;
    session_54_state: number;
    session_55_state: number;
    session_56_state: number;
    session_57_state: number;
    session_58_state: number;
    session_59_state: number;
    session_60_state: number;
    created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    internal_id: number;
    ratings: Array<{
      __typename?: 'rating';
      label: string;
      grade: number;
      created: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
  }>;
};

export type SetCurrentJourneyMutationMutationVariables = Exact<{
  user_id: Scalars['String'];
  journey_id: Scalars['Int'];
}>;

export type SetCurrentJourneyMutationMutation = {
  __typename?: 'mutation_root';
  insert_user_one?: {
    __typename?: 'user';
    id: string;
    current_journey_id?: number | null;
    user_journeys: Array<{ __typename?: 'user_journey'; journey_id: number }>;
  } | null;
};

export type ToolboxSleepDiaryConfigQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ToolboxSleepDiaryConfigQueryQuery = {
  __typename?: 'query_root';
  toolbox: Array<{
    __typename?: 'toolbox';
    user_id: string;
    autogenic_training?: boolean | null;
    behavior_activation?: boolean | null;
    deep_breath?: boolean | null;
    imagery?: boolean | null;
    meditation?: boolean | null;
    paradoxical_intention?: boolean | null;
    parking_lot?: boolean | null;
    gratitude?: boolean | null;
    light_therapy?: boolean | null;
    challenge_catastrophic_thinking?: boolean | null;
    pmr?: boolean | null;
    stimulus_control?: boolean | null;
    thought_block?: boolean | null;
  }>;
  user: Array<{
    __typename?: 'user';
    autogenic_training?: boolean | null;
    behavior_activation?: boolean | null;
    deep_breath?: boolean | null;
    imagery?: boolean | null;
    meditation?: boolean | null;
    paradoxical_intention?: boolean | null;
    parking_lot?: boolean | null;
    gratitude?: boolean | null;
    light_therapy?: boolean | null;
    challenge_catastrophic_thinking?: boolean | null;
    pmr?: boolean | null;
    stimulus_control?: boolean | null;
    thought_block?: boolean | null;
  }>;
};

export type GetSplitPaymentInfoQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetSplitPaymentInfoQueryQuery = {
  __typename?: 'query_root';
  user_by_pk?: {
    __typename?: 'user';
    id: string;
    first_name?: string | null;
    last_name?: string | null;
    email?: string | null;
    phone?: string | null;
    health_carers: Array<{
      __typename?: 'health_carer_user';
      created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
      health_carer: {
        __typename?: 'health_carer';
        id: string;
        name?: string | null;
        email: string;
        source?: string | null;
        membership_kind: Health_Carer_Membership_Kind_Enum_Enum;
        companies: Array<{ __typename?: 'company_health_carer'; company_id: number }>;
        pagarme_recipient?: { __typename?: 'health_carer_pagarme_recipient'; pagarme_recipient_id: string } | null;
      };
    }>;
  } | null;
};

export type GetSubscriptionStatusQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetSubscriptionStatusQueryQuery = {
  __typename?: 'query_root';
  user_by_pk?: { __typename?: 'user'; paid?: boolean | null; subscription_expiration?: `${number}-${number}-${number}` | null } | null;
};

export type UserUpsertMutationVariables = Exact<{
  objects: Array<User_Insert_Input> | User_Insert_Input;
  update_columns: Array<User_Update_Column> | User_Update_Column;
}>;

export type UserUpsertMutation = {
  __typename?: 'mutation_root';
  insert_user?: { __typename?: 'user_mutation_response'; returning: Array<{ __typename?: 'user'; id: string }> } | null;
};

export type UpsertUserProgramSessionMutationMutationVariables = Exact<{
  user_id: Scalars['String'];
  current_journey_id: Scalars['Int'];
  objects: Array<User_Program_Session_Insert_Input> | User_Program_Session_Insert_Input;
}>;

export type UpsertUserProgramSessionMutationMutation = {
  __typename?: 'mutation_root';
  insert_user_one?: { __typename?: 'user'; current_journey_id?: number | null } | null;
  insert_user_program_session?: {
    __typename?: 'user_program_session_mutation_response';
    affected_rows: number;
    returning: Array<{ __typename?: 'user_program_session'; state: User_Program_Session_Enum_Enum; user_id: string; session_id: number }>;
  } | null;
};

export type UpsertUserProgramSessionWithoutCurrentIdMutationMutationVariables = Exact<{
  objects: Array<User_Program_Session_Insert_Input> | User_Program_Session_Insert_Input;
}>;

export type UpsertUserProgramSessionWithoutCurrentIdMutationMutation = {
  __typename?: 'mutation_root';
  insert_user_program_session?: {
    __typename?: 'user_program_session_mutation_response';
    affected_rows: number;
    returning: Array<{ __typename?: 'user_program_session'; state: User_Program_Session_Enum_Enum; user_id: string; session_id: number }>;
  } | null;
};

export type GetAllDoctorsQueryQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllDoctorsQueryQuery = { __typename?: 'query_root'; health_carer: Array<{ __typename?: 'health_carer'; id: string }> };

export type GetAllDoctorCouponsQueryQueryVariables = Exact<{
  hcp: Scalars['String'];
}>;

export type GetAllDoctorCouponsQueryQuery = {
  __typename?: 'query_root';
  coupon: Array<{
    __typename?: 'coupon';
    active: boolean;
    code: string;
    comment?: string | null;
    expiration_date?: `${number}-${number}-${number}` | null;
    kind?: string | null;
    name: string;
    owner_company?: number | null;
    owner_hcp?: string | null;
    quantity?: number | null;
    redeem_message: string;
    value?: string | null;
  }>;
};

export type UpdateDoctorCrmMutationVariables = Exact<{
  id: Scalars['String'];
  professional_id: Scalars['String'];
  professional_id_state: Scalars['String'];
}>;

export type UpdateDoctorCrmMutation = {
  __typename?: 'mutation_root';
  update_health_carer_by_pk?: { __typename?: 'health_carer'; id: string } | null;
};

export type GetCouponsbyRedeemMessageQueryQueryVariables = Exact<{
  redeemMessagePattern: Scalars['String'];
}>;

export type GetCouponsbyRedeemMessageQueryQuery = {
  __typename?: 'query_root';
  coupon: Array<{
    __typename?: 'coupon';
    active: boolean;
    code: string;
    comment?: string | null;
    expiration_date?: `${number}-${number}-${number}` | null;
    kind?: string | null;
    name: string;
    owner_company?: number | null;
    owner_hcp?: string | null;
    quantity?: number | null;
    redeem_message: string;
    value?: string | null;
  }>;
};

export type GetCouponsForGenerateCrmCouponQueryQueryVariables = Exact<{
  coupons: Array<Scalars['String']> | Scalars['String'];
}>;

export type GetCouponsForGenerateCrmCouponQueryQuery = {
  __typename?: 'query_root';
  coupon: Array<{
    __typename?: 'coupon';
    id: number;
    code: string;
    hcp?: { __typename?: 'health_carer'; id: string; name?: string | null } | null;
  }>;
};

export type GetCompaniesForGenerateCrmCouponQueryQueryVariables = Exact<{
  companies: Array<Scalars['Int']> | Scalars['Int'];
}>;

export type GetCompaniesForGenerateCrmCouponQueryQuery = {
  __typename?: 'query_root';
  company: Array<{ __typename?: 'company'; id: number; name: string }>;
};

export type CouponCodesQueryQueryVariables = Exact<{
  codes: Array<Scalars['String']> | Scalars['String'];
}>;

export type CouponCodesQueryQuery = { __typename?: 'query_root'; coupon: Array<{ __typename?: 'coupon'; code: string }> };

export type UserCloneReportMutationMutationVariables = Exact<{
  user: User_Insert_Input;
  toolbox: Toolbox_Insert_Input;
  user_reminder_info: User_Reminder_Info_Insert_Input;
}>;

export type UserCloneReportMutationMutation = {
  __typename?: 'mutation_root';
  insert_user_one?: { __typename?: 'user'; internal_id: number } | null;
  insert_toolbox_one?: { __typename?: 'toolbox'; id: number } | null;
  insert_user_reminder_info_one?: { __typename?: 'user_reminder_info'; messenger_id: string } | null;
};

export type DeleteUserMutationMutationVariables = Exact<{
  userId: Scalars['String'];
}>;

export type DeleteUserMutationMutation = {
  __typename?: 'mutation_root';
  delete_sleep_diary_sleep_tag?: {
    __typename?: 'sleep_diary_sleep_tag_mutation_response';
    affected_rows: number;
    returning: Array<{ __typename?: 'sleep_diary_sleep_tag'; sleep_tag: Sleep_Tag_Enum_Enum; sleep_diary: number }>;
  } | null;
  delete_sleep_diary_medicine?: {
    __typename?: 'sleep_diary_medicine_mutation_response';
    affected_rows: number;
    returning: Array<{ __typename?: 'sleep_diary_medicine'; dose: number; name: string; id: number; sleep_diary_id: number }>;
  } | null;
  delete_gad7?: {
    __typename?: 'gad7_mutation_response';
    returning: Array<{
      __typename?: 'gad7';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      user_id: string;
      id: number;
      q1: number;
      q2: number;
      q3: number;
      q4: number;
      q5: number;
      q6: number;
      q7: number;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
  } | null;
  delete_google_sheet_row?: {
    __typename?: 'google_sheet_row_mutation_response';
    returning: Array<{
      __typename?: 'google_sheet_row';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      data: unknown;
      from_block?: string | null;
      id: number;
      spreadsheet_id: string;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      user_id?: string | null;
    }>;
  } | null;
  delete_phq9?: {
    __typename?: 'phq9_mutation_response';
    returning: Array<{
      __typename?: 'phq9';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      user_id: string;
      id: number;
      q1: number;
      q2: number;
      q3: number;
      q4: number;
      q5: number;
      q6: number;
      q7: number;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      q9: number;
      q8: number;
    }>;
  } | null;
  delete_menopause_greene_scale?: {
    __typename?: 'menopause_greene_scale_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'menopause_greene_scale';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      user_id: string;
      id: number;
      q1: number;
      q2: number;
      q3: number;
      q4: number;
      q5: number;
      q6: number;
      q7: number;
      q8: number;
      q9: number;
      q10: number;
      q11: number;
      q12: number;
      q13: number;
      q14: number;
      q15: number;
      q16: number;
      q17: number;
      q18: number;
      q19: number;
      q20: number;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      q21: number;
    }>;
  } | null;
  delete_sleep_diary?: {
    __typename?: 'sleep_diary_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'sleep_diary';
      id: number;
      wake_up_duration: number;
      wake_up_count: number;
      wake_up: `${number}:${number}:${number}+${number}`;
      user_id: string;
      timezone?: number | null;
      time_to_sleep: number;
      thought_block?: boolean | null;
      stimulus_control?: boolean | null;
      relationships?: number | null;
      pmr?: boolean | null;
      parking_lot?: boolean | null;
      paradoxical_intention?: boolean | null;
      meditation?: boolean | null;
      light_therapy?: boolean | null;
      imagery?: boolean | null;
      humor?: number | null;
      gratitude?: boolean | null;
      grade?: number | null;
      go_sleep: `${number}:${number}:${number}+${number}`;
      go_bed: `${number}:${number}:${number}+${number}`;
      get_up: `${number}:${number}:${number}+${number}`;
      energy?: number | null;
      deep_breath?: boolean | null;
      behavior_activation?: boolean | null;
      date: `${number}-${number}-${number}`;
      created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
      concentration?: number | null;
      comment?: string | null;
      challenge_catastrophic_thinking?: boolean | null;
      autogenic_training?: boolean | null;
      medicine?: boolean | null;
    }>;
  } | null;
  delete_psqi?: {
    __typename?: 'psqi_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'psqi';
      id: number;
      user_id: string;
      q9: number;
      q8: number;
      q7: number;
      q6: number;
      q5j_comment: string;
      q5j: number;
      q5i: number;
      q5h: number;
      q5g: number;
      q5f: number;
      q5e: number;
      q5d: number;
      q5c: number;
      q5b: number;
      q5a: number;
      q4: number;
      q3: string;
      q2: number;
      q1: string;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      age: number;
    }>;
  } | null;
  delete_sleep_restriction?: {
    __typename?: 'sleep_restriction_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'sleep_restriction';
      user_id: string;
      start_date: `${number}-${number}-${number}`;
      new_time_in_bed: number;
      hour_to_sleep: `${number}:${number}:${number}`;
      desired_wake_up: `${number}:${number}:${number}`;
      created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
      comment?: string | null;
      before_time_sleep: number;
      before_time_in_bed: number;
      before_fill_count: number;
      before_eficiency: number;
    }>;
  } | null;
  delete_toolbox?: {
    __typename?: 'toolbox_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'toolbox';
      id: number;
      user_id: string;
      thought_block?: boolean | null;
      stimulus_control?: boolean | null;
      sleep_restriction?: boolean | null;
      pmr?: boolean | null;
      parking_lot?: boolean | null;
      paradoxical_intention?: boolean | null;
      meditation?: boolean | null;
      light_therapy?: boolean | null;
      imagery?: boolean | null;
      gratitude?: boolean | null;
      deep_breath?: boolean | null;
      behavior_activation?: boolean | null;
      challenge_catastrophic_thinking?: boolean | null;
      autogenic_training?: boolean | null;
    }>;
  } | null;
  delete_isi?: {
    __typename?: 'isi_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'isi';
      user_id: string;
      q7: number;
      q6: number;
      q5: number;
      q4: number;
      q3: number;
      q2: number;
      q1: number;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      age?: number | null;
      id: number;
    }>;
  } | null;
  delete_productivity?: {
    __typename?: 'productivity_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'productivity';
      user_id: string;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      q1: number;
      q2: number;
      q3: number;
      q4: number;
      id: number;
    }>;
  } | null;
  delete_eficiency?: {
    __typename?: 'eficiency_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'eficiency';
      id: number;
      user_id: string;
      tst: number;
      tib: number;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
  } | null;
  delete_health_carer_user?: {
    __typename?: 'health_carer_user_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'health_carer_user';
      health_carer_id: string;
      user_id: string;
      created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
      id: number;
    }>;
  } | null;
  delete_rating?: {
    __typename?: 'rating_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'rating';
      id: number;
      user: string;
      label: string;
      grade: number;
      created: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
  } | null;
  delete_user_tag?: {
    __typename?: 'user_tag_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'user_tag';
      id: number;
      user_id: string;
      tag: string;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
  } | null;
  delete_gratitude?: {
    __typename?: 'gratitude_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'gratitude';
      user_id: string;
      how_do_you_feel: string;
      good_things: string;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      id: number;
    }>;
  } | null;
  delete_parking_lot?: {
    __typename?: 'parking_lot_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'parking_lot';
      user_id: string;
      tasks_when: string;
      tasks: string;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      id: number;
    }>;
  } | null;
  delete_gad?: {
    __typename?: 'gad_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'gad';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      id: number;
      q1: number;
      q2: number;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      user_id: string;
    }>;
  } | null;
  delete_kupperman?: {
    __typename?: 'kupperman_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'kupperman';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      id: number;
      q1: number;
      q10: number;
      q11: number;
      q12: number;
      q13: number;
      q2: number;
      q3: number;
      q4: number;
      q5: number;
      q6: number;
      q7: number;
      q8: number;
      q9: number;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      user_id: string;
    }>;
  } | null;
  delete_phq?: {
    __typename?: 'phq_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'phq';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      id: number;
      q2: number;
      q1: number;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      user_id: string;
    }>;
  } | null;
  delete_user_coupon?: {
    __typename?: 'user_coupon_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'user_coupon';
      coupon_id: number;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      user_id: string;
      comment?: string | null;
    }>;
  } | null;
  delete_user_checkout?: {
    __typename?: 'user_checkout_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'user_checkout';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      data: unknown;
      id: number;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      user_id: string;
      receipt?: string | null;
      sku?: string | null;
    }>;
  } | null;
  delete_company_user?: {
    __typename?: 'company_user_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'company_user';
      company_id: number;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      id: number;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      user_id: string;
    }>;
  } | null;
  delete_log?: {
    __typename?: 'log_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'log';
      created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
      data?: unknown | null;
      id: number;
      messenger_id?: string | null;
      type: Log_Type_Enum_Enum;
      updated_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    }>;
  } | null;
  delete_catastrophic_thinkings?: {
    __typename?: 'catastrophic_thinkings_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'catastrophic_thinkings';
      id: number;
      catastrophic_thinkings: string;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      fact_or_opinion: string;
      user_id: string;
    }>;
  } | null;
  delete_reminder?: {
    __typename?: 'reminder_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'reminder';
      created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
      enabled: boolean;
      hour?: number | null;
      id: number;
      messenger_id: string;
      minute?: number | null;
      timezone?: number | null;
      type: Reminder_Type_Enum_Enum;
      updated_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
      extra_data?: unknown | null;
    }>;
  } | null;
  delete_user_reminder_info?: {
    __typename?: 'user_reminder_info_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'user_reminder_info';
      created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
      messenger_id: string;
      push_token?: string[] | null;
      updated_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    }>;
  } | null;
  delete_user_key_value?: {
    __typename?: 'user_key_value_mutation_response';
    returning: Array<{
      __typename?: 'user_key_value';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      key: User_Key_Value_Key_Enum_Enum;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      user_id: string;
      value?: string | null;
    }>;
  } | null;
  delete_pre_user?: {
    __typename?: 'pre_user_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'pre_user';
      birthdate?: `${number}-${number}-${number}` | null;
      company?: string | null;
      cpf?: string | null;
      email?: string | null;
      first_name?: string | null;
      gender?: string | null;
      id: string;
      last_name?: string | null;
      source: Pre_User_Source_Enum_Enum;
      status: string;
      created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      user_id?: string | null;
      phone?: string | null;
    }>;
  } | null;
  delete_user_notification?: {
    __typename?: 'user_notification_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'user_notification';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      id: number;
      message: string;
      sender: string;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      user_id: string;
    }>;
  } | null;
  delete_sleep_diary_interval?: {
    __typename?: 'sleep_diary_interval_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'sleep_diary_interval';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      date: `${number}-${number}-${number}`;
      end: number;
      id: number;
      sleeping: boolean;
      start: number;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      user_id?: string | null;
    }>;
  } | null;
  delete_sleep_diary_apple_health?: {
    __typename?: 'sleep_diary_apple_health_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'sleep_diary_apple_health';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      date: `${number}-${number}-${number}`;
      data: unknown;
      id: number;
      user_id: string;
    }>;
  } | null;
  delete_sleep_diary_google_fit?: {
    __typename?: 'sleep_diary_google_fit_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'sleep_diary_google_fit';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      data: unknown;
      date: `${number}-${number}-${number}`;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      id: number;
      user_id: string;
    }>;
  } | null;
  delete_sleep_tracker_compressed_data?: {
    __typename?: 'sleep_tracker_compressed_data_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'sleep_tracker_compressed_data';
      alarm?: unknown | null;
      compressed_data?: unknown | null;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      date: `${number}-${number}-${number}`;
      intervals?: unknown | null;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      id: number;
      user_id: string;
    }>;
  } | null;
  delete_user_journey?: {
    __typename?: 'user_journey_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'user_journey';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      journey_id: number;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      user_id: string;
    }>;
  } | null;
  delete_user_telehealth?: {
    __typename?: 'user_telehealth_mutation_response';
    returning: Array<{
      __typename?: 'user_telehealth';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      dav_id?: string | null;
      max_appointments: number;
      min_days_between_appointments: number;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      user_id?: string | null;
    }>;
  } | null;
  delete_munich_chronotype?: {
    __typename?: 'munich_chronotype_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'munich_chronotype';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      user_id: string;
      id: number;
      q1: number;
      q2: string;
      q3?: number | null;
      q4: string;
      q5: number;
      q6: boolean;
      q7: Yes_No_Question_Enum_Enum;
      q8: string;
      q9: number;
      q10: string;
      q11: number;
      q12: string;
      q13: number;
      q14: boolean;
      q15: string;
      q16: string;
      q17: number;
      q19: boolean;
      q18: Yes_No_Question_Enum_Enum;
      q20: boolean;
      q21: boolean;
      q22: Yes_No_Question_Enum_Enum;
      q23: number;
    }>;
  } | null;
  delete_generic_questionnaire?: {
    __typename?: 'generic_questionnaire_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'generic_questionnaire';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      data: unknown;
      id: number;
      name: Generic_Questionnaire_Name_Enum_Enum;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      user_id: string;
    }>;
  } | null;
  delete_appointment?: {
    __typename?: 'appointment_mutation_response';
    returning: Array<{
      __typename?: 'appointment';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      end: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      health_carer_id: string;
      health_carer_link?: string | null;
      id: string;
      start: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      user_id?: string | null;
      user_link?: string | null;
      status: Appointment_Status_Enum_Enum;
    }>;
  } | null;
  delete_user_program_session?: {
    __typename?: 'user_program_session_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'user_program_session';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      state: User_Program_Session_Enum_Enum;
      session_id: number;
      user_id: string;
    }>;
  } | null;
  delete_personality_questionnaire?: {
    __typename?: 'personality_questionnaire_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'personality_questionnaire';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      id: number;
      q1: number;
      q10: number;
      q11: number;
      q12: number;
      q13: number;
      q14: number;
      q15: number;
      q16: number;
      q17: number;
      q18: number;
      q19: number;
      q2: number;
      q20: number;
      q3: number;
      q4: number;
      q5: number;
      q6: number;
      q7: number;
      q8: number;
      q9: number;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      user_id: string;
    }>;
  } | null;
  delete_epworth?: {
    __typename?: 'epworth_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'epworth';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      id: number;
      q1: number;
      q2: number;
      q3: number;
      q4: number;
      q5: number;
      q6: number;
      q7: number;
      q8: number;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      user_id: string;
    }>;
  } | null;
  delete_stop_bang?: {
    __typename?: 'stop_bang_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'stop_bang';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      id: number;
      q1: number;
      q2: number;
      q3: number;
      q4: number;
      q5: number;
      q6: number;
      q7: number;
      q8: number;
      q9?: number | null;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      user_id: string;
    }>;
  } | null;
  delete_munich_chronotype_core?: {
    __typename?: 'munich_chronotype_core_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'munich_chronotype_core';
      activity_preference: string;
      analysis: unknown;
      chronotype_identification: string;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      free_days_schedule_bedtime: string;
      free_days_schedule_sleep_onset_latency: number;
      free_days_schedule_sleep_time: string;
      free_days_schedule_use_alarm: string;
      free_days_schedule_wake_up_inertia: number;
      free_days_schedule_wake_up_time: string;
      has_regular_schedule: string;
      id: number;
      result: string;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      user_id: string;
      work_days_per_week: number;
      work_schedule_bedtime: string;
      work_schedule_sleep_onset_latency: number;
      work_schedule_sleep_time: string;
      work_schedule_use_alarm: string;
      work_schedule_wake_up_inertia?: number | null;
      work_schedule_wake_up_time: string;
    }>;
  } | null;
  delete_apnea_scan_photo?: {
    __typename?: 'apnea_scan_photo_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'apnea_scan_photo';
      classification: unknown;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      dimensions: unknown;
      id: number;
      location: string;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      user_id: string;
    }>;
  } | null;
  delete_apnea_questionnaire?: {
    __typename?: 'apnea_questionnaire_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'apnea_questionnaire';
      age: number;
      berlin_cat1: number;
      berlin_cat2: number;
      berlin_cat3: number;
      body_mass_index: number;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      front: unknown;
      first_analysis: boolean;
      id: number;
      q1: number;
      q10: number;
      q2: number;
      q3: number;
      q4: number;
      q6: number;
      q5: number;
      q7: number;
      q8: number;
      q9: number;
      second_analysis: boolean;
      sex: string;
      side: unknown;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      user_id: string;
    }>;
  } | null;
  delete_dream?: {
    __typename?: 'dream_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'dream';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      date: `${number}-${number}-${number}`;
      description?: string | null;
      id: number;
      remember: boolean;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      user_id?: string | null;
    }>;
  } | null;
  delete_user_by_pk?: {
    __typename?: 'user';
    id: string;
    updated_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    tried_sleep_hygiene?: boolean | null;
    tried_restriction?: boolean | null;
    tried_other?: string | null;
    tried_melatonin?: boolean | null;
    tried_medicine?: boolean | null;
    tried_alcohol?: boolean | null;
    thought_block?: boolean | null;
    temperature: boolean;
    subscription_expiration?: `${number}-${number}-${number}` | null;
    stimulus_control?: boolean | null;
    sleep_medicine: boolean;
    session_to_pay: number;
    session_9_state: number;
    session_8_state: number;
    session_7_state: number;
    session_6_state: number;
    session_60_state: number;
    session_5_state: number;
    session_59_state: number;
    session_58_state: number;
    session_57_state: number;
    session_56_state: number;
    session_55_state: number;
    session_54_state: number;
    session_53_state: number;
    session_52_state: number;
    session_51_state: number;
    session_50_state: number;
    session_4_state: number;
    session_49_state: number;
    session_48_state: number;
    session_47_state: number;
    session_46_state: number;
    session_45_state: number;
    session_44_state: number;
    session_43_state: number;
    session_42_state: number;
    session_41_state: number;
    session_40_state: number;
    session_3_state: number;
    session_39_state: number;
    session_38_state: number;
    session_37_state: number;
    session_36_state: number;
    session_35_state: number;
    session_34_state: number;
    session_33_state: number;
    session_32_state: number;
    session_31_state: number;
    session_30_state: number;
    session_2_state: number;
    session_29_state: number;
    session_28_state: number;
    session_27_state: number;
    session_26_state: number;
    session_25_state: number;
    session_24_state: number;
    session_23_state: number;
    session_22_state: number;
    session_21_state: number;
    session_20_state: number;
    session_1_state: number;
    session_19_state: number;
    session_18_state: number;
    session_17_state: number;
    session_16_state: number;
    session_15_state: number;
    session_14_state: number;
    session_12_state: number;
    session_13_state: number;
    session_11_state: number;
    session_10_state: number;
    psqi_state: number;
    profile_picture?: string | null;
    points?: number | null;
    pmr?: boolean | null;
    phone?: string | null;
    partner: boolean;
    parking_lot?: boolean | null;
    paradoxical_intention?: boolean | null;
    pain: boolean;
    paid_value?: number | null;
    paid?: boolean | null;
    noise: boolean;
    night_goal?: number | null;
    nicotine: boolean;
    nap: boolean;
    meditation?: boolean | null;
    light_therapy?: boolean | null;
    light: boolean;
    last_name?: string | null;
    internal_id: number;
    imagery?: boolean | null;
    heavy_meal: boolean;
    gratitude?: boolean | null;
    gender?: string | null;
    first_name?: string | null;
    exercise: boolean;
    enabled: boolean;
    email?: string | null;
    dream: boolean;
    desired_wake_up?: `${number}:${number}:${number}+${number}` | null;
    deep_breath?: boolean | null;
    behavior_activation?: boolean | null;
    day_goal?: number | null;
    created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    challenge_catastrophic_thinking?: boolean | null;
    can_send_whatsapp_reminder?: boolean | null;
    can_send_email_reminder?: boolean | null;
    caffeine: boolean;
    bathroom: boolean;
    autogenic_training?: boolean | null;
    alcohol: boolean;
    sleep_restriction?: boolean | null;
    document?: string | null;
    device_os?: Os_Enum_Enum | null;
    comment?: string | null;
    current_journey_id?: number | null;
    birthdate?: `${number}-${number}-${number}` | null;
    app_version?: string | null;
    alt_email?: string | null;
  } | null;
};

export type DeleteUserMutation_Gad7FragmentFragment = {
  __typename?: 'gad7';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  user_id: string;
  id: number;
  q1: number;
  q2: number;
  q3: number;
  q4: number;
  q5: number;
  q6: number;
  q7: number;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
};

export type DeleteUserMutation_Phq9FragmentFragment = {
  __typename?: 'phq9';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  user_id: string;
  id: number;
  q1: number;
  q2: number;
  q3: number;
  q4: number;
  q5: number;
  q6: number;
  q7: number;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  q9: number;
  q8: number;
};

export type DeleteUserMutation_MenopauseGreeneScaleFragmentFragment = {
  __typename?: 'menopause_greene_scale';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  user_id: string;
  id: number;
  q1: number;
  q2: number;
  q3: number;
  q4: number;
  q5: number;
  q6: number;
  q7: number;
  q8: number;
  q9: number;
  q10: number;
  q11: number;
  q12: number;
  q13: number;
  q14: number;
  q15: number;
  q16: number;
  q17: number;
  q18: number;
  q19: number;
  q20: number;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  q21: number;
};

export type DeleteUserMutationcatastrophic_User_LogFragmentFragment = {
  __typename?: 'log';
  created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
  data?: unknown | null;
  id: number;
  messenger_id?: string | null;
  type: Log_Type_Enum_Enum;
  updated_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
};

export type DeleteUserMutationcatastrophic_User_Reminder_InfoFragmentFragment = {
  __typename?: 'user_reminder_info';
  created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
  messenger_id: string;
  push_token?: string[] | null;
  updated_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
};

export type DeleteUserMutationcatastrophic_RatingFragmentFragment = {
  __typename?: 'rating';
  created: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  grade: number;
  id: number;
  label: string;
  user: string;
};

export type DeleteUserMutationcatastrophic_ReminderFragmentFragment = {
  __typename?: 'reminder';
  created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
  enabled: boolean;
  hour?: number | null;
  id: number;
  messenger_id: string;
  minute?: number | null;
  timezone?: number | null;
  type: Reminder_Type_Enum_Enum;
  updated_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
  extra_data?: unknown | null;
};

export type DeleteUserMutationcatastrophic_User_CouponFragmentFragment = {
  __typename?: 'user_coupon';
  coupon_id: number;
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  user_id: string;
  comment?: string | null;
};

export type DeleteUserMutationcatastrophic_User_CheckoutFragmentFragment = {
  __typename?: 'user_checkout';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  data: unknown;
  id: number;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  user_id: string;
  receipt?: string | null;
  sku?: string | null;
};

export type DeleteUserMutationcatastrophic_PhqFragmentFragment = {
  __typename?: 'phq';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  id: number;
  q2: number;
  q1: number;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  user_id: string;
};

export type DeleteUserMutationcatastrophic_GadFragmentFragment = {
  __typename?: 'gad';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  id: number;
  q1: number;
  q2: number;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  user_id: string;
};

export type DeleteUserMutationcatastrophic_Company_UserFragmentFragment = {
  __typename?: 'company_user';
  company_id: number;
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  id: number;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  user_id: string;
};

export type DeleteUserMutationcatastrophic_ThinkingsFragmentFragment = {
  __typename?: 'catastrophic_thinkings';
  id: number;
  catastrophic_thinkings: string;
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  fact_or_opinion: string;
  user_id: string;
};

export type DeleteUserMutationuser_TagFragmentFragment = {
  __typename?: 'user_tag';
  id: number;
  user_id: string;
  tag: string;
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
};

export type DeleteUserMutationuserFragmentFragment = {
  __typename?: 'user';
  id: string;
  updated_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
  tried_sleep_hygiene?: boolean | null;
  tried_restriction?: boolean | null;
  tried_other?: string | null;
  tried_melatonin?: boolean | null;
  tried_medicine?: boolean | null;
  tried_alcohol?: boolean | null;
  thought_block?: boolean | null;
  temperature: boolean;
  subscription_expiration?: `${number}-${number}-${number}` | null;
  stimulus_control?: boolean | null;
  sleep_medicine: boolean;
  session_to_pay: number;
  session_9_state: number;
  session_8_state: number;
  session_7_state: number;
  session_6_state: number;
  session_60_state: number;
  session_5_state: number;
  session_59_state: number;
  session_58_state: number;
  session_57_state: number;
  session_56_state: number;
  session_55_state: number;
  session_54_state: number;
  session_53_state: number;
  session_52_state: number;
  session_51_state: number;
  session_50_state: number;
  session_4_state: number;
  session_49_state: number;
  session_48_state: number;
  session_47_state: number;
  session_46_state: number;
  session_45_state: number;
  session_44_state: number;
  session_43_state: number;
  session_42_state: number;
  session_41_state: number;
  session_40_state: number;
  session_3_state: number;
  session_39_state: number;
  session_38_state: number;
  session_37_state: number;
  session_36_state: number;
  session_35_state: number;
  session_34_state: number;
  session_33_state: number;
  session_32_state: number;
  session_31_state: number;
  session_30_state: number;
  session_2_state: number;
  session_29_state: number;
  session_28_state: number;
  session_27_state: number;
  session_26_state: number;
  session_25_state: number;
  session_24_state: number;
  session_23_state: number;
  session_22_state: number;
  session_21_state: number;
  session_20_state: number;
  session_1_state: number;
  session_19_state: number;
  session_18_state: number;
  session_17_state: number;
  session_16_state: number;
  session_15_state: number;
  session_14_state: number;
  session_12_state: number;
  session_13_state: number;
  session_11_state: number;
  session_10_state: number;
  psqi_state: number;
  profile_picture?: string | null;
  points?: number | null;
  pmr?: boolean | null;
  phone?: string | null;
  partner: boolean;
  parking_lot?: boolean | null;
  paradoxical_intention?: boolean | null;
  pain: boolean;
  paid_value?: number | null;
  paid?: boolean | null;
  noise: boolean;
  night_goal?: number | null;
  nicotine: boolean;
  nap: boolean;
  meditation?: boolean | null;
  light_therapy?: boolean | null;
  light: boolean;
  last_name?: string | null;
  internal_id: number;
  imagery?: boolean | null;
  heavy_meal: boolean;
  gratitude?: boolean | null;
  gender?: string | null;
  first_name?: string | null;
  exercise: boolean;
  enabled: boolean;
  email?: string | null;
  dream: boolean;
  desired_wake_up?: `${number}:${number}:${number}+${number}` | null;
  deep_breath?: boolean | null;
  behavior_activation?: boolean | null;
  day_goal?: number | null;
  created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
  challenge_catastrophic_thinking?: boolean | null;
  can_send_whatsapp_reminder?: boolean | null;
  can_send_email_reminder?: boolean | null;
  caffeine: boolean;
  bathroom: boolean;
  autogenic_training?: boolean | null;
  alcohol: boolean;
  sleep_restriction?: boolean | null;
  document?: string | null;
  device_os?: Os_Enum_Enum | null;
  comment?: string | null;
  current_journey_id?: number | null;
  birthdate?: `${number}-${number}-${number}` | null;
  app_version?: string | null;
  alt_email?: string | null;
};

export type DeleteUserMutationtoolboxFragmentFragment = {
  __typename?: 'toolbox';
  id: number;
  user_id: string;
  thought_block?: boolean | null;
  stimulus_control?: boolean | null;
  sleep_restriction?: boolean | null;
  pmr?: boolean | null;
  parking_lot?: boolean | null;
  paradoxical_intention?: boolean | null;
  meditation?: boolean | null;
  light_therapy?: boolean | null;
  imagery?: boolean | null;
  gratitude?: boolean | null;
  deep_breath?: boolean | null;
  behavior_activation?: boolean | null;
  challenge_catastrophic_thinking?: boolean | null;
  autogenic_training?: boolean | null;
};

export type DeleteUserMutationsleep_RestrictionFragmentFragment = {
  __typename?: 'sleep_restriction';
  user_id: string;
  start_date: `${number}-${number}-${number}`;
  new_time_in_bed: number;
  hour_to_sleep: `${number}:${number}:${number}`;
  desired_wake_up: `${number}:${number}:${number}`;
  created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
  comment?: string | null;
  before_time_sleep: number;
  before_time_in_bed: number;
  before_fill_count: number;
  before_eficiency: number;
};

export type DeleteUserMutationsleep_Diary_Sleep_TagFragmentFragment = {
  __typename?: 'sleep_diary_sleep_tag';
  sleep_tag: Sleep_Tag_Enum_Enum;
  sleep_diary: number;
};

export type DeleteUserMutationsleep_DiaryFragmentFragment = {
  __typename?: 'sleep_diary';
  id: number;
  wake_up_duration: number;
  wake_up_count: number;
  wake_up: `${number}:${number}:${number}+${number}`;
  user_id: string;
  timezone?: number | null;
  time_to_sleep: number;
  thought_block?: boolean | null;
  stimulus_control?: boolean | null;
  relationships?: number | null;
  pmr?: boolean | null;
  parking_lot?: boolean | null;
  paradoxical_intention?: boolean | null;
  meditation?: boolean | null;
  light_therapy?: boolean | null;
  imagery?: boolean | null;
  humor?: number | null;
  gratitude?: boolean | null;
  grade?: number | null;
  go_sleep: `${number}:${number}:${number}+${number}`;
  go_bed: `${number}:${number}:${number}+${number}`;
  get_up: `${number}:${number}:${number}+${number}`;
  energy?: number | null;
  deep_breath?: boolean | null;
  behavior_activation?: boolean | null;
  date: `${number}-${number}-${number}`;
  created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
  concentration?: number | null;
  comment?: string | null;
  challenge_catastrophic_thinking?: boolean | null;
  autogenic_training?: boolean | null;
  medicine?: boolean | null;
};

export type DeleteUserMutationratingFragmentFragment = {
  __typename?: 'rating';
  id: number;
  user: string;
  label: string;
  grade: number;
  created: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
};

export type DeleteUserMutationpsqiFragmentFragment = {
  __typename?: 'psqi';
  id: number;
  user_id: string;
  q9: number;
  q8: number;
  q7: number;
  q6: number;
  q5j_comment: string;
  q5j: number;
  q5i: number;
  q5h: number;
  q5g: number;
  q5f: number;
  q5e: number;
  q5d: number;
  q5c: number;
  q5b: number;
  q5a: number;
  q4: number;
  q3: string;
  q2: number;
  q1: string;
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  age: number;
};

export type DeleteUserMutationparking_LotFragmentFragment = {
  __typename?: 'parking_lot';
  user_id: string;
  tasks_when: string;
  tasks: string;
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  id: number;
};

export type DeleteUserMutationisiFragmentFragment = {
  __typename?: 'isi';
  user_id: string;
  q7: number;
  q6: number;
  q5: number;
  q4: number;
  q3: number;
  q2: number;
  q1: number;
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  age?: number | null;
  id: number;
};

export type DeleteUserMutationprodFragmentFragment = {
  __typename?: 'productivity';
  user_id: string;
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  q1: number;
  q2: number;
  q3: number;
  q4: number;
  id: number;
};

export type DeleteUserMutationhealth_Carer_UserFragmentFragment = {
  __typename?: 'health_carer_user';
  health_carer_id: string;
  user_id: string;
  created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
  id: number;
};

export type DeleteUserMutationgratitudeFragmentFragment = {
  __typename?: 'gratitude';
  user_id: string;
  how_do_you_feel: string;
  good_things: string;
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  id: number;
};

export type DeleteUserMutationeficiencyFragmentFragment = {
  __typename?: 'eficiency';
  id: number;
  user_id: string;
  tst: number;
  tib: number;
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
};

export type Pre_UserFragment1Fragment = {
  __typename?: 'pre_user';
  birthdate?: `${number}-${number}-${number}` | null;
  company?: string | null;
  cpf?: string | null;
  email?: string | null;
  first_name?: string | null;
  gender?: string | null;
  id: string;
  last_name?: string | null;
  source: Pre_User_Source_Enum_Enum;
  status: string;
  created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  user_id?: string | null;
  phone?: string | null;
};

export type Delete_User_NotificationFragmentFragment = {
  __typename?: 'user_notification';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  id: number;
  message: string;
  sender: string;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  user_id: string;
};

export type Delete_Sleep_Diary_IntervalFragmentFragment = {
  __typename?: 'sleep_diary_interval';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  date: `${number}-${number}-${number}`;
  end: number;
  id: number;
  sleeping: boolean;
  start: number;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  user_id?: string | null;
};

export type Delete_Sleep_Diary_Apple_HealthFragmentFragment = {
  __typename?: 'sleep_diary_apple_health';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  date: `${number}-${number}-${number}`;
  data: unknown;
  id: number;
  user_id: string;
};

export type Delete_Sleep_Diary_Google_FitFragmentFragment = {
  __typename?: 'sleep_diary_google_fit';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  data: unknown;
  date: `${number}-${number}-${number}`;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  id: number;
  user_id: string;
};

export type Delete_Sleep_Tracker_Compressed_DataFragmentFragment = {
  __typename?: 'sleep_tracker_compressed_data';
  alarm?: unknown | null;
  compressed_data?: unknown | null;
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  date: `${number}-${number}-${number}`;
  intervals?: unknown | null;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  id: number;
  user_id: string;
};

export type Delete_User_JourneyFragmentFragment = {
  __typename?: 'user_journey';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  journey_id: number;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  user_id: string;
};

export type Delete_User_Program_SessionFragmentFragment = {
  __typename?: 'user_program_session';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  state: User_Program_Session_Enum_Enum;
  session_id: number;
  user_id: string;
};

export type Appointment_Mutation_ResponseFragmentFragment = {
  __typename?: 'appointment_mutation_response';
  returning: Array<{
    __typename?: 'appointment';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    end: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    health_carer_id: string;
    health_carer_link?: string | null;
    id: string;
    start: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    user_id?: string | null;
    user_link?: string | null;
    status: Appointment_Status_Enum_Enum;
  }>;
};

export type Delete_Munich_ChronotypeFragmentFragment = {
  __typename?: 'munich_chronotype';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  user_id: string;
  id: number;
  q1: number;
  q2: string;
  q3?: number | null;
  q4: string;
  q5: number;
  q6: boolean;
  q7: Yes_No_Question_Enum_Enum;
  q8: string;
  q9: number;
  q10: string;
  q11: number;
  q12: string;
  q13: number;
  q14: boolean;
  q15: string;
  q16: string;
  q17: number;
  q19: boolean;
  q18: Yes_No_Question_Enum_Enum;
  q20: boolean;
  q21: boolean;
  q22: Yes_No_Question_Enum_Enum;
  q23: number;
};

export type User_TelehealthFragmentFragment = {
  __typename?: 'user_telehealth';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  dav_id?: string | null;
  max_appointments: number;
  min_days_between_appointments: number;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  user_id?: string | null;
};

export type Delete_User_Key_ValueFragmentFragment = {
  __typename?: 'user_key_value';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  key: User_Key_Value_Key_Enum_Enum;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  user_id: string;
  value?: string | null;
};

export type Delete_KuppermanFragmentFragment = {
  __typename?: 'kupperman';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  id: number;
  q1: number;
  q10: number;
  q11: number;
  q12: number;
  q13: number;
  q2: number;
  q3: number;
  q4: number;
  q5: number;
  q6: number;
  q7: number;
  q8: number;
  q9: number;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  user_id: string;
};

export type Delete_Sleep_Diary_MedicineFragmentFragment = {
  __typename?: 'sleep_diary_medicine';
  dose: number;
  name: string;
  id: number;
  sleep_diary_id: number;
};

export type Delete_Google_Sheet_RowFragmentFragment = {
  __typename?: 'google_sheet_row';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  data: unknown;
  from_block?: string | null;
  id: number;
  spreadsheet_id: string;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  user_id?: string | null;
};

export type Delete_Generic_QuestionnaireFragmentFragment = {
  __typename?: 'generic_questionnaire';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  data: unknown;
  id: number;
  name: Generic_Questionnaire_Name_Enum_Enum;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  user_id: string;
};

export type Delete_Personality_QuestionnaireFragmentFragment = {
  __typename?: 'personality_questionnaire';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  id: number;
  q1: number;
  q10: number;
  q11: number;
  q12: number;
  q13: number;
  q14: number;
  q15: number;
  q16: number;
  q17: number;
  q18: number;
  q19: number;
  q2: number;
  q20: number;
  q3: number;
  q4: number;
  q5: number;
  q6: number;
  q7: number;
  q8: number;
  q9: number;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  user_id: string;
};

export type Delete_EpworthFragmentFragment = {
  __typename?: 'epworth';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  id: number;
  q1: number;
  q2: number;
  q3: number;
  q4: number;
  q5: number;
  q6: number;
  q7: number;
  q8: number;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  user_id: string;
};

export type Delete_StopbangFragmentFragment = {
  __typename?: 'stop_bang';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  id: number;
  q1: number;
  q2: number;
  q3: number;
  q4: number;
  q5: number;
  q6: number;
  q7: number;
  q8: number;
  q9?: number | null;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  user_id: string;
};

export type Delete_Munich_Chronotype_CoreFragmentFragment = {
  __typename?: 'munich_chronotype_core';
  activity_preference: string;
  analysis: unknown;
  chronotype_identification: string;
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  free_days_schedule_bedtime: string;
  free_days_schedule_sleep_onset_latency: number;
  free_days_schedule_sleep_time: string;
  free_days_schedule_use_alarm: string;
  free_days_schedule_wake_up_inertia: number;
  free_days_schedule_wake_up_time: string;
  has_regular_schedule: string;
  id: number;
  result: string;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  user_id: string;
  work_days_per_week: number;
  work_schedule_bedtime: string;
  work_schedule_sleep_onset_latency: number;
  work_schedule_sleep_time: string;
  work_schedule_use_alarm: string;
  work_schedule_wake_up_inertia?: number | null;
  work_schedule_wake_up_time: string;
};

export type Delete_Apnea_QuestionnaireFragmentFragment = {
  __typename?: 'apnea_questionnaire';
  age: number;
  berlin_cat1: number;
  berlin_cat2: number;
  berlin_cat3: number;
  body_mass_index: number;
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  front: unknown;
  first_analysis: boolean;
  id: number;
  q1: number;
  q10: number;
  q2: number;
  q3: number;
  q4: number;
  q6: number;
  q5: number;
  q7: number;
  q8: number;
  q9: number;
  second_analysis: boolean;
  sex: string;
  side: unknown;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  user_id: string;
};

export type Delete_Apnea_Scan_PhotoFragmentFragment = {
  __typename?: 'apnea_scan_photo';
  classification: unknown;
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  dimensions: unknown;
  id: number;
  location: string;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  user_id: string;
};

export type Delete_DreamFragmentFragment = {
  __typename?: 'dream';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  date: `${number}-${number}-${number}`;
  description?: string | null;
  id: number;
  remember: boolean;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  user_id?: string | null;
};

export type UserFetchReportQueryQueryVariables = Exact<{
  messenger_id: Scalars['String'];
}>;

export type UserFetchReportQueryQuery = {
  __typename?: 'query_root';
  user: Array<{
    __typename?: 'user';
    phone?: string | null;
    email?: string | null;
    gender?: string | null;
    alcohol: boolean;
    bathroom: boolean;
    caffeine: boolean;
    exercise: boolean;
    heavy_meal: boolean;
    light: boolean;
    nap: boolean;
    nicotine: boolean;
    noise: boolean;
    pain: boolean;
    partner: boolean;
    points?: number | null;
    sleep_medicine: boolean;
    temperature: boolean;
    dream: boolean;
    day_goal?: number | null;
    night_goal?: number | null;
    created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    session_1_state: number;
    session_2_state: number;
    session_3_state: number;
    session_4_state: number;
    session_5_state: number;
    session_6_state: number;
    session_7_state: number;
    session_8_state: number;
    session_9_state: number;
    session_10_state: number;
    session_11_state: number;
    session_12_state: number;
    session_13_state: number;
    session_14_state: number;
    session_15_state: number;
    session_16_state: number;
    session_17_state: number;
    session_18_state: number;
    session_19_state: number;
    session_20_state: number;
    session_21_state: number;
    session_22_state: number;
    session_23_state: number;
    session_24_state: number;
    session_25_state: number;
    session_26_state: number;
    session_27_state: number;
    session_28_state: number;
    session_29_state: number;
    session_30_state: number;
    session_31_state: number;
    session_32_state: number;
    session_33_state: number;
    session_34_state: number;
    session_35_state: number;
    session_36_state: number;
    session_37_state: number;
    session_38_state: number;
    session_39_state: number;
    session_40_state: number;
    session_41_state: number;
    session_42_state: number;
    session_43_state: number;
    session_44_state: number;
    session_45_state: number;
    session_46_state: number;
    session_47_state: number;
    session_48_state: number;
    session_49_state: number;
    session_50_state: number;
    session_51_state: number;
    session_52_state: number;
    session_53_state: number;
    session_54_state: number;
    session_55_state: number;
    session_56_state: number;
    session_57_state: number;
    session_58_state: number;
    session_59_state: number;
    session_60_state: number;
    autogenic_training?: boolean | null;
    behavior_activation?: boolean | null;
    can_send_email_reminder?: boolean | null;
    can_send_whatsapp_reminder?: boolean | null;
    challenge_catastrophic_thinking?: boolean | null;
    deep_breath?: boolean | null;
    desired_wake_up?: `${number}:${number}:${number}+${number}` | null;
    enabled: boolean;
    first_name?: string | null;
    gratitude?: boolean | null;
    imagery?: boolean | null;
    last_name?: string | null;
    light_therapy?: boolean | null;
    meditation?: boolean | null;
    paid?: boolean | null;
    paid_value?: number | null;
    paradoxical_intention?: boolean | null;
    parking_lot?: boolean | null;
    pmr?: boolean | null;
    profile_picture?: string | null;
    psqi_state: number;
    session_to_pay: number;
    stimulus_control?: boolean | null;
    subscription_expiration?: `${number}-${number}-${number}` | null;
    thought_block?: boolean | null;
    tried_alcohol?: boolean | null;
    tried_medicine?: boolean | null;
    tried_melatonin?: boolean | null;
    tried_other?: string | null;
    tried_restriction?: boolean | null;
    tried_sleep_hygiene?: boolean | null;
    updated_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    current_journey_id?: number | null;
    document?: string | null;
    alt_email?: string | null;
    app_version?: string | null;
    birthdate?: `${number}-${number}-${number}` | null;
    comment?: string | null;
    device_os?: Os_Enum_Enum | null;
    sleep_restriction?: boolean | null;
    sleep_diaries: Array<{
      __typename?: 'sleep_diary';
      concentration?: number | null;
      created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
      date: `${number}-${number}-${number}`;
      energy?: number | null;
      get_up: `${number}:${number}:${number}+${number}`;
      go_bed: `${number}:${number}:${number}+${number}`;
      go_sleep: `${number}:${number}:${number}+${number}`;
      grade?: number | null;
      humor?: number | null;
      relationships?: number | null;
      time_to_sleep: number;
      wake_up: `${number}:${number}:${number}+${number}`;
      wake_up_count: number;
      wake_up_duration: number;
      autogenic_training?: boolean | null;
      behavior_activation?: boolean | null;
      comment?: string | null;
      deep_breath?: boolean | null;
      imagery?: boolean | null;
      meditation?: boolean | null;
      paradoxical_intention?: boolean | null;
      gratitude?: boolean | null;
      light_therapy?: boolean | null;
      challenge_catastrophic_thinking?: boolean | null;
      parking_lot?: boolean | null;
      pmr?: boolean | null;
      stimulus_control?: boolean | null;
      thought_block?: boolean | null;
      timezone?: number | null;
      medicine?: boolean | null;
      tags: Array<{ __typename?: 'sleep_diary_sleep_tag'; sleep_tag: Sleep_Tag_Enum_Enum }>;
      sleep_diary_medicines: Array<{ __typename?: 'sleep_diary_medicine'; dose: number; name: string }>;
    }>;
    psqi: Array<{
      __typename?: 'psqi';
      age: number;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      q1: string;
      q2: number;
      q3: string;
      q4: number;
      q5a: number;
      q5b: number;
      q5c: number;
      q5d: number;
      q5e: number;
      q5f: number;
      q5g: number;
      q5i: number;
      q5h: number;
      q5j: number;
      q5j_comment: string;
      q6: number;
      q7: number;
      q8: number;
      q9: number;
    }>;
    sleep_restrictions: Array<{
      __typename?: 'sleep_restriction';
      start_date: `${number}-${number}-${number}`;
      desired_wake_up: `${number}:${number}:${number}`;
      hour_to_sleep: `${number}:${number}:${number}`;
      before_eficiency: number;
      before_fill_count: number;
      before_time_in_bed: number;
      before_time_sleep: number;
      comment?: string | null;
      created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
      new_time_in_bed: number;
    }>;
    toolbox?: {
      __typename?: 'toolbox';
      autogenic_training?: boolean | null;
      behavior_activation?: boolean | null;
      challenge_catastrophic_thinking?: boolean | null;
      deep_breath?: boolean | null;
      gratitude?: boolean | null;
      imagery?: boolean | null;
      light_therapy?: boolean | null;
      meditation?: boolean | null;
      paradoxical_intention?: boolean | null;
      parking_lot?: boolean | null;
      pmr?: boolean | null;
      sleep_restriction?: boolean | null;
      stimulus_control?: boolean | null;
      thought_block?: boolean | null;
    } | null;
    isi: Array<{
      __typename?: 'isi';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      q1: number;
      q2: number;
      q3: number;
      q4: number;
      q5: number;
      q6: number;
      q7: number;
      age?: number | null;
    }>;
    eficiency: Array<{
      __typename?: 'eficiency';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      tib: number;
      tst: number;
    }>;
    health_carers: Array<{
      __typename?: 'health_carer_user';
      health_carer_id: string;
      created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    }>;
    ratings: Array<{
      __typename?: 'rating';
      created: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      grade: number;
      label: string;
    }>;
    user_tags: Array<{
      __typename?: 'user_tag';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      tag: string;
    }>;
    gratitudes: Array<{
      __typename?: 'gratitude';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      good_things: string;
      how_do_you_feel: string;
    }>;
    parking_lots: Array<{
      __typename?: 'parking_lot';
      tasks: string;
      tasks_when: string;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
    catastrophic_thinkings: Array<{
      __typename?: 'catastrophic_thinkings';
      catastrophic_thinkings: string;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      fact_or_opinion: string;
    }>;
    user_coupons: Array<{
      __typename?: 'user_coupon';
      coupon_id: number;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      comment?: string | null;
    }>;
    companies: Array<{
      __typename?: 'company_user';
      company_id: number;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
    gad: Array<{
      __typename?: 'gad';
      q1: number;
      q2: number;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
    kuppermans: Array<{
      __typename?: 'kupperman';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      q1: number;
      q10: number;
      q11: number;
      q12: number;
      q13: number;
      q2: number;
      q3: number;
      q4: number;
      q6: number;
      q5: number;
      q7: number;
      q8: number;
      q9: number;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
    phq: Array<{
      __typename?: 'phq';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      q1: number;
      q2: number;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
    productivity: Array<{
      __typename?: 'productivity';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      q1: number;
      q2: number;
      q3: number;
      q4: number;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
    checkout: Array<{
      __typename?: 'user_checkout';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      data: unknown;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      receipt?: string | null;
      sku?: string | null;
    }>;
    reminder_info?: {
      __typename?: 'user_reminder_info';
      created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
      push_token?: string[] | null;
      updated_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
      reminders: Array<{
        __typename?: 'reminder';
        created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
        enabled: boolean;
        hour?: number | null;
        minute?: number | null;
        timezone?: number | null;
        type: Reminder_Type_Enum_Enum;
        updated_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
        extra_data?: unknown | null;
      }>;
    } | null;
    logs: Array<{
      __typename?: 'log';
      created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
      data?: unknown | null;
      type: Log_Type_Enum_Enum;
      updated_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    }>;
    pre_users: Array<{
      __typename?: 'pre_user';
      birthdate?: `${number}-${number}-${number}` | null;
      company?: string | null;
      cpf?: string | null;
      email?: string | null;
      first_name?: string | null;
      gender?: string | null;
      id: string;
      last_name?: string | null;
      source: Pre_User_Source_Enum_Enum;
      status: string;
      created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      phone?: string | null;
    }>;
    user_notifications: Array<{
      __typename?: 'user_notification';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      message: string;
      sender: string;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
    sleep_diary_intervals: Array<{
      __typename?: 'sleep_diary_interval';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      date: `${number}-${number}-${number}`;
      end: number;
      sleeping: boolean;
      start: number;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
    user_journeys: Array<{
      __typename?: 'user_journey';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      journey_id: number;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
    user_program_sessions: Array<{
      __typename?: 'user_program_session';
      session_id: number;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      state: User_Program_Session_Enum_Enum;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
    sleep_diary_apple_healths: Array<{
      __typename?: 'sleep_diary_apple_health';
      data: unknown;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      date: `${number}-${number}-${number}`;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
    sleep_diary_google_fits: Array<{
      __typename?: 'sleep_diary_google_fit';
      data: unknown;
      date: `${number}-${number}-${number}`;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
    sleep_tracker_compressed_data: Array<{
      __typename?: 'sleep_tracker_compressed_data';
      alarm?: unknown | null;
      compressed_data?: unknown | null;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      date: `${number}-${number}-${number}`;
      intervals?: unknown | null;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
    gad7s: Array<{
      __typename?: 'gad7';
      q1: number;
      q2: number;
      q3: number;
      q4: number;
      q5: number;
      q6: number;
      q7: number;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
    phq9s: Array<{
      __typename?: 'phq9';
      q1: number;
      q2: number;
      q3: number;
      q4: number;
      q5: number;
      q6: number;
      q7: number;
      q8: number;
      q9: number;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
    menopause_greene_scales: Array<{
      __typename?: 'menopause_greene_scale';
      q1: number;
      q2: number;
      q3: number;
      q4: number;
      q5: number;
      q6: number;
      q7: number;
      q8: number;
      q9: number;
      q10: number;
      q11: number;
      q12: number;
      q13: number;
      q14: number;
      q15: number;
      q16: number;
      q17: number;
      q18: number;
      q19: number;
      q20: number;
      q21: number;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
    generic_questionnaires: Array<{
      __typename?: 'generic_questionnaire';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      data: unknown;
      id: number;
      name: Generic_Questionnaire_Name_Enum_Enum;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
    google_sheet_rows: Array<{
      __typename?: 'google_sheet_row';
      data: unknown;
      from_block?: string | null;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      spreadsheet_id: string;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
    key_values: Array<{
      __typename?: 'user_key_value';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      key: User_Key_Value_Key_Enum_Enum;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      value?: string | null;
    }>;
    munich_chronotypes: Array<{
      __typename?: 'munich_chronotype';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      q1: number;
      q10: string;
      q11: number;
      q12: string;
      q13: number;
      q14: boolean;
      q15: string;
      q16: string;
      q17: number;
      q18: Yes_No_Question_Enum_Enum;
      q19: boolean;
      q2: string;
      q20: boolean;
      q21: boolean;
      q22: Yes_No_Question_Enum_Enum;
      q23: number;
      q3?: number | null;
      q4: string;
      q5: number;
      q6: boolean;
      q7: Yes_No_Question_Enum_Enum;
      q8: string;
      q9: number;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
    appointments: Array<{
      __typename?: 'appointment';
      end: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      health_carer_id: string;
      health_carer_link?: string | null;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      pre_user_id?: string | null;
      start: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      status: Appointment_Status_Enum_Enum;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      user_link?: string | null;
    }>;
    apnea_questionnaires: Array<{
      __typename?: 'apnea_questionnaire';
      age: number;
      berlin_cat1: number;
      berlin_cat2: number;
      berlin_cat3: number;
      body_mass_index: number;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      first_analysis: boolean;
      front: unknown;
      q1: number;
      q10: number;
      q3: number;
      q2: number;
      q4: number;
      q5: number;
      q6: number;
      q7: number;
      q8: number;
      q9: number;
      second_analysis: boolean;
      sex: string;
      side: unknown;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
    epworths: Array<{
      __typename?: 'epworth';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      q1: number;
      q2: number;
      q3: number;
      q4: number;
      q5: number;
      q6: number;
      q8: number;
      q7: number;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
    stop_bangs: Array<{
      __typename?: 'stop_bang';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      q1: number;
      q2: number;
      q3: number;
      q4: number;
      q5: number;
      q6: number;
      q8: number;
      q7: number;
      q9?: number | null;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
    munich_chronotype_cores: Array<{
      __typename?: 'munich_chronotype_core';
      activity_preference: string;
      analysis: unknown;
      chronotype_identification: string;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      free_days_schedule_bedtime: string;
      free_days_schedule_sleep_onset_latency: number;
      free_days_schedule_sleep_time: string;
      free_days_schedule_use_alarm: string;
      free_days_schedule_wake_up_inertia: number;
      free_days_schedule_wake_up_time: string;
      has_regular_schedule: string;
      result: string;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      work_days_per_week: number;
      work_schedule_sleep_onset_latency: number;
      work_schedule_bedtime: string;
      work_schedule_sleep_time: string;
      work_schedule_use_alarm: string;
      work_schedule_wake_up_inertia?: number | null;
      work_schedule_wake_up_time: string;
    }>;
    personality_questionnaires: Array<{
      __typename?: 'personality_questionnaire';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      q1: number;
      q10: number;
      q11: number;
      q12: number;
      q13: number;
      q14: number;
      q15: number;
      q16: number;
      q17: number;
      q18: number;
      q19: number;
      q2: number;
      q20: number;
      q3: number;
      q4: number;
      q5: number;
      q6: number;
      q7: number;
      q8: number;
      q9: number;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
    dreams: Array<{
      __typename?: 'dream';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      date: `${number}-${number}-${number}`;
      description?: string | null;
      remember: boolean;
      updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
  }>;
};

export type UserFetchReportQueryuserFragmentFragment = {
  __typename?: 'user';
  phone?: string | null;
  email?: string | null;
  gender?: string | null;
  alcohol: boolean;
  bathroom: boolean;
  caffeine: boolean;
  exercise: boolean;
  heavy_meal: boolean;
  light: boolean;
  nap: boolean;
  nicotine: boolean;
  noise: boolean;
  pain: boolean;
  partner: boolean;
  points?: number | null;
  sleep_medicine: boolean;
  temperature: boolean;
  dream: boolean;
  day_goal?: number | null;
  night_goal?: number | null;
  created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
  session_1_state: number;
  session_2_state: number;
  session_3_state: number;
  session_4_state: number;
  session_5_state: number;
  session_6_state: number;
  session_7_state: number;
  session_8_state: number;
  session_9_state: number;
  session_10_state: number;
  session_11_state: number;
  session_12_state: number;
  session_13_state: number;
  session_14_state: number;
  session_15_state: number;
  session_16_state: number;
  session_17_state: number;
  session_18_state: number;
  session_19_state: number;
  session_20_state: number;
  session_21_state: number;
  session_22_state: number;
  session_23_state: number;
  session_24_state: number;
  session_25_state: number;
  session_26_state: number;
  session_27_state: number;
  session_28_state: number;
  session_29_state: number;
  session_30_state: number;
  session_31_state: number;
  session_32_state: number;
  session_33_state: number;
  session_34_state: number;
  session_35_state: number;
  session_36_state: number;
  session_37_state: number;
  session_38_state: number;
  session_39_state: number;
  session_40_state: number;
  session_41_state: number;
  session_42_state: number;
  session_43_state: number;
  session_44_state: number;
  session_45_state: number;
  session_46_state: number;
  session_47_state: number;
  session_48_state: number;
  session_49_state: number;
  session_50_state: number;
  session_51_state: number;
  session_52_state: number;
  session_53_state: number;
  session_54_state: number;
  session_55_state: number;
  session_56_state: number;
  session_57_state: number;
  session_58_state: number;
  session_59_state: number;
  session_60_state: number;
  autogenic_training?: boolean | null;
  behavior_activation?: boolean | null;
  can_send_email_reminder?: boolean | null;
  can_send_whatsapp_reminder?: boolean | null;
  challenge_catastrophic_thinking?: boolean | null;
  deep_breath?: boolean | null;
  desired_wake_up?: `${number}:${number}:${number}+${number}` | null;
  enabled: boolean;
  first_name?: string | null;
  gratitude?: boolean | null;
  imagery?: boolean | null;
  last_name?: string | null;
  light_therapy?: boolean | null;
  meditation?: boolean | null;
  paid?: boolean | null;
  paid_value?: number | null;
  paradoxical_intention?: boolean | null;
  parking_lot?: boolean | null;
  pmr?: boolean | null;
  profile_picture?: string | null;
  psqi_state: number;
  session_to_pay: number;
  stimulus_control?: boolean | null;
  subscription_expiration?: `${number}-${number}-${number}` | null;
  thought_block?: boolean | null;
  tried_alcohol?: boolean | null;
  tried_medicine?: boolean | null;
  tried_melatonin?: boolean | null;
  tried_other?: string | null;
  tried_restriction?: boolean | null;
  tried_sleep_hygiene?: boolean | null;
  updated_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
  current_journey_id?: number | null;
  document?: string | null;
  alt_email?: string | null;
  app_version?: string | null;
  birthdate?: `${number}-${number}-${number}` | null;
  comment?: string | null;
  device_os?: Os_Enum_Enum | null;
  sleep_restriction?: boolean | null;
  sleep_diaries: Array<{
    __typename?: 'sleep_diary';
    concentration?: number | null;
    created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    date: `${number}-${number}-${number}`;
    energy?: number | null;
    get_up: `${number}:${number}:${number}+${number}`;
    go_bed: `${number}:${number}:${number}+${number}`;
    go_sleep: `${number}:${number}:${number}+${number}`;
    grade?: number | null;
    humor?: number | null;
    relationships?: number | null;
    time_to_sleep: number;
    wake_up: `${number}:${number}:${number}+${number}`;
    wake_up_count: number;
    wake_up_duration: number;
    autogenic_training?: boolean | null;
    behavior_activation?: boolean | null;
    comment?: string | null;
    deep_breath?: boolean | null;
    imagery?: boolean | null;
    meditation?: boolean | null;
    paradoxical_intention?: boolean | null;
    gratitude?: boolean | null;
    light_therapy?: boolean | null;
    challenge_catastrophic_thinking?: boolean | null;
    parking_lot?: boolean | null;
    pmr?: boolean | null;
    stimulus_control?: boolean | null;
    thought_block?: boolean | null;
    timezone?: number | null;
    medicine?: boolean | null;
    tags: Array<{ __typename?: 'sleep_diary_sleep_tag'; sleep_tag: Sleep_Tag_Enum_Enum }>;
    sleep_diary_medicines: Array<{ __typename?: 'sleep_diary_medicine'; dose: number; name: string }>;
  }>;
  psqi: Array<{
    __typename?: 'psqi';
    age: number;
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    q1: string;
    q2: number;
    q3: string;
    q4: number;
    q5a: number;
    q5b: number;
    q5c: number;
    q5d: number;
    q5e: number;
    q5f: number;
    q5g: number;
    q5i: number;
    q5h: number;
    q5j: number;
    q5j_comment: string;
    q6: number;
    q7: number;
    q8: number;
    q9: number;
  }>;
  sleep_restrictions: Array<{
    __typename?: 'sleep_restriction';
    start_date: `${number}-${number}-${number}`;
    desired_wake_up: `${number}:${number}:${number}`;
    hour_to_sleep: `${number}:${number}:${number}`;
    before_eficiency: number;
    before_fill_count: number;
    before_time_in_bed: number;
    before_time_sleep: number;
    comment?: string | null;
    created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    new_time_in_bed: number;
  }>;
  toolbox?: {
    __typename?: 'toolbox';
    autogenic_training?: boolean | null;
    behavior_activation?: boolean | null;
    challenge_catastrophic_thinking?: boolean | null;
    deep_breath?: boolean | null;
    gratitude?: boolean | null;
    imagery?: boolean | null;
    light_therapy?: boolean | null;
    meditation?: boolean | null;
    paradoxical_intention?: boolean | null;
    parking_lot?: boolean | null;
    pmr?: boolean | null;
    sleep_restriction?: boolean | null;
    stimulus_control?: boolean | null;
    thought_block?: boolean | null;
  } | null;
  isi: Array<{
    __typename?: 'isi';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    q1: number;
    q2: number;
    q3: number;
    q4: number;
    q5: number;
    q6: number;
    q7: number;
    age?: number | null;
  }>;
  eficiency: Array<{
    __typename?: 'eficiency';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    tib: number;
    tst: number;
  }>;
  health_carers: Array<{
    __typename?: 'health_carer_user';
    health_carer_id: string;
    created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
  }>;
  ratings: Array<{
    __typename?: 'rating';
    created: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    grade: number;
    label: string;
  }>;
  user_tags: Array<{
    __typename?: 'user_tag';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    tag: string;
  }>;
  gratitudes: Array<{
    __typename?: 'gratitude';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    good_things: string;
    how_do_you_feel: string;
  }>;
  parking_lots: Array<{
    __typename?: 'parking_lot';
    tasks: string;
    tasks_when: string;
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
  catastrophic_thinkings: Array<{
    __typename?: 'catastrophic_thinkings';
    catastrophic_thinkings: string;
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    fact_or_opinion: string;
  }>;
  user_coupons: Array<{
    __typename?: 'user_coupon';
    coupon_id: number;
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    comment?: string | null;
  }>;
  companies: Array<{
    __typename?: 'company_user';
    company_id: number;
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
  gad: Array<{
    __typename?: 'gad';
    q1: number;
    q2: number;
    updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
  kuppermans: Array<{
    __typename?: 'kupperman';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    q1: number;
    q10: number;
    q11: number;
    q12: number;
    q13: number;
    q2: number;
    q3: number;
    q4: number;
    q6: number;
    q5: number;
    q7: number;
    q8: number;
    q9: number;
    updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
  phq: Array<{
    __typename?: 'phq';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    q1: number;
    q2: number;
    updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
  productivity: Array<{
    __typename?: 'productivity';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    q1: number;
    q2: number;
    q3: number;
    q4: number;
    updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
  checkout: Array<{
    __typename?: 'user_checkout';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    data: unknown;
    updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    receipt?: string | null;
    sku?: string | null;
  }>;
  reminder_info?: {
    __typename?: 'user_reminder_info';
    created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    push_token?: string[] | null;
    updated_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    reminders: Array<{
      __typename?: 'reminder';
      created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
      enabled: boolean;
      hour?: number | null;
      minute?: number | null;
      timezone?: number | null;
      type: Reminder_Type_Enum_Enum;
      updated_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
      extra_data?: unknown | null;
    }>;
  } | null;
  logs: Array<{
    __typename?: 'log';
    created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    data?: unknown | null;
    type: Log_Type_Enum_Enum;
    updated_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
  }>;
  pre_users: Array<{
    __typename?: 'pre_user';
    birthdate?: `${number}-${number}-${number}` | null;
    company?: string | null;
    cpf?: string | null;
    email?: string | null;
    first_name?: string | null;
    gender?: string | null;
    id: string;
    last_name?: string | null;
    source: Pre_User_Source_Enum_Enum;
    status: string;
    created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    phone?: string | null;
  }>;
  user_notifications: Array<{
    __typename?: 'user_notification';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    message: string;
    sender: string;
    updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
  sleep_diary_intervals: Array<{
    __typename?: 'sleep_diary_interval';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    date: `${number}-${number}-${number}`;
    end: number;
    sleeping: boolean;
    start: number;
    updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
  user_journeys: Array<{
    __typename?: 'user_journey';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    journey_id: number;
    updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
  user_program_sessions: Array<{
    __typename?: 'user_program_session';
    session_id: number;
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    state: User_Program_Session_Enum_Enum;
    updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
  sleep_diary_apple_healths: Array<{
    __typename?: 'sleep_diary_apple_health';
    data: unknown;
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    date: `${number}-${number}-${number}`;
    updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
  sleep_diary_google_fits: Array<{
    __typename?: 'sleep_diary_google_fit';
    data: unknown;
    date: `${number}-${number}-${number}`;
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
  sleep_tracker_compressed_data: Array<{
    __typename?: 'sleep_tracker_compressed_data';
    alarm?: unknown | null;
    compressed_data?: unknown | null;
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    date: `${number}-${number}-${number}`;
    intervals?: unknown | null;
    updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
  gad7s: Array<{
    __typename?: 'gad7';
    q1: number;
    q2: number;
    q3: number;
    q4: number;
    q5: number;
    q6: number;
    q7: number;
    updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
  phq9s: Array<{
    __typename?: 'phq9';
    q1: number;
    q2: number;
    q3: number;
    q4: number;
    q5: number;
    q6: number;
    q7: number;
    q8: number;
    q9: number;
    updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
  menopause_greene_scales: Array<{
    __typename?: 'menopause_greene_scale';
    q1: number;
    q2: number;
    q3: number;
    q4: number;
    q5: number;
    q6: number;
    q7: number;
    q8: number;
    q9: number;
    q10: number;
    q11: number;
    q12: number;
    q13: number;
    q14: number;
    q15: number;
    q16: number;
    q17: number;
    q18: number;
    q19: number;
    q20: number;
    q21: number;
    updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
  generic_questionnaires: Array<{
    __typename?: 'generic_questionnaire';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    data: unknown;
    id: number;
    name: Generic_Questionnaire_Name_Enum_Enum;
    updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
  google_sheet_rows: Array<{
    __typename?: 'google_sheet_row';
    data: unknown;
    from_block?: string | null;
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    spreadsheet_id: string;
    updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
  key_values: Array<{
    __typename?: 'user_key_value';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    key: User_Key_Value_Key_Enum_Enum;
    updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    value?: string | null;
  }>;
  munich_chronotypes: Array<{
    __typename?: 'munich_chronotype';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    q1: number;
    q10: string;
    q11: number;
    q12: string;
    q13: number;
    q14: boolean;
    q15: string;
    q16: string;
    q17: number;
    q18: Yes_No_Question_Enum_Enum;
    q19: boolean;
    q2: string;
    q20: boolean;
    q21: boolean;
    q22: Yes_No_Question_Enum_Enum;
    q23: number;
    q3?: number | null;
    q4: string;
    q5: number;
    q6: boolean;
    q7: Yes_No_Question_Enum_Enum;
    q8: string;
    q9: number;
    updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
  appointments: Array<{
    __typename?: 'appointment';
    end: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    health_carer_id: string;
    health_carer_link?: string | null;
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    pre_user_id?: string | null;
    start: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    status: Appointment_Status_Enum_Enum;
    updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    user_link?: string | null;
  }>;
  apnea_questionnaires: Array<{
    __typename?: 'apnea_questionnaire';
    age: number;
    berlin_cat1: number;
    berlin_cat2: number;
    berlin_cat3: number;
    body_mass_index: number;
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    first_analysis: boolean;
    front: unknown;
    q1: number;
    q10: number;
    q3: number;
    q2: number;
    q4: number;
    q5: number;
    q6: number;
    q7: number;
    q8: number;
    q9: number;
    second_analysis: boolean;
    sex: string;
    side: unknown;
    updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
  epworths: Array<{
    __typename?: 'epworth';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    q1: number;
    q2: number;
    q3: number;
    q4: number;
    q5: number;
    q6: number;
    q8: number;
    q7: number;
    updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
  stop_bangs: Array<{
    __typename?: 'stop_bang';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    q1: number;
    q2: number;
    q3: number;
    q4: number;
    q5: number;
    q6: number;
    q8: number;
    q7: number;
    q9?: number | null;
    updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
  munich_chronotype_cores: Array<{
    __typename?: 'munich_chronotype_core';
    activity_preference: string;
    analysis: unknown;
    chronotype_identification: string;
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    free_days_schedule_bedtime: string;
    free_days_schedule_sleep_onset_latency: number;
    free_days_schedule_sleep_time: string;
    free_days_schedule_use_alarm: string;
    free_days_schedule_wake_up_inertia: number;
    free_days_schedule_wake_up_time: string;
    has_regular_schedule: string;
    result: string;
    updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    work_days_per_week: number;
    work_schedule_sleep_onset_latency: number;
    work_schedule_bedtime: string;
    work_schedule_sleep_time: string;
    work_schedule_use_alarm: string;
    work_schedule_wake_up_inertia?: number | null;
    work_schedule_wake_up_time: string;
  }>;
  personality_questionnaires: Array<{
    __typename?: 'personality_questionnaire';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    q1: number;
    q10: number;
    q11: number;
    q12: number;
    q13: number;
    q14: number;
    q15: number;
    q16: number;
    q17: number;
    q18: number;
    q19: number;
    q2: number;
    q20: number;
    q3: number;
    q4: number;
    q5: number;
    q6: number;
    q7: number;
    q8: number;
    q9: number;
    updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
  dreams: Array<{
    __typename?: 'dream';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    date: `${number}-${number}-${number}`;
    description?: string | null;
    remember: boolean;
    updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
};

export type UserFetchReportQuerycatastrophic_ThinkingsFragmentFragment = {
  __typename?: 'catastrophic_thinkings';
  catastrophic_thinkings: string;
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  fact_or_opinion: string;
};

export type UserFetchReportQueryeficiencyFragmentFragment = {
  __typename?: 'eficiency';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  tib: number;
  tst: number;
};

export type UserFetchReportQuerygratitudeFragmentFragment = {
  __typename?: 'gratitude';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  good_things: string;
  how_do_you_feel: string;
};

export type UserFetchReportQueryhealth_Carer_UserFragmentFragment = {
  __typename?: 'health_carer_user';
  health_carer_id: string;
  created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
};

export type UserFetchReportQueryisiFragmentFragment = {
  __typename?: 'isi';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  q1: number;
  q2: number;
  q3: number;
  q4: number;
  q5: number;
  q6: number;
  q7: number;
  age?: number | null;
};

export type UserFetchReportQueryparking_LotFragmentFragment = {
  __typename?: 'parking_lot';
  tasks: string;
  tasks_when: string;
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
};

export type UserFetchReportQuerypsqiFragmentFragment = {
  __typename?: 'psqi';
  age: number;
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  q1: string;
  q2: number;
  q3: string;
  q4: number;
  q5a: number;
  q5b: number;
  q5c: number;
  q5d: number;
  q5e: number;
  q5f: number;
  q5g: number;
  q5i: number;
  q5h: number;
  q5j: number;
  q5j_comment: string;
  q6: number;
  q7: number;
  q8: number;
  q9: number;
};

export type UserFetchReportQueryratingFragmentFragment = {
  __typename?: 'rating';
  created: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  grade: number;
  label: string;
};

export type UserFetchReportQuerysleep_DiaryFragmentFragment = {
  __typename?: 'sleep_diary';
  concentration?: number | null;
  created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
  date: `${number}-${number}-${number}`;
  energy?: number | null;
  get_up: `${number}:${number}:${number}+${number}`;
  go_bed: `${number}:${number}:${number}+${number}`;
  go_sleep: `${number}:${number}:${number}+${number}`;
  grade?: number | null;
  humor?: number | null;
  relationships?: number | null;
  time_to_sleep: number;
  wake_up: `${number}:${number}:${number}+${number}`;
  wake_up_count: number;
  wake_up_duration: number;
  autogenic_training?: boolean | null;
  behavior_activation?: boolean | null;
  comment?: string | null;
  deep_breath?: boolean | null;
  imagery?: boolean | null;
  meditation?: boolean | null;
  paradoxical_intention?: boolean | null;
  gratitude?: boolean | null;
  light_therapy?: boolean | null;
  challenge_catastrophic_thinking?: boolean | null;
  parking_lot?: boolean | null;
  pmr?: boolean | null;
  stimulus_control?: boolean | null;
  thought_block?: boolean | null;
  timezone?: number | null;
  medicine?: boolean | null;
  tags: Array<{ __typename?: 'sleep_diary_sleep_tag'; sleep_tag: Sleep_Tag_Enum_Enum }>;
  sleep_diary_medicines: Array<{ __typename?: 'sleep_diary_medicine'; dose: number; name: string }>;
};

export type UserFetchReportQuerysleep_RestrictionFragmentFragment = {
  __typename?: 'sleep_restriction';
  start_date: `${number}-${number}-${number}`;
  desired_wake_up: `${number}:${number}:${number}`;
  hour_to_sleep: `${number}:${number}:${number}`;
  before_eficiency: number;
  before_fill_count: number;
  before_time_in_bed: number;
  before_time_sleep: number;
  comment?: string | null;
  created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
  new_time_in_bed: number;
};

export type UserFetchReportQuerytoolboxFragmentFragment = {
  __typename?: 'toolbox';
  autogenic_training?: boolean | null;
  behavior_activation?: boolean | null;
  challenge_catastrophic_thinking?: boolean | null;
  deep_breath?: boolean | null;
  gratitude?: boolean | null;
  imagery?: boolean | null;
  light_therapy?: boolean | null;
  meditation?: boolean | null;
  paradoxical_intention?: boolean | null;
  parking_lot?: boolean | null;
  pmr?: boolean | null;
  sleep_restriction?: boolean | null;
  stimulus_control?: boolean | null;
  thought_block?: boolean | null;
};

export type UserFetchReportQueryuser_TagFragmentFragment = {
  __typename?: 'user_tag';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  tag: string;
};

export type UserFetchReportQuerysleep_Diary_Sleep_TagFragmentFragment = {
  __typename?: 'sleep_diary_sleep_tag';
  sleep_tag: Sleep_Tag_Enum_Enum;
};

export type UserFetchReportQuery_CheckoutFragmentFragment = {
  __typename?: 'user_checkout';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  data: unknown;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  receipt?: string | null;
  sku?: string | null;
};

export type UserFetchReportQuery_CouponFragmentFragment = {
  __typename?: 'user_coupon';
  coupon_id: number;
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  comment?: string | null;
};

export type UserFetchReportQuery_CompanyFragment = {
  __typename?: 'company_user';
  company_id: number;
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
};

export type UserFetchReportQuery_GadFragmentFragment = {
  __typename?: 'gad';
  q1: number;
  q2: number;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
};

export type UserFetchReportQuery_PhqFragmentFragment = {
  __typename?: 'phq';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  q1: number;
  q2: number;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
};

export type UserFetchReportQuery_ProdFragmentFragment = {
  __typename?: 'productivity';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  q1: number;
  q2: number;
  q3: number;
  q4: number;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
};

export type UserFetchReportQuery_User_Reminder_InfoFragmentFragment = {
  __typename?: 'user_reminder_info';
  created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
  push_token?: string[] | null;
  updated_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
  reminders: Array<{
    __typename?: 'reminder';
    created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    enabled: boolean;
    hour?: number | null;
    minute?: number | null;
    timezone?: number | null;
    type: Reminder_Type_Enum_Enum;
    updated_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    extra_data?: unknown | null;
  }>;
};

export type UserFetchReportQuery_RatingFragmentFragment = {
  __typename?: 'rating';
  created: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  grade: number;
  label: string;
};

export type UserFetchReportQuery_LogFragmentFragment = {
  __typename?: 'log';
  created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
  data?: unknown | null;
  type: Log_Type_Enum_Enum;
  updated_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
};

export type Pre_UserFragmentFragment = {
  __typename?: 'pre_user';
  birthdate?: `${number}-${number}-${number}` | null;
  company?: string | null;
  cpf?: string | null;
  email?: string | null;
  first_name?: string | null;
  gender?: string | null;
  id: string;
  last_name?: string | null;
  source: Pre_User_Source_Enum_Enum;
  status: string;
  created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  phone?: string | null;
};

export type Sleep_Diary_IntervalFragmentFragment = {
  __typename?: 'sleep_diary_interval';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  date: `${number}-${number}-${number}`;
  end: number;
  sleeping: boolean;
  start: number;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
};

export type User_NotificationFragmentFragment = {
  __typename?: 'user_notification';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  message: string;
  sender: string;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
};

export type User_JourneyFragmentFragment = {
  __typename?: 'user_journey';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  journey_id: number;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
};

export type User_Program_SessionFragmentFragment = {
  __typename?: 'user_program_session';
  session_id: number;
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  state: User_Program_Session_Enum_Enum;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
};

export type Sleep_Diary_Google_FitFragmentFragment = {
  __typename?: 'sleep_diary_google_fit';
  data: unknown;
  date: `${number}-${number}-${number}`;
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
};

export type Sleep_Diary_Apple_HealthFragmentFragment = {
  __typename?: 'sleep_diary_apple_health';
  data: unknown;
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  date: `${number}-${number}-${number}`;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
};

export type Sleep_Tracker_Compressed_DataFragmentFragment = {
  __typename?: 'sleep_tracker_compressed_data';
  alarm?: unknown | null;
  compressed_data?: unknown | null;
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  date: `${number}-${number}-${number}`;
  intervals?: unknown | null;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
};

export type UserFetchReportQuery_Gad7FragmentFragment = {
  __typename?: 'gad7';
  q1: number;
  q2: number;
  q3: number;
  q4: number;
  q5: number;
  q6: number;
  q7: number;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
};

export type UserFetchReportQuery_Phq9FragmentFragment = {
  __typename?: 'phq9';
  q1: number;
  q2: number;
  q3: number;
  q4: number;
  q5: number;
  q6: number;
  q7: number;
  q8: number;
  q9: number;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
};

export type UserFetchReportQuery_Menopause_Greene_ScaleFragmentFragment = {
  __typename?: 'menopause_greene_scale';
  q1: number;
  q2: number;
  q3: number;
  q4: number;
  q5: number;
  q6: number;
  q7: number;
  q8: number;
  q9: number;
  q10: number;
  q11: number;
  q12: number;
  q13: number;
  q14: number;
  q15: number;
  q16: number;
  q17: number;
  q18: number;
  q19: number;
  q20: number;
  q21: number;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
};

export type UserFetchReportQuery_KuppermanFragmentFragment = {
  __typename?: 'kupperman';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  q1: number;
  q10: number;
  q11: number;
  q12: number;
  q13: number;
  q2: number;
  q3: number;
  q4: number;
  q6: number;
  q5: number;
  q7: number;
  q8: number;
  q9: number;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
};

export type UserFetchReportQuery_Sleep_Diary_MedicineFragmentFragment = { __typename?: 'sleep_diary_medicine'; dose: number; name: string };

export type AppointmentFragmentFragment = {
  __typename?: 'appointment';
  end: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  health_carer_id: string;
  health_carer_link?: string | null;
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  pre_user_id?: string | null;
  start: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  status: Appointment_Status_Enum_Enum;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  user_link?: string | null;
};

export type Google_Sheet_RowFragmentFragment = {
  __typename?: 'google_sheet_row';
  data: unknown;
  from_block?: string | null;
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  spreadsheet_id: string;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
};

export type User_Key_ValueFragmentFragment = {
  __typename?: 'user_key_value';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  key: User_Key_Value_Key_Enum_Enum;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  value?: string | null;
};

export type Munich_ChronotypeFragmentFragment = {
  __typename?: 'munich_chronotype';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  q1: number;
  q10: string;
  q11: number;
  q12: string;
  q13: number;
  q14: boolean;
  q15: string;
  q16: string;
  q17: number;
  q18: Yes_No_Question_Enum_Enum;
  q19: boolean;
  q2: string;
  q20: boolean;
  q21: boolean;
  q22: Yes_No_Question_Enum_Enum;
  q23: number;
  q3?: number | null;
  q4: string;
  q5: number;
  q6: boolean;
  q7: Yes_No_Question_Enum_Enum;
  q8: string;
  q9: number;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
};

export type UserFetchReportQuery_Generic_QuestionnairesFragmentFragment = {
  __typename?: 'generic_questionnaire';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  data: unknown;
  id: number;
  name: Generic_Questionnaire_Name_Enum_Enum;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
};

export type Personality_QuestionnaireFragmentFragment = {
  __typename?: 'personality_questionnaire';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  q1: number;
  q10: number;
  q11: number;
  q12: number;
  q13: number;
  q14: number;
  q15: number;
  q16: number;
  q17: number;
  q18: number;
  q19: number;
  q2: number;
  q20: number;
  q3: number;
  q4: number;
  q5: number;
  q6: number;
  q7: number;
  q8: number;
  q9: number;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
};

export type Munich_Chronotype_CoreFragmentFragment = {
  __typename?: 'munich_chronotype_core';
  activity_preference: string;
  analysis: unknown;
  chronotype_identification: string;
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  free_days_schedule_bedtime: string;
  free_days_schedule_sleep_onset_latency: number;
  free_days_schedule_sleep_time: string;
  free_days_schedule_use_alarm: string;
  free_days_schedule_wake_up_inertia: number;
  free_days_schedule_wake_up_time: string;
  has_regular_schedule: string;
  result: string;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  work_days_per_week: number;
  work_schedule_sleep_onset_latency: number;
  work_schedule_bedtime: string;
  work_schedule_sleep_time: string;
  work_schedule_use_alarm: string;
  work_schedule_wake_up_inertia?: number | null;
  work_schedule_wake_up_time: string;
};

export type EpworthFragmentFragment = {
  __typename?: 'epworth';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  q1: number;
  q2: number;
  q3: number;
  q4: number;
  q5: number;
  q6: number;
  q8: number;
  q7: number;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
};

export type StopbangFragmentFragment = {
  __typename?: 'stop_bang';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  q1: number;
  q2: number;
  q3: number;
  q4: number;
  q5: number;
  q6: number;
  q8: number;
  q7: number;
  q9?: number | null;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
};

export type Apnea_QuestionnaireFragmentFragment = {
  __typename?: 'apnea_questionnaire';
  age: number;
  berlin_cat1: number;
  berlin_cat2: number;
  berlin_cat3: number;
  body_mass_index: number;
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  first_analysis: boolean;
  front: unknown;
  q1: number;
  q10: number;
  q3: number;
  q2: number;
  q4: number;
  q5: number;
  q6: number;
  q7: number;
  q8: number;
  q9: number;
  second_analysis: boolean;
  sex: string;
  side: unknown;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
};

export type DreamFragmentFragment = {
  __typename?: 'dream';
  created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  date: `${number}-${number}-${number}`;
  description?: string | null;
  remember: boolean;
  updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
};

export type UserIdQueryQueryVariables = Exact<{
  limit: Scalars['Int'];
  internal_id: Scalars['Int'];
}>;

export type UserIdQueryQuery = { __typename?: 'query_root'; user: Array<{ __typename?: 'user'; id: string; internal_id: number }> };

export type UserTransactionInfoQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type UserTransactionInfoQueryQuery = {
  __typename?: 'query_root';
  user_by_pk?: {
    __typename?: 'user';
    id: string;
    created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    user_coupons: Array<{
      __typename?: 'user_coupon';
      coupon?: { __typename?: 'coupon'; code: string; owner_hcp?: string | null; owner_company?: number | null } | null;
    }>;
    health_carers: Array<{
      __typename?: 'health_carer_user';
      health_carer: {
        __typename?: 'health_carer';
        id: string;
        companies: Array<{ __typename?: 'company_health_carer'; company_id: number }>;
      };
    }>;
  } | null;
};

export type GetSleepDiaryAndTrackerQueryQueryVariables = Exact<{
  _gt: Scalars['timestamptz'];
  _lt: Scalars['timestamptz'];
}>;

export type GetSleepDiaryAndTrackerQueryQuery = {
  __typename?: 'query_root';
  sleep_diary: Array<{
    __typename?: 'sleep_diary';
    autogenic_training?: boolean | null;
    behavior_activation?: boolean | null;
    challenge_catastrophic_thinking?: boolean | null;
    comment?: string | null;
    concentration?: number | null;
    created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    date: `${number}-${number}-${number}`;
    deep_breath?: boolean | null;
    energy?: number | null;
    get_up: `${number}:${number}:${number}+${number}`;
    go_bed: `${number}:${number}:${number}+${number}`;
    go_sleep: `${number}:${number}:${number}+${number}`;
    grade?: number | null;
    gratitude?: boolean | null;
    humor?: number | null;
    id: number;
    imagery?: boolean | null;
    light_therapy?: boolean | null;
    meditation?: boolean | null;
    paradoxical_intention?: boolean | null;
    parking_lot?: boolean | null;
    pmr?: boolean | null;
    relationships?: number | null;
    stimulus_control?: boolean | null;
    thought_block?: boolean | null;
    time_to_sleep: number;
    timezone?: number | null;
    user_id: string;
    wake_up: `${number}:${number}:${number}+${number}`;
    wake_up_count: number;
    wake_up_duration: number;
  }>;
  sleep_tracker_compressed_data: Array<{
    __typename?: 'sleep_tracker_compressed_data';
    alarm?: unknown | null;
    compressed_data?: unknown | null;
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    date: `${number}-${number}-${number}`;
    id: number;
    intervals?: unknown | null;
    updated_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    user_id: string;
  }>;
};

export type HealthCarerFinancesQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type HealthCarerFinancesQueryQuery = {
  __typename?: 'query_root';
  health_carer_by_pk?: {
    __typename?: 'health_carer';
    id: string;
    health_carer_transactions: Array<{
      __typename?: 'health_carer_transactions';
      id: number;
      amount: number;
      description: string;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      from_user?: { __typename?: 'user'; id: string; document?: string | null; email?: string | null; alt_email?: string | null } | null;
    }>;
    health_carer_money_requests: Array<{
      __typename?: 'health_carer_money_request';
      id: number;
      amount: number;
      status: Health_Carer_Money_Request_Status_Enum_Enum;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
  } | null;
};

export type HealthCarerNameQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type HealthCarerNameQueryQuery = {
  __typename?: 'query_root';
  coupon: Array<{ __typename?: 'coupon'; code: string }>;
  health_carer_by_pk?: {
    __typename?: 'health_carer';
    id: string;
    name?: string | null;
    kind?: string | null;
    gender?: string | null;
    professional_id?: string | null;
    professional_id_state?: string | null;
    membership_kind: Health_Carer_Membership_Kind_Enum_Enum;
    whatsapp?: string | null;
    health_carer_transactions_aggregate: {
      __typename?: 'health_carer_transactions_aggregate';
      aggregate?: {
        __typename?: 'health_carer_transactions_aggregate_fields';
        sum?: { __typename?: 'health_carer_transactions_sum_fields'; amount?: number | null } | null;
      } | null;
    };
    pagarme_recipient?: {
      __typename?: 'health_carer_pagarme_recipient';
      health_carer_id: string;
      document: string;
      branch_number: string;
      branch_check_digit: string;
      bank: string;
      account_type: string;
      account_number: string;
      account_check_digit: string;
    } | null;
  } | null;
};

export type PatientListQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type PatientListQueryQuery = {
  __typename?: 'query_root';
  fake_user?: {
    __typename?: 'user';
    id: string;
    current_journey_id?: number | null;
    first_name?: string | null;
    last_name?: string | null;
    profile_picture?: string | null;
    created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    updated_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    paid?: boolean | null;
    sleep_diaries: Array<{ __typename?: 'sleep_diary'; date: `${number}-${number}-${number}` }>;
    sleep_diaries_aggregate: {
      __typename?: 'sleep_diary_aggregate';
      aggregate?: { __typename?: 'sleep_diary_aggregate_fields'; count?: number | null } | null;
    };
    user_journeys: Array<{ __typename?: 'user_journey'; journey_id: number }>;
    user_program_sessions: Array<{
      __typename?: 'user_program_session';
      session_id: number;
      state: User_Program_Session_Enum_Enum;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
    ratings: Array<{ __typename?: 'rating'; created: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` }>;
  } | null;
  health_carer_by_pk?: {
    __typename?: 'health_carer';
    id: string;
    name?: string | null;
    users: Array<{
      __typename?: 'health_carer_user';
      user: {
        __typename?: 'user';
        id: string;
        current_journey_id?: number | null;
        first_name?: string | null;
        last_name?: string | null;
        profile_picture?: string | null;
        created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
        updated_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
        paid?: boolean | null;
        sleep_diaries: Array<{ __typename?: 'sleep_diary'; date: `${number}-${number}-${number}` }>;
        sleep_diaries_aggregate: {
          __typename?: 'sleep_diary_aggregate';
          aggregate?: { __typename?: 'sleep_diary_aggregate_fields'; count?: number | null } | null;
        };
        user_journeys: Array<{ __typename?: 'user_journey'; journey_id: number }>;
        user_program_sessions: Array<{
          __typename?: 'user_program_session';
          session_id: number;
          state: User_Program_Session_Enum_Enum;
          created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
        }>;
        ratings: Array<{
          __typename?: 'rating';
          created: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
        }>;
      };
    }>;
  } | null;
};

export type UserFragmentFragment = {
  __typename?: 'user';
  id: string;
  current_journey_id?: number | null;
  first_name?: string | null;
  last_name?: string | null;
  profile_picture?: string | null;
  created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
  updated_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
  paid?: boolean | null;
  sleep_diaries: Array<{ __typename?: 'sleep_diary'; date: `${number}-${number}-${number}` }>;
  sleep_diaries_aggregate: {
    __typename?: 'sleep_diary_aggregate';
    aggregate?: { __typename?: 'sleep_diary_aggregate_fields'; count?: number | null } | null;
  };
  user_journeys: Array<{ __typename?: 'user_journey'; journey_id: number }>;
  user_program_sessions: Array<{
    __typename?: 'user_program_session';
    session_id: number;
    state: User_Program_Session_Enum_Enum;
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
  ratings: Array<{ __typename?: 'rating'; created: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` }>;
};

export type AddAssesmentMutationVariables = Exact<{
  objects: Array<Assesment_Insert_Input> | Assesment_Insert_Input;
  subscription: Array<Subscription_Insert_Input> | Subscription_Insert_Input;
}>;

export type AddAssesmentMutation = {
  __typename?: 'mutation_root';
  insert_assesment?: { __typename?: 'assesment_mutation_response'; affected_rows: number } | null;
  insert_subscription?: { __typename?: 'subscription_mutation_response'; affected_rows: number } | null;
};

export type AddQuizB2BMutationVariables = Exact<{
  quizB2B: B2b_Diagnosis_V1_Insert_Input;
}>;

export type AddQuizB2BMutation = {
  __typename?: 'mutation_root';
  insert_b2b_diagnosis_v1_one?: { __typename?: 'b2b_diagnosis_v1'; id: number } | null;
};

export type LastSleepRestrictionQueryLandingPageQueryVariables = Exact<{ [key: string]: never }>;

export type LastSleepRestrictionQueryLandingPageQuery = {
  __typename?: 'query_root';
  sleep_restriction: Array<{
    __typename?: 'sleep_restriction';
    before_eficiency: number;
    before_fill_count: number;
    before_time_in_bed: number;
    before_time_sleep: number;
    desired_wake_up: `${number}:${number}:${number}`;
    hour_to_sleep: `${number}:${number}:${number}`;
    new_time_in_bed: number;
    start_date: `${number}-${number}-${number}`;
    user_id: string;
  }>;
};

export type PreUserUserQueryQueryVariables = Exact<{
  preUserId: Scalars['String'];
}>;

export type PreUserUserQueryQuery = {
  __typename?: 'query_root';
  pre_user: Array<{
    __typename?: 'pre_user';
    birthdate?: `${number}-${number}-${number}` | null;
    cpf?: string | null;
    email?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    phone?: string | null;
    user_telehealth?: { __typename?: 'user_telehealth'; dav_id?: string | null } | null;
  }>;
};

export type QuestionnairesGraphQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type QuestionnairesGraphQueryQuery = {
  __typename?: 'query_root';
  gad7: Array<{
    __typename?: 'gad7';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    id: number;
    q1: number;
    q2: number;
    q3: number;
    q4: number;
    q5: number;
    q6: number;
    q7: number;
    user_id: string;
  }>;
  phq9: Array<{
    __typename?: 'phq9';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    id: number;
    q1: number;
    q2: number;
    q3: number;
    q4: number;
    q5: number;
    q6: number;
    q7: number;
    q8: number;
    q9: number;
    user_id: string;
  }>;
  isi: Array<{
    __typename?: 'isi';
    age?: number | null;
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    id: number;
    q1: number;
    q2: number;
    q3: number;
    q4: number;
    q5: number;
    q6: number;
    q7: number;
    user_id: string;
  }>;
  kupperman: Array<{
    __typename?: 'kupperman';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    id: number;
    q1: number;
    q10: number;
    q11: number;
    q12: number;
    q13: number;
    q2: number;
    q3: number;
    q4: number;
    q5: number;
    q6: number;
    q7: number;
    q8: number;
    q9: number;
    user_id: string;
  }>;
  psqi: Array<{
    __typename?: 'psqi';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    id: number;
    q1: string;
    q2: number;
    q3: string;
    q4: number;
    q5a: number;
    q5b: number;
    q5c: number;
    q5d: number;
    q5e: number;
    q5f: number;
    q5g: number;
    q5h: number;
    q5i: number;
    q5j: number;
    q6: number;
    q7: number;
    q8: number;
    q9: number;
    user_id: string;
  }>;
  personality_questionnaire: Array<{
    __typename?: 'personality_questionnaire';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    id: number;
    q1: number;
    q10: number;
    q11: number;
    q12: number;
    q13: number;
    q14: number;
    q15: number;
    q16: number;
    q17: number;
    q18: number;
    q19: number;
    q2: number;
    q20: number;
    q3: number;
    q4: number;
    q5: number;
    q6: number;
    q7: number;
    q8: number;
    q9: number;
    user_id: string;
  }>;
  stop_bang: Array<{
    __typename?: 'stop_bang';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    id: number;
    q1: number;
    q2: number;
    q3: number;
    q4: number;
    q5: number;
    q6: number;
    q7: number;
    q8: number;
    q9?: number | null;
    user_id: string;
  }>;
  epworth: Array<{
    __typename?: 'epworth';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    id: number;
    q1: number;
    q2: number;
    q3: number;
    q4: number;
    q5: number;
    q6: number;
    q7: number;
    q8: number;
    user_id: string;
  }>;
  apnea_questionnaire: Array<{
    __typename?: 'apnea_questionnaire';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    first_analysis: boolean;
    id: number;
    second_analysis: boolean;
    user_id: string;
  }>;
  munich_chronotype_core: Array<{
    __typename?: 'munich_chronotype_core';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    id: number;
    result: string;
    user_id: string;
  }>;
};

export type SeeSleepDiaryQueryQueryVariables = Exact<{ [key: string]: never }>;

export type SeeSleepDiaryQueryQuery = {
  __typename?: 'query_root';
  sleep_diary: Array<{
    __typename?: 'sleep_diary';
    autogenic_training?: boolean | null;
    behavior_activation?: boolean | null;
    comment?: string | null;
    concentration?: number | null;
    date: `${number}-${number}-${number}`;
    deep_breath?: boolean | null;
    energy?: number | null;
    get_up: `${number}:${number}:${number}+${number}`;
    go_bed: `${number}:${number}:${number}+${number}`;
    go_sleep: `${number}:${number}:${number}+${number}`;
    grade?: number | null;
    humor?: number | null;
    id: number;
    imagery?: boolean | null;
    meditation?: boolean | null;
    paradoxical_intention?: boolean | null;
    gratitude?: boolean | null;
    light_therapy?: boolean | null;
    challenge_catastrophic_thinking?: boolean | null;
    parking_lot?: boolean | null;
    pmr?: boolean | null;
    relationships?: number | null;
    stimulus_control?: boolean | null;
    thought_block?: boolean | null;
    time_to_sleep: number;
    timezone?: number | null;
    wake_up: `${number}:${number}:${number}+${number}`;
    wake_up_count: number;
    wake_up_duration: number;
    snore_count?: number | null;
    snore_duration?: number | null;
    tags: Array<{ __typename?: 'sleep_diary_sleep_tag'; sleep_tag: Sleep_Tag_Enum_Enum }>;
    sleep_diary_medicines: Array<{ __typename?: 'sleep_diary_medicine'; name: string; dose: number; id: number }>;
  }>;
  user: Array<{ __typename?: 'user'; id: string; first_name?: string | null; day_goal?: number | null; night_goal?: number | null }>;
  sleep_restriction: Array<{
    __typename?: 'sleep_restriction';
    before_eficiency: number;
    before_fill_count: number;
    before_time_in_bed: number;
    before_time_sleep: number;
    desired_wake_up: `${number}:${number}:${number}`;
    hour_to_sleep: `${number}:${number}:${number}`;
    new_time_in_bed: number;
    start_date: `${number}-${number}-${number}`;
    user_id: string;
  }>;
};

export type SleepDiaryForSleepRestrictionQueryQueryVariables = Exact<{
  date: Scalars['date'];
}>;

export type SleepDiaryForSleepRestrictionQueryQuery = {
  __typename?: 'query_root';
  sleep_diary: Array<{
    __typename?: 'sleep_diary';
    id: number;
    date: `${number}-${number}-${number}`;
    get_up: `${number}:${number}:${number}+${number}`;
    go_sleep: `${number}:${number}:${number}+${number}`;
    timezone?: number | null;
  }>;
};

export type SleepDiaryWithMedicineQueryQueryVariables = Exact<{ [key: string]: never }>;

export type SleepDiaryWithMedicineQueryQuery = {
  __typename?: 'query_root';
  sleep_diary: Array<{
    __typename?: 'sleep_diary';
    autogenic_training?: boolean | null;
    behavior_activation?: boolean | null;
    comment?: string | null;
    concentration?: number | null;
    date: `${number}-${number}-${number}`;
    deep_breath?: boolean | null;
    energy?: number | null;
    get_up: `${number}:${number}:${number}+${number}`;
    go_bed: `${number}:${number}:${number}+${number}`;
    go_sleep: `${number}:${number}:${number}+${number}`;
    grade?: number | null;
    humor?: number | null;
    id: number;
    imagery?: boolean | null;
    meditation?: boolean | null;
    paradoxical_intention?: boolean | null;
    gratitude?: boolean | null;
    light_therapy?: boolean | null;
    challenge_catastrophic_thinking?: boolean | null;
    parking_lot?: boolean | null;
    pmr?: boolean | null;
    relationships?: number | null;
    stimulus_control?: boolean | null;
    thought_block?: boolean | null;
    time_to_sleep: number;
    timezone?: number | null;
    wake_up: `${number}:${number}:${number}+${number}`;
    wake_up_count: number;
    wake_up_duration: number;
    tags: Array<{ __typename?: 'sleep_diary_sleep_tag'; sleep_tag: Sleep_Tag_Enum_Enum }>;
    sleep_diary_medicines: Array<{ __typename?: 'sleep_diary_medicine'; name: string; dose: number; id: number }>;
  }>;
  user: Array<{ __typename?: 'user'; id: string; first_name?: string | null; day_goal?: number | null; night_goal?: number | null }>;
};

export type SleepDiaryQueryQueryVariables = Exact<{ [key: string]: never }>;

export type SleepDiaryQueryQuery = {
  __typename?: 'query_root';
  sleep_diary: Array<{
    __typename?: 'sleep_diary';
    autogenic_training?: boolean | null;
    behavior_activation?: boolean | null;
    comment?: string | null;
    concentration?: number | null;
    date: `${number}-${number}-${number}`;
    deep_breath?: boolean | null;
    energy?: number | null;
    get_up: `${number}:${number}:${number}+${number}`;
    go_bed: `${number}:${number}:${number}+${number}`;
    go_sleep: `${number}:${number}:${number}+${number}`;
    grade?: number | null;
    humor?: number | null;
    id: number;
    imagery?: boolean | null;
    meditation?: boolean | null;
    paradoxical_intention?: boolean | null;
    gratitude?: boolean | null;
    light_therapy?: boolean | null;
    challenge_catastrophic_thinking?: boolean | null;
    parking_lot?: boolean | null;
    pmr?: boolean | null;
    relationships?: number | null;
    stimulus_control?: boolean | null;
    thought_block?: boolean | null;
    time_to_sleep: number;
    timezone?: number | null;
    wake_up: `${number}:${number}:${number}+${number}`;
    wake_up_count: number;
    wake_up_duration: number;
    tags: Array<{ __typename?: 'sleep_diary_sleep_tag'; sleep_tag: Sleep_Tag_Enum_Enum }>;
  }>;
  user: Array<{ __typename?: 'user'; id: string; first_name?: string | null; day_goal?: number | null; night_goal?: number | null }>;
};

export type SleepRestrictionConfigMutationMutationVariables = Exact<{
  startDate: Scalars['date'];
  id: Scalars['String'];
  object: Sleep_Restriction_Set_Input;
}>;

export type SleepRestrictionConfigMutationMutation = {
  __typename?: 'mutation_root';
  update_sleep_restriction?: {
    __typename?: 'sleep_restriction_mutation_response';
    returning: Array<{
      __typename?: 'sleep_restriction';
      hour_to_sleep: `${number}:${number}:${number}`;
      desired_wake_up: `${number}:${number}:${number}`;
    }>;
  } | null;
};

export type SleepRestrictionConfigQueryQueryVariables = Exact<{ [key: string]: never }>;

export type SleepRestrictionConfigQueryQuery = {
  __typename?: 'query_root';
  sleep_restriction: Array<{
    __typename?: 'sleep_restriction';
    user_id: string;
    start_date: `${number}-${number}-${number}`;
    desired_wake_up: `${number}:${number}:${number}`;
    hour_to_sleep: `${number}:${number}:${number}`;
    new_time_in_bed: number;
    comment?: string | null;
    before_eficiency: number;
  }>;
};

export type SleepRestrictionGraphQueryQueryVariables = Exact<{ [key: string]: never }>;

export type SleepRestrictionGraphQueryQuery = {
  __typename?: 'query_root';
  sleep_diary: Array<{
    __typename?: 'sleep_diary';
    id: number;
    wake_up_duration: number;
    wake_up_count: number;
    wake_up: `${number}:${number}:${number}+${number}`;
    user_id: string;
    time_to_sleep: number;
    grade?: number | null;
    go_sleep: `${number}:${number}:${number}+${number}`;
    go_bed: `${number}:${number}:${number}+${number}`;
    get_up: `${number}:${number}:${number}+${number}`;
    date: `${number}-${number}-${number}`;
    comment?: string | null;
    tags: Array<{ __typename?: 'sleep_diary_sleep_tag'; sleep_tag: Sleep_Tag_Enum_Enum }>;
  }>;
  sleep_restriction: Array<{
    __typename?: 'sleep_restriction';
    before_eficiency: number;
    before_fill_count: number;
    before_time_in_bed: number;
    before_time_sleep: number;
    desired_wake_up: `${number}:${number}:${number}`;
    hour_to_sleep: `${number}:${number}:${number}`;
    new_time_in_bed: number;
    start_date: `${number}-${number}-${number}`;
    user_id: string;
  }>;
};

export type AddSubscriptionMutationVariables = Exact<{
  objects: Array<Subscription_Insert_Input> | Subscription_Insert_Input;
}>;

export type AddSubscriptionMutation = {
  __typename?: 'mutation_root';
  insert_subscription?: { __typename?: 'subscription_mutation_response'; affected_rows: number } | null;
};

export type UserNameQueryQueryVariables = Exact<{ [key: string]: never }>;

export type UserNameQueryQuery = {
  __typename?: 'query_root';
  user: Array<{ __typename?: 'user'; id: string; first_name?: string | null }>;
};

export type UserSignUpMutationVariables = Exact<{
  objects: Array<User_Insert_Input> | User_Insert_Input;
  update_columns: Array<User_Update_Column> | User_Update_Column;
}>;

export type UserSignUpMutation = {
  __typename?: 'mutation_root';
  insert_user?: {
    __typename?: 'user_mutation_response';
    returning: Array<{ __typename?: 'user'; id: string; first_name?: string | null; gender?: string | null }>;
  } | null;
};

export type UserQueryLandingPageQueryVariables = Exact<{ [key: string]: never }>;

export type UserQueryLandingPageQuery = {
  __typename?: 'query_root';
  user: Array<{
    __typename?: 'user';
    id: string;
    email?: string | null;
    can_send_email_reminder?: boolean | null;
    phone?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    gender?: string | null;
    alcohol: boolean;
    caffeine: boolean;
    nap: boolean;
    exercise: boolean;
    nicotine: boolean;
    heavy_meal: boolean;
    sleep_medicine: boolean;
    bathroom: boolean;
    noise: boolean;
    pain: boolean;
    light: boolean;
    partner: boolean;
    temperature: boolean;
    dream: boolean;
    stimulus_control?: boolean | null;
    deep_breath?: boolean | null;
    pmr?: boolean | null;
    meditation?: boolean | null;
    autogenic_training?: boolean | null;
    behavior_activation?: boolean | null;
    imagery?: boolean | null;
    parking_lot?: boolean | null;
    paradoxical_intention?: boolean | null;
    thought_block?: boolean | null;
    challenge_catastrophic_thinking?: boolean | null;
    gratitude?: boolean | null;
    light_therapy?: boolean | null;
  }>;
};

export type DoctorReportQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type DoctorReportQueryQuery = {
  __typename?: 'query_root';
  sleep_restriction: Array<{
    __typename?: 'sleep_restriction';
    before_eficiency: number;
    before_fill_count: number;
    before_time_in_bed: number;
    before_time_sleep: number;
    desired_wake_up: `${number}:${number}:${number}`;
    hour_to_sleep: `${number}:${number}:${number}`;
    new_time_in_bed: number;
    start_date: `${number}-${number}-${number}`;
    user_id: string;
  }>;
  sleep_diary: Array<{
    __typename?: 'sleep_diary';
    id: number;
    autogenic_training?: boolean | null;
    behavior_activation?: boolean | null;
    comment?: string | null;
    concentration?: number | null;
    date: `${number}-${number}-${number}`;
    deep_breath?: boolean | null;
    energy?: number | null;
    get_up: `${number}:${number}:${number}+${number}`;
    go_bed: `${number}:${number}:${number}+${number}`;
    go_sleep: `${number}:${number}:${number}+${number}`;
    grade?: number | null;
    humor?: number | null;
    medicine?: boolean | null;
    imagery?: boolean | null;
    meditation?: boolean | null;
    paradoxical_intention?: boolean | null;
    gratitude?: boolean | null;
    light_therapy?: boolean | null;
    challenge_catastrophic_thinking?: boolean | null;
    parking_lot?: boolean | null;
    pmr?: boolean | null;
    relationships?: number | null;
    stimulus_control?: boolean | null;
    thought_block?: boolean | null;
    time_to_sleep: number;
    timezone?: number | null;
    wake_up: `${number}:${number}:${number}+${number}`;
    wake_up_count: number;
    wake_up_duration: number;
    created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    snore_count?: number | null;
    snore_duration?: number | null;
    tags: Array<{ __typename?: 'sleep_diary_sleep_tag'; sleep_tag: Sleep_Tag_Enum_Enum }>;
    sleep_diary_medicines: Array<{ __typename?: 'sleep_diary_medicine'; name: string; dose: number; id: number }>;
  }>;
  rating: Array<{ __typename?: 'rating'; created: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` }>;
  user: Array<{
    __typename?: 'user';
    id: string;
    day_goal?: number | null;
    night_goal?: number | null;
    last_name?: string | null;
    first_name?: string | null;
    created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    gender?: string | null;
    current_journey_id?: number | null;
    tried_alcohol?: boolean | null;
    tried_medicine?: boolean | null;
    tried_melatonin?: boolean | null;
    tried_other?: string | null;
    tried_restriction?: boolean | null;
    tried_sleep_hygiene?: boolean | null;
    phone?: string | null;
    email?: string | null;
    profile_picture?: string | null;
    user_tags: Array<{ __typename?: 'user_tag'; tag: string }>;
    toolbox?: {
      __typename?: 'toolbox';
      id: number;
      autogenic_training?: boolean | null;
      behavior_activation?: boolean | null;
      deep_breath?: boolean | null;
      imagery?: boolean | null;
      meditation?: boolean | null;
      paradoxical_intention?: boolean | null;
      parking_lot?: boolean | null;
      gratitude?: boolean | null;
      light_therapy?: boolean | null;
      challenge_catastrophic_thinking?: boolean | null;
      sleep_restriction?: boolean | null;
      pmr?: boolean | null;
      stimulus_control?: boolean | null;
      thought_block?: boolean | null;
    } | null;
    gad: Array<{
      __typename?: 'gad';
      q1: number;
      q2: number;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
    gad7s: Array<{
      __typename?: 'gad7';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      q1: number;
      q2: number;
      q3: number;
      q4: number;
      q5: number;
      q6: number;
      q7: number;
    }>;
    phq9s: Array<{
      __typename?: 'phq9';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      q1: number;
      q2: number;
      q3: number;
      q4: number;
      q5: number;
      q6: number;
      q7: number;
      q8: number;
      q9: number;
    }>;
    phq: Array<{
      __typename?: 'phq';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      q1: number;
      q2: number;
    }>;
    epworths: Array<{
      __typename?: 'epworth';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      q1: number;
      q2: number;
      q3: number;
      q4: number;
      q5: number;
      q6: number;
      q7: number;
      q8: number;
    }>;
    stop_bangs: Array<{
      __typename?: 'stop_bang';
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      q1: number;
      q2: number;
      q3: number;
      q4: number;
      q5: number;
      q6: number;
      q7: number;
      q8: number;
      q9?: number | null;
    }>;
    isi: Array<{
      __typename?: 'isi';
      id: number;
      q1: number;
      age?: number | null;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      q2: number;
      q4: number;
      q3: number;
      q5: number;
      q6: number;
      q7: number;
    }>;
    kuppermans: Array<{
      __typename?: 'kupperman';
      id: number;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      q1: number;
      q2: number;
      q3: number;
      q4: number;
      q5: number;
      q6: number;
      q7: number;
      q8: number;
      q9: number;
      q10: number;
      q11: number;
      q12: number;
      q13: number;
    }>;
    psqi: Array<{
      __typename?: 'psqi';
      id: number;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      q1: string;
      age: number;
      q2: number;
      q3: string;
      q4: number;
      q5a: number;
      q5b: number;
      q5c: number;
      q5d: number;
      q5e: number;
      q5f: number;
      q5g: number;
      q5h: number;
      q5i: number;
      q5j: number;
      q6: number;
      q7: number;
      q8: number;
      q9: number;
    }>;
    user_journeys: Array<{ __typename?: 'user_journey'; journey_id: number }>;
    user_program_sessions: Array<{
      __typename?: 'user_program_session';
      session_id: number;
      state: User_Program_Session_Enum_Enum;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
    }>;
  }>;
};

export type SleepDiaryWithCompressedDataMutationMutationVariables = Exact<{
  sleep_diary: Array<Sleep_Diary_Insert_Input> | Sleep_Diary_Insert_Input;
  update_columns: Array<Sleep_Diary_Update_Column> | Sleep_Diary_Update_Column;
  sleep_tracker_compressed_data: Sleep_Tracker_Compressed_Data_Insert_Input;
}>;

export type SleepDiaryWithCompressedDataMutationMutation = {
  __typename?: 'mutation_root';
  insert_sleep_diary?: {
    __typename?: 'sleep_diary_mutation_response';
    returning: Array<{
      __typename?: 'sleep_diary';
      id: number;
      grade?: number | null;
      comment?: string | null;
      timezone?: number | null;
      tags: Array<{ __typename?: 'sleep_diary_sleep_tag'; sleep_tag: Sleep_Tag_Enum_Enum }>;
    }>;
  } | null;
  insert_sleep_tracker_compressed_data_one?: {
    __typename?: 'sleep_tracker_compressed_data';
    created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  } | null;
};

export type SleepDiaryMutationMutationVariables = Exact<{
  sleep_diary: Array<Sleep_Diary_Insert_Input> | Sleep_Diary_Insert_Input;
  update_columns: Array<Sleep_Diary_Update_Column> | Sleep_Diary_Update_Column;
}>;

export type SleepDiaryMutationMutation = {
  __typename?: 'mutation_root';
  insert_sleep_diary?: {
    __typename?: 'sleep_diary_mutation_response';
    returning: Array<{
      __typename?: 'sleep_diary';
      id: number;
      grade?: number | null;
      comment?: string | null;
      timezone?: number | null;
      tags: Array<{ __typename?: 'sleep_diary_sleep_tag'; sleep_tag: Sleep_Tag_Enum_Enum }>;
    }>;
  } | null;
};

export type UseSleepDiaryQueryQueryVariables = Exact<{ [key: string]: never }>;

export type UseSleepDiaryQueryQuery = {
  __typename?: 'query_root';
  user: Array<{
    __typename?: 'user';
    id: string;
    first_name?: string | null;
    last_name?: string | null;
    gender?: string | null;
    desired_wake_up?: `${number}:${number}:${number}+${number}` | null;
    day_goal?: number | null;
    night_goal?: number | null;
    alcohol: boolean;
    caffeine: boolean;
    nap: boolean;
    exercise: boolean;
    nicotine: boolean;
    heavy_meal: boolean;
    sleep_medicine: boolean;
    bathroom: boolean;
    noise: boolean;
    pain: boolean;
    light: boolean;
    partner: boolean;
    temperature: boolean;
    dream: boolean;
    stimulus_control?: boolean | null;
    deep_breath?: boolean | null;
    pmr?: boolean | null;
    meditation?: boolean | null;
    autogenic_training?: boolean | null;
    behavior_activation?: boolean | null;
    imagery?: boolean | null;
    parking_lot?: boolean | null;
    paradoxical_intention?: boolean | null;
    thought_block?: boolean | null;
    challenge_catastrophic_thinking?: boolean | null;
    gratitude?: boolean | null;
    light_therapy?: boolean | null;
    sleep_diaries: Array<{
      __typename?: 'sleep_diary';
      id: number;
      date: `${number}-${number}-${number}`;
      get_up: `${number}:${number}:${number}+${number}`;
      go_bed: `${number}:${number}:${number}+${number}`;
      go_sleep: `${number}:${number}:${number}+${number}`;
      wake_up: `${number}:${number}:${number}+${number}`;
      wake_up_count: number;
      wake_up_duration: number;
      time_to_sleep: number;
      medicine?: boolean | null;
    }>;
  }>;
};

export type UserSpecifcSleepDiaryQueryQueryVariables = Exact<{
  date: Scalars['date'];
}>;

export type UserSpecifcSleepDiaryQueryQuery = {
  __typename?: 'query_root';
  user: Array<{
    __typename?: 'user';
    id: string;
    first_name?: string | null;
    last_name?: string | null;
    gender?: string | null;
    desired_wake_up?: `${number}:${number}:${number}+${number}` | null;
    day_goal?: number | null;
    night_goal?: number | null;
    alcohol: boolean;
    caffeine: boolean;
    nap: boolean;
    exercise: boolean;
    nicotine: boolean;
    heavy_meal: boolean;
    sleep_medicine: boolean;
    bathroom: boolean;
    noise: boolean;
    pain: boolean;
    light: boolean;
    partner: boolean;
    temperature: boolean;
    dream: boolean;
    stimulus_control?: boolean | null;
    deep_breath?: boolean | null;
    pmr?: boolean | null;
    meditation?: boolean | null;
    autogenic_training?: boolean | null;
    imagery?: boolean | null;
    parking_lot?: boolean | null;
    paradoxical_intention?: boolean | null;
    thought_block?: boolean | null;
    challenge_catastrophic_thinking?: boolean | null;
    gratitude?: boolean | null;
    light_therapy?: boolean | null;
    sleep_diaries: Array<{
      __typename?: 'sleep_diary';
      id: number;
      date: `${number}-${number}-${number}`;
      get_up: `${number}:${number}:${number}+${number}`;
      go_bed: `${number}:${number}:${number}+${number}`;
      go_sleep: `${number}:${number}:${number}+${number}`;
      wake_up: `${number}:${number}:${number}+${number}`;
      wake_up_count: number;
      wake_up_duration: number;
      time_to_sleep: number;
      medicine?: boolean | null;
      comment?: string | null;
      autogenic_training?: boolean | null;
      challenge_catastrophic_thinking?: boolean | null;
      concentration?: number | null;
      deep_breath?: boolean | null;
      energy?: number | null;
      grade?: number | null;
      gratitude?: boolean | null;
      humor?: number | null;
      imagery?: boolean | null;
      light_therapy?: boolean | null;
      meditation?: boolean | null;
      paradoxical_intention?: boolean | null;
      parking_lot?: boolean | null;
      pmr?: boolean | null;
      relationships?: number | null;
      stimulus_control?: boolean | null;
      tags: Array<{ __typename?: 'sleep_diary_sleep_tag'; sleep_tag: Sleep_Tag_Enum_Enum }>;
    }>;
  }>;
};

export type InsertDiaryFromAppleHealthMutationMutationVariables = Exact<{
  sleep_diary_insert_input?: Array<Sleep_Diary_Insert_Input> | Sleep_Diary_Insert_Input;
  sleep_diary_apple_health_insert_input?: Array<Sleep_Diary_Apple_Health_Insert_Input> | Sleep_Diary_Apple_Health_Insert_Input;
}>;

export type InsertDiaryFromAppleHealthMutationMutation = {
  __typename?: 'mutation_root';
  insert_sleep_diary?: {
    __typename?: 'sleep_diary_mutation_response';
    affected_rows: number;
    returning: Array<{ __typename?: 'sleep_diary'; id: number; date: `${number}-${number}-${number}` }>;
  } | null;
  insert_sleep_diary_apple_health?: { __typename?: 'sleep_diary_apple_health_mutation_response'; affected_rows: number } | null;
};

export type InsertDiaryFromGoogleFitMutationMutationVariables = Exact<{
  sleep_diary_insert_input?: Array<Sleep_Diary_Insert_Input> | Sleep_Diary_Insert_Input;
  sleep_diary_google_fit_insert_input?: Array<Sleep_Diary_Google_Fit_Insert_Input> | Sleep_Diary_Google_Fit_Insert_Input;
}>;

export type InsertDiaryFromGoogleFitMutationMutation = {
  __typename?: 'mutation_root';
  insert_sleep_diary?: {
    __typename?: 'sleep_diary_mutation_response';
    affected_rows: number;
    returning: Array<{ __typename?: 'sleep_diary'; id: number; date: `${number}-${number}-${number}` }>;
  } | null;
  insert_sleep_diary_google_fit?: { __typename?: 'sleep_diary_google_fit_mutation_response'; affected_rows: number } | null;
};

export type InsertDiaryMutationMutationVariables = Exact<{
  objects?: Array<Sleep_Diary_Insert_Input> | Sleep_Diary_Insert_Input;
}>;

export type InsertDiaryMutationMutation = {
  __typename?: 'mutation_root';
  insert_sleep_diary?: { __typename?: 'sleep_diary_mutation_response'; affected_rows: number } | null;
};

export type UpdateToolboxMutationMutationVariables = Exact<{
  id: Scalars['Int'];
  data?: InputMaybe<Toolbox_Set_Input>;
}>;

export type UpdateToolboxMutationMutation = {
  __typename?: 'mutation_root';
  update_toolbox_by_pk?: {
    __typename?: 'toolbox';
    id: number;
    autogenic_training?: boolean | null;
    behavior_activation?: boolean | null;
    challenge_catastrophic_thinking?: boolean | null;
    deep_breath?: boolean | null;
    gratitude?: boolean | null;
    imagery?: boolean | null;
    light_therapy?: boolean | null;
    meditation?: boolean | null;
    paradoxical_intention?: boolean | null;
    parking_lot?: boolean | null;
    pmr?: boolean | null;
    sleep_restriction?: boolean | null;
    stimulus_control?: boolean | null;
    thought_block?: boolean | null;
    user_id: string;
  } | null;
};

export type UpdateUserInfoMutationMutationVariables = Exact<{
  id: Scalars['String'];
  first_name: Scalars['String'];
  gender: Scalars['String'];
  last_name: Scalars['String'];
}>;

export type UpdateUserInfoMutationMutation = { __typename?: 'mutation_root'; insert_user_one?: { __typename?: 'user'; id: string } | null };

export type UpdateUserVersionMutationMutationVariables = Exact<{
  id: Scalars['String'];
  os: Os_Enum_Enum;
  version: Scalars['String'];
}>;

export type UpdateUserVersionMutationMutation = {
  __typename?: 'mutation_root';
  insert_user_one?: { __typename?: 'user'; id: string } | null;
};

export type AppointmentConfigQueryQueryVariables = Exact<{
  user_id: Scalars['String'];
}>;

export type AppointmentConfigQueryQuery = {
  __typename?: 'query_root';
  user_by_pk?: {
    __typename?: 'user';
    id: string;
    first_name?: string | null;
    last_name?: string | null;
    birthdate?: `${number}-${number}-${number}` | null;
    email?: string | null;
    document?: string | null;
    alt_email?: string | null;
    phone?: string | null;
    companies: Array<{
      __typename?: 'company_user';
      id: number;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      company?: {
        __typename?: 'company';
        id: number;
        name: string;
        app_customizations: Array<{ __typename?: 'app_customization'; id: number; data: unknown }>;
      } | null;
    }>;
  } | null;
  app_customization: Array<{ __typename?: 'app_customization'; id: number; data: unknown }>;
  user_telehealth: Array<{
    __typename?: 'user_telehealth';
    max_appointments: number;
    min_days_between_appointments: number;
    user_id?: string | null;
  }>;
  appointment_aggregate: {
    __typename?: 'appointment_aggregate';
    aggregate?: { __typename?: 'appointment_aggregate_fields'; count?: number | null } | null;
  };
  appointment: Array<{
    __typename?: 'appointment';
    id: string;
    start: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
  }>;
};

export type DreamQueryQueryVariables = Exact<{
  user_id: Scalars['String'];
}>;

export type DreamQueryQuery = {
  __typename?: 'query_root';
  dream: Array<{ __typename?: 'dream'; date: `${number}-${number}-${number}`; id: number; remember: boolean; description?: string | null }>;
};

export type HomeQueryQueryVariables = Exact<{
  id: Scalars['String'];
  limit: Scalars['Int'];
  companyId: Scalars['Int'];
}>;

export type HomeQueryQuery = {
  __typename?: 'query_root';
  user_by_pk?: {
    __typename?: 'user';
    id: string;
    first_name?: string | null;
    last_name?: string | null;
    email?: string | null;
    phone?: string | null;
    gender?: string | null;
    birthdate?: `${number}-${number}-${number}` | null;
    alt_email?: string | null;
    sleep_restriction?: boolean | null;
    session_to_pay: number;
    subscription_expiration?: `${number}-${number}-${number}` | null;
    paid?: boolean | null;
    app_version?: string | null;
    device_os?: Os_Enum_Enum | null;
    current_journey_id?: number | null;
    sleep_diaries: Array<{ __typename?: 'sleep_diary'; id: number; date: `${number}-${number}-${number}` }>;
    sleep_restrictions: Array<{ __typename?: 'sleep_restriction'; id: number; start_date: `${number}-${number}-${number}` }>;
    sleep_restrictions_aggregate: {
      __typename?: 'sleep_restriction_aggregate';
      aggregate?: { __typename?: 'sleep_restriction_aggregate_fields'; count?: number | null } | null;
    };
    health_carers: Array<{
      __typename?: 'health_carer_user';
      id: number;
      created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
      health_carer: { __typename?: 'health_carer'; name?: string | null };
    }>;
    companies: Array<{
      __typename?: 'company_user';
      id: number;
      created_at: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;
      company?: {
        __typename?: 'company';
        id: number;
        name: string;
        app_customizations: Array<{ __typename?: 'app_customization'; id: number; type: App_Customization_Type_Enum_Enum; data: unknown }>;
      } | null;
    }>;
    user_program_sessions: Array<{ __typename?: 'user_program_session'; state: User_Program_Session_Enum_Enum; session_id: number }>;
  } | null;
  app_customization: Array<{ __typename?: 'app_customization'; id: number; type: App_Customization_Type_Enum_Enum; data: unknown }>;
};

export type OnboardingPaymentQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type OnboardingPaymentQueryQuery = {
  __typename?: 'query_root';
  user_by_pk?: {
    __typename?: 'user';
    id: string;
    subscription_expiration?: `${number}-${number}-${number}` | null;
    paid?: boolean | null;
  } | null;
};

export type SettingsPageQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type SettingsPageQueryQuery = {
  __typename?: 'query_root';
  user_by_pk?: {
    __typename?: 'user';
    id: string;
    first_name?: string | null;
    last_name?: string | null;
    gender?: string | null;
    session_to_pay: number;
    subscription_expiration?: `${number}-${number}-${number}` | null;
    paid?: boolean | null;
    alcohol: boolean;
    caffeine: boolean;
    nap: boolean;
    exercise: boolean;
    nicotine: boolean;
    heavy_meal: boolean;
    sleep_medicine: boolean;
    bathroom: boolean;
    noise: boolean;
    pain: boolean;
    light: boolean;
    partner: boolean;
    temperature: boolean;
    dream: boolean;
    stimulus_control?: boolean | null;
    deep_breath?: boolean | null;
    pmr?: boolean | null;
    meditation?: boolean | null;
    autogenic_training?: boolean | null;
    behavior_activation?: boolean | null;
    imagery?: boolean | null;
    parking_lot?: boolean | null;
    paradoxical_intention?: boolean | null;
    thought_block?: boolean | null;
    challenge_catastrophic_thinking?: boolean | null;
    gratitude?: boolean | null;
    light_therapy?: boolean | null;
    checkout: Array<{ __typename?: 'user_checkout'; data: unknown }>;
  } | null;
};

export type SleepDiaryForAppleWatchReadQueryQueryVariables = Exact<{
  date: Scalars['date'];
  userId: Scalars['String'];
}>;

export type SleepDiaryForAppleWatchReadQueryQuery = {
  __typename?: 'query_root';
  sleep_diary: Array<{ __typename?: 'sleep_diary'; date: `${number}-${number}-${number}`; id: number }>;
};

export type SleepDiaryForWearablesWriteQueryQueryVariables = Exact<{
  date: Scalars['date'];
  userId: Scalars['String'];
}>;

export type SleepDiaryForWearablesWriteQueryQuery = {
  __typename?: 'query_root';
  sleep_diary: Array<{
    __typename?: 'sleep_diary';
    id: number;
    date: `${number}-${number}-${number}`;
    go_bed: `${number}:${number}:${number}+${number}`;
    go_sleep: `${number}:${number}:${number}+${number}`;
    time_to_sleep: number;
    wake_up_duration: number;
    wake_up_count: number;
    wake_up: `${number}:${number}:${number}+${number}`;
    get_up: `${number}:${number}:${number}+${number}`;
    timezone?: number | null;
  }>;
};

export type SleepTrackerDayDiaryQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type SleepTrackerDayDiaryQueryQuery = {
  __typename?: 'query_root';
  user_by_pk?: {
    __typename?: 'user';
    id: string;
    day_goal?: number | null;
    alcohol: boolean;
    caffeine: boolean;
    nap: boolean;
    exercise: boolean;
    nicotine: boolean;
    heavy_meal: boolean;
    sleep_medicine: boolean;
    stimulus_control?: boolean | null;
    deep_breath?: boolean | null;
    pmr?: boolean | null;
    meditation?: boolean | null;
    autogenic_training?: boolean | null;
    behavior_activation?: boolean | null;
    imagery?: boolean | null;
    parking_lot?: boolean | null;
    paradoxical_intention?: boolean | null;
    thought_block?: boolean | null;
    challenge_catastrophic_thinking?: boolean | null;
    gratitude?: boolean | null;
    light_therapy?: boolean | null;
  } | null;
};

export type SleepTrackerNightDiaryQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type SleepTrackerNightDiaryQueryQuery = {
  __typename?: 'query_root';
  user_by_pk?: {
    __typename?: 'user';
    id: string;
    bathroom: boolean;
    noise: boolean;
    pain: boolean;
    light: boolean;
    partner: boolean;
    temperature: boolean;
    dream: boolean;
    sleep_diaries: Array<{ __typename?: 'sleep_diary'; id: number; date: `${number}-${number}-${number}` }>;
  } | null;
};

export type SleepTrackerQueryQueryVariables = Exact<{
  id: Scalars['String'];
  limit: Scalars['Int'];
}>;

export type SleepTrackerQueryQuery = {
  __typename?: 'query_root';
  user_by_pk?: {
    __typename?: 'user';
    id: string;
    sleep_restrictions: Array<{ __typename?: 'sleep_restriction'; id: number; desired_wake_up: `${number}:${number}:${number}` }>;
    sleep_diaries: Array<{
      __typename?: 'sleep_diary';
      id: number;
      get_up: `${number}:${number}:${number}+${number}`;
      date: `${number}-${number}-${number}`;
    }>;
  } | null;
  reminder: Array<{ __typename?: 'reminder'; id: number; type: Reminder_Type_Enum_Enum }>;
};

export type ToolsQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ToolsQueryQuery = {
  __typename?: 'query_root';
  user_by_pk?: {
    __typename?: 'user';
    id: string;
    autogenic_training?: boolean | null;
    behavior_activation?: boolean | null;
    challenge_catastrophic_thinking?: boolean | null;
    deep_breath?: boolean | null;
    gratitude?: boolean | null;
    imagery?: boolean | null;
    light_therapy?: boolean | null;
    meditation?: boolean | null;
    paradoxical_intention?: boolean | null;
    parking_lot?: boolean | null;
    pmr?: boolean | null;
    stimulus_control?: boolean | null;
    thought_block?: boolean | null;
    toolbox?: {
      __typename?: 'toolbox';
      id: number;
      user_id: string;
      autogenic_training?: boolean | null;
      behavior_activation?: boolean | null;
      challenge_catastrophic_thinking?: boolean | null;
      deep_breath?: boolean | null;
      gratitude?: boolean | null;
      imagery?: boolean | null;
      light_therapy?: boolean | null;
      meditation?: boolean | null;
      paradoxical_intention?: boolean | null;
      parking_lot?: boolean | null;
      pmr?: boolean | null;
      sleep_restriction?: boolean | null;
      stimulus_control?: boolean | null;
      thought_block?: boolean | null;
    } | null;
    user_journeys: Array<{ __typename?: 'user_journey'; user_id: string; journey_id: number }>;
  } | null;
  technique_resource: Array<{
    __typename?: 'technique_resource';
    id: number;
    image: string;
    kind: string;
    technique: string;
    title: string;
    url: string;
  }>;
};

export type UserBasicInfoQueryQueryVariables = Exact<{ [key: string]: never }>;

export type UserBasicInfoQueryQuery = {
  __typename?: 'query_root';
  user: Array<{ __typename?: 'user'; id: string; first_name?: string | null; last_name?: string | null; gender?: string | null }>;
};

export type UserKeyValueQueryQueryVariables = Exact<{
  key: User_Key_Value_Key_Enum_Enum;
  user_id: Scalars['String'];
}>;

export type UserKeyValueQueryQuery = {
  __typename?: 'query_root';
  user_key_value_by_pk?: {
    __typename?: 'user_key_value';
    key: User_Key_Value_Key_Enum_Enum;
    user_id: string;
    value?: string | null;
  } | null;
};

export type AllUserKeyValueQueryQueryVariables = Exact<{
  user_id: Scalars['String'];
}>;

export type AllUserKeyValueQueryQuery = {
  __typename?: 'query_root';
  user_key_value: Array<{ __typename?: 'user_key_value'; key: User_Key_Value_Key_Enum_Enum; user_id: string; value?: string | null }>;
};

export type UserMaxAppointmentTelehealthQueryQueryVariables = Exact<{
  user_id: Scalars['String'];
}>;

export type UserMaxAppointmentTelehealthQueryQuery = {
  __typename?: 'query_root';
  user_telehealth: Array<{ __typename?: 'user_telehealth'; max_appointments: number }>;
};

export type UserProgramSessionByUserIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type UserProgramSessionByUserIdQuery = {
  __typename?: 'query_root';
  user_program_session: Array<{ __typename?: 'user_program_session'; state: User_Program_Session_Enum_Enum; session_id: number }>;
};

export type UserSessionsQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type UserSessionsQueryQuery = {
  __typename?: 'query_root';
  user_by_pk?: {
    __typename?: 'user';
    id: string;
    paid?: boolean | null;
    current_journey_id?: number | null;
    subscription_expiration?: `${number}-${number}-${number}` | null;
    session_to_pay: number;
    user_program_sessions: Array<{ __typename?: 'user_program_session'; state: User_Program_Session_Enum_Enum; session_id: number }>;
  } | null;
};

export type YouQueryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type YouQueryQuery = {
  __typename?: 'query_root';
  user_by_pk?: {
    __typename?: 'user';
    id: string;
    sleep_restriction?: boolean | null;
    gender?: string | null;
    session_to_pay: number;
    subscription_expiration?: `${number}-${number}-${number}` | null;
    paid?: boolean | null;
    points?: number | null;
    day_goal?: number | null;
    night_goal?: number | null;
    created_at?: `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}` | null;
    sleep_diaries: Array<{
      __typename?: 'sleep_diary';
      concentration?: number | null;
      date: `${number}-${number}-${number}`;
      energy?: number | null;
      get_up: `${number}:${number}:${number}+${number}`;
      go_bed: `${number}:${number}:${number}+${number}`;
      go_sleep: `${number}:${number}:${number}+${number}`;
      grade?: number | null;
      humor?: number | null;
      relationships?: number | null;
      time_to_sleep: number;
      wake_up: `${number}:${number}:${number}+${number}`;
      wake_up_count: number;
      wake_up_duration: number;
      autogenic_training?: boolean | null;
      behavior_activation?: boolean | null;
      comment?: string | null;
      deep_breath?: boolean | null;
      id: number;
      imagery?: boolean | null;
      meditation?: boolean | null;
      paradoxical_intention?: boolean | null;
      gratitude?: boolean | null;
      light_therapy?: boolean | null;
      challenge_catastrophic_thinking?: boolean | null;
      parking_lot?: boolean | null;
      pmr?: boolean | null;
      stimulus_control?: boolean | null;
      thought_block?: boolean | null;
      timezone?: number | null;
      tags: Array<{ __typename?: 'sleep_diary_sleep_tag'; sleep_tag: Sleep_Tag_Enum_Enum }>;
    }>;
    first_sleep_restriction: Array<{ __typename?: 'sleep_restriction'; start_date: `${number}-${number}-${number}` }>;
    last_sleep_restriction: Array<{
      __typename?: 'sleep_restriction';
      start_date: `${number}-${number}-${number}`;
      desired_wake_up: `${number}:${number}:${number}`;
      hour_to_sleep: `${number}:${number}:${number}`;
    }>;
    user_program_sessions: Array<{ __typename?: 'user_program_session'; session_id: number; state: User_Program_Session_Enum_Enum }>;
  } | null;
};
