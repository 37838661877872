import { Body, H3, Separator } from '@web/atomic';
import { CardWrapper } from '@web/atomic/atm.wrapper/wrapper.component';
import React from 'react';

interface IFinancialSignUpInviteProps {
  hasFinancialInfo: boolean;
  title?: string;
  body?: string;
}

const FinancialSignUpInvite: React.FunctionComponent<IFinancialSignUpInviteProps> = (props) => {
  if (props.hasFinancialInfo) {
    return null;
  }

  return (
    <CardWrapper>
      <H3>{props.title}</H3>
      <Body> {props.body} </Body>
      <Separator />
    </CardWrapper>
  );
};

FinancialSignUpInvite.defaultProps = {
  title: 'Cadastre seus dados bancários para começar a receber em sua conta',
  body: 'Você já pode convidar seus pacientes ao programa, mas os pagamentos deles só começarão a ser transferidos quando você completar seu cadastro.',
};

export default FinancialSignUpInvite;
