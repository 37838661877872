import * as React from 'react';
import SvgIcWhatsapp from '@web/atomic/atm.svg-icon/ic-whatsapp';
import { WhatsappFABStyled } from './whatsapp-fab.component.style';
import { WhatsappNumberKind, getWhatsappClickToChat } from '@global/utils/url/create-whatsapp-url';

export interface IWhatsappFAB {
  whatsappText?: string;
  kind: WhatsappNumberKind;
}

const WhatsappFAB: React.FunctionComponent<IWhatsappFAB> = (props) => {
  const { whatsappText } = props;

  return (
    <WhatsappFABStyled
      href={getWhatsappClickToChat(props.kind, whatsappText)}
      target="_blank"
      rel="noreferrer"
      aria-label="link do whatsapp"
    >
      <SvgIcWhatsapp />
    </WhatsappFABStyled>
  );
};

WhatsappFAB.defaultProps = {
  whatsappText: `Olá%20Vigilantes%20do%20Sono!`,
};

export default WhatsappFAB;
