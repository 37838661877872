import { hasWindow } from '@web/utils/platform';
import { isDevelopment } from '../app.landing-page/src/utils/environment';

export interface CustomWindow extends Window {
  FB: {
    AppEvents: {
      logEvent: (eventName: string, valueToSum?: number, parameters?: EventParams) => void;
    };
  };
  ga: any;
  dataLayer: any;
}
declare let window: CustomWindow;

export enum EventKind {
  click = 'click',
  view = 'view',
  submit = 'form_submit',
  reload = 'reload',
}

export interface EventParams {
  event_category: string;
  event_label: string;
  value?: number;
}

export const sendEvent = (eventKind: EventKind, eventParams: EventParams): void => {
  console.log(
    `Event sent
       kind: ${eventKind}
       params: ${JSON.stringify(eventParams)}`
  );

  // https://developers.facebook.com/docs/reference/javascript/FB.AppEvents.LogEvent/
  if (hasWindow() && window.FB) window.FB.AppEvents.logEvent(eventKind, null, eventParams);

  // https://developers.google.com/analytics/devguides/collection/analyticsjs/sending-hits?hl=pt-br
  if (hasWindow() && window.ga) {
    const data = {
      hitType: 'event',
      eventCategory: eventParams.event_category,
      eventAction: eventKind,
      eventLabel: eventParams.event_label,
      eventValue: eventParams.value,
    };
    // https://stackoverflow.com/a/40761709/3670829
    if (window.dataLayer) {
      return window.ga.getAll()?.[0]?.send(data);
    } else {
      return window.ga('send', data);
    }
  }
};

////////////////////////////////////////////////////////////////////////////////////////////////////

export type CustomDataLayer = QuizDataLayer | SleepDiaryDataLayer | CustomClickDataLayer | CustomViewDataLayer;

interface QuizDataLayer {
  event: 'results_submit';
  age?: string;
  freq?: string;
  challenge?: string;
  timeToSleep?: string;
  wakeUpMiddle?: string;
  wakeUpEarly?: string;
  cause?: string;
  solution?: string;
  condition?: string;
  subscribe?: boolean;
}

interface SleepDiaryDataLayer {
  event: 'sleep_diary_submit';
  version?: string;
  error?: string;
  timeToComplete?: number;
  errorQuantity?: number;
}

interface CustomViewDataLayer {
  event: 'custom_view';
  id?: string;
  value?: number;
}

interface CustomClickDataLayer {
  event: 'custom_click';
  id?: string;
  value?: number;
}

export const sendDataLayer = (data: CustomDataLayer): void => {
  if (hasWindow() && window.dataLayer) {
    console.log('sendDataLayer -> data', data);
    const dataLayer = window.dataLayer;
    dataLayer.push(data);
  } else if (!isDevelopment) {
    console.error('Datalayer not available');
  }
};
