import { parseToDate } from '@global/utils/date/parse-to-date';
import { parseDateToShortString } from '@global/utils/date/parse-to-string';
import { getEmojiAndNameForSleepDiaryTag, getEmojiForSleepDiaryTag, SleepDiaryTag } from '@global/utils/domain/entities';
import { DoctorReportQueryQuery, Sleep_Tag_Enum_Enum } from '@global/utils/remote-graphql-types';
import { AppThemeContext, BodySmall, BrandColor, GrayColor } from '@web/atomic';
import React, { useContext } from 'react';
import { CartesianGrid, Legend, ResponsiveContainer, Scatter, ScatterChart, XAxis, YAxis } from 'recharts';
import { getCycleReferenceLine } from './cycle-reference.components';
import { ChartWrapperStyled } from './graph.styled';
import { SleepDiaryGraphProps } from './utils';
import { getDiaryTags } from '@global/utils/domain/sleep-diary';

type IBeforeBeforeSleepTagsGraphProps = SleepDiaryGraphProps;

export const BeforeSleepTagsGraph: React.FunctionComponent<IBeforeBeforeSleepTagsGraphProps> = (props) => {
  const tagsLabel = 'tags';

  const cData = props.sleepDiaryData
    ? props.sleepDiaryData.reduce((acc, curr: DoctorReportQueryQuery['sleep_diary'][number]) => {
        const diaryTags = getDiaryTags(curr);

        if (!diaryTags?.length) {
          acc.push({ name: parseDateToShortString(parseToDate(curr.date)) });
          return acc;
        }
        const newTags = [];
        diaryTags.forEach(({ sleep_tag }) => {
          const tag = mapToTag(sleep_tag);
          if (!tags.includes(tag)) {
            return;
          }
          const response = {
            name: parseDateToShortString(parseToDate(curr.date)),
          };

          response[tagsLabel] = mapTagToNum(tag);
          newTags.push(response);
        });
        if (newTags.length) {
          acc.push(...newTags);
        } else {
          acc.push({ name: parseDateToShortString(parseToDate(curr.date)) });
        }
        return acc;
      }, [])
    : [];

  // BUSINESS-RULE: the first cycle actually starts one day before the first diary
  if (props.cycles && props.cycles[0]) {
    cData.unshift({
      name: parseDateToShortString(props.cycles[0].startDate),
    });
  }

  const { theme } = useContext(AppThemeContext);

  return (
    <>
      <ChartWrapperStyled height={props.height}>
        <ResponsiveContainer>
          <ScatterChart
            height={props.height}
            width={props.width}
            // POG-ALERT: this margin is a hack to remove space before/after Y-Axis
            margin={{ top: 0, right: 35, bottom: 0, left: -35 }}
          >
            <CartesianGrid stroke={theme.name === 'dark' ? GrayColor.GrayDark : GrayColor.GrayXXXLight} />
            <XAxis
              stroke={theme.name === 'dark' ? GrayColor.GrayXLight : GrayColor.Gray}
              allowDuplicatedCategory={false}
              dataKey="name"
              label={{ position: 'insideBottomRight', offset: 0 }}
            />
            <YAxis
              stroke={theme.name === 'dark' ? GrayColor.GrayXLight : GrayColor.Gray}
              dataKey={tagsLabel}
              ticks={tags.map(mapTagToNum)}
              domain={[0, tags.length]}
              tickFormatter={mapNumToTag}
              padding={{ bottom: 20, top: 0 }}
            />
            <Legend verticalAlign="top" wrapperStyle={{ left: 0, top: -30 }} />
            <Scatter isAnimationActive={!props.removeAnimation} name="higiene do sono" data={cData} fill={BrandColor.Hoki} />
            {getCycleReferenceLine(props)}
          </ScatterChart>
        </ResponsiveContainer>
      </ChartWrapperStyled>
      <BodySmall>{tags.map((tag) => getEmojiAndNameForSleepDiaryTag(tag)).join('; ')}</BodySmall>
    </>
  );
};

const tags = [
  SleepDiaryTag.Alcohol,
  SleepDiaryTag.Caffeine,
  SleepDiaryTag.Nap,
  SleepDiaryTag.Exercise,
  SleepDiaryTag.Nicotine,
  SleepDiaryTag.Meal,
  SleepDiaryTag.Medicine,
];
const mapToTag = (tagEnum: Sleep_Tag_Enum_Enum) => {
  const dict: Record<Sleep_Tag_Enum_Enum, SleepDiaryTag> = {
    [Sleep_Tag_Enum_Enum.Alcohol]: SleepDiaryTag.Alcohol,
    [Sleep_Tag_Enum_Enum.Bathroom]: SleepDiaryTag.Bathroom,
    [Sleep_Tag_Enum_Enum.Caffeine]: SleepDiaryTag.Caffeine,
    [Sleep_Tag_Enum_Enum.Dream]: SleepDiaryTag.Dream,
    [Sleep_Tag_Enum_Enum.Exercise]: SleepDiaryTag.Exercise,
    [Sleep_Tag_Enum_Enum.Light]: SleepDiaryTag.Light,
    [Sleep_Tag_Enum_Enum.Meal]: SleepDiaryTag.Meal,
    [Sleep_Tag_Enum_Enum.Medicine]: SleepDiaryTag.Medicine,
    [Sleep_Tag_Enum_Enum.Nap]: SleepDiaryTag.Nap,
    [Sleep_Tag_Enum_Enum.Nicotine]: SleepDiaryTag.Nicotine,
    [Sleep_Tag_Enum_Enum.Noise]: SleepDiaryTag.Noise,
    [Sleep_Tag_Enum_Enum.Pain]: SleepDiaryTag.Pain,
    [Sleep_Tag_Enum_Enum.Partner]: SleepDiaryTag.Partner,
    [Sleep_Tag_Enum_Enum.Temperature]: SleepDiaryTag.Temperature,
  };
  return dict[tagEnum];
};
const mapTagToNum = (tag: SleepDiaryTag) => {
  return tags.indexOf(tag);
};
const mapNumToTag = (num: number) => {
  const tag = tags[num];
  if (!tag) {
    return '';
  }
  return getEmojiForSleepDiaryTag(tag);
};
