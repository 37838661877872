import * as React from 'react';
import { TextFieldWrapper, TextFieldWrapperProps } from './text-field-wrapper.component';
import { TextFieldStyled } from './text-field.component.style';

export interface TextFieldProps extends TextFieldWrapperProps {
  disabled?: boolean;
  length?: number;
  // https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill-processing-model

  autoComplete?:
  | 'nope'
  | 'off'
  | 'on'
  | 'name'
  | 'honorific-prefix'
  | 'given-name'
  | 'additional-name'
  | 'family-name'
  | 'honorific-suffix'
  | 'nickname'
  | 'organization-title'
  | 'username'
  | 'new-password'
  | 'current-password'
  | 'organization'
  | 'street-address'
  | 'address-line1'
  | 'address-line2'
  | 'address-line3'
  | 'address-level4'
  | 'address-level3'
  | 'address-level2'
  | 'address-level1'
  | 'country'
  | 'country-name'
  | 'postal-code'
  | 'cc-name'
  | 'cc-given-name'
  | 'cc-additional-name'
  | 'cc-family-name'
  | 'cc-number'
  | 'cc-exp'
  | 'cc-exp-month'
  | 'cc-exp-year'
  | 'cc-csc'
  | 'cc-type'
  | 'transaction-currency'
  | 'transaction-amount'
  | 'language'
  | 'bday'
  | 'bday-day'
  | 'bday-month'
  | 'bday-year'
  | 'sex'
  | 'url'
  | 'photo'
  | 'tel'
  | 'tel-country-code'
  | 'tel-national'
  | 'tel-area-code'
  | 'tel-local'
  | 'tel-local-prefix'
  | 'tel-local-suffix'
  | 'tel-extension'
  | 'email'
  | 'impp';
  id?: string;

  letterCase?: 'default' | 'upper'

  // https://html.spec.whatwg.org/multipage/interaction.html#input-modalities:-the-inputmode-attribute
  inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';
}

export interface TextFieldState {
  value?: string | number | readonly string[];
}

export class TextField extends React.Component<TextFieldProps, TextFieldState> {
  render() {
    const {
      autoComplete,
      onValueChange,
      icon,
      loading,
      disabled,
      onChange,
      value,
      dismissable,
      light,
      initialValue,
      onBlur,
      onFocus,
      invalid,
      maxLength,
      letterCase,
      ...other
    } = this.props;

    return (
      <TextFieldWrapper
        initialValue={initialValue}
        letterCase={letterCase}
        value={value}
        icon={icon}
        loading={loading}
        dismissable={dismissable}
        light={light}
        maxLength={maxLength}
        invalid={invalid}
        onValueChange={onValueChange}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        {(childrenProps) => (
          <TextFieldStyled
            disabled={disabled || loading}
            icon={icon}
            length={maxLength}
            maxLength={maxLength}
            dismissable={dismissable}
            autoComplete={autoComplete}
            value={childrenProps.value}
            light={light}
            ref={childrenProps.ref}
            onChange={childrenProps.onChangeText}
            onInput={childrenProps.onInput}
            onBlur={childrenProps.onBlur}
            onFocus={childrenProps.onFocus}
            invalid={childrenProps.invalid}
            pattern={other.type === 'number' ? '[0-9]*' : other.pattern}
            {...other}
          />
        )}
      </TextFieldWrapper >
    );
  }
}
