/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import { Search } from './search.component';
import { useWorkerizedSearchIndex } from './search.hook';
import { IndexParams } from './search.utils';

export interface FuseSearchProps<D> {
  indexParams: IndexParams<D>;
  onResultChange: (query: string, result: D) => any;
  initialValue?: string;
  label?: string;
  placeholder?: string;
  id?: string;
}

export const FuseSearch: React.FunctionComponent<FuseSearchProps<any>> = (props) => {
  /**
   * Why the "ready" logic? The search component uses a "web worker" which is a
   * feature available only on the browser. Therefore, the
   * "WorkerizedFuseSearch" should not be rendered in the server-side. That's
   * why there's this "ready" logic
   */
  const [ready, setReady] = React.useState(false);
  React.useEffect(() => {
    setReady(true);
  }, []);

  return ready ? <WorkerizedFuseSearch {...props} /> : null;
};

const WorkerizedFuseSearch: React.FunctionComponent<FuseSearchProps<any>> = (props) => {
  const searchIndex = useWorkerizedSearchIndex(props.indexParams);
  return <Search {...props} searchIndex={searchIndex} />;
};
