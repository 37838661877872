/* eslint-disable @typescript-eslint/no-var-requires */
import styled, { css } from 'styled-components';

import { GrayColor, LightColor, Body, Spacing } from '@web/atomic';
import { lightenDarkenColor } from '@global/utils/ui/color-fn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const IconTextButtonStyled = styled.div`
  cursor: pointer;
  & + & {
    margin-top: ${Spacing.Medium};
  }
`;

const buttonStyle = css`
  &:hover {
    transition: all 0.2s ease-in-out;
    fill: ${lightenDarkenColor(GrayColor.White, -20)};
    color: ${lightenDarkenColor(GrayColor.White, -20)};
  }
  &:active {
    fill: ${lightenDarkenColor(GrayColor.White, -40)};
    color: ${lightenDarkenColor(GrayColor.White, -40)};
  }
`;
export const IconTextButtonText = styled(Body)`
  ${buttonStyle}
`;

export const IconTextButtonIcon = styled(FontAwesomeIcon)`
  ${buttonStyle}
`;
