import { isNotNullNorUndefined } from '@global/utils/object/null-or-undefined';
import { FormFieldContext, FormFieldContextState } from '@web/atomic/legacy/obj.form';
import * as React from 'react';
import { SelectTemplateField } from './select-template.component';
import { SelectStyled } from './select.component.style';

export interface SelectProps extends React.InputHTMLAttributes<HTMLSelectElement> {
  disabled?: boolean;
  invalid?: boolean;
  onValueChange?: (value: any) => void;
  initialValue?: any;
}
interface SelectState {
  value?: any;
}

export class SelectField extends React.Component<SelectProps, SelectState> {
  private formFieldConsumer: FormFieldContextState;

  constructor(props) {
    super(props);
    this.state = { value: this.props.initialValue || this.props.value };
  }

  componentDidMount() {
    if (this.isControlled() && this.props.initialValue !== undefined) {
      throw new Error('Use either the initialValue prop, or the value prop, but not both');
    }

    if (this.formFieldConsumer) {
      if (this.isControlled() || this.props.initialValue !== undefined) {
        if (this.formFieldConsumer.value) {
          throw new Error('Please, use either value props in <Select> or <Form.Field> component.');
        }

        this.formFieldConsumer.onValueChange(this.state.value, null);
      }
    }
  }

  componentDidUpdate(prevProps: SelectProps) {
    if (prevProps.value !== this.props.value && this.props.value !== this.state.value) {
      this.setState({ value: this.props.value });

      if (this.props.onValueChange) {
        this.props.onValueChange(this.props.value);
      }

      if (this.formFieldConsumer) {
        this.formFieldConsumer.onValueChange(this.props.value, null);
      }
    }
  }

  render() {
    const { type, onChange, invalid, onValueChange, value, maxLength, ...other } = this.props;
    return (
      <FormFieldContext.Consumer>
        {(formFieldConsumer: FormFieldContextState) => {
          this.formFieldConsumer = formFieldConsumer;
          const val = formFieldConsumer && isNotNullNorUndefined(formFieldConsumer.value) ? formFieldConsumer.value : this.state.value;
          return (
            <SelectTemplateField invalid={this.props.invalid} maxLength={maxLength}>
              <SelectStyled onFocus={this.handleFocus} onChange={this.handleSelectChange} value={val} {...other} />
            </SelectTemplateField>
          );
        }}
      </FormFieldContext.Consumer>
    );
  }

  private handleFocus = (_event) => {
    if (this.formFieldConsumer) {
      this.formFieldConsumer.onFocusChange(true);
    }
  };

  private handleSelectChange = (event: React.FormEvent<HTMLSelectElement>) => {
    const value: string = event.currentTarget.value;

    if (this.props.onValueChange) {
      this.props.onValueChange(value);
    }

    if (this.isControlled()) {
      return;
    }

    if (this.formFieldConsumer) {
      this.formFieldConsumer.onValueChange(value, { touched: true });
    } else {
      this.setState({ value });
    }
  };

  private isControlled = () => this.props.value !== undefined;
}
