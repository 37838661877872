import flashDispatcherService from '@web/atomic/legacy/obj.flash-wrapper/flash-dispatcher.service';
import { HttpDataSource } from '@web/data/vigilantes.datasource';
import { logError } from '@web/utils/error';
import * as React from 'react';
import { isNullOrUndefined } from '@global/utils/object/null-or-undefined';
import { NativeHelper } from './native.helper';
import { JsonObject } from 'type-fest';

export interface UsePostInput<D = JsonObject, E = Error> {
  onSuccess?: (data: D) => void;
  onError?: (error: E) => void;
  url: string;
}

export interface UsePostState<D = JsonObject> {
  data: D;
  loading: boolean;
  error: D;
}

interface UsePostAction<D> {
  type: 'loading' | 'error' | 'done';
  data?: D;
}

type UsePostReducer<D> = (state: UsePostState<D>, action: UsePostAction<D>) => UsePostState<D>;

function postReducer<D = JsonObject>(state: UsePostState<D>, action: UsePostAction<D>): UsePostState<D> {
  switch (action.type) {
    case 'loading':
      return {
        ...state,
        loading: true,
      };
    case 'error':
      return {
        ...state,
        error: action.data,
        loading: false,
      };
    case 'done':
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    default:
      throw new Error();
  }
}

export function usePost<Req = JsonObject, Res = JsonObject, RawResponse = JsonObject>(
  input: UsePostInput<Res>,
  datasource: HttpDataSource
): [(data?: Req, mapper?: (response: RawResponse) => Res) => Promise<Res>, UsePostState<Res>] {
  const [state, dispatch] = React.useReducer<UsePostReducer<Res>>(postReducer, { data: null, loading: false, error: null });
  const post = React.useCallback(
    async (data?: Req, mapper?: (response: RawResponse) => Res) => {
      try {
        dispatch({ type: 'loading' });
        const rawData = await datasource.post(input.url, data);
        const mappedData = mapper && mapper(rawData);
        const finalData: Res = isNullOrUndefined(mappedData) ? rawData : mappedData;

        dispatch({ type: 'done', data: finalData });
        if (input.onSuccess) {
          input.onSuccess(finalData);
        }

        return finalData;
      } catch (error) {
        NativeHelper.postMessage({ type: 'error', data: { error } });
        dispatch({ type: 'error', data: error });
        if (input.onError) {
          input.onError(error);
        } else {
          logError(error);
          flashDispatcherService.dispatchMessage('Ocorreu um erro. Por favor, tente novamente em 1 minuto.', 'alert');
        }
        throw error;
      }
    },
    [dispatch, input.onError]
  );

  return [post, state];
}
