import * as React from 'react';
import { IconTextButtonStyled, IconTextButtonText, IconTextButtonIcon } from './icon-text-button.style';
import { Hbox, GrayColor, LightColor, SemiBold } from '@web/atomic';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { isExternalUrl } from '@web/utils/url';

interface IconTextButtonProps {
  text: string;
  onClick?: () => void;
  href?: string;
  faIcon: IconDefinition;
}

const IconTextButton: React.FunctionComponent<IconTextButtonProps> = (props) => {
  const innerContent = (
    <Hbox hAlign="flex-start">
      <Hbox.Item wrap vAlign={'center'}>
        <IconTextButtonIcon icon={props.faIcon} size={'sm'} color={GrayColor.White} />
      </Hbox.Item>
      <Hbox.Separator />
      <Hbox.Item wrap vAlign={'center'}>
        <IconTextButtonText kind={'light'}>
          <SemiBold>{props.text}</SemiBold>
        </IconTextButtonText>
      </Hbox.Item>
    </Hbox>
  );
  return (
    <IconTextButtonStyled onClick={props.onClick}>
      {props.href ? (
        <a href={props.href} target={isExternalUrl(props.href) ? '_blank' : undefined} rel="noreferrer">
          {innerContent}
        </a>
      ) : (
        innerContent
      )}
    </IconTextButtonStyled>
  );
};

export default IconTextButton;
