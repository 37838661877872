/* eslint-disable jsx-a11y/no-autofocus */

import { isNullOrUndefined } from '@global/utils/object/null-or-undefined';
import { FormFieldContext, FormFieldContextState } from '@web/atomic/legacy/obj.form';
import * as React from 'react';
import Select, { ActionMeta, Props as ReactSelectProps } from 'react-select';
import { AsyncCreatableProps } from 'react-select/async-creatable';
import { SelectProps } from './enhanced-select.utils';

interface EnhancedSelectImplementationProps extends SelectProps {
  selectComponent: React.ComponentType<
    ReactSelectProps<any> | AsyncCreatableProps<any, boolean, any> | { ref: React.Ref<Select>; disabled: boolean; invalid: boolean }
  >;
}

export class EnhancedSelectImplementation extends React.Component<EnhancedSelectImplementationProps, undefined> {
  static defaultProps = {
    isSearchable: true,
  };

  private formFieldConsumer: FormFieldContextState;
  private selectRef: React.RefObject<Select> = React.createRef();

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <FormFieldContext.Consumer>
        {(formFieldConsumer: FormFieldContextState) => {
          this.formFieldConsumer = formFieldConsumer;
          const SelectComponent = this.props.selectComponent;

          const invalid = this.formFieldConsumer && !!this.formFieldConsumer.errors.length;
          return (
            /** https://react-select.com/props */
            <SelectComponent
              isMulti={this.props.isMulti}
              isDisabled={this.props.isDisabled}
              ref={this.selectRef}
              openMenuOnFocus={true}
              autoFocus={this.props.autoFocus}
              // for styled-components
              disabled={this.props.isDisabled}
              isLoading={this.props.isLoading}
              invalid={invalid}
              // this component doesn't accept 'null' as a value
              options={this.props.options === null ? undefined : this.props.options}
              onChange={this.handleChange}
              value={this.getValue()}
              cacheOptions={!this.props.disableCache && !isNullOrUndefined(this.props.loadOptions)}
              loadOptions={this.props.loadOptions}
              isSearchable={this.props.isSearchable}
              components={this.props.components}
              loadingMessage={() => 'Carregando...'}
              formatCreateLabel={(userInput) => `Criar "${userInput}"`}
              noOptionsMessage={({ inputValue }) => {
                return inputValue.length > 0 ? 'Nenhuma opção foi encontrada.' : 'Digite algo para buscar opções.';
              }}
              placeholder={this.props.placeholder || ''}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
            />
          );
        }}
      </FormFieldContext.Consumer>
    );
  }

  focus() {
    (this.selectRef.current as any).focus();
  }

  getValue = () => {
    if (
      isNullOrUndefined(this.formFieldConsumer) ||
      isNullOrUndefined(this.formFieldConsumer.value) ||
      this.formFieldConsumer.value.length === 0
    ) {
      return undefined;
    }

    // it seems that Select does some optimizations to avoid re-rendering. To
    // force a re-render here, the value is copied to make sure the value is
    // changed and then the SelectComponent is re-rendered
    return this.formFieldConsumer.value.slice ? this.formFieldConsumer.value.slice() : this.formFieldConsumer.value;
  };

  private handleFocus = (event) => {
    if (this.props.onFocus) {
      this.props.onFocus(event);
    }
    this.formFieldConsumer.onFocusChange(true);
  };

  private handleBlur = (event) => {
    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
    this.formFieldConsumer.onFocusChange(false);
  };

  private handleChange = (obj: any, action: ActionMeta<unknown>) => {
    if (this.props.onChange) {
      this.props.onChange(obj);
    }

    if (this.formFieldConsumer) {
      if (action.action === 'remove-value' || action.action === 'pop-value') {
        this.formFieldConsumer.onValueChange([action.removedValue], false);
      } else if (action.action === 'clear') {
        this.formFieldConsumer.onClear();
      } else if (action.action === 'create-option') {
        const option = this.props.isMulti ? [obj[obj.length - 1]] : obj;
        this.formFieldConsumer.onValueChange(option, true);
      } else if (action.action === 'select-option') {
        const option = this.props.isMulti ? [action.option] : obj;
        this.formFieldConsumer.onValueChange(option, true);
      }
    }
  };
}
