import * as React from 'react';

const SvgIcLogo = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg viewBox="0 0 615 415" {...props}>
    <g transform="translate(-349 -351)" fill="none" fillRule="evenodd">
      <g>
        <path
          d="M663.853 726.285H648.88a34.53 34.53 0 0 1-30.794-16.933 34.51 34.51 0 0 1 0-35.136 34.53 34.53 0 0 1 30.794-16.933h14.974a34.53 34.53 0 0 1 30.795 16.933 34.51 34.51 0 0 1 0 35.136 34.53 34.53 0 0 1-30.795 16.933"
          fill={props.color ? props.color : '#FFF'}
          fillRule="nonzero"
        />
        <path
          d="M607.968 736.61C615.355 750.243 634.24 760 656.5 760c22.26 0 40.978-9.623 48.398-23.156"
          stroke={props.color ? props.color : '#FFF'}
          strokeWidth={11.16}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M963.733 463.445c.013 45.21-27.218 85.977-68.995 103.288-41.777 17.31-89.87 7.756-121.853-24.208-31.983-31.964-41.554-80.042-24.251-121.812 17.303-41.77 58.072-69.006 103.296-69.006 61.726.018 111.766 50.029 111.803 111.738zm-65.559 91.194c26.553-10.995 43.866-36.899 43.866-65.632 0-39.234-31.814-71.04-71.059-71.04-28.74 0-54.651 17.309-65.65 43.855-10.999 26.545-4.919 57.1 15.404 77.418 20.323 20.317 50.886 26.395 77.44 15.4zM349 463.445c.018-61.704 50.049-111.72 111.77-111.738 45.226-.014 86.006 27.213 103.32 68.983 17.313 41.77 7.75 89.852-24.23 121.823-31.98 31.971-80.077 41.532-121.858 24.223-41.78-17.309-69.016-58.077-69.002-103.29zm21.659 25.562c0 39.234 31.814 71.04 71.059 71.04s71.06-31.806 71.06-71.04c0-39.234-31.815-71.04-71.06-71.04-39.245 0-71.06 31.806-71.06 71.04z"
          fill={props.color ? props.color : '#FFF'}
          fillRule="nonzero"
        />
        <path
          d="M929.64 566.462c0-92.057-122.331-166.705-273.274-166.705-150.942 0-273.31 74.648-273.31 166.705v6.282c-.327 51.978 31.393 98.792 79.798 117.767 48.405 18.974 103.5 6.19 138.597-32.158A76.117 76.117 0 0 1 656.729 635a76.116 76.116 0 0 1 55.02 23.954c35.255 37.98 90.215 50.427 138.4 31.346 48.186-19.081 79.713-65.777 79.39-117.589v-3.609-2.606"
          fill={props.color ? props.color : '#FFF'}
          fillRule="nonzero"
        />
      </g>
    </g>
  </svg>
);

export default SvgIcLogo;
