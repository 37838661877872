import { DoctorReportQueryQuery } from '@global/utils/remote-graphql-types';
import { Col, FaIcon, Grid, H1, Row, Separator } from '@web/atomic';
import { Placeholder } from '@web/atomic/legacy/mol.placeholder';
import { ShimmerAspectRatioBoxStyled, TextShimmerBoxStyled } from '@web/atomic/legacy/mol.shimmer/shimmer.component.style';
import { LoadingState } from '@web/atomic/legacy/obj.loading-state';
import { useDocumentTitle } from '@web/atomic/obj.custom-hooks/document-title.hook';
import { useQueryCustom } from '@web/data/use-query-custom.hook';
import * as React from 'react';
import { DoctorReport } from './components/doctor-report.component';
import { DoctorReportQuery } from './data/graphql/doctor-report.query.hasura';

interface DoctorReportPageProps {
  userId: string;
  printMode?: boolean;
  /**
   * An array of react elements to be shown on the "action area" of the report
   */
  actionButtons?: JSX.Element[];

  onDataLoad?: () => void;
}

export const DoctorReportPage: React.FunctionComponent<DoctorReportPageProps> = (props) => {
  return (
    <DoctorReportPageContainer userId={props.userId}>
      {(data) => (
        <DoctorReport
          data={data}
          printMode={props.printMode}
          onUserImageLoad={props.onDataLoad}
          actionButtons={props.actionButtons || []}
        />
      )}
    </DoctorReportPageContainer>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////

interface DoctorReportPageContainerProps {
  userId: string;
  children: (data: DoctorReportQueryQuery) => JSX.Element;
}

export const DoctorReportPageContainer: React.FunctionComponent<DoctorReportPageContainerProps> = (props) => {
  const { data, error, loading } = useQueryCustom(DoctorReportQuery, {
    variables: { id: props.userId },
    fetchPolicy: 'cache-first',
  });
  const user = data && data.user && data.user[0];
  useDocumentTitle(user && `Relatório de ${user.first_name}`);
  return (
    <>
      <Grid fluid>
        <LoadingState loading={loading} error={!!error} data={!!user}>
          {/* TODO: UI - ajustar loading */}
          <LoadingState.Shimmer>
            <Row mt mb>
              <Col xs={2}>
                <ShimmerAspectRatioBoxStyled ratioPercentage={100} />
              </Col>
              <Col xs={10}>
                <H1>
                  Carregando relatório <TextShimmerBoxStyled height={'22px'} />
                </H1>
                <Separator />
              </Col>
            </Row>
          </LoadingState.Shimmer>
          <LoadingState.Error>
            <Placeholder
              icon={<FaIcon.FlashAlert size="9x" />}
              title={'Erro ao pegar seus dados'}
              description="Tente novamente mais tarde"
            />
          </LoadingState.Error>
        </LoadingState>
      </Grid>
      {data && props.children(data)}
    </>
  );
};
