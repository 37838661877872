import * as React from 'react';
import { BackgroundStyled } from './background.component.style';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface BackgroundProps {}

export class Background extends React.Component<BackgroundProps, any> {
  constructor(props: BackgroundProps) {
    super(props);
  }

  render() {
    return <BackgroundStyled {...this.props}>{this.props.children}</BackgroundStyled>;
  }
}
