import { isNullOrUndefined } from '../object/null-or-undefined';

/**
 * Map an hour to minutes relative to 00:00
 * @param hour expected to be in format "hh:mm"
 */
export const toMinutes = (hour: string) => {
  // tslint:disable: curly
  if (isNullOrUndefined(hour) || isNullOrUndefined(hour.split)) return null;
  const [h, m] = hour.split(':').map((comp) => parseInt(comp));
  if (isNullOrUndefined(h) || isNullOrUndefined(m)) return null;
  return (h % 24) * 60 + m;
};
