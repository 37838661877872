import * as React from 'react';
import { Col as FlexCol, Grid as FlexGrid, Row as FlexRow } from 'react-styled-flexboxgrid';
import { Spacing } from '@web/atomic/legacy/obj.constants';
import styled, { css } from 'styled-components';

export interface RowProps {
  mb?: boolean;
  mt?: boolean;
  noGutter?: boolean;
  expanded?: boolean;
  hideOnPrint?: boolean;
}

export interface ColProps {
  gutter?: boolean;
  mb?: boolean;
  hideOnPrint?: boolean;
}

export const GridSettings = {
  flexboxgrid: {
    gridSize: 12,
    mediaQuery: 'all',
    // Defaults
    gutterWidth: 1, // rem
    outerMargin: 1.5, // rem
    container: {
      sm: 32, // rem
      md: 61, // rem
      lg: 76, // rem
    },
    breakpoints: {
      xs: 0,
      sm: 32,
      md: 64,
      lg: 76,
    },
  },
};
export const rowMargin = GridSettings.flexboxgrid.outerMargin + GridSettings.flexboxgrid.gutterWidth / 2;

export const mobileGutterStyle = css`
  padding: 0 ${rowMargin}rem;
`;

const GridStyled = styled(FlexGrid)<{ position }>`
  position: ${(props) => props.position || 'static'};

  @media all and (max-width: 32em) {
    padding-left: ${Spacing.Medium};
    padding-right: ${Spacing.Medium};
  }
`;

const RowStyled = styled(FlexRow)<RowProps>`
  margin-bottom: ${(props) => (props.mb ? Spacing.Medium : '0px')};
  margin-top: ${(props) => (props.mt ? Spacing.Medium : '0px')};

  ${(props) => {
    if (props.expanded) {
      return `height: 100%;
              `;
    }
    return '';
  }}

  ${(props) => {
    if (props.noGutter) {
      return `overflow: hidden;
              margin-left: -${rowMargin}rem;
              margin-right: -${rowMargin}rem;
              `;
    }
    return '';
  }}

  @media print {
    ${(props) => {
      if (props.hideOnPrint) {
        return `display: none`;
      }
      return '';
    }}
  }
`;

const ColStyled = styled(FlexCol)<ColProps>`
  ${(props) => {
    if (props.gutter) {
      return mobileGutterStyle;
    }
    return '';
  }}
  margin-bottom: ${(props) => (props.mb ? Spacing.Medium : '0px')};

  @media print {
    ${(props) => {
      if (props.hideOnPrint) {
        return `display: none`;
      }
      return '';
    }}
  }
`;

export const Grid = (props) => <GridStyled {...props} />;
export const Col = (props) => <ColStyled {...props} />;
export const Row = (props) => <RowStyled {...props} />;
