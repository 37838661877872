import { getStorage, StorageType } from './storage/storage.provider';

const THEME_SELECTION_KEY = 'ThemeSelectionKey';

type ThemName = 'regular' | 'dark';

class ThemeDataSource {
  private localStorage = getStorage(StorageType.Local);

  get themeName(): ThemName {
    return this.localStorage.get(THEME_SELECTION_KEY) || null;
  }
  set themeName(value: ThemName) {
    this.localStorage.put(THEME_SELECTION_KEY, value);
  }
}

export default new ThemeDataSource();
