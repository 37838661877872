import { faCircle, faDotCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputValue } from '@web/atomic/legacy/atm.typography';
import { GrayColor, LightColor, Spacing } from '@web/atomic/legacy/obj.constants';
import { highlightStyle } from '@web/atomic/legacy/obj.mixin';
import { BaseTheme } from '@web/atomic/obj.theme';
import styled from 'styled-components';
import { fadeIn } from '../obj.animation/animation.component.style';

const radioSize = 24;

interface RadioFieldStyledProps {
  expanded: boolean;
  mb?: boolean;
  ml?: boolean;
}

export const RadioFieldStyled = styled.span`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: top;
  cursor: pointer;
  margin-left: ${(props: RadioFieldStyledProps) => (props.ml ? Spacing.Medium : 0)};
  ${(props: RadioFieldStyledProps) => (props.expanded ? 'flex: 1' : undefined)};

  & ~ & {
    margin-top: ${Spacing.Medium};
  }
`;

export const InvisibleRadioClickableArea = styled.div`
  width: 48px;
  height: 48px;
  top: -14px;
  left: -14px;
  position: absolute;
`;

export const RadioFieldLabelStyled = styled(InputValue)<{ padding; expanded }>`
  line-height: ${radioSize}px !important;
  padding-left: ${(props) => (props.padding ? Spacing.Small : 0)};
  margin-top: 2px;
  cursor: pointer;
  ${(props) => (props.expanded ? 'flex: 1' : undefined)};
`;

export const RadioFieldContentStyled = styled.div`
  margin-left: ${Spacing.XLarge};
  ${highlightStyle};
`;

const RadioBulletStyled = styled(FontAwesomeIcon).attrs({ size: 'lg' })``;

export const RadioCheckedStyled = styled(RadioBulletStyled).attrs({ icon: faDotCircle })`
  margin-top: 2px;
  color: ${({ theme }) => ((theme as BaseTheme).name === 'regular' ? LightColor.Primary : GrayColor.White)};
  animation: ${fadeIn} 0.5s linear forwards;
`;

export const RadioUncheckedStyled = styled(RadioBulletStyled).attrs({ icon: faCircle })`
  margin-top: 2px;
  color: ${({ theme }) => ((theme as BaseTheme).name === 'regular' ? GrayColor.GrayDark : GrayColor.White)};
`;

export const RadioFieldInputStyled = styled.input`
  display: none;
`;
