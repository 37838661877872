import { BodySmall, BrandColor, FontFamily, FontSize, FontWeight, GrayColor } from '@web/atomic';
import styled, { css, keyframes } from 'styled-components';

const barHeight = '16px';
const radius = parseInt(barHeight) / 2;

export const ProgressGaugeWrapper = styled.div`
  position: relative;
`;

export interface ProgressGaugeBackgroundProps {
  percentage: number;
}
export const ProgressGaugeBackground = styled.div<ProgressGaugeBackgroundProps>`
  height: ${barHeight};
  background: ${GrayColor.GrayXXXLight};
  width: ${(props) => props.percentage}%;
  border-radius: ${radius}px;
`;
const commonBorderStyle = css`
  position: absolute;
  top: ${radius}px;
  height: calc(100% - ${barHeight});
`;
export const ProgressGaugeBackgroundBorder = styled.div<ProgressGaugeBackgroundProps>`
  ${commonBorderStyle}
  right: 0;
  width: ${(props) => 100 - props.percentage}%;
  border-right: 2px dashed ${GrayColor.GrayLight};
  border-top: 2px dashed ${GrayColor.GrayLight};
  border-radius: 0 ${radius}px 0 0;
`;
export const ProgressGaugeBackgroundText = styled(BodySmall)``;

export interface ProgressGaugeForegroundProps {
  percentage: number;
  kind: ProgressGaugeKind;
}

const width = (props: { percentage: number }) => keyframes`
  0% {
    width: 0;
  };
  100% {
    width: ${props.percentage}%;
  };
  `;
export const ProgressGaugeForeground = styled.div<ProgressGaugeForegroundProps>`
  position: absolute;
  top: 0;
  z-index: 2;
  height: ${barHeight};
  background: ${(props) => getColor(props.kind)};
  animation: ${(props) => width(props)} 1s ease-in-out forwards;
  border-radius: ${radius}px;
`;
export const ProgressGaugeForegroundBorder = styled.div<{ kind: ProgressGaugeKind }>`
  ${commonBorderStyle}
  left: 0;
  border-left: 2px dashed ${(props) => getColor(props.kind)};
`;

interface ProgressGaugeForegroundTextProps {
  kind: ProgressGaugeKind;
}
export const ProgressGaugeForegroundText = styled.label<ProgressGaugeForegroundTextProps>`
  color: ${(props: ProgressGaugeForegroundTextProps) => getColor(props.kind)};
  font-size: ${FontSize.XXXSmall};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
  margin: 0;
`;

export type ProgressGaugeKind = 'positive' | 'negative' | 'neutral';

const getColor = (kind: ProgressGaugeKind) => {
  switch (kind) {
    case 'positive':
      return BrandColor.Green;
    case 'negative':
      return BrandColor.Red;
    case 'neutral':
    default:
      return BrandColor.PortGore;
  }
};
