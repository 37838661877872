/**
 * Converts a number to its percentage in a given scale
 * @param x value to be converted
 * @param max value that represents 100%
 * @param min value that represents 0%
 */
export const toPercentage = (x: number, max: number, min: number) => {
  return (100 * (x - min)) / (max - min);
};

/**
 * converts a grade to its percentage representation (this is useful in this
 * app context given that a lot of grades are in the 1-3 scale, e.g humor,
 * concentration etc)
 * @param x value to be transformed to percentage
 */
export const toPercentageOn1To3Scale = (x: number) => toPercentage(x, 3, 1);
