export enum BrandColor {
  Red = '#E72400',
  Yellow = '#f1c40f',
  MacaroniAndCheese = '#FFC774',
  Green = '#00AF8E',
  Cream = '#F9E3C9',
  Brown = '#751E00',
  BackgroundGray = '#EAECF3',

  // Blue tones
  Woodsmoke = '#14151A',
  CongressBlue = '#004680',
  Chambray = '#354991',
  Ebony = '#0b1320',
  EbonyClay = '#2B2F42',
  PortGore = '#1D1841',
  Hoki = '#5f819f',
  Porcelain = '#E6E8EA',
  Scampi = '#6772B2',
  Charade = '#1F212C',
}

export class GrayColor {
  public static readonly Black = '#000000';
  public static readonly White = '#FFFFFF';
  public static readonly Neutral = '#F8F8F8';

  public static readonly GrayXXXLight = '#F5F5F5';
  public static readonly GrayXXLight = '#F3F5FD';
  public static readonly GrayXLight = '#BDBDBD';
  public static readonly GrayLight = '#AAAAAA';
  public static readonly Gray = '#757575';
  public static readonly GrayDark = '#525252';
  public static readonly GrayXDark = '#333333';
  public static readonly GrayXXDark = '#121212';
}

// We used class rather than enums since enums don't support computed values
export class LightColor {
  public static readonly Primary = BrandColor.PortGore;
  public static readonly Secondary = BrandColor.Chambray;
  public static readonly CallToAction = BrandColor.Cream;
  public static readonly Accessory = BrandColor.Brown;

  public static readonly Background = GrayColor.White;
  public static readonly BackgroundGrayHealthCarer = BrandColor.BackgroundGray;
  public static readonly Alert = BrandColor.Red;
  public static readonly Warning = BrandColor.Yellow;
  public static readonly Success = BrandColor.Green;

  public static readonly HighlightLight = 'rgba(255, 255, 255, 0.1)';
}

export class DarkColor {
  public static readonly Primary = BrandColor.Chambray;
  public static readonly Secondary = GrayColor.White;
  public static readonly CallToAction = BrandColor.Cream;
  public static readonly Accessory = BrandColor.Brown;

  public static readonly Background = BrandColor.Woodsmoke;
  public static readonly SecondaryBackground = BrandColor.Woodsmoke;

  public static readonly BackgroundGrayHealthCarer = BrandColor.BackgroundGray;
  public static readonly Alert = BrandColor.Red;
  public static readonly Warning = BrandColor.Yellow;
  public static readonly Success = BrandColor.Green;

  public static readonly HighlightLight = 'rgba(255, 255, 255, 0.1)';
}

/**
 * "L" stands for "level" (so L1 is "level 1")
 */
export enum Alpha {
  L1 = 0.05,
  L2 = 0.1,
  L3 = 0.3,
  L4 = 0.6,
  L5 = 0.9,
}
