import { FlashMessageType } from '@web/atomic/legacy/mol.flash-message';

export interface FlashMessageDataAction {
  text: string;
  onClick: () => void;
}

export interface FlashMessageData {
  message: string;
  type: FlashMessageType;
  time: Date;
  action?: FlashMessageDataAction;
}

export interface FlashDispatcherListener {
  onReceiveMessage: (data: FlashMessageData) => void;
}

/**
 * This class is used to dispatch a message to notify its listener that something
 * wants to show a flash message.
 * The dispatched messaged is caught by FlashWrapper (@see FlashWrapper)
 */
class FlashDispatcherService {
  private listener: FlashDispatcherListener;

  setListener(listener: FlashDispatcherListener) {
    this.listener = listener;
  }

  dispatchMessage(message: string, type: FlashMessageType, action?: FlashMessageDataAction) {
    if (this.listener) {
      this.listener.onReceiveMessage({ message, type, time: new Date(), action });
    }
  }
}

export default new FlashDispatcherService();
