/**
 * https://refactoring.guru/pt-br/design-patterns/observer
 * example of implementation
 * 
```
export interface SessionObserver {
  onSessionStart: (sessionKey: keyof User) => void;
}

@Service()
export class SessionSubjectService extends BaseSubjectService<SessionObserver> {
  notifyAllSessionStart = (sessionKey: keyof User) => {
    this.observerCollection.forEach(observer => {
      observer.onSessionEnd(sessionKey);
    });
  };
}
```
 * 
 */
export class BaseSubjectService<T> {
  protected observerCollection = new Set<T>();
  subscribeObserver = (observer: T) => {
    this.observerCollection.add(observer);
  };
  unsubscribeObserver = (observer: T) => {
    this.observerCollection.delete(observer);
  };
}
