import * as React from 'react';

import { FormFieldContext, FormFieldContextState } from '@web/atomic/legacy/obj.form';
import { TextAreaStyled } from './text-area-field.component.style';
import { hasWindow } from '@web/utils/platform';

export interface TextAreaFieldProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  onValueChange?: (value: string | number | readonly string[]) => void;
  initialValue?: string | number | readonly string[];
}

export interface TextAreaFieldState {
  value?: string | number | readonly string[];
}

export class TextAreaField extends React.Component<TextAreaFieldProps, TextAreaFieldState> {
  private formFieldConsumer: FormFieldContextState;
  private textarea: HTMLTextAreaElement;

  constructor(props: TextAreaFieldProps) {
    super(props);
    this.state = {
      value: props.initialValue || props.value,
    };
  }

  componentDidMount() {
    if (this.isControlled() && this.props.initialValue !== undefined) {
      throw new Error('Use either the initialValue prop, or the value prop, but not both');
    }

    if (this.formFieldConsumer) {
      if (this.isControlled() || this.props.initialValue !== undefined) {
        if (this.formFieldConsumer.value) {
          throw new Error('Please, use either value props in <TextField> or <Form.Field> component.');
        }

        this.formFieldConsumer.onValueChange(this.state.value, null);
      }
    }
    if (this.textarea) {
      this.setState({});
    }
  }

  componentDidUpdate(prevProps: TextAreaFieldProps) {
    if (prevProps.value !== this.props.value && this.props.value !== this.state.value) {
      if (this.props.onValueChange) {
        this.props.onValueChange(this.props.value);
      }

      if (this.formFieldConsumer) {
        this.formFieldConsumer.onValueChange(this.props.value, null);
      }

      this.setState({ value: this.props.value });
    }
  }

  render() {
    const { onValueChange, onChange, value, initialValue, ...other } = this.props;

    return (
      <FormFieldContext.Consumer>
        {(formFieldConsumer: FormFieldContextState) => {
          this.formFieldConsumer = formFieldConsumer;
          const val = formFieldConsumer && formFieldConsumer.value ? formFieldConsumer.value : this.state.value;
          return (
            <TextAreaStyled
              value={val || ''}
              onChange={this.handleTextChange}
              ref={(ref) => (this.textarea = ref)}
              rows={this.computeRows()}
              invalid={formFieldConsumer && formFieldConsumer.errors.length > 0}
              {...other}
            />
          );
        }}
      </FormFieldContext.Consumer>
    );
  }

  private handleTextChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
    const value: string = event.currentTarget.value;

    if (this.formFieldConsumer && value === this.formFieldConsumer.value) {
      return;
    }

    if (this.props.onValueChange) {
      this.props.onValueChange(value);
    }

    if (this.isControlled()) {
      return;
    }

    if (this.formFieldConsumer) {
      this.formFieldConsumer.onValueChange(value, null);
      return;
    }

    this.setState({ value });
  };

  private computeRows() {
    if (!hasWindow() || !this.textarea) {
      return 1;
    }
    // https://stackoverflow.com/a/2036424/3670829
    const style = window.getComputedStyle ? window.getComputedStyle(this.textarea) : { lineHeight: '' };
    const lineHeight = parseInt(style.lineHeight, 10);
    const scrollHeight = this.textarea.scrollHeight;
    return scrollHeight / lineHeight;
  }

  private isControlled = () => this.props.value !== undefined;
}
