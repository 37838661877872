import { getEmojiAndNameForSleepDiaryTag, getEmojiForSleepDiaryTag, SleepDiaryTag } from '@global/utils/domain/entities';
import { parseToDate } from '@global/utils/date/parse-to-date';
import { parseDateToShortString } from '@global/utils/date/parse-to-string';
import { AppThemeContext, BodySmall, BrandColor, GrayColor } from '@web/atomic';
import React, { useContext } from 'react';
import { CartesianGrid, Legend, ResponsiveContainer, Scatter, ScatterChart, XAxis, YAxis } from 'recharts';
import { getCycleReferenceLine } from './cycle-reference.components';
import { ChartWrapperStyled } from './graph.styled';
import { SleepDiaryGraphProps } from './utils';

type ISleepTagsGraphProps = SleepDiaryGraphProps;

enum TagType {
  Internal,
  External,
}

export const SleepTagsGraph: React.FunctionComponent<ISleepTagsGraphProps> = (props) => {
  const { theme } = useContext(AppThemeContext);

  const internalData = [];
  const externalData = [];
  const tagsLabel = 'tags';
  if (!props.sleepDiaryData) {
    return null;
  }
  props.sleepDiaryData.forEach((curr) => {
    if (!curr.tags || !curr.tags.length) {
      internalData.push({ name: parseDateToShortString(parseToDate(curr.date)) });
      return;
    }
    let added = false;
    curr.tags.forEach((tag) => {
      const chartTag = tags.find((t) => t.key === (tag.sleep_tag as any));
      if (!chartTag) {
        return;
      }
      const response = {
        name: parseDateToShortString(parseToDate(curr.date)),
      };
      response[tagsLabel] = mapTagToNum(chartTag);
      if (chartTag.type === TagType.Internal) {
        internalData.push(response);
      } else {
        externalData.push(response);
        // WHY? internalData should have all possible dates to be used as a base
        // for axis X
        internalData.push({ name: parseDateToShortString(parseToDate(curr.date)) });
      }
      added = true;
    });
    if (!added) {
      internalData.push({ name: parseDateToShortString(parseToDate(curr.date)) });
    }
  });

  // BUSINESS-RULE: the first cycle actually starts one day before the first diary
  if (props.cycles && props.cycles[0]) {
    internalData.unshift({ name: parseDateToShortString(props.cycles[0].startDate) });
  }

  return (
    <>
      <ChartWrapperStyled height={props.height}>
        <ResponsiveContainer>
          <ScatterChart
            height={props.height}
            width={props.width}
            // POG-ALERT: this margin is a hack to remove space before/after Y-Axis
            margin={{ top: 0, right: 35, bottom: 0, left: -35 }}
          >
            <CartesianGrid stroke={theme.name === 'dark' ? GrayColor.GrayDark : GrayColor.GrayXXXLight} />
            <XAxis
              stroke={theme.name === 'dark' ? GrayColor.GrayXLight : GrayColor.Gray}
              allowDuplicatedCategory={false}
              dataKey="name"
              label={{ position: 'insideBottomRight', offset: 0 }}
            />
            <Legend verticalAlign="top" wrapperStyle={{ left: 0, top: -30 }} />
            <YAxis
              stroke={theme.name === 'dark' ? GrayColor.GrayXLight : GrayColor.Gray}
              dataKey={tagsLabel}
              ticks={tags.map(mapTagToNum)}
              domain={[0, tags.length]}
              tickFormatter={mapNumToTag}
              padding={{ bottom: 20, top: 0 }}
            />
            <Scatter
              isAnimationActive={!props.removeAnimation}
              name="Interruptores do sono internos"
              data={internalData}
              fill={BrandColor.Hoki}
            />
            <Scatter
              isAnimationActive={!props.removeAnimation}
              name="Interruptores do sono externos"
              data={externalData}
              fill={BrandColor.Yellow}
            />
            {getCycleReferenceLine(props)}
          </ScatterChart>
        </ResponsiveContainer>
      </ChartWrapperStyled>
      <BodySmall>
        <strong>Externos:</strong> {tagsGroupedByType.external.map((tag) => getEmojiAndNameForSleepDiaryTag(tag)).join('; ')} |{' '}
        <strong>Internos:</strong> {tagsGroupedByType.internal.map((tag) => getEmojiAndNameForSleepDiaryTag(tag)).join('; ')}
      </BodySmall>
    </>
  );
};

interface ChartTag {
  key: SleepDiaryTag;
  type: TagType;
}
const tags = [
  { key: SleepDiaryTag.Noise, type: TagType.External },
  { key: SleepDiaryTag.Light, type: TagType.External },
  { key: SleepDiaryTag.Partner, type: TagType.External },
  { key: SleepDiaryTag.Temperature, type: TagType.External },
  { key: SleepDiaryTag.Dream, type: TagType.Internal },
  { key: SleepDiaryTag.Pain, type: TagType.Internal },
  { key: SleepDiaryTag.Bathroom, type: TagType.Internal },
];
const tagsGroupedByType = tags.reduce(
  (acc, curr) => {
    if (curr.type === TagType.Internal) {
      acc.internal.push(curr.key);
    } else {
      acc.external.push(curr.key);
    }
    return acc;
  },
  { internal: [], external: [] }
);
const mapTagToNum = (tag: ChartTag) => {
  return tags.findIndex((t) => t.key === tag.key);
};
const mapNumToTag = (num: number) => {
  const tag = tags[num];
  if (!tag) {
    return '';
  }
  return getEmojiForSleepDiaryTag(tag.key);
};
