import {
  getEmojiAndNameForSleepDiaryTag,
  getEmojiForSleepDiaryGrade,
  getEmojiForSleepDiaryTag,
  getEmojiForSleepDiaryTechnique,
  getNameForSleepDiaryTag,
  getNameForSleepDiaryTechnique,
  getTextForSleepDiaryGrade,
  SleepDiaryGrade,
  SleepDiaryTag,
  SleepDiaryTechnique,
} from '@global/utils/domain/entities';
import { getDiaryTags } from '@global/utils/domain/sleep-diary';
import { DoctorReportQueryQuery } from '@global/utils/remote-graphql-types';
import { TD, TdProps } from '@web/atomic/legacy/mol.table';
import { formatSnore } from '@global/utils/domain/snore';
import * as React from 'react';

type ISleepDiaryTagTdPropsSleepDiary = DoctorReportQueryQuery['sleep_diary'][0];
interface ISleepDiaryTagTdProps extends TdProps {
  diary: ISleepDiaryTagTdPropsSleepDiary;
}

const commentEmoji = '📝';
const snoreEmoji = '📢';
export const SleepDiaryTagTd: React.FunctionComponent<ISleepDiaryTagTdProps> = (props) => {
  const { diary, ...other } = props;
  return (
    <TD minWidth={'106px'} {...other}>
      <SleepDiaryTagSpan tooltip={getTextForSleepDiaryGrade(diary.grade)} value={getEmojiForSleepDiaryGrade(diary.grade)} />
      {diary.comment ? <SleepDiaryTagSpan tooltip={diary.comment} value={commentEmoji} /> : ''}
      {diary.snore_count ? (
        <SleepDiaryTagSpan tooltip={`Tempo de ronco: ${formatSnore(diary.snore_duration, diary.snore_count)}`} value={snoreEmoji} />
      ) : (
        ''
      )}
      {getDiaryTags(diary).map((tag) => (
        <SleepDiaryTagSpan
          tooltip={getEmojiAndNameForSleepDiaryTag(tag.sleep_tag as any)}
          value={getEmojiForSleepDiaryTag(tag.sleep_tag as any)}
        />
      ))}
      {getTechniqueEmojis(diary)}
    </TD>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////

interface ISleepDiaryTagSpanProps {
  value: string;
  tooltip: string;
}

const SleepDiaryTagSpan: React.FunctionComponent<ISleepDiaryTagSpanProps> = (props) => {
  return (
    <span role="tooltip" data-microtip-position="top" aria-label={props.tooltip}>
      {props.value}
    </span>
  );
};

export const getSleepDiaryTagTdLabel = () => {
  const grades = `${[SleepDiaryGrade.Good, SleepDiaryGrade.Ok, SleepDiaryGrade.Bad]
    .map((item) => `${getEmojiForSleepDiaryGrade(item)}: ${getTextForSleepDiaryGrade(item)}`)
    .join('; ')}`;
  const comment = `${commentEmoji}: Comentário; `;
  const tags = `${Object.values(SleepDiaryTag)
    .map((item) => `${getEmojiForSleepDiaryTag(item)}: ${getNameForSleepDiaryTag(item)}`)
    .join('; ')}`;
  const technique = `${Object.values(SleepDiaryTechnique)
    .map((item) => `${getEmojiForSleepDiaryTechnique(item)}: ${getNameForSleepDiaryTechnique(item)}`)
    .join('; ')}`;
  return `${grades} ${comment} ${tags} ${technique}`;
};
export const getTechniqueEmojis = (item: ISleepDiaryTagTdPropsSleepDiary) => {
  if (!item) {
    return '';
  }
  const {
    deep_breath,
    meditation,
    pmr,
    imagery,
    autogenic_training,
    behavior_activation,

    parking_lot,
    paradoxical_intention,
    thought_block,
    challenge_catastrophic_thinking,
    gratitude,
    light_therapy,

    stimulus_control,
  } = item;

  const techniques = {
    deep_breath,
    meditation,
    pmr,
    imagery,
    autogenic_training,

    behavior_activation,
    parking_lot,
    paradoxical_intention,
    thought_block,
    challenge_catastrophic_thinking,
    gratitude,
    light_therapy,

    stimulus_control,
  };

  return Object.keys(techniques)
    .filter((technique) => techniques[technique])
    .map(getEmojiForSleepDiaryTechnique)
    .join(', ');
};
