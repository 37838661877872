import { InputLabel, LargeSeparator, Separator } from '@web/atomic';
import { EnhancedSelect } from '@web/atomic/legacy/atm.enhanced-select/enhanced-select.component';
import { RadioField } from '@web/atomic/legacy/atm.radio';
import { TextField } from '@web/atomic/legacy/atm.text-field';
import { Form, Validators } from '@web/atomic/legacy/obj.form';
import { useField } from '@web/atomic/obj.custom-hooks/field.hook';
import React from 'react';
import { factoryBankBranchDigitSolver } from '../../../data/bank-branch-digit-solver';
import { bankList } from '../../../data/bank-list';

/**
 * TODO B2C: Everything on financial-info-form-fields.component.tsx removed with slit payment;
 */

export enum FinancialSignUpPageAccountType {
  CheckingAccount = 'checking',
  SavingsAccount = 'savings',
}
const mapFinancialSignUpPageAccountTypeToText = (type: FinancialSignUpPageAccountType) => {
  switch (type) {
    case FinancialSignUpPageAccountType.CheckingAccount:
      return 'Conta corrente';
    case FinancialSignUpPageAccountType.SavingsAccount:
      return 'Conta poupança';
    default:
      throw new Error(`Invalid type ${type}`);
  }
};

export interface FinancialSignUpForm {
  bank: {
    label: string;
    value: string;
  };
  branch_number: string;
  branch_check_digit: string;
  account_type: FinancialSignUpPageAccountType;
  account_number: string;
  account_check_digit: string;
}

export interface IFinancialInfoFormFieldsProps {
  initialData?: FinancialSignUpForm;
}

export const FinancialInfoFormFields: React.FunctionComponent<IFinancialInfoFormFieldsProps> = (props) => {
  const [bank, handleBank] = useField(props.initialData?.bank);
  const BankBranchDigitSolver = factoryBankBranchDigitSolver(+bank?.value);

  const showAccountDigit = bank && BankBranchDigitSolver.shouldAskForAccountDigit();
  return (
    <>
      <Form.Field name="bank" onValueChange={handleBank} initialValue={props.initialData?.bank} validators={[Validators.Required()]}>
        <InputLabel>Banco </InputLabel>
        <EnhancedSelect options={bankList} />
      </Form.Field>
      <LargeSeparator />

      <Form.Field
        name={'branch_number' as keyof FinancialSignUpForm}
        initialValue={props.initialData?.branch_number}
        validators={[Validators.Required()]}
      >
        <InputLabel>Agência {showAccountDigit ? `(sem dígito)` : ''} </InputLabel>
        <TextField type={'tel'} maxLength={4} />
      </Form.Field>
      <LargeSeparator />

      {showAccountDigit && (
        <>
          <Form.Field
            name={'branch_check_digit' as keyof FinancialSignUpForm}
            initialValue={props.initialData?.branch_check_digit}
            validators={[Validators.Required()]}
          >
            <InputLabel>
              Digito da agência{' '}
              <a href={'https://www.idinheiro.com.br/digito-da-agencia/'} target="_blank" rel="noreferrer">
                {' '}
                (Clique aqui caso não saiba)
              </a>{' '}
            </InputLabel>
            <TextField type={'tel'} maxLength={1} />
          </Form.Field>
          <LargeSeparator />
        </>
      )}

      <Form.Field
        name={'account_number' as keyof FinancialSignUpForm}
        initialValue={props.initialData?.account_number}
        validators={[Validators.Required()]}
      >
        <InputLabel>Número da conta (sem dígito) </InputLabel>
        <TextField type={'tel'} maxLength={13} />
      </Form.Field>
      <LargeSeparator />

      <Form.Field
        name={'account_check_digit' as keyof FinancialSignUpForm}
        initialValue={props.initialData?.account_check_digit}
        validators={[Validators.Required()]}
      >
        <InputLabel>Dígito da conta (sem dígito) </InputLabel>
        <TextField type={'tel'} maxLength={2} />
      </Form.Field>
      <LargeSeparator />

      <Form.Field
        name={'account_type' as keyof FinancialSignUpForm}
        initialValue={props.initialData?.account_type}
        validators={[Validators.Required()]}
      >
        <InputLabel>Tipo da conta </InputLabel>
        <Separator />
        {Object.values(FinancialSignUpPageAccountType).map((value) => (
          <RadioField key={value} id={value}>
            {mapFinancialSignUpPageAccountTypeToText(value)}
          </RadioField>
        ))}
      </Form.Field>
    </>
  );
};
