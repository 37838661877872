import flashDispatcherService from '@web/atomic/legacy/obj.flash-wrapper/flash-dispatcher.service';
import { hasNavigator, hasWindow } from '@web/utils/platform';
import copy from 'copy-text-to-clipboard';
import { useEffect, useState } from 'react';
import { OperatingSystem, useOS } from '@web/atomic/obj.custom-hooks/mobile.hook';

interface CopyOrShareInfoInput {
  successMessage: string;
  errorMessage: string;
}

export const useCopyOrShareInfo = (input: CopyOrShareInfoInput) => {
  const [shareAvailable, setShareAvailable] = useState(false);
  const platform = useOS();
  useEffect(() => {
    // Share only on mobile (IOS or Android) with share feature
    const shouldUseNativeShare = platform === OperatingSystem.Android || platform === OperatingSystem.IOS;
    setShareAvailable(hasNavigator() && !!(navigator as any).share && shouldUseNativeShare);
  }, []);
  const copyOrShare = (data: { text: string; title?: string; url?: string }) => {
    const { text, title, url } = data;
    const nav: any = navigator;
    // https://web.dev/web-share/
    if (nav.share) {
      nav.share({ title, text, url });
    } else if (hasWindow()) {
      const copyInfo = copyHof(input);
      copyInfo(title, text, url);
    }
  };

  return {
    shareAvailable,
    copyOrShare,
  };
};

export const copyHof = (input: CopyOrShareInfoInput) => {
  const copyText = (text: string, title?: string, url?: string) => {
    if (hasWindow()) {
      const copyText = `${title ? `# ${title} \n\n` : ''}${text}${url ? ` - ${url}` : ''}`;
      const { successMessage, errorMessage } = input;
      try {
        const didCopy = copy(copyText);
        if (didCopy) {
          flashDispatcherService.dispatchMessage(successMessage, 'success');
        } else {
          flashDispatcherService.dispatchMessage(errorMessage, 'alert');
        }
      } catch (error) {
        console.error('Instructions: error', error);
        flashDispatcherService.dispatchMessage(errorMessage, 'alert');
      }
    }
  };
  return copyText;
};
