import { Spacing } from '@web/atomic';
import styled from 'styled-components';

export const WhatsappFABStyled = styled.a`
  cursor: pointer;
  position: fixed;
  bottom: 0;
  right: 0;
  height: 64px;
  width: 64px;
  margin-right: ${Spacing.Small};
  margin-bottom: ${Spacing.Medium};
  z-index: 99;
  @media print {
    display: none;
  }
`;
