import React from 'react';
import reactUseHotjar from 'react-use-hotjar';

const myCustomLogger = console.info;

export const useHotjar = (): void => {
  const { initHotjar } = reactUseHotjar();
  React.useEffect(() => {
    initHotjar(+process.env.HOTJAR_ID, +process.env.HOTJAR_VERSION, myCustomLogger);
  }, [initHotjar]);
};
