import * as React from 'react';

import { ActivityIndicatorStyled } from './activity-indicator.component.style';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export interface ActivityIndicatorProps {
  // 0 = false ; 1 = true; https://stackoverflow.com/a/50752064/3670829
  light?: 0 | 1;
}

export const ActivityIndicator: React.FunctionComponent<ActivityIndicatorProps> = (props: ActivityIndicatorProps) => {
  return <ActivityIndicatorStyled light={props.light} icon={faSpinner} spin={true} />;
};
