import * as React from 'react';

export function useBody() {
  const [body, setBody] = React.useState<HTMLBodyElement>();
  React.useEffect(() => {
    const nextBody = document?.querySelector('body');
    setBody(nextBody);
  }, []);
  return body;
}
