import { parseDateToShortString, parseToDate } from '@global/utils/date';
import { getMedicineAcronym } from '@global/utils/domain/sleep-diary';
import { AppThemeContext, BodySmall, FontSize, GrayColor } from '@web/atomic';
import React, { useContext } from 'react';
import { CartesianGrid, Legend, ResponsiveContainer, Scatter, ScatterChart, XAxis, YAxis } from 'recharts';
import { getCycleReferenceLine } from './cycle-reference.components';
import { ChartWrapperStyled } from './graph.styled';
import { CHART_COLORS, GetDiariesWithEmptyValuesOutput_Diary, SleepDiaryGraphProps } from './utils';

type IMedicineGraphProps = SleepDiaryGraphProps;

export const MedicineGraph: React.FunctionComponent<IMedicineGraphProps> = (props) => {
  const { theme } = useContext(AppThemeContext);
  const sleepDiaryWithMedicine = props.sleepDiaryData.filter(
    (sd) => (sd as GetDiariesWithEmptyValuesOutput_Diary)?.sleep_diary_medicines?.length > 0
  ) as Array<GetDiariesWithEmptyValuesOutput_Diary>;
  const medicineObject = generateMedicineObject(sleepDiaryWithMedicine);
  const medicineChartData = getChartData(sleepDiaryWithMedicine, medicineObject);
  const dates = new Set(medicineChartData.map((v) => v.name));
  let sortedDate = Array.from(dates).sort();
  //BUSINESS-RULE: the first cycle actually starts one day before the first diary
  if (props.cycles && props.cycles[0]) {
    const shortStartDate: string = parseDateToShortString(props.cycles[0].startDate);
    medicineChartData.unshift({ name: shortStartDate });
    sortedDate = [shortStartDate, ...sortedDate];
  }

  return (
    <>
      <ChartWrapperStyled height={props.height}>
        <ResponsiveContainer>
          <ScatterChart
            height={props.height}
            width={props.width}
            // POG-ALERT: this margin is a hack to remove space before/after Y-Axis
            margin={{ top: 0, right: 35, bottom: 0, left: -35 }}
          >
            <CartesianGrid stroke={theme.name === 'dark' ? GrayColor.GrayDark : GrayColor.GrayXXXLight} />
            <XAxis
              stroke={theme.name === 'dark' ? GrayColor.GrayXLight : GrayColor.Gray}
              allowDuplicatedCategory={false}
              dataKey="name"
              domain={sortedDate}
              label={{ position: 'insideBottomRight', offset: 0 }}
            />
            <YAxis
              style={{ fontSize: FontSize.XXXSmall }}
              stroke={theme.name === 'dark' ? GrayColor.GrayXLight : GrayColor.Gray}
              dataKey="medicine"
              ticks={Object.keys(medicineObject)}
              domain={[0, Object.keys(medicineObject).length]}
              tickFormatter={(value, index) => mapMedicineToYAxis(value, index, medicineObject)}
              padding={{ bottom: 20, top: 0 }}
            />
            <Legend verticalAlign="top" wrapperStyle={{ left: 0, top: -30 }} />
            {Object.values(medicineObject).map((medicineName, i) => {
              return (
                <Scatter
                  key={medicineName}
                  isAnimationActive={!props.removeAnimation}
                  name={`(${i + 1}) ${medicineName}`}
                  data={medicineChartData.filter((value) => medicineObject[value.medicine] === medicineName)}
                  fill={CHART_COLORS[i]}
                />
              );
            })}
            {getCycleReferenceLine(props)}
          </ScatterChart>
        </ResponsiveContainer>
      </ChartWrapperStyled>
      <BodySmall>
        {Object.keys(medicineObject).map((key, i) => {
          const medicineName = medicineObject[key];
          return (
            <React.Fragment key={key}>
              <strong>{mapMedicineToYAxis(key, i, medicineObject)}</strong>
              {`: ${medicineName}; `}
            </React.Fragment>
          );
        })}
      </BodySmall>
    </>
  );
};

interface ChartDataItem {
  name: string;
  medicine?: number;
}

const mapMedicineToYAxis = (medicineKey: number | string, _: number, medicineObject: Record<string, string>) => {
  const medicineName = medicineObject[medicineKey];
  return `${medicineKey}-${getMedicineAcronym(medicineName)}`;
};

const generateMedicineObject = (sleepDiaryData: Array<GetDiariesWithEmptyValuesOutput_Diary>) => {
  const medicineList = new Set<string>();
  for (const diary of sleepDiaryData) {
    for (const medicine of diary?.sleep_diary_medicines) {
      medicineList.add(medicine?.name);
    }
  }

  return generateMedicineObjectFromArray(medicineList);
};

const getChartData = (
  sleepDiaryData: Array<GetDiariesWithEmptyValuesOutput_Diary>,
  medicineObject: Record<string, string>
): ChartDataItem[] => {
  const medicineChartData = [];

  for (const diary of sleepDiaryData) {
    for (const medicine of diary.sleep_diary_medicines) {
      medicineChartData.push({
        name: parseDateToShortString(parseToDate(diary.date)),
        medicine: getKeyByValue(medicineObject, medicine.name),
      });
    }
  }

  const sortedChartData = medicineChartData.sort((a, b) => (a.date > b.date ? -1 : 1));

  return sortedChartData;
};

const getKeyByValue = (object: Record<string, string>, value: string) => {
  return Object.keys(object).find((key) => object[key] === value);
};

const generateMedicineObjectFromArray = (medicineList: Set<string>): Record<string, string> => {
  const medicineObject = Array.from(medicineList).reduce((acc, cur, i) => {
    return {
      ...acc,
      [i + 1]: cur,
    };
  }, {});
  return medicineObject;
};
