import { fieldBackgroundCss, fieldCss } from '@web/atomic/legacy/obj.form/field.component.styled';
import styled from 'styled-components';

export const SelectStyled = styled.select`
  appearance: none;
  border: 0;
  min-width: 60px;
  outline: none;
  ${fieldCss}
  ${fieldBackgroundCss}
  padding-right: 40px;
  padding-left: 8px;
  text-overflow: ellipsis;
`;
