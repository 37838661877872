import { hasWindow } from '@web/utils/platform';

export const logError = (error: Error): void => {
  console.error('ERROR: logError ->', error);

  const Sentry = hasWindow() && (window as any).Sentry;
  if (Sentry) {
    Sentry.captureMessage(`web.utils/error ~ ${error}`);
  }
};
