import { DoctorReportQueryQuery, User_Program_Session_Enum_Enum } from '@global/utils/remote-graphql-types';
import { HealthCarerPortalJourneyInfo, SessionInfo } from '../data/session';

export interface GetProgramProgressOutput {
  sessionsDone: number;
  allSessions: number;
  detail: {
    journeyName: string;
    sessionsDone: number;
    allSessions: number;
  }[];
}
export function getProgramProgress(user: DoctorReportQueryQuery['user'][0]): GetProgramProgressOutput {
  if (!user?.user_program_sessions || !user?.user_journeys) {
    return { sessionsDone: 0, allSessions: 1, detail: [] };
  }
  const output: GetProgramProgressOutput = user.user_journeys.reduce(
    (acc, curr) => {
      try {
        const journeyId = curr.journey_id;
        const journey: HealthCarerPortalJourneyInfo = SessionInfo.journeys[journeyId];
        const journeyAllSessions = journey.sessions;
        const journeyAllSessionsCount = journeyAllSessions.length;
        acc.allSessions += journeyAllSessionsCount ?? 0;
        const sessionStateDict = user.user_program_sessions.reduce((acc, curr) => {
          acc[curr.session_id] = curr.state;
          return acc;
        }, {} as Record<number, User_Program_Session_Enum_Enum>);
        const journeySessionsDone =
          journeyAllSessions?.filter((s) => sessionStateDict[s] === User_Program_Session_Enum_Enum.Finished) ?? [];
        const journeySessionsDoneCount = journeySessionsDone.length;
        acc.sessionsDone += journeySessionsDoneCount;
        acc.detail.push({ journeyName: journey.name, sessionsDone: journeySessionsDoneCount, allSessions: journeyAllSessionsCount });
        return acc;
      } catch (error) {
        console.error('ERROR: doctor-report.utils.ts:35 ~ getProgramProgress ~ error:', error);
        return acc;
      }
    },
    {
      sessionsDone: 0,
      allSessions: 0,
      detail: [],
    }
  );
  return output;
}
