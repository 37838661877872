import { FaIcon } from '@web/atomic/legacy/atm.fa-icon';
import { Spacing } from '@web/atomic/legacy/obj.constants';
import { fieldBorderCss, fieldCss, FieldProps } from '@web/atomic/legacy/obj.form/field.component.styled';
import styled from 'styled-components';

const estimatedCharacterWidthInPx = 17;
const widthGutterInPx = 20;

export const SelectTemplateWrapperStyled = styled.div<FieldProps & { maxLength }>`
  ${fieldBorderCss}
  position: relative;
  padding: 0;
  width: ${(props) => (props.maxLength ? `${props.maxLength * estimatedCharacterWidthInPx + widthGutterInPx}px` : '100%')};
`;

export const SelectTemplateIconStyled = styled(FaIcon.ChevronDown)`
  position: absolute;
  bottom: 16px;
  right: ${Spacing.Medium};
  color: ${(props) => props.theme.color.text};

  pointer-events: none;
`;
