import * as React from 'react';
import { IPatientPaidTagProps, PatientPaidTagStyled } from './patient-paid-tag.component.styled';

const PatientPaidTag: React.FunctionComponent<IPatientPaidTagProps> = (props) => {
  const label = props.paid ? `Paciente aderiu ao programa completo!` : `Paciente ainda não aderiu ao programa completo!`;
  return (
    <div role="tooltip" data-microtip-position="right" data-microtip-size="medium" aria-label={label}>
      <PatientPaidTagStyled {...props} />
    </div>
  );
};

export default PatientPaidTag;
