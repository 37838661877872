import { Sleep_Tag_Enum_Enum } from '../remote-graphql-types';
import { HasuraDate, HasuraTimestamptz } from '../remote-graphql-types.extra';
import { SleepDiaryGrade, SleepDiaryTag, SleepDiaryTechnique, YesNo } from './entities';
import { MedicineDoseValue } from './medicine-dose';
import { SleepIntervals } from './sleep-tracker-data-analysis';
import { CompressData } from './sleep-tracker-data-analysis/data-analysis.model';

export interface SleepDiaryFormData {
  date: HasuraDate;
  goBed: string;
  goSleep: string;
  timeToSleep: string;
  wakeUpCount: string;
  wakeUpDuration: string;
  wakeUp: string;
  getUp: string;
  grade: SleepDiaryGrade;
  tags_1: SleepDiaryTag[];
  tags_2: SleepDiaryTag[];
  comment: string;

  techniques: SleepDiaryTechnique[];
  relationships?: SleepDiaryGrade;
  concentration?: SleepDiaryGrade;
  energy?: SleepDiaryGrade;
  humor?: SleepDiaryGrade;

  medicine: YesNo;
  // list with all medicine+dose the user can take
  medicineDoseList?: MedicineDoseValue[];
  /**
   * @see * packages/component.sleep-diary/components/medicine-dose/medicine-dose.utils.tsx
   * to understand the string format
   */
  selectedMedicine: MedicineDoseValue[];
}

export interface SleepDiaryFormDataWithIntervals_Alarm {
  alarm: string;
  currentAlarm: string;
  alarmPlayDate: string;
}

export interface SleepDiaryFormDataWithIntervals extends SleepDiaryFormData {
  intervals: SleepIntervals[];
  compressData?: CompressData[];
  alarm: SleepDiaryFormDataWithIntervals_Alarm;

  // the list of all medicine names
  medicineList: MedicineDoseValue[];

  /**
   * the list of medicine+dose the user can take (given what they had already taken in the past)
   * @see MedicineDoseListRepository
   */
  medicineDoseList: MedicineDoseValue[];

  snoreData?: {
    start: HasuraTimestamptz;
    count: number;
    duration: number;
    times: Array<[start: string, end: string]>;
  };
}

/**
 *
 * Return tags adding medicine tag if needed
 *
 * SMELL_ALERT: Why? There are 2 ways of saving medicine on a sleep_diary: using
 * the property medicine or using a sleep_diary tag
 */
export function getDiaryTags(diary: {
  medicine?: boolean;
  sleep_diary_medicines?: Array<unknown>;
  tags?: Array<{ sleep_tag: Sleep_Tag_Enum_Enum }>;
}) {
  const diaryTags = diary.tags?.slice();
  const hasTakenMedicineButMedicineTagNotIncluded =
    (diary.medicine || diary.sleep_diary_medicines?.length) && !diaryTags.find((t) => t.sleep_tag === Sleep_Tag_Enum_Enum.Medicine);
  if (hasTakenMedicineButMedicineTagNotIncluded) {
    diaryTags.push({ sleep_tag: Sleep_Tag_Enum_Enum.Medicine });
  }
  return diaryTags;
}

export const getMedicineAcronym = (medicineName: string) => medicineName.slice(0, 3);
