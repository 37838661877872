import { AutoMargin, Hbox, Separator } from '@web/atomic';
import * as React from 'react';
import { InView } from 'react-intersection-observer';
import {
  ProgressGaugeBackground,
  ProgressGaugeBackgroundBorder,
  ProgressGaugeBackgroundText,
  ProgressGaugeForeground,
  ProgressGaugeForegroundBorder,
  ProgressGaugeForegroundText,
  ProgressGaugeKind,
  ProgressGaugeWrapper,
} from './progress-gauge.component.style';

export interface ProgressGaugeProps {
  maxValue: number;
  backgroundValue: number;
  foregroungValue: number;
  leftText?: JSX.Element | string;
  rightText?: JSX.Element | string;
  kind: ProgressGaugeKind;
}

export const ProgressGauge: React.FunctionComponent<ProgressGaugeProps> = (props) => {
  const { maxValue, backgroundValue, foregroungValue, leftText, rightText, kind } = props;

  const bkgPercentage = (backgroundValue / maxValue) * 100;
  const fgPercentage = (foregroungValue / maxValue) * 100;

  return (
    <ProgressGaugeWrapper>
      <InView triggerOnce={true}>
        {({ inView, ref }) => (
          <>
            <ProgressGaugeBackground ref={ref} percentage={bkgPercentage} />
            {inView && <ProgressGaugeForeground percentage={fgPercentage} kind={kind} />}
          </>
        )}
      </InView>
      {(leftText || rightText) && (
        <>
          {leftText && <ProgressGaugeForegroundBorder kind={kind} />}
          {rightText && <ProgressGaugeBackgroundBorder percentage={bkgPercentage} />}
          <Separator />
          <Hbox>
            <Hbox.Separator />
            {leftText && (
              <Hbox.Item wrap>
                <ProgressGaugeForegroundText kind={kind}>{leftText}</ProgressGaugeForegroundText>
              </Hbox.Item>
            )}
            <AutoMargin />
            {rightText && (
              <Hbox.Item wrap>
                <ProgressGaugeBackgroundText>{rightText}</ProgressGaugeBackgroundText>
              </Hbox.Item>
            )}
            <Hbox.Separator />
          </Hbox>
        </>
      )}
    </ProgressGaugeWrapper>
  );
};
