import * as React from 'react';
import { VideoStyled, VideoWrapperStyled } from './video.component.style';
import QueryString from 'querystringify';

interface VideoProps {
  url: string;
  autoplayLoopMuted?: boolean;
  /**
   * if false, then "embedded YouTube videos will be lazy-loaded" (@see
   * https://dev.to/haggen/lazy-load-embedded-youtube-videos-520g)
   */
  disableSrcDoc?: boolean;
  vertical?: boolean;
}

/**
 * Component to display a video (tested with Youtube videos) that fills the width of its container
 * https://stackoverflow.com/a/38270745/3670829
 * @param props
 */
export const Video: React.FunctionComponent<VideoProps> = (props) => {
  return (
    <VideoWrapperStyled vertical={props.vertical}>
      <VideoStyled
        vertical={props.vertical}
        src={getFormattedUrl(props.url, props.autoplayLoopMuted)}
        srcDoc={props.disableSrcDoc ? undefined : getSrcDoc(props.url, props.autoplayLoopMuted)}
      />
    </VideoWrapperStyled>
  );
};

/**
 * the Video component needs a url with the `embed` format.
 * This method tries to generate this kind of url
 *
 * @param rawUrl
 */
const getFormattedUrl = (rawUrl: string, autoplayMuted: boolean) => {
  const id = getVideoId(rawUrl);
  const isEmbedUrl = rawUrl.includes('/embed/');
  const videoUrl = isEmbedUrl ? rawUrl : `https://www.youtube.com/embed/${id}`;

  // https://www.w3schools.com/html/html_youtube.asp
  const additionalQueryParams = autoplayMuted
    ? QueryString.stringify(
        {
          autoplay: 1,
          mute: 1,
          controls: 0,
          loop: 1,
          // https://stackoverflow.com/questions/44087897/youtube-embed-feature-for-looping-single-videos-isnt-working
          playlist: id,
        },
        videoUrl.includes('?') ? '&' : true
      )
    : '';

  return `${videoUrl}${additionalQueryParams}`;
};

/**
 * https://dev.to/haggen/lazy-load-embedded-youtube-videos-520g
 * @param url
 */
const getSrcDoc = (url: string, autoplayMuted: boolean) => {
  const id = getVideoId(url);
  if (!id || autoplayMuted) {
    return undefined;
  }

  return `<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style>
  <a href=https://www.youtube.com/embed/${id}?autoplay=1><img src=https://img.youtube.com/vi/${id}/hqdefault.jpg><span>▶</span></a>`;
};

const getVideoId = (url: string) => {
  try {
    const params = QueryString.parse(url.split('?')[1]);
    return params.v;
  } catch (error) {
    console.log('getVideoId -> error', error);
    return undefined;
  }
};
