import { UpdateHealthCarerUserRequest } from '@global/utils/api/health-carer.controller.model';
import { Gender, getGenderName } from '@global/utils/domain/entities';
import { HealthCarerFactory, HealthCarerKind } from '@global/utils/domain/health-carer/health-carer-factory';
import { fixPhoneNumber } from '@global/utils/phone/phone';
import { FormData } from '@web/atomic/legacy/obj.form';
import React, { useEffect, useState } from 'react';
import { HcpUser } from '../../UserProvider';
import { FinancialSignUpForm } from '../../modules/financial-sign-up/components/financial-info-form-fields.component';

export interface IEditMenuModal {
  onClose: () => void;
  opened: boolean;
}

/**
 * TODO B2C: FinancialSignUpForm should be removed with slit payment;
 */
export type ChangeDataModalForm = FinancialSignUpForm & ChangeDataModalPersonalDataForm;

export interface ChangeDataModalPersonalDataForm {
  name: string;
  gender: string;
  email: string;
  whatsapp: string;
  /**
   * TODO B2C: bankAccount should be removed with slit payment;
   */
  bankAccount?: {
    holder_document: string;
    bank: string;
    branch_number: string;
    branch_check_digit: string;
    account_number: string;
    account_check_digit: string;
    account_type: 'checking' | 'savings';
  };
}

export interface IUpdateTitleRespose {
  title: string;
  subtitle: string;
}

export function updateTitle(user: HcpUser): IUpdateTitleRespose {
  const { kind, gender, professional_id_state, professional_id, name } = user;
  const [title, setTitle] = useState(name);
  const [subtitle, setSubtitle] = useState('');

  const updateTitle = () => {
    const hcp = HealthCarerFactory(kind as HealthCarerKind, gender as Gender);
    const professional_id_and_state = professional_id_state + professional_id;
    setTitle(`${name}`);
    setSubtitle(`${hcp.getHealthCarerKindName()} ${professional_id_and_state ?? ''}`);
  };

  useEffect(() => {
    if (!kind || !name) return;
    updateTitle();
  }, [kind, name, gender]);
  return { title, subtitle };
}

export function buildUpdateHcpData(formData: FormData<ChangeDataModalForm>, user: HcpUser): UpdateHealthCarerUserRequest {
  const hasUpdatedBankAccount =
    formData.data.account_check_digit !== user.financialInfo?.account_check_digit ||
    formData.data.account_number !== user.financialInfo?.account_number ||
    formData.data.account_type !== user.financialInfo?.account_type ||
    formData.data.bank?.value !== user.financialInfo?.bank ||
    formData.data.branch_number !== user.financialInfo?.branch_number ||
    formData.data.branch_check_digit !== user.financialInfo?.branch_check_digit;
  const data: UpdateHealthCarerUserRequest = {
    hcpId: user.id,
    name: formData.data.name,
    gender: formData.data.gender as Gender,
    whatsapp: fixPhoneNumber(formData.data.whatsapp),
    /**
     * TODO B2C: bankAccount should be removed with split payment;
     */
    bankAccount: !hasUpdatedBankAccount
      ? undefined
      : {
          holder_document: user.financialInfo.document,
          bank: formData.data.bank.value,
          branch_number: formData.data.branch_number,
          branch_check_digit: formData.data.branch_check_digit,
          account_number: formData.data.account_number,
          account_check_digit: formData.data.account_check_digit,
          account_type: formData.data.account_type,
        },
  };

  return data;
}

export function getGenderSelection(): React.ReactNode {
  return Object.values(Gender).map((item) => (
    <option value={item} key={item}>
      {getGenderName(item)}
    </option>
  ));
}
