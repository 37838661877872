import * as React from 'react';
import { SelectTemplateIconStyled, SelectTemplateWrapperStyled } from './select-template.component.style';

export interface SelectTemplateProps {
  invalid?: boolean;
  maxLength?: number;
}

export class SelectTemplateField extends React.Component<SelectTemplateProps, never> {
  render() {
    return (
      <SelectTemplateWrapperStyled invalid={this.props.invalid} maxLength={this.props.maxLength}>
        {this.props.children}
        <SelectTemplateIconStyled />
      </SelectTemplateWrapperStyled>
    );
  }
}
