import * as React from 'react';

const SvgIcWhatsapp = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg viewBox="0 0 64 64" {...props}>
    <defs>
      <filter x="-41.7%" y="-41.7%" width="183.3%" height="183.3%" filterUnits="objectBoundingBox" id="whatsapp_svg__a">
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={4} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.741176471 0 0 0 0 0.741176471 0 0 0 0 0.741176471 0 0 0 1 0"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g filter="url(#whatsapp_svg__a)" transform="translate(8 6)" fill="none" fillRule="evenodd">
      <path
        d="M47.965 23.982c0 13.245-10.738 23.983-23.983 23.983C10.737 47.965 0 37.227 0 23.982 0 10.737 10.737 0 23.982 0s23.983 10.737 23.983 23.982"
        fill="#25D366"
      />
      <path
        d="M31.715 26.289c-.376-.188-2.225-1.098-2.57-1.224-.345-.125-.595-.188-.846.188-.25.377-.972 1.224-1.191 1.475-.22.251-.439.283-.815.094-.376-.188-1.588-.585-3.025-1.866-1.118-.998-1.873-2.23-2.092-2.606-.22-.377-.024-.58.165-.768.169-.168.376-.44.564-.659.188-.22.25-.376.376-.627.125-.251.063-.471-.031-.66-.094-.187-.847-2.039-1.16-2.792-.305-.733-.616-.634-.846-.646-.22-.01-.47-.013-.721-.013s-.659.094-1.003.47c-.345.377-1.317 1.287-1.317 3.139 0 1.851 1.348 3.64 1.536 3.89.188.252 2.652 4.05 6.426 5.68a21.6 21.6 0 0 0 2.144.792c.9.287 1.72.246 2.369.15.723-.109 2.225-.91 2.539-1.789.313-.879.313-1.632.22-1.789-.095-.157-.345-.25-.722-.44m-6.863 9.372h-.005c-2.245-.001-4.447-.604-6.368-1.744l-.457-.271-4.735 1.242 1.264-4.617-.298-.474a12.483 12.483 0 0 1-1.913-6.658c.003-6.899 5.616-12.511 12.517-12.511a12.427 12.427 0 0 1 8.846 3.669 12.437 12.437 0 0 1 3.661 8.852c-.003 6.899-5.615 12.512-12.512 12.512m10.649-23.161a14.955 14.955 0 0 0-10.649-4.415c-8.297 0-15.05 6.753-15.054 15.053a15.027 15.027 0 0 0 2.01 7.526l-2.136 7.8 7.98-2.093a15.04 15.04 0 0 0 7.194 1.832h.006c8.297 0 15.05-6.753 15.054-15.053a14.963 14.963 0 0 0-4.405-10.65"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default SvgIcWhatsapp;
