import { RouteComponentProps } from '@reach/router';
import * as React from 'react';
import { withAuth } from '../../components/router/with-auth.hoc';
import FinancialActivityPage from './financial-activity.page';
import { FinancialActivityRouteUrl as FinancialUrl } from '@global/utils/health-carer/routes';

export const FinancialActivityRouteUrl = FinancialUrl;

const AuthFinancialActivityRoute = withAuth(FinancialActivityPage);
export const FinancialActivityRoute: React.FunctionComponent<RouteComponentProps> = () => <AuthFinancialActivityRoute />;
