import { User } from '@global/utils/remote-graphql-types';
import { Separator } from '@web/atomic';
import { FuseSearch, IndexParams } from '@web/atomic/mol.local-search';
import React from 'react';

const DoctorSearch = ({ users, setList }) => {
  const indexParams = React.useRef<IndexParams<User>>({
    data: users,
    keys: [
      {
        name: 'first_name',
        weight: 0.5,
      },
      {
        name: 'last_name',
        weight: 0.5,
      },
    ],
  });

  const onResult = React.useCallback((query: string, list: User[]) => {
    if (!query) {
      setList(users);
    }
    if (list) {
      setList(list);
    }
  }, []);

  return (
    <>
      <FuseSearch indexParams={indexParams.current} onResultChange={onResult} placeholder={'Buscar pacientes'} />
      <Separator />
    </>
  );
};

const MemoizedDoctorSearch = React.memo(DoctorSearch);
export default MemoizedDoctorSearch;
