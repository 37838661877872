import { Gad, Gad7 } from '../remote-graphql-types';
import { ChatfuelBlockIds, ChatfuelBlockNames } from '../chat/chatfuel-blocks-types';

// GAD methods
/** https://www.hiv.uw.edu/page/mental-health-screening/gad-2 */
export const computeGadPoints = (gad: Pick<Gad, 'q1' | 'q2'>): number => gad.q1 + gad.q2;

// https://www.hiv.uw.edu/page/mental-health-screening/gad-7
export const computeGad7Points = (gad: Pick<Gad7, 'q1' | 'q2' | 'q3' | 'q4' | 'q5' | 'q6' | 'q7'>): number =>
  gad.q1 + gad.q2 + gad.q3 + gad.q4 + gad.q5 + gad.q6 + gad.q7;

export const gad7Threshold = [5, 10, 15, 21];

/*
 *
 * Make sure the redirectBlock and Initial Block are the same block
 *
 */
const redirectBlock: ChatfuelBlockIds = '63164b7fd2056a7c50fdbb4e';
export const gad7InitialBlockName: ChatfuelBlockNames = 'gad7_youpage_entrance';

export const gad7Questionnaire = { name: 'Ansiedade', redirect: redirectBlock };

export const gad7ResultExplanationData = {
  title: 'Questionário Gad-7',
  description: 'O questionário tem como objetivo quantificar a percepção da gravidade de sintomas de ansiedade.',
  results: [
    {
      title: '0 - 5 pontos',
      description: 'não tem sintomas de ansiedade em um grau que os especialistas consideram de relevância clínica',
    },
    {
      title: '6 - 10 pontos',
      description: 'tem sintomas de ansiedade em um grau leve',
    },
    {
      title: '11 - 15 pontos',
      description: 'sintomas de ansiedade em um grau moderado',
    },
    {
      title: '16 - 21 pontos',
      description: 'sintomas graves de ansiedade',
    },
  ],
};
