import * as React from 'react';

const SvgIcClose = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg viewBox="0 0 12 12" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.2L10.8 0 6 4.8 1.2 0 0 1.2 4.8 6 0 10.8 1.2 12 6 7.2l4.8 4.8 1.2-1.2L7.2 6 12 1.2z"
      fill={props.color}
    />
    <mask id="ic_close_svg__a" maskUnits="userSpaceOnUse" x={0} y={0} width={12} height={12}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.2L10.8 0 6 4.8 1.2 0 0 1.2 4.8 6 0 10.8 1.2 12 6 7.2l4.8 4.8 1.2-1.2L7.2 6 12 1.2z"
        fill="#fff"
      />
    </mask>
  </svg>
);

export default SvgIcClose;
