import { parseDateToShortString } from '@global/utils/date/parse-to-string';
import { AppThemeContext, BodySmall, BrandColor, GrayColor } from '@web/atomic';
import React, { useContext } from 'react';
import { Area, CartesianGrid, ComposedChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { get30minReferenceLine } from './30-min-reference.components';
import { getCycleReferenceLine } from './cycle-reference.components';
import { ChartWrapperStyled } from './graph.styled';
import { SleepDiaryGraphProps } from './utils';

interface ILatencyGraphProps extends Omit<SleepDiaryGraphProps, 'sleepDiaryData'> {
  sleepDiaryData: {
    entryDate: Date;
    totalTimeToSleep?: number;
    time_to_sleep?: number; // out of convention because it comes from Sleep_Diary
  }[];
}

export const LatencyGraph: React.FunctionComponent<ILatencyGraphProps> = (props) => {
  const inBedLabel = 'na cama antes de apagar a luz (min)';
  const latencyLabel = 'latência (min)';

  const cData = props.sleepDiaryData
    ? props.sleepDiaryData.map((item) => {
        return {
          name: parseDateToShortString(item.entryDate),
          [inBedLabel]: item.totalTimeToSleep,
          [latencyLabel]: item.time_to_sleep,
        };
      })
    : [];

  // BUSINESS-RULE: the first cycle actually starts one day before the first diary
  if (props.cycles && props.cycles[0]) {
    cData.unshift({
      name: parseDateToShortString(props.cycles[0].startDate),
      [inBedLabel]: undefined,
      [latencyLabel]: undefined,
    });
  }

  const { theme } = useContext(AppThemeContext);

  return (
    <>
      <ChartWrapperStyled height={props.height}>
        <ResponsiveContainer>
          <ComposedChart
            data={cData}
            height={props.height}
            width={props.width}
            // POG-ALERT: this margin is a hack to remove space before/after Y-Axis
            margin={{ top: 0, right: 35, bottom: 0, left: -35 }}
          >
            <CartesianGrid stroke={theme.name === 'dark' ? GrayColor.GrayDark : GrayColor.GrayXXXLight} />
            <XAxis
              stroke={theme.name === 'dark' ? GrayColor.GrayXLight : GrayColor.Gray}
              dataKey="name"
              label={{ position: 'insideBottomRight', offset: 0 }}
            />
            <YAxis
              stroke={theme.name === 'dark' ? GrayColor.GrayXLight : GrayColor.Gray}
              label={{ value: 'minutos', className: 'rechart-left-label', angle: -90, position: 'top' }}
            />
            <Legend iconType="square" width={'100%' as any} verticalAlign="top" wrapperStyle={{ left: 0, top: -30 }} />
            <Tooltip />
            <Area
              isAnimationActive={!props.removeAnimation}
              connectNulls={false}
              fillOpacity={1}
              dataKey={inBedLabel}
              fill={GrayColor.GrayXLight}
              stroke={'none'}
            />
            <Area
              isAnimationActive={!props.removeAnimation}
              connectNulls={false}
              fillOpacity={1}
              dataKey={latencyLabel}
              fill={BrandColor.Hoki}
              stroke={'none'}
            />
            {get30minReferenceLine()}
            {getCycleReferenceLine(props)}
          </ComposedChart>
        </ResponsiveContainer>
      </ChartWrapperStyled>
      <BodySmall>
        A soma das duas áreas, &quot;{latencyLabel}&quot; + &quot;{inBedLabel}&quot;, representa o tempo que você passou na cama antes de
        conseguir pegar no sono.
      </BodySmall>
    </>
  );
};
