import { isNullOrUndefined } from '../object/null-or-undefined';

export const formatNumber = (value: number, maximumFractionDigits = 0, addtionalOptions: Intl.NumberFormatOptions = {}): string => {
  if (isNullOrUndefined(value)) {
    console.error('formatNumber:invalid value');
    return null;
  }

  try {
    const format = { maximumFractionDigits, ...addtionalOptions };
    return value.toLocaleString('pt-BR', format);
  } catch (error) {
    console.error('formatNumber:error: ', error);
    return value.toString();
  }
};

/** 41.5 -> 41,50 */
export const formatAsPrice = (value: number, withStyle = false): string => {
  return formatNumber(value, 2, {
    minimumFractionDigits: 2,
    style: withStyle ? 'currency' : undefined,
    currency: withStyle ? 'BRL' : undefined,
  });
};
