import { performHourSum } from '../date/perform-hour-sum';
import { getTimeInMinutesChronologically } from '../date/get-time-in-minutes-chronologically';
import { parseToDate } from '../date/parse-to-date';
import { Sleep_Diary, Sleep_Tag_Enum_Enum } from '../remote-graphql-types';

/**
 * functions to compute sleep eficiency.
 */
export interface SleepDiaryAnalysis {
  // hh:mm
  startOfSleepTime: string;
  // in minutes
  timeToTurnOffLights: number;
  // latency + time in bed before sleep
  totalTimeToSleep: number;
  // wake after sleep onset: in minutes
  awakeAtNight: number;
  earlyMorningAwakening: number;
  minutesInBed: number;
  minutesSleep: number;
  // 0 - 100
  eficiency: number;
}

export interface SleepDiaryParams {
  goBed: string;
  goSleep: string;
  timeToSleep: number;
  wakeUpDuration: number;
  wakeUp: string;
  getUp: string;
}

export const getSleepDiaryAnalysis = ({
  goBed,
  goSleep,
  timeToSleep,
  wakeUpDuration,
  wakeUp,
  getUp,
}: SleepDiaryParams): SleepDiaryAnalysis => {
  const startOfSleepTime: string = performHourSum(timeToSleep, goSleep);
  const timeInMinutes = getTimeInMinutesChronologically([goBed, goSleep, startOfSleepTime, wakeUp, getUp]);
  const [goBedInMinutes, goSleepInMinutes, _, wakeUpInMinutes, getUpInMinutes] = timeInMinutes;
  const minutesInBed = getUpInMinutes - goBedInMinutes;
  const timeToTurnOffLights = goSleepInMinutes - goBedInMinutes;
  const totalTimeToSleep = timeToTurnOffLights + timeToSleep;

  const earlyMorningAwakening = getUpInMinutes - wakeUpInMinutes;
  const awakeAtNight = wakeUpDuration + earlyMorningAwakening;
  const minutesSleep = wakeUpInMinutes - goSleepInMinutes - timeToSleep - wakeUpDuration;
  const eficiency = Math.round((minutesSleep / minutesInBed) * 100);
  return {
    startOfSleepTime,
    timeToTurnOffLights,
    totalTimeToSleep,
    awakeAtNight,
    earlyMorningAwakening,
    minutesInBed,
    minutesSleep,
    eficiency,
  } satisfies SleepDiaryAnalysis;
};

////////////////////////////////////////////////////////////////////////////////////////////////////

export type MapToSleepDiaryWithAnalysisInput = Partial<
  Pick<Sleep_Diary, 'date' | 'go_bed' | 'go_sleep' | 'time_to_sleep' | 'wake_up_duration' | 'wake_up' | 'wake_up_count' | 'get_up'> & {
    sleep_diary_medicines: Array<unknown>;
    tags: Array<{ sleep_tag: Sleep_Tag_Enum_Enum }>;
  }
>;

export interface SleepDiaryWithAnalysis extends Partial<SleepDiaryAnalysis>, MapToSleepDiaryWithAnalysisInput {
  entryDate: Date;
  usedMedication?: boolean;
}

export function mapToSleepDiaryWithAnalysis(diary: MapToSleepDiaryWithAnalysisInput): SleepDiaryWithAnalysis {
  const entryDate: Date = parseToDate(diary.date);
  if (!diary.go_bed) {
    return {
      entryDate,
      ...diary,
    };
  }

  const usedMedication =
    !!diary.tags.find((tag) => tag.sleep_tag === Sleep_Tag_Enum_Enum.Medicine) || diary.sleep_diary_medicines?.length > 0;
  const analysis: SleepDiaryAnalysis = getSleepDiaryAnalysis({
    goBed: diary.go_bed,
    goSleep: diary.go_sleep,
    timeToSleep: diary.time_to_sleep,
    wakeUpDuration: diary.wake_up_duration,
    wakeUp: diary.wake_up,
    getUp: diary.get_up,
  });
  return {
    entryDate,
    usedMedication,
    ...analysis,
    ...diary,
  };
}
