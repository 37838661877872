import { createUrlWithParams } from '../../url/create-url-with-params';
import { diactriclessKebabCase } from '../../string/case';

interface GetPatientReportUrlInput {
  first_name?: string;
  last_name?: string;
  id: string;
}

export interface HealthCarerPatientReportRoute {
  user?: string;
}

export const HealthCarerPatientReportRouteUrl = '/paciente/:user';
export const TestPatientReportRouteUrl = '/t/:user';

const getPatientReportUrl = (path: string, { first_name, last_name, id }: GetPatientReportUrlInput): string =>
  createUrlWithParams<HealthCarerPatientReportRoute>(path, {
    user: `${diactriclessKebabCase(`${first_name}${last_name ? ` ${last_name}` : ''}`)}--${id}`,
  });

export const getHealthCarerPatientReportUrl = (input: GetPatientReportUrlInput): string =>
  getPatientReportUrl(HealthCarerPatientReportRouteUrl, input);

export const getTestPatientReportUrl = (input: GetPatientReportUrlInput): string => getPatientReportUrl(TestPatientReportRouteUrl, input);

export const getIdFromHealthCarerPatientReportUrl = (url: string): string => {
  return url.split('--')[1];
};
