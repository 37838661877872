import { Col, Grid, LargeSeparator, Row } from '@web/atomic';
import * as React from 'react';

const EmptyListPlaceholder: React.FunctionComponent = () => {
  return (
    <Grid>
      <Row>
        <Col xs={12}>
          <LargeSeparator />
          <LargeSeparator />
        </Col>
      </Row>
    </Grid>
  );
};

export default EmptyListPlaceholder;
