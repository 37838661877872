/* eslint-disable  */
// https://jonlabelle.com/snippets/view/javascript/calculate-mean-median-mode-and-range-in-javascript

/**
 * The "mode" is the number that is repeated most often.
 *
 * For example, the "mode" of [3, 5, 4, 4, 1, 1, 2, 3] is [1, 3, 4].
 *
 * @param {Array} numbers An array of numbers.
 * @return {Array} The mode of the specified numbers.
 */
export function mode(numbers: number[]) {
  // as result can be bimodal or multi-modal,
  // the returned result is provided as an array
  // mode of [3, 5, 4, 4, 1, 1, 2, 3] = [1, 3, 4]
  const modes = [];
  const count = [];
  let i;
  let num;
  let maxIndex = 0;

  for (i = 0; i < numbers.length; i += 1) {
    num = numbers[i];
    count[num] = (count[num] || 0) + 1;
    if (count[num] > maxIndex) {
      maxIndex = count[num];
    }
  }

  for (i in count) {
    if (count.hasOwnProperty(i)) {
      if (count[i] === maxIndex) {
        modes.push(i);
      }
    }
  }
  return modes;
}

function onlyNumbers(array: any[]) {
  return array.every((element) => {
    return typeof element === 'number';
  });
}

export function median(array: any[]) {
  if (array?.length === 1) return array[0];

  const sorted = array.slice().sort((a, b) => a - b);
  const middle = Math.floor(sorted.length / 2);

  if (onlyNumbers(sorted)) {
    return (sorted[middle - 1] + sorted[middle]) / 2;
  }

  return sorted[middle];
}

/**
 * https://dustinpfister.github.io/2018/02/20/statistics-standard-deviation/
 * @param data
 */
export const getStandardDeviation = (data: number[]) => {
  const m = mean(data);
  if (!m) return null;

  return Math.sqrt(
    data.reduce((sq, n) => {
      return sq + Math.pow(n - m, 2);
    }, 0) /
      (data.length - 1)
  );
};

/**
 * The "mean" is the "average" you're used to, where you add up all the numbers
 * and then divide by the number of numbers.
 *
 * For example, the "mean" of [3, 5, 4, 4, 1, 1, 2, 3] is 2.875.
 *
 * @param {Array} numbers An array of numbers.
 * @return {Number} The calculated average (or mean) value from the specified
 *     numbers.
 */
export function mean(numbers: number[]) {
  if (!numbers || !numbers.length) return null;

  var total = 0,
    i;
  for (i = 0; i < numbers.length; i += 1) {
    total += numbers[i];
  }
  return total / numbers.length;
}
