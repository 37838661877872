export function parseSeconds(seconds: number): string {
  const { hours, minutes, seconds: remainingSeconds } = secondsToTime(seconds);
  let formattedTime = '';

  if (hours > 0) {
    formattedTime += `${hours}h`;
  }

  if (minutes > 0) {
    formattedTime += `${minutes}m`;
  }

  if ((hours === 0 && remainingSeconds > 0) || formattedTime === '') {
    formattedTime += `${remainingSeconds}s`;
  }

  return formattedTime;
}

function secondsToTime(totalSeconds: number): { hours: number; minutes: number; seconds: number } {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return { hours, minutes, seconds };
}
