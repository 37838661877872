import { DoctorReport } from '@component/doctor-report/components/doctor-report.component';
import { DoctorReportPageContainer } from '@component/doctor-report/doctor-report.page';
import { faComments, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { parseToDate } from '@global/utils/date/parse-to-date';
import { getGenderSuffix } from '@global/utils/domain/entities';
import { getIdFromHealthCarerPatientReportUrl } from '@global/utils/domain/health-carer/patient-report-url';
import { createUrlWithParams } from '@global/utils/url/create-url-with-params';
import { Body, Button, Col, FaIcon, Grid, H3, Row, Separator } from '@web/atomic';
import { TextAreaField } from '@web/atomic/legacy/atm.text-field';
import flashDispatcherService from '@web/atomic/legacy/obj.flash-wrapper/flash-dispatcher.service';
import { Form, Validators } from '@web/atomic/legacy/obj.form';
import { Modal } from '@web/atomic/legacy/obj.modal';
import { useBooleanState } from '@web/atomic/obj.boolean-state/boolean-state.hook';
import { InlineBlockStyled } from '@web/atomic/obj.wrappers';
import { PostUrl } from '@web/data/vigilantes.datasource';
import { EventKind, sendEvent } from '@web/utils/analytics';
import * as React from 'react';
import { useContext } from 'react';
import TinyMask from 'tinymask';
import { ReachButton } from '../../components/atm.button/button.component';
import { ShareVigilantesDoSono } from '../../components/mol.share/share.component';
import { usePostUsingFirebase } from '../../components/obj.custom-hooks/use-post-using-firebase.hook';
import { Layout } from '../../components/org.layout/layout.component';
import { withAuth } from '../../components/router/with-auth.hoc';
import { HcpUserContext } from '../../UserProvider';
import { PatientListRouteUrl } from '../patient-list/patient-list.route';
import { PrintPatientReportRouteProps, PrintPatientReportRouteUrl } from './print-patient-report.route';

interface ReportPageProps {
  user?: string;
}

const ReportPage: React.FunctionComponent<ReportPageProps> = (props) => {
  const hcp = useContext(HcpUserContext);
  return (
    <Layout>
      <Grid fluid>
        <Row mt hideOnPrint>
          <Col xs={12}>
            <ReachButton kind="link" to={PatientListRouteUrl}>
              <FaIcon.Back size="lg" />
              Voltar para lista de pacientes
            </ReachButton>
            <Separator />
          </Col>
        </Row>
        <Row hideOnPrint>
          <Col xs={12}>
            <ShareVigilantesDoSono />
          </Col>
        </Row>
      </Grid>
      <DoctorReportPageContainer userId={getIdFromHealthCarerPatientReportUrl(props.user)}>
        {(data) => {
          const { user, rating, sleep_diary } = data || {};
          const userInfo = user?.[0];
          if (!userInfo) {
            return null;
          }
          const printLink =
            props.user && createUrlWithParams<PrintPatientReportRouteProps>(PrintPatientReportRouteUrl, { user: props.user });
          const lastRatingDate = parseToDate(rating?.[0]?.created)?.getTime() || 0;
          const lastDiaryDate = parseToDate(sleep_diary?.[sleep_diary.length - 1]?.created_at)?.getTime() || 0;
          const daysSinceLastAccess = (Date.now() - Math.max(lastRatingDate, lastDiaryDate)) / (1000 * 60 * 60 * 24);
          const canSendChatfuelMessage = daysSinceLastAccess <= 1;

          return (
            <DoctorReport
              data={data}
              printMode={false}
              actionButtons={[
                <ContactButton
                  key="contact"
                  userId={userInfo.id}
                  tipPosition={'top'}
                  email={userInfo?.email}
                  gender={userInfo?.gender}
                  phone={userInfo?.phone}
                  canSendChatfuelMessage={canSendChatfuelMessage}
                  hcpId={hcp.uid}
                />,
                <PrintButton key="print" to={printLink} />,
              ]}
            />
          );
        }}
      </DoctorReportPageContainer>
    </Layout>
  );
};

const AuthDoctorReportPage = withAuth(ReportPage);
export default AuthDoctorReportPage;

////////////////////////////////////////////////////////////////////////////////////////////////////

interface PrintButtonProps {
  to: string;
}

const PrintButton: React.FunctionComponent<PrintButtonProps> = (props) => {
  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a href={props.to} rel={'alternate'} target="_blank">
      <Button kind="secondary">
        <FontAwesomeIcon fixedWidth={true} icon={faPrint} /> Imprimir
      </Button>
    </a>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////

interface ContactButtonProps {
  tipPosition: 'top' | 'bottom';
  userId: string;
  email: string;
  gender: string;
  phone: string;
  canSendChatfuelMessage: boolean;
  hcpId: string;
}

const ContactButton: React.FunctionComponent<ContactButtonProps> = (props) => {
  const { value: modalOpened, setFalse: closeModal, setTrue } = useBooleanState(false);
  const handleCloseClick = () => {
    closeModal();
  };

  const openModal = () => {
    setTrue();
    sendEvent(EventKind.click, {
      event_category: 'user_notification',
      event_label: 'open notification modal',
    });
  };

  const contactInfo = [
    props.email && (
      <a href={`mailto:${props.email}`} target="_blank" rel="noreferrer">
        email {props.email}
      </a>
    ),
    props.phone && (
      <a href={`tel:${props.phone}`} target="_blank" rel="noreferrer">
        telefone {new TinyMask('99 99 9999-9999').mask(props.phone)}{' '}
      </a>
    ),
  ].filter((item) => item);

  const postSuccess = () => {
    flashDispatcherService.dispatchMessage('Recado enviado com sucesso', 'success');
    closeModal();
  };
  const [postNotifyUser, { loading }] = usePostUsingFirebase({ url: PostUrl.NotifyUser, onSuccess: postSuccess });
  const user = React.useContext(HcpUserContext);
  const handleSubmit = (form) => {
    if (Object.keys(form.error).length !== 0) return;
    sendEvent(EventKind.click, {
      event_category: 'user_notification',
      event_label: 'send message to user',
    });
    postNotifyUser({
      user_id: props.userId,
      message: `### Você recebeu um recado de ${user?.name}\n${form.data.comment}`,
      hcp_id: props.hcpId,
    });
  };

  const buttonText = `Enviar recado para ${getGenderSuffix(props.gender)} paciente`;
  if (contactInfo.length === 0 && !props.canSendChatfuelMessage) {
    return (
      <InlineBlockStyled
        role="tooltip"
        data-microtip-position={props.tipPosition}
        data-microtip-size="fit"
        aria-label={'Esse usuário não permitiu ser contatado.'}
      >
        <Button disabled={true}>
          <FontAwesomeIcon fixedWidth={true} icon={faComments} /> {buttonText}
        </Button>
      </InlineBlockStyled>
    );
  }

  const showContactInfo = contactInfo?.length > 0;
  return (
    <>
      <Button kind={'primary'} onClick={openModal}>
        <FontAwesomeIcon fixedWidth={true} icon={faComments} /> {buttonText}
      </Button>
      <Modal disableScrollLock={true} small={true} opened={modalOpened} onClose={handleCloseClick}>
        <>
          <H3>{buttonText}</H3>
          {showContactInfo && (
            <Body>
              Você pode entrar em contato diretamente (
              {contactInfo.reduce((acc, curr) => (
                <>
                  {acc} , {curr}
                </>
              ))}
              )
            </Body>
          )}
          {(props.email || props.canSendChatfuelMessage) && (
            <>
              <Body> {showContactInfo ? 'Ou você pode deixar que nós' : 'Nós'} enviamos um recado por você. Basta escrevê-la abaixo:</Body>
              <Form onSubmit={handleSubmit}>
                <Form.Field name="comment" validators={[Validators.Required()]}>
                  <TextAreaField />
                </Form.Field>
                <Separator />
                <Button type="submit" kind="primary" loading={loading} expanded>
                  Enviar recado
                </Button>
              </Form>
            </>
          )}
          <Separator />
        </>
      </Modal>
    </>
  );
};
