/* eslint-disable react/display-name */
import {
  faFacebookMessenger,
  faFacebookSquare,
  faInstagram,
  faLinkedin,
  faTwitter,
  faWhatsapp,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { faClock, faQuestionCircle, faSquare, faCreditCard } from '@fortawesome/free-regular-svg-icons';
import {
  faAngleRight,
  faBan,
  faBarcode,
  faBars,
  faChartArea,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClone,
  faEdit,
  faEnvelope,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFileMedical,
  faImage,
  faInfoCircle,
  faKey,
  faLaughWink,
  faLink,
  faMinus,
  faPhone,
  faPlus,
  faQrcode,
  faShareAlt,
  faSignOutAlt,
  faStar,
  faStarHalf,
  faTable,
  faTimes,
  faTimesCircle,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppThemeContext } from '@web/atomic';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { BrandColor, GrayColor, LightColor } from '../obj.constants';

export const FaIcon = {
  CreditCard: (props) => <FontAwesomeIcon {...props} icon={faCreditCard} />,
  Boleto: (props) => <FontAwesomeIcon {...props} icon={faBarcode} />,
  ArrowRight: (props) => <FontAwesomeIcon {...props} icon={faAngleRight} />,
  Back: (props) => <FontAwesomeIcon {...props} icon={faChevronLeft} />,
  Ban: (props) => <FontAwesomeIcon {...props} icon={faBan} />,
  Bars: (props) => <FontAwesomeIcon {...props} icon={faBars} />,
  ChartArea: (props) => <FontAwesomeIcon {...props} icon={faChartArea} />,
  Check: (props) => <FontAwesomeIcon {...props} icon={faCheck} />,
  CheckLi: (props) => <FontAwesomeIcon {...props} color={BrandColor.Green} icon={faCheck} />,
  ChevronDown: (props) => <FontAwesomeIcon {...props} icon={faChevronDown} />,
  ChevronRight: (props) => <FontAwesomeIcon {...props} icon={faChevronRight} />,
  ChevronUp: (props) => <FontAwesomeIcon {...props} icon={faChevronUp} />,
  Clock: (props) => <FontAwesomeIcon {...props} icon={faClock} />,
  Clone: (props) => <FontAwesomeIcon {...props} icon={faClone} />,
  Close: (props) => <FontAwesomeIcon {...props} icon={faTimes} />,
  Edit: (props) => <FontAwesomeIcon {...props} icon={faEdit} />,
  Envelope: (props) => <FontAwesomeIcon {...props} icon={faEnvelope} />,
  Eye: (props) => <FontAwesomeIcon {...props} icon={faEye} />,
  EyeSlash: (props) => <FontAwesomeIcon {...props} icon={faEyeSlash} />,
  Facebook: (props) => <FontAwesomeIcon {...props} icon={faFacebookSquare} />,
  FileMedical: (props) => <FontAwesomeIcon {...props} icon={faFileMedical} />,
  FileUpload: (props) => <FontAwesomeIcon {...props} icon={faUpload} />,
  FlashAlert: (props) => <FontAwesomeIcon {...props} icon={faTimesCircle} />,
  FlashInfo: (props) => <FontAwesomeIcon {...props} icon={faInfoCircle} />,
  FlashSuccess: (props) => <FontAwesomeIcon {...props} icon={faCheckCircle} />,
  FlashWarning: (props) => <FontAwesomeIcon {...props} icon={faExclamationCircle} />,
  Image: (props) => <FontAwesomeIcon {...props} icon={faImage} />,
  Instagram: (props) => <FontAwesomeIcon {...props} icon={faInstagram} />,
  Key: (props) => <FontAwesomeIcon {...props} icon={faKey} />,
  LaughWink: (props) => <FontAwesomeIcon {...props} icon={faLaughWink} />,
  Link: (props) => <FontAwesomeIcon {...props} icon={faLink} />,
  Linkedin: (props) => <FontAwesomeIcon {...props} icon={faLinkedin} />,
  Messenger: (props) => <FontAwesomeIcon {...props} icon={faFacebookMessenger} />,
  Phone: (props) => <FontAwesomeIcon {...props} icon={faPhone} />,
  Share: (props) => <FontAwesomeIcon {...props} icon={faShareAlt} />,
  SignOut: (props) => <FontAwesomeIcon {...props} icon={faSignOutAlt} />,
  Square: (props) => <FontAwesomeIcon {...props} icon={faSquare} />,
  Star: (props) => <FontAwesomeIcon {...props} icon={faStar} />,
  StarHalf: (props) => <FontAwesomeIcon {...props} icon={faStarHalf} />,
  StepperMinus: (props) => <FontAwesomeIcon {...props} icon={faMinus} />,
  StepperPlus: (props) => <FontAwesomeIcon {...props} icon={faPlus} />,
  Table: (props) => <FontAwesomeIcon {...props} icon={faTable} />,
  Twitter: (props) => <FontAwesomeIcon {...props} icon={faTwitter} />,
  Warning: (props) => <FontAwesomeIcon {...props} icon={faExclamationTriangle} />,
  Whatsapp: (props) => <FontAwesomeIcon {...props} icon={faWhatsapp} />,
  Youtube: (props) => <FontAwesomeIcon {...props} icon={faYoutube} />,
  Question: (props) => {
    const theme = useContext(ThemeContext);
    return (
      <FontAwesomeIcon
        {...props}
        color={props.color || theme.name === 'dark' ? GrayColor.White : LightColor.Primary}
        icon={faQuestionCircle}
      />
    );
  },
  Pix: (props) => {
    const size = props.size === 'sm' ? 14 : 12;
    return (
      <svg width={size} height={size} fill={props.color} {...props}>
        <g clipPath="url(#prefix__clip0)">
          <path d="M2.642 9.18c.47 0 .913-.183 1.246-.516l1.8-1.8a.342.342 0 01.473 0L7.968 8.67c.332.333.775.516 1.246.516h.355l-2.28 2.28a1.823 1.823 0 01-2.578 0L2.425 9.18h.217zm6.572-6.367c-.47 0-.914.184-1.247.517L6.161 5.136a.335.335 0 01-.473 0l-1.8-1.8a1.752 1.752 0 00-1.246-.516h-.217L4.71.534a1.823 1.823 0 012.578 0l2.28 2.28h-.355z" />
          <path d="M.534 4.71l1.381-1.38c.03.012.064.02.099.02h.628c.324 0 .642.13.872.36l1.8 1.8a.861.861 0 001.221 0l1.807-1.806c.23-.23.547-.361.872-.361h.772a.262.262 0 00.093-.02l1.387 1.388a1.823 1.823 0 010 2.578l-1.387 1.387a.262.262 0 00-.093-.019h-.772c-.325 0-.643-.131-.872-.361L6.535 6.49a.885.885 0 00-1.221 0l-1.8 1.8c-.23.23-.548.36-.872.36h-.628a.263.263 0 00-.099.02L.534 7.29a1.823 1.823 0 010-2.58z" />
        </g>
        <defs>
          <clipPath id="prefix__clip0">
            <path fill="#fff" d="M0 0h12v12H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  },
};
