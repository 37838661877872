import { LightColor } from '@web/atomic/legacy/obj.constants';
import styled from 'styled-components';

export const BackgroundStyled = styled.div`
  background-color: ${(props) => props.theme.color.background};
  height: 100%;
  transition: background-color 0.2s ease-in;
`;

export const FullScreenPrimaryBackground = styled.section`
  background: ${LightColor.Primary};
  min-height: 100vh;
  width: 100%;
`;
