import { RouteComponentProps } from '@reach/router';
import * as React from 'react';
import LoginPage from './login.page';

export const LoginRouteUrl = '/entrar';

export interface SignUpRouteState {
  email: string;
}

export const LoginRoute: React.FunctionComponent<RouteComponentProps> = (props) => {
  const state = props.location.state as SignUpRouteState;
  return <LoginPage initialEmail={state?.email} />;
};
