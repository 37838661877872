import { hasWindow } from '@web/utils/platform';
import { NativeConfig, NativeConfigWebviewExtraData } from '@global/utils/native/native-config';
import { NativeMessage } from '@global/utils/native/native-message';
import { useEffect, useState } from 'react';
import QueryString from 'querystringify';

// all values are inserted by VigilantesDoSono app(using this:
// https://github.com/react-native-webview/react-native-webview/blob/master/docs/Guide.md#the-injectedjavascriptbeforecontentloaded-prop)
export interface CustomWindow extends Window, NativeConfig {}

declare let window: CustomWindow;

export class NativeHelper {
  static get isNative(): boolean {
    return hasWindow() && window?.isNative;
  }
  static get token(): string {
    return NativeHelper.isNative && window?.native?.token;
  }
  static get version(): string {
    return NativeHelper.isNative && window?.native?.version;
  }
  static get userId(): string {
    const userId = NativeHelper.isNative && window?.native?.userId;
    if (userId) {
      return userId;
    } else {
      // HACK_ALERT (for debug purpose): if userId is not set, then use "psid" query-param as userId
      const search: Partial<{ psid: string }> = QueryString.parse(hasWindow() ? window.location.search : '');
      const psid = search.psid;
      return psid;
    }
  }
  static get extraData(): NativeConfigWebviewExtraData {
    return NativeHelper.isNative && window?.native?.extraData;
  }

  static async postMessage(message: NativeMessage) {
    try {
      return window.ReactNativeWebView.postMessage(JSON.stringify(message));
    } catch (error) {
      console.error(`ERROR -> postMessage -> NativeHelper.isNative`, NativeHelper.isNative, message, error);
    }
  }
}

export const useNative = (): boolean => {
  const [native, setNative] = useState(false);
  useEffect(() => {
    setNative(NativeHelper.isNative);
  }, []);
  return native;
};
