import { Stop_Bang } from 'remote-graphql-types';
import { ChatfuelBlockIds, ChatfuelBlockNames } from '../chat/chatfuel-blocks-types';
import { IQuestionnaireResultExplanationData } from './questionnaire';

export enum StopBangResult {
  LowRisk = 1,
  IntermediateRisk = 2,
  HighRisk = 3,
}

/**
0. Baixo risco de AOS (apneia obstrutiva do sono): Sim para 0 a 2 perguntas
1. Risco intermediário de AOS: Sim para 3 a 4 perguntas
2. Risco alto de AOS: Sim para 5 a 8 perguntas
 ou Sim para 2 ou mais das 4 perguntas iniciais + sexo masculino
 ou Sim para 2 ou mais das 4 perguntas iniciais + IMC > 35 kg/m²
 ou Sim para 2 ou mais das 4 perguntas iniciais + circunferência do pescoço (43 cm em homens, 41 cm em mulheres)
 */
export const computeStopbangPoints = (stopbang: Pick<Stop_Bang, 'q1' | 'q2' | 'q3' | 'q4' | 'q5' | 'q6' | 'q7' | 'q8'>): StopBangResult => {
  const stopScore = stopbang.q1 + stopbang.q2 + stopbang.q3 + stopbang.q4;
  const stopbangScore = stopbang.q1 + stopbang.q2 + stopbang.q3 + stopbang.q4 + stopbang.q5 + stopbang.q6 + stopbang.q7 + stopbang.q8;

  if (
    stopScore >= 2 &&
    // Índice de massa corporal maior que 35 kg/m2
    (stopbang.q5 === 1 ||
      // O pescoço é grosso
      stopbang.q7 === 1 ||
      // Sexo = Masculino?
      stopbang.q8 === 1)
  ) {
    return StopBangResult.HighRisk;
  }

  if (stopbangScore <= 2) {
    return StopBangResult.LowRisk;
  } else if (stopbangScore === 3 || stopbangScore === 4) {
    return StopBangResult.IntermediateRisk;
  } else {
    return StopBangResult.HighRisk;
  }
};

/*
 *
 * Make sure the redirectBlock and Initial Block are the same block
 *
 */
const redirectBlock: ChatfuelBlockIds = '65f5d1a6f3a92603e57afa18';
export const stopbangInitialBlockName: ChatfuelBlockNames = 'stopbang_youpage_entrance';
export const stopbangQuestionnaire = { name: 'STOP-BANG', redirect: redirectBlock };

export const stopbangResultExplanationData: IQuestionnaireResultExplanationData = {
  title: 'STOP-BANG',
  description: 'O questionário STOP-BANG tem como objetivo avaliar o risco de apneia obstrutiva do sono.',
  results: [
    {
      title: '1 - Baixo Risco',
      description:
        'Seu resultado indica um baixo risco de apneia obstrutiva do sono. Embora isso sugira que você tem menor probabilidade de sofrer desta condição, é importante manter um estilo de vida saudável, incluindo exercícios regulares e uma dieta balanceada. Se experimentar novos sintomas ou alterações no seu padrão de sono, considere consultar um especialista.',
    },
    {
      title: '2 - Risco Intermediário',
      description:
        'Você apresenta um risco intermediário de apneia obstrutiva do sono. Isso significa que você pode ter algumas características associadas à condição, mas não necessariamente sofrerá dela. Recomenda-se monitorar a qualidade do seu sono e considerar medidas para melhorar a higiene do sono, como estabelecer uma rotina regular de sono, evitar cafeína e eletrônicos antes de dormir. Se observar sintomas como sonolência excessiva durante o dia ou se alguém observar que você para de respirar enquanto dorme, consulte um especialista.',
    },
    {
      title: '3 - Alto Risco',
      description:
        'Seu resultado indica um alto risco de apneia obstrutiva do sono, uma condição que pode afetar significativamente a qualidade do seu sono e saúde geral. É fortemente recomendado que você consulte um especialista em medicina do sono para uma avaliação mais aprofundada. Tratamentos estão disponíveis e podem incluir mudanças no estilo de vida, dispositivos de pressão positiva contínua nas vias aéreas (CPAP) ou até mesmo intervenção cirúrgica, dependendo da gravidade do seu caso. Não ignore esses sinais, pois tratar a apneia do sono pode melhorar significativamente sua qualidade de vida.',
    },
  ],
};
