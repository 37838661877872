import * as React from 'react';
import { InputCaption, InputCaptionError } from '@web/atomic/legacy/atm.typography';
import { ValidationError } from './validators';
import { Shake } from '../obj.animation/animation.component.style';

export interface FormFieldCaptionProps {
  errors?: ValidationError[];
  showAll?: boolean;
  validationPlaceholder?: string;
}

export const FormFieldCaption: React.FunctionComponent<FormFieldCaptionProps> = (props) => {
  const wrap = props.errors.map((error: ValidationError, index: number) => (
    <InputCaptionError key={error.name + index}>{error.message}</InputCaptionError>
  ));

  return (
    <React.Fragment>
      {props.showAll ? (
        wrap
      ) : props.errors && props.errors.length > 0 ? (
        <Shake>
          <InputCaptionError key={props.errors[0].name}>{props.errors[0].message}</InputCaptionError>
        </Shake>
      ) : (
        props.validationPlaceholder && <InputCaption>{props.validationPlaceholder}</InputCaption>
      )}
    </React.Fragment>
  );
};
