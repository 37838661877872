import styled from 'styled-components';
import { Spacing } from '..';

// Useful to make elements which are "display: block" stay in the same line
export interface InlineBlockStyledProps {
  verticalAlign?: 'bottom' | 'middle';
  mb?: boolean;
}

export const InlineBlockStyled = styled.div<InlineBlockStyledProps>`
  display: inline-block;
  vertical-align: ${(props) => props.verticalAlign};
  margin-bottom: ${(props) => (props.mb ? Spacing.Small : 0)};

  :not(:last-child) {
    margin-right: ${Spacing.Small};
  }
`;
