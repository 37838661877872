import { isNullOrUndefined } from '@global/utils/object';
import { getStorage, StorageType } from '@web/data/storage/storage.provider';

const IS_EYE_OPEN = 'IS_EYE_OPEN';

class UserMenuDataSource {
  private localStorage = getStorage(StorageType.Local);

  get eyeOpen() {
    const result = this.localStorage.get(IS_EYE_OPEN);
    return isNullOrUndefined(result) ? true : result;
  }
  set eyeOpen(value: boolean) {
    this.localStorage.put(IS_EYE_OPEN, value);
  }
}

export default new UserMenuDataSource();
