export enum RedeemCouponKind {
  /** this kind of coupon lets you specify when the user subscription will expire */
  SubscriptionExpirationWithDate = 'subscription_expiration_with_date',
  /** this kind of coupon lets you specify how many days the user subscription will have */
  SubscriptionExpirationInDays = 'subscription_expiration_in_days',
  /** just adds a relationship - same as a 0% discount -  (connect a user to their doctor; connect a user to their company) */
  Relationship = 'relationship',
  /** this kind of coupon gives the patient a 10% discount */
  SubscriptionDiscount10 = 'subscription_discount_10',
  /** this kind of coupon gives the patient a 25% discount */
  SubscriptionDiscount25 = 'subscription_discount_25',
  /** this kind of coupon gives the patient a 30% discount */
  SubscriptionDiscount30 = 'subscription_discount_30',
  /** this kind of coupon gives the patient a 70% discount */
  SubscriptionDiscount70 = 'subscription_discount_70',
  /** this kind of coupon gives the patient discount in appointments
   * @deprecated 6Qxlq3Py43BbkD5G telehealth was deprecated in june 2023 - https://www.notion.so/vigilantesdosono/remo-o-teleconsultas-pt1-23098acaf8de4bd085668cbbd92bf249?pvs=4
   */
  TelehealthDiscount = 'telehealth_discount',
}
