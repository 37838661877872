import { FontFamily, FontSize, FontWeight, GrayColor, Spacing } from '@web/atomic';
import styled, { css } from 'styled-components';

const TransactionItemBaseStyle = css`
  color: ${GrayColor.Gray};
  font-size: ${FontSize.XXSmall};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
`;

export const TransactionItemDateStyled = styled.p`
  ${TransactionItemBaseStyle}

  text-align: right;
`;

export const TransactionItemCaptionStyled = styled.p`
  ${TransactionItemBaseStyle}
  word-break: break-all;

  text-align: left;
  margin-top: 13px;
`;
