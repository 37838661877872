import { LightColor, DarkColor, GrayColor, BrandColor } from '@global/utils/ui/colors';
import { BaseTheme } from './theme.interface';
import { GridSettings } from '@web/atomic/legacy/obj.grid';
import { lightenDarkenColor } from '@global/utils/ui/color-fn';

export const darkTheme: BaseTheme = {
  name: 'dark',
  color: {
    primary: DarkColor.Primary,
    secondary: DarkColor.Secondary,
    accessory: DarkColor.Accessory,
    callToAction: DarkColor.CallToAction,
    success: DarkColor.Success,
    alert: lightenDarkenColor(DarkColor.Alert, 35),
    warning: DarkColor.Warning,
    background: BrandColor.EbonyClay,
    text: GrayColor.White,
  },
  ...GridSettings,
};

export const regularTheme: BaseTheme = {
  name: 'regular',
  color: {
    primary: LightColor.Primary,
    secondary: LightColor.Secondary,
    accessory: LightColor.Accessory,
    callToAction: LightColor.CallToAction,
    success: LightColor.Success,
    alert: LightColor.Alert,
    warning: LightColor.Warning,
    background: GrayColor.White,
    text: GrayColor.GrayDark,
  },
  ...GridSettings,
};
