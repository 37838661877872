import { HealthCarerKind } from './health-carer-factory';

export const validateProfessionalId = (kind: HealthCarerKind, value: any): boolean => {
  if (kind === HealthCarerKind.Doctor) {
    const professionalIdRegex = new RegExp('^[0-9]{1,7}$');
    if (typeof value === 'string') {
      return professionalIdRegex.test(value) && value.length > 0;
    }

    return professionalIdRegex.test(value) && ((value && Object.keys(value).length > 0) || !isNaN(value));
  } else {
    return !!value;
  }
};
